// import DFDataAPI from "../../../APIs/DFDataAPI";
// import { log } from "../../../helperFunctions";
import { v4 } from "uuid";

export default class DFTemplate {
	constructor(template) {
		if (template instanceof DFTemplate) {
			Object.assign(this, template);
		} else {
			this.queryData = template?.queryData ?? "DFTemplate";
			this.uid = template?.uid ?? v4();
			this.id = template?.id?.toString() ?? null;

			this.templateType = template?.templateType ?? "D&F";
			this.templateID = template?.templateID?.toString() ?? null;
			this.templateName = template?.templateName ?? null;

			this.includeScopes = template?.includeScopes ?? "";
			this.scopeID = template?.scopeID?.toString() ?? null;
			this.scopeDesc = template?.scopeDesc ?? null;
			this.scopeAbbr = template?.scopeAbbr ?? null;
			this.regionID = template?.regionID?.toString() ?? null;

			this.isArchived = template?.isArchived?.toString() ?? "0";
		}
	}

	// async insertDB() {
	// 	if (!Boolean(this.id)) {
	// 		let data = (({ duration }) => ({
	// 			duration,
	// 		}))(this);
	// 		const DFDataApi = new DFDataAPI();
	// 		let result = await DFDataApi.PortalUser_DFData_InsertTemplate(data);
	// 		log("Database Result (insert template)", result);
	// 		return result;
	// 	}
	// }

	// async updateDB() {
	// 	//TODO: Notify notifyRole when dateCompleted is not null
	// 	if (!Boolean(this.id)) {
	// 		let data = (({ Header, respRole, notifyRole, duration, isVisible }) => ({
	// 			Header,
	// 			respRole,
	// 			notifyRole,
	// 			duration,
	// 			isVisible,
	// 		}))(this);
	// 		const DFDataApi = new DFDataAPI();
	// 		let result = await DFDataApi.PortalUser_DFData_UpdateTemplate(this.id, data);
	// 		log("Database Result (update template)", result);
	// 		return result;
	// 	}
	// }

	// async deleteDB() {
	// 	if (Boolean(this.id)) {
	// 		const DFDataApi = new DFDataAPI();
	// 		let result = await DFDataApi.PortalUser_DFData_DeleteTemplate(this.id);
	// 		log("Database Result (DELETE template)", result);
	// 		return result;
	// 	}
	// }

	initEgnyte() {
		//TODO: Update folder name at deliverable level if template name is changed
	}
}
