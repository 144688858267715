import moment from "moment";
import { getValueColor } from "../../../helperFunctions";

export default class SectionTemplateMFRContractStatus {
	constructor(contract = null, contractTotals = null) {
		this.template = "MFRContractStatus";
		this.name = "Contract Status";
		this.label = "II.";
		this.heading = "Contract Status";
		this.pagebreak = 0;
		this.divider = false;
		this.type = "labelValuePairs";
		this.subSections = [
			new SubSectionTemplateMFRContractStatus1(contract, contractTotals),
			new SubSectionTemplateMFRContractStatus2(contract, contractTotals),
		];
	}
}

class SubSectionTemplateMFRContractStatus1 {
	constructor(contract = null, contractTotals = null) {
		this.template = "mfrContractStatus1";
		this.type = "labelValuePairs";
		this.width = "60%";
		this.columns = [
			{
				accessor: "leftAddon",
				width: "7%",
			},
			{
				accessor: "label",
				width: "45%",
			},
			{
				accessor: "value",
				width: "30%",
				textAlign: "right",
				valueType: "currency",
				decimals: 0,
			},
			{
				accessor: "rightAddon",
				width: "17%",
				fontSize: 8,
			},
		];
		this.data = [
			{
				leftAddon: "A.",
				label: "Bid Contract",
				value: contractTotals?.bidContractAmt ?? contract?.bidContractAmt,
				valueFontWeight: "bold",
			},
			{
				label: "Approved Change Orders",
				value: contractTotals?.acoAmt,
			},
			{
				label: "Non-Contract Changes",
				value: contractTotals?.nonAmt,
			},
			{
				leftAddon: "B.",
				label: "Current Contract Amount",
				value: contractTotals?.contractAmt ?? contract?.contractAmt,
				rightAddon: "(a)",
			},
			{
				label: "Pending Change Orders",
				value: contractTotals?.pcoAmt ?? contract?.pcoAmt,
			},
			{
				label: "Projected PCO Conversion",
				value: contractTotals?.pcoConv ?? contract?.pcoAmt,
				rightAddon: "(b)",
			},
			{
				label: "RMA",
				value: contractTotals?.rmaAmt,
			},
			{
				label: "PMA",
				value: contractTotals?.pmaAmt,
				rightAddon: "(c)",
			},
			{
				label: "Allowances",
				value: contractTotals?.allowAmt,
				rightAddon: "(d)",
			},
			{
				leftAddon: "C.",
				label: "Projected Contract Amount",
				value: contractTotals?.projContractAmt,
				rightAddon: "(a+b+c+d)",
			},
		];
	}
}

class SubSectionTemplateMFRContractStatus2 {
	constructor(contract = null, contractTotals = {}) {
		let bidProfitAmt = 0;
		let bidMarginPct = 0;
		let projProfitAmt = 0;
		let projMarginPct = 0;
		let varProfitAmt = 0;
		let varMarginPct = 0;
		if (Boolean(contractTotals?.queryData)) {
			bidProfitAmt = contractTotals?.getBidProfitAmt();
			bidMarginPct = contractTotals?.getBidMarginPct();
			projProfitAmt = contractTotals?.getProjProfitAmt();
			projMarginPct = contractTotals?.getProjMarginPct();
			varProfitAmt = contractTotals?.getVarProfitAmt();
			varMarginPct = contractTotals?.getVarMarginPct();
		}

		this.template = "mfrContractStatus2";
		this.type = "labelValuePairs";
		this.width = "40%";
		this.columns = [
			{
				accessor: "label",
				width: "50%",
			},
			{
				accessor: "value",
				width: "40%",
				valueType: "currency",
				textAlign: "right",
			},
			{
				accessor: "rightAddon",
				width: "10%",
			},
		];
		this.data = [
			{
				label: "Bid Margin ($)",
				value: bidProfitAmt,
			},
			{
				label: "Bid Margin (%)",
				value: bidMarginPct,
				valueType: "percent" ?? null,
				valueDecimals: 1,
			},
			{
				label: "Projected Profit ($)",
				value: projProfitAmt,
			},
			{
				label: "Projected Margin (%)",
				value: projMarginPct,
				valueTextColor: getValueColor(projMarginPct),
				valueType: "percent",
				valueDecimals: 1,
			},
			{
				label: "Variance Profit ($)",
				value: varProfitAmt,
			},
			{
				label: "Variance Margin (%)",
				value: varMarginPct,
				valueTextColor: getValueColor(varMarginPct),
				valueType: "percent",
				valueDecimals: 1,
			},

			{
				label: null,
				value: null,
				valueType: "string",
				valueBorderBottomWidth: 0,
			},
			{
				label: "Bonded (Y/N)",
				labelTextAlign: "right",
				value: contract?.bondedYN === "Y" ? "Yes" : "No",
				valueType: "string",
				valueTextAlign: "center",
			},
			{
				label: "OCIP / CCIP",
				labelTextAlign: "right",
				value: contract?.insuranceType,
				valueType: "string",
				valueTextAlign: "center",
			},
			{
				label: "Billing Due",
				labelTextAlign: "right",
				value: Boolean(contract?.billingDue)
					? moment(new Date("1/" + contract?.billingDue + "/2023")).format("Do")
					: "N/A",
				valueType: "string",
				valueTextAlign: "center",
			},
		];
	}
}
