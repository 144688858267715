export default class TimecardJobClassLegacy {
	constructor(job) {
		this.keyId = job?.keyId ?? job?.KeyID ?? null;
		this.description = job?.description ?? job?.Description ?? "";
		this.jcco = job?.jcco ?? job?.JCCo ?? "1";
		this.job = job?.job ?? job?.Job ?? "";
		this.location = job?.location ?? job?.Location ?? "";
		this.key = job?.key ?? "";
		this.value = job?.value ?? "";
		this.phases = null;
	}
}
