import React from "react";
import { Box, Flex, Spacer, IconButton } from "@chakra-ui/react";

export default function OverlayDrawer({ overlaySize, bg, style, onClose, childComponent, overlayProps }) {
	return (
		<Box
			flex={
				overlaySize === "sm"
					? 1
					: overlaySize === "md"
					? 2
					: overlaySize === "lg"
					? 3
					: overlaySize === "xl"
					? 4
					: 6
			}
			shadow={Boolean(overlaySize) && overlaySize !== "full" ? "base" : "none"}
			p={{ base: 1, md: 2 }}
			bg={bg ?? "white"}
			style={style}
			rounded="lg"
		>
			<Box {...overlayProps}>
				<Flex>
					<Spacer />
					<IconButton
						size="xs"
						variant="ghost"
						color="gray.600"
						icon={<i className="fas fa-times fa-lg" />}
						onClick={onClose}
					/>
				</Flex>

				<Box>{childComponent}</Box>
			</Box>
		</Box>
	);
}
