import React, { useRef } from "react";
import {
	Button,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
} from "@chakra-ui/react";

export default function Alert({ isOpen, onClose, title, body, onDelete }) {
	const cancelRef = useRef();

	return (
		<AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
			<AlertDialogOverlay>
				<AlertDialogContent>
					{title && (
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{title}
						</AlertDialogHeader>
					)}
					<AlertDialogBody>{body ?? "Are you sure?"}</AlertDialogBody>
					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={onClose}>
							Cancel
						</Button>
						<Button colorScheme="red" onClick={onDelete} ml={3}>
							Delete
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
}
