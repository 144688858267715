import React, { useMemo, useState } from "react";
import { Flex, Heading, Text, Stack, Checkbox, useToast } from "@chakra-ui/react";
import { portalUser } from "../../../App";

export default function FormSectionEmployeeFeedback(props) {
	const toast = useToast();
	let form = useMemo(() => props.form ?? null, [props.form]);
	let assignedFor = useMemo(() => props.usersMap[form?.assignedFor] ?? null, [form?.assignedFor, props.usersMap]);
	let isPrinting = useMemo(() => props.isPrinting ?? null, [props.isPrinting]);

	const [reviewSigned, setReviewSigned] = useState(Boolean(form?.dateCompleted) ?? false);

	const updateFormDateCompleted = async () => {
		if (form) {
			let newDate = form.dateCompleted ? null : new Date();
			let result = await form?.updateForm("dateCompleted", newDate);
			if (result) {
				setReviewSigned(Boolean(newDate));
			} else {
				toast({
					title: "Something went wrong!",
					description: "Please refresh the page and try again!",
					status: "error",
					duration: 5000,
					isClosable: true,
				});
			}
		}
	};

	return (
		<Stack id="FormSection-EmployeeFeedback" w="full" flex={1} spacing={4} rounded="md" px={4}>
			<Checkbox
				size="lg"
				isChecked={Boolean(reviewSigned)}
				onChange={updateFormDateCompleted}
				isDisabled={form?.assignedFor !== portalUser.user?.employeeUID && !isPrinting}
			>
				By checking this box, I agree that I have reviewed this performance evaluation with my manager.
			</Checkbox>

			{Boolean(reviewSigned) && (
				<Stack spacing={2}>
					<Flex borderBottomWidth={2} borderBottomColor="gray.300" maxW="sm" py={1}>
						<Heading className="signature" as="h3" size="2xl">
							{assignedFor?.name}
						</Heading>
					</Flex>

					<Stack spacing={0}>
						<Text fontSize="md" color="gray.500">
							Signed By: {assignedFor?.name}
						</Text>
						<Text fontSize="md" color="gray.500">
							Signed On: {form?.dateCompleted?.format("M/D/YYYY")}
						</Text>
					</Stack>
				</Stack>
			)}
		</Stack>
	);
}
