const HOLIDAYS = [
	{
		year: "2023",
		holidays: [
			{ month: "January", days: [{ name: "New Year's Day", day: "Monday, January 2nd", date: "1/2/2023" }] },
			{
				month: "February",
				days: [{ name: "President's Day", day: "Monday, February 20th", date: "2/20/2023" }],
			},
			{ month: "May", days: [{ name: "Memorial Day", day: "Monday, May 29th", date: "5/29/2023" }] },
			{ month: "July", days: [{ name: "Independence Day", day: "Tuesday, July 4th", date: "7/4/2023" }] },
			{ month: "September", days: [{ name: "Labor Day", day: "Monday, September 4th", date: "9/4/2023" }] },
			{
				month: "November",
				days: [
					{
						name: "Thanksgiving",
						day: "Thursday, November 23rd and Friday, November 24th",
						date: "11/23/2023",
					},
				],
			},
			{
				month: "December",
				days: [
					{
						name: "Christmas",
						day: "Friday, December 22nd and Monday December 25th",
						date: "12/22/2023",
					},
					{ name: "New Year's Eve", day: "Friday, December 29th", date: "12/29/2023" },
				],
			},
		],
	},
	{
		year: "2024",
		holidays: [
			{ month: "January", days: [{ name: "New Year's Day", day: "Monday, January 1st", date: "1/1/2024" }] },
			{
				month: "February",
				days: [{ name: "President's Day", day: "Monday, February 19th", date: "2/19/2024" }],
			},
			{ month: "May", days: [{ name: "Memorial Day", day: "Monday, May 27th", date: "5/27/2024" }] },
			{ month: "July", days: [{ name: "Independence Day", day: "Thursday, July 4th", date: "7/4/2024" }] },
			{ month: "September", days: [{ name: "Labor Day", day: "Monday, September 2nd", date: "9/2/2024" }] },
			{
				month: "November",
				days: [
					{ name: "Veterans Day", day: "Monday, November 11th", date: "11/11/2024" },
					{
						name: "Thanksgiving",
						day: "Thursday, November 28th and Friday, November 29th",
						date: "11/28/2024",
					},
				],
			},
			{
				month: "December",
				days: [
					{
						name: "Christmas",
						day: "Tuesday, December 24th and Wednesday December 25th",
						date: "12/24/2024",
					},
					{ name: "New Year's Eve", day: "Tuesday, December 31st", date: "12/31/2024" },
				],
			},
		],
	},
	{
		year: "2025",
		holidays: [
			{ month: "January", days: [{ name: "New Year's Day", day: "Wednesday, January 1st", date: "1/1/2025" }] },
			{
				month: "February",
				days: [{ name: "President's Day", day: "Monday, February 17th", date: "2/17/2024" }],
			},
			{ month: "May", days: [{ name: "Memorial Day", day: "Monday, May 26th", date: "5/26/2024" }] },
			{ month: "July", days: [{ name: "Independence Day", day: "Friday, July 4th", date: "7/4/2025" }] },
			{ month: "September", days: [{ name: "Labor Day", day: "Monday, September 2nd", date: "9/2/2025" }] },
			{
				month: "November",
				days: [
					{ name: "Veterans Day", day: "Monday, November 11th", date: "11/11/2025" },
					{
						name: "Thanksgiving",
						day: "Thursday, November 27th and Friday, November 28th",
						date: "11/28/2024",
					},
				],
			},
			{
				month: "December",
				days: [
					{
						name: "Christmas",
						day: "Tuesday, December 24th and Wednesday December 25th",
						date: "12/24/2025",
					},
					{ name: "New Year's Eve", day: "Tuesday, December 31st", date: "12/31/2025" },
				],
			},
		],
	},
];

export default HOLIDAYS;
