export default class Parameter {
	constructor(id = null, parameter = {}) {
		let initParameter = this.initParameter(id, parameter);
		this.id = initParameter.id;
		this.label = initParameter.label;
		this.icon = initParameter.icon;
		this.valueType = initParameter.valueType;
		this.inputType = initParameter.inputType;
		this.minW = initParameter.minW;

		this.value = initParameter.value;
		this.defaultValue = initParameter.defaultValue;
		this.options = initParameter.options;
		this.optionValue = initParameter.optionValue;
		this.optionLabel = initParameter.optionLabel;
		this.optionDesc = initParameter.optionDesc;

		this.allowBlankYN = initParameter.allowBlankYN;
		this.allowMultipleYN = initParameter.allowMultipleYN;
		this.isVisibleYN = initParameter.isVisibleYN;
	}

	initParameter(id = null, parameter = {}) {
		let label = parameter?.label ?? null;
		let icon = parameter?.icon ?? null;
		let valueType = parameter?.valueType ?? null;
		let inputType = parameter?.inputType ?? "select";
		let minW = parameter?.minW ?? "3xs";

		let allowBlankYN = parameter?.allowBlankYN ?? "Y";
		let allowMultipleYN = parameter?.allowMultipleYN ?? "N";
		let isVisibleYN = parameter?.isVisibleYN ?? "Y";

		let value = parameter?.value ?? null;
		let defaultValue = parameter?.defaultValue ?? null;
		let options = parameter?.options ?? [];
		let optionValue = parameter?.optionValue ?? id;
		let optionLabel = parameter?.optionLabel ?? id;
		let optionDesc = parameter?.optionDesc ?? null;

		if (Boolean(id) && !Boolean(label)) {
			label = id?.replace(/([a-z])([A-Z])/g, "$1 $2") ?? "";
			if (
				[
					"jcco",
					"jccm",
					"jcjm",
					"jcci",
					"jcpm",
					"jcmp",
					"jcct",
					"jcpc",
					"ddds",
					"ddsu",
					"ddsg",
					"prco",
					"prth",
					"preh",
					"arcm",
					"apvm",
					"pmoi",
					"pmop",
					"pmoh",
				].includes(label?.substring(0, 4)?.toLowerCase())
			) {
				label = label?.slice(0, 4).toUpperCase() + label?.slice(4);
			} else if (["vp", "px", "pm", "um", "gc", "ud", "bg"].includes(label?.substring(0, 2)?.toLowerCase())) {
				label = label?.slice(0, 2)?.toUpperCase() + label?.slice(2);
			} else {
				label = label?.charAt(0)?.toUpperCase() + label?.slice(1);
			}
			label = label?.replace("Key ID", "KeyID");
			label = label?.replace("WIPJob", "WIP Job");

			if (["jcco", "prco", "apco", "hqco", "co", "companyID"].includes(id)) {
				id = "jcco";
				label = "Companies";
			} else if (id === "vendorGroup") {
				label = "Vendor Group";
			} else if (id === "custGroup") {
				label = "Customer Group";
			} else if (id === "jccmKeyID") {
				label = "Contracts";
			} else if (id === "jcjmKeyID") {
				label = "Jobs";
			} else if (id === "jcciKeyID") {
				label = "Contract Item";
			} else if (id === "pmohKeyID") {
				label = "ACOs";
			} else if (id === "pmopKeyID") {
				label = "PCOs";
			} else if (id === "regionID") {
				label = "Regions";
			} else if (id === "accessYN") {
				label = "Job Access";
			}
		}

		// BOOLEANS
		if (!Boolean(valueType)) {
			valueType = "string";

			// BOOLEAN (Y/N)
			if (label.includes("YN")) {
				valueType = "boolean";
			}

			// INTEGERS
			else if (
				[
					"jcco",
					"prco",
					"co",
					"qualifier",
					"instance",
					"custgroup",
					"vendorgroup",
					"seq",
					"ext",
					"contractstatus",
					"jobstatus",
					"pcostatus",
					"pmoistatus",
					"customer",
					"costtype",
					"securitygroup",
					"year",
				].includes(id.toLowerCase())
			) {
				valueType = "integer";
			} else if (!id?.slice(-3)?.includes("UID") && id?.slice(-2)?.includes("ID")) {
				valueType = "integer";
			} else if (id?.slice(-3)?.includes("Seq")) {
				valueType = "integer";
			}

			// NUMBERS
			else if (
				["hours", "hrs", "crewsize", "qty", "quantity", "manpower", "men"].some((term) =>
					id?.toLowerCase()?.includes(term)
				)
			) {
				valueType = "number";
			}

			// CURRENCY
			else if (["amt", "amount", "overunder", "cost"].some((term) => id?.toLowerCase()?.includes(term))) {
				valueType = "currency";
			}

			// PERCENTS
			else if (["pct", "percent"].some((term) => id?.toLowerCase()?.includes(term))) {
				valueType = "percent";
			}

			// MONTH
			else if (["mth", "month"].some((term) => id?.toLowerCase()?.includes(term))) {
				valueType = "month";
				inputType = "month";
			}

			// DATE
			else if (id.includes("date") && !id.includes("time")) {
				valueType = "date";
				inputType = "date";
			}

			// DATE TIME
			else if (["date", "time"].every((term) => id?.toLowerCase()?.includes(term))) {
				valueType = "datetime";
				inputType = "date";
			}
		}

		// // Push columns where the values for those keys that are not objects (required by React-Table)
		// if (typeof obj[keys[i]] !== "object" && typeof obj[keys[i]] !== "boolean") {
		// 	columns.push(column);
		// }

		if (!Boolean(value) && Boolean(defaultValue)) {
			value = defaultValue;
		}

		return {
			id,
			label,
			icon,
			valueType,
			inputType,
			value,
			minW,
			defaultValue,
			options,
			optionValue,
			optionLabel,
			optionDesc,
			allowBlankYN,
			allowMultipleYN,
			isVisibleYN,
		};
	}
}
