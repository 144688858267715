import DFTemplate from "../classes/DFTemplate";
import DFWorkflow from "../classes/DFWorkflow";
import DFJob from "../classes/DFJob";
import DFTeam from "../classes/DFTeam";
import DFSequence from "../classes/DFSequence";
import DFDeliverable from "../classes/DFDeliverable";

const DF_CLASSES = [
	{ class: "DFTemplate", prop: "templates", obj: new DFTemplate() },
	{ class: "DFWorkflow", prop: "workflows", obj: new DFWorkflow() },
	{ class: "DFJob", prop: "jobs", obj: new DFJob() },
	{ class: "DFTeam", prop: "team", obj: new DFTeam() },
	{ class: "DFSequence", prop: "sequences", obj: new DFSequence() },
	{ class: "DFDeliverable", prop: "deliverables", obj: new DFDeliverable() },
];
export default DF_CLASSES;
