import React from "react";
import ToggleButton from "./ToggleButton";
import SettingsMenu from "./SettingsMenu";

export default function SettingsButton({ settingsIsOpen, setSettingsIsOpen, allColumns }) {
	return (
		<>
			{Boolean(setSettingsIsOpen) && (
				<SettingsMenu
					isOpen={settingsIsOpen}
					allColumns={allColumns}
					onClose={setSettingsIsOpen.off()}
					onClick={setSettingsIsOpen.toggle()}
				>
					<ToggleButton
						toggleIsOpen={settingsIsOpen}
						onClick={setSettingsIsOpen.toggle}
						// toggleOptions={[
						// 	{
						// 		isOpen: true,
						// 		label: "Show/Hide Columns",
						// 		icon: "fas fa-cog",
						// 		color: "gray.500",
						// 		bg: "whiteAlpha.900",
						// 	},
						// 	{
						// 		isOpen: false,
						// 		label: "Show/Hide Columns",
						// 		icon: "fas fa-cog",
						// 		color: "gray.500",
						// 		bg: "whiteAlpha.700",
						// 	},
						// ]}
						toggleOptions={[
							{
								isOpen: true,
								label: "Show/Hide Columns",
								icon: "fas fa-cog",
								color: "gray.500",
								bg: "whiteAlpha.700",
							},
							{
								isOpen: false,
								label: "Show/Hide Columns",
								icon: "fas fa-cog",
								color: "gray.500",
								bg: "whiteAlpha.700",
							},
						]}
					></ToggleButton>
				</SettingsMenu>
			)}
		</>
	);
}
