import React, { useMemo } from "react";
import { Heading, Stack, HStack, Container } from "@chakra-ui/react";
import BasicCard from "../../Layout/cards/BasicCard";
import { portalUser } from "../../../App";

export default function FormComponentNewPageHeader(props) {
	let form = useMemo(() => props.form ?? null, [props.form]);
	let isTesting = useMemo(() => props.isTesting ?? false, [props.isTesting]);
	// let assignedFor = useMemo(() => props.usersMap[form?.assignedFor] ?? null, [form?.assignedFor, props.usersMap]);
	let portalStyles = portalUser.styles;
	portalStyles.cardBG = "gray.100";

	const styles = useMemo(() => {
		let titleFontColor = props.isPrinting ? "var(--chakra-colors-gray-800)" : "gray.600";
		let titleFontSize = props.isPrinting
			? "32px"
			: {
					base: "var(--chakra-fontSizes-2xl)",
					md: "var(--chakra-fontSizes-3xl)",
					lg: "var(--chakra-fontSizes-4xl)",
					xl: "var(--chakra-fontSizes-4xl)",
			  };
		let titleLetterSpacing = 4;
		let subtitleFontColor = props.isPrinting ? "var(--chakra-colors-gray-700)" : "gray.500";
		let subtitleFontSize = props.isPrinting
			? "24px"
			: {
					base: "var(--chakra-fontSizes-lg)",
					md: "var(--chakra-fontSizes-xl)",
					lg: "var(--chakra-fontSizes-xl)",
					xl: "var(--chakra-fontSizes-xl)",
			  };
		let subtitleLetterSpacing = 3;

		let paramsFontColor = props.isPrinting ? "var(--chakra-colors-gray-600)" : "gray.400";
		let paramsFontSize = props.isPrinting
			? "24px"
			: {
					base: "var(--chakra-fontSizes-lg)",
					md: "var(--chakra-fontSizes-xl)",
					lg: "var(--chakra-fontSizes-2xl)",
					xl: "var(--chakra-fontSizes-2xl)",
			  };

		let paramsLetterSpacing = 2;

		return {
			titleFontColor,
			titleFontSize,
			titleLetterSpacing,
			subtitleFontColor,
			subtitleFontSize,
			subtitleLetterSpacing,
			paramsFontColor,
			paramsFontSize,
			paramsLetterSpacing,
		};
	}, [props.isPrinting]);

	return (
		<Stack spacing={2} w="full" flex={1} align="center" justify="center" textTransform="uppercase">
			<Heading
				color={styles.titleFontColor}
				fontSize={styles.titleFontSize}
				letterSpacing={styles.titleLetterSpacing}
			>
				{form?.formTitle}
			</Heading>
			<Heading
				color={styles.subtitleFontColor}
				fontSize={styles.subtitleFontSize}
				letterSpacing={styles.subtitleLetterSpacing}
			>
				{form?.formSubtitle}
			</Heading>
			{isTesting && (
				<Container maxW="container.xl" py={6}>
					<BasicCard title="Form Parameters" portalStyles={portalStyles}>
						<Stack spacing={4}>
							<HStack spacing={12} w="full" flex={1} justify="center">
								<Stack spacing={1} align="center">
									<Heading color={"gray.400"} fontSize={"sm"} letterSpacing={1}>
										Template UID
									</Heading>
									<Heading
										color={"gray.500"}
										fontSize={"sm"}
										letterSpacing={1}
										textTransform={"lowercase"}
									>
										{form?.templateUID}
									</Heading>
								</Stack>
								<Stack spacing={1} align="center">
									<Heading color={"gray.400"} fontSize={"sm"} letterSpacing={1}>
										Parent Form UID
									</Heading>
									<Heading
										color={"gray.500"}
										fontSize={"sm"}
										letterSpacing={1}
										textTransform={"lowercase"}
									>
										{form?.parentFormUID}
									</Heading>
								</Stack>
								<Stack spacing={1} align="center">
									<Heading color={"gray.400"} fontSize={"sm"} letterSpacing={1}>
										Form UID
									</Heading>
									<Heading
										color={"gray.500"}
										fontSize={"sm"}
										letterSpacing={1}
										textTransform={"lowercase"}
									>
										{form?.formUID}
									</Heading>
								</Stack>
							</HStack>
							<HStack spacing={10} w="full" flex={1} justify="center">
								<Stack spacing={1} align="center">
									<Heading color={"gray.400"} fontSize={"sm"} letterSpacing={1}>
										Assigned For
									</Heading>
									<Heading color={"gray.500"} fontSize={"sm"} letterSpacing={1}>
										{form?.assignedForName}
									</Heading>
								</Stack>
								<Stack spacing={1} align="center">
									<Heading color={"gray.400"} fontSize={"sm"} letterSpacing={1}>
										Assigned To
									</Heading>
									<Heading color={"gray.500"} fontSize={"sm"} letterSpacing={1}>
										{form?.assignedToName}
									</Heading>
								</Stack>

								<Stack spacing={1} align="center">
									<Heading color={"gray.400"} fontSize={"sm"} letterSpacing={1}>
										Sit Down Reviewer
									</Heading>
									<Heading color={"gray.500"} fontSize={"sm"} letterSpacing={1}>
										{form?.reviewedByName ?? "N/A"}
									</Heading>
								</Stack>
								<Stack spacing={1} align="center">
									<Heading color={"gray.400"} fontSize={"sm"} letterSpacing={1}>
										Sign Off By
									</Heading>
									<Heading color={"gray.500"} fontSize={"sm"} letterSpacing={1}>
										{form?.signedOffByName ?? "N/A"}
									</Heading>
								</Stack>
								<Stack spacing={1} align="center">
									<Heading color={"gray.400"} fontSize={"sm"} letterSpacing={1}>
										Approved By
									</Heading>
									<Heading color={"gray.500"} fontSize={"sm"} letterSpacing={1}>
										{form?.approvedByName ?? "N/A"}
									</Heading>
								</Stack>
							</HStack>
						</Stack>
					</BasicCard>
				</Container>
			)}
		</Stack>
	);
}
