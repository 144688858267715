import React, { useMemo } from "react";
import { Switch, Route, useLocation, useParams, Redirect } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import EmployeeTimeOffPage from "../pages/EmployeeTimeOffPage";
import EmployeeProfilePage from "../pages/EmployeeProfilePage";
import FormDataReviewsPage from "../../HRAdmin/pages/HRReviewsPage";

export default function EmployeeRoutes({ tab }) {
	let location = useLocation();

	return (
		<Switch location={location}>
			<Route exact path={`/employee`} children={<EmployeeRouteViews tab={tab} />} />
			<Route exact path={`/employee/:tab`} children={<EmployeeRouteViews tab={tab} />} />
			<Route path={`/employee/:tab/:routeUID`} children={<EmployeeRouteViews tab={tab} />} />
		</Switch>
	);
}

function EmployeeRouteViews(props) {
	let params = useParams();

	const prepareTab = (tab = null) => {
		if (!["timeoff", "contact", "reviews"]?.includes(tab)) {
			return null;
		} else {
			return tab;
		}
	};

	const prepareUID = (uid = null) => {
		if (!Boolean(uid?.length === 36)) {
			return null;
		} else {
			return uid?.toLowerCase();
		}
	};

	var routeTab = prepareTab(params?.tab);

	const routeUID = useMemo(() => {
		return prepareUID(params?.routeUID);
	}, [params?.routeUID]);

	const tab = useMemo(() => {
		return prepareTab(props.tab);
	}, [props.tab]);

	return (
		<Flex flex={1} w="full" direction="row">
			<Flex direction={"column"} w="full" flex={1}>
				{Boolean(tab) && tab !== routeTab ? (
					<Redirect push to={`/employee/${tab}`} />
				) : ["timeoff"].includes(routeTab) && !Boolean(routeUID) ? (
					// <Redirect push to={`/employee/${routeTab}/${routeUID}`} />
					<EmployeeTimeOffPage />
				) : ["timeoff"].includes(routeTab) && Boolean(routeUID) ? (
					<EmployeeTimeOffPage routeUID={routeUID} />
				) : ["contact"].includes(routeTab) ? (
					<EmployeeProfilePage />
				) : ["reviews"].includes(routeTab) ? (
					<FormDataReviewsPage tab={routeTab} routeUID={routeUID} />
				) : (
					<Box>Something went wrong - there is an issue with the Tab or routeUID</Box>
				)}
			</Flex>
		</Flex>
	);
}
