import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { formatValue, formatNumber } from "../../../helperFunctions";

export default function VirtualTableCell({ cell, maxW = "full", borderRightWidth = 1 }) {
	return (
		<Flex
			px={0}
			py={1}
			align="center"
			w={cell.column.width ?? "100%"}
			maxW={maxW}
			minW={(formatNumber(cell.column.width, 0) / 100) * 75}
			borderRightColor={"gray.400"}
			borderRightWidth={borderRightWidth}
			bg={cell.column.bg ?? "transparent"}
			role="group"
			isTruncated
		>
			<Text
				as={!Boolean(cell.value) && Boolean(cell.placeholder) ? "i" : "p"}
				w="full"
				fontSize={cell?.column?.textSize ?? "md"}
				textTransform={cell?.column?.textTransform ?? "uppercase"}
				fontFamily="body"
				position="relative"
				px={2}
				isTruncated
				color={!Boolean(cell.value) && Boolean(cell.placeholder) ? "gray.400" : cell.column.color ?? "gray.600"}
				textAlign={cell.column.textAlign}
			>
				{(!Boolean(cell.value) || cell.value === undefined || cell.value.length === 0) &&
				Boolean(cell.placeholder)
					? cell.placeHolder
					: formatValue(cell.value, cell.column.decimals, cell.column.valueType)}

				{Boolean(cell?.row?.original[cell?.column?.id + "RightAddon"]) &&
					cell?.row?.original[cell?.column?.id + "RightAddon"]}
			</Text>
		</Flex>
	);
}
