const SUP_TEAM_MEMBERS = [
	"10th Period Apprentice",
	"1st Period Apprentice",
	"2nd Period Apprentice",
	"3rd Year Apprentice (70%)",
	"4th Period Apprentice",
	"4th Period Apprentice - 55%",
	"5th Period Apprentice",
	"9th Period Apprentice",
	"Appren (6,001 - 8,000 Hours)",
	"Appren (8001 - 10000 Hours)",
	"Assistant Project Manager",
	"BIM / QAQC Director",
	"BIM Admin",
	"BIM Department Manager",
	"BIM Director",
	"BIM Lead",
	"BIM Lead Detailer",
	"BIM Manager",
	"BIM Project Manager",
	"Certified General Foreman",
	"Detailer",
	"Director of Design and Brand Management",
	"Director of Mechanical",
	"Director of Preconstruction",
	"Director of Risk Management",
	"Director, HVAC Operations",
	"Division Leader",
	"Fabrication Foreman",
	"Fire Sprinker Design Manager",
	"Fire Sprinkler Superintendent",
	"Foreman",
	"Foreman 0-10 Years",
	"Foreman 2-7 Men",
	"Foreman A",
	"Foreman/Detailer",
	"Fuel Oil Division Director",
	"Fuel Oil Manager",
	"General Foreman",
	"General Foreman 11+ Years",
	"General Foreman II HRA=A",
	"General Foreman II HRA=G",
	"HVAC BIM Lead",
	"HVAC Detailer",
	"HVAC Field Detailer",
	"HVAC Service Manager",
	"HVAC Support Detailer",
	"Journey",
	"Journeyman",
	"Journeyman 0-10 Years",
	"Journeyman 11+ Years",
	"Journeyman II HRA=A",
	"Journeyman Leadman",
	"Laborer Group 4",
	"Mechanical Engineer",
	"Mechanical Pipe Detailer",
	"Mechanical Piping BIM Lead",
	"Mechanical Piping Detailer",
	"Pipe Superintendent",
	"Plumb Superintendent",
	"Plumbing Engineer",
	"Precon QA/QC",
	"Preconstruction",
	"Preconstruction Manager",
	"Prefabrication Manager",
	"Project Engineer",
	"Project Executive",
	"Project Manager",
	"QA/QC",
	"QA/QC Manager",
	"QAQC",
	"QAQC Inspector",
	"Senior BIM Lead",
	"Senior Estimator",
	"Senior General Foreman",
	"Senior Plumbing Designer",
	"Senior Preconstruction Manager",
	"Senior Project Manager",
	"Senior Superintendent",
	"Service Manager",
	"Service Supervisor",
	"Sheetmetal Shop Supervisor",
	"Sheetmetal Superintendent",
	"Shipping and Receiving",
	"Shop",
	"Spool Manager",
	"Spooler",
	"Superintendent",
	"Superintendent Administrator",
	"Support Detailer",
	"Utility Detailer",
	"Utility Support",
	"Utility Support / Fuel Oil",
	"Utility Support / Spooler",
	"Utility Support/Spooler",
	"Working Foreman",
];
export default SUP_TEAM_MEMBERS;
