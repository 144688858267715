import React, { useMemo, useCallback } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { formatValue, log } from "../../../helperFunctions";

export default function VirtualTableFooter({ rows, footerGroups, maxRows, maxWidth, footer, scrollBarWidth }) {
	const footerArray = useMemo(() => {
		let footerArray = [];
		let filteredRows = [];
		let nonGroupedRows = rows?.filter((row) => !Boolean(row.isGrouped)) ?? [];
		if (nonGroupedRows?.length > 0) {
			filteredRows = nonGroupedRows?.map((row) => row.original) ?? [];
		}
		if (typeof footer === "function" && filteredRows?.length > 0) {
			footerArray = footer(filteredRows);
		} else {
			footerArray = footer ?? [];
		}
		return footerArray;
	}, [footer, rows]);

	// const getFooterValue = useCallback((column, rows, index) => {
	// 	let accessor = column.id;
	// 	if (typeof accessor === "function") {
	// 		accessor = accessor.name;
	// 	}

	// 	let valueType = footerRow[accessor + "Type"] ?? column?.valueType ?? "string";
	// 	let decimals = footerRow[accessor + "Decimals"] ?? column?.decimals ?? 0;
	// 	let textAlign = footerRow[accessor + "TextAlign"] ?? column?.textAlign ?? "right";
	// 	let fontSize = footerRow[accessor + "Size"] ?? "md";
	// 	let value = footerRow[accessor];
	// 	let textTransform = footerRow[accessor + "TextTransform"] ?? "uppercase";
	// 	let color = footerRow[accessor + "TextColor"] ?? column?.textColor ?? "gray.600";

	// 	if (typeof column.Footer === "function" && filteredRows?.length > 0) {
	// 		let footerArray = column?.Footer(filteredRows);

	// 		// value = footerArray[j][accessor] ?? footerRow[accessor];
	// 	}

	// 	return (
	// 		<Box
	// 			py={1}
	// 			px={2}
	// 			w={column.width ?? "full"}
	// 			fontSize={fontSize}
	// 			maxW={column.id === "expander" ? "40px" : column?.width ?? "full"}
	// 			isTruncated
	// 			textTransform={textTransform}
	// 			align="center"
	// 			// color={typeof column.Footer === "function" ? "blue.600" : "gray.600"}
	// 			color={color}
	// 			{...column.getFooterProps()}
	// 		>
	// 			<Text w={"full"} textAlign={textAlign} flex={1} px={0}>
	// 				{formatValue(value, decimals, valueType)}

	// 				{Boolean(footerRow[accessor + "RightAddon"]) && " " + footerRow[accessor + "RightAddon"]}
	// 			</Text>
	// 		</Box>
	// 	);
	// }, []);

	return (
		<>
			{footerGroups.map((group, i) => (
				<Flex
					key={i}
					w="full"
					borderBottomRadius={5}
					color="gray.600"
					fontWeight="bold"
					isTruncated
					// h="full"
					maxW={maxWidth}
					borderWidth={1}
					borderColor="gray.500"
					align="center"
					pr={rows?.length > maxRows ? scrollBarWidth + "px" : 0}
					py={0}
					direction={"column"}
					bg="gray.100"
					{...group.getFooterGroupProps()}
				>
					{Array.isArray(footerArray) &&
						footerArray?.map((footerRow, j) => {
							return (
								<Flex key={j} direction={"row"} maxW={maxWidth} w="full" py={0}>
									{group.headers.map((column, k) => {
										// if (typeof column.Footer === "function" && filteredRows?.length > 0) {
										// 	let footerArray = column?.Footer(filteredRows);
										// 	log("FOOTER Array J", footerArray[j]);
										// 	footerRow = footerArray[j] ?? null;
										// }

										// if (Boolean(footerRow)) {
										let accessor = column.id;
										if (typeof accessor === "function") {
											accessor = accessor.name;
										}
										let value = footerRow[accessor];

										let valueType = footerRow[accessor + "Type"] ?? column?.valueType ?? "string";
										let decimals = footerRow[accessor + "Decimals"] ?? column?.decimals ?? 0;
										let textAlign =
											footerRow[accessor + "TextAlign"] ?? column?.textAlign ?? "right";
										let fontSize = footerRow[accessor + "TextSize"] ?? column?.textSize ?? "md";

										let textTransform = footerRow[accessor + "TextTransform"] ?? "uppercase";
										let color =
											footerRow[accessor + "TextColor"] ?? column?.textColor ?? "gray.600";

										return (
											<Box
												key={k}
												py={1}
												px={2}
												w={column.width ?? "full"}
												fontSize={fontSize}
												maxW={column.id === "expander" ? "40px" : column?.width ?? "full"}
												isTruncated
												textTransform={textTransform}
												align="center"
												// color={typeof column.Footer === "function" ? "blue.600" : "gray.600"}
												color={color}
												{...column.getFooterProps()}
											>
												<Text w={"full"} textAlign={textAlign} flex={1} px={0}>
													{formatValue(value, decimals, valueType)}

													{Boolean(footerRow[accessor + "RightAddon"]) &&
														" " + footerRow[accessor + "RightAddon"]}
												</Text>
											</Box>
										);
									})}
								</Flex>
							);
						})}
				</Flex>
			))}
		</>
	);
}
