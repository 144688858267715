import React, { useMemo, useState } from "react";
import moment from "moment";
import { useDisclosure, Box, Button, Collapse, Stack } from "@chakra-ui/react";
import AreaGraph from "../../../core/Graphs/components/AreaGraph";
import { formatNumber } from "../../../helperFunctions";

export default function MarginTrendGraph(props) {
	const { isOpen, onToggle } = useDisclosure();

	const [marginTrend, setMarginTrend] = useState([]);

	useMemo(() => {
		let graphData = [];
		for (let i = props.contractTotalsByMth?.length - 1; i >= 0; i--) {
			let bidMarginPct = props.contractTotalsByMth[i]?.getBidMarginPct() ?? null;
			let projMarginPct = props.contractTotalsByMth[i]?.getProjMarginPct() ?? null;
			if (
				!isNaN(parseFloat(projMarginPct)) &&
				!isNaN(parseFloat(bidMarginPct)) &&
				parseFloat(projMarginPct) < 99
			) {
				let dataPoint = {
					name: moment(new Date(props.contractTotalsByMth[i].mth)).format("MMM YYYY"),
					"Bid Margin": formatNumber(bidMarginPct, 1),
					"Projected Margin": formatNumber(projMarginPct, 1),
				};
				graphData.push(dataPoint);
			}
		}

		setMarginTrend(graphData);
	}, [props.contractTotalsByMth]);

	return (
		<Stack w="full" px={4} flex={1}>
			<Collapse in={isOpen}>
				{marginTrend?.length > 0 && (
					<Box minH="xs" pt={4}>
						<AreaGraph
							title="Margin Trend By Month"
							graphData={marginTrend}
							valueType="percent"
							varY="Projected Margin"
						/>
					</Box>
				)}
			</Collapse>

			<Button
				size="sm"
				color="gray.500"
				disabled={Boolean(marginTrend?.length === 0)}
				variant="unstyled"
				_hover={{
					textDecoration: "underline",
					color: "blue.600",
					cursor: Boolean(marginTrend?.length === 0) ? "not-allowed" : "pointer",
				}}
				onClick={onToggle}
			>
				{isOpen ? "Hide Margin Trend" : "Show Margin Trend"}
			</Button>
		</Stack>
	);
}
