import React, { useMemo } from "react";
import { VStack, Stack, Table, Tbody } from "@chakra-ui/react";
import MFRProjectAnalysisSummaryCRSSection from "./MFRProjectAnalysisSummaryCRSSection";

export default function ProjectAnalysisCRS(props) {
	const contract = useMemo(() => props.contract, [props.contract]);
	const contractStatus = useMemo(() => props.contractStatus, [props.contractStatus]);

	return (
		<Stack w="full">
			<VStack spacing={4} px={4}>
				<Table size="small" bg="white" color={"gray.600"} w="full">
					<Tbody>
						<MFRProjectAnalysisSummaryCRSSection contract={contract} contractStatus={contractStatus} />
					</Tbody>
				</Table>
			</VStack>
		</Stack>
	);
}
