import React, { useState, useLayoutEffect, useRef } from "react";
import { Stack, Text } from "@chakra-ui/react";

export default function TableSizesCell({ column }) {
	const ref = useRef(null);
	const [textWidth, setTextWidth] = useState(0);
	useLayoutEffect(() => {
		function handleResize() {
			if (ref?.current) {
				let width = ref?.current?.offsetWidth ?? 0;
				setTextWidth(width);
			}
		}
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [ref]);

	return (
		<Stack
			ref={ref}
			textAlign={"center"}
			spacing={0}
			flex={1}
			w="full"
			px={2}
			justify="center"
			align="center"
			textTransform="lowercase"
		>
			<Text w="full">{column?.width ?? "100%"}</Text>
			<Text w="full">{textWidth + "px"}</Text>
		</Stack>
	);
}
