import UsersAPI from "../APIs/UsersAPI";
import { log, formatValue } from "../helperFunctions";
import MSGraphAPI from "../APIs/MSGraphAPI";

export class PPMUser {
	constructor(ppmUser, userCompanies) {
		if (ppmUser instanceof PPMUser) {
			Object.assign(this, ppmUser);
		} else {
			this.usersAPI = ppmUser?.usersAPI ?? null;
			this.keyID = ppmUser?.KeyID ?? "";
			this.msUserID = (ppmUser?.msUserID ?? ppmUser?.MSUserID ?? ppmUser?.MicrosoftID)?.toLowerCase() ?? null;
			this.employeeUID = (ppmUser?.employeeUID ?? ppmUser?.EmployeeUID)?.toLowerCase() ?? null;

			this.activeYN = ppmUser?.activeYN ?? ppmUser?.ActiveYN ?? "N";
			this.birthDay =
				formatValue(ppmUser?.BirthDate ?? ppmUser?.Birthday ?? ppmUser?.birthDay, 0, "date") ?? null;
			this.class = ppmUser?.Class ?? "";
			this.classDescription = ppmUser?.ClassDescription ?? "";

			this.commenters = ppmUser?.Commenters ?? "";
			this.craft = ppmUser?.Craft ?? "";
			this.craftClass = ppmUser?.Craft + "/" + ppmUser?.Class ?? "";
			this.dept = ppmUser?.Dept ?? "";

			this.deptID = ppmUser?.DeptID ?? 0;
			this.deptTag = ppmUser?.DeptTag ?? "";

			this.email = ppmUser?.Email ?? "";

			this.employeeName = ppmUser?.LastName + ", " + ppmUser?.FirstName ?? "";
			this.name = ppmUser?.Name ?? ppmUser?.FirstName + " " + ppmUser?.LastName ?? "";
			this.firstName = ppmUser?.FirstName ?? "";
			this.lastName = ppmUser?.LastName ?? "";
			this.legalName = ppmUser?.LegalName ?? "";
			this.employeeRole = ppmUser?.EmployeeRole ?? "";
			this.employeeRoleDescription = ppmUser?.EmployeeRoleDescription ?? "";
			this.employeeType = ppmUser?.EmployeeType ?? "";
			this.formUID = (ppmUser?.formUID ?? ppmUser?.FormUID)?.toLowerCase() ?? "";
			this.group = ppmUser?.Group ?? "";
			this.groupId = ppmUser?.GroupID ?? 0;
			this.hireDate = formatValue(ppmUser?.hireDate ?? ppmUser?.HireDate, 0, "date") ?? null;
			this.homePhone = ppmUser?.HomePhone ?? "";

			this.lastUpdate = ppmUser?.LastUpdate ?? "";
			this.longevity = ppmUser?.Longevity ?? ppmUser?.LongevityYears ?? 0; // can be gathered from calculation of mths/12
			this.longevityMths = ppmUser?.LongevityMths ?? 0;
			this.mailAddress = ppmUser?.MailAddress ?? "";
			this.mailAddress2 = ppmUser?.MailAddress2 ?? "";
			this.mailCity = ppmUser?.MailCity ?? "";
			this.mailState = ppmUser?.MailState ?? "";
			this.mailZip = ppmUser?.MailZip ?? "";

			this.managerName = ppmUser?.ManagerName ?? "";
			this.midName = ppmUser?.MidName ?? "";
			this.officeAddress = ppmUser?.OfficeAddress ?? "";
			this.officeCity = ppmUser?.OfficeCity ?? "";
			this.officeExt = ppmUser?.OfficeExt ?? "";
			this.officeFax = ppmUser?.OfficeFax ?? "";
			this.officeName = ppmUser?.OfficeName ?? "";
			this.officePhone = ppmUser?.OfficePhone ?? "";
			this.officeState = ppmUser?.OfficeState ?? "";
			this.officeZip = ppmUser?.OfficeZip ?? "";

			this.prDepartment = ppmUser?.prDepartment ?? ppmUser?.PRDepartment ?? "";
			this.prDeptID = ppmUser?.prDeptID ?? ppmUser?.PRDeptID ?? null;
			this.prEndDate = formatValue(ppmUser?.PREndDate, 0, "date") ?? null;
			this.prEmployeeCount = ppmUser?.PREmplyeeCount ?? 0;
			this.prGroup = ppmUser?.prGroup ?? ppmUser?.PRGroup ?? null;
			this.prTrade = ppmUser?.prTrade ?? ppmUser?.PRTrade ?? "";
			this.prTradeID = ppmUser?.prTradeID ?? ppmUser?.PRTradeID ?? null;
			this.parentDept = ppmUser?.parentDept ?? ppmUser?.ParentDept ?? "";
			this.parentDeptID = ppmUser?.parentDeptID ?? ppmUser?.ParentDeptID ?? null;
			this.parentDeptTag = ppmUser?.parentDeptTag ?? ppmUser?.ParentDeptTag ?? "";
			this.pastReviewAccess = ppmUser?.PastReview_Access ?? null;
			this.paystubEmail = ppmUser?.PaystubEmail ?? null;
			this.personalCell = ppmUser?.PersonalCell ?? null;
			this.phoneCarrier = ppmUser?.PhoneCarrier ?? null;
			this.portalUser = ppmUser?.PortalUser ?? 0;

			this.recentRehireDate = ppmUser?.RecentRehireDate ?? null;
			this.region = ppmUser?.Region ?? "";
			this.regionID = ppmUser?.regionID ?? ppmUser?.RegionID ?? null;
			this.regionTag = ppmUser?.RegionTag ?? "";
			this.respondentUID = (ppmUser?.respondentUID ?? ppmUser?.RespondentUID)?.toLowerCase() ?? "";
			this.reviewerName = ppmUser?.ReviewerName ?? "";
			this.reviewerType = ppmUser?.ReviewerType ?? "";
			this.role = ppmUser?.Role ?? "";
			this.roleID = ppmUser?.roleID ?? ppmUser?.RoleID ?? null;
			this.roleTag = ppmUser?.RoleTag ?? "";
			this.shirtSize = ppmUser?.ShirtSize ?? "";
			this.teamCountDept = ppmUser?.TeamCount_Dept ?? 0;

			this.termDate = ppmUser?.TermDate ?? null;

			this.title = ppmUser?.Title ?? "";
			this.trade = ppmUser?.Trade ?? "";
			this.tradeColor = ppmUser?.TradeColor ?? "";
			this.tradeID = ppmUser?.tradeID ?? ppmUser?.TradeID ?? 0;
			this.vpUserName = ppmUser?.VPUserName ?? "";
			this.wpUserID = ppmUser?.WPUserID ?? null;
			this.workCell = ppmUser?.WorkCell ?? ppmUser?.CellPhone ?? "";
			this.prehKeyID = ppmUser?.prehKeyID ?? ppmUser?.PREHKeyID ?? null;
			this.birthDate =
				formatValue(ppmUser?.BirthDate ?? ppmUser?.Birthday ?? ppmUser?.birthDay, 0, "date") ?? null;
			this.availableSick = ppmUser?.availableSick ?? ppmUser?.AvailableSick ?? 0;
			this.availableVacation = ppmUser?.availableVacation ?? ppmUser?.AvailableVacation ?? 0;
			this.accrualRate = ppmUser?.accrualRate ?? ppmUser?.AccrualRate ?? 0;
			this.maxAvailable = ppmUser?.maxAvailable ?? ppmUser?.MaxAvailable ?? 0;
			this.activeCompanies = userCompanies ?? null;
			this.status = ppmUser?.Status ?? null;
			this.statusColor = ppmUser?.StatusColor ?? null;
			this.reviewStatus = ppmUser?.ReviewStatus ?? null;

			this.managerUID = (ppmUser?.managerUID ?? ppmUser?.ManagerUID)?.toLowerCase() ?? null;
			this.reviewerUID = (ppmUser?.reviewerUID ?? ppmUser?.ReviewerUID)?.toLowerCase() ?? null;
			this.approverUID = (ppmUser?.approverUID ?? ppmUser?.ApproverUID)?.toLowerCase() ?? null;

			this.commentCount = ppmUser?.CommentCount ?? null;

			//Route Access - Employee
			this.showPortalTourYN = ppmUser?.showPortalTourYN ?? (Boolean(ppmUser?.ShowPortalTour) ? "Y" : "N");
			this.shareBirthdayYN = ppmUser?.shareBirthdayYN ?? (Boolean(ppmUser?.ShowBirthday) ? "Y" : "N");
			this.sharePhotosYN = ppmUser?.sharePhotosYN ?? (Boolean(ppmUser?.SharePhotos) ? "Y" : "N");
			//Route Access - HR Admin
			this.isDevOpsUserYN = ppmUser?.isDevOpsUserYN ?? (Boolean(ppmUser?.IsDevOpsUser) ? "Y" : "N");
			this.isPortalAdminYN = ppmUser?.isPortalAdminYN ?? (Boolean(ppmUser?.IsPortalAdmin) ? "Y" : "N");
			this.showVacationCalendarYN =
				ppmUser?.showVacationCalendarYN ?? (Boolean(ppmUser?.ShowVacationCalendar) ? "Y" : "N");
			this.showLongevityAwardsYN =
				ppmUser?.showLongevityAwardsYN ?? (Boolean(ppmUser?.ShowLongevity) ? "Y" : "N");
			this.showDevOpsPresentationYN =
				ppmUser?.showDevOpsPresentationYN ?? (Boolean(ppmUser?.ShowDevOpsPresentation) ? "Y" : "N");
			//Route Access - Team
			this.isTeamManagerYN = ppmUser?.isTeamManagerYN ?? (Boolean(ppmUser?.TeamCount_Manager) ? "Y" : "N");
			this.isTeamReviewerYN = ppmUser?.isTeamReviewerYN ?? (Boolean(ppmUser?.TeamCount_Reviewer) ? "Y" : "N");
			this.isTeamApproverYN = ppmUser?.isTeamApproverYN ?? (Boolean(ppmUser?.TeamCount_Approver) ? "Y" : "N");
			//Route Access - Exec
			this.isFullExecUserYN = ppmUser?.isFullExecUserYN ?? (Boolean(ppmUser?.IsFullExecUser) ? "Y" : "N");
			this.isExecUserYN = ppmUser?.isExecUserYN ?? (Boolean(ppmUser?.IsExecUser) ? "Y" : "N");
			//Route Access - Payroll
			this.isPayrollUserYN = ppmUser?.isPayrollUserYN ?? (Boolean(ppmUser?.IsPayrollUser) ? "Y" : "N");
			this.isAccountingUserYN = ppmUser?.isAccountingUserYN ?? (Boolean(ppmUser?.IsAccountingUser) ? "Y" : "N");
			this.showEmployeeHistoryYN =
				ppmUser?.showEmployeeHistoryYN ?? (Boolean(ppmUser?.ShowEmployeeHistory) ? "Y" : "N");
			this.showTimecardCrewEntryYN =
				ppmUser?.showTimecardCrewEntryYN ?? (Boolean(ppmUser?.Timecard_CrewEntry_Count) ? "Y" : "N");
			this.showTimecardApprovalYN =
				ppmUser?.showTimecardApprovalYN ?? (Boolean(ppmUser?.Timecard_Approval_Count) ? "Y" : "N");
			//Route Access - Operations
			this.isOperationsExecUserYN =
				ppmUser?.isOperationsExecUserYN ?? (Boolean(ppmUser?.IsOperationsExecUser) ? "Y" : "N");
			this.isOperationsUserYN = ppmUser?.isOperationsUserYN ?? (Boolean(ppmUser?.IsOperationsUser) ? "Y" : "N");
			this.showPEReviewYN = ppmUser?.showPEReviewYN ?? (Boolean(ppmUser?.showPEReview) ? "Y" : "N");

			//Route Access - Departments
			this.isEstimatingUserYN = ppmUser?.isEstimatingUserYN ?? (Boolean(ppmUser?.IsEstimatingUser) ? "Y" : "N");
			this.isPurchasingUserYN = ppmUser?.isPurchasingUserYN ?? (Boolean(ppmUser?.IsPurchasingUser) ? "Y" : "N");
			this.isPurchasingManagerYN =
				ppmUser?.isPurchasingManagerYN ?? (Boolean(ppmUser?.isPurchasingManagerYN) ? "Y" : "N");
			this.isDetailingUserYN = ppmUser?.isDetailingUserYN ?? (Boolean(ppmUser?.IsDetailingUser) ? "Y" : "N");
			this.isStartUpUserYN = ppmUser?.isStartUpUserYN ?? (Boolean(ppmUser?.isStartUpUser) ? "Y" : "N");
			//Route Access - DevOps

			this.prco = ppmUser?.prco ?? ppmUser?.PRCo ?? "1";
			this.employee = ppmUser?.employee ?? ppmUser?.Employee ?? null;
			this.prcoEmployee = this.prco + " - " + this.employee;

			this.company = ppmUser?.company ?? ppmUser?.Company ?? null;
			this.companyLogo = ppmUser?.companyLogo ?? ppmUser?.CompanyLogo ?? "";
			this.companyTag = ppmUser?.companyTag ?? ppmUser?.CompanyTag ?? "";

			this.unsyncedChanges = {};
		}
	}

	async initPPMUser(msUser = null) {
		let ppmUser = this;

		if (Boolean(msUser?.id) && !Boolean(ppmUser?.usersAPI)) {
			let usersAPI = new UsersAPI();
			let data = await usersAPI.GetUser(msUser.id);
			if (data.status === 200) {
				data = data.value;
				let users = [];
				for (let i = 0; i < data?.length; i++) {
					let user = new PPMUser(data[i]);
					user.usersAPI = usersAPI;
					if (user.activeYN === "Y") {
						users.push(user);
					}
				}

				if (users.length >= 1) {
					ppmUser = users[0];
					ppmUser.activeCompanies = users;
				} else {
					console.log("PPMUser initPPMUser - Error: No records returned from usersAPI.GetUser(msUser.id)");
				}
			} else if (data.status === 400) {
				await usersAPI.SetMicrosoftID(msUser.id, msUser.mail);
				window.location.reload();
			}
		}
		log("PPMUser initPPMUser - ppmUser", ppmUser);
		return ppmUser;
	}

	getName(firstName = this.firstName, lastName = this.lastName, midName = this.midName, display = "firstLast") {
		let name = "";
		if (display === "firstLast" && Boolean(firstName) && Boolean(lastName)) {
			name = firstName + " " + lastName;
		} else if (display === "lastFirst" && Boolean(firstName) && Boolean(lastName)) {
			name = lastName + " ," + firstName + (Boolean(midName) && " " + midName);
		}
		this.name = name;
		return name;
	}

	async getProfilePhoto(callback) {
		let msClient = new MSGraphAPI();
		let pics = await msClient.GetMSUsersPhotos([this]);
		for (let i in pics) {
			this.profilePhoto = pics[i];
		}
		if (callback) {
			callback(this);
		}
	}

	async updateUserDB(updates) {
		let result = await this.usersAPI.PortalUser_UserData_UpdateUser(this.employeeUID, updates);
		if (result.status === 200) {
			return true;
		} else {
			return false;
		}
	}

	async updatePREmployee(updates) {
		let result = await this.usersAPI.UpdatePREmployee(this.employeeUID, updates);
		if (result.status === 200) {
			return true;
		} else {
			return false;
		}
	}

	pushToUnsynced(table, update) {
		if (!Boolean(this.unsyncedChanges[table])) {
			this.unsyncedChanges[table] = {};
		}
		for (let key in update) {
			this.unsyncedChanges[table][key] = update[key];
		}
	}

	async syncAll() {
		for (let table in this.unsyncedChanges) {
			if (table === "management") {
				await this.usersAPI.UpdateEmployeeManagement(this.employeeUID, this.unsyncedChanges[table]);
			} else if (table === "ppmUser") {
				await this.usersAPI.UpdatePPMUser(this.employeeUID, this.unsyncedChanges[table]);
			}
		}
		this.unsyncedChanges = {};
	}

	async getUser(employeeUID) {
		let usersResult = await this.usersAPI.GetUsers([employeeUID]);
		if (usersResult.status === 200 && usersResult.value?.length) {
			return new PPMUser(usersResult.value[0]);
		} else {
			return false;
		}
	}
}
