import React from "react";
import {
	Box,
	Table,
	Thead,
	Th,
	Tbody,
	Tfoot,
	Tr,
	Td,
	Text,
	Heading,
	Stack,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	Button,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from "@chakra-ui/react";
import { formatValue } from "../../../helperFunctions";

export default function MeritIncreasesHistoryModal(props) {
	let history = props.selectedEmployee?.history;
	let topBottomBorder = {
		boxShadow:
			"inset 0 2px 0 #000000, inset 0 -2px 0 #000000, inset 2px 0 0 #000000, inset -2px 0 0 #000000",
	};
	let bottomBorder = {
		boxShadow:
			"inset 0 -2px 0 #000000, inset 2px 0 0 #000000, inset -2px 0 0 #000000",
	};
	let leftRightBorder = {
		boxShadow:
			"inset 2px 0 0 #000000, inset -2px 0 0 #000000, inset 0 -1px 0 #EDF2F7",
	};
	let leftBorder = { boxShadow: "inset 1px 0 0 #000000" };

	return (
		<Modal isOpen={props.isOpen} onClose={props.onClose} size="xl">
			<ModalOverlay />
			<ModalContent minW="2xl">
				<ModalHeader>
					{props.selectedEmployee?.firstName +
						" " +
						props.selectedEmployee?.lastName}
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Box
						className="skinnyScroll"
						overflowY="auto"
						maxH="md"
						pr="1"
					>
						<Table
							size="small"
							bg="white"
							top="0"
							left="0"
							color="gray.600"
						>
							<Thead
								zIndex="2"
								top={0}
								position="sticky"
								style={{
									borderSpacing: "0",
									insetBlockStart: "0",
								}}
								border="0px"
							>
								<Tr
									textTransform="uppercase"
									bg="teal.700"
									style={topBottomBorder}
								>
									<Th minW="120px" maxW="120px" w="120px">
										<Heading
											fontSize="md"
											color="white"
											isTruncated
											p="2"
											textAlign="center"
										>
											Year
										</Heading>
									</Th>
									<Th minW="120px" maxW="120px" w="120px">
										<Heading
											fontSize="md"
											color="white"
											isTruncated
											p="2"
											textAlign="center"
										>
											Salary
										</Heading>
									</Th>
									<Th minW="120px" maxW="120px" w="120px">
										<Heading
											fontSize="md"
											color="white"
											isTruncated
											p="2"
											textAlign="center"
										>
											Increase&nbsp;(%)
										</Heading>
									</Th>
									<Th minW="120px" maxW="120px" w="120px">
										<Heading
											fontSize="md"
											color="white"
											p="2"
											textAlign="center"
										>
											{/* Year&nbsp;End Bonus */}
											Bonus
										</Heading>
									</Th>
								</Tr>
							</Thead>
							<Tbody>
								{history?.map((row, i) => {
									let pctIncreaseArr = row.getPctIncrease(
										row.newSalary,
										row.oldSalary
									);

									return (
										<Tr
											style={leftRightBorder}
											border="none"
											_hover={{ bg: "blackAlpha.50" }}
											key={row.year}
										>
											<Td px="2" style={leftBorder}>
												<Stack spacing={-1}>
													<Text
														isTruncated
														fontSize="lg"
													>
														{row.year}
													</Text>
													<Text
														isTruncated
														fontSize="xs"
													>
														{row.description}
													</Text>
												</Stack>
											</Td>
											<Td
												px="2"
												style={leftBorder}
												textAlign="right"
											>
												<Stack spacing={-1}>
													<Text
														isTruncated
														fontSize="lg"
													>
														{formatValue(
															row.newSalary,
															0,
															"currency"
														)}
													</Text>
													{row.increaseDate !==
														null && (
														<Text
															isTruncated
															fontSize="xs"
														>
															{formatValue(
																row.increaseDate,
																0,
																"moment"
															).format("M/D/yy")}
														</Text>
													)}
												</Stack>
											</Td>
											<Td
												px="2"
												style={leftBorder}
												textAlign="right"
											>
												<Stack spacing={-1}>
													<Text
														isTruncated
														fontSize="lg"
														fontWeight="bold"
														color={
															pctIncreaseArr.color
														}
													>
														{pctIncreaseArr.symbol +
															formatValue(
																pctIncreaseArr.pctIncrease,
																2,
																"percent"
															)}
													</Text>
													{pctIncreaseArr.increase !==
														0 && (
														<Text
															isTruncated
															fontSize="xs"
															color={
																pctIncreaseArr.color
															}
														>
															{pctIncreaseArr.symbol +
																formatValue(
																	pctIncreaseArr.increase,
																	0,
																	"currency"
																)}
														</Text>
													)}
												</Stack>
											</Td>
											<Td
												px="2"
												style={leftBorder}
												textAlign="right"
											>
												<Stack spacing={-1}>
													{row.year !==
														props.proposalYear && (
														<Text
															isTruncated
															fontSize="lg"
														>
															{formatValue(
																row.bonusYE,
																0,
																"currency"
															)}
														</Text>
													)}
													{row.year ===
														props.proposalYear && (
														<Text
															as="i"
															isTruncated
															fontSize="lg"
														>
															TBD
														</Text>
													)}
													{row.bonusDate !== null && (
														<Text
															isTruncated
															fontSize="xs"
														>
															{formatValue(
																row.bonusDate,
																0,
																"moment"
															).format("M/D/yy")}
														</Text>
													)}
												</Stack>
											</Td>
										</Tr>
									);
								})}
							</Tbody>
							<Tfoot
								bottom={0}
								zIndex="2"
								position="sticky"
								style={{ borderCollapse: "collapse" }}
								bg="whitesmoke"
							>
								<Tr
									style={bottomBorder}
									border="none"
									fontWeight="bold"
								>
									<Td border="none" px="2" colspan={4}></Td>
								</Tr>
							</Tfoot>
						</Table>
					</Box>
				</ModalBody>

				<ModalFooter>
					<Button
						colorScheme="teal"
						onClick={props.onClose}
						textTransform="uppercase"
					>
						Close
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
