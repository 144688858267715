import React, { useCallback } from "react";
import ToggleButton from "../../ReactTable/buttons/ToggleButton";
import DocumentPDF from "./DocumentPDF";
import DocumentObj from "../classes/Document";
import ReportObj from "../classes/Report";
import logo from "./logo.png";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

export default function PrintPDFButton({ page }) {
	const getDocument = useCallback(async (page = {}) => {
		let template = page?.pageTemplate ?? page?.template ?? null;
		let reportName = page?.pageName ?? "PDF Report";
		let fileName = reportName + " " + (page?.pageTitle ?? "");
		let report = new ReportObj({
			template: template,
			reportName: reportName,
			fileName: fileName,
			pages: [page],
		});
		let document = new DocumentObj({ reports: [report] });
		return document;
	}, []);

	const printPDF = async (page = {}) => {
		let document = await getDocument(page);
		let documentPDF = (
			<DocumentPDF document={document} logo={logo} title={document?.reportName} fileName={document.fileName} />
		);

		let asPdf = pdf([]);
		asPdf.updateContainer(documentPDF);

		let blob = await asPdf.toBlob();
		saveAs(blob, document.fileName);
	};

	return (
		<>
			{Boolean(page) && (
				<ToggleButton
					onClick={() => printPDF(page)}
					toggleOptions={[
						{
							isOpen: true,
							label: "Print PDF",
							icon: "fas fa-print",
							color: "gray.500",
							bg: "whiteAlpha.700",
						},
					]}
				/>
			)}
		</>
	);
}
