import React, { useMemo } from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import logo from "./logo.png";
import moment from "moment";
// import { log } from "../../helperFunctions";

export default function PageHeaderPDF(props) {
	const pageSize = useMemo(() => props.page?.size ?? "LETTER", [props.page?.size]);

	const headerStyles = StyleSheet.create({
		headerContainer: {
			flexDirection: "column",
		},
		logoContainer: {
			flexDirection: "row",
			justifyContent: "space-between",
			width: "100%",
			borderBottomWidth: 1,
			borderBottomColor: "gray",
		},
		topLeftContainer: {
			height: pageSize === "TABLOID" ? 60 : 40,
			width: pageSize === "TABLOID" ? 155 : 125,
			minHeight: pageSize === "TABLOID" ? 60 : 40,
			minWidth: pageSize === "TABLOID" ? 155 : 125,
		},
		topRightContainer: {
			height: pageSize === "TABLOID" ? 60 : 40,
			width: pageSize === "TABLOID" ? 130 : 100,
			minHeight: pageSize === "TABLOID" ? 60 : 40,
			minWidth: pageSize === "TABLOID" ? 130 : 100,
		},
		logo: {
			objectFit: "contain",
			objectPosition: "left",
		},
		topRightText: {
			textAlign: "right",
			color: "gray",
			fontSize: pageSize === "TABLOID" ? 12 : 9,
		},
		reportNameContainer: {
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			textAlign: "center",
			textTransform: "uppercase",
		},
		reportNameText: {
			color: "gray",
			letterSpacing: 2,
			fontSize: pageSize === "TABLOID" ? 18 : 12,
			lineHeight: 1.5,
			// lineHeight: pageSize === "TABLOID" ? 1 : 1.5,
		},
		reportTitleText: {
			color: "gray",
			letterSpacing: 1.5,
			fontSize: pageSize === "TABLOID" ? 14 : 10,
		},
		addressBarContainer: {
			width: "100%",
		},
		addressContainer: {
			flexDirection: "row",
			fontSize: pageSize === "TABLOID" ? 12 : 9,
			marginHorizontal: "auto",
			paddingTop: 4,
		},
		bulletText: {
			paddingHorizontal: 10,
		},
	});

	return (
		<>
			<View style={headerStyles.logoContainer}>
				<View style={headerStyles.topLeftContainer}>
					<Image style={headerStyles.logo} src={logo} />
				</View>
				<View style={headerStyles.reportNameContainer}>
					<Text style={headerStyles.reportNameText}>{props.page.pageName}</Text>
					{Boolean(props.page?.pageTitle) && (
						<Text style={headerStyles.reportTitleText}>{props.page.pageTitle}</Text>
					)}
				</View>
				<View style={headerStyles.topRightContainer}>
					<Text style={headerStyles.topRightText}>{moment(new Date()).format("M/D/YYYY")}</Text>
				</View>
			</View>
			{Boolean(props.page?.addressBar) && (
				<View style={headerStyles.addressBarContainer}>
					<View style={headerStyles.addressContainer}>
						<Text>{props.page?.addressBar.address}</Text>
						<Text style={headerStyles.bulletText}>*</Text>
						<Text>
							{props.page?.addressBar.city +
								", " +
								props.page?.addressBar.state +
								" " +
								props.page?.addressBar.zip}
						</Text>
						<Text style={headerStyles.bulletText}>*</Text>
						<Text>{"Phone: " + props.page?.addressBar.phone}</Text>
						<Text style={headerStyles.bulletText}>*</Text>
						<Text>{"Fax: " + props.page?.addressBar.fax}</Text>
					</View>
				</View>
			)}
		</>
	);
}
