import React, { useState, useEffect, useRef, useMemo } from "react";
import {
	Container,
	Tabs as ChakraTabs,
	TabList,
	Tab,
	TabPanels,
	TabPanel,
	Heading,
	Flex,
	IconButton,
	Stack,
	HStack,
	Box,
} from "@chakra-ui/react";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
// import { log } from "../../../helperFunctions";

export default function CarouselTabs({
	viewerMaxHeight,
	setViewerMaxHeight,
	tabs,
	currentTab,
	setTab,
	direction,
	children,
	pageOrientation = "landscape",
}) {
	const styles = useMemo(() => {
		if (pageOrientation === "portrait") {
			return {
				minH: "8xl",
				maxW: "container.xl",
				bg: "white",
				minHeight: "var(--chakra-sizes-7xl)",
				maxWidth: "var(--chakra-sizes-container-xl)",
			};
		} else {
			return {
				minH: "6xl",
				maxW: "full",
				bg: "white",
				minHeight: "var(--chakra-sizes-5xl)",
				maxWidth: "100%",
			};
		}
	}, [pageOrientation]);

	const ref = useRef(null);
	const [maxHeight, setMaxHeight] = useState(viewerMaxHeight);

	useEffect(() => {
		if (currentTab === 0 && viewerMaxHeight < (ref?.current?.clientHeight ?? maxHeight)) {
			setViewerMaxHeight(ref?.current?.clientHeight ?? maxHeight);
		} else {
			setMaxHeight(viewerMaxHeight);
		}
	}, [maxHeight, viewerMaxHeight, setViewerMaxHeight, currentTab]);

	const xOffset = 350;
	const variants = {
		enter: (direction) => ({
			x: direction > 0 ? xOffset * 2 + 200 : -xOffset * 2 + 200,
			opacity: 0,
			// scale: 0.95,
			transform: "translateX(100%)",
		}),
		active: {
			x: 0,
			opacity: 1,
			scale: 1,
			// transition: { delay: 0.25, type: "spring", stiffness: 200 },
			transition: { delay: 0.25, duration: 1, type: "spring", bounce: 0.4, ease: "easeInOut" },
			transform: "translateX(0%)",
		},
		exit: (direction) => ({
			x: direction > 0 ? -xOffset * 2 + 200 : xOffset * 2 + 200,
			opacity: 0,
			// scale: 0.95,
			transform: "translateX(-100%)",
		}),
	};

	const hasPaginated = useRef(false);

	function detectPaginationGesture(e, { offset }) {
		if (hasPaginated.current) return;
		let threshold = 300 / 2;
		let newTab = currentTab;
		let tabCount = tabs.length;

		if (tabCount >= 1) {
			if (offset.x < -threshold) {
				// If user is dragging left, go forward a page
				if (currentTab === tabCount - 1) {
					newTab = 0;
				} else {
					newTab = currentTab + 1;
				}
				// Else if the user is dragging right,
				// go backwards a page
			} else if (offset.x > threshold) {
				if (currentTab === 0) {
					newTab = tabCount - 1;
				} else {
					newTab = currentTab - 1;
				}
			}
		}
		setTab(newTab, offset.x < 0 ? 1 : -1);
	}

	const [tabIndexArr, setTabIndexes] = useState([0]);
	useEffect(() => {
		let tabArr = [0];
		for (let i = 1; i < tabs?.length; i++) {
			tabArr.push(i);
		}
		setTabIndexes(tabArr);
	}, [tabs]);

	return (
		<Container
			px={0}
			flex={1}
			key={"reportTab-Container-" + currentTab}
			maxW={"full"}
			position="relative"
			overflowY="auto"
			w="full"
			h="full"
			maxH="min"
		>
			<Stack position="relative" w="full" flex={1} overflow="hidden" maxH="full" h="full" p={3} spacing={3}>
				<HStack
					w="full"
					align="center"
					direction="row"
					flex={1}
					justify="space-between"
					position="relative"
					spacing={4}
					px={0}
				>
					<IconButton
						zIndex={2}
						size="lg"
						color="whiteAlpha.700"
						bg="whiteAlpha.300"
						variant="ghost"
						shadow="md"
						_hover={{ color: "whiteAlpha.900", bg: "whiteAlpha.500", fontWeight: "bold" }}
						onClick={() => setTab(currentTab === 0 ? tabs.length - 1 : currentTab - 1)}
						icon={<i className="fas fa-chevron-left fa-2x"></i>}
					/>
					<Box w="full" h="full">
						<ChakraTabs
							isLazy
							colorScheme="teal"
							index={currentTab}
							onChange={setTab}
							display="flex"
							flexDirection="column"
							flex={1}
							w="full"
							h="full"
							// minH={styles.minH}
							minH="100%"
							borderStyle="inset"
						>
							<TabList
								color="gray.600"
								borderBottomColor="whiteAlpha.600"
								borderBottomWidth={"2px"}
								maxW={styles?.maxW ?? "full"}
								mx="auto"
								w="full"
							>
								{tabs?.map((tab, i) => (
									<Tab
										borderStyle="inset"
										key={i}
										_selected={{
											color: "teal.600",
											bg: "whiteAlpha.500",
											borderTopRadius: "md",
											borderBottom: "3px solid",
											borderBottomColor: "teal.600",
											textShadow: "1px 1px 2px var(--chakra-colors-whiteAlpha-900)",
										}}
										_hover={{
											color: "teal.500",
											bg: "whiteAlpha.600",
											borderTopRadius: "md",
											borderBottom: "3px solid",
											borderBottomColor: "teal.500",
											textShadow: "1px 1px 2px var(--chakra-colors-whiteAlpha-900)",
											shadow: "lg",
										}}
									>
										<Heading
											isTruncated
											fontSize={"lg"}
											letterSpacing={2}
											textTransform="uppercase"
											textShadow={"1px 1px 2px var(--chakra-colors-whiteAlpha-700)"}
										>
											{tab.name}
										</Heading>
									</Tab>
								))}
							</TabList>
							<TabPanels display="flex" direction="column" h="full" w="full" flex={1} pt={4}>
								{tabs?.map((tab, i) => (
									<TabPanel
										key={i}
										display="flex"
										flexDirection="column"
										h="full"
										w="full"
										flex={1}
										p={0}
										position="relative"
										ref={ref}
										rounded="md"
										minH={styles?.minH}
									>
										<AnimatePresence custom={direction}>
											<motion.div
												key={"tab-" + tab?.name + "-" + currentTab}
												className="containerShadow carouselTab"
												// whileHover={{ scale: 1.005, transition: { duration: 0.5 } }}
												data-page={currentTab}
												variants={variants}
												initial="enter"
												animate="active"
												exit="exit"
												//REMOVE DRAG drag="x"
												//REMOVE DRAG onDrag={detectPaginationGesture}
												//REMOVE DRAG onDragStart={() => (hasPaginated.current = false)}
												//REMOVE DRAG onDragEnd={() => (hasPaginated.current = true)}
												custom={direction}
												// transition={{
												// 	duration: 1.75,
												// 	type: "spring",
												// 	// bounce: 0.25,
												// 	// stiffness: 50,
												// }}
												// Snap the component back to the center
												// if it hasn't paginated
												//REMOVE DRAG dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
												// This will be used for components to resolve all
												// other variants, in this case initial and animate.

												style={{
													borderRadius: "var(--chakra-radii-md)",
													minHeight: styles?.minHeight ?? "var(--chakra-sizes-7xl)",
													maxWidth: styles?.maxWidth ?? "100%",
													marginLeft: "auto",
													marginRight: "auto",
												}}
											>
												<Flex
													rounded={"md"}
													shadow={"lg"}
													flex={1}
													h="full"
													w="full"
													position="relative"
													bg={styles?.bg ?? "whiteAlpha.800"}
													maxW={"full"}
													minH={styles.minH}
													px={styles?.px ?? 0}
													py={styles?.py ?? 0}
													overflowX={"hidden"}
													overflowY={"auto"}
												>
													{children}
												</Flex>
											</motion.div>
										</AnimatePresence>
									</TabPanel>
								))}
							</TabPanels>
						</ChakraTabs>
					</Box>
					<IconButton
						zIndex={2}
						size="lg"
						bg="whiteAlpha.300"
						color="whiteAlpha.700"
						shadow="md"
						variant="ghost"
						_hover={{ color: "whiteAlpha.900", bg: "whiteAlpha.500", fontWeight: "bold" }}
						onClick={() => setTab(currentTab === tabs.length - 1 ? 0 : currentTab + 1)}
						icon={<i className="fas fa-chevron-right fa-2x"></i>}
					/>
				</HStack>
				<Pagination tabIndexArr={tabIndexArr} currentTab={currentTab} setTab={setTab} />
			</Stack>
		</Container>
	);
}

const Pagination = ({ tabIndexArr, currentTab, setTab }) => {
	// Wrap all the pagination Indicators
	// with AnimateSharedPresence
	// so we can detect when Indicators
	// with a layoutId are removed/added

	return (
		<Flex justify="center">
			<HStack
				justify="center"
				align="center"
				py={2}
				px={4}
				spacing={10}
				bg="whiteAlpha.200"
				// shadow="sm"
				rounded="md"
				zIndex={3}
			>
				<AnimateSharedLayout mode="wait">
					{tabIndexArr.map((i) => (
						<Indicator key={i} onClick={() => setTab(i)} isSelected={i === currentTab} />
					))}
				</AnimateSharedLayout>
			</HStack>
		</Flex>
	);
};

const Indicator = ({ isSelected, onClick }) => {
	return (
		<IconButton
			variant="ghost"
			_hover={{ bg: "whiteAlpha.400", shadow: "sm" }}
			onClick={onClick}
			style={{ zIndex: "2" }}
		>
			<motion.div className="Indicator">
				{isSelected && (
					// By setting layoutId, when this component
					// is removed and a new one is added elsewhere,
					// the new component will animate out from the old one.
					<motion.div className="Indicator-highlight" layoutId="highlight" />
				)}
			</motion.div>
		</IconButton>
	);
};
