import { ajaxGET, testGET, testPOST } from "./api-functions";
// import { formatValue } from "../helperFunctions";
// import { portalUser } from "../App";

const apiBaseURL =
	(process.env.NODE_ENV === "development"
		? "https://ppmportalappnodejs-api.azurewebsites.net"
		: window.location.origin) + "/api/app/";

export default class AppAPI {
	async TestGET(headers = {}) {
		let response = await testGET(apiBaseURL, headers);
		return response;
	}

	async TestPOST(body = {}, headers = {}) {
		let response = await testPOST(apiBaseURL, body, headers);
		return response;
	}

	/************************** BATCH API CALLS  ****************************/
	//GET AppData (Companies, Regions, Departments, Roles, Comboboxes, Trades, CostTypes, Users, Leaders ) *Does not include (Phases)
	async PortalUser_GetAppData() {
		var apiRouteURL = apiBaseURL + `appData`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET Companies
	async PortalUser_GetCompanies() {
		var apiRouteURL = apiBaseURL + `appData/companies`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET Regions
	async PortalUser_GetRegions() {
		var apiRouteURL = apiBaseURL + `appData/regions`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET Departments
	async PortalUser_GetDepartments() {
		var apiRouteURL = apiBaseURL + `appData/departments`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET Roles
	async PortalUser_GetRoles() {
		var apiRouteURL = apiBaseURL + `appData/roles`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET Trades
	async PortalUser_GetTrades() {
		var apiRouteURL = apiBaseURL + `appData/trades`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET Phases
	async PortalUser_GetPhases() {
		var apiRouteURL = apiBaseURL + `appData/phases`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET Cost Types
	async PortalUser_GetCostTypes() {
		var apiRouteURL = apiBaseURL + `appData/costTypes`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET ComboBoxes
	async PortalUser_GetComboBoxes() {
		var apiRouteURL = apiBaseURL + `appData/comboBoxes`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET Leaders
	async PortalUser_GetLeaders() {
		var apiRouteURL = apiBaseURL + `appData/leaders`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}
}
