const CONTRACT_ITEM_TYPES = [
	{
		label: "Cost of Work",
		value: "COW",
		seq: 0,
		pctMargin: 0,
		color: "green",
		icon: "far fa-check-circle",
	},
	{
		label: "General Conditions",
		value: "GCS",
		seq: 1,
		pctMargin: 0,
		color: "green",
		icon: "far fa-check-circle",
	},
	{
		label: "Fee",
		value: "FEE",
		seq: 2,
		pctMargin: 100,
		color: "green",
		icon: "far fa-check-circle",
	},
	{
		label: "Buffer",
		value: "BUFF",
		seq: 3,
		pctMargin: 100,
		color: "green",
		icon: "far fa-check-circle",
	},
	{
		label: "CRS Bonus",
		value: "BON",
		seq: 4,
		pctMargin: 0,
		color: "green",
		icon: "far fa-check-circle",
	},
	{
		label: "Lump Sum",
		value: "LS",
		seq: 5,
		pctMargin: 15,
		color: "green",
		icon: "far fa-check-circle",
	},
];
export default CONTRACT_ITEM_TYPES;
