import UsersAPI from "../../../APIs/UsersAPI";
import { formatValue } from "../../../helperFunctions";
import { v4 } from "uuid";

export default class CompanyEvent {
	constructor(event) {
		this.usersAPI = new UsersAPI();
		this.keyId = event?.keyId ?? event?.KeyID ?? null;
		this.eventUID = (event?.eventUID ?? event?.EventUID)?.toLowerCase() ?? v4();
		this.title = event?.title ?? event?.Title ?? null;
		this.description = event?.description ?? event?.Description ?? null;
		this.show = event?.Show ?? event?.show ?? true;
		this.employeeType = event?.EmployeeType ?? event?.employeeType ?? null;
		this.startDate = formatValue(event?.startDate ?? event?.StartDate, 0, "date") ?? null;
		this.endDate = formatValue(event?.endDate ?? event?.EndDate, 0, "date") ?? null;
		this.regionID = event?.regionID ?? event?.RegionID ?? null;
		this.deptID = event?.deptID ?? event?.DeptID ?? null;
		this.allDay = event?.allDay ?? event?.AllDay ?? true;
		this.eventType = event?.eventType ?? event?.EventType ?? "Company";
	}

	async upload() {
		let uploadResult = await this.usersAPI.InsertCalendarEvent(this);
		console.log(uploadResult);
		if (uploadResult.status === 200) {
			return true;
		}
	}

	async sync(updates) {
		let updateResult;
		if (Boolean(updates)) {
			updateResult = await this.usersAPI.UpdateCalendarEvent(this.eventUID, updates);
		} else {
			updates = {
				Title: this.title,
				DeptID: this.deptID,
				RegionID: this.regionID,
				EmployeeType: this.employeeType,
				Description: this.description,
				StartDate: this.startDate,
				EndDate: this.endDate,
			};

			updateResult = await this.usersAPI.UpdateCalendarEvent(this.eventUID, updates);
		}
		if (updateResult === 200) {
			return true;
		}
	}

	async delete() {
		let deleteResult = await this.usersAPI.DeleteCalendarEvent(this.eventUID);
		if (deleteResult.status === 200) {
			return true;
		}
	}

	setTitle(value) {
		this.title = value;
	}

	setDescription(value) {
		this.description = value;
	}

	setEmployeeType(value) {
		if (value === "N/A") {
			value = null;
		}
		this.employeeType = value;
	}

	setRegionID(value) {
		if (value === "N/A") {
			value = null;
		}
		this.regionID = value;
	}

	setEventType(value) {
		if (value === "N/A") {
			value = null;
		}
		this.eventType = value;
	}

	setStartDate(value) {
		if (typeof value === "object") {
			value = value?.toLocaleDateString();
		}
		this.startDate = value;
	}

	setEndDate(value) {
		if (typeof value === "object") {
			value = value?.toLocaleDateString();
		}
		this.endDate = value;
	}

	getEventType() {
		if (this.eventType === "Holiday") {
			return { icon: "fas fa-tree", color: "green.600", type: "Holiday" };
		} else if (this.eventType === "Company") {
			return { icon: "fas fa-glass-cheers", color: "orange.500", type: "Company Event" };
		} else if (this.eventType === "Birthday") {
			return { icon: "fas fa-gift", color: "purple.500", type: "Birthday" };
		} else if (this.eventType === "Time Off") {
			return { icon: "fas fa-plane-departure", color: "teal.500", type: "Time Off" };
		} else {
			return { icon: "fas fa-cheers", color: "gray.500" };
		}
	}

	getIconStyle() {
		if (this.heading === "reminder") {
			return { icon: "fas fa-bell", color: "yellow.500" };
		} else if (this.heading === "update") {
			return { icon: "fas fa-exclamation-circle", color: "blue.500" };
		} else {
			return { icon: "fas fa-bell", color: "yellow.500" };
		}
	}
}
