import React, { useState, useEffect } from "react";
import {
	Flex,
	Spacer,
	Avatar,
	AvatarBadge,
	Icon,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Portal,
	Heading,
	Text,
	HStack,
	Stack,
	Link,
	Divider,
	useColorModeValue as mode,
} from "@chakra-ui/react";
import { FaUserSecret, FaBell, FaQuestionCircle, FaBug, FaRegLightbulb, FaSignOutAlt } from "react-icons/fa";
// import NotificationDrawer from "./NotificationDrawer";
// import ImpersonateModal from "./ImpersonateModal";
import MSGraphAPI from "../../../APIs/MSGraphAPI";

// import { getDeviceType } from "../helperFunctions";

import { portalUser } from "../../../App";

export default function TopNav({
	title = "Page Title",
	subtitle = "Page Subtitle",

	sideBarButton,
	virtualRoutesButton,
	boxViewButton,
	startJoyride,
}) {
	const [notificationDrawerIsOpen, setNotificationDrawer] = useState(false);
	const [impersonateModalIsOpen, setImpersonateModal] = useState(false);

	const [bannerMessage, setBannerMessage] = useState("Welcome to the Team!");
	const [notificationCount, setNotificationCount] = useState(0);

	var titleColor = mode("whiteAlpha.800", "whiteAlpha.800");
	var subtitleColor = "whiteAlpha.700";
	var bg = mode("teal.700", "gray.600");
	var bgGradient = mode(
		"linear(to-l, blackAlpha.600, blackAlpha.400, transparent)",
		"linear(to-l, blackAlpha.600, blackAlpha.400, blackAlpha.100)"
	);

	useEffect(() => {
		let bannerMessage = portalUser?.user?.getBannerMessage() ?? "Welcome to the Team!";
		let notifications = portalUser?.getNotifications(false) ?? [];

		setBannerMessage(bannerMessage);
		setNotificationCount(notifications?.length);
	}, [notificationDrawerIsOpen]);

	return (
		<Flex
			w="full"
			maxW="full"
			// position="absolute"
			align="center"
			justify="space-between"
			overflow="hidden"
			// bg={"none"}
			shadow={"none"}
			zIndex={2}
			bg={bg}
			bgGradient={bgGradient}
			minH={16}
			h={16}
			px={2}
			py={2}

			// borderStyle="inset"
			// color={color}
			// bg={bg}
			// bgGradient={bgGradient}
		>
			{/* {portalUser?.user?.isPortalAdminYN === "Y" && (
				<ImpersonateModal isOpen={impersonateModalIsOpen} onClose={() => setImpersonateModal(false)} />
			)}
			{Boolean(portalUser?.user?.employeeUID) && (
				<NotificationDrawer isOpen={notificationDrawerIsOpen} onClose={() => setNotificationDrawer(false)} />
			)} */}

			<HStack>
				{virtualRoutesButton && virtualRoutesButton}
				{boxViewButton && boxViewButton}
			</HStack>
			<HStack spacing={4} justify="flex-end" align="center">
				{/* <Stack spacing={1} textTransform={"uppercase"} fontWeight="semibold" align="flex-start">
					<Heading letterSpacing={4} color={titleColor} size={"md"} isTruncated>
						{title}
					</Heading>

					<Text color={subtitleColor} letterSpacing={2} fontSize="sm" isTruncated>
						{subtitle}
					</Text>
				</Stack> */}
			</HStack>
			<Spacer />

			<HStack spacing={4} justify="flex-end" align="center">
				<Stack
					spacing={1}
					textTransform={"uppercase"}
					fontWeight="semibold"
					align="flex-end"
					display={{ base: "none", md: "flex" }}
				>
					<Heading letterSpacing={4} color={titleColor} size={"sm"} isTruncated>
						{portalUser?.user?.getName()}
					</Heading>
					<Text color={subtitleColor} letterSpacing={2} fontSize="xs" isTruncated>
						{bannerMessage}
					</Text>
				</Stack>

				<Menu isLazy>
					<MenuButton>
						<Avatar
							id="avatarMenu"
							key={portalUser?.user?.profilePhoto}
							src={portalUser?.user?.profilePhoto ?? ""}
							bg={portalUser?.user?.profilePhoto ? "transparent" : bg}
							shadow="md"
							h={14}
							w={14}
							icon={
								<Text as="span" color="gray.100" fontSize="2xl">
									<i
										className={
											"fas fa-user" +
											(!Boolean(portalUser?.user?.msUserID === portalUser?.msUser?.id) &&
												"-secret") +
											" fa-lg"
										}
									/>
								</Text>
							}
						>
							{notificationCount > 0 && (
								<AvatarBadge bg="teal" boxSize={6} color="white" border={0} isRound bottom={1}>
									<Text fontSize="sm" fontWeight="semibold">
										{notificationCount}
									</Text>
								</AvatarBadge>
							)}
						</Avatar>
					</MenuButton>
					<Portal>
						<MenuList py={2} px={1} zIndex="120">
							<Stack w="full" spacing={1}>
								<MenuItem color="teal.600" onClick={() => setNotificationDrawer(true)} rounded={5}>
									<HStack spacing="4" align="center">
										<Icon as={FaBell} w="30px" h="30px" />
										<Text
											as="h4"
											color="gray.500"
											fontWeight="bold"
											fontSize="md"
											textTransform="uppercase"
											letterSpacing={2}
										>
											Notifications
										</Text>
									</HStack>
								</MenuItem>

								<MenuItem color="teal.600" onClick={startJoyride} rounded={5}>
									<HStack spacing="4" align="center">
										<Icon as={FaQuestionCircle} w="30px" h="30px" />
										<Text
											as="h4"
											color="gray.500"
											fontWeight="bold"
											fontSize="md"
											textTransform="uppercase"
											letterSpacing={2}
										>
											Portal Tour
										</Text>
									</HStack>
								</MenuItem>

								<MenuItem color="teal.600" rounded={5}>
									<Link
										isExternal
										href="https://forms.office.com/pages/responsepage.aspx?id=GGZ0zUbCzkuv8Z4erUbs5_RXwWay4rxBgUknt9YscmhUNTE1Rk9aNUZSQUJGVTEzU0ZNVzdQR0ZQWiQlQCN0PWcu"
									>
										<HStack spacing="4" align="center">
											<Icon as={FaBug} w="30px" h="30px" />
											<Text
												as="h4"
												color="gray.500"
												fontWeight="bold"
												fontSize="md"
												textTransform="uppercase"
												letterSpacing={2}
											>
												Report a Bug
											</Text>
										</HStack>
									</Link>
								</MenuItem>

								<MenuItem color="teal.600" rounded={5}>
									<Link
										isExternal
										href="https://forms.office.com/pages/responsepage.aspx?id=GGZ0zUbCzkuv8Z4erUbs5_RXwWay4rxBgUknt9YscmhUNjI1Qk9WMVlLUEIyWkRUMjFWRlNFV1NVQiQlQCN0PWcu"
									>
										<HStack spacing="4" align="center">
											<Icon as={FaRegLightbulb} w="30px" h="30px" />
											<Text
												as="h4"
												color="gray.500"
												fontWeight="bold"
												fontSize="md"
												textTransform="uppercase"
												letterSpacing={2}
											>
												Submit an Idea
											</Text>
										</HStack>
									</Link>
								</MenuItem>

								<MenuItem
									color="teal.600"
									onClick={() => {
										let msClient = new MSGraphAPI();
										msClient.MS_Logout();
									}}
									rounded={5}
								>
									<HStack spacing="4" align="center">
										<Icon as={FaSignOutAlt} w="30px" h="30px" />
										<Text
											as="h4"
											color="gray.500"
											fontWeight="bold"
											fontSize="md"
											textTransform="uppercase"
											letterSpacing={2}
										>
											Log Out
										</Text>
									</HStack>
								</MenuItem>

								{portalUser?.user?.isDevOpsUserYN === "Y" && (
									<>
										<Divider />
										<MenuItem
											color="teal.600"
											onClick={() => setImpersonateModal(true)}
											rounded={5}
											mt={2}
										>
											<HStack spacing="4" align="center">
												<Icon as={FaUserSecret} w="30px" h="30px" />
												<Text
													as="h4"
													color="gray.500"
													fontWeight="bold"
													fontSize="md"
													textTransform="uppercase"
													letterSpacing={2}
												>
													Impersonate
												</Text>
											</HStack>
										</MenuItem>
									</>
								)}
							</Stack>
						</MenuList>
					</Portal>
				</Menu>

				{sideBarButton && sideBarButton}
			</HStack>
		</Flex>
	);
}
