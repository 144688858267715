import { getSubtotal, log } from "../../../helperFunctions";
import moment from "moment";

export default class SectionTemplateBillingAndCostActuals {
	constructor(contractTotalsByMth = [], contractTotals = null) {
		this.template = "BillingActuals";
		this.name = "Billing & Cost Actuals";
		this.heading = "Billing & Cost Actuals";
		this.divider = false;
		this.wrap = true;
		this.pagebreak = 0;
		this.type = "virtualTable";
		this.minRows = 1;
		this.maxRows = 12;
		this.maxRowsPDF = 6;
		this.sliceType = "last";
		this.subSections = [new SubSectionTemplateBillingAndCostActuals(contractTotalsByMth, contractTotals)];
	}
}

class SubSectionTemplateBillingAndCostActuals {
	constructor(contractTotalsByMth = [], contractTotals = null) {
		this.template = "BillingAndCostActuals";
		this.type = "virtualTable";
		this.heading = "Billing & Cost Actuals";
		this.show = contractTotalsByMth?.length > 0 ? true : false;

		let footer = this.getFooter(contractTotalsByMth, contractTotals);

		let projRow = footer?.find((d) => (d.mth = "Projected Cost")) ?? null;
		this.columns = [
			{
				accessor: "year",
				Header: "Year",
				valueType: "integer",
				width: "85%",
				show: false,
			},
			{
				accessor: "mth",
				Header: "Month",
				valueType: "month",
				whiteSpace: "nowrap",
				overflow: "visible",
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},

			{
				accessor: "actualHours_delta",
				Header: "Actual Hours",
				valueType: "number",
				decimals: 1,
				show: false,
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},

			{
				accessor: "laborActualCost_delta",
				Header: "Labor Cost",
				textAlign: "right",
				valueType: "currency",
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "subLabActualCost_delta",
				Header: "Managed Labor",
				textAlign: "right",
				valueType: "currency",
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
				show: Boolean(projRow?.subLabActualCost_delta),
			},
			{
				accessor: "matActualCost_delta",
				Header: "Material Cost",
				textAlign: "right",
				valueType: "currency",
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "subActualCost_delta",
				Header: "Subs Cost",
				textAlign: "right",
				valueType: "currency",
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "equipActualCost_delta",
				Header: "Rental Cost",
				textAlign: "right",
				valueType: "currency",
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "otherJobActualCost_delta",
				Header: "Other Job Cost",
				textAlign: "right",
				valueType: "currency",
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "contActualCost_delta",
				Header: "Contingency",
				textAlign: "right",
				valueType: "currency",
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
				show: Boolean(projRow?.contActualCost_delta),
			},

			{
				accessor: "actualCost_delta",
				Header: "Actual Cost",
				textAlign: "right",
				valueType: "currency",
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "billedAmt_delta",
				Header: "Billed Amt",
				textAlign: "right",
				valueType: "currency",
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "billedVsCost_delta",
				Header: "Billed Vs Cost",
				textAlign: "right",
				valueType: "currency",
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
		];
		this.sortBy = [
			{ id: "mth", desc: false },
			{ id: "contract", desc: false },
		];

		this.data = this.getRightAddon(contractTotalsByMth).reverse();
		this.footer = (rows = contractTotalsByMth, totals = contractTotals) => {
			return this.getFooter(rows, totals);
		};
	}

	getRightAddon(data = []) {
		for (let i = 0; i < data?.length; i++) {
			if (
				moment(new Date(data[i].mth)).format("MM/DD/YYYY") ===
				moment(new Date()).startOf("month").format("MM/DD/YYYY")
			) {
				data[i].mthRightAddon = " (To Date)";
				break;
			}
		}

		return data;
	}

	getFooter(rows = [], totals = null) {
		let actual = {
			mth: "Cost To Date",
			mthType: "string",
			mthFontSize: 7,
			mthTextSize: "sm",
			actualHours_delta: 0,
			laborActualCost_delta: 0,
			subLabActualCost_delta: 0,
			matActualCost_delta: 0,
			subActualCost_delta: 0,
			equipActualCost_delta: 0,
			otherJobActualCost_delta: 0,
			contActualCost_delta: 0,
			actualCost_delta: 0,
			billedAmt_delta: 0,
			billedVsCost_delta: 0,
		};
		let proj = {
			mth: "Projected Cost",
			mthType: "string",
			mthFontSize: 7,
			mthTextSize: "sm",
			actualHours_delta: 0,
			laborActualCost_delta: 0,
			subLabActualCost_delta: 0,
			matActualCost_delta: 0,
			subActualCost_delta: 0,
			equipActualCost_delta: 0,
			otherJobActualCost_delta: 0,
			contActualCost_delta: 0,
			actualCost_delta: 0,
			billedAmt_delta: 0,
			billedVsCost_delta: 0,
		};

		let remain = {
			mth: "Cost To Complete",
			mthType: "string",
			mthFontSize: 7,
			mthTextSize: "sm",
			actualHours_delta: 0,
			laborActualCost_delta: 0,
			subLabActualCost_delta: 0,
			matActualCost_delta: 0,
			subActualCost_delta: 0,
			equipActualCost_delta: 0,
			otherJobActualCost_delta: 0,
			contActualCost_delta: 0,
			actualCost_delta: 0,
			billedAmt_delta: 0,
			billedVsCost_delta: 0,
		};

		if (!Array.isArray(totals)) {
			if (Boolean(totals?.queryData === "ContractTotalsByMth")) {
				proj.actualHours_delta = totals?.projHours ?? 0;
				proj.laborActualCost_delta = totals?.laborProjCost ?? 0;
				proj.subLabActualCost_delta = totals?.subLabProjCost ?? 0;
				proj.matActualCost_delta = totals?.matProjCost ?? 0;
				proj.subActualCost_delta = totals?.subProjCost ?? 0;
				proj.equipActualCost_delta = totals?.equipProjCost ?? 0;
				proj.otherJobActualCost_delta = totals?.otherJobProjCost ?? 0;
				proj.contActualCost_delta = totals?.contProjCost ?? 0;
				proj.actualCost_delta = totals?.getProjCostWithPCOs() ?? 0;
				proj.billedAmt_delta = totals?.projContractAmt ?? 0 - totals?.billedAmt ?? 0;
				proj.billedVsCost_delta = parseFloat(totals?.billedAmt ?? 0) - parseFloat(totals?.actualCost ?? 0);

				actual.actualHours_delta = totals?.actualHours ?? 0;
				actual.laborActualCost_delta = totals?.laborActualCost ?? 0;
				actual.subLabActualCost_delta = totals?.subLabActualCost ?? 0;
				actual.matActualCost_delta = totals?.matActualCost ?? 0;
				actual.subActualCost_delta = totals?.subActualCost ?? 0;
				actual.equipActualCost_delta = totals?.equipActualCost ?? 0;
				actual.otherJobActualCost_delta = totals?.otherJobActualCost ?? 0;
				actual.contActualCost_delta = totals?.contActualCost ?? 0;
				actual.actualCost_delta = totals?.actualCost ?? 0;
				actual.billedAmt_delta = totals?.billedAmt ?? 0;
				actual.billedVsCost_delta = parseFloat(totals?.billedAmt ?? 0) - parseFloat(totals?.actualCost ?? 0);
			}
		} else if (Array.isArray(totals)) {
			let projRow = totals?.find((d) => d.mth === "Projected Cost") ?? null;
			if (Boolean(projRow)) {
				proj = projRow;
			}

			if (Boolean(rows?.length > 0)) {
				actual.actualHours_delta = getSubtotal(rows, "actualHours_delta") ?? 0;
				actual.laborActualCost_delta = getSubtotal(rows, "laborActualCost_delta") ?? 0;
				actual.subLabActualCost_delta = getSubtotal(rows, "subLabActualCost_delta") ?? 0;
				actual.matActualCost_delta = getSubtotal(rows, "matActualCost_delta") ?? 0;
				actual.subActualCost_delta = getSubtotal(rows, "subActualCost_delta") ?? 0;
				actual.equipActualCost_delta = getSubtotal(rows, "equipActualCost_delta") ?? 0;
				actual.otherJobActualCost_delta = getSubtotal(rows, "otherJobActualCost_delta") ?? 0;
				actual.contActualCost_delta = getSubtotal(rows, "contActualCost_delta") ?? 0;
				actual.actualCost_delta = getSubtotal(rows, "actualCost_delta") ?? 0;
				actual.billedAmt_delta = getSubtotal(rows, "billedAmt_delta") ?? 0;
				actual.billedVsCost_delta = getSubtotal(rows, "billedVsCost_delta") ?? 0;
			}
		}
		remain.actualHours_delta = parseFloat(proj.actualHours_delta ?? 0) - parseFloat(actual?.actualHours_delta ?? 0);
		remain.laborActualCost_delta =
			parseFloat(proj.laborActualCost_delta ?? 0) - parseFloat(actual?.laborActualCost_delta ?? 0);
		remain.subLabActualCost_delta =
			parseFloat(proj.subLabActualCost_delta ?? 0) - parseFloat(actual?.subLabActualCost_delta ?? 0);
		remain.matActualCost_delta =
			parseFloat(proj.matActualCost_delta ?? 0) - parseFloat(actual?.matActualCost_delta ?? 0);
		remain.subActualCost_delta =
			parseFloat(proj.subActualCost_delta ?? 0) - parseFloat(actual?.subActualCost_delta ?? 0);
		remain.equipActualCost_delta =
			parseFloat(proj.equipActualCost_delta ?? 0) - parseFloat(actual?.equipActualCost_delta ?? 0);
		remain.otherJobActualCost_delta =
			parseFloat(proj.otherJobActualCost_delta ?? 0) - parseFloat(actual?.otherJobActualCost_delta ?? 0);
		remain.contActualCost_delta =
			parseFloat(proj.contActualCost_delta ?? 0) - parseFloat(actual?.contActualCost_delta ?? 0);
		remain.actualCost_delta = parseFloat(proj.actualCost_delta ?? 0) - parseFloat(actual?.actualCost_delta ?? 0);
		remain.billedAmt_delta = parseFloat(proj.billedAmt_delta ?? 0) - parseFloat(actual?.billedAmt_delta ?? 0);
		remain.billedVsCost_delta =
			parseFloat(proj.billedVsCost_delta ?? 0) - parseFloat(actual?.billedVsCost_delta ?? 0);

		return [actual, proj, remain];
	}
}
