import * as Msal from "msal";
import MSAuthAPI from "./MSAuthAPI";
import { Client } from "@microsoft/microsoft-graph-client";
import { ImplicitMSALAuthenticationProvider } from "@microsoft/microsoft-graph-client/lib/src/ImplicitMSALAuthenticationProvider";
import { MSALAuthenticationProviderOptions } from "@microsoft/microsoft-graph-client/lib/src/MSALAuthenticationProviderOptions";
import { BatchRequestContent } from "@microsoft/microsoft-graph-client/lib/src/content/BatchRequestContent";

import {
	msGraphCALL,
	msGraphPOST,
	msGraphPUT,
	msGraphPATCH,
	msGraphDELETE,
	msGraphBetaCALL,
	msGraphBetaPOST,
	msGraphBetaPATCH,
	msGraphBetaDELETE,
} from "./api-functions";
import { log } from "../helperFunctions";

const msGraphURL = "https://graph.microsoft.com/v1.0/";
const msGraphURLBeta = "https://graph.microsoft.com/beta/";

const scopes = [
	"User.ReadWrite",
	"User.Read.All",
	"People.Read.All",
	"Group.ReadWrite.All",
	"Calendars.ReadWrite",
	"Place.Read.All",
	"Mail.ReadWrite",
	"Tasks.ReadWrite",
	"Notes.ReadWrite.All",
	"Files.ReadWrite",
	"Sites.ReadWrite.All",
];

export default class MSGraphAPI extends MSAuthAPI {
	constructor(msAuth) {
		super(msAuth);

		this.msUser = msAuth?.msUser ?? {};
		const msalConfig = {
			auth: {
				clientId: process.env.REACT_APP_MS_CLIENT_ID,
				authority: process.env.REACT_APP_MS_AUTHORITY,
			},

			// cache:{
			// 	fromCache: false,
			// }
		};
		const msalInstance = new Msal.UserAgentApplication(msalConfig);
		var options = new MSALAuthenticationProviderOptions(scopes);
		var authProvider = new ImplicitMSALAuthenticationProvider(msalInstance, options);
		options = {
			authProvider,
		};
		const MSClient = Client;
		this.client = MSClient.initWithMiddleware(options);
	}

	MSBatchRequest(batchRequestContent) {
		var graphEndpoint = "$batch";
		// var batchRequestContent = new MicrosoftGraph.BatchRequestContent();
		// var mailRequest = new Request("/me/messages", {method: 'GET'});
		// let mailRequestStep = MicrosoftGraph.BatchRequestStep = { id: "1", request: mailRequest };
		// var meRequest = new Request("/me", {method: 'GET'});
		// let meRequestStep = MicrosoftGraph.BatchRequestStep = { id: "2", request: meRequest };
		// batchRequestContent.addRequest(mailRequestStep);
		// batchRequestContent.addRequest(meRequestStep);
		var content = batchRequestContent.getContent();
		return content.then((results) => {
			var promise = msGraphPOST(this.client, graphEndpoint, results);
			return promise
				.then((results2) => {
					results2.successful = true;
					return results2;
				})
				.catch((err) => {
					var response = {
						successful: false,
						error: err,
					};
					return response;
				});
		});
	}
	// async MSBatchRequest(batchRequestContent) {

	// const graphEndpoint = "$batch";

	// try {
	// 	// Get the content from the batchRequestContent
	// 	const content = await batchRequestContent.getContent();

	// 	// Make the POST request to the graph endpoint
	// 	const results = await msGraphPOST(this.msClient, graphEndpoint, content);

	// 	// If the request is successful, return the results with a successful flag
	// 	return { ...results, successful: true };
	// } catch (error) {
	// 	// If an error occurs, return an object with the successful flag set to false and the error
	// 	return {
	// 		successful: false,
	// 		error,
	// 	};
	// }

	//NOT USED
	GetNextLink(nextLink) {
		var graphEndpoint = nextLink;
		return this.callMSGraphNextLink(graphEndpoint);
	}

	//NOT USED
	callMSGraphNextLink(theUrl) {
		try {
			var url = theUrl;
			let promise = this.client.api(url).get();
			return promise
				.then((results) => {
					results.successful = true;
					return results;
				})
				.catch((err) => {
					var response = {
						successful: false,
						error: err,
					};
					return response;
				});
		} catch (error) {
			console.log(error);
		}
	}

	//***************** #1 GET Logged In User & Logged in User Photo ***********************************// --- YOU can skip Updates
	//***************** MSGraph /me - Logged In MSUser API Calls ******************************//

	// LOGGED IN MSUSER - ACCOUNT

	// GET MSUser Account for Logged In MSUser - /me
	//NOT USED
	GetLoggedInMSUser() {
		var graphEndpoint = "me";
		return msGraphCALL(this.client, graphEndpoint);
	}

	// GET Profile Photo for Logged In MSUser  - GET /me/photo
	//NOT USED
	GetLoggedInMSUserPhoto() {
		var graphEndpoint = "me/photo";
		return msGraphCALL(this.client, graphEndpoint);
	}

	// UPDATE Profile Photo for Logged In MSUser - PUT /me/photo
	//NOT USED
	// async UpdateLoggedInMSUserPhoto(photo) {
	// 	var graphEndpoint = "me/photo/$value";
	// 	return await msGraphPUT(this.client, graphEndpoint, photo);
	// }
	async UpdateLoggedInMSUserPhoto(photo) {
		const graphEndpoint = "me/photo/$value";

		try {
			const results = await msGraphPUT(this.client, graphEndpoint, photo);
			return {
				successful: true,
				data: results,
			};
		} catch (error) {
			return {
				successful: false,
				error: error.message,
			};
		}
	}

	//***************** #2 I want to get All users and Get Batches of User Photos***********************************//

	//***************** GET MS USERS - MSUsers API Calls ***********************************//

	// GET users - get msUsers with optional search params
	//NOT USED
	GetAllMSUsers(searchParam = false, top = 999) {
		var graphEndpoint;
		if (searchParam) {
			graphEndpoint = 'users?$search="' + searchParam + '"';
		} else {
			graphEndpoint = "users?$top=" + top;
		}
		return msGraphCALL(this.client, graphEndpoint);
	}

	//TODO: Move the me call to the logged in user section and look at the people object
	// GET users/people - Retrieve a collection of person objects ordered by their relevance to the user,
	// which is determined by the user's communication and collaboration patterns, and business relationships
	//NOT USED
	ListMSPeople(userId = false) {
		var graphEndpoint;
		if (userId) {
			graphEndpoint = "users/" + userId + "/people";
		} else {
			graphEndpoint = "me/people";
		}
		return msGraphCALL(this.client, graphEndpoint);
	}

	// GET MSUser Photos by array of Users
	async GetMSUsersPhotos(users) {
		let profilePics = {};
		let batchRequests = [];
		let batchRequestContent = new BatchRequestContent();
		let requestsAdded = 0;

		for (let n = 0; n < users?.length; n++) {
			let msUserID = users[n]?.msUserID ?? users[n]?.MicrosoftID ?? null;
			let taskDetailRequest = this.GetMSUserPhoto(msUserID, true);
			let mailRequestStep = { id: n, request: taskDetailRequest };
			batchRequestContent.addRequest(mailRequestStep);
			requestsAdded++;

			if (requestsAdded % 19 === 0) {
				batchRequests.push(batchRequestContent);
				if (n !== users.length - 1) {
					batchRequestContent = new BatchRequestContent();
				}
			}

			if (n === users.length - 1 && requestsAdded > 0) {
				batchRequests.push(batchRequestContent);
			}
		}

		for (let j = 0; j < batchRequests?.length; j++) {
			let batchResults = await this.MSBatchRequest(batchRequests[j]);

			for (let k = 0; k < batchResults.responses?.length; k++) {
				let index = parseInt(batchResults.responses[k]?.id);
				if (batchResults.responses[k].status === 200) {
					let byteCharacters = atob(batchResults.responses[k].body);
					let byteNumbers = new Array(byteCharacters.length);
					for (let l = 0; l < byteCharacters.length; l++) {
						byteNumbers[l] = byteCharacters.charCodeAt(l);
					}
					let byteArray = new Uint8Array(byteNumbers);

					let imgBlob = new Blob([byteArray], { type: batchResults.responses[k].headers["Content-Type"] });
					let image = window.URL.createObjectURL(imgBlob);
					profilePics[index] = image;
				}
			}
		}
		return profilePics;
	}

	// GET MSUser Photos by array of Users
	async GetMSUserProfilePhotos(users = []) {
		let photos = [];
		let batchRequests = [];
		let batchContent = new BatchRequestContent();
		let totalRequests = 0;

		log("users", users);

		for (let n = 0; n < users?.length; n++) {
			let request = this.GetMSUserPhoto(users[n]?.msUserID, true);
			let batchRequest = { id: n, test: users[n]?.msUserID, request: request };
			batchContent.addRequest(batchRequest);
			totalRequests++;

			if (totalRequests % 19 === 0) {
				batchRequests.push(batchContent);
				if (n !== users.length - 1) {
					batchContent = new BatchRequestContent();
				}
			}

			if (n === users.length - 1 && totalRequests > 0) {
				batchRequests.push(batchContent);
			}
		}

		log("Batch Requests", batchRequests);

		for (let j = 0; j < batchRequests.length; j++) {
			let batchResults = await this.MSBatchRequest(batchRequests[j]);

			for (let k = 0; k < batchResults.responses.length; k++) {
				log("batchResults.responses[k]", batchResults.responses[k]);
				let index = parseInt(batchResults.responses[k].id);
				if (batchResults.responses[k].status === 200) {
					let byteCharacters = atob(batchResults.responses[k].body);
					let byteNumbers = new Array(byteCharacters.length);
					for (let l = 0; l < byteCharacters.length; l++) {
						byteNumbers[l] = byteCharacters.charCodeAt(l);
					}
					let byteArray = new Uint8Array(byteNumbers);

					let imgBlob = new Blob([byteArray], { type: batchResults.responses[k].headers["Content-Type"] });
					let image = window.URL.createObjectURL(imgBlob);
					photos[index] = image;
				}
			}
		}
		return photos;
	}

	// GET MSUser Photo by msUserID
	GetMSUserPhoto(msUserID, batch = false) {
		var graphEndpoint = "/users/" + msUserID + "/photo/$value";
		if (batch) {
			var request = new Request(graphEndpoint, { method: "GET" });
			return request;
		} else {
			return msGraphCALL(this.client, graphEndpoint);
		}
	}

	// TODO: Which of the Profile Photo API Calls works and does the $value thing work? dont think so...
	// GET MSUser Photo by msUserID
	//NOT USED
	GetMSUserProfilePhotoInfo(userId, batch = false) {
		var graphEndpoint = "users/" + userId + "/photo";
		if (batch) {
			var request = new Request(graphEndpoint, { method: "GET" });
			return request;
		} else {
			return msGraphCALL(this.client, graphEndpoint);
		}
	}

	// GET user by msUserID - get msUser by msUserID
	GetMSUser(userId) {
		var graphEndpoint = "users/" + userId;
		return msGraphCALL(this.client, graphEndpoint);
	}

	//***************** MSGraph Groups & Teams - Teams API Calls ***********************************//

	// GET Joined Teams for Logged In MSUser - /me/joinedTeams
	//NOT USED
	GetMSUserJoinedTeams() {
		var graphEndpoint = "me/joinedTeams";
		return msGraphCALL(this.client, graphEndpoint);
	}

	// GET MSTeams Group/Team by TeamID - GET groups/{teamId}
	//NOT USED
	GetMSTeam(teamId) {
		var graphEndpoint = "groups/" + teamId;
		return msGraphCALL(this.client, graphEndpoint);
	}

	// GET MSTeams Group/Team Members by TeamID - GET groups/{teamId}/members
	//NOT USED
	GetMSTeamMembers(teamId) {
		var graphEndpoint = "groups/" + teamId + "/members";
		return msGraphCALL(this.client, graphEndpoint);
	}

	// GET MSTeams Channels by TeamID - GET teams/{teamId}/channels
	//NOT USED
	GetMSChannels(teamId) {
		var graphEndpoint = "teams/" + teamId + "/channels";
		return msGraphCALL(this.client, graphEndpoint);
	}

	// GET Primary MSTeams Channel by TeamID - GET teams/{teamId}/primaryChannel
	//NOT USED
	GetMSPrimaryChannel(teamId) {
		var graphEndpoint = "teams/" + teamId + "/primaryChannel";
		return msGraphCALL(this.client, graphEndpoint);
	}

	//------# 3 -----All the Other most import LOGGED IN MS USER  Data--- ------------------------------------------------------------

	// GET Calendars for Logged In MSUser - /me/calendars
	//NOT USED
	GetMSUserCalendars() {
		var graphEndpoint = "me/calendars";
		return msGraphCALL(this.client, graphEndpoint);
	}

	// GET Calendar Events for Logged In MSUser by CalendarID - /me/calendars/{calendarId}/events
	//NOT USED
	GetMSCalendarEventById(calendarId) {
		var graphEndpoint = "me/calendars/" + calendarId + "/events";
		return msGraphCALL(this.client, graphEndpoint);
	}

	//GET Calendar Events for Logged In MSUser  by start/end date -  me/calendar/calendarView?startDateTime={startDate}&endDateTime={endDate}
	GetMSUserCalendarView(start, end) {
		var graphEndpoint = "me/calendar/calendarView?startDateTime=" + start + "&endDateTime=" + end;
		graphEndpoint += "&$top=50";
		graphEndpoint = encodeURI(graphEndpoint);
		var headers = {
			Prefer: 'outlook.timezone="Pacific Standard Time", outlook.body-content-type="html"',
		};

		return msGraphCALL(this.client, graphEndpoint, headers);
	}

	//GET Calendar Events for Logged In MSUser after Jan 2020 -  GET /me/events?$filter=start/
	//NOT USED
	GetMSUserEvents() {
		var graphEndpoint = "me/events?$filter=start/dateTime ge '2020-01-20T00:00'";
		graphEndpoint = encodeURI(graphEndpoint);
		var headers = {
			Prefer: 'outlook.timezone="Pacific Standard Time", outlook.body-content-type="text"',
		};

		return msGraphCALL(this.client, graphEndpoint, headers);
	}

	//GET Calendar Events for Logged In MSUser between Dates? -  GET /me/events?$filter=startswith/
	//NOT USED
	GetMSFilteredEvents(filters) {
		var graphEndpoint = "me/events?$filter=startswith(";
		var filterKeys = Object.keys(filters);

		for (var i = 0; i < filterKeys.length; i++) {
			graphEndpoint += filterKeys[i] + ", '" + filters[filterKeys[i]] + "'";
		}
		graphEndpoint += ")";
		graphEndpoint = encodeURI(graphEndpoint);
		var headers = {
			Prefer: 'outlook.timezone="Pacific Standard Time", outlook.body-content-type="text"',
		};

		return msGraphCALL(this.client, graphEndpoint, headers);
	}

	// GET Calendar Event for Logged In MSUser by EventID - GET me/events
	//NOT USED
	GetMSUserEvent(eventId) {
		var graphEndpoint = "me/events/" + eventId;
		var headers = {
			Prefer: 'outlook.timezone="Pacific Standard Time", outlook.body-content-type="html"',
		};

		return msGraphCALL(this.client, graphEndpoint, headers);
	}

	// GET MSUser Calendar Events by msUserID - get msUser by msUserID
	//NOT USED
	GetMSUserEventById(userId) {
		var graphEndpoint = "users/" + userId + "/calendar/events";
		return msGraphCALL(this.client, graphEndpoint);
	}

	// TODO: TODO: Move the me call to the logged in user section
	// GET Calendar Events by userID and filter by start and end date - GET users/{msUserID}/events?filter
	//NOT USED
	GetMSCalendarEvents(startDate = false, endDate = false, msUserID = false) {
		var graphEndpoint;

		if (msUserID) {
			graphEndpoint = "users/" + msUserID;
		} else {
			graphEndpoint = "me";
		}
		graphEndpoint += "/events";

		if (startDate) {
			startDate = new Date(startDate).toISOString().replace(/T.*/gm, "");
			if (!graphEndpoint.includes("?")) {
				graphEndpoint += "?$filter=";
			}
			graphEndpoint += "start/dateTime ge '" + startDate + "'";
		}
		if (endDate) {
			var date = new Date(endDate).toISOString().replace(/T.*/gm, "");
			if (!graphEndpoint.includes("?")) {
				graphEndpoint += "?$filter=";
			}
			if (startDate) {
				graphEndpoint += " and ";
			}
			graphEndpoint += "end/dateTime le '" + date + "'";
		}
		graphEndpoint += "&$top=50";

		var headers = {
			Prefer: 'outlook.timezone="Pacific Standard Time", outlook.body-content-type="html"',
		};

		return msGraphCALL(this.client, graphEndpoint, headers);
	}

	////----------------------------------------------------------------------------------------------------------------------------------

	// POST user - create msUser
	//NOT USED
	async CreateMSUser(requestBody) {
		var graphEndpoint = "users";
		var promise = await msGraphPOST(this.client, graphEndpoint, requestBody);
		try {
			const results = await promise;
			results.successful = true;
			return results;
		} catch (err) {
			var response = {
				successful: false,
				error: err,
				requiredReqBody: {
					accountEnabled: true,
					displayName: "displayName-value",
					mailNickname: "mailNickname-value",
					userPrincipleName: "upn-value@tenant-value.onmicrosoft.com",
					passwordProfile: {
						forceChangePasswordNextSignIn: true,
						password: "password-value",
					},
				},
			};
			return response;
		}
	}

	// PUT user - update msUser
	//NOT USED
	async UpdateMSUser(userId, requestBody) {
		var graphEndpoint = "users/" + userId;
		var promise = await msGraphPATCH(this.client, graphEndpoint, requestBody);
		try {
			const results = await promise;
			results.successful = true;
			return results;
		} catch (err) {
			var response = {
				successful: false,
				error: err,
				requiredReqBody: {
					dir: "Update parameters necessary",
					aboutMe: "text entry for user to describe themselves",
					accountEnabled: true,
					birthday: "DateTimeOffset in ISO UTC format",
					businessPhones: "String array but only one number can be set",
					city: "String of city where user is located",
					country: "String of country where user is located: US",
					department: "String of department in which user works",
					displayName: "Name displayed in address book for user",
					givenName: "Given name of user. (First name)",
					hireDate: "DateTimeOffset in ISO UTC time format",
					interests: "An array of strings for user to describe their interests",
					jobTitle: "User's job title",
					mailNickname: "Mail alias for user. Must be specified when user is created",
					mobilePhone: "The primary cellular telephone number of user",
					mySite: "URL for personal site",
					officeLocation: "Office location in user's place of business",
					onPremisesImmutableId:
						"Used to associate an on-premises Active Directory user account to their Azure AD user object.",
					otherMails: "A list of additional email address for the user",
					passwordPolicies:
						"Specifies password policies for user. Possible values: 'DisableStrongPassword' and 'DisablePasswordExpiration'",
					passwordProfile: "Specifies password profile for user. Required when creating a user.",
					pastProjects: "A list for the user to enumerate their past projects",
					postalCode: "The postal code for the user's postal address.",
					preferredLanguage: "Should follow ISO 639-1 Code. Ex: en-US",
					responsiblities: "A list for the user to enumerate their responsiblities",
					schools: "A list for the user to enumerate the schools they have attended",
					skills: "A list for the user to enumerate their skills",
					state: "The state in the user's address",
					streetAddress: "The street address of the user's place of business",
					surname: "User's last name",
					usageLocation: "A two letter country code (ISO standard 3166)",
					userPrincipleName:
						"Usually the user's email. Needs to be in full email form (something@domain.com)",
					userType: "String to classify user type such as 'Member' and 'Guest'",
				},
			};
			return response;
		}
	}

	// DELETE msUser
	//NOT USED
	// async DeleteMSUser(userId) {
	// 	var graphEndpoint = "users/" + userId;
	// 	var promise = await msGraphDELETE(this.client, graphEndpoint);
	// 	try {
	// 		const results = await promise;
	// 		results.successful = true;
	// 		return results;
	// 	} catch (err) {
	// 		var response = {
	// 			successful: false,
	// 			err: err,
	// 		};
	// 		return response;
	// 	}
	// }

	async DeleteMSUser(userId) {
		const graphEndpoint = "users/" + userId;

		try {
			const results = await msGraphDELETE(this.client, graphEndpoint);
			results.successful = true;
			return results;
		} catch (err) {
			return {
				successful: false,
				error: err,
			};
		}
	}

	//GET users/delta - Gets newly created, updated, or deleted users
	//NOT USED

	GetMSUserDelta() {
		//
		var graphEndpoint = "users/delta";
		return msGraphCALL(this.client, graphEndpoint);
	}

	// CREATE Calendar Event for Logged In MSUser  - POST me/events ----SKIP INSERT UPDATE AND DELETES FOR NOW
	// async CreateMSEvent(event) {
	// 	var graphEndpoint = "me/events";
	// 	var promise = await msGraphPOST(this.client, graphEndpoint, event);
	// 	return promise
	// 		.then((results) => {
	// 			results.successful = true;
	// 			return results;
	// 		})
	// 		.catch((err) => {
	// 			var response = {
	// 				successful: false,
	// 				error: err,
	// 			};
	// 			return response;
	// 		});
	// }
	async CreateMSEvent(event) {
		const graphEndpoint = "me/events";

		try {
			const results = await msGraphPOST(this.client, graphEndpoint, event);
			results.successful = true;
			return results;
		} catch (error) {
			return {
				successful: false,
				error: error.message,
			};
		}
	}

	// -------------------- ALL API CALLS BELOW ARE NOT CURRENTLY USED --------------------

	// UPDATE Calendar Event for Logged In MSUser - Accept Event  - POST me/events /accept
	//NOT USED
	async AcceptMSEvent(eventId) {
		var graphEndpoint = "me/events/" + eventId + "/accept";
		var promise = await msGraphPOST(this.client, graphEndpoint, {});
		return promise
			.then((results) => {
				results.successful = true;
				return results;
			})
			.catch((err) => {
				var response = {
					successful: false,
					error: err,
				};
				return response;
			});
	}

	// UPDATE Calendar Event for Logged In MSUser - Decline Event - POST me/events /decline
	//NOT USED
	async DeclineMSEvent(eventId) {
		var graphEndpoint = "me/events/" + eventId + "/decline";
		var promise = await msGraphPOST(this.client, graphEndpoint, {});
		return promise
			.then((results) => {
				results.successful = true;
				return results;
			})
			.catch((err) => {
				var response = {
					successful: false,
					error: err,
				};
				return response;
			});
	}

	//GET Avaliable Meeting Times for Logged In MSUser -  GET /me/findMeetingTimes
	//NOT USED
	FindMSMeetingTimes(requestBody) {
		var graphEndpoint = "me/findMeetingTimes";
		return msGraphBetaPOST(this.client, graphEndpoint, requestBody);
	}

	// GET MSOutlook Email Folders for Logged In MSUser - /me/mailFolders
	//NOT USED
	GetMSUserEmailFolders() {
		var graphEndpoint = "me/mailFolders";
		return msGraphCALL(this.client, graphEndpoint);
	}

	// GET MSOutlook Emails for Logged In MSUser by FolderID - /me/mailFolders
	//NOT USED
	GetMSUserFolderMessages(folderId) {
		var graphEndpoint = "me/mailFolders/" + folderId + "/messages";

		return msGraphCALL(this.client, graphEndpoint);
	}

	// GET MSOutlook Emails for Logged In MSUser by MessageID - /me/messages
	//NOT USED
	GetMSUserMessage(messageId) {
		var graphEndpoint = "me/messages/" + messageId;
		return msGraphCALL(this.client, graphEndpoint);
	}

	// GET MSTeams Chats for Logged In MSUser - /me/chats
	//NOT USED
	GetMSUserChats() {
		var graphEndpoint = "me/chats";
		return msGraphCALL(this.client, graphEndpoint);
	}

	// CREATE MSTeams Chat Subscritions to Chats  - POST /subscriptions
	//NOT USED
	async SubscribeToMSChats() {
		var graphEndpoint = "/subscriptions";
		var expirationDateTime = new Date();
		expirationDateTime.setDate(expirationDateTime.getDay() + 2);

		var request = {
			changeType: "created",
			resource: "chats/getAllMessages",
			expirationDateTime: expirationDateTime.toISOString(),
			notificationUrl: "https://ppmportal.com/publicfacing/MSGraphSubscription",
		};

		return await msGraphPOST(this.client, graphEndpoint, request);
	}

	//************************** MSGraph BETA - API Call Helper Functions **************************//
	// callMSGraphBeta(theUrl) {
	// 	try {
	// 		var url = msGraphURLBeta + theUrl;
	// 		let promise = this.client.api(url).get();
	// 		return promise
	// 			.then((results) => {
	// 				results.successful = true;
	// 				return results;
	// 			})
	// 			.catch((err) => {
	// 				var response = {
	// 					successful: false,
	// 					error: err,
	// 				};
	// 				return response;
	// 			});
	// 	} catch (error) {
	// 		log("Call MSGraph Beta Error", error);
	// 	}
	// }

	// async postMSGraphBeta(theUrl, requestBody) {
	// 	var url = msGraphURLBeta + theUrl;
	// 	var promise;
	// 	if (!requestBody) {
	// 		promise = this.client.api(url).header("Content-Type", "application/json").post();
	// 	} else {
	// 		promise = this.client.api(url).header("Content-Type", "application/json").post(requestBody);
	// 	}
	// 	try {
	// 		const results = await promise;
	// 		results.successful = true;
	// 		return results;
	// 	} catch (err) {
	// 		var response = {
	// 			successful: false,
	// 			error: err,
	// 		};
	// 		return response;
	// 	}
	// }

	// async updateMSGraphBeta(theUrl, requestBody) {
	// 	var url = msGraphURLBeta + theUrl;
	// 	var promise = this.client.api(url).patch(requestBody);
	// 	try {
	// 		const results = await promise;
	// 		results.successful = true;
	// 		return results;
	// 	} catch (err) {
	// 		var response = {
	// 			successful: false,
	// 			error: err,
	// 		};
	// 		return response;
	// 	}
	// }

	// async deleteMSGraphBeta(theUrl) {
	// 	var url = msGraphURLBeta + theUrl;
	// 	var promise = this.client.api(url).delete();
	// 	try {
	// 		const results = await promise;
	// 		return results;
	// 	} catch (err) {
	// 		var response = {
	// 			successful: false,
	// 			error: err,
	// 		};
	// 		return response;
	// 	}
	// }

	//***************** MSGraph BETA - MSOutlook To Do Tasks for Logged In MSUser -/me/outlook/tasks  ************************//
	// GET MSOutlook ToDo Tasks for Logged In MSUser - GET /me/outlook/tasks
	//NOT USED
	GetMSUserToDoList() {
		var graphEndpoint = "me/outlook/tasks";
		return msGraphBetaCALL(this.client, graphEndpoint);
	}

	// GET  MSOutlook ToDo Task for Logged In MSUser by TaskID - GET /me/outlook/tasks/{taskId}
	//NOT USED
	GetMSUserToDoTask(taskId) {
		var graphEndpoint = "me/outlook/tasks/" + taskId;
		return msGraphBetaCALL(this.client, graphEndpoint);
	}

	// Create MSOutlook ToDo Task for Logged In MSUser - POST /me/outlook/tasks
	//NOT USED
	CreateMSUserToDoTask(requestBody) {
		var graphEndpoint = "me/outlook/tasks";
		return msGraphBetaPOST(this.client, graphEndpoint, requestBody);
	}

	// UPDATE MSOutlook ToDo Task for Logged In MSUser by TaskID  - PUT /me/outlook/tasks/{taskId}
	//NOT USED
	UpdateMSUserToDoTask(taskId, requestBody) {
		var graphEndpoint = "me/outlook/tasks/" + taskId;
		return msGraphBetaPATCH(this.client, graphEndpoint, requestBody);
	}

	// UPDATE MSOutlook ToDo Task for Logged In MSUser Set as Complete by TaskID  - POST /me/outlook/tasks/{taskId}
	//NOT USED
	CompleteMSToDoTask(taskId) {
		var graphEndpoint = "me/outlook/tasks/" + taskId + "/complete";
		return msGraphBetaPOST(this.client, graphEndpoint, {});
	}

	// DELETE MSOutlook ToDo Task for Logged In MSUser - DELETE /me/outlook/tasks/{taskId}
	//NOT USED
	DeleteMSToDoTask(taskId) {
		var graphEndpoint = "me/outlook/tasks/" + taskId;
		return msGraphBetaDELETE(this.client, graphEndpoint);
	}

	//NOT USED
	async CreateMSChannel(teamId, requestBody) {
		var graphEndpoint = "teams/" + teamId + "/channels";
		var promise = await msGraphPOST(this.client, graphEndpoint, requestBody);
		try {
			const results = await promise;
			results.successful = true;
			return results;
		} catch (err) {
			var response = {
				successful: false,
				error: err,
			};
			return response;
		}
	}

	// Get MSTeams Tab on a Channel by TeamID & ChannelID - GET teams/{teamId}/channels/{channelId}/tabs
	//NOT USED
	GetMSChannelTabs(teamId, channelId) {
		var graphEndpoint = "teams/" + teamId + "/channels/" + channelId + "/tabs";
		return msGraphCALL(this.client, graphEndpoint, false, "teamsApp");
	}

	// Create MSTeams Tab on a Channel by TeamID & ChannelID - POST teams/{teamId}/channels/{channelId}/tabs
	//NOT USED
	async CreateMSChannelTabs(teamId, channelId, requestBody) {
		var graphEndpoint = "teams/" + teamId + "/channels/" + channelId + "/tabs";
		var promise = await msGraphPOST(this.client, graphEndpoint, requestBody);
		try {
			const results = await promise;
			results.successful = true;
			return results;
		} catch (err) {
			var response = {
				successful: false,
				error: err,
			};
			return response;
		}
	}

	// GET MSTeams Messages for a Channel by TeamID & ChannelID - GET groups/{teamId}/channels/{channelId}/messages
	//NOT USED
	GetMSChannelMessages(teamId, channelId) {
		var graphEndpoint = "teams/" + teamId + "/channels/" + channelId + "/messages";
		return msGraphBetaCALL(this.client, graphEndpoint);
	}

	// Create MSTeams Message for a Channel by TeamID & ChannelID - POST groups/{teamId}/channels/{channelId}/messages
	//NOT USED
	async PostMSTeamConversationMessage(teamId, channelId, requestBody) {
		var graphEndpoint = "teams/" + teamId + "/channels/" + channelId + "/messages";
		var promise = msGraphBetaPOST(this.client, graphEndpoint, requestBody);
		try {
			const results = await promise;
			results.successful = true;
			return results;
		} catch (err) {
			var response = {
				successful: false,
				error: err,
			};
			return response;
		}
	}

	// Get MSTeams Message Thread by TeamID and ThreadID - GET groups/{teamId}/threads/{threadId}
	//NOT USED
	GetMSConversationThread(teamId, threadId) {
		var graphEndpoint = "groups/" + teamId + "/threads/" + threadId;
		return msGraphBetaCALL(this.client, graphEndpoint);
	}

	// Create MSTeams Message Thread by TeamID - POST groups/{teamId}/threads
	//NOT USED
	CreateMSThread(teamId, requestBody) {
		var graphEndpoint = "groups/" + teamId + "/threads";
		return msGraphBetaPOST(this.client, graphEndpoint, requestBody);
	}

	// Get MSTeams Message Thread Posts by TeamID and ThreadID - GET groups/{teamId}/threads/{threadId}/posts
	//NOT USED
	GetMSThreadPosts(teamId, conversationId) {
		var graphEndpoint = "groups/" + teamId + "/threads/" + conversationId + "/posts";
		return msGraphBetaCALL(this.client, graphEndpoint);
	}

	// Create MSTeams Message Thread Posts by TeamID and ThreadID - POST groups/{teamId}/threads/{threadId}/reply
	//NOT USED
	MS_CreateThreadPost(teamId, conversationId, requestBody) {
		var graphEndpoint = "groups/" + teamId + "/threads/" + conversationId + "/reply";
		return msGraphBetaPOST(this.client, graphEndpoint, requestBody);
	}

	//***************** MSOneNote API Calls - {route}/onenote  ***********************************//

	// GET MSOneNote Notebooks by Logged in MSUser, UserID or GroupID - GET {route}/onenote/notebooks
	//NOT USED
	GetMSNotebooks(id = false, userType) {
		var graphEndpoint;
		if (!id) {
			graphEndpoint = "me/onenote/notebooks";
		} else if (userType === "user") {
			graphEndpoint = "users/" + id + "/onenote/notebooks";
		} else {
			graphEndpoint = "groups/" + id + "/onenote/notebooks";
		}

		return msGraphCALL(this.client, graphEndpoint);
	}

	// GET MSOneNote Notebook Sections by Logged in MSUser, UserID or GroupID - GET {route}/onenote/sections
	//NOT USED
	GetMSNotebookSections(id = false, userType = false) {
		var graphEndpoint;
		if (!id) {
			graphEndpoint = "me/onenote/sections";
		} else if (userType === "user") {
			graphEndpoint = "users/" + id + "/onenote/sections";
		} else {
			graphEndpoint = "groups/" + id + "/onenote/sections";
		}

		return msGraphCALL(this.client, graphEndpoint);
	}

	// GET MSOneNote Notebook Section Groups by Logged in MSUser, UserID or GroupID - GET {route}/onenote/sectionGroups
	//NOT USED
	GetMSNotebookSectionGroups(id = false, userType = false) {
		var graphEndpoint;
		if (!id) {
			graphEndpoint = "me/onenote/sectionGroups";
		} else if (userType === "user") {
			graphEndpoint = "users/" + id + "/onenote/sectionGroups";
		} else {
			graphEndpoint = "groups/" + id + "/onenote/sectionGroups";
		}

		return msGraphCALL(this.client, graphEndpoint);
	}

	// GET MSOneNote Notebook Pages by Logged in MSUser, UserID or GroupID - GET {route}/onenote/pages
	//NOT USED
	GetMSNotebookPages(id = false, userType = false) {
		var graphEndpoint;
		if (!id) {
			graphEndpoint = "me/onenote/pages";
		} else if (userType === "user") {
			graphEndpoint = "users/" + id + "/onenote/pages";
		} else {
			graphEndpoint = "groups/" + id + "/onenote/pages";
		}

		return msGraphCALL(this.client, graphEndpoint);
	}

	// GET MSOneNote Notebook Pages by Logged in MSUser, UserID or GroupID and SectionID - GET {route}/onenote/sections/{sectionId}/pages
	//NOT USED
	GetMSNotebookSectionPages(id = false, userType = false, sectionId) {
		var graphEndpoint;
		if (!id) {
			graphEndpoint = "me/onenote/sections/" + sectionId + "/pages";
		} else if (userType === "user") {
			graphEndpoint = "users/" + id + "/onenote/sections/" + sectionId + "/pages";
		} else {
			graphEndpoint = "groups/" + id + "/onenote/sections/" + sectionId + "/pages";
		}

		return msGraphCALL(this.client, graphEndpoint);
	}

	// GET MSOneNote Notebook Page by Logged in MSUser, UserID or GroupID and PageID - GET {route}/onenote/pages/{pageId}
	//NOT USED
	GetMSNotebookPage(id = false, userType = false, pageId) {
		var graphEndpoint;
		if (!id) {
			graphEndpoint = "me/onenote/pages/" + pageId;
		} else if (userType === "user") {
			graphEndpoint = "users/" + id + "/onenote/pages/" + pageId;
		} else {
			graphEndpoint = "groups/" + id + "/onenote/pages/" + pageId;
		}

		return msGraphCALL(this.client, graphEndpoint);
	}

	// GET MSOneNote Notebook Page Content by Logged in MSUser, UserID or GroupID and PageID - GET {route}/onenote/pages/{pageId}"/content"
	//NOT USED
	GetMSNotebookPageContent(id = false, userType = false, pageId) {
		var graphEndpoint;
		if (!id) {
			graphEndpoint = "me/onenote/pages/" + pageId + "/content?includeIDs=true";
		} else if (userType === "user") {
			graphEndpoint = "users/" + id + "/onenote/pages/" + pageId + "/content?includeIDs=true";
		} else {
			graphEndpoint = "groups/" + id + "/onenote/pages/" + pageId + "/content?includeIDs=true";
		}

		return msGraphCALL(this.client, graphEndpoint);
	}

	//***************** MSGraph Planner /users - MSPlanner API Calls ***********************************//

	// GET MSPlanner Plans by MSTeams Group/Team TeamID  - GET groups/{teamId}/planner/plans
	//NOT USED
	GetMSTeamPlans(teamId) {
		var graphEndpoint = "groups/" + teamId + "/planner/plans";
		return msGraphCALL(this.client, graphEndpoint);
	}

	// CREATE MSPlanner Plan - POST planner/plans
	//NOT USED
	async CreateMSPlan(requestBody) {
		var graphEndpoint = "planner/plans";
		var promise = await msGraphPOST(this.client, graphEndpoint, requestBody);
		try {
			const results = await promise;
			results.successful = true;
			return results;
		} catch (err) {
			var response = {
				successful: false,
				error: err,
			};
			return response;
		}
	}

	// GET MSPlanner Buckets by PlanID - GET /planner/plans/{planID}/buckets
	//NOT USED
	GetMSPlanBuckets(planId) {
		var graphEndpoint = "planner/plans/" + planId + "/buckets";
		return msGraphCALL(this.client, graphEndpoint);
	}

	// GET MSPlanner Tasks by PlanID - GET /planner/plans/{planID}/tasks
	//NOT USED
	GetMSPlanTasks(planId) {
		var graphEndpoint = "planner/plans/" + planId + "/tasks";
		return msGraphCALL(this.client, graphEndpoint);
	}

	// GET MSPlanner Task Details by PlanID - GET /planner/plans/{planID}/details
	//NOT USED
	GetMSPlanDetails(planId) {
		var graphEndpoint = "planner/plans/" + planId + "/details";
		return msGraphCALL(this.client, graphEndpoint);
	}

	// GET MSPlanner Buckets by BucketID - GET /planner/buckets/{bucketId}/tasks
	//NOT USED
	GetMSBucket(bucketId) {
		var graphEndpoint = "planner/buckets/" + bucketId;
		return msGraphCALL(this.client, graphEndpoint);
	}

	// CREATE MSPlanner Bucket - POST /planner/buckets"
	//NOT USED
	async CreateMSBucket(requestBody) {
		var graphEndpoint = "planner/buckets";
		var promise = await msGraphPOST(this.client, graphEndpoint, requestBody);
		try {
			const results = await promise;
			results.successful = true;
			return results;
		} catch (err) {
			var response = {
				successful: false,
				error: err,
			};
			return response;
		}
	}

	// UPDATE MSPlanner Bucket - PUT /planner/buckets/{bucketID}"
	async updateMSGraphBucket(theUrl, etag, requestBody) {
		try {
			var url = msGraphURL + theUrl;
			var promise = await this.client
				.api(url)
				.headers({ "If-Match": etag, Prefer: "return=representation" })
				.update(requestBody);
			return promise;
		} catch (error) {
			console.log(error);
		}
	}

	// UPDATE MSPlanner Bucket - PUT /planner/buckets/{bucketID}"
	//NOT USED
	async UpdateMSPlanBucket(bucketId, etag, requestBody) {
		var graphEndpoint = "/planner/buckets/" + bucketId;
		var promise = this.updateMSGraphBucket(graphEndpoint, etag, requestBody);
		try {
			const results = await promise;
			results.successful = true;
			return results;
		} catch (err) {
			var response = {
				successful: false,
				error: err,
			};
			return response;
		}
	}

	// GET MSPlanner Tasks by BucketID - GET /planner/buckets/{bucketId}/tasks
	//NOT USED
	GetMSBucketTasks(bucketId) {
		var graphEndpoint = "planner/buckets/" + bucketId + "/tasks";
		return msGraphBetaCALL(this.client, graphEndpoint);
	}

	// GET MSPlanner Tasks for Logged In MSUser - GET /me/planner/tasks
	//NOT USED
	GetMSAllTasks() {
		var graphEndpoint = "me/planner/tasks";
		return msGraphCALL(this.client, graphEndpoint);
	}

	// GET MSPlanner Tasks by TaskID - GET /planner/tasks/{taskId}
	//NOT USED
	GetMSTask(taskId) {
		var graphEndpoint = "/planner/tasks/" + taskId;
		return msGraphBetaCALL(this.client, graphEndpoint);
	}

	// Create MSPlanner Task - POST /planner/tasks
	//NOT USED
	async CreateMSTask(task) {
		var graphEndpoint = "/planner/tasks";
		var promise = await msGraphPOST(this.client, graphEndpoint, task);
		try {
			const results = await promise;
			results.successful = true;
			return results;
		} catch (err) {
			var response = {
				successful: false,
				error: err,
			};
			return response;
		}
	}

	updateMSGraphTask(theUrl, requestBody, etag) {
		var url = msGraphURL + theUrl;
		var promise = this.client.api(url).header("If-Match", etag).patch(requestBody);
		return promise;
	}

	updateMSGraphTaskBeta(theUrl, requestBody, etag) {
		var url = msGraphURLBeta + theUrl;
		var promise = this.client.api(url).header("If-Match", etag).patch(requestBody);
		return promise;
	}

	// Update MSPlanner Task by TaskId - POST /planner/tasks/{taskId}
	//NOT USED
	async UpdateMSTask(taskId, etag, taskDetails) {
		var graphEndpoint = "/planner/tasks/" + taskId;
		var promise;

		if (Boolean(taskDetails?.priority)) {
			promise = this.updateMSGraphTaskBeta(graphEndpoint, taskDetails, etag);
		} else {
			promise = this.updateMSGraphTask(graphEndpoint, taskDetails, etag);
		}

		try {
			var results = await promise;
			if (results === undefined) {
				results = { taskId: taskId };
			}
			return results;
		} catch (err) {
			var response = {
				successful: false,
				error: err,
			};
			return response;
		}
	}

	deleteMSGraphTask(theUrl, etag) {
		//   var url = msGraphURL + theUrl;
		var promise = this.client.api(theUrl).header("If-Match", etag).delete();
		return promise;
	}

	//NOT USED
	async DeleteMSTask(taskId, etag) {
		var graphEndpoint = "planner/tasks/" + taskId;
		var promise = this.deleteMSGraphTask(graphEndpoint, etag);
		try {
			const results = await promise;
			return results;
		} catch (err) {
			var response = {
				successful: false,
				error: err,
			};
			return response;
		}
	}

	// GET MSPlanner Task Details by TaskId - GET /planner/tasks/{taskId}/details
	//NOT USED
	GetMSTaskDetails(taskId, batch = false) {
		var graphEndpoint = "/planner/tasks/" + taskId + "/details";
		if (batch) {
			var request = new Request(graphEndpoint, { method: "GET" });
			//var step: BatchRequestStep = { id: 1, request: request };
			return request;
		} else {
			return msGraphCALL(this.client, graphEndpoint);
		}
	}

	// GET MSPlanner Task Details by TaskId - POST /planner/tasks/{taskId}/details
	//NOT USED
	async UpdateMSTaskDetails(taskId, etag, taskDetails) {
		var graphEndpoint = "/planner/tasks/" + taskId + "/details";
		var promise = this.updateMSGraphTask(graphEndpoint, taskDetails, etag);
		try {
			const results = await promise;
			return results;
		} catch (err) {
			var response = {
				successful: false,
				error: err,
			};
			return response;
		}
	}

	//***************** MSGraph OneDrive - OneDrive API Calls ***********************************//

	// GET OneDrive Files for Logged In MSUser - GET /me/drive
	//NOT USED
	GetMSMyDrive() {
		var graphEndpoint = "me/drive";
		return msGraphCALL(this.client, graphEndpoint);
	}

	// Create OneDrive File Sharing Link for Logged In MSUser by ItemID - POST /me/drive/items/{itemId}/createLink
	//NOT USED
	async CreateMSFileSharingLink(itemId) {
		var graphEndpoint = "me/drive/items/" + itemId + "/createLink";
		var linkPermissions = {
			type: "view",
		};
		return await msGraphPOST(this.client, graphEndpoint, linkPermissions);
	}

	// GET OneDrive Drive for MSTeams by TeamID  - GET /groups/{teamId}/drive
	//NOT USED
	GetMSTeamDrive(teamId) {
		var graphEndpoint = "groups/" + teamId + "/drive";
		return msGraphCALL(this.client, graphEndpoint);
	}

	// GET OneDrive Root File Items by DriveID  - GET /drives/{driveId}/root/children
	//NOT USED
	GetMSDriveRoot(driveId) {
		var graphEndpoint = "drives/" + driveId + "/root/children";
		return msGraphCALL(this.client, graphEndpoint);
	}
	// GET OneDrive File Items by DriveID  - GET /drives/{driveId}/items/{itemId}/children
	//NOT USED
	GetMSTeamDriveChildren(driveId, itemId) {
		var graphEndpoint = "drives/" + driveId + "/items/" + itemId + "/children";
		return msGraphCALL(this.client, graphEndpoint);
	}
	// GET OneDrive File Thumbnails by DriveID  - GET /drives/{driveId}/items/{itemId}/thumbnails
	//NOT USED
	GetMSFileThumbnails(driveId, itemId) {
		var graphEndpoint = "drives/" + driveId + "/items/" + itemId + "/thumbnails";
		// var linkPermissions = {
		// 	type: "view",
		// 	scope: "organization",
		// };
		return msGraphCALL(this.client, graphEndpoint);
	}

	//***************** MSGraph SharePoint - SharePoint API Calls ***********************************//

	//GET Sharepoint Sites for PPMechanical Tennant
	//NOT USED
	GetMSSharePointSites() {
		var graphEndpoint = "sites/ppmechanical.sharepoint.com";
		return msGraphCALL(this.client, graphEndpoint);
	}

	//GET MSTeams Sharepoint Sites by TeamID - GET /groups/{teamId}/sites/root
	//NOT USED
	GetMSSharePointGroup(teamID) {
		var graphEndpoint = "groups/" + teamID + "/sites/root";
		return msGraphCALL(this.client, graphEndpoint);
	}

	//GET Sharepoint Lists for PPMechanical Tennant
	//NOT USED
	GetMSSharePointLists() {
		var graphEndpoint = "sites/ppmechanical.sharepoint.com/lists";
		return msGraphCALL(this.client, graphEndpoint);
	}

	//GET Sharepoint Lists by SiteID - GET /sites/{siteId}/lists
	//NOT USED
	GetGroupSharePointLists(siteID) {
		var graphEndpoint = "sites/" + siteID + "/lists";
		return msGraphCALL(this.client, graphEndpoint);
	}

	//GET Sharepoint List by SiteID and ListID - GET /sites/{siteId}/lists/{listId}
	//NOT USED
	GetGroupSharepointListbyID(siteID, listID) {
		var graphEndpoint = "sites/" + siteID + "/lists/" + listID;
		return msGraphCALL(this.client, graphEndpoint);
	}

	//GET Sharepoint List Items by SiteID and ListID - GET /sites/{siteId}/lists/{listId}/items
	//NOT USED
	GetGroupSharepointListItemsbyID(siteID, listID) {
		var graphEndpoint = "sites/" + siteID + "/lists/" + listID + "/items?expand=fields&$top=2000";
		return msGraphCALL(this.client, graphEndpoint);
	}

	//GET Sharepoint List Columns by SiteID and ListID - GET /sites/{siteId}/lists/{listId}/columns
	//NOT USED
	GetGroupSharepointListColumnsbyID(siteID, listID) {
		var graphEndpoint = "sites/" + siteID + "/lists/" + listID + "/columns?expand=fields";
		return msGraphCALL(this.client, graphEndpoint);
	}

	//GET Sharepoint List Content Types by SiteID and ListID - GET /sites/{siteId}/lists/{listId}/contentTypes
	//NOT USED
	GetGroupSharepointListContentTypesbyID(siteID, listID) {
		var graphEndpoint = "sites/" + siteID + "/lists/" + listID + "/contentTypes";
		return msGraphCALL(this.client, graphEndpoint);
	}

	//GET Sharepoint List Content Type Columns by SiteID, ListID and contentTypeID - GET /sites/{siteId}/lists/{listId}/contentTypes/{contentTypeId}/columns
	//NOT USED
	GetGroupSharepointListContentTypeColumns(siteID, listID, contentTypeID) {
		var graphEndpoint = "sites/" + siteID + "/lists/" + listID + "/contentTypes/" + contentTypeID + "/columns";
		return msGraphCALL(this.client, graphEndpoint);
	}

	//GET Sharepoint List Document Set Versions by SiteID, ListID and ItemID - GET /sites/{siteId}/lists/{listId}/items/{itemID}/documentSetVersions
	//NOT USED
	GetGroupSharepointListDocumentSetVersions(siteID, listID, itemID) {
		var graphEndpoint = "sites/" + siteID + "/lists/" + listID + "/items/" + itemID + "/documentSetVersions";
		return msGraphCALL(this.client, graphEndpoint);
	}
}
