import React from "react"; // ,{ useEffect, useState }
import { Box, Center, Flex, Stack, Heading, Text, IconButton } from "@chakra-ui/react";
import moment from "moment";
// import UsersAPI from "../../../APIs/UsersAPI";

// import { formatDate } from "../../../helperFunctions";

export default function TimeOffRequestListItem({ openModal, row, viewOption }) {
	const handleRowClick = (request) => {
		if (openModal) {
			openModal(request);
		}
	};

	return (
		<Flex
			display={row.request.status === 2 ? "none" : "flex"}
			id={row.request.requestId}
			direction="column"
			w="full"
			flex={1}
			p="2"
			align="center"
			justify="space-between"
			wrap="wrap"
			onClick={() => {
				handleRowClick(row.request);
			}}
			cursor="pointer"
			rounded="5"
			_hover={{ bg: "blackAlpha.50" }}
		>
			<Flex w="full" flex={1} wrap="wrap" justify="space-between" align="center">
				<Flex mr="2" align="flex-end">
					<Box w="60px" h="60px" rounded="5" shadow="lg" bg="whiteAlpha.800">
						<Center bg="red.600" borderTopRadius="5" p="1">
							<Heading
								as="h4"
								size="xs"
								color="whiteAlpha.900"
								letterSpacing={1}
								textTransform="uppercase"
								fontWeight="900"
							>
								{moment(row.request.startDate).format("MMM")}
							</Heading>
						</Center>
						<Center ml="-1">
							<Heading my="auto" as="h4" size="lg" color="gray.600">
								{moment(row.request.startDate).format("D")}
							</Heading>
						</Center>
					</Box>

					<IconButton
						variant="unstyled"
						key={row.request.id}
						boxSize="20px"
						color={row.request.getStatusInfo().color}
						ml="-6"
						mb="-1"
					>
						<i className={row.request.getStatusInfo().icon + " fa-lg fa-fw"} />
					</IconButton>
				</Flex>
				<Flex flex={3} w="full" align="center" mr="5">
					<Stack w="full" spacing="1" py="2">
						{viewOption !== "employee" && (
							<Heading
								fontWeight="semibold"
								size="md"
								mr="1"
								color="gray.500"
								textTransform="uppercase"
								letterSpacing={1}
							>
								{row.request.name}
							</Heading>
						)}
						{row.request?.getDays() === 1 && (
							<Heading
								pl="1"
								fontWeight="semibold"
								letterSpacing={viewOption !== "employee" ? 1 : 0}
								size={viewOption !== "employee" ? "sm" : "md"}
								mr="1"
								color={viewOption !== "employee" ? "gray.400" : "gray.500"}
							>
								{moment(row.request.startDate).format("MMM ").toUpperCase() +
									moment(row.request.startDate).format("Do") +
									(row.request.requestedHours / 8 === 1
										? moment(row.request.startDate).format(" YYYY")
										: "")}{" "}
							</Heading>
						)}
						{row.request?.getDays() !== 1 && (
							<Heading
								pl="1"
								fontWeight="semibold"
								letterSpacing={viewOption !== "employee" ? 1 : 0}
								size={viewOption !== "employee" ? "sm" : "md"}
								mr="1"
								color={viewOption !== "employee" ? "gray.400" : "gray.500"}
							>
								{moment(row.request.startDate).format("MMM ").toUpperCase() +
									moment(row.request.startDate).format("Do") +
									" to " +
									moment(row.request.endDate).format("MMM ").toUpperCase() +
									moment(row.request.endDate).format("Do ") +
									moment(row.request.endDate).format("YYYY")}{" "}
							</Heading>
						)}
						<Text as="i" pl="1" fontSize="sm" letterSpacing={1} color="gray.400">
							{row.request.requestedHours / 8 +
								" " +
								row.request.type +
								" Day" +
								(row.request.requestedHours / 8 > 1 ? "s (" : " (") +
								row.request.requestedHours +
								" Work Hours)"}
						</Text>
					</Stack>
				</Flex>

				<Flex flex={2} w="full" align="center">
					<Stack w="full" spacing="1" py="2" justify="flex-end">
						<Heading
							fontWeight="semibold"
							w="full"
							textAlign="right"
							size="md"
							isTruncated
							letterSpacing={1}
							color={row.request.getStatusInfo().color}
						>
							{row.request.getStatusInfo().status.toUpperCase()}{" "}
						</Heading>
						<Stack wrap="wrap" spacing={1}>
							<Text isTruncated textAlign="right" as="i" fontSize="sm" letterSpacing={1} color="gray.400">
								{row.request.sentToName}
							</Text>
							{row.request.dateAcknowledged && (
								<Text as="i" textAlign="right" fontSize="xs" letterSpacing={1} color="gray.400">
									{moment(row.request.dateAcknowledged).format("MMM ").toUpperCase() +
										moment(row.request.dateAcknowledged).format("Do YYYY")}{" "}
								</Text>
							)}
							{!row.request.dateAcknowledged && (
								<Text as="i" textAlign="right" fontSize="xs" letterSpacing={1} color="gray.400">
									{moment(row.request.dateCreated).format("MMM ").toUpperCase() +
										moment(row.request.dateCreated).format("Do YYYY")}{" "}
								</Text>
							)}
						</Stack>
					</Stack>
				</Flex>
			</Flex>
		</Flex>
	);
}
