import React, { useState, useRef } from "react";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { useAsyncDebounce } from "react-table";

// A Global Search Bar Filter Input
// for Tables and Lists
export default function SideBarSearchBar({
	globalFilter,
	setGlobalFilter,
	rowCount,
	listRef = null,
	toggleAllRowsExpanded,
}) {
	const [value, setValue] = useState(globalFilter);
	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 200);
	const globalFilterRef = useRef(null);

	return (
		<InputGroup
			bg="whiteAlpha.200"
			color="whiteAlpha.700"
			_placeholder={{ color: "whiteAlpha.600" }}
			_hover={{ bg: "whiteAlpha.300", color: "whiteAlpha.900" }}
		>
			<InputLeftElement pointerEvents="none" children={<i className="fas fa-search"></i>} />
			<Input
				placeholder="Search..."
				ref={globalFilterRef}
				onChange={(e) => {
					setValue(e.target.value);
					onChange(e.target.value);
					if (listRef !== null) {
						toggleAllRowsExpanded(true);
						listRef?.current.resetAfterIndex(0, false);
					}
				}}
			/>
		</InputGroup>
	);
}
