import Contract from "../../Contracts/classes/Contract";
import DFTemplate from "./DFTemplate";
import SchedulesAPI from "../../../APIs/SchedulesAPI";
import EgnyteAPI from "../../../APIs/EgnyteAPI";

import DF_SCOPES from "../constants/DFScopes";
import EGNYTE_PATH from "../constants/EgnytePath";

import { portalUser } from "../../../App";
import { log, convertArrayToMap, arrayToString, formatDate } from "../../../helperFunctions";
import { v4 } from "uuid";

export default class DFJob {
	constructor(job) {
		if (job instanceof DFJob) {
			Object.assign(this, job);
		} else {
			this.queryData = job?.queryData ?? "DFJob";
			this.schedulesAPI = new SchedulesAPI();
			this.egnyteAPI = new EgnyteAPI();
			this.uid = job?.uid ?? v4();
			this.id = job?.id?.toString() ?? null;

			this.jobID = job?.jobID?.toString() ?? null;
			this.job = job?.job ?? null;
			this.jobName = job?.jobName ?? null;
			this.installOffset = job?.installOffset ?? 0;
			this.vendorName = job?.vendorName ?? null;
			this.vendorEmail = job?.vendorEmail ?? null;
			this.isArchived = job?.isArchived?.toString() ?? "0";

			this.templateID = job?.templateID?.toString() ?? null;
			this.workflowID = job?.workflowID?.toString() ?? null;
			this.templateType = job?.templateType ?? "D&F";

			this.scopeID = job?.scopeID?.toString() ?? null;
			this.scopeDesc = job?.scopeDesc ?? null;
			this.scopeAbbr = job?.scopeAbbr ?? null;

			this.regionID = job?.regionID?.toString() ?? null;
			this.region = job?.region ?? null;

			this.accessYN = job?.accessYN ?? "Y";
			this.jccmKeyID = job?.jccmKeyID?.toString() ?? null;
			this.jcco = job?.jcco ?? null;
			this.contract = job?.contract ?? null;
			this.contractName = job?.contractName ?? null;
			this.contractStatus = job?.contractStatus ?? "Open";

			this.team = job?.team ?? [];
			this.respRoles = job?.respRoles ?? [];
			this.workflows = job?.workflows ?? [];
			this.sequences = job?.sequences ?? [];

			this.egnyteLinkMatrix = job?.egnyteLinkMatrix ?? null;
			this.egnyteLinkPlanBreaks = job?.egnyteLinkPlanBreaks ?? null;
			this.egnyteLinkSubmittals = job?.egnyteLinkSubmittals ?? null;

			this.dateCreated = formatDate(job?.dateCreated, true) ?? null;
			this.createdBy = job?.createdBy ?? null;
			this.dateUpdated = formatDate(job?.dateUpdated, true) ?? null;
			this.updatedBy = job?.updatedBy ?? null;
		}
	}

	initDFJob(template = null, contract = null, team = this.team, respRoles = this.respRoles) {
		this.initTemplate(template);
		this.initContract(contract);
		this.initTeam(team, respRoles);
		this.initTitle();
	}

	initTemplate(template = null) {
		if (Boolean(template) && template instanceof DFTemplate) {
			let templateProps = {
				templateType: template?.templateType,
				templateID: template?.templateID,
				templateName: template?.templateName,
				scopeID: template?.scopeID,
				scopeDesc: template?.scopeDesc,
				scopeAbbr: template?.scopeAbbr,
			};
			Object.assign(this, templateProps);
		}
	}

	initContract(contract = null) {
		if (Boolean(contract) && contract instanceof Contract) {
			let contractProps = {
				accessYN: contract?.accessYN,
				jccmKeyID: contract?.jccmKeyID,
				jcco: contract?.jcco,
				contract: contract?.contract,
				contractName: contract?.description,
				contractStatus: contract?.status,
				regionID: contract?.regionID,
				region: contract?.region,
			};
			Object.assign(this, contractProps);
		}
	}

	initTeam(team = this.team, respRoles = this.respRoles) {
		let teamMap = convertArrayToMap(team, "respRoleID");
		let rolesMap = convertArrayToMap(respRoles, "respRoleID");
		Object.assign(this, { team, respRoles });
		Object.keys(rolesMap).forEach((key) => {
			this[key] = teamMap[key]?.name ?? null;
		});
	}

	// Set job title and subtitle on job object
	initTitle() {
		let titleParams = [this.job, this.jobName, "-", this.scopeDesc];
		let subtitleParams = [this.contract, this.contractName, "-", this.region];
		this.title = arrayToString(titleParams, " ");
		this.subtitle = arrayToString(subtitleParams, " ");
	}

	// Insert user into team
	initTeamMembers() {
		let userMap = convertArrayToMap(portalUser?.getUsers(), "msUserID");
		for (let i = 0; i < this.team?.length; i++) {
			Boolean(userMap[this.team[i]?.msUserID]?.msUserID) &&
				this.team[i].initUser(userMap[this.team[i]?.msUserID]);
		}
	}

	// Insert deliverables into workflows and insert workflows into sequences
	initDFSequences(workflows = this.workflows, sequences = this.sequences, deliverables = []) {
		let deliverablesMap = convertArrayToMap(deliverables, "sequenceID", true);
		for (let i = 0; i < sequences?.length; i++) {
			sequences[i].templateType = this.templateType;
			sequences[i].initDFSequence(
				workflows,
				deliverablesMap[sequences[i].sequenceID] ?? [],
				this.installOffset ?? 0
			);
		}
		Object.assign(this, { workflows, sequences });
	}

	// INSERT UPDATE DELETE SQL

	async insertDB() {
		if (!Boolean(this.id)) {
			//TODO: Validations (unique job)
			let data = (({ jccmKeyID, scopeID, templateID, job, jobName, installOffset, vendorName, vendorEmail }) => ({
				jccmKeyID,
				scopeID,
				templateID,
				job,
				jobName,
				installOffset,
				vendorName,
				vendorEmail,
			}))(this);

			let result = await this.schedulesAPI.PortalUser_DFData_InsertJob(data);
			if (result?.status === 200 && result?.value?.length > 0) {
				let newJob = new DFJob(result?.value[0]);
				Object.assign(this, newJob);
				this.getEgnyteFolder();
				result.value = this;
			}
			log("Database Result (insert job)", result);
			return result;
		}
	}

	async updateDB() {
		if (Boolean(this.id)) {
			let data = (({
				workflowID,
				jobName,
				installOffset,
				vendorName,
				vendorEmail,
				egnyteLinkMatrix,
				egnyteLinkSubmittals,
				egnyteLinkPlanBreaks,
				isArchived,
			}) => ({
				workflowID,
				jobName,
				installOffset,
				vendorName,
				vendorEmail,
				egnyteLinkMatrix,
				egnyteLinkSubmittals,
				egnyteLinkPlanBreaks,
				isArchived,
			}))(this);

			let result = await this.schedulesAPI.PortalUser_DFData_UpdateJob(this.id, data);
			if (result?.status === 200 && result?.value?.length > 0) {
				result = new DFJob(result?.value[0]);
				Object.assign(this, result);
			}
			log("Database Result (update job)", result);
			return result;
		}
	}

	// getUpdateProps(job = this) {
	// 	let data = (({
	// 		workflowID,
	// 		jobName,
	// 		installOffset,
	// 		vendorName,
	// 		vendorEmail,
	// 		egnyteLinkMatrix,
	// 		egnyteLinkSubmittals,
	// 		egnyteLinkPlanBreaks,
	// 		isArchived,
	// 	}) => ({
	// 		workflowID,
	// 		jobName,
	// 		installOffset,
	// 		vendorName,
	// 		vendorEmail,
	// 		egnyteLinkMatrix,
	// 		egnyteLinkSubmittals,
	// 		egnyteLinkPlanBreaks,
	// 		isArchived,
	// 	}))(job);
	// 	return data;
	// }

	async deleteDB() {
		if (Boolean(this.id)) {
			let result = await this.schedulesAPI.PortalUser_DFData_DeleteJob(this.id);
			log("Database Result (DELETE job)", result);
			return result;
		}
	}

	async updateRow(accessor, value) {
		value = value?.replace("'", "`") ?? null;
		log("{ [accessor]: value }", { [accessor]: value });
		Object.assign(this, { [accessor]: value });
		log("this after object assignment", this);
		await this.updateDB();
		return this;
	}

	// ************ EGNYTE ****************

	formatEgnyteName(value) {
		value = value?.replace(/\s*(.)/gm, "$1");
		if (Boolean(value) && value[value?.length - 1] === ".") {
			value = value?.substr(0, value?.length - 1);
		}
		return value;
	}

	getEgnyteFolderPath() {
		let contract = this.formatEgnyteName(this.contract);
		let filepath = this.formatEgnyteName(this.job + DF_SCOPES.find(({ value }) => value === this.scopeID).abbr);
		return `${EGNYTE_PATH}/${contract}/${filepath}`;
	}

	async getEgnyteFolder() {
		let contract = this.formatEgnyteName(this.contract);
		let dfJobNumber = this.job;
		let result = await this.egnyteAPI.getFolder(`${EGNYTE_PATH}/${contract}/${dfJobNumber}`);
		log("Egnyte Folder", [result, `${EGNYTE_PATH}/${contract}/${dfJobNumber}`]);
		if (Boolean(result.errorMessage)) {
			let createResult = await this.egnyteAPI.createFolder(
				encodeURI(`${EGNYTE_PATH}/${contract}/${dfJobNumber}`)
			);
			if (Boolean(createResult?.folder_id)) {
				let materialMatrixResult = await this.egnyteAPI.createFolder(
					encodeURI(`${EGNYTE_PATH}/${contract}/${dfJobNumber}/Material Matrix`)
				);
				let staticFoldersObject = {};
				if (Boolean(materialMatrixResult?.folder_id)) {
					staticFoldersObject.egnyteLinkMatrix = materialMatrixResult.path;
				}
				let submittalApprovalsResult = await this.egnyteAPI.createFolder(
					encodeURI(`${EGNYTE_PATH}/${contract}/${dfJobNumber}/Approved Submittals`)
				);
				if (Boolean(submittalApprovalsResult?.folder_id)) {
					staticFoldersObject.egnyteLinkSubmittals = submittalApprovalsResult.path;
				}
				let miscDeliverablesResult = await this.egnyteAPI.createFolder(
					encodeURI(`${EGNYTE_PATH}/${contract}/${dfJobNumber}/Misc. Deliverables`)
				);
				if (Boolean(miscDeliverablesResult?.folder_id)) {
					staticFoldersObject.egnyteLinkPlanBreaks = miscDeliverablesResult.path;
				}
				Object.assign(this, staticFoldersObject);
				this.updateDB();
			}
		}
	}

	async getDeliverableEngyteFolder(path) {
		let contract = this.formatEgnyteName(this.contract);
		let dfJobNumber = this.formatEgnyteName(this.job);
		path = `${EGNYTE_PATH}/${contract}/${dfJobNumber}/${path}`;
		let folderResult = await this.egnyteAPI.getFolder(path, true);
		log("Egnyte Folder GET", [folderResult, path]);
		if (Boolean(folderResult.errorMessage)) {
		} else {
			return folderResult;
		}
	}
}

// 	this.templateID = job?.templateID?.toString() ?? null;

// 	this.installOffset = job?.installOffset ?? 0;
// 	this.isArchived = job?.isArchived?.toString() ?? "0";

// 	this.jccmKeyID = job?.jccmKeyID?.toString() ?? null;
// 	this.regionID = job?.regionID?.toString() ?? null;

// 	this.workflowID = job?.workflowID?.toString() ?? null;

// 	this.team = job?.team ?? [];
// 	this.respRoles = job?.respRoles ?? [];
// 	this.workflows = job?.workflows ?? [];
// 	this.sequences = job?.sequences ?? [];
// 	this.deliverables = job?.deliverables ?? [];

// 	this.vendorName = job?.vendorName ?? null;
// 	this.vendorEmail = job?.vendorEmail ?? null;

// 	this.egnyteLinkMatrix = job?.egnyteLinkMatrix ?? null;
// 	this.egnyteLinkPlanBreaks = job?.egnyteLinkPlanBreaks ??  null;
// 	this.egnyteLinkSubmittals = job?.egnyteLinkSubmittals ?? null;
// 	{
// 		accessor: "seqOrder",
// 		Header: "Order",
// 		valueType: "integer",
// 		textAlign: "center",
// 		width: "65%",
// 		textSize: "center",
// 		show: false,
// 	},
// 	{
// 		accessor: "seq",
// 		Header: "Seq",
// 		valueType: "integer",
// 		textAlign: "center",
// 		width: "50%",
// 		Footer: (rows = []) => {
// 			return this.getFooter(rows, this.footer);
// 		},
// 	},
// 	{
// 		accessor: "cpmSchedID",
// 		Header: "CPM Sched ID#",
// 		width: "75%",
// 		formInput: "text",
// 		placeholder: "N/A",
// 	},
// 	{
// 		accessor: "shopDrawings",
// 		Header: "Shop Drawings",
// 		formInput: "text",
// 		placeholder: "N/A",
// 	},
// 	{
// 		accessor: "pipingLevel",
// 		Header: "Piping Level",
// 		formInput: "text",
// 		placeholder: "N/A",
// 	},
// 	{
// 		accessor: "deckingLevel",
// 		Header: "Decking Level",
// 		formInput: "text",
// 		placeholder: "N/A",
// 		show: Boolean(dfJob?.scopeDesc === "Decking"),
// 	},
// 	{
// 		accessor: "area",
// 		Header: "Area",
// 		formInput: "text",
// 		placeholder: "N/A",
// 	},
// 	{
// 		accessor: "quad",
// 		Header: "Quad",
// 		formInput: "text",
// 		placeholder: "N/A",
// 	},
// 	{
// 		accessor: "system",
// 		Header: "System",
// 		width: "135%",
// 		formInput: "text",
// 		placeholder: "N/A",
// 	},
// ];

// let workflowColumns = this.getWorkflowColumns(dfJob);

// let lastColumns = [
// 	{
// 		accessor: "installStart",
// 		Header: dfJob?.scopeDesc === "Decking" ? "Trimble Date on Deck" : "Install Start",
// 		valueType: "date",
// 		textAlign: "center",
// 		formInput: "date",
// 	},
// 	{
// 		accessor: "comments",
// 		Header: "Comments",
// 		width: "250%",
// 	},
// 	{
// 		accessor: "isDeleted",
// 		Header: "Deleted",
// 		width: "75%",
// 		// show: false,
// 	},
// ];
