import React, { useState, useRef, useCallback, useEffect } from "react";
import {
	Drawer,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	DrawerHeader,
	DrawerBody,
	DrawerFooter,
	Flex,
	Text,
	Heading,
	Button,
	Collapse,
	Stack,
	HStack,
	useToast,
} from "@chakra-ui/react";
import DataInput from "../../../core/Inputs/data/DataInput";
import DiversityVendorDrawerItem from "./DiversityVendorDrawerItem";
import DIVERSITY_CERTS from "../constants/DiversityCerts";
import { log } from "../../../helperFunctions";
//import ConfirmationPrompt from "../../../core/Alerts/ConfirmationPrompt";
import AddNewVendor from "./AddVendorModal";
import { portalUser } from "../../../App";
import DiversityCert from "../classes/DiversityCert";

export default function DiversityVendorDrawer({ selectedVendor, isOpen, closeVendor, saveVendor, deleteVendorCert }) {
	const toast = useToast();
	const containerRef = useRef(null);
	const descriptionRef = useRef(null);
	const isPurchasingManager = portalUser?.user?.isPurchasingManagerYN === "Y";
	const diversityCerts = DIVERSITY_CERTS?.filter((d) => !["BUDGET"].includes(d.value)) ?? [];
	const [addVendorModalOpen, setAddVendorModalOpen] = useState(false);
	const [itemCount, setItemCount] = useState(selectedVendor?.diversityCerts?.length);
	const [updateTrigger, setUpdateTrigger] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);
	const [newCertActive, setNewCertActive] = useState(null);

	const spacing = { vertSpacing: 2, horzSpacing: 2, leftFlex: 4, rightFlex: 2 };

	const addDiversityCert = useCallback(async () => {
		let newCert = new DiversityCert(selectedVendor);
		log("newcert", newCert);
		selectedVendor.diversityCerts.push(newCert);
		setNewCertActive(newCert);
	}, [selectedVendor]);

	const saveDiversityCert = useCallback(async () => {
		await newCertActive.insertDiversityCert(selectedVendor);
		setUpdateTrigger(true);
		setItemCount(selectedVendor?.diversityCerts?.length + 1);
		toast({
			title: "Success!",
			description: "New certification added! Please refresh to make further updates on this certificate.",
			status: "success",
			duration: 5000,
			isClosable: true,
		});
		setNewCertActive(null);
	}, [newCertActive, selectedVendor, toast]);

	const updateVendor = useCallback(
		(attr, value) => {
			log("value", value);
			log("attr", attr);
			selectedVendor.update(attr, value);
			setUpdateTrigger(true);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedVendor]
	);

	useEffect(() => {
		if (updateTrigger) {
			setUpdateTrigger(false);
		}
	}, [updateTrigger]);

	const validateVendor = useCallback(async () => {
		setSaveLoading(true);
		await saveVendor(selectedVendor);
		setSaveLoading(false);
	}, [saveVendor, selectedVendor]);

	// const deleteVendor = async () => {
	// 	await selectedVendor?.deleteVendor();
	// 	closeVendor();
	// 	toast({
	// 		title: "Success!",
	// 		description: `Vendor ${selectedVendor?.vendorName} deleted! Please refresh the page to see your changes reflected.`,
	// 		status: "success",
	// 		duration: 5000,
	// 		isClosable: true,
	// 	});
	// };

	const archiveVendor = useCallback(
		() => {
			const value = selectedVendor?.vendorShowHideYN === "Y" ? "N" : "Y";
			selectedVendor.update("vendorShowHideYN", value);
			setUpdateTrigger(true);
			toast({
				title: "Success!",
				description: `Vendor ${selectedVendor?.vendorName} ${value === "Y" ? "Shown" : "Hidden"}!
								Please refresh the page to see your changes reflected.`,
				status: "success",
				duration: 5000,
				isClosable: true,
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedVendor]
	);

	return (
		<Drawer placement="right" isOpen={isOpen} onClose={closeVendor} size="md" key={selectedVendor?.apvmKeyID}>
			<DrawerOverlay />

			<DrawerContent ref={containerRef}>
				<DrawerCloseButton />

				<DrawerHeader>
					<HStack align="center" w="full" spacing={2}>
						<Heading as="h2" size="md" color="gray.400" textTransform="uppercase" letterSpacing={2}>
							Diversity Vendor {selectedVendor?.vendorShowHideYN === "Y" ? "" : "(Hidden)"}
						</Heading>
					</HStack>
				</DrawerHeader>

				<DrawerBody overflowX={"hidden"} overflowY={"auto"}>
					<Stack spacing={4} w="full" h="full">
						<Stack spacing={spacing.vertSpacing} w="full">
							<Stack
								p={4}
								spacing={spacing.vertSpacing}
								bg="blackAlpha.50"
								borderWidth={2}
								rounded="10"
								borderColor={"blackAlpha.100"}
							>
								{/* <HStack
									w="full"
									pb={1}
									borderBottomWidth={2}
									borderBottomColor="blackAlpha.300"
									align="center"
								>
									<Heading
										w="full"
										color={"gray.500"}
										textTransform="uppercase"
										fontSize="lg"
										letterSpacing={2}
									>
										Vendor Info
									</Heading>
									<Button
										colorScheme={"blue"}
										as="a"
										target="_blank"
										px={3}
										fontSize="sm"
										onClick={() => setAddVendorModalOpen(true)}
									>
										Add Vendor
									</Button>
								</HStack> */}

								<Stack spacing={spacing.horzSpacing} direction="row" w="full">
									<DataInput
										key={selectedVendor?.apvmKeyID + "-diversity-" + selectedVendor?.vendorGroup}
										name="diversityContact"
										type="text"
										label="Company"
										isDisabled
										placeholder="Company"
										defaultValue={selectedVendor?.vendorGroup}
										flex={spacing.leftFlex}
										variant="outline"
										inputProps={{ px: 2 }}
										isModal
									/>
									<DataInput
										key={selectedVendor?.apvmKeyID + "-diversity-" + selectedVendor?.vendor}
										name="vendor"
										isDisabled
										type="text"
										label="Vendor"
										placeholder="Vendor"
										defaultValue={selectedVendor?.vendorID}
										flex={spacing.leftFlex * 2}
										variant="outline"
										inputProps={{ px: 2 }}
										isModal
									/>
								</Stack>

								<Stack spacing={spacing.horzSpacing} direction="row" w="full">
									<DataInput
										key={selectedVendor?.apvmKeyID + "-diversity-" + selectedVendor?.vendorName}
										name="vendorName"
										type="text"
										label="Vendor Name"
										isDisabled
										defaultValue={selectedVendor?.vendorName}
										flex={spacing.leftFlex}
										variant="outline"
										inputProps={{ px: 2 }}
										isModal
									/>
								</Stack>
								<Stack spacing={spacing.horzSpacing} direction="row" w="full" alignItems="flex-end">
									<DataInput
										key={selectedVendor?.apvmKeyID + "-diversity-" + selectedVendor?.diversityURL}
										name="diversityURL"
										type="text"
										label="Certification Link"
										placeholder="Certification Link"
										defaultValue={selectedVendor?.diversityURL}
										onBlur={(ev) => {
											if (ev.target.value !== selectedVendor?.diversityURL) {
												updateVendor("diversityURL", ev.target.value);
											}
										}}
										flex={spacing.leftFlex}
										variant="outline"
										inputProps={{ px: 2 }}
										isModal
										isDisabled={!Boolean(isPurchasingManager)}
									/>
									<Button
										colorScheme={"blue"}
										as="a"
										target="_blank"
										href={selectedVendor?.diversityURL}
										px={3}
										fontSize="sm"
									>
										View
									</Button>
								</Stack>
								<Stack spacing={spacing.horzSpacing} direction="row" w="full">
									<DataInput
										key={
											selectedVendor?.apvmKeyID +
											"-diversity-" +
											selectedVendor?.diversityServiceType
										}
										name="diversityServiceType"
										type="textarea"
										rowsMin={4}
										label="Service Type"
										placeholder="Service Type"
										defaultValue={selectedVendor?.diversityServiceType}
										onBlur={(ev) => {
											if (ev.target.value !== selectedVendor?.diversityServiceType) {
												updateVendor("diversityServiceType", ev.target.value);
											}
										}}
										inputRef={descriptionRef}
										flex={spacing.leftFlex}
										labelProps={{ py: 1, letterSpacing: 1 }}
										variant="outline"
										inputBG="white"
										inputProps={{ px: 2 }}
										maxLength={256}
										isModal
										readOnly={!Boolean(isPurchasingManager)}
									/>
								</Stack>
							</Stack>
						</Stack>
						<Stack spacing={spacing.vertSpacing} w="full">
							<Stack
								p={4}
								spacing={spacing.vertSpacing}
								bg="blackAlpha.50"
								borderWidth={2}
								rounded="10"
								borderColor={"blackAlpha.100"}
							>
								<HStack
									w="full"
									pb={1}
									borderBottomWidth={2}
									borderBottomColor="blackAlpha.300"
									align="center"
								>
									<Heading
										w="full"
										color={"gray.500"}
										textTransform="uppercase"
										fontSize="lg"
										letterSpacing={2}
									>
										Diversity Contact
									</Heading>
								</HStack>

								<Stack spacing={spacing.horzSpacing} direction="row" w="full">
									<DataInput
										key={
											selectedVendor?.apvmKeyID + "-diversity-" + selectedVendor?.diversityContact
										}
										name="diversityContact"
										type="text"
										label="Contact Name"
										placeholder="Diversity Contact"
										defaultValue={selectedVendor?.diversityContact}
										onBlur={(ev) => {
											if (ev.target.value !== selectedVendor?.diversityContact) {
												updateVendor("diversityContact", ev.target.value);
											}
										}}
										flex={spacing.leftFlex}
										variant="outline"
										inputProps={{ px: 2 }}
										isModal
										isDisabled={!Boolean(isPurchasingManager)}
									/>
								</Stack>

								<Stack spacing={spacing.horzSpacing} direction="row" w="full">
									<DataInput
										key={selectedVendor?.apvmKeyID + "-diversity-" + selectedVendor?.diversityPhone}
										name="diversityPhone"
										type="text"
										label="Contact Phone"
										placeholder="(###) ###-####"
										defaultValue={selectedVendor?.diversityPhone}
										onBlur={(ev) => {
											if (ev.target.value !== selectedVendor?.diversityPhone) {
												updateVendor("diversityPhone", ev.target.value);
											}
										}}
										flex={spacing.leftFlex}
										variant="outline"
										inputProps={{ px: 2 }}
										isModal
										isDisabled={!Boolean(isPurchasingManager)}
									/>
								</Stack>

								<Stack spacing={spacing.horzSpacing} direction="row" w="full">
									<DataInput
										key={selectedVendor?.apvmKeyID + "-diversity-" + selectedVendor?.diversityEmail}
										name="diversityEmail"
										type="text"
										label="Contact Email"
										placeholder="Email"
										defaultValue={selectedVendor?.diversityEmail}
										onBlur={(ev) => {
											if (ev.target.value !== selectedVendor?.diversityEmail) {
												updateVendor("diversityEmail", ev.target.value);
											}
										}}
										flex={spacing.leftFlex}
										variant="outline"
										inputProps={{ px: 2 }}
										isModal
										isDisabled={!Boolean(isPurchasingManager)}
									/>
								</Stack>
							</Stack>
						</Stack>
						<Stack spacing={spacing.vertSpacing} w="full">
							<Stack
								p={4}
								spacing={spacing.vertSpacing}
								bg="blackAlpha.50"
								borderWidth={2}
								rounded="10"
								borderColor={"blackAlpha.100"}
							>
								<HStack
									w="full"
									pb={1}
									borderBottomWidth={2}
									borderBottomColor="blackAlpha.300"
									align="center"
								>
									<Heading
										w="full"
										color={"gray.500"}
										textTransform="uppercase"
										fontSize="lg"
										letterSpacing={2}
									>
										Certifications
									</Heading>
								</HStack>

								<Stack spacing={spacing.horzSpacing} w="full">
									<Stack p={0} spacing={spacing.horzSpacing} rounded="10">
										{selectedVendor?.diversityCerts?.map((cert, i) => {
											return (
												<DiversityVendorDrawerItem
													key={cert?.keyID}
													cert={cert}
													diversityCerts={diversityCerts}
													spacing={spacing}
													i={i}
													itemCount={itemCount}
													deleteVendorCert={deleteVendorCert}
													isPurchasingManager={Boolean(isPurchasingManager)}
													setNewCertActive={setNewCertActive}
												/>
											);
										})}
									</Stack>
								</Stack>

								<Collapse in={Boolean(itemCount < DIVERSITY_CERTS.length)}>
									{!newCertActive && (
										<Button
											size="sm"
											colorScheme="teal"
											onClick={addDiversityCert}
											w="full"
											isDisabled={!Boolean(isPurchasingManager)}
										>
											ADD DIVERSITY CERT
										</Button>
									)}
									{newCertActive && (
										<Button
											size="sm"
											colorScheme="green"
											onClick={saveDiversityCert}
											w="full"
											isDisabled={!Boolean(isPurchasingManager)}
										>
											SAVE
										</Button>
									)}
								</Collapse>
							</Stack>
						</Stack>
					</Stack>
				</DrawerBody>

				<DrawerFooter>
					<Flex justify="space-between" flex={1} w="full">
						{/* <Button
							variant="outline"
							colorScheme="red"
							onClick={closeVendor}
							borderWidth={2}
							px={3}
							fontSize="sm"
						>
							<HStack>
								<i className={"fas fa-times fa-fw"}></i>
								<Text letterSpacing={1} textTransform={"uppercase"}>
									CANCEL
								</Text>
							</HStack>
						</Button> */}
						<Button
							variant="outline"
							colorScheme={selectedVendor?.vendorShowHideYN === "Y" ? "red" : "teal"}
							onClick={archiveVendor}
							borderWidth={2}
							px={3}
							fontSize="sm"
							disabled={!Boolean(isPurchasingManager)}
						>
							<HStack>
								<i
									className={`fas fa-${
										selectedVendor?.vendorShowHideYN === "Y" ? "eye-slash" : "eye"
									} fa-fw`}
								></i>
								<Text letterSpacing={1} textTransform={"uppercase"}>
									{selectedVendor?.vendorShowHideYN === "Y" ? "Hide" : "Show"}
								</Text>
							</HStack>
						</Button>
						{/* <Flex ml="1">
							<ConfirmationPrompt
								buttonTitle={"Delete"}
								alertDialogHeader={"Delete Vendor"}
								alertDialogBody={`Are you sure you want to delete the vendor ${selectedVendor?.vendorName}`}
								confirmationButtonScheme={"red"}
								loadingText={"Deleting Vendor..."}
								confirmationFunction={deleteVendor}
								variant={"outline"}
							/>
						</Flex> */}
						<HStack spacing={2} flex={1} w="full" justify="flex-end" align="center">
							<Button
								colorScheme={"blue"}
								onClick={validateVendor}
								px={3}
								fontSize="sm"
								isLoading={saveLoading}
								loadingText={"Saving..."}
							>
								<HStack>
									<i className={"fas fa-save fa-fw"}></i>
									<Text fontSize="sm" letterSpacing={1} textTransform={"uppercase"}>
										Save
									</Text>
								</HStack>
							</Button>
						</HStack>
					</Flex>
					<AddNewVendor isOpen={addVendorModalOpen} closeModal={() => setAddVendorModalOpen(false)} />
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}
