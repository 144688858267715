import React, { useState, useEffect, useRef } from "react";
import { Container, VStack, Tabs as ChakraTabs, TabList, Tab, TabPanels, TabPanel, Heading } from "@chakra-ui/react";
// import { log } from "../../../helperFunctions";

export default function Tabs({ viewerMaxHeight, setViewerMaxHeight, tabs, tabVal, changeTab }) {
	const ref = useRef(null);
	const [maxHeight, setMaxHeight] = useState(viewerMaxHeight);

	useEffect(() => {
		if (viewerMaxHeight < (ref?.current?.clientHeight ?? maxHeight)) {
			setViewerMaxHeight(ref?.current?.clientHeight ?? maxHeight);
		} else {
			setMaxHeight(viewerMaxHeight);
		}
	}, [maxHeight, viewerMaxHeight, setViewerMaxHeight, tabVal]);

	return (
		<Container px={0} flex={1} key={"reportTab-Container-" + tabVal} maxW="full">
			<ChakraTabs
				isLazy
				colorScheme="teal"
				index={tabVal}
				onChange={changeTab}
				display="flex"
				flexDirection="column"
				flex={1}
				w="full"
				h="full"
			>
				<TabList color="gray.600" borderBottomColor="whiteAlpha.500">
					{tabs?.map((tab, i) => (
						<Tab
							key={i}
							_selected={{
								color: "teal.600",
								bg: "whiteAlpha.500",
								borderTopRadius: "5",
								borderBottom: "3px solid",
							}}
							_hover={{
								color: "teal.500",
								bg: "whiteAlpha.500",
								borderTopRadius: "5",
								borderBottom: "3px solid",
								borderBottomColor: "teal.600",
							}}
						>
							<Heading size="sm" letterSpacing={1} textTransform="uppercase">
								{tab?.name ?? tab?.tabName}
							</Heading>
						</Tab>
					))}
				</TabList>

				<TabPanels display="flex" flexDirection="column" h="full" w="full" flex={1}>
					{tabs?.map((tab, i) => (
						<TabPanel
							key={i}
							display="flex"
							flexDirection="column"
							h="full"
							w="full"
							flex={1}
							px={0}
							pt={2}
							pb={0}
						>
							<Container
								key={"tabPanel-" + tabVal}
								maxW={tab?.maxW ?? "full"}
								display="flex"
								flexDirection={"column"}
								flexGrow={1}
								w="full"
								h="full"
								position="relative"
								rounded={5}
								bg="blackAlpha.200"
								py={0}
								px={(tab?.name ?? tab?.tabName) === "Print Preview" ? 0 : 4}
								ref={ref}
							>
								<VStack
									flex={1}
									w="full"
									h="full"
									direction="column"
									spacing={8}
									key={"Vstack-" + tabVal}
									py={tab.printPreview ? 0 : 4}
									pr={tab.printPreview ? 0 : 4}
									position="relative"
									overflow="auto"
									maxH={maxHeight}
								>
									{tab?.pages?.map((page, i) => {
										return <React.Fragment key={i}>{page}</React.Fragment>;
									})}
								</VStack>
							</Container>
						</TabPanel>
					))}
				</TabPanels>
			</ChakraTabs>
		</Container>
	);
}
