import React, { useMemo } from "react";
import { Flex, Stack, HStack, Skeleton, Spinner, Heading } from "@chakra-ui/react";
import ProgressBar from "./ProgressBar";
import Timer from "./Timer";

const exType = "virtualTable";
const exHeader = {
	title: "Loading Data...",
	subtitle: "Thank you for your patience!",
	spinner: true,
};
const exProgress = null;

export default function LoadingSection({
	type = exType,
	loadingHeader = exHeader,
	progressBar = exProgress,
	isLoaded = false,
}) {
	const progressVal = useMemo(() => progressBar?.value ?? 0, [progressBar?.value]);

	const loadingComplete = useMemo(() => isLoaded || progressVal >= 100, [isLoaded, progressVal]);

	// Set Timeout for loading time

	return (
		<>
			{!loadingComplete && (
				<HStack h="full" w="full" spacing={6} align="flex-start" flex={1} rounded={5} p={6}>
					<Stack w="full" maxW="full" flex={1} h="full" spacing={2} overflow="hidden" pb={8}>
						<Flex justify="space-between" align="flex-end" maxW="full">
							{Boolean(loadingHeader) && <LoadingHeader loadingHeader={loadingHeader} />}
							{Boolean(progressBar) && <ProgressBar progressBar={progressBar} />}
						</Flex>
						{["virtualTable", "table"].includes(type) && <LoadingTable />}
						<Flex justify="flex-end" align="flex-start" maxW="full">
							<Timer seconds={40} includeUnits={["icon", "hours", "minutes", "seconds"]} />
						</Flex>
					</Stack>
				</HStack>
			)}
		</>
	);
}

export function LoadingHeader({ loadingHeader }) {
	return (
		<HStack align="center" flex={1} justify="flex-end" lineHeight={10} maxW="full">
			<Flex flex={1} w="full" justify="flex-start" isTruncated>
				<HStack w="full" py={1} px={2} align="center" spacing={4}>
					{Boolean(loadingHeader?.spinner) && (
						<Spinner thickness="5px" speed="0.75s" emptyColor="gray.200" color="teal.600" size="xl" />
					)}
					<Stack spacing={1}>
						{Boolean(loadingHeader?.title) && (
							<Heading fontSize="2xl" letterSpacing={4} color="gray.600" textTransform="uppercase">
								{loadingHeader?.title}
							</Heading>
						)}
						{Boolean(loadingHeader?.subtitle) && (
							<Heading fontSize="md" letterSpacing={2} color="gray.500" textTransform="uppercase">
								{loadingHeader?.subtitle}
							</Heading>
						)}
					</Stack>
				</HStack>
			</Flex>
		</HStack>
	);
}

export function LoadingTable() {
	return (
		<Stack w="full" maxW="full" flex={1} h="full" spacing={0} overflow="hidden">
			<Skeleton borderTopRadius={"md"} w="full" startColor="transparent" endColor="teal.600" minH="12"></Skeleton>
			<Skeleton
				minH="container.sm"
				h="full"
				w="full"
				flex={1}
				borderBottomRadius={"md"}
				direction="column"
			></Skeleton>
		</Stack>
	);
}
