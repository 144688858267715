import React, { useState, useMemo, useCallback } from "react";
import { Center, Flex, Heading, Stack, HStack, Input } from "@chakra-ui/react";

import { formatNumber } from "../../../helperFunctions";
export default function Safety(props) {
	const contract = useMemo(() => props.contract, [props.contract]);
	const [safetyLostTimeAccidents, setSafetyLostTimeAccidents] = useState(null);
	const [safetyRecordableAccidents, setSafetyRecordableAccidents] = useState(null);
	const [safetyLostDays, setSafetyLostDays] = useState(null);

	useMemo(() => {
		setSafetyLostTimeAccidents(props.contract?.safetyLostTimeAccidents);
		setSafetyRecordableAccidents(props.contract?.safetyRecordableAccidents);
		setSafetyLostDays(props.contract?.safetyLostDays);
	}, [props.contract]);

	const updateSafetyLostTimeAccidents = useCallback(
		async (ev) => {
			let val = Boolean(ev.target.value) ? ev.target.value : "0";
			if (isNaN(parseFloat(val))) {
				val = 0;
			} else if (parseFloat(val) !== parseFloat(contract?.safetyLostTimeAccidents)) {
				val = parseFloat(val).toFixed(0);
				await contract?.update("safetyLostTimeAccidents", val);
			}
			setSafetyLostTimeAccidents(val);
		},
		[contract]
	);

	const updateSafetyRecordableAccidents = useCallback(
		async (ev) => {
			let val = Boolean(ev.target.value) ? ev.target.value : "0";
			if (isNaN(parseFloat(val))) {
				val = 0;
			} else if (parseFloat(val) !== parseFloat(contract?.safetyRecordableAccidents)) {
				val = parseFloat(val).toFixed(0);
				await contract?.update("safetyRecordableAccidents", val);
			}
			setSafetyRecordableAccidents(val);
		},
		[contract]
	);

	const updateSafetyLostDays = useCallback(
		async (ev) => {
			let val = Boolean(ev.target.value) ? ev.target.value : "0";
			if (isNaN(parseFloat(val))) {
				val = 0;
			} else if (parseFloat(val) !== parseFloat(contract?.safetyLostDays)) {
				val = parseFloat(val).toFixed(0);
				await contract?.update("safetyLostDays", val);
			}
			setSafetyLostDays(val);
		},
		[contract]
	);

	return (
		<Stack w="full" spacing={4} px={4}>
			<HStack align="center">
				<Heading
					minW="25px"
					size={"md"}
					color={"gray.500"}
					textTransform="uppercase"
					isTruncated
					letterSpacing={2}
				>
					I.
				</Heading>
				<Heading size={"md"} color={"gray.500"} textTransform="uppercase" isTruncated letterSpacing={2}>
					Safety
				</Heading>
			</HStack>

			<Center w="full">
				<Flex
					direction={{ base: "column", lg: "row" }}
					flex={1}
					w="full"
					align="center"
					justify="center"
					px={8}
				>
					<Flex direction="row" flex={5} w="full" align="center" px="4">
						<Heading
							py="1"
							fontSize={"sm"}
							letterSpacing={1}
							color={"gray.500"}
							textTransform="uppercase"
							isTruncated
							textAlign="right"
							px="3"
						>
							Lost Time Accidents
						</Heading>
						<Flex maxW="80px" minW="80px" borderColor="gray.600" borderBottomWidth="1px">
							<Input
								maxLength={3}
								key={"input-16- " + safetyLostTimeAccidents}
								autoComplete="off"
								_hover={{
									bg: "blackAlpha.50",
									cursor: "pointer",
									borderRadius: "0px",
								}}
								px="2"
								py="1"
								variant="unstyled"
								bg="white"
								color="gray.600"
								borderRadius="0px"
								_focus={{
									borderBottom: "1px solid",
									borderColor: "green.600",
								}}
								type="number"
								textAlign="center"
								placeholder={formatNumber(0)}
								defaultValue={
									!Boolean(safetyLostTimeAccidents) ||
									parseFloat(safetyLostTimeAccidents).toFixed(1) === "0.0"
										? null
										: formatNumber(safetyLostTimeAccidents)
								}
								onBlur={updateSafetyLostTimeAccidents}
							/>
						</Flex>
					</Flex>

					<Flex direction="row" flex={5} w="full" align="center" px="4">
						<Heading
							py="1"
							fontSize={"sm"}
							letterSpacing={1}
							color={"gray.500"}
							textTransform="uppercase"
							isTruncated
							textAlign="right"
							px="3"
						>
							Recordable Accidents
						</Heading>
						<Flex maxW="80px" minW="80px" borderColor="gray.600" borderBottomWidth="1px">
							<Input
								maxLength={3}
								key={"input-17- " + safetyRecordableAccidents}
								autoComplete="off"
								_hover={{
									bg: "blackAlpha.50",
									cursor: "pointer",
									borderRadius: "0px",
								}}
								px="2"
								py="1"
								variant="unstyled"
								bg="white"
								color="gray.600"
								borderRadius="0px"
								_focus={{
									borderBottom: "1px solid",
									borderColor: "green.600",
								}}
								type="number"
								textAlign="center"
								placeholder={formatNumber(0)}
								defaultValue={
									!Boolean(safetyRecordableAccidents) ||
									parseFloat(safetyRecordableAccidents).toFixed(1) === "0.0"
										? null
										: formatNumber(safetyRecordableAccidents)
								}
								onBlur={updateSafetyRecordableAccidents}
							/>
						</Flex>
					</Flex>

					<Flex direction="row" flex={5} w="full" align="center" px="4">
						<Heading
							py="1"
							fontSize={"sm"}
							letterSpacing={1}
							color={"gray.500"}
							textTransform="uppercase"
							isTruncated
							textAlign="right"
							px="3"
						>
							Number of Lost Days
						</Heading>
						<Flex maxW="80px" minW="80px" borderColor="gray.600" borderBottomWidth="1px">
							<Input
								maxLength={3}
								key={"input-18- " + safetyLostDays}
								autoComplete="off"
								_hover={{
									bg: "blackAlpha.50",
									cursor: "pointer",
									borderRadius: "0px",
								}}
								px="2"
								py="1"
								variant="unstyled"
								bg="white"
								color="gray.600"
								borderRadius="0px"
								_focus={{
									borderBottom: "1px solid",
									borderColor: "green.600",
								}}
								type="number"
								textAlign="center"
								placeholder={formatNumber(0)}
								defaultValue={
									!Boolean(safetyLostDays) || parseFloat(safetyLostDays).toFixed(1) === "0.0"
										? null
										: formatNumber(safetyLostDays)
								}
								onBlur={updateSafetyLostDays}
							/>
						</Flex>
					</Flex>
				</Flex>
			</Center>
		</Stack>
	);
}
