import React, { useMemo } from "react";
import { Flex, Stack, Heading, Text } from "@chakra-ui/react";

export default function TabPanelContainer({
	bg = "white",
	position = "relative",
	heading,
	subheading,
	description,
	children,
}) {
	const showHeader = useMemo(() => Boolean(heading || subheading || description), [description, heading, subheading]);
	return (
		<Flex direction="column" w="full" minH="sm" flex={1} rounded={"md"} position="relative">
			{Boolean(showHeader) && (
				<Stack spacing={0} p={4} position={position} top={0} left={0}>
					{Boolean(heading) && (
						<Heading textTransform="uppercase" size="md" color="gray.400" letterSpacing={3}>
							{heading}
						</Heading>
					)}
					{Boolean(subheading) && (
						<Heading textTransform="uppercase" size="lg" color="gray.500" letterSpacing={3}>
							{subheading}
						</Heading>
					)}
					{Boolean(description) && (
						<Text fontSize="md" color="gray.400" letterSpacing={1}>
							{description}
						</Text>
					)}
				</Stack>
			)}

			{children}
		</Flex>
	);
}
