import React from "react";
import { Flex, Text, Heading, Stack } from "@chakra-ui/react";

export default function MFRLabelValuePair(props) {
	const [value, setValue] = React.useState(props.value ?? props.defaultValue);

	const handleOnChange = (ev) => {
		let value = ev.target.value;
		setValue(value);
	};

	let forwardingProps = { ...props };
	forwardingProps.label = null;
	forwardingProps.value = value;
	forwardingProps.onChange = handleOnChange;
	forwardingProps.editable = null;

	if (props.direction !== "vertical") {
		return (
			<Stack
				position="relative"
				direction="row"
				align="flex-end"
				spacing={1}
				flex={props.flex}
				flexBasis={props.flexBasis}
				{...props.containerProps}
			>
				<Heading
					py="1"
					flex={1}
					fontSize={"sm"}
					letterSpacing={1}
					color={props.color ?? "teal.600"}
					textTransform="uppercase"
					isTruncated={props.isTruncated ?? false}
					textAlign={props.labelProps?.textAlign}
					whiteSpace={props.whiteSpace ?? "nowrap"}
					px="3"
				>
					{props.label}:
				</Heading>

				<Flex maxW="150px" minW="150px" borderColor="gray.600" borderBottomWidth="1px" align="flex-end">
					<Text
						w="full"
						fontWeight={props.fontWeight ?? "normal"}
						fontSize="md"
						color={props.textColor ?? "gray.600"}
						_hover={{
							color: props.onClick ? "blue.600" : "",
							textDecoration: props.onClick ? "underline" : "",
							cursor: props.onClick ? "pointer" : "",
						}}
						onClick={(ev) => {
							if (props.onClick) {
								props.onClick(ev);
							}
						}}
						align={props.valueAlign}
						whiteSpace={props.whiteSpace}
					>
						{props.value}
					</Text>
				</Flex>
				<Flex position="relative" minW="50px" maxW="50px">
					{props.sideNote && (
						<Text pl="1" color={"gray.500"}>
							{props.sideNote}
						</Text>
					)}
				</Flex>
			</Stack>
		);
	}
}
