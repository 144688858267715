import { formatDate, log } from "../../../helperFunctions";
import moment from "moment";
import ProjectsAPI from "../../../APIs/ProjectsAPI";

export default class ManpowerProjectionsByMth {
	constructor(projection) {
		this.projectsAPI = new ProjectsAPI();
		this.queryData = projection?.queryData ?? projection?.QueryData ?? "ManpowerProjectionsByMth";
		this.jccmKeyID = projection?.jccmKeyID ?? projection?.JCCMKeyID ?? null;
		this.jcco = projection?.jcco ?? projection?.JCCo ?? "1";
		this.contract = projection?.contract ?? projection?.Contract?.trim() ?? null;
		this.year = moment(new Date(formatDate(projection?.mth ?? projection?.Mth) ?? null)).format("YYYY");
		this.mth = formatDate(projection?.mth ?? projection?.Mth) ?? null;
		this.title = this.getTitle();
		this.workDays = projection?.workDays ?? projection?.WorkDays ?? null;
		this.workHours = projection?.workHours ?? projection?.WorkDays * 8 ?? null;

		this.projKeyID = projection?.projKeyID ?? projection?.ProjKeyID ?? null;
		this.editable = this.getEditable();

		this.projCrewSizeOFFC_delta = projection?.projCrewSizeOFFC_delta ?? projection?.ProjCrewSizeOFFC ?? 0;
		this.projCrewSizeDETL_delta = projection?.projCrewSizeDETL_delta ?? projection?.ProjCrewSizeDETL ?? 0;
		this.projCrewSizePLUM_delta = projection?.projCrewSizePLUM_delta ?? projection?.ProjCrewSizePLUM ?? 0;
		this.projCrewSizeHVAC_delta = projection?.projCrewSizeHVAC_delta ?? projection?.ProjCrewSizeHVAC ?? 0;
		this.projCrewSizePIPE_delta = projection?.projCrewSizePIPE_delta ?? projection?.ProjCrewSizePIPE ?? 0;
		this.projCrewSizeFUEL_delta = projection?.projCrewSizeFUEL_delta ?? projection?.ProjCrewSizeFUEL ?? 0;
		this.projCrewSizePROC_delta = projection?.projCrewSizePROC_delta ?? projection?.ProjCrewSizePROC ?? 0;
		this.projCrewSizeFIRE_delta = projection?.projCrewSizeFIRE_delta ?? projection?.ProjCrewSizeFIRE ?? 0;
		this.projCrewSizeSITE_delta = projection?.projCrewSizeSITE_delta ?? projection?.ProjCrewSizeSITE ?? 0;
		this.projCrewSizeFABR_delta = projection?.projCrewSizeFABR_delta ?? projection?.ProjCrewSizeFABR ?? 0;
		this.projCrewSizeSTRT_delta = projection?.projCrewSizeSTRT_delta ?? projection?.ProjCrewSizeSTRT ?? 0;
		this.projCrewSizeCHNG_delta = projection?.projCrewSizeCHNG_delta ?? projection?.ProjCrewSizeCHNG ?? 0;

		this.projCrewSizeFIELD_delta = projection?.projCrewSizeFIELD_delta ?? this.getProjCrewSizeFIELD_delta();
		this.projCrewSizeTOTAL_delta = projection?.projCrewSizeTOTAL_delta ?? this.getProjCrewSizeTOTAL_delta();

		this.projHoursFIELD_delta = projection?.projHoursFIELD_delta ?? this.getProjHoursFIELD_delta();
		this.projHours_delta = projection?.projHours_delta ?? this.getProjHours_delta();

		this.projLaborCostFIELD_delta = projection?.projLaborCostFIELD_delta ?? this.getProjLaborCostFIELD_delta();

		this.rateToComplete = projection?.rateToComplete ?? 0;
		this.projLaborCost_delta = projection?.projLaborCost_delta ?? this.getProjLaborCost_delta();

		this.projMatCost_delta = projection?.projMatCost_delta ?? projection?.ProjMaterialCost ?? 0;
		this.projSubCost_delta = projection?.projSubCost_delta ?? projection?.ProjSubCost ?? 0;
		this.projEquipCost_delta = projection?.projEquipCost_delta ?? projection?.ProjEquipCost ?? 0;
		this.projOtherJobCost_delta = projection?.projOtherJobCost_delta ?? projection?.ProjOtherJobCost ?? 0;
		this.projPCOCost_delta = projection?.projPCOCost_delta ?? projection?.ProjPCOCost ?? 0;
		this.projContCost_delta = projection?.projContCost_delta ?? projection?.ProjContingencyCost ?? 0;
		this.projBilledAmt_delta = projection?.projBilledAmt_delta ?? projection?.ProjBilling ?? 0;

		this.projCost_delta = projection?.projCost_delta ?? this.getProjCost_delta();
		this.projBilledVsCost_delta = projection?.projBilledVsCost_delta ?? this.getProjBilledVsCost_delta();
	}

	getTitle() {
		let title = moment(new Date(this.mth)).format("MMMM YYYY");
		return title;
	}

	getEditable() {
		let currentMth = moment(new Date()).startOf("month");
		let thisMth = moment(new Date(this.mth)).startOf("month");
		// if (thisMth.diff(currentMth) >= 0) {
		// 	log("YES", thisMth.format("MMM YYYY"));
		// } else {
		// 	log("NO", thisMth.format("MMM YYYY"));
		// }
		return Boolean(thisMth.diff(currentMth) >= 0);
	}

	/*****************************************************************/
	/******************* GENERAL MATH FORMULAS *********************/
	/**************************************************************/

	// GENERAL MATH FUNCTIONS
	getPct(numerator = 0, denominator = 0) {
		let pct = 0;
		if (numerator === null || denominator === null || isNaN(numerator) || isNaN(denominator)) {
			pct = null;
		} else if (parseFloat(denominator) !== 0) {
			pct = (parseFloat(numerator ?? 0) / parseFloat(denominator)) * 100;
		}
		return pct;
	}

	getRate(dollars = 0, hours = 0) {
		let rate = 0;
		if (dollars === null || hours === null || isNaN(dollars) || isNaN(hours)) {
			rate = null;
		} else if (parseFloat(hours) !== 0) {
			rate = parseFloat(dollars ?? 0) / parseFloat(hours);
		}
		return rate;
	}

	getProjCrewSizeFIELD_delta() {
		return (
			parseFloat(this.projCrewSizePLUM_delta ?? 0) +
			parseFloat(this.projCrewSizeHVAC_delta ?? 0) +
			parseFloat(this.projCrewSizePIPE_delta ?? 0) +
			parseFloat(this.projCrewSizeFUEL_delta ?? 0) +
			parseFloat(this.projCrewSizePROC_delta ?? 0) +
			parseFloat(this.projCrewSizeFIRE_delta ?? 0) +
			parseFloat(this.projCrewSizeSITE_delta ?? 0) +
			parseFloat(this.projCrewSizeSTRT_delta ?? 0) +
			parseFloat(this.projCrewSizeCHNG_delta ?? 0)
		);
	}

	getProjCrewSizeTOTAL_delta() {
		return (
			parseFloat(this.projCrewSizeOFFC_delta ?? 0) +
			parseFloat(this.projCrewSizeDETL_delta ?? 0) +
			parseFloat(this.projCrewSizePLUM_delta ?? 0) +
			parseFloat(this.projCrewSizeHVAC_delta ?? 0) +
			parseFloat(this.projCrewSizePIPE_delta ?? 0) +
			parseFloat(this.projCrewSizeFUEL_delta ?? 0) +
			parseFloat(this.projCrewSizePROC_delta ?? 0) +
			parseFloat(this.projCrewSizeFIRE_delta ?? 0) +
			parseFloat(this.projCrewSizeSITE_delta ?? 0) +
			parseFloat(this.projCrewSizeSTRT_delta ?? 0) +
			parseFloat(this.projCrewSizeFABR_delta ?? 0) +
			parseFloat(this.projCrewSizeCHNG_delta ?? 0)
		);
	}

	getProjHoursFIELD_delta() {
		return this.getProjCrewSizeFIELD_delta() * parseFloat(this.workHours ?? 0);
	}

	getProjHours_delta() {
		return this.getProjCrewSizeTOTAL_delta() * parseFloat(this.workHours ?? 0);
	}

	getProjLaborCostFIELD_delta() {
		return this.getProjHoursFIELD_delta() * this.rateToComplete;
	}

	getProjLaborCost_delta() {
		return this.getProjHours_delta() * this.rateToComplete;
	}

	getProjCost_delta() {
		return (
			this.getProjLaborCost_delta() +
			parseFloat(this.projMatCost_delta ?? 0) +
			parseFloat(this.projSubCost_delta ?? 0) +
			parseFloat(this.projEquipCost_delta ?? 0) +
			parseFloat(this.projOtherJobCost_delta ?? 0) +
			parseFloat(this.projPCOCost_delta ?? 0) +
			parseFloat(this.projContCost_delta ?? 0)
		);
	}

	getProjBilledVsCost_delta() {
		return parseFloat(this.projBilledAmt_delta ?? 0) - this.getProjCost_delta();
	}

	getTotalLaborActualCost_delta() {
		return parseFloat(this.laborActualCost_delta ?? 0) + parseFloat(this.subLabActualCost_delta ?? 0);
	}

	getActualLaborRate_delta() {
		return this.getRate(this.getTotalLaborActualCost_delta(), this.actualHours_delta);
	}

	getBilledVsCost_delta() {
		return parseFloat(this.projBilledAmt_delta ?? 0) - this.getProjCost_delta();
	}

	getRateToComplete(contractTotalsByMth = []) {
		if (contractTotalsByMth.length > 0) {
			let monthTotal =
				contractTotalsByMth?.filter(
					(p) =>
						moment(new Date(p.mth)).format("MM/YY") === moment(new Date(this.mth)).format("MM/YY") &&
						p.jccmKeyID === this.jccmKeyID
				)[0] ?? null;

			let thisContractTotalsByMth = contractTotalsByMth?.filter((p) => p.jccmKeyID === this.jccmKeyID) ?? [];
			let months = thisContractTotalsByMth?.map((d) => moment(new Date(d.mth))),
				maxMth = moment.max(months);

			let maxMthTotal =
				thisContractTotalsByMth?.filter(
					(p) =>
						moment(new Date(p.mth)).format("MM/YY") === moment(new Date(maxMth)).format("MM/YY") &&
						p.jccmKeyID === this.jccmKeyID
				)[0] ?? null;

			if (Boolean(monthTotal)) {
				this.rateToComplete = monthTotal?.getRateToComplete();
				this.projCrewSizeFIELD_delta = this.getProjCrewSizeFIELD_delta();
				this.projCrewSizeTOTAL_delta = this.getProjCrewSizeTOTAL_delta();
				this.projHoursFIELD_delta = this.getProjHoursFIELD_delta();
				this.projHours_delta = this.getProjHours_delta();
				this.projLaborCostFIELD_delta = this.getProjLaborCostFIELD_delta();
				this.projLaborCost_delta = this.getProjLaborCost_delta();
				this.projCost_delta = this.getProjCost_delta();
				this.projBilledVsCost_delta = this.getProjBilledVsCost_delta();
			} else if (Boolean(maxMthTotal) && moment(new Date(this.mth)).diff(maxMth) > 0) {
				this.rateToComplete = maxMthTotal?.getRateToComplete();
				this.projCrewSizeFIELD_delta = this.getProjCrewSizeFIELD_delta();
				this.projCrewSizeTOTAL_delta = this.getProjCrewSizeTOTAL_delta();
				this.projHoursFIELD_delta = this.getProjHoursFIELD_delta();
				this.projHours_delta = this.getProjHours_delta();
				this.projLaborCostFIELD_delta = this.getProjLaborCostFIELD_delta();
				this.projLaborCost_delta = this.getProjLaborCost_delta();
				this.projCost_delta = this.getProjCost_delta();
				this.projBilledVsCost_delta = this.getProjBilledVsCost_delta();
			}
		}
	}

	async updateDB(updates) {
		let result = await this.projectsAPI.UpdateManpowerProjection(this.projKeyID, updates);
		log("DATABASE RESULT", result.value);
	}

	getDBAttribute(attribute) {
		if (attribute === "projCrewSizeOFFC_delta") {
			return "CrewSizeOFFC";
		} else if (attribute === "projCrewSizeDETL_delta") {
			return "CrewSizeDETL";
		} else if (attribute === "projCrewSizePLUM_delta") {
			return "CrewSizePLUM";
		} else if (attribute === "projCrewSizeHVAC_delta") {
			return "CrewSizeHVAC";
		} else if (attribute === "projCrewSizePIPE_delta") {
			return "CrewSizePIPE";
		} else if (attribute === "projCrewSizeFUEL_delta") {
			return "CrewSizeFUEL";
		} else if (attribute === "projCrewSizePROC_delta") {
			return "CrewSizePROC";
		} else if (attribute === "projCrewSizeFIRE_delta") {
			return "CrewSizeFIRE";
		} else if (attribute === "projCrewSizeSITE_delta") {
			return "CrewSizeSITE";
		} else if (attribute === "projCrewSizeFABR_delta") {
			return "CrewSizeFABR";
		} else if (attribute === "projCrewSizeSTRT_delta") {
			return "CrewSizeSTRT";
		} else if (attribute === "projCrewSizeCHNG_delta") {
			return "CrewSizeCHNG";
		} else if (attribute === "projMatCost_delta") {
			return "ProjMaterialCost";
		} else if (attribute === "projSubCost_delta") {
			return "ProjSubCost";
		} else if (attribute === "projEquipCost_delta") {
			return "ProjEquipCost";
		} else if (attribute === "projOtherJobCost_delta") {
			return "ProjOtherJobCost";
		} else if (attribute === "projPCOCost_delta") {
			return "ProjPCOCost";
		} else if (attribute === "projContCost_delta") {
			return "ProjContingencyCost";
		} else if (attribute === "projBilledAmt_delta") {
			return "ProjBilling";
		}
	}

	async update(attribute, value) {
		let val = parseFloat(value.replace("$", "").replace(",", ""));
		if (!Boolean(val) || isNaN(val)) {
			val = 0;
		}
		this[attribute] = val;
		this.projCrewSizeFIELD_delta = this.getProjCrewSizeFIELD_delta();
		this.projCrewSizeTOTAL_delta = this.getProjCrewSizeTOTAL_delta();
		this.projHoursFIELD_delta = this.getProjHoursFIELD_delta();
		this.projHours_delta = this.getProjHours_delta();
		this.projLaborCostFIELD_delta = this.getProjLaborCostFIELD_delta();
		this.projLaborCost_delta = this.getProjLaborCost_delta();
		this.projCost_delta = this.getProjCost_delta();
		this.projBilledVsCost_delta = this.getProjBilledVsCost_delta();

		let dbAttribute = this.getDBAttribute(attribute);
		let update = { [dbAttribute]: val };
		await this.updateDB(update);
	}

	// async createProjection(co, contract, attribute, value) {
	// 	let mth = this.mth;
	// 	this[attribute] = value;
	// 	let update = { Co: co, Contract: contract, Mth: mth };
	// 	await this.insertDB(update, attribute, value);
	// }
	// async insertDB(updates, attribute, value) {
	// 	log("DATABASE INSERT", updates);
	// 	let updateResult = await this.projectsAPI.InsertManpowerProjectionRow(updates); //Commented out from ProjectsAPI on 6/14/2024
	// 	this.projKeyID = updateResult.value[0].KeyID;
	// 	await this.updateProjection(attribute, value);
	// }
}
