import React, { useState, useMemo, useRef, useCallback } from "react";
import { Box, Flex, useBoolean, useDimensions } from "@chakra-ui/react";
import TopNav from "./TopNav";
import SideBar from "./SideBar";
import AppRoutes from "../routes/AppRoutes";
import Joyride from "react-joyride";
import { JOYRIDE_APP } from "../../../joyride";
import SideBarButton from "../../../modules/App/components/SideBarButton";

export default function AppContainer() {
	const appRef = useRef(null);

	const [sideBarIsOpen, setSideBarIsOpen] = useBoolean(true);
	const [appWidth, setAppWidth] = useState(null);
	const dimensions = useDimensions(appRef, true);
	var sideBarW = sideBarIsOpen ? (appWidth < 700 ? "100vw" : "2xs") : 0;

	const [runJoyride, setRunJoyride] = useState(false);
	const joyrideSteps = JOYRIDE_APP;

	const startJoyride = () => {
		setRunJoyride(true);
	};

	const joyrideCallback = (joyride) => {
		if (joyride.action === "reset" || joyride.action === "close" || joyride.action === "skip") {
			setRunJoyride(false);
		}
	};

	const toggleSideBar = useCallback(
		(appWidth) => {
			if (appWidth >= 992 && !sideBarIsOpen) {
				setSideBarIsOpen.on();
			} else if (appWidth < 700 && sideBarIsOpen) {
				setSideBarIsOpen.off();
			}
			setAppWidth(appWidth);
		},
		[setSideBarIsOpen, sideBarIsOpen]
	);

	useMemo(() => {
		let currWidth = dimensions?.contentBox?.width;
		if (!Boolean(appWidth) && Boolean(currWidth)) {
			setAppWidth(currWidth);
		} else if (Boolean(appWidth) && Boolean(currWidth) && appWidth !== currWidth) {
			toggleSideBar(currWidth);
		}
	}, [appWidth, dimensions?.contentBox?.width, toggleSideBar]);

	return (
		<Box bg="white" minH="100vh" w="full" ref={appRef} postion="relative">
			<Joyride
				run={runJoyride}
				callback={joyrideCallback}
				continuous
				showProgress={true}
				showSkipButton={true}
				spotlightClicks={true}
				steps={joyrideSteps}
				scrollToFirstStep={true}
			/>

			<TopNav
				sideBarButton={<SideBarButton sideBarIsOpen={sideBarIsOpen} setSideBarIsOpen={setSideBarIsOpen} />}
				startJoyride={startJoyride}
			/>
			<SideBar
				sideBarButton={<SideBarButton sideBarIsOpen={sideBarIsOpen} setSideBarIsOpen={setSideBarIsOpen} />}
				sideBarIsOpen={sideBarIsOpen}
				sideBarWidth={sideBarW}
			/>

			<Flex minH="inherit">
				<Box
					key="ghostMenuDrawer"
					id="ghostMenuDrawer"
					minW={0}
					w={sideBarW}
					display={sideBarIsOpen ? "flex" : "none"}
				></Box>

				<Box flex={1} overflow="auto">
					<Flex flex={1} direction="column" w="full" h="full" bg="white">
						<Box flex={1} w="full" h="full">
							<AppRoutes />
						</Box>
					</Flex>
				</Box>
			</Flex>
		</Box>
	);
}
