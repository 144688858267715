import React from "react";
import {
	Flex,
	Center,
	Checkbox,
	Text,
	Tabs,
	Tab,
	TabList,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Button,
	HStack,
	Portal,
} from "@chakra-ui/react";
import VirtualTableV2 from "../../../core/ReactTable/components/VirtualTableV2";
import VirtualListV2 from "../../../core/ReactTable/components/VirtualListV2";
import PayrollUploadBatchModal from "../components/PayrollUploadBatchModal";
import PayrollUploadTableRow from "../components/PayrollUploadTableRow";
import PayrollAPI from "../../../APIs/PayrollAPI";
import TimecardJobPhaseClassLegacy from "../classes/TimecardJobPhaseClassLegacy";

import { portalUser, ppmUser } from "../../../App";
import { mergeSort, searchFilter, log, getDeviceType } from "../../../helperFunctions";
import { v4 } from "uuid";

export default class PayrollUploadPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			payrollAPI: new PayrollAPI(),
			payPeriodInfo: this.props.payPeriods ?? null,
			prEndDate: this.props.prEndDate ?? portalUser.user?.prEndDate,
			payPeriods: this.props.payPeriods,
			resourceGroups: null,
			activeResourceGroup: "",
			members: null,
			filteredMembers: null,
			ready: false,
			selectedMembers: [],
			tabs: {},
			reviewLevel: this.props.reviewLevel ?? null,
			filters: {},
			sortAttribute: "employeeName",
			sortOrder: 1,
			tempCount: 0,
			timecardJobs: this.props?.timecardJobs ?? [],
			// dataView: 'table',
			resourceGroupFilterVal: "",
			batchModalIsOpen: false,
			tableDataChanged: false,
			tabVal: 0,
			tableUpdateID: null,
			filtering: false,
			allExpanded: false,
		};

		this.init = this.init.bind(this);
		this.selectMember = this.selectMember.bind(this);
		this.approveTime = this.approveTime.bind(this);
		this.addFilter = this.addFilter.bind(this);
		this.filteredOut = this.filteredOut.bind(this);
		this.handleTabChange = this.handleTabChange.bind(this);
		this.sortTable = this.sortTable.bind(this);
		this.getSortValue = this.getSortValue.bind(this);
		this.getAvailableJobPhases = this.getAvailableJobPhases.bind(this);
		// this.changeDataView = this.changeDataView.bind(this);
		this.clearFilters = this.clearFilters.bind(this);
		this.handleGroupSearchBar = this.handleGroupSearchBar.bind(this);
		this.addBatch = this.addBatch.bind(this);
		this.openBatchModal = this.openBatchModal.bind(this);
		this.closeBatchModal = this.closeBatchModal.bind(this);
		this.changeAck = this.changeAck.bind(this);
		this.clearSelectedMembers = this.clearSelectedMembers.bind(this);
		this.handleMenuTabChange = this.handleMenuTabChange.bind(this);
		// this.updateViewWidth = this.updateViewWidth.bind(this);
		this.selectAll = this.selectAll.bind(this);
		this.filterMembers = this.filterMembers.bind(this);
		this.clearFiltersQuick = this.clearFiltersQuick.bind(this);
		this.expandMember = this.expandMember.bind(this);
		this.triggerTableUpdate = this.triggerTableUpdate.bind(this);
		this.approveTime = this.approveTime.bind(this);
		this.insertMembers = this.insertMembers.bind(this);
		this.removeFilter = this.removeFilter.bind(this);
		this.approveSelected = this.approveSelected.bind(this);
	}

	componentDidMount() {
		//this.getResourceGroupMembers();
		this.init();
	}

	componentDidUpdate() {
		if (this.state.members === null) {
			this.init();
		}

		if (this.props.prEndDate !== this.state.prEndDate) {
			this.setState(
				{
					prEndDate: this.props.prEndDate,
				},
				() => {
					this.init();
				}
			);
		}
	}

	init() {
		this.insertMembers();
	}

	insertMembers() {
		if (this.props.resourceGroup?.members) {
			let members = this.props.members;
			let reviewLevel = this.props.reviewLevel ?? this.props.resourceGroup?.reviewLevel;
			let tabs = {};

			for (let i = 0; i < members?.length; i++) {
				if (reviewLevel === "Review") {
					tabs["PRCO " + members[i].prco + " GROUP " + members[i].prGroup] = [
						members[i].prco,
						members[i].prGroup,
					];
				} else {
					let tabStr = members[i].tradeID === 1 ? members[i].deptTag : members[i].trade;
					tabs[tabStr] = [members[i].tradeID === 1 ? "deptTag" : "trade", tabStr];
				}
			}
			let orderedTabs = mergeSort(Object.keys(tabs), { type: "string" }, 0);
			let orderedTabObj = {};

			for (let i = 0; i < orderedTabs.length; i++) {
				orderedTabObj[orderedTabs[i]] = tabs[orderedTabs[i]];
			}

			let tabArr = Object.keys(orderedTabObj);
			let tabVal = 0;

			if (reviewLevel !== "Review") {
				for (let i = 0; i < tabArr.length; i++) {
					if (tabArr[i] === ppmUser.deptTag) {
						tabVal = i + 1;
						break;
					}
				}
			}

			// console.log("this.props.members", this.props.members);

			this.setState(
				{
					members: this.props.members,
					filteredMembers: this.props.members,
					tabs: orderedTabObj,
					reviewLevel: reviewLevel,
					tabVal: tabVal,
					tableUpdateID: v4(),
				},
				() => {
					this.handleTabChange(tabVal, true);
				}
			);
		}
	}

	async getAvailableJobPhases(job) {
		let timecardJobs = this.state.timecardJobs;
		let phases = await this.state.payrollAPI.GetTimecardJobPhases(job.job, job.jcco);
		if (phases.status === 200) {
			phases = phases.value;
		}
		if (phases.status === 400) {
			if (job.job === "N/A") {
				phases = [{ key: "N/A", value: "N/A" }];
			}
		}

		for (let i = 0; i < phases.length; i++) {
			phases[i] = new TimecardJobPhaseClassLegacy(phases[i]);
		}

		for (let i = 0; i < timecardJobs.length; i++) {
			if (job.keyId === timecardJobs[i].keyId) {
				timecardJobs[i].phases = phases;
				break;
			}
		}
		this.setState({ timecardJobs: timecardJobs });
	}

	selectMember(keyID) {
		let selectedMembers = this.state.selectedMembers;
		let selectedMemberIndex = selectedMembers.indexOf(keyID);

		if (selectedMemberIndex === -1) {
			selectedMembers.push(keyID);
		} else {
			selectedMembers.splice(selectedMemberIndex, 1);
		}

		this.setState({
			selectedMembers: selectedMembers,
		});
	}

	clearSelectedMembers() {
		let selectedMembers = this.state.selectedMembers;
		for (let i = selectedMembers.length - 1; i >= 0; i--) {
			this.selectMember(selectedMembers[i]);
		}
	}

	handleTabChange(index, init = false) {
		let tabs = this.state.tabs;
		let tabArr = Object.keys(tabs);
		let reviewLevel = this.props.reviewLevel ?? this.props.resourceGroup?.reviewLevel;

		if (index === 0 && this.props.reviewLevel !== "Review") {
			this.addFilter(null, "deptTag", "");
		} else {
			let deptIndex = index;
			if (this.props.reviewLevel !== "Review") {
				deptIndex--;
			}
			let tabValue = tabs[tabArr[deptIndex]];
			if (typeof tabValue === "object") {
				if (reviewLevel === "Review") {
					this.addFilter(null, "prco", tabValue[0]);
					this.addFilter(null, "prGroup", tabValue[1]);
				} else {
					this.removeFilter(null, "trade");
					this.removeFilter(null, "deptTag");
					this.addFilter(null, tabValue[0], tabValue[1]);
				}
			} else {
				this.addFilter(null, "deptTag", tabValue);
			}
		}
		if (!init) {
			this.setState(
				{
					selectedMembers: [],
					tabVal: index,
				},
				() => {
					this.triggerTableUpdate();
				}
			);
		}
	}

	addFilter(ev, name, value) {
		if (ev !== null) {
			name = ev.target.name;
			value = ev.target.value;
		}
		let filters = this.state.filters;

		filters[name] = value;
		this.setState({
			filters: filters,
			filtering: true,
		});
		this.filterMembers();
	}

	removeFilter(ev, name) {
		if (ev !== null) {
			name = ev.target.name;
		}
		let filters = this.state.filters;

		delete filters[name];
		this.setState(
			{
				filters: filters,
				filtering: true,
			},
			() => {
				this.filterMembers();
			}
		);
	}

	filterMembers() {
		let filteredMembers = [];
		let members = this.state.members;

		for (let i = 0; i < members?.length; i++) {
			if (this.props.reviewLevel === "Review") {
				//members[i]?.timecard?.hasExceptions();
				if (
					!this.filteredOut(members[i]) &&
					members[i]?.timecard?.isTimePosted() === Boolean(this.props.showPosted)
				) {
					filteredMembers.push(members[i]);
				}
			} else {
				if (!this.filteredOut(members[i])) {
					filteredMembers.push(members[i]);
				}
			}
		}

		this.setState({
			filteredMembers: filteredMembers,
		});
		this.triggerTableUpdate();
	}

	clearFilters() {
		this.setState({ filters: {} });
		setTimeout(() => {
			this.filterMembers();
		}, 400);
	}

	isFilteredOut(member, attr, filterVal) {
		// let filteredOut = false;
		if (typeof filterVal === "string") {
			filterVal = filterVal?.toLowerCase();
		}

		if (Boolean(member[attr])) {
			let compareVal = member[attr];
			if (typeof compareVal === "string") {
				compareVal = compareVal?.toLowerCase();

				// Temp fix for precon filtering
				if (attr === "deptTag" && compareVal !== filterVal) {
					return true;
				} else if (!compareVal?.includes(filterVal)) {
					return true;
				}
			} else if (typeof compareVal === "number") {
				filterVal = parseInt(filterVal);
				if (isNaN(filterVal)) {
					return true;
				}
				if (!(compareVal === filterVal)) {
					return true;
				}
			}
		} else {
			if (member.timecard?.timecardHeader[attr] !== undefined) {
				if (attr.includes("approverUID")) {
					if (parseInt(filterVal) === 0) {
						if (member.timecard?.timecardHeader[attr] !== null) {
							return true;
						}
					} else if (parseInt(filterVal) === 1) {
						if (member.timecard?.timecardHeader[attr] === null) {
							return true;
						}
					} else if (parseInt(filterVal) === -1) {
						return false;
					}
				}

				let compareVal = member.timecard?.timecardHeader[attr];

				if (typeof compareVal === "string") {
					compareVal = compareVal?.toLowerCase();
					// Temp fix for precon filtering
					if (attr === "deptTag" && compareVal !== filterVal) {
						return true;
					} else if (!compareVal?.includes(filterVal)) {
						return true;
					}
				} else if (typeof compareVal === "number") {
					if (!(compareVal === filterVal)) {
						return true;
					}
				}
			}
			if (attr === "reg" || attr === "ot" || attr === "dt" || attr === "pto" || attr === "total") {
				let operator = filterVal.replace(/([><=]{1,2})-(\d*)/gm, "$1");
				filterVal = parseFloat(filterVal.replace(/([><=]{1,2})-(\d*)/gm, "$2"));
				let colValue = 0;
				if (attr === "reg") {
					colValue = member.timecard?.getTotalTimeByEarnCode(1);
				}
				if (attr === "ot") {
					colValue = member.timecard?.getTotalTimeByEarnCode(2);
				}
				if (attr === "dt") {
					colValue = member.timecard?.getTotalTimeByEarnCode(3);
				}
				if (attr === "pto") {
					colValue =
						member.timecard?.getTotalTimeByEarnCode(4) +
						member.timecard?.getTotalTimeByEarnCode(5) +
						member.timecard?.getTotalTimeByEarnCode(6);
				}
				if (attr === "total") {
					colValue = member.timecard?.getWeekTotal();
				}

				if (!isNaN(filterVal) && !isNaN(colValue)) {
					switch (operator) {
						case "!=":
							if (colValue === filterVal) {
								return true;
							}
							break;
						case "==":
							if (colValue !== filterVal) {
								return true;
							}
							break;
						case ">=":
							if (!(colValue >= filterVal)) {
								return true;
							}
							break;
						case ">":
							if (!(colValue > filterVal)) {
								return true;
							}
							break;
						case "<":
							if (!(colValue < filterVal)) {
								return true;
							}
							break;
						case "<=":
							if (!(colValue <= filterVal)) {
								return true;
							}
							break;
						default:
							break;
					}
				}
			}

			if (attr === "notes") {
				switch (parseInt(filterVal)) {
					case 0:
						if (member.timecard?.hasNotes()) {
							return true;
						}
						break;
					case 1:
						if (!member.timecard?.hasNotes()) {
							return true;
						}
						break;
					default:
					// do nothing
				}
			}

			if (attr === "status") {
				if (member.timecard?.getStatusLevel() !== parseInt(filterVal) && parseInt(filterVal) !== -1) {
					return true;
				}
			}

			if (attr === "approvedBy") {
				let approverUID =
					member.timecard?.timecardHeader?.approverUID1 ?? member.timecard?.timecardHeader?.approverUID2;

				if (!Boolean(approverUID)) {
					return true;
				}
				let approvedBy = this.state.members?.find(({ employeeUID }) => employeeUID === approverUID)?.name;

				if (!approvedBy?.toLowerCase()?.includes(filterVal?.toLowerCase())) {
					return true;
				}
				log("Filter Approved By", [approverUID, approvedBy, member, filterVal]);
			}
		}

		return false;
	}

	filteredOut(member) {
		let filters = this.state.filters;
		let filteredOut = false;
		//let filterAttributes = Object.keys(filters);
		if (Object.keys(filters).length === 0) {
			return filteredOut;
		}

		for (let attr in filters) {
			if (filters[attr] instanceof Array) {
				for (let i = 0; i < filters[attr].length; i++) {
					let filterVal = filters[attr][i];
					filteredOut = this.isFilteredOut(member, attr, filterVal);
					if (!filteredOut) {
						break;
					}
				}
				if (filteredOut) {
					break;
				}
			} else {
				let filterVal = filters[attr];
				if (filterVal === "" || filterVal === undefined) {
					continue;
				}
				filteredOut = this.isFilteredOut(member, attr, filterVal);
				if (filteredOut) {
					break;
				}
			}
		}
		return filteredOut;
	}

	getSortValue(member, attr) {
		if (attr === "reg") {
			return member.timecard?.getTotalTimeByEarnCode(1);
		}
		if (attr === "ot") {
			return member.timecard?.getTotalTimeByEarnCode(2);
		}
		if (attr === "dt") {
			return member.timecard?.getTotalTimeByEarnCode(3);
		}
		if (attr === "pto") {
			return (
				member.timecard?.getTotalTimeByEarnCode(4) +
				member.timecard?.getTotalTimeByEarnCode(5) +
				member.timecard?.getTotalTimeByEarnCode(6)
			);
		}
		if (attr === "total") {
			return member.timecard?.getWeekTotal();
		}
		if (attr === "notes") {
			return member.timecard?.hasNotes();
		}
		if (attr === "status") {
			return member.timecard?.getStatusLevel();
		}
		if (attr.includes("approverUID")) {
			return member.timecard?.timecardHeader[attr] === null ? 0 : 1;
		}
		return member[attr];
	}

	sortTable(attributeName, valType = "string") {
		var members = this.state.filteredMembers;
		var sortAttribute = this.state.sortAttribute;
		var sortOrder = this.state.sortOrder;
		var attribute = { name: attributeName, type: valType };

		if (attributeName === sortAttribute) {
			if (sortOrder === 0) {
				sortOrder = 1;
			} else {
				sortOrder = 0;
			}
		} else {
			sortOrder = 0;
		}
		members = mergeSort(members, attribute, sortOrder, this.getSortValue);
		this.setState({
			filteredMembers: members,
			sortAttribute: attributeName,
			sortOrder: sortOrder,
			tableDataChanged: true,
		});
	}

	changeAck() {
		this.setState({ tableDataChanged: false });
	}

	handleMenuTabChange(tabVal) {
		this.setState({ tabVal: tabVal });
	}

	handleGroupSearchBar(searchValue) {
		this.setState({ resourceGroupFilterVal: searchValue });
	}

	openBatchModal() {
		this.setState({ batchModalIsOpen: true });
	}

	closeBatchModal() {
		this.setState({ batchModalIsOpen: false });
	}

	async addBatch(batchInfo) {
		let members = this.state.members;
		let selectedMembers = this.state.selectedMembers;
		let selectedEmployeeIds = [];
		let remainingEmployees = [];

		for (let i = 0; i < members?.length; i++) {
			if (selectedMembers.includes(members[i].timecard?.timecardHeader?.keyId)) {
				if (members[i].timecard?.timecardHeader.isApproved()) {
					selectedEmployeeIds.push(members[i].employee);
				} else {
					remainingEmployees.push(members[i].timecard?.timecardHeader?.keyId);
				}
			}
		}

		let batchInfoResult = await this.state.payrollAPI.InsertPayrollTimecardBatch(
			selectedEmployeeIds,
			batchInfo,
			portalUser.user?.employeeUID
		);

		if (batchInfoResult.status === 200 && batchInfoResult.value.length > 0) {
			for (let i = 0; i < selectedEmployeeIds.length; i++) {
				for (let j = 0; j < members?.length; j++) {
					if (selectedEmployeeIds[i] === members[j].employee) {
						members[j].timecard?.setBatchInfo({ BatchID: 4, PaySeq: 2 });
					}
				}
			}
		}

		this.setState(
			{
				members: members,
				selectedMembers: remainingEmployees,
				ready: false,
			},
			() => {
				this.filterMembers();
			}
		);

		this.closeBatchModal();
		setTimeout(() => {
			this.setState({ ready: true });
		}, 1000);
	}

	selectAll() {
		let selectedMembers = this.state.selectedMembers;
		let members = this.state.members;
		let filteredMembers = this.state.filteredMembers;
		let ready = true;

		if (selectedMembers.length !== filteredMembers.length) {
			for (let i = 0; i < members.length; i++) {
				if (
					!this.filteredOut(this.state.members[i]) &&
					this.state.members[i]?.timecard?.isTimePosted() === Boolean(this.props.showPosted)
				) {
					let keyID = members[i].timecard?.timecardHeader?.keyId;
					let selectedMemberIndex = selectedMembers.indexOf(keyID);

					if (selectedMemberIndex === -1) {
						selectedMembers.push(keyID);
					}
				}
			}
		} else {
			selectedMembers = [];
			ready = false;
		}
		this.setState({
			selectedMembers: selectedMembers,
			ready: ready,
		});
		if (!ready) {
			setTimeout(() => {
				this.setState({ ready: true });
			}, 100);
		}
	}

	clearFiltersQuick() {
		this.clearFilters();
		this.setState({
			ready: false,
		});

		setTimeout(() => {
			this.setState({ ready: true });
			this.handleTabChange(0);
		}, 100);
	}

	expandMember(ev, index) {
		ev.stopPropagation();
		let members = this.state.filteredMembers;
		let allExpanded = this.state.allExpanded;

		if (index === "all") {
			allExpanded = !allExpanded;
			for (let i = 0; i < members?.length; i++) {
				members[i].timecard.isExpanded = allExpanded;
			}
		} else {
			if (members[index].timecard.isExpanded) {
				members[index].timecard.isExpanded = false;
			} else {
				members[index].timecard.isExpanded = true;
			}
		}

		this.setState({
			filteredMembers: members,
			allExpanded: allExpanded,
		});

		this.triggerTableUpdate();
	}

	triggerTableUpdate() {
		this.setState({
			tableUpdateID: v4(),
			filtering: false,
		});
	}

	async approveTime(timecardId, approverNum) {
		let members = this.state.members;
		let filteredMembers = this.state.filteredMembers;

		for (let i = 0; i < members.length; i++) {
			if (members[i]?.timecard?.timecardHeader?.keyId === timecardId) {
				await members[i].timecard.timecardHeader.updateApproved(approverNum, portalUser.user?.employeeUID);
				break;
			}
		}

		this.setState({
			members: members,
			filteredMembers: filteredMembers,
		});
	}
	async approveSelected() {
		let members = this.state.members;
		let selectedMembers = this.state.selectedMembers;

		let updates = {};
		let approverUID = portalUser.user?.employeeUID;
		let approvedDate = new Date().toLocaleDateString();

		updates = { ApproverUID1: approverUID, ApprovedDate1: approvedDate };
		let approvedSelectedResult = await this.state.payrollAPI.UpdateTimecardHeaders(selectedMembers, updates);

		if (approvedSelectedResult.status === 200) {
			for (let i = 0; i < members?.length; i++) {
				if (selectedMembers.includes(members[i].timecard?.timecardHeader?.keyId)) {
					members[i].timecard.timecardHeader.approverUID1 = approverUID;
					members[i].timecard.timecardHeader.approvedDate1 = approvedDate;
				}
			}
		}

		this.setState(
			{
				members: members,
				selectedMembers: [],
				ready: false,
			},
			() => {
				this.filterMembers();
			}
		);

		// this.closeBatchModal();
		setTimeout(() => {
			this.setState({ ready: true });
		}, 1000);
	}

	render() {
		let reviewRowsTable = [];
		let reviewRowsList = [];

		const notesFilterOptions = [
			{ display: "N/A", value: -1, icon: "far fa-clipboard" },
			{ display: "W/O Notes", value: 0, icon: "far fa-clipboard" },
			{ display: "W Notes", value: 1, icon: "fas fa-clipboard-check" },
		];

		const approvedFilterOptions = [
			{ display: "N/A", value: -1 },
			{ display: "Not Approved", value: 0 },
			{ display: "Approved", value: 1 },
		];

		const statusFilterOptions = [
			{ display: "All", value: -1 },
			{ display: "Posted", value: 6 },
			{ display: "Uploaded", value: 5 },
			{ display: "Approved", value: 4 },
			{ display: "Entered", value: 3 },
			{ display: "In Progress", value: 2 },
			{ display: "Zero Hours", value: 1 },
			{ display: "Missed Time", value: 0 },
		];

		let tableHeadFormat = [];

		if ((this.props.reviewLevel ?? this.props.resourceGroup?.reviewLevel) === "Review") {
			tableHeadFormat = [
				{
					title: (
						<Checkbox
							colorScheme="green"
							mb="0"
							size="lg"
							fontWeight="bold"
							isChecked={this.state.filteredMembers?.length === this.state.selectedMembers?.length}
						/>
					),
					width: "40px",
					colWidth: 40,
					classes: "text-center",
					onClick: this.selectAll,
				},
				{
					title: "PRCo",
					name: "prcoEmployee",
					filterPlaceholder: "PRCO",
					width: "70px",
					colWidth: 70,
					onClick: this.sortTable,
				},
				{
					title: "Employee",
					name: "employeeName",
					filterPlaceholder: "NAME",
					width: "190px",
					colWidth: 190,
					onClick: this.sortTable,
				},
				{
					title: "Craft/Class",
					name: "craftClass",
					filterPlaceholder: "CRAFT",
					width: "130px",
					colWidth: 130,
					onClick: this.sortTable,
				},
				{
					title: "Region",
					name: "region",
					filterPlaceholder: "REGION",
					width: "80px",
					colWidth: 80,
					onClick: this.sortTable,
				},
				{
					title: "Department",
					name: "dept",
					filterPlaceholder: "DEPT",
					width: "160px",
					colWidth: 160,
					classes: "border-right",
					onClick: this.sortTable,
				},
				{
					title: "Reg",
					name: "reg",
					filterPlaceholder: "0.0",
					width: "70px",
					colWidth: 70,
					type: "number",
					simple: true,
					classes: "text-center",
					onClick: this.sortTable,
				},
				{
					title: "OT",
					name: "ot",
					filterPlaceholder: "0.0",
					width: "70px",
					colWidth: 70,
					type: "number",
					simple: true,
					classes: "text-center",
					onClick: this.sortTable,
				},
				{
					title: "DT",
					name: "dt",
					filterPlaceholder: "0.0",
					width: "70px",
					colWidth: 70,
					type: "number",
					simple: true,
					classes: "text-center",
					onClick: this.sortTable,
				},
				{
					title: "PTO",
					name: "pto",
					filterPlaceholder: "0.0",
					width: "70px",
					colWidth: 70,
					type: "number",
					simple: true,
					classes: "text-center",
					onClick: this.sortTable,
				},
				{
					title: "TOTAL",
					name: "total",
					filterPlaceholder: "0.0",
					width: "70px",
					colWidth: 70,
					type: "number",
					simple: true,
					classes: "border-right text-center",
					onClick: this.sortTable,
				},
				{
					title: "Notes",
					name: "notes",
					filterPlaceholder: "N/A",
					width: "80px",
					colWidth: 80,
					type: "select",
					options: notesFilterOptions,
					classes: "text-center",
					onClick: this.sortTable,
				},
				{
					title: "Aprv 1",
					name: "approverUID1",
					filterPlaceholder: "N/A",
					width: "80px",
					colWidth: 80,
					type: "select",
					options: approvedFilterOptions,
					classes: "text-center",
					onClick: this.sortTable,
				},
				{
					title: "Aprv 2",
					name: "approverUID2",
					filterPlaceholder: "N/A",
					width: "80px",
					colWidth: 80,
					type: "select",
					options: approvedFilterOptions,
					classes: "text-center",
					onClick: this.sortTable,
				},
				{
					title: "Approved By",
					name: "approvedBy",
					filterPlaceholder: "NAME",
					width: "190px",
					colWidth: 190,
					onClick: this.sortTable,
				},
				{
					title: "Status",
					name: "status",
					filterPlaceholder: "All",
					width: "80px",
					colWidth: 80,
					type: "select",
					options: statusFilterOptions,
					classes: "text-center",
					onClick: this.sortTable,
				},
			];
		} else if (this.props.resourceGroup?.reviewLevel === "Approval") {
			tableHeadFormat = [
				{ width: "40px", colWidth: 40 },
				{
					title: "Employee",
					name: "employeeName",
					filterPlaceholder: "NAME",
					width: "190px",
					colWidth: 190,
					onClick: this.sortTable,
				},
				{
					title: "Craft/Class",
					name: "craftClass",
					filterPlaceholder: "CRAFT",
					width: "130px",
					colWidth: 130,
					onClick: this.sortTable,
				},
				{
					title: "Region",
					name: "region",
					filterPlaceholder: "REGION",
					width: "80px",
					colWidth: 80,
					onClick: this.sortTable,
				},
				{
					title: "Department",
					name: "dept",
					filterPlaceholder: "DEPT",
					width: "160px",
					colWidth: 160,
					classes: "border-right",
					onClick: this.sortTable,
				},
				{
					title: "Reg",
					name: "reg",
					filterPlaceholder: "0.0",
					width: "70px",
					colWidth: 70,
					type: "number",
					simple: true,
					classes: "text-center",
					onClick: this.sortTable,
				},
				{
					title: "OT",
					name: "ot",
					filterPlaceholder: "0.0",
					width: "70px",
					colWidth: 70,
					type: "number",
					simple: true,
					classes: "text-center",
					onClick: this.sortTable,
				},
				{
					title: "DT",
					name: "dt",
					filterPlaceholder: "0.0",
					width: "70px",
					colWidth: 70,
					type: "number",
					simple: true,
					classes: "text-center",
					onClick: this.sortTable,
				},
				{
					title: "PTO",
					name: "pto",
					filterPlaceholder: "0.0",
					width: "70px",
					colWidth: 70,
					type: "number",
					simple: true,
					classes: "text-center",
					onClick: this.sortTable,
				},
				{
					title: "TOTAL",
					name: "total",
					filterPlaceholder: "0.0",
					width: "70px",
					colWidth: 70,
					type: "number",
					simple: true,
					classes: "border-right text-center",
					onClick: this.sortTable,
				},
				{
					title: "Notes",
					name: "notes",
					filterPlaceholder: "N/A",
					width: "80px",
					colWidth: 80,
					type: "select",
					options: notesFilterOptions,
					classes: "text-center",
					onClick: this.sortTable,
				},
				{
					title: "Aprv 1",
					name: "approverUID1",
					filterPlaceholder: "N/A",
					width: "80px",
					colWidth: 80,
					type: "select",
					options: approvedFilterOptions,
					classes: "text-center",
					onClick: this.sortTable,
				},
				{
					title: "Aprv 2",
					name: "approverUID2",
					filterPlaceholder: "N/A",
					width: "80px",
					colWidth: 80,
					type: "select",
					options: approvedFilterOptions,
					classes: "text-center",
					onClick: this.sortTable,
				},
				{
					title: "Status",
					name: "status",
					filterPlaceholder: "All",
					width: "80px",
					colWidth: 80,
					type: "select",
					options: statusFilterOptions,
					classes: "text-center",
					onClick: this.sortTable,
				},
			];
		} else {
			tableHeadFormat = [
				{ width: "40px", colWidth: 40 },
				{
					title: "Employee",
					name: "employeeName",
					filterPlaceholder: "NAME",
					width: "210px",
					colWidth: 210,
					onClick: this.sortTable,
				},
				{
					title: "Craft/Class",
					name: "craftClass",
					filterPlaceholder: "CRAFT",
					width: "140px",
					colWidth: 140,
					onClick: this.sortTable,
				},
				{
					title: "Region",
					name: "region",
					filterPlaceholder: "REGION",
					width: "100px",
					colWidth: 100,
					onClick: this.sortTable,
				},
				{
					title: "Department",
					name: "dept",
					filterPlaceholder: "DEPT",
					width: "170px",
					colWidth: 170,
					classes: "border-right",
					onClick: this.sortTable,
				},
				{
					title: "Reg",
					name: "reg",
					filterPlaceholder: "0.0",
					width: "80px",
					colWidth: 80,
					type: "number",
					simple: true,
					classes: "text-center",
					onClick: this.sortTable,
				},
				{
					title: "OT",
					name: "ot",
					filterPlaceholder: "0.0",
					width: "80px",
					colWidth: 80,
					type: "number",
					simple: true,
					classes: "text-center",
					onClick: this.sortTable,
				},
				{
					title: "DT",
					name: "dt",
					filterPlaceholder: "0.0",
					width: "80px",
					colWidth: 80,
					type: "number",
					simple: true,
					classes: "text-center",
					onClick: this.sortTable,
				},
				{
					title: "PTO",
					name: "pto",
					filterPlaceholder: "0.0",
					width: "80px",
					colWidth: 80,
					type: "number",
					simple: true,
					classes: "text-center",
					onClick: this.sortTable,
				},
				{
					title: "TOTAL",
					name: "total",
					filterPlaceholder: "0.0",
					width: "80px",
					colWidth: 80,
					type: "number",
					simple: true,
					classes: "border-right text-center",
					onClick: this.sortTable,
				},
				{
					title: "Notes",
					name: "notes",
					filterPlaceholder: "N/A",
					width: "80px",
					colWidth: 80,
					type: "select",
					options: notesFilterOptions,
					classes: "text-center",
					onClick: this.sortTable,
				},
				{
					title: "Status",
					name: "status",
					filterPlaceholder: "N/A",
					width: "80px",
					colWidth: 80,
					type: "select",
					options: statusFilterOptions,
					classes: "text-center",
					onClick: this.sortTable,
				},
			];
		}

		let filterAttributes = ["employeeName", "craft", "region", "dept"];

		for (var i = 0; i < this.state.filteredMembers?.length; i++) {
			if (this.state.filteredMembers[i].timecard === null) {
				continue;
			}

			//  if (this.state.dataView === 'table') {

			if (
				(((this.props.reviewLevel ?? this.props.resourceGroup?.reviewLevel) === "Review" &&
					this.state.filteredMembers[i]?.timecard?.isTimePosted() === Boolean(this.props.showPosted)) ||
					this.props.resourceGroup?.reviewLevel !== "Review") &&
				getDeviceType() !== "mobile"
			) {
				reviewRowsTable.push(
					<PayrollUploadTableRow
						rowHeight={50}
						allMembers={this.state.members}
						member={this.state.filteredMembers[i]}
						selectMember={this.selectMember}
						selectedMembers={this.state.selectedMembers}
						approveTime={this.approveTime}
						reviewLevel={this.props.reviewLevel ?? this.props.resourceGroup?.reviewLevel}
						currentPayPeriod={this.state.payPeriodInfo?.currentPeriod}
						timecardJobs={this.state.timecardJobs}
						getAvailableJobPhases={this.getAvailableJobPhases}
						dataView="table"
						triggerTableUpdate={this.triggerTableUpdate}
						expandMember={this.expandMember}
						index={i}
						payPeriods={this.props.payPeriods}
					/>
				);
			}
			//if (this.props.resourceGroup?.reviewLevel === 'Review') {
			let isHidden = this.filteredOut(this.state.filteredMembers[i]);

			if (searchFilter(this.state.filteredMembers[i], filterAttributes, this.state.resourceGroupFilterVal)) {
				reviewRowsList.push(
					<PayrollUploadTableRow
						rowHeight={80}
						allMembers={this.state.members}
						member={this.state.filteredMembers[i]}
						selectMember={this.selectMember}
						selectedMembers={this.state.selectedMembers}
						approveTime={this.approveTime}
						hidden={isHidden}
						reviewLevel={this.props.reviewLevel ?? this.props.resourceGroup?.reviewLevel}
						currentPayPeriod={this.state.payPeriodInfo?.currentPeriod}
						timecardJobs={this.state.timecardJobs}
						getAvailableJobPhases={this.getAvailableJobPhases}
						dataView="list"
						triggerTableUpdate={this.triggerTableUpdate}
						expandMember={this.expandMember}
						index={i}
						payPeriods={this.props.payPeriods}
					/>
				);
			}
			//}
		}

		let selectedOptions = (
			<Flex justify="space-between">
				<Flex>
					<Tabs colorScheme="teal" onChange={this.handleTabChange} index={this.state.tabVal}>
						<TabList style={{ flexWrap: "wrap" }}>
							{(this.props.reviewLevel ?? this.props.resourceGroup?.reviewLevel) !== "Review" && (
								<Tab color="gray.500" fontWeight="semibold">
									ALL
								</Tab>
							)}
							{Object.keys(this.state.tabs)?.map((tab, i) => (
								<Tab textTransform="uppercase" color="gray.500" fontWeight="semibold" key={"tab-" + i}>
									{tab}
								</Tab>
							))}
						</TabList>
					</Tabs>
				</Flex>

				<Flex textAlign="end">
					{(this.props.reviewLevel ?? this.props.resourceGroup?.reviewLevel) === "Review" && (
						<Menu>
							<MenuButton
								as={Button}
								variant="solid"
								border="2px"
								borderColor="gray.300"
								color="gray.500"
								px="2"
								size="md"
							>
								<HStack spacing={2} align="center">
									<i className="fas fa-cloud-upload-alt fa-fw"></i>
									<Text textTransform="uppercase">SELECTED</Text>
								</HStack>
							</MenuButton>
							<Portal>
								<MenuList color="gray.500" fontWeight="semibold" zIndex="99">
									<MenuItem isDisabled={this.props.showPosted} onClick={this.approveSelected}>
										<HStack spacing={2} align="center">
											<i className="fas fa-check-double fa-fw fa-lg"></i>
											<Text textTransform="uppercase">APPROVE SELECTED</Text>
										</HStack>
									</MenuItem>

									<MenuItem>
										<HStack spacing={2} align="center">
											<i className="fas fa-check-square fa-fw fa-lg"></i>
											<Text textTransform="uppercase">SHOW SELECTED</Text>
										</HStack>
									</MenuItem>
									<MenuItem onClick={this.clearSelectedMembers}>
										<HStack spacing={2} align="center">
											<i className="fas fa-times-circle fa-lg fa-fw"></i>
											<Text textTransform="uppercase">CLEAR SELECTED</Text>
										</HStack>
									</MenuItem>
									<MenuItem onClick={this.openBatchModal} isDisabled={this.props.showPosted}>
										<HStack spacing={2} align="center">
											<i className="fas fa-cloud-upload-alt fa-lg fa-fw"></i>
											<Text textTransform="uppercase">CREATE BATCH</Text>
										</HStack>
									</MenuItem>
								</MenuList>
							</Portal>
						</Menu>
					)}

					<Button
						key={v4()}
						variant="solid"
						border="2px"
						borderColor="gray.300"
						color="gray.500"
						px="2"
						ml={2}
						size="md"
						onClick={(ev) => {
							this.expandMember(ev, "all");
						}}
					>
						<HStack spacing={1} align="center">
							<i
								className={
									"fas " +
									(this.state.allExpanded ? "fa-compress-alt" : "fa-expand-alt") +
									" fa-lg fa-fw"
								}
							/>
							<Text textTransform="uppercase">
								{this.state.allExpanded ? "COLLAPSE ALL" : "EXPAND ALL"}
							</Text>
						</HStack>
					</Button>
				</Flex>
			</Flex>
		);

		return (
			<>
				<PayrollUploadBatchModal
					isOpen={this.state.batchModalIsOpen}
					onClose={this.closeBatchModal}
					selectedMembers={this.state.selectedMembers}
					prGroup={this.state.filters["prGroup"]}
					prco={this.state.filters["prco"]}
					payPeriod={this.state.payPeriodInfo?.currentPeriod?.prEndDate}
					members={this.state.members}
					addBatch={this.addBatch}
				/>

				<Center display={{ base: "none", xl: "block" }}>
					{/* {Boolean(portalUser.user?.isPortalAdminYN === 'Y') && (
						<CrewTimeVirtualTableV3
							data={this.state.filteredMembers ?? []}
							selectMember={this.selectMember}
							selectedMembers={this.state.selectedMembers}
							approveTime={this.approveTime}
							reviewLevel={this.props.reviewLevel ?? this.props.resourceGroup?.reviewLevel}
							currentPayPeriod={this.state.payPeriodInfo?.currentPeriod}
							timecardJobs={this.state.timecardJobs}
							getAvailableJobPhases={this.getAvailableJobPhases}
							dataView="table"
							triggerTableUpdate={this.triggerTableUpdate}
							expandMember={this.expandMember}
							index={i}
							payPeriods={this.props.payPeriods}
						/>
					)} */}
					{/* {Boolean(portalUser.user?.isPortalAdminYN === 'N') && ( */}
					<VirtualTableV2
						baseHeight={50}
						rows={reviewRowsTable}
						tHeadFormat={tableHeadFormat}
						onRowClick={this.expandMember}
						triggerUpdate={this.state.tableUpdateID}
						options={selectedOptions}
						applyFilters={true}
						addFilter={this.addFilter}
						filtering={this.state.filtering}
						sortOrder={this.state.sortOrder}
						sortCol={this.state.sortAttribute}
						ready={Boolean(this.state.members) && Boolean(this.state.filteredMembers)}
					/>
					{/* )} */}
				</Center>

				<Center display={{ base: "block", xl: "none" }} width="full">
					<VirtualListV2
						hoverDisabled
						rows={reviewRowsList}
						onRowClick={this.expandMember}
						triggerUpdate={this.state.tableUpdateID}
						dataView="list"
						options={selectedOptions}
						defaultHeight={136}
					/>
				</Center>
			</>
		);
	}
}
