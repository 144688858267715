const VALUE_TYPES = [
	{
		queryData: "ValueType",
		valueType: "string",
		sortIcon: "alpha",
		textAlign: "left",
		decimals: null,
		format: "",
	},
	{
		queryData: "ValueType",
		valueType: "boolean",
		sortIcon: "alpha",
		textAlign: "center",
		decimals: null,
		format: "Y",
	},
	{
		queryData: "ValueType",
		valueType: "integer",
		sortIcon: "numeric",
		textAlign: "left",
		decimals: 0,
		format: "###0",
	},
	{
		queryData: "ValueType",
		valueType: "numeric",
		sortIcon: "numeric",
		textAlign: "right",
		decimals: 2,
		format: "###0",
	},
	{
		queryData: "ValueType",
		valueType: "number",
		sortIcon: "numeric",
		textAlign: "right",
		decimals: 0,
		format: "#,##0",
	},
	{
		queryData: "ValueType",
		valueType: "currency",
		sortIcon: "numeric",
		textAlign: "right",
		decimals: 0,
		format: "$#,##0",
	},
	{
		queryData: "ValueType",
		valueType: "accounting",
		sortIcon: "numeric",
		textAlign: "right",
		decimals: 0,
		format: "($#,##0)",
	},
	{
		queryData: "ValueType",
		valueType: "percent",
		sortIcon: "numeric",
		textAlign: "right",
		decimals: 1,
		format: "#,##0.0%",
	},
	{
		queryData: "ValueType",
		valueType: "year",
		sortIcon: "numeric",
		textAlign: "left",
		decimals: 0,
		format: "####",
	},
	{
		queryData: "ValueType",
		valueType: "month",
		sortIcon: "numeric",
		textAlign: "left",
		decimals: null,
		format: "MMM YYYY",
	},
	{
		queryData: "ValueType",
		valueType: "date",
		sortIcon: "numeric",
		textAlign: "left",
		decimals: null,
		format: "MM/DD/YYYY",
	},
	{
		queryData: "ValueType",
		valueType: "dateTime",
		sortIcon: "numeric",
		textAlign: "left",
		decimals: null,
		format: "MM/DD/YYYY hh:mm A",
	},
	{
		queryData: "ValueType",
		valueType: "time",
		sortIcon: "numeric",
		textAlign: "left",
		decimals: null,
		format: "hh:mm A",
	},
];
export default VALUE_TYPES;
