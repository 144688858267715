import React, { useState, useRef, useCallback, useEffect } from "react";
import {
	Drawer,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	DrawerHeader,
	DrawerBody,
	DrawerFooter,
	Flex,
	Text,
	Heading,
	Button,
	Stack,
	HStack,
	useToast,
	FormLabel,
	InputGroup,
	InputLeftAddon,
	Input,
	InputRightAddon,
	Switch,
} from "@chakra-ui/react";
import PhoneNumber from "awesome-phonenumber";
import DataInput from "../../../core/Inputs/data/DataInput";
import UserEmergencyContacts from "./UserEmergencyContacts";
// import PREmployee from "../classes/PREmployee";

import CELL_PHONE_CARRIERS from "../constants/CellPhoneCarriers";
import USER_STATUSES from "../constants/UserStatuses";
import { portalUser } from "../../../App";
import { formatValue, log } from "../../../helperFunctions";

export default function EmployeeEditDrawer({ selectedEmployee, isOpen, closeEmployee, saveEmployee }) {
	const toast = useToast();

	const spacing = { vertSpacing: 2, horzSpacing: 2, leftFlex: 4, rightFlex: 2 };
	const containerRef = useRef(null);

	const [triggerUpdate, setTriggerUpdate] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);

	const [employee, setEmployee] = useState(null);

	const getEmployee = useCallback(async (employeeUID) => {
		let user = await portalUser?.getUser(employeeUID, "employeeUID");

		// if (Boolean(employee)) {
		// 	employee = new PREmployee(employee, user);
		// }

		setEmployee(user);
	}, []);

	useEffect(() => {
		if (Boolean(selectedEmployee?.employeeUID)) {
			getEmployee(selectedEmployee?.employeeUID);
		}
	}, [getEmployee, selectedEmployee?.employeeUID]);

	const validateEmployee = useCallback(async () => {
		setSaveLoading(true);
		await saveEmployee(employee);
		setSaveLoading(false);
	}, [saveEmployee, employee]);

	const updateUser = useCallback(
		async (attr, value) => {
			log("1- attr", attr);
			log("2- value", value);
			await employee.updateUser(attr, value);
			if (["regionID"].includes(attr)) {
				employee.getRegion();
			}
			if (["deptID", "parentDeptID"].includes(attr)) {
				employee.getDepartment();
			}
			log("employee", employee);
			setTriggerUpdate(true);
		},
		[employee]
	);

	const updateShowBirthday = async (e) => {
		await employee?.updateShowBirthday(e.target.checked);
		setTriggerUpdate(true);
	};

	const updateSharePhotos = async (e) => {
		await employee?.updateSharePhotos(e.target.checked);
		setTriggerUpdate(true);
	};

	useEffect(() => {
		if (triggerUpdate) {
			setTriggerUpdate(false);
		}
	}, [triggerUpdate]);

	log("employee", employee);

	return (
		<Drawer placement="right" isOpen={isOpen} onClose={closeEmployee} size="md" key={employee?.prehKeyID}>
			<DrawerOverlay />

			<DrawerContent ref={containerRef}>
				<DrawerCloseButton />

				<DrawerHeader>
					<HStack align="center" w="full" spacing={2}>
						<Heading as="h2" size="md" color="gray.400" textTransform="uppercase" letterSpacing={2}>
							Edit Employee
						</Heading>
					</HStack>
				</DrawerHeader>

				<DrawerBody overflowX={"hidden"} overflowY={"auto"}>
					<Stack spacing={4} w="full" h="full">
						<Stack
							p={4}
							spacing={spacing.vertSpacing}
							bg="blackAlpha.50"
							borderWidth={2}
							rounded="10"
							borderColor={"blackAlpha.100"}
						>
							<HStack
								w="full"
								pb={1}
								borderBottomWidth={2}
								borderBottomColor="blackAlpha.300"
								align="center"
							>
								<Heading
									w="full"
									color={"gray.500"}
									textTransform="uppercase"
									fontSize="lg"
									letterSpacing={2}
								>
									Contact Info
								</Heading>
							</HStack>

							<DataInput
								key={employee?.employeeUID + "-" + employee?.firstName}
								name="firstName"
								type="text"
								label="First Name"
								placeholder="First Name"
								defaultValue={employee?.firstName}
								onBlur={(e) => updateUser("firstName", e.target.value)}
								flex={spacing.leftFlex}
								variant="outline"
								inputProps={{ px: 2 }}
								isModal
							/>

							<DataInput
								key={employee?.employeeUID + "-" + employee?.lastName}
								name="lastName"
								type="text"
								label="Last Name"
								placeholder="Last Name"
								defaultValue={employee?.lastName}
								onBlur={(e) => updateUser("lastName", e.target.value)}
								flex={spacing.leftFlex}
								variant="outline"
								inputProps={{ px: 2 }}
								isModal
							/>

							<DataInput
								key={employee?.employeeUID + "-" + employee?.email}
								name="email"
								type="text"
								label="Email"
								placeholder="Email"
								defaultValue={employee?.email}
								isDisabled
								flex={spacing.leftFlex}
								variant="outline"
								inputProps={{ px: 2 }}
								isModal
							/>

							<HStack spacing={spacing.horzSpacing} direction="row" w="full">
								<Flex w="full">
									<DataInput
										key={employee?.employeeUID + "-" + employee?.cellPhone}
										name="cellPhone"
										type="text"
										label="Cell Phone"
										placeholder="(###) ###-####"
										defaultValue={employee?.cellPhone}
										onBlur={async (e) => {
											if (
												e.target.value !== "" &&
												!new PhoneNumber(e.target.value, "US").isValid()
											) {
												toast({
													title: "Phone Number is invalid.",
													status: "error",
													isClosable: true,
													duration: 5000,
												});
												return;
											}
											if (e.target.value !== employee?.cellPhone) {
												employee.cellPhone = e.target.value;
												console.log("Update User cellPhone", employee);

												try {
													await employee?.updateUserDB({
														CellPhone: e.target.value,
													});
												} catch (error) {
													console.error("Error updating cellPhone", error);
													alert("An error occurred! Please refresh the page and try again.");
												}
											}
										}}
										flex={spacing.leftFlex}
										variant="outline"
										inputProps={{ px: 2 }}
										isModal
									/>
								</Flex>
								<DataInput
									name="cellPhoneCarrier"
									label="Cell Carrier"
									type="select"
									placeholder="Unassigned"
									value={employee?.cellCarrier}
									options={CELL_PHONE_CARRIERS}
									optionValue="value"
									optionText="name"
									searchable
									onOptionSelect={(e) => updateUser("cellCarrier", e)}
									isModal
								/>
							</HStack>
							<FormLabel color="gray.500" letterSpacing={1} textTransform="uppercase">
								Share Birthday
							</FormLabel>
							<InputGroup
								shadow={portalUser.styles.cardShadow}
								borderColor={portalUser.styles.cardBorderColor}
								_focus={{ borderWidth: 1.5, borderColor: "teal.500", bg: "whiteAlpha.900" }}
							>
								<InputLeftAddon
									pointerEvents="none"
									children={
										<Text color="gray.600" fontSize="lg" className={"fas fa-birthday-cake"} />
									}
								/>
								<Input
									variant="filled"
									bg="whiteAlpha.600"
									color="gray.600"
									borderWidth={1}
									borderColor={portalUser.styles.cardBorderColor}
									rounded="md"
									_hover={{ bg: "whiteAlpha.900" }}
									_focus={{
										borderWidth: 1.5,
										borderColor: "teal.500",
										bg: "whiteAlpha.900",
									}}
									type="text"
									value={formatValue(employee?.birthDay, 0, "monthDay")}
									isDisabled
								/>
								<InputRightAddon
									children={
										<HStack align="center">
											<Switch
												onChange={(e) => {
													updateShowBirthday(e);
												}}
												colorScheme="teal"
												size="md"
												mb="0"
												isChecked={employee?.shareBirthdayYN === "Y"}
											/>
											<Text>Allow</Text>
										</HStack>
									}
								/>
							</InputGroup>
							<FormLabel color="gray.500" letterSpacing={1} textTransform="uppercase">
								Share Photos
							</FormLabel>
							<InputGroup
								shadow={portalUser.styles.cardShadow}
								_focus={{ borderWidth: 1.5, borderColor: "teal.500", bg: "whiteAlpha.900" }}
							>
								<InputLeftAddon
									pointerEvents="none"
									children={<Text color="gray.600" fontSize="lg" className={"fas fa-camera"} />}
								/>
								<Input
									variant="filled"
									bg="whiteAlpha.600"
									color="gray.600"
									borderWidth={1}
									rounded="md"
									_hover={{ bg: "whiteAlpha.900" }}
									_focus={{
										borderWidth: 1.5,
										borderColor: "teal.500",
										bg: "whiteAlpha.900",
									}}
									value={employee?.sharePhotosYN === "Y" ? "Share Photos" : "Don't Share Photos"}
									isDisabled
								/>
								<InputRightAddon
									children={
										<HStack align="center">
											<Switch
												onChange={(e) => {
													updateSharePhotos(e);
												}}
												colorScheme="teal"
												size="md"
												mb="0"
												isChecked={employee?.sharePhotosYN === "Y"}
											/>
											<Text>Allow</Text>
										</HStack>
									}
								/>
							</InputGroup>
							<DataInput
								name="leaveStatus"
								label="Leave Status"
								type="select"
								placeholder="N/A"
								value={employee?.leaveStatus}
								options={USER_STATUSES}
								optionValue="value"
								optionText="status"
								searchable
								onOptionSelect={async (e) => {
									if (e !== employee?.leaveStatus) {
										employee.leaveStatus = e;
										try {
											await employee?.updatePREmployeeDB({
												udEmployeeLeave: e,
											});
											setTriggerUpdate(true);
										} catch (error) {
											console.error("Error updating leaveStatus", error);
											alert("An error occurred! Please refresh the page and try again.");
										}
									}
								}}
								isModal
							/>
						</Stack>

						<Stack
							p={4}
							spacing={spacing.vertSpacing}
							bg="blackAlpha.50"
							borderWidth={2}
							rounded="10"
							borderColor={"blackAlpha.100"}
						>
							<HStack
								w="full"
								pb={1}
								borderBottomWidth={2}
								borderBottomColor="blackAlpha.300"
								align="center"
							>
								<Heading
									w="full"
									color={"gray.500"}
									textTransform="uppercase"
									fontSize="lg"
									letterSpacing={2}
								>
									Employee Info
								</Heading>
							</HStack>

							<Stack>
								<DataInput
									name="title"
									type="text"
									label="Title"
									placeholder="Title"
									defaultValue={employee?.title}
									onBlur={(e) => updateUser("title", e.target.value)}
									flex={spacing.leftFlex}
									variant="outline"
									inputProps={{ px: 2 }}
									isModal
								/>

								<DataInput
									name="deptID"
									label="Department"
									type="select"
									value={employee?.deptID}
									options={portalUser?.getDepartments()}
									optionValue="deptID"
									optionText="dept"
									placeholder="N/A"
									searchable
									onOptionSelect={(e) => updateUser("deptID", e)}
									isModal
								/>

								<DataInput
									name="managerUID"
									label="Manager"
									type="select"
									value={employee?.managerUID}
									options={portalUser?.getUsers()}
									optionValue="employeeUID"
									optionText="name"
									optionSubheading="title"
									placeholder="Unassigned"
									searchable
									onOptionSelect={async (e) => {
										if (e !== employee?.managerUID) {
											employee.managerUID = e;
											console.log("Update User manager", employee);

											try {
												await employee.updateManagementDB({
													ManagerUID: e,
												});
											} catch (error) {
												console.error("Error updating ManagerUID", error);
												alert("An error occurred! Please refresh the page and try again.");
											}
										}
										setTriggerUpdate(true);
									}}
									isModal
								/>
								<DataInput
									name="reviewerUID"
									label="Reviewer"
									type="select"
									placeholder="Unassigned"
									value={employee?.reviewerUID}
									options={portalUser?.getUsers()}
									optionValue="employeeUID"
									optionText="name"
									optionSubheading="title"
									searchable
									onOptionSelect={async (e) => {
										if (e !== employee?.reviewerUID) {
											employee.reviewerUID = e;
											console.log("Update User reviewer", employee);

											try {
												await employee.updateManagementDB({
													ReviewerUID: e,
												});
											} catch (error) {
												console.error("Error updating ReviewerUID", error);
												alert("An error occurred! Please refresh the page and try again.");
											}
										}
										setTriggerUpdate(true);
									}}
									isModal
								/>
								<Heading fontSize={"sm"} color="red.500">
									Warning: Changing the Reviewer deletes the current year review and creates a new
									one.
								</Heading>
								<DataInput
									name="approverUID"
									label="Approver"
									type="select"
									placeholder="Unassigned"
									value={employee?.approverUID}
									options={portalUser?.getUsers()}
									optionValue="employeeUID"
									optionText="name"
									optionSubheading="title"
									searchable
									onOptionSelect={async (e) => {
										if (e !== employee?.approverUID) {
											employee.approverUID = e;
											console.log("Update User Approver", employee?.approverUID);

											try {
												await employee?.updateManagementDB({
													ApproverUID: e,
												});
											} catch (error) {
												console.error("Error updating ApproverUID", error);
												alert("An error occurred! Please refresh the page and try again.");
											}
										}
										setTriggerUpdate(true);
									}}
									isModal
								/>
							</Stack>
						</Stack>

						<Stack
							p={4}
							spacing={spacing.vertSpacing}
							bg="blackAlpha.50"
							borderWidth={2}
							rounded="10"
							borderColor={"blackAlpha.100"}
						>
							<HStack
								w="full"
								pb={1}
								borderBottomWidth={2}
								borderBottomColor="blackAlpha.300"
								align="center"
							>
								<Heading
									w="full"
									color={"gray.500"}
									textTransform="uppercase"
									fontSize="lg"
									letterSpacing={2}
								>
									Payroll Info
								</Heading>
							</HStack>

							<DataInput
								isDisabled
								name="homeAddress"
								type="text"
								label="Home Address"
								placeholder="Home Address"
								defaultValue={employee?.homeAddress}
								onBlur={async (e) => {
									if (e.target.value !== employee?.homeAddress) {
										employee.homeAddress = e.target.value;
										console.log("Update PREmployee homeAddress", employee);

										try {
											await employee?.updatePREmployeeDB({
												MailAddress: e.target.value,
											});
										} catch (error) {
											console.error("Error updating homeAddress", error);
											alert("An error occurred! Please refresh the page and try again.");
										}
									}
								}}
								flex={spacing.leftFlex}
								variant="outline"
								inputProps={{ px: 2 }}
								isModal
							/>

							<DataInput
								isDisabled
								name="homeCity"
								type="text"
								label="Home City"
								placeholder="Home City"
								defaultValue={employee?.homeCity}
								onBlur={async (e) => {
									if (e.target.value !== employee?.homeCity) {
										employee.homeCity = e.target.value;
										console.log("Update PREmployee homeCity", employee);

										try {
											await employee?.updatePREmployeeDB({
												MailCity: e.target.value,
											});
										} catch (error) {
											console.error("Error updating homeCity", error);
											alert("An error occurred! Please refresh the page and try again.");
										}
									}
								}}
								flex={spacing.leftFlex}
								variant="outline"
								inputProps={{ px: 2 }}
								isModal
							/>

							<Stack spacing={spacing.horzSpacing} direction="row" w="full">
								<DataInput
									isDisabled
									name="homeState"
									type="text"
									label="State"
									placeholder="State"
									defaultValue={employee?.homeState}
									onBlur={async (e) => {
										if (e.target.value !== employee?.homeState) {
											employee.homeState = e.target.value;
											console.log("Update PREmployee homeState", employee);

											try {
												await employee?.updatePREmployeeDB({
													MailState: e.target.value,
												});
											} catch (error) {
												console.error("Error updating homeState", error);
												alert("An error occurred! Please refresh the page and try again.");
											}
										}
									}}
									flex={spacing.leftFlex}
									variant="outline"
									inputProps={{ px: 2 }}
									isModal
								/>
								<DataInput
									isDisabled
									name="homeZip"
									type="text"
									label="Zip"
									placeholder="Zip"
									defaultValue={employee?.homeZip}
									onBlur={async (e) => {
										if (e.target.value !== employee?.homeZip) {
											employee.homeZip = e.target.value;
											console.log("Update PREmployee homeZip", employee);

											try {
												await employee?.updatePREmployeeDB({
													MailZip: e.target.value,
												});
											} catch (error) {
												console.error("Error updating homeZip", error);
												alert("An error occurred! Please refresh the page and try again.");
											}
										}
									}}
									flex={spacing.leftFlex}
									variant="outline"
									inputProps={{ px: 2 }}
									isModal
								/>
							</Stack>

							<Stack spacing={spacing.horzSpacing} direction="row" w="full">
								<DataInput
									isDisabled
									name="paystubEmail"
									type="text"
									label="Paystub Email"
									placeholder="Paystub Email"
									defaultValue={employee?.paystubEmail}
									onBlur={async (e) => {
										if (e.target.value !== employee?.paystubEmail) {
											employee.paystubEmail = e.target.value;

											try {
												await employee?.updatePREmployeeDB({
													PaystubEmail: e.target.value,
												});
											} catch (error) {
												console.error("Error updating paystubEmail", error);
												alert("An error occurred! Please refresh the page and try again.");
											}
										}
									}}
									flex={spacing.leftFlex}
									variant="outline"
									inputProps={{ px: 2 }}
									isModal
								/>
							</Stack>
						</Stack>

						<UserEmergencyContacts employeeUID={employee?.employeeUID} isDrawer />
					</Stack>
				</DrawerBody>

				<DrawerFooter>
					<Flex justify="space-between" flex={1} w="full">
						<Button
							variant="outline"
							colorScheme="red"
							onClick={closeEmployee}
							borderWidth={2}
							px={3}
							fontSize="sm"
						>
							<HStack>
								<i className={"fas fa-times fa-fw"}></i>
								<Text letterSpacing={1} textTransform={"uppercase"}>
									Close
								</Text>
							</HStack>
						</Button>

						<HStack spacing={2} flex={1} w="full" justify="flex-end" align="center">
							<Button
								colorScheme={"blue"}
								onClick={validateEmployee}
								px={3}
								fontSize="sm"
								isLoading={saveLoading}
								loadingText={"Saving..."}
							>
								<HStack>
									<i className={"fas fa-save fa-fw"}></i>
									<Text fontSize="sm" letterSpacing={1} textTransform={"uppercase"}>
										Save
									</Text>
								</HStack>
							</Button>
						</HStack>
					</Flex>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}
