import React, { useMemo, useState } from "react";
import { useDisclosure, Box, Button, Collapse, Stack } from "@chakra-ui/react";
import AreaGraph from "../../../core/Graphs/components/AreaGraph";
import moment from "moment";
// import { log } from "../../../../helperFunctions";

export default function MFRBilledVsCostGraph(props) {
	const { isOpen, onToggle } = useDisclosure();
	const [billedVsCost, setBilledVsCost] = useState([]);

	useMemo(() => {
		let graphData = [];
		for (let i = props.contractTotalsByMth?.length - 1; i >= 0; i--) {
			let projContractAmt = props.contractTotalsByMth[i]?.getProjContractAmt() ?? null;
			let projMarginPct = props.contractTotalsByMth[i]?.getProjMarginPct() ?? null;
			let contractAmt = parseFloat(props.contractTotalsByMth[i]?.contractAmt ?? 0);
			let billedToDate = parseFloat(props.contractTotalsByMth[i]?.billedAmt ?? 0);
			let costToDate = parseFloat(props.contractTotalsByMth[i]?.actualCost ?? 0);
			let receivedToDate = parseFloat(props.contractTotalsByMth[i]?.receivedAmt ?? 0);

			if (
				!isNaN(parseFloat(projMarginPct)) &&
				!isNaN(parseFloat(projContractAmt) && parseFloat(projContractAmt ?? 0) > 0)
			) {
				let dataPoint = {
					name: moment(new Date(props.contractTotalsByMth[i].mth)).format("MMM YYYY"),
					"Projected Contract": projContractAmt,
					"Current Contract": contractAmt,
					"Billed To Date": billedToDate,
					"Cost To Date": costToDate,
					"Cash Collected": receivedToDate,
				};
				graphData.push(dataPoint);
			}
		}

		setBilledVsCost(graphData);
	}, [props.contractTotalsByMth]);

	return (
		<Stack w="full" px={4}>
			<Collapse in={isOpen}>
				{billedVsCost?.length > 0 && (
					<Box minH="xs" pt={4}>
						<AreaGraph
							title="Billed Vs Cost By Month"
							graphData={billedVsCost}
							valueType="money"
							varY="Projected Contract"
						/>
					</Box>
				)}
			</Collapse>

			{billedVsCost?.length !== 0 && (
				<Button
					w="full"
					size="sm"
					color="gray.500"
					variant="unstyled"
					_hover={{ textDecoration: "underline", color: "blue.600" }}
					onClick={onToggle}
				>
					{isOpen ? "Close Billed vs Cost" : "Show Billed vs Cost"}
				</Button>
			)}
		</Stack>
	);
}
