import React from "react";
import { Flex, Stack } from "@chakra-ui/react";
import { portalUser } from "../../../../../App";
import SectionTemplateDiversityVendors from "../../../../Purchasing/classes/SectionTemplateDiversityVendors";
import Section from "../../../../../core/Reports/components/Section";
import SectionObj from "../../../../../core/Reports/classes/Section";
import LoadingSection from "../../../../../core/ProgressIndicators/components/LoadingSection";

export default function VendorList({ diversityVendors, isLoading, contract }) {
	return (
		<Flex
			p={portalUser.styles?.pagePadding}
			bg={portalUser.styles?.pageBG}
			direction="column"
			flex={1}
			justify="flex-start"
			w="full"
			h="full"
		>
			<Stack
				align="flex-start"
				w="full"
				h="full"
				flex={1}
				spacing={portalUser.styles?.pageSpacing}
				_hover={{ cursor: "pointer" }}
			>
				{isLoading ? (
					<LoadingSection />
				) : (
					<Section
						section={
							new SectionObj(
								new SectionTemplateDiversityVendors(
									"VendorDiversityAvailableVendors",
									diversityVendors,
									isLoading
								)
							)
						}
					/>
				)}
			</Stack>
		</Flex>
	);
}
