import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { log } from "../../../helperFunctions";
export default function VirtualTableExpanderCell({
	rowIsExpanded = false,
	toggleRowExpanded,
	toggleAllRowsExpanded,
	resetRowHeights,
	borderRightWidth = 1,
}) {
	return (
		<Flex
			justify="center"
			align="center"
			h="full"
			isTruncated
			w={"full"}
			maxW="40px"
			minW="40px"
			flex={1}
			px={1}
			py={1}
			cursor="pointer"
			borderRightColor={"gray.500"}
			borderRightWidth={borderRightWidth}
			onClick={(ev) => {
				ev.stopPropagation();
				toggleAllRowsExpanded(false);
				toggleRowExpanded(!rowIsExpanded);
				resetRowHeights();
			}}
		>
			<Text
				key={"fas " + rowIsExpanded ? "fa-minus" : "fa-plus"}
				w="full"
				textAlign="center"
				fontSize="lg"
				fontFamily="body"
				color="gray.400"
				rounded={5}
				px={1}
				_hover={{
					color: "teal.500",
					bg: "white",
				}}
			>
				<i className={"fas " + (rowIsExpanded ? "fa-minus" : "fa-plus")} />
			</Text>
		</Flex>
	);
}
