import React from "react";
import {
	Box,
	Text,
	Button,
	Modal as ChakraModal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Portal,
	HStack,
} from "@chakra-ui/react";

export default function Modal({ isOpen, onClose, size, title, subtitle, includeFooter, footer, children, onSubmit }) {
	return (
		<ChakraModal isOpen={isOpen} onClose={onClose} size={size ?? "lg"}>
			<Portal>
				<ModalOverlay />
				<ModalContent>
					{title && (
						<ModalHeader>
							<Box>
								<Text fontSize="2xl" textTransform="uppercase" letterSpacing={2} color="gray.500">
									{title}
								</Text>
								{subtitle && <Text color="gray.400">{subtitle}</Text>}
							</Box>
						</ModalHeader>
					)}
					<ModalCloseButton />
					<ModalBody>{children}</ModalBody>
					{(includeFooter ?? true) && (
						<ModalFooter p={3}>
							{footer ? (
								footer
							) : (
								<HStack spacing={3}>
									<Button
										variant="outline"
										colorScheme="red"
										border={"2px"}
										size="md"
										onClick={onClose}
									>
										<HStack align="center" spacing={3}>
											<i className="fas fa-times fa-lg"></i>
											<Text textTransform="uppercase" letterSpacing={1}>
												Close
											</Text>
										</HStack>
									</Button>
									{onSubmit && (
										<Button
											border={"2px"}
											colorScheme="blue"
											borderColor="blue.600"
											size="md"
											onClick={onSubmit}
										>
											<HStack align="center" spacing={3}>
												<i className="fas fa-save fa-lg"></i>
												<Text textTransform="uppercase" letterSpacing={2}>
													Save
												</Text>
											</HStack>
										</Button>
									)}
								</HStack>
							)}
						</ModalFooter>
					)}
				</ModalContent>
			</Portal>
		</ChakraModal>
	);
}
