// import { convertArrayToMap, formatValue, log } from "../../../../../helperFunctions";
import Column from "../../../core/Reports/classes/Column";
// import moment from "moment";

export default class AppMenu {
	constructor(routes = []) {
		this.type = "list";
		this.columns = this.getColumns(columns);
		this.data = this.getData(routes);

		this.groupBy = ["group", "basepath"];
		this.filterBy = [{ id: "path", value: "/" }];
		this.sortBy = [{ id: "index", desc: false }];
	}

	getColumns(columns = []) {
		let newColumns = [];
		if (columns?.length > 0) {
			for (let i = 0; i < columns?.length; i++) {
				newColumns.push(new Column(columns[i]));
			}
		}
		return newColumns;
	}

	getData(routes = []) {
		let data = [];
		if (routes?.length > 0) {
			for (let i = 0; i < routes?.length; i++) {
				let appRoute = new AppRoute(routes[i]);
				appRoute.index = i;
				data.push(appRoute);
			}
		}
		return data;
	}
}

class AppRoute {
	constructor(route) {
		this.index = route?.index ?? 0;
		this.group = route?.group ?? "group";
		this.name = route?.name ?? "name";
		this.icon = route?.icon ?? "fas fa-sitemap";
		this.basepath = route?.basepath ?? "/";
		this.path = route?.path ?? "/";
		this.fullpath = (route?.basepath ?? "/") + (route?.path ?? "/");
	}
}

const columns = [
	{
		accessor: "group",
		Header: "Menu Group",
	},
	{
		accessor: "icon",
		Header: "Menu Icon",
	},
	{
		accessor: "name",
		Header: "Menu Name",
	},
	{
		accessor: "basepath",
		Header: "Base Path",
	},
	{
		accessor: "path",
		Header: "Relative Path",
		filter: "notequals",
	},
	{
		accessor: "fullpath",
		Header: "Full Path",
	},
];
