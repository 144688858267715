import React, {
	useRef,
	// useEffect, useCallback
} from "react";
import { VariableSizeList } from "react-window";
import { Flex } from "@chakra-ui/react";

export default function TableBody({
	getTableBodyProps,
	tableBodyHeight,
	getTableBodyRow,
	getTableBodyRowHeight,
	rowCount,
	// tableBodyWidth,
	// rowHeight = 40,
	// sliceType = "first",
}) {
	const ref = useRef(null);

	// // Scroll to top/bottom
	// useEffect(() => {
	// 	if (Boolean(ref?.current) && sliceType === "last") {
	// 		let index = rowCount - 1;
	// 		ref?.current?.scrollToItem(index);
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	// const resetRowHeights = useCallback(() => {
	// 	if (Boolean(ref?.current)) {
	// 		ref?.current.resetAfterIndex(0, false);
	// 	}
	// }, [ref]);

	// useEffect(() => {
	// 	resetRowHeights();
	// }, [resetRowHeights]);

	return (
		<Flex {...getTableBodyProps} direction="column" flex={1} borderBottomWidth={1} borderBottomColor="gray.600">
			<VariableSizeList
				ref={ref}
				itemCount={rowCount}
				height={tableBodyHeight}
				itemSize={getTableBodyRowHeight}
				style={{
					overflowX: "hidden",
					width: "inherit",
					maxWidth: "inherit",
					backgroundColor: "white",
					borderStyle: "inset",
				}}
				overscanCount={10}
			>
				{getTableBodyRow}
			</VariableSizeList>
		</Flex>
	);
}
