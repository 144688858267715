import ExampleContainer from "../common/ExampleContainer";
import ProgressStepsExample from "../components/ProgressStepsExample";
import TimelineExample from "../components/TimelineExample";

export default function ProgressExamplesPage() {
	return (
		<>
			<ExampleContainer
				heading={"Progress Steps"}
				subheading={"Progress Steps"}
				children={<ProgressStepsExample />}
			/>
			<ExampleContainer heading={"Progress Steps"} subheading={"Timeline Steps"} children={<TimelineExample />} />
		</>
	);
}
