import React, { useState, useCallback, useMemo } from "react";
import { Flex, VStack } from "@chakra-ui/react";

import MainContent from "../../legacy/App/components/MainContent";
import CarouselTabs from "../Layout/tabs/CarouselTabs";

import TabPanelContainer from "./common/TabPanelContainer";
import ContainerExamplesPage from "./pages/ContainerExamplesPage";
import ProgressExamplesPage from "./pages/ProgressExamplesPage";
import FormInputExamplesPage from "./pages/FormInputExamplesPage";
import ChartExamplesPage from "./pages/ChartExamplesPage";

import AnimateExample from "./components/AnimateExample";
import HoverAndTapExample from "./components/HoverAndTapExample";
import DragAndDropExample from "./components/DragAndDropExample";
import SpringAndFollowExample from "./components/SpringAndFollowExample";
import CollapseHorizontalExample from "./components/CollapseHorizontalExample";
import CarouselExample from "./components/CarouselExample";

import { convertArrayToMap, log } from "../../helperFunctions";

export default function Examples({ pageName, type = "chakraUI" }) {
	const [viewerMaxHeight, setViewerMaxHeight] = useState(1200);
	const [[currentTab, direction], setCurrentTab] = useState([0, 0]);

	const setTab = useCallback(
		(newTab, newDirection) => {
			if (!newDirection) newDirection = newTab - currentTab;
			setCurrentTab([newTab, newDirection]);
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		},
		[currentTab]
	);

	const tabs = useMemo(() => {
		let pagesMap = convertArrayToMap(pages, "type", true);
		return pagesMap[type];
	}, [type]);

	return (
		<MainContent currentTab={currentTab} onTabChange={setTab} title={pageName}>
			<Flex bg="blackAlpha.500" direction="column" flex={1} justify="flex-start" w="full" h="full" pt={4} px={2}>
				<CarouselTabs
					tabs={tabs}
					currentTab={currentTab}
					direction={direction}
					setTab={setTab}
					viewerMaxHeight={viewerMaxHeight}
					setViewerMaxHeight={setViewerMaxHeight}
				>
					<TabPanelContainer
						// heading={pageName}
						heading={tabs[currentTab]?.heading}
						subheading={tabs[currentTab]?.name}
						children={
							<VStack spacing={8} p={8} w="full" h="full" flex={1}>
								{tabs[currentTab].content}
							</VStack>
						}
					/>
				</CarouselTabs>
			</Flex>
		</MainContent>
	);
}

const pages = [
	{
		type: "theme",
		heading: "Theme & Colors",
		name: "Theme Colors",
		content: <ContainerExamplesPage heading={"Theme Colors"} example="theme" />,
	},

	{
		type: "ui",
		heading: "UI Components",
		name: "Container Widths",
		content: <ContainerExamplesPage heading={"Container Sizes"} example="widths" />,
	},
	{
		type: "ui",
		heading: "UI Components",
		name: "Container Heights",
		content: <ContainerExamplesPage heading={"Container Sizes"} example="heights" />,
	},

	{
		type: "ui",
		heading: "UI Components",
		name: "Progress Steps",
		content: <ProgressExamplesPage />,
	},
	{
		type: "ui",
		heading: "UI Components",
		name: "Form Inputs",
		content: <FormInputExamplesPage heading={"Form Inputs"} />,
	},
	{
		type: "ui",
		heading: "UI Components",
		name: "Charts & Graphs",
		content: <ChartExamplesPage />,
	},
	{
		type: "motion",
		heading: "Framer Motion",
		name: "Animate",
		content: <AnimateExample heading={"Animate Example"} />,
	},
	{
		type: "motion",
		heading: "Framer Motion",
		name: "Hover & Tap",
		content: <HoverAndTapExample heading={"Hover & Tap Example"} />,
	},
	{
		type: "motion",
		heading: "Framer Motion",
		name: "Drag & Drop",
		content: <DragAndDropExample heading={"Drag & Drop Example"} />,
	},
	{
		type: "motion",
		heading: "Framer Motion",
		name: "Horizontal Collapse",
		content: <CollapseHorizontalExample heading={"Collapse Horizontal Example"} />,
	},
	{
		type: "motion",
		heading: "Framer Motion",
		name: "Carousel",
		content: <CarouselExample heading={"Carousel Example"} />,
	},
	{
		type: "motion",
		heading: "Framer Motion",
		name: "Spring & Follow",
		content: <SpringAndFollowExample heading={"Spring & Follow Example"} />,
	},
];
