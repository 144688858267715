import React from "react";
import { Switch } from "@chakra-ui/react";

export default function SwitchInput(props) {
	return (
		<Switch
			colorScheme="teal"
			className={props.className}
			mb={0}
			name={props.name}
			value={props.value}
			onChange={props.onChange}
			isChecked={Boolean(props.value)}
			size={props.size}
			onClick={(ev) => {
				ev.stopPropagation();
			}}
			readOnly={props.readOnly}
		/>
	);
}
