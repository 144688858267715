import React from "react";
import { Center, Heading, VStack } from "@chakra-ui/react";

import { motion } from "framer-motion";

export default function AnimateExample({ heading, subHeading }) {
	const style = {
		width: "200px",
		height: "200px",
		background: "var(--chakra-colors-teal-600)",
	};

	return (
		<Center w="full" minH="sm" h="full" bg="white" rounded={5} p={8}>
			<Center border="3px dashed" borderColor="gray.200" rounded="xl" h="full" w="full">
				<VStack spacing={4} flex={1} w="full" pb={48}>
					<Heading color="gray.500" textTransform="uppercase" letterSpacing={6} size="xl" pt={16}>
						{subHeading}
					</Heading>
					<Heading color="gray.400" textTransform="uppercase" letterSpacing={6} size="3xl" pb={16}>
						{heading}
					</Heading>
					<motion.div
						style={style}
						animate={{
							scale: [0.5, 1, 1, 0.5, 0.5],
							rotate: [0, 0, 180, 180, 0],
							borderRadius: ["0%", "0%", "50%", "50%", "0%"],
						}}
						transition={{
							duration: 2,
							ease: "easeInOut",
							times: [0, 0.2, 0.5, 0.8, 1],
							repeat: Infinity,
							repeatDelay: 1,
						}}
					/>
				</VStack>
			</Center>
		</Center>
	);
}
