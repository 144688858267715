import React, { useMemo, useEffect, useState, useRef } from "react";
import { TextareaAutosize } from "@material-ui/core";

export default function TextAreaInput(props) {
	const [maxHeight, setMaxHeight] = useState("auto"); // Start with a default maxHeight
	const textareaRef = useRef(); // Create a ref for the textarea if not passed

	useEffect(() => {
		if (textareaRef.current) {
			const currentHeight = textareaRef.current.scrollHeight; // Get the current scroll height
			setMaxHeight(`${currentHeight}px`); // Set maxHeight based on scrollHeight
		} else {
			setMaxHeight(null);
		}
	}, [props.inputRef]); // Run when inputRef changes

	const textareaStyle = useMemo(() => {
		return {
			width: "100%",
			maxHeight: maxHeight,
			fontSize: "var(--chakra-fontSizes-md)",
			lineHeight: "var(--chakra-lineHeights-short)",
			border: props.isInvalid ? "2px solid" : props.inputBorder ?? "1px solid",
			borderColor: props.isInvalid ? "var(--chakra-colors-red-500)" : "inherit",
			background: props.inputBG ?? "white",
			borderRadius: props.inputBorderRadius ?? "var(--chakra-radii-md)",
			fontFamily: "'Helvetica',sans-serif",
			paddingTop: props.inputPaddingTop ?? "var(--chakra-space-2)",
			paddingBottom: props.inputPaddingBottom ?? "var(--chakra-space-2)",
			paddingLeft: props.inputPaddingLeft ?? "var(--chakra-space-2)",
			paddingRight: props.inputPaddingRight ?? "var(--chakra-space-2)",
			cursor: Boolean(props.readOnly ?? props.isReadOnly) ? "not-allowed" : "",
		};
	}, [
		maxHeight,
		props.inputBG,
		props.inputBorder,
		props.inputBorderRadius,
		props.inputPaddingBottom,
		props.inputPaddingLeft,
		props.inputPaddingRight,
		props.inputPaddingTop,
		props.isInvalid,
		props.isReadOnly,
		props.readOnly,
	]);

	// const handlePaste = (e) => {
	// 	e.preventDefault();
	// 	const paste = e.clipboardData.getData("text");
	// 	const textarea = props.inputRef?.current || textareaRef.current;

	// 	if (textarea) {
	// 		const currentValue = textarea.value;
	// 		const start = e.target.selectionStart;
	// 		const end = e.target.selectionEnd;
	// 		const newValue = currentValue.slice(0, start) + paste + currentValue.slice(end);
	// 		textarea.value = newValue;
	// 		e.target.setSelectionRange(start + paste.length, start + paste.length);
	// 		textarea.scrollTop = 0;

	// 		if (props.onChange) {
	// 			const event = new Event("input", { bubbles: true });
	// 			textarea.dispatchEvent(event);
	// 		}
	// 	}
	// };

	// const handleInput = (e) => {
	// 	// Force scroll position to stay stable
	// 	const { scrollTop } = e.target;

	// 	// Update state or perform actions after the content changes
	// 	if (props.onChange) {
	// 		props.onChange(e);
	// 	}

	// 	// Restore the scroll position after content changes (e.g., deletions)
	// 	e.target.scrollTop = scrollTop;
	// };

	// Disable scrolling for the entire page when the textarea is focused
	const disablePageScroll = () => {
		document.body.style.overflow = "hidden"; // Disable scroll
	};

	// Enable scrolling when the textarea loses focus
	const enablePageScroll = () => {
		document.body.style.overflow = ""; // Restore scroll
	};

	// Handle page scroll disabling/enabling on focus/blur of the textarea
	useEffect(() => {
		const textarea = textareaRef.current;

		if (textarea) {
			textarea.addEventListener("focus", disablePageScroll);
			textarea.addEventListener("blur", enablePageScroll);
		}

		// return () => {
		// 	if (textarea) {
		// 		textarea.removeEventListener("focus", disablePageScroll);
		// 		textarea.removeEventListener("blur", enablePageScroll);
		// 	}
		// 	enablePageScroll(); // Ensure scroll is re-enabled on unmount
		// };
	}, [props.inputRef]);

	return (
		<TextareaAutosize
			key={props.defaultValue + "-" + props?.inputRef?.current?.value}
			ref={textareaRef ?? props.inputRef}
			required={props.isRequired}
			className={props.error ? " focusBorderColorRed" : " focusBorderColorTeal"}
			rowsMin={props.rowsMin ? props.rowsMin : 1}
			style={{
				...textareaStyle,
				...(Boolean(props.readOnly ?? props.isReadOnly) ? {} : { overflow: "auto" }),
			}}
			placeholder={props.placeholder}
			name={props.name}
			defaultValue={props.defaultValue}
			value={props.value}
			onChange={props.onChange}
			onBlur={props.onBlur}
			// onChange={props.preventScroll ? handleInput : props.onChange}
			// onPaste={handlePaste}
			// onWheel={props.onBlur}
			// onScroll={props.onBlur}
			// onWheel={() => props?.inputRef?.current?.blur()}
			onScroll={() => props?.inputRef?.current?.blur()}
			size={props.size}
			readOnly={props.readOnly ?? props.isReadOnly}
			{...props.inputProps}
			autoComplete="off"
			maxLength={props.maxLength ?? undefined}
		/>
	);
}
