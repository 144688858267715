import React, { useMemo } from "react";
import { IconButton, Tooltip } from "@chakra-ui/react";
import { convertArrayToMap } from "../../../helperFunctions";

export default function ToggleButton({
	toggleIsOpen = true,
	onClick,
	toggleOptions = [
		{
			isOpen: true,
			label: "Collapse",
			icon: "fas fa-minus",
			color: "gray.500",
			bg: "whiteAlpha.700",
			hover: null,
			focus: null,
		},
		{
			isOpen: false,
			label: "Expand",
			icon: "fas fa-plus",
			color: "gray.500",
			bg: "whiteAlpha.700",
			hover: null,
			focus: null,
		},
	],
}) {
	const option = useMemo(() => {
		let optionMap = convertArrayToMap(toggleOptions, "isOpen");
		return optionMap[toggleIsOpen];
	}, [toggleIsOpen, toggleOptions]);

	return (
		<>
			{Boolean(onClick) && (
				<Tooltip
					key={option?.icon ?? "fas fa-plus"}
					placement="top"
					label={option?.label ?? "Expand"}
					aria-label={option?.label ?? "Expand"}
				>
					<IconButton
						size={option?.size ?? "md"}
						tabIndex={option?.tabIndex}
						_focus={option?.focus && option?.focus}
						_hover={option?.hover && option?.hover}
						color={option?.color ?? "gray.500"}
						onClick={Boolean(onClick) && onClick}
						icon={<i className={"fas " + (option?.icon ?? "fas fa-plus") + " fa-lg"} />}
						bg={option?.bg ?? "whiteAlpha.700"}
						borderWidth={option?.borderWidth ?? 0}
						borderColor={option?.borderColor ?? "whiteAlpha.900"}
					/>
				</Tooltip>
			)}
		</>
	);
}
