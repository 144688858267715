import React from "react";
import { Box } from "@chakra-ui/react";
import TimecardEntryListRow from "./TimecardEntryListRow";
import VirtualListV1 from "../common/VirtualListV1";

import TimecardClassLegacy from "../classes/TimecardClassLegacy";
import EARN_CODES from "../constants/EarnCodes";

import { v4 } from "uuid";

export default class TimecardEntryList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			payweek: null,
			prEndDate: this.props.prEndDate,
			payPeriods: this.props.payPeriods,
			hoveringAdd: false,
		};

		this.loadHours = this.loadHours.bind(this);
		this.handleOnHover = this.handleOnHover.bind(this);
		this.handleOffHover = this.handleOffHover.bind(this);
		this.addHours = this.addHours.bind(this); // Requires same function in parent to handle response
		this.updateTimecardItem = this.updateTimecardItem.bind(this); // Requires same function in parent to handle response
		this.sortHours = this.sortHours.bind(this);
	}

	componentDidMount() {
		if (this.props.timecardList) {
			this.setState({
				payweek: this.props.timecardList,
				prEndDate: this.props.prEndDate,
				payPeriods: this.props.payPeriods,
			});

			if (this.props.readyCallback) {
				this.props.readyCallback(true);
			}
		} else {
			this.loadHours();
		}
	}

	componentDidUpdate() {
		if (this.props?.prEndDate !== this.state?.prEndDate) {
			this.setState({
				payweek: null,
				prEndDate: this.props.prEndDate,
			});
			if (this.props.timecardList) {
				this.setState({
					payweek: this.props.timecardList,
				});
				if (this.props.readyCallback) {
					this.props.readyCallback(true);
				}
			} else {
				this.loadHours();
			}
		}
	}

	async loadHours() {
		let payweek = new TimecardClassLegacy(this.props.employee, this.state.prEndDate);
		await payweek.loadPayweekTime();

		this.setState({
			payweek: payweek,
		});

		if (this.props.readyCallback) {
			this.props.readyCallback(true);
		}
	}

	handleOnHover() {
		this.setState({ hoveringAdd: true });
	}

	handleOffHover() {
		this.setState({ hoveringAdd: false });
	}

	addHours(ev) {
		ev.stopPropagation();
		this.props.addHours();
		if (this.props.triggerTableUpdate) {
			this.props.triggerTableUpdate();
		}
	}

	updateTimecardItem(update) {
		update.employee = this.props.employee;
		this.props.updateTimecardItem(update);
		if ((update.attr === "delete" || update.attr === "hours") && this.props.triggerTableUpdate) {
			this.props.triggerTableUpdate();
		}
	}

	sortHours(ev, sortBy) {
		ev.stopPropagation();
		if (this.props.sortHours) {
			this.props.sortHours(sortBy);
		}
	}

	render() {
		let disabledEarnCodes = [];
		let disabledJobs = [];
		let disabledPhases = [];

		//MOVE TO TIMECARD HEADER
		for (let i = 0; i < this.props.timecardList?.payweekHours.length; i++) {
			for (let j = i + 1; j < this.props.timecardList?.payweekHours.length; j++) {
				if (this.props.timecardList?.payweekHours[i].earnCode >= 4) {
					disabledEarnCodes.push(
						EARN_CODES.find(({ value }) => value === this.props.timecardList?.payweekHours[i].earnCode)?.key
					);
				}

				if (
					this.props.timecardList?.payweekHours[i].earnCode ===
						this.props.timecardList?.payweekHours[j].earnCode &&
					this.props.timecardList?.payweekHours[i].job === "N/A"
				) {
					disabledJobs.push(this.props.timecardList?.payweekHours[i].job);
				}

				if (
					this.props.timecardList?.payweekHours[i].earnCode ===
						this.props.timecardList?.payweekHours[j].earnCode &&
					this.props.timecardList?.payweekHours[i].job === this.props.timecardList?.payweekHours[j].job &&
					this.props.timecardList?.payweekHours[i].shift === this.props.timecardList?.payweekHours[j].shift
				) {
					disabledPhases.push(this.props.timecardList?.payweekHours[i].phase);
				}

				if (
					this.props.timecardList?.payweekHours[i].earnCode ===
						this.props.timecardList?.payweekHours[j].earnCode &&
					this.props.timecardList?.payweekHours[i].job === this.props.timecardList?.payweekHours[j].job &&
					this.props.timecardList?.payweekHours[i].phase === this.props.timecardList?.payweekHours[j].phase &&
					this.props.timecardList?.payweekHours[i].shift === this.props.timecardList?.payweekHours[j].shift
				) {
					disabledEarnCodes.push(
						EARN_CODES.find(({ value }) => value === this.props.timecardList?.payweekHours[i].earnCode)?.key
					);
				}
			}
		}

		let timecardListRows = [];

		for (var i = 0; i < this.props.timecardList?.payweekHours?.length; i++) {
			timecardListRows.push(
				<TimecardEntryListRow
					//KEYS
					key={this.props.timecardList.payweekHours?.keyId}
					rowIndex={i}
					//TIMECARD JOBS
					timecardJobs={this.props.timecardJobs}
					//PAY PERIODS
					payPeriods={this.state.payPeriods}
					// currentPayPeriod={this.state.currentPayPeriod} //Not Needed

					//PR EMPLOYEES
					employee={this.props.employee}
					//TIMECARD
					timecardHeader={this.props.timecardList?.timecardHeader} //TIMECARD HEADER
					payweekHours={this.props.timecardList?.payweekHours} //TIMECARD ROWS
					item={this.props.timecardList?.payweekHours[i]} //TIMECARD ROW
					//DISABLED
					disabledPhases={disabledPhases}
					disabledEarnCodes={disabledEarnCodes}
					disabledJobs={disabledJobs}
					//FUNCTIONS
					saveTimecard={this.props.saveTimecard}
					updateTimecardItem={this.updateTimecardItem}
					getAvailableJobPhases={this.props.getAvailableJobPhases}
					//VIEWS
					dataView={this.props.dataView}
				/>
			);
		}

		return (
			<Box overflowX="hidden" overflowY="hidden" direction="column" align="center" width="full">
				<VirtualListV1 key={v4()} listHeight={800} rowHeight={80} data={timecardListRows} customRows={true} />
			</Box>
		);
	}
}
