// import { getSubtotal, getRate } from "../../../../../helperFunctions";
import { portalUser } from "../../../App";
export default class SectionTemplateVendors {
	constructor(template = "VendorDiversity", vendors = [], isLoaded = true) {
		this.template = template;
		this.name = "Vendors & Subcontractors";
		this.heading = "Vendors & Subcontractors";
		this.pagebreak = 0;
		this.wrap = true;
		this.type = "virtualTable";
		this.minRows = 1;
		this.maxRows = 21;
		if (template === "Vendors") {
			this.subSections = [new SubSectionTemplateVendors()];
			this.isLoaded = true;
		} else if (template === "VendorDiversity") {
			this.subSections = [new SubSectionTemplateVendorDiversity(template, vendors, isLoaded, this.heading)];
			this.isLoaded = isLoaded;
		}
	}
}

class SubSectionTemplateVendors {
	constructor() {
		let data = portalUser?.getAPVendors();

		this.template = "Vendors";
		this.type = "virtualTable";
		this.show = data?.length > 0 ? true : false;
		this.autoExpandRows = true;

		this.columns = [
			{
				accessor: "apvmKeyID",
				Header: "Key ID",
				show: false,
				disableExport: true,
				width: "65%",
			},
			{
				accessor: "vendorGroup",
				Header: "Vendor Group",
				valueType: "integer",
				width: "65%",
				textAlign: "center",
			},
			{
				accessor: "vendor",
				Header: "Vendor",
				valueType: "integer",
				width: "65%",
				textAlign: "center",
			},
			{
				accessor: "vendorName",
				Header: "Vendor Name",
				width: "300%",
			},
			{
				accessor: "vendorPhone",
				Header: "Phone #",
			},
			{
				accessor: "poAddress",
				Header: "Address",
				width: "200%",
			},
			{
				accessor: "poCity",
				Header: "City",
				width: "150%",
			},
			{
				accessor: "poState",
				Header: "State",
				width: "65%",
				textAlign: "center",
				filter: "multiselect",
			},
			{
				accessor: "poZip",
				Header: "Zip",
				width: "80%",
			},
		];

		this.data = data;

		// let sortGroupFilter = this.getSortGroupFilter(jobCosts);

		// this.loopBy = sortGroupFilter.loopBy;
		// this.groupBy = sortGroupFilter.groupBy;
		// this.filterBy = sortGroupFilter.filterBy;
		// this.sortBy = sortGroupFilter.sortBy;

		// this.footer = this.getFooter(jobCosts);
	}

	// getSortGroupFilter(jobCosts = []) {
	// 	let loopBy = [
	// 		{ id: "costType", heading: "costTypeDesc" },
	// 		// { id: "basePhase", heading: "basePhaseDesc" },
	// 	];
	// 	// let groupBy = [];
	// 	let groupBy = ["basePhase"];
	// 	let sortBy = [
	// 		{ id: "costType", desc: false },
	// 		{ id: "jobPhase", desc: false },
	// 	];
	// 	let filterBy = [];

	// 	let keys = [...new Set(jobCosts?.map((data) => data.jccmKeyID))];
	// 	if (keys.length > 1) {
	// 		groupBy = ["jccmKeyID"];
	// 		sortBy = [
	// 			{ id: "co", desc: false },
	// 			{ id: "contract", desc: false },
	// 			{ id: "costType", desc: false },
	// 			{ id: "jobPhase", desc: false },
	// 		];
	// 	}
	// 	let sortGroupFilter = { loopBy: loopBy, sortBy: sortBy, groupBy: groupBy, filterBy: filterBy };
	// 	return sortGroupFilter;
	// }

	// getFooter(rows = [], totals = null) {
	// 	let rowCount = rows?.length ?? 0;
	// 	let footer = {
	// 		jccmKeyID: Boolean(rows[0]?.queryData === "JobCostDetailByMth")
	// 			? rowCount
	// 			: Boolean(totals)
	// 			? totals[0]?.jccmKeyID
	// 			: rowCount,
	// 		basePhaseDesc: "Total",
	// 		basePhaseDescTextAlign: "right",
	// 		costType: Boolean(totals?.queryData === "JobCostDetailByMth")
	// 			? rowCount + "/" + rowCount
	// 			: rowCount + (Boolean(totals) ? "/" + totals[0]?.jccmKeyID : "/" + rowCount),
	// 		costTypeTextAlign: "center",

	// 		origEstHours: Boolean(rows.length > 0) ? getSubtotal(rows, "origEstHours") : 0,
	// 		origEstCost: Boolean(rows.length > 0) ? getSubtotal(rows, "origEstCost") : 0,
	// 		currEstHours: Boolean(rows.length > 0) ? getSubtotal(rows, "currEstHours") : 0,
	// 		currEstCost: Boolean(rows.length > 0) ? getSubtotal(rows, "currEstCost") : 0,
	// 		actualHours: Boolean(rows.length > 0) ? getSubtotal(rows, "actualHours") : 0,
	// 		actualCost: Boolean(rows.length > 0) ? getSubtotal(rows, "actualCost") : 0,
	// 		projHours: Boolean(rows.length > 0) ? getSubtotal(rows, "projHours") : 0,
	// 		projCost: Boolean(rows.length > 0) ? getSubtotal(rows, "projCost") : 0,
	// 	};

	// 	return [footer];
	// }
}

class SubSectionTemplateVendorDiversity {
	constructor(template, vendors = [], isLoaded, heading) {
		let show = vendors?.length > 0 ? true : false;
		this.type = "virtualTable";
		this.autoExpandRows = true;

		this.template = template;
		this.type = "virtualTable";
		this.show = show;
		this.heading = heading;

		this.footer = this.getFooter(vendors);
		this.wrap = true;

		this.columns = [
			{
				accessor: "apvmKeyID",
				Header: "Key ID",
				show: false,
				disableExport: true,
				width: "65%",
			},
			{
				accessor: "vendorShowHideYN",
				Header: "Show",
				filter: "multiselect",
				textAlign: "center",
				show: false,
			},
			{
				accessor: "vendorGroup",
				Header: "Co",
				valueType: "integer",
				width: "65%",
				textAlign: "center",
			},
			{
				accessor: "vendorID",
				Header: "Vendor",
				valueType: "integer",
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},
			{
				accessor: "vendorName",
				Header: "Vendor Name",
				width: "300%",
			},
			{
				accessor: "vendorPhone",
				Header: "Phone #",
				show: false,
			},
			{
				accessor: "poAddress",
				Header: "Address",
				width: "200%",
				show: false,
			},
			{
				accessor: "poCity",
				Header: "City",
				width: "150%",
				show: false,
			},
			{
				accessor: "poState",
				Header: "State",
				width: "65%",
				textAlign: "center",
				filter: "multiselect",
				show: false,
			},
			{
				accessor: "poZip",
				Header: "Zip",
				width: "80%",
				show: false,
			},

			{
				accessor: "isDiversityVendorYN",
				Header: "Diversity Cert Y/N",
				textAlign: "center",
			},
			{
				accessor: "diversityServiceType",
				Header: "Service",
				textAlign: "center",
				width: "150%",
			},
			{
				accessor: "sbeStatus",
				Header: "SBE",
				textAlign: "center",
			},
			{
				accessor: "lbeStatus",
				Header: "LBE",
				textAlign: "center",
			},
			{
				accessor: "mbeStatus",
				Header: "MBE",
				textAlign: "center",
			},
			{
				accessor: "wbeStatus",
				Header: "WBE",
				textAlign: "center",
			},
			{
				accessor: "dbeStatus",
				Header: "DBE",
				textAlign: "center",
			},
			{
				accessor: "dvbeStatus",
				Header: "DVBE",
				textAlign: "center",
			},
			{
				accessor: "cbeStatus",
				Header: "CBE",
				textAlign: "center",
			},
			{
				accessor: "lsbeStatus",
				Header: "LSBE",
				textAlign: "center",
			},
			{
				accessor: "diversityContact",
				Header: "Diversity Contact",
				width: "175%",
			},
			{
				accessor: "diversityEmail",
				Header: "Diversity Contact Email",
				width: "250%",
			},
			{
				accessor: "diversityPhone",
				Header: "Diversity Contact Phone",
				show: false,
				width: "125%",
			},
		];

		this.data =
			vendors?.filter((vendor, index, self) => index === self.findIndex((v) => v.vendorID === vendor.vendorID)) ??
			[];

		this.filterBy = [{ id: "vendorShowHideYN", value: "Y" }];

		this.sortBy = [
			// { id: "vendorShowHideYN", desc: true },
			{ id: "isDiversityVendorYN", desc: true },
			{ id: "vendorGroup", desc: false },
			{ id: "vendorName", desc: false },
		];
	}

	getFooter(rows = [], totals = null) {
		let rowCount = rows?.length ?? 0;
		let footer = {
			vendorID: Boolean(totals?.queryData === "Vendor")
				? rowCount + "/" + rowCount
				: rowCount + (Boolean(totals) ? "/" + totals[0]?.vendorGroup : "/" + rowCount),
			vendorIDTextAlign: "right",
			vendorName: "Vendors",
		};

		return [footer];
	}
}
