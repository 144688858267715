import React from "react";
import {
	Box,
	Flex,
	Center,
	Tr,
	Td,
	Button,
	Text,
	Heading,
	Textarea,
	Tooltip,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	ModalCloseButton,
	Badge,
	Stack,
	IconButton,
	HStack,
	Modal as ChakraModal,
	List,
	ListItem,
	Grid,
} from "@chakra-ui/react";
import TimecardHeaderCard from "./TimecardEntryCard";
import TimecardHeaderNotesModal from "../components/TimecardHeaderNotesModal";
import ToastPopup from "../../../core/Alerts/ToastPopup";
import VerticalLabelValue from "../common/VerticalLabelValue";
import TimecardClassLegacy from "../classes/TimecardClassLegacy";
import $ from "jquery";
import { v4 } from "uuid";
import { portalUser } from "../../../App";
import { getDeviceType, arrayToString, convertArrayToMap, formatValue } from "../../../helperFunctions";

export default class PayrollUploadTableRow extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			expanded: false,
			visible: this.props.visible,
			hidden: this.props.hidden,
			expandedRowHeight: 0,
			timecardEntryLoaded: false,
			rowHeight: this.props.rowHeight,
			timecardList: null,
			member: null,
			uploadStatus: null,
			updates: null,
			updateTitle: "",
			notesOpen: false,
			dayNum: 0,
			viewTimecard: false,
		};

		this.toggleExpanded = this.toggleExpanded.bind(this);
		this.approveTime = this.approveTime.bind(this);
		this.timecardEntryReady = this.timecardEntryReady.bind(this);
		this.loadTimecard = this.loadTimecard.bind(this);
		this.selectMember = this.selectMember.bind(this);
		this.openWeekNotes = this.openWeekNotes.bind(this);
		this.addHours = this.addHours.bind(this);
		this.updateTimecardItem = this.updateTimecardItem.bind(this);
		this.toastShown = this.toastShown.bind(this);
		this.handleUploadRetry = this.handleUploadRetry.bind(this);
		this.openNotes = this.openNotes.bind(this);
		this.closeNotes = this.closeNotes.bind(this);
		this.updateWeekNotes = this.updateWeekNotes.bind(this);
		this.updateRowHeight = this.updateRowHeight.bind(this);
		this.openTimecard = this.openTimecard.bind(this);
		this.closeTimecard = this.closeTimecard.bind(this);
	}

	componentDidMount() {
		if (this.props.member.timecard) {
			this.setState({
				member: this.props.member,
				timecardList: this.props.member.timecard,
			});
		}
	}

	componentDidUpdate() {
		if (this.props.member.employeeUID !== this.state.member.employeeUID) {
			this.setState({
				member: this.props.member,
				timecardList: this.props.member.timecard,
			});
		}
	}

	toggleExpanded(ev) {
		ev.stopPropagation();
		if (this.state.expanded) {
			if (this.state.timecardEntryLoaded && this.props.dataView === "tableOrig") {
				this.props.addHeight(this.props.rowNum, -this.state.expandedRowHeight);
				this.setState({
					expanded: false,
					expandedRowHeight: 0,
					timecardEntryLoaded: false,
				});
			} else {
				this.setState({
					expanded: false,
					timecardEntryLoaded: false,
				});
			}
		} else {
			if (!this.props.member.timecard) {
				this.loadTimecard();
			}
			this.setState({ expanded: true });
		}
	}

	async loadTimecard() {
		let timecardList = new TimecardClassLegacy(this.props.member, this.props.payPeriods?.currentPeriod?.prEndDate);
		await timecardList.loadPayweekTime();

		this.setState({
			timecardList: timecardList,
		});
	}

	async approveTime(ev, approverNum) {
		ev.stopPropagation();
		this.props.approveTime(this.props.member.timecard.timecardHeader?.keyId, approverNum);
	}

	timecardEntryReady(isReady) {
		if (isReady && !this.state.timecardEntryLoaded) {
			if (this.props.dataView === "tableOrig") {
				let rowEl = $("#timecardEntry-" + this.props.rowNum);

				this.props.addHeight(this.props.rowNum, rowEl.height());
				this.setState({
					expandedRowHeight: rowEl.height(),
					timecardEntryLoaded: true,
				});
			} else {
				this.setState({
					timecardEntryLoaded: true,
				});
			}
		}
	}

	selectMember(ev) {
		ev.stopPropagation();
		this.props.selectMember(this.state.timecardList?.timecardHeader?.keyId);
	}

	openWeekNotes(ev) {
		ev.stopPropagation();
	}

	addHours() {
		let timecardList = this.state.timecardList;
		timecardList.addItem();
		this.setState({ timecardList: timecardList });
	}

	async updateTimecardItem(update) {
		let timecardList = this.state.timecardList;
		let status = await timecardList.updateTimecardEntry(update);

		if (timecardList.payweekHours[update.rowIndex]?.keyId !== undefined || update.attr !== "delete") {
			update.uploadId = v4();
			let statusStr = status ? "Success" : "Error";
			let updateTitle = "";

			if (update.attr === "hours") {
				let days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]; // Move to a 'Constants' file. (See about using .env)
				updateTitle = statusStr + ": " + days[update.hourIndex] + " Hours " + (status ? "Saved" : "Not Saved");
				if (!update.upload) {
					statusStr = null;
				}
			} else if (update.attr === "delete") {
				updateTitle = statusStr + ": Entry Row " + (status ? "Deleted" : "Not Deleted");
			} else {
				updateTitle = statusStr + ": Timecard " + (status ? "Saved" : "Not Saved");
			}

			this.setState({
				uploadStatus: statusStr,
				updates: update,
				updateTitle: updateTitle,
			});
		}
		this.setState({
			timecardList: timecardList,
		});
		if (
			update.attr === "job" &&
			this.props.timecardJobs.find(({ job }) => job === update.value.job).phases === null
		) {
			this.props.getAvailableJobPhases(update.value);
		}
	}

	toastShown() {
		this.setState({
			uploadStatus: null,
			updateTitle: "",
		});
	}

	handleUploadRetry(updates) {
		if (updates.attr === "hours") {
			this.updateTimecardItem(updates);
		} else {
			this.updateTimecardItem(updates);
		}
	}

	openNotes(ev) {
		ev.stopPropagation();
		this.setState({
			notesOpen: true,
		});
	}

	closeNotes() {
		this.setState({
			notesOpen: false,
		});
	}

	async updateWeekNotes(ev) {
		let timecardList = this.state.timecardList;
		let value = ev.target.value;
		// let update = {
		// 	value: value,
		// 	attr: "weekNotes",
		// 	keyId: this.state.timecardList?.timecardHeader?.keyId,
		// 	upload: true,
		// };
		await timecardList.timecardHeader.updateNotes(value);

		this.setState({
			timecardList: timecardList,
		});
	}

	updateRowHeight(rowHeight) {
		this.setState({
			rowHeight: rowHeight,
		});
	}

	openTimecard() {
		this.setState({
			viewTimecard: true,
		});
	}

	closeTimecard() {
		this.setState({
			viewTimecard: false,
		});
	}

	render() {
		var widths = null;
		if (this.props.reviewLevel === "Review") {
			widths = [40, 70, 190, 130, 80, 160, 70, 70, 70, 70, 70, 80, 80, 80, 190, 80];
		} else if (this.props.reviewLevel === "Approval") {
			widths = [40, 0, 190, 130, 80, 160, 70, 70, 70, 70, 70, 80, 80, 80, 0, 80];
		} else {
			widths = [40, 0, 210, 140, 100, 170, 80, 80, 80, 80, 80, 80, 0, 0, 0, 80];
		}

		let totalREG = 0;
		let totalOT = 0;
		let totalDT = 0;
		let totalPTO = 0;
		let totalHours = 0;

		for (let i = 0; i < this.props.member?.activeCompanies?.length; i++) {
			totalREG += this.props.member?.activeCompanies[i]?.timecard?.getTotalTimeByEarnCode(1);
			totalOT += this.props.member?.activeCompanies[i]?.timecard?.getTotalTimeByEarnCode(2);
			totalDT += this.props.member?.activeCompanies[i]?.timecard?.getTotalTimeByEarnCode(3);
			totalPTO +=
				this.props.member?.activeCompanies[i]?.timecard?.getTotalTimeByEarnCode(5) +
				this.props.member?.activeCompanies[i]?.timecard?.getTotalTimeByEarnCode(6) +
				this.props.member?.activeCompanies[i]?.timecard?.getTotalTimeByEarnCode(7);
			totalHours += this.props.member?.activeCompanies[i]?.timecard?.getWeekTotal();
		}

		let approverUID = (
			this.props.member.timecard?.timecardHeader?.approverUID1 ??
			this.props.member.timecard?.timecardHeader?.approverUID2
		)?.toLowerCase();

		let approvedBy = this.props.allMembers?.find(({ employeeUID }) => employeeUID === approverUID)?.name;

		let departments = portalUser.getDepartments();

		let deptMap = convertArrayToMap(departments, "deptID");

		if (this.props.dataView === "table") {
			return (
				<React.Fragment key={"frag-" + this.props.rowNum}>
					<Tr
						onClick={(ev) => {
							this.props.expandMember(ev, this.props.index);
						}}
						bg="white"
						cursor="pointer"
						_hover={{ bg: "blackAlpha.50" }}
					>
						{this.props.reviewLevel === "Review" && (
							<Td
								key={v4()}
								width={widths[0] + "px"}
								maxWidth={widths[0] + "px"}
								minW={widths[0] + "px"}
								px="1"
								isTruncated
							>
								<IconButton variant="ghost" size="sm" onClick={this.selectMember}>
									<i
										style={{
											color: this.props.selectedMembers?.includes(
												this.props.member?.timecard?.timecardHeader?.keyId
											)
												? "var(--chakra-colors-teal-500)"
												: "var(--chakra-colors-gray-500)",
										}}
										className={
											this.props.selectedMembers?.includes(
												this.props.member?.timecard?.timecardHeader?.keyId
											)
												? "fas fa-check-square fa-lg"
												: "far fa-square fa-lg"
										}
									></i>
								</IconButton>
							</Td>
						)}
						{this.props.reviewLevel !== "Review" && (
							<Td
								key={v4()}
								width={widths[0] + "px"}
								maxWidth={widths[0] + "px"}
								minW={widths[0] + "px"}
								cursor="pointer"
								textAlign="center"
								px="1"
								isTruncated
							>
								<IconButton variant="ghost" size="sm">
									<i
										style={{ color: "var(--chakra-colors-gray-500)" }}
										className={
											"fas " + (this.props.member?.timecard?.isExpanded ? "fa-minus" : "fa-plus")
										}
									></i>
								</IconButton>
							</Td>
						)}
						{this.props.reviewLevel === "Review" && (
							<Td
								px="1"
								width={widths[1] + "px"}
								maxWidth={widths[1] + "px"}
								minW={widths[1] + "px"}
								isTruncated
							>
								<Text color="gray.500" fontSize="md" fontWeight="semibold" isTruncated>
									{arrayToString([this.props.member?.prco, this.props.member?.employee], " - ")}
								</Text>
							</Td>
						)}
						<Td
							px="1"
							width={widths[2] + "px"}
							maxWidth={widths[2] + "px"}
							minW={widths[2] + "px"}
							isTruncated
						>
							<Text color="gray.500" fontSize="md" fontWeight="semibold" isTruncated>
								{this.props.member.employeeName}
							</Text>
						</Td>
						<Td
							px="1"
							width={widths[3] + "px"}
							maxWidth={widths[3] + "px"}
							minW={widths[3] + "px"}
							isTruncated
						>
							<Text color="gray.500" fontSize="md" fontWeight="semibold" isTruncated>
								{this.props.member.craftClass}
							</Text>
						</Td>
						<Td
							px="1"
							width={widths[4] + "px"}
							maxWidth={widths[4] + "px"}
							minW={widths[4] + "px"}
							isTruncated
						>
							<Text color="gray.500" fontSize="md" fontWeight="semibold" isTruncated>
								{this.props.member.region}
							</Text>
						</Td>
						<Td
							px="1"
							width={widths[5] + "px"}
							maxWidth={widths[5] + "px"}
							minW={widths[5] + "px"}
							isTruncated
							borderRight="1px"
							borderRightColor="gray.200"
							whiteSpace="nowrap"
						>
							<Text color="gray.500" fontSize="md" fontWeight="semibold" isTruncated>
								{this.props.member.dept}
							</Text>
						</Td>
						<Td
							px="1"
							width={widths[6] + "px"}
							maxWidth={widths[6] + "px"}
							minW={widths[6] + "px"}
							borderRight="1px"
							borderRightColor="gray.200"
							textAlign="center"
							isTruncated
						>
							<Text color="gray.500" fontSize="md" fontWeight="semibold" isTruncated>
								{formatValue(totalREG, 1, "number")}
							</Text>
						</Td>
						<Td
							px="1"
							width={widths[7] + "px"}
							maxWidth={widths[7] + "px"}
							minW={widths[7] + "px"}
							borderRight="1px"
							borderRightColor="gray.200"
							textAlign="center"
							isTruncated
						>
							<Text color="gray.500" fontSize="md" fontWeight="semibold" isTruncated>
								{formatValue(totalOT, 1, "number")}
							</Text>
						</Td>
						<Td
							px="1"
							width={widths[8] + "px"}
							maxWidth={widths[8] + "px"}
							minW={widths[8] + "px"}
							borderRight="1px"
							borderRightColor="gray.200"
							textAlign="center"
							isTruncated
						>
							<Text color="gray.500" fontSize="md" fontWeight="semibold" isTruncated>
								{formatValue(totalDT, 1, "number")}
							</Text>
						</Td>
						<Td
							px="1"
							width={widths[9] + "px"}
							maxWidth={widths[9] + "px"}
							minW={widths[9] + "px"}
							borderRight="1px"
							borderRightColor="gray.200"
							textAlign="center"
							isTruncated
						>
							<Text color="gray.500" fontSize="md" fontWeight="semibold" isTruncated>
								{formatValue(totalPTO, 1, "number")}
							</Text>
						</Td>
						<Td
							px="1"
							width={widths[10] + "px"}
							maxWidth={widths[10] + "px"}
							minW={widths[10] + "px"}
							borderRight="1px"
							borderRightColor="gray.200"
							textAlign="center"
							isTruncated
						>
							<Text color="gray.500" fontSize="md" fontWeight="semibold" isTruncated>
								{formatValue(totalHours, 1, "number")}
							</Text>
						</Td>
						<Td
							px="1"
							width={widths[11] + "px"}
							maxWidth={widths[11] + "px"}
							minW={widths[11] + "px"}
							key={v4()}
							textAlign="center"
							isTruncated
						>
							<Tooltip placement="top" hasArrow={true} label="View/Edit Notes">
								<IconButton variant="ghost" size="sm" onClick={this.openNotes}>
									<i
										style={{
											color: this.props.member?.timecard?.hasNotes()
												? "var(--chakra-colors-green-500)"
												: "var(--chakra-colors-gray-500)",
										}}
										className={
											"fa-lg " +
											(this.props.member?.timecard?.hasNotes()
												? "fas fa-clipboard-check"
												: "far fa-clipboard")
										}
									></i>
								</IconButton>
							</Tooltip>
						</Td>

						{(this.props.reviewLevel === "Approval" || this.props.reviewLevel === "Review") && (
							<>
								<Td
									px="1"
									key={v4()}
									width={widths[12] + "px"}
									maxWidth={widths[12] + "px"}
									minW={widths[12] + "px"}
									textAlign="center"
									isTruncated
								>
									<Tooltip placement="top" hasArrow={true} label="Approve Time">
										<IconButton
											variant="ghost"
											size="sm"
											onClick={(ev) => {
												this.approveTime(ev, 1);
											}}
											isDisabled={
												this.props.reviewLevel === "Approval" &&
												portalUser.user?.employeeUID === this.props.member?.employeeUID
											}
										>
											<i
												style={{
													color: this.props.member?.timecard?.timecardHeader?.approverUID1
														? "var(--chakra-colors-teal-500)"
														: "var(--chakra-colors-gray-500)",
												}}
												className={
													this.props.member?.timecard?.timecardHeader?.approverUID1 != null
														? "fas fa-check-square fa-lg"
														: "far fa-square fa-lg"
												}
											></i>
										</IconButton>
									</Tooltip>
								</Td>
								<Td
									px="1"
									key={v4()}
									width={widths[13] + "px"}
									maxWidth={widths[13] + "px"}
									minW={widths[13] + "px"}
									textAlign="center"
									isTruncated
								>
									<Tooltip placement="top" hasArrow={true} label="Approve Time">
										<IconButton
											variant="ghost"
											size="sm"
											onClick={(ev) => {
												this.approveTime(ev, 2);
											}}
											isDisabled={
												this.props.reviewLevel === "Approval" &&
												portalUser.user?.employeeUID === this.props.member?.employeeUID
											}
										>
											<i
												style={{
													color: this.props.member?.timecard?.timecardHeader?.approverUID2
														? "var(--chakra-colors-teal-500)"
														: "var(--chakra-colors-gray-500)",
												}}
												className={
													this.props.member?.timecard?.timecardHeader?.approverUID2 != null
														? "fas fa-check-square fa-lg"
														: "far fa-square fa-lg"
												}
											></i>
										</IconButton>
									</Tooltip>
								</Td>
							</>
						)}
						{this.props.reviewLevel === "Review" && (
							<Td
								px="1"
								key={v4()}
								width={widths[14] + "px"}
								maxWidth={widths[14] + "px"}
								minW={widths[14] + "px"}
								isTruncated
							>
								{/* <Tooltip placement='top' hasArrow={true} label='Approve Time'> */}
								<Text color="gray.500" fontSize="md" fontWeight="semibold" isTruncated>
									{approvedBy ?? "Not Approved"}
								</Text>
								{/* </Tooltip> */}
							</Td>
						)}

						<Td
							px="1"
							key={v4()}
							width={widths[15] + "px"}
							maxWidth={widths[15] + "px"}
							minW={widths[15] + "px"}
							textAlign="center"
							isTruncated
						>
							<Tooltip
								placement="top"
								hasArrow={true}
								label={this.props.member?.timecard?.getStatus().status}
							>
								<IconButton
									variant="unstyled"
									size="sm"
									color={this.props.member?.timecard?.getStatus().color + ".500"}
								>
									<i className={this.props.member?.timecard?.getStatus().icon}></i>
								</IconButton>
							</Tooltip>
						</Td>
					</Tr>

					{this.props.member?.timecard?.isExpanded && (
						<Tr
							id={"timecardEntry-" + this.props.rowNum}
							display="block"
							overflow="auto"
							className="skinnyScroll"
							py={3}
						>
							<Td
								colSpan={
									this.props.reviewLevel === "Review"
										? 15
										: this.props.reviewLevel === "Approval"
										? 14
										: 12
								}
								display="block"
							>
								<TimecardHeaderCard
									member={this.props.member}
									timecardJobs={this.props.timecardJobs}
									getAvailableJobPhases={this.props.getAvailableJobPhases}
									payPeriods={this.props.payPeriods}
									currentPayPeriod={this.props.currentPayPeriod}
									addHours={this.addHours}
									readyCallback={this.timecardEntryReady}
									updateTimecardItem={this.updateTimecardItem}
									triggerTableUpdate={this.props.triggerTableUpdate}
									dataView="table"
								/>
								{/* <Box mx="auto" maxW="6xl" overflowX="hidden">
									<Heading size="md" color="gray.500">
										{this.props.member.employeeName}{" "}
										<small>
											({this.props.member.craft} / {this.props.member.class} -{" "}
											{this.props.member.title})
										</small>
									</Heading>

									{this.props.member?.activeCompanies?.map((employee, i) => (
										<TimecardEntryPage
											payPeriods={this.props.payPeriods}
											employee={employee}
											readyCallback={this.timecardEntryReady}
											timecardList={employee.timecard}
											currentPayPeriod={this.props.currentPayPeriod}
											timecardJobs={this.props.timecardJobs}
											addHours={this.addHours}
											updateTimecardItem={this.updateTimecardItem}
											getAvailableJobPhases={this.props.getAvailableJobPhases}
											triggerTableUpdate={this.props.triggerTableUpdate}
										/>
									))}
								</Box> */}
							</Td>
						</Tr>
					)}

					<ToastPopup
						status={this.state.uploadStatus}
						updates={this.state.updates}
						updateTitle={this.state.updateTitle}
						toastShown={this.toastShown}
						handleUploadRetry={this.handleUploadRetry}
					/>

					<ChakraModal isOpen={this.state.notesOpen} onClose={this.closeNotes} size="xl">
						<ModalOverlay />
						<ModalContent>
							<ModalHeader py={2}>Week Notes For {this.props.member.employeeName}</ModalHeader>
							<ModalCloseButton />
							<ModalBody>
								<Textarea
									defaultValue={this.props.member?.timecard?.timecardHeader?.weekNotes}
									onBlur={this.updateWeekNotes}
								/>
								<Text>Daily Notes:</Text>
								{this.props.member?.timecard?.payweekHours?.map((payweek, i) => (
									<List key={"notesList-" + this.props.id + i}>
										{payweek?.notes?.map((note, j) => {
											if (note?.length > 0) {
												return (
													<ListItem key={"notes-" + this.props.id + i + j}>
														{this.props.currentPayPeriod?.weekDates[j].day}&nbsp;
														{this.props.currentPayPeriod?.weekDates[j].dateStr.replace(
															/(\d{1,2}\/\d{1,2}).*/gm,
															"$1"
														)}{" "}
														- {note}
													</ListItem>
												);
											} else {
												return <></>;
											}
										})}
									</List>
								))}
							</ModalBody>
							<ModalFooter>
								<Button size="sm" colorScheme="red" py={2} onClick={this.closeNotes}>
									CLOSE
								</Button>
							</ModalFooter>
						</ModalContent>
					</ChakraModal>
				</React.Fragment>
			);
		} else if (this.props.dataView === "list") {
			let dept = deptMap[this.props.member?.deptID] ?? null;
			return (
				<Flex key={"reviewListItem-" + this.props.rowNum} id={this.props.id} w="100%" display="block" mb="2">
					<Flex
						_hover={{ bg: "blackAlpha.50" }}
						cursor="pointer"
						bg="white"
						w="100%"
						p="3"
						shadow="lg"
						display="block"
						rounded="5"
						border="1px"
						borderColor="blackAlpha.200"
						onClick={(ev) => {
							this.props.expandMember(ev, this.props.index);
						}}
					>
						<Box>
							{this.props.reviewLevel === "Review" && (
								<span onClick={this.selectMember}>
									<i
										className={
											this.props.selectedMembers?.includes(
												this.state.timecardList?.timecardHeader?.keyId
											)
												? "fas fa-check-square fa-lg"
												: "far fa-square fa-lg"
										}
									></i>
								</span>
							)}
						</Box>

						<Box>
							<Flex direction="column" display="block" mx="2">
								<Flex justify="space-between" direction="row" mb="4">
									<HStack spacing="4">
										<Tooltip placement="top" hasArrow={true} label={this.props.member?.dept}>
											<IconButton
												variant="unstyled"
												bg={dept?.color ?? "gray.400"}
												color="white"
												boxSize="50px"
												w="50px"
												h="50px"
												icon={<i className={(dept?.icon ?? "fas fa-user") + " fa-2x"}></i>}
											></IconButton>
										</Tooltip>

										<Stack spacing={0}>
											<Heading
												textTransform="uppercase"
												size="md"
												color="gray.500"
												letterSpacing={1}
											>
												{this.props.member.employeeName}
											</Heading>
											<HStack spacing={2}>
												<Tooltip
													placement="top"
													hasArrow={true}
													shouldWrapChildren={true}
													label="Craft / Class"
												>
													<Text>
														{this.props.member.craft + "/" + this.props.member.class}
													</Text>
												</Tooltip>
												<Text>-</Text>
												<Tooltip
													placement="top"
													hasArrow={true}
													shouldWrapChildren={true}
													label="Title"
												>
													<Text>{this.props.member.title}</Text>
												</Tooltip>
											</HStack>
											<HStack spacing={2}>
												<Tooltip
													placement="top"
													hasArrow={true}
													shouldWrapChildren={true}
													label="Department"
												>
													<Text>{this.props.member.dept}</Text>
												</Tooltip>
												<Text>-</Text>
												<Tooltip
													placement="top"
													hasArrow={true}
													shouldWrapChildren={true}
													label="Region"
												>
													<Text>{this.props.member.region}</Text>
												</Tooltip>
											</HStack>
										</Stack>
									</HStack>
									<Box>
										<Badge
											fontSize="sm"
											colorScheme={this.state.timecardList?.getStatus().color}
											color={this.state.timecardList?.getStatus().color + ".500"}
										>
											{this.state.timecardList?.getStatus().status}
										</Badge>
									</Box>
								</Flex>

								<Flex justify="space-between">
									<Flex w="full" wrap="wrap">
										<VerticalLabelValue
											fontWeight="bold"
											label="RT"
											labelTooltip="Regular Time"
											value={formatValue(totalREG, 1, "number")}
										/>
										<VerticalLabelValue
											fontWeight="bold"
											label="OT"
											labelTooltip="Over Time"
											value={formatValue(totalOT, 1, "number")}
										/>
										<VerticalLabelValue
											fontWeight="bold"
											label="DT"
											labelTooltip="Double Time"
											value={formatValue(totalDT, 1, "number")}
										/>
										<VerticalLabelValue
											fontWeight="bold"
											label="PTO"
											labelTooltip="Paid Time Off"
											value={formatValue(totalPTO, 1, "number")}
										/>
										<VerticalLabelValue
											fontWeight="bold"
											label="TOTAL"
											labelTooltip="Total Hours This Week"
											value={formatValue(totalHours, 1, "number")}
										/>
										<Center>
											<Tooltip placement="top" hasArrow={true} label="View/Edit Notes">
												{/* comeback */}
												<span onClick={this.openNotes} className="hoverPointerLight">
													<i
														style={{
															color: this.state.timecardList?.hasNotes()
																? "var(--chakra-colors-green-400)"
																: "var(--chakra-colors-gray-400)",
														}}
														className={
															"fa-lg " +
															(this.state.timecardList?.hasNotes()
																? "fas fa-clipboard-check"
																: "far fa-clipboard")
														}
													></i>
												</span>
											</Tooltip>
										</Center>
									</Flex>

									{this.props.reviewLevel !== "Crew Entry" && (
										<Grid templateColumns="repeat(2, 1fr)" gap={2} px="3">
											<VerticalLabelValue
												label={
													<small>
														<i className="fas fa-check"></i>
													</small>
												}
												labelTooltip="Primary Approval"
												value={
													<Tooltip
														placement="top"
														hasArrow={true}
														label="Approve Time"
														display={getDeviceType() === "mobile" ? "none" : "block"}
													>
														<span
															onClick={(ev) => {
																this.approveTime(ev, 1);
															}}
															className="hoverPointerLight"
															key={v4()}
														>
															<i
																className={
																	this.state.timecardList?.timecardHeader
																		?.approverUID1 != null
																		? " fas fa-check-square text-success  fa-lg"
																		: "far fa-square fa-lg"
																}
															></i>
														</span>
													</Tooltip>
												}
											/>

											<VerticalLabelValue
												label={
													<small>
														<i className="fas fa-check-double"></i>
													</small>
												}
												labelTooltip="Secondary Approval"
												value={
													<Tooltip
														placement="top"
														hasArrow={true}
														label="Approve Time"
														display={getDeviceType() === "mobile" ? "none" : "block"}
													>
														<span
															onClick={(ev) => {
																this.approveTime(ev, 2);
															}}
															className="hoverPointerLight"
															key={v4()}
														>
															<i
																className={
																	this.state.timecardList?.timecardHeader
																		?.approverUID2 != null
																		? " fas fa-check-square text-success fa-lg"
																		: "far fa-square fa-lg"
																}
															></i>
														</span>
													</Tooltip>
												}
											/>
										</Grid>
									)}
								</Flex>
							</Flex>
						</Box>
					</Flex>

					{this.props.member?.timecard?.isExpanded && (
						<TimecardHeaderCard
							test={"5 - this.props.member?.timecard?.isExpanded"}
							member={this.props.member}
							timecardJobs={this.props.timecardJobs}
							getAvailableJobPhases={this.props.getAvailableJobPhases}
							prEndDate={this.props.prEndDate}
							payPeriods={this.props.payPeriods}
							currentPayPeriod={this.props.currentPayPeriod}
							addHours={this.addHours}
							readyCallback={this.timecardEntryReady}
							updateTimecardItem={this.updateTimecardItem}
							triggerTableUpdate={this.props.triggerTableUpdate}
							dataView="list"
							cardView="compact"
						/>
					)}
					{Boolean(this.state.notesOpen) && (
						<TimecardHeaderNotesModal
							isOpen={this.state.notesOpen}
							onClose={this.closeNotes}
							member={this.props.member}
							timecardList={this.state.timecardList}
							payPeriods={this.props.payPeriods}
							currentPayPeriod={this.props.currentPayPeriod}
						/>
					)}
				</Flex>
			);
		} else {
			return <></>;
		}
	}
}
