import React from "react";
import ToggleButton from "../../../core/ReactTable/buttons/ToggleButton";

export default function NewAppButton({ isOpen, setIsOpen }) {
	return (
		<>
			{Boolean(setIsOpen) && (
				<ToggleButton
					toggleIsOpen={isOpen}
					onClick={setIsOpen.toggle}
					toggleOptions={[
						{
							isOpen: true,
							label: "New App Off",
							icon: "fab fa-react fa-lg fa-spin",
							color: "teal.600",
							bg: "whiteAlpha.700",
							borderColor: "teal.500",
							borderWidth: 2,
						},
						{
							isOpen: false,
							label: "New App On",
							icon: "fab fa-react fa-lg",
							color: "gray.500",
							bg: "whiteAlpha.600",
							borderColor: "whiteAlpha.600",
							borderWidth: 2,
						},
					]}
				/>
			)}
		</>
	);
}
