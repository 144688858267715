import React, { useState, useEffect } from "react";
import {
	Input,
	Flex,
	Link,
	Text,
	useBoolean,
	Tooltip,
	IconButton,
	Fade,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Portal,
} from "@chakra-ui/react";
import DataInput from "../../Inputs/data/DataInput";
import DeleteButton from "../buttons/DeleteButton";
import ArchiveButton from "../buttons/ArchiveButton";
import DrawerButton from "../buttons/DrawerButton";
import EditButton from "../buttons/EditButton";
import ShowHideButton from "../buttons/ShowHideButton";
import JobAccessButton from "../buttons/JobAccessButton";
import moment from "moment";
import { formatValue, log } from "../../../helperFunctions";

// Create an editable cell renderer
export default function TableEditableCell({ value: initialValue, row: { original }, column }) {
	// We need to keep and update the state of the cell normally
	// const datepickerRef = useRef(null); // Create a ref
	const [value, setValue] = useState(initialValue);
	const [bg, setBG] = useState(column?.bg ?? "transparent");
	const [dateCompleted, setDateCompleted] = useState(null);
	const [egnyteLink, setEgnyteLink] = useState(null);
	const [formInput, setDataInput] = useState(column?.formInput ?? "checkDate");

	const [isEditing, setIsEditing] = useBoolean(false);
	const [isHovering, setIsHovering] = useBoolean(false);
	// const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	// If the initialValue is changed external, sync it up with our state
	useEffect(() => {
		setValue(initialValue);
		if (original?.hasOwnProperty(column?.id + "dateCompleted")) {
			setDateCompleted(original[column?.id + "dateCompleted"]);
		}
		if (original?.hasOwnProperty(column?.id + "Bg")) {
			setBG(original[column?.id + "Bg"]);
		}
		if (original?.hasOwnProperty(column?.id + "egnyteLink")) {
			setEgnyteLink(original[column?.id + "egnyteLink"]);
		}
		if (original?.hasOwnProperty(column?.id + "DataInput")) {
			if (original[column?.id + "showDeliverable"] === "0") {
				setDataInput("displayOnly");
			} else {
				setDataInput(original[column?.id + "DataInput"]);
			}
		}
	}, [column?.formInput, column?.id, initialValue, original]);

	const onChangeTextInput = (e) => {
		setValue(e.target.value);
	};

	// We'll update the external data when the input is blurred for text inputs
	const onBlurTextInput = async () => {
		original = await original?.updateRow(column?.id, value);
		setValue(original[column?.id]);
	};

	const onChangeDateInput = async (date) => {
		original = await original?.updateRow(column?.id, formatValue(date, 0, "date"), isEditing);
		setValue(original[column?.id]);
		setBG(original[column?.id + "Bg"]);
		setDataInput(column?.formInput ?? "checkDate");

		if (isEditing) {
			setIsEditing.off();
			setIsHovering.off();
		}
	};

	// We'll update the external data when the inpuDt is clicked for button or checkboxes
	const onClickCheckBox = async () => {
		let newDate = moment(new Date()).format("MM/DD/YYYY");
		let bg = "green.100";
		if (Boolean(dateCompleted)) {
			newDate = null;
		}
		original.updateRow(column?.id, newDate);
		bg = original[column?.id + "Bg"];
		setDateCompleted(newDate);
		setBG(bg);
	};

	const onClickButton = async (ev) => {
		ev.stopPropagation();
		if (value === "0") {
			original = await original?.updateRow(column?.id, "1");
		} else if (value === "1") {
			original = await original?.updateRow(column?.id, "0");
		} else if (value === "Y") {
			original = await original?.updateRow(column?.id, "N");
		} else if (value === "N") {
			original = await original?.updateRow(column?.id, "Y");
		}
		setValue(original[column?.id]);
	};

	const onClickHideShowButton = async (ev) => {
		ev.stopPropagation();

		if (value === "0") {
			original = await original?.updateRow(column?.id, "1");
		} else if (value === "1") {
			original = await original?.updateRow(column?.id, "0");
		} else if (value === "Y") {
			original = await original?.updateRow(column?.id, "N");
		} else if (value === "N") {
			original = await original?.updateRow(column?.id, "Y");
		}
		setValue(original[column?.id]);
	};

	const onClickReturnRowButton = (e) => {
		e.stopPropagation();
		return original;
	};

	const onClickEditDate = (ev) => {
		ev.stopPropagation();
		if (formInput === "checkDate" && !isEditing) {
			setDataInput("editDate");
			setIsEditing.on();
		} else {
			setDataInput(column?.formInput ?? "checkDate");
			setIsEditing.off();
		}
	};

	const onClickShowHideCell = async (ev) => {
		ev.stopPropagation();
		if (original[column?.id + "showDeliverable"] === "1") {
			original = await original?.updateRow(column?.id, "0", null, true);
			setDataInput("displayOnly");
		} else {
			original = await original?.updateRow(column?.id, "1", null, true);
			setDataInput(column?.formInput ?? "checkDate");
		}
		setValue(original[column?.id]);
	};

	return (
		<Flex
			direction="row"
			h="full"
			justify="center"
			align="center"
			bg={formInput === "displayOnly" ? "transparent" : dateCompleted ? "green.100" : bg}
			w={"full"}
			px={2}
			isTruncated
			flex={1}
			position="relative"
			onMouseOver={setIsHovering.on}
			onMouseLeave={setIsHovering.off}
		>
			<Flex direction="column" w="full" justify="center" align="center" spacing={1} userSelect={"none"}>
				{["text"].includes(formInput) && (
					<Input
						placeholder={column?.placeholder ?? null}
						fontSize="sm"
						rounded={"md"}
						textAlign={column?.textAlign ?? "left"}
						px={1}
						bg="whiteAlpha.800"
						value={formatValue(value, column?.decimals, column?.valueType)}
						onChange={onChangeTextInput}
						onBlur={onBlurTextInput}
					/>
				)}
				{["date", "editDate"].includes(formInput) && (
					<DataInput
						type="date"
						variant="outline"
						placeholder="mm/dd/yyyy"
						showPlaceholder={!Boolean(value)}
						value={
							Boolean(value)
								? new Date(formatValue(value, column?.decimals ?? 0, column?.valueType ?? "string"))
								: null
						}
						onChange={onChangeDateInput}
						textAlign="center"
						textTransform="uppercase"
						color={column?.color}
						inputProps={{
							bg: "whiteAlpha.700",
							fontWeight: column?.fontWeight ?? "normal",
							textTransform: "uppercase",
							color: column?.color,
							startOpen: isEditing,
						}}
					/>
				)}

				{["checkDate"].includes(formInput) && (
					<Link
						color={column?.color}
						_hover={{ color: "blue.500", fontWeight: "semibold", textDecoration: "underline" }}
						href={Boolean(egnyteLink) ? egnyteLink : null}
						isExternal
					>
						{Boolean(dateCompleted)
							? formatValue(dateCompleted, column?.decimals ?? 0, column?.valueType ?? "string")
							: Boolean(value)
							? formatValue(value, column?.decimals ?? 0, column?.valueType ?? "string")
							: "mm/dd/yyyy"}
					</Link>
				)}
				{["checkDate", "checkBox"].includes(formInput) && (
					<Text
						isDisabled={["checkBox"].includes(formInput) && Boolean(dateCompleted)}
						key={Boolean(dateCompleted) ? "fas fa-check-square" : "far fa-square"}
						color={Boolean(dateCompleted) ? "teal.500" : "gray.400"}
						cursor="pointer"
						fontSize="sm"
						onClick={onClickCheckBox}
					>
						<i
							className={
								(Boolean(dateCompleted) ? "fas fa-check-square" : "far fa-square") + " fa-lg fa-fw"
							}
						></i>
					</Text>
				)}

				{["displayOnly"].includes(formInput) && <></>}
				{["archiveButton"].includes(formInput) && (
					<ArchiveButton
						archiveIsActive={Boolean(value === "0" || value === "N")}
						onClickArchiveButton={onClickButton}
					/>
				)}
				{["deleteButton"].includes(formInput) && (
					<DeleteButton
						deleteIsActive={Boolean(value === "0" || value === "N")}
						onClickDeleteButton={onClickButton}
					/>
				)}
				{["editButton"].includes(formInput) && (
					<EditButton
						editIsActive={Boolean(value === "1" || value === "Y")}
						onClickEditButton={onClickButton}
					/>
				)}
				{["showHideButton"].includes(formInput) && (
					<ShowHideButton
						showIsActive={Boolean(value === "1" || value === "Y")}
						onClickShowHideButton={onClickHideShowButton}
					/>
				)}
				{["jobAccessButton"].includes(formInput) && (
					<JobAccessButton jobAccess={Boolean(value === "1" || value === "Y")} onClick={onClickButton} />
				)}
				{["returnRowButton"].includes(formInput) && (
					<DrawerButton
						drawerIsOpen={Boolean(value === "1" || value === "Y")}
						onClickDrawerButton={onClickReturnRowButton}
					/>
				)}
			</Flex>

			{["SUP"].includes(original?.templateType) &&
				["editDate", "checkDate", "checkBox", "displayOnly"].includes(formInput) && (
					<Flex position="absolute" bottom={0.5} right={0.5}>
						<Fade in={isHovering}>
							<Tooltip
								key={isEditing ? "fas fa-cog fa-spin" : "fas fa-cog"}
								placement="bottom"
								label={isEditing ? "Done Editing" : "Edit Date"}
								aria-label={isEditing ? "Done Editing" : "Edit Date"}
							>
								<Menu placement="bottom-end">
									<MenuButton
										as={IconButton}
										color={isEditing ? "black.500" : "gray.400"}
										boxSize={5}
										minW={5}
										icon={<i className={isEditing ? "fas fa-cog fa-spin" : "fas fa-cog"} />}
										bg="transparent"
									/>
									<Portal>
										<MenuList py={0}>
											<MenuItem
												py={2}
												display="flex"
												align="center"
												color="gray.500"
												icon={<i className={"fas fa-edit fa-fw fa-lg"} />}
												isDisabled={Boolean(dateCompleted) || formInput === "displayOnly"}
												onClick={onClickEditDate}
											>
												<Text
													lineHeight={1}
													textTransform="uppercase"
													letterSpacing={1}
													fontWeight="semibold"
												>
													Override Date
												</Text>
											</MenuItem>
											<MenuItem
												py={2}
												display="flex"
												align="center"
												color="gray.500"
												key={"fas fa-eye" + (formInput === "displayOnly" ? "-slash" : "")}
												icon={
													<i
														className={
															"fas fa-eye" +
															(formInput === "displayOnly" ? "-slash" : "") +
															" fa-fw fa-lg"
														}
													/>
												}
												onClick={onClickShowHideCell}
											>
												<Text
													lineHeight={1}
													textTransform="uppercase"
													letterSpacing={1}
													fontWeight="semibold"
												>
													{(formInput === "displayOnly" ? "Show" : "Hide") + " Deliverable"}
												</Text>
											</MenuItem>
										</MenuList>
									</Portal>
								</Menu>
							</Tooltip>
						</Fade>
					</Flex>
				)}
		</Flex>
	);
}
