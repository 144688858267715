import React, { useMemo } from "react";
import { useRouteMatch, Link, Switch, Route, Redirect, useParams } from "react-router-dom";
import {
	useBoolean,
	Flex,
	Box,
	Heading,
	Text,
	Button,
	Spacer,
	Tabs,
	TabList,
	Tab,
	HStack,
	Stack,
	IconButton,
	Tooltip,
} from "@chakra-ui/react";
import Joyride from "react-joyride";
import { portalUser } from "../../../App";
// import { log } from "../../helperFunctions";
// import { log } from "../../helperFunctions";

export default function MainContent(props) {
	const [useFullScreen, setFullScreen] = useBoolean();

	return (
		<Flex
			w="full"
			key={"content-" + props.title}
			flex={1}
			direction="column"
			h="full"
			position={useFullScreen ? "absolute" : "inherit"}
			zIndex={useFullScreen ? 200 : "unset"}
			bg={useFullScreen ? "white" : "unset"}
			left={useFullScreen ? 0 : "unset"}
			overflow={useFullScreen ? "auto" : "unset"}
		>
			{!useFullScreen && (
				<MainBanner
					title={props.title}
					subtitle={props.subtitle}
					allowFullScreen={props.allowFullScreen}
					useFullScreen={useFullScreen}
					setFullScreen={setFullScreen}
					{...props}
				/>
			)}
			<Flex w="full" maxW="100vw" flex={1} direction="column" h="full" overflow="hidden">
				{useFullScreen && (
					<MainBanner
						title={props.title}
						subtitle={props.subtitle}
						allowFullScreen={props.allowFullScreen}
						useFullScreen={useFullScreen}
						setFullScreen={setFullScreen}
						{...props}
					/>
				)}

				{props.children}
			</Flex>
		</Flex>
	);
}

function MainBanner({
	title,
	subtitle,
	pageName,
	pageButtons,

	tabs,
	disabledTabs,
	tabVal,
	onTabChange,
	changeTab,

	allowFullScreen,
	useFullScreen,
	setFullScreen,

	joyrideID,
	startJoyride,
	runJoyride,
	joyrideSteps,
	joyrideCallback,
}) {
	let prco = (portalUser.user?.prco ?? "1")?.toString();
	var colorScheme = prco === "250" ? "blue" : "teal";

	let { url } = useRouteMatch();

	const menuTabs = useMemo(() => {
		let newTabs = [];
		if (tabs?.length > 0) {
			for (let i = 0; i < tabs?.length; i++) {
				let title = "";
				let route = "";
				if (tabs[i] instanceof Object) {
					title = tabs[i]?.title;
					route = url + "/" + tabs[i]?.title?.replace(/\s/gm, "").toLowerCase();
				} else {
					title = tabs[i];
					route = url + "/" + tabs[i]?.replace(/\s/gm, "").toLowerCase();
				}
				newTabs.push({ title, route });
			}
		} else {
			newTabs.push({ title: "", route: url });
		}
		return newTabs;
	}, [url, tabs]);

	let basePath = url;
	if (basePath === "/") {
		basePath = "";
	}

	return (
		<Box borderBottomWidth={2} borderBottomColor="gray.400" borderStyle="inset">
			{runJoyride && (
				<Joyride
					run={runJoyride}
					continuous={true}
					showProgress={true}
					showSkipButton={true}
					spotlightClicks={true}
					steps={joyrideSteps}
					scrollToFirstStep={true}
					callback={joyrideCallback}
				/>
			)}

			<Box
				pt={6}
				bgGradient={
					"linear(to-r, rgba(255, 255, 255,0.9), rgba(255, 255, 255,0.5)), url(" +
					portalUser.user?.getBannerImage() +
					")"
				}
				bgSize="cover"
				bgPosition="center"
			>
				<Box>
					<Flex
						px={{ base: 4, md: 6 }}
						direction={{ base: "column", md: "row" }}
						justify="space-between"
						align={{ base: "flex-start", md: "flex-end" }}
					>
						<Stack>
							<HStack align="center" spacing={3}>
								<Heading size="lg" color="gray.600" textTransform="uppercase" letterSpacing={3}>
									{title ?? pageName}
								</Heading>
							</HStack>
							<Heading
								fontWeight={"semibold"}
								size="md"
								color="gray.500"
								textTransform="uppercase"
								letterSpacing={3}
							>
								{subtitle}
							</Heading>
						</Stack>
						{pageButtons && <HStack spacing={{ base: "2", md: "4" }}>{pageButtons}</HStack>}
					</Flex>
					<Flex w="full" px={{ base: 0, md: 4 }} align="flex-end" pt={!tabs ? 3 : 0}>
						{allowFullScreen && (
							<Flex align="center" justify="center" py={1}>
								<Tooltip label={useFullScreen ? "Show Menu" : "Hide Menu"} placement="top">
									<IconButton
										key={useFullScreen ? "fullScreenView" : "normalView"}
										variant="ghost"
										size="md"
										color="gray.500"
										p={0}
										icon={
											<i
												className={
													"fas fa-" +
													(useFullScreen ? "angle-double-right" : "angle-double-left") +
													" fa-lg fa-fw"
												}
											></i>
										}
										onClick={setFullScreen.toggle}
									/>
								</Tooltip>
							</Flex>
						)}
						{tabs && (
							<Flex w="full" flex={1}>
								<Tabs
									size="lg"
									variant="line"
									isFitted
									colorScheme="teal"
									overflowX="auto"
									overflowY="hidden"
									className="skinnyScroll"
									whiteSpace="nowrap"
									borderColor="transparent"
									index={tabVal ?? 0}
									borderStyle="inset"
								>
									<TabList id={joyrideID ?? null}>
										{menuTabs?.map((menuTab, i) => (
											<Link key={i} to={menuTab.route} style={{ textDecoration: "none" }}>
												<Tab
													textTransform="uppercase"
													fontWeight="semibold"
													color="gray.500"
													isDisabled={disabledTabs?.includes(menuTab?.title)}
												>
													{menuTab.title}
												</Tab>
											</Link>
										))}
									</TabList>
								</Tabs>
							</Flex>
						)}

						<Spacer />
						{joyrideSteps && (
							<Flex align="flex-end" id="fullPageWalkthrough" py={1}>
								<Button
									px="2"
									size="sm"
									variant="solid"
									colorScheme={colorScheme}
									onClick={startJoyride}
								>
									<HStack>
										<i className="fas fa-info-circle fa-lg"></i>
										<Text textTransform="uppercase">Page Tour</Text>
									</HStack>
								</Button>
							</Flex>
						)}
					</Flex>
					<Switch>
						<Route exact path={url + "/"}>
							{tabs?.length > 0 && <Redirect to={menuTabs[tabVal ?? 0]?.route} />}
							{tabs?.length === 0 && <MenuTab />}
						</Route>

						<Route path={url + "/:tab"}>
							<MenuTab tabChange={onTabChange ?? changeTab} tabVal={tabVal ?? 0} tabs={tabs} />
						</Route>
					</Switch>
				</Box>
			</Box>
		</Box>
	);
}

export function MenuTab({ tabs, tabVal, tabChange }) {
	let { tab } = useParams();

	let tabContent;

	if (tab) {
		for (let i = 0; i < tabs?.length; i++) {
			if (tabs[i] instanceof Object) {
				if (
					tab.replace(/\s/gm, "").toLowerCase() === tabs[i].title.replace(/\s/gm, "").toLowerCase() &&
					i !== tabVal
				) {
					tabContent = tabs[i].content;
				}
			} else {
				if (
					tab.replace(/\s/gm, "").toLowerCase() === tabs[i].replace(/\s/gm, "").toLowerCase() &&
					i !== tabVal
				) {
					tabChange(i);
				}
			}
		}
	}

	return tabContent ? <Box>{tabContent}</Box> : <></>;
}
