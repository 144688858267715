import Papa from "papaparse";
import * as XLSX from "xlsx";

//Export Data as Blob File (Excel ect)
export default function getExportFileBlob({ columns, data, fileType, fileName }) {
	if (fileType === "csv") {
		// CSV example

		const headerNames = columns.map((col) => col.exportValue);
		const csvString = Papa.unparse({ fields: headerNames, data });
		return new Blob([csvString], { type: "text/csv" });
	} else if (fileType === "xlsx") {
		// XLSX example

		let header = columns.map((c) => c.exportValue);
		header = header?.filter((d) => Boolean(d)) ?? [];
		const compatibleData = data.map((row) => {
			const obj = {};
			header?.forEach((col, index) => {
				obj[col] = row[index];
			});
			return obj;
		});

		let wb = XLSX.utils.book_new();
		let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
			header,
		});

		let widths = [];

		for (let i = 0; i < columns?.length; i++) {
			let width = columns[i]?.width ?? "100%";
			width = width.replace(/%/g, "");
			width = parseFloat(width);
			// console.log("width", width);
			let cellWidth = 15;
			if (!isNaN(cellWidth)) {
				cellWidth = cellWidth * (width / 100);
			}
			widths.push({ wch: cellWidth });
		}
		ws1["!cols"] = widths;

		// ws1["B2"].l = { Target: "https://ppmportal.com", Tooltip: "Click here to go to the PPM Portal" };
		// ws1["E2"].z = '"$"#,##0.00_);\\("$"#,##0.00\\)';
		// ws1["G2"].z = "#,##0";
		// ws1["I2"].z = "0.00%";

		// console.log("worksheet1", ws1);
		XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
		XLSX.writeFile(wb, `${fileName}.xlsx`);

		// Returning false as downloading of file is already taken care of
		return false;
	} else if (fileType === "pdf") {
		//Add logic for rendering PDF

		return false;
	}

	// Other formats goes here
	return false;
}
