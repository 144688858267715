const HR_LONGEVITY_AWARDS = [
	{ awardYear: null, awardMonths: null, color: "gray.400" },
	{ awardYear: 5, awardMonths: 60, color: "blue.400" },
	{ awardYear: 7, awardMonths: 84, color: "cyan.400" },
	{ awardYear: 10, awardMonths: 120, color: "green.400" },
	{ awardYear: 15, awardMonths: 180, color: "yellow.400" },
	{ awardYear: 20, awardMonths: 240, color: "orange.400" },
	{ awardYear: 25, awardMonths: 300, color: "red.400" },
	{ awardYear: 30, awardMonths: 360, color: "pink.400" },
	{ awardYear: 35, awardMonths: 420, color: "purple.400" },
	{ awardYear: 40, awardMonths: 480, color: "red.600" },
	{ awardYear: 45, awardMonths: 540, color: "red.600" },
	{ awardYear: 50, awardMonths: 600, color: "red.600" },
	{ awardYear: 55, awardMonths: 660, color: "red.600" },
	{ awardYear: 60, awardMonths: 720, color: "red.600" },
	{ awardYear: 65, awardMonths: 780, color: "red.600" },
	{ awardYear: 70, awardMonths: 900, color: "red.600" },
	{ awardYear: 75, awardMonths: 840, color: "red.600" },
];
export default HR_LONGEVITY_AWARDS;
