import React from "react";
import { Box, Button, VStack, Center, HStack, Text, Link, ChakraProvider } from "@chakra-ui/react";
import { GoSignIn } from "react-icons/go";
import PPMLogo from "../../../core/Logos/components/PPMLogo";

export default function LoginPage({ msAuth }) {
	const windowWidth = window.innerWidth;
	const windowHeight = window.innerHeight;
	// const userAgent = navigator.userAgent;
	const networkType = navigator.connection ? navigator.connection.effectiveType : "Not available";

	const sendLoginHelpEmail = () => {
		// Get device details
		const userAgent = navigator.userAgent;
		const deviceType = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/.test(userAgent)
			? "Mobile"
			: /Tablet|iPad/.test(userAgent)
			? "Tablet"
			: "Desktop";
		const operatingSystem = navigator.platform;
		const screenWidth = window.screen.width;
		const screenHeight = window.screen.height;

		let isDesktopZoomed = false;
		if (deviceType === "Desktop") {
			const zoomLevel = window.innerWidth / window.outerWidth;
			isDesktopZoomed = zoomLevel !== 1;
		}

		// Construct the email body with device details
		const emailSubject = "Having Trouble Logging In to the PPM Portal";
		const emailBody = `Hi David,
		
		I am unable to log in to PPM Portal. Could you please assist?

		Device Type: ${deviceType}
		Operating System: ${operatingSystem}
		Screen Width: ${screenWidth}
		Screen Height: ${screenHeight}
		Is Desktop Zoomed: ${isDesktopZoomed ? "Yes" : "No"}
		Window Width: ${windowWidth}
		Window Height: ${windowHeight}
		Network Type: ${networkType}
		User Agent: ${userAgent}
				
		Thanks`;

		// Encode the email body
		const encodedEmailBody = encodeURIComponent(emailBody);

		// Construct the mailto link
		const mailtoLink = `mailto:davidg@ppmechanical.com?subject=${encodeURIComponent(
			emailSubject
		)}&body=${encodedEmailBody}`;

		// Open the user's default email client with pre-filled subject and body
		window.location.href = mailtoLink;
	};

	return (
		<ChakraProvider>
			<Box
				height="100vh"
				backgroundImage={`url(/images/Background_LoadingPage.jpg)`}
				backgroundSize="cover"
				backgroundPosition="center"
			>
				<Center maxW="md" h="80%" mx="auto" align="center">
					<VStack spacing={3}>
						<PPMLogo color="teal.600" h={{ base: "3rem", sm: "5rem", md: "7rem" }} objectFit="cover" />
						<Button
							leftIcon={<GoSignIn size="25px" />}
							size="lg"
							pl="3"
							pr="4"
							onClick={() => msAuth?.msInstance?.loginRedirect({ scopes: msAuth?.scopes })}
							colorScheme="teal"
						>
							SIGN IN
						</Button>
						<HStack py={3} spacing={2}>
							<Text fontWeight="semibold" color="teal.700">
								Having trouble logging in?
							</Text>
							<Link
								p={1}
								fontWeight="bold"
								_hover={{
									bg: "whiteAlpha.600",
									color: "var(--chakra-colors-blue-500)!important",
									border: 2,
									rounded: 5,
								}}
								textDecoration="underline"
								color="blue.600"
								onClick={sendLoginHelpEmail}
							>
								Click Here
							</Link>
						</HStack>
					</VStack>
				</Center>
			</Box>
		</ChakraProvider>
	);
}
