import { formatDate, log, getRate, formatValue } from "../../../helperFunctions";
import { v4 } from "uuid";
import ProjectsAPI from "../../../APIs/ProjectsAPI";

export default class COItem {
	constructor(coItem) {
		this.projectsAPI = new ProjectsAPI();
		this.pmoiUID = coItem?.pmoiUID ?? v4();

		this.queryData = coItem?.queryData ?? coItem?.QueryData ?? "COItem";
		this.jccmKeyID = coItem?.jccmKeyID ?? coItem?.JCCMKeyID ?? null;
		this.jcco = coItem?.jcco ?? coItem?.JCCo ?? null;
		this.contract = coItem?.contract ?? coItem?.Contract?.trim() ?? null;

		this.jcciKeyID = coItem?.jcciKeyID ?? coItem?.JCCIKeyID ?? null;
		this.contractItem = coItem?.contractItem ?? coItem?.ContractItem ?? null;

		this.itemTypeID = coItem?.itemTypeID ?? coItem?.ItemTypeID ?? "LS";

		this.pmoiKeyID = coItem?.pmoiKeyID ?? coItem?.PMOIKeyID ?? null;
		this.approvedYN = coItem?.approvedYN ?? coItem?.ApprovedYN ?? "N";
		this.pmoiStatus = coItem?.pmoiStatus ?? coItem?.Status?.trim() ?? "PEND";
		this.description = coItem?.description ?? coItem?.Description;

		this.pmohKeyID = coItem?.pmohKeyID ?? coItem?.PMOHKeyID ?? null;
		this.aco = coItem?.aco ?? coItem?.ACO?.trim() ?? null;
		this.acoItem = coItem?.acoItem ?? coItem?.ACOItem?.trim() ?? null;
		this.acoAmt = coItem?.acoAmt ?? coItem?.ACOAmt ?? 0;
		this.approvedDate = formatDate(coItem?.approvedDate ?? coItem?.ApprovedDate) ?? null;
		this.approvedBy = coItem?.approvedBy ?? coItem?.ApprovedBy ?? null;

		this.pmopKeyID = coItem?.pmopKeyID ?? coItem?.PMOPKeyID ?? null;
		this.pco = coItem?.pco ?? coItem?.PCO?.trim() ?? null;
		this.pcoItem = coItem?.coItem ?? coItem?.PCOItem?.trim() ?? null;
		this.pcoAmt = coItem?.pcoAmt ?? coItem?.PCOAmt ?? 0;
		this.pcoConv = coItem?.pcoConv ?? coItem?.PCOConv ?? 0;

		this.pctMargin = coItem?.pctMargin ?? coItem?.PctMargin ?? 15;
		this.pctComplete = coItem?.pctComplete ?? coItem?.PctComplete ?? 0;

		this.pcoEstCost = this.getPCOEstCost();
		this.pcoActualCost = this.getPCOActualCost();
		this.pcoConvPct = this.getPCOConvPct();

		this.editable = true;
	}

	getPCOEstCost() {
		let pcoEstCost = parseFloat(this.pcoConv ?? 0) * ((100 - parseFloat(this.pctMargin ?? 0)) / 100);
		return pcoEstCost;
	}

	getPCOActualCost() {
		let pcoActualCost = this.getPCOEstCost() * (parseFloat(this.pctComplete ?? 0) / 100);
		return pcoActualCost;
	}

	getPCOConvPct() {
		return getRate(this.pcoConv, this.pcoAmt) * 100;
	}

	// PCO Items - SQL INSERT PMOI
	async insertPMOI() {
		if (Boolean(this.pmopKeyID)) {
			let data = {
				pcoConv: this.pcoConv,
				pctMargin: this.pctMargin,
				pctComplete: this.pctComplete,
				contractItem: this.contractItem,
			};
			let pmoiResult = await this.projectsAPI.InsertPMOI(this.pmopKeyID, data);
			log("DATABASE RESULT INSERT PMOI", pmoiResult);
			if (pmoiResult?.status === 200 && pmoiResult?.value?.length > 0) {
				pmoiResult = pmoiResult.value[0];
				this.pmoiKeyID = pmoiResult.pmoiKeyID;
				this.pcoEstCost = this.getPCOEstCost();
				this.pcoActualCost = this.getPCOActualCost();
				this.pcoConvPct = this.getPCOConvPct();
			}

			return this;
		}
	}

	// PCO Items - SQL UPDATE PMOI
	async updatePMOI(data) {
		let result = await this.projectsAPI.UpdatePMOI(this.pmoiKeyID, data);
		if (result.status === 200) {
			this.pcoEstCost = this.getPCOEstCost();
			this.pcoActualCost = this.getPCOActualCost();
			this.pcoConvPct = this.getPCOConvPct();
			log("DATABASE RESULT UPDATE PMOI", result);
		}

		return result;
	}

	getPMOIAttribute(attribute) {
		// NUMBERS
		if (attribute === "pcoConv") {
			return "FixedAmount";
		} else if (attribute === "pctMargin") {
			return "udPctMargin";
		} else if (attribute === "pctComplete") {
			return "udPctComplete";
		} else if (attribute === "contractItem") {
			return "ContractItem";
		}
	}

	// PCO Items - SQL DELETE PMOI
	async deletePMOI() {
		let result;
		if (Boolean(this.pmoiKeyID)) {
			result = await this.projectsAPI.DeletePMOI(this.pmoiKeyID);
			log("DATABASE RESULT DELETE PMOI", result);
		}
		return result;
	}

	async update(attribute = null, value = null) {
		// log(attribute, value);
		let strings = ["contractItem"];
		let numbers = ["pcoConv", "pctMargin", "pctComplete"];
		let pmoiAttributes = ["pcoConv", "pctMargin", "pctComplete", "contractItem"];
		let val = null;

		// STRINGS
		if (strings.includes(attribute)) {
			val = value;
			this[attribute] = val;
		}
		// NUMBERS
		else if (numbers.includes(attribute)) {
			val = parseFloat(formatValue(value, 2, "numeric"));

			if (!Boolean(val) || isNaN(val)) {
				val = 0;
			}
			if (["pctMargin", "pctComplete"].includes(attribute)) {
				if (val < 0) {
					val = 0;
				} else if (val > 100) {
					val = 100;
				}
			}

			this[attribute] = val;
		}

		// Update PMOI
		if (pmoiAttributes.includes(attribute) && Boolean(this.pmoiKeyID)) {
			let dbAttribute = this.getPMOIAttribute(attribute);
			let update = { [dbAttribute]: val };
			log("PMOI update", update);

			let result = await this.updatePMOI(update);
			return result;
		}
	}
}
