import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@chakra-ui/react";
import TextInputColumnFilter from "../../../core/ReactTable/inputs/TextInputFilter";
import getFilterTypes from "../../../core/ReactTable/functions/getFilterTypes";
import SideBarVirtualList from "./SideBarVirtualList";

export default function VirtualData({
	type = "sidebar",
	columns = [],
	data = [],
	groupBy = [],
	filterBy = [],
	sortBy = [],
	renderRow = null,
	sideBarIsOpen = true,
	onClose,
}) {
	const virtualColumns = useMemo(() => {
		let virtualColumns = columns?.filter((d) => Boolean(d.id)) ?? [];
		if (virtualColumns?.length === 0) {
			virtualColumns = [{ id: "id", accessor: "id", Header: "Column" }];
		}
		return virtualColumns;
	}, [columns]);

	const initialState = useMemo(() => {
		let columnOrder = columns?.map((d) => d.id) ?? [];

		let hiddenColumns = columns?.filter((d) => !Boolean(d.show))?.map((d) => d.id) ?? [];
		let virtualGroupBy = groupBy?.filter((d) => columnOrder?.includes(d)) ?? [];
		let virtualSortBy = sortBy?.filter((d) => columnOrder?.includes(d.id)) ?? [];
		let virtualFilterBy = filterBy?.filter((d) => columnOrder?.includes(d.id)) ?? [];

		return { columnOrder, hiddenColumns, sortBy: virtualSortBy, groupBy: virtualGroupBy, filters: virtualFilterBy };
	}, [columns, filterBy, groupBy, sortBy]);

	const defaultColumn = useMemo(
		() => ({
			width: 150,
			Filter: TextInputColumnFilter,
			sortIcon: "alpha",
			show: true,
		}),
		[]
	);

	const filterTypes = getFilterTypes();

	const [virtualData, setVirtualData] = useState(null);
	const [skipReset, setSkipReset] = useState(false);

	useMemo(() => {
		setVirtualData(data ?? []);
	}, [data]);

	const updateMyData = (rowIndex, columnId, value) => {
		setSkipReset(true);
		setVirtualData((old) =>
			old.map((row, index) => {
				if (index === rowIndex) {
					if (typeof row?.update === "function") {
						// log("Update function Exists", row);
						row.update(columnId, value);
					} else {
						// log("Update function Does not Exist", row);
						row[columnId] = value;
						return {
							...old[rowIndex],
							[columnId]: value,
						};
					}
				}
				return row;
			})
		);
	};

	useEffect(() => {
		setSkipReset(false);
	}, [virtualData]);

	return (
		<Box w="full" h="full">
			{type === "sidebar" && (
				<SideBarVirtualList
					type={type}
					columns={virtualColumns}
					data={virtualData}
					initialState={initialState}
					defaultColumn={defaultColumn}
					filterTypes={filterTypes}
					renderRow={renderRow}
					updateMyData={updateMyData}
					skipReset={skipReset}
					onClose={onClose}
					sideBarIsOpen={sideBarIsOpen}
				/>
			)}
		</Box>
	);
}
