import { portalUser } from "../../../App";
import { formatValue } from "../../../helperFunctions";
import FormComment from "./FormComment";

export default class FormInput {
	constructor(input) {
		if (input instanceof FormInput) {
			Object.assign(this, input);
		} else {
			this.queryData = input?.queryData ?? "FormInput";

			//FormUID
			this.templateUID = input?.templateUID ?? null;
			this.formUID = input?.formUID ?? null;
			this.questionUID = input?.questionUID ?? null;
			this.inputUID = input?.inputUID ?? null;

			//Integer
			this.keyID = input?.keyID ?? null;
			this.order = input?.order ?? null;

			//String
			this.description = input?.description ?? null;
			this.value = input?.value ?? null;

			this.error = false;
		}
	}

	async updateFormInput(attr = null, value = this.value) {
		value = value?.toString();
		if (Boolean(attr) && this[attr] !== value) {
			if (typeof value === "string") {
				// Replace specific apostrophe and double quote variants with backticks
				value = value.replace(/['\u2018\u2019\u201C\u201D"]/g, "`");

				// Replace any non-printable ASCII characters
				value = value.replace(/[^\x20-\x7E]/g, "`");
			}

			this[attr] = value;
			let params = { formUID: this.formUID, inputUID: this.inputUID };
			let update = {};
			if (attr === "value") {
				update = { Value: value };
			}

			let result = await portalUser?.formData?.formsAPI.UpdateFormInput(params, update);

			let lastUpdated = new Date();
			if (result.status === 200) {
				params = { formUID: this.formUID };
				let update = {
					DateModified: formatValue(lastUpdated, 0, "datetimeSQL"),
					ModifiedBy: portalUser.user?.employeeUID,
				};
				result = await portalUser?.formData?.formsAPI.UpdateForm(params, update);

				let form = portalUser?.formData?.forms?.find((d) => d.formUID === this.formUID) ?? null;
				if (Boolean(form)) {
					form.dateModified = formatValue(lastUpdated, 0, "moment");
					form.modifiedBy = portalUser.user?.employeeUID;
				}

				return this;
			} else {
				this.error = true;
				return this;
			}
		} else {
			return this;
		}
	}

	async insertFormComment() {
		let comment = new FormComment({
			templateUID: this.templateUID,
			formUID: this.formUID,
			inputUID: this.inputUID,
			employeeUID: portalUser.user?.employeeUID,
		});

		comment = await comment.insertFormComment();
		return comment;
	}

	async deleteFormComment(commentUID = null) {
		let comment = portalUser.formData.comments?.find((d) => d.commentUID === commentUID) ?? null;
		if (Boolean(comment)) {
			comment = await comment.deleteFormComment();
		}
		return comment;
	}

	validate() {
		if (this.keyID && (this.value?.trim() === "" || !Boolean(this.value))) {
			return this;
		} else {
			return null;
		}
	}
}
