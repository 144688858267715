const DF_ROLES = [
	{ templateType: "D&F", respRoleID: "projectManager", respRole: "Project Manager", roleAbbr: "PM" },
	{ templateType: "D&F", respRoleID: "receiving", respRole: "Receiving", roleAbbr: "Receiving" },
	{ templateType: "D&F", respRoleID: "qcReviewer", respRole: "QC Reviewer", roleAbbr: "QC" },
	{ templateType: "D&F", respRoleID: "foreman", respRole: "Foreman", roleAbbr: "Foreman" },
	{ templateType: "D&F", respRoleID: "detailer", respRole: "Detailer", roleAbbr: "Detailer" },
	{ templateType: "D&F", respRoleID: "supportDetailer", respRole: "Support Detailer", roleAbbr: "S. Detailer" },
	{ templateType: "D&F", respRoleID: "purchasing", respRole: "Purchasing", roleAbbr: "Purchaser" },
	{ templateType: "D&F", respRoleID: "fabrication", respRole: "Fabrication", roleAbbr: "Fab" },
	{ templateType: "SUP", respRoleID: "plumber", respRole: "Plumber", roleAbbr: "Plumber" },
	{ templateType: "SUP", respRoleID: "fitter", respRole: "Fitter", roleAbbr: "Fitter" },
	{ templateType: "SUP", respRoleID: "sheetMetal", respRole: "Sheet Metal", roleAbbr: "SM" },
	{ templateType: "SUP", respRoleID: "startUp", respRole: "Start Up", roleAbbr: "Start Up" },
	{ templateType: "SUP", respRoleID: "tab", respRole: "TAB", roleAbbr: "TAB" },
];
export default DF_ROLES;
