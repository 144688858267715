import React, { useMemo } from "react";
import {
	Button,
	Text,
	Textarea,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	ModalCloseButton,
	Modal,
	List,
	ListItem,
} from "@chakra-ui/react";

// import { log } from "../../../helperFunctions";

export default function TimecardHeaderNotesModal(props) {
	const member = useMemo(() => props.member ?? null, [props.member]);
	const currentPayPeriod = useMemo(
		() => props?.payPeriods?.currentPayPeriod ?? null,
		[props?.payPeriods?.currentPayPeriod]
	);

	const timecardHeader = useMemo(
		() => props?.timecardList?.timecardHeader ?? null,
		[props?.timecardList?.timecardHeader]
	);
	const timecardRows = useMemo(() => props?.timecardList?.payweekHours ?? [], [props?.timecardList?.payweekHours]);

	// log("TIMECARD ROWS", timecardRows);

	return (
		<Modal isOpen={props.notesOpen} onClose={props.closeNotes} size="xl">
			<ModalOverlay />
			<ModalContent>
				<ModalHeader py={2}>Week Notes For {member?.employeeName}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Textarea defaultValue={timecardHeader?.weekNotes} onBlur={props.updateWeekNotes} />
					<Text>Daily Notes:</Text>
					{timecardRows?.map((payweek, i) => (
						<List>
							{payweek.notes.map((note, j) => {
								if (note?.length > 0) {
									return (
										<ListItem key={j}>
											{currentPayPeriod?.weekDates[j].day}&nbsp;
											{currentPayPeriod?.weekDates[j].dateStr.replace(
												/(\d{1,2}\/\d{1,2}).*/gm,
												"$1"
											)}{" "}
											- {note}
										</ListItem>
									);
								} else {
									return <></>;
								}
							})}
						</List>
					))}
				</ModalBody>
				<ModalFooter>
					<Button size="sm" colorScheme="red" py={2} onClick={props.closeNotes}>
						CLOSE
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
