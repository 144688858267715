import React, { useMemo, useState, useCallback, useEffect } from "react";
import { SimpleGrid, Box, Flex, Text, Heading, Input, Stack, HStack } from "@chakra-ui/react";
import DataInput from "../../../core/Inputs/data/DataInput";
import MFRAdditionalInfoSection from "./MFRAdditionalInfoSection";

import { portalUser } from "../../../App";
import { formatValue } from "../../../helperFunctions";

export default function MFRProjectInfoSection(props) {
	const [contract, setContract] = useState({});
	const [projectMgrs, setProjectMgrs] = useState([]);
	const [customers, setCustomers] = useState([]);
	const [platforms, setPlatforms] = useState([]);
	const [projectTypes, setProjectTypes] = useState([]);
	const [contractTypes, setContractTypes] = useState([]);
	const [scopePcts, setScopePcts] = useState([]);

	const [pmName, setPMName] = useState(null);
	const [customerName, setCustomerName] = useState(null);
	const [contractType, setContractType] = useState(null);
	const [platform, setPlatform] = useState(null);
	const [projectType, setProjectType] = useState(null);

	const [devAccess, setDevAccess] = useState(false);
	const [execAccess, setExecAccess] = useState(false);

	// const [pmAccess, setPMAccess] = useState(false);

	useEffect(() => {
		setPlatforms(portalUser?.getPlatforms());
		setProjectTypes(portalUser?.getProjectTypes());
		let contractTypes = portalUser?.getContractTypes();
		setContractTypes(
			contractTypes?.sort(function (a, b) {
				return parseFloat(a?.databaseValue) - parseFloat(b?.databaseValue);
			})
		);
	}, []);

	useMemo(() => {
		setContract(props.contract);
		setPMName(props.contract?.pmName);
		setCustomerName(props.contract?.customerName);
		setContractType(props.contract?.contractType);
		setPlatform(props.contract?.platform);
		setProjectType(props.contract?.projectType);

		setCustomers(portalUser?.getARCustomers()?.filter((d) => d.custGroup === props.contract?.jcco) ?? []);
		let projectMgrs = portalUser.getJCProjectMgrs();

		if (props.contract?.regionID === "2") {
			projectMgrs =
				projectMgrs?.filter(
					(d) => (d.regionID === "1" || d.regionID === null) && d.jcco === props.contract?.jcco
				) ?? [];
		} else if (props.contract?.regionID === "4") {
			projectMgrs = projectMgrs?.filter((d) => d.jcco === props.contract?.jcco) ?? [];
		} else {
			projectMgrs =
				projectMgrs?.filter(
					(d) =>
						(d.regionID === props.contract?.regionID || d.regionID === null) &&
						d.jcco === props.contract?.jcco &&
						d.status === "Active"
				) ?? [];
		}

		setProjectMgrs(
			projectMgrs?.sort(function (a, b) {
				return parseFloat(a?.jcco) - parseFloat(b?.jcco) || a?.pmName?.localeCompare(b?.pmName);
			})
		);

		// let projectMgr = projectMgrs?.filter((d) => d?.projectMgrID === props.contract?.projectMgrID)[0] ?? {
		// 	pmEmployeeUID: null,
		// };
		if (Boolean(props.contract?.jccmKeyID)) {
			let regionID = props.contract?.regionID;
			if (regionID === "2") {
				regionID = "1";
			} else if (regionID === "4") {
				regionID = null;
			}

			if (portalUser?.user?.isDevOpsUserYN === "Y") {
				setDevAccess(true);
			} else if (
				portalUser?.user?.isOperationsExecUserYN === "Y" &&
				(regionID === portalUser?.user?.regionID || regionID === null)
			) {
				setExecAccess(true);
			}
			// else if (portalUser?.user?.employeeUID === projectMgr.pmEmployeeUID) {
			// 	setPMAccess(true);
			// }
		}

		let scopePcts = [];
		if (Boolean(props.contract?.pctPLUM > 0)) {
			scopePcts.push({ scope: "PLUM", pct: props.contract?.pctPLUM });
		}
		if (Boolean(props.contract?.pctHVAC > 0)) {
			scopePcts.push({ scope: "HVAC", pct: props.contract?.pctHVAC });
		}
		if (Boolean(props.contract?.pctPIPE > 0)) {
			scopePcts.push({ scope: "PIPE", pct: props.contract?.pctPIPE });
		}
		if (Boolean(props.contract?.pctFUEL > 0)) {
			scopePcts.push({ scope: "FUEL", pct: props.contract?.pctFUEL });
		}
		if (Boolean(props.contract?.pctPROC > 0)) {
			scopePcts.push({ scope: "PROC", pct: props.contract?.pctPROC });
		}
		if (Boolean(props.contract?.pctFIRE > 0)) {
			scopePcts.push({ scope: "FIRE", pct: props.contract?.pctFIRE });
		}
		if (Boolean(props.contract?.pctSITE > 0)) {
			scopePcts.push({ scope: "SITE", pct: props.contract?.pctSITE });
		}
		if (scopePcts.length === 0) {
			scopePcts.push({ scope: "GC's", pct: 100 });
		}
		setScopePcts(scopePcts);
	}, [props.contract]);

	const updateProjectMgr = useCallback(
		(e) => {
			if (e.projectMgrID !== contract?.projectMgrID) {
				contract.update("projectMgr", e);
				setPMName(contract?.pmName ?? null);
			}
		},
		[contract]
	);

	const updateCustomer = useCallback(
		(e) => {
			if (e.customer !== contract?.customer) {
				contract.update("customer", e);
				setCustomerName(contract?.customerName ?? null);
			}
		},
		[contract]
	);

	const updateContractType = useCallback(
		(e) => {
			if (e.databaseValue !== contract?.contractTypeID) {
				contract.update("contractType", e);
				setContractType(contract?.contractType ?? null);
				props.selectContract(contract?.jccmKeyID, false, true);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[contract]
	);

	const updatePlatform = useCallback(
		(e) => {
			if (e.databaseValue !== contract?.platformID) {
				contract.update("platform", e);
				setPlatform(contract?.platform ?? null);
			}
		},
		[contract]
	);

	const updateProjectType = useCallback(
		(e) => {
			if (e.databaseValue !== contract?.projectTypeID) {
				contract.update("projectType", e);
				setProjectType(contract?.projectType ?? null);
			}
		},
		[contract]
	);

	return (
		<Stack w="full" spacing={2} px={4}>
			<HStack spacing={8} w="full" align="flex-start">
				<Box w="full" flex={5}>
					<Flex direction="row" flex={1} w="full" align="center">
						<Heading
							py="1"
							flex={2}
							fontSize={"sm"}
							letterSpacing={1}
							color={"teal.600"}
							textTransform="uppercase"
							isTruncated
							textAlign="right"
							pr="3"
						>
							Project Manager
						</Heading>
						<Flex align="center" flex={4} borderTop="2px" borderLeft="2px" borderRight="2px">
							<Flex
								flex={1}
								align="center"
								borderBottomColor={"gray.400"}
								borderBottomWidth="1px"
								minH={"30px"}
							>
								{Boolean(devAccess || execAccess) ? (
									<DataInput
										key={"input-1-" + pmName}
										type="select"
										variant="unstyled"
										searchable
										inputProps={{
											py: "1",
											rounded: "0px",
											_hover: { bg: "blackAlpha.50", cursor: "pointer" },
										}}
										value={pmName}
										options={projectMgrs}
										optionText="pmName"
										optionSubheading="title"
										onOptionSelect={updateProjectMgr}
									/>
								) : (
									<Text
										_hover={{ cursor: "not-allowed" }}
										fontFamily={"var(--chakra-fonts-body)"}
										px="2"
										py="1"
										variant="unstyled"
										bg="white"
										color="gray.600"
										type="text"
										textAlign="left"
										w="full"
									>
										{pmName}
									</Text>
								)}
							</Flex>
						</Flex>
					</Flex>

					<Flex direction="row" flex={1} w="full" align="center">
						<Heading
							py="1"
							flex={2}
							fontSize={"sm"}
							letterSpacing={1}
							color={"teal.600"}
							textTransform="uppercase"
							isTruncated
							textAlign="right"
							pr="3"
						>
							Project Engineer
						</Heading>
						<Flex align="center" flex={4} borderLeft="2px" borderRight="2px">
							<Flex
								flex={1}
								align="center"
								borderBottomColor={"gray.400"}
								borderBottomWidth="1px"
								minH={"30px"}
							>
								<Input
									key={"input-2-" + contract?.peName}
									_hover={{ bg: "blackAlpha.50", cursor: "pointer" }}
									px="2"
									py="1"
									variant="unstyled"
									bg="white"
									borderRadius="0px"
									color="gray.600"
									_focus={{
										borderBottom: "1px solid",
										borderColor: "green.600",
									}}
									type="text"
									textAlign="left"
									defaultValue={contract?.peName}
									onBlur={(ev) => {
										if (ev.target.value !== contract?.peName) {
											contract?.update("peName", ev.target.value);
										}
									}}
								/>
							</Flex>
						</Flex>
					</Flex>

					<Flex direction="row" flex={1} w="full" align="center">
						<Heading
							py="1"
							flex={2}
							fontSize={"sm"}
							letterSpacing={1}
							color={"teal.600"}
							textTransform="uppercase"
							isTruncated
							textAlign="right"
							pr="3"
						>
							Customer
						</Heading>
						<Flex align="center" flex={4} borderLeft="2px" borderRight="2px">
							<Flex
								flex={1}
								align="center"
								borderBottomColor={"gray.400"}
								borderBottomWidth="1px"
								minH={"30px"}
							>
								{Boolean(devAccess) ? (
									<DataInput
										key={"input-3-" + customerName}
										type="select"
										variant="unstyled"
										searchable
										inputProps={{
											py: "1",
											rounded: "0px",
											_hover: { bg: "blackAlpha.50", cursor: "pointer" },
										}}
										value={customerName}
										options={customers}
										optionText="customerName"
										optionSubheading="subtitle"
										onOptionSelect={updateCustomer}
									/>
								) : (
									<Text
										_hover={{ cursor: "not-allowed" }}
										fontFamily={"var(--chakra-fonts-body)"}
										px="2"
										py="1"
										variant="unstyled"
										bg="white"
										color="gray.600"
										type="text"
										textAlign="left"
										w="full"
									>
										{customerName}
									</Text>
								)}
							</Flex>
						</Flex>
					</Flex>

					<Flex direction="row" flex={1} w="full" align="center">
						<Heading
							py="1"
							flex={2}
							fontSize={"sm"}
							letterSpacing={1}
							color={"teal.600"}
							textTransform="uppercase"
							isTruncated
							textAlign="right"
							pr="3"
						>
							Owner
						</Heading>
						<Flex align="center" flex={4} borderLeft="2px" borderRight="2px">
							<Flex
								flex={1}
								align="center"
								borderBottomColor={"gray.400"}
								borderBottomWidth="1px"
								minH={"30px"}
							>
								<Input
									key={"input-4-" + contract?.ownerName}
									_hover={{ bg: "blackAlpha.50", cursor: "pointer" }}
									px="2"
									py="1"
									variant="unstyled"
									bg="white"
									color="gray.600"
									borderRadius="0px"
									_focus={{
										borderBottom: "1px solid",
										borderColor: "green.600",
									}}
									type="text"
									textAlign="left"
									defaultValue={contract?.ownerName}
									onBlur={(ev) => {
										if (ev.target.value !== contract?.ownerName) {
											contract?.update("ownerName", ev.target.value);
										}
									}}
								/>
							</Flex>
						</Flex>
					</Flex>

					<Flex direction="row" flex={1} w="full" align="center">
						<Heading
							py="1"
							flex={2}
							fontSize={"sm"}
							letterSpacing={1}
							color={"teal.600"}
							textTransform="uppercase"
							isTruncated
							textAlign="right"
							pr="3"
						>
							Foreman
						</Heading>
						<Flex align="center" flex={4} borderLeft="2px" borderRight="2px">
							<Flex
								flex={1}
								align="center"
								borderBottomColor={"gray.400"}
								borderBottomWidth="1px"
								minH={"30px"}
							>
								<Input
									key={"input-5-" + contract?.foremanName}
									_hover={{ bg: "blackAlpha.50", cursor: "pointer" }}
									px="2"
									py="1"
									variant="unstyled"
									bg="white"
									color="gray.600"
									borderRadius="0px"
									_focus={{
										borderBottom: "1px solid",
										borderColor: "green.600",
									}}
									type="text"
									textAlign="left"
									defaultValue={contract?.foremanName}
									onBlur={(ev) => {
										if (ev.target.value !== contract?.foremanName) {
											contract?.update("foremanName", ev.target.value);
										}
									}}
								/>
							</Flex>
						</Flex>
					</Flex>

					<Flex direction="row" flex={1} w="full" align="center">
						<Heading
							py="1"
							flex={2}
							fontSize={"sm"}
							letterSpacing={1}
							color={"teal.600"}
							textTransform="uppercase"
							isTruncated
							textAlign="right"
							pr="3"
						>
							Contract Type
						</Heading>
						<Flex align="center" flex={4} borderLeft="2px" borderRight="2px">
							<Flex
								flex={1}
								align="center"
								borderBottomColor={"gray.400"}
								borderBottomWidth="1px"
								minH={"30px"}
							>
								<DataInput
									key={"input-6-" + contractType}
									type="select"
									variant="unstyled"
									searchable
									inputProps={{
										py: "1",
										rounded: "0px",
										_hover: { bg: "blackAlpha.50", cursor: "pointer" },
									}}
									value={contractType}
									options={contractTypes}
									optionText="displayValue"
									onOptionSelect={updateContractType}
								/>
							</Flex>
						</Flex>
					</Flex>

					<Flex direction="row" flex={1} w="full" align="center">
						<Heading
							py="1"
							flex={2}
							fontSize={"sm"}
							letterSpacing={1}
							color={"teal.600"}
							textTransform="uppercase"
							isTruncated
							textAlign="right"
							pr="3"
						>
							Platform
						</Heading>

						<Flex align="center" flex={4} borderBottom="2px" borderLeft="2px" borderRight="2px">
							<Flex flex={1} align="center" minH={"30px"}>
								<DataInput
									key={"input-7-" + platform}
									type="select"
									variant="unstyled"
									searchable
									inputProps={{
										py: "1",
										rounded: "0px",
										_hover: { bg: "blackAlpha.50", cursor: "pointer" },
									}}
									value={platform}
									options={platforms}
									optionText="displayValue"
									onOptionSelect={updatePlatform}
								/>
							</Flex>
						</Flex>
					</Flex>
				</Box>

				<Box w="full" flex={4}>
					<Flex direction="row" flex={1} w="full" align="center">
						<Heading
							py="1"
							flex={2}
							fontSize={"sm"}
							letterSpacing={1}
							color={"teal.600"}
							textTransform="uppercase"
							isTruncated
							textAlign="right"
							pr="3"
						>
							PM Cell
						</Heading>

						<Flex align="center" flex={4} borderTop="2px" borderLeft="2px" borderRight="2px">
							<Flex
								flex={1}
								align="center"
								borderBottomColor={"gray.400"}
								borderBottomWidth="1px"
								minH={"30px"}
							>
								<Text
									px="2"
									py="1"
									bg="white"
									color="gray.600"
									type="text"
									textAlign="left"
									fontFamily={"var(--chakra-fonts-body)"}
									_hover={{ cursor: "not-allowed" }}
									w="full"
								>
									{contract?.pmCell}
								</Text>
							</Flex>
						</Flex>
					</Flex>

					<Flex direction="row" flex={1} w="full" align="center">
						<Heading
							py="1"
							flex={2}
							fontSize={"sm"}
							letterSpacing={1}
							color={"teal.600"}
							textTransform="uppercase"
							isTruncated
							textAlign="right"
							pr="3"
						>
							PE Cell
						</Heading>
						<Flex align="center" flex={4} borderLeft="2px" borderRight="2px">
							<Flex
								flex={1}
								align="center"
								borderBottomColor={"gray.400"}
								borderBottomWidth="1px"
								minH={"30px"}
							>
								<Input
									_hover={{ bg: "blackAlpha.50", cursor: "pointer" }}
									px="2"
									py="1"
									variant="unstyled"
									bg="white"
									color="gray.600"
									borderRadius="0px"
									_focus={{
										borderBottom: "1px solid",
										borderColor: "green.600",
									}}
									type="text"
									textAlign="left"
									defaultValue={contract?.peCell}
									onBlur={(ev) => {
										if (ev.target.value !== contract?.peCell) {
											contract?.update("peCell", ev.target.value);
										}
									}}
								/>
							</Flex>
						</Flex>
					</Flex>

					<Flex direction="row" flex={1} w="full" align="center">
						<Heading
							py="1"
							flex={2}
							fontSize={"sm"}
							letterSpacing={1}
							color={"teal.600"}
							textTransform="uppercase"
							isTruncated
							textAlign="right"
							pr="3"
						>
							Telephone
						</Heading>
						<Flex align="center" flex={4} borderLeft="2px" borderRight="2px">
							<Flex
								flex={1}
								align="center"
								borderBottomColor={"gray.400"}
								borderBottomWidth="1px"
								minH={"30px"}
							>
								<Text
									px="2"
									py="1"
									bg="white"
									color="gray.600"
									textAlign="left"
									fontFamily={"var(--chakra-fonts-body)"}
									_hover={{ cursor: "not-allowed" }}
									w="full"
								>
									{contract?.customerPhone}
								</Text>
							</Flex>
						</Flex>
					</Flex>

					<Flex direction="row" flex={1} w="full" align="center">
						<Heading
							py="1"
							flex={2}
							fontSize={"sm"}
							letterSpacing={1}
							color={"teal.600"}
							textTransform="uppercase"
							isTruncated
							textAlign="right"
							pr="3"
						>
							Telephone
						</Heading>
						<Flex align="center" flex={4} borderLeft="2px" borderRight="2px">
							<Flex
								flex={1}
								align="center"
								borderBottomColor={"gray.400"}
								borderBottomWidth="1px"
								minH={"30px"}
							>
								<Input
									key={"input-8-" + contract?.ownerPhone}
									_hover={{ bg: "blackAlpha.50", cursor: "pointer" }}
									px="2"
									py="1"
									variant="unstyled"
									bg="white"
									color="gray.600"
									borderRadius="0px"
									_focus={{
										borderBottom: "1px solid",
										borderColor: "green.600",
									}}
									type="text"
									textAlign="left"
									defaultValue={contract?.ownerPhone}
									onBlur={(ev) => {
										if (ev.target.value !== contract?.ownerPhone) {
											contract?.update("ownerPhone", ev.target.value);
										}
									}}
								/>
							</Flex>
						</Flex>
					</Flex>

					<Flex direction="row" flex={1} w="full" align="center">
						<Heading
							py="1"
							flex={2}
							fontSize={"sm"}
							letterSpacing={1}
							color={"teal.600"}
							textTransform="uppercase"
							isTruncated
							textAlign="right"
							pr="3"
						>
							Foreman Cell
						</Heading>
						<Flex align="center" flex={4} borderLeft="2px" borderRight="2px">
							<Flex
								flex={1}
								align="center"
								borderBottomColor={"gray.400"}
								borderBottomWidth="1px"
								minH={"30px"}
							>
								<Input
									key={"input-9-" + contract?.foremanCell}
									_hover={{ bg: "blackAlpha.50", cursor: "pointer" }}
									px="2"
									py="1"
									variant="unstyled"
									bg="white"
									color="gray.600"
									borderRadius="0px"
									_focus={{
										borderBottom: "1px solid",
										borderColor: "green.600",
									}}
									type="text"
									textAlign="left"
									defaultValue={contract?.foremanCell}
									onBlur={(ev) => {
										if (ev.target.value !== contract?.foremanCell) {
											contract?.update("foremanCell", ev.target.value);
										}
									}}
								/>
							</Flex>
						</Flex>
					</Flex>

					<Flex direction="row" flex={1} w="full" align="center">
						<Heading
							py="1"
							flex={2}
							fontSize={"sm"}
							letterSpacing={1}
							color={"teal.600"}
							textTransform="uppercase"
							isTruncated
							textAlign="right"
							pr="3"
						>
							Project Type
						</Heading>
						<Flex align="center" flex={4} borderBottom="2px" borderLeft="2px" borderRight="2px">
							<Flex flex={1} align="center" minH={"30px"}>
								<DataInput
									key={"input-10-" + projectType}
									type="select"
									variant="unstyled"
									searchable
									inputProps={{
										py: "1",
										rounded: "0px",
										_hover: { bg: "blackAlpha.50", cursor: "pointer" },
										bg: projectType === "Unspecified" ? "red.300" : "white",
									}}
									value={projectType}
									options={projectTypes}
									optionText="displayValue"
									onOptionSelect={updateProjectType}
								/>
							</Flex>
						</Flex>
					</Flex>

					<Flex direction="row" flex={1} w="full" align="flex-start">
						<Heading
							py="2"
							flex={2}
							fontSize={"sm"}
							whiteSpace="nowrap"
							letterSpacing={1}
							color={"teal.600"}
							textTransform="uppercase"
							textAlign="right"
							pr="3"
							minH={"30px"}
						>
							Scope Of Work
						</Heading>
						<Flex w="full" flex={4} justify="center">
							{Boolean(contract) ? (
								<SimpleGrid columns={[1, 2, null, null, 3]} py={1} spacingX={2} spacingY={1} w="full">
									{scopePcts?.map((scope, i) => (
										<HStack minW="100px" spacing={2} whiteSpace="nowrap" key={i}>
											<Heading
												whiteSpace="nowrap"
												minW={"50px"}
												py="1"
												fontSize={"sm"}
												letterSpacing={1}
												color={"gray.500"}
												textTransform="uppercase"
												textAlign="center"
											>
												{scope.scope}
											</Heading>
											<Text
												_hover={{ cursor: "not-allowed" }}
												fontSize={"sm"}
												minW={"50px"}
												maxW="70px"
												borderBottom="1px"
												borderBottomColor="gray.600"
												color={"gray.600"}
												textAlign="center"
												w="full"
											>
												{formatValue(scope?.pct, 1, "percent")}
											</Text>
										</HStack>
									))}
								</SimpleGrid>
							) : (
								<Text
									as={"i"}
									fontSize="sm"
									letterSpacing={1}
									py="2"
									w="full"
									textAlign="left"
									color="gray.400"
								>
									N/A - No Project Selected
								</Text>
							)}
						</Flex>
					</Flex>
				</Box>

				<Box></Box>
			</HStack>
			<MFRAdditionalInfoSection contract={contract} />
		</Stack>
	);
}
