import React, { useState, useMemo, useCallback, useRef, useLayoutEffect } from "react";
import { Flex } from "@chakra-ui/react";
import useScrollbarWidth from "../functions/useScrollbarWidth";
import TableSizesHeaderRow from "./TableSizesHeaderRow";
import TableHeader from "./TableHeader";
import TableHeaderRow from "./TableHeaderRow";
import TableBody from "./TableBody";
import TableBodyRow from "./TableBodyRow";

export default function VirtualTable({
	subSection,
	getTableProps,
	getTableBodyProps,
	headerGroups,
	// footerGroups,
	prepareRow,
	rows,
	setSelectedRow,
	updateMyData,
	sizesIsOpen = false,
	filterIsOpen = false,
}) {
	const ref = useRef(null);
	const headerGroup = useMemo(() => headerGroups[headerGroups?.length - 1], [headerGroups]);
	// const footerGroup = useMemo(() => footerGroups[footerGroups?.length - 1], [footerGroups]);

	const rowHeight = useMemo(() => subSection?.rowHeight ?? 40, [subSection?.rowHeight]);
	const rowCount = useMemo(() => rows.length ?? 0, [rows.length]);
	const maxRows = useMemo(() => subSection?.maxRows ?? 0, [subSection?.maxRows]);

	const overflowX = useMemo(() => subSection?.overflowX ?? "hidden", [subSection?.overflowX]);
	const flexWidth = useMemo(() => subSection?.flexWidth ?? "100%", [subSection?.flexWidth]);
	const maxWidth = useMemo(() => subSection?.maxWidth ?? "100%", [subSection?.maxWidth]);

	const floatRowHeight = useMemo(() => subSection?.getFloatHeaderMinH() ?? 0, [subSection]);

	const [tableBodyWidth, setTableBodyWidth] = useState(0);
	const tableBodyHeight = useMemo(() => {
		let totalRows = rowCount >= maxRows ? maxRows : rowCount;
		let tableBodyHeight = totalRows * rowHeight;
		return tableBodyHeight;
	}, [maxRows, rowHeight, rowCount]);

	const scrollBarWidth = useScrollbarWidth();
	const scrollBarPadding = useMemo(
		() => (rowCount > maxRows ? scrollBarWidth + "px" : 0),
		[scrollBarWidth, maxRows, rowCount]
	);

	// Update the table body width on resize
	useLayoutEffect(() => {
		function handleResize() {
			if (ref?.current) {
				let width = ref?.current?.offsetWidth ?? 0;
				if (width > maxWidth) {
					width = maxWidth;
				}
				setTableBodyWidth(width);
			}
		}
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [ref, rowCount, maxWidth]);

	// Callback function to render table header row based on row type
	const getTableHeaderRow = useCallback(
		(headerGroup, rowType) => (
			<TableHeaderRow
				rowType={rowType}
				headerGroup={headerGroup}
				scrollBarPadding={scrollBarPadding}
				rowHeight={rowHeight}
			/>
		),
		[rowHeight, scrollBarPadding]
	);

	// Callback function to render table body row
	const getTableBodyRow = useCallback(
		({ index, style }) => {
			const row = rows[index];
			prepareRow(rows[index]);
			return (
				<TableBodyRow
					key={index}
					row={row}
					style={style}
					setSelectedRow={setSelectedRow}
					updateMyData={updateMyData}
				/>
			);
		},
		[prepareRow, rows, setSelectedRow, updateMyData]
	);

	const getTableBodyRowHeight = useCallback(() => {
		return rowHeight;
	}, [rowHeight]);

	return (
		<Flex direction="column" w="full" rounded={"md"}>
			<TableSizesHeaderRow
				headerGroup={headerGroup}
				sizesIsOpen={sizesIsOpen}
				tableBodyWidth={tableBodyWidth}
				scrollBarPadding={scrollBarPadding}
				maxWidth={maxWidth}
			/>
			<Flex
				{...getTableProps}
				flex={1}
				w="full"
				justify="flex-start"
				direction="column"
				overflowX={overflowX}
				className="broadScroll"
			>
				<Flex direction="column" flex={1} w={flexWidth} maxW={maxWidth} minW="full" pb={2} ref={ref}>
					<TableHeader
						getTableHeaderRow={getTableHeaderRow}
						headerGroup={headerGroup}
						tableBodyWidth={tableBodyWidth}
						sizesIsOpen={sizesIsOpen}
						filterIsOpen={filterIsOpen}
						floatRowHeight={floatRowHeight}
					/>

					{rowCount > 0 && tableBodyWidth > 0 && (
						<TableBody
							getTableBodyProps={getTableBodyProps}
							tableBodyHeight={tableBodyHeight}
							tableBodyWidth={tableBodyWidth}
							getTableBodyRow={getTableBodyRow}
							getTableBodyRowHeight={getTableBodyRowHeight}
							rowCount={rowCount}
							rowHeight={rowHeight}
							maxRows={maxRows}
							sliceType={subSection.sliceType}
						/>
					)}

					{/* {Boolean(footerGroup) && getTableHeaderRow(footerGroup, "TableFooterRow")} */}
				</Flex>
			</Flex>
		</Flex>
	);
}
