import React, { useState, useMemo, useCallback } from "react";
import { Flex, Text } from "@chakra-ui/react";
import PageTemplateEmployees from "../../HRAdmin/classes/PageTemplateEmployees";
import LoadingPage from "../../../core/ProgressIndicators/components/LoadingSection";
import Page from "../../../core/ReactTable/layout/Page";
import Panels from "../../../core/ReactTable/layout/TabPanels";
// import { log } from "../../../helperFunctions";

export default function EmployeesPage({ employees, tabVal, changeTab, isLoading = false, setSelectedRow }) {
	const [panelVal, setPanelVal] = useState(0);
	const changePanel = useCallback((panelVal = 0) => {
		setPanelVal(panelVal);
	}, []);

	const panels = useMemo(() => {
		let panels = [];
		panels.push({
			title: "Employees",
			panel: "Employees",
			data: employees ?? [],
		});

		return panels;
	}, [employees]);

	//Identify cases when there is no data for the given tab panel
	//Nobody likes infinite loading screens or crashing virtual tables
	const noData = useMemo(() => {
		if (employees?.length === 0) {
			return true;
		} else if (panels[panelVal]?.data?.length === 0) {
			return true;
		} else {
			return false;
		}
	}, [employees?.length, panelVal, panels]);

	return (
		<Flex direction="column" flex={1} justify="flex-start" h="full" rounded={5} position="relative">
			{isLoading && <LoadingPage />}
			{!isLoading && noData && <Text>No Employees</Text>}
			{!isLoading && !noData && (
				<Panels panels={panels} panelVal={panelVal} changePanel={changePanel}>
					<EmployeesPageTemplate
						selectedPanel={panels[panelVal]}
						panelVal={panelVal}
						changePanel={changePanel}
						changeTab={changeTab}
						setSelectedRow={setSelectedRow}
					/>
				</Panels>
			)}
		</Flex>
	);
}

function EmployeesPageTemplate({ selectedPanel, panelVal, changePanel, setSelectedRow }) {
	const pageTemplate = useMemo(
		() =>
			new PageTemplateEmployees(
				selectedPanel?.data,
				{ module: "Employee" },
				{
					pageName: "Company Contacts",
					pageTitle: "Employees",
				}
			),

		[selectedPanel]
	);

	return (
		<Flex direction="column" flex={1} justify="flex-start" h="full" rounded={5}>
			{pageTemplate?.data?.length === 0 ? (
				<LoadingPage />
			) : (
				<Page
					pageTemplate={pageTemplate}
					//Add any styling overrides specifically for the Web View vs PDF View
					//such as headings, background colors, or showing/hiding headers/footers
					overrideView={{
						showReportHeader: false,
						// showPageHeader: false,
						showPageFooter: false,
						shadow: "none",
						pageName: "Employees",
						pageTitle: "Company Contacts",
						orientation: "full",
					}}
					panelVal={panelVal}
					changePanel={changePanel}
					setSelectedRow={setSelectedRow}
				/>
			)}
		</Flex>
	);
}
