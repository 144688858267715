const DF_TEMPLATE_TYPES = [
	{
		template: "D&F",
		bannerTitle: "Detailing & Fab Schedule (D&F)",
		bannerSubtitle: "D&F Projects",
		tabs: ["D&F Jobs", "D&F Schedule"],
		scopeID: null,
		mapKey: ["scopeDesc", null],
	},
	{
		template: "SUP",
		bannerTitle: "Start Up Planner (SUP)",
		bannerSubtitle: "Start Up Projects",
		tabs: ["SUP Jobs", "SUP Schedule"],
		scopeID: "13",
		mapKey: [null, null],
	},
];
export default DF_TEMPLATE_TYPES;
