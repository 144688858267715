import React, { useState, useCallback, useEffect } from "react";
import { Stack, Heading, Collapse, HStack, useBoolean, IconButton } from "@chakra-ui/react";
import DataInput from "../../../core/Inputs/data/DataInput";
import CONTRACT_ITEM_TYPES from "../constants/ContractItemTypes";

import { portalUser } from "../../../App";
import { formatValue, convertArrayToMap } from "../../../helperFunctions";

const itemTypesMap = convertArrayToMap(CONTRACT_ITEM_TYPES, "value");

export default function PCOItemDrawer({ coItem, contractItems, spacing, i, itemCount, deleteCOItem }) {
	const [updateTrigger, setUpdateTrigger] = useState(false);

	const [showPMOIKeyID, setShowPMOIKeyID] = useState(false);
	const toggleShowPMOIKeyID = () => {
		setShowPMOIKeyID(!showPMOIKeyID);
	};

	const [deleteHover, setDeleteHover] = useBoolean();
	// const [showDeleteWarning, setShowDeleteWarning] = useState(false);
	// const toggleShowDeleteWarning = () => {
	// 	setShowDeleteWarning(!showDeleteWarning);
	// };

	let contractItemMap = convertArrayToMap(contractItems, "contractItem");

	const updateCOItem = useCallback(
		(attr, value) => {
			coItem.update(attr, value);
			setUpdateTrigger(true);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[coItem]
	);

	const deletePCOItem = useCallback(
		() => {
			deleteCOItem(coItem.pmoiUID);
			setUpdateTrigger(true);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[coItem]
	);

	useEffect(() => {
		if (updateTrigger) {
			setUpdateTrigger(false);
		}
	}, [updateTrigger]);

	return (
		<Stack
			p={3}
			spacing={spacing.vertSpacing}
			bg={"blackAlpha." + (i + 1) + "00"}
			borderColor={deleteHover ? "red.500" : "blackAlpha.100"}
			borderWidth={2}
			rounded="10"
		>
			{Boolean(itemCount > 1) && (
				<HStack
					w="full"
					pb={1}
					borderBottomWidth={2}
					borderBottomColor="blackAlpha.300"
					justify="space-between"
				>
					<Heading w="full" color={"gray.500"} textTransform="uppercase" fontSize="md" letterSpacing={2}>
						{"PCO Item " + (i + 1)}
					</Heading>

					{Boolean(portalUser?.user?.isDevOpsUserYN === "Y") && (
						<IconButton
							onClick={toggleShowPMOIKeyID}
							bg="whiteAlpha.800"
							_hover={{ bg: "white", borderColor: "blue.500", color: "blue.500" }}
							color="gray.400"
							boxSize="25px"
							minW="25px"
							p={2}
							borderWidth={2}
							icon={<i className="fas fa-key fa-fw " />}
						></IconButton>
					)}

					<IconButton
						onMouseLeave={setDeleteHover.off}
						onMouseEnter={setDeleteHover.on}
						onClick={deletePCOItem}
						bg="whiteAlpha.800"
						_hover={{ bg: "white", borderColor: "red.500", color: "red.500" }}
						color="gray.400"
						boxSize="25px"
						minW="25px"
						p={2}
						borderWidth={2}
						icon={<i className="fas fa-times fa-fw " />}
					></IconButton>
				</HStack>
			)}
			<Collapse in={showPMOIKeyID}>
				<Stack spacing={spacing.vertSpacing} w="full">
					<Stack spacing={spacing.horzSpacing} direction="row" w="full">
						<DataInput
							key={coItem?.pmoiUID + "-pmoiKeyID-" + coItem?.pmoiKeyID}
							name="pmoiKeyID"
							type="text"
							label="PMOI Key ID"
							placeholder="PMOI Key ID"
							isDisabled
							value={coItem?.pmoiKeyID}
							flex={spacing.leftFlex}
							variant="outline"
							inputProps={{ px: 2 }}
							isModal
						/>
						<DataInput
							key={coItem?.pmoiUID + "-jcciKeyID-" + contractItemMap[coItem?.contractItem]?.jcciKeyID}
							name="jcciKeyID"
							type="text"
							label="JCCI Key ID"
							placeholder="JCCI Key ID"
							isDisabled
							value={contractItemMap[coItem?.contractItem]?.jcciKeyID}
							flex={spacing.rightFlex}
							variant="outline"
							inputProps={{ px: 2 }}
							isModal
						/>
					</Stack>
				</Stack>
			</Collapse>

			<Stack spacing={spacing.horzSpacing} direction="row" w="full">
				<DataInput
					key={coItem?.pmoiUID + "-pcoConv-" + coItem?.pcoConv}
					name="pcoConv"
					type="text"
					label="PCO Conversion"
					placeholder={"$0.00"}
					defaultValue={
						formatValue(coItem?.pcoConv, 2, "currency") === "$0.00"
							? null
							: formatValue(coItem?.pcoConv, 2, "currency")
					}
					onBlur={(ev) => {
						if (ev.target.value !== coItem?.pcoConv) {
							updateCOItem("pcoConv", ev.target.value);
						}
					}}
					flex={spacing.leftFlex}
					inputProps={{ py: 1, fontFamily: "'Helvetica',sans-serif", fontSize: "md" }}
					variant="outline"
					isModal
				/>
				<DataInput
					key={coItem?.pmoiUID + "-pctMargin-" + coItem?.pctMargin}
					name="pctMargin"
					type="text"
					label="% Margin"
					placeholder={"0%"}
					defaultValue={
						formatValue(coItem?.pctMargin, 0, "percent") === "0%"
							? null
							: formatValue(coItem?.pctMargin, 0, "percent")
					}
					onBlur={(ev) => {
						if (ev.target.value !== coItem?.pctMargin) {
							updateCOItem("pctMargin", formatValue(ev.target.value, 0, "number"));
						}
					}}
					flex={spacing.rightFlex}
					variant="outline"
					isModal
				/>
			</Stack>

			<Stack spacing={spacing.horzSpacing} direction="row" w="full" pb={2}>
				{/* IF CONTRACT ITEMS LENGTH IS GREATER THAN 1 SHOW DROPDOWN */}
				{Boolean(contractItems?.length > 1) && (
					<DataInput
						key={coItem?.pmoiUID + "-contractItem-" + coItem?.contractItem}
						name="contractItem"
						type="select"
						placeholder="Select Contract Item"
						label="Contract Item"
						searchable
						options={contractItems}
						value={coItem?.contractItem}
						optionValue="contractItem"
						optionText="title"
						optionSubheading="subtitle"
						onOptionSelect={(ev) => {
							if (ev !== coItem?.contractItem) {
								updateCOItem("contractItem", ev);
								updateCOItem(
									"pctMargin",
									itemTypesMap[contractItemMap[coItem?.contractItem]?.itemTypeID ?? "LS"]?.pctMargin
								);
							}
						}}
						flex={spacing.leftFlex}
						variant="outline"
						isModal
					/>
				)}
				{/* IF CONTRACT ITEMS LENGTH IS LESS THAN OR EQUAL TO 1 SHOW DISABLED TEXT INPUT */}
				{Boolean(contractItems?.length <= 1) && (
					<DataInput
						key={coItem?.pmoiUID + "-contractItem-" + coItem?.contractItem}
						name="contractItem"
						type="text"
						placeholder="Select Contract Item"
						label="Contract Item"
						value={contractItemMap[coItem?.contractItem]?.title}
						flex={spacing.leftFlex}
						variant="outline"
						inputProps={{ px: 2 }}
						isModal
						isDisabled
					/>
				)}

				<DataInput
					key={coItem?.pmoiUID + "-pctComplete-" + coItem?.pctComplete}
					name="pctComplete"
					type="text"
					label="% Complete"
					placeholder={"0%"}
					defaultValue={
						formatValue(coItem?.pctComplete, 0, "percent") === "0%"
							? null
							: formatValue(coItem?.pctComplete, 0, "percent")
					}
					onBlur={(ev) => {
						if (ev.target.value !== coItem?.pctComplete) {
							updateCOItem("pctComplete", formatValue(ev.target.value, 0, "number"));
						}
					}}
					flex={spacing.rightFlex}
					variant="outline"
					inputProps={{ textAlign: "center", width: "100%" }}
					isModal
				/>
			</Stack>
		</Stack>
	);
}
