import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { Heading, Stack } from "@chakra-ui/react";
import DataInputSelectLegacy from "../common/DataInputSelectLegacy";

import { portalUser } from "../../../App";
import { formatValue, dateIsValid } from "../../../helperFunctions";

export default function PayPeriodSelect({ tabVal, payPeriods, changePayPeriod }) {
	let params = useParams();
	let history = useHistory();

	let basepath = history.location.pathname;
	if (basepath?.length > 0 && basepath?.charAt(0) === "/") {
		basepath = basepath?.slice(1);
		basepath = basepath?.substring(0, basepath?.indexOf("/"));
	}

	let disabledItems = [];
	if (dateIsValid(params.prEndDate)) {
		disabledItems.push(formatValue(params.prEndDate, 0, "date"));
	}

	const selectPayPeriod = (payPeriod) => {
		let prEndDate = payPeriod?.prEndDate;
		if (!dateIsValid(payPeriod?.prEndDate)) {
			prEndDate = portalUser.user?.prEndDate ?? null;
		}
		if (Boolean(prEndDate) && formatValue(prEndDate, 0, "date") !== formatValue(params.prEndDate, 0, "date")) {
			portalUser.user.prEndDate = formatValue(prEndDate, 0, "date");
			prEndDate = formatValue(prEndDate, 0, "dateURL");

			if (basepath === "payroll") {
				if (tabVal === 0) {
					history.push("/payroll/upload/" + prEndDate + "/uploadtime");
				} else if (tabVal === 1) {
					history.push("/payroll/upload/" + prEndDate + "/postedtime");
				} else if (tabVal === 2) {
					history.push("/payroll/upload/" + prEndDate + "/exceptions");
				}
			} else {
				if (tabVal === 0) {
					history.push("/timecard/" + prEndDate);
				} else if (tabVal === 1) {
					history.push("/timecard/" + prEndDate + "/crewtime");
				} else if (tabVal === 2) {
					history.push("/timecard/" + prEndDate + "/approvetime");
				} else if (tabVal === 4) {
					history.push("/timecard/" + prEndDate + "/jobreview");
				}
			}
		}
	};

	return (
		<Stack
			direction={{ base: "column", lg: "row" }}
			align={{ base: "flex-start", lg: "center" }}
			justify="flex-start"
			spacing={{ base: 1, lg: 4 }}
		>
			<Heading fontSize="2xl" color="gray.500" isTruncated letterSpacing={2} textTransform="uppercase">
				Week Ending
			</Heading>
			<DataInputSelectLegacy
				key={params.prEndDate}
				minW="3xs"
				w={{ base: "full", lg: "inherit" }}
				name="payPeriods"
				fontSize="lg"
				fontWeight="bold"
				color="gray.500"
				letterSpacing={1}
				value={formatValue(params.prEndDate, 0, "date")}
				onChange={selectPayPeriod}
				selectOptions={payPeriods?.payPeriods}
				disabledItems={disabledItems}
				title={"prEndDate"}
			/>
		</Stack>
	);
}
