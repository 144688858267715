import React, { useRef } from "react";
import { motion, useMotionTemplate, useMotionValue, useSpring } from "framer-motion";
import {
	Box,
	Flex,
	Text,
	Container,
	Stack,
	Button,
	// useColorModeValue as mode,
} from "@chakra-ui/react";
import "../../../glass.css";

// const ROTATION_RANGE = 32.5;
// const HALF_ROTATION_RANGE = 32.5 / 2;

const ROTATION_RANGE = 60;
const HALF_ROTATION_RANGE = 60 / 2;

export default function GlassTheme() {
	const ref = useRef(null);

	const x = useMotionValue(0);
	const y = useMotionValue(0);

	const xSpring = useSpring(x);
	const ySpring = useSpring(y);

	const transform = useMotionTemplate`rotateX(${xSpring}deg) rotateY(${ySpring}deg)`;

	const handleMouseMove = (e) => {
		if (!ref.current) return [0, 0];

		const rect = ref.current.getBoundingClientRect();

		const width = rect.width;
		const height = rect.height;

		const mouseX = (e.clientX - rect.left) * ROTATION_RANGE;
		const mouseY = (e.clientY - rect.top) * ROTATION_RANGE;

		const rX = (mouseY / height - HALF_ROTATION_RANGE) * -1;
		const rY = mouseX / width - HALF_ROTATION_RANGE;

		x.set(rX);
		y.set(rY);
	};

	const handleMouseLeave = () => {
		x.set(0);
		y.set(0);
	};

	return (
		<Container maxW="full" px={0}>
			<Flex w="full" flex={1}>
				{/* <Center bg="blackAlpha.900" w="full">
					<TiltCard />
				</Center> */}
				<Stack
					className="container-02"
					w="full"
					ref={ref}
					onMouseMove={handleMouseMove}
					onMouseLeave={handleMouseLeave}
					spacing={4}
				>
					{/* <Heading
						textTransform={"uppercase"}
						color="whiteAlpha.700"
						fontWeight={400}
						letterSpacing={2}
						p={4}
					>
						Glass Theme
					</Heading> */}

					<motion.div
						style={{
							transformStyle: "preserve-3d",
							transform,

							minHeight: "var(--chakra-sizes-lg)",
							minWidth: "var(--chakra-sizes-sm)",
							borderRadius: "var(--chakra-radii-lg)",
							position: "relative",
							background: "var(--chakra-colors-whiteAlpha-50)",
							boxShadow: "var(--chakra-shadows-xl)",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							zIndex: 1,
						}}
					>
						<Flex
							position="absolute"
							rounded="lg"
							flex={1}
							justify="center"
							align="center"
							inset={6}
							style={{
								transform: "translateZ(75px)",
								transformStyle: "preserve-3d",
							}}
						>
							<Stack className="glassmorphic-card" flex={1} direction="column" h="full">
								<Flex flex={1} direction="row"></Flex>
								<Flex
									position="relative"
									align="center"
									justify="center"
									flex={1}
									// style={{
									// 	transform: "translateZ(75px)",
									// 	transformStyle: "preserve-3d",
									// }}
								>
									<Box color={"whiteAlpha.300"} fontSize={"max(60px)"}>
										<i className="fa fa-bolt fa-fw fa-2x"></i>
									</Box>
								</Flex>
								<Stack
									align="center"
									justify="flex-end"
									className="contentBox"
									spacing={8}
									color="whiteAlpha.700"
									rounded={"md"}
									h="full"
									w="full"
									flex={1}
									py={8}
									px={4}
								>
									<Stack spacing={4}>
										<Text
											fontFamily={"unset"}
											textAlign="center"
											fontSize={"2xl"}
											textTransform="uppercase"
											letterSpacing={4}
											style={{
												transform: "translateZ(50px)",
												transformStyle: "preserve-3d",
											}}
											color="whiteAlpha.800"
										>
											Design
										</Text>
										<Text textAlign="center">
											Lorem Ipsum is simply dummy text of the printing and typesetting industry.
											Lorem Ipsum has been the industry's standard ...
										</Text>
									</Stack>
									<Button
										bg="whiteAlpha.400"
										textAlign="center"
										fontWeight={"semibold"}
										fontSize={"lg"}
										shadow="lg"
										color="whiteAlpha.700"
										textTransform="uppercase"
										letterSpacing={2}
										style={{
											transform: "translateZ(50px)",
											transformStyle: "preserve-3d",
											borderTop: "1px solid rgba(255, 255, 255, 0.9)",
											borderLeft: "1px solid rgba(255, 255, 255, 0.9)",
										}}
									>
										HOVER ME
									</Button>
								</Stack>
							</Stack>
						</Flex>
					</motion.div>
				</Stack>
			</Flex>
		</Container>
	);
}

// const TiltCard = () => {
// 	const ref = useRef(null);

// 	const x = useMotionValue(0);
// 	const y = useMotionValue(0);

// 	const xSpring = useSpring(x);
// 	const ySpring = useSpring(y);

// 	const transform = useMotionTemplate`rotateX(${xSpring}deg) rotateY(${ySpring}deg)`;

// 	const handleMouseMove = (e) => {
// 		if (!ref.current) return [0, 0];

// 		const rect = ref.current.getBoundingClientRect();

// 		const width = rect.width;
// 		const height = rect.height;

// 		const mouseX = (e.clientX - rect.left) * ROTATION_RANGE;
// 		const mouseY = (e.clientY - rect.top) * ROTATION_RANGE;

// 		const rX = (mouseY / height - HALF_ROTATION_RANGE) * -1;
// 		const rY = mouseX / width - HALF_ROTATION_RANGE;

// 		x.set(rX);
// 		y.set(rY);
// 	};

// 	const handleMouseLeave = () => {
// 		x.set(0);
// 		y.set(0);
// 	};

// 	return (
// 		<Center w="full" h="full" ref={ref} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
// 			<motion.div
// 				style={{
// 					transformStyle: "preserve-3d",
// 					transform,

// 					minHeight: "var(--chakra-sizes-lg)",
// 					minWidth: "var(--chakra-sizes-sm)",
// 					borderRadius: "var(--chakra-radii-lg)",
// 					position: "relative",
// 					background: "var(--chakra-colors-whiteAlpha-400)",
// 					boxShadow: "var(--chakra-shadows-xl)",
// 					display: "flex",
// 					justifyContent: "center",
// 					alignItems: "center",
// 				}}
// 			>
// 				<Flex
// 					position="absolute"
// 					shadow="lg"
// 					bg="whiteAlpha.900"
// 					rounded="lg"
// 					display="flex"
// 					justify="center"
// 					align="center"
// 					p={4}
// 					inset={5}
// 					style={{
// 						transform: "translateZ(75px)",
// 						transformStyle: "preserve-3d",
// 					}}
// 				>
// 					<Stack
// 						minH="3xs"
// 						minW="2xs"
// 						h="full"
// 						flex={1}
// 						align="center"
// 						justify="center"
// 						color="teal.600"
// 						spacing={6}
// 						p={4}
// 						rounded="lg"
// 						// style={{
// 						// 	transform: "translateZ(50px)",
// 						// }}
// 					>
// 						<Box
// 							style={{
// 								transform: "translateZ(75px)",
// 							}}
// 							color={"blackAlpha.50"}
// 							fontSize={"max(75px)"}
// 						>
// 							<i className="fa fa-pencil-ruler fa-fw fa-2x"></i>
// 						</Box>

// 						<Stack align="center" justify="center">
// 							<Text
// 								textAlign="center"
// 								fontSize={"xl"}
// 								textTransform="uppercase"
// 								letterSpacing={3}
// 								style={{
// 									transform: "translateZ(50px)",
// 								}}
// 							>
// 								Design
// 							</Text>
// 							<Text textAlign="center">
// 								Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
// 								has been the industry's standard ...
// 							</Text>
// 						</Stack>
// 						<Button
// 							bg="whiteAlpha.700"
// 							textAlign="center"
// 							fontWeight={"bold"}
// 							fontSize={"lg"}
// 							shadow="sm"
// 							textTransform="uppercase"
// 							style={{
// 								transform: "translateZ(50px)",
// 							}}
// 						>
// 							HOVER ME
// 						</Button>
// 					</Stack>
// 				</Flex>
// 			</motion.div>
// 		</Center>
// 	);
// };

// const TiltCardWhite = () => {
// 	const ref = useRef(null);

// 	const x = useMotionValue(0);
// 	const y = useMotionValue(0);

// 	const xSpring = useSpring(x);
// 	const ySpring = useSpring(y);

// 	const transform = useMotionTemplate`rotateX(${xSpring}deg) rotateY(${ySpring}deg)`;

// 	const handleMouseMove = (e) => {
// 		if (!ref.current) return [0, 0];

// 		const rect = ref.current.getBoundingClientRect();

// 		const width = rect.width;
// 		const height = rect.height;

// 		const mouseX = (e.clientX - rect.left) * ROTATION_RANGE;
// 		const mouseY = (e.clientY - rect.top) * ROTATION_RANGE;

// 		const rX = (mouseY / height - HALF_ROTATION_RANGE) * -1;
// 		const rY = mouseX / width - HALF_ROTATION_RANGE;

// 		x.set(rX);
// 		y.set(rY);
// 	};

// 	const handleMouseLeave = () => {
// 		x.set(0);
// 		y.set(0);
// 	};

// 	return (
// 		<Center w="full" h="full" ref={ref} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
// 			<motion.div
// 				style={{
// 					transformStyle: "preserve-3d",
// 					transform,

// 					minHeight: "var(--chakra-sizes-lg)",
// 					minWidth: "var(--chakra-sizes-sm)",
// 					borderRadius: "var(--chakra-radii-lg)",
// 					position: "relative",
// 					background: "var(--chakra-colors-whiteAlpha-400)",
// 					boxShadow: "var(--chakra-shadows-xl)",
// 					display: "flex",
// 					justifyContent: "center",
// 					alignItems: "center",
// 				}}
// 			>
// 				<Flex
// 					position="absolute"
// 					shadow="lg"
// 					bg="whiteAlpha.900"
// 					rounded="lg"
// 					display="flex"
// 					justify="center"
// 					align="center"
// 					p={4}
// 					inset={5}
// 					style={{
// 						transform: "translateZ(75px)",
// 						transformStyle: "preserve-3d",
// 					}}
// 				>
// 					<Stack
// 						minH="3xs"
// 						minW="2xs"
// 						h="full"
// 						flex={1}
// 						align="center"
// 						justify="center"
// 						color="teal.600"
// 						spacing={6}
// 						p={4}
// 						rounded="lg"
// 						// style={{
// 						// 	transform: "translateZ(50px)",
// 						// }}
// 					>
// 						<Box
// 							style={{
// 								transform: "translateZ(75px)",
// 							}}
// 							color={"blackAlpha.50"}
// 							fontSize={"max(75px)"}
// 						>
// 							<i className="fa fa-pencil-ruler fa-fw fa-2x"></i>
// 						</Box>

// 						<Stack align="center" justify="center">
// 							<Text
// 								textAlign="center"
// 								fontSize={"xl"}
// 								textTransform="uppercase"
// 								letterSpacing={3}
// 								style={{
// 									transform: "translateZ(50px)",
// 								}}
// 							>
// 								Design
// 							</Text>
// 							<Text textAlign="center">
// 								Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
// 								has been the industry's standard ...
// 							</Text>
// 						</Stack>
// 						<Button
// 							bg="whiteAlpha.700"
// 							textAlign="center"
// 							fontWeight={"bold"}
// 							fontSize={"lg"}
// 							shadow="sm"
// 							textTransform="uppercase"
// 							style={{
// 								transform: "translateZ(50px)",
// 							}}
// 						>
// 							HOVER ME
// 						</Button>
// 					</Stack>
// 				</Flex>
// 			</motion.div>
// 		</Center>
// 	);
// };
