import { chakraThemeColorsMap } from "../../../helperFunctions";

export default class SectionTemplateMFRProjectAnalysisSummary {
	constructor(contractTotals = null) {
		this.template = "MFRProjectAnalysisSummary";
		this.name = "Project Analysis Summary";
		this.divider = false;
		this.pagebreak = 1;
		this.type = "labelValuePairs";
		this.minRows = 6;
		this.py = 0;
		this.subSections = [
			new SubSectionTemplateMFRProjectAnalysisSummary1(contractTotals),
			new SubSectionTemplateMFRProjectAnalysisSummary2(),
			new SubSectionTemplateMFRProjectAnalysisSummary3(contractTotals),
		];
	}
}

class SubSectionTemplateMFRProjectAnalysisSummary1 {
	constructor(contractTotals = null) {
		let bidProfitAmt = 0;
		let bidMarginPct = 0;
		if (Boolean(contractTotals?.queryData)) {
			bidProfitAmt = contractTotals?.getBidProfitAmt();
			bidMarginPct = contractTotals?.getBidMarginPct();
		}

		this.template = "ProjectAnalysisSummary1";
		this.type = "labelValuePairs";
		this.width = "30%";
		this.columns = [
			{
				accessor: "label",
				width: "53.33%",
				borderBottomColor: chakraThemeColorsMap.silver,
				borderBottomWidth: 1,
				borderLeftWidth: 1,
				borderRightWidth: 0,
				fontSize: 9,
			},
			{
				accessor: "value",
				width: "46.67%",
				valueType: "currency",
				textAlign: "right",
				borderBottomColor: chakraThemeColorsMap.silver,
				borderBottomWidth: 1,
				borderLeftWidth: 0,
				borderRightWidth: 1,
				fontSize: 9,
			},
		];
		this.data = [
			{
				label: "Orig Bid Profit ($)",
				value: bidProfitAmt,
			},
			{
				label: "Orig Bid Margin (%)",
				labelBorderBottomColor: chakraThemeColorsMap.gray700,
				value: bidMarginPct,
				valueType: "percent",
				valueDecimals: 1,
				valueBorderBottomColor: chakraThemeColorsMap.gray700,
			},
			{
				label: null,
				labelBorderLeftWidth: 0,
				labelBorderBottomColor: chakraThemeColorsMap.gray700,
				value: null,
				valueType: "string",
				valueBorderRightWidth: 0,
				valueBorderBottomColor: chakraThemeColorsMap.gray700,
			},
			{
				label: "PCO Cost To Date",
				value: contractTotals?.pcoActualCost ?? 0,
			},
			{
				label: "Committed Cost",
				value: contractTotals?.remainCmtdCost ?? 0,
			},
			{
				label: "Unapproved AP",
				labelBorderBottomColor: chakraThemeColorsMap.gray700,
				value: contractTotals?.unapprovedAPAmt ?? 0,
				valueBorderBottomColor: chakraThemeColorsMap.gray700,
			},
		];
	}
}

class SubSectionTemplateMFRProjectAnalysisSummary2 {
	constructor() {
		this.template = "ProjectAnalysisSummary2";
		this.type = "labelValuePairs";
		this.width = "28%";
		this.columns = [
			{
				accessor: "label",
			},
		];
		this.data = [
			{
				label: null,
			},
			{
				label: null,
			},
			{
				label: null,
			},
			{
				label: null,
			},
			{
				label: null,
			},
			{
				label: null,
			},
		];
	}
}

class SubSectionTemplateMFRProjectAnalysisSummary3 {
	constructor(contractTotals = null) {
		this.template = "ProjectAnalysisSummary3";
		this.type = "labelValuePairs";
		this.width = "42%";
		this.columns = [
			{
				accessor: "label",
				width: "66.6%",
				borderTopColor: chakraThemeColorsMap.gray700,
				borderLeftColor: chakraThemeColorsMap.gray700,
				borderRightColor: chakraThemeColorsMap.gray700,
				borderBottomColor: chakraThemeColorsMap.silver,
				borderBottomWidth: 1,
				borderLeftWidth: 1,
				borderRightWidth: 0,
				fontSize: 9,
			},
			{
				accessor: "value",
				width: "33.4%",
				valueType: "currency",
				textAlign: "right",
				borderTopColor: chakraThemeColorsMap.gray700,
				borderLeftColor: chakraThemeColorsMap.gray700,
				borderRightColor: chakraThemeColorsMap.gray700,
				borderBottomColor: chakraThemeColorsMap.silver,
				borderBottomWidth: 1,
				borderLeftWidth: 0,
				borderRightWidth: 1,
				fontSize: 9,
			},
		];
		this.data = [
			{
				label: null,
				labelBorderLeftWidth: 0,
				labelBorderBottomColor: chakraThemeColorsMap.gray700,
				value: contractTotals?.getPctCompleteCosts() ?? 0,
				valueType: "percent",
				valueDecimals: 1,
				valueBorderRightWidth: 0,
				valueRightAddon: "Complete",
				valueBorderBottomColor: chakraThemeColorsMap.gray700,
			},
			{
				label: "Summary",
				labelTextTransform: "uppercase",
				labelBg: chakraThemeColorsMap.teal700,
				labelTextColor: chakraThemeColorsMap.white,
				labelBorderRightColor: chakraThemeColorsMap.teal700,
				labelBorderRightWidth: 1,
				labelBorderBottomColor: chakraThemeColorsMap.gray700,
				labelFontSize: 8,
				value: "Operations",
				valueTextTransform: "uppercase",
				valueType: "string",
				valueBg: chakraThemeColorsMap.teal700,
				valueTextColor: chakraThemeColorsMap.white,
				valueBorderLeftColor: chakraThemeColorsMap.teal700,
				valueBorderLeftWidth: 1,
				valueBorderBottomColor: chakraThemeColorsMap.gray700,
				valueFontSize: 8,
			},

			{
				label: "Projected Contract Amount",
				value: contractTotals?.getProjContractAmt() ?? 0,
			},
			{
				label: "Projected Costs at Completion",
				value: contractTotals?.getProjCostWithPCOs() ?? 0,
			},
			{
				label: "Projected Gross Margin ($)",
				value: contractTotals?.getProjProfitAmt() ?? 0,
			},
			{
				label: "Projected Gross Margin (%)",
				labelBorderBottomColor: chakraThemeColorsMap.gray700,
				value: contractTotals?.getProjMarginPct() ?? 0,
				valueType: "percent",
				valueDecimals: 1,
				valueBorderBottomColor: chakraThemeColorsMap.gray700,
			},
		];
	}
}
