import React from "react";
import ToggleButton from "./ToggleButton";

export default function ArchiveButton({ archiveIsActive = "0", onClickArchiveButton }) {
	return (
		<ToggleButton
			toggleIsOpen={archiveIsActive}
			onClick={onClickArchiveButton}
			toggleOptions={[
				{
					isOpen: true,
					label: "Archive",
					icon: "fas fa-archive",
					color: "teal.600",
					bg: "whiteAlpha.900",
				},
				{
					isOpen: false,
					label: "Restore",
					icon: "fas fa-history",
					color: "blue.500",
					bg: "whiteAlpha.700",
				},
			]}
		/>
	);
}
