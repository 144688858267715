import React from "react";
import { Box, Flex, Heading, Stack, IconButton } from "@chakra-ui/react";
import { Calendar as ReactCalendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { portalUser } from "../../../App";
import moment from "moment";

const localizer = momentLocalizer(moment);

export default class Calendar extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			events: props.calendarEvents,
			selectedEvent: null,
			showSchedules: true,
			showMeetings: false,
			showVacations: true,
			showHolidays: true,
			showOutlook: false,

			tabVal: 0,
			year: moment(new Date()).format("YYYY"),
		};

		this.init = this.init.bind(this);
		this.filterEvents = this.filterEvents.bind(this);
		this.openEvent = this.openEvent.bind(this);
		this.closeEvent = this.closeEvent.bind(this);
		this.onRangeChange = this.onRangeChange.bind(this);
		this.handleTabChange = this.handleTabChange.bind(this);
		this.applyFilters = this.applyFilters.bind(this);
	}

	componentDidMount() {
		this.init();
	}

	async init() {
		this.filterEvents();
	}

	openEvent(event) {
		let showSchedules = true;
		let showMeetings = true;

		this.setState(
			{
				selectedEvent: event,
				showSchedules: showSchedules,
				showMeetings: showMeetings,
			},
			() => {
				this.filterEvents();
			}
		);
	}

	closeEvent() {
		let selectedEvent = null;
		let showSchedules = true;
		let showMeetings = false;

		this.setState(
			{
				selectedEvent: selectedEvent,
				showSchedules: showSchedules,
				showMeetings: showMeetings,
			},
			() => {
				this.filterEvents();
			}
		);
	}

	filterEvents() {
		let calendarEvents = portalUser?.getCalendarEvents();

		let showHolidays = this.state.showHolidays;
		let showVacations = this.state.showVacations;
		let showSchedules = this.state.showSchedules;
		let showMeetings = this.state.showMeetings;
		let showOutlook = this.state.showOutlook;
		let selectedEvent = this.state.selectedEvent;

		let events = [];
		if (showHolidays) {
			events.push(...(calendarEvents?.filter((data) => data?.eventType === "Holiday") ?? []));
		}
		if (showVacations) {
			events.push(...(calendarEvents?.filter((data) => data?.eventType === "Vacation") ?? []));
		}
		if (showSchedules) {
			events.push(...(calendarEvents?.filter((data) => data?.eventType === "MFRSchedule") ?? []));
		}
		if (showMeetings) {
			events.push(...(calendarEvents?.filter((data) => data?.eventType === "MFR") ?? []));
			events.push(...(calendarEvents?.filter((data) => data?.eventType === "QFR") ?? []));
		}
		if (showOutlook) {
			events.push(...(calendarEvents?.filter((data) => data?.eventType === "Outlook") ?? []));
		}

		this.setState({
			events: events,
			selectedEvent: selectedEvent,
		});
	}

	onRangeChange(dates) {
		let year = moment(
			new Date(dates?.start?.toLocaleDateString()).setDate(
				new Date(dates?.start?.toLocaleDateString()).getDate() + 7
			)
		).format("yyyy");
		this.setState({ year: year }, () => {
			this.init();
		});
	}

	handleTabChange(tabVal) {
		this.setState({ tabVal: tabVal });
	}

	applyFilters() {
		this.handleTabChange(0);
	}

	render() {
		const min = new Date();
		min.setHours(6);
		min.setMinutes(0, 0, 0);

		const max = new Date();
		max.setHours(17);
		max.setMinutes(0, 0, 0);

		const formats = {
			eventTimeRangeFormat: () => {
				return "";
			},
		};

		return (
			<Stack w="full" flex={1} minW="container.sm" h="full">
				<Flex w="full" flex={1} justify="space-between" align="flex-end">
					<Heading
						w="full"
						textAlign="left"
						color="whiteAlpha.900"
						textShadow="2px 2px 2px var(--chakra-colors-gray-600)"
						textTransform="uppercase"
						size="lg"
						letterSpacing={6}
					>
						{this.props.heading}
					</Heading>

					{this.state.selectedEvent && (
						<IconButton size="xs" icon={<i className="fas fa-times" />} onClick={this.closeEvent} />
					)}
				</Flex>
				<Stack bg="whiteAlpha.600" rounded={5} p={4} borderColor="gray.200" borderWidth={2} h="full">
					{this.state.events?.length > 0 && !Boolean(this.state.selectedEvent) && (
						<Box rounded="10px" shadow="md" maxW="full">
							<ReactCalendar
								localizer={localizer}
								min={min}
								max={max}
								defaultDate={new Date()}
								defaultView="month"
								views={["month"]}
								events={this.state.events}
								onRangeChange={this.onRangeChange}
								style={{
									height: "65vh",
									minHeight: "550px",
									maxWidth: "100%",
									width: "100%",
									maxHight: "100%",
									backgroundColor: "var(--chakra-colors-whiteAlpha-900)",
									borderRadius: "5px",
									borderWidth: 1,
									borderColor: "var(--chakra-colors-gray-400)",
									padding: "10px",
								}}
								onSelectEvent={(event, ev) => {
									this.openEvent(event, ev);
								}}
								popup={true}
								formats={formats}
								eventPropGetter={(event) => {
									var bg = "transparent";
									var borderColor = "var(--chakra-colors-gray.500)";
									var fontColor = "white";
									if (["MFR", "QFR", "MFRSchedule"].includes(event?.eventType)) {
										bg = event?.region?.bg ?? bg;
										borderColor = event?.region?.borderColor ?? borderColor;
										fontColor = event?.region?.fontColor ?? fontColor;
									} else {
										bg = event?.bg ?? bg;
										borderColor = event?.borderColor ?? borderColor;
										fontColor = event?.fontColor ?? fontColor;
									}

									return {
										style: {
											background: bg,
											fontWeight: "600",
											borderColor: borderColor,
											color: fontColor,
										},
									};
								}}
							/>
						</Box>
					)}
					{this.state.events?.length > 0 &&
						(this.state.selectedEvent?.eventType === "MFRSchedule" ||
							this.state.selectedEvent?.eventType === "MFR" ||
							this.state.selectedEvent?.eventType === "QFR") && (
							<Box rounded="10" maxW="full">
								<ReactCalendar
									min={min}
									max={max}
									localizer={localizer}
									step={30}
									onSelectEvent={(event, ev) => {
										this.openEvent(event, ev);
									}}
									popup={true}
									startAccessor="start"
									endAccessor="end"
									defaultDate={new Date(this.state.selectedEvent?.start)}
									defaultView="work_week"
									events={this.state.events}
									views={["work_week", "agenda"]}
									formats={formats}
									drilldownView="agenda"
									style={{
										height: "65vh",
										minHeight: "550px",
										maxWidth: "100%",
										width: "100%",
										maxHight: "100%",
										backgroundColor: "var(--chakra-colors-whiteAlpha-900)",
										borderRadius: "5px",
										borderWidth: 1,
										borderColor: "var(--chakra-colors-gray-400)",
										padding: "10px",
										boxShadow: "var(--chakra-shadows-lg)",
									}}
									eventPropGetter={(event) => {
										var bg = "transparent";
										var borderColor = "var(--chakra-colors-gray.500)";
										var fontColor = "white";

										if (["MFR", "QFR", "MFRSchedule"].includes(event?.eventType)) {
											bg = event?.region?.bg ?? bg;
											borderColor = event?.region?.borderColor ?? borderColor;
											fontColor = event?.region?.fontColor ?? fontColor;
										} else {
											bg = event?.bg ?? bg;
											borderColor = event?.borderColor ?? borderColor;
											fontColor = event?.fontColor ?? fontColor;
										}

										return {
											style: {
												background: bg,
												fontWeight: "600",
												borderColor: borderColor,
												color: fontColor,
											},
										};
									}}
								/>
							</Box>
						)}
				</Stack>
			</Stack>
		);
	}
}
