import moment from "moment";
import { portalUser } from "../../../App";
import SectionTemplateMFRProjectInfo from "./SectionTemplateMFRProjectInfo";
import SectionTemplateMFRSafety from "./SectionTemplateMFRSafety";
import SectionTemplateMFRAccounting from "./SectionTemplateMFRAccounting";
import SectionTemplateMFRAccountingSummary from "./SectionTemplateMFRAccountingSummary";
import SectionTemplateMFRSchedule from "./SectionTemplateMFRSchedule";

// import { log } from "../../../../helperFunctions";

export default class PageTemplateCRSHome {
	constructor(selectedData = [], thruMth = moment(new Date()).startOf("month")) {
		let contract = {};
		let region = {};
		let contractTotalsByMth = [];
		let contractTotals = null;
		let itemTypeData = [];
		let prevContractTotals = null;
		let billingsByMth = [];
		let billings = [];
		let retention = [];
		let earlyLateAvg = 0;

		this.pageTemplate = "MonthlyForecastCRS";
		this.pageName = "Monthly Forecast Report";

		if (Boolean(selectedData.length > 0)) {
			contract = selectedData?.filter((p) => p.queryData === "Contract")[0] ?? {};
			region = portalUser?.getRegions()?.filter((p) => p.regionID === contract?.regionID)[0] ?? {};
			billingsByMth = selectedData?.filter((p) => p.queryData === "BillingsByMth") ?? [];
			billingsByMth =
				billingsByMth?.filter((p) => new Date(p.mth).getTime() <= new Date(thruMth).getTime()) ?? [];
			contractTotalsByMth = selectedData?.filter((p) => p.queryData === "ContractTotalsByMth") ?? [];
			contractTotals =
				contractTotalsByMth?.filter(
					(p) => moment(new Date(p.mth)).format("MM/YY") === moment(new Date(thruMth)).format("MM/YY")
				)[0] ?? null;
			prevContractTotals =
				contractTotalsByMth?.filter(
					(p) =>
						moment(new Date(p.mth)).format("MM/YY") ===
						moment(new Date(thruMth)).subtract(1, "months").startOf("month").format("MM/YY")
				)[0] ?? null;

			itemTypeData = selectedData?.filter((p) => ["ItemTypeTotalsByMth"].includes(p.queryData)) ?? [];
			itemTypeData =
				itemTypeData?.filter(
					(p) => moment(new Date(p.mth)).format("MM/YY") === moment(new Date(thruMth)).format("MM/YY")
				) ?? [];

			billings = billingsByMth?.filter((d) => d.arTransGroup !== "R");
			retention = billingsByMth?.filter((d) => d.arTransGroup === "R");
			let b = 0;
			for (let i = 0; i < billings?.length; i++) {
				if (Boolean(billings[i]?.datePaidOn) && b < 3) {
					earlyLateAvg =
						earlyLateAvg + (isNaN(parseFloat(billings[i]?.earlyLate ?? 0)) ? 0 : billings[i]?.earlyLate);
					b = b + 1;
				}
				billings[i].billNo = billings?.length - i;
			}
			earlyLateAvg = parseFloat(earlyLateAvg ?? 0) / 3;
		}

		this.pageTitle = this.getPageTitle(contract, thruMth);
		this.region = region;

		this.size = "LETTER";
		this.orientation = "portrait";

		if (["2", "4", "5"].includes(contract?.contractTypeID)) {
			let lumpSumGCs = true;
			if (contract?.contractTypeID === "5") {
				lumpSumGCs = false;
			}
			for (let i = 0; i < itemTypeData?.length; i++) {
				itemTypeData[i] = itemTypeData[i]?.getItemTypeDetailTotals(lumpSumGCs);
			}

			this.pageName += " CRS";
			this.pageBody = [
				new SectionTemplateMFRProjectInfo(contract),
				new SectionTemplateMFRSafety(contract),
				new SectionTemplateMFRAccounting(billings, retention, contractTotals, earlyLateAvg, true),
				new SectionTemplateMFRAccountingSummary(contractTotals, earlyLateAvg),
				new SectionTemplateMFRSchedule(contract, contractTotals, prevContractTotals, true),
			];
		}
	}

	getPageTitle(contract = null, thruMth = null) {
		let pageTitle = "Report Template";
		if (Boolean(contract?.contract)) {
			pageTitle = contract?.contract + " " + contract?.description;
			pageTitle +=
				Boolean(contract) && Boolean(thruMth) ? " Thru " + moment(new Date(thruMth)).format("MMM YYYY") : "";
		}
		return pageTitle;
	}
}
