import React, { useState, useEffect, useRef } from "react";
import {
	Container,
	Tabs as ChakraTabs,
	TabList,
	Tab,
	TabPanels,
	TabPanel,
	Heading,
	HStack,
	IconButton,
	Flex,
} from "@chakra-ui/react";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { log } from "../../../helperFunctions";

export default function Tabs({
	viewerMaxHeight,
	setViewerMaxHeight,
	tabs,
	tabVal,
	tabDirection,
	changeTab,
	updateTrigger,
	children,
}) {
	const ref = useRef(null);

	const [maxHeight, setMaxHeight] = useState(viewerMaxHeight);

	useEffect(() => {
		let height = ref?.current?.clientHeight ?? maxHeight;
		if (viewerMaxHeight < height) {
			setViewerMaxHeight(height);
		} else {
			setMaxHeight(viewerMaxHeight);
		}
	}, [maxHeight, viewerMaxHeight, setViewerMaxHeight, tabVal, updateTrigger]);

	const xOffset = 100;
	const variants = {
		enter: (direction) => ({
			x: direction > 0 ? xOffset : -xOffset,
			opacity: 0,
		}),
		active: {
			x: 0,
			opacity: 1,
			transition: { delay: 0.2 },
		},
		exit: (direction) => ({
			x: direction > 0 ? -xOffset : xOffset,
			opacity: 0,
		}),
	};

	const hasPaginated = useRef(false);

	function detectPaginationGesture(e, { offset }) {
		if (hasPaginated.current) return;
		let threshold = 300 / 2;
		let newTab = tabVal;
		let tabCount = tabs.length;

		if (tabCount >= 1) {
			if (offset.x < -threshold) {
				// If user is dragging left, go forward a page
				if (tabVal === tabCount - 1) {
					newTab = 0;
				} else {
					newTab = tabVal + 1;
				}
				// Else if the user is dragging right,
				// go backwards a page
			} else if (offset.x > threshold) {
				if (tabVal === 0) {
					newTab = tabCount - 1;
				} else {
					newTab = tabVal - 1;
				}
			}
		}
		changeTab(newTab, offset.x < 0 ? 1 : -1);
	}

	return (
		<>
			<Container px={0} flex={1} key={"reportTab-Container-" + tabVal} maxW="full">
				<Flex w="full" align="center" direction="row" flex={1} h="full" justify="space-between">
					<IconButton
						zIndex={2}
						color="whiteAlpha.600"
						variant="ghost"
						mr={2}
						_hover={{ color: "whiteAlpha.800", bg: "whiteAlpha.300", fontWeight: "bold" }}
						onClick={() => changeTab(tabVal === 0 ? tabs.length - 1 : tabVal - 1)}
						icon={<i className="fas fa-chevron-left fa-2x fa-fw"></i>}
					/>

					<ChakraTabs
						isLazy
						colorScheme="teal"
						index={tabVal}
						onChange={changeTab}
						display="flex"
						flexDirection="column"
						flex={1}
						w="full"
						h="full"
					>
						<TabList color="gray.600" borderBottomColor="whiteAlpha.500">
							{tabs?.map((tab, i) => (
								<Tab
									key={i}
									_selected={{
										color: "teal.600",
										bg: "whiteAlpha.500",
										borderTopRadius: "5",
										borderBottom: "3px solid",
									}}
									_hover={{
										color: "teal.500",
										bg: "whiteAlpha.500",
										borderTopRadius: "5",
										borderBottom: "3px solid",
										borderBottomColor: "teal.600",
									}}
								>
									<Heading size="sm" letterSpacing={1} textTransform="uppercase">
										{tab.name}
									</Heading>
								</Tab>
							))}
						</TabList>

						<TabPanels
							display="flex"
							flexDirection="column"
							h="full"
							w="full"
							flex={1}
							minH="6xl"
							ref={ref}
							mt={2}
							p={0}
						>
							{tabs?.map((i) => (
								<TabPanel
									key={i}
									display="flex"
									flexDirection="column"
									h="full"
									w="full"
									flex={1}
									p={0}
									position="relative"
									// bg="blackAlpha.300"
									borderTopLeftRadius={5}
									borderBottomLeftRadius={5}
									className="containerShadow"
								>
									<AnimatePresence custom={tabDirection}>
										<motion.div
											key={tabVal}
											className="carouselTab"
											// whileHover={{ scale: 1.005, transition: { duration: 0.5 } }}
											data-page={tabVal}
											variants={variants}
											initial="enter"
											animate="active"
											exit="exit"
											drag="x"
											onDrag={detectPaginationGesture}
											onDragStart={() => (hasPaginated.current = false)}
											onDragEnd={() => (hasPaginated.current = true)}
											style={{ height: "100%", flexGrow: 1, overflow: "auto" }}
											transition={{
												// duration: 1.5,
												type: "spring",
												bounce: 0.2,
												// stiffness: 50,
											}}
											// Snap the component back to the center
											// if it hasn't paginated
											dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
											// This will be used for components to resolve all
											// other variants, in this case initial and animate.
											custom={tabDirection}
										>
											{children}
										</motion.div>
									</AnimatePresence>
								</TabPanel>
							))}
						</TabPanels>
					</ChakraTabs>
					<IconButton
						zIndex={2}
						ml={2}
						color="whiteAlpha.600"
						variant="ghost"
						_hover={{ color: "whiteAlpha.800", bg: "whiteAlpha.300", fontWeight: "bold" }}
						onClick={() => changeTab(tabVal === tabs.length - 1 ? 0 : tabVal + 1)}
						icon={<i className="fas fa-chevron-right fa-2x"></i>}
					/>
				</Flex>
			</Container>
			<Pagination tabs={tabs} tabVal={tabVal} changeTab={changeTab} />
		</>
	);
}

const Pagination = ({ tabs, tabVal, changeTab }) => {
	// Wrap all the pagination Indicators
	// with AnimateSharedPresence
	// so we can detect when Indicators
	// with a layoutId are removed/added

	return (
		<AnimateSharedLayout>
			<HStack direction="row" w="full" justify="center" align="center" py={2} spacing={4}>
				{tabs?.map((tab, i) => (
					<Indicator key={i} onClick={() => changeTab(i)} isSelected={i === tabVal} />
				))}
			</HStack>
		</AnimateSharedLayout>
	);
};

const Indicator = ({ isSelected, onClick }) => {
	return (
		<IconButton variant="ghost" _hover={{ bg: "whiteAlpha.200" }} onClick={onClick} style={{ zIndex: "2" }}>
			<div className="Indicator">
				{isSelected && (
					// By setting layoutId, when this component
					// is removed and a new one is added elsewhere,
					// the new component will animate out from the old one.
					<motion.div className="Indicator-highlight" layoutId="highlight" />
				)}
			</div>
		</IconButton>
	);
};
