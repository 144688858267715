const FORM_INPUT_TYPES = [
	{
		inputType: "single",
		name: "Single Input",
		icon: "fas fa-question fa-fw fa-lg",
		color: "teal",
		rowsMin: 1,
	},
	{
		inputType: "text",
		name: "Text Area",
		icon: "fas fa-font fa-fw fa-lg",
		color: "teal",
		rowsMin: 3,
	},
	{
		inputType: "listText",
		name: "Text List",
		icon: "fas fa-list-ol fa-fw fa-lg",
		color: "green",
		rowsMin: 1,
	},
	{
		inputType: "radio",
		name: "Radio",
		icon: "fas fa-dot-circle fa-fw fa-lg",
		color: "blue",
		rowsMin: 1,
	},
	{
		inputType: "rating",
		name: "Rating",
		icon: "fas fa-star fa-fw fa-lg",
		color: "yellow",
		rowsMin: 1,
	},
];
export default FORM_INPUT_TYPES;
