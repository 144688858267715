import { formatDate } from "../../../helperFunctions";

export default class ACO {
	constructor(aco) {
		this.queryData = aco?.queryData ?? aco?.QueryData ?? "ACO";
		this.jccmKeyID = aco?.jccmKeyID ?? aco?.JCCMKeyID ?? null;
		this.jcco = aco?.jcco ?? aco?.JCCo ?? null;
		this.contract = aco?.contract ?? aco?.Contract?.trim() ?? null;

		this.pmohKeyID = aco?.pmohKeyID ?? aco?.PMOHKeyID ?? null;
		this.aco = aco?.aco ?? aco?.ACO?.trim() ?? null;
		this.seq = aco?.seq ?? aco?.Seq ?? null;

		this.description = aco?.description ?? aco?.Description ?? null;
		this.nonContractYN = aco?.nonContractYN ?? aco?.NonContractYN ?? "N";
		this.dateApproved = formatDate(aco?.dateApproved ?? aco?.DateApproved) ?? null;

		this.notes = aco?.notes ?? aco?.Notes ?? null;
		this.attachUID = aco?.attachUID ?? aco?.UniqueAttchID ?? null;

		this.acoEstHours = aco?.acoEstHours ?? aco?.ACOEstHours ?? 0;
		this.acoEstCost = aco?.acoEstCost ?? aco?.ACOEstCost ?? 0;
		this.acoBilledAmt = aco?.acoBilledAmt ?? aco?.ACOBilledAmt ?? 0;

		this.acoAmt = 0;
		this.pcoAmt = 0;
		this.pcoConv = 0;

		this.pctMargin = 0;
		this.pctBilled = 0;

		this.pcos = [];
		this.coItems = [];
		this.acoStatus = aco?.acoStatus ?? this.getACOStatus();
	}

	getACOStatus() {
		let acoStatus = "APPROVED";
		if (this.nonContractYN === "Y") {
			acoStatus = "NON-CONTRACT";
		}
		return acoStatus;
	}

	getACOItemTotals() {
		let pcos = this.pcos;
		if (pcos.length > 0) {
			let pcoAmt = pcos?.map((coItem) => coItem?.pcoAmt).reduce((prev, curr) => prev + curr, 0) ?? 0;
			this.pcoAmt = pcoAmt;
		}
		let coItems = this.coItems;
		if (coItems.length > 0) {
			// let pcoAmt = coItems?.map((coItem) => coItem?.pcoAmt).reduce((prev, curr) => prev + curr, 0) ?? 0;
			let pcoConv = coItems?.map((coItem) => coItem?.pcoConv).reduce((prev, curr) => prev + curr, 0) ?? 0;
			let acoAmt = coItems?.map((coItem) => coItem?.acoAmt).reduce((prev, curr) => prev + curr, 0) ?? 0;
			// this.pcoAmt = pcoAmt;
			this.pcoConv = pcoConv;
			this.acoAmt = acoAmt;

			this.pctMargin = this.getPct(acoAmt - this.acoEstCost, acoAmt);
			this.pctBilled = this.getPct(this.acoBilledAmt, acoAmt);
		}
	}

	getPct(numerator = 0, denominator = 0) {
		let pct = 0;
		if (numerator === null || denominator === null) {
			pct = null;
		} else if (denominator !== 0) {
			pct = (numerator / denominator) * 100;
		}
		return pct;
	}
}
