import React, { useState, useMemo, useEffect, useCallback } from "react";
import {
	Flex,
	Heading,
	Text,
	Stack,
	Drawer,
	DrawerContent,
	DrawerCloseButton,
	DrawerHeader,
	DrawerFooter,
	Button,
	DrawerBody,
	DrawerOverlay,
	HStack,
	Center,
} from "@chakra-ui/react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import DataInput from "../../../core/Inputs/data/DataInput";
import { AnnouncementsCardRow } from "./AnnouncementsCard";

import Region from "../../../PortalUser/Region";
import Department from "../../../PortalUser/Department";

import { portalUser } from "../../../App";
// import { log } from "../../../helperFunctions";

export default function AnnouncementsDrawer({
	isOpen,
	onClose,
	announcement,
	editable = false,
	saveAnnouncement,
	// deleteAnnoucement,
}) {
	const [updateTrigger, setUpdateTrigger] = useState(false);
	const [previewTileRow, setPreviewTileRow] = useState(null);
	const [regions, setRegions] = useState(null);
	const [departments, setDepartments] = useState(null);
	const showPreview = false;
	// const [showPreview, setShowPreview] = useState(false);

	useEffect(() => {
		const init = () => {
			let nullRegion = new Region();
			nullRegion.regionID = "All";
			nullRegion.description = "All Regions";
			let regions = [nullRegion, ...(portalUser?.getRegions() ?? [])];

			let nullDepartment = new Department();
			nullDepartment.deptID = "All";
			nullDepartment.dept = "All Departments";
			let departments = [nullDepartment, ...(portalUser?.getDepartments() ?? [])];

			setRegions(regions);
			setDepartments(departments);
		};
		init();
	}, [announcement]);

	useEffect(() => {
		if (updateTrigger) {
			setUpdateTrigger(false);
		}
	}, [updateTrigger]);

	const updateAnnouncement = useCallback(
		(attr, value) => {
			announcement.update(attr, value);
			setUpdateTrigger(true);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[announcement]
	);

	useMemo(() => {
		let annoucementRow = (
			<DragDropContext enableDefaultSensors={editable}>
				<Droppable droppableId="droppable">
					{(provided, snapshot) => (
						<Stack {...provided.droppableProps} ref={provided?.innerRef} spacing={2} w="full">
							<AnnouncementsCardRow
								key={0}
								index={0}
								announcement={announcement}
								regions={regions}
								editable={false}
							/>
						</Stack>
					)}
				</Droppable>
			</DragDropContext>
		);

		setPreviewTileRow(annoucementRow);
	}, [announcement, regions, editable]);

	return (
		<Drawer isOpen={isOpen} placement="right" size="md" onClose={onClose}>
			<DrawerOverlay>
				<Center w="full" h="full">
					<Flex bg="gray.300" minW="container.sm" maxW="container.sm" w="full" rounded={10}>
						{Boolean(previewTileRow) && Boolean(showPreview) && previewTileRow}
					</Flex>
				</Center>
			</DrawerOverlay>
			<DrawerContent>
				<DrawerHeader borderBottomWidth="1px" justify="center">
					<HStack w="full" align="center">
						<Heading as="h6" size="md" color="gray.500" letterSpacing={2} textTransform="uppercase">
							Announcements
						</Heading>
						<DrawerCloseButton />
					</HStack>
				</DrawerHeader>
				<DrawerBody w="full" className="skinnyScroll" overflowX={"hidden"} overflowY="auto">
					<Stack spacing="8" key={"newKey12"} py={4}>
						<Stack>
							<Flex w="full" textAlign="center" align="center" px="1">
								<Text
									h="full"
									fontSize="md"
									textTransform="uppercase"
									color="gray.500"
									fontWeight="600"
									display="inline-block"
									letterSpacing={2}
								>
									{(Boolean(announcement?.keyID) ? "Edit " : "New") + " Announcement"}
								</Text>
							</Flex>

							<Stack
								p="4"
								spacing={2}
								bg="blackAlpha.50"
								border="1px"
								borderColor="blackAlpha.100"
								rounded="10"
							>
								<Stack w="full" direction={"row"}>
									<DataInput
										variant="outline"
										name="heading"
										type="select"
										label="Category"
										searchable
										options={announcement?.getAnnouncementTypes()}
										optionValue={"value"}
										optionText={"label"}
										my={0}
										onOptionSelect={(ev) => updateAnnouncement("type", ev)}
										value={announcement?.type ?? "announcement"}
										placeholder="Select Category"
										isModal
										flex={3}
									/>
									<DataInput
										variant="outline"
										name="heading"
										type="select"
										label="Visible"
										searchable
										options={announcement?.getIsVisibleYN()}
										optionValue={"value"}
										optionText={"label"}
										my={0}
										onOptionSelect={(ev) => updateAnnouncement("isVisibleYN", ev)}
										value={announcement?.isVisibleYN ?? "Y"}
										isModal
										flex={1}
									/>
								</Stack>

								<DataInput
									variant="outline"
									name="description"
									type="textarea"
									label="Description"
									placeholder="e.g. The PPM Portal will be down for maintenance..."
									defaultValue={announcement?.description ?? null}
									onBlur={({ target }) => updateAnnouncement("description", target.value)}
									rowsMin={10}
									isModal
								/>

								<DataInput
									type="textarea"
									name="redirectURL"
									label="Website URL"
									rowsMin={1}
									defaultValue={announcement?.redirectURL}
									onBlur={({ target }) => updateAnnouncement("redirectURL", target.value)}
									placeholder="e.g. https://ppmportal.com/"
									isModal
								/>

								<DataInput
									name="urlDisplayText"
									type="text"
									label="Display Text"
									placeholder="e.g. PPMPortal.com"
									inputProps={{ textTransform: "none" }}
									defaultValue={announcement?.urlDisplayText}
									onBlur={({ target }) => updateAnnouncement("urlDisplayText", target.value)}
									isModal
								/>
							</Stack>
						</Stack>

						<Stack>
							<Flex align="center" w="full" textAlign="center" px="1">
								<Text
									h="full"
									fontSize="md"
									textTransform="uppercase"
									color="gray.500"
									fontWeight="600"
									mr="2"
								>
									Filter By
								</Text>
							</Flex>

							<Stack
								p="4"
								spacing={4}
								bg="blackAlpha.50"
								border="1px"
								borderColor="blackAlpha.100"
								rounded="10"
							>
								<DataInput
									variant="outline"
									name="region"
									type="select"
									label="Region"
									options={regions}
									searchable
									optionText={"description"}
									optionValue={"regionID"}
									value={announcement?.regionID ?? "All"}
									onOptionSelect={(ev) => updateAnnouncement("regionID", ev)}
									placeHolder="All Regions"
									isModal
								/>
								<DataInput
									variant="outline"
									name="department"
									type="select"
									label="Department"
									options={departments}
									searchable
									optionText={"dept"}
									optionValue={"deptID"}
									onOptionSelect={(ev) => updateAnnouncement("deptID", ev)}
									value={announcement?.deptID ?? "All"}
									placeHolder="All Departments"
									isModal
								/>
								<DataInput
									variant="outline"
									name="employeeType"
									type="select"
									label="Employee Type"
									options={announcement?.getEmployeeTypes()}
									searchable
									optionText={"label"}
									optionValue={"value"}
									onOptionSelect={(ev) => updateAnnouncement("employeeType", ev)}
									value={announcement?.employeeType ?? "All"}
									placeHolder="All Employees"
									isModal
								/>
							</Stack>
						</Stack>
					</Stack>
				</DrawerBody>
				<DrawerFooter>
					<HStack spacing={4}>
						<Button
							variant="outline"
							colorScheme="red"
							textTransform="uppercase"
							letterSpacing={1}
							onClick={onClose}
						>
							Cancel
						</Button>
						<Button
							colorScheme="blue"
							textTransform="uppercase"
							letterSpacing={1}
							onClick={() => saveAnnouncement(announcement)}
						>
							Save
						</Button>
					</HStack>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}
