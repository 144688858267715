import PayrollAPI from "../../../APIs/PayrollAPI";
import { portalUser } from "../../../App";
import { convertArrayToMap, formatValue, log } from "../../../helperFunctions";
import PayPeriod from "./PayPeriod";
import TimecardJob from "./TimecardJob";
import TimecardPhase from "./TimecardPhase";
import TimecardHeader from "./TimecardHeader";
import TimecardRow from "./TimecardRow";

export default class Payroll {
	constructor(data) {
		if (data instanceof Payroll) {
			Object.assign(this, data);
		} else {
			this.payrollAPI = data?.payrollAPI ?? null;
			this.prEndDate = formatValue(data?.prEndDate ?? portalUser.user?.prEndDate, 0, "date");
			this.employees = data?.employees ?? [];
			this.payPeriods = data?.payPeriods ?? [];
			this.timecardHeaders = data?.timecardHeaders ?? [];
			this.timecardRows = data?.timecardRows ?? [];
			this.timecardJobs = data?.timecardJobs ?? [];
			this.timecardPhases = data?.timecardPhases ?? [];
			this.reviewJobs = data?.reviewJobs ?? [];
			this.reviewPhases = data?.reviewPhases ?? [];
			this.groups = data?.groups ?? [];
			this.groupMembers = data?.groupMembers ?? [];
		}
	}

	classifyData(payroll = this, sqlData = []) {
		for (let i = 0; i < sqlData?.length; i++) {
			let queryData = sqlData[i]?.queryData;
			if (queryData === "Employee") {
				payroll.employees.push(sqlData[i]);
			} else if (queryData === "PayPeriod") {
				payroll.payPeriods.push(new PayPeriod(sqlData[i]));
			} else if (queryData === "TimecardHeader") {
				payroll.timecardHeaders.push(new TimecardHeader(sqlData[i]));
			} else if (queryData === "TimecardRow") {
				payroll.timecardRows.push(new TimecardRow(sqlData[i]));
			} else if (queryData === "TimecardJob") {
				payroll.timecardJobs.push(new TimecardJob(sqlData[i]));
			} else if (queryData === "TimecardPhase") {
				payroll.timecardPhases.push(new TimecardPhase(sqlData[i]));
			}
		}

		return payroll;
	}

	// classifyData2(payroll = this, sqlData = []) {
	// 	let classes = [
	// 		//{ attr: "employees", obj: "Employee", clss: PREmployee },
	// 		{ attr: "payPeriods", obj: "PayPeriod", clss: PayPeriod },
	// 		{ attr: "timecardHeaders", obj: "TimecardHeader", clss: TimecardHeader },
	// 		{ attr: "timecardRows", obj: "TimecardRow", clss: TimecardRow },
	// 		{ attr: "timecardJobs", obj: "TimecardJob", clss: TimecardJob },
	// 		{ attr: "timecardPhases", obj: "TimecardPhase", clss: TimecardPhase },
	// 	];
	// 	let queryMap = convertArrayToMap(sqlData, "queryData", true);

	// 	for (let i = 0; i < classes?.length; i++) {
	// 		let attr = classes[i]?.attr;
	// 		let obj = classes[i]?.obj;
	// 		let arr = queryMap[classes[i]?.obj] ?? [];
	// 		let newArr = arr?.map((d) => new [classes[i]?.clss](d));
	// 		log("attr", attr);
	// 		log("obj", obj);
	// 		log("arr", arr);
	// 		log("newArr", newArr);
	// 		// if (queryMap[classes[i]?.clss]?.length > 0) {
	// 		// 	payroll[classes[i]?.arr];
	// 		// }
	// 	}

	// 	return payroll;
	// }

	async initPayroll(params = { msUserID: portalUser.user?.msUserID, prEndDate: this.prEndDate }) {
		let payroll = this;
		if (Boolean(params?.msUserID) && !Boolean(payroll?.payrollAPI)) {
			let payrollAPI = new PayrollAPI();
			let sqlData = await payrollAPI.GetPayrollDataNew({}, params);
			if (sqlData?.status === 200 && sqlData?.value?.length > 0) {
				payroll = this.classifyData(payroll, sqlData.value);
			}
			payroll.payrollAPI = payrollAPI;
			portalUser.payroll = payroll;
		}
		return payroll;
	}

	async getPayPeriods(params = { msUserID: portalUser.user?.msUserID }) {
		let payPeriods = this.payPeriods;
		if (payPeriods?.length === 0) {
			let sqlData = (await this?.payrollAPI?.GetPayPeriodsNew({}, params)) ?? null;
			if (sqlData?.status === 200 && sqlData?.value?.length > 0) {
				payPeriods = this.classifyData(new Payroll(), sqlData.value)?.payPeriods;
			} else {
				payPeriods = this.payPeriods;
			}
			this.payPeriods = payPeriods;
		}
		return payPeriods;
	}

	async getTimecardJobs(params = { msUserID: portalUser.user?.msUserID }) {
		let timecardJobs = this.timecardJobs;
		if (timecardJobs?.length === 0) {
			let sqlData = (await this?.payrollAPI?.GetTimecardJobsNew({}, params)) ?? null;
			if (sqlData?.status === 200 && sqlData?.value?.length > 0) {
				timecardJobs = this.classifyData(new Payroll(), sqlData.value)?.timecardJobs;
			} else {
				timecardJobs = this.timecardJobs;
			}
			this.timecardJobs = timecardJobs;
		}
		return timecardJobs;
	}

	async getTimecardPhases(params = { msUserID: portalUser.user?.msUserID, jcjmKeyID: null }) {
		let timecardPhases = this.timecardPhases;
		let newPhases = timecardPhases?.filter((d) => d.jcjmKeyID === params?.jcjmKeyID) ?? [];

		if (Boolean(params?.jcjmKeyID) && newPhases?.length === 0) {
			let sqlData =
				(await this.payrollAPI.GetTimecardPhasesNew(
					{ jcjmKeyID: params?.jcjmKeyID },
					{ msUserID: params?.msUserID }
				)) ?? null;
			if (sqlData?.status === 200 && sqlData?.value?.length > 0) {
				timecardPhases = timecardPhases?.filter((d) => d.jcjmKeyID !== params?.jcjmKeyID);
				newPhases = this.classifyData(new Payroll(), sqlData.value)?.timecardPhases;
			} else {
				newPhases = timecardPhases?.filter((d) => d.jcjmKeyID === params?.jcjmKeyID) ?? [];
			}
			this.timecardPhases = [...timecardPhases, ...newPhases];
		}

		return newPhases;
	}

	async getReviewJobs(employeeUID = portalUser.user?.employeeUID, prEndDate = this.prEndDate) {
		let reviewJobs = this.reviewJobs;
		if (reviewJobs?.length === 0) {
			let result = await this.payrollAPI.GetTimecardReviewJobsNew(employeeUID, prEndDate);
			if (result?.status === 200 && result?.value?.length > 0) {
				reviewJobs = result.value;
			} else {
				reviewJobs = this.reviewJobs;
			}
			this.reviewJobs = reviewJobs;
		}
		return reviewJobs;
	}
}
