import DFWorkflow from "./DFWorkflow";
import SchedulesAPI from "../../../APIs/SchedulesAPI";
import EgnyteAPI from "../../../APIs/EgnyteAPI";

import { log, formatDate, chakraThemeColorsMap } from "../../../helperFunctions";
import { v4 } from "uuid";
import moment from "moment";

const currentDate = moment().startOf("day");

export default class DFDeliverable extends DFWorkflow {
	constructor(deliverable, workflow) {
		super(workflow);
		if (deliverable instanceof DFDeliverable && !Boolean(workflow)) {
			Object.assign(this, deliverable);
		} else {
			this.queryData = deliverable?.queryData ?? "DFDeliverable";
			this.schedulesAPI = new SchedulesAPI();
			this.uid = deliverable?.uid ?? v4();
			this.id = deliverable?.id?.toString() ?? null;
			this.deliverableID = deliverable?.deliverableID?.toString() ?? null;
			this.jobID = deliverable?.jobID?.toString() ?? null;
			this.sequenceID = deliverable?.sequenceID?.toString() ?? null;
			this.workflowID = deliverable?.workflowID?.toString() ?? null;
			this.deliverableNum = deliverable?.deliverableNum?.toString() ?? "0";
			this.duration = deliverable?.duration ?? 0;
			this.editDate = formatDate(deliverable?.editDate) ?? null;
			this.calcDate = formatDate(deliverable?.editDate ?? deliverable?.calcDate) ?? null;
			this.dateCompleted = formatDate(deliverable?.dateCompleted) ?? null;
			this.notifyRole = deliverable?.notifyRole ?? null;
			this.showDeliverable = deliverable?.showDeliverable?.toString() ?? "1";

			this.egnyteLink = deliverable?.egnyteLink ?? null;
			this.deliverableType = deliverable?.deliverableType ?? "checkDate";

			this.accessor = "deliverable_" + (deliverable?.deliverableNum?.toString() ?? "0");
		}
	}

	initDFDeliverable(deliverable = this, workflow = new DFWorkflow()) {
		deliverable = new DFDeliverable(deliverable, workflow);
		// this.getEgnyteFolder();

		let date = moment(new Date(deliverable?.calcDate));
		if (date.isValid()) {
			let dateDiff = date.diff(currentDate, "days");
			if (dateDiff < 0) {
				deliverable.bg = chakraThemeColorsMap["red100"];
			} else if (dateDiff <= 2 && dateDiff >= 0) {
				deliverable.bg = chakraThemeColorsMap["yellow100"];
			} else if (dateDiff > 2) {
				deliverable.bg = chakraThemeColorsMap["gray.100"];
			}
		}
		Object.assign(this, deliverable);
	}

	async insertDB() {
		if (!Boolean(this.id)) {
			let data = (({ duration }) => ({
				duration,
			}))(this);
			let result = await this.schedulesAPI.PortalUser_DFData_InsertDeliverable(data);
			// if (result?.status === 200 && result?.value?.length > 0) {
			// 	let newJob = new DFDeliverable(result?.value[0]);
			// 	Object.assign(this, newJob);
			// 	this.getEgnyteFolder();
			// 	result.value = this;
			// }
			// log("Database Result (insert deliverable)", result);
			return result;
		}
	}

	async updateDB() {
		//TODO: Notify notifyRole when dateCompleted is not null
		if (Boolean(this.id)) {
			let data = (({ jobID, dateCompleted, editDate, showDeliverable }) => ({
				jobID,
				dateCompleted,
				editDate,
				showDeliverable: showDeliverable ?? "1",
			}))(this);
			let result = await this.schedulesAPI.PortalUser_DFData_UpdateDeliverable(this.id, data);
			if (result?.status === 200 && result?.value?.length > 0) {
				result = new DFDeliverable(result?.value[0]);
				result.initDFDeliverable();
				Object.assign(this, result);
			}
			log("Database Result (update deliverable)", this);
			return this;
		}
	}

	async deleteDB() {
		if (Boolean(this.id)) {
			let result = await this.schedulesAPI.PortalUser_DFData_DeleteDeliverable(this.id);
			log("Database Result (DELETE deliverable)", result);
			return result;
		}
	}

	formatEgnyteName(value) {
		value = value?.replace(/\s*(.)/gm, "$1");

		if (Boolean(value) && value[value?.length - 1] === ".") {
			value = value?.substr(0, value?.length - 1);
		}
		return value;
	}

	replaceKeywords(string) {
		// Use regular expressions to replace all instances of 'QA/QC' or 'DRAWINGS/LIST' with space instead of forward slash
		const replacedString = string
			.trim()
			.replace(/QA\/QC/g, "QA QC")
			.replace(/DRAWINGS\/LIST/g, "DRAWINGS LIST");
		return replacedString;
	}

	async getEgnyteFolder() {
		const egnyteAPI = new EgnyteAPI();
		let formattedLink = this.replaceKeywords(this.egnyteLink);
		let result = await egnyteAPI.getFolder(formattedLink);
		// log("Egnyte Folder", [result, formattedLink]);
		if (Boolean(result.errorMessage)) {
			result = await egnyteAPI.createFolder(encodeURI(formattedLink));
		}
		return result;
	}

	initEgnyte() {
		//TODO: Add all the folders for the deliverable
		//Potential TODO: If workflow name is changed, rename Egnyte folder
	}
}
