import { getRate, getSubtotal } from "../../../helperFunctions";

export default class SectionTemplateACOLog {
	constructor(acos = [], pagebreak = 0, isLoaded = false) {
		this.template = "ACOs";
		this.name = "Approved Change Order Log";
		this.heading = "Approved Change Orders";
		this.divider = false;
		this.pagebreak = pagebreak;
		this.wrap = true;
		this.type = "virtualTable";
		this.minRows = 1;
		this.maxRows = 24;
		this.isLoaded = isLoaded;
		this.subSections = [new SubSectionTemplateACOLog(acos)];
	}
}

class SubSectionTemplateACOLog {
	constructor(acos = []) {
		this.template = "ACOs";
		let sortGroupFilter = this.getSortGroupFilter();
		this.type = "virtualTable";
		this.show = acos?.length > 0 ? true : false;
		this.groupBy = sortGroupFilter.groupBy;
		this.filterBy = sortGroupFilter.filterBy;
		this.sortBy = sortGroupFilter.sortBy;
		this.columns = [
			{
				accessor: "co",
				Header: "Co",
				show: false,
			},
			{
				accessor: "contract",
				Header: "Contract",
				show: false,
			},
			{
				accessor: "aco",
				Header: "ACO #",
			},
			{
				accessor: "description",
				Header: "Description",
				width: "300%",
			},
			{
				accessor: "acoStatus",
				Header: "ACO Status",
				textAlign: "center",
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},
			{
				accessor: "pcoAmt",
				Header: "Amt Submitted",
				textAlign: "right",
				valueType: "currency",
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},
			{
				accessor: "acoAmt",
				Header: "Amt Approved",
				textAlign: "right",
				valueType: "currency",
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},

			{
				accessor: "dateApproved",
				Header: "Date Approved",
				textAlign: "right",
				valueType: "date",
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},
			{
				accessor: "acoEstCost",
				Header: "Budgeted Cost",
				textAlign: "right",
				valueType: "currency",
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},
			{
				accessor: "pctMargin",
				Header: "% Margin",
				textAlign: "right",
				valueType: "percent",
				decimals: 1,
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},
			{
				accessor: "acoBilledAmt",
				Header: "Billed To Date",
				textAlign: "right",
				valueType: "currency",
				show: false,
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},
			{
				accessor: "pctBilled",
				Header: "Pct Billed",
				textAlign: "right",
				valueType: "percent",
				show: false,
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},
			{
				accessor: "notes",
				Header: "Notes / Comments",

				show: false,
			},
			{
				accessor: "expander",
				id: "expander",
				subRow: {
					rowNum: 8,
					overflowX: "hidden",
					overflowY: "auto",
					columns: [{ id: "notes" }],
				},
			},
		];
		this.data = acos;
		this.footer = (rows = acos) => {
			return this.getFooter(rows);
		};
	}

	getSortGroupFilter() {
		let groupBy = [];
		let sortBy = [
			{ id: "acoStatus", desc: false },
			{ id: "aco", desc: false },
		];
		let filterBy = [];
		let sortGroupFilter = { sortBy: sortBy, groupBy: groupBy, filterBy: filterBy };
		return sortGroupFilter;
	}

	getFooter(rows = []) {
		let footer = [];
		let statuses = ["APPROVED", "NON-CONTRACT", "TOTAL"];
		for (let i = 0; i < statuses?.length; i++) {
			let filteredACOs = rows;

			if (statuses[i] === "APPROVED") {
				filteredACOs = filteredACOs.filter((d) => d.nonContractYN === "N");
			} else if (statuses[i] === "NON-CONTRACT") {
				filteredACOs = filteredACOs.filter((d) => d.nonContractYN === "Y");
			}

			let pcoAmt = getSubtotal(filteredACOs, "pcoAmt") ?? 0;
			let acoAmt = getSubtotal(filteredACOs, "acoAmt") ?? 0;
			let acoEstCost = getSubtotal(filteredACOs, "acoEstCost") ?? 0;
			let acoConvPct = getRate(acoAmt, pcoAmt) * 100;
			let pctMargin = getRate(acoAmt - acoEstCost, acoAmt) * 100;

			let footerRow = {
				acoBg: "transparent",
				acoStatus: statuses[i],
				acoStatusTextAlign: "right",
				acoStatusFontSize: 8,

				pcoAmt: pcoAmt,
				acoAmt: acoAmt,
				dateApproved: acoConvPct,
				dateApprovedType: "percent",
				dateApprovedDecimals: 1,
				acoEstCost: acoEstCost,
				pctMargin: pctMargin,
			};
			footer.push(footerRow);
		}
		return footer;
	}
}
