import React, { useState, useCallback } from "react";
import MainContent from "../../core/ReactTable/layout/MainContent";
import TeamRoutes from "./routes/TeamRoutes";
import { JOYRIDE_MYTEAM, JOYRIDE_MYTEAM_PERFORMANCEREVIEWS } from "../../joyride";

export default function Team(props) {
	const [currentTab, setCurrentTab] = useState(props?.tabVal);
	const [runJoyride, setRunJoyride] = useState(false);

	const setTab = useCallback((tabVal) => {
		setCurrentTab(tabVal);
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	const joyrideCallback = (joyrideState) => {
		if (
			joyrideState.action === "reset" ||
			joyrideState.action === "close" ||
			joyrideState.action === "skip" ||
			joyrideState.action === "last"
		) {
			setRunJoyride(false);
		}
	};

	const tabsArr = [
		{
			name: "Team Members",
			tab: "members",
			heading: "Team",
			subheading: "Team Members",
			joyrideSteps: JOYRIDE_MYTEAM,
		},
		{
			name: "Team Reviews",
			tab: "reviews",
			heading: "Team",
			subheading: "Performance Reviews",
			joyrideSteps: JOYRIDE_MYTEAM_PERFORMANCEREVIEWS,
		},
	];
	let tabs = tabsArr.map((tab) => tab.name);
	let joyrideSteps = tabsArr[currentTab]?.joyrideSteps ?? null;

	return (
		<MainContent
			tabVal={currentTab}
			changeTab={setTab}
			// title={tabsArr[currentTab]?.heading}
			subtitle={tabsArr[currentTab]?.subheading}
			tabs={tabs}
			{...props}
			runJoyride={runJoyride}
			joyrideSteps={joyrideSteps}
			joyrideCallback={joyrideCallback}
			startJoyride={() => setRunJoyride(true)}
		>
			<TeamRoutes tab={tabsArr[currentTab]?.tab ?? tabsArr[0]?.tab} />
		</MainContent>
	);
}
