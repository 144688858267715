import React from "react";
import ToggleButton from "./ToggleButton";

export default function ExportButton({ exportData }) {
	return (
		<>
			{Boolean(exportData) && (
				<ToggleButton
					onClick={() => exportData("xlsx", false)}
					toggleOptions={[
						{
							isOpen: true,
							label: "Export Data",
							icon: "fas fa-download",
							color: "gray.500",
							bg: "whiteAlpha.700",
						},
					]}
				/>
			)}
		</>
	);
}
