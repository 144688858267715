import React, { useState, useMemo, useRef } from "react";
import {
	Box,
	Input,
	Select,
	Progress,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverBody,
	Portal,
	useOutsideClick,
	Text,
	Heading,
	Divider,
	useBoolean,
} from "@chakra-ui/react";

export default function DataInputSelectLegacy(props) {
	const [scrollTop, setScrollTop] = useState(0);
	const [filterTimer, setFilterTimer] = useState(null);

	const selectOptions = useMemo(() => props.selectOptions ?? [], [props.selectOptions]);
	const [filteredItems, setFilteredItems] = useState(selectOptions);

	const [popoverIsOpen, setPopoverBool] = useState(props.preOpen ?? false);
	const searchFieldRef = useRef(null);
	const ref = useRef();

	const openPopover = (ev) => {
		ev.preventDefault();
		ev.stopPropagation();
		setPopoverBool(true);
		if (selectOptions?.length === 0 && props.loadData) {
			props.loadData(props.name);
		}
	};

	const closePopover = () => {
		if (props.preOpen) {
			props.closePreOpen();
		}
		setPopoverBool(false);
	};

	useOutsideClick({ ref: ref, handler: () => closePopover() });

	const handleScroll = (ev) => {
		let element = ev.target;
		setScrollTop(element.scrollTop);
	};

	const handleFilterChange = (ev) => {
		ev.stopPropagation();
		let value = ev.target.value;
		setFilterTimer(clearTimeout(filterTimer));
		setFilterTimer(
			setTimeout(() => {
				filterItems(value);
			}, 200)
		);
	};

	const renderItem = (item, filterVal) => {
		filterVal = filterVal.toLowerCase();
		let filterAttr = [props.title ? props.title : "key", props.subtitle ? props.subtitle : "value"];
		let itemVal = "";

		for (let i = 0; i < filterAttr.length; i++) {
			itemVal += item[filterAttr[i]]?.toLowerCase() + " ";
		}

		if (itemVal.includes(filterVal)) {
			return true;
		} else {
			return false;
		}
	};

	const filterItems = (filterVal) => {
		let filteredArr = [];

		for (let i = 0; i < selectOptions.length; i++) {
			if (renderItem(selectOptions[i], filterVal)) {
				filteredArr.push(selectOptions[i]);
			}
		}
		setFilteredItems(filteredArr);
	};

	const selectItem = (item) => {
		closePopover();
		props.onChange(item);
		if (props.preOpen) {
			props.closePreOpen();
		}
	};

	if (selectOptions !== null && (filteredItems?.length === 0 || filteredItems === undefined)) {
		if (selectOptions?.length > 0) {
			setFilteredItems(selectOptions);
		}
	}

	let elementHeight = Math.floor(scrollTop / 45);
	let lowerBound = elementHeight - 3;
	let upperBound = Math.floor((scrollTop + 300) / 45) + 3;

	let popoverContent = (
		<PopoverContent maxH="90vh" maxW="100vw" w="sm" ref={ref}>
			<PopoverBody>
				<Box bg="white" w="full">
					<Box w="full" py="2" my="0" zIndex="2" position="sticky">
						<Input
							bg="white"
							focusBorderColor="teal.600"
							placeholder="Search..."
							onChange={handleFilterChange}
							ref={searchFieldRef}
						/>
					</Box>
					<Box
						w="full"
						overflowY="auto"
						overflowX="hidden"
						maxH={{ base: "sm", sm: "sm", md: "md" }}
						pr="1"
						className="skinnyScroll"
						onScroll={handleScroll}
					>
						{filteredItems?.map((item, i) => {
							if (i > lowerBound && i < upperBound) {
								return (
									<VirtualSelectItem
										key={i}
										item={item}
										onSelect={selectItem}
										disabled={props.disabledItems?.includes(item?.key ?? item[props.title])}
										{...props}
									/>
								);
							} else {
								return <Box height="45px"></Box>;
							}
						})}

						{selectOptions?.length === 0 && <Progress py={1} size="sm" isIndeterminate />}
					</Box>
				</Box>
			</PopoverBody>
		</PopoverContent>
	);

	let dataView = props.dataView ?? "table";
	let placement = dataView === "table" ? "bottom-start" : "bottom-end";

	return (
		<Popover
			w="full"
			initialFocusRef={searchFieldRef}
			isOpen={popoverIsOpen}
			onClose={closePopover}
			placement="auto" // Replaces 'placement' and allows Popover to adjust its position if needed
			closeOnBlur={false}
			offset={props.offset ? props.offset : [0, 0]}
		>
			<PopoverTrigger>
				<Box
					direction="column"
					w={props.w ? props.w : "inherit"}
					px={props?.px ?? 0}
					bg="whiteAlpha.400"
					borderBottomWidth={2}
					borderBottomColor={"gray.400"}
					borderTopRadius="md"
					_hover={{
						borderBottomColor: !props.isDisabled ? "teal.500" : "red.600",
						shadow: "lg",
						bg: "whiteAlpha.700",
					}}
				>
					<Select
						variant={props.variant ? props.variant : "flushed"}
						openMenuOnClick={false}
						focusBorderColor="teal.600"
						fontWeight={props?.fontWeight ?? "semibold"}
						letterSpacing={props?.letterSpacing ?? "1"}
						value={props.value}
						// bg="white"
						// borderTopRadius="md"

						color={
							props.color
								? props.color
								: props.value === props.placeholder
								? "gray.400"
								: props.isInvalid
								? "red.500"
								: props.isDisabled
								? "gray.700"
								: "teal.700"
						}
						isDisabled={props.isDisabled}
						cursor={props.isDisabled ? "not-allowed" : "pointer"}
						isInvalid={props.isInvalid}
						pl={2}
						size={props.fontSize ? props.fontSize : "md"}
						onClick={openPopover}
						isReadOnly
						minW={props?.minW ?? "50px"}
						borderBottomWidth={0}
						// borderBottomWidth={2}
						// borderBottomColor={"gray.400"}
						maxW="full"
						// _hover={{ borderBottomColor: !props.isDisabled ? "teal.500" : "red.600" }}
					>
						<option value={props.value}>{props.value}</option>
					</Select>
				</Box>
			</PopoverTrigger>
			{props.noPortal && <>{popoverContent}</>}
			{!props.noPortal && popoverIsOpen && <Portal>{popoverContent}</Portal>}
		</Popover>
	);
}

function VirtualSelectItem({ disabled, item, title, subtitle, onSelect }) {
	const [isHovering, setHovering] = useBoolean();

	if (disabled) {
		return (
			<>
				<Box
					height="45px"
					w="full"
					display="table"
					onMouseEnter={setHovering.on}
					onMouseLeave={setHovering.off}
					bg="gray.100"
					cursor={isHovering ? "not-allowed" : ""}
				>
					<div display="table-cell">
						<Box p="2">
							<Heading as="h5" size="md" pb="1" letterSpacing={1} color="gray.400" isTruncated>
								{item[title] ?? item.key}
							</Heading>
							<Text fontSize="sm" color="gray.400" isTruncated>
								{item[subtitle] ?? item.value}
							</Text>
						</Box>
					</div>
				</Box>
				<Divider orientation="horizontal" />
			</>
		);
	} else {
		return (
			<>
				<Box
					height="45px"
					w="full"
					display="table"
					onMouseEnter={setHovering.on}
					onMouseLeave={setHovering.off}
					bg={isHovering ? "blackAlpha.100" : ""}
					cursor={isHovering ? "pointer" : ""}
					onClick={() => {
						onSelect(item);
					}}
				>
					<div display="table-cell">
						<Box p="2">
							<Heading as="h5" size="md" pb="1" letterSpacing={1} color="gray.500" isTruncated>
								{item[title] ?? item.key}
							</Heading>
							<Text fontSize="sm" color="gray.500" isTruncated>
								{item[subtitle] ?? item.value}
							</Text>
						</Box>
					</div>
				</Box>
				<Divider orientation="horizontal" />
			</>
		);
	}
}
