import React, { useRef, useState, useEffect } from "react";
import { Box, Center, Flex, Text, Heading, Stack, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";

export default function SpringAndFollowExample({ heading, subHeading }) {
	const ref = useRef(null);

	const [positionOffset, setPositionOffset] = useState({ x: 0, y: 0 });
	const [pointer, setPointer] = useState({ x: 0, y: 0 });

	const globalCoords = useFollowPointer();
	const handleMouseMove = (event) => {
		if (Boolean(ref.current)) {
			const offsetX = ref?.current?.offsetLeft + ref?.current?.offsetWidth / 2;
			const offsetY = ref?.current?.offsetTop + ref?.current?.offsetHeight / 2;
			const x = globalCoords.x - offsetX - 150 - 125;
			const y = globalCoords.y - offsetY - 150;
			setPositionOffset({ x: offsetX, y: offsetY });
			setPointer({ x: x, y: y });
		}
	};

	const style = {
		width: "150px",
		height: "150px",
		background: "var(--chakra-colors-red-500)",
		borderRadius: "50%",
		cursor: "pointer",
		boxShadow: "var(--chakra-shadows-xl)",
	};

	return (
		<Box w="full" h="full" bg="white" p={8} rounded={5}>
			<VStack
				postion="relative"
				spacing={4}
				flex={1}
				w="full"
				border="3px dashed"
				borderColor="gray.200"
				rounded="xl"
				h="full"
				onMouseMove={handleMouseMove}
			>
				<Heading color="gray.500" textTransform="uppercase" letterSpacing={6} size="xl" pt={16}>
					{subHeading}
				</Heading>
				<Heading color="gray.400" textTransform="uppercase" letterSpacing={6} size="3xl" pb={16}>
					{heading}
				</Heading>
				<Flex justify="flex-end" w="full" flex={1} h="full" position="absolute">
					<Stack w="full" justify="flex-end" textAlign="right" p={6} color="gray.400">
						<Text w="full">
							Mouse: x:{Math.round(globalCoords.x, 0)} y:{Math.round(globalCoords.y, 0)}
						</Text>
						<Text w="full">
							Element: x:{Math.round(positionOffset.x, 0)} y:{Math.round(positionOffset.y, 0)}
						</Text>
						<Text w="full">
							Offset: x:{Math.round(globalCoords.x - positionOffset?.x, 0)} y:
							{Math.round(globalCoords.y - positionOffset?.y, 0)}
						</Text>
					</Stack>
				</Flex>

				<Center position="absolute" w="full" minH="2xs" h="full" top={0}>
					<motion.div
						ref={ref}
						style={style}
						animate={pointer}
						transition={{
							type: "spring",
							damping: 3,
							stiffness: 50,
							restDelta: 0.001,
						}}
					/>
				</Center>
			</VStack>
		</Box>
	);
}

function useFollowPointer(props) {
	const [globalCoords, setGlobalCoords] = useState({ x: 0, y: 0 });

	useEffect(() => {
		const handlePointerMove = (event) => {
			setGlobalCoords({
				x: event.pageX,
				y: event.pageY,
			});
		};

		window.addEventListener("pointermove", handlePointerMove);
		return () => {
			window.removeEventListener("pointermove", handlePointerMove);
		};
	}, []);

	return globalCoords;
}
