import React from "react";
import {
	Box,
	Flex,
	Center,
	Spacer,
	Button,
	Text,
	Heading,
	InputGroup,
	Input,
	Skeleton,
	Tooltip,
	VStack,
	Container,
	Stack,
	IconButton,
	Icon,
	HStack,
	StackDivider,
	InputLeftAddon,
	Tabs,
	TabPanel,
	Tab,
	TabList,
	TabPanels,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionIcon,
	AccordionPanel,
} from "@chakra-ui/react";
import { Email, Span, renderEmail } from "react-html-email";
import { IoShieldHalfOutline } from "react-icons/io5";

import MainContent from "../App/components/MainContent";
import BasicCard from "../../core/Layout/cards/BasicCard";
import IFrame from "../../core/Layout/modals/IFrame";
import PPMLogo from "../../core/Logos/components/PPMLogo";

import PayrollAPI from "../../APIs/PayrollAPI";
import PR_MONTH_NAMES from "./constants/PRMonthNames";

import { portalUser } from "../../App";
import moment from "moment";
import { v4 } from "uuid";

export default class Paystubs extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			tabVal: 0,
			payrollAPI: new PayrollAPI(),
			paystubList: null,
			attachment: null,
			pdf: null,

			authCode: null,
			enteredCode: null,
			isAuthorized: false,
			authFailed: false,
			authType: null,
			loadPage: false,

			selectedPaystub: null,
			paystubYears: null,

			print: false,
		};

		this.init = this.init.bind(this);
		this.handleTabChange = this.handleTabChange.bind(this);
		this.closeForm = this.closeForm.bind(this);

		this.handleAuthCodeChange = this.handleAuthCodeChange.bind(this);
		this.sendAuthEmail = this.sendAuthEmail.bind(this);

		this.getPaystub = this.getPaystub.bind(this);
		this.closePaystubPreview = this.closePaystubPreview.bind(this);

		this.printPage = this.printPage.bind(this);
		this.afterPrint = this.afterPrint.bind(this);
		this.downloadPaystub = this.downloadPaystub.bind(this);
	}

	componentDidMount() {
		this.init();
	}

	async init() {
		let paystubList = await this.state.payrollAPI.GetPaystubs(portalUser.user?.employeeUID);
		if (paystubList.status === 200) {
			paystubList = paystubList.value;
		}

		let years = {};

		for (let i = 0; i < paystubList.length; i++) {
			let date = moment(paystubList[i].PREndDate.replace(/T.*/, ""));
			let year = date.format("YYYY");
			let mth = date.format("M");

			if (years[year] === undefined) {
				years[year] = {};
			}

			if (years[year][mth] === undefined) {
				years[year][mth] = [];
			}

			years[year][mth].push(paystubList[i]);
		}

		this.setState({
			paystubList: paystubList,
			paystubYears: years,
		});
	}

	handleTabChange(tabVal) {
		this.setState({ tabVal: tabVal });
	}

	closeForm() {
		this.setState({ tabVal: 0 });
	}

	async getPaystub(paystub) {
		let attachment = await this.state.payrollAPI.GetAttachmentBlobFile(paystub.AttachmentID);

		if (attachment.status === 200) {
			attachment = attachment.value;
		}

		let blob = new Blob([new Uint8Array(attachment[0].AttachmentData.data)], { type: "application/pdf" });
		let fileURL = URL.createObjectURL(blob);

		// const a = document.createElement('a');
		// a.style.display = 'none';
		// a.href = fileURL;
		// a.target = '_blank';
		// document.body.appendChild(a);
		// a.click();

		this.setState({
			pdf: fileURL,
		});
	}

	handleAuthCodeChange(ev) {
		let authCode = this.state.authCode;
		let isAuthorized = this.state.isAuthorized;
		let authFailed = this.state.authFailed;

		let enteredCode = ev.target.value.toUpperCase();
		enteredCode = enteredCode.replace(/\s+/g, "");
		enteredCode = enteredCode.substring(0, 6);

		if (!isAuthorized && authCode?.length === 6 && enteredCode?.length === 6) {
			if (authCode === enteredCode) {
				isAuthorized = true;
				authFailed = false;
			} else {
				isAuthorized = false;
				authFailed = true;
			}
		} else {
			isAuthorized = false;
			authFailed = false;
		}

		this.setState(
			{
				enteredCode: enteredCode,
				authFailed: authFailed,
				isAuthorized: isAuthorized,
			},
			() => {
				setTimeout(() => {
					this.setState({ loadPage: isAuthorized });
				}, 2000);
			}
		);
	}

	getAuthCode(authCode = null) {
		if (authCode?.length >= 6) {
			authCode = authCode?.toUpperCase()?.replace(/\s+/g, "")?.substring(0, 6);
		}

		return authCode;
	}

	async sendAuthEmail(authType = "email") {
		let authCode = this.getAuthCode(v4());

		if (portalUser.user?.usersAPI && authType === "email") {
			let from = "PPM Portal<no-reply@ppmportal.com>";
			let subject = "PPM Portal User Verification Code";
			let body = renderEmail(
				<Email title={subject} style={{ width: "100%" }}>
					<Span>
						Please enter the following the 6-digit verification code into the PPM Portal to view your Past
						Paystubs: <h3 style={{ color: "teal" }}>{authCode}</h3>
					</Span>
				</Email>
			);

			let msg = {
				from: from,
				to: portalUser.user?.email,
				subject: subject,
				html: body,
			};

			await portalUser.user?.usersAPI.SendgridEmail(msg);
		}

		//log('MSG', msg)
		this.setState({
			authCode: authCode,
			authType: authType,
			isAuthorized: false,
			authFailed: false,
		});
	}

	closePaystubPreview() {
		this.setState({ selectedPaystub: null, pdf: null });
	}

	printPage() {
		this.setState({
			print: true,
		});
		return setTimeout(() => {}, 4000);
	}

	afterPrint() {
		this.setState({
			print: false,
		});
	}

	async downloadPaystub(paystub) {
		let attachment = await this.state.payrollAPI.GetAttachmentBlobFile(paystub.AttachmentID);

		if (attachment.status === 200) {
			attachment = attachment.value;
		}

		let blob = new Blob([new Uint8Array(attachment[0].AttachmentData.data)], { type: "application/pdf" });
		let fileURL = URL.createObjectURL(blob);

		const a = document.createElement("a");
		a.style.display = "none";
		a.href = fileURL;
		// the filename you want
		a.download = "Paystub_" + moment(paystub.PREndDate.replace(/T.*/, "")).format("M-D-yyyy") + ".pdf";
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(fileURL);
	}

	render() {
		let tabs = ["Paystubs"];

		let hasCellPhone = Boolean(
			portalUser.user?.cellPhone !== "" &&
				portalUser.user?.cellCarrier !== null &&
				portalUser.user?.cellCarrier !== "" &&
				portalUser.user?.cellCarrier !== null
		);
		let hiddenCellPhone = "(XXX) XXX-XXXX";
		if (hasCellPhone) {
			hiddenCellPhone = "(XXX) XXX-" + portalUser.user?.cellPhone.slice(-4);
		}

		return (
			<Box>
				{!this.state.loadPage && (
					<MainContent
						onTabChange={this.handleTabChange}
						title="Verification Required"
						tabVal={this.state.tabVal}
						tabs={["Locked"]}
						{...this.props}
					>
						<Flex
							zIndex={10}
							direction="column"
							align="center"
							justify="center"
							pos="absolute"
							bg="teal.500"
							opacity={0.9}
							top="0"
							w="full"
							minH="100vh"
							maxH="100vh"
							overflow="hidden"
						>
							<Flex flex={1}></Flex>
							<Icon
								flex={8}
								ml={{ base: "0px", md: "-112px" }}
								p="6"
								as={IoShieldHalfOutline}
								color="whiteAlpha.800"
								boxSize="full"
								maxH="container.lg"
								maxW="container.lg"
							/>
							<Flex flex={4}></Flex>
						</Flex>

						<Flex
							zIndex={11}
							pos="absolute"
							top="0"
							direction="column"
							w="full"
							minH="100vh"
							maxH="100vh"
							h="full"
							bg="blackAlpha.500"
							align="center"
							justify="center"
							overflow="hidden"
						>
							<Container mt="25vh" w="full" h="full" px="0">
								<Center
									h="full"
									p="6"
									shadow="xl"
									rounded="10"
									maxH="sm"
									bg="whiteAlpha.900"
									maxW="xl"
									ml={{ base: "0px", md: "-84px" }}
								>
									<VStack spacing={6}>
										<PPMLogo color="teal.700" h="65px" />

										<VStack spacing={2} textAlign="center">
											{!this.state.isAuthorized && !this.state.authCode && (
												<Heading
													color="gray.500"
													size="sm"
													letterSpacing={1}
													textTransform="uppercase"
												>
													To access this page, please verify your identity
												</Heading>
											)}
											{this.state.authCode &&
												!this.state.authFailed &&
												!this.state.isAuthorized && (
													<Heading
														color="gray.500"
														size="xs"
														letterSpacing={1}
														textTransform="uppercase"
													>
														{"Please enter the code sent to " +
															(this.state.authType === "text"
																? hiddenCellPhone
																: "your email") +
															" below"}
													</Heading>
												)}
											{this.state.authCode &&
												this.state.authFailed &&
												!this.state.isAuthorized && (
													<Heading
														color="red.500"
														size="xs"
														letterSpacing={1}
														textTransform="uppercase"
													>
														{"Invalid Code, Please check your " +
															(this.state.authType === "text" ? "phone" : "email") +
															" and try again"}
													</Heading>
												)}
											{this.state.isAuthorized && (
												<Heading
													color="green.600"
													size="sm"
													letterSpacing={1}
													textTransform="uppercase"
												>
													Identify verification is complete, access granted
												</Heading>
											)}

											<Text as="i" color="gray.500" letterSpacing={1} fontSize="sm">
												If you leave this page, you will need to re-verify your identity
											</Text>
										</VStack>

										<Center>
											<InputGroup>
												<InputLeftAddon
													px="3"
													borderColor={
														this.state.authCode && this.state.authFailed
															? "red.500"
															: "gray.300"
													}
													border="2px"
													pointerEvents="none"
													children={
														<Heading
															key={"authorized-" + this.state.isAuthorized}
															color={
																this.state.authCode && this.state.authFailed
																	? "red.500"
																	: this.state.isAuthorized
																	? "green.600"
																	: "gray.500"
															}
															size="sm"
														>
															<i
																className={
																	"fas " +
																	(this.state.isAuthorized
																		? "fa-lock-open"
																		: this.state.authCode && this.state.authFailed
																		? "fa-exclamation-triangle"
																		: "fa-lock") +
																	" fa-fw fa-lg"
																}
															></i>
														</Heading>
													}
												/>
												<Input
													bg="white"
													color="teal.600"
													fontWeight="bold"
													letterSpacing={2}
													textTransform="uppercase"
													textAlign="center"
													focusBorderColor={this.state.authFailed ? "red.500" : "teal.600"}
													borderColor="gray.300"
													border="2px"
													fontSize="xl"
													placeholder="Verification Code"
													_placeholder={{ color: "gray.300", fontWeight: "normal" }}
													maxW="xs"
													value={this.state.enteredCode}
													isInvalid={this.state.authFailed}
													_invalid={{ color: "red", borderColor: "red.500" }}
													errorBorderColor="red.500"
													isDisabled={!this.state.authCode}
													_disabled={{ borderColor: "gray.300", cursor: "not-allowed" }}
													onChange={this.handleAuthCodeChange}
													maxLength="7"
												/>
											</InputGroup>
										</Center>

										<HStack spacing="4">
											{/* <Button
												isDisabled={!hasCellPhone}
												textTransform="uppercase"
												onClick={() => {
													this.sendAuthEmail("text");
												}}
												size="md"
												pl="2"
												pr="3"
												colorScheme="blue"
												variant="solid"
												py={2}
											>
												<HStack spacing={1} align="center">
													<i className="fas fa-mobile-alt fa-fw fa-lg"></i>
													<Text letterSpacing={1}>
														{this.state.authCode && this.state.authType === "text"
															? "Re-Send Code"
															: "Text Code"}
													</Text>
												</HStack>
											</Button> */}

											<Button
												textTransform="uppercase"
												onClick={() => {
													this.sendAuthEmail("email");
												}}
												size="md"
												pl="2"
												pr="3"
												colorScheme="teal"
												variant="solid"
												py={2}
											>
												<HStack align="center">
													<i className="fas fa-envelope fa-fw fa-lg"></i>
													<Text letterSpacing={1}>
														{this.state.authCode && this.state.authType === "email"
															? "Re-Send Code"
															: "Email Code"}
													</Text>
												</HStack>
											</Button>
										</HStack>
										{!hasCellPhone && (
											<Text
												color="gray.400"
												size="xs"
												textAlign="center"
												letterSpacing={1}
												as="i"
											>
												To enable text message notifications, go to the <strong>Account</strong>
												tab on your <strong>Profile</strong> and add your
												<strong>Cell Phone</strong> and <strong>Carrier</strong>
											</Text>
										)}
									</VStack>
								</Center>
							</Container>
							<Spacer />
						</Flex>
					</MainContent>
				)}
				{this.state.loadPage && (
					<MainContent
						onTabChange={this.handleTabChange}
						tabVal={this.state.tabVal}
						tabs={tabs}
						{...this.props}
					>
						{this.state.tabVal === 0 && (
							<Container maxW="8xl" minW={{ base: "100%", xl: "xl" }} px="4" py="6">
								<VStack spacing="6">
									{this.state.pdf && (
										<BasicCard variant="simple" title="Preview Paystub" mt="6">
											<Flex>
												<Spacer />
												<Tooltip label="Close Preview" hasArrow={true} placement="top">
													<IconButton
														variant="ghost"
														color="gray.400"
														icon={<i className="far fa-times-circle fa-lg " />}
														onClick={(e) => {
															this.closePaystubPreview(e);
														}}
													/>
												</Tooltip>
											</Flex>
											<Box>
												<IFrame variant="simple" url={this.state.pdf} minH="90vh" />
											</Box>
										</BasicCard>
									)}

									{!this.state.pdf && (
										<BasicCard variant="simple" title="Past Paystubs" mt="6">
											<Skeleton
												rounded="10"
												minHeight="50vh"
												isLoaded={Boolean(this.state.paystubYears)}
											>
												{this.state.paystubYears && (
													<Tabs colorScheme="teal">
														<TabList>
															{Object.keys(this.state?.paystubYears)
																.reverse()
																.map((year, i) => (
																	<Tab fontWeight="bold" color="gray.400">
																		{year}
																	</Tab>
																))}
														</TabList>
														<TabPanels>
															{Object.keys(this.state?.paystubYears)
																.reverse()
																.map((year, i) => (
																	<TabPanel>
																		<Box>
																			<Accordion allowMultiple>
																				{Object.keys(
																					this.state?.paystubYears[year]
																				)
																					.reverse()
																					.map((mthIndex, j) => (
																						<AccordionItem
																							bg="whitesmoke"
																							my="4"
																							p="4"
																							rounded="5"
																							border="none"
																							shadow="md"
																						>
																							<Heading
																								as="h4"
																								color="gray.600"
																							>
																								<AccordionButton>
																									<Box
																										flex="1"
																										textAlign="left"
																									>
																										<Heading
																											color="gray.600"
																											textTransform="uppercase"
																											letterSpacing={
																												3
																											}
																											size="lg"
																										>
																											{PR_MONTH_NAMES[
																												mthIndex -
																													1
																											] +
																												" " +
																												year}
																										</Heading>
																									</Box>
																									<AccordionIcon boxSize="40px" />
																								</AccordionButton>
																							</Heading>
																							<AccordionPanel pb={4}>
																								<Stack
																									spacing={0}
																									divider={
																										<StackDivider />
																									}
																								>
																									{this.state?.paystubYears[
																										year
																									][mthIndex].map(
																										(
																											paystub,
																											k
																										) => (
																											<Flex
																												w="full"
																												p="4"
																												rounded="5"
																												className="hoverPointerWhite"
																												wrap="wrap"
																											>
																												<Flex>
																													<HStack spacing="4">
																														<Box
																															w="65px"
																															h="65px"
																															rounded="5"
																															shadow="lg"
																															bg="whiteAlpha.800"
																															mr="2"
																														>
																															<Center
																																bg="red.600"
																																borderTopRadius="md"
																																p="1"
																															>
																																<Heading
																																	as="h4"
																																	size="xs"
																																	color="whiteAlpha.900"
																																	textTransform="uppercase"
																																	fontWeight="900"
																																>
																																	{moment(
																																		paystub.PREndDate.replace(
																																			/T.*/,
																																			""
																																		)
																																	).format(
																																		"MMM"
																																	)}
																																</Heading>
																															</Center>
																															<Box
																																p={
																																	1
																																}
																															>
																																<Center>
																																	<Heading
																																		textTransform="uppercase"
																																		as="h4"
																																		size="lg"
																																		color="gray.600"
																																	>
																																		{moment(
																																			paystub.PREndDate.replace(
																																				/T.*/,
																																				""
																																			)
																																		).format(
																																			"D"
																																		)}
																																	</Heading>
																																</Center>
																															</Box>
																														</Box>
																														<Stack spacing="1">
																															<Heading
																																size="md"
																																color="gray.500"
																																letterSpacing={
																																	1
																																}
																															>
																																{"Week Ending " +
																																	moment(
																																		paystub.PREndDate.replace(
																																			/T.*/,
																																			""
																																		)
																																	).format(
																																		"M/D/yyyy"
																																	)}
																															</Heading>
																															<Text
																																size="sm"
																																color="gray.500"
																															>
																																{moment(
																																	paystub.PREndDate.replace(
																																		/T.*/,
																																		""
																																	)
																																)
																																	.subtract(
																																		6,
																																		"day"
																																	)
																																	.format(
																																		"dddd, MMM Do YYYY"
																																	) +
																																	" to " +
																																	moment(
																																		paystub.PREndDate.replace(
																																			/T.*/,
																																			""
																																		)
																																	).format(
																																		"dddd, MMM Do YYYY"
																																	)}
																															</Text>
																														</Stack>
																													</HStack>
																												</Flex>

																												<Spacer />

																												<Stack
																													direction="row"
																													spacing={
																														4
																													}
																												>
																													<Button
																														onClick={() => {
																															this.getPaystub(
																																paystub
																															);
																														}}
																														textTransform="uppercase"
																														size="md"
																														pl="2"
																														pr="3"
																														colorScheme="teal"
																														variant="solid"
																														py={
																															2
																														}
																													>
																														<i className="fas fa-file-pdf fa-fw fa-lg"></i>
																														Preview
																													</Button>
																													<Button
																														textTransform="uppercase"
																														size="md"
																														pl="2"
																														pr="3"
																														colorScheme="blue"
																														variant="solid"
																														py={
																															2
																														}
																														onClick={() => {
																															this.downloadPaystub(
																																paystub
																															);
																														}}
																													>
																														<i className="fas fa-file-download fa-fw fa-lg"></i>
																														Download
																													</Button>
																												</Stack>
																											</Flex>
																										)
																									)}
																								</Stack>
																							</AccordionPanel>
																						</AccordionItem>
																					))}
																			</Accordion>
																		</Box>
																	</TabPanel>
																))}
														</TabPanels>
													</Tabs>
												)}
											</Skeleton>
										</BasicCard>
									)}
								</VStack>
							</Container>
						)}
					</MainContent>
				)}
			</Box>
		);
	}
}
