import React from "react";

import { Box, HStack, Text, Stack, Container } from "@chakra-ui/react";
import { icons } from "../constants/icons";

export default function FontAwesomeIcons() {
	return (
		<Box p={8}>
			<Container maxW="container.lg" bg="whiteAlpha.800" p={8} rounded="lg" shadow="lg">
				<Stack>
					{icons?.map((icon, i) => (
						<HStack key={i + " " + icon.icon}>
							<Text color="teal.600" fontSize="lg">
								<i className={icon.icon + " fa-2x fa-fw"} />
							</Text>

							<Text>{icon.icon}</Text>
						</HStack>
					))}
				</Stack>
			</Container>
		</Box>
	);
}
