import React, { useMemo } from "react";
import { Flex, Stack, HStack, Text, Heading, VStack } from "@chakra-ui/react";
import PPMLogo from "../../Logos/components/PPMLogo";
import PageObj from "../../Reports/classes/Page";
// import PageHeader from "./PageHeader";
// import PageFooter from "./PageFooter";
import Section from "../sections/Section";
import PrintButton from "../../Reports/pdf/PrintPDFButton";
// import PreviewPDF from "./ReportPDF/PreviewPDF";
import { convertArrayToMap, log } from "../../../helperFunctions";

export default function Page({
	pageTemplate,
	overrideView = {},
	panelVal,
	changePanel,
	setSelectedRow,
	pageHeaderDropdown,
	pageButtons = [],
	skipReset = false,
}) {
	const page = useMemo(() => new PageObj(pageTemplate), [pageTemplate]);
	log("page", page);
	const pageSettings = useMemo(() => {
		let showPageHeader = overrideView?.showPageHeader ?? page?.showPageHeader ?? true;
		let repeatPageHeader = overrideView?.repeatPageHeader ?? page?.repeatPageHeader ?? false;
		let pageName = overrideView?.pageName ?? page?.pageName ?? null;
		let pageTitle = overrideView?.pageTitle ?? page?.pageTitle ?? null;
		let addressBar = overrideView?.addressBar ?? page?.addressBar ?? null;
		let orientation = overrideView?.orientation ?? page?.orientation ?? "portrait";
		let shadow = overrideView?.shadow ?? page?.shadow ?? "lg";
		let showPageFooter = overrideView?.showPageFooter ?? page?.showPageFooter ?? true;
		return {
			showPageHeader,
			repeatPageHeader,
			pageName,
			pageTitle,
			addressBar,
			orientation,
			shadow,
			showPageFooter,
		};
	}, [page, overrideView]);

	const pagebreaks = useMemo(() => {
		let pagebreaks = page?.pageBody ?? [];
		if (pagebreaks?.length > 0) {
			let pagebreakMap = convertArrayToMap(pagebreaks, "pagebreak");
			let pagebreakArr = Object?.entries(pagebreakMap)?.map(([key, value]) => ({
				pagebreak: key,
				section: value,
			}));
			return pagebreakArr?.filter((d) => Boolean(d.pagebreak)) ?? [];
		}
	}, [page]);

	const printButton = useMemo(() => {
		return <PrintButton page={page} />;
	}, [page]);

	const pageHeaderButtons = useMemo(() => [...pageButtons, printButton], [pageButtons, printButton]);

	return (
		<Stack spacing={2} direction="column" flex={1} justify="flex-start" h="full" rounded={"md"}>
			{pagebreaks?.map((pagebreak, i) => {
				return (
					<Flex
						key={i}
						// bg={overrideView?.bg ?? page?.bg ?? "white"}
						rounded={"md"}
						direction="column"
						flex={1}
						w={overrideView?.orientation ?? (page?.orientation === "portrait" ? "container.xl" : "full")}
						minW={overrideView?.orientation ?? (page?.orientation === "portrait" ? "container.xl" : "full")}
						maxW={overrideView?.orientation ?? (page?.orientation === "portrait" ? "container.xl" : "full")}
						shadow={overrideView?.shadow ?? page?.shadow ?? "lg"}
						justify="space-between"
						py={overrideView?.py ?? 4}
						px={overrideView?.px ?? 4}
					>
						{pageSettings?.showPageHeader && (
							<>
								{(pageSettings?.repeatPageHeader || (!pageSettings?.repeatPageHeader && i === 0)) && (
									<PageHeader
										pageHeader={pageSettings}
										panelVal={panelVal}
										changeTab={changePanel}
										pageHeaderDropdown={pageHeaderDropdown}
										pageHeaderButtons={pageHeaderButtons}
										// printPDF={<PrintPDF page={page} />}
										// previewPDF={<PreviewPDF page={page} />}
									/>
								)}
							</>
						)}
						<Flex direction="column">
							<Section
								key={i}
								page={page}
								section={pagebreak?.section}
								setSelectedRow={setSelectedRow}
								skipReset={skipReset}
							/>
						</Flex>
						{pageSettings?.showPageFooter && (
							<>
								{(pageSettings?.repeatPageHeader || (!pageSettings?.repeatPageHeader && i === 0)) && (
									<PageFooter panelVal={panelVal} pageFooter={page.pageFooter} pagebreak={i} />
								)}
							</>
						)}
					</Flex>
				);
			})}
		</Stack>
	);
}

export function PageFooter({ pagebreak }) {
	return (
		<Flex dirction="row" h="100px" minH="100px" w="full" justify="center" align="center" borderBottomRadius={5}>
			<Heading fontSize="xl" letterSpacing={6} color="gray.500" textTransform="uppercase">
				Page {pagebreak + 1}
			</Heading>
		</Flex>
	);
}

export function PageHeader({ pageHeader, panelVal, changePanel, pageHeaderDropdown, pageHeaderButtons }) {
	return (
		<Flex direction="column">
			<Flex dirction="row" w="full" justify="space-between">
				<PPMLogo color="teal.700" h="55px" minW="225px" />

				<VStack w="full" spacing={3} align="center" flex={1}>
					{Boolean(pageHeader?.pageName?.length > 0) && (
						<Heading
							fontSize={pageHeader?.pageTitle?.length > 0 ? "2xl" : "3xl"}
							letterSpacing={6}
							color="gray.500"
							textTransform="uppercase"
						>
							{pageHeader?.pageName}
						</Heading>
					)}
					{Boolean(pageHeader?.pageTitle?.length > 0) && (
						<Heading fontSize="xl" letterSpacing={4} color="gray.600" textTransform="uppercase">
							{pageHeader?.pageTitle}
						</Heading>
					)}
					<Flex>{Boolean(pageHeaderDropdown) && pageHeaderDropdown}</Flex>
					{/* <Flex>{Boolean(pageButton) && <Button>Click Me</Button>}</Flex> */}
				</VStack>

				<HStack justify="flex-end" align="flex-start" minW="225px">
					{/* {panelVal > 0 && (
						<Tooltip label="Close" placement="top">
							<IconButton
								onClick={() => changePanel(0)}
								variant="ghost"
								color="gray.500"
								bg="whiteAlpha.500"
								icon={<i className="far fa-times-circle fa-lg " />}
							/>
						</Tooltip>
					)} */}
					{pageHeaderButtons}
				</HStack>
			</Flex>

			{Boolean(pageHeader?.addressBar) && (
				<Flex px={8} w="full">
					<Flex w="full" justify="center" borderTopWidth={2} borderColor="gray.500" py={1}>
						<HStack
							color="gray.600"
							spacing={10}
							w="full"
							justify="center"
							align="center"
							divider={
								<Text px="4" fontSize="xs">
									<i className="fas fa-circle fa-xs"></i>
								</Text>
							}
						>
							<Text px="2" isTruncated>
								{pageHeader?.addressBar?.address}
							</Text>
							<Text px="2" isTruncated>
								{pageHeader?.addressBar?.city +
									", " +
									pageHeader?.addressBar?.state +
									" " +
									pageHeader?.addressBar?.zip}
							</Text>
							<Text px="2" isTruncated>
								{"Phone: " + pageHeader?.addressBar?.phone}
							</Text>
							<Text px="2" isTruncated>
								{"Fax: " + pageHeader?.addressBar?.fax}
							</Text>
						</HStack>
					</Flex>
				</Flex>
			)}
		</Flex>
	);
}
