import React, { useState, useEffect } from "react";
import { Flex } from "@chakra-ui/react";
import { portalUser } from "../../../App";

import MFRProjectSelectVirtualList from "../../../core/ReactTable/legacy/ProjectSelectVirtualList";
import SectionTemplateActiveProjects from "../../Employee/templates/SectionTemplateActiveProjects";
import SectionObj from "../../../core/Reports/classes/Section";
import Calendar from "../../Contracts/common/Calendar";
// import { log } from "../../../helperFunctions";

export default function MFRProjectSelectPage({
	viewerMaxHeight = 800,
	// reportSelect = false,
	selectedKeys = [],
	setSelectedKeys,
	selectContract,
}) {
	const [projectSelect, setProjectSelect] = useState(null);
	const [calendarEvents, setCalendarEvents] = useState([]);

	useEffect(() => {
		let section = new SectionObj(
			new SectionTemplateActiveProjects(portalUser?.getContracts(), { groupBy: "accessYN" }, "ProjectSelect")
		);

		setProjectSelect(section?.subSections[0] ?? null);
		setCalendarEvents(portalUser?.getCalendarEvents());
	}, []);

	return (
		<Flex direction="column" w="full" justify="flex-start" flex={1} h="full">
			<Flex direction={{ base: "column-reverse", xl: "row" }} w="full" flex={1}>
				<Flex direction="column" w="full" flex={1} mr={{ base: "0", xl: "6" }} h="full">
					{Boolean(projectSelect) && (
						<MFRProjectSelectVirtualList
							viewerMaxHeight={viewerMaxHeight}
							// dataset parameters
							heading={projectSelect.heading}
							columns={projectSelect.columns}
							data={projectSelect.data}
							groupBy={projectSelect.groupBy}
							filterBy={projectSelect.filterBy}
							sortBy={projectSelect.sortBy}
							// functions
							selectedKeys={selectedKeys}
							setSelectedKeys={setSelectedKeys}
							selectContract={selectContract}
						/>
					)}
				</Flex>

				<Flex
					flex={2}
					direction="column"
					my={{ base: "4", sm: "6", xl: "0" }}
					w="full"
					maxW="full"
					minW="container.md"
					h="full"
					overflow="auto"
				>
					<Calendar heading="Meeting Schedule" calendarEvents={calendarEvents} />
				</Flex>
			</Flex>
		</Flex>
	);
}

// import React from "react";
// import { Flex } from "@chakra-ui/react";
// import { portalUser } from "../../App";

// import ReportType from "./ReportType";
// import ProjectSelectVirtualList from "./VirtualList";
// import Calendar from "../MFRDashboard/Dashboard/Calendar";

// // import { log } from "../../../helperFunctions";

// export default function Dashboard(props) {
// 	return (
// 		<Flex direction="column" w="full" justify="flex-start" flex={1} h="full">
// 			<Flex direction={{ base: "column-reverse", xl: "row" }} w="full" flex={1}>
// 				<Flex direction="column" w="full" flex={1} mr={{ base: "0", xl: "6" }} h="full">
// 					{Boolean(portalUser.projects) && (
// 						<ProjectSelectVirtualList
// 							viewerMaxHeight={props.viewerMaxHeight}
// 							// dataset parameters
// 							heading={portalUser.projects?.heading}
// 							columns={portalUser.projects?.columns}
// 							data={portalUser.projects?.data}
// 							groupBy={portalUser.projects?.groupBy}
// 							filterBy={portalUser.projects?.filterBy}
// 							sortBy={portalUser.projects?.sortBy}
// 							// functions
// 							selectedKeys={props.selectedKeys}
// 							setSelectedKeys={props.setSelectedKeys}
// 							selectProject={props.selectProject}
// 						/>
// 					)}
// 				</Flex>

// 				<Flex
// 					flex={2}
// 					direction="column"
// 					my={{ base: "4", sm: "6", xl: "0" }}
// 					w="full"
// 					maxW="full"
// 					minW="container.md"
// 					h="full"
// 					overflow="auto"
// 				>
// 					<ReportType reportType={props.reportType} setReportType={props.setReportType} />
// 					<Calendar heading="Meeting Schedule" />
// 				</Flex>
// 			</Flex>
// 		</Flex>
// 	);
// }
