import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Flex, useBoolean } from "@chakra-ui/react";
import Page from "../../Contracts/common/Page";
import SectionObj from "../../../core/Reports/classes/Section";
import SectionTemplateActiveProjects from "../templates/SectionTemplateActiveProjects";
import Section from "../../../core/Reports/components/Section";
import { portalUser } from "../../../App";
import Operations from "../../Operations/classes/Operations";
import { log } from "../../../helperFunctions";

export default function ProjectsPage(props) {
	//BASE PATH OF CURRENT URL
	// let history = useHistory();
	// const basepath = useMemo(() => {
	// 	let basepath = history.location.pathname;
	// 	if (basepath?.length > 0 && basepath?.charAt(0) === "/") {
	// 		basepath = basepath?.slice(1);
	// 		basepath = basepath?.substring(0, basepath?.indexOf("/"));
	// 		return basepath;
	// 	}
	// }, [history.location.pathname]);

	//TAB OPTIONS DEPENDENT ON BASE PATH (EMPLOYEE, TEAM, or HR)
	const tab = useMemo(() => props.tab, [props.tab]);

	// Default State Variables

	const [operations, setOperations] = useState(portalUser?.operations ?? null);
	//DATA IS LOADING FLAG AND CALLBACK FUNCTION
	const [isLoading, setIsLoading] = useBoolean(true);
	const setDataLoading = useCallback(
		async function (callback) {
			if (!isLoading) {
				setIsLoading.on();
			}
			callback();
		},
		[isLoading, setIsLoading]
	);

	const getOpsData = useCallback(
		async (params = {}) => {
			let newOperations = operations;
			if (Boolean(newOperations)) {
				newOperations = await newOperations?.getProjects(params);
			}
			setOperations(newOperations);
			setIsLoading.off();
			window.scrollTo({ top: 0, behavior: "smooth" });
		},
		[operations, setIsLoading]
	);

	//ON PAGE LOAD INIT FORM DATA - GET FORMS & TEMPLATES
	const initOperationsData = useCallback(
		async (contractStatus = "1") => {
			let msUserID = portalUser.user.msUserID ?? portalUser?.msUser?.id ?? null;
			let params = { msUserID, contractStatus };
			let newOperations = operations;
			if (Boolean(msUserID) && !Boolean(newOperations)) {
				newOperations = new Operations(portalUser?.operations);

				newOperations = await newOperations?.initOperations(params);
			} else {
				getOpsData(params);
			}
			setOperations(newOperations);
			// getOpsData(params);
			// setOperations(newOperations);
			// setIsLoading.off();
		},
		[getOpsData, operations]
	);

	useEffect(() => {
		let contractStatus = null;
		if (tab === "active") {
			contractStatus = "1";
		} else if (tab === "completed") {
			contractStatus = "3";
		}
		setDataLoading(() => initOperationsData(contractStatus));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Update portalUser operations cache
	useMemo(() => {
		if (Boolean(operations) && !isLoading) {
			portalUser.operations = operations;
			log("portalUser.operations has been Updated", portalUser);
		}
	}, [isLoading, operations]);

	return (
		<Flex bg="blackAlpha.500" direction="column" flex={1} justify="flex-start" w="full" h="full" py={4} px={6}>
			<Page minH="6xl" maxW="full">
				<Section section={new SectionObj(new SectionTemplateActiveProjects(operations?.projects))} />
			</Page>
		</Flex>
	);
}
