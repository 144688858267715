import React, { useMemo, useState, useCallback } from "react";
import { Center, Text, Heading, Stack, HStack, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import DataInput from "../../../core/Inputs/data/DataInput";
import SectionTemplateMFRSchedule from "../templates/SectionTemplateMFRSchedule";

import { formatValue } from "../../../helperFunctions";
import { v4 } from "uuid";

export default function Schedule(props) {
	const contract = useMemo(() => props.contract, [props.contract]);
	const contractStatus = useMemo(() => props.contractStatus, [props.contractStatus]);
	const prevContractStatus = useMemo(() => props.prevContractStatus, [props.prevContractStatus]);
	const [schedule, setSchedule] = useState(new SectionTemplateMFRSchedule());

	useMemo(() => {
		let schedule = new SectionTemplateMFRSchedule(contract, contractStatus, prevContractStatus);
		schedule = schedule?.subSections[0] ?? {};
		setSchedule(schedule);
	}, [contract, contractStatus, prevContractStatus]);

	const updateDates = useCallback(
		async (attribute, e) => {
			if (e !== contract[attribute]) {
				await contract.update(attribute, e);
				if (attribute === "projCloseDate") {
					props.selectContract(contract?.jccmKeyID, false, true);
				}
				let schedule = new SectionTemplateMFRSchedule(contract, contractStatus, prevContractStatus);
				schedule = schedule?.subSections[0] ?? {};
				setSchedule(schedule);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[contract, contractStatus, prevContractStatus]
	);

	return (
		<Stack w="full" spacing={4} px={4}>
			<HStack align="center">
				<Heading
					minW="25px"
					size={"md"}
					color={"gray.500"}
					textTransform="uppercase"
					isTruncated
					letterSpacing={2}
				>
					{["2", "4", "5"].includes(props.contract?.contractTypeID) ? "III." : "V."}
				</Heading>
				<Heading size={"md"} color={"gray.500"} textTransform="uppercase" isTruncated letterSpacing={2}>
					Schedule
				</Heading>
			</HStack>

			<Center className="skinnyScroll" overflow="hidden" w="full">
				<Table size="small" bg="white" top="0" left="0" color={"gray.600"}>
					<Thead>
						<Tr borderColor="black" borderWidth="2px" textTransform="uppercase" bg="teal.700">
							{schedule?.columns?.map((column, i) => (
								<Th key={i}>
									<Heading fontSize="xs" color={"white"} isTruncated p="2" textAlign="center">
										{column?.Header}
									</Heading>
								</Th>
							))}
						</Tr>
					</Thead>
					<Tbody border="2px solid black">
						{schedule?.data?.map((row, i) => (
							<Tr _hover={{ bg: "blackAlpha.100" }} key={i}>
								<Td px="2" borderRight="1px">
									<Text>{row?.label}</Text>
								</Td>

								{i <= 1 ? (
									<Td px="2" borderRight="1px">
										<DataInput
											key={v4()}
											variant="unstyled"
											onChange={(ev) => {
												updateDates(i === 0 ? "origStartDate" : "origCloseDate", ev);
											}}
											inputProps={{
												color: row?.origSchedule ? "gray.600" : "gray.400",
												fontSize: "md",
											}}
											placeholder="mm/dd/yyyy"
											type="date"
											size="sm"
											inputBG="white"
											value={row?.origSchedule}
										/>
									</Td>
								) : (
									<Td
										px="2"
										borderRight="1px"
										bg={row?.origSchedule === "N/A" ? "blackAlpha.100" : "transparent"}
									>
										<Text key={v4()} py={1} textAlign="center">
											{Boolean(row?.origScheduleType === "string")
												? row.origSchedule
												: formatValue(
														row?.origSchedule,
														row?.origScheduleDecimals ?? 0,
														"number"
												  )}
											{Boolean(row?.origScheduleRightAddon)
												? " " + row?.origScheduleRightAddon
												: ""}
										</Text>
									</Td>
								)}

								{i <= 1 ? (
									<Td
										px="2"
										borderRight="1px"
										bg={row?.currSchedule === "N/A" ? "blackAlpha.100" : "transparent"}
									>
										<DataInput
											key={v4()}
											variant="unstyled"
											onChange={(ev) => {
												updateDates(i === 0 ? "currStartDate" : "projCloseDate", ev);
											}}
											inputProps={{
												color: row?.currSchedule ? "gray.600" : "gray.400",
												fontSize: "md",
											}}
											placeholder="mm/dd/yyyy"
											type="date"
											size="sm"
											inputBG="white"
											value={row?.currSchedule}
										/>
									</Td>
								) : (
									<Td
										px="2"
										borderRight="1px"
										bg={row?.currSchedule === "N/A" ? "blackAlpha.100" : "transparent"}
									>
										<Text
											key={v4()}
											py={1}
											textAlign="center"
											fontWeight={
												row?.label === "G.  Projected Hours @ Completion" ? "bold" : "normal"
											}
										>
											{Boolean(row?.currScheduleType === "string")
												? row.currSchedule
												: formatValue(
														row?.currSchedule,
														row?.currScheduleDecimals ?? 0,
														"number"
												  )}
											{Boolean(row?.currScheduleRightAddon)
												? " " + row?.currScheduleRightAddon
												: ""}
										</Text>
									</Td>
								)}

								<Td px="2" bg={row?.overUnder === "N/A" ? "blackAlpha.100" : "transparent"}>
									<Text textAlign="center">
										{Boolean(row?.overUnderType === "string")
											? row.overUnder
											: formatValue(row?.overUnder, row?.overUnderDecimals ?? 0, "number")}
										{Boolean(row?.overUnderRightAddon) ? " " + row?.overUnderRightAddon : ""}
									</Text>
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Center>
		</Stack>
	);
}
