import React from "react";
import { Container, Stack } from "@chakra-ui/react";
import UserProfileHeader from "../components/EmployeeProfileHeader";
import UserPreferencesForm from "../components/UserPreferencesForm";
import UserContactForm from "../components/UserContactForm";
import EmergencyContactsForm from "../components/UserEmergencyContacts";
// import UserSettingsForm from "../components/UserSettingsForm";
import { portalUser } from "../../../App";

export default function UserProfilePage() {
	return (
		<Stack
			bg={portalUser.styles?.pageBG}
			spacing={portalUser.styles?.pageSpacing}
			p={portalUser.styles?.pagePadding}
			w="full"
			flex={1}
			align="center"
		>
			<UserProfileHeader />
			<Container w="full" maxW="container.lg">
				<Stack spacing={portalUser.styles?.pageSpacing} w="full" flex={1} align="center" color="gray.600">
					<UserPreferencesForm />
					<UserContactForm />
					<EmergencyContactsForm />
					{/* <UserSettingsForm /> */}
				</Stack>
			</Container>
		</Stack>
	);
}
