import React, { useMemo, useCallback } from "react";
import { Flex } from "@chakra-ui/react";
import BasicCard from "../../../core/Layout/cards/BasicCard";
import VirtualListV3 from "../../../core/ReactTable/components/VirtualListV3";
import HRReviewsToDoListGroupedRow from "./HRReviewsToDoListGroupedRow";
import HRReviewsToDoListRow from "./HRReviewsToDoListRow";
import { portalUser } from "../../../App";
import { convertArrayToMap } from "../../../helperFunctions";

export default function HRReviewsToDoList(props) {
	const panel = useMemo(() => props.panel, [props.panel]);
	const data = useMemo(() => props.forms, [props.forms]);

	let users = portalUser?.userData.filter((d) => d.queryData === "User") ?? [];
	let usersMap = convertArrayToMap(users, "employeeUID");
	let departments = portalUser.getDepartments();
	let deptMap = convertArrayToMap(departments, "deptID");

	let initialState = useMemo(() => {
		let initialState = {
			defaultGroupBy: panel?.defaultGroupBy ?? [],
			defaultFilters: panel?.defaultFilters ?? [],
			defaultSortAttr: panel?.defaultSortAttr ?? [],
			defaultExpanded: panel?.defaultExpanded ?? {},
		};

		return initialState;
	}, [panel?.defaultGroupBy, panel?.defaultFilters, panel?.defaultSortAttr, panel?.defaultExpanded]);

	const columns = useMemo(() => {
		let columns = [
			{
				Header: "Form UID",
				id: "formUID",
				disableGroupBy: true,
				disableFilters: true,
				disableSortBy: true,
				accessor: (row) => row.formUID,
				Footer: (rows) => {
					// Only calculate total visits if rows change
					const rowCount = useMemo(() => rows?.length, [rows?.length]);
					return rowCount;
				},
			},
			{ Header: "Review Year", id: "formYear", accessor: (row) => row?.formYear },
			{ Header: "Form Title", id: "formTitle", accessor: (row) => row.formTitle },
			{
				Header: "Employee Status",
				id: "userStatus",
				accessor: (row) => usersMap[row.assignedFor?.toLowerCase()]?.userStatus ?? "Active",
			},
			{
				Header: "Assigned For UID",
				id: "assignedFor",
				disableGroupBy: true,
				disableFilters: true,
				disableSortBy: true,
				accessor: (row) => row.assignedFor,
			},
			{
				id: "assignedForName",
				accessor: (row) => usersMap[row.assignedFor]?.name ?? "N/A",
				Header: "Employee",
			},
			{
				id: "parentDept",
				accessor: (row) => deptMap[deptMap[usersMap[row.assignedFor]?.deptID]?.parentDeptID]?.dept ?? null,
				Header: "Parent Dept",
			},
			{
				Header: "Dept",
				id: "dept",
				accessor: (row) => deptMap[usersMap[row.assignedFor]?.deptID]?.dept ?? null,
			},

			{
				Header: "Assigned To UID",
				id: "assignedTo",
				disableFilters: true,
				disableSortBy: true,
				disableGroupBy: true,
				accessor: (row) => row.assignedTo,
			},
			{
				Header: "Reviewer",
				id: "assignedToName",
				disableFilters: true,
				accessor: (row) => usersMap[row.assignedTo]?.name ?? "Not Assigned",
			},
			{
				Header: "Status ID",
				id: "id",
				disableFilters: true,
				disableSortBy: true,
				disableGroupBy: true,
				show: false,
				accessor: (row) => row.getFormStatus()?.id,
			},
			{
				Header: "Review Status",
				id: "status",
				accessor: (row) => row.getFormStatus()?.status,
			},
			{
				Header: "Importance",
				id: "importanceLevel",
				disableFilters: true,
				disableSortBy: true,
				disableGroupBy: true,
				accessor: (row) => row.getFormStatus()?.importanceLevel,
			},
			{
				Header: "To Do List",
				id: "todoList",
				disableFilters: true,
				disableSortBy: true,
				disableGroupBy: true,

				accessor: (row) => row.getFormStatus()?.todoList,
			},

			{
				Header: "Form Type ID",
				id: "formType",
				disableFilters: true,
				disableSortBy: true,
				disableGroupBy: true,
				accessor: (row) => row?.formType ?? null,
			},
			{
				Header: "Form Type",
				id: "formTypeDesc",
				accessor: (row) => row?.formTypeDesc ?? null,
			},
			{
				Header: "Completed Evals",
				id: "completedEvals",

				disableFilters: true,
				disableSortBy: true,
				disableGroupBy: true,

				accessor: (row) => row.getPeerStatus()?.completedEvals ?? null,
			},
			{
				Header: "Total Evals",
				id: "totalEvals",

				disableFilters: true,
				disableSortBy: true,
				disableGroupBy: true,

				accessor: (row) => row.getPeerStatus()?.totalEvals ?? null,
			},
			{
				Header: "Requires Review",
				id: "requiresReviewYN",

				disableFilters: true,
				disableSortBy: true,
				disableGroupBy: true,

				accessor: (row) => row?.requiresReviewYN ?? "N",
			},
		];
		return columns;
	}, [deptMap, usersMap]);

	const RenderRow = useCallback(
		({ index, row, style }) => {
			if (row.isGrouped) {
				// let columns = row?.cells?.map((cell)=> cell.column)
				let columns = row?.cells?.map((tab) => tab.column);
				// log("columns " + row.groupByID, columns);
				let column = columns?.find((d) => d.id === row?.groupByID) ?? null;
				// log("column " + row?.groupByID, column);

				let statuses = {};

				for (let i = 0; i < row?.leafRows?.length; i++) {
					let formStatus = row?.leafRows[i].original?.getFormStatus();
					// log("formStatus", formStatus);
					// log("ROW", row);

					if (!Boolean(statuses[formStatus?.status])) {
						statuses[formStatus?.status] = {
							id: formStatus.id,
							importanceLevel: formStatus.importanceLevel,
							todoList: formStatus.todoList,
							icon: formStatus.icon,
							color: formStatus.color,
							count: 0,
						};
					}
					statuses[formStatus?.status].count = statuses[formStatus?.status].count + 1;
				}

				return (
					<Flex
						{...row.getRowProps({ style })}
						key={row.id + "-" + index}
						flex={1}
						style={style}
						w="full"
						p={2}
						h="full"
						overflow="hidden"
						minH={82}
						align="center"
						borderBottomColor="blackAlpha.300"
						borderBottomWidth={2}
						justify="space-between"
						bg={row?.depth === 0 ? "whiteAlpha.500" : "blackAlpha.100"}
					>
						<HRReviewsToDoListGroupedRow row={row} column={column} statuses={statuses} />
					</Flex>
				);
			} else {
				return (
					<Flex
						{...row.getRowProps({ style })}
						style={style}
						flex={1}
						w="full"
						h="full"
						overflow="hidden"
						align="center"
						bg="blackAlpha.300"
						maxH={175}
					>
						<HRReviewsToDoListRow
							key={index}
							form={row.original}
							usersMap={usersMap}
							openDrawer={props.openDrawer}
							selectForm={props.selectForm}
						/>
					</Flex>
				);
			}
		},
		[props.openDrawer, props.selectForm, usersMap]
	);

	return (
		<BasicCard portalStyles={portalUser.styles}>
			<VirtualListV3
				data={data}
				columns={columns}
				renderRow={RenderRow}
				minH={150}
				hoverDisabled={true}
				listHeight={775}
				defaultSortAttr={initialState.defaultSortAttr}
				defaultFilters={initialState.defaultFilters}
				defaultGroupBy={initialState.defaultGroupBy}
				defaultExpanded={initialState.defaultExpanded}
			/>
		</BasicCard>
	);
}
