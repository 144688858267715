import React, { useMemo } from "react";
import { Box, VStack, Divider, Heading, Flex, HStack, Stack, Container, Text } from "@chakra-ui/react";
import PPMLogo from "../../../core/Logos/components/PPMLogo";
import LoadingProgress from "../../../core/ProgressIndicators/components/ProgressBar";
import Timer from "../../../core/ProgressIndicators/components/Timer";

export default function LoadingScreen({ progressBar, loadingMessage }) {
	return (
		<Box h="100vh" bgImage={"url(/images/Background_LoadingPage.jpg)"} bgSize="cover" bgPosition="center">
			<Container maxW="container.md" h="full">
				<Flex direction="row" w="full" h="full" flex={1} align="center">
					<Flex direction="column" flex={1} h="full" align="center">
						<Flex direction="row" w="full" h="full" flex={3} align="center">
							<Stack w="full" align="center" justify="center">
								<PPMLogo color={"teal.600"} h={{ base: 24, sm: 24, md: 32 }} objectFit="cover" />

								<VStack spacing={1} py={1} wrap="wrap">
									<Divider borderColor="teal.600" />
									<Box px={2}>
										<Divider borderColor="teal.600" />
										<HStack spacing={6} py={1} color="teal.600" letterSpacing={3} isTruncated>
											<Heading size="lg">FAMILY.</Heading>

											<Heading size="lg">LOYALTY.</Heading>

											<Heading size="lg">RESPECT.</Heading>
										</HStack>
										<Divider borderColor="teal.600" />
									</Box>
									<Divider borderColor="teal.600" />

									<Flex flex={1} w="full" py={6} px={2}>
										{Boolean(progressBar) && <LoadingProgress progressBar={progressBar} />}
									</Flex>
									<Timer
										progressBar={progressBar}
										includeUnits={["icon", "hours", "minutes", "seconds"]}
										seconds={45}
									/>
								</VStack>
							</Stack>
						</Flex>
						<Flex direction="column" flex={1} w="full" align="center" justify="center">
							{Boolean(loadingMessage) && <LoadingScreenTips loadingMessage={loadingMessage} />}
						</Flex>
					</Flex>
				</Flex>
			</Container>
		</Box>
	);
}

export function LoadingScreenTips({ loadingMessage }) {
	const icon = useMemo(() => loadingMessage?.icon ?? "fas fa-cog fa-spin", [loadingMessage?.icon]);
	const title = useMemo(() => loadingMessage?.title ?? null, [loadingMessage?.title]);
	const body1 = useMemo(() => loadingMessage?.body1 ?? null, [loadingMessage?.body1]);
	const body2 = useMemo(() => loadingMessage?.body2 ?? null, [loadingMessage?.body2]);
	const footer = useMemo(() => loadingMessage?.footer ?? null, [loadingMessage?.footer]);

	return (
		<HStack
			bg="whiteAlpha.400"
			bgGradient={"linear(to-tr, white, whiteAlpha.400)"}
			borderWidth={2}
			borderColor="gray.300"
			shadow="lg"
			px={4}
			py={4}
			rounded="xl"
			spacing={4}
		>
			<Text color="gray.400" fontSize="4xl">
				<i className={icon + " fa-2x fa-fw"} />
			</Text>
			<Stack spacing={2}>
				{Boolean(title) && (
					<Text as="i" color="gray.500" letterSpacing={1} fontSize="xl" fontWeight="semibold">
						{title}
					</Text>
				)}
				{Boolean(body1) && (
					<Text as="i" color="gray.500" letterSpacing={1} fontSize="md">
						{body1}
					</Text>
				)}
				{Boolean(body2) && (
					<Text as="i" color="gray.500" letterSpacing={1} fontSize="md">
						{body2}
					</Text>
				)}
				{Boolean(footer) && (
					<Text as="i" color="gray.500" fontSize="md" fontWeight="semibold">
						{footer}
					</Text>
				)}
			</Stack>
		</HStack>
	);
}
