import React from "react";
import {
	Box,
	Flex,
	Text,
	Heading,
	VStack,
	HStack,
	Tooltip,
	IconButton,
} from "@chakra-ui/react";

import PPMLogo from "../../../core/Logos/components/PPMLogo";
import DataInput from "../../../core/Inputs/data/DataInput";

import {
	EmployeeHistoryList,
	EmployeeHistoryRow,
} from "../classes/MeritIncreasesClassesLegacy";

export default function MeritIncreasesDeptSecurityHeader(props) {
	let employeeSearch = new EmployeeHistoryList(props.employeeHistory);
	employeeSearch = employeeSearch.sortEmployeesByName();
	let employeeName = props.selectedEmployee?.employeeName ?? null;

	return (
		<VStack spacing="3" w="full" align="center">
			<Flex
				w="full"
				justify="space-between"
				direction={{ base: "column", lg: "row" }}
				align="flex-start"
			>
				<PPMLogo color="teal.700" h="60px" />
				<VStack spacing={1} px="4" align="center" justify="center">
					<Heading
						textAlign="center"
						size="xl"
						letterSpacing={2}
						color="gray.400"
						textTransform="uppercase"
						isTruncated
					>
						{props.title}
					</Heading>

					<Flex w="full" maxW="md">
						<DataInput
							type="select"
							variant="outline"
							placeholder="Employee Search"
							searchable
							value={employeeName}
							options={employeeSearch}
							static
							optionText="employeeName"
							optionSubheading="subheading"
							onOptionSelect={props.selectEmployee}
						/>
						{Boolean(props.selectedEmployee) && (
							<IconButton
								variant="outline"
								ml={1}
								color="gray.400"
								onClick={props.closeEmployee}
								icon={
									<i className="fas fa-times fa-fw fa-lg" />
								}
							/>
						)}
					</Flex>
				</VStack>

				<Flex minW="240px" justify="flex-end">
					{props.project !== null && (
						<HStack align="flex-start" spacing={2}>
							<Tooltip
								label="Edit Access"
								hasArrow={true}
								placement="top"
							>
								<IconButton
									onClick={() => {
										props.selectLeader(
											new EmployeeHistoryRow()
										);
									}}
									variant="ghost"
									color="gray.400"
									icon={<i className="fas fa-cog fa-lg " />}
								/>
							</Tooltip>
						</HStack>
					)}
				</Flex>
			</Flex>

			{props.showAddressBar && (
				<AddressBar projectInfo={props.project?.projectInfo} />
			)}
		</VStack>
	);
}

function AddressBar(props) {
	return (
		<Box mx="20" w="full">
			<HStack
				borderTop="2px"
				borderTopColor="gray.400"
				spacing={6}
				py="3"
				w="full"
				justify="center"
				align="center"
				divider={
					<Text px="4" fontSize="xs" color="gray.600">
						<i className="fas fa-circle fa-xs"></i>
					</Text>
				}
			>
				<Text fontSize="md" color="gray.600" isTruncated>
					{props.projectInfo?.officeAddress ?? "18250 Euclid Street"}
				</Text>
				<Text fontSize="md" color="gray.600" isTruncated>
					{props.projectInfo?.officeCity ?? "Fountain Valley"},{" "}
					{props.projectInfo?.officeState ?? "CA"}{" "}
					{props.projectInfo?.officeZip ?? "92708"}
				</Text>
				<Text fontSize="md" color="gray.600" isTruncated>
					Phone: {props.projectInfo?.officePhone ?? "(949) 474-9170"}
				</Text>
				<Text fontSize="md" color="gray.600" isTruncated>
					Fax: {props.projectInfo?.officeFax ?? "(949) 474-9936"}
				</Text>
			</HStack>
		</Box>
	);
}
