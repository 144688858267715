import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Flex } from "@chakra-ui/react";
import Tabs from "./components/Tabs";
import Dashboard from "../../modules/MFR/pages/MFRProjectSelectPage";
import ReportParameters from "./components/ParameterSelect";
import PrintPreview from "../../modules/Contracts/common/PrintPreview";

import { portalUser } from "../../App";
import MainContent from "../../legacy/App/components/MainContent";
// import { log } from "../../../helperFunctions";

export default function ReportViewer(props) {
	// Default State Variables
	const [viewerMaxHeight, setViewerMaxHeight] = useState(800);
	const [tabVal, setTabVal] = useState(0);
	const [reportTabs, setReportTabs] = useState([]);
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [thruMth, setThruMth] = useState(null);

	const [document, setDocument] = useState(portalUser?.document);

	const [data, setData] = useState([]);

	// const [document, setDocument] = useState(portalUser.getDocument("MFR"));

	// Handle Tab Change on Click
	const changeTab = useCallback((e) => {
		setTabVal(e);
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	const closeForm = useCallback(() => {
		setTabVal(0);
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	const getContractDetail = useCallback(async (jccmKeyID = []) => {
		let data = await portalUser.getContractDetail(jccmKeyID);
		setData(data);
	}, []);

	// Asychronous Call to get the Selected Data based off the Selected Keys
	const getContractData = useCallback(
		async (jccmKeyID = []) => {
			let data = await portalUser.getContractData(jccmKeyID);
			setData(data);
			getContractDetail(jccmKeyID);
		},
		[getContractDetail]
	);

	// Submit the Selected Keys and Change to the First Report Tab
	const submitKeys = useCallback(
		(jccmKeyID = []) => {
			setSelectedKeys(jccmKeyID);
			let contracts = portalUser?.getContracts();
			let contract = contracts.find((d) => jccmKeyID?.includes(d.jccmKeyID)) ?? null;

			if (Boolean(contract)) {
				// setParameters(contract);
				setThruMth(contract.thruMth);
			}
			getContractData(jccmKeyID);
			if (tabVal === 0) {
				changeTab(1);
			}
		},
		[getContractData, tabVal, changeTab]
	);

	const selectContract = useCallback(
		(jccmKeyID = null, clearSelected = false) => {
			let jccmKeys = [];

			jccmKeys.push(jccmKeyID);
			if (clearSelected) {
				jccmKeys = [];
			}

			submitKeys(jccmKeys);
		},
		[submitKeys]
	);

	const selectThruMth = useCallback((thruMth) => {
		setThruMth(thruMth);
		// setDocument(portalUser?.getDocument("MFR", selectedKeys, thruMth));
	}, []);

	const setReportTemplate = useCallback((reportTemplate) => {
		let document = portalUser?.getReportTemplates(reportTemplate);
		setDocument(document);
	}, []);

	useMemo(() => {
		let reportTemplate = props.reportTemplate ?? "ReportTemplate";
		setReportTemplate(reportTemplate);
	}, [props.reportTemplate, setReportTemplate]);

	// Get the Report Template and Report Tabs for a Selected Report Type & Update the Tab Page if the Selected Data Changes
	useMemo(() => {
		let reportTabs = [];
		// if (["MonthlyForecast"].includes(template)) {
		// 	reportTabs.push({
		// 		name: "Monthly Forecast Report",
		// 		pages: [
		// 			<Page
		// 				maxW="container.xl"
		// 				title={
		// 					"Monthly Forecast Report" +
		// 					(["2", "4", "5"].includes(contract?.contractTypeID) ? " CRS" : "")
		// 				}
		// 				contract={contract}
		// 				thruMth={thruMth}
		// 				selectContract={selectContract}
		// 				selectThruMth={selectThruMth}
		// 				contractData={contractData}
		// 				closeForm={closeForm}
		// 				printForm={printForm}
		// 				viewerMaxHeight={viewerMaxHeight}
		// 			>
		// 				<MonthlyForecastReport
		// 					pagebreak={0}
		// 					contract={contract}
		// 					thruMth={thruMth}
		// 					contractData={contractData}
		// 					changeTab={() => changeTab(4)}
		// 				/>
		// 			</Page>,
		// 			<Page
		// 				maxW="container.xl"
		// 				title={
		// 					"Monthly Forecast Report" +
		// 					(["2", "4", "5"].includes(contract?.contractTypeID) ? " CRS" : "")
		// 				}
		// 				printForm={printForm}
		// 				contract={contract}
		// 				thruMth={thruMth}
		// 				selectContract={selectContract}
		// 				selectThruMth={selectThruMth}
		// 				contractData={contractData}
		// 				closeForm={closeForm}
		// 				viewerMaxHeight={viewerMaxHeight}
		// 			>
		// 				<MonthlyForecastReport
		// 					pagebreak={1}
		// 					contract={contract}
		// 					thruMth={thruMth}
		// 					contractData={contractData}
		// 				/>
		// 			</Page>,
		// 		],
		// 	});
		// }
		// if (["MonthlyForecast"].includes(template)) {
		// 	reportTabs.push({
		// 		name: "Manpower Projections",
		// 		pages: [
		// 			<Page
		// 				minH="7xl"
		// 				maxW="full"
		// 				title={"Manpower Projections"}
		// 				printForm={printForm}
		// 				contract={contract}
		// 				thruMth={thruMth}
		// 				selectContract={selectContract}
		// 				selectThruMth={selectThruMth}
		// 				contractData={contractData}
		// 				closeForm={closeForm}
		// 				viewerMaxHeight={viewerMaxHeight}
		// 			>
		// 				<ManpowerAndBillingProjections
		// 					selectedKeys={selectedKeys}
		// 					pagebreak={0}
		// 					thruMth={thruMth}
		// 					contractData={contractData}
		// 				/>
		// 			</Page>,
		// 		],
		// 	});
		// }
		// if (["MonthlyForecast"].includes(template)) {
		// 	reportTabs.push({
		// 		name: "Billing & Cost Projections",
		// 		pages: [
		// 			<Page
		// 				minH="7xl"
		// 				maxW="full"
		// 				title={"Billing & Cost Projections"}
		// 				printForm={printForm}
		// 				contract={contract}
		// 				thruMth={thruMth}
		// 				selectContract={selectContract}
		// 				selectThruMth={selectThruMth}
		// 				contractData={contractData}
		// 				closeForm={closeForm}
		// 				viewerMaxHeight={viewerMaxHeight}
		// 			>
		// 				<ManpowerAndBillingProjections
		// 					selectedKeys={selectedKeys}
		// 					pagebreak={1}
		// 					thruMth={thruMth}
		// 					contractData={contractData}
		// 				/>
		// 			</Page>,
		// 		],
		// 	});
		// }
		// if (["MonthlyForecast", "ChangeOrderLog"].includes(template)) {
		// 	reportTabs.push({
		// 		name: "Change Order Log",
		// 		pages: [
		// 			<Page
		// 				minH="7xl"
		// 				maxW="full"
		// 				title={"Pending Change Order Log"}
		// 				printForm={printForm}
		// 				contract={contract}
		// 				selectContract={selectContract}
		// 				selectThruMth={selectThruMth}
		// 				contractData={contractData}
		// 				closeForm={closeForm}
		// 				viewerMaxHeight={viewerMaxHeight}
		// 			>
		// 				<ChangeOrderLog pagebreak={0} contractData={contractData} />
		// 			</Page>,

		// 			<Page
		// 				minH="7xl"
		// 				maxW="full"
		// 				title={"Approved Change Order Log"}
		// 				printForm={printForm}
		// 				contract={contract}
		// 				selectContract={selectContract}
		// 				selectThruMth={selectThruMth}
		// 				contractData={contractData}
		// 				closeForm={closeForm}
		// 				viewerMaxHeight={viewerMaxHeight}
		// 			>
		// 				<ChangeOrderLog pagebreak={2} contractData={contractData} />
		// 			</Page>,
		// 		],
		// 	});
		// }
		// if (["ChangeOrderLog"].includes(template)) {
		// 	reportTabs.push({
		// 		name: "Pending Change Orders",
		// 		pages: [
		// 			<Page
		// 				minH="7xl"
		// 				maxW="full"
		// 				title={"Pending Change Order Log (CO Items)"}
		// 				printForm={printForm}
		// 				contract={contract}
		// 				selectContract={selectContract}
		// 				selectThruMth={selectThruMth}
		// 				contractData={contractData}
		// 				closeForm={closeForm}
		// 				viewerMaxHeight={viewerMaxHeight}
		// 			>
		// 				<ChangeOrderLog pagebreak={1} contractData={contractData} />
		// 			</Page>,
		// 		],
		// 	});
		// }
		// if (["MonthlyForecast"].includes(template)) {
		// 	reportTabs.push({
		// 		name: "Job Cost Drilldown",
		// 		pages: [
		// 			<Page
		// 				minH="7xl"
		// 				maxW="full"
		// 				title={"Job Cost Drilldown"}
		// 				printForm={printForm}
		// 				contract={contract}
		// 				thruMth={thruMth}
		// 				selectContract={selectContract}
		// 				selectThruMth={selectThruMth}
		// 				contractData={contractData}
		// 				closeForm={closeForm}
		// 				viewerMaxHeight={viewerMaxHeight}
		// 			>
		// 				<JobCostDrilldown pagebreak={0} thruMth={thruMth} contractData={contractData} />
		// 			</Page>,
		// 		],
		// 	});
		// }
		setReportTabs(reportTabs);
	}, []);

	// Create Tabs
	const tabs = useMemo(() => {
		const dashboard = [
			{
				name: "Select Project",
				pages: [
					<Dashboard
						viewerMaxHeight={viewerMaxHeight}
						selectedKeys={selectedKeys}
						setSelectedKeys={setSelectedKeys}
						selectContract={selectContract}
					/>,
				],
			},
		];

		const printPreview = [
			{
				name: "Print Preview",
				printPreview: true,
				pages: [<PrintPreview selectedKeys={selectedKeys} thruMth={thruMth} document={document} />],
			},
		];
		return [...dashboard, ...reportTabs, ...printPreview];
	}, [reportTabs, selectContract, selectedKeys, viewerMaxHeight, thruMth, document]);

	const printForm = useCallback(
		(e) => {
			setTabVal(tabs?.length - 1);
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		},
		[tabs?.length]
	);

	return (
		<MainContent tabVal={tabVal} onTabChange={changeTab} title={tabs[tabVal]?.name}>
			<Flex
				py={4}
				px={6}
				w="full"
				flex={1}
				minH={10}
				maxH={20}
				bgGradient="linear(to-b, blackAlpha.600, blackAlpha.500)"
			>
				<ReportParameters document={document} setReportTemplate={setReportTemplate} />
			</Flex>
			<Flex bg="blackAlpha.500" direction="column" flex={1} justify="flex-start" w="full" h="full" py={4} px={6}>
				<Tabs
					tabs={tabs}
					tabVal={tabVal}
					changeTab={changeTab}
					viewerMaxHeight={viewerMaxHeight}
					setViewerMaxHeight={setViewerMaxHeight}
				/>
			</Flex>
		</MainContent>
	);
}

// const multiSelectContract = useCallback(
// 	(keyID = null, multiSelect = false, clearSelected = false, aggregate = false, submit = false) => {
// 		if (multiSelect) {
// 			let keys = [...new Set(selectedKeys?.filter((key) => key !== undefined && key !== null))];
// 			if (keys.includes(keyID)) {
// 				keys = keys?.filter((key) => key !== keyID);
// 			} else {
// 				keys.push(keyID);
// 			}
// 			if (clearSelected) {
// 				keys = [];
// 			}
// 			if (submit) {
// 				submitKeys(keys, aggregate);
// 			} else {
// 				setSelectedKeys(keys);
// 			}
// 		} else {
// 			let keys = [keyID];
// 			if (clearSelected) {
// 				keys = [];
// 			}
// 			submitKeys(keys);
// 		}
// 	},
// 	[selectedKeys, submitKeys]
// );

export function SuperCoolTimer() {
	const [counter, setCounter] = useState(60);

	// First Attempts
	// setInterval(() => setCounter(counter - 1), 1000);

	// Second Attempts
	// React.useEffect(() => {
	//   counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
	// }, []);

	// Second Attempts - Inspection
	// React.useEffect(() => {
	//   counter > 0 &&
	//     setInterval(() => {
	//       console.log(counter);
	//       setCounter(counter - 1);
	//     }, 1000);
	// }, []);

	// Third Attempts
	// React.useEffect(() => {
	//   const timer =
	//     counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
	//   return () => clearInterval(timer);
	// }, [counter]);

	// Suggested by Laurent
	useEffect(() => {
		counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
	}, [counter]);

	return (
		<div className="App">
			<div>Countdown: {counter}</div>
		</div>
	);
}

// import React, { useState, useEffect, useMemo, useCallback } from "react";
// import { Flex } from "@chakra-ui/react";

// import Tabs from "../MonthlyForecastReport/Dashboard/Tabs";
// import Dashboard from "./Dashboard";
// import PrintPreview from "../MFRDashboard/Dashboard/PrintPreview";
// import Report from "../../../AppComponents/Report/Report";
// import { portalUser } from "../../../App";
// import MainContent from "../../../AppComponents/MainContent/MainContent";
// // import { log } from "../../../helperFunctions";

// export default function Dashboard(props) {
// 	// Default State Variables
// 	const [viewerMaxHeight, setViewerMaxHeight] = useState(500);
// 	const [tabVal, setTabVal] = useState(0);
// 	const [reportType, setReportType] = useState(portalUser?.reportData?.reportType);
// 	const [reportTabs, setReportTabs] = useState([]);
// 	const [selectedKeys, setSelectedKeys] = useState([]);
// 	const [selectedData, setSelectedData] = useState(portalUser?.reportData?.selectedData);

// 	const [reportTemplate, setReportTemplate] = useState(portalUser?.reportData?.getReportTemplate(reportType));

// 	// Handle Tab Change on Click
// 	const changeTab = useCallback((e) => {
// 		setTabVal(e);
// 		window.scrollTo({
// 			top: 0,
// 			behavior: "smooth",
// 		});
// 	}, []);

// 	// Get the Report Template and Report Tabs for a Selected Report Type & Update the Tab Page if the Selected Data Changes
// 	useEffect(() => {
// 		let template = portalUser?.reportData?.getReportTemplate(reportType);
// 		setReportTemplate(template);
// 	}, [reportType]);

// 	// Get the Report Template and Report Tabs for a Selected Report Type & Update the Tab Page if the Selected Data Changes
// 	useMemo(() => {
// 		let reportTabs = [];
// 		for (let i = 0; i < reportTemplate?.pages?.length; i++) {
// 			reportTabs.push({
// 				name: reportTemplate?.pages[i].pageName,
// 				page: <Report template={reportTemplate?.pages[i]} data={selectedData} changeTab={changeTab} />,
// 			});
// 		}
// 		setReportTabs(reportTabs);
// 	}, [reportTemplate, selectedData, changeTab]);

// 	// useEffect(() => {
// 	// 	let reportTemplate = portalUser?.reportData?.getReportTemplate(reportType);
// 	// 	let reportTabs = [];
// 	// 	for (let i = 0; i < reportTemplate?.pages?.length; i++) {
// 	// 		reportTabs.push({
// 	// 			name: reportTemplate?.pages[i].pageName,
// 	// 			page: <Reports template={reportTemplate?.pages[i]} data={selectedData} changeTab={changeTab} />,
// 	// 		});
// 	// 	}
// 	// 	log("ReportTabs", reportTabs);
// 	// 	setReportTabs(reportTabs);
// 	// }, [tabVal, reportType, selectedData, changeTab]);

// 	// Asychronous Call to get the Selected Data based off the Selected Keys
// 	const getSelectedData = useCallback(async (keys = [], aggregate = false) => {
// 		let selectedData = await portalUser?.getSelectedData(keys, aggregate);
// 		setSelectedData(selectedData);
// 	}, []);

// 	// Submit the Selected Keys and Change to the First Report Tab
// 	const submitKeys = useCallback(
// 		(keys = [], aggregate = false) => {
// 			setSelectedKeys(keys);
// 			getSelectedData(keys, aggregate);
// 			changeTab(1);
// 		},
// 		[changeTab, getSelectedData]
// 	);

// 	const selectProject = useCallback(
// 		(keyID = null, multiSelect = false, clearSelected = false, aggregate = false, submit = false) => {
// 			if (multiSelect) {
// 				let keys = [...new Set(selectedKeys)];
// 				keys = keys?.filter((key) => key !== undefined);
// 				keys = keys?.filter((key) => key !== null);

// 				if (keys.includes(keyID)) {
// 					keys = keys?.filter((key) => key !== keyID);
// 				} else {
// 					keys.push(keyID);
// 				}

// 				keys = keys?.filter((key) => key !== undefined);
// 				keys = keys?.filter((key) => key !== null);
// 				keys = [...new Set(keys)];
// 				if (clearSelected) {
// 					keys = [];
// 				}
// 				if (submit) {
// 					submitKeys(keys, aggregate);
// 				} else {
// 					setSelectedKeys(keys);
// 				}
// 			} else {
// 				let keys = [keyID];
// 				if (clearSelected) {
// 					keys = [];
// 				}
// 				submitKeys(keys);
// 			}
// 		},
// 		[selectedKeys, submitKeys]
// 	);

// 	// Create Tabs
// 	const tabs = useMemo(() => {
// 		const dashboard = [
// 			{
// 				name: "Select Project",
// 				page: (
// 					<Dashboard
// 						reportType={reportType}
// 						setReportType={setReportType}
// 						selectProject={selectProject}
// 						selectedKeys={selectedKeys}
// 						setSelectedKeys={setSelectedKeys}
// 						viewerMaxHeight={viewerMaxHeight}
// 					/>
// 				),
// 			},
// 		];
// 		const printPreview = [
// 			{
// 				name: "Print Preview",
// 				printPreview: true,
// 				page: <PrintPreview />,
// 			},
// 		];
// 		return [...dashboard, ...reportTabs, ...printPreview];
// 	}, [viewerMaxHeight, reportTabs, reportType, selectProject, selectedKeys]);

// 	return (
// 		<MainContent tabVal={tabVal} onTabChange={changeTab} title={tabs[tabVal]?.name}>
// 			<Flex bg="blackAlpha.500" direction="column" flex={1} justify="flex-start" w="full" h="full" py={4} px={6}>
// 				<Tabs
// 					tabs={tabs}
// 					tabVal={tabVal}
// 					changeTab={changeTab}
// 					viewerMaxHeight={viewerMaxHeight}
// 					setViewerMaxHeight={setViewerMaxHeight}
// 				></Tabs>
// 			</Flex>
// 		</MainContent>
// 	);
// }
