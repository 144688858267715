import React from "react";
import { Heading, Stack, IconButton, HStack } from "@chakra-ui/react";
import FORM_STATUSES from "../../../core/Forms/constants/FormStatuses";
// import { log } from "../../../helperFunctions";

export default function HRReviewsToDoListGroupedRow({ row, column, statuses }) {
	// log("row", row);

	return (
		<>
			<HStack
				align="center"
				justify="flex-start"
				spacing={4}
				w="full"
				maxW="xs"
				py={2}
				px={row?.depth === 0 ? 0 : 4}
			>
				<IconButton
					size={row?.depth === 0 ? "lg" : "sm"}
					key={"GroupBy-" + row?.id + (row.isExpanded ? "-Open" : "-Closed")}
					onClick={() => row.toggleRowExpanded()}
					borderRadius="md"
					borderWidth={2}
					borderColor="gray.300"
					shadow="md"
					fontWeight="semibold"
					bg="whiteAlpha.700"
					color="gray.500"
					_hover={{
						color: "teal.500",
						borderColor: "teal.500",
						borderWidth: "2px",
					}}
					_focus={{ boxShadow: "outline", borderWidth: "2px" }}
					icon={<i className={"fas " + (row.isExpanded ? "fa-minus" : "fa-plus") + " fa-fw fa-lg"} />}
				/>

				<Stack spacing={1} justify="flex-end" isTruncated>
					<Heading
						letterSpacing={2}
						size={row?.depth === 0 ? "sm" : "xs"}
						color="gray.400"
						textShadow="2px 2px 2px white"
						textTransform="uppercase"
						isTruncated
						opacity={0.9}
					>
						{column?.Header}
					</Heading>

					<Heading
						letterSpacing={2}
						size={row?.depth === 0 ? "md" : "sm"}
						color="gray.600"
						textShadow="2px 2px 2px white"
						textTransform="uppercase"
						isTruncated
						opacity={0.9}
					>
						{row?.groupByVal}
					</Heading>
				</Stack>
			</HStack>
			<HStack align="center" justify="center" spacing={8} px={4}>
				{FORM_STATUSES.map((formStatus, i) => (
					<Stack
						// key={row.id + "-" + formStatus.status + "-" + i + "-" + statuses[formStatus.status]?.count ?? 0}
						spacing={2}
						justify="flex-start"
						w="full"
						opacity={(statuses[formStatus.status]?.count ?? 0) > 0 ? 1 : 0.5}
					>
						<Heading
							letterSpacing={1}
							fontSize={row?.depth === 0 ? "2xs" : "4xs"}
							color="gray.500"
							textShadow="2px 2px 2px white"
							textTransform="uppercase"
							opacity={0.9}
							isTruncated
						>
							{formStatus.status}
						</Heading>
						<HStack spacing={3} justify="flex-start" w="full" key={formStatus.icon + " fa-fw + fa-lg"}>
							<Heading
								key={row.id + "-" + formStatus.status + "-" + i + "-" + formStatus?.color ?? "gray"}
								fontSize="3xl"
								size={row?.depth === 0 ? "3xl" : "lg"}
								color={
									(statuses[formStatus.status]?.count ?? 0) > 0
										? formStatus.color + ".500"
										: "gray.400"
								}
								className={formStatus.icon + " fa-fw fa-lg"}
							/>
							<Heading
								w="full"
								textAlign={"center"}
								letterSpacing={1}
								size={row?.depth === 0 ? "lg" : "md"}
								color="gray.600"
								textShadow="2px 2px 2px white"
								textTransform="uppercase"
								isTruncated
								opacity={0.9}
							>
								{statuses[formStatus.status]?.count ?? 0}
							</Heading>
						</HStack>
					</Stack>
				))}
			</HStack>
			<Stack spacing={1} justify="center" align="center" px={3}>
				<Heading
					letterSpacing={1}
					fontSize={row?.depth === 0 ? "2xs" : "3xs"}
					color="gray.500"
					textShadow="2px 2px 2px white"
					textTransform="uppercase"
					opacity={0.9}
				>
					Total
				</Heading>
				<HStack spacing={3} justify="center">
					<Heading
						letterSpacing={1}
						size={row?.depth === 0 ? "lg" : "md"}
						color="gray.600"
						textShadow="2px 2px 2px white"
						textTransform="uppercase"
						isTruncated
						opacity={0.9}
					>
						{row.leafRows?.length}
					</Heading>
				</HStack>
			</Stack>
		</>
	);
}
