import User from "../../Employee/classes/User";
import SchedulesAPI from "../../../APIs/SchedulesAPI";

import { log } from "../../../helperFunctions";
import { v4 } from "uuid";

export default class DFTeam extends User {
	constructor(team, user) {
		super(user);

		if (team instanceof DFTeam && !Boolean(user)) {
			Object.assign(this, team);
		} else {
			this.queryData = team?.queryData ?? "DFTeam";
			this.schedulesAPI = new SchedulesAPI();
			this.uid = team?.uid ?? v4();
			this.id = team?.id ?? null;

			this.teamID = team?.teamID?.toString() ?? null;
			this.jobID = team?.jobID?.toString() ?? null;

			this.msUserID = team?.msUserID ?? null;
			this.employeeUID = team?.employeeUID ?? null;
			this.name = team?.name ?? null;
			this.sendEmail = team?.sendEmail ?? 0;

			this.respRoleID = team?.respRoleID?.toString() ?? null;
			this.respRole = team?.respRole?.toString() ?? null;

			this.sendEmails = team?.sendEmails ?? team?.sendEmails ?? "0";
		}
	}

	initUser(user = new User()) {
		let team = new DFTeam(this, user);
		Object.assign(this, team);
	}

	async insertDB() {
		if (!Boolean(this.id)) {
			let data = (({ jobID, employeeUID, respRoleID, sendEmail }) => ({
				jobID,
				employeeUID,
				respRoleID,
				sendEmail,
			}))(this);

			let result = await this.schedulesAPI.PortalUser_DFData_InsertTeam(data);
			if (result?.status === 200 && result?.value?.length > 0) {
				result = new DFTeam(result?.value[0]);
				Object.assign(this, result);
			}
			log("Database Result (insert team)", result);
			return result;
		}
	}

	async updateDB() {
		//TODO: add multiple team members at once
		if (Boolean(this.id)) {
			let data = (({ sendEmail }) => ({
				sendEmail,
			}))(this);
			let result = await this.schedulesAPI.PortalUser_DFData_UpdateTeam(this.id, data);
			log("Database Result (update team)", result);
			return result;
		}
	}

	async deleteDB() {
		if (Boolean(this.id)) {
			let result = await this.schedulesAPI.PortalUser_DFData_DeleteTeam(this.id);
			log("Database Result (DELETE team)", result);
			return result;
		}
	}

	// getName(firstName = this.firstName, lastName = this.lastName, middleName = null, display = "firstLast") {
	// 	if (display === "firstLast" && Boolean(firstName) && Boolean(lastName)) {
	// 		return firstName + " " + lastName;
	// 	} else if (display === "lastFirst" && Boolean(firstName) && Boolean(lastName)) {
	// 		return lastName + " ," + firstName + (Boolean(middleName) && " " + middleName);
	// 	} else {
	// 		return null;
	// 	}
	// }

	// getRespRole(respRole = this.respRole) {
	// 	if (Boolean(respRole)) {
	// 		respRole = respRole?.split(";")[0];
	// 		respRole = respRole?.replace("-", " ");
	// 		let roleID = respRole;
	// 		if (roleID?.substring(0, 3) === "TAB") {
	// 			roleID = roleID?.toLowerCase();
	// 		} else if (roleID?.substring(0, 2) === "QC") {
	// 			roleID = roleID?.charAt(0)?.toLowerCase() + roleID?.charAt(1)?.toLowerCase() + roleID?.slice(2);
	// 		} else {
	// 			roleID = roleID?.charAt(0)?.toLowerCase() + roleID?.slice(1);
	// 		}
	// 		roleID = roleID.replace(" ", "");
	// 		respRole = respRole?.replace("SheetMetal", "Sheet Metal");
	// 		respRole = respRole?.replace("StartUp", "Start Up");
	// 		return { roleID, respRole };
	// 	} else {
	// 		return { roleID: null, respRole: null };
	// 	}
	// }

	// async syncDB(updates) {
	// 	let updateRes = {} //await DFDataApi.UpdateTeamMember(this.id, updates);
	// 	if (updateRes.status === 200) {
	// 		return true;
	// 	} else {
	// 		return false;
	// 	}
	// }

	// async delete() {
	// 	let deleteRes = {} //await DFDataApi.DeleteTeamMember(this.id);
	// 	if (deleteRes.status === 200) {
	// 		return true;
	// 	} else {
	// 		return false;
	// 	}
	// }

	// async enableEmailNotifications() {
	// 	this.emailNotificationEnabled = true;
	// 	let update = { EmailNotificationEnabled: 1 };
	// 	this.syncDB(update);
	// }

	// async disableEmailNotifications() {
	// 	this.emailNotificationEnabled = false;
	// 	let update = { EmailNotificationEnabled: 0 };
	// 	this.syncDB(update);
	// }

	// async toggleEmailNotifications() {
	// 	this.emailNotificationEnabled = !Boolean(this.emailNotificationEnabled);
	// 	let update = { EmailNotificationEnabled: this.emailNotificationEnabled ? 1 : 0 };
	// 	this.syncDB(update);
	// }

	// async enablePortalNotifications() {
	// 	this.portalNotificationEnabled = true;
	// 	let update = { PortalNotificationEnabled: 1 };
	// 	this.syncDB(update);
	// }

	// async disablePortalNotifications() {
	// 	this.portalNotificationEnabled = false;
	// 	let update = { PortalNotificationEnabled: 0 };
	// 	this.syncDB(update);
	// }

	// async togglePortalNotifications() {
	// 	this.portalNotificationEnabled = !Boolean(this.portalNotificationEnabled);
	// 	let update = { PortalNotificationEnabled: this.portalNotificationEnabled ? 1 : 0 };
	// 	this.syncDB(update);
	// }

	// async updateRole(roleVal) {
	// 	this.role = roleVal;
	// 	let update = { Role: roleVal };
	// 	this.syncDB(update);
	// }
}
