import React from "react";
import { Stack, Text } from "@chakra-ui/react";
import DFScheduleButtons from "../components/DFScheduleButtons";

import { portalUser } from "../../../App";
import { convertArrayToMap } from "../../../helperFunctions";

export default class PageTemplateDFSchedule {
	constructor(jobs = [], params = {}, page = {}, selectJob) {
		this.pageTemplate = "D&FSchedule";
		this.pageName = page?.pageName ?? "Detailing & Fab Schedule (D&F)";
		this.pageTitle = page?.pageTitle ?? "D&F Schedule";
		this.size = "A2";
		this.orientation = "landscape";
		this.region = this.getRegion();

		this.pageBody = this.getSections(jobs, params, selectJob);
	}

	getSections(jobs = [], params = {}, selectJob) {
		let pageBody = [];
		if (jobs?.length > 0) {
			for (let i = 0; i < jobs?.length; i++) {
				if (jobs[i]?.isArchived === "0") {
					let section = new SectionTemplateDFSchedule(jobs[i], params, this, selectJob);
					section.pagebreak = i;
					pageBody.push(section);
				}
			}
		} else {
			pageBody.push(new SectionTemplateDFSchedule({}, params, this, selectJob));
		}
		return pageBody;
	}

	getRegion(jobs = [], params = {}) {
		let regionID = portalUser.user?.regionID ?? "0";
		if (Boolean(params?.regionID)) {
			regionID = params?.regionID;
		} else if (Boolean(jobs?.length > 0)) {
			regionID = jobs[0]?.regionID ?? "0";
		}
		return portalUser?.getRegions()?.find((p) => p.regionID === regionID)[0];
	}
}

export class SectionTemplateDFSchedule {
	constructor(job = {}, params = [], page = {}, selectJob) {
		this.template = "D&FSchedule";
		this.name = page?.pageName ?? "Detailing & Fab Schedule (D&F)";
		this.heading = page?.pageTitle ?? "D&F Schedule";

		this.type = "virtualTable";
		this.rowHeight = 60;
		this.maxRows = 15;
		this.overflowX = "auto";
		this.maxWidth = "175%";
		this.flexWidth = "max-content";

		this.isCollapsable = true;

		this.template = "D&FSchedule";
		this.name = "D&F Schedule";
		this.heading = Boolean(job?.jobID) ? job?.job + " - " + job?.jobName : null;
		this.subheading = Boolean(job?.jobID) ? job?.contract + " " + job?.contractName + " - " + job?.scopeDesc : null;

		this.subSections = this.getSubSections(job, selectJob);
	}

	getSubSections(job = {}, selectJob) {
		let subSections = [new SubSectionTemplateDFSchedule(job, selectJob)];
		return subSections;
	}
}

class SubSectionTemplateDFSchedule {
	constructor(job = {}, selectJob) {
		this.template = "D&FSchedule";
		this.isCollapsable = true;
		this.heading = null;
		this.subheading = null;

		let firstColumns = [
			{
				accessor: "seqOrder",
				Header: "Order",
				valueType: "integer",
				textAlign: "center",
				width: "65%",
				textSize: "center",
				show: false,
			},
			{
				accessor: "seq",
				Header: "Seq",
				valueType: "integer",
				textAlign: "center",
				width: "50%",
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "cpmSchedID",
				Header: "CPM Sched ID#",
				width: "75%",
				formInput: "text",
				placeholder: "N/A",
			},
			{
				accessor: "shopDrawings",
				Header: "Shop Drawings",
				formInput: "text",
				placeholder: "N/A",
			},
			{
				accessor: "pipingLevel",
				Header: "Piping Level",
				formInput: "text",
				placeholder: "N/A",
			},
			{
				accessor: "deckingLevel",
				Header: "Decking Level",
				formInput: "text",
				placeholder: "N/A",
				show: Boolean(job?.scopeDesc === "Decking"),
			},
			{
				accessor: "area",
				Header: "Area",
				formInput: "text",
				placeholder: "N/A",
			},
			{
				accessor: "quad",
				Header: "Quad",
				formInput: "text",
				placeholder: "N/A",
			},
			{
				accessor: "system",
				Header: "System",
				width: "135%",
				formInput: "text",
				placeholder: "N/A",
			},
		];

		let workflowColumns = this.getWorkflowColumns(job);

		let lastColumns = [
			{
				accessor: "installStart",
				Header:
					job?.scopeDesc === "Decking"
						? "Trimble Date on Deck"
						: job?.scopeID === "13"
						? "Finish Date"
						: "Install Start",
				valueType: "date",
				textAlign: "center",
				formInput: "date",
			},
			{
				accessor: "comments",
				Header: "Comments",
				width: "250%",
				formInput: "text",
				placeHolder: "N/A",
			},
			{
				accessor: "isDeleted",
				Header: "Delete Task",
				width: "65%",
				textAlign: "center",
				formInput: "deleteButton",
				filter: "select",
			},
		];

		let columns = [...firstColumns, ...workflowColumns, ...lastColumns];

		if (
			(["2", "3", "6"].includes(job?.scopeID) && job?.workflowID === "-8") ||
			(["3"].includes(job?.scopeID) && job?.workflowID === "9") ||
			(["5"].includes(job?.scopeID) && job?.workflowID === "-5") ||
			(["4"].includes(job?.scopeID) && job?.workflowID === "-4") ||
			(["13"].includes(job?.scopeID) && job?.workflowID === "13")
		) {
			// Find the index of the object where accessor === 'installStart'
			let index = columns.findIndex((obj) => obj.accessor === "installStart");

			// Swap only if the object is not the first one
			if (index > 0) {
				[columns[index], columns[index - 1]] = [columns[index - 1], columns[index]];
			}
		}

		this.columns = columns;
		this.data = job?.sequences ?? [];

		this.sortBy = [{ id: "seqOrder", desc: false }];
		this.filterBy = [{ id: "isDeleted", value: "0" }];

		this.buttons = <DFScheduleButtons dfJob={job} selectJob={selectJob} />;
	}

	getWorkflowColumns(job = {}) {
		let workflowColumns = [];
		let workflows = job?.workflows ?? [];

		let respRoleMap = convertArrayToMap(job?.respRoles, "respRoleID");
		let teamMap = convertArrayToMap(job?.team, "respRoleID");

		for (let i = 0; i < workflows?.length; i++) {
			let respRole = respRoleMap[workflows[i]?.respRoleID] ?? {};
			let team = teamMap[workflows[i]?.respRoleID] ?? [];
			let column = {
				accessor: workflows[i]?.accessor,
				Header: workflows[i]?.deliverableName,
				valueType: "date",
				textAlign: "center",
				tooltip: workflows[i]?.deliverableDesc,
				formInput: workflows[i]?.deliverableType,
				FloatHeader: <FloatHeaderCell respRole={respRole} team={team} />,
				show: (workflows[i]?.isVisible === "1" ? true : false) ?? true,
			};
			workflowColumns.push(column);
		}

		return workflowColumns;
	}

	getFooter(rows = [], totals = null) {
		let footer = [{ seq: rows.length }];
		return footer;
	}
}

function FloatHeaderCell({ respRole = {}, team = {} }) {
	return (
		<Stack textAlign="center" spacing={0} flex={1} w="full" px={2} justify="flex-end" align="flex-end">
			<Text fontSize="sm" fontWeight="bold" w="full" color="gray.600">
				{respRole?.roleAbbr}
			</Text>
			<Text fontSize="xs" w="full" color="gray.700" isTruncated>
				{team?.name ?? "Not Assigned"}
			</Text>
		</Stack>
	);
}
