import React, { useState, useMemo } from "react";
import { HStack, Flex, Stack } from "@chakra-ui/react";
import DataInput from "../../Inputs/data/DataInput";

import { portalUser } from "../../../App";

export default function ReportParameters({ document, setReportTemplate }) {
	const [reportTemplates, setReportTemplates] = useState([]);
	const [reportTemplate, setTemplate] = useState({});
	const [fileName, setFileName] = useState(null);
	const [parameters, setParameters] = useState([]);
	const isVisibleYN = "Y";
	// const [isVisibleYN, setIsVisibleYN] = useState("Y");

	useMemo(() => {
		let reportTemplates = portalUser?.getReportTemplates(null, isVisibleYN);
		let loadedTemplates = [...new Set(reportTemplates?.map((d) => d.reportTemplate))];
		let reportTemplate = Boolean(loadedTemplates.includes(document?.reportTemplate))
			? document?.reportTemplate
			: null;
		let fileName = Boolean(loadedTemplates.includes(document?.reportTemplate)) ? document?.fileName : null;
		let parameters = Boolean(loadedTemplates.includes(document?.reportTemplate)) ? document?.getParameters() : [];
		setReportTemplates(reportTemplates);
		setTemplate(reportTemplate);
		setFileName(fileName);
		setParameters(parameters);
	}, [document, isVisibleYN]);

	return (
		<Stack w="full">
			<HStack spacing={4}>
				<Flex>
					<DataInput
						type="select"
						variant="outline"
						placeholder="Select Report"
						label="Report Template"
						labelProps={{
							color: "whiteAlpha.900",
							textShadow: "1px 1px 2px var(--chakra-colors-gray-600)",
							fontSize: "lg",
							letterSpacing: 2,
						}}
						searchable
						value={reportTemplate}
						options={reportTemplates}
						optionValue="reportTemplate"
						optionText="reportName"
						onOptionSelect={setReportTemplate}
						textAlign="left"
						minW="xs"
						textTransform="uppercase"
						color="gray.600"
						inputProps={{
							bg: "whiteAlpha.500",
							fontWeight: "semibold",
							textTransform: "uppercase",
							color: "gray.500",
							borderWidth: "2px",
							letterSpacing: 1,
						}}
					/>
				</Flex>
				<Flex>
					<DataInput
						type="text"
						variant="outline"
						placeholder="File Name..."
						label="File Name"
						labelProps={{
							color: "whiteAlpha.900",
							textShadow: "1px 1px 2px var(--chakra-colors-gray-600)",
							fontSize: "lg",
							letterSpacing: 2,
							textTransform: "uppercase",
						}}
						textAlign="left"
						minW="sm"
						defaultValue={fileName}
						color="gray.600"
						inputProps={{
							bg: "whiteAlpha.500",
							fontWeight: "semibold",
							color: "gray.500",
							borderWidth: "2px",
							letterSpacing: 1,
						}}
					/>
				</Flex>
				{/* </HStack>
			<HStack spacing={4} w="full"> */}
				{parameters?.map((parameter, i) => {
					return (
						<Flex key={i}>
							<DataInput
								type={parameter?.inputType}
								variant="outline"
								placeholder={"Select " + parameter?.label}
								label={parameter?.label}
								labelProps={{
									color: "whiteAlpha.900",
									textShadow: "1px 1px 2px var(--chakra-colors-gray-600)",
									fontSize: "lg",
									letterSpacing: 2,
								}}
								searchable
								value={parameter?.value}
								options={parameter?.options}
								optionValue={parameter?.optionValue}
								optionText={parameter?.optionLabel}
								optionSubheading={parameter?.optionDesc}
								//onOptionSelect={setReportTemplate}

								minW={parameter?.minW}
								textTransform="uppercase"
								color="gray.600"
								inputProps={{
									bg: "whiteAlpha.500",
									fontWeight: "semibold",
									textTransform: "uppercase",
									color: "gray.500",
									borderWidth: "2px",
									letterSpacing: 1,
									textAlign: "left",
								}}
							/>
						</Flex>
					);
				})}
			</HStack>
		</Stack>
	);
}
