//ORDER ALPHABETICALLY
const DIVERSITY_SUB_TYPES = [
	{
		label: "SB-PW",
		value: "SB-PW",
		description: "Small Business PW",
	},
	{
		label: "SB Micro",
		value: "SB Micro",
		description: "Small Business Micro",
	},
];

export default DIVERSITY_SUB_TYPES;
