import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@chakra-ui/react";
// import { log } from "../../../helperFunctions";
import TextInputColumnFilter from "../../ReactTable/inputs/TextInputFilter";
import getFilterTypes from "../../ReactTable/functions/getFilterTypes";

import NotificationsVirtualList from "./NotificationsVirtualList";

export default function VirtualData({ subSection = null, renderRow = null, onClose }) {
	const [virtualData, setVirtualData] = useState(null);
	const [skipReset, setSkipReset] = useState(false);

	useMemo(() => {
		setVirtualData(subSection?.data ?? []);
	}, [subSection]);

	const columns = useMemo(() => {
		let columns = subSection?.columns?.filter((d) => Boolean(d.id)) ?? [];
		if (columns?.length === 0) {
			columns = [{ id: "id", accessor: "id", Header: "Column" }];
		}
		return columns;
	}, [subSection]);

	const initialState = useMemo(() => {
		let columnOrder = columns?.map((d) => d.id) ?? [];
		let hiddenColumns = columns?.filter((d) => !Boolean(d.show))?.map((d) => d.id) ?? [];
		let groupBy = subSection?.groupBy?.filter((d) => columnOrder?.includes(d)) ?? [];
		let sortBy = subSection?.sortBy?.filter((d) => columnOrder?.includes(d.id)) ?? [];
		let filterBy = subSection?.filterBy?.filter((d) => columnOrder?.includes(d.id)) ?? [];

		return { columnOrder, hiddenColumns, sortBy, groupBy, filters: filterBy };
	}, [columns, subSection?.filterBy, subSection?.groupBy, subSection?.sortBy]);

	const defaultColumn = useMemo(
		() => ({
			width: 150,
			Filter: TextInputColumnFilter,
			sortIcon: "alpha",
			show: true,
		}),
		[]
	);

	const filterTypes = getFilterTypes();

	const updateMyData = (rowIndex, columnId, value) => {
		setSkipReset(true);
		setVirtualData((old) =>
			old.map((row, index) => {
				if (index === rowIndex) {
					if (typeof row?.update === "function") {
						// log("Update function Exists", row);
						row.update(columnId, value);
					} else {
						// log("Update function Does not Exist", row);
						row[columnId] = value;
						return {
							...old[rowIndex],
							[columnId]: value,
						};
					}
				}
				return row;
			})
		);
	};

	useEffect(() => {
		setSkipReset(false);
	}, [virtualData]);

	return (
		<Box w="full" h="full" className="skinnyScroll" pr={2} pl={4} py={2} bg="blackAlpha.300">
			{Boolean(subSection) && (
				<NotificationsVirtualList
					data={virtualData}
					columns={columns}
					initialState={initialState}
					groupBy={subSection.groupBy}
					filterBy={subSection.filterBy}
					sortBy={subSection.sortBy}
					defaultColumn={defaultColumn}
					filterTypes={filterTypes}
					renderRow={renderRow}
					updateMyData={updateMyData}
					skipReset={skipReset}
					onClose={onClose}
				/>
			)}
		</Box>
	);
}
