import React from "react";
import {
	Box,
	Flex,
	Text,
	Heading,
	Link,
	Button,
	Icon,
	Collapse,
	Stack,
	HStack,
	useDisclosure,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverArrow,
	PopoverCloseButton,
	PopoverHeader,
	PopoverBody,
	StackDivider,
	Portal,
} from "@chakra-ui/react";
import BasicCard from "../../../core/Layout/cards/BasicCard";
import { motion } from "framer-motion";
import { GoMail } from "react-icons/go";
import ReferralsSVG from "./ReferralsSVG";
import { portalUser } from "../../../App";

export default function Referrals() {
	const { isOpen, onToggle } = useDisclosure();
	const positionsList = [
		{
			title: "Project Manager",
			link: "https://ppmechanical.egnyte.com/dl/RV8yBxKmYS",
		},
		{
			title: "Project Engineer",
			link: "https://ppmechanical.egnyte.com/dl/PZN1dkrXXg",
		},
	];

	return (
		<motion.div whileHover={{ scale: 1.01, transition: { duration: 0.5 } }}>
			<BasicCard title="Referrals" portalStyles={portalUser.styles}>
				<Box px="4">
					<Stack>
						<Flex align="center" pt="2" onClick={onToggle}>
							<ReferralsSVG />
							<Stack px="2" spacing="1">
								<Heading color="gray.500" letterSpacing={1} textTransform="uppercase" size="lg">
									Want $500? Refer a Friend!
								</Heading>
								<Text color="gray.500">
									Do you know someone who is Pan-tastic? Refer them for one of our open positions.
								</Text>
								<Box>
									<Popover isLazy>
										<PopoverTrigger>
											<Button
												bg="whiteAlpha.600"
												size="sm"
												color="teal"
												variant="outline"
												onClick={(e) => {
													e.stopPropagation();
												}}
											>
												View Open Positions
											</Button>
										</PopoverTrigger>
										<Portal>
											<PopoverContent>
												<PopoverHeader
													pt={3}
													onClick={(e) => {
														e.stopPropagation();
													}}
												>
													<Heading
														size="md"
														color="gray.600"
														onClick={(e) => {
															e.stopPropagation();
														}}
													>
														Open Positions
													</Heading>
												</PopoverHeader>
												<PopoverArrow />
												<Box
													onClick={(e) => {
														e.stopPropagation();
													}}
												>
													<PopoverCloseButton />
												</Box>
												<PopoverBody
													onClick={(e) => {
														e.stopPropagation();
													}}
												>
													<Stack
														divider={<StackDivider />}
														onClick={(e) => {
															e.stopPropagation();
														}}
													>
														{positionsList.map((position, i) => (
															<Box
																key={i}
																onClick={(e) => {
																	e.stopPropagation();
																}}
															>
																<Text
																	as="a"
																	href={position.link}
																	target="_blank"
																	color="gray.600"
																	_hover={{
																		cursor: "pointer",
																		textDecoration: "underline",
																	}}
																>
																	{position.title}
																</Text>
															</Box>
														))}
													</Stack>
												</PopoverBody>
											</PopoverContent>
										</Portal>
									</Popover>
								</Box>
							</Stack>
						</Flex>
						<Collapse in={isOpen} animateOpacity>
							<Box bg="white" p="4" rounded="10" border="1px" borderColor="blackAlpha.200">
								<Stack>
									<Heading color="gray.500" size="xs" textTransform="uppercase" fontWeight="semibold">
										Terms and Conditions:{" "}
									</Heading>
									<Box px="1">
										<Text as="i" color="gray.500">
											Referral must be full-time and employed for at least 90 days before bonus is
											paid out. Referral must not have been previously submitted by an outside
											agency or interviewed with Pan-Pacific within the last 12 months.
										</Text>
									</Box>
									<HStack
										px="1"
										fontSize="sm"
										align="center"
										spacing="3"
										_hover={{ color: "blue.500" }}
									>
										<Icon as={GoMail} color="gray.400" boxSize="25px" />
										<Link
											color="gray.500"
											textDecoration="underline"
											href={"mailto:careers@ppmechanical.com"}
										>
											careers@ppmechanical.com
										</Link>
									</HStack>
								</Stack>
							</Box>
						</Collapse>
					</Stack>
					<Button
						w="full"
						size="sm"
						pt="2"
						my="0"
						color="gray.500"
						variant="unstyled"
						_hover={{ textDecoration: "underline", color: "blue.600" }}
						onClick={onToggle}
					>
						{isOpen ? "Close" : "Learn More"}
					</Button>
				</Box>
			</BasicCard>
		</motion.div>
	);
}
