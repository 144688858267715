import React, { useMemo } from "react";
import {
	Box,
	Flex,
	Button,
	Text,
	Heading,
	Select,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	ModalCloseButton,
	Stack,
	HStack,
} from "@chakra-ui/react";
import DataInputSelectLegacy from "../common/DataInputSelectLegacy";
// import TimecardEntryHourInput from "./TimecardEntryHourInput";
import TimecardEntryHourInputSmallDevice from "./TimecardEntryHourInputSmallDevice";
import EARN_CODES from "../constants/EarnCodes";

import { formatDate, convertArrayToMap } from "../../../helperFunctions";
import { v4 } from "uuid";
import moment from "moment";

const earnCodeMap = convertArrayToMap(EARN_CODES, "value");

export default function TimecardEntryListRowModal(props) {
	const timecardJobs = useMemo(() => props.timecardJobs ?? null, [props.timecardJobs]);
	const timecardHeader = useMemo(() => props.timecardHeader ?? null, [props.timecardHeader]);
	const timecardRow = useMemo(() => props.timecardRow ?? null, [props.timecardRow]);

	return (
		<Modal
			isOpen={props.modalIsOpen}
			onClose={props.cancel}
			size={{ base: "full", md: "lg" }}
			blockScrollOnMount={true}
			scrollBehavior="inside"
		>
			<ModalOverlay />
			<ModalContent
				my={{ base: "0", sm: "auto" }}
				maxW={{ base: "100vw", sm: "md" }}
				minH={{ base: "100vh", sm: "auto" }}
				maxH={{ base: "100vh", sm: "auto" }}
			>
				<ModalHeader
					py={4}
					bg="teal.600"
					color="white"
					borderBottom="2px"
					borderBottomColor="blackAlpha.200"
					shadow="lg"
				>
					<Flex justify="space-between" align="center" w="full">
						<Heading size="lg" textTransform="uppercase">
							Timecard Entry
						</Heading>
						<ModalCloseButton variant="outline" />
					</Flex>
				</ModalHeader>
				<ModalBody className="skinnyScroll" bg="blackAlpha.50" py="4">
					<Box>
						<Heading py="2" color="gray.500" textTransform="uppercase" size="sm" letterSpacing={1}>
							Labor Type
						</Heading>
						<Stack
							color="gray.500"
							textTransform="uppercase"
							spacing={2}
							border="2px"
							borderColor="blackAlpha.200"
							px="4"
							pb="6"
							py="2"
							rounded="5"
							bg="white"
						>
							<HStack spacing={2} letterSpacing={1}>
								<Heading flex={1} size="sm">
									Type
								</Heading>
								<Flex w="full" flex={2} key={v4()}>
									<DataInputSelectLegacy
										dataView={"list"}
										name="earnCodes"
										placeholder="TYPE"
										selectOptions={EARN_CODES}
										value={earnCodeMap[timecardRow?.earnCode]?.key ?? "TYPE"}
										onChange={props.selectEarnCode}
										disabledItems={props.disabledEarnCodes}
										isDisabled={timecardHeader?.isApproved() || timecardRow.isLocked()}
										subtitle={"description"}
										w="full"
										isInvalid={timecardRow?.earnCode === null && timecardRow?.job !== null}
										noPortal={true}
									/>
								</Flex>
							</HStack>

							<HStack spacing={2}>
								<Heading flex={1} size="sm" letterSpacing={1}>
									JOB #
								</Heading>
								<Flex w="full" flex={2}>
									<DataInputSelectLegacy
										dataView={props.dataView}
										w="full"
										name="jobs"
										placeholder="JOB #"
										selectOptions={timecardJobs}
										value={timecardRow?.job ?? "JOB #"}
										onChange={props.selectJob}
										disabledItems={props.disabledJobs}
										isDisabled={timecardHeader?.isApproved() || timecardRow.isLocked()}
										isInvalid={timecardRow.earnCode !== null && timecardRow.job === null}
										noPortal={true}
									/>
								</Flex>
							</HStack>

							<HStack spacing={2}>
								<Heading flex={1} size="sm" letterSpacing={1}>
									Phase
								</Heading>
								<Flex w="full" flex={2}>
									<DataInputSelectLegacy
										dataView={props.dataView}
										w="full"
										name="phases"
										placeholder="PHASE"
										selectOptions={
											timecardJobs?.find(({ job }) => job === timecardRow?.job)?.phases
										}
										value={timecardRow?.phase ?? "PHASE"}
										onChange={props.selectPhase}
										preOpen={props.preOpen}
										closePreOpen={props.closePreOpen}
										loadData={props.loadData}
										disabledItems={props.disabledPhases}
										isDisabled={timecardHeader?.isApproved() || timecardRow.isLocked()}
										isInvalid={timecardRow?.job !== null && timecardRow?.phase === null}
										noPortal={true}
									/>
								</Flex>
							</HStack>

							<HStack spacing={2}>
								<Heading flex={1} size="sm" letterSpacing={1}>
									Shift
								</Heading>
								<Flex w="full" flex={2}>
									<Select
										size="md"
										focusBorderColor="teal.600"
										color={
											timecardHeader?.isApproved() || timecardRow?.isLocked()
												? "black!important"
												: !Boolean(timecardRow?.keyId)
												? "gray.400"
												: "gray.600"
										}
										_hover={{
											borderBottom: "2px",
											borderBottomColor: !(
												timecardHeader?.isApproved() || timecardRow?.isLocked()
											)
												? "teal.600"
												: "red.600",
										}}
										fontWeight="semibold"
										variant="flushed"
										defaultValue={timecardRow?.shift}
										isDisabled={timecardHeader?.isApproved() || timecardRow?.isLocked()}
										cursor={
											timecardHeader?.isApproved() || timecardRow?.isLocked() ? "not-allowed" : ""
										}
										onChange={props.selectShift}
									>
										<option value={1}>1</option>
										<option value={2}>2</option>
										{props?.employee?.craft === "DC16" && <option value={3}>3</option>}
									</Select>
								</Flex>
							</HStack>
						</Stack>

						<Stack color="gray.500" textTransform="uppercase" spacing={4}>
							<Stack spacing={2} pt="4">
								<Heading size="sm" letterSpacing={1}>
									Labor Hours
								</Heading>
								<Stack
									spacing={1}
									border="2px"
									borderColor="blackAlpha.200"
									p="2"
									rounded="5"
									pb="4"
									bg="white"
								>
									{timecardRow?.hours.map((hour, j) => (
										<Flex
											w="full"
											key={"hourInput-" + timecardRow?.keyId + "-" + j}
											px="4"
											align="center"
										>
											<Flex flex={1} justify="flex-start">
												<Heading
													letterSpacing={1}
													size="sm"
													color="gray.500"
													textTransform="uppercase"
												>
													{moment(formatDate(timecardRow?.prEndDate))
														.subtract(6 - j, "days")
														.format("ddd M/D")}
												</Heading>
											</Flex>
											<Flex flex={2} w="full" justify="center">
												<TimecardEntryHourInputSmallDevice
													hourIndex={j}
													item={timecardRow}
													hour={hour}
													onChange={props.updateHours}
													openNotes={props.openNotes}
													disabled={props.timecardHeader?.isApproved()}
												/>
											</Flex>
										</Flex>
									))}
								</Stack>
							</Stack>
						</Stack>
					</Box>
				</ModalBody>
				<ModalFooter bg="blackAlpha.300" borderTop="2px" borderTopColor="blackAlpha.400" shadow="lg">
					<HStack spacing="4" align>
						<Button size="md" pl="2" pr="3" colorScheme="blue" variant="solid" py={2} onClick={props.save}>
							<HStack spacing={1}>
								<i className="fas fa-save fa-fw fa-lg"></i>
								<Text textTransform="uppercase">SAVE</Text>
							</HStack>
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
