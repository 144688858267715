import PayrollAPI from "../../../APIs/PayrollAPI";

export default class TimecardHeaderClassLegacy {
	constructor(timecardHeader) {
		this.payrollAPI = new PayrollAPI();
		this.approvedDate1 = timecardHeader?.approvedDate1 ?? timecardHeader?.ApprovedDate1 ?? null;
		this.approvedDate2 = timecardHeader?.approvedDate2 ?? timecardHeader?.ApprovedDate2 ?? null;
		this.approverUID1 = (timecardHeader?.approverUID1 ?? timecardHeader?.ApproverUID1)?.toLowerCase() ?? null;
		this.approverUID2 = (timecardHeader?.approverUID2 ?? timecardHeader?.ApproverUID2)?.toLowerCase() ?? null;
		this.batchID = timecardHeader?.batchID ?? timecardHeader?.BatchID ?? null;
		this.deptID = timecardHeader?.deptID ?? timecardHeader?.DeptID ?? null;
		this.employee = timecardHeader?.employee ?? timecardHeader?.Employee ?? ""; // ?
		this.employeeUID = (timecardHeader?.employeeUID ?? timecardHeader?.EmployeeUID)?.toLowerCase() ?? null;
		this.keyId = timecardHeader?.KeyID ?? null;
		this.locked = timecardHeader?.locked ?? timecardHeader?.Locked ?? false;
		this.prco = timecardHeader?.prco ?? timecardHeader?.PRCo ?? null;
		this.prEndDate = timecardHeader?.prEndDate ?? timecardHeader?.PREndDate ?? null;
		this.prGroup = timecardHeader?.prGroup ?? timecardHeader?.PRGroup ?? null;
		this.submittedDate = timecardHeader?.submittedDate ?? timecardHeader?.SubmittedDate ?? null;
		this.submitterUID = (timecardHeader?.submitterUID ?? timecardHeader?.SubmitterUID)?.toLowerCase() ?? null;
		this.tradeID = timecardHeader?.tradeID ?? timecardHeader?.TradeID ?? 0;
		this.weekNotes = timecardHeader?.WeekNotes?.replace(/`/gm, "'") ?? null;
		this.uploadErrors = [];
		this.status = timecardHeader?.Status ?? null;
	}

	async updateTimecardInfo(updates) {
		let updateResult = await this.payrollAPI.UpdateTimecardHeader(this.keyId, updates);
		if (updateResult.status === 200) {
			return true;
		} else {
			return false;
		}
	}

	async updateNotes(value) {
		this.weekNotes = value;
		value = value.replace(/'/gm, "`");
		let update = { WeekNotes: value };
		let status = await this.updateTimecardInfo(update);
		return status;
	}

	async updateApproved(approverNum, portalUserId) {
		let updates = {};
		if (approverNum === 1) {
			if (this.approverUID1 === null) {
				this.approverUID1 = portalUserId;
				this.approvedDate1 = new Date().toLocaleDateString();
			} else {
				this.approverUID1 = null;
				this.approvedDate1 = null;
			}
			updates = { ApproverUID1: this.approverUID1, ApprovedDate1: this.approvedDate1 };
		} else if (approverNum === 2) {
			if (this.approverUID2 === null) {
				this.approverUID2 = portalUserId;
				this.approvedDate2 = new Date().toLocaleDateString();
			} else {
				this.approverUID2 = null;
				this.approvedDate2 = null;
			}
			updates = { ApproverUID2: this.approverUID2, ApprovedDate2: this.approvedDate2 };
		}
		let status = await this.updateTimecardInfo(updates);
		return status;
	}

	isApproved() {
		if (this.approverUID1 !== null || this.approverUID2 !== null) {
			return true;
		} else {
			return false;
		}
	}
}
