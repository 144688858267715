import React from "react";
import ExampleContainer from "../common/ExampleContainer";
import ContainerWidthsExample from "../components/ContainerWidthsExample";
import ContainerHeightsExample from "../components/ContainerHeightsExample";
import ThemeColorsExample from "../components/ThemeColorsExample";

export default function ContainerExamplesPage({ maxW = "full", example = "widths" }) {
	return (
		<ExampleContainer
			maxW={maxW}
			children={
				example === "widths" ? (
					<ContainerWidthsExample />
				) : example === "heights" ? (
					<ContainerHeightsExample />
				) : (
					<ThemeColorsExample />
				)
			}
		/>
	);
}
