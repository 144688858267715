import React, { useMemo } from "react";
import { Flex, Text } from "@chakra-ui/react";
import TableHeaderCell from "./TableHeaderCell";
import TableSizesCell from "./TableSizesCell";
import EditableCell from "./TableEditableCell";
import { formatValue, convertArrayToMap } from "../../../helperFunctions";

const cellStyles = [
	{
		rowType: "TableHeaderRow",
		borderRightColor: "transparent",
		textTransform: "uppercase",
	},
	{
		rowType: "TableSizesRow",
		borderRightColor: "gray.500",
		textTransform: "lowercase",
	},
	{
		rowType: "FloatHeaderRow",
		borderRightColor: "transparent",
		textTransform: "uppercase",
	},
	{
		rowType: "TableFilterRow",
		borderRightColor: "transparent",
		textTransform: "none",
	},
	{
		rowType: "TableFooterRow",
		borderRightColor: "transparent",
		textTransform: "uppercase",
	},
	{
		rowType: "TableBodyRow",
		borderRightColor: "gray.300",
	},
];
const cellStylesMap = convertArrayToMap(cellStyles, "rowType");

export default function TableCell({ rowType = "TableBodyRow", column, cell }) {
	const cellStyle = useMemo(
		() => (Boolean(cellStylesMap[rowType]) ? cellStylesMap[rowType] : cellStylesMap["TableBodyRow"]),
		[rowType]
	);

	const value = useMemo(() => {
		if (rowType === "TableHeaderRow") {
			return <TableHeaderCell column={column} />;
		} else if (rowType === "TableFilterRow") {
			return column?.render("Filter");
		} else if (rowType === "TableSizesRow") {
			return <TableSizesCell column={column} />;
		} else if (rowType === "FloatHeaderRow") {
			return column?.FloatHeader ?? null;
		} else if (rowType === "TableFooterRow") {
			return (
				<Text py={2} w="full" lineHeight={1.25}>
					{column?.render("Header")}
				</Text>
			);
		} else if (Boolean(column?.formInput)) {
			return <EditableCell value={cell?.value} row={cell?.row} column={column} />;
		} else if (Boolean(cell?.value)) {
			return (
				<Text w="full" lineHeight={1.25}>
					{formatValue(cell?.value, column?.decimals ?? 0, column?.valueType ?? "string")}
				</Text>
			);
		} else {
			return (
				<Text w="full" lineHeight={1.25} as={"i"} letterSpacing={1} color="gray.300" fontSize="sm">
					{column?.placholder ?? "N/A"}
				</Text>
			);
		}
	}, [cell, column, rowType]);

	return (
		<Flex
			direction="column"
			w={column?.width ?? "full"}
			h="inherit"
			justify="center"
			align="center"
			px={column?.formInput ? 0 : 2}
			textAlign={column?.textAlign ?? "left"}
			textTransform={column?.textTransform ?? cellStyle.textTransform}
			isTruncated
			bg={cell?.column?.bg ?? "transparent"}
			overflowWrap={"break-word"}
			whiteSpace={"break-spaces"}
			borderStyle="inset"
			borderRightWidth={1}
			borderRightColor={cellStyle.borderRightColor}
		>
			{value}
		</Flex>
	);
}
