import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Container, Stack } from "@chakra-ui/react";
import TabPanels from "../../../core/ReactTable/layout/TabPanels";
import OverlayPage from "../../../core/Layout/drawers/OverlayPage";
import HRPageHeader from "../components/HRPageHeader";
import FormViewerPage from "../../../core/Forms/pages/FormViewerPage";
import HRTemplateCards from "../components/HRTemplateCards";
import { log } from "../../../helperFunctions";
import { portalUser } from "../../../App";

export default function HRTemplatesPage({ tab = null, formData = null, isLoading = false, formUID }) {
	const panels = [
		{
			name: "2024",
			panel: "2024",
			heading: "HR Templates",
			subheading: "Form Templates 2024",
		},
		{
			name: "2023",
			panel: "2023",
			heading: "HR Templates",
			subheading: "Form Templates 2023",
		},
		{
			name: "2022",
			panel: "2022",
			heading: "HR Templates",
			subheading: "Form Templates 2022",
		},
		{
			name: "2021",
			panel: "2021",
			heading: "HR Templates",
			subheading: "Form Templates 2021",
		},
		{
			name: "2020",
			panel: "2020",
			heading: "HR Templates",
			subheading: "Form Templates 2020",
		},
	];

	const [panelVal, setPanelVal] = useState(0);

	const changePanel = useCallback((e) => {
		setPanelVal(e);
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);

	const [newFormData, setNewFormData] = useState(portalUser?.formData ?? null);
	let history = useHistory();
	const getFormData = useCallback(
		async (formUID = null) => {
			let newFormData = formData;
			if (Boolean(newFormData)) {
				newFormData = await newFormData?.getFormData(formUID);
			}
			log("newFormData", newFormData);
			setNewFormData(newFormData);

			window.scrollTo({ top: 0, behavior: "smooth" });
		},
		[formData]
	);

	const selectForm = useCallback(
		async (newUID = null) => {
			if (!Boolean(newUID) && Boolean(formUID)) {
				portalUser.formData.formUID = null;
				// log("they dont match match formUID: " + formUID + "   newUID: " + newUID);
				history.push(`/hr/templates`);
			} else if (Boolean(newUID) && newUID !== formUID) {
				portalUser.formData.formUID = newUID;
				// log("they dont match match formUID: " + formUID + "   newUID: " + newUID);
				history.push(`/hr/templates/${newUID}`);
			}

			await getFormData(newUID);
		},
		[formUID, getFormData, history]
	);

	return (
		<OverlayPage
			flex={1}
			bg={"gray.400"}
			isOpen={Boolean(newFormData?.forms?.length > 0) && (Boolean(formUID) || isLoading)} //Or isLoading so that Drawer Opens immediately FormUID is selected
			onClose={() => selectForm(null)}
			childComponent={<FormViewerPage formData={newFormData} isLoading={isLoading} selectForm={selectForm} />}
		>
			<Stack
				spacing={0}
				p={portalUser.styles?.pagePadding}
				bg={portalUser.styles?.pageBG}
				w="full"
				h="full"
				flex={1}
				align="center"
			>
				<HRPageHeader
					tab={tab}
					formData={formData}
					isLoading={isLoading}
					selectedYear={panels[panelVal]?.panel}
					selectForm={selectForm}
				/>
				<Container
					direction="column"
					display={"flex"}
					flex={1}
					justify="center"
					align="center"
					px={0}
					h="full"
					rounded="md"
					maxW="container.xl"
				>
					<TabPanels panels={panels} panelVal={panelVal} changePanel={changePanel}>
						<HRTemplateCards
							selectedYear={panels[panelVal]?.panel}
							templates={portalUser.formData?.forms?.filter((d) => d.templateUID === d.formUID) ?? []}
							selectForm={selectForm}
						/>
					</TabPanels>
				</Container>
			</Stack>
		</OverlayPage>
	);
}
