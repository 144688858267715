import React from "react";
import { Flex } from "@chakra-ui/react";

import IFrame from "../../core/Layout/modals/IFrame";

import MainContent from "../../legacy/App/components/MainContent";

export default function EmbedExcelFile({
	baseURL = "https://ppmechanical.sharepoint.com/sites/PPMDevOps/_layouts/15/Doc.aspx?sourcedoc={eafb2ec2-f63b-4e66-8d4f-6fc4a34dca86}&action=embedview&ActiveCell='Executed%20MSA'!A2&Item='Executed%20MSA'!Print_Area&wdHideGridlines=True&wdDownloadButton=True&wdInConfigurator=True&wdInConfigurator=True",
	// appID = "f464da40-32c3-4d29-9d63-45369d49612a",
}) {
	return (
		<MainContent title={"Master Subcontract Agreements"} subtitle={null}>
			<Flex bg="blackAlpha.500" direction="row" flex={1} justify="flex-start" h="full" py={4} px={4}>
				<IFrame variant={"powerApp"} url={baseURL} />
			</Flex>
		</MainContent>
	);
}
