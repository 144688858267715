import { formatDate } from "../../../helperFunctions";
// import moment from "moment";

export default class LongevityAward {
	constructor(event) {
		this.queryData = event?.queryData ?? event?.QueryData ?? "LongevityAward";
		this.employeeUID = event?.employeeUID?.toLowerCase() ?? event?.EmployeeUID?.toLowerCase() ?? null;
		this.origHireDate = formatDate(event?.origHireDate ?? event?.OriginalHireDate, true) ?? null;
		this.longevityMths = event?.longevityMths ?? event?.LongevityMths ?? 0;
		this.longevityYears = event?.longevityYears ?? event?.longevityYears ?? 0;
		this.prevLongevity = event?.prevLongevity ?? event?.PreviousLongevity ?? 0;
		this.lastAward = event?.lastAward ?? event?.lastAward ?? null;
		this.lastAwardYear = event?.lastAwardYear ?? event?.lastAwardYear ?? null;
		this.awardYear5 = event?.awardYear5 ?? event?.AwardYear5 ?? null;
		this.awardYear7 = event?.awardYear7 ?? event?.AwardYear7 ?? null;
		this.awardYear10 = event?.awardYear10 ?? event?.AwardYear10 ?? null;
		this.awardYear15 = event?.awardYear15 ?? event?.AwardYear15 ?? null;
		this.awardYear20 = event?.awardYear20 ?? event?.AwardYear20 ?? null;
		this.awardYear25 = event?.awardYear25 ?? event?.AwardYear25 ?? null;
		this.awardYear30 = event?.awardYear30 ?? event?.AwardYear30 ?? null;
		this.awardYear35 = event?.awardYear35 ?? event?.AwardYear35 ?? null;
		this.awardYear40 = event?.awardYear40 ?? event?.AwardYear40 ?? null;
		this.awardYear45 = event?.awardYear45 ?? event?.AwardYear45 ?? null;
		this.awardYear50 = event?.awardYear50 ?? event?.AwardYear50 ?? null;
		this.awardYear55 = event?.awardYear55 ?? event?.AwardYear55 ?? null;
		this.awardYear60 = event?.awardYear60 ?? event?.AwardYear60 ?? null;
		this.awardYear65 = event?.awardYear65 ?? event?.AwardYear65 ?? null;
		this.awardYear70 = event?.awardYear70 ?? event?.AwardYear70 ?? null;
		this.editable = true;
	}

	getYearsAndMonths() {
		let longevityMths = this.longevityMths;
		let years = 0;
		let months = 0;
		if (longevityMths >= 12) {
			let longevityYears = longevityMths / 12;
			years = parseInt(longevityYears);
			months = parseInt((longevityYears - parseFloat(years)) * 12);
		} else {
			months = longevityMths;
		}
		let description = "";
		if (years > 0) {
			description += years + " year" + (years > 1 ? "s" : "");
		}
		if (years > 0 && months > 0) {
			description += ", ";
		}
		if (months > 0) {
			description += months + " mth" + (months > 1 ? "s" : "");
		}

		return description;
	}
}
