import React, { useEffect, useRef, useMemo } from "react";
import * as d3 from "d3";
import { Stack, Flex, Divider, Text } from "@chakra-ui/react";
import NUMERATOR from "../Constants/Numerator";
import DENOMINATOR from "../Constants/Denominator";
import DataInput from "../../../../../core/Inputs/data/DataInput";
import { formatValue, convertArrayToMap, getSubtotal, log } from "../../../../../helperFunctions";
const spacing = { vertSpacing: 2, horzSpacing: 2, leftFlex: 4, rightFlex: 2 };

const PieChart = ({
	data = [],
	diversityVendors,
	diversityReqs,
	numerator,
	setNumerator,
	denominator,
	setDenominator,
	cost,
	amount,
}) => {
	const diversityVendorMap = useMemo(() => {
		let selectedVendors = convertArrayToMap(diversityVendors, "divCertType", true);
		return selectedVendors;
	}, [diversityVendors]);

	//PIE CHART
	const svgRef = useRef();

	// In the PieChart component, update how we get the combined certifications
	const transformData = useMemo(() => {
		// If no data, return array with single "Other" section
		if (!data || !Array.isArray(data) || data.length === 0) {
			return [
				{
					label: "Other",
					value: amount,
					targetPercent: "N/A",
					progressPercent: "N/A",
					piePercent: "100%",
					requiredAmount: amount,
					spentAmount: amount,
					originalTypes: ["Other"],
				},
			];
		}

		// Group requirements by certification types
		const groupedData = data.reduce((acc, curr) => {
			// Create a key based on the types array for grouping
			const types = Array.isArray(curr.divCertType) ? curr.divCertType : [curr.divCertType];
			const key = types.sort().join("-"); // Sort to ensure consistent ordering

			if (!acc[key]) {
				// Calculate totals for this group of certifications
				const spentAmount = types.reduce((total, certType) => {
					const vendorsForType = diversityVendorMap[certType] || [];
					const actualCost = getSubtotal(vendorsForType, "actualCost", "sum", true);
					const committedCost = getSubtotal(vendorsForType, "committedCost", "sum", true);
					return total + (numerator === "actualCost" ? actualCost : actualCost + committedCost);
				}, 0);
				// Find matching requirement
				const matchingReq = diversityReqs?.find((req) => {
					const reqTypes = Array.isArray(req.divCertType) ? req.divCertType : [req.divCertType];
					return reqTypes.some((type) => types.includes(type));
				});

				acc[key] = {
					types: types,
					requiredAmount: matchingReq ? matchingReq.divReqPct * amount : 0,
					spentAmount: spentAmount,
				};
			}
			return acc;
		}, {});

		const transformedData = Object.entries(groupedData).map(([key, group]) => {
			const progressPercentage = ((group.spentAmount / group.requiredAmount) * 100).toFixed(1);
			const piePercentage = formatValue((group.spentAmount / amount) * 100, 2, "percent");

			return {
				label: group.types
					.map((certType) => {
						// If the cert type is SBE and there's a divCertSubType in the matching requirement
						if (certType === "SBE") {
							const matchingReq = diversityReqs?.find((req) => {
								const reqTypes = Array.isArray(req.divCertType) ? req.divCertType : [req.divCertType];
								return reqTypes.includes(certType);
							});
							// Return the subtype if it exists, otherwise return SBE
							return matchingReq?.divCertSubType || certType;
						}
						return certType;
					})
					.join(", "),
				value: group.spentAmount,
				targetPercent: ((group.requiredAmount / amount) * 100).toFixed(1),
				progressPercent: progressPercentage,
				piePercent: piePercentage,
				requiredAmount: group.requiredAmount,
				spentAmount: group.spentAmount,
				originalTypes: group.types,
			};
		});

		// Add "Other" section if total spent is less than amount
		const totalSpent = transformedData.reduce((sum, item) => sum + item.spentAmount, 0);
		if (totalSpent < amount) {
			const otherAmount = amount - totalSpent;
			transformedData.push({
				label: "Other",
				value: otherAmount,
				targetPercent: "N/A",
				progressPercent: "N/A",
				piePercent: formatValue((otherAmount / amount) * 100, 2, "percent"),
				requiredAmount: otherAmount,
				spentAmount: otherAmount,
				originalTypes: ["Other"],
			});
		}

		return transformedData;
	}, [amount, data, diversityReqs, diversityVendorMap, numerator]);

	useEffect(() => {
		const margin = { top: 20, right: 120, bottom: 20, left: 120 };
		const width = 500;
		const height = 400;
		const radius = Math.min(width - margin.left - margin.right, height - margin.top - margin.bottom) / 2;

		const color = d3.scaleOrdinal(d3.schemeCategory10);
		const pie = d3.pie().value((d) => d.value);
		const arc = d3.arc().innerRadius(0).outerRadius(radius);

		const outerArc = d3
			.arc()
			.innerRadius(radius * 0.8)
			.outerRadius(radius * 0.8);

		// Clear any existing SVG content
		d3.select(svgRef.current).selectAll("*").remove();

		const tooltip = d3
			.select("body")
			.append("div")
			.attr("class", "tooltip")
			.style("position", "absolute")
			.style("padding", "5px")
			.style("background-color", "rgba(0, 0, 0, 0.7)")
			.style("color", "#fff")
			.style("border-radius", "5px")
			.style("opacity", 0)
			.style("pointer-events", "none");

		const svg = d3
			.select(svgRef.current)
			.attr("width", width)
			.attr("height", height)
			.append("g")
			.attr("transform", `translate(${width / 2}, ${height / 2})`);

		function midAngle(d) {
			return d.startAngle + (d.endAngle - d.startAngle) / 2;
		}

		// Calculate label positions
		const labelLayout = pie(transformData).map((d) => {
			const pos = outerArc.centroid(d);
			const midAngl = midAngle(d);
			const x = radius * 1.2 * (midAngl < Math.PI ? 1 : -1);
			const y = pos[1];
			return {
				...d,
				labelX: x,
				labelY: y,
				anchor: midAngl < Math.PI ? "start" : "end",
			};
		});

		// Adjust vertical positions
		labelLayout.forEach((d, i) => {
			if (i > 0) {
				let prev = labelLayout[i - 1];
				let curr = labelLayout[i];
				if (
					Math.abs(prev.labelY - curr.labelY) < 20 &&
					((prev.labelX > 0 && curr.labelX > 0) || (prev.labelX < 0 && curr.labelX < 0))
				) {
					curr.labelY = prev.labelY + 20;
				}
			}
		});

		// Create pie slices
		const paths = svg
			.selectAll("path")
			.data(pie(transformData))
			.enter()
			.append("path")
			.attr("d", arc)
			.style("fill", (d, i) => color(i));

		// Add polylines
		const polylines = svg
			.selectAll("polyline")
			.data(labelLayout)
			.enter()
			.append("polyline")
			.style("fill", "none")
			.style("stroke", "black")
			.attr("points", (d) => {
				const pos = outerArc.centroid(d);
				const x = d.labelX;
				return [arc.centroid(d), pos, [x, d.labelY]];
			});

		// Add labels
		const labels = svg
			.selectAll(".label")
			.data(labelLayout)
			.enter()
			.append("text")
			.attr("class", "label")
			.attr("dy", ".35em")
			.html((d) => `${d.data.label} (${d.data.piePercent})`)
			.attr("transform", (d) => `translate(${d.labelX},${d.labelY})`)
			.style("text-anchor", (d) => d.anchor)
			.style("font-size", "12px")
			.style("font-weight", "bold")
			.style("cursor", "pointer");

		// Function to handle hover effects
		function handleMouseOver(event, d) {
			// For consistency, ensure we're using the same data structure
			// whether coming from path or label
			const data = d.data ? d : { startAngle: d.startAngle, endAngle: d.endAngle };

			// Highlight the corresponding path and dim others
			paths
				.filter((p) => {
					return !(p.startAngle === data.startAngle && p.endAngle === data.endAngle);
				})
				.transition()
				.duration(200)
				.style("opacity", 0.8);

			// Bold the corresponding label and line
			labels
				.filter((l) => l.startAngle === data.startAngle && l.endAngle === data.endAngle)
				.transition()
				.duration(200)
				.style("font-weight", "900");

			polylines
				.filter((l) => l.startAngle === data.startAngle && l.endAngle === data.endAngle)
				.transition()
				.duration(200)
				.style("stroke-width", "2");

			// Show tooltip
			tooltip.transition().duration(200).style("opacity", 1);

			const tooltipData = d.data || d;
			const tooltipContent =
				tooltipData.label === "Other"
					? `${tooltipData.label}<br/>
                   Amount: ${formatValue(tooltipData.spentAmount, 2, "currency")}<br/>
                   Percentage of Total: ${tooltipData.piePercent}`
					: `${tooltipData.label}<br/>
                   Target Requirement: ${formatValue(tooltipData.targetPercent, 2, "percent")}<br/>
                   Amount Spent: ${formatValue(tooltipData.spentAmount, 2, "currency")}<br/>
                   Amount Required: ${formatValue(tooltipData.requiredAmount, 2, "currency")}<br/>
                   Progress: ${formatValue(tooltipData.progressPercent, 2, "percent")}<br/>
                   Percentage of Total: ${formatValue(
						(tooltipData.spentAmount / parseFloat(amount)) * 100,
						2,
						"percent"
					)}`;

			tooltip
				.html(tooltipContent)
				.style("left", `${event.pageX + 10}px`)
				.style("top", `${event.pageY - 10}px`);
		}

		function handleMouseOut(event, d) {
			// For consistency, ensure we're using the same data structure
			const data = d.data ? d : { startAngle: d.startAngle, endAngle: d.endAngle };

			// Restore all paths opacity
			paths.transition().duration(200).style("opacity", 1);

			// Restore label and line weight
			labels
				.filter((l) => l.startAngle === data.startAngle && l.endAngle === data.endAngle)
				.transition()
				.duration(200)
				.style("font-weight", "bold");

			polylines
				.filter((l) => l.startAngle === data.startAngle && l.endAngle === data.endAngle)
				.transition()
				.duration(200)
				.style("stroke-width", "1");

			// Hide tooltip
			tooltip.transition().duration(200).style("opacity", 0);
		}

		// Add hover events to both paths and labels
		paths.on("mouseover", handleMouseOver).on("mouseout", handleMouseOut);

		labels.on("mouseover", handleMouseOver).on("mouseout", handleMouseOut);

		return () => {
			tooltip.remove();
		};
	}, [amount, cost, transformData, numerator]);

	return (
		<Flex>
			<Flex flex="1" align="center" justify="center">
				<Stack align="center">
					<Text fontWeight="bold">Spent To Date</Text>
					<svg ref={svgRef}></svg>
				</Stack>
			</Flex>
			<Stack flex="1" justify="center" p={4}>
				<Flex justify="center">
					<Text fontWeight="bold">Calculation Options</Text>
				</Flex>
				<Stack>
					<Flex justify="center" align="center">
						<DataInput
							key="numerator"
							name="numerator"
							type="select"
							searchable
							options={NUMERATOR}
							value={numerator}
							optionValue="value"
							optionText="title"
							optionSubheading="tooltip"
							flex={spacing.leftFlex}
							variant="outline"
							isModal
							onOptionSelect={setNumerator}
						/>
						<Text fontWeight="bold" pl="20">
							{formatValue(cost, 2, "currency")}
						</Text>
					</Flex>
					<Divider borderColor="teal.500" borderWidth="2px" />
					<Flex justify="center" align="center">
						<DataInput
							key="denominator"
							name="denominator"
							type="select"
							searchable
							options={DENOMINATOR}
							value={denominator}
							optionValue="value"
							optionText="title"
							optionSubheading="tooltip"
							flex={spacing.leftFlex}
							variant="outline"
							isModal
							onOptionSelect={setDenominator}
						/>
						<Text fontWeight="bold" pl="20">
							{formatValue(amount, 2, "currency")}
						</Text>
					</Flex>
				</Stack>
			</Stack>
		</Flex>
	);
};

export default PieChart;
