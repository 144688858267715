export default class Trade {
	constructor(combobox) {
		this.queryData = combobox?.queryData ?? combobox?.QueryData ?? "Trade";
		this.tradeID = combobox?.tradeID ?? combobox?.TradeID ?? null;
		this.trade = combobox?.trade ?? combobox?.Trade ?? null;
		this.color = combobox?.color ?? combobox?.Color ?? "gray";
		this.parentTradeID = combobox?.parentTradeID ?? combobox?.ParentTradeID ?? null;
		this.getColors();
	}

	getColors() {
		let color = this.color;
		if (color === "gray") {
			this.color = color;
			this.fontColor = "var(--chakra-colors-" + this.color + "-600)";
			this.bg = "white";
			this.border = "var(--chakra-colors-" + color + "-400)";
		} else {
			this.fontColor = "var(--chakra-colors-" + this.color + "-400)";
			this.bg = color + "var(--chakra-colors-" + color + "-500)";
			this.border = color + "var(--chakra-colors-" + color + "-700)";
		}
	}
}
