import React, { useState } from "react";
import { Flex, Heading, Link, Icon, HStack, Image } from "@chakra-ui/react";
import BasicCard from "../../../core/Layout/cards/BasicCard";
import { motion } from "framer-motion";
import { portalUser } from "../../../App";

export default function SocialMedia() {
	const [index, setIndex] = useState(0);

	const socialMedia = [
		{
			name: "Instagram",
			icon: "fab fa-instagram-square",
			url: "https://www.instagram.com/ppmechanical/",
			image: "/images/SocialMedia_Instagram.png",
		},
		{
			name: "Facebook",
			icon: "fab fa-facebook-square",
			url: "https://www.facebook.com/ppmechanical",
			image: "/images/SocialMedia_Facebook.png",
		},
		{
			name: "LinkedIn",
			icon: "fab fa-linkedin",
			url: "https://www.linkedin.com/company/pan-pacific-mechanical/mycompany/",
			image: "/images/SocialMedia_LinkedIn.png",
		},
	];

	return (
		<motion.div whileHover={{ scale: 1.01, transition: { duration: 0.5 } }}>
			<BasicCard title="Social Media" portalStyles={portalUser.styles}>
				<Link isExternal href={socialMedia[0].url} minH="sm" hidden={index !== 0}>
					<Image
						border="1px"
						borderColor="blackAlpha.100"
						rounded="10"
						shadow="md"
						src={socialMedia[0].image}
						alt={socialMedia[0].name}
					/>
				</Link>
				<Link isExternal href={socialMedia[1].url} minH="sm" hidden={index !== 1}>
					<Image
						border="1px"
						borderColor="blackAlpha.100"
						rounded="10"
						shadow="md"
						src={socialMedia[1].image}
						alt={socialMedia[1].name}
					/>
				</Link>
				<Link isExternal href={socialMedia[2].url} minH="sm" hidden={index !== 2}>
					<Image
						border="1px"
						borderColor="blackAlpha.100"
						rounded="10"
						shadow="md"
						src={socialMedia[2].image}
						alt={socialMedia[2].name}
					/>
				</Link>

				<HStack fontSize="md" align="center" spacing="4" ml="1">
					<Flex justify="space-around" w="full" align="center" _hover={{ color: "blue.400" }}>
						{socialMedia.map((social, i) => (
							<Flex
								onMouseEnter={() => {
									setIndex(i);
								}}
								cursor="pointer"
								key={i}
							>
								<Link mt="4" href={social.url} isExternal color="blue.600">
									<HStack align="center">
										<Icon boxSize="30px">
											<i className={social.icon + " fa-lg fa-fw "} />
										</Icon>
										<Heading
											letterSpacing={1}
											textTransform="uppercase"
											size="xs"
											fontWeight="semibold"
										>
											{social.name}
										</Heading>
									</HStack>
								</Link>
							</Flex>
						))}
					</Flex>
				</HStack>
			</BasicCard>
		</motion.div>
	);
}
