import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import {
	Flex,
	Text,
	HStack,
	IconButton,
	Stack,
	Tag,
	TagLabel,
	useBoolean,
	Collapse,
	// Menu,
	// MenuList,
	// MenuButton,
	useColorMode,
	useColorModeValue as mode,
	Link,
} from "@chakra-ui/react";

import PPMLogo from "../../../core/Logos/components/PPMLogo";
import ColorModeButton from "./ColorModeButton";
// import { log } from "../../../helperFunctions";
// import { portalUser } from "../App";

export default function SideBar2({ children, sideBarIsOpen, setSideBarIsOpen, setRainbowMode }) {
	const { toggleColorMode } = useColorMode();

	var minW = 14;
	var maxW = sideBarIsOpen ? "2xs" : minW;

	var color = mode("whiteAlpha.800", "whiteAlpha.800");
	var bg = mode("teal.700", "gray.600");
	var bgGradient = mode("linear(to-r, blackAlpha.600, transparent)", "linear(to-r, blackAlpha.600, blackAlpha.100)");
	var text = mode("dark mode", "light mode");
	var hoverColor = mode("whiteAlpha.900", "white");
	var hoverBG = mode("teal.600", "whiteAlpha.400");

	// stylesHover = {
	// 	bg: "whiteAlpha.400",
	// 	color: "white",
	// };

	return (
		<Flex
			id="menuDrawer"
			h="100vh"
			display={"flex"}
			zIndex={1}
			minW={minW}
			w={maxW}
			maxW={maxW}
			color={color}
			bg={bg}
			bgGradient={bgGradient}
			direction="column"
			justify="space-between"
			overflowX="hidden"
		>
			<HStack px={2} py={2} align="flex-start" justify="flex-start" position={"relative"}>
				<PPMLogo
					color={color}
					h="12"
					maxW="3xs"
					viewBox={sideBarIsOpen ? "0 0 3374.4 816.52" : "-60 -60 816.52 816.52"}
				/>

				<IconButton
					position="absolute"
					top={1}
					right={1}
					key={sideBarIsOpen ? "fas fa-times" : "fas fa-bars"}
					onClick={setSideBarIsOpen.toggle}
					variant="ghost"
					cursor="pointer"
					size="xs"
					aria-label="Menu"
					color={color}
					bg="blackAlpha.100"
					_hover={{
						bg: "whiteAlpha.400",
						color: "white",
					}}
					icon={<i className={(sideBarIsOpen ? "fas fa-times" : "fas fa-bars") + " fa-fw"} />}
				/>
				{sideBarIsOpen && process.env.NODE_ENV === "development" && (
					<Tag
						position="absolute"
						bottom={0}
						right={1}
						size="sm"
						color="whiteAlpha.900"
						bg="whiteAlpha.600"
						px={1}
					>
						<TagLabel>
							<Text as="i" className="fas fa-code" /> DEV MODE
						</TagLabel>
					</Tag>
				)}
			</HStack>

			<Stack
				px={2}
				py={1}
				spacing={1}
				className="skinnyScroll"
				overflowY="auto"
				overflowX="hidden"
				flex={1}
				w="full"
				maxW="full"
			>
				{children}
			</Stack>

			<Stack spacing={1} py={2} px={2} fontWeight="semibold" textTransform="uppercase">
				<Flex
					id={"report-bug-link"}
					cursor="pointer"
					align="center"
					rounded={5}
					maxW="full"
					color={color}
					opacity={0.8}
					transition="all 0.2s"
					_hover={{
						textDecoration: "underline",
						opacity: 1,
						bg: hoverBG,
						color: hoverColor,
						textShadow: "0px 0px 0px transparent",
						borderColor: "blackAlpha.400",
					}}
					justify={!sideBarIsOpen ? "center" : "space-between"}
					fontWeight="semibold"
					textTransform="uppercase"
				>
					<Link isExternal href={reportBugLink}>
						<HStack align="center" justify="center" isTruncated spacing={1} w="full" flex={1}>
							<IconButton
								variant="unstyled"
								isTruncated
								icon={<i className={"fas fa-bug fa-lg fa-fw"} />}
							/>

							{sideBarIsOpen && (
								<Text maxW="full" w="full" letterSpacing={1} whiteSpace={"wrap"} fontSize={"sm"}>
									Report a Bug
								</Text>
							)}
						</HStack>
					</Link>
				</Flex>

				<Flex
					id={"submit-idea-link"}
					cursor="pointer"
					align="center"
					rounded={5}
					maxW="full"
					color={color}
					opacity={0.8}
					transition="all 0.2s"
					_hover={{
						textDecoration: "underline",
						opacity: 1,
						bg: hoverBG,
						color: hoverColor,
						textShadow: "0px 0px 0px transparent",
						borderColor: "blackAlpha.400",
					}}
					justify={!sideBarIsOpen ? "center" : "space-between"}
					fontWeight="semibold"
					textTransform="uppercase"
				>
					<Link isExternal href={submitIdeaLink}>
						<HStack align="center" justify="center" isTruncated spacing={1} w="full" flex={1}>
							<IconButton variant="unstyled" icon={<i className={"fas fa-lightbulb fa-lg fa-fw"} />} />

							{sideBarIsOpen && (
								<Text maxW="full" w="full" letterSpacing={1} whiteSpace={"wrap"} fontSize={"sm"}>
									Submit an Idea
								</Text>
							)}
						</HStack>
					</Link>
				</Flex>

				<Flex
					id={"dark-mode-button"}
					cursor="pointer"
					align="center"
					rounded={5}
					maxW="full"
					color={color}
					opacity={0.8}
					transition="all 0.2s"
					_hover={{
						textDecoration: "underline",
						opacity: 1,
						bg: hoverBG,
						color: hoverColor,
						textShadow: "0px 0px 0px transparent",
						borderColor: "blackAlpha.400",
					}}
					justify={!sideBarIsOpen ? "center" : "space-between"}
					fontWeight="semibold"
					textTransform="uppercase"
					onClick={toggleColorMode}
				>
					<HStack align="center" justify="center" isTruncated spacing={1} w="full" flex={1}>
						<ColorModeButton />

						{sideBarIsOpen && (
							<Text maxW="full" w="full" letterSpacing={1} whiteSpace={"wrap"} fontSize={"sm"}>
								{text}
							</Text>
						)}
					</HStack>
				</Flex>

				<Flex
					id={"rainbowMode-mode-button"}
					cursor="pointer"
					align="center"
					rounded={5}
					maxW="full"
					color={color}
					opacity={0.8}
					transition="all 0.2s"
					_hover={{
						textDecoration: "underline",
						opacity: 1,
						bg: hoverBG,
						color: hoverColor,
						textShadow: "0px 0px 0px transparent",
						borderColor: "blackAlpha.400",
					}}
					justify={!sideBarIsOpen ? "center" : "space-between"}
					fontWeight="semibold"
					textTransform="uppercase"
					onClick={setRainbowMode.toggle}
				>
					<HStack
						align="center"
						justify="center"
						isTruncated
						spacing={1}
						w="full"
						flex={1}
						// bgGradient="linear(to-l, #7928CA, #FF0080)"
						// bgClip="text"
					>
						<IconButton variant="unstyled" icon={<i className={"fas fa-rainbow fa-lg fa-fw"} />} />

						{sideBarIsOpen && (
							<Text maxW="full" w="full" letterSpacing={1} whiteSpace={"wrap"} fontSize={"sm"}>
								Rainbow Mode
							</Text>
						)}
					</HStack>
				</Flex>
			</Stack>
		</Flex>
	);
}

export function MenuItem({ route, sideBarIsOpen }) {
	const navRoute = useMemo(() => route, [route]);
	const basePath = useMemo(() => (navRoute?.routes ? navRoute?.path ?? "" : ""), [navRoute]);

	const [isActive, setIsActive] = useBoolean(false);
	const [menuIsOpen, setMenuIsOpen] = useBoolean(false);

	var color = mode("gray.200", "gray.200");
	var hoverColor = mode("whiteAlpha.900", "white");
	var hoverBG = mode("teal.600", "whiteAlpha.400");

	var stylesActive = {
		bg: hoverBG,
		color: hoverColor,
	};

	var stylesHover = {
		bg: hoverBG,
		color: hoverColor,
		opacity: 1,
		textDecoration: "underline",
		textShadow: "0px 0px 0px transparent",
		borderColor: "blackAlpha.400",
	};

	const onClickNavLink = (isActive) => {
		Boolean(isActive) ? setIsActive.on() : setIsActive.off();
	};

	useMemo(() => {
		if (isActive && !menuIsOpen) {
			setMenuIsOpen.on();
		}
	}, [isActive, menuIsOpen, setMenuIsOpen]);

	return (
		<Stack spacing={1}>
			<Link
				id={navRoute?.path}
				as={NavLink}
				to={navRoute?.path}
				_activeLink={stylesActive}
				_hover={stylesHover}
				color={color}
				opacity={0.8}
				transition="all 0.2s"
				borderWidth={1}
				borderColor="transparent"
				borderStyle="inset"
				rounded={"md"}
				onClick={onClickNavLink}
			>
				<MenuButton
					route={navRoute}
					sideBarIsOpen={sideBarIsOpen}
					menuIsOpen={menuIsOpen}
					isActive={isActive}
				/>
				{/* {({ isActive }) => (
					<MenuButton
						route={navRoute}
						sideBarIsOpen={sideBarIsOpen}
						menuIsOpen={subMenuIsOpen}
						isActive={isActive}
					/>
				)} */}
			</Link>

			{navRoute?.routes && (
				<Collapse in={isActive}>
					<Stack bg={"blackAlpha.300"} rounded="md" p={1} spacing={1}>
						{navRoute?.routes?.map((route, i) => {
							let path = basePath + route?.path ?? "";
							// subRoute = Object.assign(subRoute, { path });
							let subRoute = { path, icon: route.icon, name: route.name };

							return <MenuItem key={i} route={subRoute} sideBarIsOpen={sideBarIsOpen} />;
						})}
					</Stack>
				</Collapse>
			)}
		</Stack>
	);
}

export function MenuButton({ route, sideBarIsOpen = true, menuIsOpen = true }) {
	const navRoute = useMemo(() => route, [route]);
	var expandIcon = menuIsOpen ? "fas fa-chevron-down" : "fas fa-chevron-right";

	return (
		<Flex
			cursor="pointer"
			align="center"
			maxW="full"
			justify={!sideBarIsOpen ? "center" : "space-between"}
			fontWeight="semibold"
			textTransform="uppercase"
		>
			<HStack align="center" justify="center" isTruncated spacing={1} w="full" flex={1}>
				<IconButton
					key={navRoute?.icon + " fa-lg fa-fw"}
					variant="unstyled"
					icon={<i className={navRoute?.icon + " fa-lg fa-fw"} />}
				/>

				{sideBarIsOpen && (
					<Text maxW="full" w="full" letterSpacing={1} whiteSpace={"wrap"} fontSize={"sm"}>
						{navRoute?.name}
					</Text>
				)}
			</HStack>

			{navRoute?.routes && sideBarIsOpen && (
				<IconButton
					key={expandIcon + " fa-fw"}
					variant="unstyled"
					icon={<i className={expandIcon + " fa-fw"} />}
				/>
			)}
		</Flex>
	);
}

// export function SubMenuLink({ sideBarIsOpen }) {
// 	return (
// 		<Link
// 			to={basePath + item.url}
// 			onClick={(e) => {
// 				e.stopPropagation();
// 				selectItem(item.url);
// 			}}
// 		>
// 			{itemHeading}
// 		</Link>
// 	);
// }

// function MenuIcon({ item, sideBarIsOpen, toggleSideBar, activeItem, setActiveItem }) {
// 	const [isHovering, setIsHovering] = useState(false);

// 	// log("MENU ITEM", item);

// 	return (
// 		<Menu placement="left-start" isOpen={isHovering}>
// 			<MenuButton
// 				isActive={isHovering}
// 				onMouseOver={() => setIsHovering(true)}
// 				onMouseLeave={() => setIsHovering(false)}
// 			>
// 				{/* {itemHeading} */}
// 			</MenuButton>
// 			<MenuList
// 				onMouseOver={() => setIsHovering(true)}
// 				onMouseLeave={() => setIsHovering(false)}
// 				color="darkslategray"
// 			>
// 				{item?.subMenu?.map(
// 					(subItem, i) =>
// 						isAuthorized(portalUser.user, subItem.access) && (
// 							<MenuItem
// 								key={i}
// 								item={subItem}
// 								sideBarIsOpen={sideBarIsOpen}
// 								isSubMenu={true}
// 								toggleSideBar={toggleSideBar}
// 							/>
// 						)
// 				)}
// 			</MenuList>
// 		</Menu>
// 	);
// }

const reportBugLink =
	"https://forms.office.com/pages/responsepage.aspx?id=GGZ0zUbCzkuv8Z4erUbs5_RXwWay4rxBgUknt9YscmhUNTE1Rk9aNUZSQUJGVTEzU0ZNVzdQR0ZQWiQlQCN0PWcu";
const submitIdeaLink =
	"https://forms.office.com/pages/responsepage.aspx?id=GGZ0zUbCzkuv8Z4erUbs5_RXwWay4rxBgUknt9YscmhUNjI1Qk9WMVlLUEIyWkRUMjFWRlNFV1NVQiQlQCN0PWcu";
