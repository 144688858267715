import { ajaxGET, ajaxPOST, ajaxPATCH, ajaxDELETE, testGET, testPOST, setQueryParams } from "./api-functions";
import { portalUser } from "../App";

const apiBaseURL =
	(process.env.NODE_ENV === "development"
		? "https://ppmportalappnodejs-api.azurewebsites.net"
		: window.location.origin) + "/api/forms/";

export default class FormsAPI {
	async TestGET(headers = {}) {
		let response = await testGET(apiBaseURL, headers);
		return response;
	}

	async TestPOST(body = {}, headers = {}) {
		let response = await testPOST(apiBaseURL, body, headers);
		return response;
	}

	//----FORM DATA (Forms, FormTemplates, FormSections, FormQuestions, FormInputs, FormComments, FormCommenters)

	//GET Form Data
	async GetFormData(params = {}, query = { formUID: null, msUserID: portalUser.msUser?.id }) {
		var apiRouteURL = `${apiBaseURL}/formData`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//----FORM TEMPLATES

	//GET Form Templates
	async GetFormTemplates(params = {}, query = { formUID: null }) {
		var apiRouteURL = `${apiBaseURL}/formData/templates`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//----FORMS

	//GET Forms
	async GetForms(params = {}, query = { formUID: null, msUserID: portalUser.msUser?.id }) {
		var apiRouteURL = `${apiBaseURL}/formData/forms`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//INSERT Forms - HR Reviews by Year
	async InsertForms(params = {}, query = { formType: "" }) {
		var apiRouteURL = `${apiBaseURL}/formData/forms`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		let response = await ajaxPOST(apiRouteURL);
		return response;
	}

	//UPDATE Form
	async UpdateForm(params = { formUID: null }, data = {}) {
		var apiRouteURL = `${apiBaseURL}/formData/forms/${params?.formUID}`;
		apiRouteURL = setQueryParams(apiRouteURL, {});
		if (Boolean(params?.formUID) && Object.keys(data)?.length > 0) {
			let response = await ajaxPATCH(apiRouteURL, data);
			return response;
		}
	}

	//DELETE Form
	async DeleteForm(params = { formUID: null }) {
		var apiRouteURL = `${apiBaseURL}/formData/forms/${params?.formUID}`;
		if (Boolean(params?.formUID)) {
			let response = await ajaxDELETE(apiRouteURL);
			return response;
		}
	}

	//RESET Form
	async ResetForm(params = { formUID: null }) {
		var apiRouteURL = `${apiBaseURL}/formData/forms/${params?.formUID}/reset`;
		if (Boolean(params?.formUID)) {
			let response = await ajaxPATCH(apiRouteURL);
			return response;
		} else {
			return;
		}
	}

	//RESET Form Dates
	async ResetFormDates(params = { formUID: null }) {
		var apiRouteURL = `${apiBaseURL}/formData/forms/${params?.formUID}/resetDates`;
		if (Boolean(params?.formUID)) {
			let response = await ajaxPATCH(apiRouteURL);
			return response;
		} else {
			return;
		}
	}

	//----FORM SECTIONS

	//GET Forms Sections
	async GetFormSections(params = { formUID: null }, query = {}) {
		var apiRouteURL = `${apiBaseURL}/formData/forms/${params?.formUID}/sections`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		if (Boolean(params?.formUID)) {
			let response = await ajaxGET(apiRouteURL);
			return response;
		}
	}

	//----FORM QUESTIONS

	//GET Forms Questions
	async GetFormQuestions(params = { formUID: null }, query = {}) {
		var apiRouteURL = `${apiBaseURL}/formData/forms/${params?.formUID}/questions`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		if (Boolean(params?.formUID)) {
			let response = await ajaxGET(apiRouteURL);
			return response;
		}
	}

	//----FORM RATINGS

	//GET Forms Ratings
	async GetFormRatings(params = { formUID: null }, query = {}) {
		var apiRouteURL = `${apiBaseURL}/formData/forms/${params?.formUID}/ratings`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		if (Boolean(params?.formUID)) {
			let response = await ajaxGET(apiRouteURL);
			return response;
		}
	}

	//----FORM INPUTS

	//GET Forms Inputs
	async GetFormInputs(params = { formUID: null }, query = {}) {
		var apiRouteURL = `${apiBaseURL}/formData/forms/${params?.formUID}/inputs`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		if (Boolean(params?.formUID)) {
			let response = await ajaxGET(apiRouteURL);
			return response;
		}
	}

	//UPDATE Form Input
	async UpdateFormInput(params = { formUID: null, inputUID: null }, data = {}) {
		var apiRouteURL = `${apiBaseURL}/formData/forms/${params?.formUID}/inputs/${params?.inputUID}`;
		apiRouteURL = setQueryParams(apiRouteURL, {});
		if (Boolean(params?.inputUID) && Object.keys(data)?.length > 0) {
			let response = await ajaxPATCH(apiRouteURL, data);
			return response;
		}
	}

	//----FORM COMMENTS

	//GET Forms Comments
	async GetFormComments(params = { formUID: null }, query = {}) {
		var apiRouteURL = `${apiBaseURL}/formData/forms/${params?.formUID}/comments`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//INSERT Form Comment
	async InsertFormComment(params = { formUID: null, commentUID: null }, data = {}) {
		var apiRouteURL = `${apiBaseURL}/formData/forms/${params?.formUID}/comments/${params?.commentUID}`;
		apiRouteURL = setQueryParams(apiRouteURL, {});
		if (Boolean(params?.formUID) && Boolean(params?.commentUID) && Object.keys(data)?.length > 0) {
			let response = await ajaxPOST(apiRouteURL, data);
			return response;
		}
	}

	//UPDATE Form Comment
	async UpdateFormComment(params = { formUID: null, commentUID: null }, data = {}) {
		var apiRouteURL = `${apiBaseURL}/formData/forms/${params?.formUID}/comments/${params?.commentUID}`;
		apiRouteURL = setQueryParams(apiRouteURL, {});
		if (Boolean(params?.formUID) && Boolean(params?.commentUID) && Object.keys(data)?.length > 0) {
			let response = await ajaxPATCH(apiRouteURL, data);
			return response;
		}
	}

	//DELETE Form Comment
	async DeleteFormComment(params = { formUID: null, commentUID: null }) {
		var apiRouteURL = `${apiBaseURL}/formData/forms/${params?.formUID}/comments/${params?.commentUID}`;
		apiRouteURL = setQueryParams(apiRouteURL, {});
		if (Boolean(params?.formUID) && Boolean(params?.commentUID)) {
			let response = await ajaxDELETE(apiRouteURL);
			return response;
		}
	}

	//----FORM COMMENTERS

	//GET Forms Commenters
	async GetFormCommenters(params = { formUID: null }, query = {}) {
		var apiRouteURL = `${apiBaseURL}/formData/forms/${params?.formUID}/commenters`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//INSERT Form Commenter
	async InsertFormCommenter(params = { formUID: null, employeeUID: null }, data = {}) {
		var apiRouteURL = `${apiBaseURL}/formData/forms/${params?.formUID}/commenters/${params?.employeeUID}`;
		apiRouteURL = setQueryParams(apiRouteURL, {});
		if (Boolean(params?.formUID) && Boolean(params?.employeeUID) && Object.keys(data)?.length > 0) {
			let response = await ajaxPOST(apiRouteURL, data);
			return response;
		}
	}

	//UPDATE Form Commenter
	async UpdateFormCommenter(params = { formUID: null, employeeUID: null }, data = {}) {
		var apiRouteURL = `${apiBaseURL}/formData/forms/${params?.formUID}/commenters/${params?.employeeUID}`;
		apiRouteURL = setQueryParams(apiRouteURL, {});
		if (Boolean(params?.formUID) && Boolean(params?.employeeUID) && Object.keys(data)?.length > 0) {
			let response = await ajaxPATCH(apiRouteURL, data);
			return response;
		}
	}

	//DELETE Form Commenter
	async DeleteFormCommenter(params = { formUID: null, employeeUID: null }) {
		var apiRouteURL = `${apiBaseURL}/formData/forms/${params?.formUID}/commenters/${params?.employeeUID}`;
		apiRouteURL = setQueryParams(apiRouteURL, {});
		if (Boolean(params?.formUID) && Boolean(params?.employeeUID)) {
			let response = await ajaxDELETE(apiRouteURL);
			return response;
		}
	}

	// async CloneFormTemplate(data) {
	// 	var apiRouteURL = apiBaseURL + `duplicateFormTemplate/${data.duplicateFormId}`;
	// 	if (Object.keys(data)?.length > 0) {
	// 		let response = await ajaxPOST(apiRouteURL, data);
	// 		return response;
	// 	}
	// }

	// Autofill Parent Form
	// async AutoFillParentForm(formUID, data = {}) {
	// 	var apiRouteURL = apiBaseURL + `form/${formUID}/autofillParent`;
	// 	if (Boolean(formUID) && Object.keys(data)?.length > 0) {
	// 		let response = await ajaxPATCH(apiRouteURL, data);
	// 		return response;
	// 	} else {
	// 		return;
	// 	}
	// }

	// //Completed Child Forms
	// async IsChildFormsCompleted(formUID) {
	// 	var apiRouteURL = apiBaseURL + `form/${formUID}/isResponsesCompleted`;
	// 	apiRouteURL = setQueryParams(apiRouteURL);
	// 	let response = await ajaxGET(apiRouteURL);
	// 	return response;
	// }
}
