import TimecardClassLegacy from "./TimecardClassLegacy";
import PayrollAPI from "../../../APIs/PayrollAPI";
import { PPMUser } from "../../../PPM_SDK/PPMUser";

export default class ResourceGroupClassLegacy {
	constructor(group) {
		this.payrollAPI = new PayrollAPI();
		this.groupUID = (group?.groupUID ?? group?.GroupUID)?.toLowerCase() ?? "";
		this.groupName = group?.GroupName ?? group?.groupName ?? "";
		this.groupDescription = group?.GroupDescription ?? group?.groupDescription ?? "";

		this.autoUpdate = group?.AutoUpdate ?? group?.autoUpdate ?? false;
		this.groupType = group?.GroupType ?? group?.groupType ?? "";

		this.regionID = group?.RegionID ?? group?.regionID ?? null;
		this.deptID = group?.deptID ?? group?.deptID ?? null;
		this.companyID = group?.CompanyID ?? group?.companyID ?? null;
		this.contract = group?.Contract ?? group?.contract ?? null;

		this.approval = group?.Approval ?? group?.approval ?? false;
		this.crewEntry = group?.CrewEntry ?? group?.crewEntry ?? false;

		this.approverCount = group?.ApproverCount ?? group?.approverCount ?? null;
		this.crewEntryCount = group?.CrewEntryCount ?? group?.crewEntryCount ?? null;
		this.employeeCount = group?.EmployeeCount ?? group?.employeeCount ?? null;

		this.timecardHeaders = null;

		// this.timecardHours = null;
		this.members = null;
		this.reviewLevel =
			group?.reviewLevel ??
			(Boolean(this.approval) ? "Approval" : Boolean(this.crewEntry) ? "CrewEntry" : "None");
	}

	async getTimecardData(prEndDate, reviewLevel) {
		let parameters = { PREndDate: prEndDate, GroupUID: this.groupUID };
		let timecardData = await this.payrollAPI.GetTimecardData(this.groupUID, parameters);
		if (timecardData.status === 200) {
			timecardData = timecardData.value;

			let members = timecardData[0] ?? [];
			let timecardHeaders = timecardData[1] ?? [];
			let timecardRows = timecardData[2] ?? [];
			let employees = {};

			for (let i = 0; i < members?.length; i++) {
				members[i] = new PPMUser(members[i]);
				let timecardHeader = null;

				for (let j = 0; j < timecardHeaders?.length; j++) {
					if (
						members[i].prco === timecardHeaders[j].PRCo &&
						members[i].employee === parseInt(timecardHeaders[j].Employee)
					) {
						timecardHeader = timecardHeaders[j];
						break;
					}
				}

				if (timecardHeader !== null) {
					members[i].timecard = new TimecardClassLegacy(members[i], prEndDate, timecardHeader);

					let payweek = [];
					for (let j = 0; j < timecardRows?.length; j++) {
						if (
							members[i].prco === timecardRows[j].PRCo &&
							members[i].employee === parseInt(timecardRows[j].Employee)
						) {
							payweek.push(timecardRows[j]);
						}
					}
					members[i].timecard.loadTimeFromArray(payweek);
				} else {
					members[i].timecard = null;
				}

				let keyAttr = Boolean(reviewLevel) && reviewLevel === "review" ? "prehKeyID" : "employeeUID";
				if (Boolean(employees[members[i][keyAttr]])) {
					employees[members[i][keyAttr]].activeCompanies.push(members[i]);
				} else {
					// members[i].activeCompanies = [members[i]];
					let member = new PPMUser(members[i]);
					member.activeCompanies = [members[i]];
					employees[members[i][keyAttr]] = member;
				}
			}
			this.members = Object.keys(employees); //members;

			// log("ResourceGroupsObjClassLegacy - getTimecardData", this);
			// log("employees", employees);
			return employees;
		}
	}
}
