import UsersAPI from "../../../APIs/UsersAPI";
import { Email as HtmlEmail, renderEmail } from "react-html-email";

export default class Email {
	constructor(email) {
		this.usersAPI = new UsersAPI();
		this.from = email?.From ?? email?.from ?? "PPM Portal<no-reply@ppmportal.dev>";
		this.to = [];
		this.title = email?.Subject ?? email?.subject ?? null;
		this.subject = email?.Subject ?? email?.subject ?? null;
		this.html = email?.Html ?? email?.html ?? null;
		this.body = email?.Body ?? email?.body ?? null;
		// this.replyTo = email?.ReplyTo ?? email?.replyTo ?? null;
	}

	async send() {
		if (Boolean(this.to)) {
			let html = <HtmlEmail style={{ width: "98%" }}>{this.body}</HtmlEmail>;
			let htmlBody = renderEmail(html);
			let msg = {
				from: this.from,
				to: this.to,
				subject: this.subject,
				html: htmlBody,
				// replyTo: this.replyTo,
			};

			await this.usersAPI.SendgridEmail(msg);
			return true;
		}
	}

	addTo(recipient) {
		this.to.push(recipient);
	}

	async addRecipientById(recipientId) {
		let recipient = await this.usersAPI.GetUserByEmployeeUID(recipientId);
		if (recipient.status === 200 && recipient.value.length > 0) {
			let recipientEmail = recipient.value[0].Email;
			this.addTo(recipientEmail);
		}
	}
}
