import React from "react";

import ExampleContainer from "../common/ExampleContainer";
import FormInputExample from "../components/FormInputExample";

export default function FormInputsExamplePage({ heading }) {
	return (
		<>
			<ExampleContainer
				heading={heading}
				subheading={"Text Inputs"}
				children={<FormInputExample inputType={"text"} />}
			/>

			<ExampleContainer
				heading={heading}
				subheading={"Select Inputs"}
				children={<FormInputExample inputType={"select"} />}
			/>

			<ExampleContainer
				heading={heading}
				subheading={"File Inputs"}
				children={<FormInputExample inputType={"file"} />}
			/>
		</>
	);
}
