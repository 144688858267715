export default class Region {
	constructor(region) {
		this.queryData = region?.queryData ?? region?.QueryData ?? "Region";
		this.regionID = region?.regionID ?? region?.RegionID ?? null;
		this.region = region?.region ?? region?.Region ?? "N/A";
		this.description = region?.description ?? region?.Description ?? "N/A";
		this.tag = region?.tag ?? region?.Tag ?? "N/A";
		this.address = region?.address ?? region?.Address ?? "18250 Euclid Street";
		this.city = region?.city ?? region?.City ?? "Fountain Valley";
		this.state = region?.state ?? region?.State ?? "CA";
		this.zip = region?.zip ?? region?.Zip ?? "92708";
		this.phone = region?.phone ?? region?.Phone ?? "(949) 474-9170";
		this.fax = region?.fax ?? region?.Fax ?? "(949) 474-9180";
		this.pmRegionID = region?.pmRegionID ?? region?.PMRegionID ?? null;
		this.color = region?.color ?? region?.Color ?? "gray";
		this.getColors();
	}

	getColors(color = this.color) {
		if (color === "gray") {
			this.fontColor = "var(--chakra-colors-" + color + "-600)";
			this.bg = "white";
			this.borderColor = "var(--chakra-colors-" + color + "-400)";
		} else {
			this.fontColor = "white";
			this.bg = "var(--chakra-colors-" + color + "-500)";
			this.borderColor = "var(--chakra-colors-" + color + "-700)";
		}
	}
}
