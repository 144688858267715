import { portalUser } from "../../../App";
import SectionTemplatePCOLog from "./SectionTemplatePCOLog";
import SectionTemplateACOLog from "./SectionTemplateACOLog";
export default class PageTemplateChangeOrderLog {
	constructor(selectedData = null) {
		let contract = {};
		let region = {};
		let pcos = [];
		let acos = [];

		let pagebreak = 0;
		let isLoaded = false;

		// Process the selectedData array and populate various properties like contract, region, pcos, acos, and coItems
		// It also calculates the pagebreak based on the number of items in the pcos array.
		if (Boolean(selectedData.length > 0)) {
			contract = selectedData?.find((p) => p.queryData === "Contract") ?? {};
			region = portalUser?.getRegions()?.filter((p) => p.regionID === contract?.regionID)[0] ?? {};
			pcos = selectedData?.filter((d) => d.queryData === "PCO") ?? [];
			acos = selectedData?.filter((d) => d.queryData === "ACO") ?? [];
			let coItems = selectedData?.filter((d) => d.queryData === "COItem") ?? [];

			for (let i = 0; i < pcos?.length; i++) {
				let items = coItems.filter((d) => d.pmopKeyID === pcos[i].pmopKeyID) ?? [];
				pcos[i].coItems = items;
				pcos[i].getPCOItemTotals();
			}
			for (let i = 0; i < acos?.length; i++) {
				let items = coItems.filter((d) => d.pmohKeyID === acos[i].pmohKeyID) ?? [];
				// let pmopKeyID = items.map()
				acos[i].coItems = items;
				acos[i].getACOItemTotals();
			}

			isLoaded = Boolean(pcos?.length > 0) || (Boolean(contract) && contract?.pcoAmt === 0);
		}

		if (pcos.length >= 12) {
			pagebreak = 1;
		}

		this.pageTemplate = "ChangeOrders";
		this.pageName = "Change Order Log";
		this.pageTitle = this.getPageTitle(contract);
		this.region = region;
		this.size = "C4";
		this.orientation = "landscape";
		this.pageBody = [new SectionTemplatePCOLog(pcos, isLoaded), new SectionTemplateACOLog(acos, pagebreak)];
	}

	getPageTitle(contract = null) {
		let pageTitle = "Report Template";
		if (Boolean(contract?.contract)) {
			pageTitle = contract?.contract + " " + contract?.description;
		}
		return pageTitle;
	}
}
