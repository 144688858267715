import { getSubtotal } from "../../../helperFunctions";

export default class SectionTemplateDiversityVendors {
	constructor(template = "VendorDiversityAvailableVendors", vendors = [], isLoaded = true, label) {
		this.template = template;
		this.name = label ?? "Vendors";
		this.heading = label ?? "Vendors";
		this.pagebreak = 0;
		this.wrap = true;
		this.type = "virtualTable";
		this.minRows = 1;
		this.maxRows = 21;
		if (template === "VendorDiversitySpentToDate") {
			this.subSections = [
				new SubSectionTemplateVendorDiversitySpentToDate(template, vendors, isLoaded, this.heading),
			];
			this.isLoaded = isLoaded;
		} else if (template === "VendorDiversityAvailableVendors") {
			this.subSections = [
				new SubSectionTemplateVendorDiversityAvailableVendors(template, vendors, isLoaded, this.heading),
			];
			this.isLoaded = isLoaded;
		}
	}
}

class SubSectionTemplateVendorDiversitySpentToDate {
	constructor(template, vendors = [], isLoaded, heading) {
		let show = vendors?.length > 0 ? true : false;
		this.type = "virtualTable";
		this.autoExpandRows = true;

		this.template = template;
		this.type = "virtualTable";
		this.show = show;
		this.heading = heading;

		// this.footer = this.getFooter(vendors);
		this.wrap = true;

		this.columns = [
			{
				accessor: "vendorID",
				Header: "Vendor",
				valueType: "integer",
			},
			{
				accessor: "vendorName",
				Header: "Vendor Name",
				width: "300%",
			},

			{
				accessor: "actualCost",
				Header: "Actual Cost",
				valueType: "currency",
				decimals: 2,
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},
			{
				accessor: "committedCost",
				Header: "Committed Cost",
				valueType: "currency",
				decimals: 2,
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},
			{
				accessor: "totalPOCost",
				Header: "Total PO Cost",
				valueType: "currency",
				decimals: 2,
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},
		];

		const filteredVendors =
			vendors?.filter(
				(vendor, index, self) =>
					vendor.totalPOCost !== 0 && index === self.findIndex((v) => v.vendorID === vendor.vendorID)
			) ?? [];
		this.data = filteredVendors.length > 0 ? filteredVendors : vendors;
		this.footer = this.getFooter(filteredVendors ?? vendors);
		this.sortBy = [{ id: "vendorName", desc: false }];
	}

	getFooter(rows = []) {
		let actualCost = getSubtotal(rows, "actualCost");
		let committedCost = getSubtotal(rows, "committedCost");
		let totalPOCost = actualCost + committedCost;

		let footer = {
			actualCost: actualCost,
			committedCost: committedCost,
			totalPOCost: totalPOCost,
		};

		return [footer];
	}
}

class SubSectionTemplateVendorDiversityAvailableVendors {
	constructor(template, vendors = [], isLoaded, heading) {
		let show = vendors?.length > 0 ? true : false;
		this.type = "virtualTable";
		this.autoExpandRows = true;

		this.template = template;
		this.type = "virtualTable";
		this.show = show;
		this.heading = heading;

		// this.footer = this.getFooter(vendors);
		this.wrap = true;

		this.columns = [
			{
				accessor: "divCertType",
				Header: "Type",
			},
			{
				accessor: "divCertSubType",
				Header: "Sub-Type",
			},
			{
				accessor: "vendorID",
				Header: "Vendor",
				valueType: "integer",
			},
			{
				accessor: "vendorName",
				Header: "Vendor Name",
				width: "300%",
			},

			{
				accessor: "status",
				Header: "Status",
			},
			{
				accessor: "expirationDate",
				Header: "Expiration",
			},
			{
				accessor: "diversityContact",
				Header: "Contact",
			},
			{
				accessor: "diversityEmail",
				Header: "Email",
				show: false,
			},
			{
				accessor: "diversityPhone",
				Header: "Phone",
				show: false,
			},
		];

		this.data = vendors;
		this.sortBy = [{ id: "vendorID", desc: false }];
		this.loopBy = [{ id: "divCertType", heading: "divCertType" }];
	}
}
