import {
	chakraThemeColorsMap,
	getSubtotal,
	getRate,
	sumProduct,
	getValueColor,
	// log,
} from "../../../helperFunctions";
import moment from "moment";

export default class SectionTemplateManpowerProjections {
	constructor(manpowerProjectionsByMth = [], contractTotals = null, pagebreak = 0, maxRows = 16, show = true) {
		this.template = "ManpowerProjections";
		this.name = "Manpower Projections";
		this.heading = "Manpower Projections";
		this.subheading = "If a scope of work is missing, click the gear button to show additional columns";
		this.footNote = "*Click the Sync Button to Copy your Manpower & Billing Projections to iPPMech.com";
		this.tooltip = "Click the Sync Button to Copy your Manpower & Billing Projections to iPPMech.com";
		this.divider = false;
		this.wrap = true;
		this.pagebreak = pagebreak;
		this.type = "virtualTable";
		this.minRows = 1;
		this.maxRows = 16;
		this.show = show;
		this.subSections = [new SubSectionTemplateManpowerProjections(manpowerProjectionsByMth, contractTotals)];
	}
}

class SubSectionTemplateManpowerProjections {
	constructor(manpowerProjectionsByMth = [], contractTotals = null) {
		this.template = "ManpowerProjections";
		this.type = "virtualTable";
		this.show = manpowerProjectionsByMth?.length > 0 ? true : false;
		this.heading = "Manpower Projections";

		let footer = this.getFooter(manpowerProjectionsByMth, contractTotals);

		this.autoExpandRows = true;
		this.columns = [
			// {
			// 	accessor: "projKeyID",
			// 	Header: "ProjKeyID",
			// 	valueType: "integer",
			// 	width: "85%",
			// 	show: false,
			// },
			{
				accessor: "year",
				Header: "Year",
				valueType: "integer",
				width: "85%",
				show: false,
				placeHolderID: "mth",
			},
			{
				accessor: "mth",
				Header: "Month",
				valueType: "month",
				placeHolderID: "year",
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "workDays",
				Header: "Work Days",
				textAlign: "center",
				valueType: "number",
				show: false,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "workHours",
				Header: "Work Hours",
				textAlign: "center",
				valueType: "number",
				aggregate: "average",
				show: false,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "projCrewSizeOFFC_delta",
				Header: "Office",
				textAlign: "center",
				valueType: "number",
				aggregate: "average",
				decimals: 1,
				editable: true,
				width: "85%",
				show: Boolean(
					contractTotals?.actualHoursOFFC + contractTotals?.projHoursOFFC + footer[0].projCrewSizeOFFC_delta >
						0
				),
				bg: chakraThemeColorsMap.grayAlpha300,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "projCrewSizeDETL_delta",
				Header: "Detailing",
				textAlign: "center",
				valueType: "number",
				aggregate: "average",
				decimals: 1,
				editable: true,
				width: "85%",
				show: Boolean(
					contractTotals?.actualHoursDETL + contractTotals?.projHoursDETL + footer[0].projCrewSizeDETL_delta >
						0
				),
				bg: chakraThemeColorsMap.grayAlpha300,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "projCrewSizePLUM_delta",
				Header: "PLUM",
				textAlign: "center",
				valueType: "number",
				aggregate: "average",
				decimals: 1,
				editable: true,
				width: "85%",
				show: Boolean(
					contractTotals?.actualHoursPLUM + contractTotals?.projHoursPLUM + footer[0].projCrewSizePLUM_delta >
						0
				),
				bg: chakraThemeColorsMap.grayAlpha300,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "projCrewSizeHVAC_delta",
				Header: "SHEET",
				textAlign: "center",
				valueType: "number",
				aggregate: "average",
				decimals: 1,
				editable: true,
				width: "85%",
				show: Boolean(
					contractTotals?.actualHoursHVAC + contractTotals?.projHoursHVAC + footer[0].projCrewSizeHVAC_delta >
						0
				),
				bg: chakraThemeColorsMap.grayAlpha300,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "projCrewSizePIPE_delta",
				Header: "PIPE",
				textAlign: "center",
				valueType: "number",
				aggregate: "average",
				decimals: 1,
				editable: true,
				width: "85%",
				show: Boolean(
					contractTotals?.actualHoursPIPE + contractTotals?.projHoursPIPE + footer[0].projCrewSizePIPE_delta >
						0
				),
				bg: chakraThemeColorsMap.grayAlpha300,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "projCrewSizeFUEL_delta",
				Header: "FUEL",
				textAlign: "center",
				valueType: "number",
				aggregate: "average",
				decimals: 1,
				editable: true,
				width: "85%",
				show: Boolean(
					contractTotals?.actualHoursFUEL + contractTotals?.projHoursFUEL + footer[0].projCrewSizeFUEL_delta >
						0
				),
				bg: chakraThemeColorsMap.grayAlpha300,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},

			{
				accessor: "projCrewSizePROC_delta",
				Header: "PROC",
				textAlign: "center",
				valueType: "number",
				aggregate: "average",
				decimals: 1,
				editable: true,
				width: "85%",
				show: Boolean(
					contractTotals?.actualHoursPROC + contractTotals?.projHoursPROC + footer[0].projCrewSizePROC_delta >
						0
				),
				bg: chakraThemeColorsMap.grayAlpha300,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "projCrewSizeFIRE_delta",
				Header: "FIRE",
				textAlign: "center",
				valueType: "number",
				aggregate: "average",
				decimals: 1,
				editable: true,
				width: "85%",
				show: Boolean(
					contractTotals?.actualHoursFIRE + contractTotals?.projHoursFIRE + footer[0].projCrewSizeFIRE_delta >
						0
				),
				bg: chakraThemeColorsMap.grayAlpha300,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},

			{
				accessor: "projCrewSizeSITE_delta",
				Header: "SITE",
				textAlign: "center",
				valueType: "number",
				aggregate: "average",
				decimals: 1,
				editable: true,
				width: "85%",
				show: Boolean(
					contractTotals?.actualHoursSITE + contractTotals?.projHoursSITE + footer[0].projCrewSizeSITE_delta >
						0
				),
				bg: chakraThemeColorsMap.grayAlpha300,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "projCrewSizeFABR_delta",
				Header: "PREFAB",
				textAlign: "center",
				valueType: "number",
				aggregate: "average",
				decimals: 1,
				editable: true,
				width: "85%",
				show: Boolean(
					contractTotals?.actualHoursFABR + contractTotals?.projHoursFABR + footer[0].projCrewSizeFABR_delta >
						0
				),
				bg: chakraThemeColorsMap.grayAlpha300,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "projCrewSizeSTRT_delta",
				Header: "START UP",
				textAlign: "center",
				valueType: "number",
				aggregate: "average",
				decimals: 1,
				editable: true,
				width: "85%",
				show: Boolean(
					contractTotals?.actualHoursSTRT + contractTotals?.projHoursSTRT + footer[0].projCrewSizeSTRT_delta >
						0
				),
				bg: chakraThemeColorsMap.grayAlpha300,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "projCrewSizeCHNG_delta",
				Header: "CHANGE ORDERS",
				textAlign: "center",
				valueType: "number",
				aggregate: "average",
				decimals: 1,
				editable: true,
				width: "85%",
				show: Boolean(
					contractTotals?.actualHoursCHNG + contractTotals?.projHoursCHNG + footer[0].projCrewSizeCHNG_delta >
						0
				),
				bg: chakraThemeColorsMap.grayAlpha300,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				id: "projCrewSizeFIELD_delta",
				accessor: this.projCrewSizeFIELD_delta,
				Header: "Field Manpower",
				textAlign: "center",
				valueType: "number",
				aggregate: "average",
				decimals: 1,
				width: "85%",
				bg: chakraThemeColorsMap.grayAlpha300,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				id: "projCrewSizeTOTAL_delta",
				accessor: this.projCrewSizeTOTAL_delta,
				Header: "Projected Manpower",
				textAlign: "center",
				valueType: "number",
				aggregate: "average",
				decimals: 1,
				width: "85%",
				bg: chakraThemeColorsMap.grayAlpha300,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				id: "projHours_delta",
				accessor: this.projHours_delta,
				Header: "Projected Hours",
				textAlign: "right",
				valueType: "number",
				decimals: 1,
				width: "85%",
				bg: chakraThemeColorsMap.grayAlpha300,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "pctProjManpowerField",
				Header: "Field Manpower Dist (%)",
				textAlign: "center",
				valueType: "percent",
				show: false,
				decimals: 1,
				width: "85%",
				bg: chakraThemeColorsMap.grayAlpha300,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "rateToComplete",
				Header: "Rate To Complete",
				valueType: "currency",
				decimals: 2,
				width: "85%",
				bg: chakraThemeColorsMap.grayAlpha300,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				id: "projLaborCost_delta",
				accessor: this.projLaborCost_delta,
				Header: "Labor Cost",
				textAlign: "right",
				valueType: "currency",
				bg: chakraThemeColorsMap.grayAlpha300,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
		];
		this.sortBy = [
			{ id: "mth", desc: false },
			{ id: "contract", desc: false },
		];

		this.data = this.getRightAddon(manpowerProjectionsByMth);

		this.footer = (rows = manpowerProjectionsByMth, totals = contractTotals) => {
			return this.getFooter(rows, totals);
		};
	}

	getRightAddon(data = []) {
		for (let i = 0; i < data?.length; i++) {
			if (
				moment(new Date(data[i].mth)).format("MM/DD/YYYY") ===
				moment(new Date()).startOf("month").format("MM/DD/YYYY")
			) {
				data[i].mthRightAddon = " (Projected)";
				break;
			}
		}

		return data;
	}

	projCrewSizeFIELD_delta(row) {
		return row.getProjCrewSizeFIELD_delta();
	}

	projCrewSizeTOTAL_delta(row) {
		return row.getProjCrewSizeTOTAL_delta();
	}

	projHours_delta(row) {
		return row.getProjHours_delta();
	}

	projLaborCost_delta(row) {
		return row.getProjLaborCost_delta();
	}

	getFooter(rows = [], totals = null) {
		let workDays = Boolean(rows?.length > 0) ? getSubtotal(rows, "workDays") : 0;
		let workHours = Boolean(rows?.length > 0) ? getSubtotal(rows, "workHours") : 0;
		let proj = {
			mth: "Projected Manpower",
			mthType: "string",
			mthFontSize: 7,
			mthTextSize: "sm",

			projCrewSizeOFFC_deltaFontSize: 8,

			projCrewSizeOFFC_deltaTextTransform: "lowercase",
			projCrewSizeOFFC_deltaRightAddon: "hrs",

			projCrewSizeDETL_deltaFontSize: 8,

			projCrewSizeDETL_deltaTextTransform: "lowercase",
			projCrewSizeDETL_deltaRightAddon: "hrs",

			projCrewSizePLUM_deltaFontSize: 8,

			projCrewSizePLUM_deltaTextTransform: "lowercase",
			projCrewSizePLUM_deltaRightAddon: "hrs",

			projCrewSizeHVAC_deltaFontSize: 8,

			projCrewSizeHVAC_deltaTextTransform: "lowercase",
			projCrewSizeHVAC_deltaRightAddon: "hrs",

			projCrewSizePIPE_deltaFontSize: 8,

			projCrewSizePIPE_deltaTextTransform: "lowercase",
			projCrewSizePIPE_deltaRightAddon: "hrs",

			projCrewSizeFUEL_deltaFontSize: 8,

			projCrewSizeFUEL_deltaTextTransform: "lowercase",
			projCrewSizeFUEL_deltaRightAddon: "hrs",

			projCrewSizeFIRE_deltaFontSize: 8,

			projCrewSizeFIRE_deltaTextTransform: "lowercase",
			projCrewSizeFIRE_deltaRightAddon: "hrs",

			projCrewSizePROC_deltaFontSize: 8,

			projCrewSizePROC_deltaTextTransform: "lowercase",
			projCrewSizePROC_deltaRightAddon: "hrs",

			projCrewSizeSITE_deltaFontSize: 8,

			projCrewSizeSITE_deltaTextTransform: "lowercase",
			projCrewSizeSITE_deltaRightAddon: "hrs",

			projCrewSizeSTRT_deltaFontSize: 8,

			projCrewSizeSTRT_deltaTextTransform: "lowercase",
			projCrewSizeSTRT_deltaRightAddon: "hrs",

			projCrewSizeFABR_deltaFontSize: 8,

			projCrewSizeFABR_deltaTextTransform: "lowercase",
			projCrewSizeFABR_deltaRightAddon: "hrs",

			projCrewSizeCHNG_deltaFontSize: 8,

			projCrewSizeCHNG_deltaTextTransform: "lowercase",
			projCrewSizeCHNG_deltaRightAddon: "hrs",

			projCrewSizeFIELD_deltaFontSize: 8,

			projCrewSizeFIELD_deltaTextTransform: "lowercase",
			projCrewSizeFIELD_deltaRightAddon: "hrs",

			projCrewSizeTOTAL_deltaFontSize: 8,

			projCrewSizeTOTAL_deltaTextTransform: "lowercase",
			projCrewSizeTOTAL_deltaRightAddon: "hrs",

			projHours_deltaFontSize: 8,

			projHours_deltaTextTransform: "lowercase",
			projHours_deltaRightAddon: "hrs",

			rateToCompleteFontSize: 8,

			rateToCompleteRightAddon: "/hr",
			rateToCompleteTextTransform: "lowercase",

			projLaborCost_deltaFontSize: 8,

			projLaborCost_deltaTextTransform: "lowercase",

			workDays: workDays,
			workHours: workHours,
			projCrewSizeOFFC_delta: Boolean(rows?.length > 0)
				? sumProduct(rows, "projCrewSizeOFFC_delta", "workHours")
				: 0,
			projCrewSizeDETL_delta: Boolean(rows?.length > 0)
				? sumProduct(rows, "projCrewSizeDETL_delta", "workHours")
				: 0,
			projCrewSizePLUM_delta: Boolean(rows?.length > 0)
				? sumProduct(rows, "projCrewSizePLUM_delta", "workHours")
				: 0,
			projCrewSizeHVAC_delta: Boolean(rows?.length > 0)
				? sumProduct(rows, "projCrewSizeHVAC_delta", "workHours")
				: 0,
			projCrewSizePIPE_delta: Boolean(rows?.length > 0)
				? sumProduct(rows, "projCrewSizePIPE_delta", "workHours")
				: 0,
			projCrewSizeFUEL_delta: Boolean(rows?.length > 0)
				? sumProduct(rows, "projCrewSizeFUEL_delta", "workHours")
				: 0,
			projCrewSizeFIRE_delta: Boolean(rows?.length > 0)
				? sumProduct(rows, "projCrewSizeFIRE_delta", "workHours")
				: 0,
			projCrewSizePROC_delta: Boolean(rows?.length > 0)
				? sumProduct(rows, "projCrewSizePROC_delta", "workHours")
				: 0,
			projCrewSizeSITE_delta: Boolean(rows?.length > 0)
				? sumProduct(rows, "projCrewSizeSITE_delta", "workHours")
				: 0,
			projCrewSizeFABR_delta: Boolean(rows?.length > 0)
				? sumProduct(rows, "projCrewSizeFABR_delta", "workHours")
				: 0,
			projCrewSizeSTRT_delta: Boolean(rows?.length > 0)
				? sumProduct(rows, "projCrewSizeSTRT_delta", "workHours")
				: 0,
			projCrewSizeCHNG_delta: Boolean(rows?.length > 0)
				? sumProduct(rows, "projCrewSizeCHNG_delta", "workHours")
				: 0,
			projCrewSizeFIELD_delta: 0,
			projCrewSizeTOTAL_delta: 0,

			projHours_delta: Boolean(rows?.length > 0) ? getSubtotal(rows, "projHours_delta") : 0,
			projLaborCost_delta: Boolean(rows?.length > 0) ? getSubtotal(rows, "projLaborCost_delta") : 0,
			rateToComplete: Boolean(rows?.length > 0)
				? getRate(getSubtotal(rows, "projLaborCost_delta"), getSubtotal(rows, "projHours_delta"))
				: 0,
		};
		proj.projCrewSizeFIELD_delta =
			proj.projCrewSizePLUM_delta +
			proj.projCrewSizeHVAC_delta +
			proj.projCrewSizePIPE_delta +
			proj.projCrewSizeFUEL_delta +
			proj.projCrewSizeFIRE_delta +
			proj.projCrewSizePROC_delta +
			proj.projCrewSizeSITE_delta +
			proj.projCrewSizeSTRT_delta +
			proj.projCrewSizeCHNG_delta;

		proj.projCrewSizeTOTAL_delta =
			proj.projCrewSizeFIELD_delta +
			proj.projCrewSizeOFFC_delta +
			proj.projCrewSizeFABR_delta +
			proj.projCrewSizeDETL_delta;

		let costToComp = {
			mth: "Hours To Complete",
			mthType: "string",
			mthFontSize: 7,
			mthTextSize: "sm",
			projCrewSizeOFFC_deltaFontSize: 8,

			projCrewSizeOFFC_deltaTextTransform: "lowercase",
			projCrewSizeOFFC_deltaRightAddon: "hrs",

			projCrewSizeDETL_deltaFontSize: 8,

			projCrewSizeDETL_deltaTextTransform: "lowercase",
			projCrewSizeDETL_deltaRightAddon: "hrs",

			projCrewSizePLUM_deltaFontSize: 8,

			projCrewSizePLUM_deltaTextTransform: "lowercase",
			projCrewSizePLUM_deltaRightAddon: "hrs",

			projCrewSizeHVAC_deltaFontSize: 8,

			projCrewSizeHVAC_deltaTextTransform: "lowercase",
			projCrewSizeHVAC_deltaRightAddon: "hrs",

			projCrewSizePIPE_deltaFontSize: 8,

			projCrewSizePIPE_deltaTextTransform: "lowercase",
			projCrewSizePIPE_deltaRightAddon: "hrs",

			projCrewSizeFUEL_deltaFontSize: 8,

			projCrewSizeFUEL_deltaTextTransform: "lowercase",
			projCrewSizeFUEL_deltaRightAddon: "hrs",

			projCrewSizeFIRE_deltaFontSize: 8,

			projCrewSizeFIRE_deltaTextTransform: "lowercase",
			projCrewSizeFIRE_deltaRightAddon: "hrs",

			projCrewSizePROC_deltaFontSize: 8,

			projCrewSizePROC_deltaTextTransform: "lowercase",
			projCrewSizePROC_deltaRightAddon: "hrs",

			projCrewSizeSITE_deltaFontSize: 8,

			projCrewSizeSITE_deltaTextTransform: "lowercase",
			projCrewSizeSITE_deltaRightAddon: "hrs",

			projCrewSizeSTRT_deltaFontSize: 8,

			projCrewSizeSTRT_deltaTextTransform: "lowercase",
			projCrewSizeSTRT_deltaRightAddon: "hrs",

			projCrewSizeFABR_deltaFontSize: 8,

			projCrewSizeFABR_deltaTextTransform: "lowercase",
			projCrewSizeFABR_deltaRightAddon: "hrs",

			projCrewSizeCHNG_deltaFontSize: 8,

			projCrewSizeCHNG_deltaTextTransform: "lowercase",
			projCrewSizeCHNG_deltaRightAddon: "hrs",

			projCrewSizeFIELD_deltaFontSize: 8,

			projCrewSizeFIELD_deltaTextTransform: "lowercase",
			projCrewSizeFIELD_deltaRightAddon: "hrs",

			projCrewSizeTOTAL_deltaFontSize: 8,

			projCrewSizeTOTAL_deltaTextTransform: "lowercase",
			projCrewSizeTOTAL_deltaRightAddon: "hrs",

			projHours_deltaFontSize: 8,

			projHours_deltaTextTransform: "lowercase",
			projHours_deltaRightAddon: "hrs",

			rateToCompleteFontSize: 8,

			rateToCompleteRightAddon: "/hr",
			rateToCompleteTextTransform: "lowercase",

			projLaborCost_deltaFontSize: 8,

			projLaborCost_deltaTextTransform: "lowercase",
		};

		if (Array.isArray(totals)) {
			let costToCompRow = totals?.find((d) => d.mth === "Hours To Complete") ?? null;
			if (Boolean(costToCompRow)) {
				costToComp = costToCompRow;
			}
		} else {
			if (Boolean(totals?.queryData === "ContractTotalsByMth" || rows?.length === 0)) {
				costToComp.projCrewSizeOFFC_delta =
					parseFloat(totals?.projHoursOFFC ?? 0) - parseFloat(totals?.actualHoursOFFC ?? 0);
				costToComp.projCrewSizeDETL_delta =
					parseFloat(totals?.projHoursDETL ?? 0) - parseFloat(totals?.actualHoursDETL ?? 0);
				costToComp.projCrewSizePLUM_delta =
					parseFloat(totals?.projHoursPLUM ?? 0) - parseFloat(totals?.actualHoursPLUM ?? 0);
				costToComp.projCrewSizeHVAC_delta =
					parseFloat(totals?.projHoursHVAC ?? 0) - parseFloat(totals?.actualHoursHVAC ?? 0);
				costToComp.projCrewSizePIPE_delta =
					parseFloat(totals?.projHoursPIPE ?? 0) - parseFloat(totals?.actualHoursPIPE ?? 0);
				costToComp.projCrewSizeFUEL_delta =
					parseFloat(totals?.projHoursFUEL ?? 0) - parseFloat(totals?.actualHoursFUEL ?? 0);
				costToComp.projCrewSizeFIRE_delta =
					parseFloat(totals?.projHoursFIRE ?? 0) - parseFloat(totals?.actualHoursFIRE ?? 0);
				costToComp.projCrewSizePROC_delta =
					parseFloat(totals?.projHoursPROC ?? 0) - parseFloat(totals?.actualHoursPROC ?? 0);
				costToComp.projCrewSizeSITE_delta =
					parseFloat(totals?.projHoursSITE ?? 0) - parseFloat(totals?.actualHoursSITE ?? 0);
				costToComp.projCrewSizeFIRE_delta =
					parseFloat(totals?.projHoursFIRE ?? 0) - parseFloat(totals?.actualHoursFIRE ?? 0);
				costToComp.projCrewSizeFABR_delta =
					parseFloat(totals?.projHoursFABR ?? 0) - parseFloat(totals?.actualHoursFABR ?? 0);
				costToComp.projCrewSizeSTRT_delta =
					parseFloat(totals?.projHoursSTRT ?? 0) - parseFloat(totals?.actualHoursSTRT ?? 0);
				costToComp.projCrewSizeCHNG_delta =
					parseFloat(totals?.projHoursCHNG ?? 0) - parseFloat(totals?.actualHoursCHNG ?? 0);

				costToComp.projCrewSizeFIELD_delta =
					costToComp.projCrewSizePLUM_delta +
					costToComp.projCrewSizeHVAC_delta +
					costToComp.projCrewSizePIPE_delta +
					costToComp.projCrewSizeFUEL_delta +
					costToComp.projCrewSizeFIRE_delta +
					costToComp.projCrewSizePROC_delta +
					costToComp.projCrewSizeSITE_delta +
					costToComp.projCrewSizeSTRT_delta +
					costToComp.projCrewSizeCHNG_delta;

				costToComp.projCrewSizeTOTAL_delta =
					costToComp.projCrewSizeFIELD_delta +
					costToComp.projCrewSizeOFFC_delta +
					costToComp.projCrewSizeFABR_delta +
					costToComp.projCrewSizeDETL_delta;

				costToComp.projHours_delta = totals?.getHoursToComplete() ?? 0;
				costToComp.projLaborCost_delta = totals?.getLaborCostToComplete() ?? 0;
				costToComp.rateToComplete = totals?.getRateToComplete() ?? 0;
			}
		}

		let remain = {
			mth: "Remaining Hours",
			mthType: "string",
			mthFontSize: 7,
			mthTextSize: "sm",
			projCrewSizeOFFC_deltaFontSize: 8,

			projCrewSizeOFFC_deltaTextTransform: "lowercase",
			projCrewSizeOFFC_deltaRightAddon: "hrs",

			projCrewSizeDETL_deltaFontSize: 8,

			projCrewSizeDETL_deltaTextTransform: "lowercase",
			projCrewSizeDETL_deltaRightAddon: "hrs",

			projCrewSizePLUM_deltaFontSize: 8,

			projCrewSizePLUM_deltaTextTransform: "lowercase",
			projCrewSizePLUM_deltaRightAddon: "hrs",

			projCrewSizeHVAC_deltaFontSize: 8,

			projCrewSizeHVAC_deltaTextTransform: "lowercase",
			projCrewSizeHVAC_deltaRightAddon: "hrs",

			projCrewSizePIPE_deltaFontSize: 8,

			projCrewSizePIPE_deltaTextTransform: "lowercase",
			projCrewSizePIPE_deltaRightAddon: "hrs",

			projCrewSizeFUEL_deltaFontSize: 8,

			projCrewSizeFUEL_deltaTextTransform: "lowercase",
			projCrewSizeFUEL_deltaRightAddon: "hrs",

			projCrewSizeFIRE_deltaFontSize: 8,

			projCrewSizeFIRE_deltaTextTransform: "lowercase",
			projCrewSizeFIRE_deltaRightAddon: "hrs",

			projCrewSizePROC_deltaFontSize: 8,

			projCrewSizePROC_deltaTextTransform: "lowercase",
			projCrewSizePROC_deltaRightAddon: "hrs",

			projCrewSizeSITE_deltaFontSize: 8,

			projCrewSizeSITE_deltaTextTransform: "lowercase",
			projCrewSizeSITE_deltaRightAddon: "hrs",

			projCrewSizeSTRT_deltaFontSize: 8,

			projCrewSizeSTRT_deltaTextTransform: "lowercase",
			projCrewSizeSTRT_deltaRightAddon: "hrs",

			projCrewSizeFABR_deltaFontSize: 8,

			projCrewSizeFABR_deltaTextTransform: "lowercase",
			projCrewSizeFABR_deltaRightAddon: "hrs",

			projCrewSizeCHNG_deltaFontSize: 8,

			projCrewSizeCHNG_deltaTextTransform: "lowercase",
			projCrewSizeCHNG_deltaRightAddon: "hrs",

			projCrewSizeFIELD_deltaFontSize: 8,

			projCrewSizeFIELD_deltaTextTransform: "lowercase",
			projCrewSizeFIELD_deltaRightAddon: "hrs",

			projCrewSizeTOTAL_deltaFontSize: 8,

			projCrewSizeTOTAL_deltaTextTransform: "lowercase",
			projCrewSizeTOTAL_deltaRightAddon: "hrs",

			projHours_deltaFontSize: 8,

			projHours_deltaTextTransform: "lowercase",
			projHours_deltaRightAddon: "hrs",

			rateToCompleteFontSize: 8,

			rateToCompleteRightAddon: "/hr",
			rateToCompleteTextTransform: "lowercase",

			projLaborCost_deltaFontSize: 8,

			projLaborCost_deltaTextTransform: "lowercase",

			projCrewSizeOFFC_delta:
				parseFloat(costToComp?.projCrewSizeOFFC_delta ?? 0) - parseFloat(proj?.projCrewSizeOFFC_delta ?? 0),
			projCrewSizeDETL_delta:
				parseFloat(costToComp?.projCrewSizeDETL_delta ?? 0) - parseFloat(proj?.projCrewSizeDETL_delta ?? 0),
			projCrewSizePLUM_delta:
				parseFloat(costToComp?.projCrewSizePLUM_delta ?? 0) - parseFloat(proj?.projCrewSizePLUM_delta ?? 0),
			projCrewSizeHVAC_delta:
				parseFloat(costToComp?.projCrewSizeHVAC_delta ?? 0) - parseFloat(proj?.projCrewSizeHVAC_delta ?? 0),
			projCrewSizePIPE_delta:
				parseFloat(costToComp?.projCrewSizePIPE_delta ?? 0) - parseFloat(proj?.projCrewSizePIPE_delta ?? 0),
			projCrewSizeFUEL_delta:
				parseFloat(costToComp?.projCrewSizeFUEL_delta ?? 0) - parseFloat(proj?.projCrewSizeFUEL_delta ?? 0),
			projCrewSizeFIRE_delta:
				parseFloat(costToComp?.projCrewSizeFIRE_delta ?? 0) - parseFloat(proj?.projCrewSizeFIRE_delta ?? 0),
			projCrewSizePROC_delta:
				parseFloat(costToComp?.projCrewSizePROC_delta ?? 0) - parseFloat(proj?.projCrewSizePROC_delta ?? 0),
			projCrewSizeSITE_delta:
				parseFloat(costToComp?.projCrewSizeSITE_delta ?? 0) - parseFloat(proj?.projCrewSizeSITE_delta ?? 0),
			projCrewSizeFABR_delta:
				parseFloat(costToComp?.projCrewSizeFABR_delta ?? 0) - parseFloat(proj?.projCrewSizeFABR_delta ?? 0),
			projCrewSizeSTRT_delta:
				parseFloat(costToComp?.projCrewSizeSTRT_delta ?? 0) - parseFloat(proj?.projCrewSizeSTRT_delta ?? 0),
			projCrewSizeCHNG_delta:
				parseFloat(costToComp?.projCrewSizeCHNG_delta ?? 0) - parseFloat(proj?.projCrewSizeCHNG_delta ?? 0),
			projCrewSizeFIELD_delta:
				parseFloat(costToComp?.projCrewSizeFIELD_delta ?? 0) - parseFloat(proj?.projCrewSizeFIELD_delta ?? 0),
			projCrewSizeTOTAL_delta:
				parseFloat(costToComp?.projCrewSizeTOTAL_delta ?? 0) - parseFloat(proj?.projCrewSizeTOTAL_delta ?? 0),

			projHours_delta: parseFloat(costToComp?.projHours_delta ?? 0) - parseFloat(proj?.projHours_delta ?? 0),
			projLaborCost_delta:
				parseFloat(costToComp?.projLaborCost_delta ?? 0) - parseFloat(proj?.projLaborCost_delta ?? 0),

			rateToComplete: getRate(
				parseFloat(costToComp?.projLaborCost_delta ?? 0) - parseFloat(proj?.projLaborCost_delta ?? 0),
				parseFloat(costToComp?.projHours_delta ?? 0) - parseFloat(proj?.projHours_delta ?? 0)
			),
		};

		remain.projCrewSizeOFFC_deltaTextColor = getValueColor(remain.projCrewSizeOFFC_delta);
		remain.projCrewSizeDETL_deltaTextColor = getValueColor(remain.projCrewSizeDETL_delta);
		remain.projCrewSizePLUM_deltaTextColor = getValueColor(remain.projCrewSizePLUM_delta);
		remain.projCrewSizeHVAC_deltaTextColor = getValueColor(remain.projCrewSizeHVAC_delta);
		remain.projCrewSizePIPE_deltaTextColor = getValueColor(remain.projCrewSizePIPE_delta);
		remain.projCrewSizeFUEL_deltaTextColor = getValueColor(remain.projCrewSizeFUEL_delta);
		remain.projCrewSizeFIRE_deltaTextColor = getValueColor(remain.projCrewSizeFIRE_delta);
		remain.projCrewSizeSITE_deltaTextColor = getValueColor(remain.projCrewSizeSITE_delta);
		remain.projCrewSizePROC_deltaTextColor = getValueColor(remain.projCrewSizePROC_delta);
		remain.projCrewSizeSTRT_deltaTextColor = getValueColor(remain.projCrewSizeSTRT_delta);
		remain.projCrewSizeFABR_deltaTextColor = getValueColor(remain.projCrewSizeFABR_delta);
		remain.projCrewSizeCHNG_deltaTextColor = getValueColor(remain.projCrewSizeCHNG_delta);
		remain.projCrewSizeFIELD_deltaTextColor = getValueColor(remain.projCrewSizeFIELD_delta);
		remain.projCrewSizeTOTAL_deltaTextColor = getValueColor(remain.projCrewSizeTOTAL_delta);
		remain.projHours_deltaTextColor = getValueColor(remain.projHours_delta);
		remain.projLaborCost_deltaTextColor = getValueColor(remain.projLaborCost_delta);

		return [proj, costToComp, remain];
	}
}
