import User from "../modules/Employee/classes/User";
import Company from "./Company";
import Region from "./Region";
import Department from "./Department";
import Role from "./Role";
import Leader from "../modules/Employee/classes/Leader";
import Trade from "./Trade";
import Scope from "../modules/Operations/classes/Scope";
import ProjectAcct from "../modules/Operations/classes/ProjectAcct";
import Purchaser from "../modules/Operations/classes/Purchaser";
import ComboBox from "./ComboBox";
import ValueType from "../core/Reports/classes/ValueType";

import Announcement from "../modules/Home/classes/Announcement";
import TimeOffRequest from "../modules/TimeOffRequests/classes/TimeOffRequest";
import LongevityAward from "../modules/Employee/classes/LongevityAward";
import Notification from "../modules/Employee/classes/Notification";
import CalendarEvent from "../core/Calendars/classes/CalendarEvent";

import SecurityGroup from "../modules/Employee/classes/SecurityGroup";
// import JobSecurity from "../AppData/User/JobSecurity";

import Customer from "../modules/Operations/classes/Customer";
import Vendor from "../modules/Purchasing/classes/Vendor";
import DiversityCert from "../modules/Purchasing/classes/DiversityCert";
import ProjectMgr from "../modules/Operations/classes/ProjectMgr";
import Phase from "../modules/Operations/classes/Phase";
import CostType from "../modules/Operations/classes/CostType";
import PREmployee from "../modules/Employee/classes/PREmployee";

import Contract from "../modules/Contracts/classes/Contract";
import Job from "../modules/Contracts/classes/Job";
import ContractItem from "../modules/Contracts/classes/ContractItem";
import JobPhase from "../modules/Contracts/classes/JobPhase";
import PCO from "../modules/Contracts/classes/PCO";
import ACO from "../modules/Contracts/classes/ACO";
import COItem from "../modules/Contracts/classes/COItem";
import RFI from "../modules/Contracts/classes/RFI";

import ContractTotalsByMth from "../modules/MFR/classes/ContractTotalsByMth";
import BillingsByMth from "../modules/MFR/classes/BillingsByMth";
import ManpowerProjectionsByMth from "../modules/MFR/classes/ManpowerProjectionsByMth";
import ItemTypeTotalsByMth from "../modules/MFR/classes/ItemTypeTotalsByMth";
import ItemTypeDetailByMth from "../modules/MFR/classes/ItemTypeDetailByMth";
import JobCostDetailByMth from "../modules/MFR/classes/JobCostDetailByMth";

// import moment from "moment";
// import { log } from "../helperFunctions";
// import { portalUser } from "../App";

export default class AppData extends Array {
	constructor(data = []) {
		super();

		const comboTypes = [
			"DiversityCert",
			"ReportCategory",
			"EmployeeType",
			"LeaveType",
			"Platform",
			"InsuranceType",
			"ProjectType",
			"ItemType",
			"PCOStatus",
			"ContractType",
		];

		if (Boolean(data) && data?.length > 0) {
			for (let i = 0; i < data?.length; i++) {
				// APP DATA
				if (data[i]?.QueryData === "Company") {
					this.push(new Company(data[i]));
				} else if (data[i]?.QueryData === "Region") {
					this.push(new Region(data[i]));
				} else if (data[i]?.QueryData === "Department") {
					this.push(new Department(data[i]));
				} else if (data[i]?.QueryData === "Role") {
					this.push(new Role(data[i]));
				} else if (data[i]?.QueryData === "Leader") {
					this.push(new Leader(data[i]));
				} else if (data[i]?.QueryData === "Trade") {
					this.push(new Trade(data[i]));
				} else if (data[i]?.QueryData === "ScopePct") {
					this.push(new Scope(data[i]));
				} else if (data[i]?.QueryData === "ProjectAcct") {
					this.push(new ProjectAcct(data[i]));
				} else if (data[i]?.QueryData === "Purchaser") {
					this.push(new Purchaser(data[i]));
				} else if (data[i]?.QueryData === "Phase") {
					this.push(new Phase(data[i]));
				} else if (data[i]?.QueryData === "CostType") {
					this.push(new CostType(data[i]));
				} else if (data[i]?.QueryData === "ValueType") {
					this.push(new ValueType(data[i]));
				}

				// USER DATA
				else if (data[i]?.QueryData === "User") {
					this.push(new User(data[i]));
				} else if (data[i]?.QueryData === "PREmployee") {
					this.push(new PREmployee(data[i]));
				} else if (data[i]?.QueryData === "JCProjectMgr") {
					this.push(new ProjectMgr(data[i]));
				} else if (data[i]?.QueryData === "ARCustomer") {
					this.push(new Customer(data[i]));
				} else if (data[i]?.QueryData === "APVendor") {
					this.push(new Vendor(data[i]));
				} else if (data[i]?.QueryData === "DiversityVendor") {
					this.push(new Vendor(data[i]));
				} else if (data[i]?.QueryData === "DiversityCert") {
					this.push(new DiversityCert(data[i]));
				} else if (data[i]?.QueryData === "Announcement") {
					this.push(new Announcement(data[i]));
				} else if (data[i]?.QueryData === "TimeOffRequest") {
					this.push(new TimeOffRequest(data[i]));
				} else if (data[i]?.QueryData === "Notification") {
					this.push(new Notification(data[i]));
				} else if (data[i]?.QueryData === "CalendarEvent") {
					this.push(new CalendarEvent(data[i]));
				} else if (data[i]?.QueryData === "SecurityGroup") {
					this.push(new SecurityGroup(data[i]));
				} else if (data[i]?.QueryData === "LongevityAward") {
					this.push(new LongevityAward(data[i]));
				}

				// CONTRACT DATA
				else if (data[i]?.QueryData === "Contract") {
					this.push(new Contract(data[i]));
				} else if (data[i]?.QueryData === "Job") {
					this.push(new Job(data[i]));
				} else if (data[i]?.QueryData === "ContractItem") {
					this.push(new ContractItem(data[i]));
				} else if (data[i]?.QueryData === "JobPhase") {
					this.push(new JobPhase(data[i]));
				} else if (data[i]?.QueryData === "ACO") {
					this.push(new ACO(data[i]));
				} else if (data[i]?.QueryData === "PCO") {
					this.push(new PCO(data[i]));
				} else if (data[i]?.QueryData === "COItem") {
					this.push(new COItem(data[i]));
				} else if (data[i]?.QueryData === "RFI") {
					this.push(new RFI(data[i]));
				} else if (data[i]?.QueryData === "ContractTotalsByMth") {
					this.push(new ContractTotalsByMth(data[i]));
				} else if (data[i]?.QueryData === "BillingsByMth") {
					this.push(new BillingsByMth(data[i]));
				} else if (data[i]?.QueryData === "ManpowerProjectionsByMth") {
					this.push(new ManpowerProjectionsByMth(data[i]));
				} else if (data[i]?.QueryData === "JobCostDetailByMth") {
					this.push(new JobCostDetailByMth(data[i]));
				} else if (data[i]?.QueryData === "ItemTypeTotalsByMth") {
					this.push(new ItemTypeTotalsByMth(data[i]));
				} else if (data[i]?.QueryData === "ItemTypeDetailByMth") {
					this.push(new ItemTypeDetailByMth(data[i]));
				} else if (comboTypes.includes(data[i]?.QueryData)) {
					this.push(new ComboBox(data[i]));
				}
			}
		}
	}

	async newPCO(jccmKeyID = null) {
		let contractData = this;
		let contract = contractData?.filter((d) => d?.queryData === "Contract" && d.jccmKeyID === jccmKeyID) ?? [];
		if (Boolean(contract?.length > 0)) {
			contract = contract[0];
		} else {
			contract = new Contract();
		}

		let existingPCOs = contractData?.filter((d) => d?.queryData === "PCO" && d.jccmKeyID === jccmKeyID) ?? [];
		let newPCONumber = null;
		if (existingPCOs.length > 0) {
			newPCONumber = existingPCOs[existingPCOs.length - 1].pco;
			newPCONumber = newPCONumber?.replace(/[^\d]/g, "") ?? null;
			newPCONumber = parseInt(newPCONumber) + 1;
			if (isNaN(newPCONumber)) {
				newPCONumber = null;
			} else {
				newPCONumber = newPCONumber.toString()?.toUpperCase();
			}
		}

		let pco = new PCO({
			jccmKeyID: contract?.jccmKeyID,
			jcco: contract?.jcco,
			contract: contract?.contract,
			pco: newPCONumber,
			dateSubmitted: new Date(),
		});
		pco = await pco.addCOItem();

		return pco;
	}
}
