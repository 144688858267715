const HR_ADMIN_TABS = [
	{
		name: "HR Employees",
		tab: "employees",
		heading: "HR Employees",
		subheading: "Employee Management",
		pageOrientation: "landscape",
		// joyrideSteps: JOYRIDE_MYTEAM,
	},
	{
		name: "HR Calendar",
		tab: "calendar",
		heading: "HR Calendar",
		subheading: "Vacation Calendar",
		pageOrientation: "landscape",
		// joyrideSteps: JOYRIDE_MYTEAM_PERFORMANCEREVIEWS,
	},
	{
		name: "HR Reviews",
		tab: "reviews",
		heading: "HR Reviews",
		subheading: "Performance Reviews",
		pageOrientation: "landscape",
		// joyrideSteps: JOYRIDE_MYTEAM_PERFORMANCEREVIEWS,
	},
	{
		name: "HR Forms",
		tab: "forms",
		heading: "HR Forms",
		subheading: "Past HR Forms",
		pageOrientation: "landscape",
		// joyrideSteps: JOYRIDE_MYTEAM_PERFORMANCEREVIEWS,
	},
	{
		name: "HR Templates",
		tab: "templates",
		heading: "HR Templates",
		subheading: "Forms & Templates",
		pageOrientation: "landscape",
		// joyrideSteps: JOYRIDE_MYTEAM_PERFORMANCEREVIEWS,
	},
	{
		name: "HR Longevity",
		tab: "longevity",
		heading: "HR Longevity",
		subheading: "Longevity Awards",
		pageOrientation: "landscape",
		// joyrideSteps: JOYRIDE_MYTEAM_PERFORMANCEREVIEWS,
	},
];
export default HR_ADMIN_TABS;
