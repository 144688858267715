import React, { useMemo } from "react";
import ReactMultiSelect from "../../Inputs/select/MultiSelectInput";
import { formatValue } from "../../../helperFunctions";
// This is a custom filter UI for selecting
// a unique option from a list
export default function MultiSelectFilter({
	column: { filterValue, setFilter, preFilteredRows, id, decimals, valueType },
}) {
	// Calculate the options for filtering
	// using the preFilteredRows
	const options = useMemo(() => {
		const options = new Set();
		preFilteredRows?.forEach((row) => {
			options.add(row.values[id]);
		});
		const optionSet = [...options.values()];
		let optionArr = [];
		for (let i = 0; i < optionSet?.length; i++) {
			optionArr.push({
				value: optionSet[i],
				label: formatValue(optionSet[i], decimals, valueType),
				color: "blue",
			});
		}
		return optionArr;
	}, [decimals, id, preFilteredRows, valueType]);

	// using the preFilteredRows
	const optionSelected = useMemo(() => {
		let selectedArr = [];
		let values = [...new Set(options.map((o) => o.value))];
		for (let i = 0; i < filterValue?.length; i++) {
			if (values.includes(filterValue[i]) || filterValue[i] === "*") {
				selectedArr.push({ value: filterValue[i], label: filterValue[i], color: "blue" });
			}
		}
		return selectedArr;
	}, [filterValue, options]);

	// Render a multi-select box
	const handleMultiSelectChange = (e) => {
		let values = [...new Set(e.map((v) => v.value))];
		setFilter(values);
	};

	return (
		<ReactMultiSelect
			options={options}
			isMulti
			closeMenuOnSelect={false}
			hideSelectedOptions={false}
			onChange={handleMultiSelectChange}
			allowSelectAll={true}
			value={optionSelected}
		/>
	);
}
