import React, { useState } from "react";
import { Box, Stack, Text, useBoolean, Tag, TagLabel, TagCloseButton, useToast } from "@chakra-ui/react";
import BasicCard from "../../../core/Layout/cards/BasicCard";
import BasicDrawer from "../../../core/Layout/drawers/BasicDrawer";
import DataInput from "../../../core/Inputs/data/DataInput";
import DFSequence from "../classes/DFSequence";

import { log } from "../../../helperFunctions";
import moment from "moment";

export default function DFScheduleDrawer({ dfJob, isOpen, onClose, setUpdateTrigger }) {
	const toast = useToast();
	// const [task, setTask] = useState(new DFSequence({ jobID: dfJob.jobID }));
	let newTask = new DFSequence({ jobID: dfJob.jobID });
	const [dynamicAttributes, setDynamicAttributes] = useState({});
	const [staticAttributes, setStaticAttributes] = useState();
	// const [taskArray, setTaskArray] = useState([]);
	const [multiplePipingLevelsIsActive] = useBoolean();
	const [multipleDeckingLevelsIsActive] = useBoolean();
	const [multipleAreaIsActive] = useBoolean();
	const [multipleQuadIsActive] = useBoolean();
	const [multipleSystemIsActive] = useBoolean();
	const [multipleCommentsIsActive] = useBoolean();
	const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
	// const [multipleLinesDetected, setMultiLineDetected] = useBoolean();

	// useEffect(() => {
	// 	createTasks();
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [dynamicAttributes, staticAttributes]);

	// Update the task object based on form input
	const updateTask = (e) => {
		let value = e.target.value;
		let name = e.target.name;
		newTask[name] = value;
		log("newTask", newTask);
	};

	// const updateTask = (ev) => {
	// 	let value = ev.target.value;
	// 	let name = ev.target.name;
	// 	if (ev.multiple) {
	// 		addDynamicValue(ev);
	// 	} else {
	// 		task[name] = value;
	// 		let newTask = new DFSequence(task);
	// 		setTask(newTask);
	// 		modifyStaticValue(ev);
	// 	}
	// };

	// // Update the system attribute of the task
	// const updateSystem = (ev) => {
	// 	let value = ev.value;
	// 	let name = ev.name;
	// 	if (ev.multiple) {
	// 		addDynamicValue(ev);
	// 	} else {
	// 		task[name] = value;
	// 		let newTask = new DFSequence(task);
	// 		setTask(newTask);
	// 		modifyStaticValue(ev);
	// 	}
	// };

	const updateInstallDate = (date) => {
		// task.installStart = moment(new Date(date)).format("MM/DD/YYYY");
		newTask.installStart = moment(new Date(date)).format("MM/DD/YYYY");
		// setTask(task);
		// modifyStaticValue({ target: { name: "installStart", value: new Date(date) } });
	};

	// // Modify the static attribute of the task
	// const modifyStaticValue = (ev) => {
	// 	let value = ev.target?.value ?? ev.value;
	// 	let name = ev.target?.name ?? ev.name;

	// 	let staticAttrs = {};
	// 	Object.assign(staticAttrs, staticAttributes);

	// 	staticAttrs[name] = value;

	// 	let dynamic = {};
	// 	Object.assign(dynamic, dynamicAttributes);
	// 	if (Boolean(dynamic[name])) {
	// 		delete dynamic[name];
	// 	}

	// 	setStaticAttributes(staticAttrs);
	// 	setDynamicAttributes(dynamic);
	// };

	const addDynamicValue = (ev) => {
		let value = ev?.target?.value ?? ev.value;
		let name = ev?.target?.name ?? ev.name;

		let dynamic = {};
		Object.assign(dynamic, dynamicAttributes);
		if (!Boolean(dynamic[name])) {
			dynamic[name] = [];
		}
		dynamic[name].push(value);

		let staticAttrs = {};
		Object.assign(staticAttrs, staticAttributes);
		if (Boolean(staticAttrs)) {
			delete staticAttrs[name];
		}

		// log(dynamic);
		setDynamicAttributes(dynamic);
		setStaticAttributes(staticAttrs);
	};

	const removeDynamicValue = (ev) => {
		let name = ev.name;
		let value = ev.value;

		let dynamic = {};
		Object.assign(dynamic, dynamicAttributes);
		let foundIndex = dynamic[name].indexOf(value);
		if (foundIndex !== -1) {
			dynamic[name].splice(foundIndex, 1);
		}
		// log(dynamic);
		setDynamicAttributes(dynamic);
	};

	// // Generate combinations of dynamic attributes to create multiple tasks
	// const combinations = (map, currKey = 0, prevVals = [], arr = [], seq = dfJob?.tasks?.length ?? 0) => {
	// 	let keys = Object.keys(map);

	// 	if (currKey === keys.length) {
	// 		let newTask = new DFSequence();
	// 		// newTask.scheduleId = dfJob?.id;
	// 		newTask.jobID = dfJob?.id;
	// 		newTask.seq = seq;
	// 		for (let i = 0; i < keys.length; i++) {
	// 			newTask[keys[i]] = prevVals[i];
	// 		}

	// 		return [...arr, newTask];
	// 	}

	// 	for (let j = 0; j < map[keys[currKey]].length; j++) {
	// 		arr = [...combinations(map, currKey + 1, [...prevVals, map[keys[currKey]][j]], arr, seq)];
	// 	}
	// 	return arr;
	// };

	// const createTasks = () => {
	// 	let dynamic = dynamicAttributes;
	// 	// let dynamicAttrs = Object.keys(dynamic);
	// 	let tasks = combinations(dynamic);

	// 	for (let i = 0; i < tasks.length; i++) {
	// 		for (let staticAttr in staticAttributes) {
	// 			tasks[i][staticAttr] = staticAttributes[staticAttr];
	// 		}
	// 	}

	// 	setTaskArray([...tasks]);
	// };

	const handleSubmit = async () => {
		setSubmitButtonDisabled(true);
		let response = newTask;
		if (response?.id) {
			let result = await response.updateDB();
			if (result.status === 200) {
				onClose();
				toast({
					title: "Success!",
					description: "Task updated successfully!",
					status: "success",
					duration: 5000,
					isClosable: true,
				});
			} else {
				toast({
					title: "Error",
					description: "There was an error while trying to update the task.",
					status: "error",
					duration: 5000,
					isClosable: true,
				});
			}
		} else {
			let result = await response.insertDB();
			if (result.status === 200) {
				onClose();
				toast({
					title: "Success!",
					description: "Task added successfully!",
					status: "success",
					duration: 5000,
					isClosable: true,
				});
			} else {
				toast({
					title: "Error",
					description: "There was an error while trying to add a new  task.",
					status: "error",
					duration: 5000,
					isClosable: true,
				});
			}
		}
		setSubmitButtonDisabled(false);
		// setTask(new DFSequence({ jobID: dfJob.jobID }));
	};

	const handlePaste = (ev) => {
		let rows = ev.clipboardData.getData("text");
		let name = ev.target.name;
		rows = rows.split("\r\n");
		if (ev.multiple) {
			for (let i = 0; i < rows.length; i++) {
				if (rows[i].length > 0) {
					addDynamicValue({ target: { name: name, value: rows[i] } });
				}
			}
		}
	};

	// const initAttributeArr = (attr) => {
	// 	let dynamic = {};
	// 	Object.assign(dynamic, dynamicAttributes);
	// 	if (!Boolean(dynamic[attr])) {
	// 		dynamic[attr] = [];
	// 		setDynamicAttributes(dynamic);
	// 	}
	// };

	return (
		<BasicDrawer
			isOpen={isOpen}
			title="New Task"
			onSubmit={handleSubmit}
			isSubmitting={submitButtonDisabled}
			onClose={onClose}
		>
			<Box>
				<BasicCard title="Base Info">
					<Stack bg="white" borderRadius="md" p={2}>
						<Stack spacing={0}>
							<DataInput
								isDisabled
								name="job"
								label="Job Number"
								type="text"
								defaultValue={dfJob.job + " " + dfJob.jobName}
							/>
							<DataInput
								name="cpmSchedID"
								type="text"
								label="CPM Schedule ID"
								placeholder="CPM Schedule ID"
								defaultValue={newTask?.cpmSchedID}
								onBlur={updateTask}
								isModal
							/>
							<DataInput
								name="shopDrawings"
								type="text"
								label="Shop Drawings"
								placeholder="Shop Drawings"
								defaultValue={newTask?.shopDrawings}
								onBlur={updateTask}
								isModal
							/>
							<DataInput
								name="pipingLevel"
								type="text"
								label={
									<Stack direction="row" spacing={3}>
										<Text>Level</Text>
									</Stack>
								}
								placeholder="Piping Level"
								inputProps={{ autoComplete: "off", textTransform: "none" }}
								defaultValue={newTask?.pipingLevel}
								onBlur={updateTask}
								isModal
								multiple={multiplePipingLevelsIsActive}
								handlePaste={handlePaste}
							/>
							{/* <Stack direction="row" wrap="wrap" spacing={0} divider={<Box px={1} border="none" />}>
								{dynamicAttributes["pipingLevel"]?.map((value, i) => (
									<Tag>
										<TagCloseButton
											onClick={() => {
												removeDynamicValue({ name: "pipingLevel", value: value });
											}}
										/>
										<TagLabel>{value}</TagLabel>
									</Tag>
								))}
							</Stack> */}
						</Stack>
						{dfJob?.scopeID === "4" && (
							<DataInput
								name="deckingLevel"
								type="text"
								label={
									<Stack direction="row" spacing={3}>
										<Text>Decking Level</Text>
									</Stack>
								}
								placeholder="Decking Level"
								inputProps={{ autoComplete: "off", textTransform: "none" }}
								defaultValue={newTask?.deckingLevel}
								onBlur={updateTask}
								isModal
								multiple={multipleDeckingLevelsIsActive}
								handlePaste={handlePaste}
							/>
						)}
						<DataInput
							name="area"
							type="text"
							label={
								<Stack direction="row" spacing={3}>
									<Text>Area</Text>
								</Stack>
							}
							placeholder="Area"
							defaultValue={newTask?.area}
							onBlur={updateTask}
							isModal
							multiple={multipleAreaIsActive}
						/>
						{/* <Stack direction="row" wrap="wrap" spacing={0} divider={<Box px={1} border="none" />}>
							{dynamicAttributes["area"]?.map((value, i) => (
								<Tag>
									<TagCloseButton
										onClick={() => {
											removeDynamicValue({ name: "area", value: value });
										}}
									/>
									<TagLabel>{value}</TagLabel>
								</Tag>
							))}
						</Stack> */}
						<DataInput
							name="quad"
							type="text"
							label={
								<Stack direction="row" spacing={3}>
									<Text>Quad</Text>
								</Stack>
							}
							placeholder="Quad"
							defaultValue={newTask?.quad}
							onBlur={updateTask}
							isModal
							multiple={multipleQuadIsActive}
						/>

						<Stack direction="row" wrap="wrap" spacing={0} divider={<Box px={1} border="none" />}>
							{dynamicAttributes["quad"]?.map((value, i) => (
								<Tag>
									<TagCloseButton
										onClick={() => {
											removeDynamicValue({ name: "quad", value: value });
										}}
									/>
									<TagLabel>{value}</TagLabel>
								</Tag>
							))}
						</Stack>

						<DataInput
							name="system"
							type="text"
							label={
								<Stack direction="row" spacing={3}>
									<Text>System</Text>
								</Stack>
							}
							placeholder="System"
							defaultValue={newTask?.system}
							onBlur={updateTask}
							isModal
							multiple={multipleSystemIsActive}
						/>
						<Stack direction="row" wrap="wrap" spacing={0} divider={<Box px={1} border="none" />}>
							{dynamicAttributes["system"]?.map((value, i) => (
								<Tag>
									<TagCloseButton
										onClick={() => {
											removeDynamicValue({ name: "system", value: value });
										}}
									/>
									<TagLabel>{value}</TagLabel>
								</Tag>
							))}
						</Stack>
						<DataInput
							name="comments"
							type="text"
							label={
								<Stack direction="row" spacing={3}>
									<Text>Comments</Text>
								</Stack>
							}
							placeholder="Comments"
							defaultValue={newTask?.comments}
							onBlur={updateTask}
							isModal
							multiple={multipleCommentsIsActive}
						/>
						<Stack direction="row" wrap="wrap" spacing={0} divider={<Box px={1} border="none" />}>
							{dynamicAttributes["comments"]?.map((value, i) => (
								<Tag>
									<TagCloseButton
										onClick={() => {
											removeDynamicValue({ name: "comments", value: value });
										}}
									/>
									<TagLabel>{value}</TagLabel>
								</Tag>
							))}
						</Stack>

						<DataInput
							name="installStart"
							type="date"
							label="Install Start"
							searchable
							onChange={(date) => updateInstallDate(date)}
							value={Boolean(newTask?.installStart) ? new Date(newTask.installStart) : null}
							isModal
						/>
					</Stack>
				</BasicCard>

				{/* <Stack spacing={0} divider={<StackDivider />}>
					{taskArray?.map((task, i) => (
					<Stack direction="row">
						<Box flex={1}>{newTask?.cpmSchedID}</Box>
						<Box flex={1}>{newTask?.shopDrawings}</Box>
						<Box flex={1}>{newTask?.pipingLevel}</Box>
						{dfJob?.scopeID === "4" && <Box flex={1}>{newTask?.deckingLevel}</Box>}
						<Box flex={1}>{newTask?.area}</Box>
						<Box flex={1}>{newTask?.quad}</Box>
						<Box flex={1}>{newTask?.system}</Box>
						<Box flex={1}>{newTask?.comments}</Box>
					</Stack>
					))}
				</Stack> */}
			</Box>
		</BasicDrawer>
	);
}
