import React, { useState, useCallback, useEffect, useMemo } from "react";
import {
	Box,
	Center,
	Badge,
	Heading,
	Avatar,
	Stack,
	Text,
	Tooltip,
	Button,
	StackDivider,
	useToast,
	Switch as ToggleSwitch,
	Divider,
} from "@chakra-ui/react";

import moment from "moment";
import { v4 } from "uuid";
import { portalUser } from "../../../App";

import DataInput from "../../../core/Inputs/data/DataInput";
import LabelValuePair from "../../../core/Reports/components/LabelValuePair";
import ConfirmationPrompt from "../../../core/Alerts/ConfirmationPrompt";
import BasicDrawer from "../../../core/Layout/drawers/BasicDrawer";
import ToastPopup from "../../../core/Alerts/ToastPopup";

import { convertArrayToMap, log } from "../../../helperFunctions";

export default function HRReviewsDrawer({ onClose, form }) {
	const toast = useToast();
	const [updateTrigger, setUpdateTrigger] = useState(false);
	const isPortalAdmin = portalUser.user.isPortalAdminYN === "Y";
	const users = portalUser.userData?.filter((d) => d.queryData === "User");

	const [currentForm, setCurrentForm] = useState(form);
	const [reviewDate, setReviewDate] = useState(null);
	const [toastStatus, setToastStatus] = useState(null);
	const [toastUpdates, setToastUpdates] = useState({});
	const [toastTitle, setToastTitle] = useState(null);
	const [commenters, setCommenters] = useState(null);

	const [assignedToUser, setAssignedToUser] = useState(null);
	const [assignedForUser, setAssignedForUser] = useState(null);
	// const [sitDownReviewer, setSitDownReviewer] = useState(null);
	const toastShown = () => {
		setToastStatus(null);
		setToastUpdates({});
		setToastTitle(null);
	};

	// const formTemplateOptions = useMemo(() => {
	// 	let forms = portalUser.formData?.forms ?? [];
	// 	forms = forms?.filter(
	// 		(form) => form.queryData === "FormTemplate" && form.formYear === new Date().getFullYear()
	// 	);
	// 	// if (Boolean(props.selectedYear)) {
	// 	// 	forms = forms?.filter((d) => d.formYear?.toString() === props.selectedYear?.toString());
	// 	// }
	// 	return forms;
	// }, []);

	// const assignedForUser = useMemo(() => usersMap[form?.assignedFor], [form?.assignedFor, usersMap]);
	// const assignedToUser = useMemo(() => usersMap[form?.assignedTo], [form?.assignedTo, usersMap]);

	// const loadCommenters = useCallback(async () => {
	// 	let commenterResult = await formsAPI.GetFormCommenters(currentForm?.formUID);
	// 	if (commenterResult.status === 200 && commenterResult.value?.length > 0) {
	// 		let userList = new PPMUserList();
	// 		await userList.getUsersByArray(Object.keys(convertArrayToMap(commenterResult.value, "EmployeeUID")));
	// 		setCommenters(convertArrayToMap(commenterResult.value, "EmployeeUID"));
	// 		setCommenterUsers(userList);
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [currentForm?.formUID]);

	const getUser = useCallback(async (form, role = "assignedTo") => {
		let user;
		if (role === "assignedTo") {
			user = await portalUser.getUser(form?.assignedTo, "employeeUID");
			setAssignedToUser(user);
		} else if (role === "assignedFor") {
			user = await portalUser.getUser(form?.assignedFor, "employeeUID");
			setAssignedForUser(user);
		}
		// else if (role === "sitDownRevfiewer") {
		// 	user = await portalUser.getUser(form?.sitDownReviewer, "employeeUID");
		// 	setSitDownReviewer(user);
		// }
	}, []);

	useMemo(() => {
		setCurrentForm(form);
		getUser(form, "assignedTo");
		getUser(form, "assignedFor");
	}, [form, getUser]);

	var formStatus = currentForm?.getFormStatus();
	var isVisibilityDisabled = currentForm?.getIsVisibilityDisabled();

	const updateReviewDate = (date) => {
		setReviewDate(date);
		toast({
			title: "Success!",
			description: "Review date set!",
			status: "success",
			duration: 5000,
			isClosable: true,
		});
	};

	const setReviewDateMeeting = async () => {
		//TODO: Delete existing meeting
		// log("review date", reviewDate);
		let date = new Date(reviewDate);
		let endDate = new Date(reviewDate);
		endDate.setHours(endDate.getHours() + 1);
		let meetingEvent = {
			subject: currentForm?.title + " - " + assignedForUser?.name,
			body: {
				contentType: "HTML",
				content:
					"<p>Hello " +
					assignedForUser?.name +
					", <br /><br />" +
					`I look forward to meeting with you for your ${currentForm?.title}.<br /></p>`,
			},
			start: {
				dateTime: date,
				timeZone: "Pacific Standard Time",
			},
			end: {
				dateTime: endDate,
				timeZone: "Pacific Standard Time",
			},
			attendees: [
				{
					emailAddress: { address: assignedForUser?.email, name: assignedForUser?.name },
					type: "required",
				},
				{ emailAddress: { address: assignedToUser?.email, name: assignedToUser?.name }, type: "required" },
			],
			isOnlineMeeting: true,
			onlineMeetingProvider: "teamsForBusiness",
			allowNewTimeProposals: false,
			reminderMinutesBeforeStart: 15,
			isReminderOn: true,
			responseRequested: true,
		};
		if (Boolean(reviewDate)) {
			if (Boolean(currentForm?.sitDownReviewer)) {
				let sitDownReviewerEmployee = portalUser.getUsers(); //Was new PPMUserList()
				await sitDownReviewerEmployee.getUsersByArray([currentForm?.sitDownReviewer]);
				sitDownReviewerEmployee = sitDownReviewerEmployee[0];
				meetingEvent.attendees.push({
					emailAddress: { address: sitDownReviewerEmployee?.email, name: sitDownReviewerEmployee?.name },
					type: "optional",
				});
				// log("SitDownReviewer", [sitDownReviewerEmployee, meetingEvent]);
			}
			portalUser.msClient.CreateMSEvent(meetingEvent);
			let tStatus = "Success";
			let tUpdates = {
				uploadId: v4(),
			};
			let tTitle = "Calendar Invite Sent!";
			setToastTitle(tTitle);
			setToastStatus(tStatus);
			setToastUpdates(tUpdates);
			await currentForm?.updateForm("dateReview", date);
			await currentForm.sendReviewDateEmail(currentForm, date);
		}
		setCurrentForm(form);
		setUpdateTrigger(true);
	};

	const sendReminder = async () => {
		await currentForm.sendReminder(currentForm);
		setUpdateTrigger(true);
		toast({
			title: "Success!",
			description: "Reminder sent!",
			status: "success",
			duration: 5000,
			isClosable: true,
		});
	};

	const resetForm = async () => {
		let form = await currentForm?.resetForm(currentForm?.formUID);
		setCurrentForm(form);
		currentForm.dateSubmitted = null;
		currentForm.dateApproved = null;
		currentForm.reviewDate = null;
		currentForm.dateCompleted = null;
		setUpdateTrigger(true);
		toast({
			title: "Success!",
			description: "Form Reset! You may need to refresh the page to see the changes.",
			status: "success",
			duration: 5000,
			isClosable: true,
		});
	};

	const resetFormDates = async () => {
		let form = await currentForm?.resetFormDates(currentForm?.formUID);
		setCurrentForm(form);
		currentForm.dateSubmitted = null;
		currentForm.dateApproved = null;
		currentForm.reviewDate = null;
		currentForm.dateCompleted = null;
		setUpdateTrigger(true);
		toast({
			title: "Success!",
			description: "Form dates reset! You may need to refresh the page to see the changes.",
			status: "success",
			duration: 5000,
			isClosable: true,
		});
	};

	const deleteForm = async () => {
		let form = await currentForm?.deleteForm(currentForm?.formUID);
		setCurrentForm(form);
		setUpdateTrigger(true);
		toast({
			title: "Success!",
			description: "Form Deleted! You may need to refresh the page to see the changes.",
			status: "success",
			duration: 5000,
			isClosable: true,
		});
		onClose();
	};

	useEffect(() => {
		setReviewDateMeeting();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reviewDate]);

	useEffect(() => {
		if (updateTrigger) {
			setUpdateTrigger(false);
		}
	}, [updateTrigger]);

	useEffect(() => {
		let commentersMap = convertArrayToMap(portalUser.formData?.commenters, "formUID", true);
		setCommenters(commentersMap[form.formUID]);
		// log("commenters", commenters);
	}, [form.formUID]);

	return (
		<BasicDrawer isOpen={Boolean(form)} onClose={onClose} title="Review Details">
			<Stack color="gray.600">
				<Stack spacing={0}>
					{/* <Heading size="md" color="gray.600">
						{isPortalAdmin ? (
							<DataInput
								name="form"
								label="Form"
								type="select"
								placeholder="Select Form"
								value={currentForm?.formTitle}
								options={formTemplateOptions}
								// disabledOptions={users?.filter((d) => d.userStatus !== "Active")}
								optionValue="formTitle"
								optionText="formTitle"
								optionSubheading="formSubtitle"
								searchable
								isModal
								// onOptionSelect={async (e) => {
								// 	if (e !== currentForm?.templateUID) {
								// 		let form = await currentForm.updateForm("templateUID", e);
								// 		setCurrentForm(form);
								// 		setUpdateTrigger(true);
								// 	}
								// }}
							/>
						) : (
							<Text fontSize={"sm"} color="gray.500">
								{currentForm?.title}
							</Text>
						)}
					</Heading> 
					<Heading fontSize={"sm"} color="red.500">
						Warning: Changing the Form Type deletes the current year review and creates a new
						one.
					</Heading>
					*/}
					<Text fontSize={"sm"} color="gray.500">
						{currentForm?.title}
					</Text>
					<Text fontSize={"sm"} color="gray.500">
						{currentForm?.subtitle}
					</Text>
					{portalUser?.user?.isDevOpsUserYN === "Y" && (
						<Text fontSize={"sm"} color="gray.500">
							{currentForm.formUID}
						</Text>
					)}
				</Stack>

				<Stack direction="row">
					<Badge colorScheme={formStatus?.color}>{formStatus?.status}</Badge>
					{Boolean(currentForm?.dateDue) && !Boolean(currentForm?.dateCompleted) && (
						<Badge colorScheme="red">DUE: {moment(currentForm?.dateDue).format("MM/DD/YYYY")}</Badge>
					)}
				</Stack>
				<Stack direction="row" align={"center"} justifyContent="space-between" spacing={0}>
					<Center>
						<Avatar size="sm" src={assignedToUser?.profilePhoto} name={assignedToUser?.name ?? null} />
					</Center>
					<Box flex={1} pl={2}>
						{isPortalAdmin ? (
							<DataInput
								name="assignedTo"
								label="Reviewer"
								type="select"
								placeholder="Select Reviewer"
								value={currentForm?.assignedTo}
								options={users}
								disabledOptions={users?.filter((d) => d.userStatus !== "Active")}
								optionValue="employeeUID"
								optionText="name"
								optionSubheading="title"
								searchable
								isModal
								onOptionSelect={async (e) => {
									if (e !== currentForm?.assignedTo) {
										let form = await currentForm.updateForm("assignedTo", e);
										setCurrentForm(form);
										getUser(form, "assignedTo");
										setUpdateTrigger(true);
										toast({
											title: "Success!",
											description: "Review has been reassigned!",
											status: "success",
											duration: 5000,
											isClosable: true,
										});
									}
								}}
							/>
						) : (
							<LabelValuePair
								variant="smallText"
								direction="vertical"
								label="Employee"
								value={currentForm?.assignedToName}
							/>
						)}
					</Box>
				</Stack>

				<Stack direction="row" align={"center"} justifyContent="space-between" spacing={0}>
					<Center>
						<Avatar size="sm" src={assignedForUser?.profilePhoto} name={assignedForUser?.name} />
					</Center>
					<Box flex={1} pl={2}>
						<LabelValuePair
							variant="smallText"
							direction="vertical"
							label="Employee"
							value={currentForm?.assignedForName}
						/>
					</Box>
				</Stack>

				{/* {form?.formType === "3" && form?.formUID === form?.parentFormUID && (
					<Stack direction="row" align={"center"} justifyContent="space-between" spacing={0}>
						<Center>
							<Avatar size="sm" src={sitDownReviewer?.profilePhoto} name={sitDownReviewer?.name} />
						</Center>
						<Box flex={1} pl={2}>
							{isPortalAdmin ? (
								<DataInput
									name="sitDownReviewer"
									label="Sit Down Reviewer NOT FUNCTIONAL"
									type="select"
									placeholder="Select Sit Down Reviewer"
									value={currentForm?.sitDownReviewer}
									options={users}
									disabledOptions={users?.filter((d) => d.userStatus !== "Active")}
									optionValue="employeeUID"
									optionText="name"
									optionSubheading="title"
									searchable
									isModal
									onOptionSelect={async (e) => {
										if (e !== currentForm?.sitDownReviewer) {
											let form = await currentForm.updateForm("sitDownReviewer", e);
											setCurrentForm(form);
											getUser(form, "sitDownReviewer");
											setUpdateTrigger(true);
											toast({
												title: "Success!",
												description: "Sit Down Reviewer has been reassigned!",
												status: "success",
												duration: 5000,
												isClosable: true,
											});
										}
									}}
								/>
							) : (
								<LabelValuePair
									variant="smallText"
									direction="vertical"
									label="Employee"
									value={currentForm?.sitDownReviewerName}
								/>
							)}
						</Box>
					</Stack>
				)} */}
			</Stack>
			<Stack color="gray.600" mt={6} divider={<StackDivider />}>
				<Stack direction="row" align="center" justifyContent="space-between">
					<LabelValuePair
						variant="smallText"
						direction="vertical"
						label="Submitted Date"
						value={
							Boolean(currentForm?.dateSubmitted)
								? moment(currentForm?.dateSubmitted).format("MM/DD/YYYY")
								: "NO DATE"
						}
					/>
					{Boolean(isPortalAdmin) && currentForm?.assignedTo !== portalUser.user.employeeUID && (
						<Button
							variant="outline"
							size="sm"
							colorScheme="gray"
							isDisabled={Boolean(currentForm?.dateSubmitted)}
							onClick={() => {
								sendReminder();
							}}
						>
							Send Reminder
						</Button>
					)}
				</Stack>

				<Stack direction="row" align="center" justifyContent="space-between">
					<LabelValuePair
						variant="smallText"
						direction="vertical"
						label="Approved Date"
						color={currentForm?.requiresHRApprovalYN === "Y" ? "gray.500" : "gray.300"}
						value={
							Boolean(currentForm?.dateApproved)
								? moment(currentForm?.dateApproved).format("MM/DD/YYYY")
								: currentForm?.requiresHRApprovalYN === "Y"
								? "NO DATE"
								: "NOT REQUIRED"
						}
					/>
					{parseInt(formStatus.id) > 1 && isPortalAdmin && (
						<Button
							textTransform={"uppercase"}
							letterSpacing={1}
							variant={currentForm?.dateApproved ? "outline" : "solid"}
							colorScheme={currentForm?.dateApproved ? "gray" : "green"}
							// isDisabled={formStatus?.status !== "APPROVED"}
							onClick={async () => {
								let form = await currentForm.updateForm(
									"dateApproved",
									currentForm?.dateApproved ? null : new Date()
								);
								setCurrentForm(form);
								setUpdateTrigger(true);
							}}
						>
							{currentForm?.dateApproved ? "Unapprove" : "Approve"}
						</Button>
					)}
				</Stack>

				<Stack direction="row" align="center" justifyContent="space-between">
					<LabelValuePair
						variant="smallText"
						direction="vertical"
						label="Reviewed Date"
						color={Boolean(currentForm?.requiresReviewYN === "Y") ? "gray.500" : "gray.300"}
						value={
							Boolean(currentForm?.dateReview)
								? // ? moment(currentForm?.dateReview).format("MM/DD/YYYY hh:mm a")
								  moment.utc(currentForm?.dateReview).format("MM/DD/YYYY hh:mm a")
								: Boolean(currentForm?.requiresReviewYN === "Y")
								? "NO DATE"
								: "NOT REQUIRED"
						}
					/>
					<Box>
						{((parseInt(formStatus.id) > 1 && portalUser.user.employeeUID === currentForm.assignedTo) ||
							Boolean(isPortalAdmin)) && (
							<DataInput
								name="reviewDate"
								size="sm"
								type="date"
								showTimeSelect
								placeholder="Set Review Date"
								minDate={new Date(new Date().setDate(new Date().getDate()))}
								onChange={updateReviewDate}
								onCalendarClose={setReviewDateMeeting}
								value={reviewDate}
								showPlaceholder={true}
								borderBottomColor="black"
								colorScheme="black"
								isModal
							/>
						)}
					</Box>
					{/* ) : Boolean(isPortalAdmin) &&
					  Boolean(currentForm?.requiresReviewYN === "Y") &&
					  currentForm?.assignedTo !== portalUser.user.employeeUID ? (
						<Button
							variant="outline"
							size="sm"
							colorScheme="gray"
							isDisabled={Boolean(currentForm?.dateCompleted)}
							onClick={() => {
								sendReminder();
							}}
						>
							Send Reminder
						</Button>
					) : (
						<></>
					)} */}
				</Stack>

				<Stack direction="row" align="center" justifyContent="space-between">
					<LabelValuePair
						variant="smallText"
						direction="vertical"
						label="Completed Date"
						value={
							Boolean(currentForm?.dateCompleted)
								? moment(currentForm?.dateCompleted).format("MM/DD/YYYY")
								: "NO DATE"
						}
					/>
					{Boolean(isPortalAdmin) && (
						// currentForm?.assignedTo !== portalUser.user.employeeUID && (
						<Button
							variant="outline"
							size="sm"
							colorScheme="gray"
							isDisabled={Boolean(currentForm?.dateCompleted)}
							onClick={() => {
								sendReminder();
							}}
						>
							Send Reminder
						</Button>
					)}
				</Stack>
			</Stack>

			<Stack direction={"row"} justify="space-between" mt={6} alignItems="center">
				<LabelValuePair
					variant="smallText"
					direction="vertical"
					label="Form Visibility"
					color={
						Boolean(currentForm?.requiresReviewYN === "Y") && !Boolean(currentForm?.parentFormUID)
							? "gray.500"
							: "gray.300"
					}
					value={
						Boolean(currentForm?.isVisibleYN === "Y") ? "Visible" : "Not Visible"
						// : currentForm?.requiresReviewYN === "Y" && !Boolean(currentForm?.parentFormUID)
						// ? "Not Visible"
						// : "NOT APPLICABLE"
					}
				/>
				{(Boolean(isPortalAdmin) || portalUser.user.employeedUID !== currentForm?.assignedTo) && (
					<Center style={{ cursor: "loading" }}>
						<ToggleSwitch
							isChecked={currentForm?.isVisibleYN === "Y"}
							isDisabled={isVisibilityDisabled}
							onChange={async () => {
								let form = await currentForm?.updateForm("isVisibleYN", currentForm?.isVisibleYN);
								setCurrentForm(form);
								setUpdateTrigger(true);
							}}
						/>
					</Center>
				)}
			</Stack>

			{Boolean(form.commenters) && (
				<Box mt={6}>
					<Heading size="xs" color="gray.400">
						Commenters Invited
					</Heading>
					<Divider />
					<Stack divider={<StackDivider />} p={3}>
						{commenters?.length > 0 ? (
							commenters?.map((commenter, i) => (
								<Stack key={i} direction="row" alignItems="center" justifyContent="space-between">
									<Box>
										<Heading size="sm" color="gray.500">
											{commenter.commenterName}
										</Heading>
										<Text>
											{
												portalUser?.userData?.filter(
													(employee) =>
														employee.queryData === "PREmployee" &&
														employee.employeeUID === commenter.employeeUID
												)[0]?.dept
											}{" "}
											-{" "}
											{
												portalUser?.userData?.filter(
													(employee) =>
														employee.queryData === "PREmployee" &&
														employee.employeeUID === commenter.employeeUID
												)[0]?.title
											}
										</Text>
									</Box>
									<Box>
										<Badge colorScheme={commenter.commenterStatus !== 1 ? "orange" : "green"}>
											{commenter.commenterStatus !== 1
												? "Awaiting Comments"
												: "Comments Submitted"}
										</Badge>
									</Box>
								</Stack>
							))
						) : (
							<Heading size="sm" color="gray.500">
								None
							</Heading>
						)}
					</Stack>
				</Box>
			)}

			<Stack mt={4} textAlign="right">
				{Boolean(isPortalAdmin) && (
					<Box>
						<Tooltip
							label="This will reset and delete all answers and comments for the form and set the status to 'NOT STARTED'"
							placement="auto"
						>
							<ConfirmationPrompt
								buttonTitle={"Reset"}
								alertDialogHeader={"Reset"}
								alertDialogBody={
									"Are you sure you want to reset and delete all answers and comments for the selected form and set its' status to 'NOT STARTED'"
								}
								confirmationButtonScheme={"red"}
								loadingText={"Resetting..."}
								confirmationFunction={resetForm}
								onClick={async () => {
									let form = await currentForm.updateForm(
										"dateApproved",
										currentForm?.dateApproved ? null : new Date()
									);
									setCurrentForm(form);
									setUpdateTrigger(true);
								}}
								variant={"outline"}
							/>
						</Tooltip>
					</Box>
				)}

				{Boolean(isPortalAdmin) && (
					<Box>
						<Tooltip
							label="This will reset all dates for the form and set the status to 'NOT STARTED'"
							placement="auto"
						>
							<ConfirmationPrompt
								buttonTitle={"Reset Dates"}
								alertDialogHeader={"Reset Dates"}
								alertDialogBody={
									"Are you sure you want to reset all dates for the selected form and set its' status to 'NOT STARTED'"
								}
								confirmationButtonScheme={"red"}
								loadingText={"Resetting Dates..."}
								confirmationFunction={resetFormDates}
								variant={"outline"}
							/>
						</Tooltip>
					</Box>
				)}

				{Boolean(isPortalAdmin) && (
					<Box>
						<Tooltip label="This will delete the entire form" placement="auto">
							<ConfirmationPrompt
								buttonTitle={"Delete"}
								alertDialogHeader={"Delete"}
								alertDialogBody={"Are you sure you want to delete this form?"}
								confirmationButtonScheme={"red"}
								loadingText={"Deleting..."}
								confirmationFunction={deleteForm}
								variant={"outline"}
							/>
						</Tooltip>
					</Box>
				)}
				<ToastPopup
					status={toastStatus}
					updates={toastUpdates}
					updateTitle={toastTitle}
					toastShown={toastShown}
					handleUploadRetry={() => {}}
				/>
			</Stack>
		</BasicDrawer>
	);
}
