import React from "react";
import {
	BarChart,
	Bar as BarRecharts,
	XAxis,
	YAxis,
	Tooltip as TooltipRecharts,
	Legend as LegendRecharts,
} from "recharts";

export default function RechartsBarChartExample() {
	const data = [
		{ name: "January", value: 100 },
		{ name: "February", value: 200 },
		{ name: "March", value: 150 },
		{ name: "April", value: 300 },
		{ name: "May", value: 400 },
	];

	return (
		<BarChart data={data} width={800} height={500}>
			<XAxis dataKey="name" />
			<YAxis />
			<TooltipRecharts />
			<LegendRecharts />
			<BarRecharts dataKey="value" fill="#8884d8" />
		</BarChart>
	);
}
