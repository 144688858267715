import { v4 } from "uuid";

import SubSection from "./SubSection";
export default class Section {
	constructor(section, pageUID = v4()) {
		this.pageUID = pageUID;
		this.sectionUID = v4();

		this.template = section?.template ?? "SectionTemplate";
		this.type = section?.type ?? "virtualTable";
		this.name = section?.name ?? "Section Template";
		this.label = section?.label ?? null;
		this.heading = section?.heading ?? null;
		this.subheading = section?.subheading ?? null;
		this.footNote = section?.footNote ?? null;
		this.tooltip = section?.tooltip ?? null;
		this.pagebreak = section?.pagebreak ?? 0;
		this.divider = section?.divider ?? false;

		this.show = section?.show ?? true;
		this.fontSize = section?.fontSize ?? 10;

		this.minRows = section?.minRows ?? 0;
		this.maxRows = section?.maxRows ?? null;
		this.maxRowsPDF = section?.maxRowsPDF ?? null;
		this.sliceType = section?.sliceType ?? "first";

		this.wrap = section?.wrap ?? false;
		this.py = section?.py ?? 2;
		this.px = section?.px ?? 4;
		this.rowHeight = section?.rowHeight ?? 40;
		this.isLoaded = section?.isLoaded ?? false;

		this.isCollapsable = section?.isCollapsable ?? false;

		this.width = section?.width ?? "100%";
		this.maxWidth = section?.maxWidth ?? "100%";
		this.flexWidth = section?.flexWidth ?? "100%";
		this.overflowX = section?.overflowX ?? "hidden";

		this.buttons = section?.buttons ?? [];

		this.subSections = this.newSubSections(section?.subSections ?? []);
	}

	newSubSections(subSections = []) {
		let newSubSections = [];
		if (Boolean(subSections) && subSections?.length > 0) {
			for (let i = 0; i < subSections?.length; i++) {
				let subSection = new SubSection(subSections[i], this);
				newSubSections.push(subSection);
			}
		} else {
			let subSection = new SubSection();
			newSubSections.push(subSection);
		}
		return newSubSections;
	}
}
