import { ajaxGET, testGET, testPOST, setQueryParams } from "./api-functions";
import { portalUser } from "../App";

const apiBaseURL =
	(process.env.NODE_ENV === "development"
		? "https://ppmportalappnodejs-api.azurewebsites.net"
		: window.location.origin) + "/api/init/";

export default class InitAPI {
	async TestGET(headers = {}) {
		let response = await testGET(apiBaseURL, headers);
		return response;
	}

	async TestPOST(body = {}, headers = {}) {
		let response = await testPOST(apiBaseURL, body, headers);
		return response;
	}

	/************************** BATCH API CALLS  ****************************/
	async InitApp(params = {}, query = { msUserID: portalUser.user?.msUserID }) {
		var apiRouteURL = `${apiBaseURL}/data/`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET Companies
	async InitCompanies(params = {}, query = {}) {
		var apiRouteURL = `${apiBaseURL}/companies`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET Regions
	async InitRegions(params = {}, query = {}) {
		var apiRouteURL = `${apiBaseURL}/regions`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET Departments
	async InitDepartments(params = {}, query = {}) {
		var apiRouteURL = `${apiBaseURL}/departments`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET Roles
	async InitRoles(params = {}, query = {}) {
		var apiRouteURL = `${apiBaseURL}/roles`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET Trades
	async InitTrades(params = {}, query = {}) {
		var apiRouteURL = `${apiBaseURL}/trades`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET Leaders
	async InitLeaders(params = {}, query = {}) {
		var apiRouteURL = `${apiBaseURL}/leaders`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET Users
	async InitUsers(params = {}, query = {}) {
		var apiRouteURL = `${apiBaseURL}/users`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET Users
	async InitEmployees(params = {}, query = {}) {
		var apiRouteURL = `${apiBaseURL}/employees`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET Event Logs
	async InitEventLogs(params = { sessionUID: null }, query = {}) {
		var apiRouteURL = `${apiBaseURL}/events/${params?.sessionUID}`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}
}
