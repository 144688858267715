import React, { useMemo, useState, useEffect } from "react";
import { Flex, Heading, HStack } from "@chakra-ui/react";
import LabelValuePairsSection from "./LabelValueSection";
import VirtualTableSection from "./VirtualTableSection";
import LoadingSection from "../../ProgressIndicators/components/LoadingSection";
// useBoolean, Collapse
// import { FaChevronRight, FaChevronDown } from "react-icons/fa";
// import { log } from "../../../helperFunctions";

export default function Section({
	section,
	selectedRow = null,
	setSelectedRow = null,
	maxH,
	selectedKeys = [],
	newPCO,
	dnfJob,
}) {
	// Add Collapse if section is collapsable
	// const [isOpen, setOpen] = useBoolean(true);

	// const sectionHeader = (sectionType='virtualTable') => {
	// 	{Boolean(section?.heading) && section.type !== "virtualTable" && (
	// 		<HStack w="full" align="flex-start" py={2}>
	// 			{Boolean(section?.label) && (
	// 				<Heading fontSize="2xl" letterSpacing={2} color="gray.500" textTransform="uppercase">
	// 					{section?.label}
	// 				</Heading>
	// 			)}
	// 			{Boolean(section?.heading) && (
	// 				<Heading fontSize="2xl" letterSpacing={4} color="gray.500" textTransform="uppercase">
	// 					{section?.heading}
	// 				</Heading>
	// 			)}
	// 		</HStack>
	// 	)}

	// }

	return (
		<Flex rounded={2} direction="column" flex={1} align="flex-start" position="relative" px={4} py={2} w="full">
			{Boolean(section?.heading) && section.type !== "virtualTable" && (
				<HStack w="full" align="flex-start" py={2}>
					{Boolean(section?.label) && (
						<Heading fontSize="2xl" letterSpacing={2} color="gray.500" textTransform="uppercase">
							{section?.label}
						</Heading>
					)}
					{Boolean(section?.heading) && (
						<Heading fontSize="2xl" letterSpacing={4} color="gray.500" textTransform="uppercase">
							{section?.heading}
						</Heading>
					)}
				</HStack>
			)}

			{/* <Collapse in={isOpen} animateOpacity unmountOnExit>
				<Flex h="full" w="full" direction="row" flex={1} align="flex-start" maxH={maxH ? maxH : ""}></Flex>
			</Collapse> */}

			<Flex h="full" w="full" direction="row" flex={1} align="flex-start" maxH={maxH ? maxH : ""}>
				{section?.subSections?.map((subSection, i) => (
					<SubSection
						key={i}
						subSectionTemplate={subSection}
						index={section.subSections?.length - i}
						setSelectedRow={setSelectedRow}
						selectedKeys={selectedKeys}
						newPCO={newPCO}
						dnfJob={dnfJob}
					/>
				))}
			</Flex>
		</Flex>
	);
}

export let inputData = { selectedInput: { rowIndex: null, columnID: null } };

export function SubSection({
	subSectionTemplate,
	selectedRow = null,
	setSelectedRow = null,
	selectedKeys = [],
	newPCO,
	dnfJob,
}) {
	const subSection = useMemo(() => subSectionTemplate, [subSectionTemplate]);
	const [data, setData] = useState(subSectionTemplate.data);

	useMemo(() => {
		setData(subSectionTemplate.data);
	}, [subSectionTemplate.data]);

	const [skipPageReset, setSkipPageReset] = useState(false);
	const updateMyData = (rowIndex, columnId, value) => {
		// We also turn on the flag to not reset the page
		setSkipPageReset(true);

		setData((old) =>
			old.map((row, index) => {
				if (index === rowIndex) {
					if (rowIndex === data?.length - 1) {
						inputData.selectedInput = { rowIndex: null, columnID: null };
					} else {
						inputData.selectedInput = { rowIndex: rowIndex + 1, columnID: columnId };
					}

					if (typeof row?.update === "function") {
						// log("Update function Exists", row);
						row.update(columnId, value);
					} else {
						// log("Update function Does not Exist", row);
						row[columnId] = value;
						return {
							...old[rowIndex],
							[columnId]: value,
						};
					}
				}
				return row;
			})
		);
	};

	useEffect(() => {
		setSkipPageReset(false);
	}, [data]);

	return (
		<Flex
			key={subSection.sectionUID}
			w={subSection.width}
			h="full"
			align="flex-start"
			direction="column"
			rounded={10}
		>
			{/* LABEL VALUE PAIRS */}
			{subSection.type === "labelValuePairs" && data.length > 0 && (
				<LabelValuePairsSection
					columns={subSection.columns}
					data={data}
					minRows={subSection.minRows}
					maxRows={subSection.maxRows}
					groupBy={subSection.groupBy}
					filterBy={subSection.filterBy}
					sortBy={subSection.sortBy}
					autoExpandRows={subSection.autoExpandRows}
				/>
			)}

			{/* VIRTUAL TABLE */}
			{subSection.type === "virtualTable" && data.length === 0 && !subSection.isLoaded && (
				<LoadingSection isLoaded={data.length > 0 || subSection.isLoaded} />
			)}

			{subSection.type === "virtualTable" && (data.length > 0 || subSection.isLoaded) && (
				<VirtualTableSection
					label={subSection.label}
					heading={subSection.heading}
					subheading={subSection.subheading}
					footNote={subSection.footNote}
					tooltip={subSection.tooltip}
					tableColumns={subSection.columns}
					tableData={data}
					tableRowHeight={subSection.rowHeight}
					tableFooter={subSection.footer}
					tableMinRows={subSection.minRows}
					tableMaxRows={subSection.maxRows}
					tableLoopBy={subSection.loopBy}
					tableGroupBy={subSection.groupBy}
					tableFilterBy={subSection.filterBy}
					tableSortBy={subSection.sortBy}
					tableAutoExpandRows={subSection.autoExpandRows}
					sliceType={subSection.sliceType}
					skipPageReset={skipPageReset}
					updateMyData={updateMyData}
					selectedRow={selectedRow}
					setSelectedRow={setSelectedRow}
					selectedKeys={selectedKeys}
					newPCO={newPCO}
					dnfJob={dnfJob}
				/>
			)}
		</Flex>
	);
}
