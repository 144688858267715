// import SectionTemplateMFRAccountingFooter from "./SectionTemplateMFRAccountingFooter";

export default class SectionTemplateMFRAccounting {
	constructor(billings = [], retention = [], contractTotals = null, earlyLateAvg = 0, isCRS = false) {
		this.template = "MFRAccounting";
		this.name = "Accounting";
		this.label = isCRS ? "II." : "III.";
		this.heading = "Accounting";
		this.pagebreak = 0;
		this.divider = false;
		this.type = "virtualTable";
		this.minRows = 8;
		this.maxRows = 8;
		this.maxRowsPDF = 10;
		this.subSections = [new SubSectionTemplateMFRAccounting(billings, retention, contractTotals, earlyLateAvg)];
	}
}

class SubSectionTemplateMFRAccounting {
	constructor(billings = [], retention = [], contractTotals = null, earlyLateAvg = 0) {
		let sortGroupFilter = this.getSortGroupFilter([...billings, ...retention]);
		this.template = "mfrAccounting";
		this.type = "virtualTable";
		this.groupBy = sortGroupFilter.groupBy;
		this.filterBy = sortGroupFilter.filterBy;
		this.sortBy = sortGroupFilter.sortBy;
		this.columns = [
			{
				accessor: "co",
				Header: "Co",
				width: "0%",
				show: false,
			},
			{
				accessor: "contract",
				Header: "Contract",
				width: "0%",
				show: false,
			},
			{
				accessor: "billNo",
				Header: "Bill #",
				textAlign: "center",
				width: "7%",
			},
			{
				accessor: "mth",
				Header: "Month",
				valueType: "month",
				width: "12%",
			},
			{
				accessor: "netBillingAmt",
				Header: "Net Billing",
				textAlign: "right",
				valueType: "currency",
				width: "12%",
				decimals: 2,
			},
			{
				accessor: "dateSubmitted",
				Header: "Submitted",
				textAlign: "right",
				valueType: "date",
				width: "12%",
			},
			{
				accessor: "dateDueBy",
				Header: "Due By",
				textAlign: "right",
				valueType: "date",
				width: "12%",
			},
			{
				accessor: "datePaidOn",
				Header: "Paid On",
				textAlign: "right",
				valueType: "date",
				width: "12%",
			},
			{
				accessor: "earlyLate",
				Header: "Early / Late",
				textAlign: "center",
				valueType: "number",
				width: "12%",
			},
			{
				accessor: "expectedCollection",
				Header: "Expected Collection",
				textAlign: "center",
				valueType: "date",
				width: "21%",
			},
		];
		this.data = [...billings, ...retention];
		// this.footer = new SectionTemplateMFRAccountingFooter(contractTotals, earlyLateAvg);
	}
	getSortGroupFilter(billings = []) {
		let groupBy = [];
		let sortBy = [];
		let filterBy = [];
		let keys = [...new Set(billings?.map((data) => data.jccmKeyID))];
		if (keys.length > 1) {
			groupBy = ["mth"];
			sortBy = [
				{ id: "billMth", desc: true },
				{ id: "co", desc: false },
				{ id: "contract", desc: false },
			];
		}
		let sortGroupFilter = { sortBy: sortBy, groupBy: groupBy, filterBy: filterBy };
		return sortGroupFilter;
	}
}
