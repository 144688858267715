import { v4 } from "uuid";
import Column from "./Column";
import Section from "./Section";

export default class SubSection {
	constructor(subSection, section = null) {
		this.sectionUID = section?.sectionUID ?? v4();
		this.subSectionUID = v4();

		this.template = subSection?.template ?? section?.template ?? "SubSectionTemplate";
		this.type = subSection?.type ?? section?.type ?? null;
		this.name = subSection?.name ?? section?.name ?? "SubSection Template";
		this.label = subSection?.label ?? section?.label ?? null;
		this.heading = subSection?.heading ?? section?.heading ?? null;
		this.subheading = subSection?.subheading ?? section?.subheading ?? null;
		this.footNote = subSection?.footNote ?? section?.footNote ?? null;
		this.tooltip = subSection?.tooltip ?? section?.tooltip ?? null;

		this.rowHeight = subSection?.rowHeight ?? section?.rowHeight ?? 40;
		this.minRows = subSection?.minRows ?? section?.minRows ?? 0;
		this.maxRows = subSection?.maxRows ?? section?.maxRows ?? null;
		this.maxRowsPDF = subSection?.maxRowsPDF ?? section?.maxRowsPDF ?? null;
		this.sliceType = subSection?.sliceType ?? section?.sliceType ?? "first";
		this.wrap = subSection?.wrap ?? section?.wrap ?? false;
		this.fontSize = subSection?.fontSize ?? section?.fontSize ?? 10;

		this.columns = this.newColumns(subSection?.columns ?? []);
		this.data = subSection?.data ?? [];
		this.header = this.getHeaderFooter(subSection?.header ?? []);
		this.footer = this.getHeaderFooter(subSection?.footer ?? []);
		this.Footer = subSection?.Footer;
		this.loopBy = subSection?.loopBy ?? [];
		this.groupBy = subSection?.groupBy ?? [];
		this.sortBy = subSection?.sortBy ?? [];
		this.filterBy = subSection?.filterBy ?? [];
		this.autoExpandRows = subSection?.autoExpandRows ?? false;

		this.row = subSection?.row ?? {};

		// only used in labelValue pairs so far
		this.flexDirection = subSection?.flexDirection ?? "column";
		this.flexGrow = subSection?.flexGrow ?? 0;
		this.flexWrap = subSection?.flexWrap ?? "wrap";
		this.bg = subSection?.bg ?? "transparent";
		this.isLoaded = subSection?.isLoaded ?? section?.isLoaded ?? false;

		this.isCollapsable = subSection?.isCollapsable ?? section?.isCollapsable ?? false;

		this.width = subSection?.width ?? section?.width ?? "100%";
		this.maxWidth = subSection?.maxWidth ?? section?.maxWidth ?? "100%";
		this.flexWidth = subSection?.flexWidth ?? section?.flexWidth ?? "100%";
		this.overflowX = subSection?.overflowX ?? section?.overflowX ?? "hidden";

		this.buttons = subSection?.buttons ?? [];
	}

	newColumns(columns = [], maxColumns = null) {
		let newColumns = [];
		if (columns?.length > 0) {
			let showCount = 0;

			for (let i = 0; i < columns?.length; i++) {
				let column = new Column(columns[i]);
				if (column.show && Boolean(maxColumns)) {
					showCount = showCount + 1;
					if (showCount > maxColumns) {
						column.show = false;
					}
				}
				column.fontSize = this.fontSize;
				newColumns.push(column);
			}
		} else {
			let column = new Column();
			newColumns.push(column);
		}
		return newColumns;
	}

	getHeaderFooter(headerFooter = []) {
		if (typeof headerFooter === "function") {
			return headerFooter;
		} else if (Array.isArray(headerFooter)) {
			return headerFooter;
		} else {
			return new Section(headerFooter);
		}
	}

	getColumnsMap() {
		let map = {};
		for (let i = 0; i < this.columns?.length; i++) {
			let keyID = this.columns[i].accessor;
			map[keyID] = null;
		}
		return map;
	}

	getData(data = []) {
		let rows = [...data];

		if (data.length < this.minRows) {
			let columnsMap = this.getColumnsMap();

			for (let i = rows.length; i < this.minRows; i++) {
				rows.push(columnsMap);
			}
		}

		return rows;
	}

	getFloatHeaderMinH() {
		let minH = 0;
		for (let i = 0; i < this.columns?.length; i++) {
			if (Boolean(this.columns[i].FloatHeader)) {
				minH = this.rowHeight;
				break;
			}
		}
		return minH;
	}
}
