const CELL_PHONE_CARRIERS = [
	{ value: "ATT", name: "AT&T", sms: "txt.att.net", mms: "mms.att.net" },
	{ value: "BoostMobile", name: "Boost Mobile", sms: "sms.myboostmobile.com", mms: "myboostmobile.com" },
	{ value: "GoogleFi", name: "Google Fi", sms: "msg.fi.google.com", mms: "msg.fi.google.com" },
	{ value: "MetroPCS", name: "Metro PCS", sms: "mymetropcs.com", mms: "mymetropcs.com" },
	{ value: "TMobile", name: "T-Mobile", sms: "tmomail.net", mms: "tmomail.net" },
	{ value: "Verizon", name: "Verizon", sms: "vtext.com", mms: "vtext.com" },
	{ value: "VirginMobile", name: "Virgin Mobile", sms: "vmobl.com", mms: "vmpix.com" },
	{ value: "XfinityMobile", name: "Xfinity Mobile", sms: "vtext.com", mms: "vtext.com" },
];
export default CELL_PHONE_CARRIERS;
