import React, { useEffect, useState } from "react";
import {
	Input,
	Stack,
	FormControl,
	FormLabel,
	InputGroup,
	InputLeftAddon,
	Flex,
	Spacer,
	Button,
	Text,
} from "@chakra-ui/react";
import BasicCard from "../../../core/Layout/cards/BasicCard";
import Email from "../classes/Email";
import { portalUser } from "../../../App";

export default function UserContactForm({ isDrawer = false }) {
	const [triggerUpdate, setTriggerUpdate] = useState(false);

	useEffect(() => {
		if (triggerUpdate) {
			setTriggerUpdate(false);
		}
	}, [triggerUpdate]);

	const updatePaystubEmail = async (e) => {
		let value = e.target.value;

		await portalUser.user.updatePaystubEmail(value);
		setTriggerUpdate(true);
	};

	let employees = portalUser.getPREmployees();
	let employee = employees?.find((d) => d.employeeUID === portalUser.user?.employeeUID) ?? null;

	const saveAddress = async (address) => {
		let changeMade = false;
		if (address.homeAddress !== portalUser.user?.homeAddress && address.homeAddress.length > 0) {
			await portalUser.user.updateHomeAddress(address.homeAddress);
			changeMade = true;
		}
		if (address.homeCity !== portalUser.user?.homeCity && address.homeCity.length > 0) {
			await portalUser.user.updateHomeCity(address.homeCity);
			changeMade = true;
		}
		if (address.homeState !== portalUser.user?.homeState && address.homeState.length > 0) {
			await portalUser.user.updateHomeState(address.homeState);
			changeMade = true;
		}
		if (address.homeZip !== portalUser.user?.homeZip && address.homeZip.length > 0) {
			await portalUser.user.updateHomeZip(address.homeZip);
			changeMade = true;
		}

		if (changeMade) {
			let email = new Email();
			let name = portalUser.user?.getName();
			email.addTo("michellec@ppmechanical.com");
			email.addTo("carolineb@ppmechanical.com");
			email.subject = "Home Address Change";
			email.body = (
				<p>
					{name} has updated their home address to:
					<br />
					<br />
					{address.homeAddress}
					<br />
					{address.homeCity},&nbsp;{address.homeState}&nbsp;{address.mailZip}
				</p>
			);
			await email.send();
		}
	};

	return (
		<BasicCard title="Contact Info" portalStyles={portalUser.styles}>
			<Stack id="card-employeeinfo" w="full" direction="column" spacing={isDrawer ? "3" : "4"}>
				<FormControl id="email" flex={1}>
					<FormLabel color="gray.500" letterSpacing={1} textTransform="uppercase">
						Paystub Email
					</FormLabel>
					<InputGroup
						shadow={portalUser.styles.cardShadow}
						borderColor={portalUser.styles.cardBorderColor}
						rounded="md"
					>
						<InputLeftAddon
							pointerEvents="none"
							children={<Text color="gray.600" fontSize="lg" className={"fas fa-envelope"} />}
						/>
						<Input
							variant="filled"
							bg="whiteAlpha.600"
							color="gray.600"
							borderWidth={1}
							borderColor={portalUser.styles.cardBorderColor}
							rounded="md"
							_hover={{ bg: "whiteAlpha.900" }}
							_focus={{
								borderWidth: 1.5,
								borderColor: "teal.500",
								bg: "whiteAlpha.900",
							}}
							onBlur={(e) => {
								updatePaystubEmail(e);
							}}
							type="text"
							placeholder="user@example.com"
							defaultValue={employee?.paystubEmail}
						/>
					</InputGroup>
				</FormControl>
				{Boolean(employee) && <HomeAddressForm employee={employee} save={saveAddress} isDrawer={isDrawer} />}
			</Stack>
		</BasicCard>
	);
}

function HomeAddressForm({ employee, save, isDrawer }) {
	const [homeAddress, setAddress] = useState(employee?.homeAddress);
	const [homeCity, setCity] = useState(employee?.homeCity);
	const [homeState, setState] = useState(employee?.homeState);
	const [homeZip, setZip] = useState(employee?.homeZip);
	const [isEditing, setEditing] = useState(false);
	const [saving, setSaving] = useState(false);

	const enableEditing = () => {
		if (!isEditing) {
			setEditing(true);
		}
	};

	const cancel = () => {
		setEditing(false);
		setAddress(employee?.homeAddress ?? "");
		setCity(employee?.homeCity ?? "");
		setState(employee?.homeState ?? "");
		setZip(employee?.homeZip ?? "");
	};

	const submit = async () => {
		let address = {
			homeAddress: homeAddress,
			homeCity: homeCity,
			homeState: homeState,
			homeZip: homeZip,
		};
		setSaving(true);
		await save(address);
		setEditing(false);
		setSaving(false);
	};

	return (
		<Stack
			onClick={enableEditing}
			// border={isEditing ? "2px solid" : ""}
			// borderColor={isEditing ? "gray.400" : ""}
			// p={isEditing ? 2 : 0}
			// borderRadius="10px"
			spacing={isDrawer ? "3" : "4"}
		>
			<FormControl id="address" flex={1}>
				<FormLabel color="gray.500" letterSpacing={1} textTransform="uppercase">
					Home Address
				</FormLabel>
				<InputGroup
					shadow={portalUser.styles.cardShadow}
					borderColor={portalUser.styles.cardBorderColor}
					rounded="md"
				>
					<InputLeftAddon
						pointerEvents="none"
						children={<Text color="gray.600" fontSize="lg" className={"fas fa-home"} />}
					/>
					<Input
						variant="filled"
						bg="whiteAlpha.600"
						color="gray.600"
						borderWidth={1}
						borderColor={portalUser.styles.cardBorderColor}
						rounded="md"
						_hover={{ bg: "whiteAlpha.900" }}
						_focus={{
							borderWidth: 1.5,
							borderColor: "teal.500",
							bg: "whiteAlpha.900",
						}}
						type="text"
						placeholder="Mail Address"
						value={homeAddress}
						onChange={(e) => {
							setAddress(e.target.value);
						}}
						readOnly={!isEditing}
					/>
				</InputGroup>
			</FormControl>

			<Stack
				w="full"
				spacing={isDrawer ? "3" : "4"}
				direction={{ base: "column", lg: isDrawer ? "column" : "row" }}
			>
				<FormControl id="city" flex="3">
					<FormLabel color="gray.500" letterSpacing={1} textTransform="uppercase">
						City
					</FormLabel>
					<InputGroup
						shadow={portalUser.styles.cardShadow}
						borderColor={portalUser.styles.cardBorderColor}
						rounded="md"
					>
						<InputLeftAddon
							pointerEvents="none"
							children={<Text color="gray.600" fontSize="lg" className={"fas fa-city"} />}
						/>
						<Input
							variant="filled"
							bg="whiteAlpha.600"
							color="gray.600"
							borderWidth={1}
							borderColor={portalUser.styles.cardBorderColor}
							rounded="md"
							_hover={{ bg: "whiteAlpha.900" }}
							_focus={{
								borderWidth: 1.5,
								borderColor: "teal.500",
								bg: "whiteAlpha.900",
							}}
							type="text"
							placeholder="City"
							value={homeCity}
							onChange={(e) => {
								setCity(e.target.value);
							}}
							readOnly={!isEditing}
						/>
					</InputGroup>
				</FormControl>

				<FormControl id="state" flex="1">
					<FormLabel color="gray.500" letterSpacing={1} textTransform="uppercase">
						State
					</FormLabel>
					<InputGroup
						shadow={portalUser.styles.cardShadow}
						borderColor={portalUser.styles.cardBorderColor}
						rounded="md"
					>
						<InputLeftAddon
							pointerEvents="none"
							children={<Text color="gray.600" fontSize="lg" className={"fas fa-flag-usa"} />}
						/>
						<Input
							variant="filled"
							bg="whiteAlpha.600"
							color="gray.600"
							borderWidth={1}
							borderColor={portalUser.styles.cardBorderColor}
							rounded="md"
							_hover={{ bg: "whiteAlpha.900" }}
							_focus={{
								borderWidth: 1.5,
								borderColor: "teal.500",
								bg: "whiteAlpha.900",
							}}
							type="text"
							placeholder="State"
							value={homeState}
							onChange={(e) => {
								setState(e.target.value);
							}}
							readOnly={!isEditing}
						/>
					</InputGroup>
				</FormControl>

				<FormControl id="zip" flex="1">
					<FormLabel color="gray.500" letterSpacing={1} textTransform="uppercase">
						Zip
					</FormLabel>
					<InputGroup
						shadow={portalUser.styles.cardShadow}
						borderColor={portalUser.styles.cardBorderColor}
						rounded="md"
					>
						<InputLeftAddon
							pointerEvents="none"
							children={<Text color="gray.600" fontSize="lg" className={"fas fa-map-marker-alt"} />}
						/>
						<Input
							variant="filled"
							bg="whiteAlpha.600"
							color="gray.600"
							borderWidth={1}
							borderColor={portalUser.styles.cardBorderColor}
							rounded="md"
							_hover={{ bg: "whiteAlpha.900" }}
							_focus={{
								borderWidth: 1.5,
								borderColor: "teal.500",
								bg: "whiteAlpha.900",
							}}
							type="text"
							placeholder="Zip Code"
							value={homeZip}
							onChange={(e) => {
								setZip(e.target.value);
							}}
							readOnly={!isEditing}
						/>
					</InputGroup>
				</FormControl>
			</Stack>

			<Flex hidden={!isEditing} pt={3}>
				<Spacer />
				<Stack direction="row" spacing={2}>
					<Button variant="outline" colorScheme="red" onClick={cancel}>
						CANCEL
					</Button>
					<Button variant="solid" colorScheme="green" onClick={submit} isLoading={saving}>
						SAVE
					</Button>
				</Stack>
			</Flex>
		</Stack>
	);
}
