import React, { useState, useRef, useCallback, useEffect } from "react";
import {
	Drawer,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	DrawerHeader,
	DrawerBody,
	DrawerFooter,
	Flex,
	Text,
	Heading,
	Button,
	Collapse,
	Stack,
	HStack,
	IconButton,
	useBoolean,
	useToast,
} from "@chakra-ui/react";
import DataInput from "../../../core/Inputs/data/DataInput";
import ConfirmationPrompt from "../../../core/Alerts/ConfirmationPrompt";

import PCOItemDrawer from "./PCOItemDrawer";
import PCO_PRIORITIES from "../constants/PCOPriorities";
import PCO_STATUSES from "../constants/PCOStatuses";

import { portalUser } from "../../../App";
import { formatValue, log } from "../../../helperFunctions";

export default function PCODrawer({ selectedPCO, isOpen, closePCO, savePCO, deletePCO }) {
	const toast = useToast();
	const pcoStatuses = PCO_STATUSES?.filter((d) => !["BUDGET", "APPD", "TOTAL"].includes(d.value)) ?? [];
	const contractItems = portalUser?.getContractItems([selectedPCO?.jccmKeyID]);
	const [itemCount, setItemCount] = useState(selectedPCO?.coItems?.length);
	const spacing = { vertSpacing: 2, horzSpacing: 2, leftFlex: 4, rightFlex: 2 };
	const containerRef = useRef(null);
	const pcoRef = useRef(null);
	const gcRef = useRef(null);
	const pcoAmtRef = useRef(null);
	const dateSubmittedRef = useRef(null);
	const descriptionRef = useRef(null);
	const notesRef = useRef(null);

	const [errors, setErrors] = useState([]);
	const [updateTrigger, setUpdateTrigger] = useState(false);

	const [saveLoading, setSaveLoading] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);

	const addCOItem = useCallback(async () => {
		let newPCO = await selectedPCO.addCOItem();
		setItemCount(newPCO?.coItems?.length);
	}, [selectedPCO]);

	const deleteCOItem = useCallback(
		async (itemUID) => {
			log("itemUID", itemUID);
			try {
				await selectedPCO.deleteCOItem(itemUID);
				toast({
					title: "PCO Item Deleted!",
					description: "Please refresh to see any updates.",
					status: "success",
					duration: 2000,
				});
			} catch (err) {
				console.log("Error deleting CO Item", err);
				toast({ title: "Something went wrong!", description: err, status: "error", duration: 2000 });
			}

			// Update the selectedPCO.coItems array by filtering out the deleted item
			selectedPCO.coItems = selectedPCO.coItems.filter((item) => item.pmoiUID !== itemUID);
			selectedPCO.coItems.filter((item) => item.pmoiUID !== itemUID);
			portalUser.contractData =
				portalUser?.contractData?.filter((d) => d.queryData === "COItem" && d.pmoiUID !== itemUID) ?? [];
			setItemCount(selectedPCO.coItems?.length);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedPCO]
	);

	const updatePCO = useCallback(
		(attr, value) => {
			selectedPCO.update(attr, value);
			setUpdateTrigger(true);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedPCO]
	);

	useEffect(() => {
		if (updateTrigger) {
			setUpdateTrigger(false);
		}
	}, [updateTrigger]);

	const [deleteHover, setDeleteHover] = useBoolean();

	const validatePCO = useCallback(async () => {
		setSaveLoading(true);
		let errors = selectedPCO.validatePCO();
		if (errors.length === 0) {
			log("NO ERRORS!");
			await savePCO(selectedPCO);
		} else {
			log("VALIDATE ERRORS", errors);
		}
		setErrors(errors);
		setSaveLoading(false);
	}, [savePCO, selectedPCO]);

	const removePCO = useCallback(async () => {
		setDeleteLoading(true);
		await deletePCO(selectedPCO);
		setDeleteLoading(false);
	}, [deletePCO, selectedPCO]);

	return (
		<Drawer
			placement="right"
			isOpen={isOpen}
			onClose={closePCO}
			size="md"
			key={selectedPCO?.pmopUID}
			initialFocusRef={Boolean(selectedPCO?.pmopKeyID) ? pcoRef : pcoAmtRef}
		>
			<DrawerOverlay />

			<DrawerContent ref={containerRef}>
				<DrawerCloseButton />

				<DrawerHeader>
					<HStack align="center" w="full" spacing={2}>
						<Heading as="h2" size="md" color="gray.400" textTransform="uppercase" letterSpacing={2}>
							Pending Change Order
						</Heading>
					</HStack>
				</DrawerHeader>

				<DrawerBody overflowX={"hidden"} overflowY={"auto"}>
					<Stack spacing={4} w="full" h="full">
						<Stack spacing={spacing.vertSpacing} w="full">
							<Stack
								p={4}
								spacing={spacing.vertSpacing}
								bg="blackAlpha.50"
								borderWidth={2}
								rounded="10"
								borderColor={deleteHover ? "red.500" : "blackAlpha.100"}
							>
								<HStack
									w="full"
									pb={1}
									borderBottomWidth={2}
									borderBottomColor="blackAlpha.300"
									align="center"
								>
									{/* <Icon color={"gray.500"}>
										<i className="fas fa-cog fa-lg fa-fw " />
									</Icon> */}
									<Heading
										w="full"
										color={deleteHover ? "red.500" : "gray.500"}
										textTransform="uppercase"
										fontSize="lg"
										letterSpacing={2}
									>
										{(deleteHover ? "Delete" : selectedPCO?.pmopKeyID === null ? "New" : "Edit") +
											" PCO " +
											(selectedPCO?.pmopKeyID === null
												? ""
												: selectedPCO?.pco
														?.toUpperCase()
														.replace("$", "")
														?.replace(",", "")
														?.replace(" ", "")
														?.replace("%", "")
														?.replace("#", "")
														?.replace("PCO", ""))}
									</Heading>

									{Boolean(selectedPCO?.pmopKeyID) && (
										<ConfirmationPrompt
											useIconButton
											confirmationFunction={removePCO}
											icon="fa-times"
											buttonTitle="Delete"
											alertDialogHeader="Delete PCO"
											alertDialogBody="Are you sure you would like to delete this PCO? The data for the PCO will be deleted permanently."
											alertDialogFooter="Set the PCO Status to CLOSED if you would like to the keep the PCO history but filter it from the CO Log. You can view closed PCOs by clearing the PCO Status filter options."
											isLoading={deleteLoading}
											loadingText="Deleting..."
											onMouseLeave={setDeleteHover.off}
											onMouseEnter={setDeleteHover.on}
										/>
									)}
								</HStack>

								<Stack spacing={spacing.horzSpacing} direction="row" w="full">
									<DataInput
										key={selectedPCO?.pmopUID + "-pco-" + selectedPCO?.pco}
										name="pco"
										type="text"
										label="PCO #"
										placeholder="PCO #"
										inputRef={pcoRef}
										defaultValue={selectedPCO?.pco}
										isDisabled={Boolean(selectedPCO?.pmopKeyID)}
										onBlur={(ev) => {
											if (ev.target.value !== selectedPCO?.pco) {
												updatePCO("pco", ev.target.value);
											}
										}}
										flex={spacing.leftFlex}
										variant="outline"
										inputProps={{ px: 2 }}
										isModal
									/>

									<DataInput
										key={selectedPCO?.pmopUID + "-gcRef-" + selectedPCO?.gcRef}
										name="gcRef"
										type="text"
										label="GC Ref #"
										placeholder="GC Ref #"
										defaultValue={selectedPCO?.gcRef}
										inputRef={gcRef}
										flex={spacing.rightFlex}
										variant="outline"
										inputProps={{ px: 2 }}
										isModal
										onBlur={(ev) => {
											if (ev.target.value !== selectedPCO?.gcRef) {
												updatePCO("gcRef", ev.target.value);
											}
										}}
									/>
								</Stack>

								<Stack spacing={spacing.horzSpacing} direction="row" w="full">
									<DataInput
										key={selectedPCO?.pmopUID + "-pcoStatus-" + selectedPCO?.pcoStatus}
										name="pcoStatus"
										type="select"
										label="PCO Status"
										searchable
										options={pcoStatuses}
										value={selectedPCO?.pcoStatus}
										optionValue="value"
										optionText="label"
										optionSubheading="description"
										flex={spacing.leftFlex}
										variant="outline"
										isModal
										onOptionSelect={(ev) => {
											if (ev !== selectedPCO?.pcoStatus) {
												updatePCO("pcoStatus", ev);
											}
										}}
									/>

									<DataInput
										key={selectedPCO?.pmopUID + "-priority-" + selectedPCO?.priority}
										name="priority"
										type="select"
										label="Priority"
										searchable
										options={PCO_PRIORITIES}
										value={selectedPCO?.priority}
										optionValue="value"
										optionText="label"
										optionSubheading="description"
										flex={spacing.rightFlex}
										variant="outline"
										isModal
										onOptionSelect={(ev) => {
											if (ev !== selectedPCO?.priority) {
												updatePCO("priority", ev);
											}
										}}
									/>
								</Stack>

								<Stack spacing={spacing.horzSpacing} direction="row" w="full">
									<DataInput
										key={selectedPCO?.pmopUID + "-description-" + selectedPCO?.description}
										name="description"
										type="textarea"
										rowsMin={4}
										label="Description"
										placeholder="New Pending Change Order"
										defaultValue={selectedPCO?.description}
										onBlur={(ev) => {
											if (ev.target.value !== selectedPCO?.description) {
												updatePCO("description", ev.target.value);
											}
										}}
										inputRef={descriptionRef}
										flex={1}
										labelProps={{ py: 1, letterSpacing: 1 }}
										variant="outline"
										inputBG="white"
										isModal
									/>
								</Stack>

								<Stack spacing={spacing.horzSpacing} w="full">
									<Stack spacing={spacing.horzSpacing} direction="row" w="full">
										<DataInput
											key={selectedPCO?.pmopUID + "-pcoAmt-" + selectedPCO?.pcoAmt}
											name="pcoAmt"
											type="text"
											label="Amount Submitted"
											placeholder={"$0.00"}
											// inputRef={descriptionRef}
											defaultValue={
												formatValue(selectedPCO?.pcoAmt, 2, "currency") === "$0.00"
													? null
													: formatValue(selectedPCO?.pcoAmt, 2, "number")
											}
											onBlur={(ev) => {
												if (ev.target.value !== selectedPCO?.pcoAmt) {
													updatePCO("pcoAmt", ev.target.value);
												}
											}}
											flex={spacing.leftFlex}
											inputProps={{ py: 1, fontFamily: "'Helvetica',sans-serif", fontSize: "md" }}
											variant="outline"
											isModal
										/>

										<DataInput
											key={selectedPCO?.pmopUID + "-dateSubmitted-" + selectedPCO?.dateSubmitted}
											name="dateSubmitted"
											type="date"
											label="Date"
											value={selectedPCO?.dateSubmitted}
											inputRef={dateSubmittedRef}
											placeholder="mm/dd/yyyy"
											inputProps={{
												textAlign: "left",
												py: 1,
												fontSize: "md",
												fontFamily: "'Helvetica',sans-serif",
											}}
											onChange={(ev) => {
												let oldDate = formatValue(
													new Date(selectedPCO?.dateSubmitted),
													0,
													"moment"
												);
												let newDate = formatValue(new Date(ev), 0, "moment");
												if (
													newDate?.isValid() &&
													formatValue(newDate, 0, "date") !== formatValue(oldDate, 0, "date")
												) {
													updatePCO("dateSubmitted", ev);
												}
											}}
											flex={spacing.rightFlex}
											variant="outline"
											isModal
										/>
									</Stack>
									<Stack spacing={spacing.vertSpacing} w="full" pt={2}>
										<Heading
											w="full"
											color={"gray.500"}
											textTransform="uppercase"
											fontSize="md"
											letterSpacing={2}
										>
											Pending Conversion
										</Heading>

										<Stack p={0} spacing={spacing.horzSpacing} rounded="10">
											{selectedPCO?.coItems?.map((coItem, i) => {
												return (
													<PCOItemDrawer
														key={coItem?.pmoiUID}
														coItem={coItem}
														contractItems={contractItems}
														spacing={spacing}
														i={i}
														itemCount={itemCount}
														deleteCOItem={deleteCOItem}
													/>
												);
											})}
										</Stack>
									</Stack>

									<Collapse
										in={
											Boolean(itemCount < contractItems.length) &&
											Boolean(contractItems.length > 1)
										}
									>
										<Button size="sm" colorScheme="teal" onClick={addCOItem} w="full">
											NEW CONTRACT ITEM
										</Button>
									</Collapse>
								</Stack>

								<Stack spacing={spacing.horzSpacing} direction="row" w="full">
									<DataInput
										inputRef={notesRef}
										key={selectedPCO?.pmopUID + "-notes-" + selectedPCO?.notes}
										variant="outline"
										inputBG="white"
										name="notes"
										type="textarea"
										label="Notes & Comments"
										rowsMin={6}
										placeholder="Add any additional notes or comments regarding this pending change order here..."
										defaultValue={selectedPCO?.notes}
										labelProps={{ py: 1, letterSpacing: 1 }}
										flex={1}
										onBlur={(ev) => {
											if (ev.target.value !== selectedPCO?.notes) {
												updatePCO("notes", ev.target.value);
											}
										}}
										isModal
									/>
								</Stack>
							</Stack>
						</Stack>
					</Stack>
				</DrawerBody>

				<DrawerFooter>
					<Flex justify="space-between" flex={1} w="full">
						{Boolean(errors?.length > 0) ? (
							<Stack w="full" flex={2}>
								{errors?.map((error, i) => {
									return (
										<HStack align="center" spacing={2} pr={2}>
											<IconButton
												color={error.color}
												boxSize="40px"
												minW="20px"
												variant="unstyled"
											>
												<i className="fas fa-exclamation-triangle fa-fw fa-lg" />
											</IconButton>
											<Text
												fontSize="xs"
												textAlign="left"
												textTransform="uppercase"
												fontWeight="bold"
												color={error.color}
											>
												{error.errorMsg}
											</Text>
										</HStack>
									);
								})}
							</Stack>
						) : (
							<Button
								variant="outline"
								colorScheme="red"
								onClick={closePCO}
								borderWidth={2}
								px={3}
								fontSize="sm"
							>
								<HStack>
									<i className={"fas fa-times fa-fw"}></i>
									<Text letterSpacing={1} textTransform={"uppercase"}>
										CANCEL
									</Text>
								</HStack>
							</Button>
						)}

						<HStack spacing={2} flex={1} w="full" justify="flex-end" align="center">
							{/* {Boolean(selectedPCO?.pmopKeyID) && (
								<ConfirmationPrompt
									confirmationFunction={removePCO}
									buttonTitle="Delete PCO"
									alertDialogHeader="Delete Pending Change Order"
									alertDialogBody="Are you sure you would like to delete this PCO?"
									isLoading={deleteLoading}
									loadingText="Deleting..."
								/>
							)} */}
							<Button
								colorScheme={deleteLoading ? "red" : Boolean(selectedPCO?.pmopKeyID) ? "blue" : "green"}
								onClick={validatePCO}
								px={3}
								fontSize="sm"
								isLoading={saveLoading || deleteLoading}
								loadingText={
									deleteLoading
										? "Deleting..."
										: Boolean(selectedPCO?.pmopKeyID)
										? "Saving..."
										: "Creating..."
								}
							>
								<HStack>
									<i
										className={
											"fas fa-" + (Boolean(selectedPCO?.pmopKeyID) ? "save" : "plus") + " fa-fw"
										}
									></i>
									<Text fontSize="sm" letterSpacing={1} textTransform={"uppercase"}>
										{Boolean(selectedPCO?.pmopKeyID) ? "Save" : "Add"}
									</Text>
								</HStack>
							</Button>
						</HStack>
					</Flex>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}
