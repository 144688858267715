import React, { useRef } from "react";
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	Button,
	useDisclosure,
	Tooltip,
	IconButton,
	HStack,
	Text,
	Stack,
	Heading,
	Divider,
} from "@chakra-ui/react";

export default function ConfirmationPrompt({
	useIconButton = false,
	icon = null,
	buttonTitle = "Delete",
	alertDialogHeader = "Delete",
	alertDialogBody = "Are you sure? You can't undo this action afterwards.",
	alertDialogFooter = null,
	confirmationButtonScheme = "red",
	isLoading = false,
	loadingText = "Deleting...",
	confirmationFunction,
	onMouseEnter,
	onMouseLeave,
	variant = "solid",
}) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancelRef = useRef();

	return (
		<>
			{!Boolean(useIconButton) && (
				<Button
					textTransform="uppercase"
					letterSpacing={1}
					onClick={onOpen}
					colorScheme="red"
					variant={variant}
				>
					<HStack>
						{Boolean(icon) && <i className={"fas fa-" + icon + " fa-fw"}></i>}
						<Text fontSize="sm" letterSpacing={1} textTransform={"uppercase"}>
							{buttonTitle}
						</Text>
					</HStack>
				</Button>
			)}
			{Boolean(useIconButton) && (
				<Tooltip label={buttonTitle} position="top">
					<IconButton
						onClick={onOpen}
						bg="whiteAlpha.800"
						_hover={{ bg: "white", borderColor: "red.500", color: "red.500" }}
						color="gray.400"
						boxSize="25px"
						minW="25px"
						p={2}
						borderWidth={2}
						icon={<i className={"fas " + (icon ?? "fa-times") + " fa-fw "} />}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
					></IconButton>
				</Tooltip>
			)}
			<AlertDialog isCentered isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader
							letterSpacing={1}
							color="gray.400"
							fontWeight="bold"
							textTransform={"uppercase"}
							pb={2}
						>
							<Heading fontSize="2xl" letterSpacing={2}>
								{alertDialogHeader}
							</Heading>
						</AlertDialogHeader>
						<Divider />
						<AlertDialogBody>
							<Stack w="full">
								<Text color="gray.500" fontSize="lg" as={"i"}>
									{alertDialogBody}
								</Text>

								{Boolean(alertDialogFooter) && <Text color="gray.400">{alertDialogFooter}</Text>}
							</Stack>
						</AlertDialogBody>

						<AlertDialogFooter>
							<HStack spacing={3} justify={"flex-end"} w="full">
								<Button
									ref={cancelRef}
									onClick={onClose}
									fontSize="sm"
									letterSpacing={1}
									textTransform={"uppercase"}
									fontWeight={"bold"}
									px={3}
									color="gray.500"
								>
									<HStack spacing={1}>
										<i className={"fas fa-times fa-fw "}></i>
										<Text fontSize="sm" letterSpacing={1} textTransform={"uppercase"}>
											Cancel
										</Text>
									</HStack>
								</Button>

								<Button
									textTransform={"uppercase"}
									colorScheme={confirmationButtonScheme}
									isLoading={isLoading}
									loadingText={loadingText}
									onClick={() => {
										confirmationFunction();
										onClose();
									}}
									px={2}
								>
									<HStack spacing={2}>
										{Boolean(icon) && <i className={"fas fa-trash fa-fw "}></i>}
										<Text fontSize="sm" letterSpacing={1} textTransform={"uppercase"}>
											{buttonTitle}
										</Text>
									</HStack>
								</Button>
							</HStack>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
}
