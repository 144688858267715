export const theme = {
	breakpoints: {
		sm: "544px",
		md: "992px",
		lg: "1184px",
		xl: "1424px",
		"2xl": "1553px",
	},
	colors: {
		brand: {
			primary: "rgba(35, 78, 82)",
			primaryOP: "#B9C1BB05",
			secondary: "#D7D7D9",
			gray: "#595959",
			black: "#262626",
			infoPrimary: "#1171AD",
			infoSecondary: "#5AACE0",
			successPrimary: "#079156",
			successSecondary: "#00C7A9",
			light: "#B9C1BB",
			warningPrimary: "#C77C14",
			warningSecondary: "#C79E0A",
			error: "#C73A00",
		},
	},
	components: {
		Heading: {
			baseStyle: {
				fontFamily: "'Lato', sans-serif",
			},
			sizes: {
				xs: {
					fontFamily: "'Helvetica', sans-serif",
				},
				sm: {
					fontFamily: "'Helvetica', sans-serif",
				},
				md: {
					fontFamily: "'Helvetica', sans-serif",
				},
				lg: {
					fontFamily: "'Helvetica', sans-serif",
				},
			},
			variants: {
				title: {
					letterSpacing: ".20rem",
					textTransform: "uppercase",
				},
			},
		},
		Text: {
			baseStyle: {
				fontFamily: "'Helvetica', sans-serif",
			},
		},
		Button: {
			colorScheme: {
				brandPrimary: {
					background: "#007A68!important",
					color: "white",
				},
			},
		},
		Tab: {
			_focus: {
				boxShadow: "white",
			},
		},
		Radio: {
			colorScheme: {
				brandPrimary: {
					"data-checked": {
						background: "#007A68!important",
					},
				},
			},
		},
	},
	shadows: {
		outline: "0 !important",
	},
};
