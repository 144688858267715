import React, { useState } from "react";
import MainContent from "../../legacy/App/components/MainContent";
import { JOYRIDE_HOME } from "../../joyride";
import HomePage from "./pages/HomePage";

export default function Home(props) {
	// const [tabVal, setTabVal] = useState(0);
	const [runJoyride, setRunJoyride] = useState(false);

	const joyrideCallback = (joyrideState) => {
		if (
			joyrideState.action === "reset" ||
			joyrideState.action === "close" ||
			joyrideState.action === "skip" ||
			joyrideState.action === "last"
		) {
			setRunJoyride(false);
		}
	};

	return (
		<MainContent
			// onTabChange={(tabVal) => setTabVal(tabVal)}
			// tabVal={tabVal}
			{...props}
			runJoyride={runJoyride}
			joyrideSteps={JOYRIDE_HOME}
			joyrideCallback={joyrideCallback}
			startJoyride={() => setRunJoyride(true)}
		>
			<HomePage />
		</MainContent>
	);
}
