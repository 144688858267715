import React, { useState } from "react";
import {
	Flex,
	Heading,
	Text,
	Stack,
	Drawer,
	DrawerContent,
	DrawerCloseButton,
	DrawerHeader,
	DrawerFooter,
	Button,
	DrawerBody,
	HStack,
} from "@chakra-ui/react";
import DataInput from "../../../core/Inputs/data/DataInput";
import CompanyEvent from "../classes/CompanyEvent";

export default function UpcomingEventsDrawer({ isOpen, onClose, createEvent, event, saveEvent }) {
	const [companyEvent, setCompanyEvent] = useState(new CompanyEvent(event));
	const [region, setRegionID] = useState(companyEvent.regionID);
	const [title, setTitle] = useState(companyEvent.title);
	const [description, setDescription] = useState(companyEvent.description);
	const [startDate, setStartDate] = useState(event?.startDate ? new Date(event.startDate) : null);
	const [endDate, setEndDate] = useState(event?.endDate ? new Date(event.endDate) : null);
	const [eventType, setEventType] = useState(companyEvent.eventType);

	const updateStartDate = (date) => {
		let startDate = date;
		setStartDate(startDate);
		setEndDate(startDate);
	};

	const submitEvent = () => {
		companyEvent.setTitle(title);
		companyEvent.setRegionID(region);
		companyEvent.setDescription(description);
		companyEvent.setEventType(eventType);
		companyEvent.setStartDate(startDate);
		companyEvent.setEndDate(endDate);
		setCompanyEvent(companyEvent);

		if (Boolean(event)) {
			saveEvent(companyEvent);
		} else {
			createEvent(companyEvent)();
		}
	};

	const regionArr = [
		{ value: "N/A", text: "All" },
		{ value: 0, text: "SoCal" },
		{ value: 1, text: "NorCal" },
		{ value: 3, text: "Hawaii" },
	];

	const eventTypeArr = [
		{ value: "Company", text: "Company Event" },
		{ value: "Holiday", text: "Holiday" },
	];

	return (
		<Drawer isOpen={isOpen} placement="right" size="md" onClose={onClose}>
			<DrawerContent>
				<DrawerHeader borderBottomWidth="1px" justify="center">
					<HStack w="full" align="center">
						<Heading as="h5" size="lg" color="gray.500" letterSpacing={2} textTransform="uppercase">
							{title === "" ? "New Event" : "Edit Event"}
						</Heading>
						<DrawerCloseButton />
					</HStack>
				</DrawerHeader>
				<DrawerBody>
					<Stack spacing="8" key={"EventDrawer"} py={4}>
						<Stack>
							<Flex w="full" textAlign="center" align="center" px="1">
								<Text
									h="full"
									fontSize="md"
									textTransform="uppercase"
									color="gray.500"
									fontWeight="600"
									display="inline-block"
								>
									Event Info
								</Text>
							</Flex>

							<Stack
								p="4"
								spacing={4}
								bg="blackAlpha.50"
								border="1px"
								borderColor="blackAlpha.100"
								rounded="10"
							>
								<Stack spacing={2} direction="row">
									<DataInput
										name="start"
										type="date"
										label="Start Date"
										value={startDate}
										onChange={updateStartDate}
										placeholder="mm/dd/yyyy"
										inputProps={{ textAlign: "left" }}
										isModal
									/>
									<DataInput
										name="end"
										type="date"
										label="End Date"
										value={endDate}
										onChange={({ target }) => setEndDate(target.value)}
										placeholder="mm/dd/yyyy"
										inputProps={{ textAlign: "left" }}
										isModal
									/>
								</Stack>
								<DataInput
									variant="outline"
									name="title"
									type="text"
									label="Event Title"
									defaultValue={title}
									onBlur={({ target }) => setTitle(target.value)}
									isModal
								/>
								<DataInput
									variant="outline"
									name="description"
									type="textarea"
									label="Description"
									placeholder="e.g. The PPM Portal will be down for maintenance..."
									defaultValue={description}
									onBlur={({ target }) => setDescription(target.value)}
									isModal
								/>
								<DataInput
									variant="outline"
									name="heading"
									type="select"
									label="Event Type"
									options={eventTypeArr}
									my={0}
									onChange={({ target }) => setEventType(target.value)}
									value={eventType}
									placeholder="Select Event Type"
									isModal
								/>
								<DataInput
									variant="outline"
									name="region"
									type="select"
									label="Region (Optional)"
									value={region}
									options={regionArr}
									onChange={({ target }) => setRegionID(target.value)}
									isModal
								/>
							</Stack>
						</Stack>
					</Stack>
				</DrawerBody>
				<DrawerFooter borderTopWidth="1px">
					<Button colorScheme="teal" mr={3} onClick={submitEvent}>
						SAVE
					</Button>
					<Button colorScheme="red" variant="outline" onClick={onClose}>
						CANCEL
					</Button>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}
