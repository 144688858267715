import React, { useMemo } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import LabelValuePairsPDF from "./LabelValuePairsPDF";
import TablePDF from "./TablePDF";
import { chakraThemeColorsMap } from "../../../helperFunctions";

export default function SectionPDF(props) {
	const section = useMemo(() => props.section, [props.section]);

	let sectionStyles = StyleSheet.create({
		container: {
			flexDirection: "column",
			paddingVertical: (section?.py ?? 2) * 2,
			paddingHorizontal: (section?.px ?? 4) * 2,
		},
		heading: {
			borderTopColor: chakraThemeColorsMap.gray500,
			borderTopWidth: section.divider ? 1 : 0,
			paddingVertical: 4,
			flexDirection: "row",
			justifyContent: "flex-start",
		},
		labelText: {
			textTransform: "uppercase",
			color: chakraThemeColorsMap.gray,
			fontSize: 12,
			letterSpacing: 1,
			paddingRight: 4,
			fontWeight: "bolder",
		},
		headingText: {
			textTransform: "uppercase",
			color: chakraThemeColorsMap.gray,
			fontSize: 12,
			letterSpacing: 1,
			fontWeight: "bolder",
		},
		subSections: {
			flexDirection: "row",
			flexWrap: "wrap",
		},
	});

	return (
		<>
			{/* SECTIONS */}
			{section.show && (
				<View style={sectionStyles?.container} key={section.sectionUID} wrap={section.wrap}>
					{section.heading && section?.type === "labelValuePairs" && (
						<View style={sectionStyles?.heading}>
							{Boolean(section.label) && <Text style={sectionStyles?.labelText}>{section.label}</Text>}
							<Text style={sectionStyles?.headingText}>{section.heading}</Text>
						</View>
					)}

					{/* SUBSECTIONS */}
					<View style={sectionStyles?.subSections} key={section.sectionUID + "-subSections"}>
						{section.subSections?.map((subSection, i) => {
							let calcWidth = subSection.width;
							let subSectionCount = section.subSections?.length ?? 1;
							if (subSectionCount > 1 && calcWidth === "100%") {
								calcWidth = 100 / subSectionCount + "%";
							}

							let subSectionStyles = StyleSheet.create({
								flexDirection: subSection?.flexDirection,
								flexGrow: 0,
								flexWrap: subSection?.flexWrap,
								width: calcWidth,
								maxWidth: calcWidth,
								justifyContent: "center",
								alignItems: "center",
								// backgroundColor: "red",
								backgroundColor: subSection.bg ?? "transparent",
							});

							return (
								<View style={subSectionStyles} wrap={subSection?.wrap} key={i}>
									{subSection?.type === "labelValuePairs" && (
										<LabelValuePairsPDF
											data={subSection?.data ?? []}
											row={subSection?.row ?? {}}
											columns={subSection?.columns ?? []}
											footer={subSection?.footer ?? []}
											subSection={subSection ?? null}
										/>
									)}
									{["table", "virtualTable"].includes(subSection?.type) && (
										<TablePDF
											// height={props.spacing?.height}
											divider={section.divider ?? null}
											label={section.label ?? null}
											heading={section.heading ?? null}
											columns={subSection?.columns ?? []}
											data={subSection?.data ?? []}
											header={subSection?.header ?? []}
											footer={subSection?.footer ?? []}
											minRows={subSection?.minRows ?? 1}
											maxRows={subSection?.maxRowsPDF ?? null}
											sliceType={subSection?.sliceType ?? null}
											filterBy={subSection?.filterBy ?? null}
											maxWidth={calcWidth}
											wrap={subSection?.wrap}
										/>
									)}
								</View>
							);
						})}
					</View>
				</View>
			)}
		</>
	);
}
