import { ajaxGET, ajaxPOST, ajaxPATCH, ajaxDELETE, testGET, testPOST, setQueryParams } from "./api-functions";
// import { formatValue } from "../helperFunctions";
import { portalUser } from "../App";

const apiBaseURL =
	(process.env.NODE_ENV === "development"
		? "https://ppmportalappnodejs-api.azurewebsites.net"
		: window.location.origin) + "/api/projects/";

export default class ProjectsAPI {
	async TestGET(headers = {}) {
		let response = await testGET(apiBaseURL, headers);
		return response;
	}

	async TestPOST(body = {}, headers = {}) {
		let response = await testPOST(apiBaseURL, body, headers);
		return response;
	}
	// ---------------- CONTRACTS --------------

	// GET Contracts by msUserID
	async GetContracts(msUserID = portalUser.user?.msUserID, contractStatus = null) {
		var apiRouteURL = apiBaseURL + `userData/${msUserID}/contracts`;
		if (Boolean(contractStatus)) {
			apiRouteURL += "?contractStatus=" + contractStatus;
		}
		apiRouteURL = setQueryParams(apiRouteURL);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	// ---------- CONTRACT DATA ------------

	//PortalUser_ContractData]
	async GetContractData(jccmKeyID = null) {
		var apiRouteURL = apiBaseURL + `contractData/${jccmKeyID}`;
		apiRouteURL = setQueryParams(apiRouteURL);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//PortalUser_ContractData_CostReimbursible
	async GetCostReimbursibleData(jccmKeyID = null) {
		var apiRouteURL = apiBaseURL + `contractData/${jccmKeyID}/costReimbursible`;
		apiRouteURL = setQueryParams(apiRouteURL);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	// CONTRACT  DATA BY MTH-------------------------------------------------------------
	// Update JCCM by JCCMKeyID
	async UpdateJCCM(jccmKeyID = null, data = {}) {
		var apiRouteURL = apiBaseURL + `contractData/jccm/${jccmKeyID}`;
		if (Boolean(jccmKeyID) && Object.keys(data)?.length > 0) {
			let response = await ajaxPATCH(apiRouteURL, data);
			return response;
		} else {
			return;
		}
	}

	// Update JCJM by JCCMKeyID
	async UpdateJCJM(jccmKeyID = null, data = {}) {
		var apiRouteURL = apiBaseURL + `contractData/jcjm/${jccmKeyID}`;
		if (Boolean(jccmKeyID) && Object.keys(data)?.length > 0) {
			let response = await ajaxPATCH(apiRouteURL, data);
			return response;
		} else {
			return;
		}
	}

	// ---------- PMOP ----------

	// Insert PMOP by JCCMKeyID
	async InsertPMOP(jccmKeyID = null, data = {}) {
		var apiRouteURL = apiBaseURL + `contractData/pmop/${jccmKeyID}`;
		if (Boolean(jccmKeyID) && Object.keys(data)?.length > 0) {
			let response = await ajaxPOST(apiRouteURL, data);
			return response;
		} else {
			return;
		}
	}

	// Update PMOP by PMOPKeyID
	async UpdatePMOP(pmopKeyID = null, data) {
		var apiRouteURL = apiBaseURL + `contractData/pmop/${pmopKeyID}`;
		if (Boolean(pmopKeyID) && Object.keys(data)?.length > 0) {
			let response = await ajaxPATCH(apiRouteURL, data);
			return response;
		} else {
			return;
		}
	}

	// Delete PMOP by PMOPKeyID
	async DeletePMOP(pmopKeyID = null) {
		var apiRouteURL = apiBaseURL + `contractData/pmop/${pmopKeyID}`;
		if (Boolean(pmopKeyID)) {
			let response = await ajaxDELETE(apiRouteURL);
			return response;
		} else {
			return;
		}
	}

	// ---------- PMOI ----------

	// POST PMOI by PMOPKeyID
	async InsertPMOI(pmopKeyID = null, data = {}) {
		var apiRouteURL = apiBaseURL + `contractData/pmoi/${pmopKeyID}`;
		if (Boolean(pmopKeyID) && Object.keys(data)?.length > 0) {
			let response = await ajaxPOST(apiRouteURL, data);
			return response;
		} else {
			return;
		}
	}

	// PATCH PMOI by PMOIKeyID
	async UpdatePMOI(pmoiKeyID = null, data = {}) {
		var apiRouteURL = apiBaseURL + `contractData/pmoi/${pmoiKeyID}`;
		if (Boolean(pmoiKeyID) && Object.keys(data)?.length > 0) {
			let response = await ajaxPATCH(apiRouteURL, data);
			return response;
		} else {
			return;
		}
	}

	// DELETE PMOI by PMOIKeyID
	async DeletePMOI(pmoiKeyID = null) {
		var apiRouteURL = apiBaseURL + `contractData/pmoi/${pmoiKeyID}`;
		if (Boolean(pmoiKeyID)) {
			let response = await ajaxDELETE(apiRouteURL);
			return response;
		} else {
			return;
		}
	}

	// GET Sync Manpower Projections
	async SyncManpowerProjections(jccmKeyID = null) {
		var apiRouteURL = apiBaseURL + `contractData/${jccmKeyID}/sync/manpowerProjections`;
		apiRouteURL = setQueryParams(apiRouteURL);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	// ---------- OPERATIONS DATA ----------

	// GET List of Projects by User
	async GetUserProjectList(employeeUID = null) {
		var apiRouteURL = apiBaseURL + `projectsMin/${employeeUID}`;
		apiRouteURL = setQueryParams(apiRouteURL);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	/********************************************************************************************************************************/
	/*********************************************** TO BE DELETED - FOR BACKUP ONLY  **********************************************/
	/*************************************************** MONTHLY FORECAST REPORT **************************************************/
	/*****************************************************************************************************************************/

	// //POST Insert Manpower Projection Row //Currently unused but is commented out in ManpowerProjectsByMth.jsx
	// InsertManpowerProjectionRow(data={}) {
	// 	var apiRouteURL = apiBaseURL + "mfr/projections";
	// 	if (Object.keys(data)?.length > 0) {
	// 					let response = await ajaxPOST(apiRouteURL, data);
	// return response;
	// 	} else {
	// 		return;
	// 	}
	// }

	//PATCH Manpower Projection
	async UpdateManpowerProjection(keyID = null, data = {}) {
		var apiRouteURL = apiBaseURL + `mfr/projections/${keyID}`;
		if (Boolean(keyID) && Object.keys(data)?.length > 0) {
			let response = await ajaxPATCH(apiRouteURL, data);
			return response;
		} else {
			return;
		}
	}
}
