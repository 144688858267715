import moment from "moment";
import { v4 } from "uuid";
import { portalUser } from "../../../App";
import UsersAPI from "../../../APIs/UsersAPI";
import { formatDate, log, formatValue, convertArrayToMap } from "../../../helperFunctions";

export default class Notification {
	constructor(notification) {
		this.usersAPI = new UsersAPI();
		this.queryData = notification?.queryData ?? notification?.QueryData ?? "Notification";
		this.keyID = notification?.keyID ?? notification?.KeyID ?? null;
		this.notificationUID =
			notification?.notificationUID?.toLowerCase() ?? notification?.NotificationUID?.toLowerCase() ?? v4();
		this.employeeUID = notification?.employeeUID?.toLowerCase() ?? notification?.EmployeeUID?.toLowerCase() ?? null;
		this.employeeName = notification?.employeeName ?? null;
		this.referenceUID =
			notification?.referenceUID?.toLowerCase() ?? notification?.ReferenceUID?.toLowerCase() ?? null;
		this.requestUID = notification?.requestUID?.toLowerCase() ?? notification?.RequestUID?.toLowerCase() ?? null;

		this.type = notification?.type ?? notification?.Type ?? null;
		this.subject = notification?.subject ?? notification?.Subject ?? null;
		this.body = notification?.body ?? notification?.Body ?? null;
		this.category = notification?.category ?? notification?.Category ?? null;
		this.redirectURL = notification?.redirectURL ?? notification?.Link ?? null;

		this.createdBy =
			notification?.createdBy?.toLowerCase() ??
			notification?.CreatedBy?.toLowerCase() ??
			portalUser?.user?.employeeUID ??
			null;
		this.createdByName = notification?.createdByName ?? null;
		this.dateCreated =
			formatDate(notification?.dateCreated ?? notification?.DateCreated) ??
			moment(new Date()).format("MM/DD/YYYY HH:mm");
		this.dateAcknowledged = formatDate(notification?.dateAcknowledged ?? notification?.DateAcknowledged) ?? null;
		this.dateExpired = formatDate(notification?.dateExpired ?? notification?.DateExpired) ?? null;

		this.approverUID = notification?.approverUID?.toLowerCase() ?? notification?.ApproverUID?.toLowerCase() ?? null;
		this.approverName = notification?.approverName ?? null;
		this.mthCreated = notification?.mthCreated;
		this.status = notification?.status ?? null;

		this.timeOffRequest = notification?.timeOffRequest ?? null;

		this.updateNotification();
	}

	updateNotification() {
		let usersMap = convertArrayToMap(portalUser?.getUsers(), "employeeUID");
		this.employeeName = usersMap[this.employeeUID]?.getName();
		this.approverName = usersMap[this.approverUID]?.getName();
		this.createdByName = usersMap[this.createdBy]?.getName();
		this.mthCreated = this.getMthCreated();
		this.status = this.getStatus();
	}

	getMthCreated() {
		let mthCreated = formatValue(
			moment(new Date(this.dateCreated)).startOf("month").format("MM/DD/YYYY"),
			0,
			"month"
		);
		return mthCreated;
	}

	getStatus() {
		let status = (Boolean(this.dateAcknowledged) ? "Past" : "New") + " Notifications";
		return status;
	}

	async insertSQL() {
		let result = await this.usersAPI.PortalUser_InsertNotification(this);
		log("INSERT RESULT", result);
		if (result.status === 200) {
			result = result.value;

			let keyID = result[0]?.keyID ?? null;
			if (Boolean(keyID)) {
				this.keyID = keyID;
			} else {
				log("SOMTHING WENT WRONG", result);
			}
		} else {
			log("result", result);
		}
		return this;
	}

	async updateSQL(updates) {
		if (Boolean(this.keyID)) {
			let result = await this.usersAPI.PortalUser_UpdateNotification(this.keyID, updates);
			if (result.status === 200) {
				log("UPDATE NOTIFICATION", result.value);
			} else {
				log("UPDATE NOTIFICATION ERROR", result);
			}
		}
	}

	async deleteSQL() {
		if (Boolean(this.keyID)) {
			let result = await this.usersAPI.PortalUser_DeleteNotification(this.keyID);
			log("DELETE NOTIFICATION", result.value);
		}
	}

	getSQLAttr(attr) {
		if (attr === "dateAcknowledged") {
			return "DateAcknowledged";
		}
	}

	async update(attr = null, value = null) {
		if (attr === "delete") {
			this.deleteSQL();
		} else {
			if (Boolean(attr)) {
				this[attr] = value;

				let update = { [this.getSQLAttr(attr)]: value };
				this.updateSQL(update);
			}
			this.updateNotification();
		}
	}
}
