import React from "react";
import ToggleButton from "./ToggleButton";

export default function EditButton({ editIsActive = "0", onClickEditButton }) {
	return (
		<ToggleButton
			toggleIsOpen={editIsActive}
			onClick={onClickEditButton}
			toggleOptions={[
				{
					isOpen: true,
					label: "Done",
					icon: "fas fa-cog fa-spin ",
					color: "blue.500",
					bg: "whiteAlpha.900",
				},
				{
					isOpen: false,
					label: "Edit",
					icon: "fas fa-cog ",
					color: "gray.500",
					bg: "whiteAlpha.700",
				},
			]}
		/>
	);
}
