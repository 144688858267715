import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
	Box,
	Flex,
	Badge,
	Heading,
	Avatar,
	HStack,
	Stack,
	Text,
	IconButton,
	Menu,
	MenuButton,
	Checkbox,
	useToast,
	Tooltip,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { portalUser } from "../../../App";
import { convertArrayToMap, arrayToString, log } from "../../../helperFunctions";
import { useAsyncDebounce } from "react-table";
// import { useAsyncDebounce } from "react-table";

export default function TeamReviewsToDoListRow(props) {
	const toast = useToast();
	const form = useMemo(() => props.form, [props.form]);
	const isDisabled = useMemo(() => form?.isDisabled(), [form]);
	const [commenters, setCommenters] = useState(
		portalUser.formData.commenters.filter((commenter) => commenter.formUID === form.formUID)
	);
	const [disableClick, setDisableClick] = useState(false);
	const [awaitingCommenters, setAwaitingCommenters] = useState(0);
	const [commenter, setCommenter] = useState(null);
	const [formIsVisible, setFormIsVisible] = useState(form?.isVisibleYN ?? "N");

	const formStatus = useMemo(() => {
		if (Boolean(form)) {
			return form?.getFormStatus();
		} else {
			return null;
		}
	}, [form]);

	const peerStatus = useMemo(() => {
		if (Boolean(form)) {
			return form?.getPeerStatus();
		} else {
			return null;
		}
	}, [form]);

	const isVisibilityDisabled = useMemo(() => {
		if (Boolean(form)) {
			return form?.getIsVisibilityDisabled();
		} else {
			return true;
		}
	}, [form]);

	// const isVisibleYN = useMemo(() => {
	// 	if (Boolean(form)) {
	// 		return form?.getIsVisibleYN();
	// 	} else {
	// 		return "N";
	// 	}
	// }, [form]);

	// const toggleVisibility = (ev) => {
	// 	ev.stopPropagation();
	// 	let value = ev.target.checked ? 1 : 0;
	// 	props.updateFormVisiblity(value, form?.formUID);
	// };

	// const toggleCommenterStatus = (ev) => {
	// 	ev.stopPropagation();
	// 	let value = ev.target.checked ? "1" : "0";
	// 	//commenter.updateFormCommenter("commenterStatus", value);
	// };

	const [updateTrigger, setUpdateTrigger] = useState(false);

	//Note: This had useAsyncDebounce
	const updateFormCommenterStatus = useAsyncDebounce(async () => {
		if (commenter) {
			let newStatus = parseInt(commenter.commenterStatus) === 1 ? 0 : 1;
			let result = await commenter.updateFormCommenter("commenterStatus", newStatus.toString());
			if (result) {
				setCommenter((prev) => ({ ...prev, commenterStatus: newStatus }));
				setUpdateTrigger(true);
				newStatus === 0
					? setAwaitingCommenters(awaitingCommenters - 1)
					: setAwaitingCommenters(awaitingCommenters + 1);
			} else {
				toast({
					title: "Something went wrong!",
					description: "Please refresh the page and try again!",
					status: "error",
					duration: 5000,
					isClosable: true,
				});
			}
		}
	}, 200);

	//Note: This had useAsyncDebounce
	const updateFormVisiblity = useAsyncDebounce(async () => {
		if (form) {
			let result = await form?.updateForm("isVisibleYN", form?.isVisibleYN);
			if (result) {
				setFormIsVisible(result?.isVisibleYN === "Y" ? "Y" : "N");
			} else {
				toast({
					title: "Something went wrong!",
					description: "Please refresh the page and try again!",
					status: "error",
					duration: 5000,
					isClosable: true,
				});
			}
		}
	}, 200);

	useEffect(() => {
		if (updateTrigger) {
			setUpdateTrigger(false);
		}
	}, [updateTrigger]);

	const awaitingCommentersCount = useCallback(() => {
		let count = 0;
		for (let i = 0; i < commenters?.length; i++) {
			if (commenters[i].commenterStatus === 1) {
				count++;
			}
		}
		setAwaitingCommenters(count);
	}, [commenters]);

	useEffect(() => {
		let commentersMap = convertArrayToMap(portalUser.formData?.commenters, "formUID", true);
		setCommenters(commentersMap[form.formUID]);
		awaitingCommentersCount();
		let tempCommenter = commentersMap[form.formUID.toString()]?.find(
			(commenter) => commenter.employeeUID === portalUser.user.employeeUID
		);
		setCommenter(tempCommenter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form.formUID]);

	//Upon the PM submitting the peer review, this prevents them from clicking into it
	//For some reason, before a refresh it's still clickable but this fixes that issue
	useEffect(() => {
		if (form.formType === "3") {
			if (parseInt(formStatus.id) === 1 && peerStatus?.completedEvals === peerStatus?.totalEvals) {
				setDisableClick(true);
			} else {
				setDisableClick(false);
			}
		}
	}, [form.formType, formStatus.id, peerStatus?.completedEvals, peerStatus?.totalEvals]);

	return (
		<Flex
			flex={1}
			w="full"
			onClick={(ev) => {
				if (!isDisabled && !disableClick) {
					ev.stopPropagation();
					props.selectForm(form?.formUID);
				}
			}}
			cursor={isDisabled || disableClick ? "not-allowed" : "pointer"}
			bg={isDisabled || disableClick ? "blackAlpha.200" : "unset"}
			justify="center"
		>
			<Box pl={1} bg="blue.200"></Box>
			<Stack p={2} flex={1} spacing={3} w="full">
				<motion.div whileHover={{ scale: 1.01, transition: { duration: 0.5 } }}>
					<Tooltip
						label={isDisabled && "THIS FORM IS NOT READY TO BE VIEWED"}
						isDisabled={!isDisabled}
						placement="top"
						hasArrow
					>
						<Flex
							p={3}
							rounded="md"
							bg="whiteAlpha.800"
							_hover={{
								borderWidth: 2,
								borderColor: "teal.500",
								bg: "white",
							}}
							borderWidth={2}
							flex={1}
							w="full"
							shadow={portalUser.styles?.cardShadow}
							borderColor={portalUser.styles?.cardBorderColor}
							justify="space-between"
							position="relative"
						>
							{(portalUser?.user?.isPortalAdminYN === "Y" ||
								(["2", "3"].includes(form?.formType) &&
									portalUser?.user?.employeeUID === form?.assignedTo)) && (
								<Flex position="absolute" right={0} top={0} p={1} align="flex-end" justify="flex-end">
									<Menu isLazy>
										<MenuButton
											as={IconButton}
											variant="ghost"
											size="sm"
											color="gray.500"
											icon={<i className="fas fa-ellipsis-h" />}
											onClick={(ev) => {
												ev.stopPropagation();
												props.openDrawer(form);
											}}
											disabled={isDisabled}
											cursor={isDisabled ? "not-allowed" : "pointer"}
											height="1.3rem"
										/>
									</Menu>
								</Flex>
							)}
							<Flex position="absolute" bottom={0} right={0} py={1} px={2}>
								<HStack spacing={1} color="gray.400">
									<Text as="i" fontSize="xs">
										Last Updated:
									</Text>
									<Text as="i" fontSize="xs">
										{Boolean(form?.dateModified) ? form?.dateModified?.format("M/D/YYYY") : "N/A"}
									</Text>
								</HStack>
							</Flex>

							<Stack spacing={2} w="full" flex={1}>
								<Stack
									direction="row"
									w="full"
									flex={1}
									justify="flex-start"
									align="flex-start"
									wrap="wrap"
								>
									<Flex w="full" flex={2}>
										<FormEmployeeHeader
											form={form}
											regionMap={props.regionMap}
											deptMap={props.deptMap}
										/>
									</Flex>
									<Stack spacing={1} direction="column" flex={1} pt={2}>
										<Flex align="center" justify="flex-end">
											<Badge
												colorScheme={formStatus?.color}
												py={1}
												px={2}
												rounded="md"
												borderColor={formStatus?.color + ".300"}
												borderWidth={2}
											>
												<HStack fontWeight="bold" color={formStatus?.color + ".600"}>
													<Text className={formStatus?.icon} />
													<Text>{formStatus?.status}</Text>
												</HStack>
											</Badge>
										</Flex>
										{form?.formType === "3" && Boolean(peerStatus) && (
											<Flex align="center" justify="flex-end">
												<Badge
													colorScheme={
														peerStatus?.completedEvals === peerStatus?.totalEvals
															? "green"
															: "yellow"
													}
													py={1}
													px={2}
													rounded="md"
													borderColor={
														(peerStatus?.completedEvals === peerStatus?.totalEvals
															? "green"
															: "yellow") + ".300"
													}
													borderWidth={2}
												>
													<HStack
														fontWeight="bold"
														color={
															(peerStatus?.completedEvals === peerStatus?.totalEvals
																? "green"
																: "yellow") + ".600"
														}
													>
														<Text className={"fas fa-user-check"} />
														<HStack spacing={1}>
															<Text fontSize="sm">
																{(peerStatus?.completedEvals ?? 0) +
																	"/" +
																	(peerStatus?.totalEvals ?? 0)}
															</Text>

															<Text>Peer Evals</Text>
														</HStack>
													</HStack>
												</Badge>
											</Flex>
										)}
										{commenters?.length > 0 && (
											<Flex align="center" justify="flex-end">
												<Badge
													colorScheme={
														awaitingCommenters === commenters.length ? "green" : "orange"
													}
													py={1}
													px={2}
													rounded="md"
													borderColor={
														awaitingCommenters === commenters.length
															? "green.300"
															: "orange.300"
													}
													borderWidth={2}
												>
													<HStack
														spacing={1}
														fontWeight="bold"
														color={
															awaitingCommenters === commenters.length
																? "green.600"
																: "orange.600"
														}
													>
														<Text>
															{(awaitingCommenters ?? 0) + "/" + commenters.length}
														</Text>
														<Text>{"Commenter" + (commenters?.length > 1 ? "s" : "")}</Text>
													</HStack>
												</Badge>
											</Flex>
										)}
									</Stack>
								</Stack>

								<Stack spacing={4} direction={"row"} align="center">
									{form?.formType === "2" && (
										<FormEmployeeLabel employeeUID={form?.assignedTo} label="Reviewer" />
									)}

									{form?.formType === "3" && (
										<>
											<FormEmployeeLabel employeeUID={form?.assignedTo} label="Peer Evaluator" />
											<FormEmployeeLabel
												employeeUID={
													form?.sitDownReviewer ?? form?.signedOffBy ?? form?.assignedTo
												}
												label="Reviewer"
											/>
										</>
									)}

									{["2", "3"].includes(form?.formType) &&
										form?.formUID === form?.parentFormUID &&
										form?.assignedTo === portalUser.user?.employeeUID && (
											<Tooltip
												label={
													!isVisibilityDisabled && Boolean(form?.dateApproved)
														? formIsVisible === "Y"
															? "CLICK TO HIDE THIS FORM FROM THE EMPLOYEE"
															: "CLICK TO MAKE THIS FORM VISIBLE FOR THE EMPLOYEE"
														: "THIS FORM REQUIRES HR APPROVAL"
												}
												hasArrow
												placement="bottom"
											>
												<Stack
													spacing={1}
													onClick={(e) => {
														e.stopPropagation();
														// setCurrentForm(form);
														updateFormVisiblity();
														setUpdateTrigger(true);
													}}
												>
													<Text
														textTransform="uppercase"
														fontWeight="semibold"
														fontSize="xs"
														color="gray.400"
														lineHeight="none"
														isTruncated
													>
														Form Visibility
													</Text>

													<Checkbox
														key={form?.formUID + formIsVisible}
														colorScheme="green"
														size="lg"
														rounded="md"
														isChecked={formIsVisible === "Y"}
														isDisabled={
															isVisibilityDisabled || !Boolean(form?.dateApproved)
														}
													>
														<Text
															textTransform="uppercase"
															fontWeight="semibold"
															fontSize="sm"
															color="gray.500"
															lineHeight="none"
															isTruncated
														>
															{formIsVisible === "Y" ? "Visible" : "Not Visible"}
														</Text>
													</Checkbox>
												</Stack>
											</Tooltip>
										)}

									{["2", "3"].includes(form?.formType) && Boolean(commenter) && (
										<Tooltip
											label={
												!(parseInt(form?.getFormStatus()?.id) > 2)
													? parseInt(commenter?.commenterStatus) === 1
														? "CLICK TO MARK COMMENTS NOT DONE"
														: "CLICK TO MARK COMMENTS ARE DONE"
													: "THIS FORM HAS BEEN SUBMITTED AND YOUR COMMENTER STATUS CANNOT BE CHANGED"
											}
											hasArrow
											placement="bottom"
										>
											<Stack
												spacing={1}
												onClick={(ev) => {
													if (parseInt(form?.getFormStatus()?.id) <= 2) {
														ev.stopPropagation();
														updateFormCommenterStatus();
														setUpdateTrigger(true);
													}
												}}
											>
												<Text
													fontSize="xs"
													color="gray.400"
													lineHeight="none"
													textTransform="uppercase"
													fontWeight="semibold"
													isTruncated
												>
													Comment Status
												</Text>
												<Checkbox
													colorScheme="green"
													size="lg"
													// borderWidth={1}
													// borderColor="gray.300"
													rounded="md"
													isChecked={parseInt(commenter?.commenterStatus) === 1}
													isDisabled={!Boolean(commenter)}
													_hover={{
														cursor:
															parseInt(form?.getFormStatus()?.id) > 2
																? "not-allowed"
																: "pointer",
													}}
													// onChange={(ev) => {
													// 	ev.stopPropagation();
													// 	updateFormCommenterStatus(ev);
													// }}
												>
													<Text
														textTransform="uppercase"
														fontWeight="semibold"
														fontSize="sm"
														color="gray.500"
														lineHeight="none"
														isTruncated
													>
														{parseInt(commenter?.commenterStatus) === 1
															? "Finished"
															: "Awaiting Comments"}
													</Text>
												</Checkbox>
											</Stack>
										</Tooltip>
									)}
								</Stack>

								{/* <HStack spacing={4}>
							<Flex align="center" justify="center">
								<Badge colorScheme={formStatus?.color} p={1} rounded="md">
									<HStack fontWeight="bold" color={formStatus?.color + ".600"}>
										<Text className={formStatus?.icon} />
										<Text>{formStatus?.status}</Text>
								</Badge>
							</Flex>
							<Stack spacing={0} align="center">
								<Text fontSize="sm" color="gray.600" lineHeight="shorter">
									{isVisibleYN === "Y" ? "Is Visible" : "Not Visible"}
								</Text>
								<ToggleSwitch
									id={"visibility-" + form?.formUID + "-" + isVisibleYN}
									size="sm"
									isChecked={parseInt(commenter?.commenterStatus) === 1}
									onChange={toggleCommenterStatus}
								/>
							</Stack>

							<Stack spacing={0} align="center">
								<Text fontSize="sm" color="gray.600">
									{Boolean(commenter?.commenterStatus) ? "Done Commenting" : "Finished Comments"}
								</Text>

								{Boolean(commenter) && ( 
								<ToggleSwitch
									id={"visibility-" + form?.formUID + "-" + isVisibleYN}
									size="sm"
									isChecked={cparseInt(commenter?.commenterStatus) === 1}
									onChange={toggleCommenterStatus}
								/>
								)}
							</Stack>


						</HStack> */}

								{/* 
						<Stack spacing={2} direction="column" justify="space-between">
							<Stack spacing={0}>
								<Flex justify="space-between">
									<Stack direction="row" align="center">
										{Boolean(form?.dateDue) && !Boolean(form?.dateCompleted) && (
											<Badge colorScheme="red">
												DUE: {moment(form?.dateDue).format("MM/DD/YYYY")}
											</Badge>
										)}
										{form?.numSurveys > 0 && (
											<Tag size="sm" colorScheme="blue">
												<TagLabel>
													Completed Responses: {form?.surveysCompleted}/{form?.numSurveys}
												</TagLabel>
											</Tag>
										)}
									{awaitingCommenters > 0 && (
											<Tag size="sm" colorScheme="orange">
												<TagLabel>
													Awaiting {commenters.length} Commenter
													{commenters.length > 1 ? "s" : ""}
												</TagLabel>
											</Tag>
										)} 
									</Stack>
									{portalUser?.user?.employeeUID !== form?.assignedFor && (
										<HStack align="center">

											<Tooltip
												label="REQUIRES HR APPROVAL"
												placement="top"
												
												isDisabled={!isVisibilityDisabled}
											>
												<FormControl
													display="flex"
													align="center"
													onClick={(ev) => {
														ev.stopPropagation();
													}}
												>
													<FormLabel
														htmlFor={"visibility-" + form?.formUID + "-" + isVisibleYN}
														mb={0}
														mr={2}
													>
														<Text fontSize="sm" color="gray.600">
															{isVisibleYN === "Y" ? "Visible" : "Not Visible"}
														</Text>
													</FormLabel>
													<ToggleSwitch
														id={"visibility-" + form?.formUID + "-" + isVisibleYN}
														size="sm"
														isChecked={isVisibleYN === "Y"}
														isDisabled={isVisibilityDisabled}
														onChange={toggleVisibility}
													/>
												</FormControl>
											</Tooltip>
											)} 
										</HStack>
									)}
								</Flex>
							</Stack>
						</Stack> */}
							</Stack>
						</Flex>
					</Tooltip>
				</motion.div>
			</Stack>
			<Box pr={-1}></Box>
		</Flex>
	);
}

function FormEmployeeHeader(props) {
	const form = useMemo(() => props.form ?? null, [props.form]);
	const [user, setUser] = useState(null);

	const getUser = useCallback(
		async (employeeUID) => {
			let user = await portalUser.getUser(employeeUID, "employeeUID");
			if (!Boolean(user)) {
				user =
					portalUser?.userData?.find((d) => d.queryData === "User" && d.employeeUID === form?.assignedFor) ??
					null;
			}
			setUser(user);
		},
		[form?.assignedFor]
	);

	useMemo(() => {
		if (!Boolean(user) || form?.assignedFor !== user?.employeeUID) {
			getUser(form?.assignedFor);
		}
	}, [form?.assignedFor, getUser, user]);

	return (
		<HStack spacing={2}>
			<Avatar shadow="md" size="md" name={user?.name} src={user?.profilePhoto ?? null} />

			<Stack spacing={0} px={1} isTruncated>
				<Heading
					fontSize="lg"
					color="gray.600"
					textTransform="uppercase"
					isTruncated
					letterSpacing={1}
					lineHeight="base"
				>
					{form?.formTitle}
				</Heading>

				<Heading fontSize="md" color={"gray.500"} textTransform="uppercase" isTruncated letterSpacing={1}>
					{user?.name}
				</Heading>

				<Text fontSize="sm" color="gray.500">
					{arrayToString(
						[user?.title, props.regionMap[user?.regionID]?.region, props.deptMap[user?.deptID]?.dept],
						" - "
					)}
				</Text>
			</Stack>
		</HStack>
	);
}

function FormEmployeeLabel(props) {
	const [user, setUser] = useState(null);

	const getUser = useCallback(async (employeeUID) => {
		let user = await portalUser.getUser(employeeUID, "employeeUID");
		if (!Boolean(user)) {
			user = portalUser?.userData?.find((d) => d.queryData === "User" && d.employeeUID === employeeUID) ?? null;
		}
		setUser(user);
	}, []);

	useMemo(() => {
		if (!Boolean(user) || props.employeeUID !== user?.employeeUID) {
			getUser(props?.employeeUID);
		}
	}, [getUser, props.employeeUID, user]);

	return (
		<Flex align="flex-end" isTrucated>
			<HStack spacing={2} align="center" isTrucated>
				<Avatar shadow="md" size="xs" name={user?.name} src={user?.profilePhoto ?? null} />
				<Stack textTransform="uppercase" fontWeight="semibold" spacing={0} isTrucated>
					<Text fontSize="xs" color="gray.400" lineHeight="shorter" isTruncated>
						{props.label ?? "Employee"}
					</Text>
					<Heading
						fontSize="sm"
						letterSpacing={1}
						isTrucated
						// fontWeight={Boolean(user?.name) ? "semibold" : "normal"}
						fontWeight="normal"
						color={Boolean(user?.name) ? "gray.500" : "gray.400"}
					>
						{user?.name ?? "N/A"}
					</Heading>
				</Stack>
			</HStack>
		</Flex>
	);
}

// function FormListItemMenu(props) {
// 	const [isOpen, setOpen] = useBoolean();

// 	if (Boolean(props.subMenuItems)) {
// 		return (
// 			<Menu isOpen={isOpen} onClose={setOpen.off} placement="right" gutter={0} isLazy>
// 				<MenuButton
// 					onClick={(ev) => {
// 						ev.stopPropagation();
// 					}}
// 					w="full"
// 					_hover={{ bg: props.disabled ? "" : "gray.100" }}
// 					onMouseEnter={setOpen.on}
// 					onMouseLeave={setOpen.off}
// 					display={props.display}
// 					disabled={props.disabled}
// 					bg={props.disabled ? "blackAlpha.100" : ""}
// 				>
// 					<MenuItem icon={Boolean(props.icon) ? props.icon : ""}>
// 						<Stack w="full" direction="row" justify="space-between" align={"center"}>
// 							<Text>{props.title}</Text>
// 							<i className="fas fa-caret-right" />
// 						</Stack>
// 					</MenuItem>
// 				</MenuButton>

// 				{Boolean(props.children) && (
// 					<MenuList onMouseEnter={setOpen.on} onMouseLeave={setOpen.off}>
// 						{props.children}
// 					</MenuList>
// 				)}

// 				{!Boolean(props.children) && (
// 					<MenuList>
// 						{props.subMenuItems?.map((item, i) => (
// 							<FormListItemMenu title={item.title} icon={item.icon} subMenuItems={item.subMenuItems} />
// 						))}
// 					</MenuList>
// 				)}
// 			</Menu>
// 		);
// 	} else {
// 		return (
// 			<MenuItem icon={<Icon as={props.icon} />} {...props}>
// 				{props.title}
// 			</MenuItem>
// 		);
// 	}
// }
