import React, { useState, useEffect, useCallback } from "react";
import { Calendar as ReactCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import parse from "html-react-parser";
import {
	Flex,
	SimpleGrid,
	IconButton,
	Box,
	Heading,
	Text,
	Button,
	HStack,
	Center,
	Stack,
	VStack,
	StackDivider,
	Tabs,
	TabPanels,
	Tab,
	TabPanel,
	TabList,
	Container,
} from "@chakra-ui/react";

import { portalUser } from "../../../App";

import { FaUser, FaUsers, FaUserFriends, FaBan, FaGift, FaGlassCheers, FaTree } from "react-icons/fa";
import { SiMicrosoftoutlook } from "react-icons/si";
import { MdRadioButtonChecked, MdRadioButtonUnchecked, MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md";

import { IoOptionsOutline } from "react-icons/io5";

import CompanyHolidaysCard from "./CompanyHolidaysCard";
import { convertArrayToMap, formatValue } from "../../../helperFunctions";

const localizer = momentLocalizer(moment);

export default function TimeOffCalendar() {
	const [allEvents, setAllEvents] = useState([]);
	const [outlookEvents, setOutlookEvents] = useState([]);
	const [events, setEvents] = useState([]);
	const [selectedEvent, setSelectedEvent] = useState(null);
	const [tabVal, setTabVal] = useState(0);
	const [timeOffRequests, setTimeOffRequests] = useState("department");
	const [year, setYear] = useState(moment(new Date()).format("YYYY"));
	const [filters, setFilters] = useState(["outlook", "holiday", "company", "birthday"]);

	const setViewOption = useCallback(() => {
		let tempFilters = filters;
		let tempTimeOffRequests = timeOffRequests;
		let tempAllEvents = allEvents;
		let tempEvents = [];
		let filteredEvents = [];
		let tempOutlookEvents = outlookEvents;

		if (tempFilters.includes("outlook")) {
			tempEvents.push(...tempOutlookEvents);
		}
		for (let i = 0; i < tempFilters?.length; i++) {
			if (["holiday", "company"].includes(tempFilters[i])) {
				filteredEvents = tempAllEvents.filter(
					(d) => d.eventType?.toLowerCase() === tempFilters[i]?.toLowerCase()
				);
				tempEvents.push(...filteredEvents);
			}
		}
		let departments = portalUser.getDepartments();
		let deptMap = convertArrayToMap(departments, "deptID");

		filteredEvents = [];
		let timeOffEvents =
			tempAllEvents?.filter((d) => ["Time Off", "Pending Time Off", "Birthday"].includes(d.eventType)) ?? [];

		if (tempTimeOffRequests === "employee") {
			filteredEvents = timeOffEvents?.filter((d) => d.employeeUID === portalUser.user?.employeeUID);
		} else if (tempTimeOffRequests === "department") {
			let dept = deptMap[portalUser.user?.deptID] ?? null;
			// let parentDept = deptMap[dept?.parentDeptID] ?? null;
			filteredEvents = timeOffEvents?.filter((d) => d.parentDeptID === dept?.parentDeptID);
			// if (parentDept?.showRegionsYN === "Y") {
			// 	filteredEvents = timeOffEvents?.filter((d) => d.regionID === portalUser.user?.regionID);
			// }
		} else if (tempTimeOffRequests === "region") {
			filteredEvents = timeOffEvents?.filter((d) => d.regionID === portalUser.user?.regionID);
		}
		tempEvents.push(...filteredEvents);

		setEvents(tempEvents);
	}, [allEvents, filters, outlookEvents, timeOffRequests]);

	const loadOutlookEvents = useCallback(async (startDate, endDate) => {
		let outlookEventResult = await portalUser.msClient.GetMSCalendarEvents(
			startDate,
			endDate,
			portalUser.user?.msUserID
		);

		let tempYear = moment(new Date(startDate).setDate(new Date(startDate).getDate() + 7)).format("yyyy");

		let tempOutlookEvents = [];
		for (let i = 0; i < outlookEventResult.value?.length; i++) {
			let startDate = moment();
			let endDate = moment();

			let start = new Date(formatValue(outlookEventResult.value[i].start.dateTime, 0, "dateTime"));
			let end = new Date(formatValue(outlookEventResult.value[i].end.dateTime, 0, "dateTime"));
			if (outlookEventResult.value[i].isAllDay) {
				end = start;
			}
			start = moment(start);
			end = moment(end);

			startDate.set(start.toObject());
			endDate.set(end.toObject());

			tempOutlookEvents.push({
				start: startDate.toDate(),
				end: endDate.toDate(),
				title: outlookEventResult.value[i].subject,
				id: outlookEventResult.value[i].id,
				eventType: "Outlook",
				allday: outlookEventResult.value[i].isAllDay,
				description: outlookEventResult.value[i].body.content,
				employeeUID: portalUser.user.employeeUID,
			});
		}

		setYear(tempYear);
		setOutlookEvents(tempOutlookEvents);
	}, []);

	const init = useCallback(async () => {
		let tempAllEvents = allEvents;
		let startMth = new Date();
		let endMth = new Date();
		startMth = moment(startMth).startOf("month");
		endMth = moment(startMth).endOf("month");

		await loadOutlookEvents(startMth?.format("M/D/YYYY"), endMth?.format("M/D/YYYY"));
		let tempEvents = portalUser?.getCalendarEvents() ?? [];
		tempAllEvents.push(...tempEvents);
		setAllEvents(tempAllEvents);
	}, [allEvents, loadOutlookEvents]);

	useEffect(() => {
		init();
	}, []);

	const handleEventClick = (event, e) => {
		setSelectedEvent(event);
	};

	const closeEvent = () => {
		setSelectedEvent(null);
	};

	const onRangeChange = async (dates) => {
		let startDate = moment(dates?.start).format("M/D/YYYY");
		let endDate = moment(dates?.end).format("M/D/YYYY");

		await loadOutlookEvents(startDate, endDate);
	};

	const handleTabChange = (val) => {
		setTabVal(val);
	};

	const updateTimeOff = (requests) => {
		setTimeOffRequests(requests);
	};

	const updateFilter = (filterOption) => {
		setFilters((prevFilters) => {
			const updatedFilters = [...prevFilters];
			const foundIndex = updatedFilters.indexOf(filterOption);
			if (foundIndex === -1) {
				updatedFilters.push(filterOption);
			} else {
				updatedFilters.splice(foundIndex, 1);
			}
			return updatedFilters;
		});
	};

	const applyFilters = () => setTabVal(0);

	useEffect(() => {
		setViewOption();
	}, [allEvents, filters, outlookEvents, setViewOption, timeOffRequests]);

	return (
		<Tabs isFitted w="full" colorScheme="teal" color="gray.500" index={tabVal} onChange={handleTabChange}>
			<TabList mx="1">
				<Tab>
					<Heading size="md" textTransform="uppercase" fontWeight="semibold" w="full" textAlign="left">
						My Calendar
					</Heading>
				</Tab>

				<Tab textTransform="uppercase" fontWeight="semibold" maxW={{ base: "60px", md: "150px" }}>
					<Flex w="full" justify="flex-end">
						<Button
							as={Button}
							variant="outline"
							// onClick={setCollapseIsOpen.toggle}
							color="gray.500"
							size="md"
							bg="whiteAlpha.700"
							textTransform="uppercase"
							shadow="md"
							_hover={{
								color: "teal.500",
								fontWeight: "bold",
								bg: "whiteAlpha.700",
								borderColor: "teal.500",
								borderWidth: 2,
							}}
						>
							<Center px={2}>
								<IconButton variant="unstyled" as={IoOptionsOutline} w={6} h={6} />
								<Text display={{ base: "none", md: "flex" }} fontSize="md">
									FILTERS
								</Text>
							</Center>
						</Button>
					</Flex>
				</Tab>
			</TabList>

			<TabPanels>
				<TabPanel>
					<Box>
						{!selectedEvent && (
							<Box rounded="10px" shadow="md" maxW="full">
								<ReactCalendar
									localizer={localizer}
									defaultDate={new Date()}
									defaultView="month"
									events={events}
									views={["month", "work_week"]}
									onRangeChange={onRangeChange}
									style={{
										height: "60vh",
										minHeight: "550px",
										maxWidth: "100%",
										width: "100%",
										maxHight: "100%",
										backgroundColor: "white",
										borderRadius: "10px",
										border: "1px",
										padding: "10px",
									}}
									onSelectEvent={(event, ev) => {
										handleEventClick(event, ev);
									}}
									popup={true}
									eventPropGetter={(event) => {
										var backgroundColor = "";
										if (event?.eventType === "Birthday") {
											backgroundColor = "#805AD5";
										}
										if (event?.eventType === "Holiday") {
											backgroundColor = "#2F855A";
										}
										if (event?.eventType === "Company") {
											backgroundColor = "#DD6B20";
										}
										if (event?.eventType === "Pending Time Off") {
											backgroundColor =
												"repeating-linear-gradient(45deg, #D69E2E, #D69E2E 5px, #B7791F 5px, #B7791F 10px)";
										}
										if (event?.eventType === "Time Off") {
											backgroundColor = "var(--chakra-colors-teal-500)";
										}
										return { style: { background: backgroundColor, fontWeight: "600" } };
									}}
								/>
							</Box>
						)}
						{selectedEvent && (
							<Box>
								<Stack p={4} w="full">
									<Flex w="full" flex={1} justify="space-between">
										<Stack>
											<Heading as="h4" size="lg" color="gray.500">
												{selectedEvent?.title}
											</Heading>
											<Text fontSize="sm" color="gray.500">
												{formatValue(selectedEvent?.start, 0, "date")}
											</Text>
										</Stack>
										<Button variant="outline" onClick={closeEvent} bg="whiteAlpha.700">
											<HStack>
												<i className="fas fa-times" />
												<Text>Close</Text>
											</HStack>
										</Button>
									</Flex>
									<Box
										bg="whiteAlpha.800"
										p={8}
										className="skinnyScroll"
										overflow="auto"
										rounded="md"
										maxH="lg"
									>
										{Boolean(selectedEvent?.eventType === "Outlook") ? (
											<Text>{parse(selectedEvent?.description ?? "")}</Text>
										) : (
											<Text>{selectedEvent?.description}</Text>
										)}
									</Box>
								</Stack>
							</Box>
						)}
					</Box>
					<Box mt="4" overflow="auto">
						{year && <CompanyHolidaysCard year={year} />}
					</Box>
				</TabPanel>

				<TabPanel>
					<Container w="full" p="4" maxW="container.lg">
						<Stack w="full" spacing={4} divider={<StackDivider />}>
							<Stack spacing={1}>
								<Heading
									size="md"
									w="full"
									textAlign="left"
									letterSpacing={1}
									textTransform="uppercase"
									color="gray.500"
								>
									Calendar View Options
								</Heading>
								<Text as="i" color="gray.400">
									Which events would you like to show on your Calendar?{" "}
								</Text>
							</Stack>
							<SimpleGrid maxChildWidth="3xs" minChildWidth="10rem" spacing="4">
								<Flex
									justify="center"
									align="center"
									w="full"
									bg={filters.includes("outlook") ? "whiteAlpha.700" : ""}
									shadow="sm"
									flex={1}
									rounded="5"
									p={{ base: "1", md: "4" }}
									border={filters.includes("outlook") ? "2px" : "1px"}
									borderColor={filters.includes("outlook") ? "blue.600" : "blackAlpha.200"}
									onClick={() => {
										updateFilter("outlook");
									}}
									cursor="pointer"
									_hover={{ bg: "blackAlpha.50" }}
								>
									<VStack textAlign="center" w="full" spacing={1}>
										<Center color={filters.includes("outlook") ? "blue.600" : "gray.400"}>
											<Heading>
												<SiMicrosoftoutlook />
											</Heading>
										</Center>
										<Text
											isTruncated
											color={filters.includes("outlook") ? "blue.600" : "gray.400"}
											fontWeight="bold"
											fontSize="md"
											textTransform="uppercase"
										>
											Outlook Events
										</Text>
										<Text as="i" fontSize="xs">
											Show My Outlook Calendar Events on my Calendar
										</Text>
										<Center
											fontSize="4xl"
											color={filters.includes("outlook") ? "blue.600" : "gray.300"}
										>
											{filters.includes("outlook") ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
										</Center>
									</VStack>
								</Flex>

								<Flex
									justify="center"
									align="center"
									w="full"
									bg={filters.includes("holiday") ? "whiteAlpha.700" : ""}
									shadow="sm"
									flex={1}
									rounded="5"
									p={{ base: "1", md: "4" }}
									border={filters.includes("holiday") ? "2px" : "1px"}
									borderColor={filters.includes("holiday") ? "green.600" : "blackAlpha.200"}
									onClick={() => {
										updateFilter("holiday");
									}}
									cursor="pointer"
									_hover={{ bg: "blackAlpha.50" }}
								>
									<VStack textAlign="center" w="full" spacing={1}>
										<Center color={filters.includes("holiday") ? "green.600" : "gray.400"}>
											<Heading>
												<FaTree />
											</Heading>
										</Center>
										<Text
											isTruncated
											color={filters.includes("holiday") ? "green.600" : "gray.400"}
											fontWeight="bold"
											fontSize="md"
											textTransform="uppercase"
										>
											Company Holidays
										</Text>
										<Text as="i" fontSize="xs">
											Show Company Holidays on my Calendar
										</Text>
										<Center
											fontSize="4xl"
											color={filters.includes("holiday") ? "green.600" : "gray.300"}
										>
											{filters.includes("holiday") ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
										</Center>
									</VStack>
								</Flex>

								<Flex
									justify="center"
									align="center"
									w="full"
									bg={filters.includes("company") ? "whiteAlpha.700" : ""}
									shadow="sm"
									flex={1}
									rounded="5"
									p={{ base: "1", md: "4" }}
									border={filters.includes("company") ? "2px" : "1px"}
									borderColor={filters.includes("company") ? "orange.500" : "blackAlpha.200"}
									onClick={() => {
										updateFilter("company");
									}}
									cursor="pointer"
									_hover={{ bg: "blackAlpha.50" }}
								>
									<VStack textAlign="center" w="full" spacing={1}>
										<Center color={filters.includes("company") ? "orange.500" : "gray.400"}>
											<Heading>
												<FaGlassCheers />
											</Heading>
										</Center>
										<Text
											isTruncated
											color={filters.includes("company") ? "orange.500" : "gray.400"}
											fontWeight="bold"
											fontSize="md"
											textTransform="uppercase"
										>
											Company Events
										</Text>
										<Text as="i" fontSize="xs">
											Show Company Events on my Calendar
										</Text>
										<Center
											fontSize="4xl"
											color={filters.includes("company") ? "orange.500" : "gray.300"}
										>
											{filters.includes("company") ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
										</Center>
									</VStack>
								</Flex>

								<Flex
									justify="center"
									align="center"
									w="full"
									bg={filters.includes("birthday") ? "whiteAlpha.700" : ""}
									shadow="sm"
									flex={1}
									rounded="5"
									p={{ base: "1", md: "4" }}
									border={filters.includes("birthday") ? "2px" : "1px"}
									borderColor={filters.includes("birthday") ? "purple.500" : "blackAlpha.200"}
									onClick={() => {
										updateFilter("birthday");
									}}
									cursor="pointer"
									_hover={{ bg: "blackAlpha.50" }}
								>
									<VStack textAlign="center" w="full" spacing={1}>
										<Center color={filters.includes("birthday") ? "purple.500" : "gray.400"}>
											<Heading>
												<FaGift />
											</Heading>
										</Center>
										<Text
											isTruncated
											color={filters.includes("birthday") ? "purple.500" : "gray.400"}
											fontWeight="bold"
											fontSize="md"
											textTransform="uppercase"
										>
											Birthdays
										</Text>
										<Text as="i" fontSize="xs">
											Show Employee Birthdays on my Calendar
										</Text>
										<Center
											fontSize="4xl"
											color={filters.includes("birthday") ? "purple.500" : "gray.300"}
										>
											{filters.includes("birthday") ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
										</Center>
									</VStack>
								</Flex>
							</SimpleGrid>
						</Stack>

						<Stack w="full" spacing={4} divider={<StackDivider />} mt="8">
							<Stack spacing={1}>
								<Heading
									size="md"
									w="full"
									textAlign="left"
									letterSpacing={1}
									textTransform="uppercase"
									color="gray.500"
								>
									Time Off View Options
								</Heading>
								<Text as="i" color="gray.400">
									Who's time off requests would you like to show on your Calendar?{" "}
								</Text>
							</Stack>
							<SimpleGrid maxChildWidth="3xs" minChildWidth="10rem" spacing="4">
								<Flex
									justify="center"
									align="center"
									w="full"
									bg={timeOffRequests === "none" ? "whiteAlpha.700" : ""}
									shadow="sm"
									flex={1}
									rounded="5"
									p={{ base: "1", md: "4" }}
									border={timeOffRequests === "none" ? "2px" : "1px"}
									borderColor={timeOffRequests === "none" ? "teal.600" : "blackAlpha.200"}
									onClick={() => {
										updateTimeOff("none");
									}}
									cursor="pointer"
									_hover={{ bg: "blackAlpha.50" }}
								>
									<VStack textAlign="center" w="full" spacing={1}>
										<Center color={timeOffRequests === "none" ? "teal.600" : "gray.400"}>
											<Heading>
												<FaBan />
											</Heading>
										</Center>
										<Text
											isTruncated
											color={timeOffRequests === "none" ? "teal.600" : "gray.400"}
											fontWeight="bold"
											fontSize="md"
											textTransform="uppercase"
										>
											No Time Off
										</Text>
										<Text as="i" fontSize="xs">
											Hide All Time Off Requests
										</Text>
										<Center
											fontSize="4xl"
											color={timeOffRequests === "none" ? "teal.600" : "gray.300"}
										>
											{timeOffRequests === "none" ? (
												<MdRadioButtonChecked />
											) : (
												<MdRadioButtonUnchecked />
											)}
										</Center>
									</VStack>
								</Flex>

								<Flex
									justify="center"
									align="center"
									w="full"
									bg={timeOffRequests === "employee" ? "whiteAlpha.700" : ""}
									shadow="sm"
									flex={1}
									rounded="5"
									p={{ base: "1", md: "4" }}
									border={timeOffRequests === "employee" ? "2px" : "1px"}
									borderColor={timeOffRequests === "employee" ? "teal.600" : "blackAlpha.200"}
									onClick={() => {
										updateTimeOff("employee");
									}}
									cursor="pointer"
									_hover={{ bg: "blackAlpha.50" }}
								>
									<VStack textAlign="center" w="full" spacing={1}>
										<Center color={timeOffRequests === "employee" ? "teal.600" : "gray.400"}>
											<Heading>
												<FaUser />
											</Heading>
										</Center>
										<Text
											isTruncated
											color={timeOffRequests === "employee" ? "teal.600" : "gray.400"}
											fontWeight="bold"
											fontSize="md"
											textTransform="uppercase"
										>
											My Time Off
										</Text>
										<Text as="i" fontSize="xs">
											Show My Time Off Requests Only
										</Text>
										<Center
											fontSize="4xl"
											color={timeOffRequests === "employee" ? "teal.600" : "gray.300"}
										>
											{timeOffRequests === "employee" ? (
												<MdRadioButtonChecked />
											) : (
												<MdRadioButtonUnchecked />
											)}
										</Center>
									</VStack>
								</Flex>

								<Flex
									justify="center"
									align="center"
									w="full"
									bg={timeOffRequests === "department" ? "whiteAlpha.700" : ""}
									shadow="sm"
									flex={1}
									rounded="5"
									p={{ base: "1", md: "4" }}
									border={timeOffRequests === "department" ? "2px" : "1px"}
									borderColor={timeOffRequests === "department" ? "teal.600" : "blackAlpha.200"}
									onClick={() => {
										updateTimeOff("department");
									}}
									cursor="pointer"
									_hover={{ bg: "blackAlpha.50" }}
								>
									<VStack textAlign="center" w="full" spacing={1}>
										<Center color={timeOffRequests === "department" ? "teal.600" : "gray.400"}>
											<Heading>
												<FaUserFriends />
											</Heading>
										</Center>
										<Text
											color={timeOffRequests === "department" ? "teal.600" : "gray.400"}
											fontWeight="bold"
											fontSize="md"
											textTransform="uppercase"
										>
											My Department
										</Text>
										<Text as="i" fontSize="xs">
											Show Approved Time Off Requests for My Department
										</Text>
										<Center
											fontSize="4xl"
											color={timeOffRequests === "department" ? "teal.600" : "gray.300"}
										>
											{timeOffRequests === "department" ? (
												<MdRadioButtonChecked />
											) : (
												<MdRadioButtonUnchecked />
											)}
										</Center>
									</VStack>
								</Flex>

								<Flex
									justify="center"
									align="center"
									w="full"
									bg={timeOffRequests === "region" ? "whiteAlpha.700" : ""}
									shadow="sm"
									flex={1}
									rounded="5"
									p={{ base: "1", md: "4" }}
									border={timeOffRequests === "region" ? "2px" : "1px"}
									borderColor={timeOffRequests === "region" ? "teal.600" : "blackAlpha.200"}
									onClick={() => {
										updateTimeOff("region");
									}}
									cursor="pointer"
									_hover={{ bg: "blackAlpha.50" }}
								>
									<VStack textAlign="center" w="full" spacing={1}>
										<Center color={timeOffRequests === "region" ? "teal.600" : "gray.400"}>
											<Heading>
												<FaUsers />
											</Heading>
										</Center>
										<Text
											isTruncated
											color={timeOffRequests === "region" ? "teal.600" : "gray.400"}
											fontWeight="bold"
											fontSize="md"
											textTransform="uppercase"
										>
											Region Time Off{" "}
										</Text>
										<Text as="i" fontSize="xs">
											Show Time Off Requests for my Region
										</Text>
										<Center
											fontSize="4xl"
											color={timeOffRequests === "region" ? "teal.600" : "gray.300"}
										>
											{timeOffRequests === "region" ? (
												<MdRadioButtonChecked />
											) : (
												<MdRadioButtonUnchecked />
											)}
										</Center>
									</VStack>
								</Flex>
							</SimpleGrid>
						</Stack>

						<Flex w="full" justify="center" mt="8">
							<Button
								variant="outline"
								onClick={applyFilters}
								color="gray.500"
								ml={0}
								pl="0"
								size="sm"
								bg="whiteAlpha.700"
								shadow="sm"
							>
								<Center>
									<IconButton variant="unstyled" as={IoOptionsOutline} w={6} h={6} />
									<Text ml="1" fontSize="md">
										APPLY FILTERS
									</Text>
								</Center>
							</Button>
						</Flex>
					</Container>
				</TabPanel>
			</TabPanels>
		</Tabs>
	);
}
