import React, { useState, useCallback } from "react";
import {
	Box,
	Heading,
	Flex,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Button,
	HStack,
	Spinner,
} from "@chakra-ui/react";
import VirtualListV2 from "../../../core/ReactTable/components/VirtualListV2";
import { portalUser } from "../../../App";

export default function ImpersonateModal({ isOpen, onClose }) {
	const [searchVal, setSearchVal] = useState("");
	const portalUsers = portalUser.getUsers();
	const regions = portalUser.getRegions();
	const departments = portalUser.getDepartments();
	const [user, setUser] = useState(portalUser.user);
	const [isLoading, setIsLoading] = useState(false);

	const impersonateUser = useCallback(
		async (user = null) => {
			setIsLoading(true);
			await portalUser.impersonateUser(Boolean(user?.msUserID) ? user.msUserID : null);
			setUser(portalUser.user);
			setIsLoading(false);
			onClose();
		},
		[onClose]
	);

	if (isOpen) {
		var userListItems = [];

		for (var i = 0; i < portalUsers?.length; i++) {
			let filterStr = portalUsers[i].firstName + " " + portalUsers[i].lastName + " " + portalUsers[i].title;

			if (filterStr?.toLowerCase().includes(searchVal?.toLowerCase())) {
				userListItems.push(
					<UserListItemRow
						user={portalUsers[i]}
						regions={regions}
						departments={departments}
						impersonateUser={impersonateUser}
						listItemHeight="65"
					/>
				);
			}
		}

		return (
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						<Text letterSpacing={1} textTransform="uppercase">
							Impersonate User
						</Text>
						<HStack>
							<Text>Current User:</Text>
							<Text>{user.getName()}</Text>
						</HStack>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Box>
							<Box>
								<VirtualListV2
									autoFocusOnSearchBar={true}
									listHeight={400}
									rowHeight={65}
									rows={userListItems}
									searchBar
									loadingData={Boolean(portalUsers?.length === 0)}
									handleSearchBar={(val) => setSearchVal(val)}
									variant="flushed"
								/>
							</Box>
							<Box>
								<Button variant="outline" onClick={() => impersonateUser()}>
									Return To Self
								</Button>
							</Box>
						</Box>
					</ModalBody>
					<ModalFooter>
						<Button disabled={isLoading} colorScheme="red" variant="solid" onClick={onClose}>
							{isLoading ? <Spinner /> : "Close"}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	} else {
		return null;
	}
}

function UserListItemRow({ user, regions, departments, impersonateUser, listItemHeight }) {
	const region =
		regions?.filter((d) => d.regionID === user.regionID)[0] ?? regions?.filter((d) => d.regionID === "0")[0];

	const department =
		departments?.filter((d) => d.deptID === user.deptID)[0] ?? departments?.filter((d) => d.deptID === "0")[0];

	return (
		<Flex
			flex={1}
			align="center"
			w="full"
			bg={Boolean(user.msUserID) ? "" : "red.200"}
			h={listItemHeight + "px"}
			style={{ cursor: "pointer" }}
			onClick={() => impersonateUser(user)}
		>
			<Heading as="h4" size="sm" w="40%">
				{user.getName()}
			</Heading>
			<Box w="60%">
				<Text>{user.title}</Text>
				<Text>
					{region.region} {department.dept}
				</Text>
			</Box>
		</Flex>
	);
}
