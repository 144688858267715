import Vendor from "./Vendor";
import moment from "moment";

export default class DiversityVendor extends Vendor {
	constructor(diversityVendor) {
		super(diversityVendor);

		this.queryData = diversityVendor?.queryData ?? "DiversityVendor";
		this.jccmKeyID = diversityVendor?.jccmKeyID ?? null;
		this.jcco = diversityVendor?.jcco ?? null;
		this.contract = diversityVendor?.contract ?? null;
		this.divReqKeyID = diversityVendor?.divReqKeyID ?? null;
		this.divReqPct = diversityVendor?.divReqPct ?? null;
		this.apvmKeyID = diversityVendor?.apvmKeyID ?? null;
		this.vendorGroup = diversityVendor?.vendorGroup ?? null;
		this.vendorID = diversityVendor?.vendorID ?? null;
		this.vendorName = diversityVendor?.vendorName ?? null;

		// Available Vendors
		this.divCertType = diversityVendor?.divCertType ?? null;
		this.divCertSubType = diversityVendor?.divCertSubType ?? null;
		this.rawExpirationDate = diversityVendor?.dateExpires ?? null;
		this.expirationDate = this.formatDate(this.rawExpirationDate) ?? "N/A";
		this.status = diversityVendor?.status ?? this.getStatus();
		this.diversityContact = diversityVendor?.diversityContact ?? null;
		this.diversityEmail = diversityVendor?.diversityEmail ?? null;
		this.diversityPhone = diversityVendor?.diversityPhone ?? null;

		// Spent To Date
		this.diversityURL = diversityVendor?.diversityURL ?? null;
		this.rawActualCost = diversityVendor?.actualCost ?? null;
		this.actualCost = diversityVendor?.actualCost ?? null;
		this.committedCost = diversityVendor?.committedCost ?? null;
		this.totalPOCost = this.actualCost + this.committedCost ?? null;
	}

	formatDate(date) {
		if (date) {
			return moment(date).format("MM/DD/YYYY");
		}
		return null;
	}

	getStatus() {
		let status = "Active";
		if (this.expirationDate) {
			if (moment().isAfter(this.expirationDate)) {
				status = "Expired";
			}
		}
		return status;
	}
}
