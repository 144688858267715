import Company from "../../../PortalUser/Company";
import Region from "../../../PortalUser/Region";
import Department from "../../../PortalUser/Department";
import Role from "../../../PortalUser/Role";
import Trade from "../../../PortalUser/Trade";
import PREmployee from "../../Employee/classes/PREmployee";
import User from "../../Employee/classes/User";
import AppAPI from "../../../APIs/InitAPI";
// import Leader from "../../Employee/classes/Leader";

export default class CompanyData {
	constructor(data) {
		if (data instanceof CompanyData) {
			Object.assign(this, data);
		} else {
			//APP
			this.companyAPI = data?.companyAPI ?? null;
			this.companies = data?.companies ?? [];
			this.regions = data?.regions ?? [];
			this.trades = data?.trades ?? [];
			this.departments = data?.departments ?? [];
			this.roles = data?.roles ?? [];
			this.leaders = data?.leaders ?? [];
			this.users = data?.users ?? [];
			this.employees = data?.employees ?? [];
			this.logs = data?.logs ?? [];
		}
	}

	classifyData(app = this, sqlData = []) {
		for (let i = 0; i < sqlData?.length; i++) {
			let queryData = sqlData[i]?.queryData;

			if (queryData === "Company") {
				app.companies.push(new Company(sqlData[i]));
			} else if (queryData === "Region") {
				app.regions.push(new Region(sqlData[i]));
			} else if (queryData === "Trade") {
				app.trades.push(new Trade(sqlData[i]));
			} else if (queryData === "Department") {
				app.departments.push(new Department(sqlData[i]));
			} else if (queryData === "Role") {
				app.roles.push(new Role(sqlData[i]));
			} else if (queryData === "User") {
				app.users.push(new User(sqlData[i]));
			} else if (queryData === "Employee") {
				app.employees.push(new PREmployee(sqlData[i]));
			} else if (queryData === "EventLog") {
				app.logs.push(sqlData[i]);
			}
		}

		return app;
	}

	async initAppData(params = null) {
		let app = this;

		if (Boolean(params?.msUserID) && !Boolean(app?.appAPI)) {
			let appAPI = new AppAPI();
			let sqlData = await appAPI.InitApp(params);
			if (sqlData?.status === 200) {
				sqlData = sqlData?.value ?? [];
				app = this.classifyData(app, sqlData);
				app.appAPI = appAPI;
			}
		}
		return app;
	}
}
