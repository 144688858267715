export default class TimecardJobPhaseClassLegacy {
	constructor(phase) {
		this.codeOrder = phase?.CodeOrder ?? "";
		this.codeVersion = phase?.CodeVersion ?? 0;
		this.description = phase?.Description ?? "";
		this.levelAreaDesc = phase?.LevelAreaDesc ?? "";
		this.levelAreaId = phase?.LevelAreaID ?? "";
		this.phase = phase?.Phase ?? "";
		this.phaseGroup = phase?.PhaseGroup ?? 0;
		this.key = phase?.key ?? "";
		this.value = phase?.value ?? "";
	}
}
