import { portalUser } from "../../../App";

export default class PageTemplateDFJobs {
	constructor(dfJobs = [], params = {}, page = {}) {
		this.pageTemplate = "D&FJobs";
		this.pageName = page?.pageName ?? "Detailing & Fab Schedule (D&F)";
		this.pageTitle = page?.pageTitle ?? "D&F Jobs";
		this.size = page?.size ?? "TABLOID";
		this.orientation = page?.orientation ?? "landscape";
		this.pageBody = this.getSections(dfJobs, params);
	}

	getSections(dfJobs = [], params = {}) {
		let pageBody = [new SectionTemplateDFJobs(dfJobs, params, this)];
		return pageBody;
	}

	getRegion(dfJobs = [], params = {}) {
		let regionID = portalUser.user?.regionID ?? "0";
		if (Boolean(params?.regionID)) {
			regionID = params?.regionID;
		} else if (Boolean(dfJobs?.length > 0)) {
			regionID = dfJobs[0]?.regionID ?? "0";
		}
		return portalUser?.getRegions()?.find((p) => p.regionID === regionID)[0];
	}
}

export class SectionTemplateDFJobs {
	constructor(dfJobs = [], params = {}, page = {}) {
		this.template = "D&FJobs";
		this.isCollapsable = true;
		this.name = page?.pageName ?? "Detailing & Fab Schedule (D&F)";
		this.heading = page?.pageTitle ?? "D&F Jobs";

		this.type = "virtualTable";
		this.rowHeight = 40;
		this.maxRows = 20;
		this.clickable = true;
		this.wrap = true;

		this.subSections = this.getSubSections(dfJobs, params);
	}
	getSubSections(dfJobs = [], params = {}) {
		return [new SubSectionTemplateDFJob(dfJobs, params, this)];
	}
}

class SubSectionTemplateDFJob {
	constructor(dfJobs = [], params = {}, section = {}) {
		this.template = "D&FJobs";
		this.isCollapsable = true;

		this.name = section?.name ?? "Detailing & Fab Schedule (D&F)";
		this.heading = section?.heading ?? "D&F Projects";

		// this.loopBy = sortGroupFilter.loopBy;
		// this.groupBy = sortGroupFilter.groupBy;
		// this.filterBy = sortGroupFilter.filterBy;
		this.sortBy = [{ id: "contract" }, { id: "isArchived" }];
		this.filterBy = [
			{ id: "accessYN", value: "Y" },
			{ id: "isArchived", value: "0" },
		];

		this.footer = this.getFooter(dfJobs);
		this.wrap = true;

		this.columns = [
			{
				accessor: "region",
				Header: "Region",
				filter: "multiselect",
				width: "85%",
			},
			{
				accessor: "scopeDesc",
				Header: "Scope Description",
				show: false,
			},
			{
				accessor: "accessYN",
				Header: "Job Access",
				filter: "select",
				textAlign: "center",
				width: "75%",
				formInput: "jobAccessButton",
				show: false,
			},

			{
				accessor: "jcco",
				Header: "Co",
				width: "50%",
				show: false,
			},
			{
				accessor: "contract",
				Header: "Contract",
				filter: "multiselect",
				show: false,
			},
			{
				accessor: "contractName",
				Header: "Contract Name",
				width: "375%",
				filter: "multiselect",
				show: false,
			},
			{
				accessor: "contractStatus",
				Header: "Contract Status",
				filter: "multiselect",
				show: false,
			},
			{
				accessor: "jobID",
				Header: "Job ID",
				show: false,
			},
			{
				accessor: "job",
				Header: "Job",
				filter: "multiselect",
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "jobName",
				Header: "Job Name",
				width: "375%",
			},
			{
				accessor: "templateName",
				Header: "Template",
				show: false,
			},

			{
				accessor: "projectManager",
				Header: "Project Manager",
				width: "150%",
				placeholder: "Project Manager",
				show: !Boolean(params?.scopeID === "13"),
			},
			{
				accessor: "receiving",
				Header: "Receiving",
				width: "150%",
				placeholder: "Receiving",
				show: !Boolean(params?.scopeID === "13"),
			},
			{
				accessor: "qcReviewer",
				Header: "QC Reviewer",
				width: "150%",
				placeholder: "QC Reviewer",
				show: !Boolean(params?.scopeID === "13"),
			},
			{
				accessor: "foreman",
				Header: "Foreman",
				width: "150%",
				placeholder: "Foreman",
				show: !Boolean(params?.scopeID === "13"),
			},
			{
				accessor: "detailer",
				Header: "Detailer",
				width: "150%",
				placeholder: "Detailer",
				show: !Boolean(params?.scopeID === "13"),
			},
			{
				accessor: "supportDetailer",
				Header: "Suppport Detailer",
				width: "150%",
				placeholder: "Support Detailer",
				show: !Boolean(params?.scopeID === "13"),
			},
			{
				accessor: "purchasing",
				Header: "Purchasing",
				width: "150%",
				placeholder: "N/A",
				show: !Boolean(params?.scopeID === "13"),
			},
			{
				accessor: "fabrication",
				Header: "Fabrication",
				width: "150%",
				placeholder: "Fabrication",
				show: !Boolean(params?.scopeID === "13"),
			},
			{
				accessor: "plumber",
				Header: "Plumber",
				width: "150%",
				placeholder: "Plumber",
				show: Boolean(params?.scopeID === "13"),
			},
			{
				accessor: "fitter",
				Header: "Fitter",
				width: "150%",
				placeholder: "Fitter",
				show: Boolean(params?.scopeID === "13"),
			},
			{
				accessor: "sheetMetal",
				Header: "Sheet Metal",
				width: "150%",
				placeholder: "Sheet Metal",
				show: Boolean(params?.scopeID === "13"),
			},
			{
				accessor: "startUp",
				Header: "Start Up",
				width: "150%",
				placeholder: "Start Up",
				show: Boolean(params?.scopeID === "13"),
			},
			{
				accessor: "tab",
				Header: "TAB",
				width: "150%",
				placeholder: "TAB",
				show: Boolean(params?.scopeID === "13"),
			},
			{
				accessor: "installOffset",
				Header: "Install Offset",
				// editable: true,
				textAlign: "center",
				valueType: "integer",
				width: "75%",
				// formInput: "number",
			},

			{
				accessor: "isArchived",
				Header: "Archive Job",
				textAlign: "center",
				filter: "select",
				width: "75%",
				formInput: "archiveButton",
				show: Boolean(
					portalUser?.user?.isDetailingExecUser === "Y" ||
						portalUser?.user?.isStartUpUserYN === "Y" ||
						portalUser?.user?.isPortalAdminYN === "Y" ||
						portalUser?.user?.isExecUserYN === "Y"
				),
			},
		];

		this.data = dfJobs;

		this.footer = (rows = dfJobs) => {
			return this.getFooter(rows);
		};
	}

	getSortGroupFilter(rows = []) {
		// let loopBy = [{ id: "isArchivedYN", heading: "isArchivedYN" }];
		let loopBy = [];
		let groupBy = [];
		let sortBy = [];
		let filterBy = [{ id: "isArchived", value: "0" }];
		// let filterBy = [{ id: "isArchivedYN", value: "N" }];

		// let sortBy = [{ id: "pco", desc: false }];
		// let filterBy = [{ id: "pcoStatusDesc", value: ["PENDING", "ROM", "RMA", "CREDIT", "ALLOWANCE"] }];

		let sortGroupFilter = { loopBy, sortBy, groupBy, filterBy };
		return sortGroupFilter;
	}

	getFooter(rows = []) {
		let footerRow = { contract: rows?.length ?? 0, contractRightAddon: "Rows", contractTextSize: "sm" };

		return [footerRow];
	}
}
