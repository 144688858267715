import React, { useMemo } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { formatNumber, chakraThemeColorsMap } from "../../../helperFunctions";
import SectionPDF from "./SectionPDF";
import moment from "moment";

export default function LabelValuePairsPDF(props) {
	const row = useMemo(() => props.row ?? [], [props.row]);
	const data = useMemo(() => props.data ?? [], [props.data]);
	const columns = useMemo(() => props.columns ?? [], [props.columns]);
	const footer = useMemo(() => props.footer ?? [], [props.footer]);

	let rowStyles = {
		display: row?.display ?? "flex",
		flexDirection: row?.flexDirection ?? "row",
		flexWrap: row?.flexDirection ?? "nowrap",
		width: row?.width,
		maxWidth: row?.maxWidth,
		minHeight: row?.minHeight ?? 16,
		borderBottomWidth: 0,
		borderBottomColor: row?.bg ?? "transparent",
		alignItems: row?.alignItems ?? "center",
		justifyConent: row?.alignItems ?? "center",
	};

	return (
		<>
			{data?.map((data, i) => (
				<View style={StyleSheet.create(rowStyles)} key={i}>
					{columns?.map((column, j) => {
						const labelValueStyles = StyleSheet.create(getLabelValueStyles(data, column));
						let accessor = column.id;
						if (typeof accessor === "function") {
							accessor = accessor.name;
						}
						let valueType = data[accessor + "Type"] ?? column?.valueType ?? "string";
						let decimals = data[accessor + "Decimals"] ?? column?.decimals ?? 0;
						let color = data[accessor + "TextColor"] ?? column?.textColor ?? chakraThemeColorsMap.black;

						return (
							<View style={labelValueStyles[accessor]} key={j}>
								<Text style={[labelValueStyles[accessor + "Text"], { color: color }]}>
									{valueType === "currency"
										? formatNumber(data[accessor] ?? 0, decimals, "currency")
										: valueType === "percent"
										? formatNumber(data[accessor] ?? 0, decimals, "percent")
										: valueType === "number"
										? formatNumber(data[accessor] ?? 0, decimals)
										: valueType === "month" && data[accessor] !== null
										? moment(data[accessor]).format("MMM YYYY")
										: valueType === "date" && data[accessor] !== null
										? moment(data[accessor]).format("M/D/YYYY")
										: data[accessor] ?? null}
									{Boolean(data[accessor + "RightAddon"]) && " " + data[accessor + "RightAddon"]}
								</Text>
							</View>
						);
					})}
				</View>
			))}
			{/* LABEL VALUE FOOTER */}
			<View wrap={false}>
				{Array.isArray(footer) &&
					footer?.map((data, i) => {
						return (
							<View style={StyleSheet.create(rowStyles)} key={i}>
								{columns?.map((column, j) => {
									const labelValueStyles = StyleSheet.create(getLabelValueStyles(data, column));
									let accessor = column.id;
									if (typeof accessor === "function") {
										accessor = accessor.name;
									}
									let valueType = data[accessor + "Type"] ?? column?.valueType ?? "string";
									let decimals = data[accessor + "Decimals"] ?? column?.decimals ?? 0;
									let color =
										data[accessor + "TextColor"] ?? column?.textColor ?? chakraThemeColorsMap.black;

									return (
										<View style={labelValueStyles[accessor]} key={j}>
											<Text style={[labelValueStyles[accessor + "Text"], { color: color }]}>
												{valueType === "currency"
													? formatNumber(data[accessor] ?? 0, decimals, "currency")
													: valueType === "percent"
													? formatNumber(data[accessor] ?? 0, decimals, "percent")
													: valueType === "number"
													? formatNumber(data[accessor] ?? 0, decimals)
													: valueType === "month" && data[accessor] !== null
													? moment(data[accessor]).format("MMM YYYY")
													: valueType === "date" && data[accessor] !== null
													? moment(data[accessor]).format("M/D/YYYY")
													: data[accessor] ?? null}
												{Boolean(data[accessor + "RightAddon"]) &&
													" " + data[accessor + "RightAddon"]}
											</Text>
										</View>
									);
								})}
							</View>
						);
					})}

				{!Array.isArray(footer) && (
					<View style={StyleSheet.create(rowStyles)} wrap={false}>
						<SectionPDF section={footer} />
					</View>
				)}
			</View>
		</>
	);
}

function getLabelValueStyles(data, column) {
	let styles = {};
	let accessor = column.id;
	if (typeof accessor === "function") {
		accessor = accessor.name;
	}

	styles[accessor] = {
		paddingHorizontal: (data[accessor + "Px"] ?? column?.px ?? 2) + 2,
		paddingVertical: (data[accessor + "Py"] ?? column?.py ?? 0) * 2,

		height: data[accessor + "Height"] ?? column?.height ?? 16,
		justifyContent: data[accessor + "JustifyContent"] ?? column?.justifyContent ?? "center",

		backgroundColor: data[accessor + "Bg"] ?? column?.bg ?? "transparent",

		borderRadius: data[accessor + "BorderRadius"] ?? column?.borderRadius ?? 0,

		borderTopColor: data[accessor + "BorderTopColor"] ?? column?.borderTopColor ?? chakraThemeColorsMap.gray600,
		borderLeftColor: data[accessor + "BorderLeftColor"] ?? column?.borderLeftColor ?? chakraThemeColorsMap.gray600,
		borderRightColor:
			data[accessor + "BorderRightColor"] ?? column?.borderRightColor ?? chakraThemeColorsMap.gray600,
		borderBottomColor:
			data[accessor + "BorderBottomColor"] ?? column?.borderBottomColor ?? chakraThemeColorsMap.gray600,

		borderTopWidth: data[accessor + "BorderTopWidth"] ?? column?.borderTopWidth ?? 0,
		borderLeftWidth: data[accessor + "BorderLeftWidth"] ?? column?.borderLeftWidth ?? 0,
		borderRightWidth: data[accessor + "BorderRightWidth"] ?? column?.borderRightWidth ?? 0,
		borderBottomWidth:
			data[accessor + "BorderBottomWidth"] ?? column?.borderBottomWidth ?? accessor === "value" ? 1 : 0,
		width: data[accessor + "Width"] ?? column?.width ?? "100%",
		maxWidth: data[accessor + "MaxWidth"] ?? column?.maxWidth,
	};
	styles[accessor + "Text"] = {
		lineHeight: data[accessor + "LineHeight"] ?? column?.lineHeight ?? 1,
		textAlign: data[accessor + "TextAlign"] ?? column?.textAlign ?? "left",
		fontSize: data[accessor + "FontSize"] ?? column?.fontSize ?? 9,
		textTransform: data[accessor + "TextTransform"] ?? column?.textTransform ?? "none",
	};

	return styles;
}
