const PCO_STATUSES = [
	{
		label: "PENDING",
		value: "PEND",
		color: "green",
		description: "Pending Change Order",
		icon: "fas fa-dollar-sign",
	},
	{
		label: "ROM",
		value: "ROM",
		color: "yellow",
		description: "Rough Order of Magnitude",
		icon: "fas fa-search-dollar",
	},
	{
		label: "RMA",
		value: "RMA",
		color: "blue",
		description: "Requested Monetary Adjustment",
		icon: "fas fa-comment-dollar",
	},
	{
		label: "ALLOWANCE",
		value: "ALLOW",
		color: "teal",
		description: "Allowance",
		icon: "fas fa-comments-dollar",
	},
	{
		label: "CREDIT",
		value: "CREDIT",
		color: "red",
		description: "Credit / Backcharge",
		icon: "fas fa-hand-holding-usd",
	},
	{
		label: "BUDGET",
		value: "BUDGET",
		color: "purple",
		description: "Budget Required (PCO Approved)",
		icon: "fas fa-funnel-dollar",
	},
	{
		label: "APPROVED",
		value: "APPD",
		color: "green",
		description: "Approved",
		icon: "fas fa-funnel-dollar",
	},
	{
		label: "NON-CONTRACT",
		value: "APPD",
		color: "green",
		description: "Non-Contract Changes",
		icon: "fas fa-funnel-dollar",
	},
	{
		label: "CLOSED",
		value: "CLSD",
		color: "red",
		description: "Closed / Cancelled PCO",
		icon: "fas fa-ban",
	},
	{
		label: "TOTAL",
		value: "TOTAL",
		color: "gray",
		description: "Total Pending Changes",
		icon: "far fa-dollar-sign",
	},
];
export default PCO_STATUSES;
