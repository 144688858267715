import Page from "./Page";
// import { PageTemplateActiveProjects } from "../../../modules/Employee/templates/SectionTemplateActiveProjects";
// import PageTemplateMonthlyForecast from "../../../modules/MFR/templates/PageTemplateMonthlyForecast";
// import PageTemplateManpowerProjections from "../../../modules/MFR/templates/PageTemplateManpowerProjections";
// import PageTemplateCostAndBillingProjections from "../../../modules/MFR/templates/PageTemplateCostAndBillingProjections";
// import PageTemplateChangeOrderLog from "../../../modules/Contracts/templates/PageTemplateChangeOrderLog";
// import PageTemplateJobCostDrilldown from "../../../modules/MFR/templates/PageTemplateJobCostDrilldown";

import { v4 } from "uuid";
import moment from "moment";

export default class Report {
	constructor(report = {}) {
		this.reportUID = v4();
		this.template = report?.reportTemplate ?? report?.template ?? "ReportTemplate";
		this.reportName = report?.reportName ?? "Report Template";
		this.fileName = report?.fileName ?? report?.reportName ?? "PDF Report";

		this.parameters = report?.parameters ?? {
			jccmKeyID: [],
			thruMth: moment(new Date()).startOf("month"),
			loopBy: [],
			groupBy: [],
			sortBy: [],
			filterBy: [],
		};
		// this.data = report?.data ?? [];
		this.pages = this.newPages(report?.pages ?? []);
	}

	newPages(pages = []) {
		let newPages = [];
		if (Boolean(pages) && pages?.length > 0) {
			for (let i = 0; i < pages?.length; i++) {
				let page = new Page(pages[i]);
				newPages.push(page);
			}
		} else {
			let page = new Page();
			newPages.push(page);
		}
		return newPages;
	}

	// getReport(reportName = this.reportName, pageTemplates = [], data = [], parameters = this.parameters) {
	// 	let pages = [];
	// 	for (let i = 0; i < pageTemplates?.length; i++) {
	// 		if (["ActiveProjects", "ClosedProjects", "ProjectSelect"].includes(pageTemplates[i])) {
	// 			pages.push(new PageTemplateActiveProjects(data, parameters));
	// 		}

	// 		if (["MonthlyForecast"].includes(pageTemplates[i])) {
	// 			pages.push(new PageTemplateMonthlyForecast(data, parameters));
	// 		}
	// 		if (["ManpowerProjections"].includes(pageTemplates[i])) {
	// 			pages.push(new PageTemplateManpowerProjections(data, parameters));
	// 		}
	// 		if (["CostAndBillingProjections"].includes(pageTemplates[i])) {
	// 			pages.push(new PageTemplateCostAndBillingProjections(data, parameters));
	// 		}
	// 		if (["ChangeOrderLog"].includes(pageTemplates[i])) {
	// 			pages.push(new PageTemplateChangeOrderLog(data, parameters));
	// 		}
	// 		if (["PageTemplateJobCostDrilldown"].includes(pageTemplates[i])) {
	// 			pages.push(new PageTemplateJobCostDrilldown(data, parameters));
	// 		}
	// 		// if (["SubmittalLog"].includes(pageTemplates[i])) {
	// 		// 	pages.push(new PageTemplateSubmittalLog(data, parameters));
	// 		// }
	// 		// if (["RFILog"].includes(pageTemplates[i])) {
	// 		// 	pages.push(new PageTemplateRFILog(data));
	// 		// }
	// 		// if (["RFILogSQL"].includes(pageTemplates[i])) {
	// 		// 	pages.push(new PageTemplateRFILogSQL(data));
	// 		// }
	// 		// if (["StartUpProjects"].includes(pageTemplates[i])) {
	// 		// 	pages.push(new PageTemplateStartUpProject(data, parameters));
	// 		// }
	// 	}
	// 	this.reportName = reportName;
	// 	this.pages = this.newPages(pages);
	// 	return this;
	// }
}
