import React from "react";
import ToggleButton from "./ToggleButton";

export default function JobAccessButton({ jobAccess = "N", onClick = () => null }) {
	return (
		<ToggleButton
			toggleIsOpen={jobAccess}
			onClick={onClick}
			toggleOptions={[
				{
					isOpen: true,
					label: "Job Available",
					icon: "fas fa-lock-open",
					color: "gray.400",
					bg: "whiteAlpha.900",
				},
				{
					isOpen: false,
					label: "No Job Access",
					icon: "fas fa-lock",
					color: "gray.400",
					bg: "whiteAlpha.700",
				},
			]}
		/>
	);
}
