import React, { useCallback, useMemo, useEffect } from "react";
import { Flex, TableContainer } from "@chakra-ui/react";
import useScrollbarWidth from "../../ReactTable/functions/useScrollbarWidth";
import VirtualTableHeader from "./VirtualTableV4Header";
import VirtualTableRenderRow from "./VirtualTableV4Row";
import VirtualTableFooter from "./VirtualTableV4Footer";
import { VariableSizeList } from "react-window";
import { inputData } from "../components/Section";

export default function VirtualTableV4({
	allColumns,
	rows,
	prepareRow,
	getTableProps,
	headerGroups,
	filterIsOpen,
	getTableBodyProps,
	footerGroups,
	maxRows,
	minRows,
	rowHeight,
	maxWidth,
	updateMyData,
	footer,
	selectedRow,
	setSelectedRow,
	toggleAllRowsExpanded,
	expanded,
	variableSizeRef,
	rowNum = 8,
	sliceType = "first",
}) {
	const scrollBarWidth = useScrollbarWidth();

	const rowCount = useMemo(() => rows.length ?? 0, [rows.length]);

	const tableHeight = useMemo(() => {
		let rowCount = rows.length ?? 0;
		let totalRows = rowCount >= maxRows ? maxRows : rowCount;
		// let subRowCount = 0;

		// let subRows =
		// 	rows?.filter((row) => !Boolean(row.isGrouped) && Boolean(row.isExpanded) && !Boolean(row.canExpand)) ?? [];
		// subRowCount = subRows?.length ?? 0;

		// Checks to see if there are any expanded rows in the table
		let subRowsHasExpandedRows = rows?.filter((row) => Boolean(row.isExpanded)) ?? [];

		let heights = totalRows * rowHeight;
		// If the number of rows is less than 9 rows (since expanded section height is 9 * 35(rowHeight))
		// and there's an expanded section, expand the table to 315px
		if (rows.length < 9 && subRowsHasExpandedRows.length > 0) {
			heights = 315;
		}
		// + rowNum * rowHeight * subRowCount;
		// See item size for calcs
		return heights;
	}, [rows, maxRows, rowHeight]);

	const resetRowHeights = useCallback(() => {
		if (Boolean(variableSizeRef?.current)) {
			variableSizeRef?.current.resetAfterIndex(0, false);
		}
	}, [variableSizeRef]);

	const getItemSize = useCallback(
		(index) => {
			let row = rows[index];
			let subRow = allColumns?.find((d) => d.id === "expander")?.subRow ?? null;
			let showRenderSubRow =
				Boolean(subRow) && !Boolean(row.isGrouped) && Boolean(row.isExpanded) && !Boolean(row.canExpand);

			let itemSize = rowHeight;
			if (showRenderSubRow) {
				itemSize += rowHeight * rowNum;
			}
			return itemSize;
		},
		[rowHeight, rowNum, rows, allColumns]
	);

	const onScroll = useCallback(() => {
		if (Boolean(variableSizeRef?.current)) {
			document.activeElement.blur();
			inputData.selectedInput = { rowIndex: null, columnID: null };
		}
	}, [variableSizeRef]);

	const RenderRow = useCallback(
		({ index, style }) => {
			const row = rows[index];
			prepareRow(rows[index]);
			let maxRowHeight = getItemSize(index);

			return (
				<VirtualTableRenderRow
					index={index}
					style={style}
					row={row}
					rowCount={rowCount}
					maxRows={maxRows}
					maxWidth={maxWidth}
					rowHeight={rowHeight}
					maxRowHeight={maxRowHeight}
					allColumns={allColumns}
					selectedRow={selectedRow}
					setSelectedRow={setSelectedRow}
					toggleAllRowsExpanded={toggleAllRowsExpanded}
					resetRowHeights={resetRowHeights}
					updateMyData={updateMyData}
					scrollBarWidth={scrollBarWidth}
					subRow={row.values.notes}
				/>
			);
		},
		[
			rows,
			prepareRow,
			getItemSize,
			rowCount,
			maxRows,
			maxWidth,
			rowHeight,
			allColumns,
			selectedRow,
			setSelectedRow,
			toggleAllRowsExpanded,
			resetRowHeights,
			updateMyData,
			scrollBarWidth,
		]
	);

	useEffect(() => {
		if (Boolean(variableSizeRef?.current) && Boolean(sliceType === "last")) {
			let index = rows.length - 1;
			variableSizeRef?.current?.scrollToItem(index);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<TableContainer {...getTableProps()} display="inline-block" borderSpacing={0} maxW={maxWidth}>
			<VirtualTableHeader
				rows={rows}
				headerGroups={headerGroups}
				filterIsOpen={filterIsOpen}
				maxRows={maxRows}
				maxWidth={maxWidth}
				expanded={expanded}
				scrollBarWidth={scrollBarWidth}
			/>

			<Flex
				w="full"
				maxW={maxWidth}
				{...getTableBodyProps()}
				borderLeft={"1px"}
				borderLeftColor="gray.300"
				borderLeftWidth={1}
				borderRightWidth={1}
				borderColor="gray.500"
			>
				<VariableSizeList
					onScroll={onScroll}
					ref={variableSizeRef}
					itemCount={rowCount}
					height={tableHeight}
					itemSize={getItemSize}
					style={{
						overflowX: "hidden",
						background: "white",
					}}
					width={maxWidth}
					overscanCount={maxRows * 2}
				>
					{RenderRow}
				</VariableSizeList>
			</Flex>
			<VirtualTableFooter
				rows={rows}
				footerGroups={footerGroups}
				maxRows={maxRows}
				maxWidth={maxWidth}
				footer={footer}
				scrollBarWidth={scrollBarWidth}
			/>
		</TableContainer>
	);
}
