export default class ProjectMgr {
	constructor(projectMgr) {
		this.queryData = projectMgr?.queryData ?? projectMgr?.QueryData ?? "JCProjectMgr";
		this.jcmpKeyID = projectMgr?.jcmpKeyID ?? projectMgr?.JCMPKeyID ?? null;
		this.jcco = projectMgr?.jcco ?? projectMgr?.JCCo ?? null;
		this.projectMgrID = projectMgr?.projectMgrID ?? projectMgr?.ProjectMgrID ?? null;
		this.pmName = projectMgr?.pmName ?? projectMgr?.PMName ?? null;
		this.title = this.getTitle(projectMgr?.title ?? projectMgr?.Title ?? null);
		this.email = projectMgr?.email ?? projectMgr?.Email ?? null;
		this.regionID = projectMgr?.regionID ?? projectMgr?.RegionID ?? null;
		this.pmCell = projectMgr?.pmCell ?? projectMgr?.PMCell ?? null;
		this.activeYN = projectMgr?.activeYN ?? projectMgr?.ActiveYN ?? "Y";
		this.pmEmployeeUID = projectMgr?.pmEmployeeUID ?? projectMgr?.PMEmployeeUID ?? null;
		this.pxEmployeeUID = projectMgr?.pxEmployeeUID ?? projectMgr?.PXEmployeeUID ?? null;
		this.regionManagerYN = projectMgr?.regionManagerYN ?? projectMgr?.RegionManagerYN ?? "N";
		this.status = projectMgr?.status ?? projectMgr?.Status ?? "Active";
	}

	getTitle(title) {
		if (this.projectMgrID === "100") {
			return "PM Not Assigned";
		} else {
			return title;
		}
	}
}
