import React, { useMemo } from "react";
import { HStack, Center, Flex, Heading, Text, Tooltip, Stack, IconButton, Tag, TagLabel, Wrap } from "@chakra-ui/react";
import VerticalLabelValue from "../common/VerticalLabelValue";
import { getDeviceType } from "../../../helperFunctions";
import { v4 } from "uuid";

export default function ResourceGroupMemberRow(props) {
	let crewEntryLabel = <i className="fas fa-hard-hat fa-lg"></i>;
	let approvalLabel = <i className="fas fa-check-circle fa-lg"></i>;

	if (getDeviceType() === "mobile") {
		crewEntryLabel = "Crew Entry";
		approvalLabel = "Approval";
	}

	let dept = props.deptMap[props.member?.deptID] ?? null;
	let deptColor =
		dept?.color
			?.replace(".300", "")
			?.replace(".400", "")
			?.replace(".500", "")
			?.replace(".600", "")
			?.replace(".700", "") ?? "gray";

	const member = useMemo(() => props.member, [props.member]);
	const crewEntryIcon = useMemo(
		() => (Boolean(member?.crewEntry) ? "fas fa-check-square fa-lg" : "far fa-square fa-lg"),
		[member?.crewEntry]
	);
	const approvalIcon = useMemo(
		() => (Boolean(member?.approval) ? "fas fa-check-square fa-lg" : "far fa-square fa-lg"),
		[member?.approval]
	);

	return (
		<Flex
			p="3"
			key={v4()}
			id={props.id}
			// className="hoverPointerLight"
			_hover={{
				bg: deptColor + ".50",
				borderColor: deptColor + ".500",
			}}
			borderWidth={2}
			bg="white"
			mb="2"
			shadow="md"
			rounded="md"
			wrap={{ base: "wrap", md: "nowrap" }}
			display={{ base: "block", md: "flex" }}
			onClick={() => {
				props.handleMemberSelect(props.member);
			}}
			justify="space-between"
			draggable={true}
			onDrag={() => props.onDragStart(props.type, props.member)}
		>
			<Flex flex={1}>
				<HStack align="center" spacing="4">
					<Tooltip placement="top" label={member?.dept}>
						<IconButton
							key={dept?.icon ?? "fas fa-user"}
							variant="unstyled"
							bg={dept?.color ?? "gray.400"}
							color="white"
							boxSize="50px"
							w="50px"
							h="50px"
							icon={<i className={(dept?.icon ?? "fas fa-user") + " fa-2x"}></i>}
						></IconButton>
					</Tooltip>
					<Stack>
						<Heading as="h5" size="md" color="gray.500" textTransform="uppercase" isTruncated>
							{member?.lastName + ", " + member?.firstName + " " + (member?.midName ?? null)}
						</Heading>
						<Heading as="h5" size="sm" color="gray.400" textTransform="uppercase" isTruncated>
							{member?.title}
						</Heading>
					</Stack>
				</HStack>
			</Flex>

			<Flex flex={1} align="center" justify="space-between" py="4">
				<HStack>
					<IconButton
						variant="unstyled"
						color="gray.300"
						boxSize="35px"
						w="35px"
						h="35px"
						icon={<i className="fas fa-tags fa-2x fa-fw"></i>}
					></IconButton>
					<Wrap wrap="wrap" maxH="56px">
						<Tag size="md" color="gray.600" bg={"gray.200"} variant="solid" textTransform="uppercase">
							<TagLabel>{member?.dept}</TagLabel>
						</Tag>
						<Tag size="md" color="gray.600" bg="gray.200" variant="solid" textTransform="uppercase">
							<TagLabel>{member?.region}</TagLabel>
						</Tag>
						<Tag size="md" color="gray.600" bg="gray.200" variant="solid" textTransform="uppercase">
							<TagLabel>{member?.craft === "00" ? "Office" : member?.craft}</TagLabel>
						</Tag>
						<Tag size="md" color="gray.600" bg="gray.200" variant="solid" textTransform="uppercase">
							<TagLabel>{member?.trade}</TagLabel>
						</Tag>
					</Wrap>
				</HStack>

				{!props.editGroup && (
					<>
						<Flex pr={2} minW="110px">
							<Center key={v4()}>
								<VerticalLabelValue
									label={crewEntryLabel}
									labelTooltip="Crew Entry Access"
									value={
										<Tooltip placement="bottom" label="Enable Crew Entry">
											<span
												className="hoverPointerLight rounded-circle"
												onClick={() => {
													props.updateMemberRole("crewEntry", props.member);
												}}
											>
												<Text
													mt={1}
													p={0}
													cursor="pointer"
													_hover={{ bg: "white" }}
													key={crewEntryIcon}
													color={Boolean(member?.crewEntry) ? "green.400" : "gray.400"}
													className={crewEntryIcon}
												/>
											</span>
										</Tooltip>
									}
								/>
							</Center>
							<Center key={v4()}>
								<VerticalLabelValue
									label={approvalLabel}
									labelTooltip="Approval Access"
									value={
										<Tooltip placement="bottom" label="Enable Timecard Approval">
											<span
												className="hoverPointerLight rounded-circle"
												onClick={() => {
													props.updateMemberRole("approval", props.member);
												}}
											>
												<Text
													mt={1}
													p={0}
													cursor="pointer"
													_hover={{ bg: "white" }}
													key={approvalIcon}
													color={Boolean(member?.approval) ? "green.400" : "gray.400"}
													className={approvalIcon}
												/>
											</span>
										</Tooltip>
									}
								/>
							</Center>
						</Flex>
					</>
				)}
			</Flex>
		</Flex>
	);
}
