import { chakraThemeColorsMap } from "../../../helperFunctions";

export default class SectionTemplateMFRProjectInfoFooter {
	constructor(contract = null) {
		this.template = "MFRProjectInfoFooterSection";
		this.name = "Project Info Footer";
		this.divider = false;
		this.type = "labelValuePairs";
		this.px = 0;
		this.py = 0;
		this.subSections = [
			new SubSectionTemplateMFRProjectInfoFooter1(),
			new SubSectionTemplateMFRProjectInfoFooter2(contract),
			new SubSectionTemplateMFRProjectInfoFooter3(),
		];
	}
}

class SubSectionTemplateMFRProjectInfoFooter1 {
	constructor() {
		this.template = "mfrProjectInfoFooterSubSection1";
		this.type = "labelValuePairs";
		this.width = "28%";
		this.columns = [
			{
				accessor: "label",
				width: "100%",
				textAlign: "right",
			},
		];
		this.data = [
			{
				label: "Scope",
			},
		];
	}
}

class SubSectionTemplateMFRProjectInfoFooter2 {
	constructor(contract = null) {
		this.template = "mfrProjectInfoFooterSubSection2";
		this.type = "labelValuePairs";
		this.width = "62%";
		this.flexDirection = "row";
		this.flexWrap = "wrap";

		this.row = {
			display: "flex",
			flexDirection: "row",
			flexWrap: "nowrap",

			width: "33%",

			justifyContent: "center",
		};

		let label = {
			accessor: "label",
			textAlign: "right",
			fontSize: 7,
			show: true,
			justifyContent: "center",
		};
		let value = {
			accessor: "value",
			valueType: "percent",
			decimals: 1,
			textAlign: "left",
			fontSize: 7,
			justifyContent: "center",
			maxWidth: "35px",

			borderBottomWidth: 1,

			show: true,
		};

		let data = [];
		if (Boolean(contract)) {
			if (Boolean(parseFloat(contract?.pctPLUM) > 0)) {
				data.push({ labelTextColor: chakraThemeColorsMap.dimgray, label: "PLUM", value: contract?.pctPLUM });
			}
			if (Boolean(parseFloat(contract?.pctHVAC) > 0)) {
				data.push({ labelTextColor: chakraThemeColorsMap.dimgray, label: "HVAC", value: contract?.pctHVAC });
			}
			if (Boolean(parseFloat(contract?.pctPIPE) > 0)) {
				data.push({ labelTextColor: chakraThemeColorsMap.dimgray, label: "PIPE", value: contract?.pctPIPE });
			}
			if (Boolean(parseFloat(contract?.pctFUEL) > 0)) {
				data.push({ labelTextColor: chakraThemeColorsMap.dimgray, label: "FUEL", value: contract?.pctFUEL });
			}
			if (Boolean(parseFloat(contract?.pctPROC) > 0)) {
				data.push({ labelTextColor: chakraThemeColorsMap.dimgray, label: "PROC", value: contract?.pctPROC });
			}
			if (Boolean(parseFloat(contract?.pctFIRE) > 0)) {
				data.push({ labelTextColor: chakraThemeColorsMap.dimgray, label: "FIRE", value: contract?.pctFIRE });
			}
			if (Boolean(parseFloat(contract?.pctSITE) > 0)) {
				data.push({ labelTextColor: chakraThemeColorsMap.dimgray, label: "SITE", value: contract?.pctSITE });
			}
		}

		if (data.length === 0) {
			label.width = "0%";
			label.show = false;
			value.width = "100%";
			value.valueType = "string";
			data.push({ value: "N/A - No Budget" });
		}

		this.columns = [label, value];

		this.data = data;
	}
}

class SubSectionTemplateMFRProjectInfoFooter3 {
	constructor() {
		this.template = "mfrProjectInfoFooterSubSection3";
		this.type = "labelValuePairs";
		this.width = "10%";
		this.columns = [
			{
				accessor: "rightAddon",
				width: "100%",
			},
		];
		this.data = [
			{
				rightAddon: null,
			},
		];
	}
}
