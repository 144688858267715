import React, { useState, useEffect } from "react";
import {
	Flex,
	Input,
	Button,
	Stack,
	IconButton,
	FormControl,
	FormLabel,
	Skeleton,
	InputGroup,
	InputLeftAddon,
	Text,
} from "@chakra-ui/react";
import BasicCard from "../../../core/Layout/cards/BasicCard";
import PhoneInput from "react-phone-number-input/input";
import PhoneNumber from "awesome-phonenumber";

import UsersAPI from "../../../APIs/UsersAPI";
import EmergencyContact from "../classes/EmergencyContact";

import { portalUser } from "../../../App";

export default function UserEmergencyContacts({ employeeUID = portalUser?.user?.employeeUID, isDrawer = false }) {
	const usersAPI = new UsersAPI();
	const [emergencyContacts, setEmergencyContacts] = useState(null);

	useEffect(() => {
		const init = async () => {
			let contacts = await usersAPI.GetEmergencyContacts(employeeUID);

			for (let i = 0; i < contacts.value?.length; i++) {
				contacts.value[i] = new EmergencyContact(contacts.value[i]);
			}

			if (contacts.status === 200) {
				setEmergencyContacts(contacts.value);
			}
		};
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateContactName = async (e, contactUID) => {
		let value = e.target.value;
		let tempEmergencyContacts = emergencyContacts;
		for (let i = 0; i < tempEmergencyContacts.length; i++) {
			if (contactUID === tempEmergencyContacts[i].contactUID) {
				await tempEmergencyContacts[i].updateContactName(value);
				break;
			}
		}
		setEmergencyContacts([...tempEmergencyContacts]);
	};

	const updateRelationship = async (e, contactUID) => {
		let value = e.target.value;
		let tempEmergencyContacts = emergencyContacts;
		for (let i = 0; i < tempEmergencyContacts.length; i++) {
			if (contactUID === tempEmergencyContacts[i].contactUID) {
				await tempEmergencyContacts[i].updateRelationship(value);
				break;
			}
		}
		setEmergencyContacts([...tempEmergencyContacts]);
	};

	const updateEmail = async (e, contactUID) => {
		let value = e.target.value;
		let tempEmergencyContacts = emergencyContacts;
		for (let i = 0; i < tempEmergencyContacts.length; i++) {
			if (contactUID === tempEmergencyContacts[i].contactUID) {
				await tempEmergencyContacts[i].updateEmail(value);
				break;
			}
		}
		setEmergencyContacts([...tempEmergencyContacts]);
	};

	const updateHomePhone = async (e = "", contactUID) => {
		let value = e;
		let tempEmergencyContacts = emergencyContacts;

		if (typeof e === "object") {
			value = e.target.value;
		}

		for (let i = 0; i < tempEmergencyContacts.length; i++) {
			if (contactUID === tempEmergencyContacts[i].contactUID) {
				await tempEmergencyContacts[i].updateHomePhone(value);
				break;
			}
		}

		setEmergencyContacts([...tempEmergencyContacts]);
	};

	const updateWorkPhone = async (e = "", contactUID) => {
		let value = e;
		let tempEmergencyContacts = emergencyContacts;

		if (typeof e === "object") {
			value = e.target.value;
		}

		for (let i = 0; i < tempEmergencyContacts.length; i++) {
			if (contactUID === tempEmergencyContacts[i].contactUID) {
				await tempEmergencyContacts[i].updateWorkPhone(value);
				break;
			}
		}

		setEmergencyContacts([...tempEmergencyContacts]);
	};

	const addEmergencyContact = async () => {
		let tempEmergencyContacts = emergencyContacts;
		let contact = new EmergencyContact();

		await contact.upload();
		tempEmergencyContacts.push(contact);
		setEmergencyContacts([...tempEmergencyContacts]);
	};

	const deleteEmergencyContact = async (contactUID) => {
		let tempEmergencyContacts = emergencyContacts;

		for (let i = 0; i < tempEmergencyContacts.length; i++) {
			if (contactUID === tempEmergencyContacts[i].contactUID) {
				await tempEmergencyContacts[i].delete();
				emergencyContacts.splice(i, 1);
				break;
			}
		}

		setEmergencyContacts([...tempEmergencyContacts]);
	};

	return (
		// <BasicCard title="Emergency Contacts">
		// <Stack width="full" spacing="3">
		// <Flex w="full" py={1}></Flex>
		<Skeleton minH="220px" isLoaded={emergencyContacts} rounded="lg" w="full" id="card-emergencycontacts">
			<Stack w="full" spacing="6">
				{emergencyContacts?.map((contact, i) => (
					<BasicCard
						title={i === 0 ? "Emergency Contacts" : null}
						subtitle={"Emergency Contact " + (i + 1)}
						portalStyles={portalUser.styles}
						cardOptions={
							<IconButton
								display={i === 0 ? "none" : "flex"}
								cursor="pointer"
								size="sm"
								icon={<Text fontSize="xl" className={"fas fa-times-circle fa-lg fa-fw"} />}
								variant="ghost"
								onClick={(e) => {
									deleteEmergencyContact(contact.contactUID);
								}}
								colorScheme="red"
								textTransform="uppercase"
							/>
						}
					>
						<Stack key={contact.contactUID} w="full" spacing={isDrawer ? "3" : "4"}>
							<Stack
								w="full"
								spacing={isDrawer ? "3" : "4"}
								direction={{ base: "column", lg: isDrawer ? "column" : "row" }}
							>
								<FormControl id="email" flex={1}>
									<FormLabel color="gray.500" letterSpacing={1} textTransform="uppercase">
										Contact Name
									</FormLabel>
									<InputGroup
										shadow={portalUser.styles.cardShadow}
										borderColor={portalUser.styles.cardBorderColor}
										rounded="md"
									>
										<InputLeftAddon
											pointerEvents="none"
											children={<Text color="gray.600" fontSize="lg" className={"fas fa-user"} />}
										/>
										<Input
											variant="filled"
											bg="whiteAlpha.600"
											color="gray.600"
											borderWidth={1}
											borderColor={portalUser.styles.cardBorderColor}
											rounded="md"
											_hover={{ bg: "whiteAlpha.900" }}
											_focus={{
												borderWidth: 1.5,
												borderColor: "teal.500",
												bg: "whiteAlpha.900",
											}}
											type="text"
											placeholder="Contact Name"
											defaultValue={contact.name}
											maxLength={255}
											onBlur={(e) => {
												updateContactName(e, contact.contactUID);
											}}
										/>
									</InputGroup>
								</FormControl>

								<FormControl id="address" flex={1}>
									<FormLabel color="gray.500" letterSpacing={1} textTransform="uppercase">
										Relationship
									</FormLabel>
									<InputGroup
										shadow={portalUser.styles.cardShadow}
										borderColor={portalUser.styles.cardBorderColor}
										rounded="md"
									>
										<InputLeftAddon
											pointerEvents="none"
											children={
												<Text
													color="gray.600"
													fontSize="lg"
													className={"fas fa-user-friends"}
												/>
											}
										/>
										<Input
											variant="filled"
											bg="whiteAlpha.600"
											color="gray.600"
											borderWidth={1}
											borderColor={portalUser.styles.cardBorderColor}
											rounded="md"
											_hover={{ bg: "whiteAlpha.900" }}
											_focus={{
												borderWidth: 1.5,
												borderColor: "teal.500",
												bg: "whiteAlpha.900",
											}}
											type="text"
											onBlur={(e) => {
												updateRelationship(e, contact.contactUID);
											}}
											defaultValue={contact.relationship}
											placeholder="Relationship"
										/>
									</InputGroup>
								</FormControl>
							</Stack>

							<Stack
								w="full"
								spacing={isDrawer ? "3" : "4"}
								direction={{ base: "column", lg: isDrawer ? "column" : "row" }}
							>
								<FormControl id="email" flex={1}>
									<FormLabel color="gray.500" letterSpacing={1} textTransform="uppercase">
										Email
									</FormLabel>
									<InputGroup
										shadow={portalUser.styles.cardShadow}
										borderColor={portalUser.styles.cardBorderColor}
										rounded="md"
									>
										<InputLeftAddon
											pointerEvents="none"
											children={
												<Text color="gray.600" fontSize="lg" className={"fas fa-envelope"} />
											}
										/>
										<Input
											variant="filled"
											bg="whiteAlpha.600"
											color="gray.600"
											borderWidth={1}
											borderColor={portalUser.styles.cardBorderColor}
											rounded="md"
											_hover={{ bg: "whiteAlpha.900" }}
											_focus={{
												borderWidth: 1.5,
												borderColor: "teal.500",
												bg: "whiteAlpha.900",
											}}
											type="text"
											defaultValue={contact.email}
											placeholder="Email"
											onBlur={(e) => {
												updateEmail(e, contact.contactUID);
											}}
										/>
									</InputGroup>
								</FormControl>

								<Stack
									w="full"
									spacing={isDrawer ? "3" : "4"}
									direction={{ base: "column", lg: isDrawer ? "column" : "row" }}
									flex={1}
								>
									<FormControl id="address" flex={1}>
										<FormLabel
											color={
												new PhoneNumber(contact.homePhone, "US").isValid() ||
												contact.homePhone === ""
													? "gray.500"
													: "red"
											}
											letterSpacing={1}
											textTransform="uppercase"
										>
											Cell Phone
										</FormLabel>
										<InputGroup
											shadow={portalUser.styles.cardShadow}
											borderColor={portalUser.styles.cardBorderColor}
											rounded="md"
										>
											<InputLeftAddon
												pointerEvents="none"
												children={
													<Text
														color="gray.600"
														fontSize="lg"
														className={"fas fa-mobile-alt"}
													/>
												}
											/>
											<Input
												as={PhoneInput}
												country="US"
												variant="filled"
												bg="whiteAlpha.600"
												color="gray.600"
												borderWidth={1}
												borderColor={portalUser.styles.cardBorderColor}
												rounded="md"
												_hover={{ bg: "whiteAlpha.900" }}
												_focus={{
													borderWidth: 1.5,
													borderColor: new PhoneNumber(contact?.homePhone, "US").isValid()
														? "green.500"
														: "red.500",
													bg: "whiteAlpha.900",
												}}
												type="text"
												placeholder="(###) ###-####"
												value={new PhoneNumber(contact.homePhone, "US").getNumber()}
												onBlur={(e) => {
													updateHomePhone(e, contact.contactUID);
												}}
												onChange={(e) => {
													updateHomePhone(e, contact.contactUID);
												}}
												isInvalid={
													contact.homePhone === ""
														? false
														: !new PhoneNumber(contact.homePhone, "US").isValid()
												}
											/>
										</InputGroup>
									</FormControl>

									<FormControl id="address" flex={1}>
										<FormLabel
											color={
												new PhoneNumber(contact.workPhone, "US").isValid() ||
												contact.workPhone === ""
													? "gray.500"
													: "red"
											}
											letterSpacing={1}
											textTransform="uppercase"
										>
											Work Phone
										</FormLabel>
										<InputGroup
											shadow={portalUser.styles.cardShadow}
											borderColor={portalUser.styles.cardBorderColor}
											rounded="md"
										>
											<InputLeftAddon
												pointerEvents="none"
												children={
													<Text
														color="gray.600"
														fontSize="lg"
														className={"fas fa-briefcase"}
													/>
												}
											/>
											<Input
												as={PhoneInput}
												country="US"
												variant="filled"
												bg="whiteAlpha.600"
												color="gray.600"
												borderWidth={1}
												borderColor={portalUser.styles.cardBorderColor}
												rounded="md"
												_hover={{ bg: "whiteAlpha.900" }}
												_focus={{
													borderWidth: 1.5,
													borderColor: new PhoneNumber(contact?.workPhone, "US").isValid()
														? "green.500"
														: "red.500",
													bg: "whiteAlpha.900",
												}}
												type="text"
												placeholder="(###) ###-####"
												value={new PhoneNumber(contact.workPhone, "US").getNumber()}
												onBlur={(e) => {
													updateWorkPhone(e, contact.contactUID);
												}}
												onChange={(e) => {
													updateWorkPhone(e, contact.contactUID);
												}}
												isInvalid={
													contact.workPhone === ""
														? false
														: !new PhoneNumber(contact.workPhone, "US").isValid()
												}
											/>
										</InputGroup>
									</FormControl>
								</Stack>
							</Stack>
						</Stack>
					</BasicCard>
				))}

				<Flex w="full" justify="flex-end">
					<Button textTransform="uppercase" colorScheme="teal" onClick={addEmergencyContact}>
						New Contact
					</Button>
				</Flex>
			</Stack>
		</Skeleton>
		// </Stack>
		// </BasicCard>
	);
}
