import SchedulesAPI from "../../../APIs/SchedulesAPI";
import { log } from "../../../helperFunctions";
import { v4 } from "uuid";

export default class DFWorkflow {
	constructor(workflow) {
		if (workflow instanceof DFWorkflow) {
			Object.assign(this, workflow);
		} else {
			this.queryData = workflow?.queryData ?? "D&FWorkflow";
			this.schedulesAPI = new SchedulesAPI();
			this.uid = workflow?.uid ?? v4();
			this.id = workflow?.id?.toString() ?? null;

			this.templateID = workflow?.templateID?.toString() ?? null;
			this.workflowID = workflow?.workflowID?.toString() ?? null;

			this.deliverableNum = workflow?.deliverableNum?.toString() ?? "0";
			this.deliverableName = workflow?.deliverableName ?? null;
			this.deliverableDesc = workflow?.deliverableDesc ?? null;
			this.deliverableType = workflow?.deliverableType ?? "checkDate";
			this.duration = workflow?.duration ?? 0;

			this.respRoleID = workflow?.respRoleID ?? null;
			this.respRole = workflow?.respRole ?? null;
			this.notifyRole = workflow?.notifyRoles ?? null;

			this.Header = workflow?.deliverableName ?? workflow?.name ?? null;
			this.accessor = "deliverable_" + this.deliverableNum?.toString();
			this.isVisible = workflow?.isVisible ?? "1";
		}
	}

	getAccessor() {
		let accessor = "deliverable_" + this.deliverableNum?.toString();
		return accessor;
	}

	//This is moved to DFData and is used for customizing workflows for a template
	// async insertDB() {
	// 	if (!Boolean(this.id)) {
	// 		let data = (({ Header, respRole, notifyRole, duration, isVisible }) => ({
	// 			Header,
	// 			respRole,
	// 			notifyRole,
	// 			duration,
	// 			isVisible,
	// 		}))(this);
	// 		const DFDataApi = new DFDataAPI();
	// 		let result = await DFDataApi.PortalUser_DFData_InsertWorkflow(data);
	// 		if (result?.status === 200 && result?.value?.length > 0) {
	// 			result = new DFWorkflow(result?.value[0]);
	// 			Object.assign(this, result);
	// 		}
	// 		log("Database Result (insert workflow)", result);
	// 		return result;
	// 	}
	// }

	async updateDB() {
		if (Boolean(this.id)) {
			let data = (({ Header, respRole, notifyRole, duration, isVisible }) => ({
				Header,
				respRole,
				notifyRole,
				duration,
				isVisible,
			}))(this);

			let result = await this.schedulesAPI.PortalUser_DFData_UpdateWorkflow(this.id, data);
			if (result?.status === 200 && result?.value?.length > 0) {
				result = new DFWorkflow(result?.value[0]);
				Object.assign(this, result);
			}
			log("Database Result (update workflow)", result);
			return result;
		}
	}

	async deleteDB() {
		if (Boolean(this.id)) {
			let result = await this.schedulesAPI.PortalUser_DFData_DeleteWorkflow(this.id);
			log("Database Result (DELETE workflow)", result);
			return result;
		}
	}

	initEgnyte() {
		//TODO: Update folder name at deliverable level if workflow name is changed
	}
}
