import React, { useState, useMemo, useEffect } from "react";
import { Flex, Collapse } from "@chakra-ui/react";

export default function TableHeader({
	getTableHeaderRow,
	headerGroup,
	tableBodyWidth,
	sizesIsOpen,
	filterIsOpen,
	floatRowHeight = 0,
}) {
	const tableHeaderRow = useMemo(
		() => getTableHeaderRow(headerGroup, "TableHeaderRow"),
		[getTableHeaderRow, headerGroup]
	);
	const floatHeaderRow = useMemo(
		() => getTableHeaderRow(headerGroup, "FloatHeaderRow"),
		[getTableHeaderRow, headerGroup]
	);
	const tableFilterRow = useMemo(
		() => getTableHeaderRow(headerGroup, "TableFilterRow"),
		[getTableHeaderRow, headerGroup]
	);
	const [tableSizesRow, setTHeadSizesRow] = useState(null);
	useEffect(() => {
		if (tableBodyWidth > 0 && sizesIsOpen) {
			setTHeadSizesRow(getTableHeaderRow(headerGroup, "TableSizesRow"));
		}
	}, [tableBodyWidth, headerGroup, getTableHeaderRow, sizesIsOpen]);

	return (
		<Flex direction="column" flex={1}>
			<Collapse
				in={sizesIsOpen}
				animateOpacity
				style={{
					display: "flex",
					flex: 1,
					flexDirection: "row",
					width: "100%",
					justifyContent: "flex-start",
					alignItems: "flex-start",
				}}
			>
				{tableSizesRow}
			</Collapse>
			{Boolean(floatHeaderRow && floatRowHeight > 0) && floatHeaderRow}
			{Boolean(tableHeaderRow) && tableHeaderRow}

			<Collapse
				in={filterIsOpen}
				animateOpacity
				style={{
					display: "flex",
					flex: 1,
					flexDirection: "row",
					width: "100%",
					justifyContent: "flex-start",
					alignItems: "flex-start",
				}}
			>
				{tableFilterRow}
			</Collapse>
		</Flex>
	);
}
