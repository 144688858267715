import PayrollAPI from "../../../APIs/PayrollAPI";
import { portalUser } from "../../../App";
import { formatValue } from "../../../helperFunctions";

export default class PayPeriodsObjClassLegacy {
	constructor(list = []) {
		this.payrollAPI = new PayrollAPI();
		this.payPeriods = [];
		this.currentPeriod = portalUser.user?.prEndDate ?? null;

		if (list?.length > 0) {
			for (let i = 0; i < list?.length; i++) {
				let period = new PayPeriodClassLegacy(list[i]);
				this.payPeriods.push(period);
				if (period.isCurrentPeriod === 1) {
					this.currentPeriod = period;
					this.currentPeriod.weekDates = this.getWeekDates(period.prEndDate);
				}
			}
		}
	}

	async initPayPeriods(employeeUID = portalUser.user?.employeeUID, prEndDate = portalUser.user?.prEndDate) {
		let payPeriods = this;
		if (payPeriods?.payPeriods?.length === 0) {
			let result = await this.payrollAPI.GetPayPeriods(employeeUID);
			if (result?.status === 200 && result?.value?.length > 0) {
				payPeriods = new PayPeriodsObjClassLegacy(result.value);
			}
		}
		if (prEndDate) {
			payPeriods.setPayPeriod(prEndDate);
		}
		return payPeriods;
	}

	setPayPeriod(date) {
		let prevPayPeriod = this.payPeriods?.find((d) => d.isCurrentPeriod === 1 && d.prEndDate !== date) ?? null;
		if (Boolean(prevPayPeriod)) {
			prevPayPeriod.isCurrentPeriod = 0;
		}

		let newPayPeriod = this.payPeriods?.find((d) => d.prEndDate === date) ?? null;
		if (Boolean(newPayPeriod)) {
			newPayPeriod.isCurrentPeriod = 1;
			newPayPeriod.weekDates = this.getWeekDates(date);
		}
		this.currentPeriod = newPayPeriod;
	}

	getWeekDates(prEndDate = portalUser.user?.prEndDate) {
		if (typeof prEndDate === "string") {
			prEndDate = new Date(prEndDate);
		}

		var weekDates = [];
		var endDate = new Date(prEndDate);
		var startDate = new Date(prEndDate).setDate(endDate.getDate() - 6);
		var weekdays = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];
		var date = new Date(startDate);

		while (date.getDay() !== endDate.getDay()) {
			weekDates.push({
				day: weekdays[date.getDay()],
				dateStr: date.toLocaleDateString(),
			});
			date.setDate(date.getDate() + 1);
		}
		weekDates.push({
			day: weekdays[date.getDay()],
			dateStr: date.toLocaleDateString(),
		});

		return weekDates;
	}
}

class PayPeriodClassLegacy {
	constructor(payPeriod) {
		this.prEndDate =
			formatValue(payPeriod?.prEndDate ?? payPeriod?.PREndDate ?? portalUser.user?.prEndDate, 0, "date") ?? null;
		this.isCurrentPeriod = payPeriod?.IsCurrentPeriod ?? 0;
		this.weekDates = [];
	}
}
