import moment from "moment";
import { portalUser } from "../../../App";
import SectionTemplateBillingAndCostActuals from "./SectionTemplateBillingAndCostActuals";
import SectionTemplateBillingAndCostProjections from "./SectionTemplateBillingAndCostProjections";

export default class PageTemplateBillingAndCostProjections {
	constructor(selectedData = null, thruMth = null) {
		let contract = {};
		let region = {};
		let contractTotalsByMth = [];
		let manpowerProjectionsByMth = [];
		let contractTotals = null;

		if (Boolean(selectedData.length > 0)) {
			contract = selectedData?.filter((p) => p.queryData === "Contract")[0] ?? {};
			region = portalUser?.getRegions()?.filter((p) => p.regionID === contract?.regionID)[0] ?? {};
			contractTotalsByMth = selectedData?.filter((p) => p.queryData === "ContractTotalsByMth") ?? [];

			manpowerProjectionsByMth = selectedData?.filter((p) => p.queryData === "ManpowerProjectionsByMth") ?? [];

			contractTotalsByMth =
				contractTotalsByMth?.filter((p) => new Date(p.mth).getTime() <= new Date(thruMth).getTime()) ?? [];

			manpowerProjectionsByMth =
				manpowerProjectionsByMth?.filter((p) => new Date(p.mth).getTime() >= new Date(thruMth).getTime()) ?? [];
			contractTotals =
				contractTotalsByMth?.filter(
					(p) => moment(new Date(p.mth)).format("MM/YY") === moment(new Date(thruMth)).format("MM/YY")
				)[0] ?? null;
		}

		this.pageTemplate = "BillingAndCostProjections";
		this.pageName = "Billing & Cost Projections";
		this.pageTitle = this.getPageTitle(contract, thruMth);
		this.region = region;
		this.size = "C4";
		this.orientation = "landscape";
		this.pageBody = [
			new SectionTemplateBillingAndCostActuals(contractTotalsByMth, contractTotals),
			new SectionTemplateBillingAndCostProjections(manpowerProjectionsByMth, contractTotals),
		];
	}

	getPageTitle(contract = null, thruMth = null) {
		let pageTitle = "Report Template";
		if (Boolean(contract?.contract)) {
			pageTitle = contract?.contract + " " + contract?.description;
			pageTitle +=
				Boolean(contract) && Boolean(thruMth) ? " Thru " + moment(new Date(thruMth)).format("MMM YYYY") : "";
		}
		return pageTitle;
	}
}
