import React, { useMemo } from "react";
import { Flex, Stack } from "@chakra-ui/react";
import LabelValuePairLegacy from "./MFRLabelValuePair";
import { formatNumber, getValueColor } from "../../../helperFunctions";

export default function AccountingSummary(props) {
	const contract = useMemo(() => props.contract, [props.contract]);
	const contractStatus = useMemo(() => props.contractStatus, [props.contractStatus]);
	const earlyLateAvg = useMemo(() => props.earlyLateAvg, [props.earlyLateAvg]);
	// const prevContractStatus = useMemo(() => props.prevContractStatus, [props.prevContractStatus]);

	return (
		<Flex w="full" px={4}>
			<Stack spacing={1} flex={1} pr={{ base: 0, lg: 8 }} w="full">
				<LabelValuePairLegacy
					direction="horizontal"
					valueAlign="right"
					label="A. Gross Billing to Date"
					value={formatNumber(contractStatus?.billedAmt ?? contract?.billedAmt, 0, "currency")}
					sideNote={formatNumber(
						contractStatus?.getBilledToDatePct() ?? contract?.getBilledToDatePct(),
						1,
						"percent"
					)}
					sideNoteMargin={"42px"}
				/>
				<LabelValuePairLegacy
					direction="horizontal"
					valueAlign="right"
					label="B. Retention Held"
					value={formatNumber(contractStatus?.retentionAmt ?? contract?.retentionAmt, 0, "currency")}
					sideNote={formatNumber(
						contractStatus?.getRetentionPct() ?? contract?.getRetentionPct(),
						1,
						"percent"
					)}
					sideNoteMargin={"42px"}
				/>
				<LabelValuePairLegacy
					direction="horizontal"
					valueAlign="right"
					label="C. Unbilled Contract Balance"
					value={formatNumber(
						contractStatus?.getCurrBillingBal() ?? contract?.getCurrBillingBal(),
						0,
						"currency"
					)}
				/>
				<LabelValuePairLegacy
					direction="horizontal"
					valueAlign="right"
					label="D. Projected Billing Balance"
					value={formatNumber(
						contractStatus?.getProjBillingBal() ?? contract?.getProjBillingBal(),
						0,
						"currency"
					)}
				/>
				<LabelValuePairLegacy
					direction="horizontal"
					valueAlign="right"
					label="E. Early/Late (3 Month Avg)"
					value={formatNumber(isNaN(earlyLateAvg) ? 0 : earlyLateAvg, 1) + " days"}
				/>
			</Stack>

			<Stack spacing={1} flex={1} pl={{ base: 0, lg: 8 }} w="full">
				<LabelValuePairLegacy
					direction="horizontal"
					valueAlign="right"
					label="Cash Received"
					value={formatNumber(contractStatus?.receivedAmt ?? contract?.receivedAmt, 0, "currency")}
				/>
				<LabelValuePairLegacy
					direction="horizontal"
					valueAlign="right"
					label="Current Cost"
					value={formatNumber(contractStatus?.actualCost ?? contract?.actualCost, 0, "currency")}
				/>

				<LabelValuePairLegacy
					direction="horizontal"
					valueAlign="right"
					label="Received vs Cost"
					value={formatNumber(
						contractStatus?.getReceivedVsCost() ?? contract?.getReceivedVsCost(),
						0,
						"currency"
					)}
				/>
				<LabelValuePairLegacy
					direction="horizontal"
					valueAlign="right"
					label="Billed vs Cost"
					value={formatNumber(
						contractStatus?.getBilledVsCost() ?? contract?.getBilledVsCost(),
						0,
						"currency"
					)}
				/>
				<LabelValuePairLegacy
					direction="horizontal"
					valueAlign="right"
					label="Net Cash Flow"
					fontWeight="bold"
					textColor={getValueColor(formatNumber(contractStatus?.netCashFlow ?? contract?.netCashFlow))}
					value={formatNumber(contractStatus?.netCashFlow ?? contract?.netCashFlow, 0, "currency")}
				/>
			</Stack>
		</Flex>
	);
}
