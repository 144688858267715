import React, { useMemo } from "react";
import { Box, Flex, Heading, Input, HStack, Stack } from "@chakra-ui/react";
import { formatValue } from "../../../helperFunctions";

export default function FormSectionEmployeeInfo(props) {
	let form = useMemo(() => {
		let form = props.form ?? null;
		if (Boolean(form)) {
			form?.getNamesAndTitles();
			form?.getIsTemplateYN();
			form?.getFormTypeDesc();
			form?.getFormStatus();
			form?.getIsVisibleYN();
		}
		return form;
	}, [props.form]);
	let assignedFor = useMemo(() => props.usersMap[form?.assignedFor] ?? null, [form?.assignedFor, props.usersMap]);

	let longevity = useMemo(() => {
		let longevity = "";
		if (Boolean(assignedFor)) {
			let reviewYear = form?.formYear;
			let reviewMth = "10/1/" + reviewYear?.toString();
			let currentMth = formatValue(new Date(), 0, "moment")?.startOf("month").format("M/D/YYYY");
			reviewMth = formatValue(reviewMth, 0, "moment");
			currentMth = formatValue(currentMth, 0, "moment");

			let deltaInMths = reviewMth?.diff(currentMth, "months", true);
			let longevityYears = parseFloat(assignedFor?.longevityYears) + parseFloat(deltaInMths / 12);
			longevity =
				formatValue(assignedFor?.hireDate, 0, "date") + " (" + assignedFor?.getLongevity(longevityYears) + ")";
		}
		return longevity;
	}, [assignedFor, form?.formYear]);

	let reviewPeriod = useMemo(() => {
		let reviewYear = form?.formYear;

		let reviewMth = "10/1/" + reviewYear?.toString();
		reviewMth = formatValue(reviewMth, 0, "month");

		let prevReviewYear = reviewYear - 1;
		let prevReviewMth = "10/1/" + prevReviewYear?.toString();
		prevReviewMth = formatValue(prevReviewMth, 0, "month");

		return prevReviewMth + " to " + reviewMth;
	}, [form?.formYear]);

	const styles = useMemo(() => {
		let headingFontColor = props.isPrinting ? "var(--chakra-colors-gray-800)" : "gray.400";
		let headingFontSize = props.isPrinting ? "22px" : "2xl";
		let headingLetterSpacing = props.isPrinting ? 2 : 3;

		let flexDirection = props.isPrinting ? "row" : { base: "column", md: "row" };

		let labelTextAlign = "right";
		let labelMinW = props.isPrinting ? "150px" : 32;
		let labelMaxW = props.isPrinting ? "250px" : 40;
		let labelFontColor = props.isPrinting ? "var(--chakra-colors-gray-800)" : "gray.500";
		let labelFontSize = props.isPrinting ? "16px" : "lg";

		let valueTextAlign = "left";
		let valueMinW = props.isPrinting ? "150px" : "2xs";
		let valueMaxW = props.isPrinting ? "200px" : "xs";
		let valueFontColor = props.isPrinting ? "black" : "gray.600";
		let valueFontSize = props.isPrinting ? "16px" : "lg";
		let valueFontWeight = props.isPrinting ? "normal" : form?.assignedFor ? "semibold" : "normal";
		let valueFontStyle = props.isPrinting ? "normal" : form?.assignedFor ? "normal" : "italic";
		let valueBottomWidth = 2;

		return {
			headingFontColor,
			headingFontSize,
			headingLetterSpacing,

			flexDirection,

			labelMinW,
			labelMaxW,
			labelTextAlign,
			labelFontColor,
			labelFontSize,

			valueMinW,
			valueMaxW,
			valueTextAlign,
			valueFontColor,
			valueFontSize,
			valueFontWeight,
			valueFontStyle,
			valueBottomWidth,
		};
	}, [form?.assignedFor, props.isPrinting]);

	return (
		<Box id="FormSection-EmployeeInfo" w="full" flex={1} textTransform="uppercase" rounded="md">
			<Heading
				fontSize={styles.headingFontSize}
				color={styles.headingFontColor}
				letterSpacing={styles.headingLetterSpacing}
			>
				Employee Info
			</Heading>

			<Box w="full" rounded="md" p={3}>
				<Flex direction={styles.flexDirection} w="full" flex={1}>
					<Stack flex={1} w="full">
						<HStack
							spacing={3}
							align="center"
							w="full"
							flex={1}
							px={4}
							textTransform={"uppercase"}
							letterSpacing={2}
						>
							<Heading
								w={styles.labelMaxW}
								minW={styles.labelMinW}
								maxW={styles.labelMaxW}
								color={styles.labelFontColor}
								fontSize={styles.labelFontSize}
								textAlign={styles.labelTextAlign}
								isTruncated
							>
								Employee
							</Heading>

							<Input
								w={styles.valueMaxW}
								minW={styles.valueMinW}
								maxW={styles.valueMaxW}
								color={styles.valueFontColor}
								fontSize={styles.valueFontSize}
								fontWeight={styles.valueFontWeight}
								fontStyle={styles.valueFontStyle}
								textAlign={styles.valueTextAlign}
								borderBottomColor={styles.labelFontColor}
								borderBottomWidth={styles.valueBottomWidth}
								variant="flushed"
								placeholder="Employee Name"
								value={assignedFor?.name}
								isReadOnly
							/>
						</HStack>

						<HStack
							spacing={3}
							align="center"
							w="full"
							flex={1}
							px={4}
							textTransform={"uppercase"}
							letterSpacing={1}
						>
							<Heading
								w={styles.labelMaxW}
								minW={styles.labelMinW}
								maxW={styles.labelMaxW}
								color={styles.labelFontColor}
								fontSize={styles.labelFontSize}
								textAlign={styles.labelTextAlign}
								isTruncated
							>
								Hire Date
							</Heading>
							<Input
								w={styles.valueMaxW}
								minW={styles.valueMinW}
								maxW={styles.valueMaxW}
								color={styles.valueFontColor}
								fontSize={styles.valueFontSize}
								fontWeight={styles.valueFontWeight}
								fontStyle={styles.valueFontStyle}
								textAlign={styles.valueTextAlign}
								borderBottomColor={styles.labelFontColor}
								borderBottomWidth={styles.valueBottomWidth}
								variant="flushed"
								placeholder="mm/dd/yyyy"
								value={longevity}
								isReadOnly
							/>
						</HStack>
						{/* <HStack
							spacing={3}
							align="center"
							w="full"
							flex={1}
							px={4}
							textTransform={"uppercase"}
							letterSpacing={1}
						>
							<Heading
								w={styles.labelMaxW}
								minW={styles.labelMinW}
								maxW={styles.labelMaxW}
								color={styles.labelFontColor}
								fontSize={styles.labelFontSize}
								textAlign={styles.labelTextAlign}
								isTruncated
							>
								Reviewer
							</Heading>
							<Input
								w={styles.valueMaxW}
								minW={styles.valueMinW}
								maxW={styles.valueMaxW}
								color={styles.valueFontColor}
								fontSize={styles.valueFontSize}
								fontWeight={styles.valueFontWeight}
								fontStyle={styles.valueFontStyle}
								textAlign={styles.valueTextAlign}
								borderBottomColor={styles.labelFontColor}
								borderBottomWidth={styles.valueBottomWidth}
								variant="flushed"
								placeholder="Reviewer Name"
								value={form?.assignedToName ?? null}
								isReadOnly
							/>
						</HStack> */}
					</Stack>

					<Stack flex={1} w="full">
						<HStack
							spacing={3}
							align="center"
							w="full"
							flex={1}
							px={4}
							textTransform={"uppercase"}
							letterSpacing={1}
						>
							<Heading
								w={styles.labelMaxW}
								minW={styles.labelMinW}
								maxW={styles.labelMaxW}
								color={styles.labelFontColor}
								fontSize={styles.labelFontSize}
								textAlign={styles.labelTextAlign}
								isTruncated
							>
								Job Title
							</Heading>

							<Input
								w={styles.valueMaxW}
								minW={styles.valueMinW}
								maxW={styles.valueMaxW}
								color={styles.valueFontColor}
								fontSize={styles.valueFontSize}
								fontWeight={styles.valueFontWeight}
								fontStyle={styles.valueFontStyle}
								textAlign={styles.valueTextAlign}
								borderBottomColor={styles.labelFontColor}
								borderBottomWidth={styles.valueBottomWidth}
								variant="flushed"
								placeholder="Job Title"
								value={assignedFor?.title}
								isReadOnly
							/>
						</HStack>
						<HStack
							spacing={3}
							align="center"
							w="full"
							flex={1}
							px={4}
							textTransform={"uppercase"}
							letterSpacing={1}
						>
							<Heading
								w={styles.labelMaxW}
								minW={styles.labelMinW}
								maxW={styles.labelMaxW}
								color={styles.labelFontColor}
								fontSize={styles.labelFontSize}
								textAlign={styles.labelTextAlign}
								isTruncated
							>
								Review Period
							</Heading>

							<Input
								w={styles.valueMaxW}
								minW={styles.valueMinW}
								maxW={styles.valueMaxW}
								color={styles.valueFontColor}
								fontSize={styles.valueFontSize}
								fontWeight={styles.valueFontWeight}
								fontStyle={styles.valueFontStyle}
								textAlign={styles.valueTextAlign}
								borderBottomColor={styles.labelFontColor}
								borderBottomWidth={styles.valueBottomWidth}
								variant="flushed"
								placeholder={reviewPeriod}
								value={assignedFor ? reviewPeriod : null}
								isReadOnly
							/>
						</HStack>
						{/* <HStack
							spacing={3}
							align="center"
							w="full"
							flex={1}
							px={4}
							textTransform={"uppercase"}
							letterSpacing={1}
						>
							<Heading
								w={styles.labelMaxW}
								minW={styles.labelMinW}
								maxW={styles.labelMaxW}
								color={styles.labelFontColor}
								fontSize={styles.labelFontSize}
								textAlign={styles.labelTextAlign}
								isTruncated
							>
								Review Date
							</Heading>

							<Input
								w={styles.valueMaxW}
								minW={styles.valueMinW}
								maxW={styles.valueMaxW}
								color={styles.valueFontColor}
								fontSize={styles.valueFontSize}
								fontWeight={styles.valueFontWeight}
								fontStyle={styles.valueFontStyle}
								textAlign={styles.valueTextAlign}
								borderBottomColor={styles.labelFontColor}
								borderBottomWidth={styles.valueBottomWidth}
								variant="flushed"
								placeholder={"mm/dd/yyyy"}
								value={form?.dateReview?.format("M/D/YYYY")}
								isReadOnly
							/>
						</HStack> */}
					</Stack>
				</Flex>
			</Box>
		</Box>
	);
}
