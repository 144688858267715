import { convertArrayToMap } from "../../../helperFunctions";
import { portalUser } from "../../../App";

export default class PageTemplateHRReviews {
	constructor(formData = {}, params = {}, page = {}) {
		//params: isPayrollEmployees, isDirectoryEmployees, isHREmployees
		this.pageTemplate = "HRReviews";
		this.pageName = page?.pageName ?? "HRReviews";
		this.pageTitle = page?.pageTitle ?? "HR Reviews";
		this.size = page?.size ?? "LETTER";
		this.orientation = "landscape";
		this.bg = "white";
		this.region = this.getRegion();

		this.pageBody = this.getSections(formData, params);
	}

	getSections(formData = {}, params = {}) {
		let pageBody = [];
		pageBody.push(new SectionTemplateHRReviews(formData, params, this));
		return pageBody;
	}

	getRegion(regionID = null) {
		regionID = regionID ?? portalUser.user?.regionID ?? "0";
		let regions = portalUser?.getRegions();
		return regions?.find((p) => p.regionID === regionID);
	}
}

class SectionTemplateHRReviews {
	constructor(formData = {}, params = {}, page = {}) {
		this.template = "HRReviews";
		this.name = "Performance Reviews";
		this.heading = "Performance Reviews";
		// this.tooltip = "Click here to add a new Pending Change Order";
		this.divider = false;
		this.pagebreak = 0;
		this.wrap = true;
		this.type = "virtualTable";
		this.minRows = 1;
		this.maxRows = 23;
		this.subSections = this.getSubSections(formData);
	}

	getSubSections(formData = {}) {
		let subSections = [new SubSectionTemplateHRReviews(formData)];
		return subSections;
	}
}

class SubSectionTemplateHRReviews {
	constructor(formData = {}) {
		let sortGroupFilter = this.getSortGroupFilter();
		this.template = "HR Reviews";
		this.type = "virtualTable";

		// this.loopBy = sortGroupFilter.loopBy;
		this.groupBy = sortGroupFilter.groupBy;
		this.filterBy = sortGroupFilter.filterBy;
		this.sortBy = sortGroupFilter.sortBy;

		let forms = formData?.forms ?? [];

		let data = forms.map(
			({
				keyID,
				templateUID,
				formUID,
				formTypeDesc,
				assignedTo,
				assignedFor,
				dateDue,
				dateReview,
				dateApproved,
				approvedBy,
				assignedToName,
				assignedForName,
				approvedByName,
				dateCompleted,
				dateSubmitted,
				dateCreated,
				requiresReviewYN,
				parentFormUID,
				formYear,
				formType,
				isVisibleYN,
				isTemplateYN,
				title,
				subtitle,
			}) => ({
				keyID,
				templateUID,
				formUID,

				formYear,
				formType,
				formTypeDesc,

				title,
				subtitle,

				assignedTo,
				assignedToName,
				assignedFor,
				assignedForName,

				dateDue: dateDue?.format("MM/DD/YYYY") ?? null,
				dateReview: dateReview?.format("MM/DD/YYYY") ?? null,
				dateApproved: dateApproved?.format("MM/DD/YYYY") ?? null,
				approvedBy,
				approvedByName: approvedByName,
				dateCompleted: dateCompleted?.format("MM/DD/YYYY") ?? null,
				dateSubmitted: dateSubmitted?.format("MM/DD/YYYY") ?? null,
				dateCreated: dateCreated?.format("MM/DD/YYYY") ?? null,

				requiresReviewYN,
				parentFormUID,
				isVisibleYN,
				isTemplateYN,
			})
		);

		this.footer = this.getFooter(data);

		this.columns = [
			{
				id: "isTemplateYN",

				Header: "Is Template",
				textAlign: "center",
				filter: "multiselect",
				show: false,
			},
			{
				id: "keyID",
				Header: "Key ID",
				show: false,
			},
			{
				id: "templateUID",
				Header: "Template UID",
				width: "200%",
				show: false,
			},
			{
				id: "formUID",
				Header: "Form UID",
				width: "200%",
				show: false,
			},

			// {
			// 	id: "formTypeDesc",
			// 	Header: "Form Type",
			// 	Footer: (rows = []) => {
			// 		return this.getFooter(rows);
			// 	},
			// 	width: "150%",
			// },
			{
				id: "formYear",
				Header: "Year",
				filter: "multiselect",
				width: "75%",
			},
			{
				id: "formTypeDesc",
				Header: "Form Type",
				width: "150%",
				filter: "multiselect",
				show: false,
			},
			{
				id: "title",
				Header: "Title",
				width: "175%",
			},

			{
				id: "subtitle",
				Header: "Subtitle",
				width: "200%",
			},

			{
				id: "assignedTo",
				Header: "Assigned To UID",
				show: false,
				width: "200%",
			},
			{
				id: "assignedToName",
				Header: "Assigned To",
				width: "150%",
				filter: "multiselect",
			},
			{
				id: "assignedFor",
				Header: "Assigned For UID",
				show: false,
				width: "200%",
			},

			{
				id: "assignedForName",
				Header: "Assigned For",
				width: "150%",
				filter: "multiselect",
			},
			{
				id: "dateDue",
				Header: "Due Date",
				valueType: "date",
				show: false,
			},

			{
				id: "approvedBy",
				Header: "Approved By UID",
				show: false,
				width: "200%",
			},
			{
				id: "approvedByName",
				Header: "Approved By",
				width: "125%",
				filter: "multiselect",
			},
			{
				id: "dateApproved",
				Header: "Date Approved",
				valueType: "date",
				textAlign: "center",
			},
			{
				id: "dateReview",
				Header: "Review Date",
				valueType: "date",
				textAlign: "center",
			},
			{
				id: "dateCompleted",
				Header: "Date Completed",
				valueType: "date",
				textAlign: "center",
			},
			{
				id: "dateSubmitted",
				Header: "Date Submitted",
				valueType: "date",
				textAlign: "center",
				show: false,
			},
			{
				id: "dateCreated",
				Header: "dateCreated",
				valueType: "date",
				textAlign: "center",
				show: false,
			},
			{
				id: "requiresReviewYN",
				Header: "Requires Review",
				valueType: "boolean",
				textAlign: "center",
				show: false,
			},
			{
				id: "parentFormUID",
				Header: "Parent Form UID",
				textAlign: "center",
				show: false,
			},
			{
				id: "isVisibleYN",
				Header: "Is Visible",
				textAlign: "center",
				formInput: "showHideButton",
			},
		];

		data = data?.filter((d) => d.formUID !== d.templateUID);
		this.data = data;
	}

	getSortGroupFilter() {
		let groupBy = [];
		let sortBy = [
			{ id: "formYear", desc: true },
			{ id: "assignedToName", desc: false },
		];
		let filterBy = [
			{ id: "isTemplateYN", value: "N" },
			{ id: "formYear", value: "2024" },
		];
		let loopBy = [];
		// let filterBy = [{ id: "formYear", value: [moment(new Date()).format("YYYY")] }];
		// let loopBy = [{ id: "formYear", heading: "formYear" }];
		let sortGroupFilter = { sortBy, groupBy, filterBy, loopBy };
		return sortGroupFilter;
	}

	getFooter(rows = []) {
		let footer = [{ formTypeDesc: rows?.length, formTypeDescRightAddon: "Forms" }];
		return footer;
	}
}
