import React, { useState, useCallback } from "react";
import MainContent from "../../core/ReactTable/layout/MainContent";
import ProjectRoutes from "./routes/ProjectRoutes";
// import { JOYRIDE_MYTEAM, JOYRIDE_MYTEAM_PERFORMANCEREVIEWS } from "../../joyride";

export default function Projects(props) {
	const [currentTab, setCurrentTab] = useState(props?.tabVal);
	// const [runJoyride, setRunJoyride] = useState(false);

	const setTab = useCallback((tabVal) => {
		setCurrentTab(tabVal);
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	const tabsArr = [
		{
			name: "Active Projects",
			tab: "active",
			heading: "Projects",
			subheading: "Active Projects",
		},
		{
			name: "Completed Projects",
			tab: "completed",
			heading: "Projects",
			subheading: "Completed Projects",
		},
	];
	let tabs = tabsArr.map((tab) => tab.name);
	// let joyrideSteps = tabsArr[currentTab]?.joyrideSteps ?? null;

	return (
		<MainContent
			tabVal={currentTab}
			changeTab={setTab}
			// title={tabsArr[currentTab]?.heading}
			subtitle={tabsArr[currentTab]?.subheading}
			tabs={tabs}
			{...props}
			// runJoyride={runJoyride}
			// joyrideSteps={joyrideSteps}
			// joyrideCallback={joyrideCallback}
			// startJoyride={() => setRunJoyride(true)}
		>
			<ProjectRoutes tab={tabsArr[currentTab]?.tab ?? tabsArr[0]?.tab} />
		</MainContent>
	);
}
