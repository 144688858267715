export default class Customer {
	constructor(customer) {
		this.queryData = customer?.queryData ?? "ARCustomer";
		this.arcmKeyID = customer?.arcmKeyID ?? customer?.ARCMKeyID ?? null;
		this.custGroup = customer?.custGroup ?? customer?.CustGroup ?? null;
		this.customer = customer?.customer ?? customer?.Customer ?? null;
		this.customerName = customer?.customerName ?? customer?.CustomerName ?? null;
		this.customerPhone = customer?.customerPhone ?? customer?.CustomerPhone ?? null;
		this.billAddress = customer?.billAddress ?? customer?.BillAddress ?? null;
		this.billAddress2 = customer?.billAddress2 ?? customer?.BillAddress2 ?? null;
		this.billCity = customer?.billCity ?? customer?.BillCity ?? null;
		this.billState = customer?.billState ?? customer?.BillState ?? null;
		this.billZip = customer?.billZip ?? customer?.BillZip ?? null;
		this.subtitle = customer?.subtitle ?? this.getSubtitle();
	}

	getSubtitle() {
		return "(Co: " + this.custGroup + "  Customer: " + this.customer + ")";
	}
}
