import React, { useMemo } from "react";
import { Text, Heading, Tr, Td } from "@chakra-ui/react";

import { formatNumber } from "../../../helperFunctions";

export default function ProjectAnalysisSummary(props) {
	const contractStatus = useMemo(() => props.contractStatus, [props.contractStatus]);

	return (
		<>
			<Tr>
				<Td px="2" py="1" borderLeft="2px" borderBottomColor={"blackAlpha.200"} borderBottomWidth="1px">
					<Text fontSize={"sm"}>Original Bid Profit ($)</Text>
				</Td>
				<Td px="2" py="1" borderRight="2px" borderBottomColor={"blackAlpha.200"} borderBottomWidth="1px">
					<Text textAlign="right">{formatNumber(contractStatus?.getBidProfitAmt() ?? 0, 0, "currency")}</Text>
				</Td>
				<Td colSpan={2} border="0px"></Td>
				<Td colSpan={3}>
					<Text fontWeight="bold" textAlign="right">
						{formatNumber(contractStatus?.getPctCompleteCosts() ?? 0, 1, "percent") + " Complete"}
					</Text>
				</Td>
			</Tr>
			<Tr>
				<Td px="2" py="1" borderLeft="2px" borderBottomColor="black" borderBottomWidth="2px">
					<Text fontSize={"sm"}>Original Bid Margin (%)</Text>
				</Td>
				<Td px="2" py="1" borderRight="2px" borderBottomColor="black" borderBottomWidth="2px">
					<Text textAlign="right">{formatNumber(contractStatus?.getBidMarginPct() ?? 0, 1, "percent")}</Text>
				</Td>
				<Td colSpan={2} border="0px"></Td>
				<Td
					borderColor="black"
					borderTopWidth="2px"
					borderBottomWidth="2px"
					borderLeftWidth="2px"
					bg={props.isPrinting ? "white" : "teal.700"}
					textTransform="uppercase"
					colSpan={2}
				>
					<Heading fontSize="xs" color={"white"} p="2" textAlign="left">
						Summary
					</Heading>
				</Td>
				<Td
					borderColor="black"
					borderTopWidth="2px"
					borderBottomWidth="2px"
					borderRightWidth="2px"
					bg={props.isPrinting ? "white" : "teal.700"}
					textTransform="uppercase"
				>
					<Heading fontSize="xs" color={"white"} p="2" textAlign="right">
						Operations
					</Heading>
				</Td>
			</Tr>
			<Tr>
				<Td colSpan={2} border="0px"></Td>
				<Td colSpan={2} border="0px"></Td>
				<Td
					px="2"
					py="1"
					borderLeft="2px"
					colSpan={2}
					borderBottomColor={"blackAlpha.200"}
					borderBottomWidth="1px"
				>
					<Text fontSize={"sm"}>Projected Contract Amount</Text>
				</Td>
				<Td px="2" py="1" borderRight="2px" borderBottomColor={"blackAlpha.200"} borderBottomWidth="1px">
					<Text textAlign="right">
						{formatNumber(contractStatus?.getProjContractAmt() ?? 0, 0, "currency")}
					</Text>
				</Td>
			</Tr>
			<Tr>
				<Td
					px="2"
					py="1"
					borderLeft="2px"
					borderTop="2px"
					borderBottomColor={"blackAlpha.200"}
					borderBottomWidth="1px"
				>
					<Text fontSize={"sm"}>Cost To Date on Pending CO's</Text>
				</Td>
				<Td
					px="2"
					py="1"
					borderRight="2px"
					borderTop="2px"
					borderBottomColor={"blackAlpha.200"}
					borderBottomWidth="1px"
				>
					<Text textAlign="right">{formatNumber(contractStatus?.pcoActualCost ?? 0, 0, "currency")}</Text>
				</Td>
				<Td colSpan={2} border="0px"></Td>
				<Td
					px="2"
					py="1"
					borderLeft="2px"
					colSpan={2}
					borderBottomColor={"blackAlpha.200"}
					borderBottomWidth="1px"
				>
					<Text fontSize={"sm"}>Projected Costs at Completion</Text>
				</Td>
				<Td px="2" py="1" borderRight="2px" borderBottomColor={"blackAlpha.200"} borderBottomWidth="1px">
					<Text textAlign="right">
						{formatNumber(contractStatus?.getProjCostWithPCOs() ?? 0, 0, "currency")}
					</Text>
				</Td>
			</Tr>
			<Tr>
				<Td px="2" py="1" borderLeft="2px" borderBottomColor={"blackAlpha.200"} borderBottomWidth="1px">
					<Text fontSize={"sm"}>Remaining Committed Cost</Text>
				</Td>
				<Td px="2" py="1" borderRight="2px" borderBottomColor={"blackAlpha.200"} borderBottomWidth="1px">
					<Text textAlign="right">{formatNumber(contractStatus?.remainCmtdCost ?? 0, 0, "currency")}</Text>
				</Td>
				<Td colSpan={2} border="0px"></Td>
				<Td
					px="2"
					py="1"
					borderLeft="2px"
					colSpan={2}
					borderBottomColor={"blackAlpha.200"}
					borderBottomWidth="1px"
				>
					<Text fontSize={"sm"}>Projected Gross Margin ($)</Text>
				</Td>
				<Td px="2" py="1" borderRight="2px" borderBottomColor={"blackAlpha.200"} borderBottomWidth="1px">
					<Text textAlign="right">
						{formatNumber(contractStatus?.getProjProfitAmt() ?? 0, 0, "currency")}
					</Text>
				</Td>
			</Tr>
			<Tr>
				<Td px="2" py="1" borderLeft="2px" borderBottomColor="black" borderBottomWidth="2px">
					<Text fontSize={"sm"}>Unapproved AP Amount</Text>
				</Td>
				<Td px="2" py="1" borderRight="2px" borderBottomColor="black" borderBottomWidth="2px">
					<Text textAlign="right">{formatNumber(contractStatus?.unapprovedAPAmt ?? 0, 0, "currency")}</Text>
				</Td>
				<Td colSpan={2} border="0px"></Td>
				<Td px="2" py="1" borderLeft="2px" borderBottomColor="black" borderBottomWidth="2px" colSpan={2}>
					<Text fontSize={"sm"}>Projected Gross Margin (%)</Text>
				</Td>
				<Td px="2" py="1" borderRight="2px" borderBottomColor="black" borderBottomWidth="2px">
					<Text textAlign="right">{formatNumber(contractStatus?.getProjMarginPct() ?? 0, 1, "percent")}</Text>
				</Td>
			</Tr>
		</>
	);
}
