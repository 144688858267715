import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Heading, Container, Tabs, Tab, TabList, Stack, useBoolean } from "@chakra-ui/react";
import LoadingSection from "../../../core/ProgressIndicators/components/LoadingSection";
import OverlayPage from "../../../core/Layout/drawers/OverlayPage";
import TeamReviewsToDoList from "../components/TeamReviewsToDoList";
import HRReviewsDrawer from "../components/HRReviewsDrawer";
import HRReviewsToDoList from "../components/HRReviewsToDoList";
import FormViewerPage from "../../../core/Forms/pages/FormViewerPage";
import FormData from "../../../core/Forms/classes/FormData";
import HRPageHeader from "../components/HRPageHeader";

import { portalUser } from "../../../App";
import { log } from "../../../helperFunctions";

export default function HRReviewsPage(props) {
	//BASE PATH OF CURRENT URL
	let history = useHistory();
	const basepath = useMemo(() => {
		let basepath = history.location.pathname;
		if (basepath?.length > 0 && basepath?.charAt(0) === "/") {
			basepath = basepath?.slice(1);
			basepath = basepath?.substring(0, basepath?.indexOf("/"));
			return basepath;
		}
	}, [history.location.pathname]);

	//TAB OPTIONS DEPENDENT ON BASE PATH (EMPLOYEE, TEAM, or HR)
	const tab = useMemo(() => props.tab, [props.tab]);
	const [tabVal, setTabVal] = useState(0);
	const changeTab = useCallback((e) => {
		setTabVal(e);
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);

	//DATA IS LOADING FLAG AND CALLBACK FUNCTION
	const [isLoading, setIsLoading] = useBoolean(true);
	const setDataLoading = useCallback(
		async function (callback) {
			if (!isLoading) {
				setIsLoading.on();
			}
			callback();
		},
		[isLoading, setIsLoading]
	);

	const formUID = useMemo(() => props.routeUID, [props.routeUID]);
	const [formData, setFormData] = useState(portalUser?.formData ?? null);

	const getFormData = useCallback(
		async (formUID = null) => {
			let newFormData = formData;
			if (Boolean(newFormData)) {
				newFormData = await newFormData?.getFormData(formUID);
			}
			setFormData(newFormData);
			setIsLoading.off();
			window.scrollTo({ top: 0, behavior: "smooth" });
		},
		[formData, setIsLoading]
	);

	const selectForm = useCallback(
		async (newUID = null) => {
			if (!Boolean(newUID) && Boolean(formUID)) {
				portalUser.formData.formUID = null;
				// log("they dont match match formUID: " + formUID + "   newUID: " + newUID);
				history.push(`/${basepath}/${tab}`);
			} else if (Boolean(newUID) && newUID !== formUID) {
				portalUser.formData.formUID = newUID;
				// log("they dont match match formUID: " + formUID + "   newUID: " + newUID);
				history.push(`/${basepath}/${tab}/${newUID}`);
			}

			setIsLoading.on();
			await getFormData(newUID);
		},
		[basepath, formUID, getFormData, history, setIsLoading, tab]
	);

	//ON PAGE LOAD INIT FORM DATA - GET FORMS & TEMPLATES
	const initFormData = useCallback(
		async (formUID) => {
			let msUserID = portalUser.user.msUserID ?? portalUser?.msUser?.id ?? null;
			let newFormData = formData;
			if (Boolean(msUserID) && !Boolean(newFormData)) {
				newFormData = new FormData(portalUser?.formData);
				newFormData = await newFormData?.initFormData(msUserID);
			}
			if (Boolean(formUID)) {
				newFormData = await newFormData?.getFormData(formUID);
			}
			setFormData(newFormData);
			setIsLoading.off();
		},
		[formData, setIsLoading]
	);

	useEffect(() => {
		setDataLoading(() => initFormData(formUID));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Update portalUser formData cache
	useMemo(() => {
		if (Boolean(formData) && !isLoading) {
			portalUser.formData = formData;
		}
	}, [formData, isLoading]);

	const forms = useMemo(() => {
		let forms = [];

		if (!isLoading && formData?.forms?.length > 0) {
			forms = formData?.forms;

			if (tab === "templates") {
				forms = forms?.filter((d) => d.templateUID === d.formUID) ?? [];
			} else {
				forms = forms?.filter((d) => d.templateUID !== d.formUID) ?? [];
			}

			// EMPLOYEE FORMS DEFAULTS (filter to forms where AssignedFor = LoggedIn User)
			if (basepath === "employee") {
				forms = forms?.filter((d) => d.assignedFor === portalUser.user?.employeeUID) ?? [];
				forms = forms?.filter((d) => d.formUID === d.parentFormUID) ?? [];
				// forms = forms?.filter((d) => ["1", "2"].includes(d.formType)) ?? [];
			}
			// TEAM REVIEW FORMS DEFAULTS (filter to forms where AssignedFor !=== LoggedIn User and get all previous forms where the employee's Revewer is currently the LoggedIn User)
			else if (basepath === "team") {
				let users = portalUser?.userData.filter((d) => d.queryData === "User") ?? [];
				let commenterList =
					formData?.commenters?.filter((d) => d.employeeUID === portalUser.user.employeeUID) ?? null;
				let commenterFormUIDs = [...new Set(commenterList?.map((d) => d.formUID))];
				let commenterForms = forms?.filter((d) => commenterFormUIDs.includes(d.formUID)) ?? [];
				let execPeerReviews = [];

				if (portalUser.user.isOperationsExecUserYN === "Y") {
					execPeerReviews =
						forms?.filter((form) => form && form.formType === "3" && form.formUID === form.parentFormUID) ??
						[];
				}
				forms = forms?.filter((d) => d.assignedFor !== portalUser.user?.employeeUID) ?? [];

				forms =
					forms?.filter(
						(d) => (d.formType === "3" && d.formUID === d.parentFormUID) || ["1", "2"].includes(d.formType)
					) ?? [];

				let teamUsers = users?.filter((d) => d.reviewerUID === portalUser.user?.employeeUID) ?? [];

				let employeeUIDs = [...new Set(teamUsers?.map((d) => d.employeeUID) ?? [])];
				employeeUIDs = employeeUIDs?.filter((d) => Boolean(d)) ?? [];

				//Assign a sitDownReviewer to the self-evaluation if you want someone additional to see them
				let teamForms =
					forms?.filter(
						(d) => employeeUIDs.includes(d.assignedFor) || d.reviewedBy === portalUser.user?.employeeUID
					) ?? [];

				let pastForms =
					forms?.filter(
						(d) => !employeeUIDs.includes(d.assignedFor) && d.assignedTo === portalUser.user?.employeeUID
					) ?? [];

				forms = [...new Set([...teamForms, ...pastForms, ...commenterForms, ...execPeerReviews])];
				forms.sort(function (a, b) {
					return (
						b.formYear - a.formYear ||
						parseInt(a.formType) - parseInt(b.formType) ||
						a.assignedToName - b.assignedToName
					);
				});

				//let formUIDs = [...new Set(teamUsers?.map((d) => d.employeeUID) ?? [])];
			}
			// HR REVIEW TAB DEFAULTS (filter to Reviews & Peer Reviews)
			else if (basepath === "hr") {
				forms = forms?.filter((d) => ["2", "3"].includes(d.formType)) ?? [];
				forms = forms?.filter((d) => d.formUID === d.parentFormUID) ?? [];
			}
		}

		return forms;
	}, [basepath, formData?.commenters, formData?.forms, isLoading, tab]);

	const selectedYear = useMemo(() => formData?.getSubtotal(forms, "formYear", "max", true), [formData, forms]);
	const panels = useMemo(() => {
		let panels = [];

		// EMPLOYEE FORMS PANEL DEFAULTS
		if (basepath === "employee") {
			let defaultGroupBy = ["formYear"];
			let defaultFilters = [
				{ id: "userStatus", value: "Active" },
				{ id: "assignedForName", value: portalUser.user?.name },
			];
			let defaultSortAttr = [
				{ id: "formYear", desc: true },
				{ id: "formType", desc: false },
				{ id: "assignedToName", desc: false },
			];
			let defaultExpanded = { ["formYear:" + selectedYear]: true };
			let defaultExpandAll = true;

			let reviewsUser = {
				view: "employee",
				name: "Performance Reviews",
				heading: "Reviews",

				defaultFilters,
				defaultSortAttr,
				defaultGroupBy,
				defaultExpanded,
				defaultExpandAll,
			};
			panels.push(reviewsUser);
		}
		// TEAM REVIEW PANEL DEFAULTS
		else if (basepath === "team") {
			let defaultGroupBy = ["formTypeDesc"];
			let defaultFilters = [
				{ id: "userStatus", value: "Active" },
				{ id: "formYear", value: selectedYear },
			];
			let defaultSortAttr = [
				{ id: "formYear", desc: true },
				{ id: "formType", desc: false },
				{ id: "assignedToName", desc: false },
			];
			let defaultExpanded = { ["formYear:" + selectedYear]: true };
			let defaultExpandAll = true;

			let reviewsTeam = {
				view: basepath,
				name: "Performance Reviews",
				heading: "Reviews",

				defaultFilters: [
					...defaultFilters,
					// { id: "requiresReviewYN", value: " Y" },
					{ id: "formTypeDesc", value: "Performance Review" },
				],
				defaultSortAttr: [...defaultSortAttr],
				defaultGroupBy: [...defaultGroupBy],
				defaultExpanded: defaultExpanded,
				defaultExpandAll: defaultExpandAll,
			};
			panels.push(reviewsTeam);

			let selfEvalsTeam = {
				view: basepath,
				name: "Self-Evaluations",
				heading: "Forms",

				defaultFilters: [...defaultFilters, { id: "formTypeDesc", value: "Self-Evaluation" }],
				defaultSortAttr: [...defaultSortAttr],
				defaultGroupBy: [],
				defaultExpanded: defaultExpanded,
				defaultExpandAll: defaultExpandAll,
			};
			panels.push(selfEvalsTeam);

			let peerReviewsCount =
				forms?.filter((d) => d.formTypeDesc === "Peer Evaluation" && d.formUID === d.parentFormUID) ?? [];

			if (peerReviewsCount?.length > 0) {
				let peersTeam = {
					view: basepath,
					name: "Peer Evaluations",
					heading: "Forms",

					defaultFilters: [
						...defaultFilters,
						{ id: "formTypeDesc", value: "Peer Evaluation" },
						// { id: "requiresReviewYN", value: "Y" },
					],
					defaultSortAttr: [...defaultSortAttr],
					defaultGroupBy: [...defaultGroupBy],
					defaultExpanded: true,
					defaultExpandAll: defaultExpandAll,
				};
				panels.push(peersTeam);
			}
		}

		// HR REVIEW TAB DEFAULTS
		else if (basepath === "hr" && tab === "reviews") {
			let defaultGroupBy = ["formYear", "assignedToName"];
			let defaultFilters = [
				{ id: "userStatus", value: "Active" },
				{ id: "requiresReviewYN", value: "Y" },
				{ id: "formYear", value: selectedYear },
			];
			let defaultSortAttr = [
				{ id: "formYear", desc: true },
				{ id: "assignedToName", desc: false },
				{ id: "assignedForName", desc: false },
			];
			let defaultExpanded = { ["formYear:" + selectedYear]: true };
			let defaultExpandAll = false;

			let reviewsHR = {
				view: basepath,
				name: "All",
				defaultFilters,
				defaultSortAttr,
				defaultGroupBy,
				defaultExpanded,
				defaultExpandAll,
			};
			panels.push(reviewsHR);

			let statuses = ["Submitted", "Approved", "Scheduled", "Completed"];
			for (let i = 0; i < statuses?.length; i++) {
				let panel = {
					view: basepath,
					name: statuses[i],
					defaultFilters: [...defaultFilters, { id: "status", value: statuses[i] }],
					defaultSortAttr,
					defaultGroupBy,
				};
				panels.push(panel);
			}
		}
		return panels;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [basepath, selectedYear, tab]);

	const [editForm, setEditForm] = useState(null);
	const openDrawer = useCallback((form = null) => {
		setEditForm(form);
	}, []);
	const closeDrawer = useCallback((form = null) => {
		setEditForm(null);
	}, []);

	return (
		<OverlayPage
			flex={1}
			bg={"gray.400"}
			isOpen={Boolean(forms?.length > 0) && (Boolean(formUID) || isLoading)} //Or isLoading so that Drawer Opens immediately FormUID is selected
			onClose={() => selectForm(null)}
			childComponent={<FormViewerPage formData={formData} isLoading={isLoading} selectForm={selectForm} />}
		>
			{Boolean(editForm) && <HRReviewsDrawer form={editForm} onClose={closeDrawer} />}

			<Stack
				spacing={portalUser.styles?.pageSpacing}
				p={portalUser.styles?.pagePadding}
				bg={portalUser.styles?.pageBG}
				w="full"
				h="full"
				flex={1}
				align="center"
			>
				{Boolean(basepath === "hr") && (
					<HRPageHeader
						tab={tab}
						formUID={formData?.formUID}
						formData={formData}
						isLoading={isLoading}
						selectForm={selectForm}
					/>
				)}
				<Container maxW="8xl" w="full" display={"flex"} flex={1} px={0} flexDirection="column">
					<Tabs
						isLazy
						colorScheme="teal"
						w="full"
						display="flex"
						flexDirection="column"
						flex={1}
						index={tabVal}
						onChange={changeTab}
					>
						{panels?.length > 1 && (
							<TabList
								color="gray.500"
								borderBottomColor="gray.400"
								borderBottomWidth={2}
								maxW={"full"}
								mx="auto"
								w="full"
								mb={4}
							>
								{panels?.map((panel, i) => (
									<Tab
										borderStyle="inset"
										key={i}
										_selected={{
											color: "teal.600",
											bg: "whiteAlpha.500",
											borderTopRadius: "md",
											borderBottomWidth: 3,
											borderBottomColor: "teal.600",
											textShadow: "1px 1px 2px var(--chakra-colors-whiteAlpha-900)",
										}}
										_hover={{
											color: "teal.500",
											bg: "whiteAlpha.700",
											borderTopRadius: "md",
											borderBottomWidth: 3,
											borderBottomColor: "teal.600",
											textShadow: "1px 1px 2px var(--chakra-colors-whiteAlpha-900)",
											shadow: "lg",
										}}
									>
										<Heading
											isTruncated
											fontSize={"lg"}
											letterSpacing={2}
											textTransform="uppercase"
											textShadow={"2px 2px 2px var(--chakra-colors-whiteAlpha-800)"}
										>
											{panel?.name}
										</Heading>
									</Tab>
								))}
							</TabList>
						)}

						{isLoading ? (
							<LoadingSection />
						) : (
							<>
								{basepath === "hr" ? (
									<HRReviewsToDoList
										forms={forms}
										panel={panels[tabVal]}
										openDrawer={openDrawer}
										selectForm={selectForm}
									/>
								) : (
									<TeamReviewsToDoList
										forms={forms}
										panel={panels[tabVal]}
										openDrawer={openDrawer}
										selectForm={selectForm}
									/>
								)}
							</>
						)}
					</Tabs>
				</Container>
			</Stack>
		</OverlayPage>
	);
}
