import React, { useMemo } from "react";
import { Heading, Progress, HStack, Stack, Text } from "@chakra-ui/react";
import { formatValue } from "../../../helperFunctions";

export default function ProgressBar({ progressBar }) {
	const value = useMemo(() => getPercentage(progressBar?.value ?? 0), [progressBar?.value]);
	const label = useMemo(() => progressBar?.label ?? "Initializing...", [progressBar?.label]);
	const direction = useMemo(() => progressBar?.direction ?? "column", [progressBar?.direction]);

	return (
		<Stack w="full" flex={1} spacing={3} direction={direction}>
			<Heading color="teal.600" textTransform="uppercase" letterSpacing={1} size="md">
				{label}
			</Heading>
			<HStack>
				<Progress
					rounded="md"
					h={6}
					colorScheme="teal"
					w="full"
					size="lg"
					hasStripe
					isAnimated
					bg="whiteAlpha.700"
					value={value}
					borderWidth={1}
					borderColor="teal.500"
					sx={{
						"& > div:first-of-type": {
							transitionProperty: "width",
						},
					}}
				/>
				<Text fontWeight="semibold" color="teal.600" letterSpacing={1}>
					{formatValue(value, 1, "percent")}
				</Text>
			</HStack>
		</Stack>
	);
}

function getRandomArbitrary(min, max) {
	return Math.random() * (max - min) + min;
}

function getPercentage(progressVal = 0) {
	let value = progressVal;
	if (value > 0 && value < 100) {
		value = value + getRandomArbitrary(-1, 6) + Math.random();
	}
	return value;
}
