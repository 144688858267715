export const iconTypes = [
	{ type: "fas", description: "Solid" },
	{ type: "far", description: "Regular" },
	{ type: "fab", description: "Brand" },
];

export const icons = [
	{ type: "fab", icon: "fab fa-500px", content: "\f26e" },

	{ type: "fab", icon: "fab fa-accessible-icon", content: "\f368" },

	{ type: "fab", icon: "fab fa-accusoft", content: "\f369" },

	{ type: "fab", icon: "fab fa-acquisitions-incorporated", content: "\f6af" },

	{ type: "fas", icon: "fas fa-ad", content: "\f641" },

	{ type: "fas", icon: "fas fa-address-book", content: "\f2b9" },

	{ type: "far", icon: "far fa-address-book", content: "\f2b9" },

	{ type: "fas", icon: "fas fa-address-card", content: "\f2bb" },

	{ type: "far", icon: "far fa-address-card", content: "\f2bb" },

	{ type: "fas", icon: "fas fa-adjust", content: "\f042" },

	{ type: "fab", icon: "fab fa-adn", content: "\f170" },

	{ type: "fab", icon: "fab fa-adversal", content: "\f36a" },

	{ type: "fab", icon: "fab fa-affiliatetheme", content: "\f36b" },

	{ type: "fas", icon: "fas fa-air-freshener", content: "\f5d0" },

	{ type: "fab", icon: "fab fa-airbnb", content: "\f834" },

	{ type: "fab", icon: "fab fa-algolia", content: "\f36c" },

	{ type: "fas", icon: "fas fa-align-center", content: "\f037" },

	{ type: "fas", icon: "fas fa-align-justify", content: "\f039" },

	{ type: "fas", icon: "fas fa-align-left", content: "\f036" },

	{ type: "fas", icon: "fas fa-align-right", content: "\f038" },

	{ type: "fab", icon: "fab fa-alipay", content: "\f642" },

	{ type: "fas", icon: "fas fa-allergies", content: "\f461" },

	{ type: "fab", icon: "fab fa-amazon", content: "\f270" },

	{ type: "fab", icon: "fab fa-amazon-pay", content: "\f42c" },

	{ type: "fas", icon: "fas fa-ambulance", content: "\f0f9" },

	{ type: "fas", icon: "fas fa-american-sign-language-interpreting", content: "\f2a3" },

	{ type: "fab", icon: "fab fa-amilia", content: "\f36d" },

	{ type: "fas", icon: "fas fa-anchor", content: "\f13d" },

	{ type: "fab", icon: "fab fa-android", content: "\f17b" },

	{ type: "fab", icon: "fab fa-angellist", content: "\f209" },

	{ type: "fas", icon: "fas fa-angle-double-down", content: "\f103" },

	{ type: "fas", icon: "fas fa-angle-double-left", content: "\f100" },

	{ type: "fas", icon: "fas fa-angle-double-right", content: "\f101" },

	{ type: "fas", icon: "fas fa-angle-double-up", content: "\f102" },

	{ type: "fas", icon: "fas fa-angle-down", content: "\f107" },

	{ type: "fas", icon: "fas fa-angle-left", content: "\f104" },

	{ type: "fas", icon: "fas fa-angle-right", content: "\f105" },

	{ type: "fas", icon: "fas fa-angle-up", content: "\f106" },

	{ type: "fas", icon: "fas fa-angry", content: "\f556" },

	{ type: "fab", icon: "fab fa-angrycreative", content: "\f36e" },

	{ type: "fab", icon: "fab fa-angular", content: "\f420" },

	{ type: "fas", icon: "fas fa-ankh", content: "\f644" },

	{ type: "fab", icon: "fab fa-app-store", content: "\f36f" },

	{ type: "fab", icon: "fab fa-app-store-ios", content: "\f370" },

	{ type: "fab", icon: "fab fa-apper", content: "\f371" },

	{ type: "fab", icon: "fab fa-apple", content: "\f179" },

	{ type: "fas", icon: "fas fa-apple-alt", content: "\f5d1" },

	{ type: "fab", icon: "fab fa-apple-pay", content: "\f415" },

	{ type: "fas", icon: "fas fa-archive", content: "\f187" },

	{ type: "fas", icon: "fas fa-archway", content: "\f557" },

	{ type: "fas", icon: "fas fa-arrow-alt-circle-down", content: "\f358" },

	{ type: "fas", icon: "fas fa-arrow-alt-circle-left", content: "\f359" },

	{ type: "fas", icon: "fas fa-arrow-alt-circle-right", content: "\f35a" },

	{ type: "fas", icon: "fas fa-arrow-alt-circle-up", content: "\f35b" },

	{ type: "fas", icon: "fas fa-arrow-circle-down", content: "\f0ab" },

	{ type: "fas", icon: "fas fa-arrow-circle-left", content: "\f0a8" },

	{ type: "fas", icon: "fas fa-arrow-circle-right", content: "\f0a9" },

	{ type: "fas", icon: "fas fa-arrow-circle-up", content: "\f0aa" },

	{ type: "fas", icon: "fas fa-arrow-down", content: "\f063" },

	{ type: "fas", icon: "fas fa-arrow-left", content: "\f060" },

	{ type: "fas", icon: "fas fa-arrow-right", content: "\f061" },

	{ type: "fas", icon: "fas fa-arrow-up", content: "\f062" },

	{ type: "fas", icon: "fas fa-arrows-alt", content: "\f0b2" },

	{ type: "fas", icon: "fas fa-arrows-alt-h", content: "\f337" },

	{ type: "fas", icon: "fas fa-arrows-alt-v", content: "\f338" },

	{ type: "fab", icon: "fab fa-artstation", content: "\f77a" },

	{ type: "fas", icon: "fas fa-assistive-listening-systems", content: "\f2a2" },

	{ type: "fas", icon: "fas fa-asterisk", content: "\f069" },

	{ type: "fab", icon: "fab fa-asymmetrik", content: "\f372" },

	{ type: "fas", icon: "fas fa-at", content: "\f1fa" },

	{ type: "fas", icon: "fas fa-atlas", content: "\f558" },

	{ type: "fab", icon: "fab fa-atlassian", content: "\f77b" },

	{ type: "fas", icon: "fas fa-atom", content: "\f5d2" },

	{ type: "fab", icon: "fab fa-audible", content: "\f373" },

	{ type: "fas", icon: "fas fa-audio-description", content: "\f29e" },

	{ type: "fab", icon: "fab fa-autoprefixer", content: "\f41c" },

	{ type: "fab", icon: "fab fa-avianex", content: "\f374" },

	{ type: "fab", icon: "fab fa-aviato", content: "\f421" },

	{ type: "fas", icon: "fas fa-award", content: "\f559" },

	{ type: "fab", icon: "fab fa-aws", content: "\f375" },

	{ type: "fas", icon: "fas fa-baby", content: "\f77c" },

	{ type: "fas", icon: "fas fa-baby-carriage", content: "\f77d" },

	{ type: "fas", icon: "fas fa-backspace", content: "\f55a" },

	{ type: "fas", icon: "fas fa-backward", content: "\f04a" },

	{ type: "fas", icon: "fas fa-bacon", content: "\f7e5" },

	{ type: "fas", icon: "fas fa-bacteria", content: "e059" },

	{ type: "fas", icon: "fas fa-bacterium", content: "e05a" },

	{ type: "fas", icon: "fas fa-bahai", content: "\f666" },

	{ type: "fas", icon: "fas fa-balance-scale", content: "\f24e" },

	{ type: "fas", icon: "fas fa-balance-scale-left", content: "\f515" },

	{ type: "fas", icon: "fas fa-balance-scale-right", content: "\f516" },

	{ type: "fas", icon: "fas fa-ban", content: "\f05e" },

	{ type: "fas", icon: "fas fa-band-aid", content: "\f462" },

	{ type: "fab", icon: "fab fa-bandcamp", content: "\f2d5" },

	{ type: "fas", icon: "fas fa-barcode", content: "\f02a" },

	{ type: "fas", icon: "fas fa-bars", content: "\f0c9" },

	{ type: "fas", icon: "fas fa-baseball-ball", content: "\f433" },

	{ type: "fas", icon: "fas fa-basketball-ball", content: "\f434" },

	{ type: "fas", icon: "fas fa-bath", content: "\f2cd" },

	{ type: "fas", icon: "fas fa-battery-empty", content: "\f244" },

	{ type: "fas", icon: "fas fa-battery-full", content: "\f240" },

	{ type: "fas", icon: "fas fa-battery-half", content: "\f242" },

	{ type: "fas", icon: "fas fa-battery-quarter", content: "\f243" },

	{ type: "fas", icon: "fas fa-battery-three-quarters", content: "\f241" },

	{ type: "fab", icon: "fab fa-battle-net", content: "\f835" },

	{ type: "fas", icon: "fas fa-bed", content: "\f236" },

	{ type: "fas", icon: "fas fa-beer", content: "\f0fc" },

	{ type: "fab", icon: "fab fa-behance", content: "\f1b4" },

	{ type: "fab", icon: "fab fa-behance-square", content: "\f1b5" },

	{ type: "fas", icon: "fas fa-bell", content: "\f0f3" },

	{ type: "fas", icon: "fas fa-bell-slash", content: "\f1f6" },

	{ type: "fas", icon: "fas fa-bezier-curve", content: "\f55b" },

	{ type: "fas", icon: "fas fa-bible", content: "\f647" },

	{ type: "fas", icon: "fas fa-bicycle", content: "\f206" },

	{ type: "fas", icon: "fas fa-biking", content: "\f84a" },

	{ type: "fab", icon: "fab fa-bimobject", content: "\f378" },

	{ type: "fas", icon: "fas fa-binoculars", content: "\f1e5" },

	{ type: "fas", icon: "fas fa-biohazard", content: "\f780" },

	{ type: "fas", icon: "fas fa-birthday-cake", content: "\f1fd" },

	{ type: "fab", icon: "fab fa-bitbucket", content: "\f171" },

	{ type: "fab", icon: "fab fa-bitcoin", content: "\f379" },

	{ type: "fab", icon: "fab fa-bity", content: "\f37a" },

	{ type: "fab", icon: "fab fa-black-tie", content: "\f27e" },

	{ type: "fab", icon: "fab fa-blackberry", content: "\f37b" },

	{ type: "fas", icon: "fas fa-blender", content: "\f517" },

	{ type: "fas", icon: "fas fa-blender-phone", content: "\f6b6" },

	{ type: "fas", icon: "fas fa-blind", content: "\f29d" },

	{ type: "fas", icon: "fas fa-blog", content: "\f781" },

	{ type: "fab", icon: "fab fa-blogger", content: "\f37c" },

	{ type: "fab", icon: "fab fa-blogger-b", content: "\f37d" },

	{ type: "fab", icon: "fab fa-bluetooth", content: "\f293" },

	{ type: "fab", icon: "fab fa-bluetooth-b", content: "\f294" },

	{ type: "fas", icon: "fas fa-bold", content: "\f032" },

	{ type: "fas", icon: "fas fa-bolt", content: "\f0e7" },

	{ type: "fas", icon: "fas fa-bomb", content: "\f1e2" },

	{ type: "fas", icon: "fas fa-bone", content: "\f5d7" },

	{ type: "fas", icon: "fas fa-bong", content: "\f55c" },

	{ type: "fas", icon: "fas fa-book", content: "\f02d" },

	{ type: "fas", icon: "fas fa-book-dead", content: "\f6b7" },

	{ type: "fas", icon: "fas fa-book-medical", content: "\f7e6" },

	{ type: "fas", icon: "fas fa-book-open", content: "\f518" },

	{ type: "fas", icon: "fas fa-book-reader", content: "\f5da" },

	{ type: "fas", icon: "fas fa-bookmark", content: "\f02e" },

	{ type: "fab", icon: "fab fa-bootstrap", content: "\f836" },

	{ type: "fas", icon: "fas fa-border-all", content: "\f84c" },

	{ type: "fas", icon: "fas fa-border-none", content: "\f850" },

	{ type: "fas", icon: "fas fa-border-style", content: "\f853" },

	{ type: "fas", icon: "fas fa-bowling-ball", content: "\f436" },

	{ type: "fas", icon: "fas fa-box", content: "\f466" },

	{ type: "fas", icon: "fas fa-box-open", content: "\f49e" },

	{ type: "fas", icon: "fas fa-box-tissue", content: "e05b" },

	{ type: "fas", icon: "fas fa-boxes", content: "\f468" },

	{ type: "fas", icon: "fas fa-braille", content: "\f2a1" },

	{ type: "fas", icon: "fas fa-brain", content: "\f5dc" },

	{ type: "fas", icon: "fas fa-bread-slice", content: "\f7ec" },

	{ type: "fas", icon: "fas fa-briefcase", content: "\f0b1" },

	{ type: "fas", icon: "fas fa-briefcase-medical", content: "\f469" },

	{ type: "fas", icon: "fas fa-broadcast-tower", content: "\f519" },

	{ type: "fas", icon: "fas fa-broom", content: "\f51a" },

	{ type: "fas", icon: "fas fa-brush", content: "\f55d" },

	{ type: "fab", icon: "fab fa-btc", content: "\f15a" },

	{ type: "fab", icon: "fab fa-buffer", content: "\f837" },

	{ type: "fas", icon: "fas fa-bug", content: "\f188" },

	{ type: "fas", icon: "fas fa-building", content: "\f1ad" },

	{ type: "fas", icon: "fas fa-bullhorn", content: "\f0a1" },

	{ type: "fas", icon: "fas fa-bullseye", content: "\f140" },

	{ type: "fas", icon: "fas fa-burn", content: "\f46a" },

	{ type: "fab", icon: "fab fa-buromobelexperte", content: "\f37f" },

	{ type: "fas", icon: "fas fa-bus", content: "\f207" },

	{ type: "fas", icon: "fas fa-bus-alt", content: "\f55e" },

	{ type: "fas", icon: "fas fa-business-time", content: "\f64a" },

	{ type: "fab", icon: "fab fa-buy-n-large", content: "\f8a6" },

	{ type: "fab", icon: "fab fa-buysellads", content: "\f20d" },

	{ type: "fas", icon: "fas fa-calculator", content: "\f1ec" },

	{ type: "fas", icon: "fas fa-calendar", content: "\f133" },

	{ type: "fas", icon: "fas fa-calendar-alt", content: "\f073" },

	{ type: "fas", icon: "fas fa-calendar-check", content: "\f274" },

	{ type: "fas", icon: "fas fa-calendar-day", content: "\f783" },

	{ type: "fas", icon: "fas fa-calendar-minus", content: "\f272" },

	{ type: "fas", icon: "fas fa-calendar-plus", content: "\f271" },

	{ type: "fas", icon: "fas fa-calendar-times", content: "\f273" },

	{ type: "fas", icon: "fas fa-calendar-week", content: "\f784" },

	{ type: "fas", icon: "fas fa-camera", content: "\f030" },

	{ type: "fas", icon: "fas fa-camera-retro", content: "\f083" },

	{ type: "fas", icon: "fas fa-campground", content: "\f6bb" },

	{ type: "fab", icon: "fab fa-canadian-maple-leaf", content: "\f785" },

	{ type: "fas", icon: "fas fa-candy-cane", content: "\f786" },

	{ type: "fas", icon: "fas fa-cannabis", content: "\f55f" },

	{ type: "fas", icon: "fas fa-capsules", content: "\f46b" },

	{ type: "fas", icon: "fas fa-car", content: "\f1b9" },

	{ type: "fas", icon: "fas fa-car-alt", content: "\f5de" },

	{ type: "fas", icon: "fas fa-car-battery", content: "\f5df" },

	{ type: "fas", icon: "fas fa-car-crash", content: "\f5e1" },

	{ type: "fas", icon: "fas fa-car-side", content: "\f5e4" },

	{ type: "fas", icon: "fas fa-caravan", content: "\f8ff" },

	{ type: "fas", icon: "fas fa-caret-down", content: "\f0d7" },

	{ type: "fas", icon: "fas fa-caret-left", content: "\f0d9" },

	{ type: "fas", icon: "fas fa-caret-right", content: "\f0da" },

	{ type: "fas", icon: "fas fa-caret-square-down", content: "\f150" },

	{ type: "fas", icon: "fas fa-caret-square-left", content: "\f191" },

	{ type: "fas", icon: "fas fa-caret-square-right", content: "\f152" },

	{ type: "fas", icon: "fas fa-caret-square-up", content: "\f151" },

	{ type: "fas", icon: "fas fa-caret-up", content: "\f0d8" },

	{ type: "fas", icon: "fas fa-carrot", content: "\f787" },

	{ type: "fas", icon: "fas fa-cart-arrow-down", content: "\f218" },

	{ type: "fas", icon: "fas fa-cart-plus", content: "\f217" },

	{ type: "fas", icon: "fas fa-cash-register", content: "\f788" },

	{ type: "fab", icon: "fab fa-cat", content: "\f6be" },

	{ type: "fab", icon: "fab fa-cc-amazon-pay", content: "\f42d" },

	{ type: "fab", icon: "fab fa-cc-amex", content: "\f1f3" },

	{ type: "fab", icon: "fab fa-cc-apple-pay", content: "\f416" },

	{ type: "fab", icon: "fab fa-cc-diners-club", content: "\f24c" },

	{ type: "fab", icon: "fab fa-cc-discover", content: "\f1f2" },

	{ type: "fab", icon: "fab fa-cc-jcb", content: "\f24b" },

	{ type: "fab", icon: "fab fa-cc-mastercard", content: "\f1f1" },

	{ type: "fab", icon: "fab fa-cc-paypal", content: "\f1f4" },

	{ type: "fab", icon: "fab fa-cc-stripe", content: "\f1f5" },

	{ type: "fab", icon: "fab fa-cc-visa", content: "\f1f0" },

	{ type: "fab", icon: "fab fa-centercode", content: "\f380" },

	{ type: "fab", icon: "fab fa-centos", content: "\f789" },

	{ type: "fas", icon: "fas fa-certificate", content: "\f0a3" },

	{ type: "fas", icon: "fas fa-chair", content: "\f6c0" },

	{ type: "fas", icon: "fas fa-chalkboard", content: "\f51b" },

	{ type: "fas", icon: "fas fa-chalkboard-teacher", content: "\f51c" },

	{ type: "fas", icon: "fas fa-charging-station", content: "\f5e7" },

	{ type: "fas", icon: "fas fa-chart-area", content: "\f1fe" },

	{ type: "fas", icon: "fas fa-chart-bar", content: "\f080" },

	{ type: "fas", icon: "fas fa-chart-line", content: "\f201" },

	{ type: "fas", icon: "fas fa-chart-pie", content: "\f200" },

	{ type: "fas", icon: "fas fa-check", content: "\f00c" },

	{ type: "fas", icon: "fas fa-check-circle", content: "\f058" },

	{ type: "fas", icon: "fas fa-check-double", content: "\f560" },

	{ type: "fas", icon: "fas fa-check-square", content: "\f14a" },

	{ type: "fas", icon: "fas fa-cheese", content: "\f7ef" },

	{ type: "fas", icon: "fas fa-chess", content: "\f439" },

	{ type: "fas", icon: "fas fa-chess-bishop", content: "\f43a" },

	{ type: "fas", icon: "fas fa-chess-board", content: "\f43c" },

	{ type: "fas", icon: "fas fa-chess-king", content: "\f43f" },

	{ type: "fas", icon: "fas fa-chess-knight", content: "\f441" },

	{ type: "fas", icon: "fas fa-chess-pawn", content: "\f443" },

	{ type: "fas", icon: "fas fa-chess-queen", content: "\f445" },

	{ type: "fas", icon: "fas fa-chess-rook", content: "\f447" },

	{ type: "fas", icon: "fas fa-chevron-circle-down", content: "\f13a" },

	{ type: "fas", icon: "fas fa-chevron-circle-left", content: "\f137" },

	{ type: "fas", icon: "fas fa-chevron-circle-right", content: "\f138" },

	{ type: "fas", icon: "fas fa-chevron-circle-up", content: "\f139" },

	{ type: "fas", icon: "fas fa-chevron-down", content: "\f078" },

	{ type: "fas", icon: "fas fa-chevron-left", content: "\f053" },

	{ type: "fas", icon: "fas fa-chevron-right", content: "\f054" },

	{ type: "fas", icon: "fas fa-chevron-up", content: "\f077" },

	{ type: "fas", icon: "fas fa-child", content: "\f1ae" },

	{ type: "fab", icon: "fab fa-chrome", content: "\f268" },

	{ type: "fab", icon: "fab fa-chromecast", content: "\f838" },

	{ type: "fas", icon: "fas fa-church", content: "\f51d" },

	{ type: "fas", icon: "fas fa-circle", content: "\f111" },

	{ type: "fas", icon: "fas fa-circle-notch", content: "\f1ce" },

	{ type: "fas", icon: "fas fa-city", content: "\f64f" },

	{ type: "fas", icon: "fas fa-clinic-medical", content: "\f7f2" },

	{ type: "fas", icon: "fas fa-clipboard", content: "\f328" },

	{ type: "fas", icon: "fas fa-clipboard-check", content: "\f46c" },

	{ type: "fas", icon: "fas fa-clipboard-list", content: "\f46d" },

	{ type: "fas", icon: "fas fa-clock", content: "\f017" },

	{ type: "fas", icon: "fas fa-clone", content: "\f24d" },

	{ type: "fas", icon: "fas fa-closed-captioning", content: "\f20a" },

	{ type: "fas", icon: "fas fa-cloud", content: "\f0c2" },

	{ type: "fas", icon: "fas fa-cloud-download-alt", content: "\f381" },

	{ type: "fas", icon: "fas fa-cloud-meatball", content: "\f73b" },

	{ type: "fas", icon: "fas fa-cloud-moon", content: "\f6c3" },

	{ type: "fas", icon: "fas fa-cloud-moon-rain", content: "\f73c" },

	{ type: "fas", icon: "fas fa-cloud-rain", content: "\f73d" },

	{ type: "fas", icon: "fas fa-cloud-showers-heavy", content: "\f740" },

	{ type: "fas", icon: "fas fa-cloud-sun", content: "\f6c4" },

	{ type: "fas", icon: "fas fa-cloud-sun-rain", content: "\f743" },

	{ type: "fas", icon: "fas fa-cloud-upload-alt", content: "\f382" },

	{ type: "fab", icon: "fab fa-cloudflare", content: "e07d" },

	{ type: "fab", icon: "fab fa-cloudscale", content: "\f383" },

	{ type: "fab", icon: "fab fa-cloudsmith", content: "\f384" },

	{ type: "fab", icon: "fab fa-cloudversify", content: "\f385" },

	{ type: "fas", icon: "fas fa-cocktail", content: "\f561" },

	{ type: "fas", icon: "fas fa-code", content: "\f121" },

	{ type: "fas", icon: "fas fa-code-branch", content: "\f126" },

	{ type: "fab", icon: "fab fa-codepen", content: "\f1cb" },

	{ type: "fab", icon: "fab fa-codiepie", content: "\f284" },

	{ type: "fas", icon: "fas fa-coffee", content: "\f0f4" },

	{ type: "fas", icon: "fas fa-cog", content: "\f013" },

	{ type: "fas", icon: "fas fa-cogs", content: "\f085" },

	{ type: "fas", icon: "fas fa-coins", content: "\f51e" },

	{ type: "fas", icon: "fas fa-columns", content: "\f0db" },

	{ type: "fas", icon: "fas fa-comment", content: "\f075" },

	{ type: "fas", icon: "fas fa-comment-alt", content: "\f27a" },

	{ type: "fas", icon: "fas fa-comment-dollar", content: "\f651" },

	{ type: "fas", icon: "fas fa-comment-dots", content: "\f4ad" },

	{ type: "fas", icon: "fas fa-comment-medical", content: "\f7f5" },

	{ type: "fas", icon: "fas fa-comment-slash", content: "\f4b3" },

	{ type: "fas", icon: "fas fa-comments", content: "\f086" },

	{ type: "fas", icon: "fas fa-comments-dollar", content: "\f653" },

	{ type: "fas", icon: "fas fa-compact-disc", content: "\f51f" },

	{ type: "fas", icon: "fas fa-compass", content: "\f14e" },

	{ type: "fas", icon: "fas fa-compress", content: "\f066" },

	{ type: "fas", icon: "fas fa-compress-alt", content: "\f422" },

	{ type: "fas", icon: "fas fa-compress-arrows-alt", content: "\f78c" },

	{ type: "fas", icon: "fas fa-concierge-bell", content: "\f562" },

	{ type: "fab", icon: "fab fa-confluence", content: "\f78d" },

	{ type: "fab", icon: "fab fa-connectdevelop", content: "\f20e" },

	{ type: "fab", icon: "fab fa-contao", content: "\f26d" },

	{ type: "fas", icon: "fas fa-cookie", content: "\f563" },

	{ type: "fas", icon: "fas fa-cookie-bite", content: "\f564" },

	{ type: "fas", icon: "fas fa-copy", content: "\f0c5" },

	{ type: "fas", icon: "fas fa-copyright", content: "\f1f9" },

	{ type: "fab", icon: "fab fa-cotton-bureau", content: "\f89e" },

	{ type: "fas", icon: "fas fa-couch", content: "\f4b8" },

	{ type: "fab", icon: "fab fa-cpanel", content: "\f388" },

	{ type: "fab", icon: "fab fa-creative-commons", content: "\f25e" },

	{ type: "fab", icon: "fab fa-creative-commons-by", content: "\f4e7" },

	{ type: "fab", icon: "fab fa-creative-commons-nc", content: "\f4e8" },

	{ type: "fab", icon: "fab fa-creative-commons-nc-eu", content: "\f4e9" },

	{ type: "fab", icon: "fab fa-creative-commons-nc-jp", content: "\f4ea" },

	{ type: "fab", icon: "fab fa-creative-commons-nd", content: "\f4eb" },

	{ type: "fab", icon: "fab fa-creative-commons-pd", content: "\f4ec" },

	{ type: "fab", icon: "fab fa-creative-commons-pd-alt", content: "\f4ed" },

	{ type: "fab", icon: "fab fa-creative-commons-remix", content: "\f4ee" },

	{ type: "fab", icon: "fab fa-creative-commons-sa", content: "\f4ef" },

	{ type: "fab", icon: "fab fa-creative-commons-sampling", content: "\f4f0" },

	{ type: "fab", icon: "fab fa-creative-commons-sampling-plus", content: "\f4f1" },

	{ type: "fab", icon: "fab fa-creative-commons-share", content: "\f4f2" },

	{ type: "fab", icon: "fab fa-creative-commons-zero", content: "\f4f3" },

	{ type: "fas", icon: "fas fa-credit-card", content: "\f09d" },

	{ type: "fab", icon: "fab fa-critical-role", content: "\f6c9" },

	{ type: "fas", icon: "fas fa-crop", content: "\f125" },

	{ type: "fas", icon: "fas fa-crop-alt", content: "\f565" },

	{ type: "fas", icon: "fas fa-cross", content: "\f654" },

	{ type: "fas", icon: "fas fa-crosshairs", content: "\f05b" },

	{ type: "fas", icon: "fas fa-crow", content: "\f520" },

	{ type: "fas", icon: "fas fa-crown", content: "\f521" },

	{ type: "fas", icon: "fas fa-crutch", content: "\f7f7" },

	{ type: "fab", icon: "fab fa-css3", content: "\f13c" },

	{ type: "fab", icon: "fab fa-css3-alt", content: "\f38b" },

	{ type: "fas", icon: "fas fa-cube", content: "\f1b2" },

	{ type: "fas", icon: "fas fa-cubes", content: "\f1b3" },

	{ type: "fas", icon: "fas fa-cut", content: "\f0c4" },

	{ type: "fab", icon: "fab fa-cuttlefish", content: "\f38c" },

	{ type: "fab", icon: "fab fa-d-and-d", content: "\f38d" },

	{ type: "fab", icon: "fab fa-d-and-d-beyond", content: "\f6ca" },

	{ type: "fab", icon: "fab fa-dailymotion", content: "e052" },

	{ type: "fab", icon: "fab fa-dashcube", content: "\f210" },

	{ type: "fas", icon: "fas fa-database", content: "\f1c0" },

	{ type: "fas", icon: "fas fa-deaf", content: "\f2a4" },

	{ type: "fab", icon: "fab fa-deezer", content: "e077" },

	{ type: "fab", icon: "fab fa-delicious", content: "\f1a5" },

	{ type: "fas", icon: "fas fa-democrat", content: "\f747" },

	{ type: "fab", icon: "fab fa-deploydog", content: "\f38e" },

	{ type: "fab", icon: "fab fa-deskpro", content: "\f38f" },

	{ type: "fas", icon: "fas fa-desktop", content: "\f108" },

	{ type: "fab", icon: "fab fa-dev", content: "\f6cc" },

	{ type: "fab", icon: "fab fa-deviantart", content: "\f1bd" },

	{ type: "fas", icon: "fas fa-dharmachakra", content: "\f655" },

	{ type: "fab", icon: "fab fa-dhl", content: "\f790" },

	{ type: "fas", icon: "fas fa-diagnoses", content: "\f470" },

	{ type: "fab", icon: "fab fa-diaspora", content: "\f791" },

	{ type: "fas", icon: "fas fa-dice", content: "\f522" },

	{ type: "fas", icon: "fas fa-dice-d20", content: "\f6cf" },

	{ type: "fas", icon: "fas fa-dice-d6", content: "\f6d1" },

	{ type: "fas", icon: "fas fa-dice-five", content: "\f523" },

	{ type: "fas", icon: "fas fa-dice-four", content: "\f524" },

	{ type: "fas", icon: "fas fa-dice-one", content: "\f525" },

	{ type: "fas", icon: "fas fa-dice-six", content: "\f526" },

	{ type: "fas", icon: "fas fa-dice-three", content: "\f527" },

	{ type: "fas", icon: "fas fa-dice-two", content: "\f528" },

	{ type: "fab", icon: "fab fa-digg", content: "\f1a6" },

	{ type: "fab", icon: "fab fa-digital-ocean", content: "\f391" },

	{ type: "fas", icon: "fas fa-digital-tachograph", content: "\f566" },

	{ type: "fas", icon: "fas fa-directions", content: "\f5eb" },

	{ type: "fab", icon: "fab fa-discord", content: "\f392" },

	{ type: "fab", icon: "fab fa-discourse", content: "\f393" },

	{ type: "fas", icon: "fas fa-disease", content: "\f7fa" },

	{ type: "fas", icon: "fas fa-divide", content: "\f529" },

	{ type: "fas", icon: "fas fa-dizzy", content: "\f567" },

	{ type: "fas", icon: "fas fa-dna", content: "\f471" },

	{ type: "fab", icon: "fab fa-dochub", content: "\f394" },

	{ type: "fab", icon: "fab fa-docker", content: "\f395" },

	{ type: "fas", icon: "fas fa-dog", content: "\f6d3" },

	{ type: "fas", icon: "fas fa-dollar-sign", content: "\f155" },

	{ type: "fas", icon: "fas fa-dolly", content: "\f472" },

	{ type: "fas", icon: "fas fa-dolly-flatbed", content: "\f474" },

	{ type: "fas", icon: "fas fa-donate", content: "\f4b9" },

	{ type: "fas", icon: "fas fa-door-closed", content: "\f52a" },

	{ type: "fas", icon: "fas fa-door-open", content: "\f52b" },

	{ type: "fas", icon: "fas fa-dot-circle", content: "\f192" },

	{ type: "fas", icon: "fas fa-dove", content: "\f4ba" },

	{ type: "fas", icon: "fas fa-download", content: "\f019" },

	{ type: "fab", icon: "fab fa-draft2digital", content: "\f396" },

	{ type: "fas", icon: "fas fa-drafting-compass", content: "\f568" },

	{ type: "fas", icon: "fas fa-dragon", content: "\f6d5" },

	{ type: "fas", icon: "fas fa-draw-polygon", content: "\f5ee" },

	{ type: "fab", icon: "fab fa-dribbble", content: "\f17d" },

	{ type: "fab", icon: "fab fa-dribbble-square", content: "\f397" },

	{ type: "fab", icon: "fab fa-dropbox", content: "\f16b" },

	{ type: "fas", icon: "fas fa-drum", content: "\f569" },

	{ type: "fas", icon: "fas fa-drum-steelpan", content: "\f56a" },

	{ type: "fas", icon: "fas fa-drumstick-bite", content: "\f6d7" },

	{ type: "fab", icon: "fab fa-drupal", content: "\f1a9" },

	{ type: "fas", icon: "fas fa-dumbbell", content: "\f44b" },

	{ type: "fas", icon: "fas fa-dumpster", content: "\f793" },

	{ type: "fas", icon: "fas fa-dumpster-fire", content: "\f794" },

	{ type: "fas", icon: "fas fa-dungeon", content: "\f6d9" },

	{ type: "fab", icon: "fab fa-dyalog", content: "\f399" },

	{ type: "fab", icon: "fab fa-earlybirds", content: "\f39a" },

	{ type: "fab", icon: "fab fa-ebay", content: "\f4f4" },

	{ type: "fab", icon: "fab fa-edge", content: "\f282" },

	{ type: "fab", icon: "fab fa-edge-legacy", content: "e078" },

	{ type: "fas", icon: "fas fa-edit", content: "\f044" },

	{ type: "fas", icon: "fas fa-egg", content: "\f7fb" },

	{ type: "fas", icon: "fas fa-eject", content: "\f052" },

	{ type: "fab", icon: "fab fa-elementor", content: "\f430" },

	{ type: "fas", icon: "fas fa-ellipsis-h", content: "\f141" },

	{ type: "fas", icon: "fas fa-ellipsis-v", content: "\f142" },

	{ type: "fab", icon: "fab fa-ello", content: "\f5f1" },

	{ type: "fab", icon: "fab fa-ember", content: "\f423" },

	{ type: "fab", icon: "fab fa-empire", content: "\f1d1" },

	{ type: "fas", icon: "fas fa-envelope", content: "\f0e0" },

	{ type: "fas", icon: "fas fa-envelope-open", content: "\f2b6" },

	{ type: "fas", icon: "fas fa-envelope-open-text", content: "\f658" },

	{ type: "fas", icon: "fas fa-envelope-square", content: "\f199" },

	{ type: "fab", icon: "fab fa-envira", content: "\f299" },

	{ type: "fas", icon: "fas fa-equals", content: "\f52c" },

	{ type: "fas", icon: "fas fa-eraser", content: "\f12d" },

	{ type: "fab", icon: "fab fa-erlang", content: "\f39d" },

	{ type: "fab", icon: "fab fa-ethereum", content: "\f42e" },

	{ type: "fas", icon: "fas fa-ethernet", content: "\f796" },

	{ type: "fab", icon: "fab fa-etsy", content: "\f2d7" },

	{ type: "fas", icon: "fas fa-euro-sign", content: "\f153" },

	{ type: "fab", icon: "fab fa-evernote", content: "\f839" },

	{ type: "fas", icon: "fas fa-exchange-alt", content: "\f362" },

	{ type: "fas", icon: "fas fa-exclamation", content: "\f12a" },

	{ type: "fas", icon: "fas fa-exclamation-circle", content: "\f06a" },

	{ type: "fas", icon: "fas fa-exclamation-triangle", content: "\f071" },

	{ type: "fas", icon: "fas fa-expand", content: "\f065" },

	{ type: "fas", icon: "fas fa-expand-alt", content: "\f424" },

	{ type: "fas", icon: "fas fa-expand-arrows-alt", content: "\f31e" },

	{ type: "fab", icon: "fab fa-expeditedssl", content: "\f23e" },

	{ type: "fas", icon: "fas fa-external-link-alt", content: "\f35d" },

	{ type: "fas", icon: "fas fa-external-link-square-alt", content: "\f360" },

	{ type: "fas", icon: "fas fa-eye", content: "\f06e" },

	{ type: "fas", icon: "fas fa-eye-dropper", content: "\f1fb" },

	{ type: "fas", icon: "fas fa-eye-slash", content: "\f070" },

	{ type: "fab", icon: "fab fa-facebook", content: "\f09a" },

	{ type: "fab", icon: "fab fa-facebook-f", content: "\f39e" },

	{ type: "fab", icon: "fab fa-facebook-messenger", content: "\f39f" },

	{ type: "fab", icon: "fab fa-facebook-square", content: "\f082" },

	{ type: "fas", icon: "fas fa-fan", content: "\f863" },

	{ type: "fab", icon: "fab fa-fantasy-flight-games", content: "\f6dc" },

	{ type: "fas", icon: "fas fa-fast-backward", content: "\f049" },

	{ type: "fas", icon: "fas fa-fast-forward", content: "\f050" },

	{ type: "fas", icon: "fas fa-faucet", content: "e005" },

	{ type: "fas", icon: "fas fa-fax", content: "\f1ac" },

	{ type: "fas", icon: "fas fa-feather", content: "\f52d" },

	{ type: "fas", icon: "fas fa-feather-alt", content: "\f56b" },

	{ type: "fab", icon: "fab fa-fedex", content: "\f797" },

	{ type: "fab", icon: "fab fa-fedora", content: "\f798" },

	{ type: "fas", icon: "fas fa-female", content: "\f182" },

	{ type: "fas", icon: "fas fa-fighter-jet", content: "\f0fb" },

	{ type: "fab", icon: "fab fa-figma", content: "\f799" },

	{ type: "fas", icon: "fas fa-file", content: "\f15b" },

	{ type: "fas", icon: "fas fa-file-alt", content: "\f15c" },

	{ type: "fas", icon: "fas fa-file-archive", content: "\f1c6" },

	{ type: "fas", icon: "fas fa-file-audio", content: "\f1c7" },

	{ type: "fas", icon: "fas fa-file-code", content: "\f1c9" },

	{ type: "fas", icon: "fas fa-file-contract", content: "\f56c" },

	{ type: "fas", icon: "fas fa-file-csv", content: "\f6dd" },

	{ type: "fas", icon: "fas fa-file-download", content: "\f56d" },

	{ type: "fas", icon: "fas fa-file-excel", content: "\f1c3" },

	{ type: "fas", icon: "fas fa-file-export", content: "\f56e" },

	{ type: "fas", icon: "fas fa-file-image", content: "\f1c5" },

	{ type: "fas", icon: "fas fa-file-import", content: "\f56f" },

	{ type: "fas", icon: "fas fa-file-invoice", content: "\f570" },

	{ type: "fas", icon: "fas fa-file-invoice-dollar", content: "\f571" },

	{ type: "fas", icon: "fas fa-file-medical", content: "\f477" },

	{ type: "fas", icon: "fas fa-file-medical-alt", content: "\f478" },

	{ type: "fas", icon: "fas fa-file-pdf", content: "\f1c1" },

	{ type: "fas", icon: "fas fa-file-powerpoint", content: "\f1c4" },

	{ type: "fas", icon: "fas fa-file-prescription", content: "\f572" },

	{ type: "fas", icon: "fas fa-file-signature", content: "\f573" },

	{ type: "fas", icon: "fas fa-file-upload", content: "\f574" },

	{ type: "fas", icon: "fas fa-file-video", content: "\f1c8" },

	{ type: "fas", icon: "fas fa-file-word", content: "\f1c2" },

	{ type: "fas", icon: "fas fa-fill", content: "\f575" },

	{ type: "fas", icon: "fas fa-fill-drip", content: "\f576" },

	{ type: "fas", icon: "fas fa-film", content: "\f008" },

	{ type: "fas", icon: "fas fa-filter", content: "\f0b0" },

	{ type: "fas", icon: "fas fa-fingerprint", content: "\f577" },

	{ type: "fas", icon: "fas fa-fire", content: "\f06d" },

	{ type: "fas", icon: "fas fa-fire-alt", content: "\f7e4" },

	{ type: "fas", icon: "fas fa-fire-extinguisher", content: "\f134" },

	{ type: "fab", icon: "fab fa-firefox", content: "\f269" },

	{ type: "fab", icon: "fab fa-firefox-browser", content: "e007" },

	{ type: "fas", icon: "fas fa-first-aid", content: "\f479" },

	{ type: "fab", icon: "fab fa-first-order", content: "\f2b0" },

	{ type: "fab", icon: "fab fa-first-order-alt", content: "\f50a" },

	{ type: "fab", icon: "fab fa-firstdraft", content: "\f3a1" },

	{ type: "fas", icon: "fas fa-fish", content: "\f578" },

	{ type: "fas", icon: "fas fa-fist-raised", content: "\f6de" },

	{ type: "fas", icon: "fas fa-flag", content: "\f024" },

	{ type: "fas", icon: "fas fa-flag-checkered", content: "\f11e" },

	{ type: "fas", icon: "fas fa-flag-usa", content: "\f74d" },

	{ type: "fas", icon: "fas fa-flask", content: "\f0c3" },

	{ type: "fab", icon: "fab fa-flickr", content: "\f16e" },

	{ type: "fab", icon: "fab fa-flipboard", content: "\f44d" },

	{ type: "fas", icon: "fas fa-flushed", content: "\f579" },

	{ type: "fab", icon: "fab fa-fly", content: "\f417" },

	{ type: "fas", icon: "fas fa-folder", content: "\f07b" },

	{ type: "fas", icon: "fas fa-folder-minus", content: "\f65d" },

	{ type: "fas", icon: "fas fa-folder-open", content: "\f07c" },

	{ type: "fas", icon: "fas fa-folder-plus", content: "\f65e" },

	{ type: "fas", icon: "fas fa-font", content: "\f031" },

	{ type: "fab", icon: "fab fa-font-awesome", content: "\f2b4" },

	{ type: "fab", icon: "fab fa-font-awesome-alt", content: "\f35c" },

	{ type: "fab", icon: "fab fa-font-awesome-flag", content: "\f425" },

	{ type: "fas", icon: "fas fa-font-awesome-logo-full", content: "\f4e6" },

	{ type: "fab", icon: "fab fa-fonticons", content: "\f280" },

	{ type: "fab", icon: "fab fa-fonticons-fi", content: "\f3a2" },

	{ type: "fas", icon: "fas fa-football-ball", content: "\f44e" },

	{ type: "fab", icon: "fab fa-fort-awesome", content: "\f286" },

	{ type: "fab", icon: "fab fa-fort-awesome-alt", content: "\f3a3" },

	{ type: "fab", icon: "fab fa-forumbee", content: "\f211" },

	{ type: "fas", icon: "fas fa-forward", content: "\f04e" },

	{ type: "fab", icon: "fab fa-foursquare", content: "\f180" },

	{ type: "fab", icon: "fab fa-free-code-camp", content: "\f2c5" },

	{ type: "fab", icon: "fab fa-freebsd", content: "\f3a4" },

	{ type: "fas", icon: "fas fa-frog", content: "\f52e" },

	{ type: "fas", icon: "fas fa-frown", content: "\f119" },

	{ type: "fas", icon: "fas fa-frown-open", content: "\f57a" },

	{ type: "fab", icon: "fab fa-fulcrum", content: "\f50b" },

	{ type: "fas", icon: "fas fa-funnel-dollar", content: "\f662" },

	{ type: "fas", icon: "fas fa-futbol", content: "\f1e3" },

	{ type: "fab", icon: "fab fa-galactic-republic", content: "\f50c" },

	{ type: "fab", icon: "fab fa-galactic-senate", content: "\f50d" },

	{ type: "fas", icon: "fas fa-gamepad", content: "\f11b" },

	{ type: "fas", icon: "fas fa-gas-pump", content: "\f52f" },

	{ type: "fas", icon: "fas fa-gavel", content: "\f0e3" },

	{ type: "fas", icon: "fas fa-gem", content: "\f3a5" },

	{ type: "fas", icon: "fas fa-genderless", content: "\f22d" },

	{ type: "fab", icon: "fab fa-get-pocket", content: "\f265" },

	{ type: "fab", icon: "fab fa-gg", content: "\f260" },

	{ type: "fab", icon: "fab fa-gg-circle", content: "\f261" },

	{ type: "fas", icon: "fas fa-ghost", content: "\f6e2" },

	{ type: "fas", icon: "fas fa-gift", content: "\f06b" },

	{ type: "fas", icon: "fas fa-gifts", content: "\f79c" },

	{ type: "fab", icon: "fab fa-git", content: "\f1d3" },

	{ type: "fab", icon: "fab fa-git-alt", content: "\f841" },

	{ type: "fab", icon: "fab fa-git-square", content: "\f1d2" },

	{ type: "fab", icon: "fab fa-github", content: "\f09b" },

	{ type: "fab", icon: "fab fa-github-alt", content: "\f113" },

	{ type: "fab", icon: "fab fa-github-square", content: "\f092" },

	{ type: "fab", icon: "fab fa-gitkraken", content: "\f3a6" },

	{ type: "fab", icon: "fab fa-gitlab", content: "\f296" },

	{ type: "fab", icon: "fab fa-gitter", content: "\f426" },

	{ type: "fas", icon: "fas fa-glass-cheers", content: "\f79f" },

	{ type: "fas", icon: "fas fa-glass-martini", content: "\f000" },

	{ type: "fas", icon: "fas fa-glass-martini-alt", content: "\f57b" },

	{ type: "fas", icon: "fas fa-glass-whiskey", content: "\f7a0" },

	{ type: "fas", icon: "fas fa-glasses", content: "\f530" },

	{ type: "fab", icon: "fab fa-glide", content: "\f2a5" },

	{ type: "fab", icon: "fab fa-glide-g", content: "\f2a6" },

	{ type: "fas", icon: "fas fa-globe", content: "\f0ac" },

	{ type: "fas", icon: "fas fa-globe-africa", content: "\f57c" },

	{ type: "fas", icon: "fas fa-globe-americas", content: "\f57d" },

	{ type: "fas", icon: "fas fa-globe-asia", content: "\f57e" },

	{ type: "fas", icon: "fas fa-globe-europe", content: "\f7a2" },

	{ type: "fab", icon: "fab fa-gofore", content: "\f3a7" },

	{ type: "fas", icon: "fas fa-golf-ball", content: "\f450" },

	{ type: "fab", icon: "fab fa-goodreads", content: "\f3a8" },

	{ type: "fab", icon: "fab fa-goodreads-g", content: "\f3a9" },

	{ type: "fab", icon: "fab fa-google", content: "\f1a0" },

	{ type: "fab", icon: "fab fa-google-drive", content: "\f3aa" },

	{ type: "fab", icon: "fab fa-google-pay", content: "e079" },

	{ type: "fab", icon: "fab fa-google-play", content: "\f3ab" },

	{ type: "fab", icon: "fab fa-google-plus", content: "\f2b3" },

	{ type: "fab", icon: "fab fa-google-plus-g", content: "\f0d5" },

	{ type: "fab", icon: "fab fa-google-plus-square", content: "\f0d4" },

	{ type: "fab", icon: "fab fa-google-wallet", content: "\f1ee" },

	{ type: "fas", icon: "fas fa-gopuram", content: "\f664" },

	{ type: "fas", icon: "fas fa-graduation-cap", content: "\f19d" },

	{ type: "fab", icon: "fab fa-gratipay", content: "\f184" },

	{ type: "fab", icon: "fab fa-grav", content: "\f2d6" },

	{ type: "fas", icon: "fas fa-greater-than", content: "\f531" },

	{ type: "fas", icon: "fas fa-greater-than-equal", content: "\f532" },

	{ type: "fas", icon: "fas fa-grimace", content: "\f57f" },

	{ type: "fas", icon: "fas fa-grin", content: "\f580" },

	{ type: "fas", icon: "fas fa-grin-alt", content: "\f581" },

	{ type: "fas", icon: "fas fa-grin-beam", content: "\f582" },

	{ type: "fas", icon: "fas fa-grin-beam-sweat", content: "\f583" },

	{ type: "fas", icon: "fas fa-grin-hearts", content: "\f584" },

	{ type: "fas", icon: "fas fa-grin-squint", content: "\f585" },

	{ type: "fas", icon: "fas fa-grin-squint-tears", content: "\f586" },

	{ type: "fas", icon: "fas fa-grin-stars", content: "\f587" },

	{ type: "fas", icon: "fas fa-grin-tears", content: "\f588" },

	{ type: "fas", icon: "fas fa-grin-tongue", content: "\f589" },

	{ type: "fas", icon: "fas fa-grin-tongue-squint", content: "\f58a" },

	{ type: "fas", icon: "fas fa-grin-tongue-wink", content: "\f58b" },

	{ type: "fas", icon: "fas fa-grin-wink", content: "\f58c" },

	{ type: "fas", icon: "fas fa-grip-horizontal", content: "\f58d" },

	{ type: "fas", icon: "fas fa-grip-lines", content: "\f7a4" },

	{ type: "fas", icon: "fas fa-grip-lines-vertical", content: "\f7a5" },

	{ type: "fas", icon: "fas fa-grip-vertical", content: "\f58e" },

	{ type: "fab", icon: "fab fa-gripfire", content: "\f3ac" },

	{ type: "fab", icon: "fab fa-grunt", content: "\f3ad" },

	{ type: "fab", icon: "fab fa-guilded", content: "e07e" },

	{ type: "fas", icon: "fas fa-guitar", content: "\f7a6" },

	{ type: "fab", icon: "fab fa-gulp", content: "\f3ae" },

	{ type: "fas", icon: "fas fa-h-square", content: "\f0fd" },

	{ type: "fab", icon: "fab fa-hacker-news", content: "\f1d4" },

	{ type: "fab", icon: "fab fa-hacker-news-square", content: "\f3af" },

	{ type: "fab", icon: "fab fa-hackerrank", content: "\f5f7" },

	{ type: "fas", icon: "fas fa-hamburger", content: "\f805" },

	{ type: "fas", icon: "fas fa-hammer", content: "\f6e3" },

	{ type: "fas", icon: "fas fa-hamsa", content: "\f665" },

	{ type: "fas", icon: "fas fa-hand-holding", content: "\f4bd" },

	{ type: "fas", icon: "fas fa-hand-holding-heart", content: "\f4be" },

	{ type: "fas", icon: "fas fa-hand-holding-medical", content: "e05c" },

	{ type: "fas", icon: "fas fa-hand-holding-usd", content: "\f4c0" },

	{ type: "fas", icon: "fas fa-hand-holding-water", content: "\f4c1" },

	{ type: "fas", icon: "fas fa-hand-lizard", content: "\f258" },

	{ type: "fas", icon: "fas fa-hand-middle-finger", content: "\f806" },

	{ type: "fas", icon: "fas fa-hand-paper", content: "\f256" },

	{ type: "fas", icon: "fas fa-hand-peace", content: "\f25b" },

	{ type: "fas", icon: "fas fa-hand-point-down", content: "\f0a7" },

	{ type: "fas", icon: "fas fa-hand-point-left", content: "\f0a5" },

	{ type: "fas", icon: "fas fa-hand-point-right", content: "\f0a4" },

	{ type: "fas", icon: "fas fa-hand-point-up", content: "\f0a6" },

	{ type: "fas", icon: "fas fa-hand-pointer", content: "\f25a" },

	{ type: "fas", icon: "fas fa-hand-rock", content: "\f255" },

	{ type: "fas", icon: "fas fa-hand-scissors", content: "\f257" },

	{ type: "fas", icon: "fas fa-hand-sparkles", content: "e05d" },

	{ type: "fas", icon: "fas fa-hand-spock", content: "\f259" },

	{ type: "fas", icon: "fas fa-hands", content: "\f4c2" },

	{ type: "fas", icon: "fas fa-hands-helping", content: "\f4c4" },

	{ type: "fas", icon: "fas fa-hands-wash", content: "e05e" },

	{ type: "fas", icon: "fas fa-handshake", content: "\f2b5" },

	{ type: "fas", icon: "fas fa-handshake-alt-slash", content: "e05f" },

	{ type: "fas", icon: "fas fa-handshake-slash", content: "e060" },

	{ type: "fas", icon: "fas fa-hanukiah", content: "\f6e6" },

	{ type: "fas", icon: "fas fa-hard-hat", content: "\f807" },

	{ type: "fas", icon: "fas fa-hashtag", content: "\f292" },

	{ type: "fas", icon: "fas fa-hat-cowboy", content: "\f8c0" },

	{ type: "fas", icon: "fas fa-hat-cowboy-side", content: "\f8c1" },

	{ type: "fas", icon: "fas fa-hat-wizard", content: "\f6e8" },

	{ type: "fas", icon: "fas fa-hdd", content: "\f0a0" },

	{ type: "fas", icon: "fas fa-head-side-cough", content: "e061" },

	{ type: "fas", icon: "fas fa-head-side-cough-slash", content: "e062" },

	{ type: "fas", icon: "fas fa-head-side-mask", content: "e063" },

	{ type: "fas", icon: "fas fa-head-side-virus", content: "e064" },

	{ type: "fas", icon: "fas fa-heading", content: "\f1dc" },

	{ type: "fas", icon: "fas fa-headphones", content: "\f025" },

	{ type: "fas", icon: "fas fa-headphones-alt", content: "\f58f" },

	{ type: "fas", icon: "fas fa-headset", content: "\f590" },

	{ type: "fas", icon: "fas fa-heart", content: "\f004" },

	{ type: "fas", icon: "fas fa-heart-broken", content: "\f7a9" },

	{ type: "fas", icon: "fas fa-heartbeat", content: "\f21e" },

	{ type: "fas", icon: "fas fa-helicopter", content: "\f533" },

	{ type: "fas", icon: "fas fa-highlighter", content: "\f591" },

	{ type: "fas", icon: "fas fa-hiking", content: "\f6ec" },

	{ type: "fas", icon: "fas fa-hippo", content: "\f6ed" },

	{ type: "fab", icon: "fab fa-hips", content: "\f452" },

	{ type: "fab", icon: "fab fa-hire-a-helper", content: "\f3b0" },

	{ type: "fas", icon: "fas fa-history", content: "\f1da" },

	{ type: "fab", icon: "fab fa-hive", content: "e07f" },

	{ type: "fas", icon: "fas fa-hockey-puck", content: "\f453" },

	{ type: "fas", icon: "fas fa-holly-berry", content: "\f7aa" },

	{ type: "fas", icon: "fas fa-home", content: "\f015" },

	{ type: "fab", icon: "fab fa-hooli", content: "\f427" },

	{ type: "fab", icon: "fab fa-hornbill", content: "\f592" },

	{ type: "fas", icon: "fas fa-horse", content: "\f6f0" },

	{ type: "fas", icon: "fas fa-horse-head", content: "\f7ab" },

	{ type: "fas", icon: "fas fa-hospital", content: "\f0f8" },

	{ type: "fas", icon: "fas fa-hospital-alt", content: "\f47d" },

	{ type: "fas", icon: "fas fa-hospital-symbol", content: "\f47e" },

	{ type: "fas", icon: "fas fa-hospital-user", content: "\f80d" },

	{ type: "fas", icon: "fas fa-hot-tub", content: "\f593" },

	{ type: "fas", icon: "fas fa-hotdog", content: "\f80f" },

	{ type: "fas", icon: "fas fa-hotel", content: "\f594" },

	{ type: "fab", icon: "fab fa-hotjar", content: "\f3b1" },

	{ type: "fas", icon: "fas fa-hourglass", content: "\f254" },

	{ type: "fas", icon: "fas fa-hourglass-end", content: "\f253" },

	{ type: "fas", icon: "fas fa-hourglass-half", content: "\f252" },

	{ type: "fas", icon: "fas fa-hourglass-start", content: "\f251" },

	{ type: "fas", icon: "fas fa-house-damage", content: "\f6f1" },

	{ type: "fas", icon: "fas fa-house-user", content: "e065" },

	{ type: "fab", icon: "fab fa-houzz", content: "\f27c" },

	{ type: "fas", icon: "fas fa-hryvnia", content: "\f6f2" },

	{ type: "fab", icon: "fab fa-html5", content: "\f13b" },

	{ type: "fab", icon: "fab fa-hubspot", content: "\f3b2" },

	{ type: "fas", icon: "fas fa-i-cursor", content: "\f246" },

	{ type: "fas", icon: "fas fa-ice-cream", content: "\f810" },

	{ type: "fas", icon: "fas fa-icicles", content: "\f7ad" },

	{ type: "fas", icon: "fas fa-icons", content: "\f86d" },

	{ type: "fas", icon: "fas fa-id-badge", content: "\f2c1" },

	{ type: "fas", icon: "fas fa-id-card", content: "\f2c2" },

	{ type: "fas", icon: "fas fa-id-card-alt", content: "\f47f" },

	{ type: "fab", icon: "fab fa-ideal", content: "e013" },

	{ type: "fas", icon: "fas fa-igloo", content: "\f7ae" },

	{ type: "fas", icon: "fas fa-image", content: "\f03e" },

	{ type: "fas", icon: "fas fa-images", content: "\f302" },

	{ type: "fab", icon: "fab fa-imdb", content: "\f2d8" },

	{ type: "fas", icon: "fas fa-inbox", content: "\f01c" },

	{ type: "fas", icon: "fas fa-indent", content: "\f03c" },

	{ type: "fas", icon: "fas fa-industry", content: "\f275" },

	{ type: "fas", icon: "fas fa-infinity", content: "\f534" },

	{ type: "fas", icon: "fas fa-info", content: "\f129" },

	{ type: "fas", icon: "fas fa-info-circle", content: "\f05a" },

	{ type: "fab", icon: "fab fa-innosoft", content: "e080" },

	{ type: "fab", icon: "fab fa-instagram", content: "\f16d" },

	{ type: "fab", icon: "fab fa-instagram-square", content: "e055" },

	{ type: "fab", icon: "fab fa-instalod", content: "e081" },

	{ type: "fab", icon: "fab fa-intercom", content: "\f7af" },

	{ type: "fab", icon: "fab fa-internet-explorer", content: "\f26b" },

	{ type: "fab", icon: "fab fa-invision", content: "\f7b0" },

	{ type: "fab", icon: "fab fa-ioxhost", content: "\f208" },

	{ type: "fas", icon: "fas fa-italic", content: "\f033" },

	{ type: "fab", icon: "fab fa-itch-io", content: "\f83a" },

	{ type: "fab", icon: "fab fa-itunes", content: "\f3b4" },

	{ type: "fab", icon: "fab fa-itunes-note", content: "\f3b5" },

	{ type: "fab", icon: "fab fa-java", content: "\f4e4" },

	{ type: "fas", icon: "fas fa-jedi", content: "\f669" },

	{ type: "fab", icon: "fab fa-jedi-order", content: "\f50e" },

	{ type: "fab", icon: "fab fa-jenkins", content: "\f3b6" },

	{ type: "fab", icon: "fab fa-jira", content: "\f7b1" },

	{ type: "fab", icon: "fab fa-joget", content: "\f3b7" },

	{ type: "fas", icon: "fas fa-joint", content: "\f595" },

	{ type: "fab", icon: "fab fa-joomla", content: "\f1aa" },

	{ type: "fas", icon: "fas fa-journal-whills", content: "\f66a" },

	{ type: "fab", icon: "fab fa-js", content: "\f3b8" },

	{ type: "fab", icon: "fab fa-js-square", content: "\f3b9" },

	{ type: "fab", icon: "fab fa-jsfiddle", content: "\f1cc" },

	{ type: "fas", icon: "fas fa-kaaba", content: "\f66b" },

	{ type: "fab", icon: "fab fa-kaggle", content: "\f5fa" },

	{ type: "fas", icon: "fas fa-key", content: "\f084" },

	{ type: "fab", icon: "fab fa-keybase", content: "\f4f5" },

	{ type: "fas", icon: "fas fa-keyboard", content: "\f11c" },

	{ type: "fab", icon: "fab fa-keycdn", content: "\f3ba" },

	{ type: "fas", icon: "fas fa-khanda", content: "\f66d" },

	{ type: "fab", icon: "fab fa-kickstarter", content: "\f3bb" },

	{ type: "fab", icon: "fab fa-kickstarter-k", content: "\f3bc" },

	{ type: "fas", icon: "fas fa-kiss", content: "\f596" },

	{ type: "fas", icon: "fas fa-kiss-beam", content: "\f597" },

	{ type: "fas", icon: "fas fa-kiss-wink-heart", content: "\f598" },

	{ type: "fas", icon: "fas fa-kiwi-bird", content: "\f535" },

	{ type: "fab", icon: "fab fa-korvue", content: "\f42f" },

	{ type: "fas", icon: "fas fa-landmark", content: "\f66f" },

	{ type: "fas", icon: "fas fa-language", content: "\f1ab" },

	{ type: "fas", icon: "fas fa-laptop", content: "\f109" },

	{ type: "fas", icon: "fas fa-laptop-code", content: "\f5fc" },

	{ type: "fas", icon: "fas fa-laptop-house", content: "e066" },

	{ type: "fas", icon: "fas fa-laptop-medical", content: "\f812" },

	{ type: "fab", icon: "fab fa-laravel", content: "\f3bd" },

	{ type: "fab", icon: "fab fa-lastfm", content: "\f202" },

	{ type: "fab", icon: "fab fa-lastfm-square", content: "\f203" },

	{ type: "fas", icon: "fas fa-laugh", content: "\f599" },

	{ type: "fas", icon: "fas fa-laugh-beam", content: "\f59a" },

	{ type: "fas", icon: "fas fa-laugh-squint", content: "\f59b" },

	{ type: "fas", icon: "fas fa-laugh-wink", content: "\f59c" },

	{ type: "fas", icon: "fas fa-layer-group", content: "\f5fd" },

	{ type: "fas", icon: "fas fa-leaf", content: "\f06c" },

	{ type: "fab", icon: "fab fa-leanpub", content: "\f212" },

	{ type: "fas", icon: "fas fa-lemon", content: "\f094" },

	{ type: "fab", icon: "fab fa-less", content: "\f41d" },

	{ type: "fas", icon: "fas fa-less-than", content: "\f536" },

	{ type: "fas", icon: "fas fa-less-than-equal", content: "\f537" },

	{ type: "fas", icon: "fas fa-level-down-alt", content: "\f3be" },

	{ type: "fas", icon: "fas fa-level-up-alt", content: "\f3bf" },

	{ type: "fas", icon: "fas fa-life-ring", content: "\f1cd" },

	{ type: "fas", icon: "fas fa-lightbulb", content: "\f0eb" },

	{ type: "fab", icon: "fab fa-line", content: "\f3c0" },

	{ type: "fas", icon: "fas fa-link", content: "\f0c1" },

	{ type: "fab", icon: "fab fa-linkedin", content: "\f08c" },

	{ type: "fab", icon: "fab fa-linkedin-in", content: "\f0e1" },

	{ type: "fab", icon: "fab fa-linode", content: "\f2b8" },

	{ type: "fab", icon: "fab fa-linux", content: "\f17c" },

	{ type: "fas", icon: "fas fa-lira-sign", content: "\f195" },

	{ type: "fas", icon: "fas fa-list", content: "\f03a" },

	{ type: "fas", icon: "fas fa-list-alt", content: "\f022" },

	{ type: "fas", icon: "fas fa-list-ol", content: "\f0cb" },

	{ type: "fas", icon: "fas fa-list-ul", content: "\f0ca" },

	{ type: "fas", icon: "fas fa-location-arrow", content: "\f124" },

	{ type: "fas", icon: "fas fa-lock", content: "\f023" },

	{ type: "fas", icon: "fas fa-lock-open", content: "\f3c1" },

	{ type: "fas", icon: "fas fa-long-arrow-alt-down", content: "\f309" },

	{ type: "fas", icon: "fas fa-long-arrow-alt-left", content: "\f30a" },

	{ type: "fas", icon: "fas fa-long-arrow-alt-right", content: "\f30b" },

	{ type: "fas", icon: "fas fa-long-arrow-alt-up", content: "\f30c" },

	{ type: "fas", icon: "fas fa-low-vision", content: "\f2a8" },

	{ type: "fas", icon: "fas fa-luggage-cart", content: "\f59d" },

	{ type: "fas", icon: "fas fa-lungs", content: "\f604" },

	{ type: "fas", icon: "fas fa-lungs-virus", content: "e067" },

	{ type: "fab", icon: "fab fa-lyft", content: "\f3c3" },

	{ type: "fab", icon: "fab fa-magento", content: "\f3c4" },

	{ type: "fas", icon: "fas fa-magic", content: "\f0d0" },

	{ type: "fas", icon: "fas fa-magnet", content: "\f076" },

	{ type: "fas", icon: "fas fa-mail-bulk", content: "\f674" },

	{ type: "fab", icon: "fab fa-mailchimp", content: "\f59e" },

	{ type: "fas", icon: "fas fa-male", content: "\f183" },

	{ type: "fab", icon: "fab fa-mandalorian", content: "\f50f" },

	{ type: "fas", icon: "fas fa-map", content: "\f279" },

	{ type: "fas", icon: "fas fa-map-marked", content: "\f59f" },

	{ type: "fas", icon: "fas fa-map-marked-alt", content: "\f5a0" },

	{ type: "fas", icon: "fas fa-map-marker", content: "\f041" },

	{ type: "fas", icon: "fas fa-map-marker-alt", content: "\f3c5" },

	{ type: "fas", icon: "fas fa-map-pin", content: "\f276" },

	{ type: "fas", icon: "fas fa-map-signs", content: "\f277" },

	{ type: "fab", icon: "fab fa-markdown", content: "\f60f" },

	{ type: "fas", icon: "fas fa-marker", content: "\f5a1" },

	{ type: "fas", icon: "fas fa-mars", content: "\f222" },

	{ type: "fas", icon: "fas fa-mars-double", content: "\f227" },

	{ type: "fas", icon: "fas fa-mars-stroke", content: "\f229" },

	{ type: "fas", icon: "fas fa-mars-stroke-h", content: "\f22b" },

	{ type: "fas", icon: "fas fa-mars-stroke-v", content: "\f22a" },

	{ type: "fas", icon: "fas fa-mask", content: "\f6fa" },

	{ type: "fab", icon: "fab fa-mastodon", content: "\f4f6" },

	{ type: "fab", icon: "fab fa-maxcdn", content: "\f136" },

	{ type: "fab", icon: "fab fa-mdb", content: "\f8ca" },

	{ type: "fas", icon: "fas fa-medal", content: "\f5a2" },

	{ type: "fab", icon: "fab fa-medapps", content: "\f3c6" },

	{ type: "fab", icon: "fab fa-medium", content: "\f23a" },

	{ type: "fab", icon: "fab fa-medium-m", content: "\f3c7" },

	{ type: "fas", icon: "fas fa-medkit", content: "\f0fa" },

	{ type: "fab", icon: "fab fa-medrt", content: "\f3c8" },

	{ type: "fab", icon: "fab fa-meetup", content: "\f2e0" },

	{ type: "fab", icon: "fab fa-megaport", content: "\f5a3" },

	{ type: "fas", icon: "fas fa-meh", content: "\f11a" },

	{ type: "fas", icon: "fas fa-meh-blank", content: "\f5a4" },

	{ type: "fas", icon: "fas fa-meh-rolling-eyes", content: "\f5a5" },

	{ type: "fas", icon: "fas fa-memory", content: "\f538" },

	{ type: "fab", icon: "fab fa-mendeley", content: "\f7b3" },

	{ type: "fas", icon: "fas fa-menorah", content: "\f676" },

	{ type: "fas", icon: "fas fa-mercury", content: "\f223" },

	{ type: "fas", icon: "fas fa-meteor", content: "\f753" },

	{ type: "fab", icon: "fab fa-microblog", content: "e01a" },

	{ type: "fas", icon: "fas fa-microchip", content: "\f2db" },

	{ type: "fas", icon: "fas fa-microphone", content: "\f130" },

	{ type: "fas", icon: "fas fa-microphone-alt", content: "\f3c9" },

	{ type: "fas", icon: "fas fa-microphone-alt-slash", content: "\f539" },

	{ type: "fas", icon: "fas fa-microphone-slash", content: "\f131" },

	{ type: "fas", icon: "fas fa-microscope", content: "\f610" },

	{ type: "fab", icon: "fab fa-microsoft", content: "\f3ca" },

	{ type: "fas", icon: "fas fa-minus", content: "\f068" },

	{ type: "fas", icon: "fas fa-minus-circle", content: "\f056" },

	{ type: "fas", icon: "fas fa-minus-square", content: "\f146" },

	{ type: "fas", icon: "fas fa-mitten", content: "\f7b5" },

	{ type: "fab", icon: "fab fa-mix", content: "\f3cb" },

	{ type: "fab", icon: "fab fa-mixcloud", content: "\f289" },

	{ type: "fab", icon: "fab fa-mixer", content: "e056" },

	{ type: "fab", icon: "fab fa-mizuni", content: "\f3cc" },

	{ type: "fas", icon: "fas fa-mobile", content: "\f10b" },

	{ type: "fas", icon: "fas fa-mobile-alt", content: "\f3cd" },

	{ type: "fab", icon: "fab fa-modx", content: "\f285" },

	{ type: "fab", icon: "fab fa-monero", content: "\f3d0" },

	{ type: "fas", icon: "fas fa-money-bill", content: "\f0d6" },

	{ type: "fas", icon: "fas fa-money-bill-alt", content: "\f3d1" },

	{ type: "fas", icon: "fas fa-money-bill-wave", content: "\f53a" },

	{ type: "fas", icon: "fas fa-money-bill-wave-alt", content: "\f53b" },

	{ type: "fas", icon: "fas fa-money-check", content: "\f53c" },

	{ type: "fas", icon: "fas fa-money-check-alt", content: "\f53d" },

	{ type: "fas", icon: "fas fa-monument", content: "\f5a6" },

	{ type: "fas", icon: "fas fa-moon", content: "\f186" },

	{ type: "fas", icon: "fas fa-mortar-pestle", content: "\f5a7" },

	{ type: "fas", icon: "fas fa-mosque", content: "\f678" },

	{ type: "fas", icon: "fas fa-motorcycle", content: "\f21c" },

	{ type: "fas", icon: "fas fa-mountain", content: "\f6fc" },

	{ type: "fas", icon: "fas fa-mouse", content: "\f8cc" },

	{ type: "fas", icon: "fas fa-mouse-pointer", content: "\f245" },

	{ type: "fas", icon: "fas fa-mug-hot", content: "\f7b6" },

	{ type: "fas", icon: "fas fa-music", content: "\f001" },

	{ type: "fab", icon: "fab fa-napster", content: "\f3d2" },

	{ type: "fab", icon: "fab fa-neos", content: "\f612" },

	{ type: "fas", icon: "fas fa-network-wired", content: "\f6ff" },

	{ type: "fas", icon: "fas fa-neuter", content: "\f22c" },

	{ type: "fas", icon: "fas fa-newspaper", content: "\f1ea" },

	{ type: "fab", icon: "fab fa-nimblr", content: "\f5a8" },

	{ type: "fab", icon: "fab fa-node", content: "\f419" },

	{ type: "fab", icon: "fab fa-node-js", content: "\f3d3" },

	{ type: "fas", icon: "fas fa-not-equal", content: "\f53e" },

	{ type: "fas", icon: "fas fa-notes-medical", content: "\f481" },

	{ type: "fab", icon: "fab fa-npm", content: "\f3d4" },

	{ type: "fab", icon: "fab fa-ns8", content: "\f3d5" },

	{ type: "fab", icon: "fab fa-nutritionix", content: "\f3d6" },

	{ type: "fas", icon: "fas fa-object-group", content: "\f247" },

	{ type: "fas", icon: "fas fa-object-ungroup", content: "\f248" },

	{ type: "fab", icon: "fab fa-octopus-deploy", content: "e082" },

	{ type: "fab", icon: "fab fa-odnoklassniki", content: "\f263" },

	{ type: "fab", icon: "fab fa-odnoklassniki-square", content: "\f264" },

	{ type: "fas", icon: "fas fa-oil-can", content: "\f613" },

	{ type: "fab", icon: "fab fa-old-republic", content: "\f510" },

	{ type: "fas", icon: "fas fa-om", content: "\f679" },

	{ type: "fab", icon: "fab fa-opencart", content: "\f23d" },

	{ type: "fab", icon: "fab fa-openid", content: "\f19b" },

	{ type: "fab", icon: "fab fa-opera", content: "\f26a" },

	{ type: "fab", icon: "fab fa-optin-monster", content: "\f23c" },

	{ type: "fab", icon: "fab fa-orcid", content: "\f8d2" },

	{ type: "fab", icon: "fab fa-osi", content: "\f41a" },

	{ type: "fas", icon: "fas fa-otter", content: "\f700" },

	{ type: "fas", icon: "fas fa-outdent", content: "\f03b" },

	{ type: "fab", icon: "fab fa-page4", content: "\f3d7" },

	{ type: "fab", icon: "fab fa-pagelines", content: "\f18c" },

	{ type: "fas", icon: "fas fa-pager", content: "\f815" },

	{ type: "fas", icon: "fas fa-paint-brush", content: "\f1fc" },

	{ type: "fas", icon: "fas fa-paint-roller", content: "\f5aa" },

	{ type: "fas", icon: "fas fa-palette", content: "\f53f" },

	{ type: "fab", icon: "fab fa-palfed", content: "\f3d8" },

	{ type: "fas", icon: "fas fa-pallet", content: "\f482" },

	{ type: "fas", icon: "fas fa-paper-plane", content: "\f1d8" },

	{ type: "fas", icon: "fas fa-paperclip", content: "\f0c6" },

	{ type: "fas", icon: "fas fa-parachute-box", content: "\f4cd" },

	{ type: "fas", icon: "fas fa-paragraph", content: "\f1dd" },

	{ type: "fas", icon: "fas fa-parking", content: "\f540" },

	{ type: "fas", icon: "fas fa-passport", content: "\f5ab" },

	{ type: "fas", icon: "fas fa-pastafarianism", content: "\f67b" },

	{ type: "fas", icon: "fas fa-paste", content: "\f0ea" },

	{ type: "fab", icon: "fab fa-patreon", content: "\f3d9" },

	{ type: "fas", icon: "fas fa-pause", content: "\f04c" },

	{ type: "fas", icon: "fas fa-pause-circle", content: "\f28b" },

	{ type: "fas", icon: "fas fa-paw", content: "\f1b0" },

	{ type: "fab", icon: "fab fa-paypal", content: "\f1ed" },

	{ type: "fas", icon: "fas fa-peace", content: "\f67c" },

	{ type: "fas", icon: "fas fa-pen", content: "\f304" },

	{ type: "fas", icon: "fas fa-pen-alt", content: "\f305" },

	{ type: "fas", icon: "fas fa-pen-fancy", content: "\f5ac" },

	{ type: "fas", icon: "fas fa-pen-nib", content: "\f5ad" },

	{ type: "fas", icon: "fas fa-pen-square", content: "\f14b" },

	{ type: "fas", icon: "fas fa-pencil-alt", content: "\f303" },

	{ type: "fas", icon: "fas fa-pencil-ruler", content: "\f5ae" },

	{ type: "fab", icon: "fab fa-penny-arcade", content: "\f704" },

	{ type: "fas", icon: "fas fa-people-arrows", content: "e068" },

	{ type: "fas", icon: "fas fa-people-carry", content: "\f4ce" },

	{ type: "fas", icon: "fas fa-pepper-hot", content: "\f816" },

	{ type: "fab", icon: "fab fa-perbyte", content: "e083" },

	{ type: "fas", icon: "fas fa-percent", content: "\f295" },

	{ type: "fas", icon: "fas fa-percentage", content: "\f541" },

	{ type: "fab", icon: "fab fa-periscope", content: "\f3da" },

	{ type: "fas", icon: "fas fa-person-booth", content: "\f756" },

	{ type: "fab", icon: "fab fa-phabricator", content: "\f3db" },

	{ type: "fab", icon: "fab fa-phoenix-framework", content: "\f3dc" },

	{ type: "fab", icon: "fab fa-phoenix-squadron", content: "\f511" },

	{ type: "fas", icon: "fas fa-phone", content: "\f095" },

	{ type: "fas", icon: "fas fa-phone-alt", content: "\f879" },

	{ type: "fas", icon: "fas fa-phone-slash", content: "\f3dd" },

	{ type: "fas", icon: "fas fa-phone-square", content: "\f098" },

	{ type: "fas", icon: "fas fa-phone-square-alt", content: "\f87b" },

	{ type: "fas", icon: "fas fa-phone-volume", content: "\f2a0" },

	{ type: "fas", icon: "fas fa-photo-video", content: "\f87c" },

	{ type: "fab", icon: "fab fa-php", content: "\f457" },

	{ type: "fab", icon: "fab fa-pied-piper", content: "\f2ae" },

	{ type: "fab", icon: "fab fa-pied-piper-alt", content: "\f1a8" },

	{ type: "fab", icon: "fab fa-pied-piper-hat", content: "\f4e5" },

	{ type: "fab", icon: "fab fa-pied-piper-pp", content: "\f1a7" },

	{ type: "fab", icon: "fab fa-pied-piper-square", content: "e01e" },

	{ type: "fas", icon: "fas fa-piggy-bank", content: "\f4d3" },

	{ type: "fas", icon: "fas fa-pills", content: "\f484" },

	{ type: "fab", icon: "fab fa-pinterest", content: "\f0d2" },

	{ type: "fab", icon: "fab fa-pinterest-p", content: "\f231" },

	{ type: "fab", icon: "fab fa-pinterest-square", content: "\f0d3" },

	{ type: "fas", icon: "fas fa-pizza-slice", content: "\f818" },

	{ type: "fas", icon: "fas fa-place-of-worship", content: "\f67f" },

	{ type: "fas", icon: "fas fa-plane", content: "\f072" },

	{ type: "fas", icon: "fas fa-plane-arrival", content: "\f5af" },

	{ type: "fas", icon: "fas fa-plane-departure", content: "\f5b0" },

	{ type: "fas", icon: "fas fa-plane-slash", content: "e069" },

	{ type: "fas", icon: "fas fa-play", content: "\f04b" },

	{ type: "fas", icon: "fas fa-play-circle", content: "\f144" },

	{ type: "fab", icon: "fab fa-playstation", content: "\f3df" },

	{ type: "fas", icon: "fas fa-plug", content: "\f1e6" },

	{ type: "fas", icon: "fas fa-plus", content: "\f067" },

	{ type: "fas", icon: "fas fa-plus-circle", content: "\f055" },

	{ type: "fas", icon: "fas fa-plus-square", content: "\f0fe" },

	{ type: "fas", icon: "fas fa-podcast", content: "\f2ce" },

	{ type: "fas", icon: "fas fa-poll", content: "\f681" },

	{ type: "fas", icon: "fas fa-poll-h", content: "\f682" },

	{ type: "fas", icon: "fas fa-poo", content: "\f2fe" },

	{ type: "fas", icon: "fas fa-poo-storm", content: "\f75a" },

	{ type: "fas", icon: "fas fa-poop", content: "\f619" },

	{ type: "fas", icon: "fas fa-portrait", content: "\f3e0" },

	{ type: "fas", icon: "fas fa-pound-sign", content: "\f154" },

	{ type: "fas", icon: "fas fa-power-off", content: "\f011" },

	{ type: "fas", icon: "fas fa-pray", content: "\f683" },

	{ type: "fas", icon: "fas fa-praying-hands", content: "\f684" },

	{ type: "fas", icon: "fas fa-prescription", content: "\f5b1" },

	{ type: "fas", icon: "fas fa-prescription-bottle", content: "\f485" },

	{ type: "fas", icon: "fas fa-prescription-bottle-alt", content: "\f486" },

	{ type: "fas", icon: "fas fa-print", content: "\f02f" },

	{ type: "fas", icon: "fas fa-procedures", content: "\f487" },

	{ type: "fab", icon: "fab fa-product-hunt", content: "\f288" },

	{ type: "fas", icon: "fas fa-project-diagram", content: "\f542" },

	{ type: "fas", icon: "fas fa-pump-medical", content: "e06a" },

	{ type: "fas", icon: "fas fa-pump-soap", content: "e06b" },

	{ type: "fab", icon: "fab fa-pushed", content: "\f3e1" },

	{ type: "fas", icon: "fas fa-puzzle-piece", content: "\f12e" },

	{ type: "fab", icon: "fab fa-python", content: "\f3e2" },

	{ type: "fab", icon: "fab fa-qq", content: "\f1d6" },

	{ type: "fas", icon: "fas fa-qrcode", content: "\f029" },

	{ type: "fas", icon: "fas fa-question", content: "\f128" },

	{ type: "fas", icon: "fas fa-question-circle", content: "\f059" },

	{ type: "fas", icon: "fas fa-quidditch", content: "\f458" },

	{ type: "fab", icon: "fab fa-quinscape", content: "\f459" },

	{ type: "fab", icon: "fab fa-quora", content: "\f2c4" },

	{ type: "fas", icon: "fas fa-quote-left", content: "\f10d" },

	{ type: "fas", icon: "fas fa-quote-right", content: "\f10e" },

	{ type: "fas", icon: "fas fa-quran", content: "\f687" },

	{ type: "fab", icon: "fab fa-r-project", content: "\f4f7" },

	{ type: "fas", icon: "fas fa-radiation", content: "\f7b9" },

	{ type: "fas", icon: "fas fa-radiation-alt", content: "\f7ba" },

	{ type: "fas", icon: "fas fa-rainbow", content: "\f75b" },

	{ type: "fas", icon: "fas fa-random", content: "\f074" },

	{ type: "fab", icon: "fab fa-raspberry-pi", content: "\f7bb" },

	{ type: "fab", icon: "fab fa-ravelry", content: "\f2d9" },

	{ type: "fab", icon: "fab fa-react", content: "\f41b" },

	{ type: "fab", icon: "fab fa-reacteurope", content: "\f75d" },

	{ type: "fab", icon: "fab fa-readme", content: "\f4d5" },

	{ type: "fab", icon: "fab fa-rebel", content: "\f1d0" },

	{ type: "fas", icon: "fas fa-receipt", content: "\f543" },

	{ type: "fas", icon: "fas fa-record-vinyl", content: "\f8d9" },

	{ type: "fas", icon: "fas fa-recycle", content: "\f1b8" },

	{ type: "fab", icon: "fab fa-red-river", content: "\f3e3" },

	{ type: "fab", icon: "fab fa-reddit", content: "\f1a1" },

	{ type: "fab", icon: "fab fa-reddit-alien", content: "\f281" },

	{ type: "fab", icon: "fab fa-reddit-square", content: "\f1a2" },

	{ type: "fab", icon: "fab fa-redhat", content: "\f7bc" },

	{ type: "fas", icon: "fas fa-redo", content: "\f01e" },

	{ type: "fas", icon: "fas fa-redo-alt", content: "\f2f9" },

	{ type: "fas", icon: "fas fa-registered", content: "\f25d" },

	{ type: "fas", icon: "fas fa-remove-format", content: "\f87d" },

	{ type: "fab", icon: "fab fa-renren", content: "\f18b" },

	{ type: "fas", icon: "fas fa-reply", content: "\f3e5" },

	{ type: "fas", icon: "fas fa-reply-all", content: "\f122" },

	{ type: "fab", icon: "fab fa-replyd", content: "\f3e6" },

	{ type: "fas", icon: "fas fa-republican", content: "\f75e" },

	{ type: "fab", icon: "fab fa-researchgate", content: "\f4f8" },

	{ type: "fab", icon: "fab fa-resolving", content: "\f3e7" },

	{ type: "fas", icon: "fas fa-restroom", content: "\f7bd" },

	{ type: "fas", icon: "fas fa-retweet", content: "\f079" },

	{ type: "fab", icon: "fab fa-rev", content: "\f5b2" },

	{ type: "fas", icon: "fas fa-ribbon", content: "\f4d6" },

	{ type: "fas", icon: "fas fa-ring", content: "\f70b" },

	{ type: "fas", icon: "fas fa-road", content: "\f018" },

	{ type: "fas", icon: "fas fa-robot", content: "\f544" },

	{ type: "fas", icon: "fas fa-rocket", content: "\f135" },

	{ type: "fab", icon: "fab fa-rocketchat", content: "\f3e8" },

	{ type: "fab", icon: "fab fa-rockrms", content: "\f3e9" },

	{ type: "fas", icon: "fas fa-route", content: "\f4d7" },

	{ type: "fas", icon: "fas fa-rss", content: "\f09e" },

	{ type: "fas", icon: "fas fa-rss-square", content: "\f143" },

	{ type: "fas", icon: "fas fa-ruble-sign", content: "\f158" },

	{ type: "fas", icon: "fas fa-ruler", content: "\f545" },

	{ type: "fas", icon: "fas fa-ruler-combined", content: "\f546" },

	{ type: "fas", icon: "fas fa-ruler-horizontal", content: "\f547" },

	{ type: "fas", icon: "fas fa-ruler-vertical", content: "\f548" },

	{ type: "fas", icon: "fas fa-running", content: "\f70c" },

	{ type: "fas", icon: "fas fa-rupee-sign", content: "\f156" },

	{ type: "fab", icon: "fab fa-rust", content: "e07a" },

	{ type: "fas", icon: "fas fa-sad-cry", content: "\f5b3" },

	{ type: "fas", icon: "fas fa-sad-tear", content: "\f5b4" },

	{ type: "fab", icon: "fab fa-safari", content: "\f267" },

	{ type: "fab", icon: "fab fa-salesforce", content: "\f83b" },

	{ type: "fab", icon: "fab fa-sass", content: "\f41e" },

	{ type: "fas", icon: "fas fa-satellite", content: "\f7bf" },

	{ type: "fas", icon: "fas fa-satellite-dish", content: "\f7c0" },

	{ type: "fas", icon: "fas fa-save", content: "\f0c7" },

	{ type: "fab", icon: "fab fa-schlix", content: "\f3ea" },

	{ type: "fas", icon: "fas fa-school", content: "\f549" },

	{ type: "fas", icon: "fas fa-screwdriver", content: "\f54a" },

	{ type: "fab", icon: "fab fa-scribd", content: "\f28a" },

	{ type: "fas", icon: "fas fa-scroll", content: "\f70e" },

	{ type: "fas", icon: "fas fa-sd-card", content: "\f7c2" },

	{ type: "fas", icon: "fas fa-search", content: "\f002" },

	{ type: "fas", icon: "fas fa-search-dollar", content: "\f688" },

	{ type: "fas", icon: "fas fa-search-location", content: "\f689" },

	{ type: "fas", icon: "fas fa-search-minus", content: "\f010" },

	{ type: "fas", icon: "fas fa-search-plus", content: "\f00e" },

	{ type: "fab", icon: "fab fa-searchengin", content: "\f3eb" },

	{ type: "fas", icon: "fas fa-seedling", content: "\f4d8" },

	{ type: "fab", icon: "fab fa-sellcast", content: "\f2da" },

	{ type: "fab", icon: "fab fa-sellsy", content: "\f213" },

	{ type: "fas", icon: "fas fa-server", content: "\f233" },

	{ type: "fab", icon: "fab fa-servicestack", content: "\f3ec" },

	{ type: "fas", icon: "fas fa-shapes", content: "\f61f" },

	{ type: "fas", icon: "fas fa-share", content: "\f064" },

	{ type: "fas", icon: "fas fa-share-alt", content: "\f1e0" },

	{ type: "fas", icon: "fas fa-share-alt-square", content: "\f1e1" },

	{ type: "fas", icon: "fas fa-share-square", content: "\f14d" },

	{ type: "fas", icon: "fas fa-shekel-sign", content: "\f20b" },

	{ type: "fas", icon: "fas fa-shield-alt", content: "\f3ed" },

	{ type: "fas", icon: "fas fa-shield-virus", content: "e06c" },

	{ type: "fas", icon: "fas fa-ship", content: "\f21a" },

	{ type: "fas", icon: "fas fa-shipping-fast", content: "\f48b" },

	{ type: "fab", icon: "fab fa-shirtsinbulk", content: "\f214" },

	{ type: "fas", icon: "fas fa-shoe-prints", content: "\f54b" },

	{ type: "fab", icon: "fab fa-shopify", content: "e057" },

	{ type: "fas", icon: "fas fa-shopping-bag", content: "\f290" },

	{ type: "fas", icon: "fas fa-shopping-basket", content: "\f291" },

	{ type: "fas", icon: "fas fa-shopping-cart", content: "\f07a" },

	{ type: "fab", icon: "fab fa-shopware", content: "\f5b5" },

	{ type: "fas", icon: "fas fa-shower", content: "\f2cc" },

	{ type: "fas", icon: "fas fa-shuttle-van", content: "\f5b6" },

	{ type: "fas", icon: "fas fa-sign", content: "\f4d9" },

	{ type: "fas", icon: "fas fa-sign-in-alt", content: "\f2f6" },

	{ type: "fas", icon: "fas fa-sign-language", content: "\f2a7" },

	{ type: "fas", icon: "fas fa-sign-out-alt", content: "\f2f5" },

	{ type: "fas", icon: "fas fa-signal", content: "\f012" },

	{ type: "fas", icon: "fas fa-signature", content: "\f5b7" },

	{ type: "fas", icon: "fas fa-sim-card", content: "\f7c4" },

	{ type: "fab", icon: "fab fa-simplybuilt", content: "\f215" },

	{ type: "fas", icon: "fas fa-sink", content: "e06d" },

	{ type: "fas", icon: "fas fa-sistrix", content: "\f3ee" },

	{ type: "fas", icon: "fas fa-sitemap", content: "\f0e8" },

	{ type: "fab", icon: "fab fa-sith", content: "\f512" },

	{ type: "fas", icon: "fas fa-skating", content: "\f7c5" },

	{ type: "fas", icon: "fas fa-sketch", content: "\f7c6" },

	{ type: "fas", icon: "fas fa-skiing", content: "\f7c9" },

	{ type: "fas", icon: "fas fa-skiing-nordic", content: "\f7ca" },

	{ type: "fas", icon: "fas fa-skull", content: "\f54c" },

	{ type: "fas", icon: "fas fa-skull-crossbones", content: "\f714" },

	{ type: "fab", icon: "fab fa-skyatlas", content: "\f216" },

	{ type: "fab", icon: "fab fa-skype", content: "\f17e" },

	{ type: "fab", icon: "fab fa-slack", content: "\f198" },

	{ type: "fab", icon: "fab fa-slack-hash", content: "\f3ef" },

	{ type: "fas", icon: "fas fa-slash", content: "\f715" },

	{ type: "fas", icon: "fas fa-sleigh", content: "\f7cc" },

	{ type: "fas", icon: "fas fa-sliders-h", content: "\f1de" },

	{ type: "fab", icon: "fab fa-slideshare", content: "\f1e7" },

	{ type: "fas", icon: "fas fa-smile", content: "\f118" },

	{ type: "fas", icon: "fas fa-smile-beam", content: "\f5b8" },

	{ type: "fas", icon: "fas fa-smile-wink", content: "\f4da" },

	{ type: "fas", icon: "fas fa-smog", content: "\f75f" },

	{ type: "fas", icon: "fas fa-smoking", content: "\f48d" },

	{ type: "fas", icon: "fas fa-smoking-ban", content: "\f54d" },

	{ type: "fas", icon: "fas fa-sms", content: "\f7cd" },

	{ type: "fab", icon: "fab fa-snapchat", content: "\f2ab" },

	{ type: "fab", icon: "fab fa-snapchat-ghost", content: "\f2ac" },

	{ type: "fab", icon: "fab fa-snapchat-square", content: "\f2ad" },

	{ type: "fas", icon: "fas fa-snowboarding", content: "\f7ce" },

	{ type: "fas", icon: "fas fa-snowflake", content: "\f2dc" },

	{ type: "fas", icon: "fas fa-snowman", content: "\f7d0" },

	{ type: "fas", icon: "fas fa-snowplow", content: "\f7d2" },

	{ type: "fas", icon: "fas fa-soap", content: "e06e" },

	{ type: "fas", icon: "fas fa-socks", content: "\f696" },

	{ type: "fas", icon: "fas fa-solar-panel", content: "\f5ba" },

	{ type: "fas", icon: "fas fa-sort", content: "\f0dc" },

	{ type: "fas", icon: "fas fa-sort-alpha-down", content: "\f15d" },

	{ type: "fas", icon: "fas fa-sort-alpha-down-alt", content: "\f881" },

	{ type: "fas", icon: "fas fa-sort-alpha-up", content: "\f15e" },

	{ type: "fas", icon: "fas fa-sort-alpha-up-alt", content: "\f882" },

	{ type: "fas", icon: "fas fa-sort-amount-down", content: "\f160" },

	{ type: "fas", icon: "fas fa-sort-amount-down-alt", content: "\f884" },

	{ type: "fas", icon: "fas fa-sort-amount-up", content: "\f161" },

	{ type: "fas", icon: "fas fa-sort-amount-up-alt", content: "\f885" },

	{ type: "fas", icon: "fas fa-sort-down", content: "\f0dd" },

	{ type: "fas", icon: "fas fa-sort-numeric-down", content: "\f162" },

	{ type: "fas", icon: "fas fa-sort-numeric-down-alt", content: "\f886" },

	{ type: "fas", icon: "fas fa-sort-numeric-up", content: "\f163" },

	{ type: "fas", icon: "fas fa-sort-numeric-up-alt", content: "\f887" },

	{ type: "fas", icon: "fas fa-sort-up", content: "\f0de" },

	{ type: "fab", icon: "fab fa-soundcloud", content: "\f1be" },

	{ type: "fab", icon: "fab fa-sourcetree", content: "\f7d3" },

	{ type: "fas", icon: "fas fa-spa", content: "\f5bb" },

	{ type: "fas", icon: "fas fa-space-shuttle", content: "\f197" },

	{ type: "fab", icon: "fab fa-speakap", content: "\f3f3" },

	{ type: "fab", icon: "fab fa-speaker-deck", content: "\f83c" },

	{ type: "fas", icon: "fas fa-spell-check", content: "\f891" },

	{ type: "fas", icon: "fas fa-spider", content: "\f717" },

	{ type: "fas", icon: "fas fa-spinner", content: "\f110" },

	{ type: "fas", icon: "fas fa-splotch", content: "\f5bc" },

	{ type: "fab", icon: "fab fa-spotify", content: "\f1bc" },

	{ type: "fas", icon: "fas fa-spray-can", content: "\f5bd" },

	{ type: "fas", icon: "fas fa-square", content: "\f0c8" },

	{ type: "fas", icon: "fas fa-square-full", content: "\f45c" },

	{ type: "fas", icon: "fas fa-square-root-alt", content: "\f698" },

	{ type: "fab", icon: "fab fa-squarespace", content: "\f5be" },

	{ type: "fab", icon: "fab fa-stack-exchange", content: "\f18d" },

	{ type: "fab", icon: "fab fa-stack-overflow", content: "\f16c" },

	{ type: "fab", icon: "fab fa-stackpath", content: "\f842" },

	{ type: "fas", icon: "fas fa-stamp", content: "\f5bf" },

	{ type: "fas", icon: "fas fa-star", content: "\f005" },

	{ type: "fas", icon: "fas fa-star-and-crescent", content: "\f699" },

	{ type: "fas", icon: "fas fa-star-half", content: "\f089" },

	{ type: "fas", icon: "fas fa-star-half-alt", content: "\f5c0" },

	{ type: "fas", icon: "fas fa-star-of-david", content: "\f69a" },

	{ type: "fas", icon: "fas fa-star-of-life", content: "\f621" },

	{ type: "fab", icon: "fab fa-staylinked", content: "\f3f5" },

	{ type: "fab", icon: "fab fa-steam", content: "\f1b6" },

	{ type: "fab", icon: "fab fa-steam-square", content: "\f1b7" },

	{ type: "fab", icon: "fab fa-steam-symbol", content: "\f3f6" },

	{ type: "fas", icon: "fas fa-step-backward", content: "\f048" },

	{ type: "fas", icon: "fas fa-step-forward", content: "\f051" },

	{ type: "fas", icon: "fas fa-stethoscope", content: "\f0f1" },

	{ type: "fab", icon: "fab fa-sticker-mule", content: "\f3f7" },

	{ type: "fas", icon: "fas fa-sticky-note", content: "\f249" },

	{ type: "fas", icon: "fas fa-stop", content: "\f04d" },

	{ type: "fas", icon: "fas fa-stop-circle", content: "\f28d" },

	{ type: "fas", icon: "fas fa-stopwatch", content: "\f2f2" },

	{ type: "fas", icon: "fas fa-stopwatch-20", content: "e06f" },

	{ type: "fas", icon: "fas fa-store", content: "\f54e" },

	{ type: "fas", icon: "fas fa-store-alt", content: "\f54f" },

	{ type: "fas", icon: "fas fa-store-alt-slash", content: "e070" },

	{ type: "fas", icon: "fas fa-store-slash", content: "e071" },

	{ type: "fab", icon: "fab fa-strava", content: "\f428" },

	{ type: "fas", icon: "fas fa-stream", content: "\f550" },

	{ type: "fas", icon: "fas fa-street-view", content: "\f21d" },

	{ type: "fas", icon: "fas fa-strikethrough", content: "\f0cc" },

	{ type: "fab", icon: "fab fa-stripe", content: "\f429" },

	{ type: "fab", icon: "fab fa-stripe-s", content: "\f42a" },

	{ type: "fas", icon: "fas fa-stroopwafel", content: "\f551" },

	{ type: "fab", icon: "fab fa-studiovinari", content: "\f3f8" },

	{ type: "fab", icon: "fab fa-stumbleupon", content: "\f1a4" },

	{ type: "fab", icon: "fab fa-stumbleupon-circle", content: "\f1a3" },

	{ type: "fas", icon: "fas fa-subscript", content: "\f12c" },

	{ type: "fas", icon: "fas fa-subway", content: "\f239" },

	{ type: "fas", icon: "fas fa-suitcase", content: "\f0f2" },

	{ type: "fas", icon: "fas fa-suitcase-rolling", content: "\f5c1" },

	{ type: "fas", icon: "fas fa-sun", content: "\f185" },

	{ type: "fab", icon: "fab fa-superpowers", content: "\f2dd" },

	{ type: "fas", icon: "fas fa-superscript", content: "\f12b" },

	{ type: "fab", icon: "fab fa-supple", content: "\f3f9" },

	{ type: "fas", icon: "fas fa-surprise", content: "\f5c2" },

	{ type: "fab", icon: "fab fa-suse", content: "\f7d6" },

	{ type: "fas", icon: "fas fa-swatchbook", content: "\f5c3" },

	{ type: "fab", icon: "fab fa-swift", content: "\f8e1" },

	{ type: "fas", icon: "fas fa-swimmer", content: "\f5c4" },

	{ type: "fas", icon: "fas fa-swimming-pool", content: "\f5c5" },

	{ type: "fab", icon: "fab fa-symfony", content: "\f83d" },

	{ type: "fas", icon: "fas fa-synagogue", content: "\f69b" },

	{ type: "fas", icon: "fas fa-sync", content: "\f021" },

	{ type: "fas", icon: "fas fa-sync-alt", content: "\f2f1" },

	{ type: "fas", icon: "fas fa-syringe", content: "\f48e" },

	{ type: "fas", icon: "fas fa-table", content: "\f0ce" },

	{ type: "fas", icon: "fas fa-table-tennis", content: "\f45d" },

	{ type: "fas", icon: "fas fa-tablet", content: "\f10a" },

	{ type: "fas", icon: "fas fa-tablet-alt", content: "\f3fa" },

	{ type: "fas", icon: "fas fa-tablets", content: "\f490" },

	{ type: "fas", icon: "fas fa-tachometer-alt", content: "\f3fd" },

	{ type: "fas", icon: "fas fa-tag", content: "\f02b" },

	{ type: "fas", icon: "fas fa-tags", content: "\f02c" },

	{ type: "fas", icon: "fas fa-tape", content: "\f4db" },

	{ type: "fas", icon: "fas fa-tasks", content: "\f0ae" },

	{ type: "fas", icon: "fas fa-taxi", content: "\f1ba" },

	{ type: "fab", icon: "fab fa-teamspeak", content: "\f4f9" },

	{ type: "fas", icon: "fas fa-teeth", content: "\f62e" },

	{ type: "fas", icon: "fas fa-teeth-open", content: "\f62f" },

	{ type: "fab", icon: "fab fa-telegram", content: "\f2c6" },

	{ type: "fab", icon: "fab fa-telegram-plane", content: "\f3fe" },

	{ type: "fas", icon: "fas fa-temperature-high", content: "\f769" },

	{ type: "fas", icon: "fas fa-temperature-low", content: "\f76b" },

	{ type: "fab", icon: "fab fa-tencent-weibo", content: "\f1d5" },

	{ type: "fas", icon: "fas fa-tenge", content: "\f7d7" },

	{ type: "fas", icon: "fas fa-terminal", content: "\f120" },

	{ type: "fas", icon: "fas fa-text-height", content: "\f034" },

	{ type: "fas", icon: "fas fa-text-width", content: "\f035" },

	{ type: "fas", icon: "fas fa-th", content: "\f00a" },

	{ type: "fas", icon: "fas fa-th-large", content: "\f009" },

	{ type: "fas", icon: "fas fa-th-list", content: "\f00b" },

	{ type: "fab", icon: "fab fa-the-red-yeti", content: "\f69d" },

	{ type: "fas", icon: "fas fa-theater-masks", content: "\f630" },

	{ type: "fab", icon: "fab fa-themeco", content: "\f5c6" },

	{ type: "fab", icon: "fab fa-themeisle", content: "\f2b2" },

	{ type: "fas", icon: "fas fa-thermometer", content: "\f491" },

	{ type: "fas", icon: "fas fa-thermometer-empty", content: "\f2cb" },

	{ type: "fas", icon: "fas fa-thermometer-full", content: "\f2c7" },

	{ type: "fas", icon: "fas fa-thermometer-half", content: "\f2c9" },

	{ type: "fas", icon: "fas fa-thermometer-quarter", content: "\f2ca" },

	{ type: "fas", icon: "fas fa-thermometer-three-quarters", content: "\f2c8" },

	{ type: "fab", icon: "fab fa-think-peaks", content: "\f731" },

	{ type: "fas", icon: "fas fa-thumbs-down", content: "\f165" },

	{ type: "fas", icon: "fas fa-thumbs-up", content: "\f164" },

	{ type: "fas", icon: "fas fa-thumbtack", content: "\f08d" },

	{ type: "fas", icon: "fas fa-ticket-alt", content: "\f3ff" },

	{ type: "fab", icon: "fab fa-tiktok", content: "e07b" },

	{ type: "fas", icon: "fas fa-times", content: "\f00d" },

	{ type: "fas", icon: "fas fa-times-circle", content: "\f057" },

	{ type: "fas", icon: "fas fa-tint", content: "\f043" },

	{ type: "fas", icon: "fas fa-tint-slash", content: "\f5c7" },

	{ type: "fas", icon: "fas fa-tired", content: "\f5c8" },

	{ type: "fas", icon: "fas fa-toggle-off", content: "\f204" },

	{ type: "fas", icon: "fas fa-toggle-on", content: "\f205" },

	{ type: "fas", icon: "fas fa-toilet", content: "\f7d8" },

	{ type: "fas", icon: "fas fa-toilet-paper", content: "\f71e" },

	{ type: "fas", icon: "fas fa-toilet-paper-slash", content: "e072" },

	{ type: "fas", icon: "fas fa-toolbox", content: "\f552" },

	{ type: "fas", icon: "fas fa-tools", content: "\f7d9" },

	{ type: "fas", icon: "fas fa-tooth", content: "\f5c9" },

	{ type: "fas", icon: "fas fa-torah", content: "\f6a0" },

	{ type: "fas", icon: "fas fa-torii-gate", content: "\f6a1" },

	{ type: "fas", icon: "fas fa-tractor", content: "\f722" },

	{ type: "fab", icon: "fab fa-trade-federation", content: "\f513" },

	{ type: "fas", icon: "fas fa-trademark", content: "\f25c" },

	{ type: "fas", icon: "fas fa-traffic-light", content: "\f637" },

	{ type: "fas", icon: "fas fa-trailer", content: "e041" },

	{ type: "fas", icon: "fas fa-train", content: "\f238" },

	{ type: "fas", icon: "fas fa-tram", content: "\f7da" },

	{ type: "fas", icon: "fas fa-transgender", content: "\f224" },

	{ type: "fas", icon: "fas fa-transgender-alt", content: "\f225" },

	{ type: "fas", icon: "fas fa-trash", content: "\f1f8" },

	{ type: "fas", icon: "fas fa-trash-alt", content: "\f2ed" },

	{ type: "fas", icon: "fas fa-trash-restore", content: "\f829" },

	{ type: "fas", icon: "fas fa-trash-restore-alt", content: "\f82a" },

	{ type: "fas", icon: "fas fa-tree", content: "\f1bb" },

	{ type: "fab", icon: "fab fa-trello", content: "\f181" },

	{ type: "fas", icon: "fas fa-trophy", content: "\f091" },

	{ type: "fas", icon: "fas fa-truck", content: "\f0d1" },

	{ type: "fas", icon: "fas fa-truck-loading", content: "\f4de" },

	{ type: "fas", icon: "fas fa-truck-monster", content: "\f63b" },

	{ type: "fas", icon: "fas fa-truck-moving", content: "\f4df" },

	{ type: "fas", icon: "fas fa-truck-pickup", content: "\f63c" },

	{ type: "fas", icon: "fas fa-tshirt", content: "\f553" },

	{ type: "fas", icon: "fas fa-tty", content: "\f1e4" },

	{ type: "fab", icon: "fab fa-tumblr", content: "\f173" },

	{ type: "fab", icon: "fab fa-tumblr-square", content: "\f174" },

	{ type: "fas", icon: "fas fa-tv", content: "\f26c" },

	{ type: "fab", icon: "fab fa-twitch", content: "\f1e8" },

	{ type: "fab", icon: "fab fa-twitter", content: "\f099" },

	{ type: "fab", icon: "fab fa-twitter-square", content: "\f081" },

	{ type: "fab", icon: "fab fa-typo3", content: "\f42b" },

	{ type: "fab", icon: "fab fa-uber", content: "\f402" },

	{ type: "fab", icon: "fab fa-ubuntu", content: "\f7df" },

	{ type: "fab", icon: "fab fa-uikit", content: "\f403" },

	{ type: "fab", icon: "fab fa-umbraco", content: "\f8e8" },

	{ type: "fas", icon: "fas fa-umbrella", content: "\f0e9" },

	{ type: "fas", icon: "fas fa-umbrella-beach", content: "\f5ca" },

	{ type: "fab", icon: "fab fa-uncharted", content: "e084" },

	{ type: "fas", icon: "fas fa-underline", content: "\f0cd" },

	{ type: "fas", icon: "fas fa-undo", content: "\f0e2" },

	{ type: "fas", icon: "fas fa-undo-alt", content: "\f2ea" },

	{ type: "fas", icon: "fas fa-uniregistry", content: "\f404" },

	{ type: "fas", icon: "fas fa-unity", content: "e049" },

	{ type: "fas", icon: "fas fa-universal-access", content: "\f29a" },

	{ type: "fas", icon: "fas fa-university", content: "\f19c" },

	{ type: "fas", icon: "fas fa-unlink", content: "\f127" },

	{ type: "fas", icon: "fas fa-unlock", content: "\f09c" },

	{ type: "fas", icon: "fas fa-unlock-alt", content: "\f13e" },

	{ type: "fab", icon: "fab fa-unsplash", content: "e07c" },

	{ type: "fab", icon: "fab fa-untappd", content: "\f405" },

	{ type: "fas", icon: "fas fa-upload", content: "\f093" },

	{ type: "fab", icon: "fab fa-ups", content: "\f7e0" },

	{ type: "fab", icon: "fab fa-usb", content: "\f287" },

	{ type: "fas", icon: "fas fa-user", content: "\f007" },

	{ type: "fas", icon: "fas fa-user-alt", content: "\f406" },

	{ type: "fas", icon: "fas fa-user-alt-slash", content: "\f4fa" },

	{ type: "fas", icon: "fas fa-user-astronaut", content: "\f4fb" },

	{ type: "fas", icon: "fas fa-user-check", content: "\f4fc" },

	{ type: "fas", icon: "fas fa-user-circle", content: "\f2bd" },

	{ type: "fas", icon: "fas fa-user-clock", content: "\f4fd" },

	{ type: "fas", icon: "fas fa-user-cog", content: "\f4fe" },

	{ type: "fas", icon: "fas fa-user-edit", content: "\f4ff" },

	{ type: "fas", icon: "fas fa-user-friends", content: "\f500" },

	{ type: "fas", icon: "fas fa-user-graduate", content: "\f501" },

	{ type: "fas", icon: "fas fa-user-injured", content: "\f728" },

	{ type: "fas", icon: "fas fa-user-lock", content: "\f502" },

	{ type: "fas", icon: "fas fa-user-md", content: "\f0f0" },

	{ type: "fas", icon: "fas fa-user-minus", content: "\f503" },

	{ type: "fas", icon: "fas fa-user-ninja", content: "\f504" },

	{ type: "fas", icon: "fas fa-user-nurse", content: "\f82f" },

	{ type: "fas", icon: "fas fa-user-plus", content: "\f234" },

	{ type: "fas", icon: "fas fa-user-secret", content: "\f21b" },

	{ type: "fas", icon: "fas fa-user-shield", content: "\f505" },

	{ type: "fas", icon: "fas fa-user-slash", content: "\f506" },

	{ type: "fas", icon: "fas fa-user-tag", content: "\f507" },

	{ type: "fas", icon: "fas fa-user-tie", content: "\f508" },

	{ type: "fas", icon: "fas fa-user-times", content: "\f235" },

	{ type: "fas", icon: "fas fa-users", content: "\f0c0" },

	{ type: "fas", icon: "fas fa-users-cog", content: "\f509" },

	{ type: "fas", icon: "fas fa-users-slash", content: "e073" },

	{ type: "fab", icon: "fab fa-usps", content: "\f7e1" },

	{ type: "fab", icon: "fab fa-ussunnah", content: "\f407" },

	{ type: "fas", icon: "fas fa-utensil-spoon", content: "\f2e5" },

	{ type: "fas", icon: "fas fa-utensils", content: "\f2e7" },

	{ type: "fab", icon: "fab fa-vaadin", content: "\f408" },

	{ type: "fas", icon: "fas fa-vector-square", content: "\f5cb" },

	{ type: "fas", icon: "fas fa-venus", content: "\f221" },

	{ type: "fas", icon: "fas fa-venus-double", content: "\f226" },

	{ type: "fas", icon: "fas fa-venus-mars", content: "\f228" },

	{ type: "fas", icon: "fas fa-vest", content: "e085" },

	{ type: "fas", icon: "fas fa-vest-patches", content: "e086" },

	{ type: "fab", icon: "fab fa-viacoin", content: "\f237" },

	{ type: "fab", icon: "fab fa-viadeo", content: "\f2a9" },

	{ type: "fab", icon: "fab fa-viadeo-square", content: "\f2aa" },

	{ type: "fas", icon: "fas fa-vial", content: "\f492" },

	{ type: "fas", icon: "fas fa-vials", content: "\f493" },

	{ type: "fab", icon: "fab fa-viber", content: "\f409" },

	{ type: "fas", icon: "fas fa-video", content: "\f03d" },

	{ type: "fas", icon: "fas fa-video-slash", content: "\f4e2" },

	{ type: "fas", icon: "fas fa-vihara", content: "\f6a7" },

	{ type: "fab", icon: "fab fa-vimeo", content: "\f40a" },

	{ type: "fab", icon: "fab fa-vimeo-square", content: "\f194" },

	{ type: "fab", icon: "fab fa-vimeo-v", content: "\f27d" },

	{ type: "fab", icon: "fab fa-vine", content: "\f1ca" },

	{ type: "fas", icon: "fas fa-virus", content: "e074" },

	{ type: "fas", icon: "fas fa-virus-slash", content: "e075" },

	{ type: "fas", icon: "fas fa-viruses", content: "e076" },

	{ type: "fab", icon: "fab fa-vk", content: "\f189" },

	{ type: "fab", icon: "fab fa-vnv", content: "\f40b" },

	{ type: "fas", icon: "fas fa-voicemail", content: "\f897" },

	{ type: "fas", icon: "fas fa-volleyball-ball", content: "\f45f" },

	{ type: "fas", icon: "fas fa-volume-down", content: "\f027" },

	{ type: "fas", icon: "fas fa-volume-mute", content: "\f6a9" },

	{ type: "fas", icon: "fas fa-volume-off", content: "\f026" },

	{ type: "fas", icon: "fas fa-volume-up", content: "\f028" },

	{ type: "fas", icon: "fas fa-vote-yea", content: "\f772" },

	{ type: "fas", icon: "fas fa-vr-cardboard", content: "\f729" },

	{ type: "fab", icon: "fab fa-vuejs", content: "\f41f" },

	{ type: "fas", icon: "fas fa-walking", content: "\f554" },

	{ type: "fas", icon: "fas fa-wallet", content: "\f555" },

	{ type: "fas", icon: "fas fa-warehouse", content: "\f494" },

	{ type: "fab", icon: "fab fa-watchman-monitoring", content: "e087" },

	{ type: "fas", icon: "fas fa-water", content: "\f773" },

	{ type: "fas", icon: "fas fa-wave-square", content: "\f83e" },

	{ type: "fab", icon: "fab fa-waze", content: "\f83f" },

	{ type: "fab", icon: "fab fa-weebly", content: "\f5cc" },

	{ type: "fab", icon: "fab fa-weibo", content: "\f18a" },

	{ type: "fas", icon: "fas fa-weight", content: "\f496" },

	{ type: "fas", icon: "fas fa-weight-hanging", content: "\f5cd" },

	{ type: "fab", icon: "fab fa-weixin", content: "\f1d7" },

	{ type: "fab", icon: "fab fa-whatsapp", content: "\f232" },

	{ type: "fab", icon: "fab fa-whatsapp-square", content: "\f40c" },

	{ type: "fas", icon: "fas fa-wheelchair", content: "\f193" },

	{ type: "fas", icon: "fas fa-whmcs", content: "\f40d" },

	{ type: "fas", icon: "fas fa-wifi", content: "\f1eb" },

	{ type: "fab", icon: "fab fa-wikipedia-w", content: "\f266" },

	{ type: "fas", icon: "fas fa-wind", content: "\f72e" },

	{ type: "fas", icon: "fas fa-window-close", content: "\f410" },

	{ type: "fas", icon: "fas fa-window-maximize", content: "\f2d0" },

	{ type: "fas", icon: "fas fa-window-minimize", content: "\f2d1" },

	{ type: "fas", icon: "fas fa-window-restore", content: "\f2d2" },

	{ type: "fab", icon: "fab fa-windows", content: "\f17a" },

	{ type: "fas", icon: "fas fa-wine-bottle", content: "\f72f" },

	{ type: "fas", icon: "fas fa-wine-glass", content: "\f4e3" },

	{ type: "fas", icon: "fas fa-wine-glass-alt", content: "\f5ce" },

	{ type: "fab", icon: "fab fa-wix", content: "\f5cf" },

	{ type: "fab", icon: "fab fa-wizards-of-the-coast", content: "\f730" },

	{ type: "fab", icon: "fab fa-wodu", content: "e088" },

	{ type: "fab", icon: "fab fa-wolf-pack-battalion", content: "\f514" },

	{ type: "fas", icon: "fas fa-won-sign", content: "\f159" },

	{ type: "fab", icon: "fab fa-wordpress", content: "\f19a" },

	{ type: "fab", icon: "fab fa-wordpress-simple", content: "\f411" },

	{ type: "fab", icon: "fab fa-wpbeginner", content: "\f297" },

	{ type: "fab", icon: "fab fa-wpexplorer", content: "\f2de" },

	{ type: "fab", icon: "fab fa-wpforms", content: "\f298" },

	{ type: "fab", icon: "fab fa-wpressr", content: "\f3e4" },

	{ type: "fas", icon: "fas fa-wrench", content: "\f0ad" },

	{ type: "fas", icon: "fas fa-x-ray", content: "\f497" },

	{ type: "fab", icon: "fab fa-xbox", content: "\f412" },

	{ type: "fab", icon: "fab fa-xing", content: "\f168" },

	{ type: "fab", icon: "fab fa-xing-square", content: "\f169" },

	{ type: "fab", icon: "fab fa-y-combinator", content: "\f23b" },

	{ type: "fab", icon: "fab fa-yahoo", content: "\f19e" },

	{ type: "fab", icon: "fab fa-yammer", content: "\f840" },

	{ type: "fab", icon: "fab fa-yandex", content: "\f413" },

	{ type: "fab", icon: "fab fa-yandex-international", content: "\f414" },

	{ type: "fab", icon: "fab fa-yarn", content: "\f7e3" },

	{ type: "fab", icon: "fab fa-yelp", content: "\f1e9" },

	{ type: "fas", icon: "fas fa-yen-sign", content: "\f157" },

	{ type: "fas", icon: "fas fa-yin-yang", content: "\f6ad" },

	{ type: "fab", icon: "fab fa-yoast", content: "\f2b1" },

	{ type: "fab", icon: "fab fa-youtube", content: "\f167" },

	{ type: "fab", icon: "fab fa-youtube-square", content: "\f431" },

	{ type: "fab", icon: "fab fa-zhihu", content: "\f63f" },
];
