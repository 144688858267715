import React from "react";
import { Container, Tabs, TabList, Tab, Heading, Stack, Flex } from "@chakra-ui/react";

export default function TabPanels({ panels = [], panelVal = 0, changePanel, buttonGroup, children }) {
	return (
		<Container px={0} flex={1} maxW="full">
			<Tabs
				isLazy
				colorScheme="teal"
				index={panelVal}
				onChange={changePanel}
				display="flex"
				flexDirection="column"
				flex={1}
				w="full"
				h="full"
			>
				{/* {Boolean(panels?.length > 1) && ( */}
				<Flex px={1} py={2} direction="row" w="full" justify="space-between">
					<TabList
						color="gray.500"
						borderBottomColor="gray.400"
						borderBottomWidth={2}
						maxW={"full"}
						mx="auto"
						w="full"
					>
						{panels?.length > 1 &&
							panels?.map((panel, i) => (
								<Tab
									key={i}
									borderStyle="inset"
									_selected={{
										color: "teal.600",
										bg: "whiteAlpha.500",
										borderTopRadius: "md",
										borderBottomWidth: 3,
										borderBottomColor: "teal.600",
										textShadow: "1px 1px 2px var(--chakra-colors-whiteAlpha-900)",
									}}
									_hover={{
										color: "teal.500",
										bg: "whiteAlpha.700",
										borderTopRadius: "md",
										borderBottomWidth: 3,
										borderBottomColor: "teal.600",
										textShadow: "1px 1px 2px var(--chakra-colors-whiteAlpha-900)",
										shadow: "lg",
									}}
								>
									<Heading
										isTruncated
										fontSize={"lg"}
										letterSpacing={2}
										textTransform="uppercase"
										textShadow={"2px 2px 2px var(--chakra-colors-whiteAlpha-800)"}
									>
										{panel?.panel ?? panel}
									</Heading>
								</Tab>
							))}
					</TabList>
					{buttonGroup && buttonGroup}
				</Flex>
				<Stack spacing={0}>{children}</Stack>
			</Tabs>
		</Container>
	);
}
