import React, { useRef, useState, useMemo } from "react";
import { NavLink } from "react-router-dom";
import {
	Box,
	Flex,
	Spacer,
	Text,
	Divider,
	HStack,
	IconButton,
	Stack,
	Tag,
	TagLabel,
	Collapse,
	useBoolean,
	Link,
} from "@chakra-ui/react";
import { getDeviceType, isAuthorized } from "../../../helperFunctions";
import { routes } from "../../../modules/App/routes/routes";

import PPMLogo from "../../../core/Logos/components/PPMLogo";
import { portalUser } from "../../../App";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all";

export default function SideBar({ sideBarButton, sideBarIsOpen, sideBarWidth }) {
	const ref = useRef(null);

	const [activeItem, setActiveItem] = useState("");

	const maxW = useMemo(() => sideBarWidth, [sideBarWidth]);

	if (getDeviceType() === "mobile" && !sideBarIsOpen) {
		return null;
	} else {
		return (
			<Flex
				id="menuDrawer"
				borderColor="var(--chakra-colors-gray-400)!important"
				borderRight="2px"
				h="100vh"
				position="fixed"
				zIndex={6}
				w={maxW}
				maxW={maxW}
				display={{ base: sideBarIsOpen ? "flex" : "none", lg: "flex" }}
				color={"gray.500"}
				bgGradient="linear(to-r, gray.200 0%, gray.50 25%, gray.100 55%, gray.300 100%)"
				direction="column"
				justify="space-between"
				ref={ref}
				overflowX="hidden"
			>
				<HStack p="2" w="full" align="flex-start">
					<PPMLogo color="teal.700" h="14" display="flex" />
				</HStack>
				{sideBarIsOpen && maxW === "100vw" && (
					<Flex p={1} position="absolute" right={4} top={0}>
						{sideBarButton && sideBarButton}
					</Flex>
				)}

				{process.env.NODE_ENV === "development" && (
					<Flex w="full" justify={"flex-end"} mt={-5}>
						<Tag size="sm" color="gray.600" bg="whiteAlpha.700">
							<TagLabel>
								<Text as="i" className="fas fa-code" /> DEV MODE
							</TagLabel>
						</Tag>
					</Flex>
				)}

				<Divider borderColor="gray.400" py="1" borderBottomWidth={2} />

				<Flex direction="column" p="1" className="skinnyScroll" overflowY="auto" overflowX="hidden">
					{routes.map((section, i) => {
						let itemCount = 0;
						for (let i = 0; i < section?.length; i++) {
							if (isAuthorized(portalUser.user, section[i].access)) {
								itemCount += 1;
							}
						}
						return (
							<Stack key={i} p={1} spacing={1} flex={1} w="full" maxW="full">
								{itemCount > 0 && i > 0 && (
									<Box py={2}>
										<Divider borderColor="gray.400" borderBottomWidth={2} />
									</Box>
								)}

								{section?.map(
									(item, j) =>
										isAuthorized(portalUser.user, item.access) && (
											<MenuItem
												key={j}
												item={item}
												sectionIndex={i}
												section={section}
												drawerOpen={sideBarIsOpen}
												// toggleSideBar={toggleSideBar}
												activeItem={activeItem}
												setActiveItem={setActiveItem}
											/>
										)
								)}
							</Stack>
						);
					})}
				</Flex>

				<Spacer />
			</Flex>
		);
	}
}

function MenuItem({
	item,
	sectionIndex,
	drawerOpen,
	toggleSideBar,
	activeItem,
	setActiveItem,
	isSubMenu,

	isMenuList,
}) {
	const [isOpen, setIsOpen] = useBoolean();
	var collapseIcon = isOpen ? "fas fa-chevron-down" : "fas fa-chevron-right";

	const itemHeading = (
		<Flex
			id={"menuItem-" + item.name.replace(/\s/gm, "").toLowerCase()}
			onClick={() => setIsOpen.toggle()}
			maxW="full"
			align="center"
			fontWeight="semibold"
			textTransform="uppercase"
			justify={!isMenuList && !drawerOpen ? "center" : "space-between"}
			px={1}
		>
			<HStack align="center" isTruncated spacing={isSubMenu ? 2 : sectionIndex === 0 ? 5 : 4} w="full" flex={1}>
				{item.icon && (
					<IconButton
						variant="unstyled"
						icon={<i className={item.icon} />}
						boxSize={isSubMenu ? 8 : 10}
						maxW={8}
						minW={8}
					/>
				)}
				{item.icon && typeof item.icon === "object" && (
					<IconButton
						variant="unstyled"
						as={item.icon}
						boxSize={isSubMenu ? 8 : 10}
						maxW={isSubMenu ? 6 : 8}
					/>
				)}
				{(drawerOpen || (!drawerOpen && isSubMenu)) && (
					<Text
						maxW="full"
						w="full"
						isTruncated
						letterSpacing={sectionIndex === 0 ? 2 : isSubMenu ? 0 : 1}
						textShadow="inherit"
						fontSize={!isSubMenu ? "md" : "sm"}
					>
						{item.name}
					</Text>
				)}
			</HStack>

			{Boolean(item.subMenu) && (
				<Text key={collapseIcon}>
					<i className={collapseIcon + " fa-fw"} />
				</Text>
			)}
		</Flex>
	);

	return (
		<>
			<Link
				borderColor={"transparent"}
				borderWidth={1}
				rounded="md"
				cursor="pointer"
				_hover={{
					bg: "teal.600",
					color: "white",
					borderColor: "blackAlpha.400",
					textShadow: "0px 0px 0px transparent",
					textDecoration: "underline",
				}}
				_activeLink={{
					bg: Boolean(item?.subMenu) ? "teal.600" : "teal.500",
					color: "white",
					borderColor: "blackAlpha.400",
					textShadow: "1px 1px 1px var(--chakra-colors-gray-600)",
				}}
				as={NavLink}
				to={item?.url}
				onClick={(e) => {
					e.stopPropagation();
					if (Boolean(item?.subMenu)) {
						e.preventDefault();
					}
				}}
			>
				{itemHeading}
			</Link>
			{Boolean(item?.subMenu) && (
				<Collapse in={isOpen}>
					<Stack bg={"blackAlpha.200"} borderWidth={2} borderColor="gray.400" spacing={1} p={1} rounded="md">
						{item?.subMenu?.map(
							(subItem, i) =>
								isAuthorized(portalUser.user, subItem.access) && (
									<MenuItem
										key={i}
										item={subItem}
										drawerOpen={drawerOpen}
										isSubMenu={true}
										toggleSideBar={toggleSideBar}
										activeItem={activeItem}
										setActiveItem={setActiveItem}
									/>
								)
						)}
					</Stack>
				</Collapse>
			)}
		</>
	);
}

/* <Menu placement="left-start" isOpen={isHoveringOver}>
<MenuButton
	isActive={isHoveringOver}
	onMouseOver={() => setIsHoveringOver(true)}
	onMouseLeave={() => setIsHoveringOver(false)}
>
	{itemHeading}
</MenuButton>
<MenuList
	onMouseOver={() => setIsHoveringOver(true)}
	onMouseLeave={() => setIsHoveringOver(false)}
	color="darkslategray"
>
	{item["subMenu"].map(
		(subItem, i) =>
			isAuthorized(ppmUser, subItem.access) && (
				<MenuItem
					key={i}
					item={subItem}
					drawerOpen={drawerOpen}
					isSubMenu={true}
					isMenuList={true}
					toggleSideBar={toggleSideBar}
					activeItem={activeItem}
					setActiveItem={setActiveItem}
				/>
			)
	)}
</MenuList>
</Menu> */
