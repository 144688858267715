import React from "react";
import {
	Flex,
	Box,
	Heading,
	Tabs,
	TabList,
	Tab,
	HStack,
	Stack,
	IconButton,
	Tooltip,
	useBoolean,
} from "@chakra-ui/react";
import { portalUser } from "../../../App";
// import { log } from "../../../../helperFunctions";

export default function MainContent({
	bannerTitle,
	bannerSubtitle,
	title,
	subtitle,
	tabs,
	tabVal,
	changeTab,
	allowFullScreen,
	children,
	styles = { bg: "transparent" },
}) {
	const [useFullScreen, setFullScreen] = useBoolean();

	return (
		<Flex
			w="full"
			flex={1}
			direction="column"
			h="full"
			position={useFullScreen ? "absolute" : "inherit"}
			zIndex={useFullScreen ? 200 : "unset"}
			bg={useFullScreen ? "white" : "unset"}
			left={useFullScreen ? 0 : "unset"}
			overflow={useFullScreen ? "auto" : "unset"}
		>
			{!useFullScreen && (
				<MainBanner
					bannerTitle={bannerTitle}
					bannerSubtitle={bannerSubtitle}
					title={title}
					subtitle={subtitle}
					tabs={tabs}
					tabVal={tabVal}
					changeTab={changeTab}
					allowFullScreen={allowFullScreen}
					useFullScreen={useFullScreen}
					setFullScreen={setFullScreen}
				/>
			)}
			<Flex
				w="full"
				maxW="100vw"
				flex={1}
				direction="column"
				bg={styles?.bg ?? "transparent"}
				overflowY="auto"
				overflowX="hidden"
			>
				{useFullScreen && (
					<MainBanner
						bannerTitle={bannerTitle}
						bannerSubtitle={bannerSubtitle}
						tabs={tabs}
						tabVal={tabVal}
						changeTab={changeTab}
						allowFullScreen={allowFullScreen}
						useFullScreen={useFullScreen}
						setFullScreen={setFullScreen}
					/>
				)}

				{children}
			</Flex>
		</Flex>
	);
}

function MainBanner({
	bannerTitle,
	bannerSubtitle,
	title,
	subtitle,

	tabs,
	disabledTabs,
	tabVal,
	changeTab,

	allowFullScreen,
	useFullScreen,
	setFullScreen,
}) {
	return (
		<Box borderBottomWidth={2} borderBottomColor="gray.400" borderStyle="inset">
			<Box
				pt={4}
				bgGradient={
					"linear(to-r, rgba(255, 255, 255,0.9), rgba(255, 255, 255,0.5)), url(" +
					portalUser.user?.getBannerImage() +
					")"
				}
				bgSize="cover"
				bgPosition="center"
			>
				<Box>
					<Flex
						px={{ base: 4, md: 6 }}
						direction={{ base: "column", md: "row" }}
						justify="space-between"
						align={{ base: "flex-start", md: "flex-end" }}
					>
						<Stack spacing={0}>
							<Heading
								fontWeight={"semibold"}
								size="md"
								color="gray.500"
								textTransform="uppercase"
								letterSpacing={3}
							>
								{title}
							</Heading>
							<HStack align="center" spacing={3}>
								<Heading size="lg" color="gray.600" textTransform="uppercase" letterSpacing={3}>
									{subtitle}
								</Heading>
							</HStack>
						</Stack>
						<Stack>
							<HStack align="center" spacing={3}>
								<Heading size="lg" color="gray.600" textTransform="uppercase" letterSpacing={3}>
									{bannerTitle}
								</Heading>
							</HStack>
							<Heading
								fontWeight={"semibold"}
								size="md"
								color="gray.500"
								textTransform="uppercase"
								letterSpacing={3}
							>
								{bannerSubtitle}
							</Heading>
						</Stack>
					</Flex>
					<Flex w="full" px={{ base: 0, md: 4 }} align="flex-end" pt={!tabs ? 3 : 0}>
						{allowFullScreen && (
							<Flex align="center" justify="center" py={1}>
								<Tooltip label={useFullScreen ? "Show Menu" : "Hide Menu"} placement="top">
									<IconButton
										key={useFullScreen ? "fullScreenView" : "normalView"}
										variant="ghost"
										size="md"
										color="gray.500"
										p={0}
										icon={
											<i
												className={
													"fas fa-" +
													(useFullScreen ? "angle-double-right" : "angle-double-left") +
													" fa-lg fa-fw"
												}
											></i>
										}
										onClick={setFullScreen.toggle}
									/>
								</Tooltip>
							</Flex>
						)}
						{tabs && (
							<Flex w="full" flex={1} zIndex={2}>
								<Tabs
									onChange={(index) => changeTab(index)}
									size="lg"
									variant="line"
									isFitted
									colorScheme="teal"
									overflowX="auto"
									overflowY="hidden"
									className="skinnyScroll"
									whiteSpace="nowrap"
									borderColor="transparent"
									index={tabVal ?? 0}
								>
									<TabList>
										{tabs?.map((tab, i) => (
											<Tab
												key={i}
												textTransform="uppercase"
												fontWeight="semibold"
												color="gray.500"
												isDisabled={disabledTabs?.includes(tab)}
											>
												{tab}
											</Tab>
										))}
									</TabList>
								</Tabs>
							</Flex>
						)}
					</Flex>
				</Box>
			</Box>
		</Box>
	);
}
