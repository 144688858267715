import { log } from "../../../helperFunctions";

export default class SectionTemplateProjects {
	constructor(projects = [], params = {}, template = "Projects") {
		let name = "Projects";
		this.template = template;
		this.name = name;
		this.heading = name;
		this.divider = false;
		this.pagebreak = 0;
		this.wrap = true;
		this.show = Boolean(projects.length > 0);
		this.type = "virtualTable";
		this.minRows = 1;
		this.maxRows = 22;
		this.subSections = this.getSubSectionTemplate(projects, params, template);
	}
	getSubSectionTemplate(projects = [], params = {}, template) {
		if (this.template === "ProjectSelect") {
			return [new SubSectionTemplateProjectSelect(projects)];
		} else {
			return [new SubSectionTemplateProjects(projects, params, template, this.heading)];
		}
	}
}

class SubSectionTemplateProjects {
	constructor(projects = [], params = {}, template, heading) {
		let show = projects?.length > 0 ? true : false;

		this.template = template;
		this.type = "virtualTable";
		this.show = show;
		this.heading = heading;

		this.footer = this.getFooter(projects);
		let sortGroupFilter = this.getSortGroupFilter(params);
		this.filterBy = sortGroupFilter.filterBy;
		this.sortBy = sortGroupFilter.sortBy ?? [
			{ id: "project", desc: false },
			{ id: "contract", desc: false },
			{ id: "job", desc: false },
		];
		this.wrap = true;

		this.columns = [
			{
				accessor: "selectedYN",
				Header: "Selected YN",
				valueType: "boolean",
				filter: "select",
				show: false,
				disableExport: true,
			},
			{
				accessor: "jccmKeyID",
				Header: "Key ID",
				valueType: "integer",
				width: "85%",
				show: false,
				showGroupBy: false,
			},

			{
				accessor: "accessYN",
				Header: "Job Access",
				valueType: "boolean",
				filter: "multiselect",
				width: "75%",
				show: false,
			},
			{
				accessor: "title",
				Header: "Job #",
				valueType: "string",
				show: false,
				disableExport: true,
				width: "300%",
			},
			{ accessor: "subtitle", Header: "Subtitle", valueType: "string", show: false, disableExport: true },
			{ accessor: "companyDesc", Header: "Company", show: false, filter: "multiselect" },
			{
				accessor: "companyTag",
				Header: "Company",
				filter: "multiselect",
				width: "70%",
				show: false,
			},

			{
				accessor: "project",
				Header: "Project",
				valueType: "string",
				width: "85%",
				show: false,
			},

			{
				accessor: "contract",
				Header: "Contract",
				showGroupBy: false,
				filter: "multiselect",

				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},

			{
				accessor: "job",
				Header: "job",
				showGroupBy: false,
				filter: "multiselect",

				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "jobVal",
				Header: "JobVal",
				valueType: "number",
				decimals: 4,
				width: "85%",
				show: false,
				disableExport: true,
			},

			{
				accessor: "contractVal",
				Header: "ContractVal",
				valueType: "number",
				decimals: 4,
				width: "85%",
				show: false,
				disableExport: true,
			},

			{ accessor: "contractName", Header: "Contract Name", width: "300%" },

			{
				accessor: "contractStatus",
				Header: "Contract Status",
				valueType: "integer",
				filter: "multiselect",
			},
			{
				accessor: "status",
				Header: "Contract Status",
				valueType: "string",
				filter: "multiselect",
				width: "85%",
				show: false,
			},

			{ accessor: "regionID", Header: "Region ID", valueType: "integer", show: false, disableExport: true },
			{ accessor: "region", Header: "Region", filter: "multiselect", width: "85%" },
			{ accessor: "regionDesc", Header: "Region (Long Desc)", show: false, showGroupBy: false },

			{ accessor: "customer", Header: "Customer", valueType: "integer", show: false, disableExport: true },
			{ accessor: "customerName", Header: "General Contractor", valueType: "multiselect", width: "200%" },
			{ accessor: "pmName", Header: "Project Manager", width: "125%", show: true },
			{ accessor: "purchaserID", Header: "Purchaser", showGroupBy: false, show: false, disableExport: true },
			{ accessor: "purchaserName", Header: "Purchaser", width: "125%" },
			{ accessor: "projectAcctID", Header: "PA", showGroupBy: false, show: false, disableExport: true },
			{ accessor: "paName", Header: "Project Acct", width: "125%" },
			{ accessor: "jobAddress", Header: "Address", width: "200%", placeholder: "N/A - Address Missing" },
			{ accessor: "jobCity", Header: "City", width: "150%", placeholder: "N/A" },
			{ accessor: "jobState", Header: "State", width: "75%", placeholder: "N/A" },
			{ accessor: "jobZip", Header: "Zip", width: "100%", placeholder: "N/A" },
			{ accessor: "projectCity", Header: "Project City", width: "125%", show: false },
			{
				accessor: "pmID",
				Header: "PM #",
				valueType: "integer",
				showGroupBy: false,
				filter: "multiselect",
				show: false,
				disableExport: true,
			},

			{ accessor: "projectType", Header: "Project Type", width: "125%" },
			{ accessor: "contractType", Header: "Contract Type", show: false },
			{ accessor: "platform", Header: "Platform", show: false },
			{ accessor: "startMth", Header: "Start Month", valueType: "month" },
			{
				accessor: "endMth",
				Header: Boolean(projects[0]?.contractStatus === "3") ? "Close Month" : "Expected Close",
				showGroupBy: false,
				valueType: "month",
			},
			// { accessor: "thruMth", Header: "Last Projection", showGroupBy: false, valueType: "month", show: false },

			{ accessor: "bidContractAmt", Header: "Bid Contract", valueType: "currency", show: false },
			{ accessor: "bidMarginPct", Header: "Bid Margin", valueType: "percent", show: false },
			{ accessor: "contractAmt", Header: "Contract Amount", valueType: "currency", show: false },
			{ accessor: "currMarginPct", Header: "Current Margin", valueType: "percent", show: false },
			{ accessor: "projContractAmt", Header: "Projected Contract", valueType: "currency", show: false },
			{ accessor: "projMarginPct", Header: "Projected Margin", valueType: "percent", show: false },
			{ accessor: "actualCost", Header: "Cost To Date", valueType: "currency", show: false },
			{ accessor: "costToDatePct", Header: "Pct Complete", valueType: "percent", show: false },
			{ accessor: "billedAmt", Header: "Billed To Date", valueType: "currency", show: false },
			{ accessor: "billedToDatePct", Header: "Pct Billed", valueType: "percent", show: false },
		];

		this.data = projects;
	}

	getSortGroupFilter(params) {
		// let loopBy = [{ id: "isArchivedYN", heading: "isArchivedYN" }];
		let loopBy = params?.loopBy ?? [];
		let groupBy = params?.groupBy ?? [];
		let sortBy = params?.sortBy ?? [];
		let filterBy = params?.filterBy ?? [];

		let sortGroupFilter = { loopBy, sortBy, groupBy, filterBy };
		return sortGroupFilter;
	}

	getFooter(rows = [], totals = null) {
		if (this.heading === "Active Projects") {
			rows = rows.filter((row) => row.contractStatus === "1");
		} else if (this.heading === "Completed Projects") {
			rows = rows.filter((row) => row.contractStatus === "3");
		}
		let rowCount = rows?.length ?? 0;
		let footer = {
			contract: Boolean(totals?.queryData === "Contract")
				? rowCount + "/" + rowCount
				: rowCount + (Boolean(totals) ? "/" + totals[0]?.jccmKeyID : "/" + rowCount),
			contractTextAlign: "center",
			job: "Projects",
		};

		return [footer];
	}

	// getFooter(rows = [], totals = null) {
	// 	// log("rows", rows.length);
	// 	let rowCount = 0;
	// 	// if (Boolean(params?.filterBy[0]?.value === "3")) {
	// 	// 	rowCount = rows.filter((row) => row.contractStatus === "3").length;
	// 	// } else {
	// 	// 	rowCount = rows.filter((row) => row.contractStatus === "1").length;
	// 	// }
	// 	let footer = { jccmKeyID: null, contract: null, job: null };

	// 	if (!Boolean(totals) && rows?.length > 0) {
	// 		rowCount = rows?.length ?? 0;
	// 		footer.jccmKeyID = rowCount;
	// 		footer.contract = 0;
	// 		footer.job = 0;

	// 		return [footer];
	// 	} else if (Boolean(totals) && rows?.length > 0) {
	// 		footer = totals[0];
	// 		let totalCount = footer[0].jccmKeyID;
	// 		rowCount = rows?.length ?? 0;

	// 		footer.contract = rowCount + "/" + totalCount;
	// 		footer.job = rowCount + "/" + totalCount;

	// 		return [footer];
	// 	}
	// }
}

class SubSectionTemplateProjectSelect {
	constructor(contracts = []) {
		log("contracts", contracts);

		this.template = "ProjectSelect";
		this.type = "virtualTable";
		this.heading = "Select Project";
		this.columns = [
			{
				accessor: "selectedYN",
				Header: "Selected YN",
				valueType: "boolean",
				filter: "select",
				show: false,
				disableExport: true,
			},
			{
				accessor: "jccmKeyID",
				Header: "Key ID",
				valueType: "integer",
				show: false,
				showGroupBy: false,
				disableExport: true,
			},
			{ accessor: "accessYN", Header: "Job Access", valueType: "boolean", filter: "multiselect" },
			{ accessor: "title", Header: "Title", valueType: "string", show: false, disableExport: true },
			{ accessor: "subtitle", Header: "Subtitle", valueType: "string", show: false, disableExport: true },
			{ accessor: "companyDesc", Header: "Company", show: false, filter: "multiselect" },
			{ accessor: "jcco", Header: "JCCo", valueType: "integer", show: false, filter: "multiselect" },
			{ accessor: "contract", Header: "Job #", width: "50%", showGroupBy: false, filter: "multiselect" },
			{ accessor: "description", Header: "Description", showGroupBy: false, width: "200%" },
			{
				accessor: "contractStatus",
				Header: "Contract Status",
				valueType: "integer",
				show: false,
				disableExport: true,
			},
			{
				accessor: "status",
				Header: "Contract Status",
				valueType: "string",
				filter: "multiselect",
			},
			{ accessor: "regionID", Header: "Region ID", valueType: "integer", show: false, disableExport: true },
			{ accessor: "region", Header: "Region (Short Desc)", filter: "multiselect" },
			{ accessor: "regionDesc", Header: "Region (Long Desc)", show: false, width: "250%" },
			{ accessor: "jobAddress", Header: "Project Address", width: "200%" },
			{ accessor: "projectCity", Header: "Project City", show: "false" },
			{
				accessor: "projectMgrID",
				Header: "PM #",
				valueType: "integer",
				showGroupBy: false,
				filter: "multiselect",
				show: false,
				disableExport: true,
			},
			{ accessor: "pmName", Header: "Project Manager", width: "150%", show: false },
			{ accessor: "projectAcctID", Header: "PA", showGroupBy: false, show: false, disableExport: true },
			{ accessor: "paName", Header: "Project Accountant", show: false },
			{ accessor: "purchaserID", Header: "Purchaser", showGroupBy: false, show: false, disableExport: true },
			{ accessor: "purchaserName", Header: "Purchaser", show: false },
			{ accessor: "customer", Header: "Customer", valueType: "integer", show: false, disableExport: true },
			{ accessor: "customerName", Header: "General Contractor", valueType: "multiselect", width: "200%" },
			{ accessor: "projectType", Header: "Project Type" },
			{ accessor: "contractType", Header: "Contract Type" },
			{ accessor: "startMth", Header: "Start Month", valueType: "month", show: false },
			{ accessor: "endMth", Header: "Close Month", showGroupBy: false, valueType: "month", show: false },
			{ accessor: "thruMth", Header: "Last Projection", showGroupBy: false, valueType: "month", show: false },
			{ accessor: "bidContractAmt", Header: "Bid Contract ($)", valueType: "currency" },
			{ accessor: "bidMarginPct", Header: "Bid Margin (%)", valueType: "percent" },
			{ accessor: "contractAmt", Header: "Contract Amount ($)", valueType: "currency" },
			{ accessor: "currMarginPct", Header: "Current Margin (%)", valueType: "percent" },
			{ accessor: "projContractAmt", Header: "Projected Contract ($)", valueType: "currency" },
			{ accessor: "projMarginPct", Header: "Projected Margin (%)", valueType: "percent" },
			{ accessor: "actualCost", Header: "Cost To Date ($)", valueType: "currency" },
			{ accessor: "costToDatePct", Header: "Percent Complete (%)", valueType: "percent" },
			{ accessor: "billedAmt", Header: "Billed To Date ($)", valueType: "currency" },
			{ accessor: "billedToDatePct", Header: "Percent Billed (%)", valueType: "percent" },
		];
		this.sortBy = [
			{ id: "regionDesc", desc: true },
			{ id: "pmName", desc: false },
			{ id: "jcco", desc: false },
		];
		this.filterBy = [
			{ id: "accessYN", value: "Y" },
			{ id: "contractStatus", value: "1" },
		];

		this.groupBy = ["regionDesc", "pmName"];

		this.data = contracts;
	}
}
