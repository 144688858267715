import React, { useState, useMemo, useRef, useCallback, useEffect } from "react";
import {
	Tr,
	Td,
	Button,
	Heading,
	Textarea,
	Flex,
	Tooltip,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	ModalCloseButton,
	IconButton,
	Portal,
} from "@chakra-ui/react";
import { portalUser } from "../../../App";
import PayrollAPI from "../../../APIs/PayrollAPI";
import TimecardJobClassLegacy from "../classes/TimecardJobClassLegacy";
import DataInputSelectLegacy from "../common/DataInputSelectLegacy";
import TimecardEntryHourInput from "./TimecardEntryHourInput";
import PR_WEEK_DAYS from "../constants/PRWeekDays";
import EARN_CODES from "../constants/EarnCodes";
import { convertArrayToMap } from "../../../helperFunctions";
import { v4 } from "uuid";

const earnCodeMap = convertArrayToMap(EARN_CODES, "value");

export default function TimecardTableRow(props) {
	const ref = useRef();
	const [preOpen, setPreOpen] = useState(false);
	const [notesOpen, setNotesOpen] = useState(false);
	const [dayNum, setDayNum] = useState(0);
	const [timecardJobs, setTimecardJobs] = useState(props.timecardJobs ?? []);

	const closePreOpen = () => {
		setPreOpen(false);
	};

	const loadData = (dataName) => {
		if (dataName === "phases") {
			let selectedJob = props.item?.job;
			props.getAvailableJobPhases(timecardJobs?.find(({ job }) => job === selectedJob));
		}
	};

	const selectEarnCode = async (earnCode) => {
		earnCode = earnCode.value;
		let selection = { value: earnCode, rowIndex: props.rowIndex, attr: "earnCode", keyId: props.item?.keyId };
		await props.updateTimecardItem(selection);
	};

	const selectJob = async (job) => {
		let selection = { value: job, rowIndex: props.rowIndex, attr: "job", keyId: props.item?.keyId };
		await props.updateTimecardItem(selection);
	};

	const selectPhase = async (phase) => {
		let selection = { value: phase, rowIndex: props.rowIndex, attr: "phase", keyId: props.item?.keyId };
		await props.updateTimecardItem(selection);
	};

	const selectShift = async (ev) => {
		let shift = ev.shift;
		let selection = { value: shift, rowIndex: props.rowIndex, attr: "shift", keyId: props.item?.keyId };
		await props.updateTimecardItem(selection);
	};

	const updateHours = async (ev, index, upload = false) => {
		let value = ev.target.value;
		let hourChange = {
			value: value,
			rowIndex: props.rowIndex,
			hourIndex: index,
			attr: "hours",
			keyId: props.item?.keyId,
			upload: upload,
		};
		await props.updateTimecardItem(hourChange);
	};

	const updateNotes = async (ev) => {
		let notesIndex = dayNum;
		let value = ev.target.value;

		let noteChange = {
			value: value,
			rowIndex: props.rowIndex,
			notesIndex: notesIndex,
			attr: "notes",
			keyId: props.item?.keyId,
			upload: true,
		};
		await props.updateTimecardItem(noteChange);
	};

	const deleteEntry = useCallback(
		async (ev) => {
			ev.stopPropagation();
			let deletion = { rowIndex: props.rowIndex, attr: "delete", keyId: props.item?.keyId };
			await props.updateTimecardItem(deletion);
		},
		[props]
	);

	const openNotes = (dayIndex) => {
		setDayNum(dayIndex);
		setNotesOpen(true);
	};

	const closeNotes = () => {
		setNotesOpen(false);
	};

	let disabledEarnCodes = [...props.disabledEarnCodes];
	let timecardJobsCopy = [...timecardJobs];
	let disabledPhases = [];
	let checkEarnCode = props.item?.earnCode;
	let checkJob = props.item?.job;
	let checkPhase = props.item?.phase;
	let checkShift = props.item?.shift;

	if (checkJob === "N/A" && checkPhase === "N/A") {
		disabledEarnCodes.push("OT");
		disabledEarnCodes.push("DT");
	} else if (checkJob !== null && checkJob !== "N/A") {
		disabledEarnCodes.push("VAC");
		disabledEarnCodes.push("SICK");
		disabledEarnCodes.push("HOL");
	}

	for (let i = 0; i < props.payweekHours?.length; i++) {
		if (!Boolean(props.payweekHours[i].keyId) || props.payweekHours[i].keyId === props.item?.keyId) {
			continue;
		}

		if (checkJob === props.payweekHours[i].job && checkPhase === props.payweekHours[i].phase) {
			disabledEarnCodes.push(props.payweekHours[i].earnCode);
		}

		for (let j = 0; j < timecardJobsCopy?.length; j++) {
			if (
				checkJob === props.payweekHours[i].job &&
				checkEarnCode === props.payweekHours[i].earnCode &&
				checkShift === props.payweekHours[i].shift
			) {
				if (!Boolean(timecardJobsCopy[j].disabledPhases)) {
					timecardJobsCopy[j].disabledPhases = [];
				}
				disabledPhases.push(props.payweekHours[i].phase);
			}
		}
	}

	let disabledJobs = [...props.disabledJobs];
	for (let i = 0; i < timecardJobs?.length; i++) {
		if (checkEarnCode === 5 || checkEarnCode === 6 || checkEarnCode === 7) {
			if (i === 0) {
				continue;
			}
			disabledJobs.push(timecardJobs[i].key);
		}
	}

	const deleteButton = useMemo(() => {
		let deleteButton = (
			<Portal position="relative" containerRef={ref}>
				<Flex
					flex={1}
					direction="column"
					position="absolute"
					justify="center"
					align="center"
					_groupHover={{
						display: "flex",
						borderTopLeftRadius: "md",
						borderBottomLeftRadius: "md",
					}}
					_hover={{ bg: "red.100" }}
					display="none"
					h="full"
					ml={"-32px"}
				>
					<Tooltip label={"Delete"} placement="bottom">
						<IconButton
							isRound
							size="sm"
							w={6}
							colorScheme={props.timecardHeader?.isApproved() || props.item?.isLocked() ? "gray" : "red"}
							color={
								props.timecardHeader?.isApproved() || props.item?.isLocked() ? "gray.500" : "red.600"
							}
							variant="unstyled"
							isDisabled={props.timecardHeader?.isApproved() || props.item?.isLocked()}
							onClick={deleteEntry}
						>
							<i
								className={
									"fas fa-fw fa-lg " +
									(props.timecardHeader?.isApproved() || props.item?.isLocked()
										? "fa-lock"
										: "fa-times-circle")
								}
							></i>
						</IconButton>
					</Tooltip>
				</Flex>
			</Portal>
		);
		return deleteButton;
	}, [deleteEntry, props.item, props.timecardHeader]);

	useEffect(() => {
		if (props?.timecardJobs.length === 0) {
			const payrollAPI = new PayrollAPI();
			const getTimecardJobs = async (employeeUID = portalUser?.user?.employeeUID) => {
				let newTimecardJobs = await payrollAPI.GetTimecardJobs(employeeUID);
				if (newTimecardJobs.status === 200) {
					newTimecardJobs = newTimecardJobs.value;
					for (let i = 0; i < newTimecardJobs.length; i++) {
						newTimecardJobs[i] = new TimecardJobClassLegacy(newTimecardJobs[i]);
					}
					setTimecardJobs(newTimecardJobs);
				}
			};
			getTimecardJobs();
		}
	}, [props?.timecardJobs.length]);

	return (
		<>
			{deleteButton}
			<Tr
				postion="relative"
				verticalAlign="center"
				key={props.item?.keyId ?? "entryRow-" + props.employee.employeeUID + props.rowIndex}
				role="group"
				bg="whiteAlpha.500"
				borderBottomWidth={2}
				borderColor="gray.200"
			>
				<Td px={2} position="relative" ref={ref} borderLeftWidth={2} borderLeftColor="gray.300">
					<Tooltip
						label={earnCodeMap[props?.item?.earnCode]?.description ?? ""}
						isDisabled={!Boolean(props?.item?.earnCode)}
						shouldWrapChildren={true}
						placement="bottom-start"
					>
						<DataInputSelectLegacy
							dataView={props.dataView}
							w="full"
							name="earnCodes"
							placeholder="TYPE"
							selectOptions={EARN_CODES}
							onChange={selectEarnCode}
							disabledItems={disabledEarnCodes}
							subtitle={"description"}
							isDisabled={props.timecardHeader?.isApproved() || props.item?.isLocked()}
							isInvalid={props.item?.earnCode === null && props.item?.job !== null}
							offset={[-18, 8]}
							value={earnCodeMap[props?.item?.earnCode]?.key ?? "TYPE"}
						/>
					</Tooltip>
				</Td>
				<Td key={v4()} minW="125px" maxW="125px" w="125px" px={2}>
					<Tooltip
						label={Boolean(props.item?.job) ? props.item?.jobDescription : ""}
						isDisabled={!props.item?.job}
						shouldWrapChildren={true}
						placement="bottom-start"
					>
						<DataInputSelectLegacy
							dataView={props.dataView}
							w="full"
							name="jobs"
							placeholder="JOB #"
							selectOptions={timecardJobs}
							value={props.item?.job ?? "JOB #"}
							onChange={selectJob}
							disabledItems={disabledJobs}
							isDisabled={props.timecardHeader?.isApproved() || props.item?.isLocked()}
							isInvalid={props.item?.earnCode !== null && props.item?.job === null}
							offset={[-18, 8]}
						/>
					</Tooltip>
				</Td>
				<Td key={v4()} minW="195px" px={2}>
					<Tooltip
						label={Boolean(props.item?.phase) ? props.item?.phaseDescription : ""}
						isDisabled={!props.item?.phase}
						shouldWrapChildren={true}
						placement="bottom-start"
					>
						<DataInputSelectLegacy
							dataView={"table"}
							w="full"
							name="phases"
							placeholder="PHASE"
							selectOptions={timecardJobs?.find(({ job }) => job === props.item?.job)?.phases}
							value={props.item?.phase ?? "PHASE"}
							onChange={selectPhase}
							disabledItems={disabledPhases}
							preOpen={preOpen}
							closePreOpen={closePreOpen}
							loadData={loadData}
							isDisabled={props.timecardHeader?.isApproved() || props.item?.isLocked()}
							isInvalid={props.item?.job !== null && props.item?.phase === null}
							offset={[-18, 8]}
						/>
					</Tooltip>
				</Td>
				<Td borderRight={2} borderRightColor="gray.200" px={2}>
					<Tooltip
						label={Boolean(props.item?.job) ? "Shift " + props.item?.shift : ""}
						isDisabled={!props.item?.shift && !props.item?.job}
						shouldWrapChildren={true}
						placement="bottom-start"
					>
						<DataInputSelectLegacy
							dataView={"table"}
							w="full"
							name="shifts"
							placeholder={null}
							selectOptions={[
								{ shift: 1, description: "1st Shift" },
								{ shift: 2, description: "2nd Shift" },
								{
									shift: 3,
									description:
										props?.employee?.craft === "DC16" ? "3rd Shift - Disabled" : "3rd Shift",
								},
							]}
							value={props.item?.shift}
							title={"shift"}
							subtitle={"description"}
							onChange={selectShift}
							disabledItems={props?.employee?.craft === "DC16" ? [3] : []}
							isDisabled={props.timecardHeader?.isApproved() || props.item?.isLocked()}
							isInvalid={props.item?.job !== null && props.item?.phase === null}
							offset={[-18, 8]}
						/>
					</Tooltip>
				</Td>
				{props.item?.hours.map((hour, j) => (
					<Td key={"hourInput-" + props.item?.keyId + "-" + j} maxW="100px" px={2}>
						<Tooltip
							label={
								props.timecardHeader?.isApproved() || props.item?.isLocked()
									? "Timecard Locked"
									: "Missing Labor Type, Job # or Phase"
							}
							isDisabled={
								!(props.timecardHeader?.isApproved() || props.item?.isLocked()) &&
								Boolean(props.item?.earnCode) &&
								Boolean(props.item?.job) &&
								Boolean(props.item?.phase) &&
								Boolean(props.item?.shift)
							}
							shouldWrapChildren={
								props.timecardHeader?.isApproved() ||
								props.item?.isLocked() ||
								!Boolean(props.item?.earnCode) ||
								!Boolean(props.item?.job) ||
								!Boolean(props.item?.phase) ||
								!Boolean(props.item?.shift)
							}
							placement="bottom-start"
						>
							<TimecardEntryHourInput
								item={props.item}
								hour={hour}
								onChange={updateHours}
								hourIndex={j}
								openNotes={openNotes}
								disabled={props.timecardHeader?.isApproved()}
							/>
						</Tooltip>
					</Td>
				))}
				<Td key={v4()} borderRightWidth={2} borderRightColor="gray.300" textAlign="center" maxW="100px" px={2}>
					<Heading size="sm" color="gray.500">
						{props.item?.getTotalHours().toFixed(1)}
					</Heading>
				</Td>
				{Boolean(notesOpen) && (
					<Modal isOpen={notesOpen} onClose={closeNotes} size="xl">
						<ModalOverlay />
						<ModalContent>
							<ModalHeader py={2}>Notes For {PR_WEEK_DAYS[dayNum]}</ModalHeader>
							<ModalCloseButton />
							<ModalBody>
								<Textarea
									defaultValue={props.item?.notes[dayNum]}
									index={dayNum}
									onBlur={updateNotes}
									name={"DayNotes" + dayNum}
									isReadOnly={props.timecardHeader?.isApproved() || props.item?.isLocked()}
								/>
							</ModalBody>
							<ModalFooter>
								<Button size="sm" colorScheme="red" py={2} onClick={closeNotes}>
									CLOSE
								</Button>
							</ModalFooter>
						</ModalContent>
					</Modal>
				)}
			</Tr>
		</>
	);
}
