import React, { useState, useEffect, useCallback } from "react";
import {
	Box,
	Flex,
	Center,
	Heading,
	Text,
	Stack,
	Button,
	HStack,
	IconButton,
	Link,
	Tag,
	TagLabel,
	useBoolean,
	Tooltip,
	Collapse,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import BasicCard from "../../../core/Layout/cards/BasicCard";
import ConfirmationPrompt from "../../../core/Alerts/ConfirmationPrompt";
import AnnouncementDrawer from "./AnnouncementsDrawer";
import Announcement from "../classes/Announcement";

import { portalUser } from "../../../App";
import { motion } from "framer-motion";
// import { log } from "../../../helperFunctions";

export default function AnnouncementsCard() {
	const [updateTrigger, setUpdateTrigger] = useState(false);
	const [announcements, setAnnouncements] = useState([]);
	const [regions, setRegions] = useState(null);
	const [editable, setEditable] = useState(false);
	const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);

	useEffect(() => {
		const init = () => {
			let announcements = portalUser?.getAnnouncements() ?? [];

			let regions = portalUser?.getRegions() ?? [];
			let editable = false;
			if (portalUser.user?.isPortalAdminYN === "Y" || portalUser.user?.isDevOpsUserYN === "Y") {
				editable = true;
			} else {
				// Filter By Region, Department or EmployeeType
				announcements =
					announcements?.filter((d) => d.regionID === portalUser.user?.regionID || d.regionID === null) ?? [];
			}

			setAnnouncements(announcements);
			setRegions(regions);
			setEditable(editable);
		};
		init();
	}, []);

	const newAnnouncement = async () => {
		let announcement = portalUser.tempData?.filter((d) => (d.queryData = "Announcement")) ?? [];
		if (portalUser.tempData?.length === 0) {
			announcement = new Announcement();
			portalUser.tempData = [announcement];
		} else {
			announcement = announcement[0];
		}
		setSelectedAnnouncement(announcement);
	};

	const saveAnnouncement = async (announcement) => {
		if (!Boolean(announcement?.keyID)) {
			announcement.insertSQL();
			portalUser?.userData.push(announcement);
		}
		let announcements = portalUser?.getAnnouncements() ?? [];

		setAnnouncements(announcements);
		if (selectedAnnouncement?.keyID === null) {
			portalUser.tempData = [selectedAnnouncement];
		} else {
			portalUser.tempData = [];
		}
		setSelectedAnnouncement(null);
		setUpdateTrigger(true);
	};

	const closeAnnouncement = () => {
		if (selectedAnnouncement?.keyID === null) {
			portalUser.tempData = [selectedAnnouncement];
		} else {
			portalUser.tempData = [];
		}
		setSelectedAnnouncement(null);
	};

	const editAnnouncement = async (announcement) => {
		if (Boolean(announcement?.announcementUID)) {
			setSelectedAnnouncement(announcement);
		} else {
			closeAnnouncement();
		}
	};

	const deleteAnnouncement = async (announcement) => {
		portalUser.userData = portalUser.userData?.filter((d) => d.announcementUID !== announcement?.announcementUID);
		if (Boolean(announcement?.keyID)) {
			await announcement.deleteSQL();
		}
		let announcements = portalUser?.getAnnouncements() ?? [];
		setAnnouncements(announcements);
		setSelectedAnnouncement(null);
		setUpdateTrigger(true);
	};

	// TO DO: Reorder by Seq
	// const onDragEnd = async (result) => {
	// 	log("announcemnents", announcements);
	// 	let sourceIndex = result.source.index;
	// 	let destinationIndex = result.destination.index;
	// 	await announcements.reorder(sourceIndex, destinationIndex);
	// 	setAnnouncements(announcements);
	// };

	const onDragEnd = async (result) => {
		if (!result.destination) {
			return; // dropped outside the list
		}
		const { source, destination } = result;
		const reorderedAnnouncements = Array.from(announcements);
		const [removed] = reorderedAnnouncements.splice(source.index, 1);
		reorderedAnnouncements.splice(destination.index, 0, removed);
		// Update the seq key based on the new order
		reorderedAnnouncements.forEach(async (announcement, index) => {
			announcement.seq = index + 1; // Assuming seq starts from 1
			// Update the announcement's seq in the database
			await announcement.update("seq", announcement.seq);
		});
		// Update the announcements state with the reordered list
		setAnnouncements(reorderedAnnouncements);
	};
	useEffect(() => {
		if (updateTrigger) {
			setUpdateTrigger(false);
		}
	}, [updateTrigger]);

	const cardOptions = (
		<Box hidden={!Boolean(portalUser.user?.isPortalAdminYN ?? portalUser.user?.isDevOpsUserYN === "Y")}>
			<Button
				variant="outline"
				onClick={newAnnouncement}
				color="gray.500"
				px={2}
				size="md"
				bg="whiteAlpha.700"
				shadow="md"
				textTransform="uppercase"
				_hover={{
					color: "blue.500",
					fontWeight: "bold",
					bg: "whiteAlpha.800",
					borderColor: "blue.500",
					textDecoration: "underline",
					borderWidth: 2,
				}}
			>
				<HStack align="center" spacing={1}>
					<Text lineHeight={1} className={"fas fa-plus fa-fw"} fontSize="sm" />
					<Text lineHeight={1} fontSize="sm" letterSpacing={1} pr={1}>
						Add
					</Text>
				</HStack>
			</Button>
		</Box>
	);

	return (
		<BasicCard
			title="Announcements"
			cardOptions={cardOptions}
			// portalStyles={portalUser.styles}
		>
			{Boolean(selectedAnnouncement) && (
				<AnnouncementDrawer
					isOpen={Boolean(selectedAnnouncement)}
					onClose={closeAnnouncement}
					announcement={selectedAnnouncement}
					editable={editable}
					saveAnnouncement={saveAnnouncement}
					deleteAnnouncement={deleteAnnouncement}
				/>
			)}

			{announcements?.length === 0 ? (
				<Center>
					<Heading color="gray.400" as="h5" size="md" textTransform={"uppercase"}>
						No New Announcments
					</Heading>
				</Center>
			) : (
				<DragDropContext onDragEnd={onDragEnd} enableDefaultSensors={editable}>
					<Droppable droppableId="droppable">
						{(provided, snapshot) => (
							<Stack {...provided.droppableProps} ref={provided?.innerRef} spacing={3}>
								{announcements?.map(
									(announcement, i) =>
										Boolean(editable || (!editable && announcement.isVisibleYN === "Y")) && (
											<AnnouncementsCardRow
												key={i}
												index={i}
												announcement={announcement}
												regions={regions}
												editable={editable}
												provided={provided}
												editAnnouncement={editAnnouncement}
												deleteAnnouncement={deleteAnnouncement}
											/>
										)
								)}
								{provided.placeholder}
							</Stack>
						)}
					</Droppable>
				</DragDropContext>
			)}
		</BasicCard>
	);
}

export function AnnouncementsCardRow({
	index = 0,
	announcement = new Announcement(),
	regions = [],
	editable = false,
	editAnnouncement,
	deleteAnnouncement,
}) {
	let announcementType = announcement?.getAnnouncementType();
	const isPortalAdmin = portalUser.user.isPortalAdminYN === "Y";
	let region = regions?.find((d) => d.regionID === announcement?.regionID) ?? null;
	let employeeType = announcement.getEmployeeType();

	const [isHovering, setIsHovering] = useBoolean(false);
	const [editHover, setEditHover] = useBoolean(false);
	const [deleteHover, setDeleteHover] = useBoolean(false);
	const [deleteLoading, setDeleteLoading] = useState(false);

	const removeAnnouncement = useCallback(
		async (announcement) => {
			setDeleteLoading(true);
			await deleteAnnouncement(announcement);
			setDeleteLoading(false);
		},
		[deleteAnnouncement]
	);

	return (
		<Draggable
			key={announcement?.announcementUID}
			draggableId={announcement?.announcementUID}
			index={index}
			isDragDisabled={!editable}
		>
			{(provided, snapshot) => (
				<motion.div
					whileHover={{ scale: 1.01, transition: { duration: 0.5 } }}
					style={{ position: "relative" }}
					onMouseEnter={setIsHovering.on}
					onMouseLeave={setIsHovering.off}
				>
					{Boolean(editable) && (
						<>
							<Flex
								w="full"
								justify="flex-end"
								position="absolute"
								top={0}
								p="2"
								flex={1}
								borderTopRightRadius={10}
								borderTopLeftRadius={10}
								onMouseEnter={setIsHovering.on}
								onMouseLeave={setIsHovering.off}
								minH={6}
							></Flex>

							<Collapse in={isHovering} style={{ overflow: "visible" }}>
								<Flex
									w="full"
									justify={"flex-end"}
									position="absolute"
									top={0}
									flex={1}
									p={1}
									bgGradient={
										isHovering
											? "linear(to-b, blackAlpha.200, whiteAlpha.200)"
											: "linear(to-b, transparent, transparent)"
									}
									borderTopRightRadius={10}
									borderTopLeftRadius={10}
									onMouseEnter={setIsHovering.on}
									minH={8}
								>
									<HStack spacing={1} p={1} rounded={5} zIndex={2}>
										<Tooltip label={"Edit"} position="top">
											<IconButton
												onClick={() => editAnnouncement(announcement)}
												bg="whiteAlpha.800"
												_hover={{
													bg: "white",
													borderColor: "blue.500",
													color: "blue.500",
												}}
												color="gray.400"
												boxSize="25px"
												minW="25px"
												p={2}
												borderWidth={2}
												icon={<i className={"fas fa-cog fa-fw "} />}
												onMouseLeave={setEditHover.off}
												onMouseEnter={setEditHover.on}
											></IconButton>
										</Tooltip>
										<ConfirmationPrompt
											useIconButton
											confirmationFunction={() => removeAnnouncement(announcement)}
											icon="fa-times"
											alertDialogHeader="Delete Announcement"
											alertDialogBody="Are you sure you would like to delete this Announcement? The data for the Announcement will be deleted permanently."
											alertDialogFooter="Tip: You can set the visibility of announcement to hidden if you would like to create announcements in advance but keep them hidden from employees."
											isLoading={deleteLoading}
											loadingText="Deleting..."
											onMouseLeave={setDeleteHover.off}
											onMouseEnter={setDeleteHover.on}
										/>
									</HStack>
								</Flex>
							</Collapse>
						</>
					)}

					<Flex
						w="full"
						_hover={{
							bg: announcementType?.color
								?.replace(".600", ".50")
								?.replace(".500", ".50")
								?.replace(".400", ".50"),
							shadow: "lg",
							borderWidth: 2,
							borderColor: editHover
								? "blue.500"
								: deleteHover
								? "red.500"
								: announcementType?.color ?? "gray.500",
						}}
						rounded="md"
						p={4}
						shadow={portalUser.styles?.cardShadow}
						opacity={announcement?.isVisibleYN === "Y" ? 1 : 0.5}
						borderStyle={announcement?.isVisibleYN === "Y" ? "solid" : "dashed"}
						borderWidth={2}
						borderColor={
							editHover ? "blue.600" : deleteHover ? "red.600" : portalUser.styles?.cardBorderColor
						}
						bg={editHover ? "blue.50" : deleteHover ? "red.50" : "whiteAlpha.800"}
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						justify="space-between"
						key={
							announcement?.announcementUID +
							(deleteHover || editHover ? "-AnnouncementRow" : "-AnnouncementRow")
						}
					>
						<HStack spacing={4} w="full" align="center">
							<Stack spacing={2} opacity={editHover || deleteHover ? 0.75 : 1}>
								<IconButton
									size="md"
									variant="unstyled"
									color={
										editHover
											? "blue.500"
											: deleteHover
											? "red.500"
											: announcementType?.color ?? "gray.500"
									}
									bg={deleteHover || editHover ? "whiteAlpha.800" : "whiteAlpha.200"}
									borderWidth={2}
									borderColor={
										editHover
											? "blue.600"
											: deleteHover
											? "red.600"
											: announcementType?.color ?? "gray.500"
									}
									shadow="md"
									isRound
									boxSize="70px"
									key={
										announcement?.announcementUID +
										(editHover ? "-editButton" : deleteHover ? "-deleteButton" : "-iconButton")
									}
									p={1}
								>
									<i
										className={
											(editHover
												? "fas fa-cog"
												: deleteHover
												? "fas fa-trash"
												: announcementType?.icon) + " fa-3x"
										}
									/>
								</IconButton>
								<Stack spacing={1} w="full" justify="center">
									{announcement.isVisibleYN === "N" && (
										<Tag
											size="md"
											color={"white"}
											bg={"gray.500"}
											variant="solid"
											textTransform="uppercase"
										>
											<TagLabel textAlign="center" mx="auto" p={1}>
												Hidden
											</TagLabel>
										</Tag>
									)}

									{/* {(deleteHover || editHover) && (
										<Tag
											size="md"
											color={"white"}
											bg={deleteHover ? "red.600" : editHover ? "blue.600" : "gray.500"}
											variant="solid"
											textTransform="uppercase"
										>
											<TagLabel
												textAlign="center"
												mx="auto"
												p={1}
												letterSpacing={deleteHover ? 0 : 1}
											>
												{deleteHover ? "Delete" : "Edit"}
											</TagLabel>
										</Tag>
									)} */}

									{Boolean(region) && !deleteHover && !editHover && isPortalAdmin && (
										<Tag
											size="md"
											color={"white"}
											bg={deleteHover || editHover ? "gray.500" : region?.bg}
											variant="solid"
											textTransform="uppercase"
										>
											<TagLabel textAlign="center" p={1} mx="auto">
												{region?.region}
											</TagLabel>
										</Tag>
									)}

									{Boolean(announcement?.employeeType) &&
										!deleteHover &&
										!editHover &&
										isPortalAdmin && (
											<Tag
												size="md"
												color={deleteHover || editHover ? "whiteAlpha.800" : "white"}
												bg={deleteHover || editHover ? "gray.500" : employeeType?.color}
												variant="solid"
												textTransform="uppercase"
											>
												<TagLabel textAlign="center" p={1} mx="auto">
													{announcement?.employeeType}
												</TagLabel>
											</Tag>
										)}
								</Stack>
							</Stack>
							<Stack w="full" flex={1} spacing={1}>
								{Boolean(announcementType?.label) && (
									<Heading
										as={"span"}
										size="md"
										letterSpacing={1}
										pr={2}
										color={
											deleteHover || editHover
												? "gray.500"
												: announcementType?.color ?? "gray.500"
										}
										textTransform={"uppercase"}
									>
										{announcementType?.label}
									</Heading>
								)}
								<Text
									as="h6"
									size="sm"
									color="gray.500"
									opacity={editHover || deleteHover ? 0.75 : 1}
									w="full"
								>
									{announcement?.description ??
										" e.g. The PPM Portal will be down for maintenance..."}
									{Boolean(announcement?.redirectURL) && (
										<>
											{announcement?.redirectURL?.includes("www.") ||
											announcement?.redirectURL?.includes("https://") ? (
												<Link
													px={2}
													fontWeight="bold"
													textDecoration="underline"
													color="blue.600"
													href={announcement?.redirectURL}
													isExternal
												>
													{announcement?.urlDisplayText}
												</Link>
											) : (
												<RouterLink
													style={{
														textDecoration: "underline",
														color: "var(--chakra-colors-blue-600)",
														fontWeight: "bold",
														paddingLeft: "var(--chakra-space-2)",
														paddingRight: "var(--chakra-space-2)",
													}}
													to={`${announcement?.redirectURL}`}
												>
													{announcement?.urlDisplayText}
												</RouterLink>
											)}
										</>
									)}
								</Text>
							</Stack>
						</HStack>
					</Flex>
				</motion.div>
			)}
		</Draggable>
	);
}
