import moment from "moment";
import { portalUser } from "../../../App";
import SectionTemplateManpowerActuals from "./SectionTemplateManpowerActuals";
import SectionTemplateManpowerProjections from "./SectionTemplateManpowerProjections";
export default class PageTemplateManpowerProjections {
	constructor(selectedData = null, thruMth = null) {
		let contract = {};
		let region = {};
		let contractTotalsByMth = [];
		let manpowerProjectionsByMth = [];
		let contractTotals = null;

		if (Boolean(selectedData.length > 0)) {
			contract = selectedData?.filter((p) => p.queryData === "Contract")[0] ?? {};
			region = portalUser?.getRegions()?.filter((p) => p.regionID === contract?.regionID)[0] ?? {};
			contractTotalsByMth = selectedData?.filter((p) => p.queryData === "ContractTotalsByMth") ?? [];
			manpowerProjectionsByMth = selectedData?.filter((p) => p.queryData === "ManpowerProjectionsByMth") ?? [];

			contractTotalsByMth =
				contractTotalsByMth?.filter((p) => new Date(p.mth).getTime() <= new Date(thruMth).getTime()) ?? [];
			contractTotals =
				contractTotalsByMth?.filter(
					(p) => moment(new Date(p.mth)).format("MM/YY") === moment(new Date(thruMth)).format("MM/YY")
				)[0] ?? null;

			manpowerProjectionsByMth =
				manpowerProjectionsByMth?.filter((p) => new Date(p.mth).getTime() >= new Date(thruMth).getTime()) ?? [];
		}

		this.pageTemplate = "ManpowerProjections";
		this.pageName = "Manpower Projections";
		this.pageTitle = this.getPageTitle(contract, thruMth);
		this.region = region;
		this.size = "C4";
		this.orientation = "landscape";
		this.pageBody = [
			new SectionTemplateManpowerActuals(contractTotalsByMth, contractTotals),
			new SectionTemplateManpowerProjections(manpowerProjectionsByMth, contractTotals),
		];
	}

	getPageTitle(contract = null, thruMth = null) {
		let pageTitle = "Report Template";
		if (Boolean(contract?.contract)) {
			pageTitle = contract?.contract + " " + contract?.description;
			pageTitle +=
				Boolean(contract) && Boolean(thruMth) ? " Thru " + moment(new Date(thruMth)).format("MMM YYYY") : "";
		}
		return pageTitle;
	}
}
