import React, { useEffect } from "react";
import { Box, Stack } from "@chakra-ui/react";
import OverlayDrawer from "./OverlayDrawer";
import { useSpring, animated } from "@react-spring/web";
import { portalUser } from "../../../App";

export default function OverlayPage(props) {
	const [springProps, springApi] = useSpring(() => ({ transform: "translateX(100vw)" }));
	const [isOpen, setOpen] = React.useState(props.isOpen);

	useEffect(() => {
		springApi.start({ transform: isOpen ? "translateX(0)" : "translateX(100vw)" });
	}, [isOpen, springApi]);

	// const onClose = () => {
	// 	setOpen(false);
	// 	if (props.onClose) {
	// 		props.onClose();
	// 	}
	// };

	useEffect(() => {
		setOpen(props.isOpen);
	}, [props.isOpen]);

	const AnimatedDrawer = animated(OverlayDrawer);

	return (
		<>
			{Boolean(props.children) ? (
				<Stack direction="row" w="full" flex={props.flex} bg={portalUser.styles.pageBG}>
					{(!props.isOpen || (Boolean(props.overlaySize) && props.overlaySize !== "full")) && (
						<Box flex={4} display={{ base: props.isOpen ? "none" : "block", md: "block" }}>
							{props.children}
						</Box>
					)}
					{props.isOpen && Boolean(props.childComponent) && (
						<AnimatedDrawer bg="transparent" style={springProps} {...props} onClose={props.onClose} />
					)}
				</Stack>
			) : !props.isOpen && Boolean(props.parentComponent) ? (
				<Box flex={props.flex}>{props.parentComponent}</Box>
			) : (
				<Box flex={props.flex}>{props.children}</Box>
			)}
		</>
	);
}
