import React, { useMemo, useCallback } from "react";
import { Flex, Heading, Stack, IconButton, HStack } from "@chakra-ui/react";
import BasicCard from "../../../core/Layout/cards/BasicCard";
import VirtualListV3 from "../../../core/ReactTable/components/VirtualListV3";
import TeamReviewsToDoListRow from "./TeamReviewsToDoListRow";
import { portalUser } from "../../../App";
import { convertArrayToMap } from "../../../helperFunctions";

export default function TeamReviewsToDoList(props) {
	const panel = useMemo(() => props.panel, [props.panel]);
	let users = portalUser?.userData.filter((d) => d.queryData === "User") ?? [];
	let departments = portalUser.getDepartments();
	let regions = portalUser.getRegions();
	let usersMap = convertArrayToMap(users, "employeeUID");
	let deptMap = convertArrayToMap(departments, "deptID");
	let regionMap = convertArrayToMap(regions, "regionID");

	const data = useMemo(() => [...new Set(props.forms)], [props.forms]);

	const columns = useMemo(() => {
		let columns = [
			{
				Header: "Form UID",
				id: "formUID",
				disableGroupBy: true,
				disableFilters: true,
				disableSortBy: true,
				accessor: (row) => row.formUID,
				Footer: (rows) => {
					// Only calculate total visits if rows change
					const rowCount = useMemo(() => rows?.length, [rows?.length]);
					return rowCount;
				},
			},
			{ Header: "Year", id: "formYear", accessor: (row) => row?.formYear },
			{ Header: "Form Title", id: "formTitle", accessor: (row) => row.formTitle },
			{
				Header: "Employee Status",
				id: "userStatus",
				accessor: (row) => usersMap[row.assignedFor?.toLowerCase()]?.userStatus ?? "Active",
			},
			{
				Header: "Assigned For UID",
				id: "assignedFor",
				disableGroupBy: true,
				disableFilters: true,
				disableSortBy: true,
				accessor: (row) => row.assignedFor?.toLowerCase(),
			},
			{
				Header: "Employee Name",
				id: "assignedForName",
				accessor: (row) => usersMap[row.assignedFor?.toLowerCase()]?.name ?? null,
			},
			{
				Header: "Parent Dept",
				id: "parentDept",

				accessor: (row) =>
					deptMap[deptMap[usersMap[row.assignedFor?.toLowerCase()]?.deptID]?.parentDeptID]?.dept ?? null,
			},
			{
				Header: "Dept",
				id: "dept",
				accessor: (row) => deptMap[usersMap[row.assignedFor?.toLowerCase()]?.deptID]?.dept ?? null,
			},

			{
				Header: "Assigned To UID",
				id: "assignedTo",
				disableFilters: true,
				disableSortBy: true,
				disableGroupBy: true,
				accessor: (row) => row.assignedTo?.toLowerCase(),
			},
			{
				Header: "Reviewer",
				id: "assignedToName",
				disableFilters: true,
				accessor: (row) => usersMap[row.assignedTo?.toLowerCase()]?.name ?? null,
			},
			{
				Header: "Status ID",
				id: "id",
				disableFilters: true,
				disableSortBy: true,
				disableGroupBy: true,
				show: false,
				accessor: (row) => row.getFormStatus()?.id,
			},
			{
				Header: "Review Status",
				id: "status",
				accessor: (row) => row.getFormStatus()?.status,
			},
			{
				Header: "Importance",
				id: "importanceLevel",
				disableFilters: true,
				disableSortBy: true,
				disableGroupBy: true,
				accessor: (row) => row.getFormStatus()?.importanceLevel,
			},
			{
				Header: "To Do List",
				id: "todoList",

				disableFilters: true,
				disableSortBy: true,
				disableGroupBy: true,

				accessor: (row) => row.getFormStatus()?.todoList,
			},

			{
				Header: "Form Type ID",
				id: "formType",

				disableFilters: true,
				disableSortBy: true,
				disableGroupBy: true,

				accessor: (row) => row?.formType ?? null,
			},
			{
				Header: "Form Type",
				id: "formTypeDesc",
				accessor: (row) => row?.formTypeDesc ?? null,
			},
			{
				Header: "Completed Evals",
				id: "completedEvals",

				disableFilters: true,
				disableSortBy: true,
				disableGroupBy: true,

				accessor: (row) => row.getPeerStatus()?.completedEvals ?? null,
			},
			{
				Header: "Total Evals",
				id: "totalEvals",

				disableFilters: true,
				disableSortBy: true,
				disableGroupBy: true,

				accessor: (row) => row.getPeerStatus()?.totalEvals ?? null,
			},
			{
				Header: "Requires Review",
				id: "requiresReviewYN",

				disableFilters: true,
				disableSortBy: true,
				disableGroupBy: true,

				accessor: (row) => row?.requiresReviewYN ?? "N",
			},
		];
		return columns;
	}, [deptMap, usersMap]);

	let initialStateToDoList = useMemo(() => {
		let initialState = {
			defaultFilters: [...panel?.defaultFilters, { id: "todoList", value: "In Progress" }],
			defaultSortAttr: [...panel?.defaultSortAttr],
			defaultGroupBy: [...panel?.defaultGroupBy],
			defaultExpanded: panel?.defaultExpanded,
			defaultExpandAll: panel?.defaultExpandAll ?? false,
		};
		return initialState;
	}, [
		panel?.defaultFilters,
		panel?.defaultSortAttr,
		panel?.defaultGroupBy,
		panel?.defaultExpanded,
		panel?.defaultExpandAll,
	]);

	let initialStateCompletedList = useMemo(() => {
		let initialState = {
			defaultFilters: [...panel?.defaultFilters, { id: "todoList", value: "Completed" }],
			defaultSortAttr: [...panel?.defaultSortAttr],
			defaultGroupBy: [...panel?.defaultGroupBy],
			defaultExpanded: panel?.defaultExpanded,
			defaultExpandAll: panel?.defaultExpandAll ?? false,
		};
		return initialState;
	}, [
		panel?.defaultFilters,
		panel?.defaultSortAttr,
		panel?.defaultGroupBy,
		panel?.defaultExpanded,
		panel?.defaultExpandAll,
	]);

	const RenderRow = useCallback(
		({ row, index, style }) => {
			if (row.isGrouped) {
				let columns = row?.cells?.map((tab) => tab.column);
				let column = columns?.find((d) => d.id === row?.groupByID) ?? null;
				let statuses = {};
				for (let i = 0; i < row?.leafRows?.length; i++) {
					let reviewStatus = row?.leafRows[i].original?.getFormStatus();
					if (!Boolean(statuses[reviewStatus?.status])) {
						statuses[reviewStatus?.status] = {
							id: reviewStatus.id,
							importanceLevel: reviewStatus.importanceLevel,
							todoList: reviewStatus.todoList,
							icon: reviewStatus.icon,
							color: reviewStatus.color,
							count: 0,
						};
					}
					statuses[reviewStatus?.status].count = statuses[reviewStatus?.status].count + 1;
				}

				return (
					<Flex
						{...row.getRowProps({ style })}
						flex={1}
						style={style}
						w="full"
						p={3}
						h="full"
						overflow="hidden"
						align="center"
						borderBottomColor="blackAlpha.300"
						borderBottomWidth={2}
					>
						<Flex w="full" flex={1} align="center" justify="space-between" py={2}>
							<HStack spacing={4}>
								<IconButton
									size={"lg"}
									key={"GroupBy-" + row?.groupByVal + (row.isExpanded ? "-Open" : "-Closed")}
									onClick={() => row.toggleRowExpanded()}
									borderRadius="md"
									borderWidth={2}
									borderColor="gray.300"
									shadow="md"
									fontWeight="semibold"
									bg="whiteAlpha.700"
									color="gray.500"
									_hover={{
										color: "teal.500",
										borderColor: "teal.500",
										borderWidth: "2px",
									}}
									_focus={{ boxShadow: "outline", borderWidth: "2px" }}
									icon={
										<i
											className={
												"fas " + (row.isExpanded ? "fa-minus" : "fa-plus") + " fa-fw fa-lg"
											}
										/>
									}
								/>

								<Stack spacing={0} justify="flex-end">
									<Heading
										letterSpacing={2}
										size="sm"
										color="gray.400"
										textShadow="2px 2px 2px white"
										textTransform="uppercase"
										isTruncated
										opacity={0.9}
									>
										{column?.Header}
									</Heading>

									<Heading
										letterSpacing={3}
										size="lg"
										color="gray.600"
										textShadow="2px 2px 2px white"
										textTransform="uppercase"
										isTruncated
										opacity={0.9}
									>
										{row?.groupByVal}
									</Heading>
								</Stack>
							</HStack>
						</Flex>
					</Flex>
				);
			} else {
				return (
					<Flex
						{...row.getRowProps({ style })}
						flex={1}
						w="full"
						h="full"
						overflow="hidden"
						align="center"
						bg="blackAlpha.100"
					>
						<TeamReviewsToDoListRow
							style={style}
							key={index}
							usersMap={usersMap}
							deptMap={deptMap}
							regionMap={regionMap}
							form={row.original}
							selectForm={props.selectForm}
							openDrawer={props.openDrawer}
						/>
					</Flex>
				);
			}
		},
		[usersMap, deptMap, regionMap, props.selectForm, props.openDrawer]
	);

	return (
		<Stack
			borderRadius="lg"
			w="full"
			flex={1}
			direction={{ base: "column", lg: "row" }}
			spacing={portalUser.styles?.pageSpacing}
		>
			<BasicCard title={"In Progress"} flex={1} portalStyles={portalUser.styles}>
				<VirtualListV3
					minH={135}
					listName={"uncompleted"}
					data={data}
					columns={columns}
					renderRow={RenderRow}
					listHeight={775}
					defaultSortAttr={initialStateToDoList.defaultSortAttr}
					defaultFilters={initialStateToDoList.defaultFilters}
					defaultGroupBy={initialStateToDoList.defaultGroupBy}
					defaultExpanded={initialStateToDoList.defaultExpanded}
					expandAll={initialStateCompletedList.defaultExpandAll}
				/>
			</BasicCard>

			<BasicCard title={"Completed"} flex={1} portalStyles={portalUser.styles}>
				<VirtualListV3
					minH={135}
					listName={"completed"}
					data={data}
					columns={columns}
					renderRow={RenderRow}
					listHeight={775}
					defaultSortAttr={initialStateCompletedList.defaultSortAttr}
					defaultFilters={initialStateCompletedList.defaultFilters}
					defaultGroupBy={initialStateCompletedList.defaultGroupBy}
					defaultExpanded={initialStateCompletedList.defaultExpanded}
					expandAll={initialStateCompletedList.defaultExpandAll}
				/>
			</BasicCard>
		</Stack>
	);
}
