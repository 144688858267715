import React from "react";
import {
	NumberInput as ChakraUINumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
} from "@chakra-ui/react";

export default function NumberInput(props) {
	return (
		<ChakraUINumberInput
			variant={props.variant ?? "filled"}
			name={props.name}
			value={props.value}
			onChange={props.onChange}
			onBlur={props.onBlur}
			colorScheme={props.colorScheme}
			bg={props.inputProps?.bg ?? "white"}
			size={props.size}
			borderRadius="md"
			defaultValue={props.defaultValue}
			readOnly={props.readOnly}
			max={props.max}
			{...props.inputProps}
		>
			<NumberInputField borderRadius="md" />

			<NumberInputStepper>
				<NumberIncrementStepper />
				<NumberDecrementStepper />
			</NumberInputStepper>
		</ChakraUINumberInput>
	);
}
