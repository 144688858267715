import React, { forwardRef, useState } from "react";
import { Text, Button, Portal } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { formatValue } from "../../../helperFunctions";
import moment from "moment";

export const DateInput = forwardRef((props, ref) => {
	const [startDate, setStartDate] = useState(Boolean(props.value) ? new Date(props.value) : null);

	const handleCalendarClose = () => {
		if (Boolean(props.onChange)) {
			props.onChange(startDate, props.name);
		}

		if (Boolean(props.onCalendarClose)) {
			props.onCalendarClose(props.name);
		}
	};

	const CustomButtonInput = forwardRef(({ value, onClick }, ref) => {
		let formattedDate = moment(Boolean(value) ? new Date(value) : new Date());
		formattedDate = formattedDate.isValid()
			? formatValue(formattedDate.format("MM/DD/YYYY"), 0, props?.valueType ?? "date")
			: value;

		return (
			<Button
				{...props.inputProps}
				fontWeight={props.fontWeight ?? "normal"}
				w="full"
				className="focusBorderColorTeal"
				variant={props.variant ?? "outline"}
				color={props.inputProps?.color ?? "gray.600"}
				borderBottom={props.variant === "flushed" ? "1px" : ""}
				borderBottomColor={props.borderBottomColor ?? "gray.200"}
				rounded={props.variant === "flushed" ? "0px" : "5px"}
				size={props.size}
				px="0"
				onClick={onClick}
				colorScheme={props.colorScheme}
				readOnly={props.readOnly}
				ref={ref}
				bg={props.inputProps?.bg ?? "white"}
				hover={{ bg: "whiteAlpha.600" }}
			>
				<Text
					w="full"
					textAlign={props.inputProps?.textAlign ?? "center"}
					px={2}
					color={value && !Boolean(props.showPlaceholder) ? "inherit" : "gray.400"}
				>
					{formattedDate ?? (Boolean(props.showPlaceholder) && (props.placeholder ?? "mm/dd/yyyy"))}
				</Text>
			</Button>
		);
	});

	const CustomPopperContainer = ({ children }) => {
		return <Portal>{children}</Portal>;
	};

	return (
		<DatePicker
			{...props.inputProps}
			disabled={props.disabled}
			className={props.className + " datepickerButton focusBorderColorTeal"}
			placeholderText={props.name ?? props.placeholder}
			// placeholderText="mm/dd/yyyy"
			popperProps={{
				positionFixed: true, // use this to make the popper position: fixed
			}}
			onChange={(date) => {
				setStartDate(date);
			}}
			onCalendarClose={handleCalendarClose}
			showMonthYearPicker={props.inputProps?.showMonthYearPicker ?? props.showMonthYearPicker ?? false}
			showMonthDropdown={props.inputProps?.showMonthYearPicker ?? props.showMonthYearPicker ? false : true}
			showYearDropdown={props.inputProps?.showMonthYearPicker ?? props.showMonthYearPicker ? false : true}
			// autoFocus={props.inputProps?.autoFocus ?? false}
			// peekNextMonth={props.inputProps?.showMonthYearPicker ? false : true}
			// dropdownMode="select"
			startOpen={props.inputProps?.startOpen ?? false}
			shouldCloseOnSelect={props.inputProps?.shouldCloseOnSelect ?? true}
			selected={startDate}
			// selected={Boolean(props.value) ? startDate : null}
			minDate={props.minDate ?? new Date("1/1/1980")}
			maxDate={props.maxDate ?? new Date("1/1/2050")}
			showTimeSelect={props.showTimeSelect}
			customInput={<CustomButtonInput ref={ref} />}
			minTime={new Date(new Date(startDate).setHours(6)).setMinutes(0)}
			maxTime={new Date(new Date(startDate).setHours(18)).setMinutes(0)}
			popperContainer={!props.isModal && CustomPopperContainer}
		/>
	);
});

export default DateInput;
