import React, { useMemo } from "react";
import { Heading, GridItem, SimpleGrid, Stack } from "@chakra-ui/react";
import BasicCard from "../../../core/Layout/cards/BasicCard";
import FormTemplateCard from "./HRTemplatesCard";
import HR_FORM_TYPES from "../../../core/Forms/constants/FormTemplateTypes";
import { convertArrayToMap } from "../../../helperFunctions";
import { portalUser } from "../../../App";

const formTypeDescMap = convertArrayToMap(HR_FORM_TYPES, "formType");

export default function HRTemplateCards(props) {
	const selectedYear = useMemo(() => props.selectedYear, [props.selectedYear]);
	const templates = useMemo(() => props.templates, [props.templates]);
	const formTypesByYear = useMemo(() => {
		let formYearMap = convertArrayToMap(templates, "formYear", true);
		let templatesByYear = formYearMap[selectedYear] ?? [];
		let formTypesMap = convertArrayToMap(templatesByYear, "formType", true);
		let formTypesByYear = [];
		for (let i = 1; i < 4; i++) {
			formTypesByYear.push({
				formType: i,
				formTypeDesc: formTypeDescMap[i].formTypeDesc,
				templates: formTypesMap[i] ?? [],
			});
		}
		return formTypesByYear;
	}, [selectedYear, templates]);

	return (
		<Stack spacing={8} py={4}>
			{formTypesByYear?.map((formType, i) => (
				<BasicCard
					title={formType.formTypeDesc + "s " + selectedYear}
					portalStyles={portalUser.styles}
					flex={0}
				>
					{formType.templates?.length > 0 ? (
						<SimpleGrid columns={[1, 2, 2, 3, 4]} spacing={4}>
							{formType.templates?.map((template, j) => (
								<GridItem key={j}>
									<FormTemplateCard
										template={template}
										selectForm={props.selectForm}
										// openAssignModal={this.openAssignModal}
										// deleteFormTemplate={this.deleteFormTemplate}
									/>
								</GridItem>
							))}
						</SimpleGrid>
					) : (
						<Heading as="i" size="sm" color="gray.400">
							{"N/A - No " + formType.formTypeDesc + " Templates"}
						</Heading>
					)}
				</BasicCard>
			))}
		</Stack>
	);
}
