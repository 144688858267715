import React from "react";
import { Stack, Flex, HStack, Tag, TagLabel, TagCloseButton } from "@chakra-ui/react";
import { log } from "../../../helperFunctions";

export default function DataTags(props) {
	return (
		<Stack spacing={0}>
			<HStack direction="row" spacing={0} wrap={"wrap"} justify="flex-start">
				{props.headerGroups.map((headerGroup) =>
					headerGroup.headers.map((column, i) =>
						column.isGrouped && column.canGroupBy ? (
							<Flex p={1}>
								<Tag key={i} size="sm" colorScheme="blue">
									<TagLabel>Group By: {column.render("Header")}</TagLabel>
									<TagCloseButton {...column.getGroupByToggleProps()} />
								</Tag>
							</Flex>
						) : (
							<></>
						)
					)
				)}
			</HStack>
			<HStack direction="row" spacing={0} wrap={"wrap"} justify="flex-start">
				{props.headerGroups.map((headerGroup) =>
					headerGroup.headers.map((column, i) =>
						Boolean(column.filterValue) && column.canFilter ? (
							<Flex p={1}>
								<Tag key={i} size="sm" colorScheme="green">
									<TagLabel>
										{column.render("Header")}: {column.filterValue}
									</TagLabel>
									<TagCloseButton
										onClick={(ev) => {
											ev.stopPropagation();
											column.setFilter(undefined);
										}}
									/>
								</Tag>
							</Flex>
						) : (
							<></>
						)
					)
				)}
			</HStack>
			<HStack direction="row" spacing={0} wrap={"wrap"} justify="flex-start">
				{props.headerGroups.map((headerGroup) =>
					headerGroup.headers.map((column, i) =>
						column.isSorted && column.canSort ? (
							<Flex p={1}>
								<Tag key={i} size="sm" colorScheme="teal">
									<TagLabel>Sort By: {column.render("Header")}</TagLabel>
									<TagCloseButton {...column.getSortByToggleProps()} />
								</Tag>
							</Flex>
						) : (
							<></>
						)
					)
				)}
			</HStack>
		</Stack>
	);
}
