import React, { useState, useMemo, useEffect, useCallback } from "react";
import {
	Box,
	Heading,
	Avatar,
	Stack,
	Text,
	Portal,
	Popover,
	PopoverTrigger,
	PopoverContent,
	useBoolean,
	HStack,
	Divider,
} from "@chakra-ui/react";
import { portalUser } from "../../../App";
import { convertArrayToMap } from "../../../helperFunctions";

export default function EmployeePopover({ msUserID = null, employeeUID = null, children }) {
	const [isHovering, setHovering] = useBoolean();
	const [hoveringTimeout, setHoveringTimeout] = useState(null);
	const [isCheckingHover, setCheckHover] = useBoolean();

	const [user, setUser] = useState(null);

	var regionMap = convertArrayToMap(portalUser?.getRegions(), "regionID");
	var deptMap = convertArrayToMap(portalUser?.getDepartments(), "deptID");

	const initUser = useCallback(async (id, attr) => {
		let user = await portalUser.getUser(id, attr);
		setUser(user);
	}, []);

	useMemo(() => {
		if (isHovering && msUserID) {
			initUser(msUserID, "msUserID");
		} else if (isHovering && employeeUID) {
			initUser(employeeUID, "employeeUID");
		}
	}, [isHovering, msUserID, employeeUID, initUser]);

	useMemo(() => {
		let timeout = setTimeout(() => {
			setCheckHover.on();
		}, 1000);
		setHoveringTimeout(timeout);
	}, [setCheckHover]);

	useMemo(() => {
		if (isCheckingHover) {
			if (isHovering) {
				setHovering.on();
			} else {
				setHovering.off();
			}
			setCheckHover.off();
		}
	}, [isCheckingHover, isHovering, setCheckHover, setHovering]);

	const handleMouseOver = () => {
		setHovering.on();
	};

	const handleMouseLeave = () => {
		let hoveringTimer = hoveringTimeout;
		clearTimeout(hoveringTimer);
		setHoveringTimeout(null);
		setHovering.off();
	};

	const linkHoverStyle = {
		cursor: "pointer",
		color: "blue.600",
		textDecoration: "underline",
	};

	return (
		<Popover isOpen={isHovering} isLazy>
			<PopoverTrigger>
				<Box onMouseEnter={handleMouseOver} onMouseLeave={handleMouseLeave}>
					{Boolean(children) && children}
					{!Boolean(children) && (
						<Avatar size="xs" onMouseEnter={handleMouseOver} onMouseLeave={handleMouseLeave} />
					)}
				</Box>
			</PopoverTrigger>
			<Portal>
				<PopoverContent onMouseEnter={handleMouseOver} onMouseLeave={handleMouseLeave}>
					{Boolean(user?.employeeUID) && (
						<Box
							p={2}
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							<HStack align="center" p={2} spacing={3}>
								<Avatar size="lg" src={user?.profilePhoto} />

								<Stack flex={1} spacing={1} color="gray.600">
									<Heading
										size="md"
										color="gray.500"
										fontWeight="semibold"
										letterSpacing={1}
										textTransform="uppercase"
									>
										{user?.getName()}
									</Heading>
									<Stack flex={1} spacing={0} color="gray.600" fontSize="xs">
										<Text>{user?.title}</Text>
										<Text>
											{deptMap[user?.deptID]?.dept} - {regionMap[user?.regionID]?.description}
										</Text>
									</Stack>
								</Stack>
							</HStack>
							<Divider />

							<Stack spacing={2} p={2}>
								<HStack
									as="a"
									href={`mailto: ${user?.email}`}
									color="gray.600"
									_hover={linkHoverStyle}
									fontSize="sm"
								>
									<Text>
										<i className="fas fa-envelope fa-lg fa-fw" />
									</Text>

									<Text>{user?.email}</Text>
								</HStack>
								<HStack
									as="a"
									href={`tel: ${user?.cellPhone}`}
									color="gray.600"
									_hover={linkHoverStyle}
									fontSize="sm"
								>
									<Text>
										<i className="fas fa-mobile-alt fa-lg fa-fw" />
									</Text>

									<Text>{"Cell: " + user?.cellPhone}</Text>
								</HStack>
								<HStack
									as="a"
									href={`tel: ${regionMap[user?.regionID]?.phone}`}
									color="gray.600"
									_hover={linkHoverStyle}
									fontSize="sm"
								>
									<Text>
										<i className="fas fa-phone fa-lg fa-fw" />
									</Text>
									<Text>
										{"Office: " + regionMap[user?.regionID]?.phone}
										{Boolean(user?.ext) && " x" + user?.ext}
									</Text>
								</HStack>
							</Stack>
						</Box>
					)}
				</PopoverContent>
			</Portal>
		</Popover>
	);
}
