import React, { useState } from "react";
import { Stack, Flex, Container } from "@chakra-ui/react";

import HRReviewsDrawer from "./HRReviewsDrawer";

import PageTemplateHRReviews from "../classes/PageTemplateHRReviews";

import Page from "../../../core/ReactTable/layout/Page";
import HRPageHeader from "./HRPageHeader";
import { portalUser } from "../../../App";

export default function HRReviewsTable(props) {
	const selectForm = async (form) => {
		props?.selectForm(form?.formUID);
	};

	const [formEdit, setFormEdit] = useState(null);

	const editForm = (form = null) => {
		let formEdit = portalUser.formData.forms?.find((d) => d.formUID === form?.formUID) ?? null;
		setFormEdit(formEdit);
	};

	return (
		<Stack
			spacing={0}
			p={portalUser.styles?.pagePadding}
			bg={portalUser.styles?.pageBG}
			w="full"
			h="full"
			flex={1}
			align="center"
		>
			<HRPageHeader tab={props.tab} formData={portalUser?.formData} />
			<Container
				direction="column"
				maxW={"full"}
				display={"flex"}
				minW="container.lg"
				px={0}
				flex={1}
				justify="flex-start"
				h="full"
			>
				<Flex direction="column" flex={1} justify="flex-start" h="full" rounded={5}>
					{!props?.isLoading && (
						<Page
							pageTemplate={new PageTemplateHRReviews(portalUser.formData, { module: "HRForms" })}
							overrideView={{
								showReportHeader: false,
								showPageHeader: false,
								showPageFooter: false,
								shadow: "none",
								orientation: "full",
							}}
							skipReset={false}
							setSelectedRow={editForm}
						/>
					)}
				</Flex>
			</Container>
			{Boolean(formEdit) && (
				<HRReviewsDrawer
					isOpen={Boolean(formEdit)}
					form={formEdit}
					onClose={editForm}
					selectForm={selectForm}
				/>
			)}
		</Stack>
	);
}
