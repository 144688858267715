import React, { useState, useMemo, useCallback } from "react";
import { Box, Container, Flex, Text, Heading, Avatar, Link, Stack, Badge, Skeleton, HStack } from "@chakra-ui/react";
import UserPopover from "./EmployeePopover";
import BasicCard from "../../../core/Layout/cards/BasicCard";
import { portalUser } from "../../../App";

export default function UserProfilePage({ msUserID = null, employeeUID = null }) {
	const [user, setUser] = useState(null);

	const initUser = useCallback(async (id, attr) => {
		let user = await portalUser.getUser(id, attr);
		if (Boolean(user?.employeeUID)) {
			if (Boolean(user?.managerUID)) {
				user.manager = await portalUser.getUser(user?.managerUID, "employeeUID");
			}
			if (Boolean(user?.reviewerUID)) {
				user.reviewer = await portalUser.getUser(user?.reviewerUID, "employeeUID");
			}
			if (Boolean(user?.approverUID)) {
				user.approver = await portalUser.getUser(user?.approverUID, "employeeUID");
			}
		}
		setUser(user);
	}, []);

	useMemo(() => {
		if (msUserID) {
			initUser(msUserID, "msUserID");
		} else if (employeeUID) {
			initUser(employeeUID, "employeeUID");
		}
	}, [msUserID, employeeUID, initUser]);

	const linkHoverStyle = {
		cursor: "pointer",
		color: "blue.600",
		textDecoration: "underline",
	};

	return (
		<Container maxW="container.lg" px={0} w="full">
			{Boolean(user) && (
				<Skeleton isLoaded={user?.employeeUID} rounded={"md"}>
					<Box>
						<Flex p={3} wrap="wrap">
							<Box textAlign="center" p={3}>
								<Avatar
									key={user?.employeeUID}
									src={user?.profilePhoto}
									name={user?.getName()}
									size="xl"
								/>
							</Box>

							<Box flex={1} p={3}>
								<Stack spacing={8}>
									<Stack spacing={0}>
										<Heading size="xl" color="gray.600" textTransform="uppercase" letterSpacing={2}>
											{user?.getName()}
										</Heading>
										<Heading size="sm" color="gray.500" textTransform="uppercase" letterSpacing={1}>
											{user?.title}
										</Heading>

										<Box>
											<Badge
												fontSize="md"
												colorScheme={Boolean(user?.userStatus === "Active") ? "green" : "red"}
											>
												{user?.userStatus ?? "InActive"}
											</Badge>
										</Box>
									</Stack>

									<BasicCard title="Contact Info">
										<Stack spacing={4} flex={1} p={2} color="gray.600" fontWeight="semibold">
											<Flex justify="space-between" align="center">
												<Heading size="sm" letterSpacing={1} textTransform="uppercase">
													Name
												</Heading>
												<Text>{user?.getName()}</Text>
											</Flex>

											<Flex justify="space-between" align="center">
												<Heading size="sm" letterSpacing={1} textTransform="uppercase">
													Email
												</Heading>
												<Link
													color="gray.600"
													fontWeight="semibold"
													_hover={linkHoverStyle}
													href={"mailto:" + user?.email}
												>
													{user?.email}
												</Link>
											</Flex>

											<Flex justify="space-between" align="center">
												<Heading
													size="sm"
													letterSpacing={1}
													textTransform="uppercase"
													color="gray.600"
												>
													Cell Phone
												</Heading>
												<Link
													color="gray.600"
													fontWeight="semibold"
													_hover={linkHoverStyle}
													href={"tel:" + user?.cellPhone}
												>
													{user?.cellPhone}
												</Link>
											</Flex>

											<Flex justify="space-between" align="center">
												<Heading
													size="sm"
													letterSpacing={1}
													textTransform="uppercase"
													color="gray.600"
												>
													Office Phone
												</Heading>
												<Link
													color="gray.600"
													fontWeight="semibold"
													_hover={linkHoverStyle}
													href={"tel:" + user?.officePhone}
												>
													{user?.region?.phone}
													{Boolean(user?.ext) && " x" + user?.ext}
												</Link>
											</Flex>
										</Stack>
									</BasicCard>

									<BasicCard title="Company Info">
										<Stack spacing={4} flex={1} p={2} color="gray.600" fontWeight="semibold">
											<Flex justify="space-between" align="center">
												<Heading size="sm" letterSpacing={1} textTransform="uppercase">
													Title
												</Heading>
												<Text>{user?.title}</Text>
											</Flex>
											<Flex justify="space-between" align="center">
												<Heading size="sm" letterSpacing={1} textTransform="uppercase">
													Department
												</Heading>
												<Text color="gray.600">{user?.department?.dept}</Text>
											</Flex>
											<Flex justify="space-between" align="center">
												<Heading size="sm" letterSpacing={1} textTransform="uppercase">
													Trade
												</Heading>
												<Text color="gray.600">{user?.trade?.trade ?? "N/A"}</Text>
											</Flex>
											<Flex justify="space-between" align="center">
												<Heading size="sm" letterSpacing={1} textTransform="uppercase">
													Region
												</Heading>
												<Text>{user?.region?.description}</Text>
											</Flex>

											<Flex justify="space-between" align="center">
												<Heading size="sm" letterSpacing={1} textTransform="uppercase">
													Longevity
												</Heading>
												<HStack>
													{Boolean(parseInt(user?.longevityYears) > 0) && (
														<Text>
															{Math.floor(parseInt(user?.longevityYears)) + " Years"}
														</Text>
													)}
													{Boolean(parseInt(user?.longevityMths % 12) > 0) && (
														<Text>{parseInt(user?.longevityMths) % 12} Months</Text>
													)}
												</HStack>
											</Flex>
										</Stack>
									</BasicCard>

									<BasicCard title="Manager Info">
										<Skeleton isLoaded={Boolean(user?.employeeUID)}>
											<Stack spacing={4} flex={1} p={2}>
												<Flex justify="space-between" align="center">
													<Heading size="sm" letterSpacing={1} textTransform="uppercase">
														Manager
													</Heading>
													{Boolean(user?.reviewer?.employeeUID) && (
														<UserPopover employeeUID={user?.managerUID}>
															<Text>{user?.approver?.getName()}</Text>
														</UserPopover>
													)}
												</Flex>

												<Flex justify="space-between" align="center" color="gray.600">
													<Heading size="sm" letterSpacing={1} textTransform="uppercase">
														Reviewer
													</Heading>
													{Boolean(user?.reviewer?.employeeUID) && (
														<UserPopover employeeUID={user?.managerUID}>
															<Text>{user?.reviewer?.getName()}</Text>
														</UserPopover>
													)}
												</Flex>

												<Flex justify="space-between" align="center" color="gray.600">
													<Heading size="sm" letterSpacing={1} textTransform="uppercase">
														Approver
													</Heading>
													{Boolean(user?.approver?.employeeUID) && (
														<UserPopover employeeUID={user?.managerUID}>
															<Text>{user?.approver?.getName()}</Text>
														</UserPopover>
													)}
												</Flex>
											</Stack>
										</Skeleton>
									</BasicCard>
								</Stack>
							</Box>
						</Flex>
					</Box>
				</Skeleton>
			)}
		</Container>
	);
}
