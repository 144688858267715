import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Flex, Heading, Avatar, AvatarBadge, Link, Input, Stack, HStack, IconButton } from "@chakra-ui/react";
import { FaCamera, FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import AvatarEdit from "react-avatar-edit";
import $ from "jquery";

import { convertArrayToMap, getDeviceType, dataURItoBlob } from "../../../helperFunctions";
import { portalUser } from "../../../App";
import UserPopover from "./EmployeePopover";
import { motion } from "framer-motion";

export default function EmployeeProfileHeader() {
	const [editPhoto, setEditPhoto] = useState(false);
	const [photoToEdit, setPhotoToEdit] = useState(null);
	const [photoToUpload, setPhotoToUpload] = useState(false);
	var deviceType = getDeviceType();

	const choosePhoto = (e) => {
		let photo = e.target.files[0];
		var reader = new FileReader();
		reader.onload = (e) => {
			let photoSrc = e.target.result;
			setEditPhoto(true);
			setPhotoToEdit(photoSrc);
			setPhotoToUpload(photoSrc);
		};
		reader.readAsDataURL(photo);
	};

	const closePhotoEdit = () => {
		setEditPhoto(false);
		setPhotoToEdit(null);
		setPhotoToUpload(null);
	};

	const editPhotoFunction = (blob) => {
		setPhotoToUpload(blob);
	};

	const uploadPhoto = async () => {
		let photo = photoToUpload;
		let photoBlob = dataURItoBlob(photo, "image/*");
		portalUser.UpdateUserPhoto(photoBlob);
		portalUser.user.profilePhoto = photo;
		setUser(portalUser.user);
		closePhotoEdit();
	};

	var regionMap = convertArrayToMap(portalUser?.getRegions(), "regionID");
	var deptMap = convertArrayToMap(portalUser?.getDepartments(), "deptID");

	const [user, setUser] = useState(portalUser?.user ?? null);
	const [manager, setManager] = useState(null);
	const [approver, setApprover] = useState(null);
	const [reviewer, setReviewer] = useState(null);

	const initUser = useCallback(async (employeeUID = null, role = "employee") => {
		if (Boolean(employeeUID)) {
			let user = await portalUser.getUser(employeeUID, "employeeUID");
			if (role === "employee") {
				setUser(user);
			} else if (role === "manager") {
				setManager(user);
			} else if (role === "approver") {
				setApprover(user);
			} else if (role === "reviewer") {
				setReviewer(user);
			}
		}
	}, []);

	useEffect(() => {
		if (!Boolean(user) || user?.employeeUID !== portalUser.user?.employeeUID) {
			initUser(portalUser.user?.employeeUID, "employee");
		}
	}, [initUser, user]);

	useMemo(() => {
		if (Boolean(user)) {
			if (!Boolean(manager) || user?.managerUID !== manager?.employeeUID) {
				initUser(user?.managerUID, "manager");
			} else if (!Boolean(approver) || user?.approverUID !== approver?.employeeUID) {
				initUser(user?.approverUID, "approver");
			} else if (!Boolean(reviewer) || user?.reviewerUID !== reviewer?.employeeUID) {
				initUser(user?.reviewerUID, "reviewer");
			}
		}
	}, [approver, initUser, manager, reviewer, user]);

	return (
		<motion.div whileHover={{ scale: 1.005, transition: { duration: 1 } }} style={{ width: "100%" }}>
			<Flex
				justify="space-between"
				p={portalUser.styles?.cardPadding}
				rounded="lg"
				bg={portalUser.styles?.cardBG}
				shadow={portalUser.styles?.cardShadow}
				borderWidth={portalUser.styles?.cardBorderWidth}
				borderColor={portalUser.styles?.cardBorderColor}
				w="full"
				_hover={{ bg: "white", shadow: "lg", borderWidth: 2, borderColor: "teal.500" }}
				isTruncated
			>
				{user && (
					<Stack
						direction={{ base: "column", lg: "row" }}
						spacing={4}
						w="full"
						justify={{ base: "center", lg: "space-between" }}
						wrap={"wrap"}
						isTruncated
					>
						<HStack spacing={3} flex={1} py={2} justify={{ base: "center", md: "flex-start" }} isTruncated>
							{!editPhoto && (
								<Avatar
									src={user?.profilePhoto}
									key={user?.profilePhoto}
									name={user?.name}
									size={deviceType === "mobile" ? "lg" : "2xl"}
									shadow="lg"
									mx="1"
									bg="whiteAlpha.800"
								>
									<AvatarBadge
										borderColor="gray.500"
										border="2px"
										bg="whiteAlpha.800"
										boxSize=".95em"
										color="gray.500"
										_hover={{ color: "whiteAlpha.800", bg: "gray.500" }}
										align="center"
										justify="center"
										shadow="lg"
										insetEnd="2.5"
										bottom="2"
									>
										<IconButton
											isRound
											icon={<FaCamera style={{ margin: "auto" }} />}
											fontSize={28}
											align="center"
											justify="center"
											variant="unstyled"
											cursor="pointer"
											onClick={() => {
												$("#editPhotoInput").click();
											}}
										/>
									</AvatarBadge>
								</Avatar>
							)}
							<Input
								id="editPhotoInput"
								type="file"
								accept="image/*"
								onChange={choosePhoto}
								hidden={true}
							/>
							{editPhoto && (
								<Stack spacing="0" align="center">
									<AvatarEdit
										closeIconColor="rgba(255,255,255,0)"
										width={150}
										height={150}
										borderRadius="10"
										onCrop={editPhotoFunction}
										onClose={closePhotoEdit}
										src={photoToEdit}
									/>
									<Flex w="full" justify="space-between">
										<IconButton
											variant="ghost"
											isRound
											bg="whiteAlpha.400"
											colorScheme="red"
											size="md"
											fontSize="25px"
											align="center"
											justify="center"
											icon={<FaTimesCircle />}
											onClick={closePhotoEdit}
										/>
										<IconButton
											variant="ghost"
											isRound
											bg="whiteAlpha.400"
											colorScheme="teal"
											size="md"
											fontSize="25px"
											align="center"
											justify="center"
											icon={<FaCheckCircle />}
											onClick={uploadPhoto}
										/>
									</Flex>
								</Stack>
							)}

							<Stack px={4} spacing={3}>
								<Heading
									size="lg"
									color="gray.600"
									isTruncated
									letterSpacing={2}
									textTransform={"uppercase"}
								>
									{user?.name}
								</Heading>
								<Heading
									textTransform={"uppercase"}
									size="md"
									color="gray.500"
									isTruncated
									letterSpacing={1}
								>
									{user?.title}
								</Heading>

								<HStack align="center" color="gray.500" textTransform={"uppercase"} spacing={3}>
									<Heading
										size="lg"
										color="gray.500"
										letterSpacing={1}
										className={deptMap[user?.deptID]?.icon + " fa-fw"}
									/>

									<Heading color={"gray.400"} size="md" isTruncated letterSpacing={1}>
										{deptMap[user?.deptID]?.dept + " - " + regionMap[user?.regionID]?.tag}
									</Heading>
								</HStack>
							</Stack>
						</HStack>
						<HStack
							spacing={3}
							flex={1}
							py={2}
							justify={{ base: "flex-start", xl: "flex-end" }}
							isTruncated
						>
							<Stack px={4} spacing={3} isTruncated justify="flex-start">
								<HStack align="center" spacing={3}>
									<Heading color={"gray.500"} size="lg" className="far fa-envelope fa-lg fa-fw" />
									<Stack spacing={1}>
										<Heading
											size="xs"
											color={"gray.400"}
											textTransform={"uppercase"}
											letterSpacing={2}
											isTruncated
										>
											Email
										</Heading>
										<Link color={"gray.500"} href={"mailto:" + user?.email}>
											<Heading size="sm" color={"gray.500"} fontWeight="normal">
												{user?.email}
											</Heading>
										</Link>
									</Stack>
								</HStack>

								<HStack align="center" spacing={3}>
									<Heading color={"gray.500"} size="lg" className="fas fa-mobile-alt fa-fw" />

									<Stack spacing={1}>
										<Heading
											size="xs"
											color={"gray.400"}
											textTransform={"uppercase"}
											letterSpacing={2}
											isTruncated
										>
											Cell
										</Heading>
										<Link color={"gray.400"} href={"tel:" + user?.cellPhone}>
											<Heading size="xs" color={"gray.500"} fontWeight="normal">
												{user.cellPhone}
											</Heading>
										</Link>
									</Stack>
								</HStack>

								<HStack align="center" spacing={3}>
									<Heading color={"gray.500"} size="lg" className="fas fa-phone-alt fa-fw" />

									<Stack spacing={1}>
										<Heading
											size="xs"
											color={"gray.400"}
											textTransform={"uppercase"}
											letterSpacing={2}
											isTruncated
										>
											Office
										</Heading>
										<Link color={"gray.400"} href={"tel:" + regionMap[user?.regionID]?.phone}>
											<Heading size="xs" color={"gray.500"} fontWeight="normal">
												{regionMap[user?.regionID]?.phone + (user?.ext ? " x" + user?.ext : "")}
											</Heading>
										</Link>
									</Stack>
								</HStack>
							</Stack>

							{deviceType !== "mobile" && (
								<Stack px={4} spacing={3} isTruncated justify="flex-start">
									<UserPopover employeeUID={user?.reviewerUID}>
										<HStack align="center" spacing={3} isTruncated>
											<Heading
												color={"gray.500"}
												size="lg"
												className="fas fa-user-tie fa-lg fa-fw"
											/>
											<Stack spacing={1} isTruncated>
												<Heading
													size="xs"
													color={"gray.400"}
													textTransform={"uppercase"}
													letterSpacing={2}
													isTruncated
												>
													Reviewer
												</Heading>

												<Heading size="xs" color={"gray.500"} fontWeight="normal">
													{reviewer?.name ?? "N/A"}
												</Heading>
											</Stack>
										</HStack>
									</UserPopover>

									<UserPopover employeeUID={user?.managerUID}>
										<HStack align="center" spacing={3} isTruncated>
											<Heading
												color={"gray.500"}
												size="lg"
												className="fas fa-user-friends fa-fw"
											/>

											<Stack spacing={1} isTruncated>
												<Heading
													size="xs"
													color={"gray.400"}
													textTransform={"uppercase"}
													letterSpacing={2}
													isTruncated
												>
													Manager
												</Heading>

												<Heading size="xs" color={"gray.500"} fontWeight="normal">
													{manager?.name ?? "N/A"}
												</Heading>
											</Stack>
										</HStack>
									</UserPopover>

									<UserPopover employeeUID={user?.approverUID}>
										<HStack align="center" spacing={3} isTruncated>
											<Heading color={"gray.500"} size="lg" className="fas fa-user-clock fa-fw" />

											<Stack spacing={1} isTruncated>
												<Heading
													size="xs"
													color={"gray.400"}
													textTransform={"uppercase"}
													letterSpacing={2}
													isTruncated
												>
													Approver (PTO)
												</Heading>

												<Heading size="xs" color={"gray.500"} fontWeight="normal">
													{approver?.name ?? "N/A"}
												</Heading>
											</Stack>
										</HStack>
									</UserPopover>
								</Stack>
							)}
						</HStack>
					</Stack>
				)}
			</Flex>
		</motion.div>
	);
}
