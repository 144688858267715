import React, { useCallback } from "react";
import { Box, Flex } from "@chakra-ui/react";
import VirtualTableCell from "./VirtualTableV4Cell";
// import VirtualTableGroupedCell from "./VirtualTableGroupedCell";
import VirtualTableExpanderCell from "./VirtualTableV4ExpanderCell";
import VirtualTableEditableCell from "./VirtualTableV4EditableCell";
import VirtualTableRenderSubRow from "./VirtualTableV4SubRow";

export default function VirtualTableRenderRow({
	row,
	style,
	selectedRow,
	setSelectedRow,
	rowCount,
	maxRows,
	rowHeight,
	maxWidth,
	toggleAllRowsExpanded,
	resetRowHeights,
	updateMyData,
	allColumns,
	maxRowHeight,
	subRow,
}) {
	const RenderTableCell = useCallback(
		({ row, cell, i }) => {
			let maxW = cell.column.id === "expander" ? "40px" : cell.column.width ?? "full";
			let borderRightWidth = 1;
			if (row?.cells?.length === 0 || (rowCount <= maxRows && row?.cells?.length - 1 === i)) {
				borderRightWidth = 0;
			}

			const shownColumns = allColumns?.filter((d) => Boolean(d.show)) ?? [];
			let columnIndex = shownColumns?.findIndex((item) => item.id === cell.column.id) ?? 0;

			if (cell.column.id === "expander") {
				return (
					<VirtualTableExpanderCell
						rowIsExpanded={Boolean(row.isExpanded)}
						toggleAllRowsExpanded={toggleAllRowsExpanded}
						toggleRowExpanded={row.toggleRowExpanded}
						resetRowHeights={resetRowHeights}
						cell={cell}
						maxW={maxW}
						borderRightWidth={borderRightWidth}
					/>
				);
			} else if (Boolean(cell.column?.editable)) {
				return (
					<VirtualTableEditableCell
						value={cell.value}
						column={cell.column}
						row={cell.row}
						updateMyData={updateMyData}
						// selectedInput={selectedInput}
						cell={cell}
						maxW={maxW}
						borderRightWidth={borderRightWidth}
						rowCount={rowCount}
						columnIndex={columnIndex}
					/>
				);
			} else {
				return <VirtualTableCell cell={cell} maxW={maxW} borderRightWidth={borderRightWidth} />;
			}
		},
		[allColumns, maxRows, resetRowHeights, rowCount, toggleAllRowsExpanded, updateMyData]
	);

	const RenderSubRow = useCallback(
		({ row }) => {
			return (
				<VirtualTableRenderSubRow
					row={row}
					maxWidth={maxWidth}
					rowHeight={rowHeight}
					maxRowHeight={maxRowHeight}
					subRow={subRow}
					allColumns={allColumns}
					// toggleAllRowsExpanded={toggleAllRowsExpanded}
					// toggleRowExpanded={row.toggleRowExpanded}
					// resetRowHeights={resetRowHeights}
				/>
			);
		},
		[maxWidth, rowHeight, maxRowHeight, subRow, allColumns]
	);

	return (
		<Box
			{...row.getRowProps()}
			style={style}
			borderBottomWidth={1}
			borderBottomColor="gray.200"
			maxH={maxRowHeight}
		>
			<Flex
				h="full"
				w="full"
				position="relative"
				maxW={maxWidth}
				fontWeight={row.original?.rowType === "Subtotal" ? "bold" : "normal"}
				bg={row.original?.rowType === "Subtotal" ? "blackAlpha.50" : row.isGrouped ? "blue.50" : "white"}
				textTransform={row.original?.rowType === "Subtotal" ? "uppercase" : ""}
				_hover={{ fontWeight: "bold", bg: "blackAlpha.50" }}
				isTruncated
				maxH={rowHeight}
				onClick={
					!Boolean(selectedRow) && Boolean(setSelectedRow)
						? () => setSelectedRow(row?.original ?? null)
						: null
				}
			>
				{row.cells.map((cell, i) => RenderTableCell({ row, cell, i }))}
			</Flex>

			{Boolean(subRow) &&
				!Boolean(row.isGrouped) &&
				Boolean(row.isExpanded) &&
				!Boolean(row.canExpand) &&
				RenderSubRow({ row })}
		</Box>
	);
}
