import React, { useState, useCallback, useEffect } from "react";
import { Stack, Heading, HStack, useBoolean, IconButton, Flex, Tooltip, useToast } from "@chakra-ui/react";
import moment from "moment";
import DataInput from "../../../core/Inputs/data/DataInput";
import DIVERSITY_CERTS from "../constants/DiversityCerts";

import { formatValue, convertArrayToMap } from "../../../helperFunctions";
import LOCAL_BUSINESSES from "../constants/LocalBusinesses";
import DIVERSITY_SUB_TYPES from "../constants/DiversitySubTypes";
const diversityCertMap = convertArrayToMap(DIVERSITY_CERTS, "label");

export default function DiversityVendorDrawerItem({
	cert,
	diversityCerts = [],
	spacing,
	i,
	deleteVendorCert,
	isPurchasingManager,
	setNewCertActive,
}) {
	const toast = useToast();
	const [updateTrigger, setUpdateTrigger] = useState(false);
	const [certificate, setCertificate] = useState(cert);
	const [deleteHover, setDeleteHover] = useBoolean();
	const isWithinOrPast30Days = moment(cert?.expirationDate).diff(moment(), "days") <= 30;
	const isPastExpiration = moment().isAfter(moment(cert?.expirationDate));

	const updateDiversityCert = useCallback(
		(attr, value) => {
			cert.update(attr, value);
			setUpdateTrigger(true);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[cert]
	);

	useEffect(() => {
		if (updateTrigger) {
			setUpdateTrigger(false);
		}
	}, [updateTrigger]);

	const deleteCertificate = (certif) => {
		deleteVendorCert(certif);
		setCertificate(null);
		setNewCertActive(null);
		toast({
			title: "Success!",
			description:
				"Certification deleted! If the certificate was just added, please refresh the page to confirm your updates.",
			status: "success",
			duration: 5000,
			isClosable: true,
		});
	};

	const success = (
		<IconButton variant="ghost" size="sm" _hover={{ cursor: "not-allowed" }}>
			<i
				style={{
					color: "var(--chakra-colors-teal-500)",
				}}
				className={"fas fa-check-square fa-lg"}
			></i>
		</IconButton>
	);

	const failure = (
		<IconButton variant="ghost" size="sm" _hover={{ cursor: "not-allowed" }}>
			<i
				style={{
					color: "var(--chakra-colors-red-500)",
				}}
				className={"fas fa-window-close fa-lg"}
			></i>
		</IconButton>
	);

	return (
		certificate && (
			<Stack
				p={3}
				spacing={spacing.vertSpacing}
				bg={"blackAlpha.200"}
				borderColor={deleteHover ? "red.500" : "blackAlpha.100"}
				borderWidth={2}
				rounded="lg"
			>
				<HStack
					w="full"
					pb={1}
					borderBottomWidth={2}
					borderBottomColor="blackAlpha.300"
					justify="space-between"
				>
					<Heading w="full" color={"gray.500"} textTransform="uppercase" fontSize="md" letterSpacing={2}>
						{diversityCertMap[cert?.diversityCert]?.description ?? "None Selected"}
					</Heading>

					<IconButton
						onMouseLeave={setDeleteHover.off}
						onMouseEnter={setDeleteHover.on}
						onClick={() => deleteCertificate(cert?.keyID)}
						bg="whiteAlpha.800"
						_hover={{ bg: "white", borderColor: "red.500", color: "red.500" }}
						color="gray.400"
						boxSize="25px"
						minW="25px"
						p={2}
						borderWidth={2}
						icon={<i className="fas fa-times fa-fw " />}
						isDisabled={!isPurchasingManager}
					></IconButton>
				</HStack>

				<Stack spacing={spacing.horzSpacing} direction="row" w="full" pb={2}>
					<DataInput
						key={cert?.keyID + "-diversityCerts-" + cert?.diversityCert}
						name="diversityCert"
						type="select"
						placeholder="Select Certification"
						label="Certification"
						searchable
						options={diversityCerts}
						value={cert?.diversityCert}
						optionValue="label"
						optionText="label"
						optionSubheading="description"
						onOptionSelect={(ev) => {
							if (ev !== cert?.diversityCert) {
								updateDiversityCert("diversityCert", ev);
							}
						}}
						flex={spacing.leftFlex}
						variant="outline"
						isModal
						isDisabled={!isPurchasingManager}
					/>
					<DataInput
						key={cert?.keyID + "-expirationDate-" + cert?.expirationDate}
						name="expirationDate"
						type="date"
						label="Expiration"
						placeholder={"mm/dd/yyyy"}
						value={formatValue(cert?.expirationDate, 0, "date")}
						onChange={(ev) => {
							// let oldDate = formatValue(new Date(cert?.expirationDate), 0, "moment");
							// let newDate = formatValue(new Date(ev), 0, "moment");
							if (
								formatValue(new Date(ev), 0, "moment")?.isValid() &&
								formatValue(moment(cert?.expirationDate).format("M/D/YYYY")) !==
									formatValue(moment(ev).format("M/D/YYYY"))
							) {
								updateDiversityCert("expirationDate", ev);
								const daysDiff = moment(ev).diff(moment(), "days");

								if (daysDiff < 0) {
									// Date is in the past
									updateDiversityCert("reminderStatus", 2);
									updateDiversityCert("reminderDate", moment().format("MM/DD/YYYY"));
								} else if (daysDiff <= 30) {
									// Date is within next 30 days
									updateDiversityCert("reminderStatus", 1);
									updateDiversityCert("reminderDate", moment().format("MM/DD/YYYY"));
								} else {
									// Date is more than 30 days away
									updateDiversityCert("reminderStatus", 0);
									updateDiversityCert("reminderDate", null);
								}
							}
						}}
						flex={spacing.rightFlex}
						variant="outline"
						inputProps={{
							textAlign: "center",
							width: "100%",
							bg: !isPurchasingManager ? "grey.200" : "white",
							cursor: !isPurchasingManager ? "not-allowed" : "pointer",
						}}
						isModal
						disabled={!isPurchasingManager}
					/>
				</Stack>
				{cert?.diversityCert === "SBE" && (
					<Flex>
						<DataInput
							key={cert?.keyID + "-certSubType-" + cert?.certSubType}
							name="certSubType"
							type="select"
							placeholder="Select Subcategory Type"
							label="Subcategory Type"
							searchable
							options={DIVERSITY_SUB_TYPES}
							value={cert?.certSubType}
							optionValue="value"
							optionText="label"
							optionSubheading="description"
							onOptionSelect={(ev) => {
								if (ev !== cert?.certSubType) {
									updateDiversityCert("certSubType", ev);
								}
							}}
							flex={spacing.leftFlex}
							variant="outline"
							isModal
							isDisabled={!isPurchasingManager}
						/>
					</Flex>
				)}
				{(cert?.diversityCert === "LSBE" || cert?.diversityCert === "LBE") && (
					<Flex>
						<DataInput
							key={cert?.keyID + "-city-" + cert?.city}
							name="city"
							type="select"
							placeholder="Select City"
							label="City"
							searchable
							options={LOCAL_BUSINESSES}
							value={cert?.city}
							optionValue="value"
							optionText="label"
							optionSubheading="description"
							onOptionSelect={(ev) => {
								if (ev !== cert?.city) {
									updateDiversityCert("city", ev);
								}
							}}
							flex={spacing.leftFlex}
							variant="outline"
							isModal
							isDisabled={!isPurchasingManager}
						/>
					</Flex>
				)}
				{isWithinOrPast30Days && isPurchasingManager && (
					<Flex justify="space-between">
						<Tooltip
							label={
								cert?.reminderStatus === 1 || cert?.reminderStatus === 2
									? `30 day expiration reminder sent${
											cert?.reminderStatus === 1 ? " on " + cert?.reminderDate : ""
									  }`
									: "30 day expiration reminder has not been sent."
							}
							hasArrow={true}
							placement="top"
						>
							<Flex align="center">
								<Flex>30 Day Reminder: </Flex>
								<Flex>
									{cert?.reminderStatus === 1 || cert?.reminderStatus === 2 ? success : failure}
								</Flex>
							</Flex>
						</Tooltip>
						{isPastExpiration && (
							<Tooltip
								label={
									cert?.reminderStatus
										? `Expiration reminder sent${
												cert?.reminderStatus === 2 ? " on " + cert?.reminderDate : ""
										  }`
										: "Expiration reminder could not be sent. Please check the contact email."
								}
								hasArrow={true}
								placement="top"
							>
								<Flex align="center">
									<Flex>Expired Reminder:</Flex>
									<Flex>{cert?.reminderStatus === 2 ? success : failure}</Flex>
								</Flex>
							</Tooltip>
						)}
					</Flex>
				)}
			</Stack>
		)
	);
}
