import React, { useMemo, useState } from "react";
import { Box, Stack, Heading } from "@chakra-ui/react";
import BasicCard from "../../../core/Layout/cards/BasicCard";
import TimecardEntryContainer from "./TimecardEntryContainer";
import { portalUser } from "../../../App";
// import { log } from "../../../helperFunctions";

export default function TimecardEntryCard(props) {
	const user = useMemo(() => props.member ?? null, [props.member]);
	const employees = useMemo(() => user?.activeCompanies ?? [], [user?.activeCompanies]);
	const prEndDate = useMemo(() => props?.prEndDate ?? [], [props?.prEndDate]);

	const title = useMemo(() => user?.employeeName, [user?.employeeName]);
	const subtitle = useMemo(
		() => user?.title + " (" + user?.craft + " / " + user?.class + ") - " + user?.dept + " - " + user?.region,
		[user?.class, user?.craft, user?.dept, user?.region, user?.title]
	);

	const [reRenderKey, setReRenderKey] = useState(0);

	// Callback to trigger re-rendering
	const triggerReRender = () => {
		setReRenderKey((prevKey) => prevKey + 1); // Increment the key to force re-render
	};

	// log("TIMECARD Header - Memo", prEndDate);

	return (
		<BasicCard id="timecardTable" w="full" portalStyles={portalUser.styles} bg="blue.200">
			<Stack spacing={4}>
				<Stack spacing={2}>
					{Boolean(title) && (
						<Heading
							fontSize="xl"
							textTransform="uppercase"
							letterSpacing={2}
							color="gray.500"
							textShadow="2px 2px 3px white"
						>
							{title}
						</Heading>
					)}
					{Boolean(subtitle) && (
						<Heading
							fontSize="md"
							textTransform="uppercase"
							letterSpacing={2}
							color="gray.400"
							textShadow="1px 1px 2px white"
						>
							{subtitle}
						</Heading>
					)}
				</Stack>
				{employees?.length > 0 &&
					employees?.map((employee, i) => {
						const secondCompany = employees.length === 2 && employee === employees[1];
						return (
							<Box key={"timecard-" + employee.prco + "-" + employee.employee} w="full" px={0}>
								<TimecardEntryContainer
									key={`${i}-${reRenderKey}`} // Change the key to force re-render
									//TIMECARD JOBS
									timecardJobs={props.timecardJobs}
									getAvailableJobPhases={props.getAvailableJobPhases}
									//PAY PERIODS
									prEndDate={prEndDate}
									payPeriods={props.payPeriods}
									//PR EMPLOYEE
									employee={employee}
									timecardList={employee.timecard}
									//FUNCTIONS
									readyCallback={props.timecardEntryReady}
									addHours={props.addHours}
									updateTimecardItem={props.updateTimecardItem}
									triggerTableUpdate={props.triggerTableUpdate}
									triggerReRender={triggerReRender} // Pass the trigger callback
									//VIEW
									dataView={props.dataView}
									cardView={props.cardView}
									//Employees with two companies
									secondCompany={secondCompany}
								/>
							</Box>
						);
					})}
			</Stack>
		</BasicCard>
	);
}
