export default class DiversityReq {
	constructor(diversityReq) {
		this.queryData = diversityReq?.queryData ?? "DiversityReq";
		this.keyID = diversityReq?.keyID ?? null;
		this.jccmKeyID = diversityReq?.jccmKeyID ?? null;
		this.jcco = diversityReq?.jcco ?? null;
		this.contract = diversityReq?.contract ?? null;
		this.divReqKeyID = diversityReq?.divReqKeyID ?? null;
		this.divReqPct = diversityReq?.divReqPct ?? null;
		this.divCertType = diversityReq?.divCertType ?? null;
		this.divCertSubType = diversityReq?.divCertSubType ?? null;
		this.divCertDesc = diversityReq?.divCertDesc ?? null;
	}
}
