import React, { useMemo } from "react";
import { Flex } from "@chakra-ui/react";
import TableCell from "./TableCell";
import { convertArrayToMap } from "../../../helperFunctions";

const rowStyles = [
	{
		rowType: "TableHeaderRow",
		bg: "teal.700",
		color: "white",
		borderColor: "transparent",
		borderTopColor: "gray.400",
		borderBottomColor: "transparent",
		borderWidth: 1,
		borderTopWidth: 1,
		borderBottomWidth: 0,
		borderTopRadius: "md",
		borderBottomRadius: 0,
	},
	{
		rowType: "TableSizesRow",
		bg: "whiteAlpha.400",
		color: "gray.500",
		borderColor: "gray.300",
		borderTopColor: "gray.300",
		borderBottomColor: "transparent",
		borderWidth: 1,
		borderTopWidth: 1,
		borderBottomWidth: 0,
		borderTopRadius: "md",
		borderBottomRadius: 0,
	},
	{
		rowType: "FloatHeaderRow",
		bg: "transparent",
		color: "gray.500",
		borderColor: "transparent",
		borderTopColor: "transparent",
		borderBottomColor: "transparent",
		borderWidth: 1,
		borderTopWidth: 0,
		borderBottomWidth: 0,
		borderTopRadius: "md",
		borderBottomRadius: 0,
	},
	{
		rowType: "TableFilterRow",
		bg: "gray.200",
		color: "gray.600",
		borderColor: "gray.400",
		borderTopColor: "gray.400",
		borderBottomColor: "transparent",
		borderWidth: 1,
		borderTopWidth: 1,
		borderBottomWidth: 0,
		borderTopRadius: 0,
		borderBottomRadius: 0,
	},
	{
		rowType: "TableFooterRow",
		bg: "gray.200",
		color: "gray.600",
		borderColor: "gray.400",
		borderTopColor: "gray.400",
		borderBottomColor: "transparent",
		borderWidth: 1,
		borderTopWidth: 1,
		borderBottomWidth: 1,
		borderTopRadius: 0,
		borderBottomRadius: 1,
	},
	{
		rowType: "TableBodyRow",
		bg: "transparent",
		color: "gray.500",
		borderColor: "gray.400",
		borderTopColor: "gray.400",
		borderBottomColor: "transparent",
		borderWidth: 1,
		borderTopWidth: 1,
		borderBottomWidth: 0,
		borderTopRadius: 0,
		borderBottomRadius: 0,
	},
];
const rowStylesMap = convertArrayToMap(rowStyles, "rowType");

export default function TableHeaderRow({ rowType, headerGroup, scrollBarPadding = 0, rowHeight = 40 }) {
	const rowStyle = useMemo(
		() => (Boolean(rowStylesMap[rowType]) ? rowStylesMap[rowType] : rowStylesMap["TableBodyRow"]),
		[rowType]
	);
	const columns = useMemo(() => headerGroup?.headers ?? [], [headerGroup?.headers]);
	const pr = useMemo(() => scrollBarPadding ?? 0, [scrollBarPadding]);

	return (
		<Flex
			{...headerGroup.getHeaderGroupProps()}
			direction="row"
			flex={1}
			w="full"
			align="stretch"
			isTruncated
			borderStyle="inset"
			bg={rowStyle?.bg}
			color={rowStyle?.color}
			borderColor={rowStyle?.borderColor}
			borderTopColor={rowStyle?.borderTopColor}
			borderBottomColor={rowStyle?.borderBottomColor}
			borderWidth={rowStyle?.borderWidth}
			borderTopWidth={rowStyle?.borderTopWidth}
			borderBottomWidth={rowStyle?.borderBottomWidth}
			borderTopRadius={rowStyle?.borderTopRadius}
			borderBottomRadius={rowStyle?.borderBottomRadius}
			pr={pr}
			minH={rowHeight + "px"}
		>
			{columns?.length > 0 &&
				columns?.map((column, i) => <TableCell key={i} rowType={rowType} column={column} />)}
		</Flex>
	);
}
