import React, { useMemo } from "react";
import {
	Text,
	Heading,
	Container,
	Stack,
	Table,
	Thead,
	Th,
	Tbody,
	Tr,
	Td,
	Tfoot,
} from "@chakra-ui/react";

import { EmployeeHistoryList } from "../classes/MeritIncreasesClassesLegacy";

import { formatValue } from "../../../helperFunctions";

export default function MeritIncreasesRegionSummariesPage(props) {
	// const proposalYear = useMemo(
	// 	() => props.proposalYear,
	// 	[props.proposalYear]
	// );
	const employeeHistory = useMemo(
		() => props.employeeHistory,
		[props.employeeHistory]
	);
	const regionArr = useMemo(() => props.regionArr, [props.regionArr]);
	const deptTypeArr = useMemo(() => props.deptTypeArr, [props.deptTypeArr]);

	return (
		<Container
			maxW="full"
			bg="white"
			rounded={"md"}
			shadow="lg"
			minH="6xl"
			p={12}
		>
			<Stack spacing={4}>
				<Heading
					size="xl"
					color="gray.600"
					textTransform="uppercase"
					letterSpacing={4}
				>
					Region Summaries
				</Heading>
				{/* <HStack p={6} spacing={6} align="center" isTruncated>
				<Avatar
					bg="gray.500"
					w={16}
					h={16}
					color="white"
					icon={<i className={"fas fa-dollar-sign fa-lg"}></i>}
				/>
				<Stack>
					<Heading
						size="xl"
						color="gray.600"
						textTransform="uppercase"
						letterSpacing={4}
					>
						Region Totals
					</Heading>
					<Text
						textTransform="uppercase"
						fontWeight="bold"
						color="gray.500"
						letterSpacing={2}
						fontSize="xl"
					>
						{"Salary Proposals for " + proposalYear}
					</Text>
				</Stack>
			</HStack> */}
				{employeeHistory?.length > 0 && (
					<Container maxW="full" px={0}>
						{regionArr?.map((region, i) => {
							let regionEmployees = employeeHistory;

							if (region.regionID !== -1) {
								regionEmployees = new EmployeeHistoryList(
									regionEmployees.filter(
										(d) => d?.regionID === region?.regionID
									)
								);
							}

							return (
								<MeritIncreasesRegionSummariesTable
									key={i}
									region={region}
									deptTypeArr={deptTypeArr}
									regionEmployees={regionEmployees}
								/>
							);
						})}
					</Container>
				)}
			</Stack>
		</Container>
	);
}

function MeritIncreasesRegionSummariesTable(props) {
	const region = useMemo(() => props.region, [props.region]);
	const deptTypeArr = useMemo(() => props.deptTypeArr, [props.deptTypeArr]);
	const regionEmployees = useMemo(
		() => props.regionEmployees,
		[props.regionEmployees]
	);
	let regionTotals = regionEmployees.getEmployeeHistoryTotalsSummary();

	let departmentMap = regionEmployees.createDepartmentMap("deptID");
	departmentMap = departmentMap.getSubTotalsSummary();

	let regionPct = 0;
	if (regionTotals.newSalary !== 0) {
		regionPct =
			((regionTotals.proposedSalary - regionTotals.newSalary) /
				regionTotals.newSalary) *
			100;
	}
	let regionNet =
		regionTotals.proposedSalary +
		regionTotals.proposedBonus +
		(regionTotals.proposedCell +
			regionTotals.proposedVehicle +
			regionTotals.proposedHousing) *
			12;

	return (
		<Stack align="flex-start" pb="10">
			<Heading
				textTransform={"uppercase"}
				letterSpacing={2}
				color="gray.600"
			>
				{region.region}
			</Heading>
			<Table
				size="small"
				bg="white"
				color="gray.600"
				key={"tableSummary-" + region.regionID}
			>
				<MeritIncreasesRegionSummariesTableHeader region={region} />

				<Tbody
					borderLeft="2px"
					borderRight="2px"
					borderColor="blackAlpha.700"
				>
					{departmentMap?.map((dept, i) => {
						return (
							<MeritIncreasesRegionSummariesTableRow
								key={i}
								dept={dept}
							/>
						);
					})}
				</Tbody>
				<MeritIncreasesRegionSummariesTableFooter
					region={region}
					deptTypeArr={deptTypeArr}
					regionEmployees={regionEmployees}
					regionPct={regionPct}
					regionTotals={regionTotals}
					regionNet={regionNet}
				/>
			</Table>
		</Stack>
	);
}

function MeritIncreasesRegionSummariesTableHeader(props) {
	const region = useMemo(() => props.region, [props.region]);
	return (
		<Thead
			border="2px"
			borderColor="blackAlpha.700"
			bg={region.color.replace("6", "7")}
			fontWeight={"bold"}
		>
			<Th p={2} textAlign="left" color="white">
				Department
			</Th>
			<Th p={2} textAlign="right" color="white">
				Current Salaries
			</Th>
			<Th p={2} textAlign="right" color="white">
				Proposed Salaries
			</Th>
			<Th p={2} textAlign="right" color="white">
				Proposed Increase
			</Th>
			<Th p={2} textAlign="right" color="white">
				Percent Increase
			</Th>
			<Th p={2} textAlign="right" color="white">
				Proposed Bonuses
			</Th>
			<Th p={2} textAlign="right" color="white">
				Phone Allowances
			</Th>
			<Th p={2} textAlign="right" color="white">
				Vehicle Allowances
			</Th>
			<Th p={2} textAlign="right" color="white">
				Housing Allowances
			</Th>
			<Th p={2} textAlign="right" color="white">
				Total Yearly&nbsp;Comp
			</Th>
		</Thead>
	);
}

function MeritIncreasesRegionSummariesTableRow(props) {
	const dept = useMemo(() => props.dept, [props.dept]);
	let pct = 0;
	if (dept.totalsRow.newSalary !== 0) {
		pct =
			((dept.totalsRow.proposedSalary - dept.totalsRow.newSalary) /
				dept.totalsRow.newSalary) *
			100;
	}
	let net =
		dept.totalsRow.proposedSalary +
		dept.totalsRow.proposedBonus +
		(dept.totalsRow.proposedCell +
			dept.totalsRow.proposedVehicle +
			dept.totalsRow.proposedHousing) *
			12;
	return (
		<Tr textTransform="uppercase">
			<Td px={2}>
				<Text isTruncated>
					{dept.dept +
						" (" +
						dept.totalsRow.hasProposal +
						"/" +
						dept.totalsRow.employeeCount +
						")"}
				</Text>
			</Td>
			<Td px={2}>
				<Text textAlign="right">
					{formatValue(dept.totalsRow.newSalary, 0, "currency")}
				</Text>
			</Td>
			<Td px={2}>
				<Text textAlign="right">
					{formatValue(dept.totalsRow.proposedSalary, 0, "currency")}
				</Text>
			</Td>
			<Td px={2}>
				<Text
					fontWeight={
						formatValue(
							dept.totalsRow.proposedSalary -
								dept.totalsRow.newSalary,
							0,
							"integer"
						) > 0
							? "semibold"
							: formatValue(
									dept.totalsRow.proposedSalary -
										dept.totalsRow.newSalary,
									0,
									"integer"
							  ) < 0
							? "semibold"
							: "normal"
					}
					color={
						formatValue(
							dept.totalsRow.proposedSalary -
								dept.totalsRow.newSalary,
							0,
							"integer"
						) > 0
							? "green.600"
							: formatValue(
									dept.totalsRow.proposedSalary -
										dept.totalsRow.newSalary,
									1,
									"integer"
							  ) < 0
							? "red.600"
							: ""
					}
					textAlign="right"
				>
					{(formatValue(
						dept.totalsRow.proposedSalary -
							dept.totalsRow.newSalary,
						1,
						"integer"
					) > 0
						? "+"
						: "") +
						formatValue(
							dept.totalsRow.proposedSalary -
								dept.totalsRow.newSalary,
							0,
							"currency"
						)}
				</Text>
			</Td>
			<Td px={2}>
				<Text
					fontWeight={
						formatValue(pct, 1, "numeric") > 0
							? "semibold"
							: formatValue(pct, 1, "numeric") < 0
							? "semibold"
							: "normal"
					}
					color={
						formatValue(pct, 1, "numeric") > 0
							? "green.600"
							: formatValue(pct, 1, "numeric") < 0
							? "red.600"
							: ""
					}
					textAlign="right"
				>
					{(formatValue(pct, 2, "numeric") > 0 ? "+" : "") +
						formatValue(pct, 2, "percent")}
				</Text>
			</Td>
			<Td px={2}>
				<Text textAlign="right">
					{formatValue(dept.totalsRow.proposedBonus, 0, "currency")}
				</Text>
			</Td>
			<Td px={2}>
				<Text textAlign="right">
					{formatValue(dept.totalsRow.proposedCell, 0, "currency")}
				</Text>
			</Td>
			<Td px={2}>
				<Text textAlign="right">
					{formatValue(dept.totalsRow.proposedVehicle, 0, "currency")}
				</Text>
			</Td>
			<Td px={2}>
				<Text textAlign="right">
					{formatValue(dept.totalsRow.proposedHousing, 0, "currency")}
				</Text>
			</Td>
			<Td px={2}>
				<Text textAlign="right">{formatValue(net, 0, "currency")}</Text>
			</Td>
		</Tr>
	);
}

function MeritIncreasesRegionSummariesTableFooter(props) {
	const region = useMemo(() => props.region, [props.region]);
	const deptTypeArr = useMemo(() => props.deptTypeArr, [props.deptTypeArr]);
	const regionEmployees = useMemo(
		() => props.regionEmployees,
		[props.regionEmployees]
	);
	const regionTotals = useMemo(
		() => props.regionTotals,
		[props.regionTotals]
	);
	const regionNet = useMemo(() => props.regionNet, [props.regionNet]);
	const regionPct = useMemo(() => props.regionPct, [props.regionPct]);
	return (
		<Tfoot border="2px">
			<Tr textTransform="uppercase" borderColor="blackAlpha.700">
				<Td bg="blackAlpha.100" px={2}>
					<Text fontWeight={"bold"}>{region.region}</Text>
				</Td>
				<Td bg="blackAlpha.100" px={2}>
					<Text fontWeight={"bold"} textAlign="right">
						{formatValue(regionTotals.newSalary, 0, "currency")}
					</Text>
				</Td>
				<Td bg="blackAlpha.100" px={2}>
					<Text fontWeight={"bold"} textAlign="right">
						{formatValue(
							regionTotals.proposedSalary,
							0,
							"currency"
						)}
					</Text>
				</Td>
				<Td bg="blackAlpha.100" px={2}>
					<Text
						color={
							regionPct > 0
								? "green.600"
								: regionPct < 0
								? "red.600"
								: ""
						}
						fontWeight={
							regionPct > 0
								? "bold"
								: regionPct < 0
								? "bold"
								: "normal"
						}
						textAlign="right"
					>
						{formatValue(
							regionTotals.proposedSalary -
								regionTotals.newSalary,
							0,
							"currency"
						)}
					</Text>
				</Td>
				<Td bg="blackAlpha.100" px={2}>
					<Text
						color={
							regionPct > 0
								? "green.600"
								: regionPct < 0
								? "red.600"
								: ""
						}
						fontWeight={
							regionPct > 0
								? "bold"
								: regionPct < 0
								? "bold"
								: "normal"
						}
						textAlign="right"
					>
						{(regionPct > 0 ? "+" : "") +
							formatValue(regionPct, 2, "percent")}
					</Text>
				</Td>
				<Td bg="blackAlpha.100" px={2}>
					<Text fontWeight={"bold"} textAlign="right">
						{formatValue(regionTotals.proposedBonus, 0, "currency")}
					</Text>
				</Td>
				<Td bg="blackAlpha.100" px={2}>
					<Text fontWeight={"bold"} textAlign="right">
						{formatValue(regionTotals.proposedCell, 0, "currency")}
					</Text>
				</Td>
				<Td bg="blackAlpha.100" px={2}>
					<Text fontWeight={"bold"} textAlign="right">
						{formatValue(
							regionTotals.proposedVehicle,
							0,
							"currency"
						)}
					</Text>
				</Td>
				<Td bg="blackAlpha.100" px={2}>
					<Text fontWeight={"bold"} textAlign="right">
						{formatValue(
							regionTotals.proposedHousing,
							0,
							"currency"
						)}
					</Text>
				</Td>
				<Td bg="blackAlpha.100" px={2}>
					<Text fontWeight={"bold"} textAlign="right">
						{formatValue(regionNet, 0, "currency")}
					</Text>
				</Td>
			</Tr>

			<Tr
				bg="blackAlpha.100"
				textTransform="uppercase"
				borderColor="blackAlpha.700"
			>
				<Td colSpan={10}>
					<Text minH={2}></Text>
				</Td>
			</Tr>

			{deptTypeArr?.map((type, i) => {
				let deptTypeEmployees = new EmployeeHistoryList(
					regionEmployees.filter(
						(data) => data?.prDeptType === type?.deptType
					)
				);
				let deptTypeTotals =
					deptTypeEmployees.getEmployeeHistoryTotalsSummary();

				let deptTypePct = 0;
				if (deptTypeTotals.newSalary !== 0) {
					deptTypePct =
						((deptTypeTotals.proposedSalary -
							deptTypeTotals.newSalary) /
							deptTypeTotals.newSalary) *
						100;
				}
				let deptTypeNet =
					deptTypeTotals.proposedSalary +
					deptTypeTotals.proposedBonus +
					(deptTypeTotals.proposedCell +
						deptTypeTotals.proposedVehicle +
						deptTypeTotals.proposedHousing) *
						12;

				return (
					<Tr
						key={i}
						textTransform="uppercase"
						borderColor="blackAlpha.700"
					>
						<Td bg="blackAlpha.100" px={2}>
							<Text>{type.description}</Text>
						</Td>
						<Td bg="blackAlpha.100" px={2}>
							<Text textAlign="right">
								{formatValue(
									deptTypeTotals.newSalary,
									0,
									"currency"
								)}
							</Text>
						</Td>
						<Td bg="blackAlpha.100" px={2}>
							<Text textAlign="right">
								{formatValue(
									deptTypeTotals.proposedSalary,
									0,
									"currency"
								)}
							</Text>
						</Td>
						<Td bg="blackAlpha.100" px={2}>
							<Text
								color={
									deptTypePct > 0
										? "green.600"
										: deptTypePct < 0
										? "red.600"
										: ""
								}
								fontWeight={
									deptTypePct > 0
										? "bold"
										: deptTypePct < 0
										? "bold"
										: "normal"
								}
								textAlign="right"
							>
								{formatValue(
									deptTypeTotals.proposedSalary -
										deptTypeTotals.newSalary,
									0,
									"currency"
								)}
							</Text>
						</Td>
						<Td bg="blackAlpha.100" px={2}>
							<Text
								color={
									deptTypePct > 0
										? "green.600"
										: deptTypePct < 0
										? "red.600"
										: ""
								}
								fontWeight={
									deptTypePct > 0
										? "bold"
										: deptTypePct < 0
										? "bold"
										: "normal"
								}
								textAlign="right"
							>
								{(deptTypePct > 0 ? "+" : "") +
									formatValue(deptTypePct, 2, "percent")}
							</Text>
						</Td>
						<Td bg="blackAlpha.100" px={2}>
							<Text textAlign="right">
								{formatValue(
									deptTypeTotals.proposedBonus,
									0,
									"currency"
								)}
							</Text>
						</Td>
						<Td bg="blackAlpha.100" px={2}>
							<Text textAlign="right">
								{formatValue(
									deptTypeTotals.proposedCell,
									0,
									"currency"
								)}
							</Text>
						</Td>
						<Td bg="blackAlpha.100" px={2}>
							<Text textAlign="right">
								{formatValue(
									deptTypeTotals.proposedVehicle,
									0,
									"currency"
								)}
							</Text>
						</Td>
						<Td bg="blackAlpha.100" px={2}>
							<Text textAlign="right">
								{formatValue(
									deptTypeTotals.proposedHousing,
									0,
									"currency"
								)}
							</Text>
						</Td>
						<Td bg="blackAlpha.100" px={2}>
							<Text textAlign="right">
								{formatValue(deptTypeNet, 0, "currency")}
							</Text>
						</Td>
					</Tr>
				);
			})}
			<Tr textTransform="uppercase" borderColor="blackAlpha.700" pb="2">
				<Td bg="blackAlpha.100" px={2}>
					<Text fontWeight={"bold"}>{region.region}</Text>
				</Td>
				<Td bg="blackAlpha.100" px={2}>
					<Text fontWeight={"bold"} textAlign="right">
						{formatValue(regionTotals.newSalary, 0, "currency")}
					</Text>
				</Td>
				<Td bg="blackAlpha.100" px={2}>
					<Text fontWeight={"bold"} textAlign="right">
						{formatValue(
							regionTotals.proposedSalary,
							0,
							"currency"
						)}
					</Text>
				</Td>
				<Td bg="blackAlpha.100" px={2}>
					<Text
						color={
							regionPct > 0
								? "green.600"
								: regionPct < 0
								? "red.600"
								: ""
						}
						fontWeight={"bold"}
						textAlign="right"
					>
						{formatValue(
							regionTotals.proposedSalary -
								regionTotals.newSalary,
							0,
							"currency"
						)}
					</Text>
				</Td>
				<Td bg="blackAlpha.100" px={2}>
					<Text
						color={
							parseFloat(formatValue(regionPct, 2)) > 0.0
								? "green.600"
								: parseFloat(formatValue(regionPct, 2)) < 0.0
								? "red.600"
								: ""
						}
						fontWeight={"bold"}
						textAlign="right"
					>
						{(regionPct > 0 ? "+" : "") +
							formatValue(regionPct, 2, "percent")}
					</Text>
				</Td>
				<Td bg="blackAlpha.100" px={2}>
					<Text fontWeight={"bold"} textAlign="right">
						{formatValue(regionTotals.proposedBonus, 0, "currency")}
					</Text>
				</Td>
				<Td bg="blackAlpha.100" px={2}>
					<Text fontWeight={"bold"} textAlign="right">
						{formatValue(regionTotals.proposedCell, 0, "currency")}
					</Text>
				</Td>
				<Td bg="blackAlpha.100" px={2}>
					<Text fontWeight={"bold"} textAlign="right">
						{formatValue(
							regionTotals.proposedVehicle,
							0,
							"currency"
						)}
					</Text>
				</Td>
				<Td bg="blackAlpha.100" px={2}>
					<Text fontWeight={"bold"} textAlign="right">
						{formatValue(
							regionTotals.proposedHousing,
							0,
							"currency"
						)}
					</Text>
				</Td>
				<Td bg="blackAlpha.100" px={2}>
					<Text fontWeight={"bold"} textAlign="right">
						{formatValue(regionNet, 0, "currency")}
					</Text>
				</Td>
			</Tr>
		</Tfoot>
	);
}
