import React, { useState, useMemo } from "react";
// import moment from "moment";
import SectionObj from "../../../core/Reports/classes/Section";
import SectionTemplateJobCostDrilldown from "../templates/SectionTemplateJobCostDrilldown";
import SectionTemplateLaborDrilldown from "../templates/SectionTemplateLaborDrilldown";
import Section from "../../../core/Reports/components/Section";
// import { log } from "../../../helperFunctions";
export default function JobCostDrilldown(props) {
	let pagebreak = props.pagebreak ?? 0;

	const [laborDrilldown, setLaborDrilldown] = useState(new SectionObj(new SectionTemplateLaborDrilldown()));
	const [matlCostDrilldown, setMatlCostDrilldown] = useState(new SectionObj(new SectionTemplateJobCostDrilldown()));
	const [subsCostDrilldown, setSubsCostDrilldown] = useState(new SectionObj(new SectionTemplateJobCostDrilldown()));
	const [equipCostDrilldown, setEquipCostDrilldown] = useState(new SectionObj(new SectionTemplateJobCostDrilldown()));
	const [othrCostDrilldown, setOthrCostDrilldown] = useState(new SectionObj(new SectionTemplateJobCostDrilldown()));

	useMemo(() => {
		let jobCosts = props.contractData?.filter((d) => d.queryData === "JobCostDetailByMth") ?? [];
		let contract = props.contractData?.find((d) => d.queryData === "JobCostDetailByMth") ?? [];

		jobCosts = jobCosts?.filter((d) => new Date(d.mth).getTime() === new Date(props.thruMth).getTime());

		let isLoaded = Boolean(Boolean(props.contractData?.length > 0) || Boolean(contract));

		let laborCosts = jobCosts?.filter((d) => [1, 6].includes(d.costType)) ?? [];
		let matlCosts = jobCosts?.filter((d) => [2].includes(d.costType)) ?? [];
		let subsCosts = jobCosts?.filter((d) => [3].includes(d.costType)) ?? [];
		let equipCosts = jobCosts?.filter((d) => [4].includes(d.costType)) ?? [];
		let othrCosts = jobCosts?.filter((d) => [5].includes(d.costType)) ?? [];

		setLaborDrilldown(new SectionObj(new SectionTemplateLaborDrilldown(laborCosts, 1, 22, isLoaded)));
		setMatlCostDrilldown(new SectionObj(new SectionTemplateJobCostDrilldown(matlCosts, 1, 22, isLoaded)));
		setSubsCostDrilldown(new SectionObj(new SectionTemplateJobCostDrilldown(subsCosts, 1, 22, isLoaded)));
		setEquipCostDrilldown(new SectionObj(new SectionTemplateJobCostDrilldown(equipCosts, 1, 22, isLoaded)));
		setOthrCostDrilldown(new SectionObj(new SectionTemplateJobCostDrilldown(othrCosts, 1, 22, isLoaded)));
	}, [props.contractData, props.thruMth]);

	return (
		<>
			{pagebreak === 0 && <Section section={laborDrilldown} />}
			{pagebreak === 0 && <Section section={matlCostDrilldown} />}
			{pagebreak === 0 && <Section section={subsCostDrilldown} />}
			{pagebreak === 0 && <Section section={equipCostDrilldown} />}
			{pagebreak === 0 && <Section section={othrCostDrilldown} />}
		</>
	);
}
