import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Flex, useBoolean } from "@chakra-ui/react";
import MainContent from "../../core/ReactTable/layout/MainContent";
//import MainContent from "../../legacy/App/components/MainContent";
import DFJobsPage from "./pages/DFJobsPage";
import DFSchedulesPage from "./pages/DFSchedulesPage";
import DF_TEMPLATE_TYPES from "./constants/DFTemplateTypes";

import { portalUser } from "../../App";
import { convertArrayToMap } from "../../helperFunctions";

// Define the the two D&F arrays to set up the panels
const settingsMap = convertArrayToMap(DF_TEMPLATE_TYPES, "scopeID");

export default function DFMain({ jobID = null, jccmKeyID = null, scopeID = null, regionID = null, debug = false }) {
	const reportSettings = useMemo(
		() => (Boolean(scopeID) && scopeID?.length > 0 ? settingsMap[scopeID] : settingsMap[null]),
		[scopeID]
	);
	const [bannerTitle, setBannerTitle] = useState(reportSettings?.bannerTitle);
	const [bannerSubtitle, setBannerSubtitle] = useState(reportSettings?.bannerSubtitle);

	const tabs = useMemo(() => reportSettings?.tabs, [reportSettings]);
	const [tabVal, setTabVal] = useState(0);
	const changeTab = useCallback((e) => setTabVal(e), []);

	const [params, setParams] = useState({ jobID, jccmKeyID, scopeID, regionID });
	const [dfJobs, setDFJobs] = useState([]); // ??
	const [selectedJobs, setSelectedJobs] = useState([]);

	const [isLoading, setIsLoading] = useBoolean(true);

	// Callback to turn on the Data is loading flag
	const setDataLoading = useCallback(
		(callback) => {
			if (!isLoading) {
				setIsLoading.on();
			}
			callback();
		},
		[isLoading, setIsLoading]
	);

	// 3. if Params contain a value, make portalUser getDFJobs API call
	const initSelectedJobs = useCallback(
		async (params, selectedParams = true) => {
			let selectedJobs = [];
			if (selectedParams) {
				selectedJobs = await portalUser.DFData.getDFData(params, "jobs");
			}
			setSelectedJobs(selectedJobs);
			setIsLoading.off();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[debug]
	);

	// 2. Update the Params and set Data is Loading
	const initParams = useCallback(
		async (params = { jobID, jccmKeyID, scopeID, regionID }) => {
			let paramValues = Object.values(params);
			let paramCount = paramValues?.filter((d) => Boolean(d) && d !== "")?.length ?? 0;
			setParams(params);
			setDataLoading(() => initSelectedJobs(params, Boolean(paramCount > 0)));
		},
		[jobID, jccmKeyID, scopeID, regionID, setDataLoading, initSelectedJobs]
	);

	// 1. Initialize D&F Jobs the first time the Page is Loaded
	useEffect(() => {
		const initDFJobs = async (params = { jobID, jccmKeyID, scopeID, regionID }) => {
			if (dfJobs?.length === 0) {
				let jobs = portalUser?.DFData?.jobs ?? [];
				if (jobs?.length === 0) {
					jobs = await portalUser.DFData?.getDFData(params, "jobs");
				}
				if (Boolean(reportSettings?.template)) {
					jobs = jobs?.filter((d) => d.templateType === reportSettings?.template) ?? [];
				}
				setDFJobs(jobs);
			}
			initParams(params);
		};
		setDataLoading(initDFJobs);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Update the banner text when changing the tab value
	const updateBanner = useCallback(
		(bannerTitle = null, bannerSubtitle = null) => {
			if (!isLoading && bannerTitle !== reportSettings[tabVal]?.bannerTitle) {
				setBannerTitle(bannerTitle);
			}
			if (!isLoading && bannerSubtitle !== reportSettings[tabVal]?.bannerTitle) {
				setBannerSubtitle(bannerSubtitle);
			}
		},
		[isLoading, reportSettings, tabVal]
	);

	return (
		<MainContent
			title={bannerTitle}
			subtitle={bannerSubtitle}
			allowFullScreen
			tabVal={tabVal}
			tabs={tabs}
			changeTab={changeTab}
		>
			<Flex bg="blackAlpha.400" direction="column" flex={1} justify="flex-start" h="full" px={4}>
				{tabVal === 0 && (
					<DFJobsPage
						reportSettings={reportSettings}
						updateBanner={updateBanner}
						dfJobs={dfJobs}
						selectedJobs={selectedJobs}
						params={params}
						initParams={initParams}
						tabVal={tabVal}
						changeTab={changeTab}
						isLoading={isLoading}
					/>
				)}
				{tabVal === 1 && (
					<DFSchedulesPage
						reportSettings={reportSettings}
						updateBanner={updateBanner}
						dfJobs={dfJobs}
						selectedJobs={selectedJobs}
						params={params}
						initParams={initParams}
						tabVal={tabVal}
						changeTab={changeTab}
						isLoading={isLoading}
					/>
				)}
			</Flex>
		</MainContent>
	);
}
