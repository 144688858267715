import React from "react";
import { Heading, Stack } from "@chakra-ui/react";

export default function ExampleContainer({ heading, subheading, maxW = "container.lg", children }) {
	return (
		<Stack h="full" w="full" maxW={maxW} spacing={8}>
			<Stack spacing={1} textTransform="uppercase">
				<Heading size="md" color="gray.400" letterSpacing={2}>
					{heading}
				</Heading>
				<Heading size="lg" color="gray.500" letterSpacing={2}>
					{subheading}
				</Heading>

				<Stack
					spacing={6}
					p={4}
					flex={1}
					w="full"
					bg="blackAlpha.50"
					border="3px dashed"
					rounded={"lg"}
					borderColor="gray.300"
				>
					{children}
				</Stack>
			</Stack>
		</Stack>
	);
}
