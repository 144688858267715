import React, { useMemo } from "react";
import { Flex, Stack, HStack, Heading, SlideFade } from "@chakra-ui/react";
import CollapseButton from "../buttons/CollapseButton";

export default function SectionHeader({ page, section, collapseIsOpen, setCollapseIsOpen, headerButtons, searchBar }) {
	const collapseButton = useMemo(() => {
		return <CollapseButton collapseIsOpen={collapseIsOpen} setCollapseIsOpen={setCollapseIsOpen} />;
	}, [collapseIsOpen, setCollapseIsOpen]);

	return (
		<Stack direction="column" w="full" rounded="md" px={1} py={2}>
			<Flex direction="row" justify="space-between" w="full" rounded={"md"}>
				<HStack spacing={4} align="center" pr={4} isTruncated>
					{Boolean(section?.isCollapsable) && page?.pageBody?.length > 1 && collapseButton}
					<Stack spacing={1}>
						{Boolean(section?.label || section?.heading) && (
							<HStack spacing={4} letterSpacing={2} color="gray.600" textTransform="uppercase">
								{Boolean(section?.label) && <Heading size="lg">{section?.label} </Heading>}
								{Boolean(section?.heading) && <Heading fontSize="2xl">{section?.heading} </Heading>}
							</HStack>
						)}
						{Boolean(section?.subheading) && (
							<HStack spacing={1} letterSpacing={1} color="gray.700" textTransform="uppercase">
								{Boolean(section?.subheading) && <Heading size="sm">{section?.subheading}</Heading>}
							</HStack>
						)}
					</Stack>
				</HStack>

				<SlideFade
					in={collapseIsOpen}
					offsetX="100px"
					offsetY="0px"
					unmountOnExit
					style={{
						display: "flex",
						flex: 1,
						flexDirection: "row",
						width: "100%",
						justifyContent: "flex-end",
						alignItems: "center",
					}}
				>
					<HStack color="gray.500">{headerButtons}</HStack>
				</SlideFade>
			</Flex>
			{Boolean(searchBar) && searchBar}
		</Stack>
	);
}
