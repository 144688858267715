import React, { useState, useEffect, useMemo } from "react";
import { Box, Flex, Center, Heading, Text, Stack, HStack, Divider, Button, Collapse } from "@chakra-ui/react";
import BasicCard from "../../../core/Layout/cards/BasicCard";
import moment from "moment";
import { motion } from "framer-motion";

import { portalUser } from "../../../App";
import { mergeSort } from "../../../helperFunctions";
// import { log } from "../../../helperFunctions";

export default function WeekAheadCard() {
	const [events, setEvents] = useState(null);
	const [showMore, setShowMore] = useState(false);

	useEffect(() => {
		const init = async () => {
			const date = new Date();
			let nextDay = new Date();
			nextDay.setDate(date.getDate() + 7);

			const outlookEvents = await portalUser.msClient.GetMSUserCalendarView(
				date.toLocaleDateString(),
				nextDay.toLocaleDateString()
			);
			let calendarEvents = [];

			for (let i = 0; i < outlookEvents.value?.length; i++) {
				let startDate = moment(outlookEvents.value[i].start.dateTime);
				let endDate = moment(outlookEvents.value[i].end.dateTime);
				if (outlookEvents.value[i].isAllDay) {
					endDate = startDate;
				}

				calendarEvents.push({
					id: outlookEvents.value[i].id,
					eventType: "Outlook",
					start: startDate,
					end: endDate,
					allday: outlookEvents.value[i].isAllDay,
					subject: outlookEvents.value[i].subject,
					body: outlookEvents.value[i].body,
					bodyPreview: outlookEvents.value[i].bodyPreview,
					responseRequested: outlookEvents.value[i].responseRequested,
					responseStatus: outlookEvents.value[i].responseStatus,
					isCancelled: outlookEvents.value[i].isCancelled,
					attendees: outlookEvents.value[i].attendees,
				});
			}

			const attribute = { name: "start", type: "number" };
			calendarEvents = mergeSort(calendarEvents, attribute, 0, (event, attr) => {
				return new Date(event[attr]).getTime();
			});

			setEvents(calendarEvents);
		};
		if (portalUser.msClient) {
			init();
		}
	}, []);

	let eventRows = [];
	for (let i = 0; i < events?.length; i++) {
		eventRows.push(<WeekAheadCardRow key={i} event={events[i]} />);
	}

	return (
		<BasicCard title="Your Week Ahead">
			<Stack spacing={3} pb={3}>
				{events?.length === 0 && (
					<Center p={3}>
						<Box pb={1}>
							<Divider
								orientation="vertical"
								borderColor="teal.500"
								opacity="0"
								borderWidth="2px"
								mr="2"
							/>
						</Box>
						<Box px={2}>
							<Heading as="h6" size="sm" color="gray.500">
								No Scheduled Events
							</Heading>
						</Box>
					</Center>
				)}
				{eventRows?.map((eventRow, i) => i < 4 && eventRow)}

				<Collapse in={showMore} style={{ overflow: "visible" }}>
					<Stack spacing={3}>{eventRows?.map((eventRow, i) => i >= 4 && eventRow)}</Stack>
				</Collapse>
			</Stack>
			{events?.length > 4 && (
				<Flex w="full" justify="center">
					<Button
						variant="outline"
						onClick={() => setShowMore(!showMore)}
						color="gray.500"
						px={2}
						size="md"
						bg="whiteAlpha.700"
						shadow="md"
						textTransform="uppercase"
						_hover={{
							color: "blue.500",
							fontWeight: "bold",
							bg: "whiteAlpha.800",
							borderColor: "blue.500",
							textDecoration: "underline",
							borderWidth: 2,
						}}
					>
						<HStack align="center" spacing={1} key={!showMore ? "Show More" : "Show Less"}>
							<Text
								lineHeight={1}
								className={!showMore ? "fas fa-plus fa-fw" : "fas fa-minus fa-fw"}
								fontSize="sm"
							/>
							<Text lineHeight={1} fontSize="sm" letterSpacing={1} pr={1}>
								{!showMore ? "Show More" : "Show Less"}
							</Text>
						</HStack>
					</Button>
				</Flex>
			)}
		</BasicCard>
	);
}

function WeekAheadCardRow(props) {
	const event = useMemo(() => props.event, [props.event]);

	return (
		<motion.div whileHover={{ scale: 1.01, transition: { duration: 0.5 } }}>
			<Flex
				p={4}
				rounded="md"
				bg="whiteAlpha.800"
				_hover={{
					borderWidth: 2,
					borderColor: (event?.isCancelled ? "red" : "blue") + ".500",
					bg: (event?.isCancelled ? "red" : "blue") + ".50",
					shadow: "lg",
				}}
				borderWidth={2}
				flex={1}
				w="full"
				shadow={portalUser.styles?.cardShadow}
				borderColor={portalUser.styles?.cardBorderColor}
			>
				<Stack
					spacing={1}
					flex={1}
					w="full"
					pl={3}
					borderLeftWidth={4}
					borderLeftColor={(event?.isCancelled ? "red" : "blue") + ".500"}
				>
					<Heading color="gray.600" size="md">
						{event?.subject}
					</Heading>
					<Stack spacing={0}>
						<Text color="gray.500" fontSize="md">
							{event?.start.format("dddd, MMMM Do YYYY")}
						</Text>
						<Text color="gray.500" fontSize="md">
							{event?.start.format("h:mma") + " - " + event?.end.format("h:mma")}
						</Text>
					</Stack>
				</Stack>
			</Flex>
		</motion.div>
	);
}
