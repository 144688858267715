import { formatValue } from "../../../helperFunctions";
export default class PowerBIEmbedLink {
	constructor(link) {
		if (link instanceof PowerBIEmbedLink) {
			Object.assign(this, link);
		} else {
			this.queryData = link?.queryData ?? "EmbedLink";
			this.keyID = link?.keyID?.toString() ?? null;
			this.module = link?.module?.toUpperCase() ?? "DEV";
			this.ctid = link?.ctid?.toString()?.toLowerCase() ?? null;
			this.reportId = link?.reportId?.toString()?.toLowerCase() ?? null;
			this.title = link?.title ?? null;
			this.abbr = link?.abbr?.toUpperCase() ?? null;
			this.description = link?.description ?? null;
			this.version = link?.version?.toString() ?? "1.0";
			this.status = link?.status ?? "In Progress";
			this.dateModified = formatValue(link?.dateModified, 0, "date") ?? null;
			this.notes = link?.notes ?? null;
			this.url = link?.url ?? null;
		}
	}

	// async upload() {
	// 	let insertResult = await this.devopsAPI.InsertPowerBILink(this.reportUID);
	// 	return insertResult;
	// }

	// async updateDB(updates) {
	// 	let result = await this.devopsAPI.UpdatePowerBILink(this.reportUID, updates);
	// 	if (result.status === 200) {
	// 		return true;
	// 	} else {
	// 		return false;
	// 	}
	// }

	// async updateTitle(value) {
	// 	this.title = value.replace("'", "`");
	// 	let updates = {
	// 		Title: this.title,
	// 	};
	// 	await this.updateDB(updates);
	// }

	// async updateFileName(value) {
	// 	this.fileName = value.replace("'", "`");
	// 	let updates = {
	// 		FileName: this.fileName,
	// 	};
	// 	await this.updateDB(updates);
	// }

	// async updateTab(value) {
	// 	this.tab = value.replace("'", "`");
	// 	let updates = {
	// 		Tab: this.tab,
	// 	};
	// 	await this.updateDB(updates);
	// }

	// async updateURL(value) {
	// 	this.url = value.replace("'", "`");
	// 	let updates = {
	// 		URL: this.url,
	// 	};
	// 	await this.updateDB(updates);
	// }

	// async updateIsActive(value) {
	// 	this.isActive = value;
	// 	let updates = {
	// 		isActive: this.isActive,
	// 	};
	// 	await this.updateDB(updates);
	// }
}
