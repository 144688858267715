const FORM_TEMPLATE_TYPES = [
	{
		keyID: 1,
		formType: "0",
		formTypeID: "0",
		formTypeName: "N/A",
		formTypeDesc: "Not Assigned",
		formTypeAbbr: "N/A",
		isActiveYN: "Y",
	},
	{
		keyID: 2,
		formType: "1",
		formTypeID: "1",
		formTypeName: "Self-Eval",
		formTypeDesc: "Self-Evaluation",
		formTypeAbbr: "SELF",
		isActiveYN: "Y",
	},
	{
		keyID: 3,
		formType: "2",
		formTypeID: "2",
		formTypeName: "Review",
		formTypeDesc: "Performance Review",
		formTypeAbbr: "REVIEW",
		isActiveYN: "Y",
	},
	{
		keyID: 4,
		formType: "3",
		formTypeID: "3",
		formTypeName: "Peer Eval",
		formTypeDesc: "Peer Evaluation",
		formTypeAbbr: "PEER",
		isActiveYN: "Y",
	},
	{
		keyID: 5,
		formType: "4",
		formTypeID: "4",
		formTypeName: "Survey",
		formTypeDesc: "Survey",
		formTypeAbbr: "SURVEY",
		isActiveYN: "N",
	},
	{
		keyID: 6,
		formType: "5",
		formTypeID: "5",
		formTypeName: "New Hire",
		formTypeDesc: "New Hire Form",
		formTypeAbbr: "HIRE",
		isActiveYN: "N",
	},
	{
		keyID: 7,
		formType: "6",
		formTypeID: "6",
		formTypeName: "FAQ",
		formTypeDesc: "Frequently Asked Questions",
		formTypeAbbr: "FAQ",
		isActiveYN: "N",
	},
];
export default FORM_TEMPLATE_TYPES;
