const USER_STATUSES = [
	{ value: "N/A", status: "N/A" },
	{ value: "Consultant", status: "Consultant" },
	{ value: "Maternity", status: "Maternity/PFL" },
	{ value: "Leave", status: "Leave" },
	{ value: "Disability", status: "Disability" },
	{ value: "Seasonal", status: "Seasonal" },
	{ value: "Paternity", status: "Paternity/PFL" },
];
export default USER_STATUSES;
