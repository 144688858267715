import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
	Flex,
	Text,
	Container,
	Heading,
	Stack,
	HStack,
	VStack,
	Checkbox,
	Button,
	IconButton,
	Switch,
	Box,
	useToast,
} from "@chakra-ui/react";
import Panels from "../../../core/ReactTable/layout/TabPanels";
import DataInput from "../../../core/Inputs/data/DataInput";
import FullPageDrawer from "../../../core/Layout/drawers/FullPageDrawer";

import DFJob from "../classes/DFJob";
import DFTeam from "../classes/DFTeam";
import SUP_TEAM_MEMBERS from "../constants/SUPTeamMembers";

import { portalUser } from "../../../App";
import { log, arrayToString, convertArrayToMap } from "../../../helperFunctions";

export default function DFJobDrawer({ dfJob, existingDFJobNames, isOpen, onClose, selectJob }) {
	const [job] = useState(new DFJob(dfJob));
	const [isLoadingNewWorkflows, setIsLoadingNewWorkflows] = useState(false);
	const [updateTrigger, setUpdateTrigger] = useState(false);
	const [panelVal, setPanelVal] = useState(0);
	const changePanel = useCallback((panelVal = 0) => {
		setPanelVal(panelVal);
	}, []);
	const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

	const updateJob = useCallback((msg = { id: null, title: null, description: null }) => {
		msg = Object.assign(msg, {
			id: msg.id,
			title: msg.title + " has been updated",
			status: "success",
			duration: 5000,
			isClosable: true,
		});
	}, []);

	// Dynamic seting of panels
	// If Start Up, add the edit columns panel
	const panels = useMemo(() => {
		let jobInfoPanel = {
			panel: "Job Information",
			content: <DFJobInfo job={job} updateJob={updateJob} existingDFJobNames={existingDFJobNames} />,
		};
		let editWorkflowPanel = { panel: "Edit Columns", content: <DFWorkflowEdit job={job} /> };
		let teamSelectPanel = { panel: "Select Team", content: <DFTeamSelect job={job} /> };
		let reviewCreatePanel = {
			panel: "Review & Create",
			content: (
				<DFReviewCreate
					job={job}
					existingDFJobNames={existingDFJobNames}
					submitButtonDisabled={submitButtonDisabled}
					setSubmitButtonDisabled={setSubmitButtonDisabled}
					onClose={onClose}
					setUpdateTrigger={setUpdateTrigger}
				/>
			),
		};

		if (job?.templateType === "SUP") {
			return [jobInfoPanel, editWorkflowPanel, teamSelectPanel, reviewCreatePanel];
		} else {
			return [jobInfoPanel, teamSelectPanel, reviewCreatePanel];
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [job]);

	const createNewWorkflows = async () => {
		setIsLoadingNewWorkflows(true);
		//Should copy all workflows from where workflowID === templateID with the new templateID
		let result = await portalUser?.DFData.initJobCustomWorkflows(job.id);
		if (result) {
			setUpdateTrigger(true);
			selectJob(job, "job", true);
		} else {
			// 'ERROR'
			alert("Error: Could not customize columns.");
		}
		setIsLoadingNewWorkflows(false);
	};

	useEffect(() => {
		if (updateTrigger) {
			setUpdateTrigger(false);
		}
	}, [updateTrigger]);

	return (
		<FullPageDrawer isOpen={isOpen} onClose={onClose}>
			<Container
				borderWidth={2}
				borderColor="gray.200"
				maxW="container.xl"
				bg="whiteAlpha.800"
				p={8}
				rounded={5}
				shadow="xl"
				minH="xl"
				h="full"
			>
				<Heading color="gray.500" textTransform="uppercase" letterSpacing={2} fontFamily={"body"}>
					{(job?.id ? "Edit " : "New ") + job?.templateType + " Job"}
				</Heading>

				<Panels
					panels={panels}
					panelVal={panelVal}
					changePanel={changePanel}
					buttonGroup={
						Boolean(job?.templateID === job?.workflowID) &&
						panelVal === 1 &&
						panels.length === 4 && (
							<Flex>
								<Button
									colorScheme="teal"
									textTransform={"uppercase"}
									isLoading={isLoadingNewWorkflows}
									onClick={createNewWorkflows}
								>
									Custom Columns
								</Button>
							</Flex>
						)
					}
				>
					<Flex
						minH="lg"
						direction="row"
						flex={1}
						w="full"
						h="full"
						p={4}
						rounded="5"
						borderWidth={2}
						borderColor="gray.200"
						bg="blackAlpha.50"
					>
						{Boolean(panels[panelVal]?.content) && panels[panelVal]?.content}
					</Flex>
				</Panels>
			</Container>
		</FullPageDrawer>
	);
}

export function DFJobInfo({ job, updateJob }) {
	const [contract, setContract] = useState(portalUser.getContract(job?.jccmKeyID) ?? {});
	const [template, setTemplate] = useState({});

	const updateContract = (contract) => {
		setContract(contract);
		job.initContract(contract);
		job.job = (job?.contract ?? "") + (job?.scopeAbbr ?? "");
		job.jobName = job?.contractName;
		log("UPDATE CONTRACT", job);
		let msg = { id: contract?.jccmKeyID, title: "Viewpoint Job", description: contract?.title };
		updateJob(msg);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	};

	const updateTemplate = useCallback((template) => {
		setTemplate(template);
		job.initTemplate(template);
		let rolesMap = convertArrayToMap(portalUser.DFData.roles, "templateType", true);
		let respRoles = rolesMap[job.templateType] ?? [];
		job.initTeam(job.team, respRoles);
		log("UPDATE TEMPLATE", job);
		job.job = (job?.contract ?? "") + (job?.scopeAbbr ?? "");
		let msg = { id: template?.templateID, title: "Scope", description: template?.scopeDesc };
		updateJob(msg);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useMemo(() => {
		let templates = portalUser.DFData.getTemplates(job?.templateType);
		if (Boolean(job?.templateID)) {
			templates = convertArrayToMap(templates, "templateID", true)[job?.templateID] ?? [];
		}
		if (Boolean(job?.scopeID)) {
			templates = convertArrayToMap(templates, "scopeID", true)[job?.scopeID] ?? [];
		}
		if (templates?.length === 1) {
			updateTemplate(templates[0]);
		}
	}, [job, updateTemplate]);

	return (
		<Stack flex={1} w="full" h="full">
			<DataInput
				isRequired
				isDisabled={Boolean(job?.jobID)}
				name="contract"
				label="Viewpoint Job"
				type="select"
				placeholder="Select Job"
				searchable
				options={portalUser.getContracts("1")}
				optionText="title"
				optionSubheading="subtitle"
				onOptionSelect={(e) => {
					if (e?.jccmKeyID !== job?.jccmKeyID) {
						updateContract(e);
					}
				}}
				value={contract?.title}
				isModal
			/>
			<DataInput
				isRequired
				isDisabled={Boolean(job?.jobID)}
				name="job"
				label="Job Number"
				type="text"
				defaultValue={job?.job ?? (job?.contract ?? "") + (job?.scopeAbbr ?? "")}
				onBlur={(e) => {
					if (e.target.value !== job?.job) {
						let msg = { id: "Job Number", title: "Job Number", description: e.target.value };
						job.job = e.target.value;
						log("UPDATE JOB NUMBER", job);
						updateJob(msg);
					}
				}}
			/>
			<DataInput
				isRequired
				name="jobName"
				label="Job Name"
				type="text"
				defaultValue={job?.jobName ?? job?.contractName}
				onBlur={(e) => {
					if (e.target.value !== job?.jobName) {
						let msg = { id: "Job Name", title: "Job Name", description: e.target.value };
						job.jobName = e.target.value;
						log("UPDATE JOB NAME", job);
						if (Boolean(job?.jobID)) {
							job.updateDB();
						}
						updateJob(msg);
					}
				}}
			/>
			<DataInput
				isRequired
				name="installOffset"
				label={job?.templateID === "13" || job?.templateType === "SUP" ? "Finish Date" : "Install Start Offset"}
				type="number"
				defaultValue={job?.installOffset ?? 0}
				onBlur={(e) => {
					if (e.target.value !== job?.installOffset) {
						// let msg = {
						// 	id: "installOffset",
						// 	title: "Install Start Offset",
						// 	description: formatValue(e.target.value, "number", 0) + " days",
						// };
						job.installOffset = e.target.value;
						log("UPDATE INSTALL START OFFSET", job);
						if (Boolean(job?.jobID)) {
							job.updateDB();
						}
						// updateJob(msg);
					}
				}}
			/>
			<DataInput
				isRequired
				isDisabled={Boolean(job?.jobID)}
				name="scope"
				type="select"
				label="Scope of Work"
				placeholder="Select Scope"
				options={portalUser.DFData.getTemplates()}
				optionText="scopeDesc"
				optionSubheading="templateName"
				searchable
				onOptionSelect={(e) => {
					if (e?.templateID !== template?.templateID) {
						updateTemplate(e);
					}
				}}
				value={template?.scopeDesc}
				isModal
			/>
			{job?.scopeID === "5" && (
				<>
					<DataInput
						isRequired
						name="vendorName"
						label="Vendor Name"
						type="text"
						defaultValue={job?.vendorName}
						onBlur={(e) => {
							if (e.target.value !== job?.vendorName) {
								let msg = { id: "Vendor Name", title: "Vendor Name", description: e.target.value };
								job.vendorName = e.target.value;
								log("UPDATE VENDOR NAME", job);
								if (Boolean(job?.jobID)) {
									job.updateDB();
								}
								updateJob(msg);
							}
						}}
					/>
					<DataInput
						isRequired
						name="vendorEmail"
						label="Vendor Email"
						type="text"
						defaultValue={job?.vendorEmail}
						onBlur={(e) => {
							if (e.target.value !== job?.vendorEmail) {
								let msg = { id: "Vendor Email", title: "Vendor Email", description: e.target.value };
								job.vendorEmail = e.target.value;
								log("UPDATE VENDOR EMAIL", job);
								if (Boolean(job?.jobID)) {
									job.updateDB();
								}
								updateJob(msg);
							}
						}}
					/>
				</>
			)}
		</Stack>
	);
}

export function DFWorkflowEdit({ job }) {
	const [updateTrigger, setUpdateTrigger] = useState(false);

	// get Current Template workflow options from database based off scope
	const workflows = useMemo(() => job?.workflows, [job?.workflows]);

	const toggleActiveWorkflow = (workflow) => {
		if (workflow.isVisible === 1 || workflow.isVisible === "1") {
			workflow.isVisible = "0";
		} else {
			workflow.isVisible = "1";
		}
		setUpdateTrigger(true);
		workflow.updateDB();
	};

	useEffect(() => {
		if (updateTrigger) {
			setUpdateTrigger(false);
		}
	}, [updateTrigger]);

	return (
		<Flex minH="lg" maxH="5xl" direction="row" flex={1} w="full" h="full" rounded="5" pr={2} overflowY={"auto"}>
			{workflows?.length === 0 ? (
				<Flex direction="column">
					<Box bg="white" borderRadius="md" p="4">
						<Text fontWeight="bold" fontSize="3xl" color="teal.500">
							No columns yet.
						</Text>
						<Text color="gray.600" fontSize="xl">
							Return to this tab after creating a job to edit the columns.
						</Text>
					</Box>
				</Flex>
			) : (
				<Flex
					direction="column"
					flex={1}
					w="full"
					h="full"
					borderWidth={1}
					borderColor="gray.500"
					borderTopRadius="md"
				>
					<Flex
						flex={1}
						textTransform={"uppercase"}
						color="white"
						bg="teal.700"
						px={4}
						py={2}
						borderTopRadius="md"
					>
						<Stack spacing={0} flex={1}>
							<Heading size="sm">Column</Heading>
							<Text fontSize="sm">Number</Text>
						</Stack>

						<HStack spacing={4} flex={4} align="center">
							<Stack spacing={0}>
								<Heading size="sm">Deliverable Name</Heading>
								<Text fontSize="sm">Column & Egnyte Folder</Text>
							</Stack>
						</HStack>

						<Flex flex={2}>
							<Stack spacing={0}>
								<Heading size="sm">Team Member</Heading>
								<Text fontSize="sm">Primarily Responsible</Text>
							</Stack>
						</Flex>
						<Flex flex={2}>
							<Stack spacing={0}>
								<Heading size="sm"># of Work Days</Heading>
								<Text fontSize="sm">Before Install Start</Text>
							</Stack>
						</Flex>

						<Stack spacing={0} flex={1} align="center" justify="center">
							<Heading size="sm">Show / Hide</Heading>
							<Text fontSize="sm">Column</Text>
						</Stack>
					</Flex>
					{workflows?.map((workflow, i) => (
						<Flex
							key={i}
							flex={1}
							px={4}
							borderTopWidth={1}
							borderTopColor={"gray.300"}
							justify="center"
							w="full"
							py={1}
							bg="blackAlpha.100"
						>
							<HStack flex={1} align="center">
								<Text fontWeight="semibold" fontSize="md" color="gray.500" textAlign="center">
									{workflow?.deliverableNum}
								</Text>
							</HStack>
							<HStack flex={4} align="center">
								<DataInput
									isRequired
									name="deliverableName"
									placeholder="Deliverable Name"
									size="sm"
									isDisabled={workflow?.templateID === workflow?.workflowID}
									borderRadius="md"
									defaultValue={workflow?.Header}
									onBlur={(e) => {
										if (e.target.value !== workflow?.Header) {
											workflow.Header = e.target.value;
											log("UPDATE WORKFLOW NAME", workflow);
											if (Boolean(workflow?.id)) {
												workflow.updateDB();
												setUpdateTrigger(true);
											}
										}
									}}
								/>
							</HStack>

							<Flex flex={2} align="center" px={2}>
								<DataInput
									isRequired
									name="respRole"
									type="select"
									placeholder="Select Team"
									size="sm"
									borderRadius="md"
									isDisabled={workflow?.templateID === workflow?.workflowID}
									options={job?.respRoles}
									optionText="respRole"
									optionSubheading="scopeDesc"
									searchable
									onOptionSelect={(e) => {
										if (e.respRoleID !== workflow?.respRoleID) {
											log("UPDATE WORKFLOW RESP/NOTIFY ROLE", workflow);
											workflow.respRoleID = e.respRoleID;
											workflow.respRole = e.respRole;
											workflow.notifyRole = e.respRoleID;
											if (Boolean(workflow?.id)) {
												workflow.updateDB();
												setUpdateTrigger(true);
											}
										}
									}}
									value={workflow?.respRole}
									isModal
								/>
							</Flex>
							<Flex flex={2} align="center" px={2}>
								<HStack flex={1} align="center">
									<DataInput
										size="sm"
										isRequired
										name="duration"
										type="number"
										isDisabled={workflow?.templateID === workflow?.workflowID}
										defaultValue={workflow?.duration}
										onBlur={(e) => {
											if (e.target.value !== workflow?.duration) {
												workflow.duration = e.target.value;
												log("UPDATE WORKFLOW DURATION", workflow);
												if (Boolean(workflow?.id)) {
													workflow.updateDB();
													setUpdateTrigger(true);
												}
											}
										}}
									/>
									<Text as="i" color="gray.400" w="full" isTruncated textAlign="left">
										{(workflow?.duration === 0
											? "on"
											: workflow?.duration > 0
											? "before"
											: "after") + " install"}
									</Text>
								</HStack>
							</Flex>
							<HStack spacing={4} flex={1} align="center">
								<Text fontWeight="semibold" fontSize="md" color="gray.500" w="full" textAlign="center">
									{/* <i className={"fas fa-cog fa-fw fa-lg"} /> */}
									<Switch
										id="isVisible"
										sizer="lg"
										isDisabled={workflow?.templateID === workflow?.workflowID}
										isChecked={
											workflow?.isVisible === 1 || workflow?.isVisible === "1" ? true : false
										}
										onChange={() => toggleActiveWorkflow(workflow)}
									/>
								</Text>
							</HStack>
						</Flex>
					))}
				</Flex>
			)}
		</Flex>
	);
}

export function DFTeamSelect({ job }) {
	const [updateTrigger, setUpdateTrigger] = useState(false);

	const addTeamMember = async (newTeamMember) => {
		job.team = [...job?.team, newTeamMember];

		if (job?.id) {
			let result = await newTeamMember.insertDB();
			log("RESULT TEAM", result);
		}
		setUpdateTrigger(true);
	};

	const deleteTeamMember = (teamMember) => {
		// Filter out the team member to be deleted from the job.team array
		const updatedTeam = job?.team.filter(
			(member) => !(member.respRoleID === teamMember.respRoleID && member.employeeUID === teamMember.employeeUID)
		);
		// Update the job object with the modified team
		if (Boolean(teamMember?.id)) {
			teamMember.deleteDB();
		}
		job.team = updatedTeam;
		setUpdateTrigger(true);
	};

	const toggleSendEmail = (teamMember) => {
		if (teamMember.sendEmail === 0 || teamMember.sendEmail === "0") {
			teamMember.sendEmail = "1";
		} else {
			teamMember.sendEmail = "0";
		}
		// Find the index of the team member in the job.team array
		const index = job?.team.findIndex(
			(member) => member.respRoleID === teamMember.respRoleID && member.employeeUID === teamMember.employeeUID
		);
		if (index !== -1) {
			// Toggle the sendEmail status for the specified team member
			let updatedTeam = [...job?.team];
			updatedTeam[index] = new DFTeam(teamMember);
			// Update the job object with the modified team
			if (Boolean(job?.id)) {
				teamMember.updateDB();
			}
			job.team = updatedTeam;
		}
		setUpdateTrigger(true);
	};

	useEffect(() => {
		if (updateTrigger) {
			setUpdateTrigger(false);
		}
	}, [updateTrigger]);

	return (
		<Stack flex={1}>
			<Heading size="md" fontWeight="bold" textTransform="uppercase" color="gray.400" letterSpacing={3}>
				Team Members
			</Heading>
			<Stack px={1} spacing={1}>
				{job?.respRoles?.map((respRole, i) => (
					<VStack key={i} alignItems="flex-start">
						<HStack>
							<Text fontWeight="bold">{respRole?.respRole + ":"}</Text>
							{job?.team?.some((member) => member.respRoleID === respRole.respRoleID) ? (
								job?.team
									.filter((member) => member.respRoleID === respRole.respRoleID)
									.map((member, index) => (
										<HStack key={index}>
											<Checkbox
												isChecked={member.sendEmail === 1}
												onChange={() => toggleSendEmail(member)}
											/>
											<Text>{member.name}</Text>
											<IconButton
												size="sm"
												variant="ghost"
												colorScheme="red"
												icon={<i className="fas fa-trash" />}
												onClick={() => deleteTeamMember(member)}
											/>
										</HStack>
									))
							) : (
								<Text as="i" color="gray.500">
									N/A
								</Text>
							)}
						</HStack>
						<DataInput
							name="teamMember"
							label={respRole?.respRole}
							type="select"
							placeholder={`Select ${respRole?.respRole}`}
							searchable
							options={portalUser
								?.getUsers()
								?.filter(
									(user) =>
										user.userStatus === "Active" &&
										(user.isStartUpUserYN === "Y" || SUP_TEAM_MEMBERS.includes(user?.title))
								)}
							optionText="name"
							optionSubheading="title"
							onOptionSelect={(e) => {
								// Create a new object based on selected data and set queryData to 'DFTeam'
								const newData = {
									...e,
									queryData: "DFTeam",
									jobID: job?.id ?? null,
									respRole: respRole.respRole,
									respRoleID: respRole.respRoleID,
								};
								addTeamMember(new DFTeam(newData));
							}}
							isModal
						/>
					</VStack>
				))}
			</Stack>
		</Stack>
	);
}

export function DFReviewCreate({
	job,
	existingDFJobNames,
	submitButtonDisabled,
	setSubmitButtonDisabled,
	onClose,
	setUpdateTrigger,
}) {
	const toast = useToast();
	const [existingJobNumberError, setExistingJobNumberError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!Boolean(job?.jobID) && existingDFJobNames.includes(job?.job)) {
			setExistingJobNumberError(true);
		} else {
			setExistingJobNumberError(false);
		}
	}, [existingDFJobNames, job?.job, job?.jobID]);

	const insertJob = async (job) => {
		setSubmitButtonDisabled(true);
		setIsLoading(true);
		if (!Boolean(job?.id)) {
			let newJob = await job.insertDB();
			if (newJob.status === 200) {
				job?.team.forEach(async (teamMember) => {
					teamMember.jobID = job.id;
					await teamMember.insertDB();
				});
				onClose();
				toast({
					title: "Success!",
					description: "Job created successfully!",
					status: "success",
					duration: 5000,
					isClosable: true,
				});
			} else {
				toast({
					title: "Error",
					description: "Something went wrong while creating a new job.",
					status: "error",
					duration: 5000,
					isClosable: true,
				});
			}
		} else {
			let updatedJob = await job?.updateDB();
			if (Object.keys(updatedJob).length > 0) {
				setUpdateTrigger(true);
				onClose();
				toast({
					title: "Success!",
					description: "Job edited successfully!",
					status: "success",
					duration: 5000,
					isClosable: true,
				});
			} else {
				toast({
					title: "Error",
					description: "Something went wrong while updating an existing job.",
					status: "error",
					duration: 5000,
					isClosable: true,
				});
			}
		}
		setSubmitButtonDisabled(false);
		setIsLoading(false);
	};

	return (
		<Flex flex={1} direction="column" justify="space-between">
			<Flex flex={1} direction="row">
				<Stack flex={1}>
					<Heading size="md" fontWeight="bold" textTransform="uppercase" color="gray.400" letterSpacing={3}>
						Job Information
					</Heading>
					<Stack px={1} spacing={1}>
						<HStack>
							<Text fontWeight="bold">Viewpoint Job:</Text>
							{job?.contract ? (
								<Text>{job?.contract + " " + job?.contractName}</Text>
							) : (
								<Text as={"i"} color="gray.500">
									N/A
								</Text>
							)}
						</HStack>

						<HStack>
							<Text fontWeight="bold">{(job?.scopeID === "13" ? "SUP" : "D&F") + " Job Number:"}</Text>
							{job?.job ? (
								<Text>{job?.job}</Text>
							) : (
								<Text as={"i"} color="gray.500">
									N/A
								</Text>
							)}
						</HStack>

						<HStack>
							<Text fontWeight="bold">{(job?.scopeID === "13" ? "SUP" : "D&F") + " Job Name:"}</Text>
							{job?.jobName ? (
								<Text>{job?.jobName}</Text>
							) : (
								<Text as={"i"} color="gray.500">
									N/A
								</Text>
							)}
						</HStack>

						<HStack>
							<Text fontWeight="bold">Region:</Text>
							{job?.region ? (
								<Text>{job?.region}</Text>
							) : (
								<Text as={"i"} color="gray.500">
									N/A
								</Text>
							)}
						</HStack>

						<HStack>
							<Text fontWeight="bold">Scope of Work:</Text>
							{job?.scopeID ? (
								<Text>{job?.scopeDesc}</Text>
							) : (
								<Text as={"i"} color="gray.500">
									N/A
								</Text>
							)}
						</HStack>

						<HStack>
							<Text fontWeight="bold">
								{job?.templateID === "13" || job?.templateType === "SUP"
									? "Finish Date:"
									: "Install Start Offset:"}
							</Text>
							{job?.installOffset ? (
								<Text>
									{job?.installOffset === 1
										? job?.installOffset + " day"
										: job?.installOffset + " days"}
								</Text>
							) : (
								<Text as={"i"} color="gray.500">
									0 days
								</Text>
							)}
						</HStack>

						{job?.scopeID === "5" && (
							<>
								<HStack>
									<Text fontWeight="bold">Vendor Name:</Text>
									{job?.vendorName ? (
										<Text>{job?.vendorName}</Text>
									) : (
										<Text as={"i"} color="gray.500">
											N/A
										</Text>
									)}
								</HStack>
								<HStack>
									<Text fontWeight="bold">Vendor Email:</Text>
									{job?.vendorEmail ? (
										<Text>{job?.vendorEmail}</Text>
									) : (
										<Text as={"i"} color="gray.500">
											N/A
										</Text>
									)}
								</HStack>
							</>
						)}
					</Stack>
				</Stack>
				<Stack flex={1}>
					<Heading size="md" fontWeight="bold" textTransform="uppercase" color="gray.400" letterSpacing={3}>
						Team Members
					</Heading>
					<Stack px={1} spacing={1}>
						{job?.respRoles?.map((respRole, i) => (
							<HStack key={i}>
								<Text fontWeight="bold">{respRole?.respRole + ":"}</Text>
								{job?.team?.filter((member) => member.respRoleID === respRole.respRoleID).length > 0 ? (
									<Text>
										{arrayToString(
											job?.team?.filter((member) => member.respRoleID === respRole.respRoleID),
											", ",
											false,
											"name"
										)}
									</Text>
								) : (
									<Text as={"i"} color="gray.500">
										N/A
									</Text>
								)}
							</HStack>
						))}
					</Stack>
				</Stack>
			</Flex>
			<Flex direction="row" justify="center">
				{/* <Text>{errorMessage}</Text> */}
				<VStack>
					<Button
						isDisabled={
							!Boolean(job?.jccmKeyID) ||
							!Boolean(job?.scopeID) ||
							Boolean(existingJobNumberError) ||
							Boolean(submitButtonDisabled)
						}
						colorScheme="blue"
						onClick={() => insertJob(job)}
						isLoading={isLoading}
					>
						{Boolean(job?.jobID) ? "UPDATE JOB" : "CREATE JOB"}
					</Button>
					{Boolean(existingJobNumberError) && (
						<Text color="red" fontWeight="bold">
							Job number already exists, please choose another name.
						</Text>
					)}
				</VStack>
			</Flex>
		</Flex>
	);
}
