import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
	Box,
	Flex,
	Text,
	Heading,
	Center,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Collapse,
	Avatar,
	AvatarBadge,
	Input,
	InputGroup,
	InputLeftElement,
	Stack,
	HStack,
	Wrap,
	Tag,
	TagLabel,
	FormControl,
	FormLabel,
	Spacer,
	Icon,
	Link,
	VStack,
	Button,
} from "@chakra-ui/react";
import VirtualListV3 from "../../../core/ReactTable/components/VirtualListV3";

import { GoMail } from "react-icons/go";
import { HiPhone } from "react-icons/hi";
import { motion } from "framer-motion";
import moment from "moment";

import { portalUser } from "../../../App";
import { convertArrayToMap, formatValue } from "../../../helperFunctions";

export default function TeamList(props) {
	const columns = useMemo(() => {
		let cols = [
			{ Header: "Name", id: "name", accessor: (row) => row?.name },
			{ Header: "Department", id: "dept", accessor: (row) => row?.dept },
			{ Header: "Title", id: "title", accessor: (row) => row?.title },
			{ Header: "User Status", id: "userStatus", accessor: (row) => row?.userStatus },
		];
		return cols;
	}, []);

	const data = useMemo(() => props.data ?? [], [props.data]);

	const RenderRow = useCallback(
		({ row, index }) => {
			let deptMap = convertArrayToMap(portalUser.getDepartments(), "deptID");
			let regionMap = convertArrayToMap(portalUser.getRegions(), "regionID");
			let employeeMap = convertArrayToMap(portalUser.getPREmployees(), "employeeUID");
			let team = data?.find(({ name }) => name === row.groupByVal);
			return (
				<>
					{Boolean(row.isGrouped) ? (
						<Flex key={index} w="full" p={2} onClick={row.toggleExpansion} align="center">
							<Box flex={3}>
								<Heading size="sm" color="gray.600">
									{row.groupByVal}
								</Heading>
							</Box>
							<Box flex={1}>{team?.role}</Box>
							<Box flex={1}>{team?.members?.length} Members</Box>
						</Flex>
					) : (
						<Box key={index} p={2}>
							<TeamListRow
								key={row.original.employeeUID}
								employee={row.original}
								onClick={() => {
									props.viewEmployee(row.original);
								}}
								// onClick={() => {
								// 	props.viewTeam(row.original.employeeUID);
								// }}
								deptMap={deptMap}
								regionMap={regionMap}
								employeeMap={employeeMap}
							/>
						</Box>
					)}
				</>
			);
		},
		[props.data, props.viewEmployee]
	);

	return (
		<Box borderRadius="lg">
			{props.data?.length === 0 && (
				<Center w="full" p={3}>
					<Heading size="sm" color="gray.500">
						NO TEAMS AVAILABLE
					</Heading>
				</Center>
			)}
			<Accordion allowToggle>
				{props.data?.map((team, i) => (
					<AccordionItem key={i}>
						{({ isExpanded }) => (
							<>
								<AccordionButton>
									<Flex
										px={4}
										flex="1"
										textAlign="left"
										align="center"
										fontWeight="bold"
										textTransform={"uppercase"}
									>
										<Box flex={3}>
											<Text py="2" fontSize="md" color="gray.600">
												{team.name}
											</Text>
										</Box>
										<Box flex={1}>
											<Text py="2" fontSize="md" color="gray.600">
												{team?.role}
											</Text>
										</Box>
										<Box flex={1}>
											<Text py="2" fontSize="md" color="gray.600">
												{
													(
														team?.members?.filter((d) =>
															["Active", "Consultant"].includes(d.userStatus)
														) ?? []
													)?.length
												}{" "}
												Employees
											</Text>
										</Box>
									</Flex>
									<AccordionIcon />
								</AccordionButton>

								{isExpanded && (
									<AccordionPanel>
										<VirtualListV3
											listName={team?.name.replace(/\s/gm, "") + i}
											data={team?.members}
											columns={columns}
											renderRow={RenderRow}
											listHeight={800}
											listOnly
											defaultSortAttr={[{ id: "name", desc: false }]}
											// defaultFilters={[{ id: "userStatus", value: "Active" }]}
										/>
									</AccordionPanel>
								)}
							</>
						)}
					</AccordionItem>
				))}
			</Accordion>
		</Box>
	);
}

function TeamListRow(props) {
	const [user, setUser] = useState(null);

	const initUser = useCallback(async (id, attr) => {
		let user = await portalUser.getUser(id, attr);
		setUser(user);
	}, []);

	useMemo(() => {
		if (props.employee?.msUserID) {
			initUser(props.employee?.msUserID, "msUserID");
		} else if (props.employee?.employeeUID) {
			initUser(props.employee?.employeeUID, "employeeUID");
		}
	}, [props.employee, initUser]);

	const handleOnClick = (ev) => {
		ev.stopPropagation();
		if (Boolean(props.onClick)) {
			props.onClick(props.employee?.employeeUID);
		}
	};

	const region = useMemo(() => props.regionMap[user?.regionID] ?? null, [props.regionMap, user?.regionID]);
	const dept = useMemo(() => props.deptMap[user?.deptID] ?? null, [props.deptMap, user?.deptID]);

	const parentDept = useMemo(() => props.deptMap[dept?.parentDeptID] ?? null, [props.deptMap, dept?.parentDeptID]);
	const employee = useMemo(
		() => props.employeeMap[user?.employeeUID] ?? null,
		[props.employeeMap, user?.employeeUID]
	);

	useEffect(() => {}, [props.employee]);

	return (
		<motion.div whileHover={{ scale: 1.01, transition: { duration: 0.5 } }}>
			<Flex
				key={user?.employeeUID}
				w="full"
				flex="1"
				justify="space-between"
				direction={{ base: "column", lg: "row" }}
				onClick={handleOnClick}
				cursor="pointer"
				bg="whiteAlpha.900"
				p={portalUser.styles?.cardPadding}
				_hover={{ borderWidth: 2, bg: "whiteAlpha.900", borderColor: "teal.500" }}
				rounded="md"
				borderWidth={1}
				shadow={portalUser.styles?.cardShadow}
			>
				<HStack spacing="4" flex="1" mr="2">
					<Avatar alt={user?.name} size="xl" src={user?.profilePhoto ?? null}>
						<AvatarBadge
							boxSize="1.25em"
							border=".1em solid"
							bg={dept?.color ?? "gray.400"}
							color="white"
							align="center"
						>
							<Text
								className={dept?.icon ?? "fas fa-user fa-fw fa-lg"}
								fontSize="lg"
								h="100%"
								textAlign="center"
								align="center"
							/>
						</AvatarBadge>
					</Avatar>
					<Stack spacing={1} maxW="full">
						<Heading maxW="full" isTruncated size="lg" color={"gray.600"}>
							{user?.name}
						</Heading>
						<HStack fontSize="md" align="center" spacing="3">
							<Heading isTruncated color={"gray.500"} fontWeight="semibold" size="sm">
								{user?.title}
							</Heading>
							<Wrap shouldWrapChildren>
								<Tag size="md" bg={dept?.color ?? "gray.400"} variant="solid">
									<TagLabel>{dept?.dept}</TagLabel>
								</Tag>
								{Boolean(parentDept?.deptID !== dept?.deptID) && (
									<Tag size="md" bg={parentDept?.color ?? "gray.400"} variant="solid">
										<TagLabel>{parentDept?.dept}</TagLabel>
									</Tag>
								)}
							</Wrap>
						</HStack>
						<Stack spacing={{ base: "2", md: "8" }} direction={{ base: "column", md: "row" }}>
							<HStack fontSize="md" align="center" spacing="3">
								<Icon as={HiPhone} color={"gray.600"} boxSize="25px" />
								<Stack spacing="1">
									<Heading size="xs" color={"gray.600"} fontWeight="normal" isTruncated>
										<a href={"tel:" + region?.phone}>
											O: {region?.phone + (user?.ext ? " x" + user?.ext : "")}
										</a>
									</Heading>
									{user?.cellPhone && (
										<Heading size="xs" color={"gray.600"} fontWeight="normal" isTruncated>
											<a href={"tel:" + user?.cellPhone}>C: {user?.cellPhone}</a>
										</Heading>
									)}
								</Stack>
							</HStack>
							<HStack fontSize="sm" align="center" spacing="3">
								<Icon as={GoMail} color={"gray.600"} boxSize="25px" />
								<Link color={"gray.600"} textDecoration="underline" href={"mailto:" + user?.email}>
									{user?.email}
								</Link>
							</HStack>
						</Stack>
					</Stack>
				</HStack>

				<Flex mt={{ base: "6", lg: "2" }} mb={{ base: "4", lg: "2" }} flex="1" justify="space-around">
					<Flex align="center">
						<Stack spacing="4">
							<HStack spacing="4" align="center">
								<Heading color="teal.500" size="lg">
									<i className="fas fa-birthday-cake fa-lg"></i>
								</Heading>
								{user?.shareBirthdayYN === "Y" ? (
									<Heading color={"gray.600"} isTruncated size="md">
										{moment(new Date(user?.birthDay)).format("MMM Do")}
									</Heading>
								) : (
									<Button
										border="2px"
										borderColor="blackAlpha.200"
										bg="gray.100"
										color="gray.500"
										letterSpacing={1}
									>
										PRIVATE
									</Button>
								)}
							</HStack>
							<HStack spacing="4">
								<Heading color="teal.500" size="lg">
									<i className="far fa-calendar-alt fa-lg "></i>
								</Heading>
								<Heading color={"gray.600"} isTruncated size="md">
									{formatValue(user?.longevityYears ?? 0, 1, "number") + " Years"}
								</Heading>
							</HStack>
						</Stack>
					</Flex>
					<Spacer mx="10" display={{ base: "none", lg: "flex" }} />
					<Flex align="center">
						<Stack spacing="4">
							<HStack spacing="4">
								<Heading color="teal.500" size="lg">
									<i className="fas fa-plane fa-rotate-270 fa-lg"></i>
								</Heading>
								<Heading color={"gray.600"} isTruncated size="md">
									{formatValue(employee?.availableVacation ?? 0, 1, "number") + " Hours"}
								</Heading>
							</HStack>
							<HStack spacing="4">
								<Heading color="teal.500" size="lg">
									<i className="fas fa-stethoscope fa-lg"></i>
								</Heading>
								<Heading color={"gray.600"} isTruncated size="md">
									{formatValue(employee?.availableSick ?? 0, 1, "number") + " Hours"}
								</Heading>
							</HStack>
						</Stack>
					</Flex>
					<Spacer mx="10" display={{ base: "none", lg: "flex" }} />
				</Flex>
			</Flex>

			<Collapse in={props.selected} animateOpacity>
				<Box p="2">
					<VStack width="full" spacing="6" px="8" pt="6" pb="10">
						<Stack w="full" spacing="6" flex="1" direction={{ base: "column", md: "row" }}>
							<Flex w="full" flex="2" direction={{ base: "column", md: "row" }}>
								<FormControl id="firstName" mr="10">
									<FormLabel m="0" color={"gray.600"} textTransform="uppercase">
										First Name
									</FormLabel>
									<Input
										borderColor={"blackAlpha.400"}
										borderBottomWidth={"2px"}
										size="lg"
										focusBorderColor="teal.600"
										fontWeight={"normal"}
										variant="flushed"
										type="text"
										color={"gray.600"}
										readOnly
										value={user?.firstName}
										maxLength={255}
									/>
								</FormControl>
								<FormControl id="lastName" mt={{ base: "6", md: "0" }} mr="10">
									<FormLabel m="0" color={"gray.600"} textTransform="uppercase">
										Last Name
									</FormLabel>
									<Input
										borderColor={"blackAlpha.400"}
										borderBottomWidth={"2px"}
										focusBorderColor="teal.600"
										size="lg"
										fontWeight={"normal"}
										variant="flushed"
										type="text"
										color={"gray.600"}
										readOnly
										value={user?.lastName}
										maxLength={255}
									/>
								</FormControl>
							</Flex>

							<Flex
								w="full"
								flex="1"
								direction={{ base: "column", md: "row" }}
								mt={{ base: "6", md: "0" }}
							>
								<FormControl id="title" mr="10">
									<FormLabel m="0" color={"gray.600"} textTransform="uppercase">
										Title
									</FormLabel>
									<InputGroup size="lg">
										<InputLeftElement
											pointerEvents="none"
											color={"gray.500"}
											children={<i className="far fa-address-card fa-lg"></i>}
										/>
										<Input
											borderColor={"blackAlpha.400"}
											borderBottomWidth={"2px"}
											focusBorderColor="teal.600"
											fontWeight={"normal"}
											variant="flushed"
											type="title"
											color={"gray.600"}
											readOnly
											value={user?.title}
										/>
									</InputGroup>
								</FormControl>
							</Flex>
						</Stack>

						<Stack w="full" spacing="6" flex="1" direction={{ base: "column", md: "row" }}>
							<Flex w="full" flex="2" direction={{ base: "column", md: "row" }}>
								<FormControl id="email" mr="10">
									<FormLabel m="0" color={"gray.600"} textTransform="uppercase">
										Email
									</FormLabel>
									<InputGroup size="lg">
										<InputLeftElement
											pointerEvents="none"
											color={"gray.500"}
											children={<i className="far fa-envelope fa-lg"></i>}
										/>
										<Input
											borderColor={"blackAlpha.400"}
											borderBottomWidth={"2px"}
											focusBorderColor="teal.600"
											fontWeight={"normal"}
											variant="flushed"
											color={"gray.600"}
											type="email"
											readOnly
											value={user?.email}
										/>
									</InputGroup>
								</FormControl>
							</Flex>

							<Flex w="full" flex="1" direction={{ base: "column", md: "row" }}>
								<FormControl id="cellPhone" mr="10">
									<FormLabel m="0" color={"gray.600"} textTransform="uppercase">
										Work Cell
									</FormLabel>
									<InputGroup size="lg">
										<InputLeftElement
											pointerEvents="none"
											color={"gray.500"}
											children={<i className="fas fa-phone-alt fa-lg"></i>}
										/>
										<Input
											borderColor={"blackAlpha.400"}
											borderBottomWidth={"2px"}
											focusBorderColor="teal.600"
											fontWeight={"normal"}
											variant="flushed"
											type="text"
											color={"gray.600"}
											placeholder="(###) ###-####"
											readOnly
											value={user?.cellPhone}
											maxLength={255}
										/>
									</InputGroup>
								</FormControl>
							</Flex>
						</Stack>
					</VStack>
				</Box>
			</Collapse>
		</motion.div>
	);
}
