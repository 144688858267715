import React, { useState, useEffect } from "react";
import {
	Heading,
	Button,
	Drawer,
	DrawerContent,
	DrawerCloseButton,
	DrawerHeader,
	DrawerBody,
	DrawerFooter,
	HStack,
} from "@chakra-ui/react";
// import { io } from "socket.io-client";
import { portalUser } from "../../../App";
import SectionTemplateHRNotifications from "../../../modules/Employee/templates/SectionTemplateHRNotifications";
import SectionObj from "../../Reports/classes/Section";
import NotificationRow from "./NotificationRow";
import VirtualData from "./NotificationsVirtualData";
// import { log } from "../../../helperFunctions";

export default function NotificationDrawer({ isOpen, onClose }) {
	const [subSection, setSubSection] = useState(null);
	const [showAll, setShowAll] = useState(false);
	const [dismissAll, setDismissAll] = useState(false);

	useEffect(() => {
		if (!Boolean(dismissAll)) {
			let section = new SectionObj(new SectionTemplateHRNotifications());
			let subSection = section?.subSections[0] ?? null;

			if (Boolean(subSection)) {
				let groupBy = [];
				let filterBy = [];
				let sortBy = [];
				if (Boolean(showAll)) {
					groupBy = ["status", "mthCreated"];
					sortBy = [
						{ id: "dateCreated", desc: true },
						{ id: "status", desc: false },
					];
				} else {
					groupBy = ["createdByName"];
					filterBy = [{ id: "status", value: "New Notifications" }];
					sortBy = [{ id: "dateCreated", desc: true }];
				}
				subSection.sortBy = sortBy;
				subSection.groupBy = groupBy;
				subSection.filterBy = filterBy;
			}
			setSubSection(subSection);
		} else {
			setDismissAll(false);
		}
	}, [showAll, dismissAll]);

	const dismissAllNotifications = async () => {
		if (Boolean(portalUser?.user)) {
			let dismissAll = await portalUser.user.dismissAllNotifications();
			setDismissAll(dismissAll);
		}
	};

	return (
		<Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
			<DrawerContent bg="whiteAlpha.900">
				<DrawerHeader bg="white" justify="center" borderBottomWidth={2}>
					<Heading color="gray.500" textTransform={"uppercase"} size="lg" letterSpacing={2}>
						{subSection?.heading}
					</Heading>
				</DrawerHeader>
				<DrawerCloseButton />

				<DrawerBody w="full" h="full" p={0}>
					{Boolean(subSection) && (
						<VirtualData subSection={subSection} renderRow={NotificationRow} onClose={onClose} />
					)}
				</DrawerBody>

				<DrawerFooter>
					<HStack w="full" justify="flex-end">
						<Button
							colorScheme="teal"
							style={{ marginRight: 10 }}
							onClick={() => setShowAll(!showAll)}
							textTransform="uppercase"
						>
							{showAll ? "Hide" : "View"} History
						</Button>
						<Button
							// disabled={data?.data?.length === 0}
							colorScheme="teal"
							onClick={dismissAllNotifications}
							textTransform="uppercase"
						>
							Dismiss All
						</Button>
					</HStack>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}
