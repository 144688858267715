import React, { useRef, useMemo, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";

import {
	Switch,
	Menu,
	MenuItem,
	MenuGroup,
	MenuButton,
	MenuList,
	Flex,
	Text,
	Heading,
	SlideFade,
	HStack,
	IconButton,
	useOutsideClick,
	Button,
	Stack,
	Tooltip,
} from "@chakra-ui/react";
import GlobalSearchFilter from "../../ReactTable/inputs/GlobalSearchFilter";
import { portalUser } from "../../../App";

export default function VirtualTableSettings({
	label,
	heading,
	subheading,
	tooltip,
	searchIsOpen,
	filterIsOpen,
	setFilterIsOpen,
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter,
	rowCount,
	allColumns,
	exportData,
	toggleAllRowsExpanded,
	autoExpandRows,

	maxWidth,
	setHiddenColumns,
	newPCO,
	dnfJob = {},
	selectedKeys = [],
}) {
	const ref = useRef();
	const history = useHistory();
	const [isOpen, setSearchIsOpen] = useState(searchIsOpen);
	const searchCount = useMemo(() => rowCount ?? 0, [rowCount]);
	const [syncData, setSyncData] = useState(false);
	// log("ALL COLUMNS FOR EXPORT", allColumns);
	useOutsideClick({
		ref: ref,
		handler: () => setSearchIsOpen(false),
	});

	// Asychronous Call to get the Selected Data based off the Selected Keys
	const syncManpowerProjections = useCallback(async () => {
		if (Boolean(selectedKeys.length > 0)) {
			let contractData = await portalUser.syncContractDataManpowerProjections(selectedKeys);
			if (Boolean(contractData)) {
				setSyncData(false);
			}
		} else {
			setSyncData(false);
		}
	}, [selectedKeys]);

	const syncButton = async () => {
		setSyncData(true);
		syncManpowerProjections();
	};

	// Asychronous Call to get the Selected Data based off the Selected Keys
	const addPCO = useCallback(async () => {
		if (Boolean(selectedKeys.length > 0)) {
			newPCO(selectedKeys[0]);
		}
	}, [newPCO, selectedKeys]);

	let fontSize = Boolean(["Contract Status CRS", "Project Analysis CRS"].includes(heading)) ? "xl" : "2xl";
	let minW = Boolean(["Contract Status CRS", "Project Analysis CRS"].includes(heading)) ? "25px" : "";

	return (
		<Stack w="full">
			<Flex justify="space-between" align="flex-end" maxW={maxWidth}>
				<HStack align="center" flex={1} justify="flex-end" lineHeight={10} maxW={maxWidth}>
					{!isOpen ? (
						<Flex flex={1} w="full" justify="flex-start" isTruncated>
							<Stack w="full" spacing={0} align="flex-start">
								<HStack w="full" align="flex-end" py={2}>
									{Boolean(label) && (
										<Heading
											fontSize={fontSize}
											minW={minW}
											letterSpacing={2}
											color="gray.500"
											textTransform="uppercase"
										>
											{label}
										</Heading>
									)}
									<Heading
										fontSize={fontSize}
										minW={minW}
										letterSpacing={2}
										color="gray.500"
										textTransform="uppercase"
									>
										{heading}
									</Heading>
								</HStack>
								{Boolean(subheading) && (
									<Text color="gray.400" letterSpacing={1} my={0} lineHeight={1}>
										{subheading}
									</Text>
								)}
							</Stack>
						</Flex>
					) : (
						<SlideFade
							maxH={10}
							className="chakra-offset-slide-forceFlex"
							ref={ref}
							in={isOpen}
							offsetX="50px"
							offsetY="0px"
							display="flex"
							direction="row"
							flex={1}
							w={isOpen ? "full" : 0}
						>
							<Flex flex={1} w={isOpen ? "full" : 0} lineHeight={10}>
								<GlobalSearchFilter
									preGlobalFilteredRows={preGlobalFilteredRows}
									globalFilter={globalFilter}
									setGlobalFilter={setGlobalFilter}
									rowCount={searchCount}
								/>
							</Flex>
						</SlideFade>
					)}
					{!isOpen && (
						<>
							<>
								{Boolean(heading === "Pending Change Orders") && (
									<Tooltip label={tooltip} aria-label="Add PCO Tooltip">
										<Button bg="green.500" color="white" onClick={addPCO} pl={1} pr={2}>
											<HStack spacing={1} px={0}>
												<i className={"fas fa-plus fa-fw"} />
												<Text textTransform="uppercase" color="white" letterSpacing={1}>
													PCO
												</Text>
											</HStack>
										</Button>
									</Tooltip>
								)}
							</>

							<>
								{(Boolean(heading === "Manpower Projections") ||
									Boolean(heading === "Billing & Cost Projections")) && (
									<Tooltip label={tooltip} aria-label="Sync Button Tooltip">
										<Button bg="blue.500" color="white" onClick={syncButton} px={2}>
											<HStack key={!syncData ? "notSync" : "sync"} spacing={1}>
												<i
													className={
														"fas " +
														(!syncData ? "fa-sync-alt" : "fa-sync-alt fa-spin") +
														" fa-lg fa-fw"
													}
												/>
												<Text textTransform="uppercase" color="white" letterSpacing={1}>
													Sync
												</Text>
											</HStack>
										</Button>
									</Tooltip>
								)}
							</>

							<>
								{(Boolean(heading === "Plumbing Jobs") ||
									Boolean(heading === "Mechanical Piping Jobs") ||
									Boolean(heading === "Decking Jobs") ||
									Boolean(heading === "Sheet Metal Jobs") ||
									Boolean(heading === "Fuel Oil Jobs") ||
									Boolean(heading === "Start Up Jobs") ||
									Boolean(heading === "Fire Protection Jobs")) && (
									<Tooltip label={tooltip} aria-label="Add DF Job Tooltip">
										<Button
											bg="green.500"
											color="white"
											onClick={() => history.push("/newdfjob")}
											pl={1}
											pr={1}
										>
											<HStack spacing={1} px={0}>
												<i className={"fas fa-plus fa-fw"} />
												<Text textTransform="uppercase" color="white" letterSpacing={1}>
													JOB
												</Text>
											</HStack>
										</Button>
									</Tooltip>
								)}
							</>
						</>
					)}

					<IconButton
						key={(isOpen ? "open" : "closed") + "-GlobalSearchIconButton"}
						onClick={() => setSearchIsOpen(!isOpen)}
						borderRadius="md"
						borderWidth={"2px"}
						fontWeight="semibold"
						bg={isOpen ? "teal.500" : "gray.100"}
						color={isOpen ? "white" : "gray.500"}
						borderColor={isOpen ? "green.600" : ""}
						_hover={{
							color: isOpen ? "white" : "teal.500",
							borderColor: "green.600",
							borderWidth: "2px",
						}}
						_focus={{ boxShadow: "outline", borderWidth: "2px" }}
						icon={<i className={"fas " + (isOpen ? "fa-times" : "fa-search") + " fa-lg fa-fw"} />}
					/>
					<IconButton
						onClick={() => setFilterIsOpen.toggle()}
						borderRadius="md"
						borderWidth={"2px"}
						fontWeight="semibold"
						bg={filterIsOpen ? "gray.500" : "gray.100"}
						borderColor={filterIsOpen ? "gray.500" : "gray.100"}
						color={filterIsOpen ? "white" : "gray.500"}
						_hover={{
							color: filterIsOpen ? "white" : "gray.600",
							borderColor: "gray.500",
							borderWidth: "2px",
						}}
						_focus={{ boxShadow: "outline", borderWidth: "2px" }}
						icon={<i className="fas fa-filter fa-lg  fa-fw" />}
					></IconButton>
					<Menu p={0}>
						<MenuButton
							size="xs"
							px={2}
							py={2}
							transition="all 0.2s"
							borderRadius="md"
							borderWidth="2px"
							fontWeight="semibold"
							bg="gray.100"
							color="gray.500"
							h={10}
							minW={10}
							_hover={{
								color: "teal.600",
								borderColor: "teal.600",
								borderWidth: "2px",
							}}
							_expanded={{ bg: "teal.600", color: "white", borderColor: "teal.600" }}
							_focus={{ boxShadow: "outline", borderWidth: "2px" }}
						>
							<Text>
								<i className="fas fa-download fa-lg " />
							</Text>
						</MenuButton>

						<MenuList color="gray.500" bg="gray.100">
							<MenuGroup
								title="EXPORT DATA"
								fontWeight="semibold"
								letterSpacing={1}
								color="gray.400"
								overflowY="auto"
								maxH="container.md"
							>
								<MenuItem
									onClick={() => {
										exportData("xlsx", false);
									}}
								>
									<HStack align="center" spacing={3} letterSpacing={1}>
										<Text fontSize="lg">
											<i className="fas fa-file-excel fa-lg" />
										</Text>
										<Text fontWeight="semibold">EXCEL (.xlsx)</Text>
									</HStack>
								</MenuItem>

								<MenuItem
									onClick={() => {
										exportData("csv", false);
									}}
								>
									<HStack align="center" spacing={3} letterSpacing={1}>
										<Text fontSize="lg">
											<i className="fas fa-file-csv fa-lg" />
										</Text>
										<Text fontWeight="semibold">CSV (.csv)</Text>
									</HStack>
								</MenuItem>
							</MenuGroup>
						</MenuList>
					</Menu>
					<Menu closeOnSelect={false} p={0}>
						<MenuButton
							size="xs"
							p={2}
							borderRadius="md"
							borderWidth="2px"
							fontWeight="semibold"
							bg="gray.100"
							color="gray.500"
							h={10}
							minW={10}
							_hover={{
								color: "blue.600",
								borderColor: "blue.600",
								borderWidth: "2px",
							}}
							_expanded={{
								bg: "blue.500",
								color: "white",
								borderColor: "blue.500",
							}}
							_focus={{ boxShadow: "outline", borderWidth: "2px" }}
						>
							<Text>
								<i className="fas fa-cog fa-lg " />
							</Text>
						</MenuButton>

						<MenuList
							color="gray.500"
							bg="gray.50"
							minW="md"
							maxH="md"
							overflow="auto"
							className="skinnyScroll"
							pr="2"
						>
							<MenuGroup
								title={
									<Flex w="full" flex={1}>
										<Flex align="center" letterSpacing={1} flex={2} isTruncated>
											<Text
												fontWeight="semibold"
												lineHeight={1.15}
												fontSize="sm"
												textTransform="uppercase"
												isTruncated
											>
												Columns
											</Text>
										</Flex>
										<Flex align="center" letterSpacing={1} flex={1} isTruncated justify="center">
											<Text
												fontWeight="semibold"
												lineHeight={1.15}
												fontSize="sm"
												textTransform="uppercase"
												isTruncated
											>
												Show/Hide
											</Text>
										</Flex>
										{/* <Flex align="center" letterSpacing={1} flex={1} isTruncated justify="center">
										<Text
											fontWeight="semibold"
											lineHeight={1.15}
											fontSize="sm"
											textTransform="uppercase"
											isTruncated
										>
											Group By
										</Text>
									</Flex> */}
									</Flex>
								}
								fontWeight="semibold"
								letterSpacing={1}
								color="gray.500"
								borderBottom="2px"
								borderColor="gray.400"
							>
								{allColumns?.map((column) => {
									return (
										column.id !== "expander" &&
										!column.disableExport && (
											<MenuItem key={column.id}>
												<Flex align="center" letterSpacing={1} flex={2} isTruncated>
													<Text
														lineHeight={1.15}
														fontSize="sm"
														isTruncated
														fontWeight="semibold"
														textTransform="uppercase"
													>
														{column.Header}
													</Text>
												</Flex>
												<Flex align="center" letterSpacing={1} flex={1} justify="center">
													<Switch
														id={column.id + "-Switch-" + column.isVisible}
														colorScheme="blue"
														isChecked={column.isVisible}
														{...column.getToggleHiddenProps()}
													/>
												</Flex>
												{/* <Flex align="center" letterSpacing={1} flex={1} justify="center">
												<Switch
													id={column.id + "-GroupBy"}
													colorScheme="blue"
													isChecked={column.isGrouped}
													{...column.getGroupByToggleProps()}
													key={column.accessor}
													onChange={() => {
														column.toggleGroupBy();
														if (!column.isVisible) {
															let hiddenCols =
																allColumns?.filter((d) => d.isVisible === false) ?? [];
															hiddenCols.push(column);
															setHiddenColumns(
																hiddenCols.map((column) => column.accessor)
															);
														}
														toggleAllRowsExpanded(true);
													}}
												/>
											</Flex> */}
											</MenuItem>
										)
									);
								})}
							</MenuGroup>
						</MenuList>
					</Menu>
				</HStack>
			</Flex>
		</Stack>
	);
}
