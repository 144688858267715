import { ajaxGET, ajaxPOST, ajaxPATCH, ajaxDELETE, testGET, testPOST, setQueryParams } from "./api-functions";
import { portalUser } from "../App";

const apiBaseURL =
	(process.env.NODE_ENV === "development"
		? "https://ppmportalappnodejs-api.azurewebsites.net"
		: window.location.origin) + "/api/schedules/";

export default class SchedulesAPI {
	async TestGET(headers = {}) {
		let response = await testGET(apiBaseURL, headers);
		return response;
	}

	async TestPOST(body = {}, headers = {}) {
		let response = await testPOST(apiBaseURL, body, headers);
		return response;
	}

	/************************** BATCH API CALLS  ****************************/

	//GET DFData
	async PortalUser_DFData_GetData(
		params = {
			jccmKeyID: "",
			scopeID: "",
			jobID: "",
			regionID: "",
			isArchived: "",
		}
	) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData`;
		apiRouteURL = setQueryParams(apiRouteURL, params);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	/************************** JOB API CALLS  ****************************/

	//GET Job(s) by jobID(s), jccmKeyID(s), scopeID, or regionID
	async PortalUser_DFData_GetJobs(
		params = {
			jccmKeyID: "",
			scopeID: "",
			jobID: "",
			regionID: "",
			isArchived: "",
		}
	) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/jobs`;
		apiRouteURL = setQueryParams(apiRouteURL, params);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//INSERT Job
	async PortalUser_DFData_InsertJob(data = {}) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/jobs`;
		if (Object.keys(data)?.length > 0) {
			let response = await ajaxPOST(apiRouteURL, data);
			return response;
		}
	}

	//UPDATE Job by jobID
	async PortalUser_DFData_UpdateJob(id, data = {}) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/jobs/${id}`;
		if (Boolean(id) && Object.keys(data)?.length > 0) {
			let response = await ajaxPATCH(apiRouteURL, data);
			return response;
		} else {
			return;
		}
	}

	//DELETE Job by jobID
	async PortalUser_DFData_DeleteJob(id = {}) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/jobs/${id}`;
		if (Boolean(id)) {
			let response = await ajaxDELETE(apiRouteURL);
			return response;
		} else {
			return;
		}
	}

	/************************** TEAM API CALLS  ****************************/

	//GET Team(s) by jobID(s), jccmKeyID(s), scopeID, or regionID
	async PortalUser_DFData_GetDFTeams(
		params = {
			jccmKeyID: "",
			scopeID: "",
			jobID: "",
			regionID: "",
			isArchived: "",
		}
	) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/teams`;
		apiRouteURL = setQueryParams(apiRouteURL, params);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//INSERT Team
	async PortalUser_DFData_InsertTeam(data = {}) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/teams`;
		if (Object.keys(data)?.length > 0) {
			let response = await ajaxPOST(apiRouteURL, data);
			return response;
		} else {
			return;
		}
	}

	//UPDATE Team by teamID
	async PortalUser_DFData_UpdateTeam(id = null, data = {}) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/teams/${id}`;
		if (Boolean(id) && Object.keys(data)?.length > 0) {
			let response = await ajaxPATCH(apiRouteURL, data);
			return response;
		} else {
			return;
		}
	}

	//DELETE Team by teamID
	async PortalUser_DFData_DeleteTeam(id = null) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/teams/${id}`;
		if (Boolean(id)) {
			let response = await ajaxDELETE(apiRouteURL);
			return response;
		} else {
			return;
		}
	}

	/************************** Workflow API CALLS  ****************************/

	//GET Workflow(s) by jobID(s), jccmKeyID(s), scopeID, or regionID
	async PortalUser_DFData_GetDFWorkflows(
		params = {
			jccmKeyID: "",
			scopeID: "",
			jobID: "",
			regionID: "",
			isArchived: "",
		}
	) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/workflows`;
		apiRouteURL = setQueryParams(apiRouteURL, params);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//INSERT Workflows by jobID
	async PortalUser_DFData_InsertJob_Workflows(jobID = null) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/jobs/${jobID}/workflows`;
		if (Boolean(jobID)) {
			let response = await ajaxPOST(apiRouteURL);
			return response;
		} else {
			return;
		}
	}

	//INSERT Workflow
	async PortalUser_DFData_InsertWorkflow(data = {}) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/workflows`;
		if (Object.keys(data)?.length > 0) {
			let response = await ajaxPOST(apiRouteURL, data);
			return response;
		} else {
			return;
		}
	}

	//UPDATE Workflow by keyID
	async PortalUser_DFData_UpdateWorkflow(id = null, data = {}) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/workflows/${id}`;
		if (Boolean(id) && Object.keys(data)?.length > 0) {
			let response = await ajaxPATCH(apiRouteURL, data);
			return response;
		} else {
			return;
		}
	}

	//DELETE Workflow by keyID
	async PortalUser_DFData_DeleteWorkflow(id = null) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/workflows/${id}`;
		if (Boolean(id)) {
			let response = await ajaxDELETE(apiRouteURL);
			return response;
		} else {
			return;
		}
	}

	/************************** SEQUENCE API CALLS  ****************************/

	//GET Sequences(s)
	async PortalUser_DFData_GetDFSequences(
		params = {
			jccmKeyID: "",
			scopeID: "",
			jobID: "",
			regionID: "",
			isArchived: "",
		}
	) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/sequences`;
		apiRouteURL = setQueryParams(apiRouteURL, params);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//INSERT Sequence
	async PortalUser_DFData_InsertSequence(data = {}) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/sequences`;
		if (Object.keys(data)?.length > 0) {
			let response = await ajaxPOST(apiRouteURL, data);
			return response;
		} else {
			return;
		}
	}

	//UPDATE Sequence
	async PortalUser_DFData_UpdateSequence(id = null, data = {}) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/sequences/${id}`;
		if (Boolean(id) && Object.keys(data)?.length > 0) {
			let response = await ajaxPATCH(apiRouteURL, data);
			return response;
		} else {
			return;
		}
	}

	//DELETE Sequence
	async PortalUser_DFData_DeleteSequence(id = null) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/sequences/${id}`;
		if (Boolean(id)) {
			let response = await ajaxDELETE(apiRouteURL);
			return response;
		} else {
			return;
		}
	}

	/************************** DELIVERABLE API CALLS  ****************************/

	//GET Deliverable(s)
	async PortalUser_DFData_GetDFDeliverables(
		params = {
			jccmKeyID: "",
			scopeID: "",
			jobID: "",
			regionID: "",
			isArchived: "",
		}
	) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/deliverables`;
		apiRouteURL = setQueryParams(apiRouteURL, params);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//INSERT Deliverable
	async PortalUser_DFData_InsertDeliverable(data = {}) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/deliverables`;
		if (Object.keys(data)?.length > 0) {
			let response = await ajaxPOST(apiRouteURL, data);
			return response;
		} else {
			return;
		}
	}

	//UPDATE Deliverable
	async PortalUser_DFData_UpdateDeliverable(id = null, data = {}) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/deliverables/${id}`;
		if (Boolean(id) && Object.keys(data)?.length > 0) {
			let response = await ajaxPATCH(apiRouteURL, data);
			return response;
		} else {
			return;
		}
	}

	//DELETE Deliverable
	async PortalUser_DFData_DeleteDeliverable(id = null) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/deliverables/${id}`;
		if (Boolean(id)) {
			let response = await ajaxDELETE(apiRouteURL);
			return response;
		} else {
			return;
		}
	}

	/************************** TEMPLATE API CALLS  ****************************/
	/**************************** (not used yet) ******************************/

	//GET Template(s)
	async PortalUser_DFData_GetTemplates(
		params = {
			jccmKeyID: "",
			scopeID: "",
			jobID: "",
			regionID: "",
			isArchived: "",
		}
	) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/templates`;
		apiRouteURL = setQueryParams(apiRouteURL, params);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//INSERT Template
	async PortalUser_DFData_InsertTemplate(data = {}) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/templates`;
		if (Object.keys(data)?.length > 0) {
			let response = await ajaxPOST(apiRouteURL, data);
			return response;
		} else {
			return;
		}
	}

	//UPDATE Template
	async PortalUser_DFData_UpdateTemplate(id = null, data = {}) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/templates/${id}`;
		if (Boolean(id) && Object.keys(data)?.length > 0) {
			let response = await ajaxPATCH(apiRouteURL, data);
			return response;
		} else {
			return;
		}
	}

	//DELETE Template
	async PortalUser_DFData_DeleteTemplate(id = null) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/templates/${id}`;
		if (Boolean(id)) {
			let response = await ajaxDELETE(apiRouteURL);
			return response;
		} else {
			return;
		}
	}

	/************************** SCOPE API CALLS  ****************************/
	/*************************** (not used yet) ***************************/

	//GET Scope(s)
	async PortalUser_DFData_GetScopes(
		params = {
			jccmKeyID: "",
			scopeID: "",
			jobID: "",
			regionID: "",
			isArchived: "",
		}
	) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/scopes`;
		apiRouteURL = setQueryParams(apiRouteURL, params);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//INSERT Scope
	async PortalUser_DFData_InsertScope(data = {}) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/scopes`;
		if (Object.keys(data)?.length > 0) {
			let response = await ajaxPOST(apiRouteURL, data);
			return response;
		} else {
			return;
		}
	}

	//UPDATE Scope
	async PortalUser_DFData_UpdateScope(id = null, data = {}) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/scopes/${id}`;
		if (Boolean(id) && Object.keys(data)?.length > 0) {
			let response = await ajaxPATCH(apiRouteURL, data);
			return response;
		} else {
			return;
		}
	}

	//DELETE Scope
	async PortalUser_DFData_DeleteScope(id = null) {
		var msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id;
		var apiRouteURL = apiBaseURL + `${msUserID}/DFData/scopes/${id}`;
		if (Boolean(id)) {
			let response = await ajaxDELETE(apiRouteURL);
			return response;
		} else {
			return;
		}
	}
}
