import React, { useState, useMemo } from "react";
import {
	Box,
	Container,
	Flex,
	HStack,
	Heading,
	Text,
	Spacer,
	IconButton,
	Skeleton,
	Tabs,
	TabList,
	Tab,
	Button,
	Stack,
	useBoolean,
	CircularProgress,
	Input,
} from "@chakra-ui/react";

import UsersAPI from "../../../APIs/UsersAPI";
import BasicDrawer from "../../../core/Layout/drawers/BasicDrawer";

import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";

import DataInput from "../../../core/Inputs/data/DataInput";
import LabelValuePair from "../../../core/Reports/components/LabelValuePair";

import { portalUser } from "../../../App";
import Region from "../../../PortalUser/Region";

import TimeOffRequestLegacy from "../../TimeOffRequests/classes/TimeOffRequestLegacy";

import { formatDate, dayDifference } from "../../../helperFunctions";

const localizer = momentLocalizer(moment);

export default class HRCalendar extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			usersAPI: new UsersAPI(),
			users: [],
			timeoffRequests: [],
			calendarEvents: [],
			selectedEvent: null,
			filters: {},
			timeoffCreatorIsOpen: false,
			tabVal: 0,
		};

		this.init = this.init.bind(this);
		this.viewEvent = this.viewEvent.bind(this);
		this.closeEvent = this.closeEvent.bind(this);
		this.handleFilterUpdate = this.handleFilterUpdate.bind(this);
		this.toggleTimeoffCreator = this.toggleTimeoffCreator.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.handleTabChange = this.handleTabChange.bind(this);
	}

	componentDidMount() {
		this.init();
	}

	componentDidUpdate() {
		if (!isNaN(this.props.tabVal) && this.state.tabVal !== this.props.tabVal) {
			this.handleTabChange(this.props.tabVal);
		}
	}

	async init() {
		let requests = await this.state.usersAPI.GetTimeOffRequestList();
		let timeoffRequests = [];
		let calendarEvents = [];

		let users = portalUser?.getUsers();
		let activeUsers = users?.map((user) => user.employeeUID);

		if (requests.status === 200) {
			requests = requests.value;
			for (let i = 0; i < requests?.length; i++) {
				let timeoffReq = new TimeOffRequestLegacy(requests[i]);
				let user = users?.find((d) => d.employeeUID === timeoffReq?.employeeUID?.toLowerCase()) ?? {};

				timeoffReq.regionID = Boolean(user?.regionID) ? user?.regionID : null;
				if (activeUsers.includes(timeoffReq?.employeeUID?.toLowerCase())) {
					timeoffRequests.push(timeoffReq);

					let startDate = moment(timeoffReq?.startDate.replace(/T.*/gm, " 00:00:00").replace(/-/gm, "/"));
					let endDate = moment(timeoffReq?.endDate?.replace(/T.*/gm, " 17:00:00").replace(/-/gm, "/"));
					let regions = portalUser?.getRegions();
					let calendarEvent = {
						start: startDate.toDate(),
						end: endDate.toDate(),
						title: timeoffReq?.name + " - " + timeoffReq?.type,
						id: timeoffReq.id,
						eventType: "timeoff",
						description: timeoffReq?.name + " - " + timeoffReq?.type,
						employeeUID: timeoffReq?.employeeUID,
						type: timeoffReq?.type,
						regionID: timeoffReq?.regionID,
						region: regions?.find((d) => d.regionID === timeoffReq?.regionID) ?? new Region(),
					};

					calendarEvents.push(calendarEvent);
				}
			}
		}

		this.setState({
			timeoffRequests: timeoffRequests,
			calendarEvents: calendarEvents,
			users: users,
		});
	}

	viewEvent(event, ev) {
		event = this.state.timeoffRequests.find(({ id }) => id === event.id);
		this.setState({
			selectedEvent: event,
		});
	}

	closeEvent() {
		this.setState({
			selectedEvent: null,
		});
	}

	handleFilterUpdate(attr, value) {
		let filters = this.state.filters;
		filters[attr] = value;

		if (filters[attr] === null) {
			delete filters[attr];
		}

		this.setState({
			filters: filters,
		});
	}

	toggleTimeoffCreator() {
		let timeoffCreatorIsOpen = this.state.timeoffCreatorIsOpen;
		this.setState({ timeoffCreatorIsOpen: !timeoffCreatorIsOpen });
	}

	handleSearchChange(ev) {
		let filters = this.state.filters;
		filters.title = ev.target.value;
		this.setState({
			filters: filters,
		});
	}

	handleTabChange(ev) {
		let tabVal = ev;
		let regionID = null;
		if (tabVal === 1) {
			regionID = "0";
		} else if (tabVal === 2) {
			regionID = "1";
		} else if (tabVal === 3) {
			regionID = "3";
		}

		this.handleFilterUpdate("regionID", regionID);

		this.setState({
			tabVal: ev,
		});
	}

	render() {
		const tabs = ["All", "SoCal", "NorCal", "Hawaii"];
		let calendarEvents = this.state.calendarEvents;
		let filters = this.state.filters;
		let visibleEvents = [];
		let filteredEvents = calendarEvents;
		if (Object.keys(filters)?.length > 0) {
			let validFilters = 0;
			for (let attr in filters) {
				let value = filters[attr]?.toLowerCase();
				if (Boolean(value)) {
					validFilters += 1;
					filteredEvents = filteredEvents?.filter((d) => d[attr]?.toLowerCase()?.includes(value)) ?? [];
				}
			}
			if (validFilters === 0) {
				visibleEvents = calendarEvents;
			} else {
				visibleEvents.push(...filteredEvents);
			}
		} else {
			visibleEvents = calendarEvents;
		}

		//Removes any inactive users
		visibleEvents = visibleEvents.filter((event) => !event.title.includes("null"));

		return (
			<Skeleton isLoaded={Boolean(this.state.calendarEvents)} h="full" flex={1} px={2}>
				<Container maxW="full" h="full">
					<Flex h="full" direction="column" flex={1} hidden={Boolean(this.state.selectedEvent)}>
						<HStack w="full" spacing={portalUser.styles?.pageSpacing} justify="space-between" mb={4}>
							<Tabs flex={1} colorScheme="teal" index={this.state.tabVal} onChange={this.handleTabChange}>
								{tabs?.length > 1 && isNaN(this.props.tabVal) && (
									<TabList
										color="gray.500"
										borderBottomColor="gray.400"
										borderBottomWidth={2}
										maxW={"full"}
										mx="auto"
										w="full"
									>
										{tabs?.map((tab, i) => (
											<Tab
												borderStyle="inset"
												key={i}
												_selected={{
													color: "teal.600",
													bg: "whiteAlpha.500",
													borderTopRadius: "md",
													borderBottomWidth: 3,
													borderBottomColor: "teal.600",
													textShadow: "1px 1px 2px var(--chakra-colors-whiteAlpha-900)",
												}}
												_hover={{
													color: "teal.500",
													bg: "whiteAlpha.700",
													borderTopRadius: "md",
													borderBottomWidth: 3,
													borderBottomColor: "teal.600",
													textShadow: "1px 1px 2px var(--chakra-colors-whiteAlpha-900)",
													shadow: "lg",
												}}
											>
												<Heading
													isTruncated
													fontSize={"lg"}
													letterSpacing={2}
													textTransform="uppercase"
													textShadow={"2px 2px 2px var(--chakra-colors-whiteAlpha-800)"}
												>
													{tab}
												</Heading>
											</Tab>
										))}
									</TabList>
								)}
							</Tabs>

							<HStack align="center" flex={1} w="full">
								<Input
									rounded="md"
									shadow={portalUser.styles?.cardShadow}
									borderWidth={1}
									borderColor={portalUser.styles?.cardBorderColor}
									size="md"
									px={2}
									h={9}
									bg={portalUser.styles?.cardBG}
									onChange={this.handleSearchChange}
									placeholder="Search..."
								/>

								{Boolean(portalUser.user?.isPortalAdminYN === "Y") && (
									<Button
										size="sm"
										py={2}
										textTransform="uppercase"
										onClick={this.toggleTimeoffCreator}
										colorScheme="teal"
									>
										+ New Request
									</Button>
								)}
							</HStack>
						</HStack>
						<Calendar
							localizer={localizer}
							defaultDate={new Date()}
							defaultView="month"
							events={visibleEvents ?? []}
							views={["month", "work_week"]}
							style={{
								height: "100%",
								minHeight: "800px",
								maxWidth: "100%",
								width: "100%",
								backgroundColor: "white",
								borderRadius: "var(--chakra-radii-md)",
								boxShadow: portalUser.styles?.cardShadow,
								borderWidth: 2,
								borderColor: portalUser.styles?.cardBorderColor,
								padding: "var(--chakra-sizes-6)",
							}}
							popup={true}
							onSelectEvent={(event, ev) => {
								this.viewEvent(event, ev);
							}}
							eventPropGetter={(event) => {
								return {
									style: {
										background: event?.region?.bg,
										fontWeight: "600",
										borderColor: event?.region?.borderColor,
										color: event?.region?.fontColor,
									},
								};
							}}
						/>
					</Flex>

					{Boolean(this.state.selectedEvent) && (
						<Box bg="white" p="3">
							<Flex>
								<Spacer />
								<IconButton
									variant="ghost"
									color="gray.500"
									icon={<i className="fas fa-times" />}
									onClick={this.closeEvent}
								/>
							</Flex>

							<Box>
								<Heading as="h5" color="gray.600">
									{this.state.selectedEvent.type}: {this.state.selectedEvent.name}
								</Heading>
								<Heading as="h6" size="sm" color="gray.500">
									{moment(this.state.selectedEvent.startDate).format("M/D/YYYY")} -{" "}
									{moment(this.state.selectedEvent.endDate).format("M/D/YYYY")}
								</Heading>
								<Text color="gray.600" mt="3">
									{this.state.selectedEvent.name} will be on {this.state.selectedEvent.type} leave
									for&nbsp;
									{moment(this.state.selectedEvent.startDate).format("ddd, MMMM D YYYY")} to{" "}
									{moment(this.state.selectedEvent.endDate).format("ddd, MMMM D YYYY")}
								</Text>
							</Box>
						</Box>
					)}
				</Container>
				{this.state.timeoffCreatorIsOpen && (
					<TimeoffRequestDrawer
						isOpen={this.state.timeoffCreatorIsOpen}
						onClose={this.toggleTimeoffCreator}
					/>
				)}
			</Skeleton>
		);
	}
}

function TimeoffRequestDrawer(props) {
	const usersAPI = new UsersAPI();

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [holidays, setHolidays] = useState([]);
	const [isCalculating, setCalculating] = useBoolean();
	const [employeeUID, setEmployee] = useState(null);
	const [type, setType] = useState("Vacation");
	const [notes, setNotes] = useState(null);
	const [dbLoading, setDBLoading] = useBoolean();

	const requestTypes = useMemo(() => {
		return [
			{ text: "Vacation", value: "Vacation" },
			{ text: "Sick", value: "Sick" },
		];
	}, []);

	const updateDateRange = async (date, name) => {
		//let tempDate = endDate;
		// log("Date", [date, name]);
		setStartDate(date[0]);
		setEndDate(date[1]);
		let sDate = date[0];
		let eDate = date[1];

		if (sDate) {
			sDate = new Date(sDate);
			sDate.setHours(0);
			setStartDate(sDate);
		}

		if (eDate) {
			eDate = new Date(eDate);
			eDate.setHours(5);
			setEndDate(eDate);
		}

		setCalculating.on();
		if (sDate && eDate) {
			let params = {};
			let query = {
				eventType: "Holiday",
				startDate: sDate.toLocaleDateString(),
				endDate: eDate.toLocaleDateString(),
			};
			let holidays = await usersAPI.GetCalendarEvents(params, query);

			setHolidays(holidays.value);
			setCalculating.off();
		}
	};

	const updateStartDate = async (date, name) => {
		let tempDate = endDate;

		if (name === "start") {
			if (date.getTime() > endDate.getTime()) {
				tempDate = new Date(date);
				tempDate.setDate(date.getDate());
				setEndDate(tempDate);
			}
			date = new Date(date);
			date.setHours(0);
			setStartDate(date);
		} else if (name === "end") {
			date = new Date(date);
			date.setHours(5);
			setEndDate(date);
		}
		setCalculating.on();
		let params = {};
		let query = {
			eventType: "Holiday",
			startDate: new Date(name === "start" ? date : startDate).toLocaleDateString(),
			endDate: new Date(name === "start" ? tempDate : date).toLocaleDateString(),
		};
		let holidays = await usersAPI.GetCalendarEvents(params, query);
		//portalUser.getCalendarEvents()?.filter((d)=> d.eventType === 'Holiday')
		setHolidays(holidays.value);
		//setCalculating(false);
	};

	const hours = useMemo(() => {
		let holidayCount = 0;
		for (let i = 0; i < holidays?.length; i++) {
			let startDate = new Date(formatDate(holidays[i].StartDate));
			let endDate = new Date(formatDate(holidays[i].EndDate));
			endDate.setDate(endDate.getDate() + 1);
			while (startDate.toLocaleDateString() !== endDate.toLocaleDateString()) {
				if (startDate.getDay() !== 0 && startDate.getDay() !== 6) {
					holidayCount++;
				}
				startDate.setDate(startDate.getDate() + 1);
			}
		}

		let totalHours = 8 * (dayDifference(startDate, endDate) - holidayCount);
		if (totalHours < 0) {
			totalHours = 0;
		}
		setCalculating.off();
		return totalHours;
	}, [endDate, holidays, setCalculating, startDate]);

	const updateEmployee = (employeeUID) => {
		setEmployee(employeeUID);
	};

	const updateType = (ev) => {
		let requestType = ev.target.value;
		setType(requestType);
	};

	const updateNotes = (ev) => {
		let notes = ev.target.value;
		setNotes(notes);
	};

	const handleSubmit = async () => {
		let timeoffRequest = new TimeOffRequestLegacy();
		timeoffRequest.employeeUID = employeeUID;
		timeoffRequest.type = type;
		timeoffRequest.startDate = startDate.toLocaleDateString();
		timeoffRequest.endDate = endDate.toLocaleDateString();
		timeoffRequest.requestedHours = hours;
		timeoffRequest.requestNote = notes;

		timeoffRequest.dateAcknowledged = new Date().toLocaleDateString();
		timeoffRequest.acknowledgedBy = portalUser.user?.employeeUID;
		timeoffRequest.status = 1;

		setDBLoading.on();

		await usersAPI.InsertTimeoffRequest(timeoffRequest);
		setDBLoading.off();
		props.onClose();
	};

	let users = portalUser.getUsers();
	let disabledUsers = users?.filter((d) => d.userStatus !== "Active");

	return (
		<BasicDrawer
			isOpen={props.isOpen}
			onClose={props.onClose}
			title="Time Off Request"
			onSubmit={handleSubmit}
			isSubmitting={dbLoading}
		>
			<Box>
				<Stack>
					<DataInput
						key={employeeUID}
						name="employee"
						label="Employee"
						type="select"
						options={users}
						disabledOptions={disabledUsers}
						optionText="name"
						optionSubheading="email"
						optionValue="employeeUID"
						searchable
						onOptionSelect={updateEmployee}
						value={employeeUID}
						isModal
					/>
					<DataInput
						name="type"
						label="Request Type"
						type="select"
						options={requestTypes}
						onChange={updateType}
						value={type}
					/>
					<Stack direction="row">
						<DataInput
							name="startDate"
							label="Start Date"
							type="date"
							onChange={(date) => {
								updateStartDate(date, "start");
							}}
							value={startDate}
							isModal
						/>
						<DataInput
							name="endDate"
							label="End Date"
							type="date"
							onChange={(date) => {
								updateStartDate(date, "end");
							}}
							value={endDate}
							isModal
						/>
					</Stack>
					<DataInput
						name="startToEndDateRangeTest"
						label="Date Range"
						type="date"
						onChange={(date) => {
							updateDateRange(date, "end");
						}}
						inputProps={{ selected: startDate, startDate: startDate, endDate: endDate, selectsRange: true }}
						isModal
					/>
					<LabelValuePair
						direction="vertical"
						smallText
						label="Hours"
						value={
							isCalculating ? (
								<CircularProgress isIndeterminate color="blue.600" width="30px" height="30px" />
							) : (
								hours
							)
						}
						labelProps={{ color: "gray.500" }}
					/>
					<DataInput
						name="notes"
						label="Notes (Optional)"
						type="textarea"
						onBlur={updateNotes}
						defaultValue={notes}
					/>
				</Stack>
			</Box>
		</BasicDrawer>
	);
}
