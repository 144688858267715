const ANNOUNCEMENT_TYPES = [
	{ value: "announcement", label: "Announcement", icon: "fas fa-bullhorn", color: "gray.500" },
	{ value: "reminder", label: "Reminder", icon: "fas fa-bell", color: "yellow.400" },
	{ value: "update", label: "Update", icon: "fas fa-exclamation-circle", color: "blue.500" },
	{ value: "new project", label: "New Project", icon: "fas fa-award", color: "green.500" },
	{ value: "achievement", label: "Achievement", icon: "fas fa-trophy", color: "orange.400" },
	{ value: "upcoming event", label: "Upcoming Event", icon: "fas fa-glass-cheers", color: "orange.500" },
	{ value: "payroll reminder", label: "Payroll Reminder", icon: "fas fa-history", color: "teal.500" },
	{ value: "system maintenance", label: "System Maintenance", icon: "fas fa-power-off", color: "red.500" },
	{ value: "company news", label: "Company News", icon: "fas fa-newspaper", color: "cyan.600" },
	{ value: "company goals", label: "Company Goals", icon: "fas fa-bullseye", color: "teal.500" },
	{ value: "office dress-up", label: "Office Dress-Up", icon: "fas fa-tshirt", color: "blue.500" },
];
export default ANNOUNCEMENT_TYPES;
