import { formatDate } from "../../../helperFunctions";
export default class JobPhase {
	constructor(jobPhase) {
		this.queryData = jobPhase?.queryData ?? jobPhase?.QueryData ?? "JobPhase";
		this.jccmKeyID = jobPhase?.jccmKeyID ?? jobPhase?.JCCMKeyID ?? null;
		this.jcpmKeyID = jobPhase?.jcpmKeyID ?? jobPhase?.JCPMKeyID ?? null;
		this.jcjpKeyID = jobPhase?.jcjpKeyID ?? jobPhase?.JCJPKeyID ?? null;
		this.jcchKeyID = jobPhase?.jcchKeyID ?? jobPhase?.JCCHKeyID ?? null;
		this.jcjpKeyID = jobPhase?.jcjpKeyID ?? jobPhase?.jcjpKeyID ?? null;

		this.jcco = jobPhase?.jcco ?? jobPhase?.JCCo ?? null;
		this.contract = jobPhase?.contract ?? jobPhase?.Contract?.trim() ?? null;
		this.job = jobPhase?.job ?? jobPhase?.Job?.trim() ?? null;
		this.contractItem = jobPhase?.contractItem ?? jobPhase?.ContractItem?.trim() ?? null;
		this.itemTypeID = jobPhase?.itemTypeID ?? jobPhase?.ItemTypeID ?? "LS";

		this.phaseGroup = jobPhase?.phaseGroup ?? jobPhase?.PhaseGroup ?? null;
		this.jobPhase = jobPhase?.jobPhase ?? jobPhase?.JobPhase ?? null;
		this.description = jobPhase?.description ?? jobPhase?.Description ?? null;
		this.scope = jobPhase?.scope ?? jobPhase?.Scope ?? "GENL";

		this.costType = jobPhase?.costType ?? jobPhase?.CostType ?? null;
		this.origEstCost = jobPhase?.origEstCost ?? jobPhase?.OrigEstCost ?? 0;
		this.origEstHours = jobPhase?.origEstHours ?? jobPhase?.OrigEstHours ?? 0;
		this.origEstUnits = jobPhase?.origEstUnits ?? jobPhase?.OrigEstUnits ?? 0;
		this.um = jobPhase?.um ?? jobPhase?.UM ?? null;

		this.sourceStatus = jobPhase?.sourceStatus ?? jobPhase?.sourceStatus ?? "J";
		this.interfaceDate = formatDate(jobPhase?.interfaceDate ?? jobPhase?.InterfaceDate) ?? null;
		this.lastProjDate = formatDate(jobPhase?.lastProjDate ?? jobPhase?.LastProjDate) ?? null;

		this.activeTimecardYN = jobPhase?.activeTimecardYN ?? jobPhase?.ActiveTimecardYN ?? "Y";
		this.activePhaseYN = jobPhase?.activePhaseYN ?? jobPhase?.ActivePhaseYN ?? "Y";
		this.activeCostTypeYN = jobPhase?.activeCostTypeYN ?? jobPhase?.ActiveCostTypeYN ?? "Y";

		// this.tradeID = jobPhase?.tradeID ?? jobPhase?.TradeID ?? null;
		// this.phaseID = jobPhase?.phaseID ?? jobPhase?.PhaseID ?? null;
		// this.areaID = jobPhase?.areaID ?? jobPhase?.AreaID ?? null;
		// this.modifierID = jobPhase?.modifierID ?? jobPhase?.ModifierID ?? null;
	}

	// ,PhaseGroup = jcjp.PhaseGroup
	// ,BasePhase = LEFT(jcjp.Phase,8)
	// ,TradeID = LEFT(jcjp.Phase,2)
	// ,PhaseID = RIGHT(LEFT(jcjp.Phase,7),4)
	// ,AreaID = RIGHT(LEFT(jcjp.Phase,12),4)
	// ,ModifierID = CASE WHEN LEN(jcjp.Phase)=15 THEN RIGHT(jcjp.Phase,2) ELSE '' END
}
