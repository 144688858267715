import { v4 } from "uuid";
import Section from "./Section";

export default class Page {
	constructor(page, reportUID = v4()) {
		this.reportUID = reportUID;
		this.pageUID = v4();

		this.show = page?.show ?? true;
		this.wrap = page?.wrap ?? true;

		this.size = page?.size ?? "LETTER";
		this.orientation = page?.orientation ?? "portrait";
		this.shadow = page?.shadow ?? "lg";

		this.pageMargins = page?.pageMargins ?? {};
		this.bg = page?.bg ?? "white";

		this.pageTemplate = page?.pageTemplate ?? "PageTemplate";
		this.pageName = page?.pageName ?? "Report Template";
		this.pageTitle = page?.pageTitle ?? "Default Page Template";

		this.pageBody = this.newSections(page?.pageBody ?? []);

		this.showPageHeader = page?.showPageHeader ?? true;
		this.addressBar = page?.addressBar ?? this.getAddressBar(page?.region ?? {});

		this.showPageFooter = page?.showPageFooter ?? true;

		this.pageFooter = page?.pageFooter ?? {};
		this.pageHeader = page?.pageHeader ?? {};
	}

	getAddressBar(region = {}) {
		let addressBar = {
			address: region?.address ?? "18250 Euclid Street",
			city: region?.city ?? "Fountain Valley",
			state: region?.state ?? "CA",
			zip: region?.zip ?? "92708",
			phone: region?.phone ?? "(949) 474-9170",
			fax: region?.fax ?? "(949) 474-9936",
		};
		return addressBar;
	}

	newSections(sections = []) {
		let newSections = [];
		if (Boolean(sections) && sections?.length > 0) {
			for (let i = 0; i < sections?.length; i++) {
				let section = new Section(sections[i]);
				newSections.push(section);
			}
		} else {
			let section = new Section(null);
			newSections.push(section);
		}
		return newSections;
	}
}
