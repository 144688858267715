export default class Role {
	constructor(role) {
		this.queryData = role?.queryData ?? role?.QueryData ?? "Role";
		this.roleID = role?.roleID ?? role?.RoleID ?? null;
		this.role = role?.role ?? role?.Role ?? null;
		this.tag = role?.tag ?? role?.Tag ?? null;
		this.rank = role?.rank ?? role?.Rank ?? null;
		this.deptID = role?.deptID ?? role?.DeptID ?? null;
		this.seq = role?.seq ?? role?.Seq ?? null;
		this.accessibleOnlyYN = role?.accessibleOnlyYN ?? role?.AccessibleOnly ?? "N";
		this.showMainToolbarYN = role?.showMainToolbarYN ?? role?.ShowMainToolbar ?? "Y";
		this.hideModFoldersYN = role?.hideModFoldersYN ?? role?.HideModFolders ?? "N";
		this.showLogoPanelYN = role?.showLogoPanelYN ?? role?.ShowLogoPanel ?? "N";
		this.minimizeUseYN = role?.minimizeUseYN ?? role?.MinimizeUse ?? "N";
	}
}
