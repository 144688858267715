import { formatValue } from "../../../helperFunctions";

export default class TimecardHeader {
	constructor(header) {
		if (header instanceof TimecardHeader) {
			Object.assign(this, header);
		} else {
			this.queryData = header?.queryData ?? "TimecardHeader";

			this.keyID = header?.keyID?.toString() ?? null;
			this.prEndDate = formatValue(header?.prEndDate, 0, "date") ?? null;
			this.employeeUID = header?.employeeUID?.toLowerCase() ?? null;
			this.prco = header?.prco?.toString() ?? null;
			this.prGroup = header?.prGroup?.toString() ?? null;
			this.prEmployee = header?.prEmployee?.toString() ?? null;

			this.submitterUID = header?.submitterUID?.toLowerCase() ?? null;
			this.submittedDate = formatValue(header?.submittedDate, 0, "date") ?? null;
			this.approverUID1 = header?.approverUID1?.toLowerCase() ?? null;
			this.approvedDate1 = formatValue(header?.approvedDate1, 0, "date") ?? null;
			this.approverUID2 = header?.approverUID2?.toLowerCase() ?? null;
			this.approvedDate2 = formatValue(header?.approvedDate2, 0, "date") ?? null;
			this.weekNotes = header?.weekNotes ?? null;
			this.isLockedYN = header?.isLockedYN ?? "N";
			this.hqbcKeyID = header?.hqbcKeyID?.toString() ?? null;
		}
	}
}
