import { getValueColor } from "../../../helperFunctions";

export default class SectionTemplateMFRAccountingSummary {
	constructor(contractTotals = null, earlyLateAvg = 0) {
		this.template = "MFRAccountingSummary";
		this.name = "Accounting Summary";
		this.heading = null;
		this.divider = false;
		this.pagebreak = 0;
		this.type = "labelValuePairs";

		this.subSections = [
			new SubSectionTemplateMFRAccountingSummary1(contractTotals, earlyLateAvg),
			new SubSectionTemplateMFRAccountingSummary2(contractTotals),
		];
	}
}

class SubSectionTemplateMFRAccountingSummary1 {
	constructor(contractTotals = null, earlyLateAvg = 0) {
		let billedToDatePct = 0;
		let retentionPct = 0;
		let currBillingBal = 0;
		let projBillingBal = 0;
		if (Boolean(contractTotals?.queryData)) {
			billedToDatePct = contractTotals?.getBilledToDatePct() ?? 0;
			retentionPct = contractTotals?.getRetentionPct() ?? 0;
			currBillingBal = contractTotals?.getCurrBillingBal() ?? 0;
			projBillingBal = contractTotals?.getProjBillingBal() ?? 0;
		}

		this.template = "mfrAccountingSummary1";
		this.type = "labelValuePairs";
		this.width = "60%";
		this.columns = [
			{
				accessor: "leftAddon",
				width: "6%",
			},
			{
				accessor: "label",
				width: "48%",
			},
			{
				accessor: "value",
				width: "30%",
				textAlign: "right",
				valueType: "currency",
				decimals: 0,
			},
			{
				accessor: "rightAddon",
				width: "16%",
				fontSize: 8,
			},
		];
		this.data = [
			{
				leftAddon: "A.",
				label: "Gross Billing To Date",
				value: contractTotals?.billedAmt ?? 0,
				rightAddon: billedToDatePct,
				rightAddonType: "percent",
				rightAddonDecimals: 1,
			},
			{
				leftAddon: "B.",
				label: "Retention Held",
				value: contractTotals?.retentionAmt ?? 0,
				rightAddon: retentionPct,
				rightAddonType: "percent",
				rightAddonDecimals: 1,
			},
			{
				leftAddon: "C.",
				label: "Unbilled Contract Balance",
				value: currBillingBal,
			},
			{
				leftAddon: "D.",
				label: "Projected Billing Balance",
				value: projBillingBal,
			},
			{
				leftAddon: "E.",
				label: "Early/Late (3 Month Avg)",
				value: earlyLateAvg,
				valueType: "number",
				valueDecimals: 1,
				rightAddon: earlyLateAvg > 0 ? "days late" : "days early",
			},
		];
	}
}

class SubSectionTemplateMFRAccountingSummary2 {
	constructor(contractTotals = null) {
		let receivedVsCost = 0;
		let billedVsCost = 0;
		let netCashFlow = 0;

		if (Boolean(contractTotals?.queryData)) {
			receivedVsCost = contractTotals?.getReceivedVsCost() ?? 0;
			billedVsCost = contractTotals?.getBilledVsCost() ?? 0;
			netCashFlow = contractTotals?.netCashFlow ?? 0;
		}

		this.template = "mfrAccountingSummary2";
		this.type = "labelValuePairs";
		this.width = "40%";
		this.columns = [
			{
				accessor: "label",
				width: "50%",
			},
			{
				accessor: "value",
				width: "40%",
				valueType: "currency",
				textAlign: "right",
			},
			{
				accessor: "rightAddon",
				width: "10%",
			},
		];
		this.data = [
			{
				label: "Cash Recieved",
				value: contractTotals?.receivedAmt ?? 0,
			},
			{
				label: "Current Cost",
				value: contractTotals?.actualCost ?? 0,
			},
			{
				label: "Received Vs Cost",
				value: receivedVsCost,
				valueTextColor: getValueColor(receivedVsCost),
			},
			{
				label: "Billed Vs Cost",
				value: billedVsCost,
			},
			{
				label: "Net Cash Flow",
				value: netCashFlow,
				valueTextColor: getValueColor(netCashFlow),
			},
		];
	}
}
