import { formatDate } from "../../../helperFunctions";

export default class Job {
	constructor(job) {
		if (job instanceof Job) {
			Object.assign(this, job);
		} else {
			this.queryData = job?.queryData ?? job?.QueryData ?? "Job";
			this.msUserID = job?.msUserID ?? null;
			this.securityGroup = job?.securityGroup?.toString() ?? null;
			this.jobAccessYN = job?.jobAccessYN ?? "N";

			this.jcco = job?.jcco?.toString() ?? job?.JCCo?.toString() ?? null;
			this.companyName = job?.companyName ?? null;
			this.companyTag = job?.companyTag ?? null;

			this.jccmKeyID = job?.jccmKeyID?.toString() ?? null;
			this.project = job?.project ?? null;
			this.contract = job?.contract ?? job?.Contract?.trim() ?? null;
			this.contractName = job?.contractName ?? null;
			this.contractStatus = job?.contractStatus ?? job?.ContractStatus ?? "0";
			this.custGroup = job?.custGroup ?? "1";
			this.customer = job?.customerName ?? null;
			this.customerName = job?.customerName ?? null;

			this.regionID = job?.regionID?.toString() ?? null;
			this.region = job?.region ?? null;
			this.tradeID = job?.tradeID?.toString() ?? null;
			this.trade = job?.trade ?? null;
			this.projectTypeID = job?.projectTypeID?.toString() ?? null;
			this.projectType = job?.projectType ?? null;
			this.contractTypeID = job?.contractTypeID?.toString() ?? null;
			this.contractType = job?.contractType ?? null;
			this.platformID = job?.platformID?.toString() ?? "3";
			this.platform = job?.platform ?? "Plan & Spec";
			this.insuranceTypeID = job?.insuranceTypeID?.toString() ?? "1";
			this.insuranceType = job?.insuranceType ?? "PPM";
			this.bondedYN = job?.bondedYN ?? "N";

			this.startMth = formatDate(job?.startMth ?? null);
			this.endMth = formatDate(job?.endMth ?? null);

			this.jcjmKeyID = job?.jcjmKeyID?.toString() ?? null;
			this.job = job?.job ?? job?.Job?.trim() ?? null;
			this.jobName = job?.jobName ?? null;
			this.jobStatus = job?.jobStatus?.toString() ?? "0";

			this.pmID = job?.pmID?.toString() ?? "100";
			this.pmName = job?.pmName ?? null;
			this.paID = job?.paID?.toString() ?? "N/A";
			this.paName = job?.paName ?? null;
			this.purchaserID = job?.purchaserID?.toString() ?? "N/A";
			this.purchaserName = job?.purchaserName ?? null;
			this.jobAddress = job?.jobAddress ?? job?.JobAddress ?? null;
			this.jobCity = job?.jobCity ?? job?.JobCity ?? null;
			this.jobState = job?.jobState ?? job?.JobState ?? null;
			this.jobZip = job?.jobZip ?? job?.JobZip ?? null;

			this.certifiedYN = job?.certifiedYN ?? job?.CertifiedYN ?? "N";
			this.hrsPerManDay = job?.hrsPerManDay ?? job?.HrsPerManDay ?? 8;

			// this.revGrpInv = job?.revGrpInv ?? job?.RevGrpInv ?? null;
			// this.insCode = job?.insCode ?? job?.InsCode ?? null;
		}
	}
}
