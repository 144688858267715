import React, { useState, useEffect, useMemo } from "react";
import {
	Box,
	Center,
	Flex,
	Text,
	Heading,
	AvatarGroup,
	Avatar,
	AvatarBadge,
	Button,
	Collapse,
	Stack,
	HStack,
	Skeleton,
} from "@chakra-ui/react";
import moment from "moment";
import { portalUser } from "../../../App";
import { convertArrayToMap, sortArrayBy } from "../../../helperFunctions";
import BasicCard from "../../../core/Layout/cards/BasicCard";
import { motion } from "framer-motion";
import { BatchRequestContent } from "@microsoft/microsoft-graph-client/lib/src/content/BatchRequestContent";
import HR_LONGEVITY_AWARDS from "../../Employee/constants/HRLongevityAwards";

const awardMap = convertArrayToMap(HR_LONGEVITY_AWARDS, "awardMonths");

export default function CelebrationsCard() {
	const [longevitySummary, setLongevitySummary] = useState(false);

	const [longevityEmployees, setLongevityEmployees] = useState([]);
	useEffect(() => {
		const init = async () => {
			let employees = portalUser.getPREmployees() ?? [];
			employees = employees?.filter((d) => d.employeeUID !== "3f4b25a2-545e-40a2-9d7d-d2c1ead77734");
			employees = employees?.filter((d) => Boolean(awardMap[parseInt(d.longevityMths)]?.awardYear ?? null));

			employees = sortArrayBy(employees, "deptID", 1, "number");
			employees = sortArrayBy(employees, "parentDeptID", 1, "number");
			employees = sortArrayBy(employees, "longevityMths", -1);

			employees = employees?.map((employee) => ({
				msUserID: employee?.msUserID ?? null,
				employeeUID: employee?.employeeUID,
				awardYear: awardMap[parseInt(employee?.longevityMths)]?.awardYear,
				awardColor: awardMap[parseInt(employee?.longevityMths)]?.color,
				longevityMths: employee?.longevityMths,
				name: employee?.name,
				region: employee?.region,
				title: employee?.title,
				deptID: employee?.deptID,
				dept: employee?.dept,
				parentDeptID: employee?.parentDeptID,
				profilePhoto: employee?.profilePhoto,
			}));
			employees = [...new Set(employees)];

			let batchRequestContent = new BatchRequestContent();
			let batchRequests = [];
			let requestsAdded = 0;
			for (let i = 0; i < employees?.length; i++) {
				if (!Boolean(employees[i]?.msUserID) && employees[i]?.initPhoto) {
					continue;
				}
				let request = portalUser.msClient.GetMSUserPhoto(employees[i]?.msUserID, true);

				batchRequestContent.addRequest({ id: i, request: request });
				requestsAdded++;

				if (requestsAdded % 19 === 0) {
					batchRequests.push(batchRequestContent);
					batchRequestContent = new BatchRequestContent();
				}
				if (i === employees?.length - 1 && requestsAdded > 0) {
					batchRequests.push(batchRequestContent);
				}
			}
			for (let j = 0; j < batchRequests?.length; j++) {
				if (batchRequests[j].requests.size === 0) {
					continue;
				}
				let batchResults = await portalUser.msClient.MSBatchRequest(batchRequests[j]);

				for (let k = 0; k < batchResults?.responses?.length; k++) {
					let index = parseInt(batchResults.responses[k].id);
					if (batchResults.responses[k].status === 200) {
						let byteCharacters = atob(batchResults.responses[k].body);
						let byteNumbers = new Array(byteCharacters?.length);
						for (let l = 0; l < byteCharacters?.length; l++) {
							byteNumbers[l] = byteCharacters.charCodeAt(l);
						}
						let byteArray = new Uint8Array(byteNumbers);

						let imgBlob = new Blob([byteArray], {
							type: batchResults.responses[k].headers["Content-Type"],
						});
						let image = window.URL.createObjectURL(imgBlob);
						employees[index].profilePhoto = image;
					}
					employees[index].initPhoto = false;
					let user =
						portalUser.userData?.find(
							(d) => d.queryData === "User" && d.employeeUID === employees[index]?.employeeUID
						) ?? null;
					if (Boolean(user)) {
						user.profilePhoto = employees[index].profilePhoto;
						user.initPhoto = employees[index].initPhoto;
					}

					let employee =
						portalUser.userData?.find(
							(d) => d.queryData === "PREmployee" && d.employeeUID === employees[index]?.employeeUID
						) ?? null;
					if (Boolean(user)) {
						employee.profilePhoto = employees[index].profilePhoto;
						employee.initPhoto = employees[index].initPhoto;
					}
				}
			}

			setLongevityEmployees(employees);
		};

		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const longevityAwards = useMemo(
		() => convertArrayToMap(longevityEmployees, "awardYear", true),
		[longevityEmployees]
	);

	var deptMap = convertArrayToMap(portalUser.getDepartments(), "deptID");

	const [selectedYear, setSelectedYear] = useState(null);
	const toggleAwardYear = (awardYear) => {
		selectedYear === awardYear ? setSelectedYear(null) : setSelectedYear(awardYear);
	};

	return (
		<motion.div whileHover={{ scale: 1.01, transition: { duration: 0.5 } }}>
			<BasicCard title="Celebrations" portalStyles={portalUser.styles}>
				<Stack spacing="2" w="full">
					<Heading letterSpacing={1} textTransform={"uppercase"} size="sm" color="gray.500">
						{"Longevity Milestones in " + moment(new Date()).format("MMMM yyyy")}
					</Heading>

					<Skeleton isLoaded={longevityEmployees?.length > 0} rounded="lg" minH="150px">
						<Stack spacing="2" w="full">
							<Collapse in={!longevitySummary}>
								<Box
									cursor="pointer"
									_hover={{ bg: "whiteAlpha.900", borderColor: "gray.200" }}
									borderWidth={2}
									borderColor="transparent"
									rounded="lg"
									onClick={(e) => {
										setLongevitySummary(e);
									}}
								>
									<Flex px="2" py="4" w="full" overflowX="auto" className="skinnyScroll">
										<Center flex={1} mr="2" fontStyle="italic" color="gray.600">
											<Heading size="xl" color="orange.400">
												<i className="fas fa-trophy fa-lg fa-fw" />
											</Heading>
										</Center>
										<AvatarGroup flex={8} size="lg" max={8} spacing={-2}>
											{longevityEmployees.map((user, j) => (
												<Avatar
													key={j}
													size="md"
													name={user?.name}
													src={user?.profilePhoto ?? ""}
													borderWidth={2}
													borderColor={
														user?.profilePhoto
															? "whiteAlpha.800"
															: deptMap[user?.deptID]?.color
													}
													shadow="md"
													fontWeight="bold"
													letterSpacing={2}
													color="whiteAlpha.900"
													textAlign="center"
													bg={deptMap[user?.deptID]?.color}
													bgGradient={
														"linear(to-tr, " +
														deptMap[user?.deptID]?.color +
														", blackAlpha.400)"
													}
												>
													<AvatarBadge
														size="sm"
														bg={user?.awardColor}
														boxSize={7}
														borderWidth={1}
														color="white"
													>
														<Text fontSize="md" w="full" textAlign="center">
															{user?.awardYear}
														</Text>
													</AvatarBadge>
												</Avatar>
											))}
										</AvatarGroup>
									</Flex>
								</Box>
							</Collapse>

							<Collapse in={longevitySummary}>
								<Stack w="full">
									{Object.keys(longevityAwards)
										?.reverse()
										?.map((awardYear, j) => (
											<Box cursor="pointer" key={j}>
												<Flex
													id={awardYear}
													onClick={(e) => {
														toggleAwardYear(awardYear);
													}}
													_hover={{
														bg: "whiteAlpha.900",
														borderColor: awardMap[awardYear * 12].color,
													}}
													rounded="lg"
													borderWidth={2}
													borderColor="transparent"
													px="2"
													py="4"
												>
													<Center
														flex={1}
														mr="4"
														fontStyle="italic"
														color="gray.600"
														pos="relative"
													>
														<Heading size="xl" color="orange.400" opacity="0.50">
															<i className="fas fa-trophy fa-lg fa-fw" />
														</Heading>
														<HStack
															pos="absolute"
															fontStyle="italic"
															color="gray.600"
															textShadow="1px 1px #ED8936"
														>
															<Heading size="xl">{awardYear}</Heading>
															<Heading size="md">Yrs</Heading>
														</HStack>
													</Center>

													<AvatarGroup
														flex={8}
														size="lg"
														max={8}
														spacing={-2}
														cursor="pointer"
													>
														{longevityAwards[awardYear].map((user, j) => (
															<Avatar
																key={j}
																size="md"
																name={user?.name}
																src={user?.profilePhoto ?? ""}
																borderWidth={2}
																borderColor={
																	user?.profilePhoto
																		? "whiteAlpha.800"
																		: deptMap[user?.deptID]?.color
																}
																shadow="md"
																fontWeight="bold"
																letterSpacing={2}
																color="whiteAlpha.900"
																textAlign="center"
																bg={deptMap[user?.deptID]?.color}
																bgGradient={
																	"linear(to-tr, " +
																	deptMap[user?.deptID]?.color +
																	", blackAlpha.400)"
																}
															>
																<AvatarBadge
																	justify="center"
																	align="center"
																	size="sm"
																	bg={user?.awardColor}
																	boxSize={8}
																	borderWidth="1px"
																	color="white"
																>
																	<Text fontSize="md" w="full" textAlign="center">
																		{user?.awardYear}
																	</Text>
																</AvatarBadge>
															</Avatar>
														))}
													</AvatarGroup>
												</Flex>

												<Collapse in={awardYear === selectedYear ? true : false}>
													<Box
														bg="whiteAlpha.900"
														p="4"
														rounded="lg"
														border="1px"
														borderColor="blackAlpha.200"
														cursor="default"
													>
														<Text
															color="gray.500"
															letterSpacing={1}
															textTransform="uppercase"
															pb="2"
															size="lg"
															fontWeight="semibold"
														>
															{"Congratulations on " + awardYear + " years!"}
														</Text>
														<Flex
															px="4"
															maxH="2xs"
															direction="column"
															wrap="wrap"
															className="skinnyScroll"
															overflowY={"auto"}
														>
															{longevityAwards[awardYear]?.map((user, j) => (
																<HStack flex={1} py="1" key={j}>
																	<Avatar
																		key={j}
																		size="md"
																		name={user?.name}
																		src={user?.profilePhoto ?? ""}
																		borderWidth={2}
																		borderColor={
																			user?.profilePhoto
																				? "whiteAlpha.800"
																				: deptMap[user?.deptID]?.color
																		}
																		shadow="md"
																		fontWeight="bold"
																		letterSpacing={2}
																		color="whiteAlpha.900"
																		textAlign="center"
																		bg={deptMap[user?.deptID]?.color}
																		bgGradient={
																			"linear(to-tr, " +
																			deptMap[user?.deptID]?.color +
																			", blackAlpha.400)"
																		}
																	/>
																	<Stack spacing="0" color="gray.500">
																		<HStack spacing="1">
																			<Heading
																				color="gray.500"
																				textTransform="uppercase"
																				size="xs"
																				fontWeight="semibold"
																			>
																				{user?.name}
																			</Heading>
																		</HStack>
																		<Text as="i" fontSize="xs">
																			{user?.title +
																				" (" +
																				user?.dept +
																				" - " +
																				user?.region +
																				")"}
																		</Text>
																	</Stack>
																</HStack>
															))}
														</Flex>
													</Box>
												</Collapse>
											</Box>
										))}
								</Stack>
							</Collapse>

							<Flex px="2" w="full">
								<Text fontSize="lg" color="gray.500">
									<span>Congratulations to </span>
									{longevityEmployees?.map(
										(user, j) =>
											j < 5 && (
												<span key={j}>
													{user?.name}
													{j >= 4 ? " " : ", "}
												</span>
											)
									)}
									<span>
										{(longevityEmployees?.length > 4
											? "and " + (longevityEmployees?.length - 5) + " others"
											: "") +
											" who hit a Longevity Milestone in " +
											moment(new Date())?.format("MMMM yyyy") +
											"!"}
									</span>
								</Text>
							</Flex>
						</Stack>

						<Flex w="full" justify="center" py={3}>
							<Button
								// variant="outline"
								onClick={() => setLongevitySummary(!longevitySummary)}
								color="gray.500"
								px={2}
								size="md"
								bg="whiteAlpha.700"
								shadow="md"
								borderWidth={2}
								borderColor="gray.300"
								textTransform="uppercase"
								_hover={{
									color: "blue.500",
									fontWeight: "bold",
									bg: "whiteAlpha.800",
									borderColor: "blue.500",
									shadow: "lg",
									textDecoration: "underline",
									borderWidth: 2,
								}}
							>
								<HStack align="center" spacing={1} key={!longevitySummary ? "Show More" : "Show Less"}>
									<Text
										lineHeight={1}
										className={!longevitySummary ? "fas fa-plus fa-fw" : "fas fa-minus fa-fw"}
										fontSize="sm"
									/>
									<Text lineHeight={1} fontSize="sm" letterSpacing={1} pr={1}>
										{!longevitySummary ? "Show More" : "Show Less"}
									</Text>
								</HStack>
							</Button>
						</Flex>
					</Skeleton>
				</Stack>
			</BasicCard>
		</motion.div>
	);
}
