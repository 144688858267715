import React, { useMemo } from "react";
import {
	Stack,
	HStack,
	Flex,
	Center,
	Box,
	Heading,
	Popover,
	PopoverContent,
	PopoverTrigger,
	PopoverBody,
	PopoverCloseButton,
	Spacer,
	IconButton,
} from "@chakra-ui/react";

export default function BasicCard(props) {
	const cardId = typeof props.title === "string" ? "card-" + props.title.replace(/\s/gm, "").toLowerCase() : props.id;
	const portalStyles = useMemo(() => props.portalStyles ?? null, [props.portalStyles]);

	if (props.variant === "list") {
		return (
			<Box w="full">
				<Flex px={2} py={1}>
					<Box>
						{props.title && (
							<Heading fontSize="md" variant="title" color="gray.500" textTransform="uppercase">
								{props.title}
							</Heading>
						)}
					</Box>
					<Spacer />
					{props.helpPopover && (
						<Popover isLazy placement="right">
							<PopoverTrigger>
								<IconButton variant="ghost" size="small" color="gray.400">
									<i className="fas fa-question-circle"></i>
								</IconButton>
							</PopoverTrigger>
							<PopoverContent
								bg="whiteAlpha.700"
								backdropFilter="blur(3px)"
								p={3}
								textShadow="0px 0px 30px white"
							>
								<PopoverCloseButton />
								<PopoverBody>{props.helpPopover}</PopoverBody>
							</PopoverContent>
						</Popover>
					)}
				</Flex>
				<Box rounded="10" bg={props?.bg ?? "blackAlpha.50"} border="1px" borderColor="blackAlpha.100">
					<Box style={{ padding: "1.2rem" }}>{props.children}</Box>
				</Box>
			</Box>
		);
	} else if (props.variant === "flushed") {
		return (
			<Box>
				<Flex px={4} py={1}>
					<Box>
						{props.title && (
							<Heading fontSize="md" variant="title" color="gray.400" textTransform="uppercase">
								{props.title}
							</Heading>
						)}
					</Box>
					<Spacer />
					{props.helpPopover && (
						<Center>
							<Popover isLazy placement="top">
								<PopoverTrigger>
									<IconButton variant="ghost" size="sm" color="gray.400">
										<i className="fas fa-question-circle"></i>
									</IconButton>
								</PopoverTrigger>
								<PopoverContent
									bg="whiteAlpha.700"
									backdropFilter="blur(3px)"
									p={3}
									textShadow="0px 0px 30px white"
								>
									<PopoverCloseButton />
									<PopoverBody>{props.helpPopover}</PopoverBody>
								</PopoverContent>
							</Popover>
						</Center>
					)}
				</Flex>
				<Box borderRadius="8px">
					<Box p={props.noPadding ? 0 : 4}>{props.children}</Box>
				</Box>
			</Box>
		);
	} else {
		return (
			<Stack spacing={1} id={cardId} w="full" flex={props.flex} maxW={props?.maxW ?? "full"}>
				<Flex w="full" color="gray.500" textShadow="2px 2px 3px white" justify="space-between">
					{Boolean(props?.cardHeader) && props?.cardHeader}
					{Boolean(props?.title || props?.subtitle) && (
						<Stack spacing={1} justify="flex-end">
							{Boolean(props?.title) && (
								<Heading
									fontSize="xl"
									textTransform="uppercase"
									letterSpacing={2}
									color="gray.500"
									textShadow="2px 2px 3px white"
								>
									{props?.title}
								</Heading>
							)}
							{Boolean(props?.subtitle) && (
								<Heading
									fontSize="md"
									textTransform="uppercase"
									letterSpacing={2}
									color="gray.400"
									textShadow="1px 1px 2px white"
								>
									{props?.subtitle}
								</Heading>
							)}
						</Stack>
					)}
					<HStack align="center">
						{Boolean(props.helpPopover) && (
							<Popover isLazy placement="right">
								<PopoverTrigger>
									<IconButton variant="ghost" size="small" color="gray.400">
										<i className="fas fa-question-circle"></i>
									</IconButton>
								</PopoverTrigger>
								<PopoverContent
									bg="whiteAlpha.700"
									backdropFilter="blur(3px)"
									p={3}
									textShadow="0px 0px 30px white"
								>
									<PopoverCloseButton />
									<PopoverBody>{props.helpPopover}</PopoverBody>
								</PopoverContent>
							</Popover>
						)}
						{Boolean(props.cardOptions) && props.cardOptions}
					</HStack>
				</Flex>

				<Box
					rounded="lg"
					shadow={portalStyles?.cardShadow}
					p={portalStyles?.cardPadding}
					borderWidth={portalStyles?.cardBorderWidth}
					borderColor={portalStyles?.cardBorderColor}
					bg={portalStyles?.cardBG}
				>
					{props.children}
				</Box>
			</Stack>
		);
	}
}
