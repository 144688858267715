import React, { useState, useMemo, useCallback } from "react";
import { Switch, Route, useLocation, useParams, Redirect } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import CarouselTabs from "../../../core/Layout/tabs/CarouselTabs";
import HREmployeesPage from "../pages/HREmployeesPage";
import HRReviewsToDoPage from "../pages/HRReviewsPage";
import HRCalendarPage from "../pages/HRCalendarPage";
import HRLongevityPage from "../pages/HRLongevityPage";
import HRReviewsTable from "../components/HRReviewsTable";
import HRTemplatesPage from "../pages/HRTemplatesPage";
import HR_ADMIN_TABS from "../constants/HRAdminTabs";

export var previousTab = 0;

export default function HRAdminRoutes(props) {
	const [viewerMaxHeight, setViewerMaxHeight] = useState(1200);

	let location = useLocation();
	const [[currentTab, direction], setCurrentTab] = useState([props.currentTab, 0]);
	const setTab = useCallback(
		(newTab, newDirection) => {
			if (!newDirection) newDirection = newTab - currentTab;
			previousTab = currentTab;
			setCurrentTab([newTab, newDirection]);
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		},
		[currentTab]
	);

	const getTabDirection = (currentTab = 0) => {
		let tabDirection = 0;
		if (currentTab === 0 && previousTab === 4) {
			tabDirection = 1;
		} else if (currentTab === 4 && previousTab === 0) {
			tabDirection = -1;
		} else {
			tabDirection = currentTab - previousTab;
		}
		return tabDirection;
	};

	return (
		<CarouselTabs
			tabs={HR_ADMIN_TABS}
			currentTab={currentTab}
			direction={getTabDirection(currentTab)}
			setTab={setTab}
			viewerMaxHeight={viewerMaxHeight}
			setViewerMaxHeight={setViewerMaxHeight}
			pageOrientation={HR_ADMIN_TABS[currentTab]?.pageOrientation}
		>
			<Flex direction="column" flex={1} justify="flex-start" w="full" maxH="full">
				<Switch location={location}>
					<Route exact path={`/hr`} children={<HRAdminRouteViews tab={HR_ADMIN_TABS[0]?.tab} />} />
					<Route
						exact
						path={`/hr/:tab`}
						children={<HRAdminRouteViews tab={HR_ADMIN_TABS[currentTab]?.tab} />}
					/>
					<Route
						path={`/hr/:tab/:routeUID`}
						children={<HRAdminRouteViews tab={HR_ADMIN_TABS[currentTab]?.tab} />}
					/>
				</Switch>
			</Flex>
		</CarouselTabs>
	);
}

function HRAdminRouteViews(props) {
	let params = useParams();

	const prepareTab = (tab = null) => {
		if (!["employees", "calendar", "reviews", "forms", "templates", "longevity"]?.includes(tab)) {
			return null;
		} else {
			return tab;
		}
	};

	const prepareUID = (uid = null) => {
		if (!Boolean(uid?.length === 36)) {
			return null;
		} else {
			return uid?.toLowerCase();
		}
	};

	var routeTab = prepareTab(params?.tab);
	var routeUID = prepareUID(params?.routeUID);

	const tab = useMemo(() => {
		return prepareTab(props.tab);
	}, [props.tab]);

	return (
		<Flex flex={1} w="full" direction="row">
			<Flex direction={"column"} w="full" flex={1}>
				{Boolean(tab) && tab !== routeTab ? (
					<Redirect push to={`/hr/${tab}`} />
				) : ["employees, calendar, longevity"].includes(routeTab) && Boolean(routeUID) ? (
					<Redirect push to={`/hr/${routeTab}`} />
				) : ["employees"].includes(routeTab) ? (
					<HREmployeesPage tab={routeTab} />
				) : ["calendar"].includes(routeTab) ? (
					<HRCalendarPage tab={routeTab} />
				) : ["longevity"].includes(routeTab) ? (
					<HRLongevityPage tab={routeTab} />
				) : ["reviews"].includes(routeTab) ? (
					<HRReviewsToDoPage tab={routeTab} routeUID={routeUID} />
				) : ["forms"].includes(routeTab) ? (
					<HRReviewsTable tab={routeTab} />
				) : ["templates"].includes(routeTab) ? (
					<HRTemplatesPage tab={routeTab} formUID={routeUID} />
				) : (
					<Box>Something went wrong - there is an issue with the Tab or FormUID</Box>
				)}
			</Flex>
		</Flex>
	);
}
