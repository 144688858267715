export default class Phase {
	constructor(phase) {
		this.queryData = phase?.queryData ?? phase?.QueryData ?? "JCPhase";
		this.jcpmKeyID = phase?.jcpmKeyID ?? phase?.JCPMKeyID ?? null;

		this.phaseGroup = phase?.phaseGroup ?? phase?.PhaseGroup ?? null;
		this.tradeID = phase?.tradeID ?? phase?.TradeID ?? null;
		this.phaseID = phase?.phaseID ?? phase?.PhaseID ?? null;

		this.phase = phase?.phase ?? phase?.Phase ?? null;
		this.description = phase?.description ?? phase?.Description ?? null;
		this.costType = phase?.costType ?? phase?.CostType ?? null;
		this.um = phase?.um ?? phase?.UM ?? null;

		this.scope = phase?.scope ?? phase?.Scope ?? "GENL";
		this.manpowerScope = phase?.manpowerScope ?? phase?.ManpowerScope ?? null;
		this.reportCategory = phase?.reportCategory ?? phase?.ReportCategory ?? null;
		this.status = phase?.status ?? phase?.Status ?? "Standard";

		this.preFabKeyID = phase?.preFabKeyID ?? phase?.PreFabKeyID ?? null;
		this.preFabPhase = phase?.preFabPhase ?? phase?.PreFabPhase ?? null;
		this.preFabFactor = phase?.preFabFactor ?? phase?.PreFabFactor ?? null;

		this.notes = phase?.notes ?? phase?.Notes ?? null;
		this.version = phase?.version ?? phase?.Version ?? "2021";
	}
}
