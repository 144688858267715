import React, { useMemo } from "react";
import { Flex, useBoolean, Collapse } from "@chakra-ui/react";
import SectionHeader from "./SectionHeader";
import {
	useTable,
	useResizeColumns,
	useGroupBy,
	useExpanded,
	useGlobalFilter,
	useFilters,
	useSortBy,
	useBlockLayout,
	useColumnOrder,
	useRowState,
} from "react-table";
import { useExportData } from "react-table-plugins";
import getExportFileBlob from "../functions/getExportFileBlob";
import getFilterTypes from "../functions/getFilterTypes";
import TextInputColumnFilter from "../inputs/TextInputFilter";
import VirtualTable from "../table/VirtualTable";
import SearchBar from "../buttons/SearchBar";
import SearchButton from "../buttons/SearchButton";
import SizesButton from "../buttons/SizesButton";
import FilterButton from "../buttons/FilterButton";
import ExportButton from "../buttons/ExportButton";
import SettingsButton from "../buttons/SettingsButton";
import PrintButton from "../../Reports/pdf/PrintPDFButton";
import { log } from "../../../helperFunctions";

export default function VirtualSection({
	page,
	subSection,
	data,
	columns,
	initialState,
	skipPageReset,
	updateMyData,
	setSelectedRow,
}) {
	const [collapseIsOpen, setCollapseIsOpen] = useBoolean(true);
	const [searchIsOpen, setSearchIsOpen] = useBoolean(false);
	const [sizesIsOpen, setSizesIsOpen] = useBoolean(false);
	const [filterIsOpen, setFilterIsOpen] = useBoolean(false);
	const [settingsIsOpen, setSettingsIsOpen] = useBoolean(false);

	const filterTypes = useMemo(() => getFilterTypes(), []);

	// Default Column Settings
	const defaultColumn = useMemo(
		() => ({
			Filter: TextInputColumnFilter,
			filter: "textNoCaps",
			sortIcon: "alpha",
			justify: "center",
			align: "center",
			show: true,
			// maxWidth: 150,
			minWidth: 135,
		}),
		[]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		footerGroups,
		prepareRow,
		rows,
		state,
		preGlobalFilteredRows,
		setGlobalFilter,
		exportData,
		allColumns,
		// setHiddenColumns,
		// toggleAllRowsExpanded,
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			getExportFileBlob,
			filterTypes,
			initialState: initialState,
			autoResetPage: !skipPageReset,
			updateMyData,
		},
		useBlockLayout,
		useColumnOrder,
		useResizeColumns,
		useFilters,
		useGlobalFilter,
		useGroupBy,
		useSortBy,
		useRowState,
		useExportData,
		useExpanded
	);

	const buttons = useMemo(() => subSection?.buttons, [subSection?.buttons]);

	const searchBar = useMemo(() => {
		return (
			<SearchBar
				searchIsOpen={searchIsOpen && collapseIsOpen}
				preGlobalFilteredRows={preGlobalFilteredRows}
				globalFilter={state.globalFilter}
				setGlobalFilter={setGlobalFilter}
				rowCount={rows?.length ?? 0}
			/>
		);
	}, [collapseIsOpen, preGlobalFilteredRows, rows?.length, searchIsOpen, setGlobalFilter, state.globalFilter]);

	const searchButton = useMemo(() => {
		return <SearchButton searchIsOpen={searchIsOpen} setSearchIsOpen={setSearchIsOpen} />;
	}, [searchIsOpen, setSearchIsOpen]);

	const sizesButton = useMemo(() => {
		return <SizesButton sizesIsOpen={sizesIsOpen} setSizesIsOpen={setSizesIsOpen} />;
	}, [sizesIsOpen, setSizesIsOpen]);

	const filterButton = useMemo(() => {
		return <FilterButton filterIsOpen={filterIsOpen} setFilterIsOpen={setFilterIsOpen} />;
	}, [filterIsOpen, setFilterIsOpen]);

	const exportButton = useMemo(() => {
		return <ExportButton exportData={exportData} />;
	}, [exportData]);

	const printButton = useMemo(() => {
		return <PrintButton page={page} />;
	}, [page]);

	const settingsButton = useMemo(() => {
		return (
			<SettingsButton
				settingsIsOpen={settingsIsOpen}
				setSettingsIsOpen={setSettingsIsOpen}
				allColumns={allColumns}
			/>
		);
	}, [setSettingsIsOpen, settingsIsOpen, allColumns]);

	const headerButtons = useMemo(
		() => [buttons, searchButton, filterButton, exportButton, printButton, settingsButton],
		[buttons, searchButton, filterButton, exportButton, printButton, settingsButton]
	);

	log("data", data);
	log("columns", rows);

	return (
		<Flex id="VirtualSection" direction="column" w="full" flex={1} h="full" rounded="md">
			<SectionHeader
				page={page}
				section={subSection}
				collapseIsOpen={collapseIsOpen}
				setCollapseIsOpen={setCollapseIsOpen}
				headerButtons={headerButtons}
				searchBar={searchIsOpen && searchBar}
			/>
			<Collapse
				in={collapseIsOpen}
				animateOpacity
				style={{
					display: "flex",
					flex: 1,
					flexDirection: "row",
					width: "100%",
					justifyContent: "flex-start",
					alignItems: "flex-start",
					borderRadius: "var(--chakra-radii-md)",
				}}
			>
				{subSection.type === "virtualTable" && (
					<VirtualTable
						subSection={subSection}
						getTableProps={getTableProps}
						getTableBodyProps={getTableBodyProps}
						headerGroups={headerGroups}
						footerGroups={footerGroups}
						prepareRow={prepareRow}
						rows={rows}
						setSelectedRow={setSelectedRow}
						updateMyData={updateMyData}
						sizesIsOpen={sizesIsOpen}
						filterIsOpen={filterIsOpen}
					/>
				)}
			</Collapse>
		</Flex>
	);
}
