import React from "react";
import { Input, HStack } from "@chakra-ui/react";

export default function NumberRangeFilter({ column: { filterValue = [], preFilteredRows, setFilter, id } }) {
	// const [min, max] = useMemo(() => {
	// 	let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
	// 	let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
	// 	preFilteredRows?.forEach((row) => {
	// 		min = Math.min(row.values[id], min);
	// 		max = Math.max(row.values[id], max);
	// 	});
	// 	return [min, max];
	// }, [id, preFilteredRows]);

	return (
		<HStack align="center" spacing={1}>
			<Input
				size="sm"
				bg="white"
				borderColor="gray.200"
				rounded={5}
				value={filterValue[0] || ""}
				type="number"
				onChange={(e) => {
					const val = e.target.value;
					setFilter((old = []) => [val ? parseFloat(val, 10) : undefined, old[1]]);
				}}
				placeholder={`Min`}
			/>

			<Input
				size="sm"
				bg="white"
				borderColor="gray.200"
				rounded={5}
				value={filterValue[1] || ""}
				type="number"
				onChange={(e) => {
					const val = e.target.value;
					setFilter((old = []) => [old[0], val ? parseFloat(val, 10) : undefined]);
				}}
				placeholder={`Max`}
			/>
		</HStack>
	);
}
