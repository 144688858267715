import { portalUser } from "../../../App";
import { convertArrayToMap } from "../../../helperFunctions";

export default class FormCommenter {
	constructor(commenter) {
		if (commenter instanceof FormCommenter) {
			Object.assign(this, commenter);
		} else {
			this.queryData = commenter?.queryData ?? "FormCommenter";
			this.keyID = commenter?.keyID ?? null;
			this.formUID = commenter?.formUID?.toLowerCase() ?? null;
			this.employeeUID = (commenter?.employeeUID ?? portalUser.user?.employeeUID)?.toLowerCase();
			this.commenterName = commenter?.commenterName ?? null;
			this.commenterStatus = commenter?.commenterStatus ?? null;
		}
		this.getName();
	}

	getName() {
		let usersMap = convertArrayToMap(
			portalUser?.userData?.filter((d) => d.queryData === "User"),
			"employeeUID"
		);
		this.commenterName = usersMap[this.employeeUID]?.name;
	}

	async insertFormCommenter() {
		if (Boolean(this.employeeUID) && !Boolean(this.keyID)) {
			let params = { formUID: this.formUID, employeeUID: this.employeeUID };
			let data = { formUID: this.formUID, employeeUID: this.employeeUID };

			let result = await portalUser.formData?.formsAPI?.InsertFormCommenter(params, data);
			if (result?.status === 200 && result?.value?.length > 0) {
				this.keyID = result?.value[0]?.keyID ?? null;
				this.commenterStatus = result?.value[0]?.commenterStatus ?? null;

				if (!Boolean(this.keyID)) {
					this.error = true;
				} else {
					this.getName();
					portalUser.formData.commenters.push(this);
				}

				return this;
			} else {
				this.error = true;
				return this;
			}
		} else {
			return null;
		}
	}

	async updateFormCommenter(attr = null, value = this.value) {
		value = value?.toString();
		if (Boolean(attr) && this[attr] !== value) {
			if (typeof value === "string") {
				value = value?.replace(/'/gm, "`");
			}
			this[attr] = value;
			let params = { formUID: this.formUID, employeeUID: this.employeeUID };
			let update = {};
			if (attr === "value") {
				update = { Value: value };
			} else if (attr === "commenterStatus") {
				if (value === "1") {
					value = 1;
				} else {
					value = 0;
				}
				update = { Status: value };
			}
			let result = await portalUser.formData?.formsAPI.UpdateFormCommenter(params, update);

			// let lastUpdated = new Date();
			if (result?.status === 200) {
				// let update = {
				// 	DateModified: formatValue(lastUpdated, 0, "datetimeSQL"),
				// 	ModifiedBy: portalUser.user?.employeeUID,
				// };
				// portalUser.formData?.formsAPI.UpdateForm(params, update);
				// this.lastUpdated = formatValue(lastUpdated, 0, "moment");
				this.getName();
				return this;
			} else {
				this.error = true;
				return this;
			}
		} else {
			return this;
		}
	}

	async deleteFormCommenter() {
		if (Boolean(this.employeeUID) && Boolean(this.keyID)) {
			let params = { formUID: this.formUID, employeeUID: this.employeeUID };
			let result = await portalUser.formData?.formsAPI?.DeleteFormCommenter(params);

			if (result?.status === 200 && result?.value?.length === 0) {
				let commmenters = portalUser.formData.commenters;
				commmenters =
					commmenters?.filter((d) => d.formUID + d.employeeUID !== this.formUID + this.employeeUID) ?? [];
				portalUser.formData.commenters = commmenters;

				return null;
			} else {
				this.error = true;
				return this;
			}
		} else {
			return null;
		}
	}
}
