import PayrollAPI from "../../../APIs/PayrollAPI";
import { log } from "../../../helperFunctions";
import { formatDate } from "../../../helperFunctions";
import moment from "moment";

export class EmployeeHistoryData extends Array {
	constructor(employee) {
		super();
		if (Boolean(employee)) {
			let employeeMap = new EmployeeHistoryList();
			let keyID = 0;
			let oldRate = 0;
			let oldSalary = 0;
			let description = "Starting Salary";

			for (let i = 0; i < employee?.length; i++) {
				let employeeRow = new EmployeeHistoryRow(employee[i]);

				if (employeeRow.keyID !== keyID) {
					oldRate = 0;
					oldSalary = 0;
				}

				employeeRow.oldRate = oldRate;
				employeeRow.oldSalary = oldSalary;
				if (employeeRow.newRate === null) {
					employeeRow.newRate = oldRate;
				}
				if (employeeRow.newSalary === null) {
					employeeRow.newSalary = oldSalary;
				}

				if (employeeRow.keyID !== keyID) {
					description = "Starting Salary";
				} else if (employeeRow.oldSalary === employeeRow.newSalary) {
					description = "No Change";
				} else if (employeeRow.oldSalary < employeeRow.newSalary) {
					description = "Salary Increase";
				} else if (employeeRow.oldSalary > employeeRow.newSalary) {
					description = "Salary Decrease";
				}
				employeeRow.description = description;
				oldRate = employeeRow.newRate;
				oldSalary = employeeRow.newSalary;

				if (employeeRow.keyID !== keyID) {
					keyID = employeeRow.keyID;
				}

				if (!Boolean(employeeMap[keyID])) {
					employeeMap[keyID] = employeeRow;
				}
				employeeMap[keyID].history.push(new HistoryRow(employeeRow));
			}

			let employeeHistory = new EmployeeHistoryList();
			Object.keys(employeeMap ?? {})?.map((key, i) =>
				employeeHistory.push(employeeMap[key])
			);

			for (let i = 0; i < employeeHistory?.length; i++) {
				let history = employeeHistory[i].history.reverse();
				employeeHistory[i].history = history;
				employeeHistory[i].description =
					history[0]?.description ?? null;
				employeeHistory[i].year = history[0].year;
				employeeHistory[i].oldRate = history[0].oldRate;
				employeeHistory[i].oldSalary = history[0].oldSalary;
				employeeHistory[i].newRate = history[0].newRate;
				employeeHistory[i].newSalary = history[0].newSalary;
				employeeHistory[i].increaseDate =
					history[0]?.increaseDate ?? null;
				employeeHistory[i].comments = history[0]?.comments ?? null;
				employeeHistory[i].bonusYE = history[1]?.bonusYE ?? 0;
				employeeHistory[i].bonusDate = history[1]?.bonusDate ?? null;
			}

			return employeeHistory;
		}
	}
}

export class EmployeeHistoryList extends Array {
	constructor(employee) {
		super();
		if (Boolean(employee)) {
			for (let i = 0; i < employee?.length; i++) {
				let employeeRow = new EmployeeHistoryRow(employee[i]);
				this.push(employeeRow);
			}
		}
	}

	getProposalYear() {
		let year = (this[0]?.proposalYear ?? moment(new Date()).year()) + 1;
		return year;
	}

	sortEmployees() {
		let sortedArr = this;
		sortedArr.sort(function (a, b) {
			return (
				a.deptGroupID - b.deptGroupID ||
				a.parentDeptOrder - b.parentDeptOrder ||
				a.deptOrder - b.deptOrder ||
				a.accessLevel - b.accessLevel ||
				a.execOrder - b.execOrder ||
				b.totalDeptCount - a.totalDeptCount ||
				b.parentDeptCount - a.parentDeptCount ||
				b.childDeptCount - a.childDeptCount ||
				a.regionID - b.regionID ||
				b.longevityMths - a.longevityMths ||
				a.prco - b.prco
			);
		});
		return sortedArr;
	}

	createDeptArr(regionArr) {
		let deptMap = new DepartmentList();
		let employeesArr = this.sortEmployees();
		for (let i = 0; i < employeesArr?.length; i++) {
			let keyID = employeesArr[i].deptID;
			if (!Boolean(deptMap[keyID])) {
				deptMap[keyID] = new DepartmentRow(employeesArr[i]);
				deptMap[keyID].getRegions(regionArr);
			}
		}

		let departmentMap = new DepartmentList();
		Object.keys(deptMap ?? {})?.map((key, i) =>
			departmentMap.push(deptMap[key])
		);
		departmentMap.sort(function (a, b) {
			return (
				a.deptGroupID - b.deptGroupID ||
				a.parentDeptOrder - b.parentDeptOrder ||
				a.deptOrder - b.deptOrder
			);
		});
		departmentMap[0].dept = "All Departments";
		departmentMap[0].parentDept = "All Departments";

		return departmentMap;
	}

	addDepartments(deptArr) {
		for (let i = 0; i < this?.length; i++) {
			this[i].addDepartments(deptArr);
		}
		return this;
	}

	createDepartmentMap(attr) {
		let deptMap = new DeptMap();
		for (let i = 0; i < this?.length; i++) {
			let keyID = this[i][attr];

			if (!Boolean(deptMap[keyID])) {
				deptMap[keyID] = new DeptMapRow(this[i]);
			}
			deptMap[keyID].rows.push(this[i]);
		}

		let departmentMap = new DeptMap();
		Object.keys(deptMap ?? {})?.map((key, i) =>
			departmentMap.push(deptMap[key])
		);
		departmentMap.sort(function (a, b) {
			return (
				a.deptGroupID - b.deptGroupID ||
				a.parentDeptOrder - b.parentDeptOrder ||
				a.deptOrder - b.deptOrder ||
				a.regionID - b.regionID
			);
		});

		return departmentMap;
	}

	applyLeaderAccess(leaderAccess) {
		for (let i = 0; i < this?.length; i++) {
			let employeeArr = leaderAccess?.filter(
				(data) => data?.employeeUID === this[i]?.employeeUID
			);
			for (let j = 0; j < this[i]?.departments?.length; j++) {
				let deptArr = employeeArr?.filter(
					(data) =>
						parseInt(data?.deptID) ===
						parseInt(this[i]?.departments[j]?.deptID)
				);

				for (
					let k = 0;
					k < this[i]?.departments[j]?.regions?.length;
					k++
				) {
					let regionArr = deptArr?.filter(
						(data) =>
							parseInt(data?.regionID) ===
							parseInt(
								this[i]?.departments[j]?.regions[k]?.regionID
							)
					);
					if (regionArr?.length > 0) {
						this[i]?.departments[j]?.regions[k]?.setAccess();
					}
				}
				let regionAccessArr = this[i]?.departments[j]?.regions?.filter(
					(data) => data?.hasAccess === true
				);
				if (regionAccessArr?.length > 0) {
					this[i]?.departments[j]?.setAccess();
				}
			}
		}
		return this;
	}

	getEmployeeHistoryTotals() {
		let totalsRow = new EmployeeHistoryRow();
		for (let i = 0; i < this.length; i++) {
			totalsRow.addRow(this[i]);
		}
		return totalsRow;
	}

	getEmployeeHistoryTotalsSummary() {
		let totalsRow = new EmployeeHistoryRow();
		for (let i = 0; i < this.length; i++) {
			totalsRow.addRowSummary(this[i]);
		}
		return totalsRow;
	}

	sortEmployeesByName() {
		let sortedArr = this;
		sortedArr.sort(function (a, b) {
			return a.lastName.localeCompare(b.lastName);
		});
		return sortedArr;
	}

	createEmployeeMap() {
		let employeeMap = new EmployeeHistoryList();

		for (let i = 0; i < this?.length; i++) {
			let keyID = this[i].employeeUID;
			if (!Boolean(employeeMap[keyID])) {
				employeeMap[keyID] = new EmployeeHistoryRow(this[i]);
			}
		}

		let employeeList = new EmployeeHistoryList();
		Object.keys(employeeMap ?? {})?.map((key, i) =>
			employeeList.push(employeeMap[key])
		);
		employeeList.sortEmployees();

		return employeeList;
	}
}

export class EmployeeHistoryRow {
	constructor(employeeRow) {
		this.payrollAPI = new PayrollAPI();
		this.keyID = Number(employeeRow?.KeyID ?? employeeRow?.keyID) ?? 0;
		this.employeeUID =
			employeeRow?.EmployeeUID ?? employeeRow?.employeeUID ?? null;
		this.prco = Number(employeeRow?.PRCo ?? employeeRow?.prco) ?? null;
		this.employee = employeeRow?.Employee ?? employeeRow?.employee ?? null;
		this.firstName =
			employeeRow?.FirstName ?? employeeRow?.firstName ?? null;
		this.lastName = employeeRow?.LastName ?? employeeRow?.lastName ?? null;

		this.title = employeeRow?.Title ?? employeeRow?.title ?? null;
		this.longevityMths =
			Number(employeeRow?.LongevityMths ?? employeeRow?.longevityMths) ??
			0;
		this.hireDate =
			formatDate(employeeRow?.HireDate) ??
			formatDate(employeeRow?.hireDate) ??
			null;
		this.employeeType =
			employeeRow?.EmployeeType ?? employeeRow?.employeeType ?? null;

		this.prDeptType =
			employeeRow?.PRDeptType ?? employeeRow?.prDeptType ?? 0;
		this.jobCharge = employeeRow?.JobCharge ?? employeeRow?.jobCharge ?? 0;

		this.reviewerUID =
			employeeRow?.ReviewerUID ?? employeeRow?.reviewerUID ?? null;
		this.reviewerName =
			employeeRow?.ReviewerName ??
			employeeRow?.reviewerName ??
			"No Reviewer Assigned";
		this.managerUID =
			employeeRow?.ManagerUID ?? employeeRow?.managerUID ?? null;
		this.managerName =
			employeeRow?.ManagerName ??
			employeeRow?.managerName ??
			"No Manager Assigned";

		this.regionID = employeeRow?.RegionID ?? employeeRow?.regionID ?? null;
		this.region = employeeRow?.Region ?? employeeRow?.region ?? null;

		this.deptGroupID =
			Number(employeeRow?.DeptGroupID ?? employeeRow?.deptGroupID) ??
			null;
		this.deptGroup =
			employeeRow?.DeptGroup ?? employeeRow?.deptGroup ?? null;
		this.deptGroupDesc =
			employeeRow?.DeptGroupDesc ?? employeeRow?.deptGroupDesc ?? null;
		this.deptGroupIcon =
			employeeRow?.DeptGroupIcon ?? employeeRow?.deptGroupIcon ?? null;
		this.deptGroupColor =
			employeeRow?.DeptGroupColor ?? employeeRow?.deptGroupColor ?? null;

		this.parentDeptOrder =
			Number(
				employeeRow?.ParentDeptOrder ?? employeeRow?.parentDeptOrder
			) ?? null;
		this.parentDeptID =
			Number(employeeRow?.ParentDeptID ?? employeeRow?.parentDeptID) ??
			null;
		this.parentDept =
			employeeRow?.ParentDept ?? employeeRow?.parentDept ?? null;
		this.parentDeptIcon =
			employeeRow?.ParentDeptIcon ?? employeeRow?.parentDeptIcon ?? null;
		this.parentDeptColor =
			employeeRow?.ParentDeptColor ??
			employeeRow?.parentDeptColor ??
			null;

		this.deptOrder =
			Number(employeeRow?.DeptOrder ?? employeeRow?.deptOrder) ?? null;
		this.deptID =
			Number(employeeRow?.DeptID ?? employeeRow?.deptID) ?? null;
		this.dept = employeeRow?.Dept ?? employeeRow?.dept ?? null;
		this.deptColor =
			employeeRow?.DeptColor ?? employeeRow?.deptColor ?? null;
		this.deptIcon = employeeRow?.DeptIcon ?? employeeRow?.deptIcon ?? null;
		this.showRegions =
			employeeRow?.ShowRegions ?? employeeRow?.showRegions ?? false;
		this.showTrades =
			employeeRow?.ShowTrades ?? employeeRow?.showTrades ?? false;

		this.accessLevel =
			employeeRow?.AccessLevel ?? employeeRow?.accessLevel ?? 5;

		this.totalDeptCount =
			Number(
				employeeRow?.TotalDeptCount ?? employeeRow?.totalDeptCount
			) ?? 0;
		this.parentDeptCount =
			Number(
				employeeRow?.ParentDeptCount ?? employeeRow?.parentDeptCount
			) ?? 0;
		this.childDeptCount =
			Number(
				employeeRow?.ChildDeptCount ?? employeeRow?.childDeptCount
			) ?? 0;
		this.execOrder =
			Number(employeeRow?.ExecOrder ?? employeeRow?.execOrder) ?? 0;

		this.proposalYear =
			Number(employeeRow?.ProposalYear ?? employeeRow?.proposalYear) ??
			moment(new Date()).year();
		this.proposedSalary =
			employeeRow?.ProposedSalary ?? employeeRow?.proposedSalary ?? 0;
		this.proposedBonus =
			employeeRow?.ProposedBonus ?? employeeRow?.proposedBonus ?? 0;
		this.proposedCell =
			employeeRow?.ProposedCell ??
			employeeRow?.proposedCell ??
			employeeRow?.CellPhoneAllowance ??
			0;
		this.proposedVehicle =
			employeeRow?.ProposedVehicle ??
			employeeRow?.proposedVehicle ??
			employeeRow?.VehicleAllowance ??
			0;
		this.proposedHousing =
			employeeRow?.ProposedHousing ??
			employeeRow?.proposedHousing ??
			employeeRow?.HousingAllowance ??
			0;
		this.managerNotes =
			employeeRow?.ManagerNotes ?? employeeRow?.managerNotes ?? null;
		this.cellPhoneAllowance =
			employeeRow?.CellPhoneAllowance ??
			employeeRow?.cellPhoneAllowance ??
			0;
		this.vehicleAllowance =
			employeeRow?.VehicleAllowance ?? employeeRow?.vehicleAllowance ?? 0;
		this.housingAllowance =
			employeeRow?.HousingAllowance ?? employeeRow?.housingAllowance ?? 0;

		this.year = Number(employeeRow?.Year ?? employeeRow?.year) ?? null;
		this.description = employeeRow?.description ?? null;
		this.oldRate = 0;
		this.oldSalary = 0;
		this.newRate = employeeRow?.NewRate ?? employeeRow?.newRate ?? null;
		this.newSalary =
			employeeRow?.NewSalary ?? employeeRow?.newSalary ?? null;
		this.increaseDate =
			formatDate(employeeRow?.IncreaseDate) ??
			formatDate(employeeRow?.increaseDate) ??
			null;
		this.comments = employeeRow?.Comments ?? employeeRow?.comments ?? null;
		this.bonusYE = employeeRow?.BonusYE ?? employeeRow?.bonusYE ?? 0;
		this.bonusDate =
			formatDate(employeeRow?.BonusDate) ??
			formatDate(employeeRow?.bonusDate) ??
			null;

		this.locked = employeeRow?.locked ?? "Y";
		this.showEmployee = true;

		this.history = employeeRow?.history ?? [];
		this.departments =
			new DepartmentList(employeeRow?.departments) ??
			new DepartmentList();

		this.employeeName = this.getEmployeeName();
		this.subheading = this.getSubheading();
		this.deptByRegion = this.getDeptByRegion();

		this.employeeCount = 0;
		this.hasProposal = 0;
		this.totalYearlyComp = this.getTotalYearlyComp();
	}

	getTotalYearlyComp() {
		let totalYearlyComp =
			((this.proposedSalary ?? 0) === 0
				? this.newSalary
				: this.proposedSalary) +
			(this.proposedBonus ?? 0) +
			((this.proposedCell ?? 0) +
				(this.proposedVehicle ?? 0) +
				(this.proposedHousing ?? 0)) *
				12;
		return totalYearlyComp;
	}

	getDeptByRegion() {
		let deptByRegion = null;
		if (Boolean(this.dept) && Boolean(this.region)) {
			if (this.showRegions) {
				deptByRegion = this.dept + " " + this.region;
			} else {
				deptByRegion = this.dept;
			}
		}
		return deptByRegion;
	}

	getEmployeeName() {
		let name = null;
		if (this.firstName !== null && this.lastName !== null) {
			//name = (this.lastName + ', ' + this.firstName );
			name = this.firstName + " " + this.lastName;
		}
		return name;
	}
	getSubheading() {
		let subheading = null;
		if (this.title !== null && this.dept !== null && this.region !== null) {
			subheading = this.title + " - " + this.dept + " - " + this.region;
		}
		return subheading;
	}

	getLongevity() {
		let longevityMths = this.longevityMths ?? 0;
		let longevityYears = parseInt(this.longevityMths / 12);
		let remainingMths = longevityMths - longevityYears * 12;
		let longevity = "";
		if (longevityYears > 0) {
			if (longevityYears === 1) {
				longevity = longevityYears + " yr ";
			} else {
				longevity = longevityYears + " yrs ";
			}
		}
		if (remainingMths > 0) {
			if (remainingMths === 1) {
				longevity += remainingMths + " mth";
			} else {
				longevity += remainingMths + " mths";
			}
		}

		return longevity;
	}

	getPctIncrease(v1 = 0, v2 = 0) {
		let pctIncrease = 0;
		let increase = 0;
		if (v1 !== 0 && v2 !== 0) {
			pctIncrease = ((v1 - v2) / v2) * 100;
			increase = v1 - v2;
		}

		let color = "gray.400";
		let symbol = "";
		if (pctIncrease > 0) {
			color = "green.600";
			symbol = "+";
		} else if (pctIncrease < 0) {
			color = "red.600";
		}
		let pctIncreaseArr = {
			pctIncrease: pctIncrease,
			color: color,
			symbol: symbol,
			increase: increase,
		};
		return pctIncreaseArr;
	}

	getProposedRate() {
		let proposedSalary = this.proposedSalary ?? 0;
		let proposedRate = proposedSalary / 2080;
		return proposedRate;
	}

	addRow(row) {
		this.oldRate += row.oldRate ?? 0;
		this.oldSalary += row.oldSalary ?? 0;
		this.newRate += row.newRate ?? 0;
		this.newSalary += row.newSalary ?? 0;
		this.bonusYE += row.bonusYE ?? 0;

		this.proposedSalary += row.proposedSalary ?? 0;
		this.proposedBonus += row.proposedBonus ?? 0;
		this.proposedCell += row.proposedCell ?? 0;
		this.proposedVehicle += row.proposedVehicle ?? 0;
		this.proposedHousing += row.proposedHousing ?? 0;
		this.totalYearlyComp += row.getTotalYearlyComp() ?? 0;
	}

	addRowSummary(row) {
		this.oldRate += row.oldRate ?? 0;
		this.oldSalary += row.oldSalary ?? 0;
		this.newRate += row.newRate ?? 0;
		this.newSalary += row.newSalary ?? 0;
		this.bonusYE += row.bonusYE ?? 0;

		this.proposedSalary += row.proposedSalary
			? row.proposedSalary
			: row.newSalary;
		this.employeeCount += 1;
		this.hasProposal += row.proposedSalary ? 1 : 0;
		this.proposedBonus += row.proposedBonus ?? 0;
		this.proposedCell += row.proposedCell ?? 0;
		this.proposedVehicle += row.proposedVehicle ?? 0;
		this.proposedHousing += row.proposedHousing ?? 0;
	}

	addDepartments(parentDeptArr) {
		this.departments = new DepartmentList(parentDeptArr);
	}

	setShowEmployee(value = false) {
		this.showEmployee = value;
	}

	async updateEmployeeRow(attr, value) {
		if (attr === "proposedPctIncrease") {
			attr = "proposedSalary";
			let salary = this.newSalary ?? 0;
			let pct = (value ?? 0) / 100;
			value = pct * salary + salary;
		}
		this[attr] = value;
		let dbAttr = this.getDBAttr(attr);
		let update = { [dbAttr]: value };
		await this.updateDB(update);
	}

	getDBAttr(attr) {
		if (attr === "proposedSalary") {
			return "udProposedSalary";
		} else if (attr === "proposedBonus") {
			return "udProposedBonus";
		} else if (attr === "proposedCell") {
			return "udProposedCell";
		} else if (attr === "proposedVehicle") {
			return "udProposedVehicle";
		} else if (attr === "proposedHousing") {
			return "udProposedHousing";
		} else if (attr === "managerNotes") {
			return "udManagerNotes";
		}
	}

	async updateDB(updates) {
		let updateResult = await this.payrollAPI.UpdateEmployeeHistory(
			this.keyID,
			updates
		);
		log("DATABASE UPDATE", updateResult);
	}
}

export class AccessMap extends Array {
	constructor(access) {
		super();
		if (Boolean(access)) {
			for (let i = 0; i < access?.length; i++) {
				this.push(new AccessRow(access[i]));
			}
		}
	}

	getAccessLevel() {
		for (let i = 0; i < this?.length; i++) {
			this[i].getAccessLevel();
		}
	}

	getEmployeeAccess(employeeUID) {
		let accessLevels = this.filter(
			(data) => data?.employeeUID === employeeUID
		);
		if (accessLevels?.length === 0) {
			accessLevels = { employeeUID: employeeUID, accessLevel: 5 };
		}

		let accessLevel = new AccessMap(accessLevels);
		accessLevel.getAccessLevel();
		return accessLevel;
	}

	createLevelsMap() {
		let levelsArr = new LevelsList();
		levelsArr.push(new LevelsRow({ accessLevel: 0 }));

		for (let i = 0; i < this?.length; i++) {
			let keyID = this[i].accessLevel;

			if (!Boolean(levelsArr[keyID])) {
				levelsArr[keyID] = new LevelsRow(this[i]);
			}

			levelsArr[keyID].employees.push(new EmployeeHistoryRow(this[i]));
		}

		let levelsList = new LevelsList();
		Object.keys(levelsArr ?? {})?.map((key, i) =>
			levelsList.push(levelsArr[key])
		);
		levelsList.sort(function (a, b) {
			return a.accessLevel - b.accessLevel;
		});

		return levelsList;
	}
}

export class AccessRow {
	constructor(row) {
		this.employeeUID = row?.EmployeeUID ?? row?.employeeUID ?? null;
		this.deptGroupID = Number(row?.DeptGroupID ?? row?.deptGroupID) ?? null;
		this.parentDeptID =
			Number(row?.ParentDeptID ?? row?.parentDeptID) ?? null;
		this.deptID = Number(row?.DeptID) ?? row?.deptID ?? null;
		this.regionID = Number(row?.RegionID) ?? row?.regionID ?? null;
		this.showHistory = row?.ShowHistory ?? row.showHistory ?? null;
		this.accessLevel = Number(row?.AccessLevel ?? row?.accessLevel) ?? null;
		this.accessLevelDesc = row?.accessLevelDesc ?? null;
		this.accessLevelSubheading = row?.accessLevelSubheading ?? null;
		this.accessLevelLongDesc = row?.accessLevelLongDesc ?? null;
		this.accessLevelIcon = row?.accessLevelIcon ?? null;
	}

	getAccessLevel() {
		if (this.accessLevel === 0) {
			this.accessLevelDesc = "All Access Levels";
			this.accessLevelSubheading = "View users with any level of access";
			this.accessLevelLongDesc =
				"This option lets you view all users with any level of access";
			this.accessLevelIcon = "fas fa-universal-access";
		} else if (this.accessLevel === 1) {
			this.accessLevelDesc = "Full Executive Access";
			this.accessLevelSubheading =
				"Can view regional summaries, approve salaries & bonuses for all departments and regions, and edit user access";
			this.accessLevelSubheading =
				"Can view regional summaries, approve salaries & bonuses for all departments and regions, and edit user access";
			this.accessLevelIcon = "fas fa-shield-alt";
			// this.accessLevelIcon = 'fas fa-user-tie'
		} else if (this.accessLevel === 2) {
			this.accessLevelDesc = "Regional Leader Access";
			this.accessLevelSubheading =
				"Can view salaries & bonuses for all departments in a specified region";
			this.accessLevelLongDesc =
				"Can view salaries & bonuses for all departments in a specified region";
			this.accessLevelIcon = "fas fa-user-tie";
		} else if (this.accessLevel === 3) {
			this.accessLevelDesc = "Department Leader Access";
			this.accessLevelSubheading =
				"Can view salaries & bonuses for employees in specified departments and regions";
			this.accessLevelLongDesc =
				"Can view salaries & bonuses for employees in specified departments and regions";
			this.accessLevelIcon = "fas fa-users";
		} else if (this.accessLevel === 4) {
			this.accessLevelDesc = "Department Manager Access";
			this.accessLevelSubheading =
				"Can add notes for employees in a specified departments and regions, cannot view salary & bonus history";
			this.accessLevelLongDesc =
				"Can add notes for employees in a specified departments and regions, cannot view salary & bonus history";
			this.accessLevelIcon = "fas fa-user-friends";
		} else {
			this.accessLevelDesc = "No User Access";
			this.accessLevelSubheading = "Users with no access";
			this.accessLevelLongDesc =
				"These users do not have access to view any employee in any department or region";
			this.accessLevelIcon = "fas fa-ban";
		}
	}
}

export class LevelsList extends Array {
	constructor(employee) {
		super();
		if (Boolean(employee)) {
			for (let i = 0; i < employee?.length; i++) {
				let levelsRow = new LevelsRow(employee[i]);
				this.push(levelsRow);
			}
		}
	}

	getAccessLevel() {
		for (let i = 0; i < this?.length; i++) {
			this[i].getAccessLevel();
		}
	}

	addEmployeeHistory(employeeHistory) {
		for (let i = 0; i < this?.length; i++) {
			this[i].addEmployeeHistory(employeeHistory);
		}
	}
}

export class LevelsRow {
	constructor(row) {
		this.accessLevel = row?.accessLevel ?? 0;
		this.accessLevelDesc = row?.accessLevelDesc ?? null;
		this.accessLevelSubheading = row?.accessLevelSubheading ?? null;
		this.accessLevelLongDesc = row?.accessLevelLongDesc ?? null;
		this.accessLevelIcon = row?.accessLevelIcon ?? null;
		this.showLevel = true;
		this.employees =
			new EmployeeHistoryList(row?.employees) ??
			new EmployeeHistoryList();
	}

	getAccessLevel() {
		if (this.accessLevel === 0) {
			this.accessLevelDesc = "All Access Levels";
			this.accessLevelSubheading = "View users with any level of access";
			this.accessLevelLongDesc =
				"This option lets you view all users with any level of access";
			this.accessLevelIcon = "fas fa-universal-access";
		} else if (this.accessLevel === 1) {
			this.accessLevelDesc = "Full Executive Access";
			this.accessLevelSubheading =
				"Can view regional summaries, approve salaries & bonuses for all departments and regions, and edit user access";
			this.accessLevelSubheading =
				"Can view regional summaries, approve salaries & bonuses for all departments and regions, and edit user access";
			this.accessLevelIcon = "fas fa-shield-alt";
			// this.accessLevelIcon = 'fas fa-user-tie'
		} else if (this.accessLevel === 2) {
			this.accessLevelDesc = "Regional Leader Access";
			this.accessLevelSubheading =
				"Can view salaries & bonuses for all departments in a specified region";
			this.accessLevelLongDesc =
				"Can view salaries & bonuses for all departments in a specified region";
			this.accessLevelIcon = "fas fa-user-tie";
		} else if (this.accessLevel === 3) {
			this.accessLevelDesc = "Department Leader Access";
			this.accessLevelSubheading =
				"Can view salaries & bonuses for employees in specified departments and regions";
			this.accessLevelLongDesc =
				"Can view salaries & bonuses for employees in specified departments and regions";
			this.accessLevelIcon = "fas fa-users";
		} else if (this.accessLevel === 4) {
			this.accessLevelDesc = "Department Manager Access";
			this.accessLevelSubheading =
				"Can add notes for employees in a specified departments and regions, cannot view salary & bonus history";
			this.accessLevelLongDesc =
				"Can add notes for employees in a specified departments and regions, cannot view salary & bonus history";
			this.accessLevelIcon = "fas fa-user-friends";
		} else {
			this.accessLevelDesc = "No User Access";
			this.accessLevelSubheading = "Users with no access";
			this.accessLevelLongDesc =
				"These users do not have access to view any employee in any department or region";
			this.accessLevelIcon = "fas fa-ban";
		}
	}

	setShowLevel(value = false) {
		this.showLevel = value;
	}

	addEmployeeHistory(employeeHistory) {
		for (let i = 0; i < this.employees?.length; i++) {
			let employeeUID = this.employees[i]?.employeeUID;
			let employeeHistoryRow = employeeHistory.filter(
				(data) => data?.employeeUID === employeeUID
			);
			this.employees[i] = new EmployeeHistoryRow(employeeHistoryRow[0]);
		}
		this.employees?.sortEmployees();

		let newEmployeeList = this.employees?.createEmployeeMap();
		this.employees = newEmployeeList;
	}
}

export class DepartmentList extends Array {
	constructor(access) {
		super();
		if (Boolean(access)) {
			for (let i = 0; i < access?.length; i++) {
				this.push(new DepartmentRow(access[i]));
			}
		}
	}

	createDepartmentMap(attr) {
		let deptMap = new DeptMap();
		for (let i = 0; i < this?.length; i++) {
			let keyID = this[i][attr];

			if (!Boolean(deptMap[keyID])) {
				deptMap[keyID] = new DeptMapRow(this[i]);
			}
			deptMap[keyID].rows.push(this[i]);
		}

		let departmentMap = new DeptMap();
		Object.keys(deptMap ?? {})?.map((key, i) =>
			departmentMap.push(deptMap[key])
		);
		departmentMap.sort(function (a, b) {
			return (
				a.deptGroupID - b.deptGroupID ||
				a.parentDeptOrder - b.parentDeptOrder ||
				a.deptOrder - b.deptOrder ||
				a.regionID - b.regionID
			);
		});

		return departmentMap;
	}
}

export class DepartmentRow {
	constructor(row) {
		this.deptGroupID = row?.DeptGroupID ?? row?.deptGroupID ?? null;
		this.deptGroup = row?.DeptGroup ?? row?.deptGroup ?? null;
		this.deptGroupDesc = row?.DeptGroupDesc ?? row?.deptGroupDesc ?? null;
		this.deptGroupIcon = row?.DeptGroupIcon ?? row?.deptGroupIcon ?? null;
		this.deptGroupColor =
			row?.DeptGroupColor ?? row?.deptGroupColor ?? null;

		this.parentDeptOrder =
			row?.ParentDeptOrder ?? row?.parentDeptOrder ?? null;
		this.parentDeptID = row?.ParentDeptID ?? row?.parentDeptID ?? null;
		this.parentDept = row?.ParentDept ?? row?.parentDept ?? null;
		this.parentDeptIcon =
			row?.ParentDeptIcon ?? row?.parentDeptIcon ?? null;
		this.parentDeptColor =
			row?.ParentDeptColor ?? row?.parentDeptColor ?? null;

		this.deptOrder = row?.DeptOrder ?? row?.deptOrder ?? null;
		this.deptID = row?.DeptID ?? row?.deptID ?? null;
		this.dept = row?.Dept ?? row?.dept ?? null;
		this.deptIcon = row?.DeptIcon ?? row?.deptIcon ?? null;
		this.deptColor = row?.DeptColor ?? row?.deptColor ?? null;

		this.hasAccess = row?.hasAccess ?? false;
		this.showDept = row?.showDept ?? false;
		this.regions = new RegionList(row?.regions) ?? new RegionList();
	}

	getRegions(regionArr) {
		let regions = new RegionList(regionArr);
		this.regions = regions;
	}

	setAccess() {
		this.hasAccess = true;
		this.showDept = true;
	}

	setShowDept(value = false) {
		this.showDept = value;
	}
}

export class RegionList extends Array {
	constructor(access) {
		super();
		if (Boolean(access)) {
			for (let i = 0; i < access?.length; i++) {
				this.push(new RegionRow(access[i]));
			}
		}
	}
}

export class RegionRow {
	constructor(row) {
		this.regionID = row?.regionID ?? null;
		this.region = row?.region ?? null;
		this.color = row?.color ?? null;
		this.hasAccess = row?.hasAccess ?? false;
		this.showRegion = row?.showRegion ?? false;
	}

	setAccess() {
		this.hasAccess = true;
		this.showRegion = true;
	}

	setShowRegion(value = false) {
		this.showRegion = value;
	}
}

export class HistoryList extends Array {
	constructor(employee) {
		super();
		if (Boolean(employee)) {
			for (let i = 0; i < employee?.length; i++) {
				let employeeRow = new HistoryRow(employee[i]);
				this.push(employeeRow);
			}
		}
	}

	getOldValues() {
		let oldRate = 0;
		let oldSalary = 0;
		for (let i = 0; i < this?.length; i++) {
			this[i].oldRate = oldRate;
			this[i].oldSalary = oldSalary;
			if (this[i].newRate === null) {
				this[i].newRate = oldRate;
			}
			if (this[i].newSalary === null) {
				this[i].newSalary = oldSalary;
			}
			let description;
			if (i === 0) {
				description = "Starting Salary";
			} else if (this[i].oldSalary === this[i].newSalary) {
				description = "No Change";
			} else if (this[i].oldSalary < this[i].newSalary) {
				description = "Salary Increase";
			} else if (this[i].oldSalary > this[i].newSalary) {
				description = "Salary Decrease";
			}
			this[i].description = description;
			oldRate = this[i].newRate;
			oldSalary = this[i].newSalary;
		}
	}
}

export class HistoryRow {
	constructor(employeeRow) {
		this.employeeUID =
			employeeRow?.EmployeeUID ?? employeeRow?.employeeUID ?? null;
		this.prco = employeeRow?.PRCo ?? employeeRow?.prco ?? null;
		this.employee = employeeRow?.Employee ?? employeeRow?.employee ?? null;

		this.year = employeeRow?.Year ?? employeeRow?.year ?? null;
		this.description =
			employeeRow?.Description ?? employeeRow?.description ?? null;
		this.oldRate = employeeRow?.OldRate ?? employeeRow?.oldRate ?? null;
		this.oldSalary =
			employeeRow?.OldSalary ?? employeeRow?.oldSalary ?? null;
		this.newRate = employeeRow?.NewRate ?? employeeRow?.newRate ?? null;
		this.newSalary =
			employeeRow?.NewSalary ?? employeeRow?.newSalary ?? null;
		this.increaseDate =
			formatDate(employeeRow?.IncreaseDate) ??
			formatDate(employeeRow?.increaseDate) ??
			null;

		this.bonusYE = employeeRow?.BonusYE ?? employeeRow?.bonusYE ?? null;
		this.bonusDate =
			formatDate(employeeRow?.BonusDate) ??
			formatDate(employeeRow?.bonusDate) ??
			null;
	}

	getPctIncrease(v1 = 0, v2 = 0) {
		let pctIncrease = 0;
		let increase = 0;
		if (v1 !== 0 && v2 !== 0) {
			pctIncrease = ((v1 - v2) / v2) * 100;
			increase = v1 - v2;
		}

		let color = "gray.400";
		let symbol = "";
		if (pctIncrease > 0) {
			color = "green.600";
			symbol = "+";
		} else if (pctIncrease < 0) {
			color = "red.600";
		}
		let pctIncreaseArr = {
			pctIncrease: pctIncrease,
			color: color,
			symbol: symbol,
			increase: increase,
		};
		return pctIncreaseArr;
	}
}

export class DeptMap extends Array {
	constructor(employeeHistory) {
		super();
		if (Boolean(employeeHistory)) {
			for (let i = 0; i < employeeHistory?.length; i++) {
				this.push(new DeptMapRow(employeeHistory[i]));
			}
		}
	}

	getSubTotals() {
		for (let i = 0; i < this?.length; i++) {
			this[i].getEmployeeTotals();
		}
		return this;
	}

	getSubTotalsSummary() {
		for (let i = 0; i < this?.length; i++) {
			this[i].getEmployeeTotalsSummary();
		}
		return this;
	}

	getTotals() {
		let employeeHistoryList = new EmployeeHistoryList();

		for (let i = 0; i < this?.length; i++) {
			this[i].getDeptTotals();
			employeeHistoryList.push(this[i].totalsRow);
		}
		let totalsRow = employeeHistoryList.getEmployeeHistoryTotals();

		return totalsRow;
	}

	createDepartmentMap(attr) {
		let deptMap = new DeptMap();
		for (let i = 0; i < this?.length; i++) {
			let keyID = this[i][attr];

			if (!Boolean(deptMap[keyID])) {
				deptMap[keyID] = new DeptMapRow(this[i]);
			}
			deptMap[keyID].rows.push(this[i]);
		}

		let departmentMap = new DeptMap();
		Object.keys(deptMap ?? {})?.map((key, i) =>
			departmentMap.push(deptMap[key])
		);
		departmentMap.sort(function (a, b) {
			return (
				a.deptGroupID - b.deptGroupID ||
				a.parentDeptOrder - b.parentDeptOrder ||
				a.deptOrder - b.deptOrder ||
				a.regionID - b.regionID
			);
		});

		return departmentMap;
	}
}

export class DeptMapRow {
	constructor(mapRow) {
		this.deptGroupID = mapRow?.deptGroupID ?? null;
		this.deptGroup = mapRow?.deptGroup ?? null;
		this.deptGroupDesc = mapRow?.deptGroupDesc ?? null;
		this.deptGroupIcon = mapRow?.deptGroupIcon ?? null;
		this.deptGroupColor = mapRow?.deptGroupColor ?? null;

		this.parentDeptOrder = mapRow?.parentDeptOrder ?? null;
		this.parentDeptID = mapRow?.parentDeptID ?? null;
		this.parentDept = mapRow?.parentDept ?? null;

		this.deptOrder = mapRow?.deptOrder ?? null;
		this.deptID = mapRow?.deptID ?? null;
		this.dept = mapRow?.dept ?? null;
		this.deptColor = mapRow?.deptColor ?? null;
		this.deptIcon = mapRow?.deptIcon ?? null;

		this.deptByRegion = mapRow?.deptByRegion ?? null;
		this.regionID = mapRow?.regionID ?? null;

		this.rows = [];
		this.totalsRow = [];
	}

	getEmployeeTotals() {
		let employeeHistoryList = new EmployeeHistoryList(this.rows);
		let totalsRow = employeeHistoryList.getEmployeeHistoryTotals();
		this.totalsRow = totalsRow;
	}

	getEmployeeTotalsSummary() {
		let employeeHistoryList = new EmployeeHistoryList(this.rows);
		let totalsRow = employeeHistoryList.getEmployeeHistoryTotalsSummary();
		this.totalsRow = totalsRow;
	}

	getDeptTotals() {
		let employeeHistoryList = new EmployeeHistoryList();

		for (let i = 0; i < this?.rows?.length; i++) {
			employeeHistoryList.push(this.rows[i].totalsRow);
		}

		let totalsRow = employeeHistoryList.getEmployeeHistoryTotals();
		this.totalsRow = totalsRow;
	}
}
