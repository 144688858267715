// import { convertArrayToMap, formatValue, log } from "../../../../../helperFunctions";
import { portalUser } from "../../../App";
// import moment from "moment";

export default class SectionTemplateHRNotifications {
	constructor() {
		this.template = "Notifications";
		this.name = "Notifications";
		this.heading = "Notifications";
		// this.tooltip = "Click here to add a new Pending Change Order";
		this.divider = false;
		this.pagebreak = 0;
		this.wrap = true;
		this.type = "virtualTable";
		this.minRows = 1;
		this.maxRows = 25;
		this.subSections = [new SubSectionTemplateHRNotifications()];
	}
}

class SubSectionTemplateHRNotifications {
	constructor() {
		let sortGroupFilter = this.getSortGroupFilter();
		this.template = "Notifications";
		this.type = "virtualTable";

		this.loopBy = sortGroupFilter.loopBy;
		this.groupBy = sortGroupFilter.groupBy;
		this.filterBy = sortGroupFilter.filterBy;
		this.sortBy = sortGroupFilter.sortBy;

		// let regionsMap = convertArrayToMap(portalUser?.getRegions(), "regionID");
		// let departmentsMap = convertArrayToMap(portalUser?.getDepartments(), "deptID");

		let notifications = portalUser?.getNotifications();

		this.footer = this.getFooter(notifications);

		this.columns = [
			{
				accessor: "keyID",
				Header: "KeyID",
				show: false,
				showGroupBy: false,
			},
			{
				accessor: "notificationUID",
				Header: "NotificationUID",
				width: "200%",
				show: false,
				showGroupBy: false,
			},

			{
				accessor: "mthCreated",
				Header: "Month Created",
				valueType: "month",
				show: false,
			},

			{
				accessor: "dateCreated",
				Header: "Date Created",
				valueType: "date",
				showGroupBy: false,
			},

			{
				accessor: "createdBy",
				Header: "CreatedByUID",
				width: "200%",
				show: false,
				showGroupBy: false,
			},
			{
				accessor: "createdByName",
				Header: "Created By",
				width: "125%",
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},

			{
				accessor: "referenceUID",
				Header: "referenceUID",
				width: "200%",
				show: false,
				showGroupBy: false,
			},

			{
				accessor: "subject",
				Header: "Subject",
				width: "200%",
			},
			{
				accessor: "body",
				Header: "Body",
				width: "350%",
				showGroupBy: false,
			},

			{
				accessor: "type",
				Header: "Type",
			},
			{
				accessor: "category",
				Header: "Category",
			},
			{
				accessor: "redirectURL",
				Header: "Redirect URL",
				width: "200%",
				showGroupBy: false,
			},

			{
				accessor: "employeeUID",
				Header: "EmployeeUID",
				width: "200%",
				show: false,
				showGroupBy: false,
			},
			{
				accessor: "employeeName",
				Header: "Sent To",

				width: "125%",
				showGroupBy: false,
			},

			{
				accessor: "dateAcknowledged",
				Header: "Date Acknowledged",
				valueType: "date",
				showGroupBy: false,
			},

			{
				accessor: "dateExpired",
				Header: "Date Expired",
				valueType: "date",
				show: false,
				showGroupBy: false,
			},

			{
				accessor: "approverUID",
				Header: "ApproverUID",
				width: "200%",
				show: false,
				showGroupBy: false,
			},
			{
				accessor: "approverName",
				Header: "Approver",
				width: "125%",
				show: false,
				showGroupBy: false,
			},
			{
				accessor: "status",
				Header: "Status",
			},
		];

		this.data = notifications;
	}

	getSortGroupFilter() {
		let groupBy = [];
		let sortBy = [
			{ id: "status", desc: false },
			{ id: "dateCreated", desc: true },
		];
		let filterBy = [];
		let loopBy = [];
		// let loopBy = [{ id: "category", heading: "category" }];
		let sortGroupFilter = { sortBy, groupBy, filterBy, loopBy };
		return sortGroupFilter;
	}

	getFooter(rows = []) {
		let footer = [
			{
				dateCreatedFontSize: "sm",
				dateCreatedType: "number",
				dateCreated: rows?.length,
				dateCreatedRightAddon: "Notifications",
			},
		];
		return footer;
	}
}
