import SectionTemplateMFRProjectInfoFooter from "./SectionTemplateMFRProjectInfoFooter";

export default class SectionTemplateMFRProjectInfo {
	constructor(contract = null) {
		this.template = "MFRProjectInfo";
		this.name = "Project Info";
		this.show = true;
		this.pagebreak = 0;
		this.divider = false;
		this.type = "labelValuePairs";
		this.subSections = [
			new SubSectionTemplateMFRProjectInfo1(contract),
			new SubSectionTemplateMFRProjectInfo2(contract),
		];
	}
}

class SubSectionTemplateMFRProjectInfo1 {
	constructor(contract = null) {
		this.template = "mfrProjectInfo1";
		this.type = "labelValuePairs";
		this.width = "60%";
		this.columns = [
			{
				accessor: "label",
				width: "30%",
				textAlign: "right",
			},
			{
				accessor: "value",
				width: "55%",
				borderBottomWidth: 1,
			},
			{
				accessor: "rightAddon",
				width: "15%",
			},
		];
		this.data = [
			{
				label: "Project Manager",
				value: contract?.pmName ?? null,
			},
			{
				label: "Project Engineer",
				value: contract?.peName ?? null,
			},
			{
				label: "Customer",
				value: contract?.customerName ?? null,
			},
			{
				label: "Owner",
				value: contract?.ownerName ?? null,
			},
			{
				label: "Foreman",
				value: contract?.foremanName ?? null,
			},
			{
				label: "Contract Type",
				value: contract?.contractType ?? null,
			},
			{
				label: "Platform",
				value: contract?.platform ?? null,
			},
		];
	}
}

class SubSectionTemplateMFRProjectInfo2 {
	constructor(contract = null) {
		this.template = "mfrProjectInfo2";
		this.type = "labelValuePairs";
		this.width = "40%";
		this.columns = [
			{
				accessor: "label",
				width: "28%",
				textAlign: "right",
			},
			{
				accessor: "value",
				width: "62%",
			},
			{
				accessor: "rightAddon",
				width: "10%",
			},
		];
		this.data = [
			{
				label: "Cell",
				value: contract?.pmCell ?? null,
			},
			{
				label: "Cell",
				value: contract?.peCell ?? null,
			},
			{
				label: "Telephone",
				value: contract?.customerPhone ?? null,
			},
			{
				label: "Telephone",
				value: contract?.ownerPhone ?? null,
			},
			{
				label: "Cell",
				value: contract?.foremanCell ?? null,
			},
			{
				label: "Project Type",
				value: contract?.projectType ?? null,
			},
		];
		this.footer = new SectionTemplateMFRProjectInfoFooter(contract);
	}
}
