export default class Department {
	constructor(dept) {
		this.queryData = dept?.queryData ?? dept?.QueryData ?? "Department";
		this.deptID = dept?.deptID ?? dept?.DeptID ?? null;
		this.dept = dept?.dept ?? dept?.Dept ?? null;
		this.tag = dept?.tag ?? dept?.Tag ?? null;
		this.seq = dept?.seq ?? dept?.Seq ?? null;
		this.icon = dept?.icon ?? dept?.Icon ?? null;
		this.color = dept?.color ?? dept?.Color ?? null;
		this.showRegionsYN = dept?.showRegionsYN ?? dept?.ShowRegionsYN ?? "N";
		this.showTradesYN = dept?.showTradesYN ?? dept?.ShowTradesYN ?? "N";

		this.parentDeptID = dept?.parentDeptID ?? dept?.ParentDeptID ?? this.deptID;
	}
}
