//ORDER ALPHABETICALLY
const LOCAL_BUSINESSES = [
	{ value: "Fountain Valley", label: "Fountain Valley" },
	{ value: "Fremont", label: "Fremont" },
	{ value: "Irvine", label: "Irvine" },
	{ value: "Los Angeles", label: "Los Angeles" },
	{ value: "Oakland", label: "Oakland" },
	{ value: "Orange", label: "Orange" },
	{ value: "San Francisco", label: "San Francisco" },
];

export default LOCAL_BUSINESSES;
