import React from "react";
import ToggleButton from "../../../core/ReactTable/buttons/ToggleButton";

export default function BoxViewButton({ isOpen, setIsOpen, size = "md" }) {
	return (
		<>
			{Boolean(setIsOpen) && (
				<ToggleButton
					toggleIsOpen={isOpen}
					onClick={setIsOpen.toggle}
					toggleOptions={[
						{
							isOpen: true,
							label: "Close Box View",
							icon: "far fa-window-maximize",
							color: "gray.500",
							bg: "whiteAlpha.700",
							borderWidth: 1,
						},
						{
							isOpen: false,
							label: "Open Box View",
							icon: "fas fa-th-large",
							color: "gray.500",
							bg: "whiteAlpha.700",
							borderWidth: 1,
						},
					]}
				/>
			)}
		</>
	);
}
