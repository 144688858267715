import React, { useState } from "react";
import { Stack, Text } from "@chakra-ui/react";
import DataInput from "../../Inputs/data/DataInput";
import MultiSelect from "../../Inputs/select/MultiSelectInput";

export default function DataInputExample({ inputType }) {
	const [optionSelected, setOptionSelected] = useState(null);

	const optionsArr = [
		{
			startDateTime: "3/20/2023",
			dueDateTime: "3/24/2023",
			text: "3/24/2023 to 3/24/2023",
		},
		{
			startDateTime: "3/27/2023",
			dueDateTime: "3/31/2023",
			text: "3/27/2023 to 3/31/2023",
		},
		{
			startDateTime: "4/3/2023",
			dueDateTime: "4/7/2023",
			text: "4/3/2023 to 4/7/2023",
		},
	];

	const colourOptions = [
		{ value: "red", label: "Red", color: "red" },
		{ value: "orange", label: "Orange", color: "orange" },
		{ value: "yellow", label: "Yellow", color: "yellow" },
		{ value: "green", label: "Green", color: "green" },
		{ value: "teal", label: "Teal", color: "teal" },
		{ value: "cyan", label: "Cyan", color: "cyan" },
		{ value: "blue", label: "Blue", color: "blue" },
		{ value: "purple", label: "Purple", color: "purple" },
		{ value: "pink", label: "Pink", color: "pink" },
		{ value: "gray", label: "Gray", color: "gray" },
	];

	return (
		<Stack h="full" w="full" spacing={6}>
			{inputType === "text" && (
				<DataInput
					type="text"
					variant="outline"
					placeholder="Type Here..."
					label="Text Input"
					labelProps={{ color: "gray.500", letterSpacing: 2, w: "full" }}
					textAlign="left"
					minW="3xs"
					color="gray.500"
					inputProps={{
						bg: "whiteAlpha.900",
						// color: "gray.500",
					}}
				/>
			)}
			{inputType === "text" && (
				<DataInput
					type="number"
					variant="outline"
					placeholder="0"
					label="Number Input"
					labelProps={{ color: "gray.500", letterSpacing: 2, w: "full" }}
					defaultValue={1}
					textAlign="left"
					minW="3xs"
					color="gray.500"
					inputProps={{
						bg: "whiteAlpha.900",
						fontWeight: "semibold",
						textTransform: "uppercase",
						color: "gray.500",
					}}
				/>
			)}

			{inputType === "text" && (
				<DataInput
					type="textarea"
					variant="outline"
					placeholder="Type Here..."
					label="Text Area Input"
					labelProps={{ color: "gray.500", letterSpacing: 2, w: "full" }}
					textAlign="left"
					minW="3xs"
					textTransform="uppercase"
					color="gray.500"
					inputProps={{
						bg: "whiteAlpha.900",
						fontWeight: "semibold",
						textTransform: "uppercase",
						color: "gray.500",
					}}
				/>
			)}
			{inputType === "text" && (
				<DataInput
					type="editor"
					variant="outline"
					placeholder="Text Editor"
					label="Text Editor Input"
					labelProps={{ color: "gray.500", letterSpacing: 2, w: "full" }}
					textAlign="left"
					minW="3xs"
					textTransform="uppercase"
					color="gray.500"
					inputProps={{
						bg: "whiteAlpha.900",

						color: "gray.500",
					}}
				/>
			)}

			{inputType === "select" && (
				<DataInput
					type="select"
					variant="outline"
					placeholder="Select..."
					label="Simple Select"
					labelProps={{ color: "gray.500", letterSpacing: 2, w: "full" }}
					// value={"3/20/2023"}
					textAlign="left"
					minW="3xs"
					maxW="sm"
					color="gray.500"
					inputProps={{
						bg: "whiteAlpha.900",

						// color: "gray.500",
					}}
				/>
			)}
			{inputType === "select" && (
				<DataInput
					type="select"
					variant="outline"
					placeholder="Select..."
					label="Searchable Select"
					labelProps={{ color: "gray.500", letterSpacing: 2, w: "full" }}
					searchable
					options={optionsArr}
					optionValue="startDateTime" // optionValue is an optional prop from an object in the options array. if no optionValue is provided,
					// then onOptionSelect the entire object will be returned. if an optionValue prop is provided, just that prop will be returned from the selected object.
					optionText="text"
					optionSubheading="dueDateTime"
					onOptionSelect={() => null}
					textAlign="left"
					minW="3xs"
					maxW="sm"
					textTransform="uppercase"
					inputProps={{
						bg: "whiteAlpha.900",

						// color: "gray.500",
					}}
				/>
			)}

			{inputType === "select" && (
				<Stack spacing={1} minW="3xs" maxW="sm">
					<Text
						fontSize={"md"}
						textTransform="uppercase"
						fontWeight="600"
						display="inline-block"
						color="gray.500"
						letterSpacing={2}
					>
						Multi Select
					</Text>
					<MultiSelect
						options={colourOptions}
						isMulti
						closeMenuOnSelect={false}
						hideSelectedOptions={false}
						onChange={setOptionSelected}
						allowSelectAll={true}
						value={optionSelected}
					/>
				</Stack>
			)}
			{inputType === "select" && (
				<DataInput
					type="date"
					variant="outline"
					placeholder="mm/dd/yyyy"
					label="Date Select"
					labelProps={{ color: "gray.500", letterSpacing: 2, w: "full" }}
					searchable
					// value={"3/20/2023"}
					textAlign="left"
					minW="3xs"
					maxW="sm"
					textTransform="uppercase"
					color="gray.500"
					inputProps={{
						bg: "whiteAlpha.900",

						textAlign: "left",
						color: "gray.500",
					}}
				/>
			)}
			{inputType === "file" && (
				<DataInput
					type="file"
					// variant="outline"
					placeholder="Upload File"
					label="Upload File"
					labelProps={{ color: "gray.500", letterSpacing: 2, w: "full" }}
					searchable
					textAlign="left"
					minW="3xs"
					maxW="sm"
					textTransform="uppercase"
					color="gray.500"
					inputProps={{
						bg: "whiteAlpha.900",
						fontWeight: "semibold",
						textTransform: "uppercase",
						color: "gray.500",
					}}
				/>
			)}
		</Stack>
	);
}
