import React, { useState, useMemo } from "react";
import {
	Flex,
	Text,
	Heading,
	VStack,
	Input,
	Stack,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	IconButton,
} from "@chakra-ui/react";
import LongevityAwardsClassLegacy from "../classes/HRLongevityClassLegacy";
import moment from "moment";
import { v4 } from "uuid";

export default function HRLongevityTable(props) {
	const [longevity, setLongevity] = useState(new LongevityAwardsClassLegacy(props.longevity));
	useMemo(() => {
		setLongevity(props.longevity);
	}, [props.longevity]);

	const [showPrevYearsGuess, setshowPrevYearsGuess] = useState(props.showPrevYearsGuess);
	useMemo(() => setshowPrevYearsGuess(props.showPrevYearsGuess), [props.showPrevYearsGuess]);

	const [show, setShow] = useState(props.yearRange === "Less Than 5 Years" ? false : true);
	const handleToggle = () => setShow(!show);

	const handleLongevityUpdate = async (ev, i) => {
		let name = ev.target.name;
		let value = parseFloat(ev.target.value.replace("$", "").replace(",", ""));

		let newlongevity = longevity;
		if (newlongevity[i]?.employeeUID) {
			newlongevity[i]?.updateEmployeeLongevityRow(name, value);
		}
		let templongevity = new LongevityAwardsClassLegacy();
		Object.assign(templongevity, newlongevity);
		setLongevity(templongevity);
	};

	let topBottomBorder = {
		boxShadow: "inset 0 2px 0 #000000, inset 0 -2px 0 #000000, inset 2px 0 0 #000000, inset -2px 0 0 #000000",
	};
	let leftRightBorder = { boxShadow: "inset 2px 0 0 #000000, inset -2px 0 0 #000000, inset 0 -1px 0 #EDF2F7" };
	let rightBorder = { boxShadow: "inset 1px 0 0 #000000" };

	let icon = show ? "fa-minus" : "fa-plus";

	return (
		<VStack key={props.yearRange} w="full" flex={1} className="skinnyScroll" overflow="auto" pr="2">
			<Flex w="full" flex={1} justify="space-between" align="center">
				<Heading color="teal.600" textTransform="uppercase" letterSpacing={2} textShadow="2px 2px white">
					{props.yearRange}
				</Heading>

				<IconButton
					key={icon}
					onClick={handleToggle}
					variant="ghost"
					size="sm"
					bg="whiteAlpha.400"
					color="gray.500"
					_hover={{ color: "teal.500", bg: "whiteAlpha.800", shadow: "md" }}
					icon={<i className={"fas " + icon + " fa-fw fa-lg"}></i>}
				/>
			</Flex>

			{show && (
				<Table size="small" bg="white" top="0" left="0" color="gray.600">
					<Thead
						zIndex="2"
						top={0}
						position="sticky"
						style={{ borderSpacing: "0", insetBlockStart: "0" }}
						border="0px"
					>
						<Tr textTransform="uppercase" bg="teal.600" style={topBottomBorder}>
							<Th maxW="250px" w="250px" minW="225px" px="2">
								<Heading fontSize="lg" color="white" isTruncated textAlign="left" letterSpacing={1}>
									Employee
								</Heading>
							</Th>
							<Th maxW="150px" minW="150px" w="150px" px="2">
								<Heading fontSize="sm" color="white" textAlign="center">
									Most&nbsp;Recent Award
								</Heading>
							</Th>
							<Th maxW="150px" minW="150px" w="150px" px="2">
								<Heading fontSize="sm" color="white" textAlign="center">
									Longevity Last&nbsp;Year
								</Heading>
							</Th>
							<Th maxW="150px" minW="150px" w="150px" px="2">
								<Heading fontSize="sm" color="white" textAlign="center">
									Longevity This&nbsp;Year
								</Heading>
							</Th>
							{props.awardYears?.map((year) => (
								<Th minW="85px" w="85px" maxW="100px">
									<VStack h="full" spacing={-3} w="full">
										<Text
											fontSize="4xl"
											bgClip="text"
											fontWeight="extrabold"
											bgGradient="linear(to-b, white 40%, var(--chakra-colors-whiteAlpha-100))"
											isTruncated
											textAlign="center"
										>
											{year}
										</Text>
										<Heading
											p="1"
											fontSize="sm"
											color="white"
											textShadow="1px 1px var(--chakra-colors-teal-800)"
											isTruncated
											textAlign="center"
											letterSpacing={1}
										>
											Years
										</Heading>
									</VStack>
								</Th>
							))}
						</Tr>
					</Thead>

					<Tbody style={{ borderSpacing: "0", insetBlockEnd: "0" }} border="none">
						{longevity?.map((employee, i) => {
							return (
								<Tr
									key={employee.employeeUID}
									style={leftRightBorder}
									border="none"
									_hover={{ bg: "blackAlpha.100", fontWeight: "bold" }}
								>
									<Td px="2" style={rightBorder}>
										<Stack w="full" spacing={-1}>
											<Text
												fontWeight="bold"
												isTruncated
												fontSize="md"
												color="gray.600"
												letterSpacing={1}
											>
												{employee.name}
											</Text>
											<Text fontSize="xs" color="gray.500" letterSpacing={1}>
												{"Hired " + moment(employee.hireDate).format("M/D/yyyy")}
											</Text>
										</Stack>
									</Td>
									<Td px="2" style={rightBorder}>
										{employee.lastAward !== null && (
											<VStack w="full" spacing={-1}>
												<Text fontWeight="bold" fontSize="md" color="gray.500">
													{employee.lastAward + " Years"}
												</Text>
												<Text fontSize="xs" color="gray.500" letterSpacing={1}>
													{"in " + employee.lastAwardYear}
												</Text>
											</VStack>
										)}
										{employee.lastAward === null && (
											<VStack w="full">
												<Text fontWeight="bold" fontSize="md" color="gray.500">
													N/A
												</Text>
											</VStack>
										)}
									</Td>
									<Td px="2" style={rightBorder}>
										<VStack w="full" spacing={-1}>
											<Text fontWeight="bold" fontSize="md" color="gray.500">
												{employee.prevLongevityDesc?.years + " Years"}
											</Text>
											{employee.prevLongevityDesc["months"] !== 0 && (
												<Text fontSize="xs" color="gray.500" letterSpacing={1}>
													{employee.prevLongevityDesc["months"] + " Months"}
												</Text>
											)}
										</VStack>
									</Td>
									<Td px="2" style={rightBorder}>
										<VStack w="full" spacing={-1}>
											<Text fontWeight="bold" fontSize="md" color="gray.500">
												{employee.projLongevityDesc?.years + " Years"}
											</Text>
											{employee.projLongevityDesc["months"] !== 0 && (
												<Text fontSize="xs" color="gray.500" letterSpacing={1}>
													{employee.projLongevityDesc["months"] + " Months"}
												</Text>
											)}
										</VStack>
									</Td>
									{props.awardYears?.map((year) => {
										let property = "awardYear" + year;

										let placeholder = "";
										if (employee.projYear >= 5 && employee[property] === null) {
											if (employee.projYear === year) {
												placeholder = moment().year();
											} else if (
												employee.lastAward !== null &&
												(year < employee.lastAward) & showPrevYearsGuess
											) {
												placeholder = employee.lastAwardYear - (employee.lastAward - year);
											} else if ((employee.projYear > year) & showPrevYearsGuess) {
												placeholder = moment().year() - (employee.projYear - year);
											}
										}

										return (
											<Td
												style={rightBorder}
												bg="transparent"
												cursor={employee?.locked === "Y" ? "not-allowed" : "pointer"}
											>
												<Input
													key={v4()}
													name={property}
													defaultValue={employee[property]}
													maxLength={4}
													placeholder={placeholder !== "" ? placeholder + "?" : placeholder}
													onBlur={(ev) => {
														handleLongevityUpdate(ev, i);
													}}
													_placeholder={
														placeholder >= moment().year() - 1
															? { fontWeight: "normal" }
															: { fontWeight: "normal", color: "blackAlpha.400" }
													}
													cursor="pointer"
													fontWeight="bold"
													color="gray.500"
													variant="unstyled"
													textAlign="center"
													autoComplete="off"
												/>
												{/* isDisabled={(employee?.locked === 'Y')}
                                                cursor={(employee?.locked === 'Y' ? 'not-allowed' : 'pointer')}
                                                 */}
											</Td>
										);
									})}
								</Tr>
							);
						})}
					</Tbody>
				</Table>
			)}
		</VStack>
	);
}
