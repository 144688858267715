import React, { useState, useMemo, useEffect, useCallback } from "react";
import { Heading, Progress, HStack, Stack, Text, Flex, Button, Icon, Tooltip, useBoolean } from "@chakra-ui/react";
import { FaPlus, FaMinus } from "react-icons/fa";
import { formatValue, convertArrayToMap, getSubtotal, log } from "../../../../../helperFunctions";

import { portalUser } from "../../../../../App";
import Section from "../../../../../core/Reports/components/Section";
import SectionObj from "../../../../../core/Reports/classes/Section";
import LoadingSection from "../../../../../core/ProgressIndicators/components/LoadingSection";
import SectionTemplateDiversityVendors from "../../../../Purchasing/classes/SectionTemplateDiversityVendors";
import DiversityVendorDrawer from "../../../../Purchasing/components/DiversityVendorDrawer";
import Operations from "../../../../Operations/classes/Operations";

export default function ProgressBar({ group, diversityVendors, isLoading, amount, numerator }) {
	const diversityVendorMap = useMemo(() => {
		let selectedVendors = convertArrayToMap(diversityVendors, "divCertType", true);
		return selectedVendors;
	}, [diversityVendors]);

	const [showVendors, setShowVendors] = useState(false);

	// Get the combined label for display
	const label = group.divCertType
		.map((certType) => {
			// If the cert type is SBE and there's a divCertSubType, use that instead
			if (certType === "SBE" && group.divCertSubType) {
				return group.divCertSubType;
			}
			return certType;
		})
		.join(", ");

	let totalRequired = group?.divReqPct * amount;

	// Calculate total costs across all certification types
	const actualCost = group.divCertType.reduce((total, certType) => {
		const vendorsForType = diversityVendorMap[certType] || [];
		return total + getSubtotal(vendorsForType, "actualCost", "sum", true);
	}, 0);

	const committedCost = group.divCertType.reduce((total, certType) => {
		const vendorsForType = diversityVendorMap[certType] || [];
		return total + getSubtotal(vendorsForType, "committedCost", "sum", true);
	}, 0);

	const [spentToDate, setSpentToDate] = useState(0);
	const percentTowardsGoal = (spentToDate / totalRequired) * 100;

	// Update useEffect to run when the component mounts and when costs change
	useEffect(() => {
		const newSpentToDate = numerator === "actualCost" ? actualCost : actualCost + committedCost;
		setSpentToDate(newSpentToDate);
	}, [actualCost, committedCost, numerator]);

	// The below is for the diversity vendor drawer
	const [selectedVendor, setSelectedVendor] = useState(null);
	const [updateTrigger, setUpdateTrigger] = useState(false);
	const [operations, setOperations] = useState(portalUser?.operations ?? null);
	const [isLoadingCerts, setIsLoadingCerts] = useBoolean(true);

	const setDataLoading = useCallback(
		async function (callback) {
			if (!isLoadingCerts) {
				setIsLoadingCerts.on();
			}
			callback();
		},
		[isLoadingCerts, setIsLoadingCerts]
	);

	//ON PAGE LOAD INIT Operations Data DATA
	const initOperationsData = useCallback(async () => {
		let msUserID = portalUser.user.msUserID ?? portalUser?.msUser?.id ?? null;
		let params = { msUserID };
		let newOperations = operations;
		if (Boolean(msUserID) && !Boolean(newOperations?.operationsAPI)) {
			newOperations = new Operations(portalUser?.operations);
			newOperations = await newOperations?.initOperations(params);
		}
		if (Boolean(newOperations?.operationsAPI)) {
			newOperations = await newOperations.getVendors(params);
			newOperations = await newOperations.getDiversityCerts(params);
		}

		setOperations(newOperations);
		setIsLoadingCerts.off();
	}, [operations, setIsLoadingCerts]);

	useEffect(() => {
		setDataLoading(() => initOperationsData());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Update portalUser operations cache
	useMemo(() => {
		if (Boolean(operations) && !isLoadingCerts) {
			portalUser.operations = operations;
		}
	}, [isLoadingCerts, operations]);

	useEffect(() => {
		if (updateTrigger) {
			setUpdateTrigger(false);
		}
	}, [updateTrigger]);

	return (
		<Stack w="full" flex={1} spacing={3} direction="column">
			<Flex justify="space-between">
				<Heading color="teal.600" textTransform="uppercase" letterSpacing={1} size="md">
					{label}
				</Heading>
				<Text fontWeight="semibold" color="teal.600" letterSpacing={1}>
					{formatValue(group?.divReqPct * 100, 2, "percent")}
				</Text>
			</Flex>
			<HStack>
				<Progress
					rounded="md"
					h={6}
					colorScheme="teal"
					w="full"
					size="lg"
					hasStripe
					isAnimated
					bg="whiteAlpha.700"
					value={percentTowardsGoal}
					borderWidth={1}
					borderColor="teal.500"
					sx={{
						"& > div:first-of-type": {
							transitionProperty: "width",
						},
					}}
				/>
			</HStack>
			<Flex justify="space-between">
				<Tooltip label="Total Required / Spent to Date" hasArrow={true} placement="top">
					<Stack>
						<Flex fontSize="3xl">{formatValue(percentTowardsGoal, 2, "percent")}</Flex>
						<Flex fontSize="xl">Towards Goal</Flex>
					</Stack>
				</Tooltip>

				<Tooltip label="Certification Spending" hasArrow={true} placement="top">
					<Stack>
						<Flex fontSize="3xl">{formatValue(spentToDate, 2, "currency")}</Flex>
						<Flex fontSize="xl">Spent to Date</Flex>
					</Stack>
				</Tooltip>

				<Tooltip label="Percent Required * Contract Amount" hasArrow={true} placement="top">
					<Stack>
						<Flex fontSize="3xl">{formatValue(totalRequired, 2, "currency")}</Flex>
						<Flex fontSize="xl">Total Required</Flex>
					</Stack>
				</Tooltip>

				<Tooltip label="Total Required - Spent to Date" hasArrow={true} placement="top">
					<Stack>
						<Flex fontSize="3xl">{formatValue(totalRequired - spentToDate, 2, "currency")}</Flex>
						<Flex fontSize="xl">Total Remaining</Flex>
					</Stack>
				</Tooltip>
			</Flex>
			<Flex justifyContent="flex-end" w="full" pt={4}>
				<Button
					variant="outline"
					onClick={() => setShowVendors(!showVendors)}
					color="gray.500"
					borderColor="teal.300"
					px={2}
					size="md"
					bg="whiteAlpha.800"
					shadow="md"
					textTransform="uppercase"
					_hover={{
						color: "teal.500",
						fontWeight: "bold",
						bg: "whiteAlpha.900",
						borderColor: "teal.500",
						borderWidth: 2,
					}}
					tabIndex={-1}
				>
					<HStack align="center" spacing={1}>
						<Icon as={showVendors ? FaMinus : FaPlus} fontSize="sm" />
						<Text lineHeight={1} fontSize="sm" letterSpacing={1} pr={1}>
							{showVendors ? "Hide Vendors" : "Show Vendors"}
						</Text>
					</HStack>
				</Button>
			</Flex>
			{showVendors && (
				<DiversityVendorsSpentToDateTable
					isLoading={isLoading}
					label={group?.divCertType}
					diversityVendorMap={diversityVendorMap}
					selectedVendor={selectedVendor}
					setSelectedVendor={setSelectedVendor}
					operations={operations}
				/>
			)}
			{Boolean(selectedVendor) && (
				<DiversityVendorDrawer
					selectedVendor={selectedVendor}
					isOpen={Boolean(selectedVendor)}
					closeVendor={() => setSelectedVendor(null)}
					saveVendor={() => setSelectedVendor(null)}
					deleteVendorCert={() => setSelectedVendor(null)}
				/>
			)}
		</Stack>
	);
}

const DiversityVendorsSpentToDateTable = ({
	isLoading,
	label,
	diversityVendorMap,
	selectedVendor,
	setSelectedVendor,
	operations,
}) => {
	const selectVendor = useCallback(
		(vendor) => {
			if (Boolean(vendor?.apvmKeyID) && Boolean(operations)) {
				let certs = operations?.diversityCerts ?? [];
				certs = certs.filter((d) => parseInt(d.vendorGroup) === vendor?.vendorGroup) ?? [];
				let certMap = convertArrayToMap(certs, "vendorID", true);
				if (certMap[vendor?.vendorID]?.length > 0) {
					vendor.diversityCerts = certMap[vendor?.vendorID] ?? [];
				}
				setSelectedVendor(vendor);
			}
		},
		[operations, setSelectedVendor]
	);

	// Map through each cert type and create a section for each
	const sections = useMemo(() => {
		if (!isLoading) {
			return Array.isArray(label)
				? label.map((certType) => (
						<Section
							key={certType}
							section={
								new SectionObj(
									new SectionTemplateDiversityVendors(
										"VendorDiversitySpentToDate",
										diversityVendorMap[certType] ?? [],
										isLoading,
										certType
									)
								)
							}
							selectedRow={selectedVendor}
							setSelectedRow={selectVendor}
						/>
				  ))
				: null;
		} else {
			return <LoadingSection />;
		}
	}, [diversityVendorMap, isLoading, label, selectVendor, selectedVendor]);

	return (
		<Flex
			p={portalUser.styles?.pagePadding}
			bg={portalUser.styles?.pageBG}
			direction="column"
			flex={1}
			justify="flex-start"
			w="full"
			h="full"
		>
			<Stack
				align="flex-start"
				w="full"
				h="full"
				flex={1}
				spacing={portalUser.styles?.pageSpacing}
				_hover={{ cursor: "pointer" }}
			>
				{sections}
			</Stack>
		</Flex>
	);
};
