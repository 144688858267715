import React, { useState, useRef } from "react";
import {
	Box,
	Center,
	Flex,
	Text,
	Heading,
	Tabs,
	TabPanels,
	TabList,
	TabPanel,
	Tab,
	Avatar,
	VStack,
	IconButton,
	Container,
	Image,
} from "@chakra-ui/react";
import PPMLogo from "../../../core/Logos/components/PPMLogo";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";

export default class DevTeamPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			goalsAccordion: "",
			tabPanelVal: 0,

			isAuthorized: false,
			loadPage: false,
		};
		this.scrollTop = this.scrollTop.bind(this);
		this.handleTabPanelChange = this.handleTabPanelChange.bind(this);
		this.closeForm = this.closeForm.bind(this);
	}

	scrollTop() {
		document.body.scrollTop = document.documentElement.scrollTop = 0;
	}

	handleTabPanelChange(tabPanelVal) {
		this.setState({
			tabPanelVal: tabPanelVal,
		});
		this.scrollTop();
	}

	closeForm() {
		this.handleTabPanelChange(0);
	}

	render() {
		return (
			<Box py={8} bg="blackAlpha.500" flex={1}>
				<Container maxW="container.xl" px="0" w="full">
					<Tabs colorScheme="teal" index={this.state.tabPanelVal} onChange={this.handleTabPanelChange}>
						<TabList color="gray.600" borderBottomColor="whiteAlpha.500">
							<Tab
								_selected={{
									color: "teal.600",
									bg: "whiteAlpha.500",
									borderTopRadius: "5",
									borderBottom: "3px solid",
								}}
								_hover={{
									color: "teal.500",
									bg: "whiteAlpha.500",
									borderTopRadius: "5",
									borderBottom: "3px solid",
									borderBottomColor: "teal.600",
								}}
							>
								<Heading size="sm" textTransform="uppercase" letterSpacing={2}>
									Meet the Team
								</Heading>
							</Tab>

							<Tab
								_selected={{
									color: "teal.600",
									bg: "whiteAlpha.500",
									borderTopRadius: "5",
									borderBottom: "3px solid",
								}}
								_hover={{
									color: "teal.500",
									bg: "whiteAlpha.500",
									borderTopRadius: "5",
									borderBottom: "3px solid",
									borderBottomColor: "teal.600",
								}}
							>
								<Heading size="sm" textTransform="uppercase" letterSpacing={2}>
									History of the PPM Portal
								</Heading>
							</Tab>
						</TabList>

						<TabPanels>
							<TabPanel px="0">
								<VStack spacing={16} p="0" w="full">
									<Container
										maxW="container.xl"
										px="0"
										shadow="2xl"
										rounded={5}
										style={{
											backgroundImage:
												"linear-gradient(rgba(255, 255, 255, 1), 50%, rgba(255, 255, 255, 0.25)), url(/images/Background_Hexagons.jpg)",
											backgroundSize: "cover",
										}}
									>
										<VStack spacing={0} pt="8" px={8}>
											<Flex w="full" justify="space-between" align="flex-start">
												<PPMLogo color="teal.700" h="75px" />
											</Flex>

											<Container
												maxW="container.lg"
												w="full"
												position="relative"
												minH="md"
												py={8}
											>
												<Center
													zIndex={2}
													opacity={1}
													position="absolute"
													w="full"
													flex={1}
													h="full"
													align="center"
												>
													<VStack spacing={0} pt={8}>
														<Heading
															color="teal.800"
															textTransform="uppercase"
															fontSize="9xl"
															textShadow="2px 2px white"
															letterSpacing={16}
														>
															Dev&nbsp;Ops
														</Heading>
														<VStack spacing={3} w="full" py="2">
															<Heading
																color="teal.700"
																textTransform="uppercase"
																textShadow="1px 1px white"
																fontSize="4xl"
																letterSpacing={3}
															>
																Software & Web Development
															</Heading>
														</VStack>
													</VStack>
												</Center>
												<Center position="absolute" w="full" h="full">
													<VStack>
														<Image
															maxW="2xl"
															opacity=".3"
															src="https://www.ippmech.com/wp-content/uploads/2020/10/PPMDevOps.png"
														/>
													</VStack>
												</Center>
											</Container>
										</VStack>
										<Box maxH={"100px"}>
											<Flex align="center" justify="space-around" flex={1} w="full">
												<motion.div whileHover={{ scale: 1.15, transition: { duration: 1 } }}>
													<VStack minW="250px">
														<Avatar
															shadow="lg"
															h={44}
															w={44}
															src={"/images/DevTeam_David.png"}
														/>
														<Heading
															letterSpacing={8}
															textShadow="2px 2px var(--chakra-colors-teal-800)"
															color="white"
															fontSize="lg"
															textTransform="uppercase"
														>
															David Gebert
														</Heading>
														<Heading
															letterSpacing={4}
															color="whiteAlpha.800"
															fontSize="sm"
															textTransform="uppercase"
															textShadow="sm"
														>
															Dev Ops Engineer
														</Heading>
													</VStack>
												</motion.div>
												<motion.div whileHover={{ scale: 1.1, transition: { duration: 1 } }}>
													<VStack minW="250px">
														<Avatar
															shadow="lg"
															h={56}
															w={56}
															src={"/images/DevTeam_Niklas.png"}
														/>
														<Heading
															letterSpacing={8}
															textShadow="2px 2px var(--chakra-colors-teal-800)"
															color="white"
															fontSize="lg"
															textTransform="uppercase"
														>
															Niklas Janetzky
														</Heading>
														<Heading
															letterSpacing={4}
															color="whiteAlpha.800"
															fontSize="sm"
															textTransform="uppercase"
															textShadow="sm"
														>
															Product Management
														</Heading>
													</VStack>
												</motion.div>
												<motion.div whileHover={{ scale: 1.1, transition: { duration: 1 } }}>
													<VStack minW="250px">
														<Avatar
															shadow="lg"
															h={56}
															w={56}
															src={"/images/DevTeam_Dustin.png"}
														/>
														<Heading
															letterSpacing={8}
															textShadow="2px 2px var(--chakra-colors-teal-800)"
															color="white"
															fontSize="lg"
															textTransform="uppercase"
														>
															Dustin Fisher
														</Heading>
														<Heading
															letterSpacing={4}
															color="whiteAlpha.800"
															fontSize="sm"
															textTransform="uppercase"
															textShadow="sm"
														>
															Dev Team Lead
														</Heading>
													</VStack>
												</motion.div>
												<motion.div whileHover={{ scale: 1.1, transition: { duration: 1 } }}>
													<VStack minW="250px">
														<Avatar
															shadow="lg"
															h={44}
															w={44}
															src={"/images/DevTeam_Omar.png"}
														/>
														<Heading
															letterSpacing={8}
															textShadow="2px 2px var(--chakra-colors-teal-800)"
															color="white"
															fontSize="lg"
															textTransform="uppercase"
														>
															Omar El Tarzi
														</Heading>
														<Heading
															letterSpacing={4}
															color="whiteAlpha.800"
															fontSize="sm"
															textTransform="uppercase"
															textShadow="sm"
														>
															Full Stack Developer
														</Heading>
													</VStack>
												</motion.div>
											</Flex>
										</Box>

										<Box
											w="full"
											bgGradient="linear(to-r, teal.800, teal.500)"
											align="center"
											borderBottomRadius={5}
											h="225px"
										>
											<VStack spacing={3} direction="column" w="full" py="8"></VStack>
										</Box>
									</Container>
								</VStack>
							</TabPanel>

							<TabPanel px="0">
								<EvolutionOfPortal />
							</TabPanel>
						</TabPanels>
					</Tabs>
				</Container>
			</Box>
		);
	}
}

export const EvolutionOfPortal = () => {
	const pages = [0, 1, 2, 3, 4, 5];

	const xOffset = 100;
	const variants = {
		enter: (direction) => ({
			x: direction > 0 ? xOffset : -xOffset,
			opacity: 0,
		}),
		active: {
			x: 0,
			opacity: 1,
			transition: { delay: 0.2 },
		},
		exit: (direction) => ({
			x: direction > 0 ? -xOffset : xOffset,
			opacity: 0,
		}),
	};

	const [[currentPage, direction], setCurrentPage] = useState([0, 0]);

	/* We keep track of the pagination direction as well as
	 * current page, this way we can dynamically generate different
	 * animations depending on the direction of travel */

	function setPage(newPage, newDirection) {
		if (!newDirection) newDirection = newPage - currentPage;
		setCurrentPage([newPage, newDirection]);
	}

	const hasPaginated = useRef(false);

	function detectPaginationGesture(e, { offset }) {
		if (hasPaginated.current) return;

		let threshold = 200 / 2;
		let newPage;

		if (offset.x < -threshold) {
			// If user is dragging left, go forward a page
			newPage = currentPage + 1;
			setPage(newPage, offset.x < 0 ? 1 : -1);
		} else if (offset.x > threshold) {
			// Else if the user is dragging right,
			// go backwards a page
			newPage = currentPage - 1;
			setPage(newPage, offset.x < 0 ? 1 : -1);
		} else {
			newPage = currentPage;
			setPage(newPage, 1);
		}
	}

	let backgroundImage;
	let footnote;
	if (currentPage === 0) {
		backgroundImage = "/images/iPPMech_2016_MainMenu.png";
		footnote = "The original version of iPPMech.com went live in 2016";
	} else if (currentPage === 1) {
		backgroundImage = "/images/iPPMech_2017_MainMenu.png";
		footnote = "The user interface was significantly upgraded in 2017";
	} else if (currentPage === 2) {
		backgroundImage = "/images/iPPMech_2018_BidBoard.png";
		footnote = "The Bid Board is upgraded and the original D&F goes live in 2018";
	} else if (currentPage === 3) {
		backgroundImage = "/images/iPPMech_2019_GPI.png";
		footnote = "The PCO Log and D&F are upgraded and the Global Pricing Initiative goes live in 2019";
		// } else if (currentPage === 4) {
		// 	backgroundImage = PPMPortal2021;
		// 	footnote = "We acquire PPMPortal.com and begin to build our new Employee Portal in 2020";
		//
	} else if (currentPage === 4) {
		backgroundImage = "/images/PPMPortal_2021_Timecard.png";
		footnote = "We went live with the Employee Portal and Timecard in April 2021";
	} else if (currentPage === 5) {
		backgroundImage = "/images/PPMPortal_2022_Profile.png";
		footnote = "We released the fully modernized version of Employee Portal in January 2022";
	} else {
		backgroundImage = null;
		footnote = "";
	}

	return (
		<VStack maxW="full" w="full" px="8" pt="8" pb="4" bg="teal.600" rounded="10" overflow="hidden">
			<Flex w="full" justify="space-between" align="flex-start">
				<PPMLogo color="whiteAlpha.800" h="65px" />

				<VStack pt={8} spacing={0} justify="flex-end">
					<Heading color="whiteAlpha.700" textTransform="uppercase" fontSize="2xl" letterSpacing={3}>
						Evolution of the
					</Heading>
					<Heading
						color="white"
						textShadow="3px 3px var(--chakra-colors-teal-800)"
						textTransform="uppercase"
						fontSize="7xl"
						letterSpacing={6}
					>
						PPM Portal
					</Heading>
				</VStack>

				<PPMLogo color="teal.600" h="65px" />
			</Flex>

			<Flex w="full" align="center" flex={1} justify="space-between">
				<IconButton
					zIndex={2}
					mx="auto"
					color="whiteAlpha.800"
					_hover={{ color: "teal.500" }}
					variant="ghost"
					onClick={() => setPage(currentPage === 0 ? pages.length - 1 : currentPage - 1)}
					icon={<i className="fas fa-chevron-left fa-2x"></i>}
				/>

				<Container w="full" className="slider-container" rounded={5}>
					<AnimatePresence custom={direction}>
						<motion.div
							key={currentPage}
							className="slide"
							whileHover={{ scale: 1.005, transition: { duration: 0.5 } }}
							data-page={currentPage}
							variants={variants}
							initial="enter"
							animate="active"
							exit="exit"
							drag="x"
							onDrag={detectPaginationGesture}
							onDragStart={() => (hasPaginated.current = false)}
							onDragEnd={() => (hasPaginated.current = true)}
							// Snap the component back to the center
							// if it hasn't paginated
							dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
							// This will be used for components to resolve all
							// other variants, in this case initial and animate.
							custom={direction}
						>
							<Center
								maxH="736px"
								shadow="md"
								rounded={5}
								zIndex="0"
								style={{
									background: "white url(" + backgroundImage + " ) no-repeat",
									backgroundSize: "contain",
									backgroundPositionX: "center",
									backgroundPositionY: "90px",
								}}
								maxW="full"
								h="full"
							>
								<Image
									src={"/images/ImageFrame_WebBrowser.png"}
									maxW="container.lg"
									rounded={5}
								></Image>
							</Center>
							<Text
								pt={2}
								w="full"
								textAlign="center"
								className="slide-text"
								color="whiteAlpha.900"
								fontWeight="medium"
								fontSize="2xl"
							>
								{footnote}
							</Text>
						</motion.div>
					</AnimatePresence>
				</Container>

				<IconButton
					zIndex={2}
					mx="auto"
					color="whiteAlpha.800"
					_hover={{ color: "teal.500" }}
					variant="ghost"
					onClick={() => setPage(currentPage === pages.length - 1 ? 0 : currentPage + 1)}
					icon={<i className="fas fa-chevron-right fa-2x"></i>}
				/>
			</Flex>

			<Pagination pages={pages} currentPage={currentPage} setPage={setPage} />
		</VStack>
	);
};

const Pagination = (props) => {
	// Wrap all the pagination Indicators
	// with AnimateSharedPresence
	// so we can detect when Indicators
	// with a layoutId are removed/added
	const pages = props.pages ?? [0];
	return (
		<AnimateSharedLayout>
			<div className="Indicators">
				{pages.map((page) => (
					<Indicator key={page} onClick={() => props.setPage(page)} isSelected={page === props.currentPage} />
				))}
			</div>
		</AnimateSharedLayout>
	);
};

const Indicator = ({ isSelected, onClick }) => {
	return (
		<div className="Indicator-container" onClick={onClick} style={{ zIndex: "2" }}>
			<div className="Indicator">
				{isSelected && (
					// By setting layoutId, when this component
					// is removed and a new one is added elsewhere,
					// the new component will animate out from the old one.
					<motion.div className="Indicator-highlight" layoutId="highlight" />
				)}
			</div>
		</div>
	);
};
