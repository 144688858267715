import React from "react";
import { Box, Flex, Text, IconButton, Divider, Stack } from "@chakra-ui/react";

export default function FormPageStepperComponent({ steps, pageVal, setPageVal }) {
	return (
		<Flex flex={1} w="full">
			{steps?.map((step, i) => (
				<React.Fragment key={i}>
					<Stack p={2} align="center">
						<Flex>
							<IconButton
								size="sm"
								borderRadius="50%"
								colorScheme={pageVal === i ? "teal" : "gray"}
								icon={
									<Text
										lineHeight={"none"}
										w="full"
										textAlign="center"
										fontSize="lg"
										fontWeight="bold"
										fontColor={pageVal === i ? "white" : "gray.400"}
									>
										{i + 1}
									</Text>
								}
								onClick={() => {
									setPageVal(i);
								}}
							></IconButton>
						</Flex>
						<Text fontSize="md" fontWeight="bold" color="gray.500">
							{step}
						</Text>
					</Stack>
					{i < steps?.length - 1 && (
						<Box p={2} pt={6} flex={1}>
							<Divider borderColor="blackAlpha.400" />
						</Box>
					)}
				</React.Fragment>
			))}
		</Flex>
	);
}
