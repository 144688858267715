import React, { useMemo, useState } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { formatNumber, chakraThemeColorsMap } from "../../../helperFunctions";
import SectionPDF from "./SectionPDF";
import moment from "moment";

export default function TablePDF(props) {
	const divider = useMemo(() => props.divider ?? null, [props.divider]);
	const label = useMemo(() => props.label ?? null, [props.label]);
	const heading = useMemo(() => props.heading ?? null, [props.heading]);
	const columns = useMemo(
		() => props.columns.filter((data) => data.show === true && data.id !== "expander") ?? [],
		[props.columns]
	);
	const data = useMemo(() => props.data ?? [], [props.data]);
	const header = useMemo(() => props.header ?? [], [props.header]);
	const footer = useMemo(() => {
		let footer = props.footer ?? [];
		if (typeof footer === "function") {
			return footer(props.data);
		} else {
			return footer;
		}
	}, [props.footer, props.data]);
	const minRows = useMemo(() => props.minRows ?? 1, [props.minRows]);
	const maxRows = useMemo(() => props.maxRows ?? null, [props.maxRows]);
	const sliceType = useMemo(() => props.sliceType ?? "first", [props.sliceType]);
	const sortBy = useMemo(() => props.sortBy ?? [], [props.sortBy]);
	const filterBy = useMemo(() => props.filterBy ?? [], [props.filterBy]);
	const wrap = useMemo(() => props.wrap ?? false, [props.wrap]);
	const maxWidth = useMemo(() => props.maxWidth, [props.maxWidth]);

	const [rows, setRows] = useState([]);

	const headingStyles = StyleSheet.create({
		heading: {
			borderTopColor: chakraThemeColorsMap.gray500,
			borderTopWidth: divider ? 1 : 0,
			paddingVertical: Array.isArray(header) && header.length > 0 ? 2 : 6,
			flexDirection: "row",
			justifyContent: "flex-start",
		},
		labelText: {
			textTransform: "uppercase",
			color: chakraThemeColorsMap.gray,
			fontSize: 12,
			letterSpacing: 1,
			paddingRight: 4,
			fontWeight: "bolder",
		},
		headingText: {
			textTransform: "uppercase",
			color: chakraThemeColorsMap.gray,
			fontSize: 12,
			letterSpacing: 1,
			fontWeight: "bolder",
			wordWrap: "normal",
		},
		tr: {
			flexDirection: "row",
			backgroundColor: chakraThemeColorsMap.teal700,
			color: "white",
			justifyContent: "center",
			alignItems: "center",
			minHeight: 12,
			paddingVertical: 1,
			maxWidth: maxWidth,

			borderTopColor: chakraThemeColorsMap.gray600,
			borderLeftColor: chakraThemeColorsMap.gray600,
			borderRightColor: chakraThemeColorsMap.gray600,
			borderBottomColor: chakraThemeColorsMap.gray600,

			borderTopWidth: 1,
			borderLeftWidth: 1,
			borderRightWidth: 1,
			borderBottomWidth: 1,
		},
	});

	useMemo(
		() => setRows(getRows(data, columns, minRows, filterBy, sortBy, maxRows, wrap, sliceType)),
		[data, columns, minRows, filterBy, sortBy, maxRows, wrap, sliceType]
	);

	return (
		<View wrap={wrap}>
			{/* TABLE Heading */}
			<View wrap={false} fixed>
				{/* SECTION HEADING */}
				{heading && (
					<View style={headingStyles?.heading}>
						{Boolean(label) && <Text style={headingStyles?.labelText}>{label}</Text>}
						<Text style={headingStyles?.headingText}>{heading}</Text>
					</View>
				)}

				{/* TABLE HEADER */}
				{Array.isArray(header) &&
					header?.map((data, i) => {
						let tHeadStyles = StyleSheet.create(getTrStyles(header.length, i, "header", maxWidth));

						return (
							<View style={tHeadStyles.tr} wrap={false} key={i}>
								{columns?.map((column, j) => {
									let tdStyles = StyleSheet.create(
										getTdStyles(data, column, columns.length, j, "header")
									);
									let accessor = column.id;
									if (typeof accessor === "function") {
										accessor = accessor.name;
									}
									let valueType = data[accessor + "Type"] ?? column?.valueType ?? "string";
									let decimals = data[accessor + "Decimals"] ?? column?.decimals ?? 0;
									let color =
										data[accessor + "TextColor"] ?? column?.textColor ?? chakraThemeColorsMap.black;

									return (
										<View style={tdStyles[accessor]} key={j}>
											<Text style={[tdStyles[accessor + "Text"], { color: color }]}>
												{valueType === "currency"
													? formatNumber(data[accessor], decimals, "currency")
													: valueType === "accounting"
													? formatNumber(data[accessor], decimals, "accounting")
													: valueType === "percent"
													? formatNumber(data[accessor], decimals, "percent")
													: valueType === "number"
													? formatNumber(data[accessor], decimals)
													: valueType === "month" && data[accessor] !== null
													? moment(data[accessor]).format("MMM YYYY")
													: valueType === "date" && data[accessor] !== null
													? moment(data[accessor]).format("M/D/YYYY")
													: data[accessor] ?? null}
												{Boolean(data[accessor + "RightAddon"]) &&
													" " + data[accessor + "RightAddon"]}
											</Text>
										</View>
									);
								})}
							</View>
						);
					})}

				{!Array.isArray(header) && (
					<View style={StyleSheet.create(getTrStyles(1, 0, "header", maxWidth)).tr} wrap={false}>
						<SectionPDF section={header} />
					</View>
				)}

				<View style={headingStyles.tr} wrap={false}>
					{columns?.map((column, i) => {
						const thStyles = StyleSheet.create(getThStyles(column));
						let accessor = column.id;
						if (typeof accessor === "function") {
							accessor = accessor.name;
						}
						let color = column?.textColor ?? "white";

						return (
							<View style={thStyles[accessor]} key={i}>
								<Text style={[thStyles[accessor + "Text"], { color: color }]}>{column.Header}</Text>
							</View>
						);
					})}
				</View>
			</View>

			{/* TABLE BODY */}
			{rows?.map((data, i) => {
				let tBodyStyles = StyleSheet.create(getTrStyles(rows.length, i, "data", maxWidth));
				return (
					<View style={tBodyStyles.tr} wrap={false} key={i}>
						{columns?.map((column, j) => {
							let tdStyles = StyleSheet.create(getTdStyles(data, column, columns.length, j));
							let accessor = column.id;
							if (typeof accessor === "function") {
								accessor = accessor.name;
							}
							let valueType = data[accessor + "Type"] ?? column?.valueType ?? "string";
							let decimals = data[accessor + "Decimals"] ?? column?.decimals ?? 0;
							let color = data[accessor + "TextColor"] ?? column?.textColor ?? chakraThemeColorsMap.black;

							return (
								<View style={tdStyles[accessor]} key={j}>
									<Text style={[tdStyles[accessor + "Text"], { color: color }]}>
										{valueType === "currency"
											? formatNumber(data[accessor], decimals, "currency")
											: valueType === "accounting"
											? formatNumber(data[accessor], decimals, "accounting")
											: valueType === "percent"
											? formatNumber(data[accessor], decimals, "percent")
											: valueType === "number"
											? formatNumber(data[accessor], decimals)
											: valueType === "month" && data[accessor] !== null
											? moment(data[accessor]).format("MMM YYYY")
											: valueType === "date" && data[accessor] !== null
											? moment(data[accessor]).format("M/D/YYYY")
											: data[accessor] ?? null}
										{Boolean(data[accessor + "RightAddon"]) && " " + data[accessor + "RightAddon"]}
									</Text>
								</View>
							);
						})}
					</View>
				);
			})}
			{/* TABLE FOOTER */}
			<View wrap={false}>
				{Array.isArray(footer) &&
					footer?.map((data, i) => {
						let tFootStyles = StyleSheet.create(getTrStyles(footer.length, i, "footer", maxWidth));

						return (
							<View style={tFootStyles.tr} wrap={false} key={i}>
								{columns?.map((column, j) => {
									let tdStyles = StyleSheet.create(
										getTdStyles(data, column, columns.length, j, "footer")
									);

									let accessor = column.id;
									if (typeof accessor === "function") {
										accessor = accessor.name;
									}
									let valueType = data[accessor + "Type"] ?? column?.valueType ?? "string";
									let decimals = data[accessor + "Decimals"] ?? column?.decimals ?? 0;
									let color =
										data[accessor + "TextColor"] ?? column?.textColor ?? chakraThemeColorsMap.black;

									return (
										<View style={tdStyles[accessor]} key={j}>
											<Text style={[tdStyles[accessor + "Text"], { color: color }]}>
												{valueType === "currency"
													? formatNumber(data[accessor], decimals, "currency")
													: valueType === "accounting"
													? formatNumber(data[accessor], decimals, "accounting")
													: valueType === "percent"
													? formatNumber(data[accessor], decimals, "percent")
													: valueType === "number"
													? formatNumber(data[accessor], decimals)
													: valueType === "month" && data[accessor] !== null
													? moment(data[accessor]).format("MMM YYYY")
													: valueType === "date" && data[accessor] !== null
													? moment(data[accessor]).format("M/D/YYYY")
													: data[accessor] ?? null}
												{Boolean(data[accessor + "RightAddon"]) &&
													" " + data[accessor + "RightAddon"]}
											</Text>
										</View>
									);
								})}
							</View>
						);
					})}

				{Array.isArray(footer) && footer.length === 0 && (
					<View style={StyleSheet.create(getTrStyles(1, 0, "blank", maxWidth)).tr} wrap={false} />
				)}

				{!Array.isArray(footer) && (
					<View style={StyleSheet.create(getTrStyles(1, 0, "footer", maxWidth)).tr} wrap={false}>
						<SectionPDF section={footer} />
					</View>
				)}
			</View>
		</View>
	);
}

function getRows(
	data = [],
	columns = [],
	minRows = 1,
	filterBy = [],
	sortBy = [],
	maxRows = null,
	wrap = false,
	sliceType = "first"
) {
	let rows = [];
	if (Array.isArray(data) && data.length > 0) {
		rows?.push(...data);
	}

	if (filterBy.length > 0 && rows?.length > 0) {
		for (let i = 0; i < filterBy?.length; i++) {
			if (Array.isArray(filterBy[i]?.value)) {
				rows = rows?.filter((row) => filterBy[i].value.includes(row[filterBy[i].id]));
			} else {
				rows = rows?.filter((row) => row[filterBy[i].id] === filterBy[i].value);
			}
		}
	}

	if (minRows - rows?.length > 0) {
		let blankRows = [];
		for (let i = 0; i < minRows - rows.length; i++) {
			let blankRow = {
				keyID: "BlankRow" + i,
			};
			if (columns.length > 0) {
				for (let i = 0; i < columns?.length; i++) {
					blankRow[columns[i].accessor] = null;
				}
			} else {
				blankRow.td = "DATA";
			}
			blankRows?.push(blankRow);
		}
		rows?.push(...blankRows);
	}

	if (Boolean(maxRows) && Boolean(rows?.length > 0) && rows?.length > maxRows) {
		let maxIndex = rows?.length - 1;
		if (sliceType === "first") {
			rows = rows?.slice(0, maxRows);
		} else if (Boolean(maxRows) && sliceType === "last") {
			rows = rows?.slice(maxIndex - maxRows, maxIndex);
		}
	}

	return rows;
}

function getThStyles(column = {}) {
	let styles = {};
	let accessor = column.id;
	if (typeof accessor === "function") {
		accessor = accessor.name;
	}

	styles[accessor] = {
		paddingVertical: 0,
		paddingHorizontal: (column?.px ?? 2) + 2,
		width: column?.width ?? "100%",
		minHeight: column?.height ?? 12,
		justifyContent: column?.justifyContent ?? "center",

		backgroundColor: chakraThemeColorsMap.none,
		borderRadius: column?.borderRadius ?? 0,

		borderTopColor: column?.borderTopColor ?? chakraThemeColorsMap.gray600,
		borderLeftColor: column?.borderLeftColor ?? chakraThemeColorsMap.gray600,
		borderRightColor: column?.borderRightColor ?? chakraThemeColorsMap.gray600,
		borderBottomColor: column?.borderBottomColor ?? chakraThemeColorsMap.gray600,

		borderTopWidth: column?.borderTopWidth ?? 0,
		borderLeftWidth: column?.borderLeftWidth ?? 0,
		borderRightWidth: column?.borderRightWidth ?? 0,
		borderBottomWidth: column?.borderBottomWidth ?? 0,
	};
	styles[accessor + "Text"] = {
		paddingVertical: 0,
		width: "100%",
		textAlign: column?.textAlign ?? "left",
		fontSize: 7,
		textTransform: column?.textTransform ?? "uppercase",
	};

	return styles;
}

function getTrStyles(length = 1, index = 0, type = "data", maxWidth) {
	let styles = {
		tr: {
			maxWidth: maxWidth,

			flexDirection: "row",
			minHeight: ["footer", "data"].includes(type) ? 16 : type === "blank" ? 2 : 8,
			backgroundColor: type === "footer" ? chakraThemeColorsMap.whitesmoke : chakraThemeColorsMap.none,

			borderTopColor: chakraThemeColorsMap.gray600,
			borderLeftColor: chakraThemeColorsMap.gray600,
			borderRightColor: chakraThemeColorsMap.gray600,
			borderBottomColor: type === "data" ? chakraThemeColorsMap.silver : chakraThemeColorsMap.gray600,

			borderTopWidth: 0,
			borderLeftWidth: ["footer", "data"].includes(type) ? 1 : 0,
			borderRightWidth: ["footer", "data"].includes(type) ? 1 : 0,
			borderBottomWidth: type === "data" ? 1 : 0,
		},
		td: {
			paddingHorizontal: 4,
			justifyContent: "center",
			width: "100%",
			textAlign: "center",
			fontSize: type === "header" ? 8 : 9,

			borderTopColor: chakraThemeColorsMap.silver,
			borderLeftColor: chakraThemeColorsMap.silver,
			borderRightColor: chakraThemeColorsMap.silver,
			borderBottomColor: chakraThemeColorsMap.silver,

			borderTopWidth: 0,
			borderLeftWidth: 0,
			borderRightWidth: 0,
			borderBottomWidth: 0,
		},
	};

	if (index === 0 && ["footer", "blank"].includes(type)) {
		styles.tr.borderTopWidth = 1;
	}

	if (length === 0) {
		styles.tr.borderTopWidth = type === "footer" ? 1 : 0;
		styles.tr.borderLeftWidth = 0;
		styles.tr.borderRightWidth = 0;
		styles.tr.backgroundColor = chakraThemeColorsMap.none;
	} else if (length === index + 1 && !["header", "blank"].includes(type)) {
		styles.tr.borderBottomWidth = 1;
	}

	if ((length === 1 && index === 0) || (length > 1 && length === index + 1)) {
		if (type === "data") {
			styles.tr.borderBottomWidth = 0;
			styles.td.borderRightWidth = 0;
		}
	}

	return styles;
}

function getTdStyles(data = {}, column = {}, length = 1, index = 0, type = "data") {
	let styles = {};
	let accessor = column.id;
	if (typeof accessor === "function") {
		accessor = accessor.name;
	}
	let subtotalRowBg = data[accessor + "Bg"] ?? chakraThemeColorsMap.none;

	// OVERRIDE SUBTOTAL ROWS -2/9/2023  MAKE THE WHOLE ROW PRINT WHITESMOKE BACKGROUND COLOR
	// TO DO: OMAR/DUSTIN - we should create a "tr" style for the subsection template that allows us to define an entire row color
	if (Boolean(data?.rowType === "Subtotal")) {
		subtotalRowBg = chakraThemeColorsMap.whitesmoke;
	}

	styles[accessor] = {
		height: data[accessor + "Height"] ?? column?.height ?? 16,
		width: data[accessor + "Width"] ?? column?.width ?? "100%",
		justifyContent: data[accessor + "JustifyContent"] ?? column?.justifyContent ?? "center",
		paddingHorizontal: (data[accessor + "Px"] ?? column?.px ?? 2) * 2,
		paddingVertical: (data[accessor + "Py"] ?? column?.py ?? 0) * 2,

		// OVERRIDE SUBTOTAL ROWS -2/9/2023
		backgroundColor: subtotalRowBg,
		// backgroundColor: data[accessor + "Bg"] ?? chakraThemeColorsMap.none,

		borderRightColor: data[accessor + "BorderRightColor"] ?? chakraThemeColorsMap.silver,
		borderRightWidth: data[accessor + "BorderRightWidth"] ?? ["header", "footer"].includes(type) ? 0 : 1,
		borderBottomColor: data[accessor + "BorderBottomColor"] ?? chakraThemeColorsMap.silver,
		borderBottomWidth: data[accessor + "BorderBottomWidth"] ?? 0,
	};

	styles[accessor + "Text"] = {
		width: "100%",
		lineHeight: data[accessor + "LineHeight"] ?? column?.lineHeight ?? 1,
		textAlign: data[accessor + "TextAlign"] ?? column?.textAlign ?? "left",
		fontSize: data[accessor + "FontSize"] ?? 9,

		textTransform:
			data[accessor + "TextTransform"] ??
			column?.textTransform ??
			(["footer"].includes(type) ? "uppercase" : "none"),
	};

	if ((length === 1 && index === 0) || (length > 1 && length === index + 1)) {
		styles[accessor].borderRightWidth = data[accessor + "BorderRightWidth"] ?? 0;
	}

	return styles;
}
