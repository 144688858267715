import { useCallback, useMemo, useState } from "react";
import { Container, Stack, Divider, Text, useBreakpointValue, Icon, Circle } from "@chakra-ui/react";
import { HiCheck } from "react-icons/hi";

export default function ProgressStepsExamplePage() {
	const steps = [
		{
			title: "Step 1",
			description: "Description",
		},
		{
			title: "Step 2",
			description: "Description",
		},
		{
			title: "Step 3",
			description: "Description",
		},
		{
			title: "Step 4",
			description: "Description",
		},
		{
			title: "Step 5",
			description: "Description",
		},
	];

	const [currentStep, { setStep }] = useStep({
		maxStep: steps.length,
		initialStep: 1,
	});
	return (
		<Container
			py={{
				base: "4",
				md: "8",
			}}
			w="full"
			minW="container.lg"
		>
			<Stack
				spacing="0"
				direction={{
					base: "column",
					md: "row",
				}}
			>
				{steps.map((step, id) => (
					<Step
						key={id}
						cursor="pointer"
						onClick={() => setStep(id)}
						title={step.title}
						description={step.description}
						isActive={currentStep === id}
						isCompleted={currentStep > id}
						isFirstStep={id === 0}
						isLastStep={steps.length === id + 1}
					/>
				))}
			</Stack>
		</Container>
	);
}

const Step = (props) => {
	const { isActive, isCompleted, isLastStep, isFirstStep, title, description, ...stackProps } = props;
	const isMobile = useBreakpointValue({
		base: true,
		md: false,
	});
	const orientation = useBreakpointValue({
		base: "vertical",
		md: "horizontal",
	});
	return (
		<Stack
			spacing="4"
			direction={{
				base: "row",
				md: "column",
			}}
			flex="1"
			{...stackProps}
		>
			<Stack
				spacing="0"
				align="center"
				direction={{
					base: "column",
					md: "row",
				}}
			>
				<Divider
					display={isMobile ? "none" : "initial"}
					orientation={orientation}
					borderWidth="2px"
					borderColor={isFirstStep ? "transparent" : isCompleted || isActive ? "teal.500" : "inherit"}
				/>
				<StepCircle isActive={isActive} isCompleted={isCompleted} />
				<Divider
					orientation={orientation}
					borderWidth="2px"
					borderColor={isCompleted ? "teal.500" : isLastStep ? "transparent" : "inherit"}
				/>
			</Stack>
			<Stack
				spacing="0.5"
				pb={isMobile && !isLastStep ? "8" : "0"}
				align={{
					base: "start",
					md: "center",
				}}
			>
				<Text fontSize="lg" color="gray.500" fontWeight="semibold">
					{title}
				</Text>
				<Text color="gray.400">{description}</Text>
			</Stack>
		</Stack>
	);
};

const StepCircle = ({ isCompleted, isActive }) => {
	return (
		<Circle
			size="8"
			bg={isCompleted ? "teal.500" : "inherit"}
			borderWidth={isCompleted ? "0" : "2px"}
			borderColor={isActive ? "teal.500" : "inherit"}
		>
			{isCompleted ? (
				<Icon as={HiCheck} color="white" boxSize="6" />
			) : (
				<Circle bg={isActive ? "teal.500" : "gray.500"} size="3" />
			)}
		</Circle>
	);
};

const useStep = (props) => {
	const { maxStep, initialStep = 0 } = props;
	const [currentStep, setCurrentStep] = useState(initialStep);
	const canGoToNextStep = useMemo(() => currentStep + 1 <= maxStep, [currentStep, maxStep]);
	const canGoToPrevStep = useMemo(() => currentStep - 1 >= 0, [currentStep]);
	const setStep = useCallback(
		(step) => {
			const newStep = step instanceof Function ? step(currentStep) : step;
			if (newStep >= 0 && newStep <= maxStep) {
				setCurrentStep(newStep);
				return;
			}
			throw new Error("Step not valid");
		},
		[maxStep, currentStep]
	);
	const goToNextStep = useCallback(() => {
		if (canGoToNextStep) {
			setCurrentStep((step) => step + 1);
		}
	}, [canGoToNextStep]);
	const goToPrevStep = useCallback(() => {
		if (canGoToPrevStep) {
			setCurrentStep((step) => step - 1);
		}
	}, [canGoToPrevStep]);
	const reset = useCallback(() => {
		setCurrentStep(0);
	}, []);
	return [
		currentStep,
		{
			goToNextStep,
			goToPrevStep,
			canGoToNextStep,
			canGoToPrevStep,
			setStep,
			reset,
		},
	];
};
