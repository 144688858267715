import UsersAPI from "../../../APIs/UsersAPI";
import { portalUser } from "../../../App";
import { v4 } from "uuid";
import ANNOUNCEMENT_TYPES from "../constants/AnnouncmentTypes";
import EMPLOYEE_TYPES from "../constants/EmployeeTypes";
import VISIBILITY_TYPES from "../constants/VisibilityTypes";
import moment from "moment";
import { formatDate, log } from "../../../helperFunctions";

export default class Announcement {
	constructor(announcement) {
		this.usersAPI = new UsersAPI();
		this.queryData = announcement?.queryData ?? announcement?.QueryData ?? "Announcement";
		this.announcementUID =
			announcement?.announcementUID?.toLowerCase() ?? announcement?.AnnouncementUID?.toLowerCase() ?? v4();
		this.keyID = announcement?.keyID ?? announcement?.KeyID ?? null;

		this.seq = announcement?.seq ?? announcement?.Seq ?? 0;
		this.type = announcement?.type ?? announcement?.Type ?? "announcement";

		this.description = announcement?.description ?? announcement?.Description ?? null;

		this.regionID = announcement?.regionID ?? announcement?.RegionID ?? null;
		this.deptID = announcement?.deptID ?? announcement?.DeptID ?? null;
		this.employeeType = announcement?.employeeType ?? announcement?.EmployeeType ?? null;

		this.redirectURL = announcement?.redirectURL ?? announcement?.RedirectURL ?? null;
		this.urlDisplayText = announcement?.urlDisplayText ?? announcement?.URLDisplayText ?? null;

		this.dateCreated = formatDate(announcement?.datePosted ?? announcement?.DatePosted) ?? new Date();
		this.createdBy = announcement?.createdBy?.toLowerCase() ?? announcement?.CreatedBy?.toLowerCase() ?? null;
		this.datePosted = formatDate(announcement?.datePosted ?? announcement?.DatePosted) ?? new Date();
		this.postedBy = announcement?.postedBy?.toLowerCase() ?? announcement?.PostedBy?.toLowerCase() ?? null;

		this.isVisibleYN = announcement?.isVisibleYN ?? announcement?.IsVisibleYN ?? "Y";
	}

	getAnnouncementTypes() {
		return ANNOUNCEMENT_TYPES;
	}

	getAnnouncementType() {
		return ANNOUNCEMENT_TYPES?.find((d) => d.value === this.type) ?? null;
	}

	getEmployeeTypes() {
		return EMPLOYEE_TYPES;
	}

	getEmployeeType() {
		return EMPLOYEE_TYPES?.find((d) => d.value === this.employeeType);
	}

	getIsVisibleYN() {
		return VISIBILITY_TYPES;
	}

	// SQL INSERT ANNOUNCEMENT
	async insertSQL() {
		if (!Boolean(this.keyID)) {
			let data = {
				announcementUID: this.announcementUID,
				seq: this.seq,
				type: this.type,
				description: this.description,
				regionID: this.regionID,
				deptID: this.deptID,
				employeeType: this.employeeType,
				redirectURL: this.redirectURL,
				urlDisplayText: this.urlDisplayText,
				dateCreated: moment(this.dateCreated).format("MM/DD/YYYY"),
				createdBy: this.createdBy ?? portalUser?.user?.employeeUID,
				datePosted: moment(this.datePosted).format("MM/DD/YYYY"),
				postedBy: this.postedBy ?? portalUser?.user?.employeeUID,
				isVisibleYN: this.isVisibleYN,
			};

			let result = await this.usersAPI.PortalUser_InsertAnnouncement(data);
			log("DATABASE RESULT INSERT ANNOUNCEMENTS", result);
			if (result?.status === 200 && result?.value?.length > 0) {
				result = result.value[0];
				this.keyID = result?.KeyID;
			}
		}
	}

	// SQL UPDATE ANNOUNCEMENT
	async updateSQL(data) {
		if (Boolean(this.keyID)) {
			let result = await this.usersAPI.PortalUser_UpdateAnnouncement(this.keyID, data);
			log("DATABASE RESULT UPDATE ANNOUNCEMENTS", result);
			return result;
		}
	}

	// SQL DELETE ANNOUNCEMENT
	async deleteSQL() {
		if (Boolean(this.keyID)) {
			let deleteResult = await this.usersAPI.PortalUser_DeleteAnnouncement(this.keyID);
			if (deleteResult.status === 200) {
				log("Delete Announcement Successful", deleteResult.value);
				return true;
			} else {
				log("Delete Announcement Error", deleteResult);
				return false;
			}
		}
	}

	getAttribute(attribute) {
		if (attribute === "announcementUID") {
			return "AnnouncementUID";
		} else if (attribute === "seq") {
			return "Seq";
		} else if (attribute === "type") {
			return "Heading";
		} else if (attribute === "isVisibleYN") {
			return "isVisible";
		} else if (attribute === "description") {
			return "Description";
		} else if (attribute === "redirectURL") {
			return "Url";
		} else if (attribute === "urlDisplayText") {
			return "LinkText";
		} else if (attribute === "regionID") {
			return "RegionID";
		} else if (attribute === "deptID") {
			return "DeptID";
		} else if (attribute === "employeeType") {
			return "EmployeeType";
		} else if (attribute === "dateCreated") {
			return "DateCreated";
		} else if (attribute === "createdBy") {
			return "CreatedBy";
		} else if (attribute === "datePosted") {
			return "DatePosted";
		} else if (attribute === "postedBy") {
			return "PostedBy";
		}
	}

	async update(attribute = null, value = null) {
		// log(attribute, value);
		let strings = [
			"type",
			"description",
			"redirectURL",
			"urlDisplayText",
			"regionID",
			"deptID",
			"employeeType",
			"postedBy",
		];
		let boolean = ["isVisibleYN"];
		let numbers = ["seq"];
		let dates = ["datePosted", "dateCreated"];

		let val = null;

		// STRINGS
		if (strings.includes(attribute)) {
			val = value?.replace("'", "`") ?? null;
			if (attribute !== "description") {
				val = val?.replace(/\n/g, " ")?.trim() ?? null;
			}
			if (["regionID", "deptID"].includes(attribute) && val === "All") {
				val = null;
			}
			this[attribute] = value;
		}

		// BOOLEAN (Y/N String to True/False)
		else if (boolean.includes(attribute)) {
			if (value === "Y") {
				val = 1;
			} else {
				val = 0;
			}
			this[attribute] = value;
		}

		// NUMBERS
		else if (numbers.includes(attribute)) {
			val = parseFloat(
				value?.toString()?.replace("$", "")?.replace(",", "")?.replace(" ", "")?.replace("%", "") ?? "0"
			);
			if (!Boolean(val) || isNaN(val)) {
				val = 0;
			}

			this[attribute] = val;
		}

		// DATES
		else if (dates.includes(attribute)) {
			if (Boolean(value)) {
				val = moment(new Date(value)).format("MM/DD/YYYY");
				this[attribute] = val;
			}
		}

		// Update
		let dbAttribute = this.getAttribute(attribute);
		let update = { [dbAttribute]: val };
		if (Boolean(this.keyID)) {
			let result = await this.updateSQL(update);
			return result;
		}
	}
}
