import React, { useMemo } from "react";
import { Heading, Stack } from "@chakra-ui/react";
import DataInputSelectLegacy from "../common/DataInputSelectLegacy";
// import { log } from "../../../helperFunctions";

export default function ResourceGroupSelect({
	page = "timecard",
	tabVal,
	changeResourceGroup,
	availableResourceGroups = [],
	activeGroup,
	isDisabled,
	disabledGroups,
}) {
	const resourceGroupOptions = useMemo(() => availableResourceGroups ?? [], [availableResourceGroups]);

	const selectResourceGroup = async (group) => {
		let level = null;
		if (page === "payroll") {
			level = "review";
		} else if (tabVal === 1) {
			level = "crewEntry";
		} else if (tabVal === 2) {
			level = "approval";
		} else if (tabVal === 3) {
			level = "management";
		}
		await changeResourceGroup(level, group);
	};

	return (
		<Stack
			direction={{ base: "column", lg: "row" }}
			align={{ base: "flex-start", lg: "center" }}
			justify="flex-start"
			spacing={{ base: 1, lg: 3 }}
		>
			<Heading fontSize="2xl" color="gray.500" isTruncated letterSpacing={2} textTransform="uppercase">
				Resource Group
			</Heading>

			<DataInputSelectLegacy
				key={activeGroup?.groupUID}
				minW="xs"
				w={{ base: "full", lg: "inherit" }}
				name="resourceGroups"
				fontSize="lg"
				fontWeight="bold"
				letterSpacing={1}
				color={activeGroup?.groupName ? "gray.500" : "gray.400"}
				selectOptions={resourceGroupOptions}
				value={activeGroup?.groupName ?? "Loading..."}
				onChange={selectResourceGroup}
				disabledItems={disabledGroups}
				title={"groupName"}
				subtitle={"groupDescription"}
			/>
		</Stack>
	);
}
