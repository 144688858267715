import PayrollAPI from "../../../APIs/PayrollAPI";
import PayPeriod from "../../Payroll/classes/PayPeriod";
import TimecardJob from "../../Payroll/classes/TimecardJob";
import TimecardPhase from "../../Payroll/classes/TimecardPhase";
import TimecardHeader from "../../Payroll/classes/TimecardHeader";
import TimecardRow from "../../Payroll/classes/TimecardRow";
// import { log, convertArrayToMap } from "../helperFunctions";

export default class PayrollData {
	constructor(data) {
		if (data instanceof PayrollData) {
			Object.assign(this, data);
		} else {
			//PAYROLL
			this.payrollAPI = data?.payrollAPI ?? new PayrollAPI();
			this.prEndDate = data?.prEndDate ?? null;
			this.payPeriods = data?.payPeriods ?? []; //Load on init of Payroll Module
			this.employees = data?.employees ?? [];
			this.leaders = data?.leaders ?? [];
			this.timecardJobs = data?.timecardJobs ?? [];
			this.timecardPhases = data?.timecardPhases ?? [];
			this.timecardHeaders = data?.timecardHeaders ?? [];
			this.timecardRows = data?.timecardRows ?? [];
			this.resourceGroups = data?.resourceGroups ?? [];
			this.resourceMembers = data?.resourceMembers ?? [];
			this.paystubs = data?.paystubs ?? [];
		}
	}

	classifyData(data = this, sqlData = []) {
		for (let i = 0; i < sqlData?.length; i++) {
			let queryData = sqlData[i]?.queryData;
			if (queryData === "Employee") {
				data.employees.push(sqlData[i]);
			} else if (queryData === "PayPeriod") {
				data.payPeriods.push(new PayPeriod(sqlData[i]));
			} else if (queryData === "TimecardHeader") {
				data.timecardHeaders.push(new TimecardHeader(sqlData[i]));
			} else if (queryData === "TimecardRow") {
				data.timecardRows.push(new TimecardRow(sqlData[i]));
			} else if (queryData === "TimecardJob") {
				data.timecardJobs.push(new TimecardJob(sqlData[i]));
			} else if (queryData === "TimecardPhase") {
				data.timecardPhases.push(new TimecardPhase(sqlData[i]));
			}
		}

		return data;
	}

	async initPayrollData(params = null) {
		let data = this;

		if (Boolean(params?.msUserID) && !Boolean(data?.payrollAPI)) {
			let payrollAPI = null; //new PayrollAPI();
			let sqlData = null; //await userAPI.InitUser(params);
			if (sqlData?.status === 200) {
				sqlData = sqlData?.value ?? [];
				data = this.classifyData(data, sqlData);
				data.payrollAPI = payrollAPI;
			}
		}
		return data;
	}
}
