import { formatValue } from "../../../helperFunctions";

export default class Project {
	constructor(project) {
		if (project instanceof Project) {
			Object.assign(this, project);
		} else {
			this.queryData = project?.queryData ?? "Project";
			this.accessYN = project?.accessYN ?? "N";

			// Project Info
			this.jccmKeyID = project?.jccmKeyID ?? null;
			this.jcco = project?.jcco ?? "1";
			this.contract = project?.contract ?? null;
			this.contractName = project?.contractName ?? null;
			this.contractStatus = project?.contractStatus ?? "1";
			this.job = project?.project ?? null;

			this.regionID = project?.regionID ?? project?.RegionID ?? null;
			this.tradeID = project?.tradeID ?? project?.TradeID ?? null;

			// Project Team
			this.custGroup = project?.custGroup ?? "1";
			this.customer = project?.customer ?? null;
			this.customerName = project?.customerName ?? null;
			this.customerPhone = project?.customerPhone ?? null;
			this.ownerName = project?.ownerName ?? null;
			this.ownerPhone = project?.ownerPhone ?? null;
			// this.pmEmployeeUID = project?.pmEmployeeUID ?? project?.PMEmployeeUID ?? null;
			// this.projectMgrID = project?.projectMgrID ?? project?.ProjectMgrID ?? null;
			this.pmName = project?.pmName ?? null;
			this.pmCell = project?.pmCell ?? null;
			this.projectAcctID = project?.projectAcctID ?? "N/A";
			this.paName = project?.paName ?? null;
			this.purchaserID = project?.purchaserID ?? "N/A";
			this.purchaserName = project?.purchaserName ?? null;
			// this.peName = project?.peName ?? project?.PEName ?? null;
			// this.peCell = project?.peCell ?? project?.PECell ?? null;
			// this.foremanName = project?.foremanName ?? project?.ForemanName ?? null;
			// this.foremanCell = project?.foremanCell ?? project?.ForemanCell ?? null;

			// this.companyDesc = project?.companyDesc ?? project?.CompanyDesc ?? null;
			this.region = project?.region ?? null;
			// this.regionDesc = project?.regionDesc ?? project?.RegionDesc ?? null;

			// Project Info
			this.contractTypeID = project?.contractTypeID ?? "1";
			this.contractType = project?.contractType ?? null;
			this.projectTypeID = project?.projectTypeID ?? "Unspecified";
			this.projectType = project?.projectType ?? null;
			this.platformID = project?.platformID ?? "3";
			this.platform = project?.platform ?? null;
			this.insuranceTypeID = project?.insuranceTypeID ?? "1";
			this.insuranceType = project?.insuranceType ?? null;

			// // Project Address
			this.jobAddress = project?.jobAddress ?? "Missing Project Address";
			this.jobCity = project?.jobCity ?? null;
			this.jobState = project?.jobState ?? null;
			this.jobZip = project?.jobZip ?? null;

			// // Scope of Work %
			// this.pctPLUM = project?.pctPLUM ?? project?.PctPLUM ?? 0;
			// this.pctHVAC = project?.pctHVAC ?? project?.PctHVAC ?? 0;
			// this.pctPIPE = project?.pctPIPE ?? project?.PctPIPE ?? 0;
			// this.pctFUEL = project?.pctFUEL ?? project?.PctFUEL ?? 0;
			// this.pctPROC = project?.pctPROC ?? project?.PctPROC ?? 0;
			// this.pctFIRE = project?.pctFIRE ?? project?.PctFIRE ?? 0;
			// this.pctSITE = project?.pctSITE ?? project?.PctSITE ?? 0;

			// // Diversity Requirements
			// this.pctLOCAL = project?.pctLOCAL ?? project?.PctLocalHire ?? 0;
			// this.pctMBE = project?.pctMBE ?? project?.PctMBE ?? 0;
			// this.pctSBE = project?.pctSBE ?? project?.PctSBE ?? 0;
			// this.pctWBE = project?.pctWBE ?? project?.PctWBE ?? 0;
			// this.pctDBE = project?.pctDBE ?? project?.PctDBE ?? 0;
			// this.pctDVBE = project?.pctDVBE ?? project?.PctDVBE ?? 0;
			// this.pctLBE = project?.pctLBE ?? project?.PctLBE ?? 0;
			// this.pctCBE = project?.pctCBE ?? project?.PctCBE ?? 0;
			// this.pctLSBE = project?.pctLSBE ?? project?.PctHUB ?? 0;

			// //AdditionalInfo
			// this.bondedYN = project?.bondedYN ?? project?.BondedYN ?? "N";
			// this.billingDue = project?.billingDue ?? project?.BillDayOfMth ?? 15;
			// this.certifiedYN = project?.certifiedYN ?? project?.CertifiedYN ?? "N";
			// this.hrsPerManDay = project?.hrsPerManDay ?? project?.HrsPerManDay ?? 8;

			// //Project Mths
			this.startMth = formatValue(project?.startMth, 0, "mth") ?? null;
			this.endMth = formatValue(project?.endMth, 0, "mth") ?? null;
			this.thruMth = formatValue(project?.thruMth, 0, "mth") ?? null;
			this.lastProjDate = formatValue(project?.lastProjDate, 0, "mth") ?? null;
		}
	}
}
