import { v4 } from "uuid";
import UsersAPI from "../../../APIs/UsersAPI";
import { portalUser } from "../../../App";

export default class EmergencyContact {
	constructor(contact) {
		if (contact instanceof EmergencyContact) {
			Object.assign(this, contact);
		} else {
			this.usersAPI = new UsersAPI();
			this.contactUID = contact?.ContactUID ?? v4();
			this.seq = contact?.Seq ?? null;
			this.name = contact?.Contact ?? null;
			this.relationship = contact?.Relationship ?? null;
			this.workPhone = contact?.WorkPhone ?? "";
			this.homePhone = contact?.HomePhone ?? "";
			this.email = contact?.Email ?? null;
		}
	}

	async upload() {
		let result = await this.usersAPI.InsertEmergencyContact(portalUser.user.employeeUID, this.contactUID, this);
		return result;
	}

	async updateDB() {
		let result = await this.usersAPI.UpdateEmergencyContact(portalUser.user.employeeUID, this.contactUID, this);
		if (result.status === 200) {
			return true;
		} else {
			return false;
		}
	}

	async delete() {
		let result = await this.usersAPI.DeleteEmergencyContact(portalUser.user.employeeUID, this.contactUID);
		return result;
	}

	async updateContactName(value) {
		this.name = value.replace("'", "`");
		await this.updateDB();
	}

	async updateRelationship(value) {
		this.relationship = value.replace("'", "`");
		await this.updateDB();
	}
	async updateEmail(value) {
		this.email = value.replace("'", "`");
		await this.updateDB();
	}
	async updateHomePhone(value) {
		this.homePhone = value.replace("'", "`");
		await this.updateDB();
	}
	async updateWorkPhone(value) {
		this.workPhone = value.replace("'", "`");
		await this.updateDB();
	}
}
