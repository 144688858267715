import { formatValue } from "../../../helperFunctions";

export default class BillingsByMth {
	constructor(billing) {
		this.queryData = billing?.queryData ?? billing?.QueryData ?? "BillingsByMth";
		this.jccmKeyID = billing?.jccmKeyID ?? billing?.JCCMKeyID ?? null;
		this.jcco = billing?.jcco ?? billing?.JCCo ?? null;
		this.contract = billing?.contract ?? billing?.Contract?.trim() ?? null;
		this.type = billing?.type ?? billing?.ARTransGroup ?? null;
		this.arTransGroup = billing?.arTransGroup ?? billing?.ARTransGroup ?? null;
		this.billNo = billing?.billNo ?? billing?.ARTransGroup ?? null;
		this.mth = formatValue(billing?.mth ?? billing?.Mth, 0, "date") ?? null;
		this.netBillingAmt = billing?.netBillingAmt ?? billing?.NetBilling ?? 0;
		this.paidAmt = billing?.paidAmt ?? billing?.PaidAmt ?? 0;
		this.dateSubmitted = formatValue(billing?.dateSubmitted ?? billing?.DateSubmitted, "date") ?? null;
		this.dateDueBy = formatValue(billing?.dateDueBy ?? billing?.DueBy, "date") ?? null;
		this.datePaidOn = formatValue(billing?.datePaidOn ?? billing?.PaidOn, "date") ?? null;
		this.earlyLate = billing?.earlyLate ?? billing?.EarlyLate ?? null;
		this.expectedCollection =
			formatValue(billing?.expectedCollection ?? billing?.ExpectedCollection, 0, "date") ?? null;
		this.remainingAmt = this.getRemainingAmt();
	}

	getRemainingAmt() {
		return parseFloat(this.netBillingAmt ?? 0) - parseFloat(this.paidAmt);
	}
}
