import React from "react";
import { Switch, Route, Redirect, useParams, useLocation, useHistory } from "react-router-dom";

import Timecard from "../Timecard";
// import ResourceGroups from "../ResourceGroups";
import Paystubs from "../Paystubs";

import { portalUser } from "../../../App";
import { formatValue, log } from "../../../helperFunctions";

export default function TimecardRoutes({ pageName, tabVal }) {
	const location = useLocation();

	return (
		<Switch location={location}>
			{/* <Route path="/timecard/groups">
				<ResourceGroups pageName={pageName} tabVal={tabVal} />
			</Route> */}
			<Route path="/timecard/paystubs">
				<Paystubs pageName={pageName} tabVal={tabVal} />
			</Route>
			<Route path="/timecard/:prEndDate">
				<TimecardRouteViews pageName={pageName} tabVal={tabVal} />
			</Route>
			<Route exact path="/timecard">
				<Redirect to={"/timecard/" + formatValue(portalUser?.user?.prEndDate, 0, "dateURL")} />
			</Route>
		</Switch>

		// <Switch location={location}>
		// <Route
		// 	exact
		// 	path={`/timecard`}
		// 	children={
		// 		<Redirect
		// 			to={`/timecard/${formatValue(
		// 				portalUser?.user?.prEndDate,
		// 				0,
		// 				"dateURL"
		// 			)}/${selectedTab.tab}`}
		// 		/>
		// 	}
		// />
		// <Route
		// 	exact
		// 	path={`/timecard/:tab`}
		// 	children={
		// 		<TimecardRouteViews timecardUser={timecardUser} tab={selectedTab.tab} />
		// 	}
		// />
		// <Route
		// 	path={`/timecard/:prEndDate/:tab`}
		// 	children={<TimecardRouteViews tab={selectedTab.tab} />}
		// />
		// </Switch>
	);
}

function TimecardRouteViews({ pageName, tabVal }) {
	let { prEndDate } = useParams();
	let history = useHistory();

	let basepath = history.location.pathname;
	if (basepath?.length > 0 && basepath?.charAt(0) === "/") {
		basepath = basepath?.slice(1);
		basepath = basepath?.substring(0, basepath?.indexOf("/"));
	}

	// prEndDate = formatValue(prEndDate, 0, "date");

	// if (basepath === "payroll") {
	// 	if (tabVal === 0) {
	// 		history.push("/payroll/upload/" + prEndDate + "/uploadtime");
	// 	} else if (tabVal === 1) {
	// 		history.push("/payroll/upload/" + prEndDate + "/postedtime");
	// 	} else if (tabVal === 2) {
	// 		history.push("/payroll/upload/" + prEndDate + "/exceptions");
	// 	}
	// } else {
	// 	if (tabVal === 0) {
	// 		history.push("/timecard/" + prEndDate + "/mytime");
	// 	} else if (tabVal === 1) {
	// 		history.push("/timecard/" + prEndDate + "/crewtime");
	// 	} else if (tabVal === 2) {
	// 		history.push("/timecard/" + prEndDate + "/approvetime");
	// 	} else if (tabVal === 4) {
	// 		history.push("/timecard/" + prEndDate + "/jobreview");
	// 	}
	// }

	if (!Boolean(prEndDate) || prEndDate === "mytime") {
		tabVal = 0;
		history.push("/timecard/" + formatValue(portalUser.user?.prEndDate, 0, "dateURL") + "/mytime");
	} else if (prEndDate === "crewtime") {
		tabVal = 1;
		history.push("/timecard/" + formatValue(portalUser.user?.prEndDate, 0, "dateURL") + "/crewtime");
	} else if (prEndDate === "approvetime") {
		tabVal = 2;
		history.push("/timecard/" + formatValue(portalUser.user?.prEndDate, 0, "dateURL") + "/approvetime");
	}

	return <Timecard pageName={pageName} tabVal={tabVal} prEndDate={formatValue(prEndDate, 0, "date")} />;
}
