import React, { useState, useCallback } from "react";
import MainContent from "../../core/ReactTable/layout/MainContent";
import HRAdminRoutes from "./routes/HRAdminRoutes";
import HR_ADMIN_TABS from "./constants/HRAdminTabs";
// import { JOYRIDE_MYTEAM, JOYRIDE_MYTEAM_PERFORMANCEREVIEWS } from "../../joyride";

export default function HRAdmin(props) {
	const [[currentTab, direction], setCurrentTab] = useState([props.tabVal, 0]);
	const setTab = useCallback(
		(newTab, newDirection) => {
			if (!newDirection) newDirection = newTab - currentTab;
			setCurrentTab([newTab, newDirection]);
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		},
		[currentTab]
	);

	const [runJoyride, setRunJoyride] = useState(false);
	const joyrideCallback = (joyrideState) => {
		if (
			joyrideState.action === "reset" ||
			joyrideState.action === "close" ||
			joyrideState.action === "skip" ||
			joyrideState.action === "last"
		) {
			setRunJoyride(false);
		}
	};

	// let tabs = tabsArr.map((tab) => tab.name);
	// let joyrideSteps = tabsArr[currentTab]?.joyrideSteps ?? null;

	return (
		<MainContent
			currentTab={currentTab}
			onTabChange={setTab}
			title={HR_ADMIN_TABS[currentTab]?.heading}
			subtitle={HR_ADMIN_TABS[currentTab]?.subheading}
			styles={{ bg: "blackAlpha.400" }}
			// tabs={tabs}

			runJoyride={runJoyride}
			// joyrideSteps={joyrideSteps}
			joyrideCallback={joyrideCallback}
			startJoyride={() => setRunJoyride(true)}
			{...props}
		>
			<HRAdminRoutes
				HR_ADMIN_TABS={HR_ADMIN_TABS}
				currentTab={currentTab}
				direction={direction}
				setTab={setTab}
			/>
		</MainContent>
	);
}
