import {
	getSubtotal,
	getRate,
	sumProduct,
	// log,
	// chakraThemeColorsMap,
	// getValueColor,
} from "../../../helperFunctions";
import moment from "moment";

export default class SectionTemplateManpowerActuals {
	constructor(contractTotalsByMth = [], contractTotals = null) {
		this.template = "ManpowerActuals";
		this.name = "Manpower Actuals ";
		this.heading = "Manpower Actuals";
		this.divider = false;
		this.wrap = true;
		this.pagebreak = 0;
		this.type = "virtualTable";
		this.minRows = 1;
		this.maxRows = 12;
		this.maxRowsPDF = 6;
		this.sliceType = "last";
		this.show = contractTotalsByMth?.length > 0 ? true : false;
		this.subSections = [new SubSectionTemplateManpowerActuals(contractTotalsByMth, contractTotals)];
	}
}

class SubSectionTemplateManpowerActuals {
	constructor(contractTotalsByMth = [], contractTotals = null) {
		this.template = "ManpowerActuals";
		this.type = "virtualTable";
		this.heading = "Manpower Actuals";
		this.width = "100%";
		this.wrap = true;

		this.autoExpandRows = true;

		this.columns = [
			{
				accessor: "year",
				Header: "Year",
				valueType: "integer",
				width: "85%",
				show: false,
			},
			{
				accessor: "mth",
				Header: "Month",
				valueType: "month",
				whiteSpace: "nowrap",
				placeHolderID: "year",
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "workDays",
				Header: "Work Days",
				textAlign: "center",
				valueType: "number",
				aggregate: "average",
				width: "85%",
				show: false,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "workHours",
				Header: "Work Hours",
				textAlign: "center",
				valueType: "number",
				aggregate: "average",
				width: "85%",
				show: false,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "actualCrewSizeOFFC_delta",
				Header: "OFFICE",
				textAlign: "center",
				valueType: "number",
				width: "85%",
				decimals: 1,
				aggregate: "average",
				textTransform: "none",

				show: Boolean(contractTotals?.actualHoursOFFC + contractTotals?.projHoursOFFC > 0),
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "actualCrewSizeDETL_delta",
				Header: "DETAILING",
				textAlign: "center",
				valueType: "number",
				width: "85%",
				decimals: 1,
				aggregate: "average",
				rightAddon: "men",
				show: Boolean(contractTotals?.actualHoursDETL + contractTotals?.projHoursDETL > 0),
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "actualCrewSizePLUM_delta",
				Header: "PLUMBING",
				textAlign: "center",
				valueType: "number",
				width: "85%",
				decimals: 1,
				aggregate: "average",
				show: Boolean(contractTotals?.actualHoursPLUM + contractTotals?.projHoursPLUM > 0),
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "actualCrewSizeHVAC_delta",
				Header: "SHEET METAL",
				textAlign: "center",
				valueType: "number",
				width: "85%",
				decimals: 1,
				aggregate: "average",
				show: Boolean(contractTotals?.actualHoursHVAC + contractTotals?.projHoursHVAC > 0),
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "actualCrewSizePIPE_delta",
				Header: "PIPING",
				textAlign: "center",
				valueType: "number",
				width: "85%",
				decimals: 1,
				aggregate: "average",
				show: Boolean(contractTotals?.actualHoursPIPE + contractTotals?.projHoursPIPE > 0),
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "actualCrewSizeFUEL_delta",
				Header: "FUEL OIL",
				textAlign: "center",
				valueType: "number",
				width: "85%",
				decimals: 1,
				aggregate: "average",
				show: Boolean(contractTotals?.actualHoursFUEL + contractTotals?.projHoursFUEL > 0),
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "actualCrewSizePROC_delta",
				Header: "PROCESS PIPE",
				textAlign: "center",
				valueType: "number",
				width: "85%",
				decimals: 1,
				aggregate: "average",
				show: Boolean(contractTotals?.actualHoursPROC + contractTotals?.projHoursPROC > 0),
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "actualCrewSizeFIRE_delta",
				Header: "FIRE",
				textAlign: "center",
				valueType: "number",
				width: "85%",
				decimals: 1,
				aggregate: "average",
				show: Boolean(contractTotals?.actualHoursFIRE + contractTotals?.projHoursFIRE > 0),
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "actualCrewSizeSITE_delta",
				Header: "SITE UTITLIES",
				textAlign: "center",
				valueType: "number",
				width: "85%",
				decimals: 1,
				aggregate: "average",
				show: Boolean(contractTotals?.actualHoursSITE + contractTotals?.projHoursSITE > 0),
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "actualCrewSizeFABR_delta",
				Header: "PREFAB",
				textAlign: "center",
				valueType: "number",
				width: "85%",
				decimals: 1,
				aggregate: "average",
				show: Boolean(contractTotals?.actualHoursFABR + contractTotals?.projHoursFABR > 0),
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "actualCrewSizeSTRT_delta",
				Header: "START UP",
				textAlign: "center",
				valueType: "number",
				width: "85%",
				decimals: 1,
				aggregate: "average",
				show: Boolean(contractTotals?.actualHoursSTRT + contractTotals?.projHoursSTRT > 0),
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "actualCrewSizeCHNG_delta",
				Header: "CHANGE ORDERS",
				textAlign: "center",
				valueType: "number",
				width: "85%",
				decimals: 1,
				aggregate: "average",
				show: Boolean(contractTotals?.actualHoursCHNG + contractTotals?.projHoursCHNG > 0),
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "actualCrewSizeFIELD_delta",
				Header: "Field Manpower",
				textAlign: "center",
				valueType: "number",
				width: "85%",
				decimals: 1,
				aggregate: "average",
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "actualCrewSizeTOTAL_delta",
				Header: "Actual Manpower",
				textAlign: "center",
				valueType: "number",
				width: "85%",
				decimals: 1,
				aggregate: "average",
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "actualHours_delta",
				Header: "Actual Hours",
				valueType: "number",
				decimals: 1,
				width: "85%",
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "actualLaborRate_delta",
				Header: "Actual Rate",
				valueType: "currency",
				decimals: 2,
				width: "85%",
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "pctActualLabor",
				Header: "% Labor To Date",
				textAlign: "center",
				valueType: "percent",
				decimals: 1,
				show: false,
				width: "85%",
			},
			{
				accessor: "laborActualCost_delta",
				Header: "Labor Cost",
				valueType: "currency",
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
		];

		this.groupBy = [];

		this.sortBy = [
			{ id: "mth", desc: false },
			{ id: "contract", desc: false },
		];

		this.data = this.getRightAddon(contractTotalsByMth).reverse();

		this.footer = (rows = contractTotalsByMth, totals = contractTotals) => {
			return this.getFooter(rows, totals);
		};
	}

	getRightAddon(data = []) {
		for (let i = 0; i < data?.length; i++) {
			if (
				moment(new Date(data[i].mth)).format("MM/DD/YYYY") ===
				moment(new Date()).startOf("month").format("MM/DD/YYYY")
			) {
				data[i].mthRightAddon = " (To Date)";
				break;
			}
		}

		return data;
	}

	getFooter(rows = [], totals = null) {
		let men = {
			mth: "Average Manpower",
			mthType: "string",
			mthFontSize: 7,
			mthTextSize: "sm",

			workDays: 0,
			workDaysRightAddon: "days",
			workHours: 0,
			workHoursRightAddon: "hrs",

			actualCrewSizeOFFC_delta: 0,
			actualCrewSizeOFFC_deltaFontSize: 8,

			actualCrewSizeOFFC_deltaTextTransform: "lowercase",
			actualCrewSizeOFFC_deltaRightAddon: "men",

			actualCrewSizeDETL_delta: 0,
			actualCrewSizeDETL_deltaFontSize: 8,

			actualCrewSizeDETL_deltaTextTransform: "lowercase",
			actualCrewSizeDETL_deltaRightAddon: "men",

			actualCrewSizePLUM_delta: 0,
			actualCrewSizePLUM_deltaFontSize: 8,

			actualCrewSizePLUM_deltaTextTransform: "lowercase",
			actualCrewSizePLUM_deltaRightAddon: "men",

			actualCrewSizeHVAC_delta: 0,
			actualCrewSizeHVAC_deltaFontSize: 8,

			actualCrewSizeHVAC_deltaTextTransform: "lowercase",
			actualCrewSizeHVAC_deltaRightAddon: "men",

			actualCrewSizePIPE_delta: 0,
			actualCrewSizePIPE_deltaFontSize: 8,

			actualCrewSizePIPE_deltaTextTransform: "lowercase",
			actualCrewSizePIPE_deltaRightAddon: "men",

			actualCrewSizeFUEL_delta: 0,
			actualCrewSizeFUEL_deltaFontSize: 8,

			actualCrewSizeFUEL_deltaTextTransform: "lowercase",
			actualCrewSizeFUEL_deltaRightAddon: "men",

			actualCrewSizeFIRE_delta: 0,
			actualCrewSizeFIRE_deltaFontSize: 8,

			actualCrewSizeFIRE_deltaTextTransform: "lowercase",
			actualCrewSizeFIRE_deltaRightAddon: "men",

			actualCrewSizeSITE_delta: 0,
			actualCrewSizeSITE_deltaFontSize: 8,

			actualCrewSizeSITE_deltaTextTransform: "lowercase",
			actualCrewSizeSITE_deltaRightAddon: "men",

			actualCrewSizePROC_delta: 0,
			actualCrewSizePROC_deltaFontSize: 8,

			actualCrewSizePROC_deltaTextTransform: "lowercase",
			actualCrewSizePROC_deltaRightAddon: "men",

			actualCrewSizeSTRT_delta: 0,
			actualCrewSizeSTRT_deltaFontSize: 8,

			actualCrewSizeSTRT_deltaTextTransform: "lowercase",
			actualCrewSizeSTRT_deltaRightAddon: "men",

			actualCrewSizeFABR_delta: 0,
			actualCrewSizeFABR_deltaFontSize: 8,

			actualCrewSizeFABR_deltaTextTransform: "lowercase",
			actualCrewSizeFABR_deltaRightAddon: "men",

			actualCrewSizeCHNG_delta: 0,
			actualCrewSizeCHNG_deltaFontSize: 8,

			actualCrewSizeCHNG_deltaTextTransform: "lowercase",
			actualCrewSizeCHNG_deltaRightAddon: "men",

			actualCrewSizeFIELD_delta: 0,
			actualCrewSizeFIELD_deltaFontSize: 8,

			actualCrewSizeFIELD_deltaTextTransform: "lowercase",
			actualCrewSizeFIELD_deltaRightAddon: "men",

			actualCrewSizeTOTAL_delta: 0,
			actualCrewSizeTOTAL_deltaFontSize: 8,
			actualCrewSizeTOTAL_deltaRightAddon: "men",
			actualCrewSizeTOTAL_deltaTextTransform: "lowercase",
		};
		let actual = {
			mth: "Hours To Date",
			mthType: "string",
			mthFontSize: 7,
			mthTextSize: "sm",

			workDays: 0,
			workDaysRightAddon: "days",
			workHours: 0,
			workHoursRightAddon: "hrs",

			actualCrewSizeOFFC_delta: 0,
			actualCrewSizeOFFC_deltaFontSize: 8,

			actualCrewSizeOFFC_deltaTextTransform: "lowercase",
			actualCrewSizeOFFC_deltaRightAddon: "hrs",

			actualCrewSizeDETL_delta: 0,
			actualCrewSizeDETL_deltaFontSize: 8,

			actualCrewSizeDETL_deltaTextTransform: "lowercase",
			actualCrewSizeDETL_deltaRightAddon: "hrs",

			actualCrewSizePLUM_delta: 0,
			actualCrewSizePLUM_deltaFontSize: 8,

			actualCrewSizePLUM_deltaTextTransform: "lowercase",
			actualCrewSizePLUM_deltaRightAddon: "hrs",

			actualCrewSizeHVAC_delta: 0,
			actualCrewSizeHVAC_deltaFontSize: 8,

			actualCrewSizeHVAC_deltaTextTransform: "lowercase",
			actualCrewSizeHVAC_deltaRightAddon: "hrs",

			actualCrewSizePIPE_delta: 0,
			actualCrewSizePIPE_deltaFontSize: 8,

			actualCrewSizePIPE_deltaTextTransform: "lowercase",
			actualCrewSizePIPE_deltaRightAddon: "hrs",

			actualCrewSizeFUEL_delta: 0,
			actualCrewSizeFUEL_deltaFontSize: 8,

			actualCrewSizeFUEL_deltaTextTransform: "lowercase",
			actualCrewSizeFUEL_deltaRightAddon: "hrs",

			actualCrewSizeFIRE_delta: 0,
			actualCrewSizeFIRE_deltaFontSize: 8,

			actualCrewSizeFIRE_deltaTextTransform: "lowercase",
			actualCrewSizeFIRE_deltaRightAddon: "hrs",

			actualCrewSizeSITE_delta: 0,
			actualCrewSizeSITE_deltaFontSize: 8,

			actualCrewSizeSITE_deltaTextTransform: "lowercase",
			actualCrewSizeSITE_deltaRightAddon: "hrs",

			actualCrewSizePROC_delta: 0,
			actualCrewSizePROC_deltaFontSize: 8,

			actualCrewSizePROC_deltaTextTransform: "lowercase",
			actualCrewSizePROC_deltaRightAddon: "hrs",

			actualCrewSizeSTRT_delta: 0,
			actualCrewSizeSTRT_deltaFontSize: 8,

			actualCrewSizeSTRT_deltaTextTransform: "lowercase",
			actualCrewSizeSTRT_deltaRightAddon: "hrs",

			actualCrewSizeFABR_delta: 0,
			actualCrewSizeFABR_deltaFontSize: 8,

			actualCrewSizeFABR_deltaTextTransform: "lowercase",
			actualCrewSizeFABR_deltaRightAddon: "hrs",

			actualCrewSizeCHNG_delta: 0,
			actualCrewSizeCHNG_deltaFontSize: 8,

			actualCrewSizeCHNG_deltaTextTransform: "lowercase",
			actualCrewSizeCHNG_deltaRightAddon: "hrs",

			actualCrewSizeFIELD_delta: 0,
			actualCrewSizeFIELD_deltaFontSize: 8,

			actualCrewSizeFIELD_deltaTextTransform: "lowercase",
			actualCrewSizeFIELD_deltaRightAddon: "hrs",

			actualCrewSizeTOTAL_delta: 0,
			actualCrewSizeTOTAL_deltaFontSize: 8,
			actualCrewSizeTOTAL_deltaRightAddon: "hrs",
			actualCrewSizeTOTAL_deltaTextTransform: "lowercase",

			actualHours_delta: 0,
			actualHours_deltaTextAlign: "right",
			actualHours_deltaFontSize: 8,

			actualHours_deltaTextTransform: "lowercase",
			actualHours_deltaRightAddon: "hrs",

			actualLaborRate_delta: 0,
			actualLaborRate_deltaTextAlign: "right",
			actualLaborRate_deltaRightAddon: "/hr",
			actualLaborRate_deltaTextTransform: "lowercase",
			actualLaborRate_deltaFontSize: 8,

			laborActualCost_delta: 0,
			laborActualCost_deltaTextAlign: "right",
			laborActualCost_deltaFontSize: 8,

			laborActualCost_deltaTextTransform: "lowercase",
		};
		let proj = {
			mth: "Projected Hours",
			mthType: "string",
			mthFontSize: 7,
			mthTextSize: "sm",

			workDays: 0,
			workDaysRightAddon: "days",
			workHours: 0,
			workHoursRightAddon: "hrs",

			actualCrewSizeOFFC_delta: 0,
			actualCrewSizeOFFC_deltaFontSize: 8,

			actualCrewSizeOFFC_deltaTextTransform: "lowercase",
			actualCrewSizeOFFC_deltaRightAddon: "hrs",

			actualCrewSizeDETL_delta: 0,
			actualCrewSizeDETL_deltaFontSize: 8,

			actualCrewSizeDETL_deltaTextTransform: "lowercase",
			actualCrewSizeDETL_deltaRightAddon: "hrs",

			actualCrewSizePLUM_delta: 0,
			actualCrewSizePLUM_deltaFontSize: 8,

			actualCrewSizePLUM_deltaTextTransform: "lowercase",
			actualCrewSizePLUM_deltaRightAddon: "hrs",

			actualCrewSizeHVAC_delta: 0,
			actualCrewSizeHVAC_deltaFontSize: 8,

			actualCrewSizeHVAC_deltaTextTransform: "lowercase",
			actualCrewSizeHVAC_deltaRightAddon: "hrs",

			actualCrewSizePIPE_delta: 0,
			actualCrewSizePIPE_deltaFontSize: 8,

			actualCrewSizePIPE_deltaTextTransform: "lowercase",
			actualCrewSizePIPE_deltaRightAddon: "hrs",

			actualCrewSizeFUEL_delta: 0,
			actualCrewSizeFUEL_deltaFontSize: 8,

			actualCrewSizeFUEL_deltaTextTransform: "lowercase",
			actualCrewSizeFUEL_deltaRightAddon: "hrs",

			actualCrewSizeFIRE_delta: 0,
			actualCrewSizeFIRE_deltaFontSize: 8,

			actualCrewSizeFIRE_deltaTextTransform: "lowercase",
			actualCrewSizeFIRE_deltaRightAddon: "hrs",

			actualCrewSizeSITE_delta: 0,
			actualCrewSizeSITE_deltaFontSize: 8,

			actualCrewSizeSITE_deltaTextTransform: "lowercase",
			actualCrewSizeSITE_deltaRightAddon: "hrs",

			actualCrewSizePROC_delta: 0,
			actualCrewSizePROC_deltaFontSize: 8,

			actualCrewSizePROC_deltaTextTransform: "lowercase",
			actualCrewSizePROC_deltaRightAddon: "hrs",

			actualCrewSizeSTRT_delta: 0,
			actualCrewSizeSTRT_deltaFontSize: 8,

			actualCrewSizeSTRT_deltaTextTransform: "lowercase",
			actualCrewSizeSTRT_deltaRightAddon: "hrs",

			actualCrewSizeFABR_delta: 0,
			actualCrewSizeFABR_deltaFontSize: 8,

			actualCrewSizeFABR_deltaTextTransform: "lowercase",
			actualCrewSizeFABR_deltaRightAddon: "hrs",

			actualCrewSizeCHNG_delta: 0,
			actualCrewSizeCHNG_deltaFontSize: 8,

			actualCrewSizeCHNG_deltaTextTransform: "lowercase",
			actualCrewSizeCHNG_deltaRightAddon: "hrs",

			actualCrewSizeFIELD_delta: 0,
			actualCrewSizeFIELD_deltaFontSize: 8,

			actualCrewSizeFIELD_deltaTextTransform: "lowercase",
			actualCrewSizeFIELD_deltaRightAddon: "hrs",

			actualCrewSizeTOTAL_delta: 0,
			actualCrewSizeTOTAL_deltaFontSize: 8,
			actualCrewSizeTOTAL_deltaRightAddon: "hrs",
			actualCrewSizeTOTAL_deltaTextTransform: "lowercase",

			actualHours_delta: 0,
			actualHours_deltaTextAlign: "right",
			actualHours_deltaFontSize: 8,

			actualHours_deltaTextTransform: "lowercase",
			actualHours_deltaRightAddon: "hrs",

			actualLaborRate_delta: 0,
			actualLaborRate_deltaTextAlign: "right",
			actualLaborRate_deltaRightAddon: "/hr",
			actualLaborRate_deltaTextTransform: "lowercase",
			actualLaborRate_deltaFontSize: 8,

			laborActualCost_delta: 0,
			laborActualCost_deltaTextAlign: "right",
			laborActualCost_deltaFontSize: 8,

			laborActualCost_deltaTextTransform: "lowercase",
		};

		let remain = {
			mth: "Hours To Complete",
			mthType: "string",
			mthFontSize: 7,
			mthTextSize: "sm",

			workDays: 0,
			workDaysRightAddon: "days",
			workHours: 0,
			workHoursRightAddon: "hrs",

			actualCrewSizeOFFC_delta: 0,
			actualCrewSizeOFFC_deltaFontSize: 8,

			actualCrewSizeOFFC_deltaTextTransform: "lowercase",
			actualCrewSizeOFFC_deltaRightAddon: "hrs",

			actualCrewSizeDETL_delta: 0,
			actualCrewSizeDETL_deltaFontSize: 8,

			actualCrewSizeDETL_deltaTextTransform: "lowercase",
			actualCrewSizeDETL_deltaRightAddon: "hrs",

			actualCrewSizePLUM_delta: 0,
			actualCrewSizePLUM_deltaFontSize: 8,

			actualCrewSizePLUM_deltaTextTransform: "lowercase",
			actualCrewSizePLUM_deltaRightAddon: "hrs",

			actualCrewSizeHVAC_delta: 0,
			actualCrewSizeHVAC_deltaFontSize: 8,

			actualCrewSizeHVAC_deltaTextTransform: "lowercase",
			actualCrewSizeHVAC_deltaRightAddon: "hrs",

			actualCrewSizePIPE_delta: 0,
			actualCrewSizePIPE_deltaFontSize: 8,

			actualCrewSizePIPE_deltaTextTransform: "lowercase",
			actualCrewSizePIPE_deltaRightAddon: "hrs",

			actualCrewSizeFUEL_delta: 0,
			actualCrewSizeFUEL_deltaFontSize: 8,

			actualCrewSizeFUEL_deltaTextTransform: "lowercase",
			actualCrewSizeFUEL_deltaRightAddon: "hrs",

			actualCrewSizeFIRE_delta: 0,
			actualCrewSizeFIRE_deltaFontSize: 8,

			actualCrewSizeFIRE_deltaTextTransform: "lowercase",
			actualCrewSizeFIRE_deltaRightAddon: "hrs",

			actualCrewSizeSITE_delta: 0,
			actualCrewSizeSITE_deltaFontSize: 8,

			actualCrewSizeSITE_deltaTextTransform: "lowercase",
			actualCrewSizeSITE_deltaRightAddon: "hrs",

			actualCrewSizePROC_delta: 0,
			actualCrewSizePROC_deltaFontSize: 8,

			actualCrewSizePROC_deltaTextTransform: "lowercase",
			actualCrewSizePROC_deltaRightAddon: "hrs",

			actualCrewSizeSTRT_delta: 0,
			actualCrewSizeSTRT_deltaFontSize: 8,

			actualCrewSizeSTRT_deltaTextTransform: "lowercase",
			actualCrewSizeSTRT_deltaRightAddon: "hrs",

			actualCrewSizeFABR_delta: 0,
			actualCrewSizeFABR_deltaFontSize: 8,

			actualCrewSizeFABR_deltaTextTransform: "lowercase",
			actualCrewSizeFABR_deltaRightAddon: "hrs",

			actualCrewSizeCHNG_delta: 0,
			actualCrewSizeCHNG_deltaFontSize: 8,

			actualCrewSizeCHNG_deltaTextTransform: "lowercase",
			actualCrewSizeCHNG_deltaRightAddon: "hrs",

			actualCrewSizeFIELD_delta: 0,
			actualCrewSizeFIELD_deltaFontSize: 8,

			actualCrewSizeFIELD_deltaTextTransform: "lowercase",
			actualCrewSizeFIELD_deltaRightAddon: "hrs",

			actualCrewSizeTOTAL_delta: 0,
			actualCrewSizeTOTAL_deltaFontSize: 8,
			actualCrewSizeTOTAL_deltaRightAddon: "hrs",
			actualCrewSizeTOTAL_deltaTextTransform: "lowercase",

			actualHours_delta: 0,
			actualHours_deltaTextAlign: "right",
			actualHours_deltaFontSize: 8,

			actualHours_deltaTextTransform: "lowercase",
			actualHours_deltaRightAddon: "hrs",

			actualLaborRate_delta: 0,
			actualLaborRate_deltaTextAlign: "right",
			actualLaborRate_deltaRightAddon: "/hr",
			actualLaborRate_deltaTextTransform: "lowercase",
			actualLaborRate_deltaFontSize: 8,

			laborActualCost_delta: 0,
			laborActualCost_deltaTextAlign: "right",
			laborActualCost_deltaFontSize: 8,

			laborActualCost_deltaTextTransform: "lowercase",
		};

		// STATIC FOOTERS
		if (!Array.isArray(totals)) {
			if (Boolean(totals?.queryData === "ContractTotalsByMth" || rows?.length === 0)) {
				men.workDays = getSubtotal(rows, "workDays") ?? 0;
				men.workHours = getSubtotal(rows, "workHours") ?? 0;

				men.actualCrewSizeOFFC_delta = getSubtotal(rows, "actualCrewSizeOFFC_delta", "average") ?? 0;
				men.actualCrewSizeDETL_delta = getSubtotal(rows, "actualCrewSizeDETL_delta", "average") ?? 0;
				men.actualCrewSizePLUM_delta = getSubtotal(rows, "actualCrewSizePLUM_delta", "average") ?? 0;
				men.actualCrewSizeHVAC_delta = getSubtotal(rows, "actualCrewSizeHVAC_delta", "average") ?? 0;
				men.actualCrewSizePIPE_delta = getSubtotal(rows, "actualCrewSizePIPE_delta", "average") ?? 0;
				men.actualCrewSizeFUEL_delta = getSubtotal(rows, "actualCrewSizeFUEL_delta", "average") ?? 0;
				men.actualCrewSizeFIRE_delta = getSubtotal(rows, "actualCrewSizeFIRE_delta", "average") ?? 0;
				men.actualCrewSizeSITE_delta = getSubtotal(rows, "actualCrewSizeSITE_delta", "average") ?? 0;
				men.actualCrewSizePROC_delta = getSubtotal(rows, "actualCrewSizePROC_delta", "average") ?? 0;
				men.actualCrewSizeSTRT_delta = getSubtotal(rows, "actualCrewSizeSTRT_delta", "average") ?? 0;
				men.actualCrewSizeFABR_delta = getSubtotal(rows, "actualCrewSizeFABR_delta", "average") ?? 0;
				men.actualCrewSizeCHNG_delta = getSubtotal(rows, "actualCrewSizeCHNG_delta", "average") ?? 0;

				men.actualCrewSizeFIELD_delta =
					men.actualCrewSizePLUM_delta +
					men.actualCrewSizeHVAC_delta +
					men.actualCrewSizePIPE_delta +
					men.actualCrewSizeFUEL_delta +
					men.actualCrewSizeFIRE_delta +
					men.actualCrewSizeSITE_delta +
					men.actualCrewSizePROC_delta +
					men.actualCrewSizeSTRT_delta +
					men.actualCrewSizeCHNG_delta;

				men.actualCrewSizeTOTAL_delta =
					men.actualCrewSizeFIELD_delta +
					men.actualCrewSizeOFFC_delta +
					men.actualCrewSizeDETL_delta +
					men.actualCrewSizeFABR_delta;

				actual.workDays = getSubtotal(rows, "workDays") ?? 0;
				actual.workHours = getSubtotal(rows, "workHours") ?? 0;

				// getSubtotal(rows, "actualCrewSizeOFFC_delta") ?? 0;

				// "men (" + sumProduct(rows, "actualCrewSizeOFFC_delta", "workHours") ?? 0 + " hrs)";
				actual.actualCrewSizeOFFC_delta = sumProduct(rows, "actualCrewSizeOFFC_delta", "workHours");
				actual.actualCrewSizeDETL_delta = sumProduct(rows, "actualCrewSizeDETL_delta", "workHours") ?? 0;
				actual.actualCrewSizePLUM_delta = sumProduct(rows, "actualCrewSizePLUM_delta", "workHours") ?? 0;
				actual.actualCrewSizeHVAC_delta = sumProduct(rows, "actualCrewSizeHVAC_delta", "workHours") ?? 0;
				actual.actualCrewSizePIPE_delta = sumProduct(rows, "actualCrewSizePIPE_delta", "workHours") ?? 0;
				actual.actualCrewSizeFUEL_delta = sumProduct(rows, "actualCrewSizeFUEL_delta", "workHours") ?? 0;
				actual.actualCrewSizeFIRE_delta = sumProduct(rows, "actualCrewSizeFIRE_delta", "workHours") ?? 0;
				actual.actualCrewSizeSITE_delta = sumProduct(rows, "actualCrewSizeSITE_delta", "workHours") ?? 0;
				actual.actualCrewSizePROC_delta = sumProduct(rows, "actualCrewSizePROC_delta", "workHours") ?? 0;
				actual.actualCrewSizeSTRT_delta = sumProduct(rows, "actualCrewSizeSTRT_delta", "workHours") ?? 0;
				actual.actualCrewSizeFABR_delta = sumProduct(rows, "actualCrewSizeFABR_delta", "workHours") ?? 0;
				actual.actualCrewSizeCHNG_delta = sumProduct(rows, "actualCrewSizeCHNG_delta", "workHours") ?? 0;

				actual.actualCrewSizeFIELD_delta =
					actual.actualCrewSizePLUM_delta +
					actual.actualCrewSizeHVAC_delta +
					actual.actualCrewSizePIPE_delta +
					actual.actualCrewSizeFUEL_delta +
					actual.actualCrewSizeFIRE_delta +
					actual.actualCrewSizeSITE_delta +
					actual.actualCrewSizePROC_delta +
					actual.actualCrewSizeSTRT_delta +
					actual.actualCrewSizeCHNG_delta;

				actual.actualCrewSizeTOTAL_delta =
					actual.actualCrewSizeFIELD_delta +
					actual.actualCrewSizeOFFC_delta +
					actual.actualCrewSizeDETL_delta +
					actual.actualCrewSizeFABR_delta;

				actual.actualHours_delta = totals?.actualHours ?? 0;
				actual.actualLaborRate_delta = getRate(
					(totals?.laborActualCost ?? 0) + (totals?.subLabActualCost ?? 0),
					totals?.actualHours ?? 0
				);
				actual.laborActualCost_delta = (totals?.laborActualCost ?? 0) + (totals?.subLabActualCost ?? 0);

				proj.actualCrewSizeOFFC_delta = totals?.projHoursOFFC ?? 0;
				proj.actualCrewSizeDETL_delta = totals?.projHoursDETL ?? 0;
				proj.actualCrewSizePLUM_delta = totals?.projHoursPLUM ?? 0;
				proj.actualCrewSizeHVAC_delta = totals?.projHoursHVAC ?? 0;
				proj.actualCrewSizePIPE_delta = totals?.projHoursPIPE ?? 0;
				proj.actualCrewSizeFUEL_delta = totals?.projHoursFUEL ?? 0;
				proj.actualCrewSizeFIRE_delta = totals?.projHoursFIRE ?? 0;
				proj.actualCrewSizeSITE_delta = totals?.projHoursSITE ?? 0;
				proj.actualCrewSizePROC_delta = totals?.projHoursPROC ?? 0;
				proj.actualCrewSizeSTRT_delta = totals?.projHoursSTRT ?? 0;
				proj.actualCrewSizeFABR_delta = totals?.projHoursFABR ?? 0;
				proj.actualCrewSizeCHNG_delta = totals?.projHoursCHNG ?? 0;

				proj.actualCrewSizeFIELD_delta =
					proj.actualCrewSizePLUM_delta +
					proj.actualCrewSizeHVAC_delta +
					proj.actualCrewSizePIPE_delta +
					proj.actualCrewSizeFUEL_delta +
					proj.actualCrewSizeFIRE_delta +
					proj.actualCrewSizeSITE_delta +
					proj.actualCrewSizePROC_delta +
					proj.actualCrewSizeSTRT_delta +
					proj.actualCrewSizeCHNG_delta;

				proj.actualCrewSizeTOTAL_delta =
					proj.actualCrewSizeFIELD_delta +
					proj.actualCrewSizeOFFC_delta +
					proj.actualCrewSizeDETL_delta +
					proj.actualCrewSizeFABR_delta;

				proj.actualHours_delta = totals?.projHours ?? 0;
				proj.actualLaborRate_delta = getRate(
					(totals?.laborProjCost ?? 0) + (totals?.subLabProjCost ?? 0),
					totals?.projHours ?? 0
				);
				proj.laborActualCost_delta = (totals?.laborProjCost ?? 0) + (totals?.subLabProjCost ?? 0);
			}
		} else if (Array.isArray(totals)) {
			let projRow = totals?.find((d) => d.mth === "Projected Hours") ?? null;
			if (Boolean(projRow)) {
				proj = projRow;
			}

			if (Boolean(rows?.length > 0)) {
				men.workDays = getSubtotal(rows, "workDays") ?? 0;
				men.workHours = getSubtotal(rows, "workHours") ?? 0;

				men.actualCrewSizeOFFC_delta = getSubtotal(rows, "actualCrewSizeOFFC_delta") ?? 0;
				men.actualCrewSizeDETL_delta = getSubtotal(rows, "actualCrewSizeDETL_delta") ?? 0;
				men.actualCrewSizePLUM_delta = getSubtotal(rows, "actualCrewSizePLUM_delta") ?? 0;
				men.actualCrewSizeHVAC_delta = getSubtotal(rows, "actualCrewSizeHVAC_delta") ?? 0;
				men.actualCrewSizePIPE_delta = getSubtotal(rows, "actualCrewSizePIPE_delta") ?? 0;
				men.actualCrewSizeFUEL_delta = getSubtotal(rows, "actualCrewSizeFUEL_delta") ?? 0;
				men.actualCrewSizeFIRE_delta = getSubtotal(rows, "actualCrewSizeFIRE_delta") ?? 0;
				men.actualCrewSizeSITE_delta = getSubtotal(rows, "actualCrewSizeSITE_delta") ?? 0;
				men.actualCrewSizePROC_delta = getSubtotal(rows, "actualCrewSizePROC_delta") ?? 0;
				men.actualCrewSizeSTRT_delta = getSubtotal(rows, "actualCrewSizeSTRT_delta") ?? 0;
				men.actualCrewSizeFABR_delta = getSubtotal(rows, "actualCrewSizeFABR_delta") ?? 0;
				men.actualCrewSizeCHNG_delta = getSubtotal(rows, "actualCrewSizeCHNG_delta") ?? 0;

				actual.workDays = getSubtotal(rows, "workDays") ?? 0;
				actual.workHours = getSubtotal(rows, "workHours") ?? 0;

				actual.actualCrewSizeOFFC_delta = sumProduct(rows, "actualCrewSizeOFFC_delta", "workHours") ?? 0;
				actual.actualCrewSizeDETL_delta = sumProduct(rows, "actualCrewSizeDETL_delta", "workHours") ?? 0;
				actual.actualCrewSizePLUM_delta = sumProduct(rows, "actualCrewSizePLUM_delta", "workHours") ?? 0;
				actual.actualCrewSizeHVAC_delta = sumProduct(rows, "actualCrewSizeHVAC_delta", "workHours") ?? 0;
				actual.actualCrewSizePIPE_delta = sumProduct(rows, "actualCrewSizePIPE_delta", "workHours") ?? 0;
				actual.actualCrewSizeFUEL_delta = sumProduct(rows, "actualCrewSizeFUEL_delta", "workHours") ?? 0;
				actual.actualCrewSizeFIRE_delta = sumProduct(rows, "actualCrewSizeFIRE_delta", "workHours") ?? 0;
				actual.actualCrewSizeSITE_delta = sumProduct(rows, "actualCrewSizeSITE_delta", "workHours") ?? 0;
				actual.actualCrewSizePROC_delta = sumProduct(rows, "actualCrewSizePROC_delta", "workHours") ?? 0;
				actual.actualCrewSizeSTRT_delta = sumProduct(rows, "actualCrewSizeSTRT_delta", "workHours") ?? 0;
				actual.actualCrewSizeFABR_delta = sumProduct(rows, "actualCrewSizeFABR_delta", "workHours") ?? 0;
				actual.actualCrewSizeCHNG_delta = sumProduct(rows, "actualCrewSizeCHNG_delta", "workHours") ?? 0;

				actual.actualCrewSizeFIELD_delta =
					actual.actualCrewSizePLUM_delta +
					actual.actualCrewSizeHVAC_delta +
					actual.actualCrewSizePIPE_delta +
					actual.actualCrewSizeFUEL_delta +
					actual.actualCrewSizeFIRE_delta +
					actual.actualCrewSizeSITE_delta +
					actual.actualCrewSizePROC_delta +
					actual.actualCrewSizeSTRT_delta +
					actual.actualCrewSizeCHNG_delta;

				actual.actualCrewSizeTOTAL_delta =
					actual.actualCrewSizeFIELD_delta +
					actual.actualCrewSizeOFFC_delta +
					actual.actualCrewSizeDETL_delta +
					actual.actualCrewSizeFABR_delta;

				actual.actualHours_delta = getSubtotal(rows, "actualHours_delta") ?? 0;
				actual.actualLaborRate_delta = getRate(
					getSubtotal(rows, "laborActualCost_delta") ?? 0,
					getSubtotal(rows, "actualHours_delta") ?? 0
				);
				actual.laborActualCost_delta = getSubtotal(rows, "laborActualCost_delta") ?? 0;
			}
		}

		remain.actualCrewSizeOFFC_delta =
			parseFloat(proj.actualCrewSizeOFFC_delta ?? 0) - parseFloat(actual?.actualCrewSizeOFFC_delta ?? 0);
		remain.actualCrewSizeDETL_delta =
			parseFloat(proj.actualCrewSizeDETL_delta ?? 0) - parseFloat(actual?.actualCrewSizeDETL_delta ?? 0);
		remain.actualCrewSizePLUM_delta =
			parseFloat(proj.actualCrewSizePLUM_delta ?? 0) - parseFloat(actual?.actualCrewSizePLUM_delta ?? 0);
		remain.actualCrewSizeHVAC_delta =
			parseFloat(proj.actualCrewSizeHVAC_delta ?? 0) - parseFloat(actual?.actualCrewSizeHVAC_delta ?? 0);
		remain.actualCrewSizePIPE_delta =
			parseFloat(proj.actualCrewSizePIPE_delta ?? 0) - parseFloat(actual?.actualCrewSizePIPE_delta ?? 0);
		remain.actualCrewSizeFUEL_delta =
			parseFloat(proj.actualCrewSizeFUEL_delta ?? 0) - parseFloat(actual?.actualCrewSizeFUEL_delta ?? 0);
		remain.actualCrewSizeFIRE_delta =
			parseFloat(proj.actualCrewSizeFIRE_delta ?? 0) - parseFloat(actual?.actualCrewSizeFIRE_delta ?? 0);
		remain.actualCrewSizeSITE_delta =
			parseFloat(proj.actualCrewSizeSITE_delta ?? 0) - parseFloat(actual?.actualCrewSizeSITE_delta ?? 0);
		remain.actualCrewSizePROC_delta =
			parseFloat(proj.actualCrewSizePROC_delta ?? 0) - parseFloat(actual?.actualCrewSizePROC_delta ?? 0);
		remain.actualCrewSizeSTRT_delta =
			parseFloat(proj.actualCrewSizeSTRT_delta ?? 0) - parseFloat(actual?.actualCrewSizeSTRT_delta ?? 0);
		remain.actualCrewSizeFABR_delta =
			parseFloat(proj.actualCrewSizeFABR_delta ?? 0) - parseFloat(actual?.actualCrewSizeFABR_delta ?? 0);
		remain.actualCrewSizeCHNG_delta =
			parseFloat(proj.actualCrewSizeCHNG_delta ?? 0) - parseFloat(actual?.actualCrewSizeCHNG_delta ?? 0);
		remain.actualCrewSizeFIELD_delta =
			parseFloat(proj.actualCrewSizeFIELD_delta ?? 0) - parseFloat(actual?.actualCrewSizeFIELD_delta ?? 0);
		remain.actualCrewSizeTOTAL_delta =
			parseFloat(proj.actualCrewSizeTOTAL_delta ?? 0) - parseFloat(actual?.actualCrewSizeTOTAL_delta ?? 0);

		remain.actualHours_delta = parseFloat(proj.actualHours_delta ?? 0) - parseFloat(actual?.actualHours_delta ?? 0);
		remain.actualLaborRate_delta = getRate(
			parseFloat(proj.laborActualCost_delta ?? 0) - parseFloat(actual?.laborActualCost_delta ?? 0),
			parseFloat(proj.actualHours_delta ?? 0) - parseFloat(actual?.actualHours_delta ?? 0)
		);
		remain.laborActualCost_delta =
			parseFloat(proj.laborActualCost_delta ?? 0) - parseFloat(actual?.laborActualCost_delta ?? 0);

		// remain.actualCrewSizeOFFC_deltaTextColor = getValueColor(
		// 	remain.actualCrewSizeOFFC_delta,
		// 	chakraThemeColorsMap.gray600
		// );
		// remain.actualCrewSizeDETL_deltaTextColor = getValueColor(
		// 	remain.actualCrewSizeDETL_delta,
		// 	chakraThemeColorsMap.gray600
		// );
		// remain.actualCrewSizePLUM_deltaTextColor = getValueColor(
		// 	remain.actualCrewSizePLUM_delta,
		// 	chakraThemeColorsMap.gray600
		// );
		// remain.actualCrewSizeHVAC_deltaTextColor = getValueColor(
		// 	remain.actualCrewSizeHVAC_delta,
		// 	chakraThemeColorsMap.gray600
		// );
		// remain.actualCrewSizePIPE_deltaTextColor = getValueColor(
		// 	remain.actualCrewSizePIPE_delta,
		// 	chakraThemeColorsMap.gray600
		// );
		// remain.actualCrewSizeFUEL_deltaTextColor = getValueColor(
		// 	remain.actualCrewSizeFUEL_delta,
		// 	chakraThemeColorsMap.gray600
		// );
		// remain.actualCrewSizeFIRE_deltaTextColor = getValueColor(
		// 	remain.actualCrewSizeFIRE_delta,
		// 	chakraThemeColorsMap.gray600
		// );
		// remain.actualCrewSizeSITE_deltaTextColor = getValueColor(
		// 	remain.actualCrewSizeSITE_delta,
		// 	chakraThemeColorsMap.gray600
		// );
		// remain.actualCrewSizePROC_deltaTextColor = getValueColor(
		// 	remain.actualCrewSizePROC_delta,
		// 	chakraThemeColorsMap.gray600
		// );
		// remain.actualCrewSizeSTRT_deltaTextColor = getValueColor(
		// 	remain.actualCrewSizeSTRT_delta,
		// 	chakraThemeColorsMap.gray600
		// );
		// remain.actualCrewSizeFABR_deltaTextColor = getValueColor(
		// 	remain.actualCrewSizeFABR_delta,
		// 	chakraThemeColorsMap.gray600
		// );
		// remain.actualCrewSizeCHNG_deltaTextColor = getValueColor(
		// 	remain.actualCrewSizeCHNG_delta,
		// 	chakraThemeColorsMap.gray600
		// );
		// remain.actualCrewSizeFIELD_deltaTextColor = getValueColor(
		// 	remain.actualCrewSizeFIELD_delta,
		// 	chakraThemeColorsMap.gray600
		// );
		// remain.actualCrewSizeTOTAL_deltaTextColor = getValueColor(
		// 	remain.actualCrewSizeTOTAL_delta,
		// 	chakraThemeColorsMap.gray600
		// );
		// remain.actualHours_deltaTextColor = getValueColor(remain.actualHours_delta, chakraThemeColorsMap.gray600);
		// remain.laborActualCost_deltaTextColor = getValueColor(
		// 	remain.laborActualCost_delta,
		// 	chakraThemeColorsMap.gray600
		// );

		// return [men, actual, proj, remain];
		return [actual, proj, remain];
	}
}
