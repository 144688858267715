import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useDisclosure, SimpleGrid, Box, Flex, Text, Heading, Input, HStack, Collapse, Button } from "@chakra-ui/react";
import DataInput from "../../../core/Inputs/data/DataInput";

import { portalUser } from "../../../App";
import { formatValue } from "../../../helperFunctions";

export default function MFRAdditionalInfoSection(props) {
	const { isOpen, onToggle } = useDisclosure();
	const [contract, setContract] = useState({});
	const [projectAccts, setProjectAccts] = useState([]);
	const [purchasers, setPurchasers] = useState([]);
	const [diversityReqs, setDiversityReqs] = useState([]);

	const [paName, setPAName] = useState(null);
	const [purchaserName, setPurchaserName] = useState(null);

	const [devAccess, setDevAccess] = useState(false);
	const [execAccess, setExecAccess] = useState(false);
	// const [pmAccess, setPMAccess] = useState(false);

	useEffect(() => {
		setProjectAccts(portalUser?.getProjectAccts());
		setPurchasers(portalUser?.getPurchasers());
	}, []);

	useMemo(() => {
		setContract(props.contract);
		setPAName(props.contract?.paName);
		setPurchaserName(props.contract?.purchaserName);
		// let projectMgrs = portalUser.getJCProjectMgrs();
		// if (props.contract?.regionID === "2") {
		// 	projectMgrs =
		// 		projectMgrs?.filter(
		// 			(d) => (d.regionID === "1" || d.regionID === null) && d.jcco === props.contract?.jcco
		// 		) ?? [];
		// } else if (props.contract?.regionID === "4") {
		// 	projectMgrs = projectMgrs?.filter((d) => d.jcco === props.contract?.jcco) ?? [];
		// } else {
		// 	projectMgrs =
		// 		projectMgrs?.filter(
		// 			(d) =>
		// 				(d.regionID === props.contract?.regionID || d.regionID === null) &&
		// 				d.jcco === props.contract?.jcco &&
		// 				d.status === "Active"
		// 		) ?? [];
		// }
		// let projectMgr = projectMgrs?.filter((d) => d?.projectMgrID === props.contract?.projectMgrID)[0] ?? {
		// 	pmEmployeeUID: null,
		// };
		if (Boolean(props.contract?.jccmKeyID)) {
			let regionID = props.contract?.regionID;
			if (regionID === "2") {
				regionID = "1";
			} else if (regionID === "4") {
				regionID = null;
			}

			if (portalUser?.user?.isDevOpsUserYN === "Y") {
				setDevAccess(true);
			} else if (
				portalUser?.user?.isOperationsExecUserYN === "Y" &&
				(regionID === portalUser?.user?.regionID || regionID === null)
			) {
				setExecAccess(true);
			}
			// else if (portalUser?.user?.employeeUID === projectMgr.pmEmployeeUID) {
			// 	setPMAccess(true);
			// }
		}
	}, [props.contract]);

	useEffect(() => {
		let diversityReqs = [
			{ req: "LOCAL", pct: contract?.pctLOCAL ?? 0 },
			{ req: "MBE", pct: contract?.pctMBE ?? 0 },
			{ req: "SBE", pct: contract?.pctSBE ?? 0 },
			{ req: "WBE", pct: contract?.pctWBE ?? 0 },
			{ req: "DBE", pct: contract?.pctDBE ?? 0 },
			{ req: "DVBE", pct: contract?.pctDVBE ?? 0 },
			{ req: "LBE", pct: contract?.pctLBE ?? 0 },
			{ req: "CBE", pct: contract?.pctCBE ?? 0 },
			{ req: "LSBE", pct: contract?.pctLSBE ?? 0 },
		];

		setDiversityReqs(diversityReqs);
	}, [contract]);

	const updateProjectAcct = useCallback(
		(e) => {
			if (e.projectAcctID !== contract?.projectAcctID) {
				contract.update("projectAcct", e);
				setPAName(contract?.paName ?? null);
			}
		},
		[contract]
	);

	const updatePurchaser = useCallback(
		(e) => {
			if (e.purchaserID !== contract?.purchaserID) {
				contract.update("purchaser", e);
				setPurchaserName(contract?.purchaserName ?? null);
			}
		},
		[contract]
	);

	const updateDiversityReqs = useCallback(() => {
		let diversityReqs = [
			{ req: "LOCAL", pct: contract?.pctLOCAL ?? 0 },
			{ req: "MBE", pct: contract?.pctMBE ?? 0 },
			{ req: "SBE", pct: contract?.pctSBE ?? 0 },
			{ req: "WBE", pct: contract?.pctWBE ?? 0 },
			{ req: "DBE", pct: contract?.pctDBE ?? 0 },
			{ req: "DVBE", pct: contract?.pctDVBE ?? 0 },
			{ req: "LBE", pct: contract?.pctLBE ?? 0 },
			{ req: "CBE", pct: contract?.pctCBE ?? 0 },
			{ req: "LSBE", pct: contract?.pctLSBE ?? 0 },
		];

		setDiversityReqs(diversityReqs);
	}, [contract]);

	return (
		<Box>
			<Collapse in={isOpen}>
				<HStack spacing={8} w="full" align="flex-start">
					<Box w="full" flex={5}>
						<Flex direction="row" flex={1} w="full" align="center">
							<Heading
								py="1"
								flex={2}
								fontSize={"sm"}
								letterSpacing={1}
								color={"teal.600"}
								textTransform="uppercase"
								isTruncated
								textAlign="right"
								pr="3"
							>
								Project Accountant
							</Heading>

							<Flex
								flex={4}
								borderTop="2px"
								borderLeft="2px"
								borderRight="2px"
								borderBottom="1px"
								borderBottomColor="gray.400"
								minH={"30px"}
							>
								{Boolean(devAccess) ? (
									<DataInput
										key={"input-11-" + paName}
										type="select"
										variant="unstyled"
										searchable
										inputProps={{
											py: "1",
											rounded: "0px",
											_hover: { bg: "blackAlpha.50", cursor: "pointer" },
										}}
										value={paName}
										options={projectAccts}
										optionText="displayValue"
										onOptionSelect={updateProjectAcct}
									/>
								) : (
									<Text
										_hover={{ cursor: "not-allowed" }}
										fontFamily={"var(--chakra-fonts-body)"}
										px="2"
										py="1"
										variant="unstyled"
										bg="white"
										color="gray.600"
										type="text"
										textAlign="left"
										w="full"
									>
										{paName}
									</Text>
								)}
							</Flex>

							<Flex display={{ base: "none", md: "flex", lg: "none" }} flex={1}></Flex>
						</Flex>

						<Flex direction="row" flex={1} w="full" align="center">
							<Heading
								py="1"
								flex={2}
								fontSize={"sm"}
								letterSpacing={1}
								color={"teal.600"}
								textTransform="uppercase"
								isTruncated
								textAlign="right"
								pr="3"
							>
								Purchaser
							</Heading>
							<Flex
								flex={4}
								borderLeft="2px"
								borderRight="2px"
								borderBottom="1px"
								borderBottomColor="gray.400"
								minH={"30px"}
							>
								{Boolean(devAccess || execAccess) ? (
									<DataInput
										key={"input-12-" + purchaserName}
										type="select"
										variant="unstyled"
										searchable
										inputProps={{
											py: "1",
											rounded: "0px",
											_hover: { bg: "blackAlpha.50", cursor: "pointer" },
										}}
										value={purchaserName}
										options={purchasers}
										optionText="displayValue"
										onOptionSelect={updatePurchaser}
									/>
								) : (
									<Text
										_hover={{ cursor: "not-allowed" }}
										fontFamily={"var(--chakra-fonts-body)"}
										px="2"
										py="1"
										variant="unstyled"
										bg="white"
										color="gray.600"
										type="text"
										textAlign="left"
										w="full"
									>
										{purchaserName}
									</Text>
								)}
							</Flex>
							<Flex display={{ base: "none", md: "flex", lg: "none" }} flex={1}></Flex>
						</Flex>

						<Flex direction="row" flex={1} w="full" align="center" display={{ base: "none", lg: "flex" }}>
							<Heading
								py="1"
								flex={2}
								fontSize={"sm"}
								letterSpacing={1}
								color={"teal.600"}
								textTransform="uppercase"
								isTruncated
								textAlign="right"
								pr="3"
							>
								Address
							</Heading>
							<Flex
								flex={4}
								borderLeft="2px"
								borderRight="2px"
								borderBottom="1px"
								borderBottomColor="gray.400"
								minH={"30px"}
							>
								<Input
									flex={1}
									key={"input-13-" + contract?.jobAddress}
									autoComplete="off"
									_hover={{ bg: "blackAlpha.50", cursor: "pointer" }}
									px="2"
									py="1"
									variant="unstyled"
									bg="white"
									color="gray.600"
									borderRadius="0px"
									_focus={{
										borderBottom: "1px solid",
										borderColor: "green.600",
									}}
									type="text"
									textAlign="left"
									defaultValue={contract?.jobAddress}
									onBlur={(ev) => {
										if (ev.target.value !== contract?.jobAddress) {
											contract?.update("jobAddress", ev.target.value);
										}
									}}
								/>
							</Flex>
							<Flex display={{ base: "none", md: "flex", lg: "none" }} flex={1}></Flex>
						</Flex>

						<Flex direction="row" flex={1} w="full" align="center">
							<Heading
								py="1"
								flex={2}
								fontSize={"sm"}
								letterSpacing={1}
								color={"teal.600"}
								textTransform="uppercase"
								isTruncated
								textAlign="right"
								pr="3"
							>
								City
							</Heading>
							<Flex flex={4} borderLeft="2px" borderRight="2px" borderBottom="2px">
								<Input
									flex={3}
									key={"input-14-" + contract?.jobCity}
									autoComplete="off"
									_hover={{ bg: "blackAlpha.50", cursor: "pointer", borderRadius: "0px" }}
									px="2"
									py="1"
									variant="unstyled"
									bg="white"
									color="gray.600"
									borderRadius="0px"
									_focus={{
										borderBottom: "1px solid",
										borderColor: "green.600",
									}}
									type="text"
									textAlign="left"
									defaultValue={contract?.jobCity}
									onBlur={(ev) => {
										if (ev.target.value !== contract?.jobCity) {
											contract?.update("jobCity", ev.target.value);
										}
									}}
								/>

								<Flex direction="row" flex={4} align="center">
									<Heading
										py="1"
										flex={3}
										size="sm"
										color={"teal.600"}
										textTransform="uppercase"
										isTruncated
										textAlign="right"
										px="2"
									>
										State
									</Heading>

									<Input
										flex={1}
										maxLength={2}
										key={"input-15-" + contract?.jobState}
										autoComplete="off"
										_hover={{ bg: "blackAlpha.50", cursor: "pointer", borderRadius: "0px" }}
										px="2"
										py="1"
										variant="unstyled"
										bg="white"
										color="gray.600"
										borderRadius="0px"
										_focus={{
											borderBottom: "1px solid",
											borderColor: "green.600",
										}}
										type="text"
										textAlign="left"
										defaultValue={contract?.jobState}
										onBlur={(ev) => {
											if (ev.target.value !== contract?.jobState && ev.target.length === 2) {
												contract?.update("jobState", ev.target.value);
											}
										}}
									/>

									<Heading
										flex={1}
										py="1"
										size="sm"
										color={"teal.600"}
										textTransform="uppercase"
										textAlign="right"
										px="2"
									>
										Zip
									</Heading>

									<Input
										flex={2}
										maxLength={5}
										key={"input-16-" + contract?.jobZip}
										autoComplete="off"
										_hover={{ bg: "blackAlpha.50", cursor: "pointer", borderRadius: "0px" }}
										px="2"
										py="1"
										variant="unstyled"
										bg="white"
										color="gray.600"
										borderRadius="0px"
										_focus={{
											borderBottom: "1px solid",
											borderColor: "green.600",
										}}
										type="number"
										textAlign="left"
										defaultValue={contract?.jobZip}
										onBlur={(ev) => {
											if (ev.target.value !== contract?.jobZip) {
												contract?.update("jobZip", ev.target.value);
											}
										}}
									/>
								</Flex>
							</Flex>
							<Flex display={{ base: "none", md: "flex", lg: "none" }} flex={1}></Flex>
						</Flex>
					</Box>

					<Box w="full" flex={4}>
						<Flex direction="row" flex={1} w="full" align="flex-start">
							<Heading
								py="2"
								flex={2}
								fontSize={"sm"}
								whiteSpace="nowrap"
								letterSpacing={1}
								color={"teal.600"}
								textTransform="uppercase"
								textAlign="right"
								pr="3"
								minH={"30px"}
							>
								Diversity Goals
							</Heading>
							<Flex w="full" flex={4} justify="center">
								<SimpleGrid columns={[1, 2, null, null, 3]} py={1} spacingX={2} spacingY={1} w="full">
									{diversityReqs?.map((req, i) => (
										<HStack minW="100px" spacing={2} whiteSpace="nowrap" key={i}>
											<Heading
												whiteSpace="nowrap"
												minW={"50px"}
												py="1"
												fontSize={"sm"}
												letterSpacing={1}
												color={"gray.500"}
												textTransform="uppercase"
												textAlign="center"
											>
												{req.req}
											</Heading>

											<Input
												minW={"50px"}
												maxW="70px"
												// maxLength={3}
												key={"input-req-" + i + "-" + req.pct}
												autoComplete="off"
												_hover={{
													bg: "blackAlpha.50",
													cursor: "pointer",
													borderRadius: "0px",
												}}
												fontSize={"sm"}
												px="1"
												borderBottom="1px"
												borderBottomColor="gray.600"
												color={"gray.600"}
												textAlign="center"
												py="0"
												variant="unstyled"
												borderRadius="0px"
												bg="white"
												_focus={{
													borderBottom: "1px solid",
													borderColor: "green.600",
													borderRadius: "0px",
												}}
												defaultValue={formatValue(req.pct, 1, "percent")}
												onBlur={async (ev) => {
													if (
														formatValue(ev.target.value, 1, "percent") !==
														formatValue(req.pct, 1, "percent")
													) {
														await contract?.update("pct" + req.req, ev.target.value);
														updateDiversityReqs();
													}
												}}
											/>
										</HStack>
									))}
								</SimpleGrid>
							</Flex>
						</Flex>
					</Box>
					<Box></Box>
				</HStack>
			</Collapse>

			<Button
				w="full"
				size="sm"
				my="0"
				color="gray.500"
				variant="unstyled"
				_hover={{ textDecoration: "underline", color: "blue.600" }}
				onClick={onToggle}
			>
				{isOpen ? "Close Additional Info" : "Show Additional Info"}
			</Button>
		</Box>
	);
}
