import React from "react";
import { Container, Stack } from "@chakra-ui/react";
import HRCalendar from "../components/HRCalendar";
import HRPageHeader from "../components/HRPageHeader";
import { portalUser } from "../../../App";

export default function HRCalendarPage(props) {
	return (
		<Stack
			spacing={0}
			p={portalUser.styles?.pagePadding}
			bg={portalUser.styles?.pageBG}
			w="full"
			h="full"
			flex={1}
			align="center"
		>
			<HRPageHeader tab={props.tab} />
			<Container
				direction="column"
				maxW={"full"}
				display={"flex"}
				minW="container.lg"
				flex={1}
				justify="flex-start"
				h="full"
				px={0}
			>
				<HRCalendar />
			</Container>
		</Stack>
	);
}
