import React, { useState, useMemo, useCallback } from "react";
import { Flex, HStack, Stack, Heading, Text } from "@chakra-ui/react";
import BasicCard from "../../Layout/cards/BasicCard";
import VirtualListV3 from "../../ReactTable/components/VirtualListV3";
import FormClass from "../classes/Form";

import { portalUser } from "../../../App";
import { convertArrayToMap, log } from "../../../helperFunctions";

export default function FormSectionCommenters(props) {
	let users = portalUser.getUsers();

	const [form, setForm] = useState(null);

	useMemo(() => {
		if (Boolean(props.form)) {
			setForm(props.form);
		}
	}, [props.form]);

	const commenters = useMemo(() => {
		let commenters = form?.commenters ?? [];
		let commenterUIDs = commenters?.map((d) => d.employeeUID) ?? [];
		commenterUIDs = commenterUIDs?.filter((d) => Boolean(d)) ?? [];
		// commenterUIDs.push(form?.assignedTo);
		commenterUIDs = [...new Set(commenterUIDs?.filter((d) => Boolean(d)) ?? [])];
		// log("commenterUIDs", commenterUIDs);
		return users?.filter((d) => commenterUIDs.includes(d.employeeUID)) ?? [];
	}, [form?.commenters, users]);

	const available = useMemo(() => {
		//FILTER OUT COMMENTERS
		// let availableUIDs = commenters?.map((d) => d.employeeUID) ?? [];
		// availableUIDs.push(form?.assignedTo);
		// availableUIDs = availableUIDs?.filter((d) => Boolean(d)) ?? [];
		// let employees = users?.filter((d) => !availableUIDs.includes(d.employeeUID));
		// log("employees", employees);

		//GET REVIEWER UIDS
		let reviewers = users?.filter((d) => Boolean(d.reviewerUID)) ?? [];
		let reviewerUIDs = [...new Set(reviewers?.map((d) => d.reviewerUID) ?? [])];
		reviewerUIDs = reviewerUIDs?.filter((d) => Boolean(d)) ?? [];

		//GET MANAGER UIDS
		let managers = users?.filter((d) => Boolean(d.managerUID)) ?? [];
		let managerUIDs = [...new Set(managers?.map((d) => d.managerUID) ?? [])];
		managerUIDs = managerUIDs?.filter((d) => Boolean(d)) ?? [];

		let allReviewerUIDs = [...new Set([...reviewerUIDs, ...managerUIDs])];

		let available = users?.filter((d) => allReviewerUIDs.includes(d.employeeUID)) ?? [];
		//Add people as commenters who are not reviewers: Robert Yamane, Joe Contreraz, Mark Carruthers, Vas Lolis
		let robert = "839C9F40-5C49-48B2-A473-F8A09D018682".toLowerCase();
		let joe = "9FE00A92-7CBC-4B79-B063-D5A03FA17A15".toLowerCase();
		let mark = "3151C4BC-AEDC-43AC-AC78-5689C0A257BE".toLowerCase();
		let vas = "A923AF61-6443-4B73-A0D8-C918CCF325BF".toLowerCase();
		let extraReviewers = users?.filter((user) => [robert, joe, mark, vas].includes(user.employeeUID));
		available.push(...extraReviewers);
		let commenterUIDs = commenters?.map((d) => d.employeeUID) ?? [];

		available =
			available?.filter(
				(d) => ![form?.assignedTo, form?.assignedFor, ...commenterUIDs]?.includes(d.employeeUID)
			) ?? [];

		return available;
	}, [commenters, form?.assignedFor, form?.assignedTo, users]);

	let usersMap = convertArrayToMap(users, "employeeUID");
	let departments = portalUser.getDepartments();
	let deptMap = convertArrayToMap(departments, "deptID");
	let regions = portalUser.getRegions();
	let regionMap = convertArrayToMap(regions, "regionID");

	let initialState = useMemo(() => {
		let initialState = {
			defaultGroupBy: [],
			defaultFilters: [{ id: "userStatus", value: "Active" }],
			defaultSortAttr: [],
		};

		return initialState;
	}, []);

	const updateFormCommenters = useCallback(
		async (employeeUID = null) => {
			if (Boolean(employeeUID)) {
				let newForm = form;
				// log("NEWFORM", newForm);
				let commenters = newForm?.commenters ?? [];
				let commenterUIDs = commenters?.map((d) => d.employeeUID) ?? [];
				commenterUIDs = commenterUIDs?.filter((d) => Boolean(d)) ?? [];
				commenterUIDs.push(newForm?.assignedTo);

				if (commenterUIDs.includes(employeeUID)) {
					newForm = await newForm.deleteFormCommenter(
						employeeUID,
						props?.form?.assignedForName,
						props?.form?.formUID,
						true
					);
					log("Delete Form Commenter Result", newForm);
				} else {
					newForm = await newForm.insertFormCommenter(
						employeeUID,
						props?.form?.assignedForName,
						props?.form?.formUID,
						false
					);
					log("Insert Form Commenter Result", newForm);
				}
				setForm(new FormClass(newForm));
			}
		},
		[form]
	);

	const columns = useMemo(() => {
		let columns = [
			{
				Header: "Form UID",
				id: "formUID",
				accessor: "formUID",
				disableGroupBy: true,
				disableFilters: true,
				disableSortBy: true,
				// accessor: (row) => row.formUID,
			},

			{
				Header: "Employee UID",
				id: "employeeUID",
				accessor: "employeeUID",
				disableGroupBy: true,
				disableFilters: true,
				disableSortBy: true,
				// accessor: (row) => row.original?.employeeUID,
			},
			{
				Header: "Employee",
				id: "name",

				accessor: (row) => row?.name ?? null,
			},
			{
				Header: "Title",
				id: "title",

				disableFilters: true,
				accessor: (row) => row?.title ?? null,
			},
			{
				Header: "Reviewer UID",
				id: "reviewerUID",

				disableFilters: true,
				disableSortBy: true,
				disableGroupBy: true,
				accessor: (row) => row?.reviewerUID ?? null,
			},
			{
				Header: "Reviewer",
				id: "reviewerName",
				disableFilters: true,
				accessor: (row) => usersMap[row?.reviewerUID]?.name ?? null,
			},

			{
				Header: "Dept",
				id: "dept",
				accessor: (row) => deptMap[row?.deptID]?.dept ?? null,
			},
			{
				Header: "Parent Dept",
				id: "parentDept",
				accessor: (row) => deptMap[deptMap[row?.deptID]?.parentDeptID]?.dept ?? null,
			},

			{
				Header: "Region",
				id: "region",
				accessor: (row) => regionMap[row?.regionID]?.tag ?? null,
			},

			{
				Header: "Employee Status",
				id: "userStatus",
				accessor: (row) => row?.userStatus ?? "Active",
			},
		];
		return columns;
	}, [deptMap, regionMap, usersMap]);

	const RenderRow = useCallback(
		({ index, row, style }) => {
			if (row.isGrouped) {
				// let columns = row?.cells?.map((cell)=> cell.column)
				// let columns = row?.cells?.map((tab) => tab.column);
				// // log("columns " + row.groupByID, columns);
				// let column = columns?.find((d) => d.id === row?.groupByID) ?? null;
				// // log("column " + row?.groupByID, column);

				return (
					<Flex
						{...row.getRowProps({ style })}
						key={row.id + "-" + index}
						flex={1}
						style={style}
						w="full"
						p={2}
						h="full"
						overflow="hidden"
						align="center"
						borderBottomColor="blackAlpha.300"
						borderBottomWidth={2}
						justify="space-between"
						bg={row?.depth === 0 && "whiteAlpha.500"}
					>
						{/* <HRReviewsToDoListGroupedRow row={row} column={column} statuses={statuses} /> */}
					</Flex>
				);
			} else {
				return (
					<Flex
						{...row.getRowProps({ style })}
						style={style}
						flex={1}
						w="full"
						overflow="hidden"
						bg="blackAlpha.100"
						_hover={{ bg: "blackAlpha.200" }}
						maxH={125}
						minH={125}
						align="center"
						h="full"
						px={3}
						py={2}
					>
						<Stack
							bg="whiteAlpha.800"
							_hover={{ bg: "whiteAlpha.900", shadow: "lg" }}
							px={4}
							py={2}
							rounded="md"
							w="full"
							shadow="md"
							spacing={1}
							flex={1}
							textTransform="uppercase"
							onClick={() => updateFormCommenters(row?.original?.employeeUID)}
						>
							<Heading size="md" letterSpacing={1}>
								{row?.original?.name}
							</Heading>
							<Text>{row?.original?.title}</Text>
							<Text>{row?.original?.userStatus}</Text>
						</Stack>
					</Flex>
				);
			}
		},
		[updateFormCommenters]
	);

	return (
		<Flex px={4} w="full" flex={1}>
			<BasicCard title="Invite Commenters" styles={{ padding: portalUser?.pageSpacing }}>
				<HStack
					w="full"
					spacing={portalUser.styles?.pageSpacing}
					py={portalUser.styles?.pagePadding}
					px={portalUser.styles?.pagePadding}
					shadow={portalUser.styles?.cardShadow}
					borderWidth={portalUser.styles?.cardBorderWidth}
					borderColor={portalUser.styles?.cardBorderColor}
					borderRadius="lg"
					align="flex-start"
					bg={portalUser.styles.pageBG}
				>
					<FormSectionCommenterList
						data={available}
						columns={columns}
						initialState={initialState}
						RenderRow={RenderRow}
					/>
					<FormSectionCommenterList
						data={commenters}
						columns={columns}
						initialState={initialState}
						RenderRow={RenderRow}
					/>
				</HStack>
			</BasicCard>
		</Flex>
	);
}

function FormSectionCommenterList(props) {
	let columns = useMemo(() => props.columns ?? null, [props.columns]);
	let data = useMemo(() => props.data ?? null, [props.data]);
	let initialState = useMemo(() => props.initialState ?? null, [props.initialState]);
	let renderRow = useMemo(() => props.RenderRow ?? null, [props.RenderRow]);

	return (
		<VirtualListV3
			data={data}
			columns={columns}
			renderRow={renderRow}
			minH={125}
			hoverDisabled={true}
			listHeight={775}
			defaultSortAttr={initialState.defaultSortAttr}
			defaultFilters={initialState.defaultFilters}
			defaultGroupBy={initialState.defaultGroupBy}
			hideFilters
			// listOnly
		/>
	);
}
