import React, { useState, useMemo, useCallback } from "react";
import { Flex, Text, Button, useBoolean, HStack } from "@chakra-ui/react";
import LoadingSection from "../../../core/ProgressIndicators/components/LoadingSection";
import TabPanels from "../../../core/ReactTable/layout/TabPanels";
import Page from "../../../core/ReactTable/layout/Page";
import DFJobSelect from "../components/DFJobSelect";
import DFJobDrawer from "../components/DFJobDrawer";
import PageTemplateDFJobs from "../classes/PageTemplateDFJobs";

import { convertArrayToMap } from "../../../helperFunctions";

export default function DFJobs({
	reportSettings,
	updateBanner,
	dfJobs,
	selectedJobs,
	params,
	initParams,
	tabVal,
	changeTab,
	isLoading = false,
}) {
	// const isLoading = true;

	const [panelVal, setPanelVal] = useState(0);
	const changePanel = useCallback((panelVal = 0) => {
		setPanelVal(panelVal);
	}, []);

	const [newDFJobIsOpen, setNewDFJobIsOpen] = useBoolean(false);

	//Create tab panels from the list of dfJobs by a given property (eg. scopeDesc)
	//by mapping over the dfJobs array, using the report settings and current tabVal
	const panels = useMemo(() => {
		let panels = [];

		//Create map of DFJobs based off the key for
		//the specific tabVal found in report settingss
		let mapKey = reportSettings?.mapKey[tabVal] ?? null;
		if (Boolean(params?.jobID || params?.jccmKeyID)) {
			mapKey = null;
		}
		let panelMap = convertArrayToMap(dfJobs, mapKey, true);

		//If the mapKey is a property of the dfJobs
		//push the DFJobs into groups by that key
		if (Boolean(mapKey)) {
			for (let key of Object?.keys(panelMap)) {
				if (key !== "Start Up") {
					panels.push({
						title: reportSettings?.bannerTitle,
						subtitle: key + " Projects",
						panel: key,
						data: panelMap[key] ?? [],
					});
				}
			}
			//If the mapKey is not a property of the dfJobs (eg. null)
			//push the all the DFJobs into a single tab panel
		} else {
			panels.push({
				title: reportSettings?.bannerTitle,
				subtitle: reportSettings?.bannerSubtitle,
				panel: reportSettings?.bannerSubtitle,
				data: dfJobs ?? [],
			});
		}
		return panels;
	}, [dfJobs, tabVal, reportSettings, params]);

	//Update the banner title and subtitle to
	//better match the currently shown jobs
	useMemo(() => {
		let selectedPanel = panels[panelVal];
		if (selectedPanel?.data?.length > 0) {
			let title = selectedPanel?.title ?? reportSettings?.bannerTitle ?? null;
			let subtitle = selectedPanel?.subtitle ?? reportSettings?.bannerSubtitle ?? null;
			updateBanner(title, subtitle);
		}
	}, [panelVal, panels, reportSettings, updateBanner]);

	//Identify cases when there is no data for the given tab panel
	//Nobody likes infinite loading screens or crashing virtual tables
	const noData = useMemo(() => {
		if (dfJobs?.length === 0) {
			return true;
		} else if (panels[panelVal]?.data?.length === 0) {
			return true;
		} else {
			return false;
		}
	}, [dfJobs?.length, panelVal, panels]);

	const dfJob = useMemo(() => {
		if (selectedJobs.length === 1 && Boolean(params?.jobID) && tabVal !== 0) {
			let dfJob = selectedJobs?.find((d) => d.jobID === params?.jobID);
			return dfJob;
		} else if (Boolean(params?.scopeID)) {
			return { scopeID: params?.scopeID, templateType: reportSettings.template };
		} else {
			return { templateType: reportSettings.template };
		}
	}, [params?.jobID, params?.scopeID, reportSettings.template, selectedJobs, tabVal]);

	const selectDFJob = (e) => {
		changeTab(1);
		initParams({ jobID: e?.jobID, scopeID: params?.scopeID });
	};

	const resetDFJob = () => {
		changeTab(0);
		initParams({ scopeID: params?.scopeID });
	};

	//Reset tab Panel back to the first tab panel
	// if the tab is changed (panelVal = 0)
	useMemo(() => {
		if (tabVal !== 0 && panelVal !== 0) {
			setPanelVal(0);
		}
	}, [panelVal, tabVal]);

	const existingDFJobNames = dfJobs.map((job) => job.job);

	return (
		<Flex direction="column" flex={1} justify="flex-start" h="full" rounded={5} position="relative">
			{isLoading && <LoadingSection />}
			{!isLoading && noData && <Text>No D&F Jobs</Text>}
			{!isLoading && !noData && (
				<>
					{Boolean(newDFJobIsOpen) && (
						<DFJobDrawer
							dfJob={dfJob}
							existingDFJobNames={existingDFJobNames}
							isOpen={newDFJobIsOpen}
							onClose={() => setNewDFJobIsOpen.off()}
						/>
					)}
					<TabPanels
						panels={panels}
						panelVal={panelVal}
						changePanel={changePanel}
						buttonGroup={
							<HStack justify="flex-end">
								<DFJobSelect dfJobs={dfJobs} params={params} selectDFJob={selectDFJob} />
								{params?.jobID && (
									<Button textTransform="uppercase" onClick={() => resetDFJob()}>
										Close Job
									</Button>
								)}
								<Button textTransform="uppercase" onClick={() => setNewDFJobIsOpen.toggle()}>
									New Job
								</Button>
							</HStack>
						}
					>
						<DFJobsPageTemplate
							selectedPanel={panels[panelVal]}
							params={params}
							dfJobs={dfJobs}
							panelVal={panelVal}
							changePanel={changePanel}
							changeTab={changeTab}
							selectDFJob={selectDFJob}
						/>
					</TabPanels>
				</>
			)}
		</Flex>
	);
}

export function DFJobsPageTemplate({ selectedPanel, dfJobs, params, panelVal, changePanel, selectDFJob }) {
	//Use the given dfJobs and the DFJobsPageTemplate class to define the default page view settings (pageHeaders, footers, padding, headings etc)
	//and give us the prereq for a seachable, filterable, sortable virtual table (such as the columns, datatypes, header names, rowHeights, maxWidths etc)

	const pageTemplate = useMemo(
		() =>
			new PageTemplateDFJobs(selectedPanel?.data, params, {
				pageName: selectedPanel?.title,
				pageTitle: selectedPanel?.subtitle,
			}),

		[selectedPanel, params]
	);

	//Take the dfJob specific page template class and feed it
	//into a standardized reusable page view component
	return (
		<Flex direction="column" flex={1} justify="flex-start" h="full" rounded={5}>
			{pageTemplate?.data?.length === 0 ? (
				<LoadingSection />
			) : (
				<Page
					pageTemplate={pageTemplate}
					//Add any styling overrides specifically for the Web View vs PDF View
					//such as headings, background colors, or showing/hiding headers/footers
					overrideView={{
						showReportHeader: false,
						showPageHeader: false,
						showPageFooter: false,
						bg: "transparent",
						shadow: "none",

						px: 0,
						pageName: selectedPanel?.subtitle,
						pageTitle: "",
						addressBar: false,
					}}
					//Pass any custom/dfJob specifc input components such as dropdowns, drawers or overlay pages
					//into so that we can keep the Page Component as reusable as possible (bonus that its faster to render)
					panelVal={panelVal}
					changePanel={changePanel}
					pageHeaderDropdown={<DFJobSelect dfJobs={dfJobs} params={params} selectDFJob={selectDFJob} />}
					setSelectedRow={selectDFJob}
				/>
			)}
		</Flex>
	);
}
