import React from "react";
import { Stack, Container, Box } from "@chakra-ui/react";

export default function ContainerWidthsExample() {
	return (
		<Stack spacing={2} w="full">
			<Container
				bg="blue.900"
				maxW="full"
				w="full"
				h="100px"
				color="gray.50"
				p="2"
				borderWidth={1}
				borderColor="gray.300"
				rounded={"md"}
			>
				Full Container (full)
			</Container>
			<Box
				bg="teal.900"
				w="8xl"
				h="100px"
				color="gray.50"
				p="2"
				borderWidth={1}
				borderColor="gray.300"
				rounded={"md"}
			>
				Extra-Large Box (8xl)
			</Box>

			<Box
				bg="teal.800"
				w="7xl"
				h="100px"
				color="gray.50"
				p="2"
				borderWidth={1}
				borderColor="gray.300"
				rounded={"md"}
			>
				Extra-Large Box (7xl)
			</Box>
			<Container
				bg="blue.700"
				maxW="container.xl"
				w="full"
				h="100px"
				color="gray.50"
				p="2"
				borderWidth={1}
				borderColor="gray.300"
				rounded={"md"}
			>
				Extra-Large Container (container.xl)
			</Container>
			<Box
				bg="teal.700"
				w="6xl"
				h="100px"
				color="gray.50"
				p="2"
				borderWidth={1}
				borderColor="gray.300"
				rounded={"md"}
			>
				Extra-Large Box (6xl)
			</Box>

			<Box
				bg="teal.600"
				w="5xl"
				h="100px"
				color="gray.50"
				p="2"
				borderWidth={1}
				borderColor="gray.300"
				rounded={"md"}
			>
				Extra-Large Box (5xl)
			</Box>
			<Container
				bg="blue.600"
				maxW="container.lg"
				w="full"
				h="100px"
				color="gray.50"
				p="2"
				borderWidth={1}
				borderColor="gray.300"
				rounded={"md"}
			>
				Large Container (container.lg)
			</Container>
			<Box
				bg="teal.500"
				w="4xl"
				h="100px"
				color="gray.50"
				p="2"
				borderWidth={1}
				borderColor="gray.300"
				rounded={"md"}
			>
				Extra-Large Box (4xl)
			</Box>

			<Box
				bg="teal.400"
				w="3xl"
				h="100px"
				color="gray.50"
				p="2"
				borderWidth={1}
				borderColor="gray.300"
				rounded={"md"}
			>
				Extra-Large Box (3xl)
			</Box>
			<Container
				bg="blue.500"
				maxW="container.md"
				w="full"
				h="100px"
				color="gray.50"
				p="2"
				borderWidth={1}
				borderColor="gray.300"
				rounded={"md"}
			>
				Medium Container (container.md)
			</Container>
			<Box
				bg="teal.300"
				w="2xl"
				h="100px"
				color="gray.50"
				p="2"
				borderWidth={1}
				borderColor="gray.300"
				rounded={"md"}
			>
				Extra-Large Box (2xl)
			</Box>
			<Container
				bg="blue.300"
				maxW="container.sm"
				w="full"
				h="100px"
				color="gray.50"
				p="2"
				borderWidth={1}
				borderColor="gray.300"
				rounded={"md"}
			>
				Small Container (container.sm)
			</Container>
			<Box
				bg="teal.200"
				w="xl"
				h="100px"
				color="gray.600"
				p="2"
				borderWidth={1}
				borderColor="gray.300"
				rounded={"md"}
			>
				Extra-Large Box (xl)
			</Box>
			<Box
				bg="teal.100"
				w="lg"
				h="100px"
				color="gray.600"
				p="2"
				borderWidth={1}
				borderColor="gray.300"
				rounded={"md"}
			>
				Large Box (lg)
			</Box>
			<Box
				bg="teal.50"
				w="md"
				h="100px"
				color="gray.600"
				p="2"
				borderWidth={1}
				borderColor="gray.300"
				rounded={"md"}
			>
				Medium Box (md)
			</Box>
			<Box
				bg="white"
				w="sm"
				h="100px"
				color="gray.600"
				p="2"
				borderWidth={1}
				borderColor="gray.300"
				rounded={"md"}
			>
				Small Box (sm)
			</Box>
			<Box
				bg="blackAlpha.50"
				w="xs"
				h="100px"
				color="gray.600"
				p="2"
				borderWidth={1}
				borderColor="gray.300"
				rounded={"md"}
			>
				Extra-Small Box (xs)
			</Box>
			<Box
				bg="blackAlpha.100"
				w="2xs"
				h="100px"
				color="gray.600"
				p="2"
				borderWidth={1}
				borderColor="gray.300"
				rounded={"md"}
			>
				2x Extra-Small Box (2xs)
			</Box>
			<Box
				bg="blackAlpha.200"
				w="3xs"
				h="100px"
				color="gray.600"
				p="2"
				borderWidth={1}
				borderColor="gray.300"
				rounded={"md"}
			>
				3x Extra-Small Box (3xs)
			</Box>
		</Stack>
	);
}
