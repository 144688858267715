import React from "react";
import ToggleButton from "./ToggleButton";

export default function DeleteButton({ deleteIsActive = "0", onClickDeleteButton }) {
	return (
		<ToggleButton
			toggleIsOpen={deleteIsActive}
			onClick={onClickDeleteButton}
			toggleOptions={[
				{
					isOpen: true,
					label: "Delete",
					icon: "fas fa-trash-alt",
					color: "red.500",
					bg: "whiteAlpha.900",
				},
				{
					isOpen: false,
					label: "Restore",
					icon: "fas fa-trash-restore",
					color: "gray.500",
					bg: "whiteAlpha.700",
				},
			]}
		/>
	);
}
