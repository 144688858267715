export default class SectionTemplateMFRSafety {
	constructor(contract = null) {
		this.template = "MFRSafety";
		this.name = "Safety";
		this.label = "I.";
		this.heading = "Safety";
		this.pagebreak = 0;
		this.divider = false;
		this.type = "labelValuePairs";
		this.py = 0;
		this.subSections = [
			new SubSectionTemplateMFRSafety1(contract),
			new SubSectionTemplateMFRSafety2(contract),
			new SubSectionTemplateMFRSafety3(contract),
		];
	}
}

class SubSectionTemplateMFRSafety1 {
	constructor(contract = null) {
		this.template = "mfrSafety1";
		this.type = "labelValuePairs";
		this.columns = [
			{
				accessor: "leftAddon",
				width: "2%",
			},
			{
				accessor: "label",
				textAlign: "right",
				width: "60%",
			},
			{
				accessor: "value",
				width: "30%",
				textAlign: "center",
				valueType: "number",
				decimals: 0,
			},
			{
				accessor: "rightAddon",
				width: "8%",
			},
		];
		this.data = [
			{
				leftAddon: null,
				label: "Lost Time Accidents",
				value: contract?.safetyLostTimeAccidents ?? null,
				rightAddon: null,
			},
		];
	}
}

class SubSectionTemplateMFRSafety2 {
	constructor(contract = null) {
		this.template = "mfrSafety2";
		this.type = "labelValuePairs";
		this.columns = [
			{
				accessor: "leftAddon",
				width: "2%",
			},
			{
				accessor: "label",
				textAlign: "right",
				width: "60%",
			},
			{
				accessor: "value",
				width: "30%",
				textAlign: "center",
				valueType: "number",
				decimals: 0,
			},
			{
				accessor: "rightAddon",
				width: "8%",
			},
		];
		this.data = [
			{
				leftAddon: null,
				label: "Recordable Accidents",
				value: contract?.safetyRecordableAccidents ?? null,
				rightAddon: null,
			},
		];
	}
}

class SubSectionTemplateMFRSafety3 {
	constructor(contract = null) {
		this.template = "mfrSafety3";
		this.type = "labelValuePairs";
		this.columns = [
			{
				accessor: "leftAddon",
				width: "2%",
			},
			{
				accessor: "label",
				textAlign: "right",
				width: "60%",
			},
			{
				accessor: "value",
				width: "30%",
				textAlign: "center",
				valueType: "number",
				decimals: 0,
			},
			{
				accessor: "rightAddon",
				width: "8%",
			},
		];
		this.data = [
			{
				leftAddon: null,
				label: "# of Lost Days",
				value: contract?.safetyLostDays ?? null,
				rightAddon: null,
			},
		];
	}
}
