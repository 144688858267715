import React, { useState, useRef, useEffect, useMemo, Fragment } from "react";
import { Heading, Stack, HStack, useBoolean, Flex, Text } from "@chakra-ui/react";

import {
	useTable,
	useResizeColumns,
	useGroupBy,
	useExpanded,
	useGlobalFilter,
	useFilters,
	useSortBy,
	useBlockLayout,
	useColumnOrder,
	useRowState,
} from "react-table";
import { useExportData } from "react-table-plugins";
import VirtualTableV4Settings from "../table/VirtualTableV4Settings";
import VirtualTableV4 from "../table/VirtualTableV4";

import getFilterTypes from "../../ReactTable/functions/getFilterTypes";
import getExportFileBlob from "../../ReactTable/functions/getExportFileBlob";
import TextInputColumnFilter from "../../ReactTable/inputs/TextInputFilter";
// import { log } from "../../../../../../helperFunctions";

export default function VirtualTableSection({
	label,
	heading,
	subheading,
	footNote,
	tooltip,
	sliceType,
	tableColumns,
	tableData,
	tableFooter,
	tableLoopBy = [],
	tableGroupBy = [],
	tableFilterBy = [],
	tableSortBy = [],
	tableHiddenColumns,
	tableAutoExpandRows = true,
	tableRowHeight,
	tableMaxRows,
	tableMinRows,
	skipPageReset,
	updateMyData,
	selectedInput,
	selectedRow,
	setSelectedRow,
	selectedKeys = [],
	newPCO,
	dnfJob,
}) {
	// Table Heading, ColumnDefs, and Data (Memoized as required by React-Table)

	const columns = useMemo(() => tableColumns, [tableColumns]);
	const data = useMemo(() => tableData, [tableData]);
	const footer = useMemo(() => tableFooter, [tableFooter]);

	// Inital State Settings: Default GroupBy, FilterBy, SortBy, and HiddenColumn Props
	const loopBy = useMemo(() => tableLoopBy ?? [], [tableLoopBy]);
	const groupBy = useMemo(() => tableGroupBy ?? [], [tableGroupBy]);
	const filterBy = useMemo(() => tableFilterBy ?? [], [tableFilterBy]);
	const sortBy = useMemo(() => tableSortBy ?? [], [tableSortBy]);
	const hiddenColumns = useMemo(() => {
		let hiddenColumns = [];
		if (Boolean(tableHiddenColumns)) {
			hiddenColumns = tableHiddenColumns;
		} else {
			let excludeColumns = tableColumns?.filter((d) => d.show === false) ?? [];
			hiddenColumns = excludeColumns?.map((d) => d.accessor) ?? [];
		}
		return hiddenColumns;
	}, [tableColumns, tableHiddenColumns]);
	const autoExpandRows = useMemo(() => tableAutoExpandRows ?? true, [tableAutoExpandRows]);

	// Table Width Variables (variables: ref, maxWidth - uses ref to set the maxWith of the Table)
	const ref = useRef(null);
	const [maxWidth, setMaxWidth] = useState(ref?.current?.clientWidth);

	// Table Height Props (rowHeight, maxRows: defines height of each table rows and total number of visible rows)
	const rowHeight = useMemo(() => tableRowHeight ?? 35, [tableRowHeight]);
	const maxRows = useMemo(() => tableMaxRows ?? data.length, [tableMaxRows, data.length]);
	const minRows = useMemo(() => tableMinRows ?? 0, [tableMinRows]);

	// Default Column Settings
	const defaultColumn = useMemo(
		() => ({
			Filter: TextInputColumnFilter,
			filter: "textNoCaps",
			sortIcon: "alpha",
			show: true,
			maxWidth: 800,
		}),
		[]
	);

	const [filterIsOpen, setFilterIsOpen] = useBoolean(false);
	const filterTypes = getFilterTypes();

	const variableSizeRef = useRef(null);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		footerGroups,
		prepareRow,
		rows,
		state,
		preGlobalFilteredRows,
		toggleAllRowsExpanded,
		setGlobalFilter,
		visibleColumns,
		setHiddenColumns,
		allColumns,
		exportData,
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			autoResetPage: !skipPageReset,
			autoResetExpanded: !skipPageReset,
			autoResetGroupBy: !skipPageReset,
			autoResetSelectedRows: !skipPageReset,
			autoResetSortBy: !skipPageReset,
			autoResetFilters: !skipPageReset,
			autoResetRowState: !skipPageReset,
			getExportFileBlob,
			filterTypes,
			initialState: {
				hiddenColumns: hiddenColumns,
				sortBy: sortBy,
				groupBy: groupBy,
				filters: filterBy,
			},
			updateMyData,
		},
		useBlockLayout,
		useColumnOrder,
		useResizeColumns,
		useFilters,
		useGlobalFilter,
		useGroupBy,
		useSortBy,
		useRowState,
		useExportData,
		useExpanded
	);

	useEffect(() => {
		let visibleColumnWidth = visibleColumns.map((d) => d.width).reduce((partialSum, a) => partialSum + a, 0);
		let maxWidth = visibleColumnWidth <= ref?.current?.clientWidth ? visibleColumnWidth : ref?.current?.clientWidth;
		setMaxWidth(maxWidth);
	}, [visibleColumns, ref?.current?.clientWidth]);

	useEffect(() => {
		toggleAllRowsExpanded(autoExpandRows);
		if (Boolean(variableSizeRef?.current)) {
			variableSizeRef?.current.resetAfterIndex(0, false);
		}
	}, [autoExpandRows, toggleAllRowsExpanded]);

	return (
		<HStack h="full" w="full" spacing={6} align="flex-start" flex={1} ref={ref} rounded={5}>
			<Stack w="full" maxW={maxWidth} flex={1} h="full" spacing={2} overflow="hidden">
				<VirtualTableV4Settings
					label={label}
					heading={heading}
					subheading={subheading}
					tooltip={tooltip}
					searchIsOpen={false}
					filterIsOpen={filterIsOpen}
					setFilterIsOpen={setFilterIsOpen}
					preGlobalFilteredRows={preGlobalFilteredRows}
					globalFilter={state.globalFilter}
					setGlobalFilter={setGlobalFilter}
					rowCount={rows?.length ?? 0}
					allColumns={allColumns}
					exportData={exportData}
					toggleAllRowsExpanded={toggleAllRowsExpanded}
					autoExpandRows={autoExpandRows}
					maxWidth={maxWidth}
					setHiddenColumns={setHiddenColumns}
					selectedKeys={selectedKeys}
					newPCO={newPCO}
					dnfJob={dnfJob}
				/>

				{Boolean(loopBy.length > 0) && Boolean(rows.length > 0) ? (
					<>
						{loopBy?.map((loop, i) => {
							let loopBySet = [
								...new Set(
									rows?.map((row) => ({
										id: row.values[loop.id],
										heading: row.values[loop?.heading ?? loop?.id],
									}))
								),
							];
							loopBySet = loopBySet?.filter((row) => Boolean(row.id)) ?? [];
							let uniqueSet = [...new Map(loopBySet.map((item) => [item.id, item])).values()];
							return (
								<Fragment key={i}>
									{Boolean(uniqueSet.length > 0) && (
										<>
											{uniqueSet?.map((obj, j) => {
												let newRows =
													rows?.filter(
														(row) =>
															(Boolean(row.isGrouped) &&
																row?.leafRows?.some(
																	(leafRow) => leafRow.values[loop.id] === obj.id
																)) ||
															(!Boolean(row.isGrouped) && row.values[loop.id] === obj.id)
													) ?? [];

												return (
													<Fragment key={j}>
														<Heading
															pt={3}
															size="md"
															letterSpacing={3}
															color={"gray.500"}
															textTransform="uppercase"
														>
															{obj.heading}
														</Heading>
														<VirtualTableV4
															defaultColumn={defaultColumn}
															getTableProps={getTableProps}
															getTableBodyProps={getTableBodyProps}
															headerGroups={headerGroups}
															visibleColumns={visibleColumns}
															allColumns={allColumns}
															filterIsOpen={filterIsOpen}
															rows={newRows}
															prepareRow={prepareRow}
															footerGroups={footerGroups}
															maxWidth={maxWidth}
															maxRows={maxRows}
															minRows={minRows}
															rowHeight={rowHeight}
															groupBy={state.groupBy}
															updateMyData={updateMyData}
															skipPageReset={skipPageReset}
															sliceType={sliceType}
															footer={footer}
															selectedRow={selectedRow}
															setSelectedRow={setSelectedRow}
															toggleAllRowsExpanded={toggleAllRowsExpanded}
															autoExpandRows={autoExpandRows}
															expanded={state.expanded}
															variableSizeRef={variableSizeRef}
														/>
													</Fragment>
												);
											})}
										</>
									)}
								</Fragment>
							);
						})}
					</>
				) : (
					<VirtualTableV4
						defaultColumn={defaultColumn}
						getTableProps={getTableProps}
						getTableBodyProps={getTableBodyProps}
						headerGroups={headerGroups}
						visibleColumns={visibleColumns}
						allColumns={allColumns}
						filterIsOpen={filterIsOpen}
						rows={rows}
						prepareRow={prepareRow}
						footerGroups={footerGroups}
						maxWidth={maxWidth}
						maxRows={maxRows}
						minRows={minRows}
						rowHeight={rowHeight}
						groupBy={state.groupBy}
						updateMyData={updateMyData}
						skipPageReset={skipPageReset}
						sliceType={sliceType}
						footer={footer}
						selectedRow={selectedRow}
						setSelectedRow={setSelectedRow}
						toggleAllRowsExpanded={toggleAllRowsExpanded}
						autoExpandRows={autoExpandRows}
						expanded={state.expanded}
						variableSizeRef={variableSizeRef}
					/>
				)}
				{Boolean(footNote) && (
					<Flex w="full" flex={1} justify="flex-end">
						<Text color="gray.400" letterSpacing={1} my={0} lineHeight={1}>
							{footNote}
						</Text>
					</Flex>
				)}
			</Stack>
		</HStack>
	);
}
