import React, { useMemo } from "react";
import { Flex, Stack, Heading, HStack, IconButton, Tooltip } from "@chakra-ui/react";
import PPMLogo from "../../../core/Logos/components/PPMLogo";
import MAC5Logo from "../../../core/Logos/components/MAC5Logo";
import HRReviewsSelect from "./HRReviewsSelect";
import { portalUser } from "../../../App";
import HR_ADMIN_TABS from "../constants/HRAdminTabs";
import { convertArrayToMap } from "../../../helperFunctions";

export default function HRPageHeader(props) {
	let tab = useMemo(() => props?.tab, [props?.tab]);
	const formData = useMemo(() => props.formData ?? null, [props.formData]);
	const formUID = useMemo(() => props.formUID, [props.formUID]);
	const closeForm = () => {
		props.selectForm(null);
	};
	const tabsMap = convertArrayToMap(HR_ADMIN_TABS, "tab");

	return (
		<Flex direction="row" w="full" justify="space-between" align="flex-start">
			<Flex maxW="sm" w="full" justify="flex-start" align="flex-start" p={4}>
				{Boolean(portalUser?.user?.prco === "250") ? (
					<MAC5Logo color={"blue.600"} h={16} px={2} />
				) : (
					<PPMLogo color={"teal.700"} h={16} />
				)}
			</Flex>

			<Stack spacing={1} w="full" flex={1} align="center" justify="center" py={6}>
				<Stack spacing={0} w="full" flex={1} align="center" justify="center">
					<Heading
						size="lg"
						color="teal.700"
						textShadow="2px 2px whitesmoke"
						textTransform="uppercase"
						letterSpacing={3}
					>
						{Boolean(formUID) ? tabsMap[tab]?.heading : tabsMap[tab]?.name}
					</Heading>
					<Heading
						size="xl"
						textTransform="uppercase"
						letterSpacing={3}
						color="teal.600"
						textShadow="2px 2px white"
						isTruncated
					>
						{tabsMap[tab]?.subheading}
					</Heading>
				</Stack>
			</Stack>

			<Stack spacing={1} flex={1} justify="flex-end" align="flex-start" maxW="sm" w="full" p={4}>
				<HStack justify="flex-end" align="flex-start" spacing={2} maxW="sm" w="full">
					<Tooltip label="Print/Download" placement="top">
						<IconButton
							isDisabled={!Boolean(formUID)}
							key={"fas fa-print fa-lg"}
							variant="ghost"
							color="gray.400"
							icon={<i className="fas fa-print fa-lg" />}
							// onClick={() => {
							// 	setTimeout(handlePrint, 1000);
							// }}
						></IconButton>
					</Tooltip>
					<Tooltip label="Close" placement="top">
						<IconButton
							isDisabled={!Boolean(formUID)}
							key={"far fa-times-circle fa-lg "}
							variant="ghost"
							color="gray.400"
							icon={<i className="far fa-times-circle fa-lg " />}
							onClick={closeForm}
						/>
					</Tooltip>
				</HStack>
				{["templates", "forms", "reviews"].includes(tab) && (
					<HRReviewsSelect
						tab={tab}
						formUID={formUID}
						formData={formData}
						selectForm={props.selectForm}
						selectedYear={props.selectedYear}
						showLabel={false}
					/>
				)}
			</Stack>
		</Flex>
	);
}
