import React from "react";
import { Center, Heading, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";

export default function HoverAndTapExample({ heading, subHeading }) {
	const style = {
		width: "250px",
		height: "250px",
		background: "var(--chakra-colors-blue-500)",
		borderRadius: "var(--chakra-radii-xl)",
		cursor: "pointer",
	};

	return (
		<Center w="full" minH="sm" h="full" bg="white" rounded={5} p={8}>
			<Center border="3px dashed" borderColor="gray.200" rounded="xl" h="full" w="full">
				<VStack spacing={4} flex={1} w="full" pb={48}>
					<Heading color="gray.500" textTransform="uppercase" letterSpacing={6} size="xl" pt={16}>
						{subHeading}
					</Heading>
					<Heading color="gray.400" textTransform="uppercase" letterSpacing={6} size="3xl" pb={16}>
						{heading}
					</Heading>
					<motion.div style={style} whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }} />
				</VStack>
			</Center>
		</Center>
	);
}
