import React, { useMemo, useState, useEffect } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import logo from "../../../core/Reports/pdf/logo.png";
import DocumentPDF from "../../../core/Reports/pdf/DocumentPDF";
import { portalUser } from "../../../App";

export default function PrintPreview(props) {
	const selectedKeys = useMemo(() => props?.selectedKeys ?? [], [props.selectedKeys]);
	const thruMth = useMemo(() => props.thruMth, [props.thruMth]);
	const template = useMemo(() => props.template, [props.template]);
	const [document, setDocument] = useState(null);

	useEffect(() => {
		const initDocument = async (template, selectedKeys, thruMth) => {
			let document = await portalUser?.getDocument(template, selectedKeys, thruMth);
			setDocument(document);
		};
		initDocument(template, selectedKeys, thruMth);
	}, [selectedKeys, thruMth, template]);

	return (
		<PDFViewer
			style={{
				width: "100%",
				height: "100%",
				borderRadius: 5,
			}}
		>
			<DocumentPDF
				title={"MonthlyForecastReport.pdf"}
				document={document}
				logo={logo}
				fileName={"MonthlyForecastReport.pdf"}
			/>
		</PDFViewer>
	);
}
