import { portalUser } from "../App";
import { log } from "../helperFunctions";

const defaultBaseURL =
	(process.env.NODE_ENV === "development"
		? "https://ppmportalappnodejs-api.azurewebsites.net"
		: window.location.origin) + "/api/users/";

const testBody = {
	test: "testValue",
	value: "actual value",
	work: {
		id: 1,
		value: "hello",
	},
};

// function checkMSAuth(msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id ?? portalData.msUser?.id) {
function checkMSAuth(msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id) {
	if (process.env.NODE_ENV === "development") {
		return msUserID;
	} else {
		return "";
	}
}

export async function ajaxGET(url, headers = {}) {
	headers = Object.assign(
		{
			"Content-Type": "application/json",
			environment: process.env.NODE_ENV,
			Authentication: checkMSAuth(),
		},
		headers
	);

	let response = await fetch(url, {
		method: "GET",
		headers,
	})
		.then((res) =>
			res
				.json()
				.then((data) => ({
					value: data,
					status: res.status,
				}))
				.then((response) => {
					if (response.value?.error) {
						alert("The Portal is currently updating, please refresh the page");
					}
					return response;
				})
		)
		.catch(async (err) => {
			if (err.name === "AbortError") {
				console.log("AbortError", err);
			} else {
				console.log("Other Error", err);
				// alert(
				// 	"Anyone else sick of error messages? Trust us we are too... Unfortunately you are currently unauthorized :(",
				// 	err
				// );
			}
			console.log("method", "GET");
			console.log("url", url);
			console.log("headers", headers);
			console.log("err", err);
			return err;
		});

	return response;
}

export async function ajaxPOST(url, body = {}, headers = {}) {
	headers = Object.assign(
		{
			"Content-Type": "application/json",
			environment: process.env.NODE_ENV,
			Authentication: checkMSAuth(),
		},
		headers
	);

	let response = await fetch(url, {
		method: "POST",
		headers,
		body: JSON.stringify(body),
	})
		.then((res) =>
			res
				.json()
				.then((data) => ({
					value: data,
					status: res.status,
				}))
				.then((response) => {
					if (response.value?.error) {
						alert("The Portal is currently updating, please refresh the page");
					}
					return response;
				})
		)
		.catch((err) => {
			if (err.name === "AbortError") {
				console.log("AbortError", err);
			} else {
				console.log("Other Error", err);
				// alert(
				// 	"Anyone else sick of error messages? Trust us we are too... Unfortunately you are currently unauthorized :(",
				// 	err
				// );
			}
			console.log("method", "POST");
			console.log("url", url);
			console.log("headers", headers);
			console.log("body", body);
			console.log("err", err);
			return err;
		});

	return response;
}

export async function ajaxFilePOST(url, body = {}, headers = {}) {
	headers = Object.assign(
		{
			"Content-Type": "multipart/form-data",
			environment: process.env.NODE_ENV,
			Authentication: checkMSAuth(),
		},
		headers
	);

	let response = await fetch(url, {
		method: "POST",
		headers,
		body: JSON.stringify(body),
	})
		.then((res) =>
			res
				.json()
				.then((data) => ({
					value: data,
					status: res.status,
				}))
				.then((response) => {
					if (response.value?.error) {
						alert("The Portal is currently updating, please refresh the page");
					}
					return response;
				})
		)
		.catch((err) => {
			if (err.name === "AbortError") {
				console.log("AbortError", err);
			} else {
				console.log("Other Error", err);
				// alert(
				// 	"Anyone else sick of error messages? Trust us we are too... Unfortunately you are currently unauthorized :(",
				// 	err
				// );
			}
			console.log("method", "POST");
			console.log("url", url);
			console.log("headers", headers);
			console.log("body", body);
			console.log("err", err);
			return err;
		});

	return response;
}

export async function ajaxPATCH(url, body = {}, headers = {}) {
	headers = Object.assign(
		{
			"Content-Type": "application/json",
			environment: process.env.NODE_ENV,
			Authentication: checkMSAuth(),
		},
		headers
	);

	let response = await fetch(url, {
		method: "PATCH",
		headers,
		body: JSON.stringify(body),
	})
		.then((res) =>
			res
				.json()
				.then((data) => ({
					value: data,
					status: res.status,
				}))
				.then((response) => {
					if (response.value?.error) {
						alert(
							"The Portal is currently updating. Copy/paste or screenshot any recently entered information as it may not be saved. Please refresh the page once you are ready."
						);
					}
					return response;
				})
		)
		.catch((err) => {
			if (err.name === "AbortError") {
				console.log("AbortError", err);
			} else {
				console.log("Other Error", err);
				// alert(
				// 	"Anyone else sick of error messages? Trust us we are too... Unfortunately you are currently unauthorized :(",
				// 	err
				// );
			}
			console.log("method", "PATCH");
			console.log("url", url);
			console.log("headers", headers);
			console.log("body", body);
			console.log("err", err);
			return err;
		});

	return response;
}

export async function ajaxDELETE(url, headers = {}) {
	headers = Object.assign(
		{
			"Content-Type": "application/json",
			environment: process.env.NODE_ENV,
			Authentication: checkMSAuth(),
		},
		headers
	);

	let response = await fetch(url, {
		method: "DELETE",
		headers,
	})
		.then((res) =>
			res
				.json()
				.then((data) => ({
					value: data,
					status: res.status,
				}))
				.then((response) => {
					if (response.value?.error) {
						alert(
							"The Portal is currently updating. Copy/paste or screenshot any recently entered information as it may not be saved. Please refresh the page once you are ready."
						);
					}
					return response;
				})
		)
		.catch((err) => {
			if (err.name === "AbortError") {
				console.log("AbortError", err);
			} else {
				console.log("Other Error", err);
				// alert(
				// 	"Anyone else sick of error messages? Trust us we are too... Unfortunately you are currently unauthorized :(",
				// 	err
				// );
			}
			console.log("method", "DELETE");
			console.log("url", url);
			console.log("headers", headers);
			console.log("err", err);
			return err;
		});
	return response;
}

export async function testGET(apiBaseURL = defaultBaseURL, headers = {}) {
	var apiRouteURL = apiBaseURL + "testGet";
	let response = await ajaxGET(apiRouteURL, (headers = {}));
	return response;
}

export async function testPOST(apiBaseURL = defaultBaseURL, body = testBody, headers = {}) {
	var apiRouteURL = apiBaseURL + "testPost";
	let response = await ajaxPOST(apiRouteURL, body, headers);
	return response;
}

export function setQueryParams(apiRouteURL = "", queryParams = {}) {
	let operator = "?";
	for (const key in queryParams) {
		if (queryParams.hasOwnProperty(key) && Boolean(queryParams[key])) {
			apiRouteURL += operator + key + "=" + queryParams[key];
			operator = "&";
		}
	}
	return apiRouteURL;
}

//************************** MSGraph - API Call Helper Functions **************************//
const msGraphURL = "https://graph.microsoft.com/v1.0/";

export function msGraphCALL(client, endpoint, headers = false, expand = false) {
	try {
		var requestURL = msGraphURL + endpoint;
		var promise;
		if (headers) {
			promise = client.api(requestURL).headers(headers).get();
		} else if (expand) {
			promise = client.api(requestURL).expand(expand).get();
		} else {
			promise = client.api(requestURL).get();
		}

		return promise
			.then((results) => {
				// log("What am I getting", results);
				results.successful = true;
				return results;
			})
			.catch((err) => {
				// throw new Error(`Error making PUT request: ${err.message}`);

				var response = {
					successful: false,
					error: err,
				};

				// log("Call MSGraph Error", response);
				return response;
			});
	} catch (error) {
		log("Call MSGraph Error", error);
		return error;
	}
}

// export function msGraphPOST(client, theUrl, requestBody) {
// 	var url = msGraphURL + theUrl;
// 	var promise = client.api(url).header("Content-Type", "application/json").post(requestBody);
// 	return promise;
// }

// export function msGraphPUT(client, theUrl, requestBody) {
// 	var url = msGraphURL + theUrl;
// 	var promise = client.api(url).put(requestBody);
// 	return promise;
// }

// export function msGraphPATCH(client, theUrl, requestBody) {
// 	var url = msGraphURL + theUrl;
// 	var promise = client.api(url).patch(requestBody);
// 	return promise;
// }

// export function msGraphDELETE(client, theUrl) {
// 	var url = msGraphURL + theUrl;
// 	var promise = client.api(url).delete();
// 	return promise;
// }

export async function msGraphPOST(client, theUrl, requestBody) {
	const url = msGraphURL + theUrl;
	try {
		const response = await client.api(url).header("Content-Type", "application/json").post(requestBody);
		return response;
	} catch (error) {
		throw new Error(`Error making POST request: ${error.message}`);
	}
}

export async function msGraphPUT(client, theUrl, requestBody) {
	const url = msGraphURL + theUrl;
	try {
		const response = await client.api(url).header("Content-Type", "application/json").put(requestBody);
		return response;
	} catch (error) {
		throw new Error(`Error making PUT request: ${error.message}`);
	}
}

export async function msGraphPATCH(client, theUrl, requestBody) {
	const url = msGraphURL + theUrl;
	try {
		const response = await client.api(url).header("Content-Type", "application/json").patch(requestBody);
		return response;
	} catch (error) {
		throw new Error(`Error making PATCH request: ${error.message}`);
	}
}

export async function msGraphDELETE(client, theUrl) {
	const url = msGraphURL + theUrl;
	try {
		const response = await client.api(url).delete();
		return response;
	} catch (error) {
		throw new Error(`Error making DELETE request: ${error.message}`);
	}
}

//************************** MSGraph BETA - API Call Helper Functions **************************//
const msGraphURLBeta = "https://graph.microsoft.com/beta/";

export function msGraphBetaCALL(client, theUrl) {
	try {
		var url = msGraphURLBeta + theUrl;
		let promise = client.api(url).get();
		return promise
			.then((results) => {
				results.successful = true;
				return results;
			})
			.catch((err) => {
				var response = {
					successful: false,
					error: err,
				};
				return response;
			});
	} catch (error) {
		log("Call MSGraph Beta Error", error);
	}
}

export async function msGraphBetaPOST(client, theUrl, requestBody) {
	var url = msGraphURLBeta + theUrl;
	var promise;
	if (!requestBody) {
		promise = client.api(url).header("Content-Type", "application/json").post();
	} else {
		promise = client.api(url).header("Content-Type", "application/json").post(requestBody);
	}
	try {
		const results = await promise;
		results.successful = true;
		return results;
	} catch (err) {
		var response = {
			successful: false,
			error: err,
		};
		return response;
	}
}

export async function msGraphBetaPATCH(client, theUrl, requestBody) {
	var url = msGraphURLBeta + theUrl;
	var promise = client.api(url).patch(requestBody);
	try {
		const results = await promise;
		results.successful = true;
		return results;
	} catch (err) {
		var response = {
			successful: false,
			error: err,
		};
		return response;
	}
}

export async function msGraphBetaDELETE(client, theUrl) {
	var url = msGraphURLBeta + theUrl;
	var promise = client.api(url).delete();
	try {
		const results = await promise;
		return results;
	} catch (err) {
		var response = {
			successful: false,
			error: err,
		};
		return response;
	}
}
