import Report from "./Report";
import Parameter from "./Parameter";
import { v4 } from "uuid";
import moment from "moment";
import { log } from "../../../helperFunctions";
import { portalUser } from "../../../App";

export default class Document {
	constructor(document = {}) {
		this.documentUID = v4();
		this.reportTemplate = document?.reportTemplate ?? "ReportTemplate";
		this.reports = this.newReports(document?.reports ?? []);

		this.reportDate = document?.reportDate ?? new Date();
		this.parameters = this.newParameters(document?.parameters ?? {});

		this.reportName = this.getReportName(document?.reportName ?? null);
		this.fileName = this.getFileName(document?.fileName ?? null);
	}

	newReports(reports = []) {
		let newReports = [];
		if (Boolean(reports) && reports?.length > 0) {
			for (let i = 0; i < reports?.length; i++) {
				let report = reports[i];
				if (report instanceof Report) {
					log("already a report, SKIP");
					report = reports[i];
				} else {
					report = new Report(reports[i]);
				}
				newReports.push(report);
			}
		} else {
			let report = new Report();
			newReports.push(report);
		}
		return newReports;
	}

	getReportName(reportName = null) {
		reportName = reportName ?? this.reports[0]?.reportName ?? "Report PDF";
		return reportName;
	}

	getFileName(fileName = null) {
		fileName = fileName ?? this.reports[0]?.fileName ?? this.getReportName();
		fileName = fileName.replace(".pdf", "");
		fileName += " " + moment(this.reportDate).format("MM-DD-YYYY");
		fileName += ".pdf";

		return fileName;
	}

	newParameters(parameters = {}) {
		let newParameters = {};
		let properties = Object?.keys(parameters) ?? [];

		if (Boolean(properties) && properties?.length > 0) {
			for (let i = 0; i < properties?.length; i++) {
				let parameter = { [properties[i]]: new Parameter(properties[i], parameters[properties[i]]) };
				newParameters = Object.assign(newParameters, parameter);
			}
		}
		return newParameters;
	}

	getParameters() {
		let parameters = this.parameters;
		let newParameters = [];
		let properties = Object?.keys(parameters) ?? [];
		if (Boolean(properties) && properties?.length > 0) {
			for (let i = 0; i < properties?.length; i++) {
				newParameters.push(parameters[properties[i]]);
			}
		}
		return newParameters;
	}

	/*********************************************************************/
	/***********  DOCUMENT OBJECTS & REPORT TEMPLATES *******************/
	/*******************************************************************/

	getReportTemplates(template = null) {
		let parameters = this.parameters;
		let thruMth = moment(new Date()).startOf("month");
		let reportTemplates = [
			{
				reportTemplate: "ReportTemplate",
				reportName: "Report Template",
				pageTemplates: [],
				parameters: { pageTemplates: { label: "Pages", defaultValue: [] } },
				isVisibleYN: "Y",
			},
			{
				reportTemplate: "ActiveProjects",
				reportName: "Project Select",
				pageTemplates: ["ProjectSelect"],
				parameters: {
					jcco: { defaultValue: [] },
					regionID: { defaultValue: [] },
					contractStatus: { defaultValue: "1" },
					accessYN: { defaultValue: "Y" },
				},
				isVisibleYN: "N",
			},
			{
				reportTemplate: "ActiveProjects",
				reportName: "Active Job List",
				parameters: {
					jcco: { defaultValue: [] },
					contractStatus: { defaultValue: "1" },
					regionID: { defaultValue: [] },
					accessYN: { defaultValue: "Y" },
				},
				pageTemplates: ["ActiveProjects", "ClosedProjects"],
				isVisibleYN: "Y",
			},
			{
				reportTemplate: "D&FJobs",
				reportName: "D&F Job Select",
				pageTemplates: ["D&FJobs"],
				// parameters: {
				// 	jcco: { defaultValue: [] },
				// 	regionID: { defaultValue: [] },
				// 	contractStatus: { defaultValue: "1" },
				// 	accessYN: { defaultValue: "Y" },
				// },
				isVisibleYN: "N",
			},
			{
				reportTemplate: "StartUpProjects",
				reportName: "Start Up Project Select",
				pageTemplates: ["StartUpProjects"],
				// parameters: {
				// 	jcco: { defaultValue: [] },
				// 	regionID: { defaultValue: [] },
				// 	contractStatus: { defaultValue: "1" },
				// 	accessYN: { defaultValue: "Y" },
				// },
				isVisibleYN: "N",
			},
			{
				reportTemplate: "MonthlyForecast",
				reportName: "Monthly Forecast Report",
				pageTemplates: [
					"MonthlyForecast",
					"ManpowerProjections",
					"CostAndBillingProjections",
					"ChangeOrderLog",
				],
				parameters: {
					jccmKeyID: { defaultValue: [] },
					thruMth: { defaultValue: thruMth },
				},
				isVisibleYN: "Y",
			},
			{
				reportTemplate: "ChangeOrderLog",
				reportName: "Change Order Log",
				pageTemplates: ["ChangeOrderLog"],
				parameters: {
					jccmKeyID: { defaultValue: [] },
				},
				isVisibleYN: "Y",
			},
			{
				reportTemplate: "SubmittalsAndRFIs",
				reportName: "Submittals & RFIs",
				pageTemplates: ["SubmittalLog", "RFILog"],
				parameters: {
					jccmKeyID: { defaultValue: ["4782"] },
				},
				isVisibleYN: "Y",
			},
			{
				reportTemplate: "JobCostDrilldown",
				reportName: "Job Cost Drilldown",
				pageTemplates: ["JobCostDrilldown"],
				parameters: {
					jccmKeyID: { defaultValue: [] },
					thruMth: { defaultValue: thruMth },
				},
				isVisibleYN: "Y",
			},
			// {
			// 	reportTemplate: "Diversity",
			// 	reportName: "Diversity",
			// 	pageTemplates: ["Diversity"],
			// 	parameters: {
			// 		jccmKeyID: { defaultValue: [] },
			// 		thruMth: { defaultValue: thruMth },
			// 	},
			// 	isVisibleYN: "Y",
			// },
		];

		if (Boolean(template)) {
			let reportTemplate = reportTemplates?.find((d) => d.reportTemplate === template) ?? this;
			let properties = Object?.keys(reportTemplate?.parameters) ?? [];
			if (Boolean(properties) && properties?.length > 0) {
				for (let i = 0; i < properties?.length; i++) {
					let id = properties[i];
					let value = parameters[id]?.value;
					Object.assign(reportTemplate.parameters[id], this.getParameterOptions(id));
					if (Boolean(value)) {
						reportTemplate.parameters[id].value = value;
					}
				}
			}
			return reportTemplate;
		} else {
			return reportTemplates;
		}
	}

	getParameterOptions(id = null, options = []) {
		let contractStatuses = [
			{ optionLabel: "All Contracts", optionDesc: "All Projects regardless of Status", optionValue: null },
			{ optionLabel: "Open Contracts", optionDesc: "Projects the user cannot access", optionValue: "1" },
			{ optionLabel: "Closed Contracts", optionDesc: "Projects the user cannot access", optionValue: "3" },
		];
		let jobAccess = [
			{ optionLabel: "All Job Access", optionDesc: "All Jobs regardless of Access", optionValue: null },
			{ optionLabel: "User Has Access", optionDesc: "Only Jobs the User Can Access", optionValue: "Y" },
			{ optionLabel: "User Needs Access", optionDesc: "Only Jobs the User Cannot Access", optionValue: "N" },
		];

		if (Boolean(id)) {
			if (id === "pageTemplates") {
				return {
					options: this.getPageTemplates(),
					optionLabel: "tabName",
					minW: "md",
				};
			} else if (id === "jcco") {
				return {
					options: options.length > 0 ? options : portalUser?.getCompanies(),
					optionLabel: "title",
					optionDesc: "subtitle",
					minW: "3xs",
				};
			} else if (id === "contractStatus") {
				return {
					options: contractStatuses,
					optionValue: "optionValue",
					optionLabel: "optionLabel",
					optionDesc: "optionDesc",
					minW: "3xs",
				};
			} else if (id === "regionID") {
				return {
					options: options.length > 0 ? options : portalUser?.getRegions(),
					optionValue: "regionID",
					optionLabel: "tag",
					optionDesc: "description",
					minW: "3xs",
				};
			} else if (id === "accessYN") {
				return {
					options: jobAccess,
					optionValue: "optionValue",
					optionLabel: "optionLabel",
					optionDesc: "optionDesc",
					minW: "3xs",
				};
			} else if (id === "jccmKeyID") {
				return {
					options: options.length > 0 ? options : portalUser?.getContracts(),
					optionLabel: "title",
					optionDesc: "subtitle",

					minW: "md",
				};
			} else {
				return {};
			}
		}
	}

	getPageTemplates() {
		return [
			{
				pageTemplate: "ReportTemplate",
				tabName: "Report Template",
				pageName: "Report Template",
				parameters: {},
			},
			{
				pageTemplate: "ActiveProjects",
				tabName: "Active Projects",
				pageName: "Active Projects",
				parameters: {
					loopBy: [{ id: "jcco", heading: "companyDesc" }],
					groupBy: [],
					sortBy: [
						{ id: "co", desc: false },
						{ id: "jobVal", desc: false },
						{ id: "contractVal", desc: false },
						{ id: "wipJob", desc: false },
						{ id: "contract", desc: false },
					],
					filterBy: [{ id: "contractStatus", value: "1" }],
				},
			},
			{
				pageTemplate: "ClosedProjects",
				tabName: "Closed Projects",
				pageName: "Completed Projects",
				parameters: {
					loopBy: [{ id: "co", heading: "companyDesc" }],
					groupBy: [],
					sortBy: [
						{ id: "co", desc: false },
						{ id: "jobVal", desc: false },
						{ id: "contractVal", desc: false },
						{ id: "wipJob", desc: false },
						{ id: "contract", desc: false },
					],
					filterBy: [{ id: "contractStatus", value: "3" }],
				},
			},
			{
				pageTemplate: "ProjectSelect",
				tabName: "Select Project",
				pageName: "Select Project",
				parameters: {
					loopBy: [],
					groupBy: ["regionDesc", "pmName"],
					sortBy: [
						{ id: "regionDesc", desc: true },
						{ id: "pmName", desc: false },
					],
					filterBy: [
						{ id: "accessYN", value: "Y" },
						{ id: "contractStatus", value: "1" },
					],
				},
			},
			{
				pageTemplate: "MonthlyForecast",
				tabName: "Monthly Forecast Report",
				pageName: "Monthly Forecast Report",
				parameters: {
					jccmKeyID: null,
					thruMth: moment(new Date()).startOf("month"),
					loopBy: [],
					groupBy: [],
					sortBy: [],
					filterBy: [{ id: "accessYN", value: "Y" }],
				},
			},
			{
				pageTemplate: "ManpowerProjections",
				tabName: "Manpower Projections",
				pageName: "Manpower Projections",
				parameters: {
					jccmKeyID: null,
					thruMth: moment(new Date()).startOf("month"),
					loopBy: [],
					groupBy: [],
					sortBy: [
						{ id: "mth", desc: false },
						{ id: "contract", desc: false },
					],
					filterBy: [],
				},
			},
			{
				pageTemplate: "CostAndBillingProjections",
				tabName: "Cost & Billing Projections",
				pageName: "Cost & Billing Projections",
				parameters: { jccmKeyID: null, thruMth: moment(new Date()).startOf("month") },
				loopBy: [],
				groupBy: [],
				sortBy: [
					{ id: "mth", desc: false },
					{ id: "contract", desc: false },
				],
				filterBy: [],
			},
			{
				pageTemplate: "ChangeOrderLog",
				tabName: "Change Order Log",
				pageName: "Change Order Log",
				parameters: {
					jccmKeyID: null,
					loopBy: [],
					groupBy: [],
					sortBy: [],
					filterBy: [{ id: "pcoStatus", value: ["PEND", "ROM", "RMA", "CREDIT", "BUDGET"] }],
				},
			},
			{
				pageTemplate: "JobCostDrilldown",
				tabName: "Job Cost Drilldown",
				pageName: "Job Cost Drilldown",
				parameters: {
					jccmKeyID: null,
					thruMth: moment(new Date()).startOf("month"),
					loopBy: [{ id: "costType", heading: "costTypeDesc" }],
					groupBy: ["basePhase"],
					sortBy: [],
					filterBy: [],
				},
			},
			// {
			// 	pageTemplate: "Diversity",
			// 	tabName: "Diversity",
			// 	pageName: "Diversity",
			// 	parameters: {
			// 		jccmKeyID: null,
			// 		thruMth: moment(new Date()).startOf("month"),
			// 		loopBy: [],
			// 		groupBy: [],
			// 		sortBy: [],
			// 		filterBy: [],
			// 	},
			// },
			{
				pageTemplate: "SubmittalLog",
				tabName: "Submittal Log (SharePoint)",
				pageName: "Submittal Log",
				parameters: { jccmKeyID: null, groupBy: [], sortBy: [], filterBy: [] },
			},
			{
				pageTemplate: "RFILog",
				tabName: "RFI Log (SharePoint)",
				pageName: "Requests for Information",
				parameters: { jccmKeyID: null, groupBy: [], sortBy: [], filterBy: [] },
			},
			{
				pageTemplate: "RFILogSQL",
				tabName: "RFI Log (SQL)",
				pageName: "Requests for Information SQL",
				parameters: { jccmKeyID: null, groupBy: [], sortBy: [], filterBy: [] },
			},
		];
	}
}
