import React, { useState } from "react";
import { Input } from "@chakra-ui/react";
import { useAsyncDebounce } from "react-table";

// Define a default UI for filtering
export default function TextInputColumnFilter({ column: { filterValue, setFilter } }) {
	const [value, setValue] = useState(filterValue);
	const onChange = useAsyncDebounce((value) => {
		setFilter(value || undefined);
	}, 200);

	return (
		<Input
			size="sm"
			rounded={5}
			variant="outline"
			borderColor="gray.200"
			bg="white"
			value={value || ""}
			onChange={(e) => {
				setValue(e.target.value);
				onChange(e.target.value);
			}}
			placeholder={`Search...`}
		/>
	);
}
