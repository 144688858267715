import React, { useState, useEffect, useMemo } from "react";
import { Stack, HStack, Text, useBoolean, Collapse, IconButton } from "@chakra-ui/react";
import moment from "moment";
// import { portalUser } from "../../../App";
import { formatValue, getRate } from "../../../helperFunctions";

// const DevOpsUsers = [
// 	"dustinf@ppmechanical.com",
// 	"niklasj@ppmechanical.com",
// 	"omare@ppmechanical.com",
// 	"dgebert@ppmechanical.com",
// 	"jared@ppmechanical.com",
// ];

export default function Timer({
	days = 0,
	hours = 0,
	minutes = 0,
	seconds = 0,
	progressBar = null,
	includeUnits = ["icon", "hours", "minutes", "seconds"],
}) {
	const [stopTimer, setStopTimer] = useBoolean(false);
	const [totalSeconds, setTotalSeconds] = useState(0);
	const [time, setTime] = useState(getTimefromSeconds(days * 86400 + hours * 3600 + minutes * 60 + seconds));
	const maxTime = useMemo(() => {
		let maxDays = days ?? 0;
		let maxHours = hours ?? 0;
		let maxMinutes = minutes ?? 0;
		let maxSeconds = seconds ?? 0;
		let totalSeconds = maxDays * 86400 + maxHours * 3600 + maxMinutes * 60 + maxSeconds;
		let maxTime = { totalSeconds, days: maxDays, hours: maxHours, minutes: maxMinutes, seconds: maxSeconds };
		return maxTime;
	}, [days, hours, minutes, seconds]);

	useEffect(() => {
		if (!stopTimer) {
			const timeInterval = setInterval(() => {
				setTotalSeconds((prevTotalSeconds) => prevTotalSeconds + 1);
			}, 1000);

			return () => clearInterval(timeInterval);
		}
	}, [stopTimer, totalSeconds]);

	useMemo(() => {
		if (!stopTimer && Boolean(maxTime.totalSeconds > 0) && totalSeconds >= maxTime.totalSeconds) {
			setStopTimer.on();
		} else {
			setTime(getTimefromSeconds(totalSeconds));
		}
	}, [maxTime.totalSeconds, totalSeconds, stopTimer, setStopTimer]);

	// useMemo(
	// 	() => {
	// 		if (progressBar?.value) {
	// 			Object.assign(progressBar, time);
	// 			if (portalUser.loginTime?.length === 0) {
	// 				portalUser.loginTime.push(progressBar);
	// 			} else if (portalUser.loginTime?.length > 0) {
	// 				let index = portalUser.loginTime?.length - 1;
	// 				let previousTime = portalUser.loginTime[index];

	// 				if (progressBar?.label !== previousTime?.label) {
	// 					portalUser.loginTime.push(progressBar);
	// 				}
	// 			}
	// 		}
	// 	},
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// 	[progressBar?.value]
	// );

	const styles = useMemo(() => {
		let styles = { bg: "whiteAlpha.800", timeColor: "gray.500", unitColor: "gray.400", opacity: 0.8 };
		if (Boolean(maxTime.totalSeconds > 0)) {
			let pctComplete = parseInt(getRate(time.totalSeconds, maxTime.totalSeconds) * 100);

			if (pctComplete >= 100) {
				styles = {
					icon: "fas fa-hourglass-start",
					bg: "red.100",
					timeColor: "red.600",
					unitColor: "red.500",
					opacity: 0.9,
				};
			} else if (pctComplete >= 90) {
				styles = { bg: "red.50", timeColor: "red.600", unitColor: "red.500", opacity: 0.8 };
			} else if (pctComplete >= 82) {
				styles = { bg: "orange.50", timeColor: "orange.600", unitColor: "orange.500", opacity: 0.9 };
			} else if (pctComplete >= 75) {
				styles = { bg: "orange.50", timeColor: "orange.600", unitColor: "orange.500", opacity: 0.8 };
			} else if (pctComplete >= 62) {
				styles = { bg: "yellow.50", timeColor: "yellow.600", unitColor: "yellow.500", opacity: 0.9 };
			} else if (pctComplete >= 50) {
				styles = { bg: "yellow.50", timeColor: "yellow.600", unitColor: "yellow.500", opacity: 0.8 };
			} else if (pctComplete >= 42) {
				styles = { bg: "whiteAlpha.800", timeColor: "gray.500", unitColor: "gray.400", opacity: 0.9 };
			} else if (pctComplete >= 25) {
				styles = { bg: "whiteAlpha.800", timeColor: "gray.500", unitColor: "gray.400", opacity: 0.8 };
			}
		}

		return styles;
	}, [maxTime.totalSeconds, time.totalSeconds]);

	return (
		<Collapse
			in={totalSeconds >= 1}
			transition={{
				enter: { duration: 1 },
				delay: 0.5,
				delayChildren: 0.2,
			}}
			minW={"sm"}
			minH={24}
		>
			<HStack
				align="center"
				justify="center"
				opacity={styles.opacity}
				spacing={3}
				bg="whiteAlpha.600"
				rounded="xl"
				minW={"sm"}
				p={3}
			>
				{includeUnits?.includes("icon") && (
					<IconButton
						align="center"
						justify="center"
						spacing={0}
						bg={styles?.bg ?? "whiteAlpha.800"}
						borderWidth={2}
						borderColor={styles?.timeColor ?? "gray.500"}
						p={3}
						rounded="xl"
						maxH={20}
						h={20}
						minH={20}
						maxW={20}
						w={20}
						minW={20}
						shadow="md"
						icon={
							<Text
								fontWeight="bold"
								fontSize="4xl"
								color={styles?.timeColor ?? "gray.500"}
								lineHeight={1}
							>
								<i className="fas fa-stopwatch fa-fw fa-lg" />
							</Text>
						}
					/>
				)}
				{includeUnits?.includes("days") && <TimerCounter styles={styles} time={time} unit="days" />}
				{includeUnits?.includes("hours") && <TimerCounter styles={styles} time={time} unit="hours" />}
				{includeUnits?.includes("minutes") && <TimerCounter styles={styles} time={time} unit="minutes" />}
				{includeUnits?.includes("seconds") && <TimerCounter styles={styles} time={time} unit="seconds" />}
			</HStack>
		</Collapse>
	);
}

function TimerCounter({ styles = {}, time = {}, unit = null }) {
	const counterStyles = useMemo(() => styles, [styles]);
	const counterUnit = useMemo(() => {
		if (["days", "hours", "minutes", "seconds"]?.includes(unit)) {
			return unit;
		} else {
			return null;
		}
	}, [unit]);
	const counterValue = useMemo(() => {
		if (Boolean(counterUnit)) {
			return formatValue(time[counterUnit], 0, "number");
		} else {
			return "0";
		}
	}, [counterUnit, time]);

	return (
		<Stack
			align="center"
			justify="center"
			spacing={0}
			bg={counterStyles?.bg ?? "whiteAlpha.800"}
			borderWidth={2}
			borderColor={counterStyles?.timeColor ?? "gray.500"}
			p={3}
			rounded="xl"
			maxH={20}
			h={20}
			minH={20}
			maxW={20}
			w={20}
			minW={20}
			shadow="md"
		>
			<Text fontWeight="bold" fontSize="4xl" color={counterStyles?.timeColor ?? "gray.500"} lineHeight={1}>
				{(counterValue?.length === 1 ? "0" : "") + counterValue}
			</Text>
			<Text as="i" fontSize="md" color={counterStyles?.unitColor ?? "gray.400"} lineHeight={1}>
				{counterUnit}
			</Text>
		</Stack>
	);
}

function getTimefromSeconds(totalSeconds = 0, units = null) {
	let datetime = moment(new Date()).format("MM/DD/YYYY hh:mm:ss");
	let days = Math.floor(totalSeconds / 86400);
	let hours = Math.floor((totalSeconds % 86400) / 3600);
	let minutes = Math.floor((totalSeconds % 3600) / 60);
	let seconds = totalSeconds % 60;
	let time = { totalSeconds, datetime, days, hours, minutes, seconds };

	if (Boolean(units) && ["datetime", "days", "hours", "minutes", "seconds"].includes(time?.units)) {
		return time[units];
	} else {
		return time;
	}
}
