const DIVERSITY_CERTS = [
	{
		label: "SBE",
		value: "SBE",
		color: "green",
		description: "Small Business Enterprise",
	},
	{
		label: "LBE",
		value: "LBE",
		color: "yellow",
		description: "Local-Owned Business Enterprise",
	},
	{
		label: "MBE",
		value: "MBE",
		color: "yellow",
		description: "Minority Business Enterprise",
	},
	{
		label: "WBE",
		value: "WBE",
		color: "yellow",
		description: "Women-Owned Business Enterprise",
	},
	{
		label: "DBE",
		value: "DBE",
		color: "yellow",
		description: "Disadvantaged Business Enterprise",
	},
	{
		label: "DVBE",
		value: "DVBE",
		color: "yellow",
		description: "Disabled Veteran Business Enterprise",
	},
	{
		label: "CBE",
		value: "CBE",
		color: "yellow",
		description: "Certified Business Enterprise",
	},
	{
		label: "LSBE",
		value: "LSBE",
		color: "yellow",
		description: "Local Small Business Enterprise",
	},
];
export default DIVERSITY_CERTS;
