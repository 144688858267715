import ResourceGroupClassLegacy from "./ResourceGroupClassLegacy";
import PayrollAPI from "../../../APIs/PayrollAPI";
import { PPMUser } from "../../../PPM_SDK/PPMUser";
import { portalUser } from "../../../App";
import { convertArrayToMap } from "../../../helperFunctions";

export default class ResourceGroupsObjClassLegacy {
	constructor(employeeUID, prEndDate) {
		this.payrollAPI = new PayrollAPI();
		this.employeeUID = employeeUID ?? portalUser.user?.employeeUID;
		this.prEndDate = prEndDate ?? portalUser.user?.prEndDate;
		this.availableResourceGroups = [];
	}

	async getAvailableResourceGroups() {
		let resourceGroups = await this.payrollAPI.GetResourceGroups(portalUser.user?.employeeUID, this.reviewLevel);
		if (resourceGroups.status === 200) {
			resourceGroups = resourceGroups.value;
			for (let i = 0; i < resourceGroups?.length; i++) {
				this.availableResourceGroups.push(new ResourceGroupClassLegacy(resourceGroups[i]));
			}
		}
	}

	getActiveGroup(level, group) {
		let activeGroup = null;
		if (Boolean(group)) {
			if (level === "crewEntry" && Boolean(group.crewEntry)) {
				activeGroup = group;
			}
			if (level === "approval" && Boolean(group.approval)) {
				activeGroup = group;
			}
			if (level === "review") {
				activeGroup = group;
			}
			if (level === "management") {
				activeGroup = group;
			}
		}

		let resourceGroups = this.availableResourceGroups;
		if (!Boolean(activeGroup)) {
			for (let i = 0; i < resourceGroups?.length; i++) {
				if (level === "crewEntry" && Boolean(resourceGroups[i].crewEntry)) {
					activeGroup = resourceGroups[i];
					break;
				}
				if (level === "approval" && Boolean(resourceGroups[i].approval)) {
					activeGroup = resourceGroups[i];
					break;
				}
				if (level === "review") {
					if (resourceGroups[i].groupType === "Global") {
						activeGroup = resourceGroups[i];
						break;
					}
				}
			}
		}
		return activeGroup;
	}
}

export class ResourceGroupsObjClassLegacy2 {
	constructor(employeeUID, reviewLevel) {
		this.payrollAPI = new PayrollAPI();
		this.employeeUID = employeeUID ?? portalUser.user?.employeeUID;
		this.reviewLevel = reviewLevel;
		this.list = [];
		this.activeGroup = null;
	}

	// async loadList() {
	// 	let groups = await this.payrollAPI.GetResourceGroups(this.employeeUID, this.reviewLevel);

	// 	if (groups.status === 200) {
	// 		groups = groups.value;
	// 	}
	// 	for (let i = 0; i < groups.length; i++) {
	// 		this.list.push(new ResourceGroup(groups[i]));
	// 	}
	// }

	setList(resourceGroups) {
		for (let i = 0; i < resourceGroups.length; i++) {
			this.list.push(new ResourceGroup(resourceGroups[i]));
		}
		if (this.list.length > 0) {
			this.setActiveGroup(this.list[0].groupUID);
		}
	}

	setActiveGroup(groupUID) {
		for (let i = 0; i < this.list.length; i++) {
			if (this.list[i].groupUID === groupUID) {
				this.activeGroup = this.list[i];
				break;
			}
		}
	}

	getPrimaryGroup() {
		if (this.list.length > 0) {
			return this.list[0];
		} else {
			return null;
		}
	}

	getGroup(uid) {
		for (let i = 0; i < this.list.length; i++) {
			if (this.list[i].groupUID === uid) {
				return this.list[i];
			}
		}
	}
}

class ResourceGroup {
	constructor(group) {
		this.payrollAPI = new PayrollAPI();
		this.autoUpdate = group?.AutoUpdate ?? group?.autoUpdate ?? false;
		this.groupUID = group?.GroupUID ?? group?.groupUID ?? group?.groupUID ?? null;
		this.groupType = group?.GroupType ?? group?.groupType ?? "";
		this.groupName = group?.GroupName ?? group?.groupName ?? "";
		this.groupDescription = group?.GroupDescription ?? group?.groupDescription ?? "";
		this.approverCount = group?.ApproverCount ?? group?.approverCount ?? null;
		this.crewEntryCount = group?.CrewEntryCount ?? group?.crewEntryCount ?? null;
		this.employeeCount = group?.EmployeeCount ?? group?.employeeCount ?? null;
		this.approval = group?.Approval ?? group?.approval ?? 0;
		this.crewEntry = group?.CrewEntry ?? group?.crewEntry ?? 0;
		this.reviewLevel =
			group?.reviewLevel ??
			(Boolean(this.approval) ? "Approval" : Boolean(this.crewEntry) ? "CrewEntry" : "None");
	}
}

export class ResourceGroupMemberList {
	constructor(members = []) {
		this.payrollAPI = new PayrollAPI();
		this.list = this.createList(members);
	}

	createList(members = []) {
		let groupMembers = [];
		for (let i = 0; i < members?.length; i++) {
			let groupMember = members[i];
			if (!(groupMember instanceof ResourceGroupMember)) {
				groupMember = new ResourceGroupMember(groupMember);
			}

			groupMembers.push(groupMember);
		}
		return groupMembers;
	}

	setList(memberArr) {
		this.list = memberArr;
	}

	async addMember(groupUID, member) {
		for (let i = 0; i < this.list.length; i++) {
			if (member.employeeUID === this.list[i].employeeUID && member.prco === this.list[i].prco) {
				return true;
			}
		}

		let result = await this.payrollAPI.InsertResourceGroupMember(groupUID, member.employeeUID);
		if (result.status === 200) {
			this.list.push(new ResourceGroupMember(member));
			return true;
		}
	}

	async deleteMember(groupUID, member) {
		for (let i = 0; i < this.list.length; i++) {
			if (member.employeeUID === this.list[i].employeeUID && member.prco === this.list[i].prco) {
				let result = await this.payrollAPI.DeleteResourceGroupMember(groupUID, member.employeeUID);
				if (result.status === 200) {
					this.list.splice(i, 1);
					return true;
				}
			}
		}
		return true;
	}
}

const roles = [
	{ role: "crewEntry", sqlColumn: "CrewEntry" },
	{ role: "approval", sqlColumn: "Approval" },
];
const rolesMap = convertArrayToMap(roles, "role");

export class ResourceGroupMember extends PPMUser {
	constructor(member) {
		super(member);
		this.payrollAPI = new PayrollAPI();
		this.employeeUID = member?.employeeUID ?? member?.EmployeeUID ?? null;
		this.groupUID = member?.groupUID ?? member?.GroupUID ?? null;
		this.crewEntry = member?.crewEntry ?? member?.CrewEntry ?? false;
		this.approval = member?.approval ?? member?.Approval ?? false;
	}

	async updateTimecardRole(groupUID = null, role = null, value = null) {
		let sqlColumn = rolesMap[role]?.sqlColumn ?? null;
		if (Boolean(groupUID) && Boolean(this.employeeUID) && Boolean(sqlColumn) && Boolean(value)) {
			let update = { [sqlColumn]: value };
			let result = await this.payrollAPI.UpdateResourceGroupMember(groupUID, this.employeeUID, update);

			if (result.status === 200) {
				this[role] = value;
			}

			return result;
		}
	}
}
