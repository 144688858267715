import ProjectsAPI from "../../../APIs/ProjectsAPI";
import COItem from "./COItem";
import PCO_PRIORITIES from "../constants/PCOPriorities";
import PCO_STATUSES from "../constants/PCOStatuses";

import { portalUser } from "../../../App";
import { formatValue, log, getPct, convertArrayToMap } from "../../../helperFunctions";
import { v4 } from "uuid";

const priorityMap = convertArrayToMap(PCO_PRIORITIES, "value");
const pcoStatusMap = convertArrayToMap(PCO_STATUSES, "value");

export default class PCO {
	constructor(pco) {
		this.pmopUID = v4();
		this.projectsAPI = new ProjectsAPI();
		this.queryData = pco?.queryData ?? pco?.QueryData ?? "PCO";
		this.jccmKeyID = pco?.jccmKeyID ?? pco?.JCCMKeyID ?? null;
		this.jcco = pco?.jcco ?? pco?.JCCo ?? null;
		this.contract = pco?.contract ?? pco?.Contract?.trim() ?? null;

		this.pmopKeyID = pco?.pmopKeyID ?? pco?.PMOPKeyID ?? null;
		this.pco = pco?.pco ?? pco?.PCO?.trim() ?? null;
		this.pcoType = pco?.pcoType ?? pco?.PCOType ?? "PCO";
		this.gcRef = pco?.gcRef ?? pco?.GCRef ?? null;
		this.description = pco?.description ?? pco?.Description ?? null;
		this.priority = pco?.priority ?? pco?.Priority ?? 3;
		this.priorityDesc = pco?.priorityDesc ?? null;

		this.pcoStatus = pco?.pcoStatus ?? pco?.Status?.trim() ?? "PEND";
		this.pcoStatusDesc = pco?.pcoStatusDesc ?? this.getPCOStatusDesc();

		this.pendingStatus = pco?.pendingStatus ?? pco?.PendingStatus ?? 0;

		this.dateCreated = formatValue(pco?.dateCreated ?? pco?.DateCreated, 0, "date") ?? null;
		this.dateSubmitted = formatValue(pco?.dateSubmitted ?? pco?.DateSubmitted, 0, "date") ?? null;
		this.dateApproved = formatValue(pco?.dateApproved ?? pco?.DateApproved, 0, "date") ?? null;

		this.notes = pco?.notes ?? pco?.Notes ?? null;
		this.attachUID = pco?.attachUID ?? pco?.UniqueAttchUID ?? null;

		this.romAmt = pco?.romAmt ?? pco?.ROMAmt ?? 0;
		this.pcoAmt = pco?.pcoAmt ?? pco?.PCOAmt ?? 0;
		this.pcoConv = pco?.pcoConv ?? 0;
		this.pcoConvPct = pco?.pcoConvPct ?? 0;
		this.pctMargin = pco?.pctMargin ?? 15;
		this.pctComplete = pco?.pctComplete ?? pco?.PctComplete ?? 0;
		this.pcoEstCost = pco?.pcoEstCost ?? 0;
		this.pcoActualCost = pco?.pcoActualCost ?? 0;

		this.acoAmt = pco?.acoAmt ?? 0;

		this.coItems = pco?.coItems ?? [];
		// this.editable = true;
	}

	getPCOStatusDesc() {
		this.pcoStatusDesc = pcoStatusMap[this.pcoStatus]?.label;
	}
	getPriorityDesc() {
		this.priorityDesc = priorityMap[this.priority]?.label;
	}

	getPCOItemTotals() {
		this.getPCOStatusDesc();
		this.getPriorityDesc();

		for (let i = 0; i < this.coItems?.length; i++) {
			if (this.coItems[i].pmoiStatus !== this.pcoStatus) {
				this.coItems[i].pmoiStatus = this.pcoStatus;
			}
		}
		if (this.coItems.length > 0) {
			let pcoConv = this.coItems?.map((pcoItem) => pcoItem?.pcoConv).reduce((prev, curr) => prev + curr, 0) ?? 0;
			let acoAmt = this.coItems?.map((pcoItem) => pcoItem?.acoAmt).reduce((prev, curr) => prev + curr, 0) ?? 0;
			let pcoEstCost =
				this.coItems?.map((pcoItem) => pcoItem?.getPCOEstCost()).reduce((prev, curr) => prev + curr, 0) ?? 0;
			let pcoActualCost =
				this.coItems?.map((pcoItem) => pcoItem?.getPCOActualCost()).reduce((prev, curr) => prev + curr, 0) ?? 0;

			let pctMargin = [...new Set(this.coItems?.map((pcoItem) => pcoItem.pctMargin))];
			let pctComplete = [...new Set(this.coItems?.map((pcoItem) => pcoItem.pctComplete))];

			if (pcoConv !== 0) {
				pctMargin = getPct(pcoConv - pcoEstCost, pcoConv);
			} else if (Array.isArray(pctMargin) && pctMargin?.length === 1) {
				pctMargin = pctMargin[0];
			} else {
				pctMargin = 0;
			}
			if (pcoEstCost !== 0) {
				pctComplete = getPct(pcoActualCost, pcoEstCost);
			} else if (Array.isArray(pctComplete) && pctComplete?.length === 1) {
				pctComplete = pctComplete[0];
			} else {
				pctComplete = 0;
			}

			let pcoConvPct = getPct(pcoConv, this.pcoAmt);

			this.pcoConv = pcoConv;
			this.acoAmt = acoAmt;
			this.pcoEstCost = pcoEstCost;
			this.pcoActualCost = pcoActualCost;
			this.pcoConvPct = pcoConvPct;
			this.pctMargin = pctMargin;
			this.pctComplete = pctComplete;
		}
	}

	async addCOItem(contractItem = null) {
		let pco = this;

		if (!Boolean(contractItem)) {
			let contractItems = portalUser?.getContractItems([pco.jccmKeyID]);
			let coItemCount = pco.coItems?.length ?? 0;
			if (coItemCount > contractItems.length) {
				coItemCount = 0;
			}
			contractItem = contractItems[coItemCount];
		}

		let coItem = new COItem({
			jccmKeyID: pco.jccmKeyID,
			jcco: pco.jcco,
			contract: pco.contract,
			pmopKeyID: pco.pmopKeyID,
			pco: pco.pco,
			pmoiStatus: pco.pcoStatus,
			jcciKeyID: contractItem?.jcciKeyID,
			contractItem: contractItem?.contractItem,
		});

		if (pco.pmopKeyID !== null) {
			coItem.pmopKeyID = pco.pmopKeyID;
			coItem = await coItem.insertPMOI();
		}

		pco.coItems.push(coItem);
		return pco;
	}

	async deleteCOItem(itemUID) {
		let coItems = this.coItems;
		let deleteItem = coItems.find((data) => data?.pmoiUID === itemUID) ?? {};
		if (Boolean(deleteItem?.pmoiKeyID)) {
			deleteItem.deletePMOI();
		}
		this.coItems = coItems.filter((data) => data?.itemUID !== itemUID);
		this.getPCOItemTotals();
		return this;
	}

	validatePCO() {
		this.getPCOItemTotals();

		let errors = [];

		// PCO NUMBER
		let existingPCOs =
			portalUser?.contractData?.filter((d) => d.queryData === "PCO" && d.jccmKeyID === this.jccmKeyID) ?? [];
		if (existingPCOs.length > 0 && this.pmopKeyID === null) {
			existingPCOs = existingPCOs?.map((d) => d.pco) ?? [];
			if (existingPCOs.includes(this.pco)) {
				errors.push({ errorMsg: "This PCO number has already been used.", ref: "pcoRef", color: "red.500" });
			}
		}

		let pcoAmt = parseFloat(
			this.pcoAmt?.toString()?.replace("$", "")?.replace(",", "")?.replace(" ", "")?.replace("%", "") ?? null
		);

		let pcoConv = parseFloat(
			this.pcoConv?.toString()?.replace("$", "")?.replace(",", "")?.replace(" ", "")?.replace("%", "") ?? null
		);

		// PCO DESCRIPTION
		if (!Boolean(this.description)) {
			errors.push({ errorMsg: "PCO must have a Description", ref: "descriptionRef", color: "yellow.500" });
		}

		// PCO SUBMITTED
		if (isNaN(pcoAmt)) {
			errors.push({ errorMsg: "Amount Submitted must be a Number", ref: "pcoAmtRef", color: "yellow.500" });
			// pcoAmt = 0;
		} else if (["PEND"].includes(this?.pcoStatus) && pcoAmt < 0) {
			errors.push({ errorMsg: "Amount Submitted must be Positive", ref: "pcoAmtRef", color: "yellow.500" });
		} else if (["CREDIT"].includes(this?.pcoStatus) && pcoAmt > 0) {
			errors.push({ errorMsg: "Amount Submitted must be Negative", ref: "pcoAmtRef", color: "yellow.500" });
		}
		this.pcoAmt = pcoAmt;

		// PCO CONVERSION
		if (isNaN(pcoConv)) {
			errors.push({ errorMsg: "Amount Submitted must be a Number", ref: "pcoConvRef", color: "yellow.500" });
			// this.pcoConv = 0;
		} else if (["PEND"].includes(this?.pcoStatus) && pcoConv < 0) {
			errors.push({ errorMsg: "Amount Submitted must be Positive", ref: "pcoConvRef", color: "yellow.500" });
		} else if (["CREDIT"].includes(this?.pcoStatus) && pcoConv > 0) {
			errors.push({ errorMsg: "Amount Submitted must be Negative", ref: "pcoConvRef", color: "yellow.500" });
		}

		return errors;
	}

	/****************************************************************/
	/*************** SQL UPDATES -  JCCM AND JCJM  *****************/
	/************ Contract and Job Master Tables ******************/
	/*************************************************************/

	// PCOs - SQL INSERT PMOP
	async insertPMOP() {
		this.pco = this.pco?.toString()?.toUpperCase();
		if (!Boolean(this.pmopKeyID) && Boolean(this.jccmKeyID) && Boolean(this.pco)) {
			let data = {
				jccmKeyID: this.jccmKeyID,
				pco: this.pco,
				gcRef: this.gcRef,
				description: this.description,
				dateSubmitted: this.dateSubmitted,
				pcoAmt: this.pcoAmt,
				pcoStatus: this.pcoStatus,
				notes: this.notes,
			};
			// log("this.pcoAmt", this.pcoAmt);
			let pmopResult = await this.projectsAPI.InsertPMOP(this.jccmKeyID, data);
			if (pmopResult?.status === 200 && pmopResult?.value?.length > 0) {
				pmopResult = pmopResult.value[0];

				this.pmopKeyID = pmopResult.pmopKeyID;

				let coItems = [];
				for (let i = 0; i < this.coItems?.length; i++) {
					let coItem = this.coItems[i];
					coItem.pmopKeyID = this.pmopKeyID;
					coItem = await coItem.insertPMOI();
					coItems.push(coItem);
				}
				this.coItems = coItems;
			}

			return this;
		}
	}

	// PCOs - SQL UPDATE PMOP
	async updatePMOP(data) {
		if (Boolean(this.pmopKeyID)) {
			let result = await this.projectsAPI.UpdatePMOP(this.pmopKeyID, data);
			log("DATABASE RESULT UPDATE PMOP", result);
			return result;
		}
	}

	getPMOPAttribute(attribute) {
		// STRINGS
		if (attribute === "pco") {
			return "PCO";
		} else if (attribute === "gcRef") {
			return "Details";
		} else if (attribute === "description") {
			return "Description";
		} else if (attribute === "notes") {
			return "Notes";
		} else if (attribute === "pcoStatus") {
			return "Status";
			// NUMBERS
		} else if (attribute === "priority") {
			return "Priority";
		} else if (attribute === "pcoAmt") {
			return "ROMAmount";
		}
		// DATES
		else if (attribute === "dateSubmitted") {
			return "Date2";
		}
	}

	// PCOs - SQL DELETE PMOP
	async deletePMOP() {
		let result = await this.projectsAPI.DeletePMOP(this.pmopKeyID);
		// log("DATABASE RESULT DELETE PMOP", result);
		return result;
	}

	async update(attribute = null, value = null) {
		// log(attribute, value);

		let strings = ["pco", "description", "gcRef", "notes", "pcoStatus"];
		let numbers = ["priority", "pcoAmt"];
		let dates = ["dateSubmitted"];

		let pmopAttributes = [
			"pco",
			"description",
			"gcRef",
			"notes",
			"pcoStatus",
			"pcoAmt",
			"dateSubmitted",
			"priority",
		];
		let val = null;

		// STRINGS
		if (strings.includes(attribute)) {
			val = value?.replace("'", "`") ?? null;
			if (attribute !== "notes") {
				val = val?.replace(/\n/g, " ")?.trim() ?? null;
			}

			this[attribute] = val;
		}
		// NUMBERS
		else if (numbers.includes(attribute)) {
			val = parseFloat(formatValue(value, 2, "numeric"));

			log("val in update PCO", val);
			if (!Boolean(val) || isNaN(val)) {
				val = 0;
			}

			this[attribute] = val;
		}
		// DATES
		else if (dates.includes(attribute)) {
			if (Boolean(value)) {
				val = formatValue(value, 0, "date");
				this[attribute] = val;
			}
		}

		// Update PMOP
		if (pmopAttributes.includes(attribute) && Boolean(this.pmopKeyID)) {
			let dbAttribute = this.getPMOPAttribute(attribute);
			let update = { [dbAttribute]: val };
			let result = await this.updatePMOP(update);
			return result;
		}
	}
}
