import React, { useState, useMemo } from "react";
import {
	Text,
	Heading,
	HStack,
	Stack,
	Drawer,
	DrawerBody,
	DrawerOverlay,
	DrawerContent,
	DrawerHeader,
	DrawerCloseButton,
	IconButton,
	Collapse,
	Flex,
	SimpleGrid,
	VStack,
	Center,
	Checkbox,
} from "@chakra-ui/react";
import DataInput from "../../../core/Inputs/data/DataInput";
import { RegionList } from "../classes/MeritIncreasesClassesLegacy";
import { log } from "../../../helperFunctions";

export default function MeritIncreasesDeptSecurityDrawer(props) {
	// const [selectedAccess, setSelectedAccess] = useState(props.selectedAccess);
	const [selectedLeader, setSelectedLeader] = useState(props.selectedLeader);
	const regions = useMemo(
		() => new RegionList(props.regionArr),
		[props.regionArr]
	);

	// let levels = props.levelsMap;
	// let defaultLevel = levels[0];
	// if (selectedAccess?.accessLevelDesc !== null) {
	// 	defaultLevel = selectedAccess?.accessLevelDesc;
	// } else if (selectedLeader?.employeeUID !== null) {
	// 	defaultLevel = selectedLeader?.accessLevelDesc;
	// }

	let employees = props.employeeHistory;

	let leaders = employees?.filter(
		(data) => data?.employeeUID !== "AB653524-E7EE-40FA-B095-6A68D8BCEC27"
	);

	const leaderDropdown = (leader) => {
		setSelectedLeader(leader);
	};

	// log('selectedLeader', selectedLeader)

	let departments = selectedLeader?.departments;
	departments = departments?.filter((data) => data?.deptID !== 23) ?? [];

	// log("departments", departments);
	//log(departments, departments);
	//let parentDepts = departments.createDepartmentMap('parentDeptID')
	//let deptGroups = parentDepts.createDepartmentMap('deptGroupID')

	let deptGroups = departments?.createDepartmentMap("deptGroupID") ?? {};

	//log('selectedLeader', selectedLeader )
	//log('deptGroups',deptGroups)

	return (
		<Drawer
			placement="right"
			isOpen
			onClose={props.onClose}
			size="lg"
			key={"Drawer-" + 1}
		>
			<DrawerOverlay />

			<DrawerContent>
				<DrawerCloseButton />

				<DrawerHeader>
					<HStack align="center" w="full" spacing={2}>
						<IconButton
							variant="unstyled"
							color="gray.400"
							boxSize="30px"
							minW="20px"
						>
							<i
								className={
									(selectedLeader?.employeeUID === null
										? "fas fa-plus-square"
										: "fas fa-edit") + " fa-fw fa-lg"
								}
							/>
						</IconButton>
						<Heading
							as="h4"
							size="md"
							color="gray.400"
							textTransform="uppercase"
							letterSpacing={2}
						>
							{selectedLeader?.employeeUID === null
								? "Add Department Leader"
								: "Edit Leader Access"}
						</Heading>
					</HStack>
				</DrawerHeader>

				<DrawerBody>
					<Stack spacing={2} w="full">
						<HStack align="center" w="full" spacing={1}>
							<IconButton
								variant="unstyled"
								color="gray.500"
								boxSize="30px"
								minW="20px"
							>
								<i className="fas fa-shield-alt fa-fw fa-lg" />
							</IconButton>
							<Text
								h="full"
								fontSize="md"
								textTransform="uppercase"
								color="gray.500"
								fontWeight="600"
								letterSpacing={1}
							>
								Department Leader Access
							</Text>
						</HStack>

						<Stack
							p="4"
							spacing={4}
							bg="blackAlpha.50"
							border="2px"
							borderColor="blackAlpha.200"
							rounded="10"
						>
							<Stack spacing={2} direction="row">
								<DataInput
									key={
										"employeeUID-" +
										selectedLeader?.employeeUID
									}
									name="employeeUID"
									type="select"
									label="Employee Name"
									placeholder="Search Employees"
									searchable
									static
									value={selectedLeader?.employeeName}
									options={leaders}
									// onBlur={(ev) => { ev.stopPropagation(); props.updatePCO('pco', ev, true) }}
									// inputRef={pcoRef}
									// error={(pcoErrors.length > 0 ? pcoErrors[0] : false)}
									optionText="employeeName"
									optionSubheading="subheading"
									onOptionSelect={leaderDropdown}
									flex={2}
									variant="outline"
									inputProps={{ px: "2" }}
								/>
							</Stack>

							<Collapse in={Boolean(selectedLeader)}>
								<Stack spacing={6}>
									{deptGroups?.map((deptGroup, i) => {
										let deptCount = deptGroup?.rows?.length;

										return (
											<MeritIncreasesDeptSecurityGroupItem
												key={i}
												regions={regions}
												deptGroup={deptGroup}
												selectedLeader={selectedLeader}
												deptCount={deptCount}
												isOpen={Boolean(selectedLeader)}
											/>
										);
									})}
								</Stack>
							</Collapse>
						</Stack>
					</Stack>
				</DrawerBody>
				{/* <DrawerFooter></DrawerFooter> */}
			</DrawerContent>
		</Drawer>
	);
}

function MeritIncreasesDeptSecurityGroupItem(props) {
	let deptGroup = props.deptGroup;

	const [isOpen, setIsOpen] = useState(false);

	const handleToggle = () => setIsOpen(!isOpen);

	let icon = isOpen ? "fa-minus" : "fa-plus";

	// bg={(isHovering ? 'blackAlpha.50' : '')}
	// onMouseEnter={setHovering.on} onMouseLeave={setHovering.off}

	useMemo(() => {
		let depts = deptGroup?.rows?.filter((data) => data?.hasAccess === true);
		let hasAccess = false;
		if (depts?.length > 0) {
			hasAccess = true;
		}
		setIsOpen(hasAccess);
	}, [deptGroup?.rows]);

	log("deptGroup", deptGroup);

	let deptCount = props.deptCount;
	let regions = props.regions;

	if (
		props.selectedLeader?.accessLevel === 1 ||
		props.selectedLeader?.accessLevel === 2
	) {
		// if (deptGroup?.deptGroupID !== 1) {
		// 	//setIsOpen(false)
		// }
	}

	return (
		<Stack spacing={1} key={deptGroup?.deptGroupID}>
			<Flex justify={"flex-start"} align="center">
				<IconButton
					key={icon}
					onClick={handleToggle}
					variant="unstyled"
					color="gray.500"
					size="sm"
					icon={<i className={"fas " + icon + " fa-fw fa-lg"}></i>}
				/>
				<Heading
					isTruncated
					size="sm"
					color="gray.500"
					textTransform="uppercase"
				>
					{deptGroup?.deptGroupDesc}
				</Heading>
			</Flex>
			<Collapse in={isOpen}>
				<Stack
					spacing={4}
					bg="white"
					p="4"
					border="2px"
					borderColor="blackAlpha.200"
					rounded="10"
				>
					<Stack
						spacing={4}
						onClick={handleToggle}
						_hover={{ cursor: "pointer" }}
					>
						<Stack spacing={1}>
							<Stack spacing={4}>
								<Stack spacing={1} key={deptGroup?.deptGroupID}>
									<Flex
										w="full"
										justify="flex-start"
										align="center"
									>
										<Flex isTruncated flex={1} pr={6}>
											<Heading
												isTruncated
												size="sm"
												color="gray.500"
												textTransform="uppercase"
											>
												{deptGroup?.deptGroup}
											</Heading>
										</Flex>

										<MeritIncreasesDeptSecurityRowItem
											regions={regions}
											selectedLeader={
												props.selectedLeader
											}
											type="header"
										/>
									</Flex>
								</Stack>
							</Stack>
						</Stack>

						<MeritIncreasesDeptSecurityDeptItem
							deptGroup={deptGroup}
							selectedLeader={props.selectedLeader}
							deptCount={deptCount}
						/>
					</Stack>
				</Stack>
			</Collapse>
		</Stack>
	);
}

function MeritIncreasesDeptSecurityDeptItem(props) {
	const [isOpen, setIsOpen] = React.useState(true);

	React.useMemo(
		() => setIsOpen(Boolean(props.selectedLeader)),
		[props.selectedLeader]
	);

	let departments = props.deptGroup?.rows;

	return (
		<Collapse in={isOpen}>
			<Stack spacing={4} pl={6}>
				{departments?.map((dept, i) => {
					let regions = dept?.regions;
					return (
						<Stack key={i} spacing={1}>
							<Flex
								w="full"
								justify="space-between"
								align="center"
							>
								<Flex isTruncated flex={1}>
									<Heading
										isTruncated
										size="sm"
										color="gray.400"
										textTransform="uppercase"
									>
										{dept?.dept}
									</Heading>
								</Flex>
								<MeritIncreasesDeptSecurityRowItem
									regions={regions}
									selectedLeader={props.selectedLeader}
									type="line"
								/>
							</Flex>
						</Stack>
					);
				})}
			</Stack>
		</Collapse>
	);
}

function MeritIncreasesDeptSecurityRowItem(props) {
	let regions = props.regions;
	let type = props.type;

	return (
		<SimpleGrid
			columns={4}
			spacing={5}
			flex={1}
			minW="50px"
			px="2"
			align="center"
		>
			{regions?.map((region, i) => {
				let regionLabel =
					region?.region === "All Regions" ? "All" : region?.region;

				return (
					<Center key={i} minW="40px" align="center">
						<VStack spacing={1}>
							{type === "header" && (
								<Text
									fontWeight="bold"
									color={region?.color}
									textTransform="uppercase"
									fontSize="sm"
								>
									{regionLabel}
								</Text>
							)}
							{type === "line" && (
								<Checkbox
									color={region?.color}
									isChecked={region?.hasAccess}
								></Checkbox>
							)}
						</VStack>
					</Center>
				);
			})}
		</SimpleGrid>
	);
}
