import { formatDate, getRate } from "../../../helperFunctions";

export default class ItemTypeDetailByMth {
	constructor(itemType) {
		this.queryData = itemType?.queryData ?? itemType?.QueryData ?? "ItemTypeDetailByMth";
		this.jccmKeyID = itemType?.jccmKeyID ?? itemType?.JCCMKeyID ?? null;
		this.jcco = itemType?.jcco ?? itemType?.JCCo ?? "1";
		this.contract = itemType?.contract ?? itemType?.Contract?.trim() ?? null;
		this.mth = formatDate(itemType?.mth ?? itemType?.Mth) ?? null;

		this.itemTypeSeq = itemType?.itemTypeSeq ?? itemType?.ItemTypeSeq;
		this.itemTypeID = itemType?.itemTypeID ?? itemType?.ItemTypeID ?? "COW";
		this.itemType = itemType?.itemType ?? itemType?.ItemType ?? "Cost of Work";

		this.costTypeSeq = itemType?.costTypeSeq ?? itemType?.CostTypeSeq ?? null;
		this.costType = itemType?.costType ?? itemType?.CostType ?? null;
		this.costTypeDesc = itemType?.costTypeDesc ?? itemType?.CostTypeDesc ?? null;

		this.origEstCost = itemType?.origEstCost ?? itemType?.OrigEstCost ?? 0;
		this.currEstCost = itemType?.currEstCost ?? itemType?.CurrEstCost ?? 0;
		this.actualCost = itemType?.actualCost ?? itemType?.ActualCost ?? 0;
		this.projCost = itemType?.projCost ?? itemType?.ProjCost ?? 0;
		this.remainCost = this.getRemainCost();

		this.billedAmt = itemType?.billedAmt ?? itemType?.BilledAmt ?? 0;
		this.adjBilledAmt = itemType?.adjBilledAmt ?? itemType?.AdjBilledAmt ?? 0;
		this.newBilledAmt = this.getBilledAmt();

		this.preBilled = itemType?.preBilled ?? itemType?.PreBilled ?? 0;
		this.activeBilled = itemType?.activeBilled ?? itemType?.ActiveBilled ?? 0;

		this.billedCost = itemType?.billedCost ?? itemType?.BilledCost ?? 0;
		this.unBilledCost = itemType?.unBilledCost ?? itemType?.UnBilledCost ?? 0;
		this.billedIndirectCost = itemType?.billedIndirectCost ?? itemType?.BilledIndirectCost ?? 0;
		this.unBilledIndirectCost = itemType?.unBilledIndirectCost ?? itemType?.UnBilledIndirectCost ?? 0;
		this.indirectPct = this.getIndirectPct();
		this.unBilledCostLessIndirects = this.getUnBilledCostsLessIndirects();

		this.projContractAmt = this.getProjContractAmt();
		this.projRemainBilling = this.getProjRemainBilling();
		this.finalContractAmt = this.getFinalContractAmt();
	}

	getBilledAmt(lumpSumGCs = true) {
		let billedAmt = parseFloat(this.billedAmt ?? 0);
		if (Boolean(lumpSumGCs)) {
			billedAmt += parseFloat(this.adjBilledAmt ?? 0);
		}
		return billedAmt;
	}

	getRemainCost() {
		let remainCost = parseFloat(this.projCost ?? 0) - parseFloat(this.actualCost ?? 0);
		return remainCost;
	}

	getIndirectPct() {
		let indirectPct = null;
		if (Boolean(this.costType === 1)) {
			let indirectCost = parseFloat(this.billedIndirectCost ?? 0) + parseFloat(this.unBilledIndirectCost ?? 0);
			indirectPct = getRate(indirectCost, this.actualCost) * 100;
		}
		return indirectPct;
	}

	getUnBilledCostsLessIndirects() {
		let unbilledCostsLessIndirects =
			parseFloat(this.unBilledCost ?? 0) -
			parseFloat(this.unBilledIndirectCost ?? 0) +
			(parseFloat(this.activeBilled ?? 0) - parseFloat(this.preBilled ?? 0));

		return unbilledCostsLessIndirects;
	}

	getProjContractAmt() {
		return this.currEstCost ?? 0;
	}

	getProjRemainBilling(lumpSumGCs = true) {
		let projRemainingBilling = 0;
		let remainCost = this.getRemainCost();
		let remainIndirect = (remainCost * this.getIndirectPct()) / 100;
		let unbilledCostsLessIndirects = this.getUnBilledCostsLessIndirects();
		// let activeBilledAmt = parseFloat(this.activeBilled ?? 0);
		// let preBilledAmt = parseFloat(this.preBilled ?? 0) * -1;

		if (lumpSumGCs) {
			if (["COW"].includes(this.itemTypeID)) {
				projRemainingBilling = remainCost - remainIndirect;
				projRemainingBilling += unbilledCostsLessIndirects;
				// projRemainingBilling += activeBilledAmt;
				// projRemainingBilling += preBilledAmt;
			}
		} else {
			if (["COW", "GCS"].includes(this.itemTypeID)) {
				projRemainingBilling = remainCost - remainIndirect;
				projRemainingBilling += unbilledCostsLessIndirects;
				// projRemainingBilling += activeBilledAmt;
				// projRemainingBilling += preBilledAmt;
			}
		}

		return projRemainingBilling;
	}

	getFinalContractAmt(lumpSumGCs = true) {
		let finalContractAmt = this.getProjRemainBilling(lumpSumGCs) + this.getBilledAmt(lumpSumGCs);
		return finalContractAmt;
	}

	toggleLumpSumGCs(lumpSumGCs = true) {
		this.newBilledAmt = this.getBilledAmt(lumpSumGCs);
		this.projContractAmt = this.getProjContractAmt(lumpSumGCs);
		this.projRemainBilling = this.getProjRemainBilling(lumpSumGCs);
		this.finalContractAmt = this.getFinalContractAmt(lumpSumGCs);
	}
}
