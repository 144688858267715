import React, { useMemo } from "react";
import { Box, Flex, Spacer, Heading, Text, useBoolean, Stack } from "@chakra-ui/react";
import Alert from "../../../core/Alerts/Alert";
import { portalUser } from "../../../App";

export default function HRTemplatesCard(props) {
	const [deleteAlertIsOpen, setAlertOpen] = useBoolean();

	const template = useMemo(() => props.template, [props.template]);

	// const deleteFormTemplate = () => {
	// 	setAlertOpen.off();
	// 	props.deleteFormTemplate(template.templateUID);
	// };

	return (
		<>
			<Flex
				direction="column"
				key={template.templateUID}
				id={template.templateUID}
				onClick={() => props.selectForm(template.formUID)}
				h="3xs"
				shadow="lg"
				borderRadius="lg"
				bg="white"
				w="full"
				minW="2xs"
				maxW="2xs"
				borderWidth={2}
				borderColor={portalUser.styles.cardBorderColor}
			>
				<Box bg="teal.600" p={2} borderTopRadius="lg" borderWidth={2} borderColor="teal.600">
					<Flex w="full" flex={1} justify="space-between" align="center">
						<Heading color="white" textTransform="uppercase" letterSpacing={1} size="xs" px={1}>
							{template?.formTypeDesc}
						</Heading>
						{/* <Menu> */}
						{/* <MenuButton
								as={IconButton}
								variant="ghost"
								size="sm"
								color="white"
								_hover={{ bg: "whiteAlpha.600", color: "gray.200" }}
								p={1}
								icon={<i className="fas fa-ellipsis-v " />}
								onClick={(ev) => {
									ev.stopPropagation();
									props.selectForm(template?.formUID)
								}}
							/> */}
						{/* <Portal>
								<MenuList>
									<MenuItem
										align="center"
										color="gray.500"
										icon={<i className="fas fa-user-tag fa-lg fa-fw" />}
										onClick={(ev) => {
											ev.stopPropagation();
											props.openAssignModal(template);
										}}
									>
										<Text fontWeight="semibold" textTransform="uppercase">
											Assign To
										</Text>
									</MenuItem>
									<MenuItem
										align="center"
										color="gray.500"
										icon={<i className="fas fa-trash-alt fa-lg fa-fw" />}
										onClick={(ev) => {
											ev.stopPropagation();
											setAlertOpen.on();
										}}
									>
										<Text fontWeight="semibold" textTransform="uppercase">
											Delete
										</Text>
									</MenuItem>
								</MenuList>
							</Portal> */}
						{/* </Menu> */}
					</Flex>
				</Box>
				<Flex direction="column" p={3} flex={1} color="gray.600">
					<Stack spacing={1}>
						<Heading size="sm" isTruncated>
							{template?.title}
						</Heading>
						<Text color="gray.500" fontWeight="semibold" fontSize="sm">
							{template?.subtitle}
						</Text>
					</Stack>

					<Spacer />
					<Flex w="full" flex={1} justify="flex-end" align="flex-end">
						<Text fontSize="sm">
							{"Date Created: " + new Date(template?.dateCreated).toLocaleDateString()}
						</Text>
					</Flex>
				</Flex>
			</Flex>

			<Alert
				isOpen={deleteAlertIsOpen}
				onClose={setAlertOpen.off}
				title="Delete Form Template"
				// onDelete={deleteFormTemplate}
			/>
		</>
	);
}
