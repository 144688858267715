export default class FormRating {
	constructor(rating) {
		if (rating instanceof FormRating) {
			Object.assign(this, rating);
		} else {
			this.queryData = rating?.queryData ?? "FormRating";

			//FormUID
			this.templateUID = rating?.templateUID ?? null;
			this.questionUID = rating?.questionUID ?? null;
			this.ratingUID = rating?.ratingUID ?? null;

			// Integer
			this.keyID = rating?.keyID ?? null;
			this.order = rating?.order ?? null;

			//String
			this.heading = rating?.heading ?? null;
			this.subheading = rating?.subheading ?? null;
			this.ratingType = rating?.ratingType ?? null;
		}
	}
}
