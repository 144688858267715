import React, { useEffect, useState } from "react";
import { Center, Input, Tooltip, useBoolean, IconButton } from "@chakra-ui/react";

export default function TimecardHourInput({ hour, item, disabled, style, onChange, hourIndex, openNotes }) {
	const [isHovering, setHovering] = useBoolean();
	const [overtime, setOvertime] = useState(false);

	useEffect(() => {
		hour > 8 ? setOvertime(true) : setOvertime(false);
	}, [hour]);

	return (
		<Center
			onMouseEnter={Boolean(item.keyId) && (!disabled || !item.isLocked()) ? setHovering.on : setHovering.off}
			onMouseLeave={setHovering.off}
			position="relative"
			tabIndex="-1"
		>
			{overtime ? (
				<>
					<Tooltip label="Over 8 hours in a day">
						<Input
							focusBorderColor="teal.600"
							borderBottomWidth={2}
							_hover={{
								borderBottomColor:
									(!disabled || !item.isLocked()) && Boolean(item.keyId) ? "teal.600" : "red.600",
							}}
							variant="flushed"
							placeholder="0.0"
							fontWeight="semibold"
							position="relative"
							color={overtime ? "red" : "gray.600"}
							value={hour === "0.0" ? "" : hour}
							size="md"
							bg="white"
							rounded="md"
							textAlign="center"
							type="number"
							isDisabled={!Boolean(item.keyId) || disabled || item.isLocked()}
							cursor={!Boolean(item.keyId) || disabled || item.isLocked() ? "not-allowed" : ""}
							style={style ?? {}}
							onClick={(ev) => {
								ev.stopPropagation();
							}}
							maxW="50px"
							onChange={(ev) => {
								onChange(ev, hourIndex, false);
							}}
							onBlur={(ev) => {
								onChange(ev, hourIndex, true);
							}}
						/>
					</Tooltip>
				</>
			) : (
				<Input
					focusBorderColor="teal.600"
					boxShadow={"0px 0px 0px 0px transparent"}
					borderBottomWidth={2}
					_hover={{
						borderBottom: "2px",
						borderBottomColor:
							(!disabled || !item.isLocked()) && Boolean(item.keyId) ? "teal.600" : "red.600",
					}}
					variant="flushed"
					placeholder="0.0"
					fontWeight="semibold"
					position="relative"
					color={overtime ? "red" : "gray.600"}
					value={hour === "0.0" ? "" : hour}
					size="md"
					bg="white"
					textAlign="center"
					type="number"
					isDisabled={!Boolean(item.keyId) || disabled || item.isLocked()}
					cursor={!Boolean(item.keyId) || disabled || item.isLocked() ? "not-allowed" : ""}
					style={style ?? {}}
					onClick={(ev) => {
						ev.stopPropagation();
					}}
					maxW="50px"
					onChange={(ev) => {
						onChange(ev, hourIndex, false);
					}}
					onBlur={(ev) => {
						onChange(ev, hourIndex, true);
					}}
				/>
			)}
			<IconButton
				icon={
					<i
						className={
							"fa-lg " + (item.hasNotes(hourIndex) ? "fas fa-clipboard-check" : "far fa-clipboard")
						}
					></i>
				}
				color="gray.500"
				position="absolute"
				right={-4}
				bg="none"
				hidden={item.hasNotes(hourIndex) ? false : !isHovering}
				size="sm"
				variant="ghost"
				cursor={!Boolean(item.keyId) ? "not-allowed" : "pointer"}
				onClick={() => {
					openNotes(hourIndex);
				}}
				isDisabled={!Boolean(item.keyId)}
				tabIndex="-1"
			/>
		</Center>
	);
}
