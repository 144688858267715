import React from "react";
import { Flex, Text, Collapse } from "@chakra-ui/react";

export default function TableSizesHeaderRow({ sizesIsOpen = false, tableBodyWidth, maxWidth, scrollBarPadding }) {
	return (
		<Collapse
			in={sizesIsOpen}
			animateOpacity
			style={{
				display: "flex",

				flexDirection: "row",
				width: "100%",
				maxWidth: "100%",
				justifyContent: "flex-start",
				alignItems: "stretch",
				borderTopRadius: "var(--chakra-radii-md)",
			}}
		>
			<Flex
				borderTopLeftRadius="md"
				borderTopRightRadius={scrollBarPadding !== 0 ? 0 : "md"}
				direction="column"
				flex={1}
				justify="center"
				align="center"
				color="gray.500"
				bg="whiteAlpha.400"
			>
				<Flex pl={scrollBarPadding} w="full" direction="column" flex={1} justify="center" align="center" py={1}>
					<Text>{"Max Width: " + maxWidth}</Text>
					<Text isTruncated>{tableBodyWidth + "px"}</Text>
				</Flex>
			</Flex>

			<Flex
				direction="column"
				w={scrollBarPadding}
				justify="center"
				align="flex-end"
				borderTopRightRadius="md"
				color="gray.500"
				bg="blackAlpha.100"
			>
				{scrollBarPadding !== 0 && (
					<Text fontSize="sm" isTruncated>
						{scrollBarPadding}
					</Text>
				)}
			</Flex>
		</Collapse>
	);
}
