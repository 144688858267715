import React from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, useRouteMatch, useLocation } from "react-router-dom";
import { log } from "../../../helperFunctions";

import HomePage from "../../Home/pages/HomePage";
import Newsletter from "../../Employee/Newsletter";
import GlassTheme from "../components/GlassTheme";
import "../../../glass.css";
import FontAwesomeIcons from "../components/FontAwesomeIcons";
const styles = { spacing: 8 };
export const flatRoutes = [
	{
		group: "Employee Portal",
		name: "Employee",
		basepath: "/employee",
		path: "/",
		icon: "fas fa-user",
	},
	{
		group: "Employee Portal",
		name: "Home",
		basepath: "/employee",
		path: "/home",
		icon: "fas fa-home",
		page: <HomePage styles={styles} />,
	},
	{
		group: "Employee Portal",
		name: "Profile",
		basepath: "/employee",
		path: "/profile",
		icon: "fas fa-user",
		page: <GlassTheme />,
	},
	{
		group: "Employee Portal",
		name: "Team",
		basepath: "/employee",
		path: "/team",
		icon: "fas fa-user-friends",
		page: <FontAwesomeIcons />,
	},
	{
		group: "Employee Portal",
		name: "Timecard",
		basepath: "/timecard",
		path: "/",
		icon: "fas fa-clock",
	},
	{
		group: "Employee Portal",
		name: "My Timecard",
		basepath: "/timecard",
		path: "/mytime",
		icon: "fas fa-clock",
		// page: <TimecardRoutes pageName="My Timecard" tabVal={0} />,
	},
	{
		group: "Employee Portal",
		name: "Crew Entry",
		basepath: "/timecard",
		path: "/crewtime",
		icon: "fas fa-hard-hat",
		// page: <TimecardRoutes pageName="Crew Time" tabVal={1} />,
	},
	{
		group: "Employee Portal",
		name: "Approve Time",
		basepath: "/timecard",
		path: "/approvetime",
		icon: "fas fa-tasks",
		// page: <TimecardRoutes pageName="Approve Time" tabVal={2} />,
	},
	{
		group: "Employee Portal",
		name: "Resource Groups",
		basepath: "/timecard",
		path: "/groups",
		icon: "fas fa-sitemap",
		// page: <TimecardRoutes pageName="Resource Groups" />,
	},
	{
		group: "Employee Portal",
		name: "Past Paystubs",
		basepath: "/timecard",
		path: "/paystubs",
		icon: "fas fa-money-check-alt",
		// page: <TimecardRoutes pageName="Past Paystubs" />,
	},

	{
		group: "Payroll",
		name: "Payroll",
		basepath: "/payroll",
		path: "/",
		icon: "fas fa-money-check-alt",
		// access: ["isPayrollUser"],
	},
	{
		group: "Payroll",
		name: "Weekly Payroll",
		basepath: "/payroll",
		path: "/upload",
		icon: "fas fa-cloud-upload-alt",
		// page: <PayrollRoutes pageName="Payroll Upload" tabVal={0} />,
		// access: ["isPayrollUser"],
	},
	{
		group: "Payroll",
		name: "PR Employees",
		basepath: "/payroll",
		path: "/employees",
		icon: "fas fa-users",
		// page: <PayrollRoutes pageName="Payroll Employees" />,
		// access: ["isPayrollUser"],
	},
	{
		group: "Payroll",
		name: "Proposed Increases",
		basepath: "/payroll",
		icon: "fas fa-hand-holding-usd",
		// access: ["showEmployeeHistory"],
		// page: <PayrollRoutes pageName="Merit Increases" />,
	},
	{
		group: "Employee Portal",
		name: "Projects",
		basepath: "/projects",
		path: "/",
		icon: "fas fa-hard-hat",
		// page: <Projects pageName="Active Projects"  />,
	},
	{
		group: "Employee Portal",
		name: "Directory",
		basepath: "/directory",
		path: "/",
		icon: "fas fa-address-book",
		// page: <Directory pageName="Employee Directory" />,
	},
	{
		group: "Employee Portal",
		name: "Newsletter",
		basepath: "/newsletter",
		path: "/",
		icon: "fas fa-bullhorn",
		// page: <Newsletter />,
	},
	{
		group: "Schedules",
		name: "Schedules",
		basepath: "/schedules",
		path: "/",
		icon: "fas fa-clipboard-list",
		// access: ["isDevOpsUserYN"],
		// page: <DFMain />,
	},

	{
		group: "Schedules",
		name: "Detailing (D&F)",
		basepath: "/schedules",
		path: "/d&f",
		icon: "fas fa-clipboard-list",
		// access: ["isDevOpsUserYN"],
		// page: <DFMain />,
	},
	{
		group: "Schedules",
		name: "Start Up (SUP)",
		basepath: "/schedules",
		path: "/sup",
		icon: "fas fa-tools",
		// access: ["isStartUpUserYN"],
		// page: <DFMain scopeID={"13"} />,
	},
	{
		group: "Purchasing",
		name: "Purchasing",
		basepath: "/purchasing",
		path: "/",
		icon: "fas fa-dollar-sign",
		// access: ["isPurchasingUserYN"],
	},
	{
		group: "Purchasing",
		name: "Diversity Vendors",
		basepath: "/purchasing",
		path: "/vendors",
		icon: "fas fa-award",
		// access: ["isPurchasingUserYN"],
	},

	{
		group: "Operations",
		name: "Operations",
		basepath: "/operations",
		path: "/",
		icon: "fas fa-building",
		// access: ["isOperationsUserYN"],
	},
	{
		group: "Operations",
		name: "Monthly Forecast",
		basepath: "/operations",
		path: "/mfr",
		icon: "fas fa-calendar-alt",
		access: ["isOperationsUserYN"],
		// page: <MFR pageName="Monthly Forecast Report" tabVal={0} template="MonthlyForecast" />,
	},
	{
		group: "Operations",
		name: "PE Review Form",
		basepath: "/operations",
		path: "/pe-review",
		icon: "fas fa-file-alt",
		// access: ["showPEReviewYN"],
		// page: (
		//     <EmbedPowerApp
		//         title="PE Review Form"
		//         baseURL="https://web.powerapps.com/webplayer/iframeapp?source=iframe&appId=/providers/Microsoft.PowerApps/apps/"
		//         template="MonthlyForecast"
		//         appID="f464da40-32c3-4d29-9d63-45369d49612a"
		//     />
		// ),
	},

	{
		group: "Human Resources",
		name: "Human Resources",
		basepath: "/hr",
		path: "/",
		icon: "fas fa-universal-access",
		// access: ["showVacationCalendarYN"],
	},

	{
		group: "Human Resources",
		name: "HR Employees",
		basepath: "/hr",
		path: "/hr/employees",
		icon: "fas fa-users",
		// access: ["isPortalAdminYN"],
		// page: <HREmployees pageName="HR Employee Management" />,
	},
	{
		group: "Human Resources",
		name: "HR Reviews",
		basepath: "/hr",
		path: "/reviews",
		icon: "fas fa-clipboard-check",
		// access: ["isPortalAdminYN"],
		// page: <HRReviews pageName="HR Performance Reviews" />,
	},
	{
		group: "Human Resources",
		name: "HR Templates",
		basepath: "/hr",
		path: "/templates",
		icon: "fas fa-file-alt",
		// access: ["isPortalAdminYN"],
		// page: <HRTemplates pageName="HR Form Templates" />,
	},
	{
		group: "Human Resources",
		name: "HR Calendar",
		basepath: "/hr",
		path: "/calendar",
		icon: "fas fa-plane",
		// access: ["showVacationCalendarYN"],
		// page: <HRCalendar pageName="HR Calendar" />,
	},
	{
		group: "Human Resources",
		name: "Longevity Awards",
		basepath: "/hr",
		path: "/hr/longevity",
		icon: "fas fa-trophy",
		// access: ["showLongevityAwardsYN"],
		// page: <LongevityAwards pageName="Longevity Awards" />,
	},

	{
		group: "Dev Ops",
		name: "Dev Ops",
		basepath: "/devops",
		path: "/",
		icon: "fas fa-code",
		// access: ["isDevOpsUserYN"],
	},
	{
		group: "Dev Ops",
		name: "The Dev Team",
		basepath: "/devops",
		path: "/team",
		icon: "fas fa-laptop-code",
		// access: ["isDevOpsUserYN"],
		// page: <DevOps pageName="Dev Ops" />,
	},

	{
		group: "Dev Ops",
		name: "Power BI",
		basepath: "/powerbi",
		path: "/",
		icon: "fas fa-chart-bar",
		access: ["isDevOpsUserYN"],
	},
	{
		group: "Dev Ops",
		name: "Embed Reports",
		basepath: "/powerbi",
		path: "/reports",
		icon: "fas fa-chart-bar",
		// access: ["isDevOpsUserYN"],
		// page: <PowerBIDashboard pageName="Power BI Reports" />,
	},
	{
		group: "Dev Ops",
		name: "Executive",
		basepath: "/powerbi",
		path: "/executive",
		icon: "fas fa-user-tie",
		// access: ["isDevOpsUserYN"],
		// page: <PowerBIReport pageName="Executive Reports" />,
	},
	{
		group: "Dev Ops",
		name: "Operations",
		basepath: "/powerbi",
		path: "/operations",
		icon: "fas fa-hard-hat",
		// access: ["isDevOpsUserYN"],
		// page: <PowerBIReport pageName="Productivity" />,
	},
	{
		group: "Dev Ops",
		name: "AP Invoices",
		basepath: "/powerbi",
		path: "/invoices",
		icon: "fas fa-file-invoice-dollar",
		// access: ["isDevOpsUserYN"],
		// page: <PowerBIReport pageName="PDF Attachment" />,
	},
	{
		group: "Dev Ops",
		name: "Examples",
		basepath: "/examples",
		path: "/",
		icon: "fab fa-react",
		// access: ["isDevOpsUserYN"],
	},
	{
		group: "Dev Ops",
		name: "UI Components",
		basepath: "/examples",
		path: "/ui",
		icon: "fab fa-react",
		// access: ["isDevOpsUserYN"],
		// page: <Examples pageName="UI Components" type="ui" />,
	},
	{
		group: "Dev Ops",
		name: "Framer Motion",
		basepath: "/examples",
		path: "/motion",
		icon: "fas fa-wind",
		// access: ["isDevOpsUserYN"],
		// page: <Examples pageName="Framer Motion Components" type="motion" />,
	},
	{
		group: "Legacy",
		name: "Legacy",
		basepath: "/legacy",
		path: "/",
		icon: "fas fa-history",
		// access: ["isDevOpsUserYN"],
		// subMenu: [
	},
	{
		group: "Legacy",
		name: "D&F Schedule",
		basepath: "/legacy",
		path: "/detailing",
		icon: "fas fa-pencil-ruler",
		// access: ["isDevOpsUserYN"],
		// page: <DFRoutes pageName="D&F Schedule" tabVal={0} />,
	},
	{
		group: "Legacy",
		name: "Report Viewer",
		basepath: "/legacy",
		path: "/reports",
		icon: "fas fa-file-invoice",
		// access: ["isDevOpsUserYN"],
		// page: <ReportViewer pageName="Report Dashboard" tabVal={0} />,
	},
	{
		group: "Legacy",
		name: "Purchase Order",
		basepath: "/legacy",
		path: "/purchasing/po",
		icon: "fas fa-file-alt",
		// access: ["isDevOpsUserYN"],
		// page: <PurchaseOrder pageName="Material Request" />,
	},
];

export const treeRoutes = [
	{
		path: "/employee",
		name: "Employee Portal",
		icon: "fas fa-home",

		routes: [
			{
				path: "/home",
				name: "Home",
				icon: "fas fa-home",
				component: <HomePage />,
			},
			{
				path: "/profile",
				name: "Profile",
				icon: "fas fa-user",
				component: <GlassTheme />,
			},
			{
				path: "/team",
				name: "Team",
				icon: "fas fa-user-friends",
				component: <FontAwesomeIcons />,
			},
			{
				path: "/projects",
				name: "Projects",
				icon: "fas fa-hard-hat",
			},
			{
				path: "/directory",
				name: "Directory",
				icon: "fas fa-address-book",
			},
			{
				path: "/newsletter",
				icon: "fas fa-bullhorn",
				name: "Newsletter",
				component: <Newsletter />,
			},
		],
	},
	{
		path: "/timecard",
		name: "Timecard",
		icon: "fas fa-clock",

		routes: [
			{
				path: "/mytime",
				name: "My Time",
				icon: "fas fa-clock",
			},
			{
				path: "/crewtime",
				name: "Crew Time",
				icon: "fas fa-hard-hat",
			},
			{
				path: "/approval",
				name: "Approve Time",
				icon: "fas fa-check-circle",
			},
			{
				path: "/manage",
				name: "Manage Crews",
				icon: "fas fa-sitemap",
			},
			{
				path: "/paystubs",
				icon: "fas fa-download",
				name: "Past Paystubs",
			},
			{
				path: "/reports",
				name: "Weekly Reports",
				icon: "fas fa-chart-bar",
			},
		],
	},
	{
		path: "/payroll",
		name: "Payroll",
		icon: "fas fa-dollar-sign",

		routes: [
			{
				path: "/upload",
				name: "Upload Time",
				icon: "fas fa-cloud-upload-alt",
				// component: <HomePage pageName="Employee Portal" />,
			},
			{
				path: "/employees",
				name: "PR Employees",
				icon: "fas fa-users",
			},

			{
				path: "/increases",
				icon: "fas fa-hand-holding-usd",
				name: "Proposed Increases",
			},
		],
	},
	{
		path: "/devops",
		name: "Dev Ops",
		icon: "fas fa-code",

		routes: [
			{
				path: "/themes",
				name: "Upload Time",
				icon: "fas fa-cloud-upload-alt",
				// component: <HomePage pageName="Employee Portal" />,
			},
			{
				path: "/employees",
				name: "PR Employees",
				icon: "fas fa-users",
			},

			{
				path: "/increases",
				icon: "fas fa-hand-holding-usd",
				name: "Proposed Increases",
			},
		],
	},
	{
		path: "/newsletter",
		name: "Newsletter",
		icon: "fas fa-bullhorn",
		// component: <Newsletter pageName="Newsletter" />,
	},

	// {
	// 	path: "/routes",
	// 	name: "Routes",
	// 	icon: "fas fa-sitemap",
	// 	component: <Newsletter pageName="Newsletter" />,
	// },
];

// Some folks find value in a centralized route config.
// A route config is just data. React is great at mapping
// data into components, and <Route> is a component.

// Our route config is just an array of logical "routes"
// with `path` and `component` props, ordered the same
// way you'd do inside a `<Switch>`.
const testRoutes = [
	{
		path: "/aunt",
		component: Aunt,
	},
	{
		path: "/parent",
		component: Parent,
		routes: [
			{
				path: "/parent/child1",
				component: Child1,
			},
			{
				path: "/parent/child2",
				component: Child2,
				routes: [
					{
						path: "/parent/child2/grandchild1",
						component: GrandChild1,
					},
				],
			},
		],
	},
];

export default function TestRoutes() {
	return (
		<Router>
			<div>
				<div>
					<ul>
						<li>
							<Link to="/aunt">Aunt</Link>
						</li>

						<li>
							<Link to="/parent">Parent</Link>
						</li>

						<li>
							<Link to="/parent/child1">Child 1</Link>
						</li>
						<li>
							<Link to="/parent/child2">Child 2</Link>
						</li>
						<li>
							<Link to="/parent/child2/grandchild1">Grand Child 1</Link>
						</li>
					</ul>
				</div>

				<Switch>
					{testRoutes?.map((route, i) => (
						<RouteWithSubRoutes key={i} {...route} />
					))}
				</Switch>
			</div>
		</Router>
	);
}

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
function RouteWithSubRoutes(route) {
	const location = useLocation();
	let { path, url } = useRouteMatch();
	log("location", location);
	log("path", path);
	log("url", url);

	return (
		<Route
			path={route.path}
			render={(props) => (
				// pass the sub-routes down to keep nesting
				<route.component {...props} routes={route.routes} />
			)}
		/>
	);
}

function Aunt() {
	return <h3>Aunt</h3>;
}

function Parent({ routes }) {
	const location = useLocation();
	let { path, url } = useRouteMatch();
	log("Parent location", location);
	log("Parent path", path);
	log("Parent url", url);

	return (
		<Switch location={location}>
			{routes.map((route, i) => (
				<RouteWithSubRoutes key={i} {...route} />
			))}
			<Route exact path={path}>
				<Redirect to={`${routes[0].path}`} state={{}} />
			</Route>
		</Switch>
	);
}

function Child1() {
	return <h2>Child 1</h2>;
}

function Child2({ routes }) {
	return (
		<Switch>
			{routes.map((route, i) => (
				<RouteWithSubRoutes key={i} {...route} />
			))}
		</Switch>
	);
}

function GrandChild1() {
	return <h3>GrandChild 1</h3>;
}
