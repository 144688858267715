import SchedulesAPI from "../../../APIs/SchedulesAPI";
import DFJob from "./DFJob";
import DF_CLASSES from "../constants/DFClasses";
import DF_TYPES from "../constants/DFTypes";
import DF_ROLES from "../constants/DFRoles";

import { portalUser } from "../../../App";
import { log, convertArrayToMap } from "../../../helperFunctions";

export default class DFData {
	constructor(data = []) {
		if (data instanceof DFData) {
			// If data is already part of the DFData class, assign its properties and there's no need to run further functions
			Object.assign(this, data);
		} else {
			// Otherwise assign the classes array and place the data into its respective class
			this.schedulesAPI = new SchedulesAPI();
			this.classes = DF_CLASSES;
			this.types = DF_TYPES;
			this.roles = DF_ROLES;
			this.templates = [];
			this.jobs = [];
			this.workflows = [];
			this.team = [];
			this.sequences = [];
			this.deliverables = [];
			this.classifyData(data);
		}
	}

	// Place data into their respective class
	classifyData(data = []) {
		let classMap = convertArrayToMap(this.classes, "class");
		let dataMap = convertArrayToMap(data, "queryData", true);
		// Loop over the classMap and push each class into the newData object
		Object.keys(classMap).forEach((key) => {
			let newData = [];
			let newProp = classMap[key]?.prop;
			let newClass = classMap[key]?.obj?.constructor;
			for (let i = 0; i < dataMap[key]?.length; i++) {
				newData.push(new newClass(dataMap[key][i]));
			}
			this[newProp] = newData;
		});
		return this;
	}

	// Fetch all D&F Data
	async getDFData(params = {}, prop) {
		let DFData = this;

		// If no jobs are loaded, call initDFData without any params to load all jobs
		if (DFData?.templates?.length === 0) {
			DFData = await this.initDFData({});
			Object.assign(this, DFData);
			if (Object.keys(DFData).length === 0) {
				return DFData[prop] ?? [];
			}
		}

		let newDFData = await this.initDFData(params);
		log("New DFData", newDFData);

		// for each  object in array that is complete load it

		// if Append, remove duplicate keys,

		// if Clear, override it

		// Assign new properties to DFData

		// return Jobs
		if (Boolean(prop)) {
			return newDFData[prop] ?? [];
		} else {
			return newDFData;
		}
	}

	// When Calling Data from API We Start Here
	async initDFData(params = {}) {
		let data = await this.schedulesAPI.PortalUser_DFData_GetData(params);

		// Success
		if (data?.status === 200) {
			let newDFData = new DFData(data.value);
			newDFData.initDFJobs(params);

			return newDFData;
		} else {
			let errorMsg = "initDFData: ";
			errorMsg += "initDFData was not successful";
			this.error = true;
			log(errorMsg);
		}
	}

	// Insert workflows, teams, sequences, and deliverables into the job objects
	initDFJobs(params) {
		let templateMap = convertArrayToMap(this.templates, "templateID", true);
		let rolesMap = convertArrayToMap(this.roles, "templateType", true);
		let teamsMap = convertArrayToMap(this.team, "jobID", true);
		let contractMap = convertArrayToMap(portalUser?.getContracts(), "jccmKeyID");
		let workflowsMap = convertArrayToMap(this.workflows, "workflowID", true);
		let sequencesMap = convertArrayToMap(this.sequences, "jobID", true);
		let deliverablesMap = convertArrayToMap(this.deliverables, "jobID", true);

		// initDFJob: Add template, contract, respRoles, and team to DFJob
		for (let i = 0; i < this.jobs?.length; i++) {
			let scopeMap = convertArrayToMap(templateMap[this.jobs[i]?.templateID], "scopeID");
			let template = scopeMap[this.jobs[i]?.scopeID] ?? null;
			let contract = contractMap[this.jobs[i]?.jccmKeyID] ?? null;
			let respRoles = rolesMap[this.jobs[i]?.templateType] ?? [];
			let team = teamsMap[this.jobs[i]?.jobID] ?? [];

			this.jobs[i].initDFJob(template, contract, team, respRoles);

			// initDFSequences: Add sequences, workflows and deliverables
			if (Object.values(params)?.length > 0) {
				let workflows = workflowsMap[this.jobs[i].workflowID] ?? [];
				let sequences = sequencesMap[this.jobs[i].jobID] ?? [];
				let deliverables = deliverablesMap[this.jobs[i].jobID] ?? [];
				this.jobs[i].initDFSequences(workflows, sequences, deliverables);
			}
		}
	}

	getTemplateType(type = "D&F") {
		let typeMap = convertArrayToMap(this.types, "templateType");
		let templateType = typeMap[type];
		return templateType;
	}

	getTemplates(templateType = "D&F", showArchived = false) {
		let templates = this.templates;

		if (!showArchived) {
			templates = templates?.filter((d) => d.isArchived === "0") ?? [];
		}
		if (templateType) {
			templates = convertArrayToMap(templates, "templateType", true)[templateType] ?? [];
		}

		return templates;
	}

	getJob(job = new DFJob()) {
		let jobs = this.jobs;

		if (job?.jobID) {
			job = convertArrayToMap(jobs, "jobID")[job.jobID] ?? job;
		} else {
			if (job?.jccmKeyID) {
				jobs = convertArrayToMap(jobs, "jccmKeyID", true)[job.jccmKeyID] ?? [];
			}
			if (job?.scopeID) {
				jobs = convertArrayToMap(jobs, "scopeID", true)[job.scopeID] ?? [];
			}
			if (job?.templateID) {
				jobs = convertArrayToMap(jobs, "templateID", true)[job.templateID] ?? [];
			}

			return job;
		}
	}

	async initJobCustomWorkflows(jobID = null) {
		let job = this.jobs.find((d) => d.jobID === jobID) ?? null;
		// log("custom job", job);
		if (Boolean(job?.id)) {
			if (job?.workflowID === job?.templateID) {
				// log("JOB ID: Step 2");
				let result = await this.schedulesAPI.PortalUser_DFData_InsertJob_Workflows(job?.id);
				// log("result", result);
				if (result?.status === 200 && result?.value?.length > 0) {
					// log("JOB ID: Step 3");
					// log("Database Result (initialized custom workflows)", result);
					return true;
				}
			} else {
				alert("Error: This job already has custom workflows");
				return false;
			}
			// log("JOB ID: Step 4");
		} else {
			// log("JOB ID: Step 5");
			// alert("Error: Job not found");
			return null;
		}
		// log("JOB ID: Step 6");
	}
}
