import { chakraThemeColorsMap, formatValue } from "../../../helperFunctions";

export default class SectionTemplateMFRCRSProjectAnalysisSummary {
	constructor(contractTotals = null, contractStatus = null) {
		this.template = "MFRProjectAnalysisSummary";
		this.name = "Project Analysis Summary";
		this.divider = false;
		this.pagebreak = 1;
		this.type = "labelValuePairs";
		this.minRows = 6;
		this.py = 0;
		this.subSections = [
			new SubSectionTemplateMFRCRSProjectAnalysisSummary1(contractTotals),
			new SubSectionTemplateMFRCRSProjectAnalysisSummary2(),
			new SubSectionTemplateMFRCRSProjectAnalysisSummary3(contractTotals, contractStatus),
		];
	}
}

//OLD BELOW
class SubSectionTemplateMFRCRSProjectAnalysisSummary1 {
	constructor(contractTotals = null) {
		// let bidProfitAmt = 0;
		// let bidMarginPct = 0;
		// if (Boolean(contractTotals?.queryData)) {
		// 	bidProfitAmt = contractTotals?.getBidProfitAmt();
		// 	bidMarginPct = contractTotals?.getBidMarginPct();
		// }

		this.template = "ProjectAnalysisSummary1";
		this.type = "labelValuePairs";
		this.width = "30%";
		this.columns = [
			{
				accessor: "label",
				width: "53.33%",
				borderBottomColor: chakraThemeColorsMap.silver,
				borderBottomWidth: 1,
				borderLeftWidth: 1,
				borderRightWidth: 0,
				fontSize: 9,
			},
			{
				accessor: "value",
				width: "46.67%",
				valueType: "currency",
				textAlign: "right",
				borderBottomColor: chakraThemeColorsMap.silver,
				borderBottomWidth: 1,
				borderLeftWidth: 0,
				borderRightWidth: 1,
				fontSize: 9,
			},
		];
		this.data = [
			{
				label: "Committed Cost",
				// labelBorderTopColor: chakraThemeColorsMap.gray700,
				// labelBorderTopWidth: 1,
				value: contractTotals?.remainCmtdCost ?? 0,
				// valueBorderTopColor: chakraThemeColorsMap.gray700,
				// valueBorderTopWidth: 1,
			},
			{
				label: "Unapproved AP",
				labelBorderBottomColor: chakraThemeColorsMap.gray700,
				value: contractTotals?.unapprovedAPAmt ?? 0,
				valueBorderBottomColor: chakraThemeColorsMap.gray700,
			},
			{
				label: null,
				labelBorderBottomWidth: 0,
				labelBorderLeftWidth: 0,
				labelBorderRightWidth: 0,
				valueBorderBottomWidth: 0,
				valueBorderLeftWidth: 0,
				valueBorderRightWidth: 0,
				value: "",
			},
			{
				label: null,
				labelBorderBottomWidth: 0,
				labelBorderLeftWidth: 0,
				labelBorderRightWidth: 0,
				valueBorderBottomWidth: 0,
				valueBorderLeftWidth: 0,
				valueBorderRightWidth: 0,
				value: "",
			},
			{
				label: null,
				labelBorderBottomWidth: 0,
				labelBorderLeftWidth: 0,
				labelBorderRightWidth: 0,
				valueBorderBottomWidth: 0,
				valueBorderLeftWidth: 0,
				valueBorderRightWidth: 0,
				value: "",
			},
			{
				label: null,
				labelBorderBottomWidth: 0,
				labelBorderLeftWidth: 0,
				labelBorderRightWidth: 0,
				valueBorderBottomWidth: 0,
				valueBorderLeftWidth: 0,
				valueBorderRightWidth: 0,
				value: "",
			},
			{
				label: null,
				labelBorderBottomWidth: 0,
				labelBorderLeftWidth: 0,
				labelBorderRightWidth: 0,
				valueBorderBottomWidth: 0,
				valueBorderLeftWidth: 0,
				valueBorderRightWidth: 0,
				value: "",
			},
		];
	}
}

class SubSectionTemplateMFRCRSProjectAnalysisSummary2 {
	constructor() {
		this.template = "ProjectAnalysisSummary2";
		this.type = "labelValuePairs";
		this.width = "40%";
		this.columns = [
			{
				accessor: "label",
			},
		];
		this.data = [
			{
				label: null,
			},
			{
				label: null,
			},
			{
				label: null,
			},
			// {
			// 	label: null,
			// },
			// {
			// 	label: null,
			// },
			// {
			// 	label: null,
			// },
		];
	}
}

class SubSectionTemplateMFRCRSProjectAnalysisSummary3 {
	constructor(contractTotals = null, contractStatus = null) {
		this.template = "ProjectAnalysisSummary3";
		this.type = "labelValuePairs";
		this.width = "30%";
		this.columns = [
			{
				accessor: "label",
				width: "50%",
				borderTopColor: chakraThemeColorsMap.gray700,
				borderLeftColor: chakraThemeColorsMap.gray700,
				borderRightColor: chakraThemeColorsMap.gray700,
				borderBottomColor: chakraThemeColorsMap.silver,
				borderBottomWidth: 1,
				borderLeftWidth: 1,
				borderRightWidth: 0,
				fontSize: 9,
			},
			{
				accessor: "value",
				width: "50%",
				valueType: "currency",
				textAlign: "right",
				borderTopColor: chakraThemeColorsMap.gray700,
				borderLeftColor: chakraThemeColorsMap.gray700,
				borderRightColor: chakraThemeColorsMap.gray700,
				borderBottomColor: chakraThemeColorsMap.silver,
				borderBottomWidth: 1,
				borderLeftWidth: 0,
				borderRightWidth: 1,
				fontSize: 9,
			},
		];
		this.data = [
			{
				label: "Wage Type",
				labelTextTransform: "uppercase",
				labelBg: chakraThemeColorsMap.teal700,
				labelTextColor: chakraThemeColorsMap.white,
				labelBorderRightColor: chakraThemeColorsMap.teal700,
				labelBorderRightWidth: 1,
				labelBorderBottomColor: chakraThemeColorsMap.gray700,
				labelFontSize: 8,
				value: "Rate",
				valueTextTransform: "uppercase",
				valueType: "string",
				valueBg: chakraThemeColorsMap.teal700,
				valueTextColor: chakraThemeColorsMap.white,
				valueBorderLeftColor: chakraThemeColorsMap.teal700,
				valueBorderLeftWidth: 1,
				valueBorderBottomColor: chakraThemeColorsMap.gray700,
				valueFontSize: 8,
				valueDecimals: 2,
			},

			{
				label: "Original Budgeted Rate",
				// value: contractTotals?.getProjContractAmt() ?? 0,
				value: formatValue(contractStatus?.getOrigEstRate() ?? 0, 2, "currency") ?? 0,
				valueDecimals: 2,
				valueRightAddon: " / hr",
			},
			{
				label: "Current Budgeted Rate",
				// value: contractTotals?.getProjCostWithPCOs() ?? 0,
				value: formatValue(contractStatus?.getCurrEstRate() ?? 0, 2, "currency") ?? 0,
				valueDecimals: 2,
				valueRightAddon: " / hr",
			},
			{
				label: "Current Rate",
				// value: contractTotals?.getProjProfitAmt() ?? 0,
				value: formatValue(contractStatus?.getActualRate() ?? 0, 2, "currency") ?? 0,
				valueDecimals: 2,
				valueRightAddon: " / hr",
			},
			{
				label: "Rate to Complete",
				// value: contractTotals?.getProjProfitAmt() ?? 0,
				value: formatValue(contractStatus?.getRateToComplete() ?? 0, 2, "currency") ?? 0,
				valueDecimals: 2,
				valueRightAddon: " / hr",
			},
			{
				label: "Projected Rate",
				// value: contractTotals?.getProjProfitAmt() ?? 0,
				value: formatValue(contractStatus?.getProjRate() ?? 0, 2, "currency") ?? 0,
				valueDecimals: 2,
				valueRightAddon: " / hr",
			},
			{
				label: "Variance on Rate",
				labelBorderBottomColor: chakraThemeColorsMap.gray700,
				// value: contractTotals?.getProjMarginPct() ?? 0,
				value: formatValue(contractStatus?.getVarOnRateAmt(), 0, "currency") ?? 0,
				valueBorderBottomColor: chakraThemeColorsMap.gray700,
			},
		];
	}
}

// class SubSectionTemplateMFRCRSProjectAnalysisSummary3 {
// 	constructor(contractTotals = null) {
// 		this.template = "ProjectAnalysisSummary3";
// 		this.type = "labelValuePairs";
// 		this.width = "42%";
// 		this.columns = [
// 			{
// 				accessor: "label",
// 				width: "33.4%",
// 				borderTopColor: chakraThemeColorsMap.gray700,
// 				borderLeftColor: chakraThemeColorsMap.gray700,
// 				borderRightColor: chakraThemeColorsMap.gray700,
// 				borderBottomColor: chakraThemeColorsMap.silver,
// 				borderBottomWidth: 1,
// 				borderLeftWidth: 1,
// 				borderRightWidth: 0,
// 				fontSize: 9,
// 			},
// 			{
// 				accessor: "cowValue",
// 				width: "33.3%",
// 				valueType: "currency",
// 				textAlign: "right",
// 				borderTopColor: chakraThemeColorsMap.gray700,
// 				borderLeftColor: chakraThemeColorsMap.gray700,
// 				borderBottomColor: chakraThemeColorsMap.silver,
// 				borderBottomWidth: 1,
// 				borderLeftWidth: 0,
// 				fontSize: 9,
// 			},
// 			{
// 				accessor: "gcValue",
// 				width: "33.3%",
// 				valueType: "currency",
// 				textAlign: "right",
// 				borderTopColor: chakraThemeColorsMap.gray700,
// 				borderLeftColor: chakraThemeColorsMap.gray700,
// 				borderRightColor: chakraThemeColorsMap.gray700,
// 				borderBottomColor: chakraThemeColorsMap.silver,
// 				borderBottomWidth: 1,
// 				borderLeftWidth: 0,
// 				borderRightWidth: 1,
// 				fontSize: 9,
// 			},
// 		];
// 		this.data = [
// 			{
// 				label: "Wage Rate",
// 				labelTextTransform: "uppercase",
// 				labelBg: chakraThemeColorsMap.teal700,
// 				labelTextColor: chakraThemeColorsMap.white,
// 				labelBorderRightColor: chakraThemeColorsMap.teal700,
// 				labelBorderRightWidth: 1,
// 				labelBorderBottomColor: chakraThemeColorsMap.gray700,
// 				labelFontSize: 8,
// 				cowValue: "Cost of Work",
// 				cowValueTextTransform: "uppercase",
// 				cowValueType: "string",
// 				cowValueBg: chakraThemeColorsMap.teal700,
// 				cowValueTextColor: chakraThemeColorsMap.white,
// 				cowValueBorderLeftColor: chakraThemeColorsMap.teal700,
// 				cowValueBorderLeftWidth: 1,
// 				cowValueBorderBottomColor: chakraThemeColorsMap.gray700,
// 				cowValueFontSize: 8,
// 				cowValueDecimals: 2,
// 				gcValue: "General Conditions",
// 				gcValueTextTransform: "uppercase",
// 				gcValueType: "string",
// 				gcValueBg: chakraThemeColorsMap.teal700,
// 				gcValueTextColor: chakraThemeColorsMap.white,
// 				gcValueBorderLeftColor: chakraThemeColorsMap.teal700,
// 				gcValueBorderLeftWidth: 1,
// 				gcValueBorderBottomColor: chakraThemeColorsMap.gray700,
// 				gcValueFontSize: 8,
// 				gcValueDecimals: 2,
// 			},

// 			{
// 				label: "Original Budgeted Rate",
// 				cowValue: contractTotals?.getProjContractAmt() ?? 0,
// 				gcValue: contractTotals?.getProjContractAmt() ?? 0,
// 			},
// 			{
// 				label: "Current Budgeted Rate",
// 				cowValue: contractTotals?.getProjCostWithPCOs() ?? 0,
// 				gcValue: contractTotals?.getProjCostWithPCOs() ?? 0,
// 			},
// 			{
// 				label: "Current Rate",
// 				cowValue: contractTotals?.getProjProfitAmt() ?? 0,
// 				gcValue: contractTotals?.getProjProfitAmt() ?? 0,
// 			},
// 			{
// 				label: "Rate to Complete",
// 				cowValue: contractTotals?.getProjProfitAmt() ?? 0,
// 				gcValue: contractTotals?.getProjProfitAmt() ?? 0,
// 			},
// 			{
// 				label: "Variance on Rate",
// 				labelBorderBottomColor: chakraThemeColorsMap.gray700,
// 				cowValue: contractTotals?.getProjMarginPct() ?? 0,
// 				cowValueDecimals: 1,
// 				cowValueBorderBottomColor: chakraThemeColorsMap.gray700,
// 				gcValue: contractTotals?.getProjMarginPct() ?? 0,
// 				gcValueDecimals: 1,
// 				gcValueBorderBottomColor: chakraThemeColorsMap.gray700,
// 			},
// 		];
// 	}
// }
