import React from "react";
import { Box, Center, Heading, Text, Tooltip } from "@chakra-ui/react";

export default function VerticalLabelValue(props) {
	return (
		<Box px="2">
			<Center>
				<Tooltip placement="top" hasArrow={true} label={props.labelTooltip ?? props.label}>
					<Heading
						as="h4"
						size="xs"
						color="gray.500"
						className={props.className}
						fontWeight={props.fontWeight}
					>
						{props.label}
					</Heading>
				</Tooltip>
			</Center>
			<Center>
				<Text color="gray.600" fontWeight={props.fontWeight} className={props.className}>
					{props.value}
				</Text>
			</Center>
		</Box>
	);
}
