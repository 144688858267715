import React, { useRef } from "react";
import { Input, Button } from "@chakra-ui/react";

export default function FileInput(props) {
	const fileRef = useRef(null);

	const handleClick = (ev) => {
		ev.stopPropagation();
		fileRef.current.click();
	};

	const handleChange = (ev) => {
		ev.stopPropagation();

		if (props.onChange) {
			props.onChange(ev);
		}
	};

	return (
		<>
			<Button size="sm" variant="outline" colorScheme={"blue"} onClick={handleClick}>
				{props.placeholder ?? "Choose File"}
			</Button>
			<Input type="file" name={props.name} ref={fileRef} onChange={handleChange} hidden accept={props.accept} />
		</>
	);
}
