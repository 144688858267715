import React from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { routes } from "../../../modules/App/routes/routes";
import { isAuthorized } from "../../../helperFunctions";
import { portalUser } from "../../../App";

export default function AppRoutes() {
	let appRoutes = getAppRoutes(routes);
	const location = useLocation();

	return (
		<Switch location={location}>
			{appRoutes?.map((route, i) => (
				<Route key={i} path={route.url} exact={Boolean(route?.exact)}>
					{route.page}
				</Route>
			))}
			<Route path="*">
				<Redirect to="/home" />
			</Route>
		</Switch>
	);
}

export function getAppRoutes(appRoutes = routes) {
	let newRoutes = [];

	appRoutes?.forEach((section) => {
		section?.forEach((item) => {
			if (item?.subMenu?.length > 0 && isAuthorized(portalUser.user, item?.access)) {
				//if a menu item has submenu items & the user is authorized,
				//then add the authorized submenu items to routes
				item?.subMenu?.forEach((subItem) => {
					if (isAuthorized(portalUser.user, subItem?.access)) {
						newRoutes.push({
							url: subItem?.url,
							page: subItem?.page,
						});
					}
				});

				//if the parent menu item has a valid path and page component,
				//then add an exact path for the parent menu item to routes
				if (item?.url && item?.page) {
					newRoutes.push({
						url: item?.url,
						page: item?.page,
						exact: true,
					});
				} else {
					//else if the parent menu item has submenu items but not a valid path and page component,
					//then loop thru the submenu items until the first authorized submenu item,
					//then add an exact redirect path for the parent menu item to routes and break the loop
					for (let i = 0; i < item?.subMenu?.length; i++) {
						if (isAuthorized(item?.subMenu[i]?.access)) {
							newRoutes.push({
								url: item?.url,
								page: <Redirect to={`${item?.subMenu[i]?.url}`} />,
								exact: true,
							});
							break;
						}
					}
				}

				//if a menu item does not have any submenu items and the user is authorized
				//then add the authorized submenu items to routes
			} else if (isAuthorized(portalUser.user, item?.access)) {
				newRoutes.push({
					url: item?.url,
					page: item?.page,
				});
			}
		});
	});

	// **IMPORT NOTE**: Do not sort the array of routes!
	// The routes will by default be pushed into the new routes array in the correct order
	// newRoutes?.sort((a, b) => (a.url?.match(/\/\w*/g)?.length || 0) - (b.url?.match(/\/\w*/g)?.length || 0));

	return newRoutes;
}
