import React, { useState, useCallback, useEffect, useMemo } from "react";
import {
	Flex,
	Stack,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	useBoolean,
	Box,
	VStack,
	Text,
	Circle,
	Container,
	Heading,
	useColorModeValue,
	keyframes,
	Icon,
} from "@chakra-ui/react";
import { ArrowUpIcon, CopyIcon } from "@chakra-ui/icons";

import VendorList from "./AvailableVendors/VendorList";
import RequirementsPage from "./Requirements/RequirementsPage";
import SpentToDatePage from "./SpentToDate/SpentToDatePage";
import MainContent from "../../../../legacy/App/components/MainContent";
import { portalUser } from "../../../../App";
import DataInput from "../../../../core/Inputs/data/DataInput";
import PPMLogo from "../../../../core/Logos/components/PPMLogo";

export default function MFRDiversityPage(props) {
	const [contract, setContract] = useState({});
	const [isLoading, setIsLoading] = useBoolean(true);
	const [operations, setOperations] = useState(portalUser?.operations ?? null);
	const [diversityVendors, setDiversityVendors] = useState(portalUser?.operations?.diversityVendors ?? null);
	const [diversityReqs, setDiversityReqs] = useState([]);
	const [updateTrigger, setUpdateTrigger] = useState(false);

	const params = useMemo(() => ({ jccmKeyID: contract?.jccmKeyID } ?? { jccmKeyID: null }), [contract?.jccmKeyID]);

	const setDataLoading = useCallback(
		async function (callback) {
			if (!isLoading) {
				setIsLoading.on();
			}
			callback();
		},
		[isLoading, setIsLoading]
	);

	const getDiversityReqs = useCallback(
		async (operations = portalUser?.operations) => {
			if (operations && params.jccmKeyID) {
				operations = await operations.initDiversityReqs(params);
				setOperations(operations);
			}
			setIsLoading.off();
		},
		[params, setIsLoading]
	);

	const getDiversityVendors = useCallback(
		async (operations = portalUser?.operations) => {
			if (operations && params.jccmKeyID) {
				operations = await operations.initDiversityVendors(params);
				await getDiversityReqs(operations);
			} else {
				setOperations(operations);
				setIsLoading.off();
			}
		},
		[getDiversityReqs, params, setIsLoading]
	);

	const initOperationsData = useCallback(
		async (operations = portalUser?.operations) => {
			if (!operations) {
				await portalUser.initOperations();
				operations = portalUser?.operations ?? null;
			}
			await getDiversityVendors(operations);
		},
		[getDiversityVendors]
	);

	useMemo(() => {
		if (operations && !isLoading && params?.jccmKeyID) {
			let diversityVendors = operations.diversityVendors?.filter((d) => d.jccmKeyID === params.jccmKeyID) ?? [];
			setDiversityVendors(diversityVendors);
			let diversityReqs = operations.diversityReqs?.filter((d) => d.jccmKeyID === params.jccmKeyID) ?? [];
			setDiversityReqs(diversityReqs);
		}
	}, [isLoading, operations, params.jccmKeyID]);

	useEffect(() => {
		setDataLoading(() => initOperationsData(operations));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.jccmKeyID]);

	useEffect(() => {
		if (updateTrigger) {
			setUpdateTrigger(false);
		}
	}, [updateTrigger]);

	// Add this state at the top of your component with your other states
	const [vendorAccordionOpen, setVendorAccordionOpen] = useState(false);

	return (
		<MainContent title={props.pageName}>
			<Stack p="2">
				<Flex align="center" justify="space-between">
					<PPMLogo color={"teal.700"} h="55px" minW="225px" />
					<Flex minW="675" ml="5">
						<DataInput
							type="select"
							variant="outline"
							placeholder="Select Project"
							searchable
							value={contract.title ?? null}
							options={portalUser?.getContracts() ?? []}
							inputProps={{
								fontSize: "lg",
								bg: "transparent",
								py: "0",
								fontWeight: "semibold",
								textAlign: "left",
								color: "teal.600",
							}}
							optionText="title"
							optionSubheading="subtitle"
							onOptionSelect={(e) => setContract(e)}
						/>
					</Flex>
					<Flex></Flex>
					<Flex></Flex>
				</Flex>
				{!params?.jccmKeyID ? (
					<Flex justify={"center"}>
						<SelectProjectPrompt />
					</Flex>
				) : (
					<Stack>
						<Accordion defaultIndex={[0]} allowToggle>
							<AccordionItem>
								<AccordionButton bg="teal.300" _hover={{ bg: "teal.500" }}>
									<Flex
										fontWeight="semibold"
										flex="1"
										textAlign="left"
										textTransform="uppercase"
										paddingY={2}
									>
										Diversity Requirements
									</Flex>
									<AccordionIcon />
								</AccordionButton>
								<AccordionPanel>
									<RequirementsPage
										diversityReqs={diversityReqs}
										jccmKeyID={params.jccmKeyID}
										operations={operations}
										setOperations={setOperations}
										setUpdateTrigger={setUpdateTrigger}
									/>
								</AccordionPanel>
							</AccordionItem>
						</Accordion>
						<Accordion
							allowToggle
							onChange={(expandedIndex) => setVendorAccordionOpen(expandedIndex === 0)}
						>
							<AccordionItem>
								<AccordionButton bg="teal.300" _hover={{ bg: "teal.500" }}>
									<Flex
										fontWeight="semibold"
										flex="1"
										textAlign="left"
										textTransform="uppercase"
										paddingY={2}
									>
										Available Vendors
									</Flex>
									<AccordionIcon />
								</AccordionButton>
								<AccordionPanel>
									<Box style={{ display: "block" }}>
										<VendorList
											diversityVendors={diversityVendors}
											isLoading={isLoading}
											setIsLoading={setIsLoading}
											contract={contract}
										/>
									</Box>
								</AccordionPanel>
							</AccordionItem>
						</Accordion>
						<Accordion defaultIndex={[0]} allowToggle>
							<AccordionItem>
								<AccordionButton bg="teal.300" _hover={{ bg: "teal.500" }}>
									<Flex
										fontWeight="semibold"
										flex="1"
										textAlign="left"
										textTransform="uppercase"
										paddingY={2}
									>
										Spent to Date
									</Flex>
									<AccordionIcon />
								</AccordionButton>
								<AccordionPanel>
									<SpentToDatePage
										diversityVendors={diversityVendors}
										isLoading={isLoading}
										contract={contract}
										diversityReqs={diversityReqs}
									/>
								</AccordionPanel>
							</AccordionItem>
						</Accordion>
					</Stack>
				)}
			</Stack>
		</MainContent>
	);
}

const pulseKeyframe = keyframes`
   0% {transform: scale(1); opacity: 0.5}
   50% {transform: scale(1.1); opacity: 0.8}
   100% {transform: scale(1); opacity: 0.5}
 `;

const floatKeyframe = keyframes`
   0% {transform: translateY(0px)}
   50% {transform: translateY(-10px)}
   100% {transform: translateY(0px)}
 `;

const glowKeyframe = keyframes`
   0% {box-shadow: 0 0 0 0 rgba(49, 151, 149, 0.6)}
   70% {box-shadow: 0 0 0 10px rgba(49, 151, 149, 0)}
   100% {box-shadow: 0 0 0 0 rgba(49, 151, 149, 0)}
 `;

function SelectProjectPrompt() {
	const pulseAnimation = `${pulseKeyframe} 3s infinite ease-in-out`;
	const floatAnimation = `${floatKeyframe} 3s infinite ease-in-out`;
	const glowAnimation = `${glowKeyframe} 2s infinite`;

	const bgColor = useColorModeValue("white", "gray.800");
	const textColor = useColorModeValue("gray.600", "gray.300");
	const headingColor = useColorModeValue("teal.600", "teal.300");
	const iconBgColor = useColorModeValue("teal.50", "teal.900");

	return (
		<Container maxW="container.lg">
			<Box position="relative" height="70vh" display="flex" alignItems="center" justifyContent="center">
				{/* Background circles */}
				<Box
					position="absolute"
					top="50%"
					left="50%"
					transform="translate(-50%, -50%)"
					width="500px"
					height="500px"
					borderRadius="full"
					bg="teal.50"
					filter="blur(40px)"
					opacity="0.6"
					animation={pulseAnimation}
				/>

				{/* Main content card */}
				<Box
					position="relative"
					bg={bgColor}
					borderRadius="2xl"
					boxShadow="xl"
					p={12}
					maxW="2xl"
					width="full"
					textAlign="center"
					backdropFilter="blur(10px)"
					border="1px solid"
					borderColor="teal.100"
				>
					{/* Floating icon */}
					<Flex justifyContent="center" mb={8} animation={floatAnimation}>
						<Circle size="100px" bg={iconBgColor} animation={glowAnimation}>
							<Icon as={CopyIcon} w={12} h={12} color="teal.500" />
						</Circle>
					</Flex>

					<VStack spacing={6}>
						<Heading as="h2" size="xl" color={headingColor} fontWeight="bold">
							Select A Project
						</Heading>

						<Text fontSize="lg" color={textColor} maxW="md" lineHeight="tall">
							Choose a project from the dropdown menu above to view diversity requirements, available
							vendors, and detailed spending analytics.
						</Text>

						{/* Animated indicator */}
						<Flex
							align="center"
							justify="center"
							gap={3}
							mt={6}
							animation={`${floatKeyframe} 2s infinite ease-in-out`}
						>
							<Icon as={ArrowUpIcon} w={6} h={6} color="teal.500" />
							<Text fontSize="sm" color="teal.500" fontWeight="medium">
								Use the selector above
							</Text>
						</Flex>

						{/* Status indicator */}
						<Flex align="center" gap={2} mt={4}>
							<Circle size="8px" bg="teal.500" animation={`${pulseKeyframe} 1.5s infinite`} />
							<Text fontSize="sm" color={textColor} fontWeight="medium">
								Waiting for project selection...
							</Text>
						</Flex>
					</VStack>
				</Box>
			</Box>
		</Container>
	);
}
