import { chakraThemeColorsMap } from "../../../helperFunctions";

export default class SectionTemplateMFRSchedule {
	constructor(contract = null, contractTotals = null, prevContractTotals = null, isCRS = false) {
		this.template = "MFRSchedule";
		this.name = "Schedule";
		this.label = isCRS ? "III." : "V.";
		this.heading = "Schedule";
		this.divider = false;
		this.pagebreak = 1;
		this.type = "virtualTable";
		this.subSections = [new SubSectionTemplateMFRSchedule(contract, contractTotals, prevContractTotals)];
	}
}

class SubSectionTemplateMFRSchedule {
	constructor(contract = null, contractStatus = null, prevContractStatus = null) {
		let origStartDate = contract?.origStartDate ?? null;
		let currStartDate = contract?.currStartDate ?? null;
		let overUnderStartDate =
			Boolean(origStartDate) && Boolean(currStartDate)
				? (new Date(currStartDate).getTime() - new Date(origStartDate).getTime()) / (1000 * 60 * 60 * 24)
				: 0;

		let origCloseDate = contract?.origCloseDate ?? null;
		let projCloseDate = contract?.projCloseDate ?? null;
		let overUnderCloseDate =
			Boolean(origCloseDate) && Boolean(projCloseDate)
				? (new Date(projCloseDate).getTime() - new Date(origCloseDate).getTime()) / (1000 * 60 * 60 * 24)
				: 0;

		let origDuration =
			Boolean(origCloseDate) && Boolean(origStartDate)
				? (new Date(origCloseDate).getTime() - new Date(origStartDate).getTime()) / (1000 * 60 * 60 * 24)
				: 0;
		let currDuration =
			Boolean(projCloseDate) && Boolean(currStartDate)
				? (new Date(projCloseDate).getTime() - new Date(currStartDate).getTime()) / (1000 * 60 * 60 * 24)
				: 0;
		let overUnderDuration = Boolean(currDuration) && Boolean(origDuration) ? currDuration - origDuration : 0;

		let origEstHours = parseFloat(contractStatus?.origEstHours ?? contract?.origEstHours ?? 0);
		let currEstHours = parseFloat(contractStatus?.currEstHours ?? contract?.currEstHours ?? 0);
		let overUnderEstHours = currEstHours - origEstHours;

		let projHours = parseFloat(contractStatus?.projHours ?? contract?.projHours ?? 0);
		let actualHours = parseFloat(contractStatus?.actualHours ?? contract?.actualHours ?? 0);
		let remainHours = projHours - actualHours;
		let overUnderProjHours = projHours - currEstHours;

		let fieldManpower = 0;
		if (Boolean(prevContractStatus)) {
			fieldManpower = prevContractStatus?.getActualCrewSizeFIELD_delta() ?? 0;
		}

		this.template = "MFRSchedule";
		this.type = "virtualTable";
		this.columns = [
			{
				accessor: "label",
				Header: "Schedule",
				width: "40%",
			},
			{
				accessor: "origSchedule",
				Header: "Original",
				textAlign: "center",
				valueType: "number",
				width: "20%",
			},
			{
				accessor: "currSchedule",
				Header: "Current",
				textAlign: "center",
				valueType: "number",
				width: "20%",
			},
			{
				accessor: "overUnder",
				Header: "Over / (Under)",
				textAlign: "center",
				valueType: "number",
				width: "20%",
			},
		];
		this.data = [
			{
				label: "A.  Scheduled Start Date",
				origSchedule: origStartDate,
				origScheduleType: "date",
				origScheduleTextColor: Boolean(origStartDate)
					? chakraThemeColorsMap.black
					: chakraThemeColorsMap.silver,
				currSchedule: currStartDate,
				currScheduleType: "date",
				currScheduleTextColor: Boolean(currStartDate)
					? chakraThemeColorsMap.black
					: chakraThemeColorsMap.silver,
				overUnder: overUnderStartDate,
				overUnderRightAddon: "days",
			},
			{
				label: "B.  Scheduled Completion Date",
				origSchedule: origCloseDate,
				origScheduleType: "date",
				origScheduleTextColor: Boolean(origCloseDate)
					? chakraThemeColorsMap.black
					: chakraThemeColorsMap.silver,
				currSchedule: projCloseDate,
				currScheduleType: "date",
				currScheduleTextColor: Boolean(projCloseDate)
					? chakraThemeColorsMap.black
					: chakraThemeColorsMap.silver,
				overUnder: overUnderCloseDate,
				overUnderRightAddon: "days",
			},
			{
				label: "C.  Overall Duration",
				origSchedule: origDuration,
				origScheduleRightAddon: "days",
				currSchedule: currDuration,
				currScheduleRightAddon: "days",
				overUnder: overUnderDuration,
				overUnderRightAddon: "days",
			},
			{
				label: "D.  Total Budgeted Manhours",
				origSchedule: origEstHours,
				origScheduleDecimals: 0,
				origScheduleRightAddon: "hrs",
				currSchedule: currEstHours,
				currScheduleDecimals: 0,
				currScheduleRightAddon: "hrs",
				overUnder: overUnderEstHours,
				overUnderRightAddon: "hrs",
			},
			{
				label: "E.  Current Actual Manhours",

				origSchedule: "N/A",
				origScheduleType: "string",
				origScheduleBg: chakraThemeColorsMap.whitesmoke,

				currSchedule: actualHours,
				currScheduleDecimals: 0,
				currScheduleRightAddon: "hrs",

				overUnder: "N/A",
				overUnderBg: chakraThemeColorsMap.whitesmoke,
				overUnderType: "string",
			},
			{
				label: "F.  Projected Hours To Complete",
				origSchedule: "N/A",
				origScheduleBg: chakraThemeColorsMap.whitesmoke,
				origScheduleType: "string",

				currSchedule: remainHours,
				currScheduleDecimals: 0,
				currScheduleRightAddon: "hrs",

				overUnder: "N/A",
				overUnderBg: chakraThemeColorsMap.whitesmoke,
				overUnderType: "string",
			},
			{
				label: "G.  Projected Hours @ Completion",
				origSchedule: "N/A",
				origScheduleBg: chakraThemeColorsMap.whitesmoke,
				origScheduleType: "string",

				currSchedule: projHours,
				currScheduleDecimals: 0,
				currScheduleRightAddon: "hrs",

				overUnder: overUnderProjHours,
				overUnderRightAddon: "hrs",
				overUnderDecimals: 0,
			},
			{
				label: "H.  Field Manpower",
				origSchedule: "N/A",
				origScheduleType: "string",
				origScheduleBg: chakraThemeColorsMap.whitesmoke,
				currSchedule: fieldManpower,
				currScheduleDecimals: 1,
				currScheduleRightAddon: "men",
				overUnder: "N/A",
				overUnderBg: chakraThemeColorsMap.whitesmoke,
				overUnderType: "string",
			},
		];
	}
}
