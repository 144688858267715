import React, { useState } from "react";
import { Box, Center, Stack, Heading, Button, HStack, useToast } from "@chakra-ui/react";
import Modal from "../../../core/Layout/modals/Modal";
import DataInput from "../../../core/Inputs/data/DataInput";
import Vendor from "../classes/Vendor";

export default function AddNewVendor(props) {
	const toast = useToast();
	const vendor = new Vendor();
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const spacing = { vertSpacing: 2, horzSpacing: 2, leftFlex: 4, rightFlex: 2 };

	const submitRequest = () => {
		setButtonDisabled(true);
		let request = {
			vendorGroup: "1",
			vendorName: vendor.vendorName,
			vendorSortName: vendor.vendorName.toUpperCase(),
			poAddress: vendor.poAddress,
			poAddress2: vendor.poAddress2,
			poCity: vendor.poCity,
			poState: vendor.poState,
			poZip: vendor.poZip,
			diversityURL: vendor.diversityURL,
			diversityContact: vendor.diversityContact,
			diversityEmail: vendor.diversityEmail,
			diversityPhone: vendor.diversityPhone,
			diversityServiceType: vendor.diversityServiceType,
		};
		// vendor.insertVendor(request);
		setButtonDisabled(false);
		props.closeModal();
		toast({
			title: "Oops! This button does not work yet!",
			// description: "New vendor added! Please refresh the page to see the changes.",
			status: "error",
			duration: 5000,
			isClosable: true,
		});
	};

	let footer = (
		<Center direction="row" w="full" p={4}>
			<HStack spacing="4" w="full">
				<Button variant="outline" colorScheme="red" flex={1} onClick={props.closeModal}>
					CANCEL
				</Button>
				<Button
					variant="solid"
					colorScheme="green"
					flex={1}
					onClick={() => {
						submitRequest();
					}}
					disabled={buttonDisabled}
				>
					SUBMIT
				</Button>
			</HStack>
		</Center>
	);

	return (
		<Modal
			title="New Vendor"
			isOpen={props.isOpen}
			onClose={props.closeModal}
			size="lg"
			onSubmit={submitRequest}
			submitting={props.submitting}
			footer={footer}
		>
			<Box>
				<Heading
					as="h4"
					mb="1"
					textTransform="uppercase"
					py="1"
					isTruncated
					letterSpacing={1}
					fontWeight="semibold"
					size="xs"
					color="gray.400"
				>
					Vendor Info
				</Heading>

				<Stack
					w="full"
					h="full"
					flex={1}
					bg="blackAlpha.50"
					p="4"
					rounded="5"
					border="1px"
					borderColor="blackAlpha.100"
				>
					<DataInput
						isDisabled
						key="vendorGroup"
						name="vendorGroup"
						type="text"
						label="Company"
						placeholder="Company"
						defaultValue="Pan-Pacific Mechanical"
						flex={spacing.leftFlex}
						variant="outline"
						inputProps={{ px: 2 }}
						isModal
					/>
					<DataInput
						key="vendorName"
						name="vendorName"
						type="text"
						label="Vendor Name"
						placeholder="Vendor Name"
						onBlur={(e) => {
							vendor.vendorName = e.target.value;
						}}
						defaultValue={null}
						flex={spacing.leftFlex}
						variant="outline"
						inputProps={{ px: 2 }}
						isModal
					/>
					<DataInput
						key="poAddress"
						name="poAddress"
						type="text"
						label="Vendor Address"
						placeholder="Vendor Address"
						onBlur={(e) => {
							vendor.poAddress = e.target.value;
						}}
						defaultValue={null}
						flex={spacing.leftFlex}
						variant="outline"
						inputProps={{ px: 2 }}
						isModal
					/>
					<DataInput
						key="poAddress2"
						name="poAddress2"
						type="text"
						label="Vendor Address 2"
						placeholder="Vendor Address 2"
						onBlur={(e) => {
							vendor.poAddress2 = e.target.value;
						}}
						defaultValue={null}
						flex={spacing.leftFlex}
						variant="outline"
						inputProps={{ px: 2 }}
						isModal
					/>
					<DataInput
						key="poCity"
						name="poCity"
						type="text"
						label="Vendor City"
						placeholder="Vendor City"
						onBlur={(e) => {
							vendor.poCity = e.target.value;
						}}
						defaultValue={null}
						flex={spacing.leftFlex}
						variant="outline"
						inputProps={{ px: 2 }}
						isModal
					/>
					<DataInput
						key="poState"
						name="poState"
						type="text"
						label="Vendor State"
						placeholder="Vendor State"
						onBlur={(e) => {
							vendor.poState = e.target.value;
						}}
						defaultValue={null}
						flex={spacing.leftFlex}
						variant="outline"
						inputProps={{ px: 2 }}
						isModal
					/>
					<DataInput
						key="poZip"
						name="poZip"
						type="text"
						label="Vendor ZIP"
						placeholder="Vendor ZIP"
						onBlur={(e) => {
							vendor.poZip = e.target.value;
						}}
						defaultValue={null}
						flex={spacing.leftFlex}
						variant="outline"
						inputProps={{ px: 2 }}
						isModal
					/>
					<DataInput
						key="diversityURL"
						name="diversityURL"
						type="text"
						label="Vendor Link"
						placeholder="Vendor Link"
						onBlur={(e) => {
							vendor.diversityURL = e.target.value;
						}}
						defaultValue={null}
						flex={spacing.leftFlex}
						variant="outline"
						inputProps={{ px: 2 }}
						isModal
					/>
					<DataInput
						key="diversityContact"
						name="diversityContact"
						type="text"
						label="Vendor Contact Name"
						placeholder="Vendor Contact Name"
						onBlur={(e) => {
							vendor.diversityContact = e.target.value;
						}}
						defaultValue={null}
						flex={spacing.leftFlex}
						variant="outline"
						inputProps={{ px: 2 }}
						isModal
					/>
					<DataInput
						key="diversityEmail"
						name="diversityEmail"
						type="text"
						label="Vendor Email"
						placeholder="Vendor Email"
						onBlur={(e) => {
							vendor.diversityEmail = e.target.value;
						}}
						defaultValue={null}
						flex={spacing.leftFlex}
						variant="outline"
						inputProps={{ px: 2 }}
						isModal
					/>
					<DataInput
						key="diversityPhone"
						name="diversityPhone"
						type="text"
						label="Vendor Phone #"
						placeholder="Vendor Phone #"
						onBlur={(e) => {
							vendor.diversityPhone = e.target.value;
						}}
						defaultValue={null}
						flex={spacing.leftFlex}
						variant="outline"
						inputProps={{ px: 2 }}
						isModal
					/>
					<DataInput
						key="diversityServiceType"
						name="diversityServiceType"
						type="text"
						label="Vendor Service Type"
						placeholder="Vendor Service Type"
						onBlur={(e) => {
							vendor.diversityServiceType = e.target.value;
						}}
						defaultValue={null}
						flex={spacing.leftFlex}
						variant="outline"
						inputProps={{ px: 2 }}
						isModal
					/>
				</Stack>
			</Box>
		</Modal>
	);
}
