const TIME_OFF_RELEVANCE_LEVELS = [
	{ level: 1, levelDesc: "My Time Off" },
	{ level: 2, levelDesc: "Team Time Off" },
	{ level: 3, levelDesc: "Sent To Me" },
	{ level: 4, levelDesc: "Also Notified Me" },
	{ level: 5, levelDesc: "My Department" },
	{ level: 6, levelDesc: "My Parent Dept" },
	{ level: 7, levelDesc: "My Region" },
	{ level: 8, levelDesc: "All Other Requests" },
];
export default TIME_OFF_RELEVANCE_LEVELS;
