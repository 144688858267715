import React from "react";
import ToggleButton from "../../../core/ReactTable/buttons/ToggleButton";

export default function VirtualRoutesButton({ isOpen, setIsOpen }) {
	return (
		<>
			{Boolean(setIsOpen) && (
				<ToggleButton
					toggleIsOpen={isOpen}
					onClick={setIsOpen.toggle}
					toggleOptions={[
						{
							isOpen: true,
							label: "Searchable Menu Off",
							icon: "fas fa-unlink fa-lg",
							color: "gray.500",
							bg: "whiteAlpha.700",
							borderWidth: 2,
						},
						{
							isOpen: false,
							label: "Searchable Menu On",
							icon: "fas fa-link fa-lg",
							color: "gray.500",
							bg: "whiteAlpha.700",
							borderWidth: 2,
						},
					]}
				/>
			)}
		</>
	);
}
