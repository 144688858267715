import React, { useMemo } from "react";
import { Switch, Route, useLocation, useParams, Redirect } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import ProjectsPage from "../pages/ProjectsPage";

export default function ProjectRoutes({ tab }) {
	let location = useLocation();

	return (
		<Switch location={location}>
			<Route exact path={`/projects`} children={<ProjectRouteViews tab={tab} />} />
			<Route exact path={`/projects/:tab`} children={<ProjectRouteViews tab={tab} />} />
		</Switch>
	);
}

function ProjectRouteViews(props) {
	let params = useParams();

	const prepareTab = (tab = null) => {
		if (!["active", "completed"]?.includes(tab)) {
			return null;
		} else {
			return tab;
		}
	};

	var routeTab = prepareTab(params?.tab);

	const tab = useMemo(() => {
		return prepareTab(props.tab);
	}, [props.tab]);

	return (
		<Flex flex={1} w="full" direction="row" justify="center">
			<Flex direction={"column"} w="full" flex={1}>
				{Boolean(tab) && tab !== routeTab ? (
					<Redirect push to={`/projects/${tab}`} />
				) : ["active", "completed"].includes(routeTab) ? (
					<ProjectsPage tab={routeTab} />
				) : (
					<Box>Something went wrong - there is an issue with the Projects Tab</Box>
				)}
			</Flex>
		</Flex>
	);
}
