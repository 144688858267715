import React from "react";
import {
	Box,
	Stack,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Td,
	Heading,
	Text,
} from "@chakra-ui/react";
import TimecardEntryTableRow from "./TimecardEntryTableRow";
import TimecardClassLegacy from "../classes/TimecardClassLegacy";
import EARN_CODES from "../constants/EarnCodes";

export default class TimecardEntryTable extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			payweek: null,
			prEndDate: this.props.prEndDate,
			payPeriods: this.props.payPeriods,
			hoveringAdd: false,
		};

		this.loadHours = this.loadHours.bind(this);
		this.handleOnHover = this.handleOnHover.bind(this);
		this.handleOffHover = this.handleOffHover.bind(this);
		this.addHours = this.addHours.bind(this); // Requires same function in parent to handle response
		this.updateTimecardItem = this.updateTimecardItem.bind(this); // Requires same function in parent to handle response
		this.sortHours = this.sortHours.bind(this);
	}

	componentDidMount() {
		if (this.props.timecardList) {
			this.setState({
				payweek: this.props.timecardList,
				prEndDate: this.props.prEndDate,
				payPeriods: this.props.payPeriods,
			});

			if (this.props.readyCallback) {
				this.props.readyCallback(true);
			}
		} else {
			this.loadHours();
		}
	}

	componentDidUpdate() {
		if (this.props?.prEndDate !== this.state?.prEndDate) {
			this.setState({
				payweek: null,
				prEndDate: this.props.prEndDate,
			});
			if (this.props.timecardList) {
				this.setState({
					payweek: this.props.timecardList,
				});
				if (this.props.readyCallback) {
					this.props.readyCallback(true);
				}
			} else {
				this.loadHours();
			}
		}
	}

	async loadHours() {
		let payweek = new TimecardClassLegacy(
			this.props.employee,
			this.state.prEndDate
		);
		await payweek.loadPayweekTime();

		this.setState({
			payweek: payweek,
		});

		if (this.props.readyCallback) {
			this.props.readyCallback(true);
		}
	}

	handleOnHover() {
		this.setState({ hoveringAdd: true });
	}

	handleOffHover() {
		this.setState({ hoveringAdd: false });
	}

	addHours(ev) {
		ev.stopPropagation();
		this.props.addHours();
		if (this.props.triggerTableUpdate) {
			this.props.triggerTableUpdate();
		}
	}

	updateTimecardItem(update) {
		update.employee = this.props.employee;
		this.props.updateTimecardItem(update);
		if (
			(update.attr === "delete" || update.attr === "hours") &&
			this.props.triggerTableUpdate
		) {
			this.props.triggerTableUpdate();
		}
	}

	sortHours(ev, sortBy) {
		ev.stopPropagation();
		if (this.props.sortHours) {
			this.props.sortHours(sortBy);
		}
	}

	render() {
		let disabledEarnCodes = [];
		let disabledJobs = [];
		let disabledPhases = [];

		//MOVE TO TIMECARD HEADER
		for (let i = 0; i < this.props.timecardList?.payweekHours.length; i++) {
			for (
				let j = i + 1;
				j < this.props.timecardList?.payweekHours.length;
				j++
			) {
				if (this.props.timecardList?.payweekHours[i].earnCode >= 4) {
					disabledEarnCodes.push(
						EARN_CODES.find(
							({ value }) =>
								value ===
								this.props.timecardList?.payweekHours[i]
									.earnCode
						)?.key
					);
				}

				if (
					this.props.timecardList?.payweekHours[i].earnCode ===
						this.props.timecardList?.payweekHours[j].earnCode &&
					this.props.timecardList?.payweekHours[i].job === "N/A"
				) {
					disabledJobs.push(
						this.props.timecardList?.payweekHours[i].job
					);
				}

				if (
					this.props.timecardList?.payweekHours[i].earnCode ===
						this.props.timecardList?.payweekHours[j].earnCode &&
					this.props.timecardList?.payweekHours[i].job ===
						this.props.timecardList?.payweekHours[j].job &&
					this.props.timecardList?.payweekHours[i].shift ===
						this.props.timecardList?.payweekHours[j].shift
				) {
					disabledPhases.push(
						this.props.timecardList?.payweekHours[i].phase
					);
				}

				if (
					this.props.timecardList?.payweekHours[i].earnCode ===
						this.props.timecardList?.payweekHours[j].earnCode &&
					this.props.timecardList?.payweekHours[i].job ===
						this.props.timecardList?.payweekHours[j].job &&
					this.props.timecardList?.payweekHours[i].phase ===
						this.props.timecardList?.payweekHours[j].phase &&
					this.props.timecardList?.payweekHours[i].shift ===
						this.props.timecardList?.payweekHours[j].shift
				) {
					disabledEarnCodes.push(
						EARN_CODES.find(
							({ value }) =>
								value ===
								this.props.timecardList?.payweekHours[i]
									.earnCode
						)?.key
					);
				}
			}
		}

		return (
			<Box w="full" overflowX="auto" px={"32px"}>
				<Heading
					w="full"
					size="md"
					color="gray.500"
					textTransform="uppercase"
					py={1}
				>
					{this.props.companyMap[this.props.employee?.prco]?.company}
				</Heading>
				<Table
					key={
						"timecardEntryTable-PRCo-" +
						this.props.employee?.prco +
						"-Employee-" +
						this.props.employee?.employee
					}
					size="sm"
					borderStyle="inset"
				>
					<Thead
						shadow="lg"
						textTransform="uppercase"
						fontWeight="bold"
						bg={
							this.props.employee?.prco === "250"
								? "blue.600"
								: "teal.600"
						}
						color="white"
					>
						<Tr borderTopRadius={"md"} py={1}>
							<Td
								borderTopLeftRadius="md"
								isTruncated
								minW="110px"
								cursor="pointer"
								onClick={(ev) => {
									this.sortHours(ev, "earnCode");
								}}
								px={1}
							>
								<Stack
									spacing={1}
									w="full"
									flex={1}
									h="full"
									justify="center"
									align="flex-start"
									p={1}
									textShadow={"1.25px 1.25px 1px #2D3748"}
									rounded="md"
								>
									<Text letterSpacing={2} fontSize="sm">
										Labor
									</Text>
								</Stack>
							</Td>
							<Td
								px={1}
								isTruncated
								minW="125px"
								maxW="125px"
								w="125px"
							>
								<Stack
									spacing={1}
									w="full"
									flex={1}
									h="full"
									justify="center"
									align="flex-start"
									p={1}
									isTruncated
									textShadow={"1.25px 1.25px 1px #2D3748"}
								>
									<Text
										letterSpacing={2}
										fontSize="sm"
										isTruncated
									>
										Job #
									</Text>
								</Stack>
							</Td>
							<Td px={1} minW="175px">
								<Stack
									spacing={1}
									w="full"
									flex={1}
									h="full"
									justify="center"
									align="flex-start"
									p={1}
									isTruncated
									textShadow={"1.25px 1.25px 1px #2D3748"}
								>
									<Text
										letterSpacing={2}
										fontSize="sm"
										isTruncated
									>
										Phase
									</Text>
								</Stack>
							</Td>
							<Td
								px={1}
								// borderRightWidth={2} borderRightColor="gray.300"
							>
								<Stack
									spacing={1}
									w="full"
									flex={1}
									h="full"
									justify="center"
									align="flex-start"
									isTruncated
									p={1}
									textShadow={"1.25px 1.25px 1px #2D3748"}
								>
									<Text
										letterSpacing={2}
										fontSize="sm"
										isTruncated
									>
										Shift
									</Text>
								</Stack>
							</Td>
							{this.props.payPeriods?.currentPeriod?.weekDates?.map(
								(day, i) => (
									<Td
										px={1}
										key={i}
										isTruncated
										textAlign="center"
										maxW="100px"
										minW="80px"
									>
										<Stack
											spacing={1}
											w="full"
											flex={1}
											h="full"
											justify="center"
											align="center"
											isTruncated
											p={1}
											textShadow={
												"1.25px 1.25px 1px #2D3748"
											}
										>
											<Text
												letterSpacing={1}
												fontSize="xs"
											>
												{day?.day}
											</Text>
											<Text
												letterSpacing={1}
												fontSize="md"
											>
												{day?.dateStr?.replace(
													/(\d{1,2}\/\d{1,2}).*/gm,
													"$1"
												)}
											</Text>
										</Stack>
									</Td>
								)
							)}

							<Td
								px={1}
								// borderLeftWidth={2}
								// borderRightColor="gray.300"
								textAlign="center"
								maxW="100px"
								borderTopRightRadius="md"
							>
								<Stack
									spacing={1}
									w="full"
									flex={1}
									h="full"
									justify="center"
									align="center"
									isTruncated
									p={1}
									textShadow={"1.25px 1.25px 1px #2D3748"}
								>
									<Text letterSpacing={1} fontSize="xs">
										Total
									</Text>
									<Text letterSpacing={1} fontSize="sm">
										Hrs
									</Text>
								</Stack>
							</Td>
						</Tr>
					</Thead>
					{this.props.ready && (
						<Tbody shadow="md" bg="whiteAlpha.900">
							{this.props.timecardList?.payweekHours?.map(
								(timecardRow, i) => (
									<TimecardEntryTableRow
										//KEYS
										key={i}
										rowIndex={i}
										//TIMECARD JOBS
										timecardJobs={this.props.timecardJobs}
										//PAY PERIODS
										payPeriods={this.props.payPeriods}
										// currentPayPeriod={this.state.currentPayPeriod} //Not Needed

										//PR EMPLOYEES
										employee={this.props.employee}
										//TIMECARD
										timecardHeader={
											this.props.timecardList
												?.timecardHeader
										}
										payweekHours={
											this.props.timecardList.payweekHours
										} //TIMECARD ROWS
										item={timecardRow} //TIMECARD ROW
										//DISABLED
										disabledPhases={disabledPhases}
										disabledEarnCodes={disabledEarnCodes}
										disabledJobs={disabledJobs}
										//FUNCTIONS
										saveTimecard={this.props.saveTimecard}
										updateTimecardItem={
											this.updateTimecardItem
										}
										getAvailableJobPhases={
											this.props.getAvailableJobPhases
										}
										//VIEWS
										dataView={"table"}
									/>
								)
							)}
						</Tbody>
					)}
					{this.props.ready && (
						<Tfoot>
							<Tr py={1}>
								<Td
									textAlign="right"
									colSpan={4}
									borderBottomWidth={0}
									borderTopWidth={2}
									borderTopColor="gray.400"
								>
									<Heading
										size="sm"
										color="gray.500"
										letterSpacing={2}
										py={1}
									>
										WEEK TOTAL
									</Heading>
								</Td>
								{this.props.timecardList
									?.getDayTotals()
									.map((day, i) => (
										<Td
											key={i}
											textAlign="center"
											maxW="100px"
											borderBottomWidth={0}
											borderTopWidth={2}
											borderTopColor="gray.400"
										>
											<Heading size="sm" color="gray.500">
												{day.toFixed(1)}
											</Heading>
										</Td>
									))}
								<Td
									key={
										"timecardEntryTableTotal-" +
										this.props.employee.employeeUID
									}
									textAlign="center"
									maxW="100px"
									borderBottomWidth={0}
									borderTopWidth={2}
									borderTopColor="gray.400"
								>
									<Heading size="sm" color="gray.500">
										{this.props.timecardList
											?.getWeekTotal()
											.toFixed(1)}
									</Heading>
								</Td>
							</Tr>
						</Tfoot>
					)}
				</Table>
			</Box>
		);
	}
}
