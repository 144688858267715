import React from "react";
import { default as ReactSelect, components } from "react-select";
import makeAnimated from "react-select/animated";
import { Checkbox, Text, HStack, Flex, Heading } from "@chakra-ui/react";
import { log } from "../../../helperFunctions";

// React Select MuliSelect Component with Select All Option
export default function MultiSelectInput({
	allowSelectAll = true,
	options,
	onChange,
	isMulti = true,
	closeMenuOnSelect = false,
	hideSelectedOptions = false,
	menuIsOpen,
	value,
}) {
	const allOption = {
		label: "Select All",
		value: "*",
	};

	let allOptions = allowSelectAll ? [allOption, ...options] : options;
	// log("allOptions", allOptions);

	if (allowSelectAll) {
		return (
			<ReactSelect
				// {...props}
				components={{
					Option,
					MultiValue,
					ValueContainer,
					animatedComponents,
				}}
				menuIsOpen={menuIsOpen}
				isMulti={isMulti}
				defaultValue={value}
				closeMenuOnSelect={closeMenuOnSelect}
				hideSelectedOptions={hideSelectedOptions}
				menuPortalTarget={document.querySelector("body")}
				placeholder={
					<Flex
						px={2}
						className="select-placeholder-text"
						textTransform="none"
						color="gray.400"
						fontWeight="normal"
					>
						Select...
					</Flex>
				}
				styles={{
					container: (baseStyles, state) => ({
						...baseStyles,
						width: "100%",
						flexGrow: 1,
						borderRadius: 5,
					}),
					control: (baseStyles, state) => ({
						...baseStyles,
						width: "100%",
						flexGrow: 1,
						borderRadius: 5,
						// height: "var(--chakra-sizes-8)",
						minHeight: "var(--chakra-sizes-8)",
						margin: 0,
						borderColor: state.isFocused ? "var(--chakra-colors-teal-600)" : "",
						boxShadow: state.isFocused ? "0px 0px 0px 1px var(--chakra-colors-teal-600)" : "",

						"&:hover": {
							boxShadow: "0px 0px 0px 1px var(--chakra-colors-teal-600)",
							borderColor: "var(--chakra-colors-teal-600)",
						},
					}),
					menu: (baseStyles, state) => ({
						...baseStyles,
						zIndex: 2,
					}),
					menuPortal: (base) => ({ ...base, zIndex: 9999, minWidth: "var(--chakra-sizes-2xs)" }),
					option: (provided, state) => ({
						...provided,
						backgroundColor: state.isSelected ? "var(--chakra-colors-gray-100)" : "inherit",
						color: state.isSelected ? "var(--chakra-colors-teal-600)" : "var(--chakra-colors-gray-500)",
						fontWeight: state.isSelected ? "bold" : "normal",
						whiteSpace: "nowrap",
						minWidth: "125px",
						cursor: "pointer",
						"&:hover": {
							backgroundColor: state.isSelected
								? "var(--chakra-colors-teal-600)"
								: "var(--chakra-colors-gray-100)",
							// fontWeight: "bold",
							color: state.isSelected ? "white" : "var(--chakra-colors-gray-500)",
						},
					}),
					valueContainer: (styles, { data }) => {
						return {
							...styles,
							overflowY: "auto",
							padding: 2,
							//
						};
					},
					multiValue: (styles, { data }) => {
						return {
							...styles,
							backgroundColor: `var(--chakra-colors-${data?.color ?? "gray"}-200)`,

							display: "flex",
							alignItems: "center",
							":hover": {
								backgroundColor: `var(--chakra-colors-${data?.color ?? "gray"}-300)`,
							},
						};
					},
					multiValueLabel: (styles, { data }) => ({
						...styles,
						color: `var(--chakra-colors-${data?.color ?? "gray"}-500)`,
						display: "flex",
						alignItems: "center",
						":hover": {
							color: `var(--chakra-colors-${data?.color ?? "gray"}-600)`,
						},
					}),
					multiValueRemove: (styles, { data }) => ({
						...styles,
						color: `var(--chakra-colors-${data?.color ?? "gray"}-600)`,
						padding: 2,
						":hover": {
							backgroundColor: `var(--chakra-colors-${data?.color ?? "gray"}-500)`,
							color: "white",
						},
					}),
					clearIndicator: (styles) => ({
						...styles,
						padding: 2,
						display: "none",
					}),
					dropdownIndicator: (styles) => ({
						...styles,
						padding: 2,
					}),
				}}
				options={allOptions}
				value={value}
				onChange={(selected, event) => {
					if (selected !== null && selected.length > 0) {
						if (selected[selected.length - 1].value === allOption.value) {
							log("CLICK SELECT ALL OPTION");
							return onChange(allOptions);
						}
						let result = [];
						if (selected.length >= options.length) {
							if (event.action === "select-option") {
								log("CLICK ALL INDIVIDUAL OPTIONS");
								result = allOptions;
							} else {
								let selectOptions = selected.map((s) => s.value);
								if (selectOptions.includes(allOption.value)) {
									log("DESELECT AN OPTION WHEN ALL ARE SELECTED");
									result = selected.filter((option) => option.value !== allOption.value);
								}
							}
							return onChange(result);
						}
					}
					return onChange(selected);
				}}
			/>
		);
	} else {
		return (
			<ReactSelect
				components={{
					Option,
					MultiValue,
					ValueContainer,
					animatedComponents,
				}}
				styles={{ width: "100%", flexGrow: 1 }}
				options={options}
				onChange={onChange}
				isMulti={isMulti}
				closeMenuOnSelect={closeMenuOnSelect}
				hideSelectedOptions={hideSelectedOptions}
				value={value}
			/>
		);
	}
}

const animatedComponents = makeAnimated();

const Option = (props) => {
	return (
		<Flex w="full" flex={1}>
			<components.Option {...props}>
				<HStack
					align="center"
					spacing={3}
					onClick={(e) => {
						e.preventDefault();
					}}
				>
					<Checkbox
						size="lg"
						colorScheme="teal"
						isChecked={props.isSelected}
						color="gray.500"
						borderColor="gray.400"
					/>

					<Text
						fontSize="sm"
						letterSpacing={1}
						whiteSpace="no-wrap"
						// fontWeight={props.isSelected ? "semibold" : "normal"}
						// color={props.isSelected ? "var(--chakra-colors-teal-500)" : "gray.500"}
					>
						{props.label}
					</Text>
				</HStack>
			</components.Option>
		</Flex>
	);
};

const allOption = {
	label: "Select All",
	value: "*",
};

const ValueContainer = ({ children, ...props }) => {
	const currentValues = props.getValue();
	let toBeRendered = children;
	if (currentValues.some((val) => val.value === allOption.value)) {
		toBeRendered = [[children[0][0]], children[1]];
		// toBeRendered = [[children[0][0]], children[1]];
	}

	return <components.ValueContainer {...props}>{toBeRendered}</components.ValueContainer>;
};

const MultiValue = (props) => {
	let labelToBeDisplayed = `${props.data.label}`;
	if (props.data.value === allOption.value) {
		labelToBeDisplayed = "All Selected";
	}

	return (
		<components.MultiValue {...props}>
			<Heading fontSize="xs">
				{/* <Heading fontSize="xs" letterSpacing={1} textTransform="uppercase"> */}
				{labelToBeDisplayed}
			</Heading>
		</components.MultiValue>
	);
};
