import React from "react";
import { Switch, Menu, MenuItem, MenuGroup, MenuButton, MenuList, Flex, Text, useBoolean } from "@chakra-ui/react";

export default function SettingsMenu({ allColumns, isOpen, onClose, onClick, children }) {
	return (
		<Menu closeOnSelect={false} p={0} onClose={onClose}>
			<MenuButton onClick={onClick}>{children}</MenuButton>

			<MenuList color="gray.500" bg="gray.50" minW="md" maxH="md" overflow="auto" className="skinnyScroll" pr="2">
				<MenuGroup
					title={
						<Flex w="full" flex={1}>
							<Flex align="center" letterSpacing={1} flex={2} isTruncated>
								<Text
									fontWeight="semibold"
									lineHeight={1.15}
									fontSize="sm"
									textTransform="uppercase"
									isTruncated
								>
									Columns
								</Text>
							</Flex>
							<Flex align="center" letterSpacing={1} flex={1} isTruncated justify="center">
								<Text
									fontWeight="semibold"
									lineHeight={1.15}
									fontSize="sm"
									textTransform="uppercase"
									isTruncated
								>
									Show/Hide
								</Text>
							</Flex>
						</Flex>
					}
					fontWeight="semibold"
					letterSpacing={1}
					color="gray.500"
					borderBottom="2px"
					borderColor="gray.400"
				>
					{allColumns?.map((column, i) => (
						<SettingsMenuItem key={i} column={column} />
					))}
				</MenuGroup>
			</MenuList>
		</Menu>
	);
}

function SettingsMenuItem({ column }) {
	const [isVisible, setIsVisible] = useBoolean(column?.isVisible ?? true);

	const toggleMenuItem = () => {
		setIsVisible.toggle();
		column.toggleHidden(isVisible);
	};

	return (
		column.id !== "expander" &&
		!column.disableExport && (
			<MenuItem key={column.id}>
				<Flex align="center" letterSpacing={1} flex={2} isTruncated>
					<Text lineHeight={1.15} fontSize="sm" isTruncated fontWeight="semibold" textTransform="uppercase">
						{column.Header}
					</Text>
				</Flex>
				<Flex align="center" letterSpacing={1} flex={1} justify="center">
					<Switch
						id={column.id + "-Switch-" + isVisible}
						colorScheme="blue"
						isChecked={isVisible}
						onChange={toggleMenuItem}
					/>
				</Flex>
			</MenuItem>
		)
	);
}
