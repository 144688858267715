import React from "react";
import { Button } from "@chakra-ui/react";
import EgnyteLinkMenu from "./EgnyteLinkMenu";

export default function DFScheduleButtons({ dfJob, selectJob }) {
	return (
		<>
			{Boolean(dfJob) && Object.keys(dfJob).length > 1 && (
				<>
					<Button
						bg="whiteAlpha.700"
						_hover={{ bg: "whiteAlpha.900", color: "teal.500" }}
						textTransform="uppercase"
						onClick={() => selectJob(dfJob, "task")}
					>
						Add Task
					</Button>
					<Button
						bg="whiteAlpha.700"
						_hover={{ bg: "whiteAlpha.900", color: "teal.500" }}
						textTransform="uppercase"
						onClick={() => selectJob(dfJob, "job")}
					>
						Edit Job
					</Button>
					<EgnyteLinkMenu dfJob={dfJob} />
				</>
			)}
		</>
	);
}
