import React, { useState } from "react";
import { Box, Input, Divider, Progress } from "@chakra-ui/react";

export default function ResourceGroupsVirtualList(props) {
	const [scrollTop, setScrollTop] = useState(0);
	const [filterStr, setFilter] = useState("");
	const [filterTimer, setFilterTimer] = useState(null);

	const handleScroll = (ev) => {
		ev.stopPropagation();
		var element = ev.target;
		setScrollTop(element.scrollTop);
	};

	const handleFilter = (ev) => {
		var input = ev.target.value;
		input.toLowerCase();
		setFilterTimer(clearTimeout(filterTimer));
		setFilterTimer(
			setTimeout(() => {
				setFilter(input);
			}, 200)
		);
	};

	const elementPosition = Math.floor(scrollTop / props.listItemHeight);
	const numViewableRows = Math.ceil(props.height / props.listItemHeight);
	const lowerBound = elementPosition - 2;
	const upperBound = elementPosition + (numViewableRows + 2);
	var filter;

	if (props.proto) {
		filter = filterStr.toLowerCase();
	} else {
		filter = props.filter ? props.filter?.toLowerCase() : "";
	}

	var filteredOut = 0;

	return (
		<div>
			{props.searchBar !== false && (
				<Input name="listSearch" variant="outline" placeholder="Search..." onChange={handleFilter} />
			)}
			{props.loadingData && (
				<Box py={3}>
					<Progress size="xs" isIndeterminate />
				</Box>
			)}
			<Box
				className="skinnyScroll"
				style={{ height: props.height + "px", minHeight: props.height + "px" }}
				overflowY="auto"
				overflowX="hidden"
				onScroll={handleScroll}
			>
				{props.listItems.map((item, i) => {
					let render = true;

					if (!item["filterStr"]?.toLowerCase()?.includes(filter)) {
						render = false;
						filteredOut++;
					}

					if (i - filteredOut >= lowerBound && i - filteredOut < upperBound && render) {
						return (
							<Box key={"listItem-" + i} style={{ height: props.listItemHeight + "px" }}>
								{item["component"]}
								<Divider />
							</Box>
						);
					} else {
						return (
							<div key={"listItem-" + i} style={{ height: props.listItemHeight + "px" }} hidden={!render}>
								<Divider />
							</div>
						);
					}
				})}
			</Box>
		</div>
	);
}
