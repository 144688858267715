import React from "react";
import ToggleButton from "./ToggleButton";

export default function FilterButton({ filterIsOpen, setFilterIsOpen }) {
	return (
		<>
			{Boolean(setFilterIsOpen) && (
				<ToggleButton
					toggleIsOpen={filterIsOpen}
					onClick={setFilterIsOpen.toggle}
					toggleOptions={[
						{
							isOpen: true,
							label: "Hide Filters",
							icon: "fas fa-filter",
							color: "teal.500",
							bg: "whiteAlpha.900",
						},
						{
							isOpen: false,
							label: "Show Filters",
							icon: "fas fa-filter",
							color: "gray.500",
							bg: "whiteAlpha.700",
						},
					]}
				/>
			)}
		</>
	);
}
