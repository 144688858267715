import React, { useMemo } from "react";
import { Flex, Text, Spacer } from "@chakra-ui/react";
import { formatNumber } from "../../../helperFunctions";
import moment from "moment";

export default function LabelValuePairsSection(props) {
	const columns = useMemo(() => props.columns ?? [], [props.columns]);
	const data = useMemo(() => props.data ?? [], [props.data]);

	let styles = {};
	if (columns.length > 0) {
		for (let i = 0; i < columns?.length; i++) {
			let keyID = columns[i].accessor;
			styles[keyID] = {
				paddingHorizontal: 2,
				justifyContent: "flex-end",
				borderColor: "gray.600",
				borderTopWidth: columns[i]?.borderTopWidth ?? 0,
				borderLeftWidth: columns[i]?.borderLeftWidth ?? 0,
				borderRightWidth: columns[i]?.borderRightWidth ?? 0,
				borderBottomWidth: columns[i]?.borderBottomWidth ?? columns[i].accessor === "value" ? 1 : 0,
				borderRadius: columns[i]?.borderRadius ?? 0,
				width: columns[i]?.width ?? "100%",
			};
			keyID = columns[i].accessor + "Text";
			styles[keyID] = {
				textAlign: columns[i]?.textAlign ?? "left",
				fontSize: columns[i]?.fontSize ?? "md",
				fontWeight:
					columns[i]?.fontWeight ??
					(columns[i].accessor === "label" ||
						columns[i].accessor === "leftAddon" ||
						columns[i].accessor === "rightAddon")
						? "semibold"
						: "md",
				textTransform:
					columns[i]?.textTransform ??
					(columns[i].accessor === "label" || columns[i].accessor === "leftAddon")
						? "uppercase"
						: "",
				letterSpacing:
					columns[i]?.letterSpacing ??
					(columns[i].accessor === "label" ||
						columns[i].accessor === "leftAddon" ||
						columns[i].accessor === "rightAddon")
						? 1
						: 0,
				color:
					columns[i]?.color ??
					(columns[i].accessor === "label" ||
						columns[i].accessor === "leftAddon" ||
						columns[i].accessor === "rightAddon")
						? "gray.500"
						: "gray.600",
			};
		}
	}

	return (
		<>
			{data?.map((data) => (
				<Flex
					justify="flex-end"
					w="full"
					// flex={1}
					h={8}
					minH={8}
					textAlign="left"
					direction="row"
					flexGrow={0}
					key={data.keyID}
				>
					{columns.length > 0 &&
						columns?.map((column) => {
							return (
								<Flex
									direction="column"
									align="flex-start"
									borderTopWidth={styles[column.accessor].borderTopWidth}
									borderLeftWidth={styles[column.accessor].borderLeftWidth}
									borderRightWidth={styles[column.accessor].borderRightWidth}
									borderBottomWidth={styles[column.accessor].borderBottomWidth}
									borderRadius={styles[column.accessor].borderRadius}
									w={styles[column.accessor].width}
									px={styles[column.accessor].paddingHorizontal}
									borderColor="gray.500"
									justify={styles[column.accessor].justifyContent}
								>
									<Text
										textAlign={styles[column.accessor + "Text"].textAlign}
										fontSize={styles[column.accessor + "Text"].fontSize}
										fontWeight={styles[column.accessor + "Text"].fontWeight}
										textTransform={styles[column.accessor + "Text"].textTransform}
										letterSpacing={styles[column.accessor + "Text"].letterSpacing}
										color={styles[column.accessor + "Text"].color}
										w="full"
										isTruncated
									>
										{(data[column.accessor + "Type"] ?? column?.valueType) === "currency"
											? formatNumber(
													data[column.accessor] ?? 0,
													data[column.accessor + "Decimals"] ?? column?.decimals ?? 0,
													"currency"
											  )
											: (data[column.accessor + "Type"] ?? column?.valueType) === "percent"
											? formatNumber(
													data[column.accessor] ?? 0,
													data[column.accessor + "Decimals"] ?? column?.decimals ?? 1,
													"percent"
											  )
											: (data[column.accessor + "Type"] ?? column?.valueType) === "number"
											? formatNumber(
													data[column.accessor] ?? 0,
													data[column.accessor + "Decimals"] ?? column?.decimals ?? 0
											  )
											: (data[column.accessor + "Type"] ?? column?.valueType) === "month" &&
											  data[column.accessor] !== null
											? moment(data[column.accessor]).format("MMM YYYY")
											: (data[column.accessor + "Type"] ?? column?.valueType) === "date" &&
											  data[column.accessor] !== null
											? moment(data[column.accessor]).format("M/D/YYYY")
											: data[column.accessor] ?? null}
									</Text>
								</Flex>
							);
						})}
					{columns.length === 0 && (
						<Flex px={4} justify="center">
							<Text>{data.td}</Text>
						</Flex>
					)}
				</Flex>
			))}
			<Spacer />
		</>
	);
}
