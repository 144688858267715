import React, { useState, useEffect, useRef } from "react";
import { Input, Flex, Icon, InputGroup, InputLeftElement } from "@chakra-ui/react";

import { formatValue } from "../../../helperFunctions";
import { inputData } from "../components/Section";

export default function VirtualTableEditableCell({
	cell,
	maxW,
	borderRightWidth,
	value: initialValue,
	row: { index, original },
	column: { id, valueType, decimals, textAlign },
	updateMyData,
	rowCount = 0,
	columnIndex = 0,
}) {
	// We need to keep and update the state of the cell normally
	const [newValue, setNewValue] = useState(initialValue);

	const inputRef = useRef();

	const onChange = (e) => {
		setNewValue(e.target.value);
	};

	const onBlur = (e) => {
		if (+initialValue !== +newValue) {
			e.stopPropagation();
			inputData.selectedInput = { rowIndex: null, columnID: null };
			updateMyData(index, id, newValue);
		}
	};

	// If the initialValue is changed external, sync it up with our state
	useEffect(() => {
		setNewValue(initialValue);
	}, [initialValue]);

	return (
		<Flex
			px={0}
			py={1}
			align="center"
			w={cell.column.width ?? "full"}
			// maxW={maxW}
			borderRightColor={"gray.400"}
			borderRightWidth={borderRightWidth}
			bg={"blackAlpha.50"}
			role="group"
			isTruncated
			onClick={(e) => e.stopPropagation()}
		>
			<InputGroup rounded={5} px={0} w="" mx={"1"}>
				{!Boolean(original?.editable) && (
					<InputLeftElement
						_groupHover={{ visibility: "visible" }}
						borderRightWidth={2}
						borderRightColor={Boolean(original?.editable) ? "teal.500" : "red.600"}
						visibility={"hidden"}
						bg="blackAlpha.100"
						pointerEvents="none"
						borderTopLeftRadius={5}
						borderBottomLeftRadius={5}
						h="full"
						maxH={inputRef?.current?.offsetHeight ?? "full"}
					>
						<Icon color={Boolean(original?.editable) ? "gray.500" : "gray.400"} lineHeight={1}>
							<i className="fas fa-lock fa-fw fa-sm" />
						</Icon>
					</InputLeftElement>
				)}

				<Input
					tabIndex={index + columnIndex * rowCount}
					isDisabled={!Boolean(original?.editable)}
					ref={inputRef}
					_hover={{
						cursor: Boolean(original?.editable) ? "pointer" : "not-allowed",
						fontWeight: "semibold",
						borderWidth: 2,
						borderColor: Boolean(original?.editable) ? "teal.500" : "red.600",
					}}
					autoFocus={Boolean(
						inputData.selectedInput.rowIndex === index && inputData.selectedInput.columnID === id
					)}
					variant="unstyled"
					bg={Boolean(original?.editable) ? "white" : "whiteAlpha.200"}
					// bg={Boolean(original?.editable) ? "white" : "whiteAlpha.700"}
					// color={Boolean(original?.editable) ? "gray.600" : "gray.500"}

					color={"gray.600"}
					borderWidth={Boolean(original?.editable) ? 1 : 0}
					textAlign={textAlign}
					placeholder={formatValue(0, decimals, valueType)}
					defaultValue={
						Boolean(original?.editable) && newValue === 0
							? null
							: formatValue(newValue, decimals, valueType)
					}
					onChange={onChange}
					onBlur={onBlur}
					autoComplete="off"
					rounded={5}
					px={2}
					_focus={{ fontWeight: "semibold", borderWidth: 1, borderColor: "teal.500" }}
				/>
			</InputGroup>
		</Flex>
	);
}
