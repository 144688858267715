const DF_TYPES = [
	{
		queryData: "DFType",
		templateType: "D&F",
		title: "Detailing & Fab Schedule (D&F)",
		subtitle: "D&F Projects",
		params: {},
		tabs: ["D&F Jobs", "D&F Schedule"],
		mapKeys: ["scopeDesc", null],
	},
	{
		queryData: "DFType",
		templateType: "SUP",
		title: "Start Up Planner (SUP)",
		subtitle: "Start Up Projects",
		params: { scopeID: "13" },
		tabs: ["SUP Jobs", "SUP Schedule"],
		mapKeys: [null, null],
	},
];
export default DF_TYPES;
