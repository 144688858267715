import React from "react";
import ToggleButton from "./ToggleButton";

export default function SearchButton({ searchIsOpen, setSearchIsOpen }) {
	return (
		<>
			{Boolean(setSearchIsOpen) && (
				<ToggleButton
					toggleIsOpen={searchIsOpen}
					onClick={setSearchIsOpen.toggle}
					toggleOptions={[
						{
							isOpen: true,
							label: "Hide Search Bar",
							icon: "fas fa-search",
							color: "teal.500",
							bg: "whiteAlpha.900",
						},
						{
							isOpen: false,
							label: "Show Search Bar",
							icon: "fas fa-search",
							color: "gray.500",
							bg: "whiteAlpha.700",
						},
					]}
				/>
			)}
		</>
	);
}
