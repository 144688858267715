import PayrollAPI from "../../../APIs/PayrollAPI";
import { formatValue, log } from "../../../helperFunctions";
import TimecardJobPhaseClassLegacy from "./TimecardJobPhaseClassLegacy";

export default class TimecardEntryRowClassLegacy {
	constructor(item, employee, prEndDate) {
		this.payrollAPI = new PayrollAPI();
		this.keyId = item?.keyId ?? item?.KeyID ?? null;
		this.employeeUID = (item?.employeeUID ?? item?.EmployeeUID ?? employee?.employeeUID)?.toLowerCase() ?? null;
		this.prEndDate = formatValue(item?.prEndDate ?? item?.PREndDate ?? prEndDate, 0, "date");
		this.prco = item?.prco ?? item?.PRCo ?? employee?.prco;
		this.employee = item?.employee ?? item?.Employee ?? employee?.employee ?? null;
		this.jcco = item?.jcco ?? item?.JCCo ?? null;
		this.job = item?.Job ?? item?.job ?? null;
		this.jobDescription = item?.jobDescription ?? item?.JobDescription ?? null;
		this.phaseGroup = item?.phaseGroup ?? item?.PhaseGroup ?? null;
		this.phase = item?.phase ?? item?.Phase ?? null;
		this.phaseDescription = item?.phaseDescription ?? item?.PhaseDescription ?? "";
		this.earnCode = item?.earnCode ?? item?.EarnCode ?? null;
		this.shift = item?.shift ?? item?.Shift ?? 1;
		this.hours = item?.hours ?? ["0.0", "0.0", "0.0", "0.0", "0.0", "0.0", "0.0"];
		this.notes = item?.notes ?? [null, null, null, null, null, null, null];
		this.batchID = item?.batchID ?? item?.BatchID ?? null;
		this.paySeq = item?.paySeq ?? item?.PaySeq ?? null;
		this.status = item?.status ?? item?.Status ?? null;
		this.statusIcon = item?.statusIcon ?? item?.StatusIcon ?? null;
		this.locked = item?.locked ?? item?.Locked ?? 0;
		this.uploadErrors = [];
		this.hourInputTimer = null;

		if (item && !item.hours) {
			for (var i = 1; i < 8; i++) {
				let dayNum = "Day" + i;
				let dayHours = 0;
				let dayNotesNum = "Day" + i + "Notes";

				if (item[dayNum] === ".00") {
					dayHours = parseFloat(0.0).toFixed(1);
				} else {
					dayHours = parseFloat(item[dayNum]).toFixed(1);
				}
				this.hours[i - 1] = dayHours;
				this.notes[i - 1] = item[dayNotesNum];
			}
			if (this.job === null) {
				this.job = "N/A";
				this.phase = "N/A";
				this.phaseDescription = "Overhead";
			}
		}
	}

	async insertTimecardEntry() {
		let insertedRow = await this.payrollAPI.InsertTimecardRow(this);
		log("insertedRow", insertedRow);
		if (insertedRow.status === 200) {
			this.keyId = insertedRow.value[0].KeyID;
			return true;
		} else {
			return false;
		}
	}

	async deleteHours(keyId) {
		if (this.keyId !== undefined && keyId === this.keyId) {
			let deletedRow = await this.payrollAPI.DeleteTimecardRow(this.keyId);
			if (deletedRow.status === 200) {
				this.keyId = undefined;
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}

	async updateTimecard(updates) {
		let updateResult = await this.payrollAPI.UpdateTimecardRow(this.keyId, updates);
		if (updateResult.status === 200 && updateResult.value?.length > 0) {
			for (let attr in updates) {
				if (attr === "KeyID") {
					continue;
				}
				if (updateResult.value[0][attr] !== updates[attr]) {
					this.uploadErrors.push(updates);
					return false;
				}
			}
			return true;
		} else {
			return false;
		}
	}

	async save() {
		let updates = {
			EarnCode: this.earnCode,
			JCCo: this.jcco,
			Job: this.job,
			PhaseGroup: this.phaseGroup,
			Phase: this.phase,
			Shift: this.shift,
		};

		for (let i = 0; i < this.hours.length; i++) {
			if (this.hours[i] === ".00" || this.hours[i] === "" || isNaN(this.hours[i])) {
				this.hours[i] = 0;
			}
			this.hours[i] = parseFloat(this.hours[i]);

			this.notes[i] = this.notes[i]?.replace(/'/gm, "`");
			let dbNoteAttr = "Day" + (i + 1) + "Notes";

			let dbAttr = "Day" + (i + 1);
			updates[dbAttr] = this.hours[i];
			updates[dbNoteAttr] = this.notes[i];
		}

		let uploadResult = await this.updateTimecard(updates);
		return uploadResult;
	}

	async saveHour(index) {
		let updates = {};

		if (this.hours[index] === ".00" || this.hours[index] === "" || isNaN(this.hours[index])) {
			this.hours[index] = 0;
		}
		this.hours[index] = parseFloat(this.hours[index]);

		let dbAttr = "Day" + (index + 1);
		updates[dbAttr] = this.hours[index];

		let uploadResult = await this.updateTimecard(updates);
		return uploadResult;
	}

	async updateHours(hourIndex, value, upload = false) {
		if (value.includes(".")) {
			let decimalStr = value.substr(value.indexOf("."));

			if (decimalStr.length > 2) {
				value = value.substr(0, value.indexOf(".") + 2);
			}
			let decimal = value.replace(/\d+.{1}(\d)/gm, "$1");
			if (parseInt(decimal) !== 0 && parseInt(decimal) !== 5) {
				value = value.replace(/(\d+.{1})\d/gm, "$1");
			}
		}

		if (hourIndex === 6 && parseFloat(value) > 16) {
			return false;
		} else if (hourIndex !== 6 && parseFloat(value) > 16) {
			return false;
		}

		if (upload) {
			if (value === ".00" || value === "" || isNaN(value)) {
				value = 0;
			}
			value = parseFloat(value);
		}
		this.hours[hourIndex] = value;

		if (upload) {
			let dbAttr = "Day" + (hourIndex + 1);
			let updates = { [dbAttr]: value };
			// Upload to Db
			let uploadResult = await this.updateTimecard(updates);
			this.hours[hourIndex] = value.toFixed(1);
			return uploadResult;
		} else {
			clearTimeout(this.hourInputTimer);
			this.hourInputTimer = setTimeout(async () => {
				this.saveHour(hourIndex);
			}, 1000);
			return true;
		}
	}

	updateNotes(dayNum, value, upload = false) {
		this.notes[dayNum] = value;

		if (upload) {
			value = value.replace(/'/gm, "`");
			let dbAttr = "Day" + (dayNum + 1) + "Notes";
			let updates = { [dbAttr]: value };
			// Upload to Db
			return this.updateTimecard(updates);
		} else {
			return true;
		}
	}

	changeEarnCode(value, upload = true) {
		let updates = {};
		value = parseInt(value);
		this.earnCode = updates.EarnCode = value;

		if (value === 5 || value === 6 || value === 7) {
			this.phase = updates.Phase = "N/A";
			this.phaseGroup = updates.PhaseGroup = null;
			this.phaseDescription = "Overhead";
			this.job = updates.Job = "N/A";
			this.shift = updates.Shift = 1;
		} else if ((value === 2 || value === 3) && this.job === "N/A") {
			this.phase = null;
			this.phaseGroup = null;
			this.phaseDescription = "";
			this.job = null;
			this.hours = ["", "", "", "", "", "", ""];
		}

		if (this.keyId !== undefined && upload) {
			return this.updateTimecard(updates);
		} else {
			return true;
		}
	}

	async changeJob(value, upload = true) {
		let updates = {};
		this.job = updates.Job = value.job;
		this.jcco = updates.JCCo = value.jcco;
		this.jobDescription = value.description;

		if (value.job === "N/A") {
			this.phase = updates.Phase = "N/A";
			this.phaseGroup = updates.PhaseGroup = null;
			this.phaseDescription = "Overhead";
			this.jobDescription = "Overhead";
			this.shift = updates.Shift = 1;
		} else {
			this.phase = updates.Phase = null;
			this.phaseGroup = updates.PhaseGroup = null;
			this.phaseDescription = "";
			this.hours = ["", "", "", "", "", "", ""];
			await this.deleteHours(this.keyId);
		}
		// Update DB
		if (this.keyId !== undefined && upload) {
			return this.updateTimecard(updates);
		} else {
			return true;
		}
	}

	changePhase(value, upload = true) {
		let updates = {};
		this.phase = updates.Phase = this.job === "N/A" ? "N/A" : value.phase;
		this.phaseGroup = updates.PhaseGroup = this.job === "N/A" ? null : value.phaseGroup;
		this.phaseDescription = value.description;

		// Update DB
		if (this.keyId !== undefined && upload) {
			return this.updateTimecard(updates);
		} else {
			return true;
		}
	}

	changeShift(value, upload = true) {
		let updates = {};
		this.shift = updates.Shift = parseInt(value);

		// Update DB
		if (this.keyId !== undefined && upload) {
			return this.updateTimecard(updates);
		} else {
			return true;
		}
	}

	isFilled() {
		if (this.job !== "N/A" && (this.phase === null || this.phase === "N/A")) {
			return false;
		}

		if (this.earnCode === null || this.job === null || this.phase === null) {
			return false;
		}
		return true;
	}

	equals(compareItem) {
		if (
			compareItem.earnCode === this.earnCode &&
			compareItem.job === this.job &&
			compareItem.phase === this.phase &&
			compareItem.shift === this.shift &&
			compareItem.paySeq === null
		) {
			return true;
		} else {
			return false;
		}
	}

	getTotalHours() {
		let total = 0;
		if (Boolean(this.keyId)) {
			for (let i = 0; i < this.hours.length; i++) {
				let hours = isNaN(parseFloat(this.hours[i])) ? 0 : parseFloat(this.hours[i]);
				total += hours;
			}
		}
		return isNaN(total) ? 0.0 : total;
	}

	hasNotes(index) {
		if (index >= 0) {
			if (this.notes[index]?.length > 0) {
				return true;
			}
		} else {
			for (let i = 0; i < this.notes.length; i++) {
				if (this.notes[i]?.length > 0) {
					return true;
				}
			}
		}

		return false;
	}

	//DUSTIN LOOK HERE!!
	isUndefined() {
		return Boolean(this.keyId);
	}

	isLocked() {
		if (this.paySeq !== null && this.batchID !== null) {
			return true;
		} else {
			return false;
		}
	}

	isPosted() {
		if (this.paySeq !== null) {
			return false;
		} else {
			return true;
		}
	}

	async getTimecardJobPhases(co = null, job = null) {
		let phases = [];
		let naPhases = [new TimecardJobPhaseClassLegacy({ key: "N/A", value: "N/A" })];
		if (job === "N/A" || !Boolean(co) || !Boolean(job)) {
			return naPhases;
		} else {
			phases = await this.payrollAPI.GetTimecardJobPhases(job, co);
			if (phases.status === 200) {
				phases = phases.value;
				for (let i = 0; i < phases?.length; i++) {
					phases[i] = new TimecardJobPhaseClassLegacy(phases[i]);
				}
				return phases;
			} else if (phases.status === 400) {
				if (job === "N/A") {
					return naPhases;
				}
			} else {
				return phases;
			}
		}
	}
}
