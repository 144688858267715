import React from "react";
import { Center, Heading, VStack, Flex, Button } from "@chakra-ui/react";
import { motion } from "framer-motion";

export default function DragAndDropExample({ heading, subHeading }) {
	const [count, setCount] = React.useState(0);
	const constraintsRef = React.useRef(null);

	const button = {
		rest: { scale: 1 },
		hover: { scale: 1.1 },
		pressed: { scale: 0.95 },
	};

	const refresh = {
		position: "absolute",
		borderRadius: "10px",
		maxWidth: "100px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		cursor: "pointer",
	};

	const dragArea = {
		opacity: "0.2",
		background: "var(--chakra-colors-gray-500)",
		position: "relative",
		width: "600px",
		height: "600px",
		borderRadius: "var(--chakra-radii-xl)",
	};

	const style = {
		width: "200px",
		height: "200px",
		background: "var(--chakra-colors-teal-400)",
		borderRadius: "var(--chakra-radii-xl)",
		cursor: "pointer",
		position: "absolute",
	};

	return (
		<Center w="full" minH="sm" h="full" bg="white" rounded={5} p={8}>
			<Center border="3px dashed" borderColor="gray.200" rounded="xl" h="full" w="full">
				<VStack spacing={4} flex={1} w="full" pb={48}>
					<Heading color="gray.500" textTransform="uppercase" letterSpacing={6} size="xl" pt={16}>
						{subHeading}
					</Heading>
					<Heading color="gray.400" textTransform="uppercase" letterSpacing={6} size="3xl" pb={8}>
						{heading}
					</Heading>

					<Flex w="full" flex={1} position="relative" justify="flex-end">
						<motion.div
							style={refresh}
							variants={button}
							initial="rest"
							whileHover="hover"
							whileTap="pressed"
						></motion.div>
					</Flex>

					<Flex key={count} direction="column" w="full" h="full" justify="center" align="center" pb={16}>
						<motion.div ref={constraintsRef} style={dragArea} />
						<motion.div drag dragConstraints={constraintsRef} style={style} />
					</Flex>
					<Button
						onClick={() => setCount(count + 1)}
						textTransform="uppercase"
						fontWeight="semibold"
						border="1px"
						size="lg"
						borderColor="gray.300"
						shadow="md"
						letterSpacing={1}
						color="gray.500"
					>
						Refresh
					</Button>
				</VStack>
			</Center>
		</Center>
	);
}
