export default class TimecardPhase {
	constructor(phase) {
		if (phase instanceof TimecardPhase) {
			Object.assign(this, phase);
		} else {
			this.queryData = phase?.queryData ?? "TimecardPhase";
			this.jcco = phase?.jcco ?? "1";
			this.job = phase?.job ?? "1";
			this.phaseGroup = phase?.phaseGroup ?? "1";
			this.phase = phase?.phase ?? null;
			this.description = phase?.description ?? null;
			this.levelAreaID = phase?.levelAreaID ?? null;
			this.levelAreaDesc = phase?.levelAreaDesc ?? null;
			this.codeVersion = phase?.codeVersion ?? "2021";
			this.codeOrder = phase?.codeOrder ?? null;
			this.key = phase?.key ?? null;
			this.value = phase?.value ?? null;
		}
	}
}
