import React, { useState, useMemo, useCallback } from "react";
import { Container, Tabs, TabList, Tab, Heading, TabPanels, TabPanel } from "@chakra-ui/react";
import { portalUser } from "../../../App";
import BasicCard from "../../../core/Layout/cards/BasicCard";

import AccrualCalculatorCard from "./AccrualCalculatorCard";
import { convertArrayToMap } from "../../../helperFunctions";

export default function AvailableTimeOffCard({ upcomingRequests }) {
	const [tabVal, setTabVal] = useState(0);
	const [tabs, setTabs] = useState([]);

	const changeTab = useCallback((e) => {
		setTabVal(e);
	}, []);

	useMemo(() => {
		let tabs = [];
		let employeesMap = convertArrayToMap(portalUser?.getPREmployees(), "employeeUID", true);
		let prEmployees = employeesMap[portalUser.user?.employeeUID] ?? [];

		let companyMap = convertArrayToMap(portalUser?.getCompanies(), "jcco");
		if (Boolean(prEmployees?.length > 0)) {
			for (let i = 0; i < prEmployees?.length; i++) {
				tabs.push({
					name: companyMap[prEmployees[i]?.prco]?.tag ?? null,
					page: <AccrualCalculatorCard prEmployee={prEmployees[i]} upcomingRequests={upcomingRequests} />,
				});
			}
		}
		setTabs(tabs);
	}, [upcomingRequests]);

	return (
		<BasicCard title="Available Time Off" portalStyles={portalUser.styles}>
			<Container px={0} flex={1} key={"reportTab-Container-" + tabVal} maxW="full">
				<Tabs
					isLazy
					colorScheme="teal"
					index={tabVal}
					onChange={changeTab}
					display="flex"
					flexDirection="column"
					flex={1}
					w="full"
					h="full"
				>
					{Boolean(tabs.length > 1) && (
						<TabList color="gray.600" borderBottomColor="whiteAlpha.500">
							{tabs?.map((tab, i) => (
								<Tab
									key={i}
									_selected={{
										color: "teal.600",
										bg: "whiteAlpha.500",
										borderTopRadius: "5",
										borderBottom: "3px solid",
									}}
									_hover={{
										color: "teal.500",
										bg: "whiteAlpha.500",
										borderTopRadius: "5",
										borderBottom: "3px solid",
										borderBottomColor: "teal.600",
									}}
								>
									<Heading size="sm" letterSpacing={1} textTransform="uppercase">
										{tab.name}
									</Heading>
								</Tab>
							))}
						</TabList>
					)}

					<TabPanels display="flex" flexDirection="column" h="full" w="full" flex={1}>
						{tabs?.map((tab, i) => (
							<TabPanel
								key={i}
								display="flex"
								flexDirection="column"
								h="full"
								w="full"
								flex={1}
								px={0}
								pt={2}
								pb={0}
							>
								{tab.page}
							</TabPanel>
						))}
					</TabPanels>
				</Tabs>
			</Container>
		</BasicCard>
	);
}
