import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Flex, Stack, Button, Text, IconButton, Tooltip, Spinner } from "@chakra-ui/react";
import DataInput from "../../../../../core/Inputs/data/DataInput";
import DIVERSITY_CERTS from "../../../../Purchasing/constants/DiversityCerts";
import DIVERSITY_SUB_TYPES from "../../../../Purchasing/constants/DiversitySubTypes";
import LoadingSection from "../../../../../core/ProgressIndicators/components/LoadingSection";
import { log } from "../../../../../helperFunctions";

const RequirementsPage = ({ diversityReqs, jccmKeyID, operations, setOperations, setUpdateTrigger }) => {
	const [jobRequirements, setJobRequirements] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	log("jobRequirements", jobRequirements);

	useMemo(() => {
		if (diversityReqs) {
			const groupedReqs = diversityReqs.reduce((acc, req) => {
				const key = `${req.divReqKeyID}-${req.divReqPct}`;

				if (!acc[key]) {
					acc[key] = {
						divReqKeyID: req.divReqKeyID,
						requirementNum: 0,
						// Round to 1 decimal place to avoid floating point precision issues
						percent: (Math.round(req.divReqPct * 1000) / 10).toString(),
						types: [],
					};
				}

				// Handle both array and string formats for divCertType
				const certType = Array.isArray(req.divCertType) ? req.divCertType[0] : req.divCertType;

				acc[key].types.push({
					id: Date.now() + Math.random(),
					type: certType,
					certSubType: req.divCertSubType,
				});

				return acc;
			}, {});

			const requirementsArray = Object.values(groupedReqs)
				.sort((a, b) => parseInt(a.divReqKeyID) - parseInt(b.divReqKeyID))
				.map((req, index) => ({
					...req,
					requirementNum: index + 1,
				}));

			setJobRequirements(requirementsArray);
		}
	}, [diversityReqs]);

	useEffect(() => {
		const hasInvalidRequirement = jobRequirements.some(
			(requirement) =>
				!requirement.percent || // Check for empty percent
				requirement.percent === "0" || // Check for zero percent
				requirement.types.some((type) => !type.type) // Check for empty type in the types array
		);

		setIsDisabled(hasInvalidRequirement);
	}, [jobRequirements]);

	const handleAddRow = () => {
		setJobRequirements([
			...jobRequirements,
			{
				requirementNum: jobRequirements.length + 1,
				percent: "",
				types: [{ id: Date.now(), type: "" }],
			},
		]);
	};

	const handleAddType = (requirementNum) => {
		setJobRequirements((prevState) =>
			prevState.map((req) =>
				req.requirementNum === requirementNum
					? {
							...req,
							types: [...req.types, { id: Date.now(), type: "" }],
					  }
					: req
			)
		);
	};

	const handlePercentChange = (requirementNum, value) => {
		const numericValue = value.replace(/[$%]/g, "");
		setJobRequirements((prevState) =>
			prevState.map((req) => (req.requirementNum === requirementNum ? { ...req, percent: numericValue } : req))
		);
	};

	const handleTypeChange = (requirementNum, typeId, newValue, field) => {
		setJobRequirements((prevRequirements) =>
			prevRequirements.map((req) => {
				if (req.requirementNum === requirementNum) {
					return {
						...req,
						types: req.types.map((type) => {
							if (type.id === typeId) {
								// Clear certSubType when changing type unless it's SBE
								const updates = { [field]: newValue };
								if (field === "type" && newValue !== "SBE") {
									updates.certSubType = null;
								}
								return { ...type, ...updates };
							}
							return type;
						}),
					};
				}
				return req;
			})
		);
	};

	const handleDeleteType = (requirementNum, typeId) => {
		setJobRequirements((prevRequirements) =>
			prevRequirements.map((req) =>
				req.requirementNum === requirementNum
					? {
							...req,
							types: req.types.filter((type) => type.id !== typeId),
					  }
					: req
			)
		);
	};

	const handleDeleteRequirement = (requirementNum) => {
		setJobRequirements((prevRequirements) =>
			prevRequirements
				.filter((req) => req.requirementNum !== requirementNum)
				.map((req, index) => ({
					...req,
					requirementNum: index + 1,
				}))
		);
	};

	const handleSave = useCallback(
		async (ops = operations) => {
			setIsLoading(true);

			const apiDataReqs = jobRequirements.map((req) => ({
				jccmKeyID,
				divReqPct: parseFloat(req.percent) / 100,
			}));

			// Transform the data back to the API format with arrays
			const apiDataReqCerts = jobRequirements.flatMap((req) => {
				return req.types.map((type) => ({
					divReqPct: parseFloat(req.percent) / 100,
					divReqKeyID: req.requirementNum.toString(),
					divCertType: type.type, // Wrap in array for new format
					divCertSubType: type.certSubType,
				}));
			});

			// TODO: Make API call here
			console.log("apiDataReqs:", apiDataReqs);
			console.log("apiDataReqCerts:", apiDataReqCerts);
			// if (ops && jccmKeyID) {
			// 	ops = await operations.initDiversityReqs({ jccmKeyID }, apiData);
			// 	setOperations(operations);
			// 	setUpdateTrigger(true);
			// }
			setIsLoading(false);
		},
		[jccmKeyID, jobRequirements, operations]
	);

	return !diversityReqs ? (
		<LoadingSection />
	) : (
		<Stack spacing={4} overflowX="auto" whiteSpace="nowrap" pb={4}>
			{jobRequirements.map((requirement, reqIndex) => (
				<Flex key={jccmKeyID + "-" + requirement.requirementNum} direction="column" align="flex-start">
					{reqIndex > 0 && (
						<Flex justify="center" width="300px" ml="50" mb="5">
							<Text fontWeight="bold">AND</Text>
						</Flex>
					)}
					<Flex direction="row" align="flex-end">
						<Tooltip label="Delete Requirement" placement="top" hasArrow>
							<IconButton
								colorScheme="red"
								variant="ghost"
								size="lg"
								mr={2}
								icon={<i className="fas fa-trash-alt" />}
								onClick={() => handleDeleteRequirement(requirement.requirementNum)}
							/>
						</Tooltip>

						<DataInput
							name={`requirement-${requirement.requirementNum}`}
							type="number"
							label={`Requirement ${requirement.requirementNum} Percentage`}
							onBlur={(e) => handlePercentChange(requirement.requirementNum, e.target.value)}
							defaultValue={requirement.percent}
							pr={2}
							variant="outline"
							max={100}
							width="300px"
						/>

						{requirement.types.map((type, typeIndex) => {
							const selectedTypes = requirement.types.map((t) => t.type).filter((t) => t && t !== "SBE"); // Don't include SBE in filtering
							const availableOptions = DIVERSITY_CERTS.filter(
								(cert) =>
									cert.value === "SBE" || // Always allow SBE
									!selectedTypes.includes(cert.value) ||
									cert.value === type.type
							);

							return (
								<Flex key={type.id} align="center">
									<Stack>
										{requirement.types.length > 1 && (
											<Tooltip label="Delete Certification" placement="top" hasArrow>
												<IconButton
													colorScheme="red"
													variant="ghost"
													size="sm"
													icon={<i className="fas fa-minus-circle" />}
													onClick={() =>
														handleDeleteType(requirement.requirementNum, type.id)
													}
												/>
											</Tooltip>
										)}
										<DataInput
											name={`certification-${type.type}`}
											type="select"
											label="Certification"
											placeholder="Select Cert"
											searchable
											options={availableOptions}
											value={type.type}
											optionValue="value"
											optionText="label"
											optionSubheading="description"
											onOptionSelect={(e) =>
												handleTypeChange(requirement.requirementNum, type.id, e, "type")
											}
											variant="outline"
											pl={2}
											width="175px"
										/>
										{type.type === "SBE" && (
											<DataInput
												name={`subtype-${type.certSubType}`}
												type="select"
												label="Sub Type"
												placeholder="Select Subtype"
												searchable
												options={DIVERSITY_SUB_TYPES}
												value={type.certSubType}
												optionValue="value"
												optionText="label"
												optionSubheading="description"
												onOptionSelect={(e) =>
													handleTypeChange(
														requirement.requirementNum,
														type.id,
														e,
														"certSubType"
													)
												}
												variant="outline"
												pl={2}
												width="175px"
											/>
										)}
									</Stack>
									{typeIndex < requirement.types.length - 1 && (
										<Text fontWeight="bold" color="orange.500" mx={4}>
											OR
										</Text>
									)}
								</Flex>
							);
						})}
						<Button
							isDisabled={isLoading || isDisabled}
							onClick={() => handleAddType(requirement.requirementNum)}
							ml={4}
							textTransform="uppercase"
						>
							Or
						</Button>
					</Flex>
				</Flex>
			))}

			<Flex width="100%" justifyContent="space-between" mt={4}>
				<Button
					isDisabled={isLoading || isDisabled}
					onClick={handleAddRow}
					ml={40}
					colorScheme="blue"
					textTransform="uppercase"
				>
					Add Requirement
				</Button>
				<Button
					isDisabled={isLoading || isDisabled}
					colorScheme="green"
					onClick={handleSave}
					textTransform="uppercase"
				>
					{isLoading ? <Spinner /> : "Save Requirements"}
				</Button>
			</Flex>
		</Stack>
	);
};

export default RequirementsPage;
