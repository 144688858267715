// import LongevityAward from "../modules/Employee/classes/LongevityAward";

import Announcement from "../../Home/classes/Announcement";
import TimeOffRequest from "../../TimeOffRequests/classes/TimeOffRequest";
import Notification from "../../Employee/classes/Notification";
import CalendarEvent from "../../../core/Calendars/classes/CalendarEvent";
import SecurityGroup from "../../Employee/classes/SecurityGroup";

// import { log, convertArrayToMap } from "../helperFunctions";

export default class UserData {
	constructor(user) {
		if (user instanceof UserData) {
			Object.assign(this, user);
		} else {
			//APP
			this.userAPI = user?.userAPI ?? null;
			this.user = user?.user ?? null;
			this.employees = user?.employees ?? [];
			this.notifications = user?.notifications ?? [];
			this.announcements = user?.announcements ?? [];
			this.calendarEvents = user?.calendarEvents ?? [];
			this.timeOffRequests = user?.timeOffRequests ?? [];
			this.emergencyContacts = user?.emergencyContacts ?? [];
			this.permissions = user?.permissions ?? [];
			this.settings = user?.settings ?? [];
		}
	}

	classifyData(user = this, sqlData = []) {
		for (let i = 0; i < sqlData?.length; i++) {
			let queryData = sqlData[i]?.queryData;

			if (queryData === "Notification") {
				user.notifications.push(new Notification(sqlData[i]));
			} else if (queryData === "CalendarEvent") {
				user.calendarEvents.push(new CalendarEvent(sqlData[i]));
			} else if (queryData === "Announcement") {
				user.announcements.push(new Announcement(sqlData[i]));
			} else if (queryData === "TimeOffRequest") {
				user.timeOffRequests.push(new TimeOffRequest(sqlData[i]));
			} else if (queryData === "SecurityGroup") {
				user.securityGroups.push(new SecurityGroup(sqlData[i]));
			}
		}

		return user;
	}

	async initUserData(params = null) {
		let user = this;

		if (Boolean(params?.msUserID) && !Boolean(user?.userAPI)) {
			let userAPI = null; //new UsersAPI();
			let sqlData = null; //await userAPI.InitUser(params);
			if (sqlData?.status === 200) {
				sqlData = sqlData?.value ?? [];
				user = this.classifyData(user, sqlData);
				user.userAPI = userAPI;
			}
		}
		return user;
	}
}
