import React from "react";
import { Box, Center, Flex, Heading, Button, Spacer, Container } from "@chakra-ui/react";
import {
	AreaChart,
	XAxis,
	YAxis,
	CartesianGrid,
	Area,
	Tooltip as TooltipRe,
	ResponsiveContainer,
	Legend,
	BarChart,
	Bar,
	Dot,
	ReferenceArea,
} from "recharts";
import { formatValue } from "../../../helperFunctions";

export default class AreaGraph extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			graphDataStr: JSON.stringify(this.props.graphData),
			graphData: this.props.graphData,
			plotData: {},
			xStart: null,
			xEnd: null,
			varY: null,
			maxY: 100,
			minY: 0,
			decimals: 0,
		};

		this.init = this.init.bind(this);
		this.initData = this.initData.bind(this);
		this.handleLegendItemClick = this.handleLegendItemClick.bind(this);
		this.handleLegendItemMouseOver = this.handleLegendItemMouseOver.bind(this);
		this.handleLegendItemMouseLeave = this.handleLegendItemMouseLeave.bind(this);
		this.tooltipFormatter = this.tooltipFormatter.bind(this);
		this.tickFormatter = this.tickFormatter.bind(this);
		this.handleLinePointClick = this.handleLinePointClick.bind(this);
		this.handleMouseDown = this.handleMouseDown.bind(this);
		this.handleMouseMove = this.handleMouseMove.bind(this);
		this.handleMouseUp = this.handleMouseUp.bind(this);
		this.resetZoom = this.resetZoom.bind(this);
	}

	componentDidMount() {
		if (this.props.valueType === "percent") {
			this.initData();
		} else {
			this.init();
		}
	}

	componentDidUpdate() {
		if (JSON.stringify(this.props.graphData) !== this.state.graphDataStr) {
			this.setState({
				graphDataStr: JSON.stringify(this.props.graphData),
				graphData: null,
			});
			this.initData();
			this.init();
		}
	}

	init() {
		if (this.props.graphData?.length > 0) {
			let plotData = {};
			let graphKeys = Object.keys(this.props.graphData[0]);
			const colors = ["#48BB78", "#4299E1", "#48BB78", "#F56565", "#ECC94B", "#ED8936"];

			for (let i = 0; i < graphKeys.length; i++) {
				if (graphKeys[i] === "name") {
					continue;
				}
				plotData[graphKeys[i]] = {
					hide: false,
					color: colors[i % colors.length],
					hovering: false,
					zIndex: 1,
				};
			}

			this.setState({
				plotData: plotData,
			});
		}
	}

	initData() {
		let graphData = [];
		let maxY = 0;
		let minY = 0;
		let decimals = 0;
		let interval = 0;
		let valueType = this.props.valueType;

		for (let i = 0; i < this.props.graphData?.length; i++) {
			if (Boolean(this.state.xStart) && Boolean(this.state.xEnd)) {
				if (
					new Date(this.props.graphData[i].name).getTime() >= new Date(this.state.xStart).getTime() &&
					new Date(this.props.graphData[i].name).getTime() <= new Date(this.state.xEnd).getTime()
				) {
					let value = parseFloat(this.props.graphData[i][this.props.varY] ?? null);

					if (Boolean(value) && !isNaN(value)) {
						if (!Boolean(maxY) || parseFloat(value) >= parseFloat(maxY)) {
							maxY = parseFloat(value);
						}
						if (!Boolean(minY) || parseFloat(value) <= parseFloat(minY)) {
							minY = parseFloat(value);
						}
					}
					graphData.push(this.props.graphData[i]);
				}
			} else {
				let value = parseFloat(this.props.graphData[i][this.props.varY] ?? null);

				if (Boolean(value) && !isNaN(value)) {
					value = Math.ceil(value / 5) * 5;
					if (!Boolean(maxY) || parseFloat(value) >= parseFloat(maxY)) {
						maxY = value;
					}
					if (!Boolean(minY) || parseFloat(value - 5) <= parseFloat(minY)) {
						minY = value - 5;
					}
				}
				graphData.push(this.props.graphData[i]);
			}
		}
		if (valueType === "percent") {
			if (!Boolean(this.state.xStart) && !Boolean(this.state.xEnd)) {
				if (minY < -25) {
					minY = -25;
				} else if (minY > 0) {
					minY = 0;
				}
				if (maxY > 50) {
					maxY = 50;
				}
			}

			if (maxY === 0) {
				maxY = maxY + 0.0001;
			} else if (maxY % 1 === 0) {
				maxY = maxY - 0.0001;
			}

			if (minY === 0) {
				minY = minY + 0.0001;
			} else if (minY % 1 === 0) {
				minY = minY - 0.0001;
			}
			if (minY === maxY) {
				minY = minY - 0.25;
				maxY = maxY + 0.25;
			}

			let delta = parseFloat(maxY ?? 0) - parseFloat(minY ?? 0);
			if (delta === 0) {
				delta = 0.00001;
			}

			let val = Math.floor(Math.log(delta) / Math.log(10) + 1);
			// log("val", val);
			if (parseFloat(val * -1) > 4) {
				val = -4;
			}

			let multiple = "1";
			if (parseInt(val) === 0) {
				multiple = "1";
			} else if (parseInt(val) > 0) {
				multiple = "1" + "0".repeat(parseInt(val) - 1);
			} else {
				multiple = "0." + "0".repeat(val * -1) + "1";
			}
			multiple = parseFloat(multiple / 10);

			maxY = Math.ceil(maxY / multiple) * multiple;
			minY = Math.floor(minY / multiple) * multiple;
		}
		interval = 11;

		this.setState(
			{
				graphData: graphData,
				maxY: maxY,
				minY: minY,
				interval: interval,
				decimals: decimals,
			},
			() => this.init()
		);
	}

	handleLegendItemClick(item) {
		let plotData = this.state.plotData;
		plotData[item.dataKey].hide = !plotData[item.dataKey].hide;
		this.setState({ plotData: plotData });
	}

	handleLegendItemMouseOver(item) {
		let plotData = this.state.plotData;
		plotData[item.dataKey].hovering = true;
		this.setState({ plotData: plotData });
	}

	handleLegendItemMouseLeave(item) {
		let plotData = this.state.plotData;
		plotData[item.dataKey].hovering = false;
		this.setState({ plotData: plotData });
	}

	handleLinePointClick(item, point) {
		// log("item", item);
		// log("point", point);
		// let plotData = this.state.plotData;
		// plotData[item.dataKey].hide = !plotData[item.dataKey].hide;
		// this.setState({ plotData: plotData });
	}

	handleMouseDown(point, ev) {
		ev.stopPropagation();
		if (!Boolean(point)) {
			return false;
		}
		let xStart = point?.activeLabel;
		this.setState({
			xStart: xStart,
			settingZoom: true,
		});
	}

	handleMouseUp(point, ev) {
		ev.stopPropagation();
		if (!Boolean(point)) {
			return false;
		}
		let xStart = this.state.xStart;
		let xEnd = point?.activeLabel;

		if (new Date(xStart).getTime() > new Date(xEnd).getTime()) {
			let temp = xStart;
			xStart = xEnd;
			xEnd = temp;
		}

		if (xStart !== xEnd) {
			this.setState({
				xStart: xStart,
				xEnd: xEnd,
			});
			setTimeout(() => {
				this.initData();
			}, 200);
		}

		this.setState({
			settingZoom: false,
		});
	}

	resetZoom() {
		this.setState({ xStart: null, xEnd: null });
		setTimeout(() => {
			this.initData();
		}, 200);
	}

	handleMouseMove(point, ev) {
		ev.stopPropagation();
		if (this.state.settingZoom) {
			let xEnd = point?.activeLabel;
			this.setState({ xEnd: xEnd });
		}
	}

	tooltipFormatter(value, name, props) {
		if (this.props.valueType === "money") {
			value = formatValue(value, 0, "currency");
		} else if (this.props.valueType === "percent") {
			value = value + "%";
		}
		return value;
	}

	tickFormatter(value) {
		//log('formatter', value);
		if (this.props.valueType === "money") {
			value = value.toString();
			let powerMatches = value.replace(/(\d{0,3})((\d{3})*$)/gm, "$2");
			value = value.replace(/(\d{0,3})((\d{3})*$)/gm, "$1");

			if (powerMatches) {
				powerMatches = powerMatches.match(/\d{3}/gm);
				switch (powerMatches?.length) {
					case 3:
						value += "B";
						break;
					case 2:
						value += "M";
						break;
					case 1:
						value += "K";
						break;
					default:
						value += "";
						break;
				}
			}
			value = "$" + value;
		} else {
			let delta = parseFloat(this.state.maxY) - parseFloat(this.state.minY) / 10;
			// log("delta", delta);
			let decimals = 0;
			if (parseInt(delta) <= 10 && parseFloat(delta) >= 7) {
				decimals = 1;
			} else if (parseFloat(delta) < 7 && parseFloat(delta) > 1) {
				decimals = 2;
			} else if (parseFloat(delta) <= 1 && parseFloat(delta) > 0.6) {
				decimals = 2;
			} else if (parseFloat(delta) <= 0.6) {
				decimals = 3;
			}
			value = formatValue(parseFloat(value), decimals);
		}

		return value;
	}

	render() {
		if (this.props.type === "bar") {
			return (
				<Box>
					<Center>
						<Heading as="h5" size="sm" color="gray.600">
							{this.props.title}
						</Heading>
					</Center>

					<Box>
						<Button onClick={this.resetZoom}>RESET</Button>
					</Box>

					<Center>
						<ResponsiveContainer>
							<BarChart data={this.props.graphData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
								<defs>
									{Object.keys(this.state.plotData)?.map((plotKey, i) => (
										<linearGradient
											key={i}
											id={"plot-" + plotKey.replace(/\s/gm, "-")}
											x1="0"
											y1="0"
											x2="0"
											y2="1"
										>
											<stop
												offset="5%"
												stopColor={this.state.plotData[plotKey].color}
												stopOpacity={this.state.plotData[plotKey].hovering ? 1 : 0.8}
											/>
											<stop
												offset="95%"
												stopColor={this.state.plotData[plotKey].color}
												stopOpacity={this.state.plotData[plotKey].hovering ? 0.8 : 0}
											/>
										</linearGradient>
									))}
								</defs>
								<XAxis dataKey="name" />
								<YAxis
									label={<Heading size="xs">Dollars</Heading>}
									tickFormatter={this.tickFormatter}
									domain={this.props.valueType === "percent" ? [0, 100] : ["auto", "auto"]}
								/>
								<CartesianGrid strokeDasharray="3 3" />
								<TooltipRe formatter={this.tooltipFormatter} offset={20} />
								<Legend
									verticalAlign="bottom"
									align="center"
									layout="horizontal"
									wrapperStyle={{ right: "15px" }}
									{...this.props.legendProps}
									onClick={this.handleLegendItemClick}
									onMouseEnter={this.handleLegendItemMouseOver}
									onMouseLeave={this.handleLegendItemMouseLeave}
								/>

								{Object.keys(this.state.plotData)?.map((plotKey, i) => (
									<Bar
										key={i}
										type="linear"
										dataKey={plotKey}
										stroke={this.state.plotData[plotKey].color}
										fillOpacity={this.state.plotData[plotKey].hovering ? 1 : 0.95}
										fill={"url(#plot-" + plotKey.replace(/\s/gm, "-") + ")"}
										hide={this.state.plotData[plotKey].hide}
									/>
								))}
							</BarChart>
						</ResponsiveContainer>
					</Center>
				</Box>
			);
		} else {
			let chart = (
				<AreaChart
					data={this.state.graphData}
					margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
					onMouseDown={this.handleMouseDown}
					onMouseUp={this.handleMouseUp}
					onMouseMove={this.handleMouseMove}
				>
					<defs>
						{Object.keys(this.state.plotData)?.map((plotKey, i) => (
							<linearGradient
								key={i}
								id={"plot-" + plotKey.replace(/\s/gm, "-") + (this.props.name ?? "")}
								x1="0"
								y1="0"
								x2="0"
								y2="1"
							>
								<stop
									offset="5%"
									stopColor={this.state.plotData[plotKey].color}
									stopOpacity={this.state.plotData[plotKey].hovering ? 1 : 0.8}
								/>
								<stop
									offset="95%"
									stopColor={this.state.plotData[plotKey].color}
									stopOpacity={this.state.plotData[plotKey].hovering ? 0.8 : 0}
								/>
							</linearGradient>
						))}
					</defs>
					<XAxis tick={{ fontWeight: "bold" }} dataKey="name" />
					{this.props.valueType === "money" && (
						<YAxis
							interval="preserveStartEnd"
							style={{ fontSize: "1.5 rem", color: "#A0AEC0" }}
							type="number"
							tick={{ fontWeight: "bold" }}
							tickFormatter={this.tickFormatter}
							domain={[0, "auto"]}
						/>
					)}
					{this.props.valueType === "percent" && (
						<YAxis
							interval="preserveStartEnd"
							style={{ fontSize: "1.5 rem", color: "#A0AEC0" }}
							type="number"
							tick={{ fontWeight: "bold" }}
							tickFormatter={this.tickFormatter}
							domain={[this.state.minY, this.state.maxY]}
							tickCount={this.state.interval}
							allowDataOverflow={true}
							dataKey={"Pct Margin %"}
						/>
					)}
					<CartesianGrid strokeDasharray="3 3" />
					<TooltipRe
						contentStyle={{ fontWeight: "bold", textTransform: "uppercase" }}
						formatter={this.tooltipFormatter}
						offset={10}
					/>
					<Legend
						verticalAlign="bottom"
						align="center"
						wrapperStyle={{ fontWeight: "bold", textTransform: "uppercase" }}
						layout="horizontal"
						{...this.props.legendProps}
						onClick={this.handleLegendItemClick}
						onMouseEnter={this.handleLegendItemMouseOver}
						onMouseLeave={this.handleLegendItemMouseLeave}
					/>

					{Object.keys(this.state.plotData)?.map((plotKey, i) => (
						<Area
							key={i}
							strokeWidth={3}
							type="linear"
							dataKey={plotKey}
							stroke={this.state.plotData[plotKey].color}
							fillOpacity={this.state.plotData[plotKey].hovering ? 1 : 0.95}
							fill={"url(#plot-" + plotKey.replace(/\s/gm, "-") + (this.props.name ?? "") + ")"}
							hide={this.state.plotData[plotKey].hide}
							activeDot={<Dot onClick={this.handleLinePointClick} />}
						/>
					))}

					{this.state.settingZoom && <ReferenceArea x1={this.state.xStart} x2={this.state.xEnd} />}
				</AreaChart>
			);

			return (
				<Container maxW="container.xl">
					<Flex w="full" justify="center">
						<Spacer />
						<Heading textTransform="uppercase" as="h5" size="md" color="gray.500" letterSpacing={1}>
							{this.props.title}
						</Heading>
						<Spacer />
						<Button size="sm" shadow="xs" color="gray.500" variant="outline" onClick={this.resetZoom}>
							RESET
						</Button>
					</Flex>

					<Center userSelect="none">
						<ResponsiveContainer height={500}>{chart}</ResponsiveContainer>
					</Center>
					<Flex></Flex>
				</Container>
			);
			//}
		}
	}
}
