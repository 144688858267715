import React, { useMemo } from "react";
import { Switch, Route, useLocation, useParams, Redirect } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import DiversityVendorsPage from "../pages/DiversityVendorsPage";
import PurchaseOrderPage from "../pages/PurchaseOrderPage";

export default function PurchasingRoutes({ tab }) {
	let location = useLocation();

	return (
		<Switch location={location}>
			<Route exact path={`/purchasing`} children={<PurchasingRouteViews tab={tab} />} />
			<Route exact path={`/purchasing/:tab`} children={<PurchasingRouteViews tab={tab} />} />
			<Route path={`/purchasing/:tab/:formUID`} children={<PurchasingRouteViews tab={tab} />} />
		</Switch>
	);
}

function PurchasingRouteViews(props) {
	let params = useParams();

	const prepareTab = (tab = null) => {
		if (!["vendors", "po"]?.includes(tab)) {
			return null;
		} else {
			return tab;
		}
	};

	const prepareUID = (uid = null) => {
		if (!Boolean(uid?.length === 36)) {
			return null;
		} else {
			return uid?.toLowerCase();
		}
	};

	var routeTab = prepareTab(params?.tab);
	var routeUID = prepareUID(params?.routeUID);

	const tab = useMemo(() => {
		return prepareTab(props.tab);
	}, [props.tab]);

	return (
		<Flex flex={1} w="full" direction="row" justify="center">
			<Flex direction={"column"} w="full" flex={1}>
				{Boolean(tab) && tab !== routeTab ? (
					<Redirect push to={`/purchasing/${tab}`} />
				) : ["vendors"].includes(routeTab) && Boolean(routeUID) ? (
					<Redirect push to={`/purchasing/${routeTab}`} />
				) : ["vendors"].includes(routeTab) ? (
					<DiversityVendorsPage />
				) : ["po"].includes(routeTab) ? (
					<PurchaseOrderPage />
				) : (
					<Box>Something went wrong - there is an issue with the Tab or FormUID</Box>
				)}
			</Flex>
		</Flex>
	);
}
