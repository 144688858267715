import React, { useMemo } from "react";
import { Select } from "@chakra-ui/react";
import moment from "moment";
// import { log } from "../../../helperFunctions";

// This is a custom filter UI for selecting
// a unique option from a list
export default function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id, valueType } }) {
	// Calculate the options for filtering
	// using the preFilteredRows
	const options = useMemo(() => {
		const options = new Set();
		preFilteredRows?.forEach((row) => {
			options.add(row.values[id]);
		});
		return [...options.values()];
	}, [id, preFilteredRows]);

	return (
		<Select
			value={filterValue}
			size="sm"
			bg="white"
			borderColor="gray.200"
			rounded={5}
			color={Boolean(filterValue) ? "gray.600" : "gray.400"}
			_hover={{ color: "gray.600" }}
			_focus={{ color: "gray.600" }}
			onChange={(e) => {
				setFilter(e.target.value || undefined);
			}}
		>
			<option value="">Select...</option>
			{options.map((option, i) => (
				<option key={i} value={option}>
					{Boolean(option) && valueType === "month"
						? moment(new Date(option)).startOf("month").format("MMM YYYY")
						: option}
				</option>
			))}
		</Select>
	);
}
