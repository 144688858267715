import React, { useState, useMemo } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import {
	Box,
	Flex,
	useBoolean,
	Text,
	Container,
	Stack,
	HStack,
	IconButton,
	Collapse,
	Heading,
	useColorModeValue as mode,
} from "@chakra-ui/react";

import TopNav from "./TopNav";
import SideBarButton from "./SideBarButton";
import BoxViewButton from "./BoxViewButton";
import SideBarWithTreeRoutes from "./SideBarWithTreeRoutes";
import SideBarWithVirtualRoutes from "./SideBarWithVirtualRoutes";

import { flatRoutes, treeRoutes } from "../routes/AppRoutes";

import VirtualRoutes from "./VirtualRoutes";
import AppMenu from "../classes/AppMenu";

import VirtualRoutesButton from "../components/VirtualRoutesButton";

// HELPER FUNCTIONS
// import { log } from "../../helperFunctions";

export default function AppContainer() {
	const [sideBarIsOpen, setSideBarIsOpen] = useBoolean(true);
	const [rainbowMode, setRainbowMode] = useBoolean(false);

	const [routes, setRoutes] = useState(flatRoutes);
	const [appMenu, setAppMenu] = useState(null);

	const [virtualRoutesMode, setVirtualRoutesMode] = useBoolean(false);
	const [boxViewIsOpen, setBoxViewIsOpen] = useBoolean(false);

	useMemo(() => {
		if (virtualRoutesMode) {
			setRoutes(flatRoutes);
		} else {
			setRoutes(treeRoutes);
		}
	}, [virtualRoutesMode]);

	useMemo(() => {
		setAppMenu(new AppMenu(routes));
	}, [routes]);

	var bg = rainbowMode ? conicalRainbowBG : mode("gray.200", "gray.700");
	var bgGradient = mode("linear(to-t, blackAlpha.400, transparent)", "linear(to-b, blackAlpha.600, transparent)");

	return (
		<Flex
			direction="row"
			flex={1}
			maxW="100vw"
			maxH="100vh"
			w="full"
			h="full"
			bg={bg}
			// bgGradient={bgGradient}
			position="relative"
			shadow="xl"
		>
			{!virtualRoutesMode && (
				<SideBarWithTreeRoutes
					routes={routes}
					sideBarIsOpen={sideBarIsOpen}
					setSideBarIsOpen={setSideBarIsOpen}
					rainbowMode={rainbowMode}
					setRainbowMode={setRainbowMode}
				/>
			)}
			{virtualRoutesMode && (
				<SideBarWithVirtualRoutes
					sideBarIsOpen={sideBarIsOpen}
					setSideBarIsOpen={setSideBarIsOpen}
					rainbowMode={rainbowMode}
					setRainbowMode={setRainbowMode}
				>
					{Boolean(appMenu) && (
						<VirtualRoutes
							data={appMenu.data}
							columns={appMenu.columns}
							groupBy={appMenu.groupBy}
							sortBy={appMenu.sortBy}
							filterBy={appMenu.filterBy}
							sideBarIsOpen={sideBarIsOpen}
							renderRow={MenuRow}
						/>
					)}
				</SideBarWithVirtualRoutes>
			)}

			<Flex id="HELLO" direction="column" flex={1} justify="flex-start" bgGradient={bgGradient} maxH="inherit">
				<TopNav
					sideBarIsOpen={sideBarIsOpen}
					setSideBarIsOpen={setSideBarIsOpen}
					sideBarButton={<SideBarButton sideBarIsOpen={sideBarIsOpen} setSideBarIsOpen={setSideBarIsOpen} />}
					boxViewButton={<BoxViewButton isOpen={boxViewIsOpen} setIsOpen={setBoxViewIsOpen} />}
					virtualRoutesButton={
						<VirtualRoutesButton isOpen={virtualRoutesMode} setIsOpen={setVirtualRoutesMode} />
					}
					// startJoyride={startJoyride}
				/>
				<Box overflowY="auto">
					{boxViewIsOpen ? <RouteBoxView routes={routes} /> : <AppRoutes routes={routes} />}
				</Box>
			</Flex>
		</Flex>
	);
}

function MenuRow(row = null, sideBarIsOpen = true, expandIcon = null) {
	const route = useMemo(() => row ?? {}, [row]);

	var color = mode("gray.200", "gray.200");
	var hoverColor = mode("whiteAlpha.900", "white");
	var hoverBG = mode("teal.600", "whiteAlpha.400");

	var stylesActive = {
		bg: hoverBG,
		color: hoverColor,
	};

	var stylesHover = {
		bg: hoverBG,
		color: hoverColor,
		opacity: 1,
		textDecoration: "underline",
		textShadow: "0px 0px 0px transparent",
		borderColor: "blackAlpha.400",
	};

	return (
		// <Flex
		// 	w="full"
		// 	pt={2}
		// 	justify="space-between"
		// 	p={1}
		// 	bg="whiteAlpha.700"
		// 	rounded="md"
		// 	color={"gray.500"}
		// 	h={10}
		// 	align="center"
		// >
		// 	<Text fontSize="sm">{route?.name}</Text>
		// 	<Text fontSize="xs">{route?.basepath + route?.path}</Text>
		// </Flex>
		<Flex
			color={color}
			_active={stylesActive}
			_hover={stylesHover}
			cursor="pointer"
			align="center"
			maxW="full"
			justify={!sideBarIsOpen ? "center" : "space-between"}
			fontWeight="semibold"
			textTransform="uppercase"
			rounded="md"
			w="full"
			flex={1}
			opacity={0.8}
		>
			<HStack align="center" justify="center" isTruncated spacing={1} w="full" flex={1} position="relative">
				<IconButton
					key={route?.icon + " fa-lg fa-fw"}
					variant="unstyled"
					icon={<i className={route?.icon + " fa-lg fa-fw"} />}
				/>

				{sideBarIsOpen && (
					<Text fontFamily="unset" maxW="full" w="full" letterSpacing={1} whiteSpace={"wrap"} fontSize={"md"}>
						{route?.name}
					</Text>
				)}
				{sideBarIsOpen && expandIcon && expandIcon}
			</HStack>
		</Flex>
	);
}

function AppRoutes({ routes }) {
	const location = useLocation();
	const appRoutes = useMemo(() => getRoutes(routes), [routes]);

	return (
		<Switch location={location}>
			{Boolean(appRoutes?.length > 0) && appRoutes}
			<Route exact path={"/"}>
				<Redirect to={"/employee"} />
			</Route>
		</Switch>
	);
}

function getRoutes(routes = [], basePath = "") {
	let newRoutes = [];

	for (let i = 0; i < routes?.length; i++) {
		let route = routes[i];
		let path = basePath + route?.path ?? "";

		if (Boolean(route?.routes?.length > 0)) {
			newRoutes.push(...getRoutes(route?.routes, path));
			newRoutes.push(
				<Route exact path={path}>
					<Redirect push to={path + route?.routes[0]?.path} />
				</Route>
			);
		} else if (Boolean(route?.component)) {
			newRoutes.push(
				<Route exact path={path}>
					{route?.component ?? <></>}
				</Route>
			);
		} else {
			newRoutes.push(
				<Route exact path={path}>
					<Box>
						<Text>This Page does not exist</Text>
					</Box>
				</Route>
			);
		}
	}

	return newRoutes;
}

function RouteBoxView({ routes }) {
	const boxRoutes = useMemo(() => getRoutesBox(routes), [routes]);

	return (
		<Container maxW="full" px={0} overflowX="hidden" overflowY="auto" className="skinnyScroll">
			<Stack spacing={2} w="full" p={8} rounded="md" bg="whiteAlpha.600">
				<Heading color="gray.400" textTransform="uppercase" size="lg">
					Menu Routes
				</Heading>
				<Stack spacing={8} px={8} py={4}>
					{boxRoutes}
				</Stack>
			</Stack>
		</Container>
	);
}

function getRoutesBox(routes = [], basePath = "") {
	let newRoutes = [];

	for (let i = 0; i < routes?.length; i++) {
		let route = routes[i];
		let path = basePath + route?.path ?? "";

		// EXACT ROUTES
		// if the route has a component, go to that component, else go to redirectURL
		if (Boolean(route?.routes?.length > 0)) {
			newRoutes.push(
				<Stack spacing={2}>
					<Box bg="blue.300" rounded="md" p={4}>
						<HStack spacing={4}>
							<IconButton
								key={route?.icon + " fa-fw fa-lg"}
								colorScheme="blue"
								icon={<i className={route?.icon + " fa-fw fa-lg"} />}
							/>
							<Stack spacing={0}>
								<Text color="blue.900" fontWeight="bold" textTransform="uppercase" fontSize="xl">
									{route?.name}
								</Text>

								<HStack>
									<Text
										color="blue.900"
										fontWeight={"semibold"}
										textDecoration="underline"
										fontSize="md"
									>
										{path}
									</Text>
									<Text as="i" color="gray.700" fontSize="sm">
										redirects to
									</Text>
									<Text color="blue.800" fontWeight={"semibold"} textDecoration="underline">
										{path + route?.routes[0]?.path}
									</Text>
								</HStack>
							</Stack>
						</HStack>
					</Box>
					<Box pl={8} pr={2} pb={4}>
						<Collapse in={true}>
							<Stack spacing={2} bg="blackAlpha.200" rounded="md" p={4}>
								{getRoutesBox(route?.routes, path)}
							</Stack>
						</Collapse>
					</Box>
				</Stack>
			);
		} else if (Boolean(route?.component)) {
			newRoutes.push(
				<Box bg="blue.200" rounded="md" p={4}>
					<HStack spacing={4}>
						<IconButton
							key={route?.icon + " fa-fw fa-lg"}
							colorScheme="blue"
							icon={<i className={route?.icon + " fa-fw fa-lg"} />}
						/>
						<Stack spacing={0}>
							<Text color="blue.900" fontWeight="bold" textTransform="uppercase" fontSize="xl">
								{route?.name} Page
							</Text>
							<HStack>
								<Text as="i" color="gray.700" fontSize="sm">
									navigate to
								</Text>
								<Text color="blue.800" fontWeight={"semibold"} textDecoration="underline">
									{path}
								</Text>
							</HStack>
						</Stack>
					</HStack>
				</Box>
			);
		} else {
			newRoutes.push(
				<Box bg="blue.200" rounded="md" p={4}>
					<HStack spacing={4}>
						<IconButton
							key={route?.icon + " fa-fw fa-lg"}
							colorScheme="blue"
							icon={<i className={route?.icon + " fa-fw fa-lg"} />}
						/>
						<Stack spacing={0}>
							<Text color="blue.900" fontWeight="bold" textTransform="uppercase" fontSize="xl">
								{route?.name} Page
							</Text>
							<HStack>
								<Text as="i" color="gray.700" fontSize="sm">
									navigate to
								</Text>
								<Text color="blue.800" fontWeight={"semibold"} textDecoration="underline">
									{path}
								</Text>
							</HStack>
						</Stack>
					</HStack>
				</Box>
			);
		}
	}

	return newRoutes;
}

// function getNavRoutes(routes = [], basePath = "") {
// 	let navRoutes = [];

// 	for (let i = 0; i < routes?.length; i++) {
// 		let route = routes[i];
// 		log("route.path", route?.path);
// 		let path = basePath + route?.path ?? "";

// 		if (Boolean(route?.routes?.length > 0)) {
// 			// navRoutes.push(route);
// 			navRoutes.push(...getNavRoutes(route?.routes, path));
// 		} else {
// 			Object.assign(route, { path });
// 			navRoutes.push(route);
// 		}
// 	}

// 	return navRoutes;
// }

const conicalRainbowBG = `repeating-conic-gradient(
	from 0deg at 80% 50%,
	#5691f580 0% 8.25%,
	#b338ff80 8.25% 16.5%,
	#f8305880 16.5% 25%
), repeating-conic-gradient(
	from 15deg at 50% 50%,
	#e856f580 0% 8.25%,
	#ff384c80 8.25% 16.5%,
	#e7f83080 16.5% 25%
), repeating-conic-gradient(from 0deg at 20% 50%, #f58356ff 0% 8.25%, #caff38ff
	8.25% 16.5%, #30f88aff 16.5% 25%)`;
