export default class SectionTemplateMFRCloseOut {
	constructor(contract = null, isCRS = false) {
		this.template = "MFRCloseOut";
		this.name = "Close Out";
		this.label = isCRS ? "V." : "VI.";
		this.heading = "Close Out";
		this.divider = false;
		this.pagebreak = 1;
		this.type = "labelValuePairs";
		this.py = 0;
		this.subSections = [
			new SubSectionTemplateMFRCloseOut1(contract),
			new SubSectionTemplateMFRCloseOut2(contract),
			new SubSectionTemplateMFRCloseOut3(contract),
		];
	}
}

class SubSectionTemplateMFRCloseOut1 {
	constructor() {
		this.template = "MFRCloseOut1";
		this.type = "labelValuePairs";
		this.width = "30%";
		this.columns = [
			{
				accessor: "leftAddon",
				width: "20%",
			},
			{
				accessor: "label",
				width: "80%",
			},
		];

		this.data = [
			{
				leftAddon: "A.",
				label: "As Builts",
			},
			{
				leftAddon: "B.",
				label: "O & M's",
			},
			{
				leftAddon: "C.",
				label: "Warranties",
			},
			{
				leftAddon: "D.",
				label: "Punchlist",
			},
			{
				leftAddon: "E.",
				label: "Warranty Period",
			},
			{
				leftAddon: "F.",
				label: "Customer Survey",
			},
			{
				leftAddon: "G.",
				label: "T.C.O.",
			},
			{
				leftAddon: "H.",
				label: "Lien Rights Expire",
			},
			{
				leftAddon: "I.",
				label: "100% Complete Billing",
			},
			{
				leftAddon: "J.",
				label: "Retention Billing",
			},
		];
	}
}

class SubSectionTemplateMFRCloseOut2 {
	constructor(contract = null) {
		this.template = "MFRCloseOut2";
		this.type = "labelValuePairs";
		this.width = "35%";
		this.columns = [
			{
				accessor: "label",
				textAlign: "right",
				width: "40%",
			},
			{
				accessor: "value",
				textAlign: "center",
				valueType: "date",
				width: "40%",
			},
			{
				accessor: "rightAddon",
				width: "20%",
			},
		];

		this.data = [
			{
				label: "Due Date",
				value: contract?.ddAsBuiltsDate,
			},
			{
				label: "Due Date",
				value: contract?.ddOMsDate,
			},
			{
				label: "Due Date",
				value: contract?.ddWarrantiesDate,
			},
			{
				label: "Due Date",
				value: contract?.ddPunchlistDate,
			},
			{
				label: "Starts",
				value: contract?.warrantyStartDate,
			},
			{
				label: "Due Date",
				value: contract?.ddCustomerSurveyDate,
			},
			{
				label: "Due Date",
				value: contract?.ddTCOsDate,
			},
			{
				label: "Due Date",
				value: contract?.ddLienRightsDate,
			},
			{
				label: "Due Date",
				value: contract?.ddCompleteBillingDate,
			},
			{
				label: "Due Date",
				value: contract?.ddRetentionBillingDate,
			},
		];
	}
}

class SubSectionTemplateMFRCloseOut3 {
	constructor(contract = null) {
		this.template = "MFRCloseOut3";
		this.type = "labelValuePairs";
		this.width = "35%";
		this.columns = [
			{
				accessor: "label",
				textAlign: "right",
				width: "40%",
			},
			{
				accessor: "value",
				textAlign: "center",
				valueType: "date",
				width: "40%",
			},
			{
				accessor: "rightAddon",
				width: "20%",
			},
		];
		this.data = [
			{
				label: "Actual Date",
				value: contract?.adAsBuiltsDate ?? null,
			},
			{
				label: "Actual Date",
				value: contract?.adOMsDate ?? null,
			},
			{
				label: "Actual Date",
				value: contract?.adWarrantiesDate ?? null,
			},
			{
				label: "Actual Date",
				value: contract?.adPunchlistDate ?? null,
			},
			{
				label: "Expires",
				value: contract?.warrantyExpirationDate ?? null,
			},
			{
				label: "Actual Date",
				value: contract?.adCustomerSurveyDate ?? null,
			},
			{
				label: "Actual Date",
				value: contract?.adTCOsDate ?? null,
			},
			{
				label: "Actual Date",
				value: contract?.adLienRightsDate ?? null,
			},
			{
				label: "Actual Date",
				value: contract?.adCompleteBillingDate ?? null,
			},
			{
				label: "Actual Date",
				value: contract?.adRetentionBillingDate ?? null,
			},
		];
	}
}
