import { formatDate } from "../../../helperFunctions";
import moment from "moment";

export default class ContractTotalsByMth {
	constructor(contract) {
		this.queryData = contract?.queryData ?? contract?.QueryData ?? "ContractTotalsByMth";
		this.jccmKeyID = contract?.jccmKeyID ?? contract?.JCCMKeyID ?? null;
		this.jcco = contract?.jcco ?? contract?.JCCo ?? null;
		this.contract = contract?.contract ?? contract?.Contract?.trim() ?? null;
		this.year = moment(
			new Date(formatDate(contract?.mth ?? contract?.Mth ?? contract?.thruMth ?? contract?.ThruMth) ?? null)
		).format("YYYY");
		this.mth = formatDate(contract?.mth ?? contract?.Mth ?? contract?.thruMth ?? contract?.ThruMth) ?? null;
		this.workDays = contract?.workDays ?? contract?.WorkDays ?? null;
		this.workHours = contract?.workHours ?? contract?.WorkDays * 8 ?? null;
		this.lastProjDate = formatDate(contract?.lastProjDate ?? contract?.LastProjDate) ?? null;
		this.pmName = contract?.pmName ?? contract?.PMName ?? "Unassigned";
		this.title = this.getTitle();
		this.subtitle = this.getSubtitle();

		this.origContractAmt = contract?.origContractAmt ?? contract?.OrigContractAmt ?? 0;
		this.adjOrigContractAmt = contract?.adjOrigContractAmt ?? contract?.AdjOrigContractAmt ?? 0;
		this.contractAmt = contract?.contractAmt ?? contract?.ContractAmt ?? 0;

		this.acoAmt = contract?.acoAmt ?? contract?.ACOAmt ?? 0;
		this.nonAmt = contract?.nonAmt ?? contract?.NonAmt ?? 0;

		this.pcoAmt = contract?.pcoAmt ?? contract?.PCOAmt ?? 0;
		this.pcoConv = contract?.pcoConv ?? contract?.PCOConv ?? 0;
		this.romAmt = contract?.romAmt ?? contract?.ROMAmt ?? 0;
		this.rmaAmt = contract?.rmaAmt ?? contract?.RMAAmt ?? 0;
		this.pmaAmt = contract?.pmaAmt ?? contract?.PMAAmt ?? 0;
		this.allowAmt = contract?.allowAmt ?? contract?.AllowAmt ?? 0;

		this.origEstHours = contract?.origEstHours ?? contract?.OrigEstHours ?? 0;
		this.currEstHours = contract?.currEstHours ?? contract?.CurrEstHours ?? 0;
		this.actualHours = contract?.actualHours ?? contract?.ActualHours ?? 0;
		this.projHours = contract?.projHours ?? contract?.ProjHours ?? 0;

		this.origEstCost = contract?.origEstCost ?? contract?.OrigEstCost ?? 0;
		this.currEstCost = contract?.currEstCost ?? contract?.CurrEstCost ?? 0;
		this.actualCost = contract?.actualCost ?? contract?.ActualCost ?? 0;
		this.projCost = contract?.projCost ?? contract?.ProjCost ?? 0;
		this.remainCmtdCost = contract?.remainCmtdCost ?? contract?.RemainCmtdCost ?? 0;

		this.pcoEstCost = contract?.pcoEstCost ?? contract?.PCOEstCost ?? 0;
		this.pcoActualCost = contract?.pcoActualCost ?? contract?.PCOActualCost ?? 0;

		this.billedAmt = contract?.billedAmt ?? contract?.BilledAmt ?? 0;
		this.receivedAmt = contract?.receivedAmt ?? contract?.ReceivedAmt ?? 0;

		this.retentionAmt = contract?.retentionAmt ?? contract?.RetentionAmt ?? 0;
		this.netCashFlow = contract?.netCashFlow ?? contract?.NetCashFlow ?? 0;
		this.mfrActualCost = contract?.mfrActualCost ?? contract?.MFRActualCost ?? 0;
		this.unapprovedAPAmt = contract?.unapprovedAPAmt ?? contract?.UnapprovedAPAmt ?? 0;

		this.costToDatePct = this.getPctCompleteCosts() ?? 0;
		this.billedToDatePct = this.getBilledToDatePct() ?? 0;
		this.retentionPct = this.getRetentionPct() ?? 0;

		this.actualLaborRate = contract?.actualLaborRate ?? this.getActualLaborRate();
		this.totalLaborActualCost = contract?.totalLaborActualCost ?? this.getTotalLaborActualCost();

		this.billedVsCost = contract?.billedVsCost ?? this.getBilledVsCost();
		this.receivedVsCost = contract?.receivedVsCost ?? this.getReceivedVsCost();

		// PROFIT % AND MARGIN %
		this.pcoCostToComplete = contract?.pcoCostToComplete ?? this.getPCOCostToComplete();
		this.currEstCostPCOs = contract.currEstCostWithPCOs ?? this.getCurrEstCostWithPCOs();
		this.actualCostWithPCOs = contract.actualCostWithPCOs ?? this.getActualCostWithPCOs();
		this.projCostWithPCOs = contract.projCostWithPCOs ?? this.getProjCostWithPCOs();

		this.bidContractAmt = contract?.bidContractAmt ?? this.getBidContractAmt();
		this.projContractAmt = contract?.projContractAmt ?? this.getProjContractAmt();
		this.bidProfitAmt = contract?.bidProfitAmt ?? this.getBidProfitAmt();
		this.bidMarginPct = contract?.bidMarginPct ?? this?.getBidMarginPct();
		this.currProfitAmt = contract?.currProfitAmt ?? this.getCurrProfitAmt();
		this.currMarginPct = contract?.currMarginPct ?? this.getCurrMarginPct();
		this.projProfitAmt = contract?.projProfitAmt ?? this.getProjProfitAmt();
		this.projMarginPct = contract?.projMarginPct ?? this.getProjMarginPct();
		this.varProfitAmt = contract?.varProfitAmt ?? this?.getVarProfitAmt();
		this.varMarginPct = contract?.varMarginPct ?? this.getVarMarginPct();

		this.laborOrigEstCost = contract?.laborOrigEstCost ?? contract?.LaborOrigEstCost ?? 0;
		this.laborCurrEstCost = contract?.laborCurrEstCost ?? contract?.LaborCurrEstCost ?? 0;
		this.laborActualCost = contract?.laborActualCost ?? contract?.LaborActualCost ?? 0;
		this.laborProjCost = contract?.laborProjCost ?? contract?.LaborProjCost ?? 0;

		this.subLabOrigEstCost = contract?.subLabOrigEstCost ?? contract?.SubLabOrigEstCost ?? 0;
		this.subLabCurrEstCost = contract?.subLabCurrEstCost ?? contract?.SubLabCurrEstCost ?? 0;
		this.subLabActualCost = contract?.subLabActualCost ?? contract?.SubLabActualCost ?? 0;
		this.subLabProjCost = contract?.subLabProjCost ?? contract?.SubLabProjCost ?? 0;

		this.matOrigEstCost = contract?.matOrigEstCost ?? contract?.MatOrigEstCost ?? 0;
		this.matCurrEstCost = contract?.matCurrEstCost ?? contract?.MatCurrEstCost ?? 0;
		this.matActualCost = contract?.matActualCost ?? contract?.MatActualCost ?? 0;
		this.matProjCost = contract?.matProjCost ?? contract?.MatProjCost ?? 0;

		this.subOrigEstCost = contract?.subOrigEstCost ?? contract?.SubOrigEstCost ?? 0;
		this.subCurrEstCost = contract?.subCurrEstCost ?? contract?.SubCurrEstCost ?? 0;
		this.subActualCost = contract?.subActualCost ?? contract?.SubActualCost ?? 0;
		this.subProjCost = contract?.subProjCost ?? contract?.SubProjCost ?? 0;

		this.equipOrigEstCost = contract?.equipOrigEstCost ?? contract?.EquipOrigEstCost ?? 0;
		this.equipCurrEstCost = contract?.equipCurrEstCost ?? contract?.EquipCurrEstCost ?? 0;
		this.equipActualCost = contract?.equipActualCost ?? contract?.EquipActualCost ?? 0;
		this.equipProjCost = contract?.equipProjCost ?? contract?.EquipProjCost ?? 0;

		this.otherJobOrigEstCost = contract?.otherJobOrigEstCost ?? contract?.OtherJobOrigEstCost ?? 0;
		this.otherJobCurrEstCost = contract?.otherJobCurrEstCost ?? contract?.OtherJobCurrEstCost ?? 0;
		this.otherJobActualCost = contract?.otherJobActualCost ?? contract?.OtherJobActualCost ?? 0;
		this.otherJobProjCost = contract?.otherJobProjCost ?? contract?.OtherJobProjCost ?? 0;

		this.contOrigEstCost = contract?.contOrigEstCost ?? contract?.ContOrigEstCost ?? 0;
		this.contCurrEstCost = contract?.contCurrEstCost ?? contract?.ContCurrEstCost ?? 0;
		this.contActualCost = contract?.contActualCost ?? contract?.ContActualCost ?? 0;
		this.contProjCost = contract?.contProjCost ?? contract?.ContProjCost ?? 0;

		this.actualHours_delta = contract?.actualHours_delta ?? contract?.ActualHours_DELTA ?? 0;

		this.actualHoursOFFC = contract?.actualHoursOFFC ?? contract?.ActualHoursOFFC ?? 0;
		this.actualHoursDETL = contract?.actualHoursDETL ?? contract?.ActualHoursDETL ?? 0;
		this.actualHoursPLUM = contract?.actualHoursPLUM ?? contract?.ActualHoursPLUM ?? 0;
		this.actualHoursHVAC = contract?.actualHoursHVAC ?? contract?.ActualHoursHVAC ?? 0;
		this.actualHoursPIPE = contract?.actualHoursPIPE ?? contract?.ActualHoursPIPE ?? 0;
		this.actualHoursFUEL = contract?.actualHoursFUEL ?? contract?.ActualHoursFUEL ?? 0;
		this.actualHoursPROC = contract?.actualHoursPROC ?? contract?.ActualHoursPROC ?? 0;
		this.actualHoursFIRE = contract?.actualHoursFIRE ?? contract?.ActualHoursFIRE ?? 0;
		this.actualHoursSITE = contract?.actualHoursSITE ?? contract?.ActualHoursSITE ?? 0;
		this.actualHoursSTRT = contract?.actualHoursSTRT ?? contract?.ActualHoursSTRT ?? 0;
		this.actualHoursFABR = contract?.actualHoursFABR ?? contract?.ActualHoursFABR ?? 0;
		this.actualHoursCHNG = contract?.actualHoursCHNG ?? contract?.ActualHoursCHNG ?? 0;

		this.projHoursOFFC = contract?.projHoursOFFC ?? contract?.ProjHoursOFFC ?? 0;
		this.projHoursDETL = contract?.projHoursDETL ?? contract?.ProjHoursDETL ?? 0;
		this.projHoursPLUM = contract?.projHoursPLUM ?? contract?.ProjHoursPLUM ?? 0;
		this.projHoursHVAC = contract?.projHoursHVAC ?? contract?.ProjHoursHVAC ?? 0;
		this.projHoursPIPE = contract?.projHoursPIPE ?? contract?.ProjHoursPIPE ?? 0;
		this.projHoursFUEL = contract?.projHoursFUEL ?? contract?.ProjHoursFUEL ?? 0;
		this.projHoursPROC = contract?.projHoursPROC ?? contract?.ProjHoursPROC ?? 0;
		this.projHoursFIRE = contract?.projHoursFIRE ?? contract?.ProjHoursFIRE ?? 0;
		this.projHoursSITE = contract?.projHoursSITE ?? contract?.ProjHoursSITE ?? 0;
		this.projHoursSTRT = contract?.projHoursSTRT ?? contract?.ProjHoursSTRT ?? 0;
		this.projHoursFABR = contract?.projHoursFABR ?? contract?.ProjHoursFABR ?? 0;
		this.projHoursCHNG = contract?.projHoursCHNG ?? contract?.ProjHoursCHNG ?? 0;

		this.origEstCost_delta = contract?.origEstCost_delta ?? contract?.OrigEstCost_DELTA ?? 0;
		this.currEstCost_delta = contract?.currEstCost_delta ?? contract?.CurrEstCost_DELTA ?? 0;
		this.actualCost_delta = contract?.actualCost_delta ?? contract?.ActualCost_DELTA ?? 0;
		this.projCost_delta = contract?.projCost_delta ?? contract?.ProjCost_DELTA ?? 0;
		this.remainCmtdCost_delta = contract?.remainCmtdCost_delta ?? contract?.RemainCmtdCost_DELTA ?? 0;

		this.billedAmt_delta = contract?.billedAmt_delta ?? contract?.BilledAmt_DELTA ?? 0;
		this.receivedAmt_delta = contract?.receivedAmt_delta ?? contract?.ReceivedAmt_DELTA ?? 0;

		this.laborOrigEstCost_delta = contract?.laborOrigEstCost_delta ?? contract?.LaborOrigEstCost_DELTA ?? 0;
		this.laborCurrEstCost_delta = contract?.laborCurrEstCost_delta ?? contract?.LaborCurrEstCost_DELTA ?? 0;
		this.laborActualCost_delta = contract?.laborActualCost_delta ?? contract?.LaborActualCost_DELTA ?? 0;
		this.laborProjCost_delta = contract?.laborProjCost_delta ?? contract?.LaborProjCost_DELTA ?? 0;

		this.subLabOrigEstCost_delta = contract?.subLabOrigEstCost_delta ?? contract?.SubLabOrigEstCost_DELTA ?? 0;
		this.subLabCurrEstCost_delta = contract?.subLabCurrEstCost_delta ?? contract?.SubLabCurrEstCost_DELTA ?? 0;
		this.subLabActualCost_delta = contract?.subLabActualCost_delta ?? contract?.SubLabActualCost_DELTA ?? 0;
		this.subLabProjCost_delta = contract?.subLabProjCost_delta ?? contract?.SubLabProjCost_DELTA ?? 0;

		this.matActualCost_delta = contract?.matActualCost_delta ?? contract?.MatActualCost_DELTA ?? 0;
		this.matProjCost_delta = contract?.matProjCost_delta ?? contract?.MatProjCost_DELTA ?? 0;
		this.subActualCost_delta = contract?.subActualCost_delta ?? contract?.SubActualCost_DELTA ?? 0;
		this.subProjCost_delta = contract?.subProjCost_delta ?? contract?.SubProjCost_DELTA ?? 0;
		this.equipActualCost_delta = contract?.equipActualCost_delta ?? contract?.EquipActualCost_DELTA ?? 0;
		this.equipProjCost_delta = contract?.equipProjCost_delta ?? contract?.EquipProjCost_DELTA ?? 0;
		this.otherJobActualCost_delta = contract?.otherJobActualCost_delta ?? contract?.OtherJobActualCost_DELTA ?? 0;
		this.otherJobProjCost_delta = contract?.otherJobProjCost_delta ?? contract?.OtherJobProjCost_DELTA ?? 0;
		this.contActualCost_delta = contract?.contActualCost_delta ?? contract?.ContActualCost_DELTA ?? 0;
		this.contProjCost_delta = contract?.contProjCost_delta ?? contract?.ContProjCost_DELTA ?? 0;

		this.actualHoursOFFC_delta = contract?.actualHoursOFFC_delta ?? contract?.ActualHoursOFFC_DELTA ?? 0;
		this.actualHoursDETL_delta = contract?.actualHoursDETL_delta ?? contract?.ActualHoursDETL_DELTA ?? 0;
		this.actualHoursPLUM_delta = contract?.actualHoursPLUM_delta ?? contract?.ActualHoursPLUM_DELTA ?? 0;
		this.actualHoursHVAC_delta = contract?.actualHoursHVAC_delta ?? contract?.ActualHoursHVAC_DELTA ?? 0;
		this.actualHoursPIPE_delta = contract?.actualHoursPIPE_delta ?? contract?.ActualHoursPIPE_DELTA ?? 0;
		this.actualHoursFUEL_delta = contract?.actualHoursFUEL_delta ?? contract?.ActualHoursFUEL_DELTA ?? 0;
		this.actualHoursPROC_delta = contract?.actualHoursPROC_delta ?? contract?.ActualHoursPROC_DELTA ?? 0;
		this.actualHoursFIRE_delta = contract?.actualHoursFIRE_delta ?? contract?.ActualHoursFIRE_DELTA ?? 0;
		this.actualHoursSITE_delta = contract?.actualHoursSITE_delta ?? contract?.ActualHoursSITE_DELTA ?? 0;
		this.actualHoursSTRT_delta = contract?.actualHoursSTRT_delta ?? contract?.ActualHoursSTRT_DELTA ?? 0;
		this.actualHoursFABR_delta = contract?.actualHoursFABR_delta ?? contract?.ActualHoursFABR_DELTA ?? 0;
		this.actualHoursCHNG_delta = contract?.actualHoursCHNG_delta ?? contract?.ActualHoursCHNG_DELTA ?? 0;

		this.actualLaborRate_delta = contract?.actualLaborRate_delta ?? this.getActualLaborRate_delta();
		this.totalLaborActualCost_delta = contract?.totalLaborActualCost_delta ?? this.getTotalLaborActualCost_delta();
		this.billedVsCost_delta = contract?.billedVsCost_delta ?? this.getBilledVsCost_delta();

		this.actualCrewSizeOFFC_delta = contract?.actualCrewSizeOFFC_delta ?? this.getActualCrewSizeOFFC_delta();
		this.actualCrewSizeDETL_delta = contract?.actualCrewSizeDETL_delta ?? this.getActualCrewSizeDETL_delta();
		this.actualCrewSizePLUM_delta = contract?.actualCrewSizePLUM_delta ?? this.getActualCrewSizePLUM_delta();
		this.actualCrewSizeHVAC_delta = contract?.actualCrewSizeHVAC_delta ?? this.getActualCrewSizeHVAC_delta();
		this.actualCrewSizePIPE_delta = contract?.actualCrewSizePIPE_delta ?? this.getActualCrewSizePIPE_delta();
		this.actualCrewSizeFUEL_delta = contract?.actualCrewSizeFUEL_delta ?? this.getActualCrewSizeFUEL_delta();
		this.actualCrewSizePROC_delta = contract?.actualCrewSizePROC_delta ?? this.getActualCrewSizePROC_delta();
		this.actualCrewSizeFIRE_delta = contract?.actualCrewSizeFIRE_delta ?? this.getActualCrewSizeFIRE_delta();
		this.actualCrewSizeSITE_delta = contract?.actualCrewSizeSITE_delta ?? this.getActualCrewSizeSITE_delta();
		this.actualCrewSizeSTRT_delta = contract?.actualCrewSizeSTRT_delta ?? this.getActualCrewSizeSTRT_delta();
		this.actualCrewSizeFABR_delta = contract?.actualCrewSizeFABR_delta ?? this.getActualCrewSizeFABR_delta();
		this.actualCrewSizeCHNG_delta = contract?.actualCrewSizeCHNG_delta ?? this.getActualCrewSizeCHNG_delta();

		this.actualCrewSizeFIELD_delta = contract?.actualCrewSizeFIELD_delta ?? this.getActualCrewSizeFIELD_delta();
		this.actualCrewSizeTOTAL_delta = contract?.actualCrewSizeTOTAL_delta ?? this.getActualCrewSizeTOTAL_delta();
	}

	updateContractTotals(contract = null) {
		if (
			Boolean(contract?.accessYN === "Y") &&
			moment(new Date(this.mth)).format("MMM YYY") === moment(new Date()).format("MMM YYY")
		) {
			this.origContractAmt = contract?.origContractAmt;
			this.adjOrigContractAmt = contract?.adjOrigContractAmt;
			this.contractAmt = contract?.contractAmt;
			this.acoAmt = contract?.acoAmt;
			this.nonAmt = contract?.nonAmt;
			this.pcoAmt = contract?.pcoAmt;
			this.pcoConv = contract?.pcoConv;
			this.romAmt = contract?.romAmt;
			this.rmaAmt = contract?.rmaAmt;
			this.pmaAmt = contract?.pmaAmt;
			this.allowAmt = contract?.allowAmt;
			this.pcoEstCost = contract?.pcoEstCost;
			this.pcoActualCost = contract?.pcoActualCost;
			this.pcoCostToComplete = this.getPCOCostToComplete();
			this.currEstCostPCOs = this.getCurrEstCostWithPCOs();
			this.actualCostWithPCOs = this.getActualCostWithPCOs();
			this.projCostWithPCOs = this.getProjCostWithPCOs();
			this.bidContractAmt = this.getBidContractAmt();
			this.projContractAmt = this.getProjContractAmt();
			this.bidProfitAmt = this.getBidProfitAmt();
			this.bidMarginPct = this?.getBidMarginPct();
			this.currProfitAmt = this.getCurrProfitAmt();
			this.currMarginPct = this.getCurrMarginPct();
			this.projProfitAmt = this.getProjProfitAmt();
			this.projMarginPct = this.getProjMarginPct();
			this.varProfitAmt = this?.getVarProfitAmt();
			this.varMarginPct = this.getVarMarginPct();
			this.costToDatePct = this.getPctCompleteCosts();
			this.billedToDatePct = this.getBilledToDatePct();
			this.retentionPct = this.getRetentionPct();
			this.actualLaborRate = this.getActualLaborRate();
			this.totalLaborActualCost = this.getTotalLaborActualCost();
			this.billedVsCost = this.getBilledVsCost();
			this.receivedVsCost = this.getReceivedVsCost();
			this.actualCrewSizeOFFC_delta = this.getActualCrewSizeOFFC_delta();
			this.actualCrewSizeDETL_delta = this.getActualCrewSizeDETL_delta();
			this.actualCrewSizePLUM_delta = this.getActualCrewSizePLUM_delta();
			this.actualCrewSizeHVAC_delta = this.getActualCrewSizeHVAC_delta();
			this.actualCrewSizePIPE_delta = this.getActualCrewSizePIPE_delta();
			this.actualCrewSizeFUEL_delta = this.getActualCrewSizeFUEL_delta();
			this.actualCrewSizePROC_delta = this.getActualCrewSizePROC_delta();
			this.actualCrewSizeFIRE_delta = this.getActualCrewSizeFIRE_delta();
			this.actualCrewSizeSITE_delta = this.getActualCrewSizeSITE_delta();
			this.actualCrewSizeSTRT_delta = this.getActualCrewSizeSTRT_delta();
			this.actualCrewSizeFABR_delta = this.getActualCrewSizeFABR_delta();
			this.actualCrewSizeCHNG_delta = this.getActualCrewSizeCHNG_delta();
			this.actualCrewSizeFIELD_delta = this.getActualCrewSizeFIELD_delta();
			this.actualCrewSizeTOTAL_delta = this.getActualCrewSizeTOTAL_delta();
		}
	}

	getTitle() {
		let title = moment(new Date(this.mth)).format("MMMM YYYY");
		return title;
	}

	getSubtitle() {
		let subtitle = "";
		if (!Boolean(this.jccmKeyID)) {
			subtitle = "N/A - No Project";
		} else if (moment(new Date()).format("MM/YYYY") === moment(new Date(this.mth)).format("MM/YYYY")) {
			subtitle = "Live Data as of " + moment(new Date()).format("M/D/YYYY");
		} else if (Boolean(this.lastProjDate)) {
			subtitle = "Projection Posted on " + moment(new Date(this.lastProjDate)).format("M/D/YYYY");
		} else {
			subtitle = "No Projection - Data thru " + moment(new Date(this.mth)).endOf("month").format("M/D/YYYY");
		}

		return subtitle;
	}

	/*****************************************************************/
	/******************* GENERAL MATH FORMULAS *********************/
	/**************************************************************/

	// GENERAL MATH FUNCTIONS
	getPct(numerator = 0, denominator = 0) {
		let pct = 0;
		if (numerator === null || denominator === null || isNaN(numerator) || isNaN(denominator)) {
			pct = null;
		} else if (parseFloat(denominator) !== 0) {
			pct = (parseFloat(numerator ?? 0) / parseFloat(denominator)) * 100;
		}
		return pct;
	}

	getRate(dollars = 0, hours = 0) {
		let rate = 0;
		if (dollars === null || hours === null || isNaN(dollars) || isNaN(hours)) {
			rate = null;
		} else if (parseFloat(hours) !== 0) {
			rate = parseFloat(dollars ?? 0) / parseFloat(hours);
		}
		return rate;
	}

	/*****************************************************************/
	/*************** TOTAL CONTRACT AMOUNTS ($) *********************/
	/************ (CONTRACT AMOUNT + ADJUSTMENT AMOUNTS) **********/
	/*************************************************************/

	getBidContractAmt() {
		return parseFloat(this.origContractAmt ?? 0) + parseFloat(this.adjOrigContractAmt ?? 0);
	}

	getProjContractAmt() {
		return (
			parseFloat(this.contractAmt ?? 0) +
			parseFloat(this.pcoConv ?? 0) +
			parseFloat(this.pmaAmt ?? 0) +
			parseFloat(this.allowAmt ?? 0)
		);
	}

	/*****************************************************************/
	/*************** TOTAL CONTRACT PROFIT ($) *********************/
	/************ (CONTRACT AMOUNT - PROFIT AMOUNT) ***************/
	/*************************************************************/

	getBidProfitAmt() {
		return this.getBidContractAmt() - parseFloat(this.origEstCost ?? 0);
	}

	getCurrProfitAmt() {
		return parseFloat(this.contractAmt ?? 0) - this.getActualCostWithPCOs();
	}

	getProjProfitAmt() {
		return this.getProjContractAmt() - this.getProjCostWithPCOs();
	}

	getVarProfitAmt() {
		return this.getProjProfitAmt() - this.getBidProfitAmt();
	}

	/*****************************************************************/
	/*************** TOTAL PERCENT MARGIN (%) **********************/
	/************ (PROFIT AMOUNT / CONTRACT AMOUNT) ***************/
	/*************************************************************/

	getBidMarginPct() {
		let bidProfitAmt = this?.getBidProfitAmt() ?? 0;
		let bidContractAmt = this?.getBidContractAmt() ?? 0;
		let bidMarginPct = this.getPct(bidProfitAmt, bidContractAmt);
		return bidMarginPct;
	}

	getCurrMarginPct() {
		return this.getPct(this?.getCurrProfitAmt(), parseFloat(this?.contractAmt ?? 0));
	}

	getProjMarginPct() {
		return this.getPct(this.getProjProfitAmt(), this.getProjContractAmt());
	}

	getVarMarginPct() {
		return this.getProjMarginPct() - this.getBidMarginPct();
	}

	/*****************************************************************/
	/************** COST TO DATE TOTALS W/ PCOS *********************/
	/*********** (COSTS TO DATE + PCO COSTS TO DATE) **************/
	/*************************************************************/

	getActualCostWithPCOs() {
		return parseFloat(this.actualCost ?? 0) + parseFloat(this.pcoActualCost ?? 0);
	}

	getCurrEstCostWithPCOs() {
		return parseFloat(this.currEstCost ?? 0) + parseFloat(this.pcoEstCost ?? 0);
	}

	getProjCostWithPCOs() {
		return parseFloat(this.projCost ?? 0) + this.getPCOCostToComplete();
	}

	getCostToCompleteWithPCOs() {
		return this.getProjCostWithPCOs() - parseFloat(this.actualCost ?? 0);
	}

	getOverUnderBudgetWithPCOs() {
		return this.getProjCostWithPCOs() - this.getCurrEstCostWithPCOs();
	}

	/*****************************************************************/
	/********* PERCENT BILLED AND PERCENT COMPLETE % ****************/
	/******** (TOTALS TO DATE / TOTALS AT COMPLETION) **************/
	/*************************************************************/

	getBilledVsCost() {
		let billedVsCost = parseFloat(this.billedAmt ?? 0) - parseFloat(this.actualCost ?? 0); //this.getactualCostWithPCOs() JARED?
		return billedVsCost;
	}

	getTotalLaborActualCost() {
		return parseFloat(this.laborActualCost ?? 0) + parseFloat(this.subLabActualCost ?? 0);
	}

	getTotalOtherJobActualCost() {
		return parseFloat(this.otherJobActualCost ?? 0) + parseFloat(this.contActualCost ?? 0);
	}

	getActualLaborRate() {
		return this.getRate(this.getTotalLaborActualCost(), this.actualHours);
	}

	getReceivedVsCost() {
		let receivedVsCost = parseFloat(this.receivedAmt ?? 0) - parseFloat(this.actualCost ?? 0); //this.getactualCostWithPCOs() JARED?
		return receivedVsCost;
	}

	getCurrBillingBal() {
		return parseFloat(this.contractAmt ?? 0) - parseFloat(this.billedAmt ?? 0);
	}

	getProjBillingBal() {
		return this.getProjContractAmt() - parseFloat(this.billedAmt ?? 0); //this.getactualCostWithPCOs() JARED?
	}

	getProjBilledVsCost() {
		return this.getProjBillingBal() - this.getCostToCompleteWithPCOs(); //this.getactualCostWithPCOs() JARED?
	}

	getBilledToDatePct() {
		return this.getPct(parseFloat(this.billedAmt ?? 0), parseFloat(this.contractAmt ?? 0));
	}

	getProjBilledPct() {
		return this.getPct(parseFloat(this.billedAmt ?? 0), this.getProjContractAmt());
	}

	getRetentionPct() {
		return this.getPct(parseFloat(this.retentionAmt ?? 0), parseFloat(this.billedAmt ?? 0));
	}

	getPctCompleteHours() {
		return this.getPct(parseFloat(this.actualHours ?? 0), parseFloat(this.projHours ?? 0));
	}

	getPctCompleteCosts() {
		return this.getPct(parseFloat(this.actualCost ?? 0), this.getProjCostWithPCOs()); //this.getactualCostWithPCOs() JARED?
	}

	/****************************************************************/
	/************** COMPOSITE LABOR RATES $ / HR *******************/
	/************ (DOLLARS TO DATE / HOURS TO DATE) ***************/
	/*************************************************************/

	getOrigEstRate() {
		return this.getRate(parseFloat(this.laborOrigEstCost ?? 0), parseFloat(this.origEstHours ?? 0));
	}

	getCurrEstRate() {
		return this.getRate(parseFloat(this.laborCurrEstCost ?? 0), parseFloat(this.currEstHours ?? 0));
	}

	getActualRate() {
		return this.getRate(
			parseFloat(this.laborActualCost ?? 0) + parseFloat(this.subLabActualCost ?? 0),
			parseFloat(this.actualHours ?? 0)
		);
	}

	getProjRate() {
		return this.getRate(
			parseFloat(this.laborProjCost ?? 0) + parseFloat(this.subLabProjCost ?? 0),
			parseFloat(this.projHours ?? 0)
		);
	}

	getRateToComplete() {
		return this.getRate(this.getLaborCostToComplete() + this.getSubLabCostToComplete(), this.getHoursToComplete());
	}

	getVarOnRate() {
		return this.getRateToComplete() - this.getActualRate();
	}

	getVarOnRateAmt() {
		return this.getVarOnRate() * this.getHoursToComplete();
	}

	/**************************************************************/
	/************* COSTS TO COMPLETE BY COST TYPE ***************/
	/**************** (PROJECTED - ACTUALS) *********************/
	/***********************************************************/

	getHoursToComplete() {
		return parseFloat(this.projHours ?? 0) - parseFloat(this.actualHours ?? 0);
	}

	getLaborCostToComplete() {
		return parseFloat(this.laborProjCost ?? 0) - parseFloat(this.laborActualCost ?? 0);
	}

	getSubLabCostToComplete() {
		return parseFloat(this.subLabProjCost ?? 0) - parseFloat(this.subLabActualCost ?? 0);
	}

	getMatCostToComplete() {
		return parseFloat(this.matProjCost ?? 0) - parseFloat(this.matActualCost ?? 0);
	}

	getSubCostToComplete() {
		return parseFloat(this.subProjCost ?? 0) - parseFloat(this.subActualCost ?? 0);
	}

	getEquipCostToComplete() {
		return parseFloat(this.equipProjCost ?? 0) - parseFloat(this.equipActualCost ?? 0);
	}

	getOtherJobCostToComplete() {
		return parseFloat(this.otherJobProjCost ?? 0) - parseFloat(this.otherJobActualCost ?? 0);
	}

	getContCostToComplete() {
		return parseFloat(this.contProjCost ?? 0) - parseFloat(this.contActualCost ?? 0);
	}

	getPCOCostToComplete() {
		return parseFloat(this.pcoEstCost ?? 0) - parseFloat(this.pcoActualCost ?? 0);
	}

	/*****************************************************************/
	/*************** OVER / UNDER BUDGET BY COST TYPE ***************/
	/**************** (PROJECTED - CURR EST COST) ******************/
	/**************************************************************/

	getLaborOverUnderBudget() {
		return parseFloat(this.laborProjCost ?? 0) - parseFloat(this.laborCurrEstCost ?? 0);
	}

	getSubLabOverUnderBudget() {
		return parseFloat(this.subLabProjCost ?? 0) - parseFloat(this.subLabCurrEstCost ?? 0);
	}

	getMatOverUnderBudget() {
		return parseFloat(this.matProjCost ?? 0) - parseFloat(this.matCurrEstCost ?? 0);
	}

	getSubOverUnderBudget() {
		return parseFloat(this.subProjCost ?? 0) - parseFloat(this.subCurrEstCost ?? 0);
	}

	getEquipOverUnderBudget() {
		return parseFloat(this.equipProjCost ?? 0) - parseFloat(this.equipCurrEstCost ?? 0);
	}

	getOtherJobOverUnderBudget() {
		return parseFloat(this.otherJobProjCost ?? 0) - parseFloat(this.otherJobCurrEstCost ?? 0);
	}

	getContOverUnderBudget() {
		return parseFloat(this.contProjCost ?? 0) - parseFloat(this.contCurrEstCost ?? 0);
	}

	getPCOOverUnderBudget() {
		return this.getPCOCostToComplete() - parseFloat(this.pcoEstCost ?? 0);
	}

	/**************************************************************/
	/************* TOTALS COSTS BY SCOPE OF WORK *****************/
	/**************** (PROJECTED - ACTUALS) *********************/
	/***********************************************************/

	/**************************************************************/
	/************* TOTALS COSTS BY SCOPE OF WORK *****************/
	/**************** (PROJECTED - ACTUALS) *********************/
	/***********************************************************/

	getActualCrewSizeOFFC_delta() {
		return this.getRate(parseFloat(this.actualHoursOFFC_delta ?? 0), parseFloat(this.workDays ?? 0) * 8);
	}

	getActualCrewSizeDETL_delta() {
		return this.getRate(parseFloat(this.actualHoursDETL_delta ?? 0), parseFloat(this.workDays ?? 0) * 8);
	}

	getActualCrewSizePLUM_delta() {
		return this.getRate(parseFloat(this.actualHoursPLUM_delta ?? 0), parseFloat(this.workDays ?? 0) * 8);
	}

	getActualCrewSizeHVAC_delta() {
		return this.getRate(parseFloat(this.actualHoursHVAC_delta ?? 0), parseFloat(this.workDays ?? 0) * 8);
	}

	getActualCrewSizePIPE_delta() {
		return this.getRate(parseFloat(this.actualHoursPIPE_delta ?? 0), parseFloat(this.workDays ?? 0) * 8);
	}

	getActualCrewSizeFUEL_delta() {
		return this.getRate(parseFloat(this.actualHoursFUEL_delta ?? 0), parseFloat(this.workDays ?? 0) * 8);
	}

	getActualCrewSizeFIRE_delta() {
		return this.getRate(parseFloat(this.actualHoursFIRE_delta ?? 0), parseFloat(this.workDays ?? 0) * 8);
	}

	getActualCrewSizePROC_delta() {
		return this.getRate(parseFloat(this.actualHoursPROC_delta ?? 0), parseFloat(this.workDays ?? 0) * 8);
	}

	getActualCrewSizeSITE_delta() {
		return this.getRate(parseFloat(this.actualHoursSITE_delta ?? 0), parseFloat(this.workDays ?? 0) * 8);
	}

	getActualCrewSizeSTRT_delta() {
		return this.getRate(parseFloat(this.actualHoursSTRT_delta ?? 0), parseFloat(this.workDays ?? 0) * 8);
	}

	getActualCrewSizeFABR_delta() {
		return this.getRate(parseFloat(this.actualHoursFABR_delta ?? 0), parseFloat(this.workDays ?? 0) * 8);
	}

	getActualCrewSizeCHNG_delta() {
		return this.getRate(parseFloat(this.actualHoursCHNG_delta ?? 0), parseFloat(this.workDays ?? 0) * 8);
	}

	getActualCrewSizeFIELD_delta() {
		return (
			this.getActualCrewSizePLUM_delta() +
			this.getActualCrewSizeHVAC_delta() +
			this.getActualCrewSizePIPE_delta() +
			this.getActualCrewSizeFUEL_delta() +
			this.getActualCrewSizePROC_delta() +
			this.getActualCrewSizeFIRE_delta() +
			this.getActualCrewSizeSITE_delta() +
			this.getActualCrewSizeSTRT_delta() +
			this.getActualCrewSizeCHNG_delta()
		);
	}

	getActualCrewSizeTOTAL_delta() {
		return (
			this.getActualCrewSizeOFFC_delta() +
			this.getActualCrewSizeDETL_delta() +
			this.getActualCrewSizePLUM_delta() +
			this.getActualCrewSizeHVAC_delta() +
			this.getActualCrewSizePIPE_delta() +
			this.getActualCrewSizeFUEL_delta() +
			this.getActualCrewSizeFIRE_delta() +
			this.getActualCrewSizePROC_delta() +
			this.getActualCrewSizeSITE_delta() +
			this.getActualCrewSizeSTRT_delta() +
			this.getActualCrewSizeFABR_delta() +
			this.getActualCrewSizeCHNG_delta()
		);
	}

	getTotalLaborActualCost_delta() {
		return parseFloat(this.laborActualCost_delta ?? 0) + parseFloat(this.subLabActualCost_delta ?? 0);
	}

	getActualLaborRate_delta() {
		return this.getRate(this.getTotalLaborActualCost_delta(), this.actualHours_delta);
	}

	getBilledVsCost_delta() {
		return parseFloat(this.billedAmt_delta ?? 0) - parseFloat(this.actualCost_delta ?? 0);
	}

	/****************************************************************/
	/************** ORIG CONTRACT ADJUSTMENT ***********************/
	/************* (UPDATE BID CONTRACT AMOUNT ) *****************/
	/*************************************************************/
	updateAdjOrigContractAmt(adjOrigContractAmt) {
		this.adjOrigContractAmt = adjOrigContractAmt ?? 0;
	}
}
