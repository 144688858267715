import React, { useMemo } from "react";
import {
	Box,
	Heading,
	Button,
	useBoolean,
	Center,
	Stack,
	StackDivider,
	VStack,
	Text,
	Badge,
	Collapse,
} from "@chakra-ui/react";
import { convertArrayToMap } from "../../../helperFunctions";
import HOLIDAYS from "../constants/Holidays";

export default function CompanyHolidaysCard({ year }) {
	const [collapseIsOpen, setCollapseIsOpen] = useBoolean();

	const companyHolidays = useMemo(() => {
		let companyHolidays = convertArrayToMap(HOLIDAYS, "year");
		companyHolidays = companyHolidays[year]?.holidays ?? [];

		for (let i = 0; i < companyHolidays?.length; i++) {
			for (let j = 0; j < companyHolidays[i]?.days?.length; j++) {
				let today = new Date();
				let date = new Date(companyHolidays[i]?.days[j]?.date);

				if (today.getTime() < date.getTime()) {
					Object.assign(companyHolidays[i]?.days[j], { isUpcoming: true });
					return companyHolidays;
				}
			}
		}
		return companyHolidays;
	}, [year]);

	return (
		<>
			<Collapse in={collapseIsOpen}>
				<Box>
					<Heading px={2} py={1} fontSize="md" variant="title" color="gray.400" textTransform="uppercase">
						{"Company Holidays in " + year}
					</Heading>
					<Box p={2} bg="white" rounded="10" border="1px" borderColor="blackAlpha.200">
						<Stack
							w="full"
							direction={{ base: "column", lg: "row" }}
							spacing="6"
							py="4"
							divider={<StackDivider />}
							overflow="auto"
						>
							{companyHolidays?.map((month, i) => (
								<CompanyHolidayRow key={i} month={month} />
							))}
						</Stack>
					</Box>
				</Box>
			</Collapse>

			<Center py={2}>
				<Button
					variant="outline"
					onClick={setCollapseIsOpen.toggle}
					color="gray.500"
					px={2}
					size="md"
					bg="whiteAlpha.700"
					textTransform="uppercase"
					shadow="md"
					_hover={{
						color: "teal.500",
						fontWeight: "bold",
						bg: "whiteAlpha.700",
						borderColor: "teal.500",
						borderWidth: 2,
					}}
				>
					{(collapseIsOpen ? "Hide" : "Show") + " Holidays in " + year}
				</Button>
			</Center>
		</>
	);
}

function CompanyHolidayRow({ month }) {
	return (
		<VStack flex={1} spacing="4">
			<Heading w="full" textAlign="center" as="h5" size="sm" color="gray.400" variant="title">
				{month?.month}
			</Heading>
			<VStack color="gray.500" spacing="1" w="full">
				{month?.days?.map((day, i) => (
					<Box key={i} textAlign="center">
						{day?.isUpcoming && (
							<Badge colorScheme="green" my="1" color="green" fontSize="md">
								Upcoming
							</Badge>
						)}
						<Text isTruncated fontWeight="semibold">
							{day?.name}
						</Text>
						<Text>Office Closed {day?.day}</Text>
					</Box>
				))}
			</VStack>
		</VStack>
	);
}
