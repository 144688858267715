import React, { useState, useRef } from "react";
import {
	Box,
	Center,
	HStack,
	Text,
	InputGroup,
	Input,
	Select,
	Button,
	Stack,
	Heading,
	useOutsideClick,
	useBoolean,
	InputRightElement,
	Progress,
	Checkbox,
	Icon,
	Portal,
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@chakra-ui/react";
import { v4 } from "uuid";
import VirtualListV3 from "../../ReactTable/components/VirtualListV3";
import { FaAngleRight, FaAngleDown } from "react-icons/fa";

// import { log } from "../../../helperFunctions";

export default function SelectInput(props) {
	const [isOpen, setOpen] = useBoolean();
	const [searchFilter, setSearchFilter] = useState("");
	// const [isTranslated, setTranslated] = useBoolean();

	const ref = useRef();
	const initialFocusRef = useRef();

	useOutsideClick({
		ref: ref,
		handler: () => setOpen.off(),
	});

	// useOutsideClick({
	// 	ref: initialFocusRef,
	// 	handler: () => setOpen.off(),
	// });

	const setIsOpen = (value) => {
		if (Boolean(props.readOnly ?? props.isReadOnly ?? props.isDisabled)) {
			return;
		}
		if (!isOpen && Boolean(value)) {
			setSearchFilter("");
		}
		setOpen.on();
	};

	// const checkIntersecting = useAsyncDebounce((entry) => {
	// 	if (!isOpen) {
	// 		return null;
	// 	}
	// 	if (entry.intersectionRatio < 1) {
	// 		if (isTranslated) {
	// 			$(entry.target).css("transform", "translate(0px,0px)");
	// 			setTranslated.off();
	// 		} else {
	// 			$(entry.target).css("transform", "translate(0px,-" + ($(entry.target)[0]?.clientHeight + 40) + "px)");
	// 			setTranslated.on();
	// 		}
	// 	}
	// }, 50);

	// const translateOptionList = React.useEffect(() => {
	// 	const observer = new IntersectionObserver((entries) => {
	// 		entries.forEach((entry) => {
	// 			if (props.static) {
	// 				return null;
	// 			}

	// 			checkIntersecting(entry);
	// 		});
	// 	});

	// 	if (isOpen) {
	// 		let optionList = $("#selectOptions-" + props.name);
	// 		if (Boolean(optionList)) {
	// 			observer.observe(optionList[0]);
	// 		}
	// 	} else {
	// 		observer.disconnect();
	// 	}
	// }, [checkIntersecting, isOpen, props.name, props.static]);

	const filterList = (ev) => {
		let value = ev.target.value;

		setSearchFilter(value);
	};

	const clearFilter = (ev) => {
		if (isOpen) {
			ev.stopPropagation();
			setSearchFilter("");
			ref?.current?.children[0]?.children[0]?.focus();
		}
		setSearchFilter("");
	};

	const onSelect = React.useCallback((option) => {
		let returnValue;
		if (props.optionValue) {
			returnValue = option[props.optionValue];
		} else {
			returnValue = option;
		}
		// if (!props.multiple) {
		// 	setOpen.off();
		// }
		if (props.onOptionSelect) {
			props.onOptionSelect(returnValue);
		} else if (props.onChange) {
			props.onChange(returnValue);
		} else if (props.onSelect) {
			props.onSelect({ name: props.name, value: returnValue, multiple: props.multiple });
		}
	}, []);

	const handleOnChange = (ev) => {
		ev.stopPropagation();
		if (Boolean(props.readOnly ?? props.isReadOnly ?? props.isDisabled)) {
			return;
		}
		ev.multiple = props.multiple;
		props.onChange(ev);
	};

	const selectOptions = React.useMemo(() => {
		let visibleOptions = [];

		if (props.includeNA) {
			visibleOptions.push({
				[props.optionValue ?? "value"]: "N/A",
				[props.optionText ?? "value"]: "N/A",
			});
		}

		for (let i = 0; i < props.options?.length; i++) {
			let option = Object.create(props.options[i]);
			Object.assign(option, props.options[i]);

			if (props.selectedOptions && props.multiple) {
				let foundIndex = props.selectedOptions.findIndex((selectedOption) => {
					return option[props.optionValue ?? "value"] === selectedOption[props.optionValue ?? "value"];
				});
				let selected = foundIndex === -1 ? false : true;
				option.selectFoundIndex = foundIndex;
				option.selectSelected = selected;
			}

			if (
				option[props.optionText ?? "text"]?.toLowerCase().includes(searchFilter?.toLowerCase()) ||
				option[props.optionSubheading]?.toLowerCase()?.includes(searchFilter?.toLowerCase())
			) {
				visibleOptions.push(option);
			}
		}
		return visibleOptions;
	}, [
		props.includeNA,
		props.optionValue,
		props.optionText,
		props.options,
		props.selectedOptions,
		props.multiple,
		props.optionSubheading,
		searchFilter,
	]);

	const columns = React.useMemo(() => {
		let cols = [];
		cols.push({ Header: "Text", id: "text", accessor: (row) => row[props.optionText ?? "text"] });
		if (props.optionSubheading) {
			cols.push({ Header: "SubHeading", id: "subHeading", accessor: (row) => row[props.optionSubheading] });
		}
		if (props.groupBy) {
			for (let i = 0; i < props.groupBy?.length; i++) {
				cols.push({
					Header: props.groupBy[i].toUpperCase(),
					id: props.groupBy[i],
					accessor: (row) => row[props.groupBy[i]],
				});
			}
		}

		return cols;
	}, [props.groupBy, props.optionSubheading, props.optionText]);

	const disabledValues = React.useMemo(
		() => [...new Set(props.disabledOptions?.map((d) => d[props.optionValue ?? "value"]))],
		[props.disabledOptions, props.optionValue]
	);

	const RenderRow = React.useCallback(
		({ index, row }) => {
			if (row.isGrouped) {
				return (
					<Stack
						key={index}
						direction="row"
						p={1}
						flex={1}
						w="full"
						alignItems="center"
						{...row.getToggleRowExpandedProps()}
					>
						<Heading size="xs" color="gray.500">
							{row.groupByVal}
						</Heading>
						<Icon color="gray.500" as={row.isExpanded ? FaAngleDown : FaAngleRight} />
						{/* <Divider flex={1} borderColor='gray.200' /> */}
					</Stack>
				);
			} else {
				let option = row.original;
				let selected = option.selectSelected;
				let CustomRender = props.customRender;

				let isDisabled = disabledValues?.includes(option[props.optionValue ?? "value"]);

				return (
					<Center
						key={index}
						width="full"
						minHeight="30px"
						px={3}
						py={1}
						onClick={
							!isDisabled &&
							(selected
								? () => {
										Boolean(props.onRemove) ? props.onRemove() : onSelect(option);
								  }
								: () => {
										// log("selectInput onclick option", option);
										if (!Boolean(props.multiple)) {
											setOpen.off();
										}
										onSelect(option);
								  })
						}
						cursor={isDisabled ? "not-allowed" : "pointer"}
						bg={isDisabled ? "gray.100" : selected ? "teal.100" : ""}
					>
						{selected && props.numberSelected && (
							<Center pr={3}>
								<Text as="span" color="green.600">
									<Heading as="h5" size="sm">
										{option.selectFoundIndex + 1}
									</Heading>
								</Text>
							</Center>
						)}
						<Box flex={1} width="full">
							{!Boolean(props.customRender) && (
								<>
									<Heading
										as="h6"
										color={isDisabled ? "gray.400" : selected ? "teal.600" : "gray.600"}
										size="sm"
									>
										{option[props.optionText ?? "text"]}
									</Heading>
									{props.optionSubheading && (
										<Text
											fontSize="sm"
											color={isDisabled ? "gray.400" : selected ? "teal.500" : "gray.500"}
										>
											{option[props.optionSubheading]}
										</Text>
									)}
								</>
							)}
							{Boolean(props.customRender) && <CustomRender option={option} selected={selected} />}
						</Box>
						{selected && !Boolean(props.numberSelected) && (
							<Center>
								<Text as="span" color="green.600">
									<i className="fas fa-check-circle" />
								</Text>
							</Center>
						)}
					</Center>
				);
			}
		},
		[disabledValues]
	);

	// React.useEffect(() => {
	// 	if (isOpen) {
	// 		let optionList = $("#selectOptions-" + props.name);
	// 		if (props.static) {
	// 			return null;
	// 		}
	// 		if (Boolean(optionList)) {
	// 			if (!isTranslated) {
	// 				optionList.css("transform", "translate(0px,0px)");
	// 			} else {
	// 				optionList.css("transform", "translate(0px,-" + (optionList[0]?.clientHeight + 40) + "px)");
	// 			}
	// 		}
	// 	}
	// }, [props.name, isOpen, isTranslated, props.static]);

	const handleSelectAll = () => {
		if (props.onSelectAll) {
			props.onSelectAll(selectOptions);
		}
	};

	if (props.searchable) {
		let showClear = props.hideClear ? false : true;
		let value = props.value;

		for (let i = 0; i < props.options?.length; i++) {
			if (
				!props.multiple &&
				Boolean(props.value) &&
				props.options[i][props.optionValue ?? "value"] === props.value
			) {
				value = props.options[i][props.optionText ?? "text"];
				break;
			}
		}
		// log("ref", ref);
		return (
			<Box ref={ref} width="full">
				<Popover
					id={"selectOptions-" + props.name}
					isLazy
					placement="bottom-start"
					w="full"
					gutter={2}
					initialFocusRef={initialFocusRef}
					onOpen={() => {
						setIsOpen(value);
					}}
					isOpen={isOpen}
					onClose={setOpen.off}
					style={{ zIndex: "var(--chakra-zIndices-modal)" }}
					// closeOnBlur={false}
				>
					<PopoverTrigger>
						<InputGroup
							align="center"
							colorScheme="teal"
							size={props.size}
							// onMouseDown={() => {
							// 	openList(value);
							// }}
							_hover={{ cursor: "pointer", bg: "whiteAlpha.600", rounded: 5 }}
							variant={props.variant ?? "outline"}
						>
							<Input
								onEsc={setOpen.off}
								ref={initialFocusRef}
								px={props.inputProps?.px ?? 2}
								py={props.inputProps?.py ?? 2}
								autoComplete="new-password"
								className={props.className + " focusBorderColorTeal"}
								placeholder={props.placeholder}
								_placeholder={{ color: "gray.500" }}
								onChange={filterList}
								value={isOpen ? searchFilter : value ?? searchFilter}
								isReadOnly={props.readOnly ?? props.isReadOnly ?? props.isDisabled}
								bg={props.inputProps?.bg ?? "white"}
								cursor="pointer"
								{...props.inputProps}
							/>
							<InputRightElement
								h="full"
								key={v4()}
								w="16"
								justifyContent="end"
								children={
									<Button size="xs" variant="ghost" colorScheme="gray" color="gray.500" mr={1}>
										<HStack spacing={1} align="center">
											<Text
												as="i"
												className={"fas " + (isOpen ? "fa-times" : "fa-chevron-down")}
											/>
											{isOpen && showClear && <Text>CLEAR</Text>}
										</HStack>
									</Button>
								}
								onClick={clearFilter}
							/>
						</InputGroup>
					</PopoverTrigger>
					<Portal containerRef={props.isModal ? ref : null}>
						<PopoverContent w={ref?.current?.clientWidth}>
							<Box w="full" bg="white" shadow="md">
								{props.isLoaded === false && (
									<Box p={2}>
										<Progress size="xs" isIndeterminate />
									</Box>
								)}
								{props.onSelectAll && (
									<Box px={2} pt={2}>
										<Checkbox onChange={handleSelectAll}>
											<Heading size="xs" color="gray.500">
												SELECT ALL
											</Heading>
										</Checkbox>
									</Box>
								)}
								<VirtualListV3
									data={selectOptions}
									columns={columns}
									renderRow={RenderRow}
									listName={"select-" + props.name + "-" + v4()}
									listOnly
									defaultRowHeight={20}
									listHeight={300}
									defaultGroupBy={props.groupBy ?? null}
									expandAll
								/>
							</Box>
						</PopoverContent>
					</Portal>
				</Popover>
			</Box>
		);
	} else {
		return (
			<Select
				className="skinnyScroll focusBorderColorTeal"
				// style={{ padding: 'var(--chakra-space-2)' }}
				cursor="pointer"
				colorScheme="teal"
				variant={props.variant ?? "filled"}
				name={props.name}
				value={props.value}
				defaultValue={props.defaultValue}
				onChange={handleOnChange}
				placeholder={props.placeholder}
				isInvalid={props.isInvalid}
				bg={props.inputProps?.bg ?? "white"}
				size={props.size}
				borderRadius="md"
				isReadOnly={props.readOnly ?? props.isReadOnly ?? props.isDisabled}
				{...props.inputProps}
			>
				{props.includeNA && <option value="N/A">N/A</option>}
				{props.children && <>{props.children}</>}
				{!props.children &&
					props.options?.map(
						(option, i) =>
							(props.hiddenOptions
								? props.hiddenOptions.find((hiddenOption) => {
										return (
											option[props.optionValue ?? "value"] ===
											hiddenOption[props.optionValue ?? "value"]
										);
								  }) === undefined
								: true) && (
								<option key={i} value={option[props.optionValue ?? "value"] ?? option}>
									{option[props.optionText ?? "text"] ?? option}
								</option>
							)
					)}
			</Select>
		);
	}
}
