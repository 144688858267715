import React, { useState } from "react";
import { Box, Stack, Skeleton, Divider, Flex, InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import $ from "jquery";
import { sum } from "../../../helperFunctions";

export default class VirtualListV2 extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			scrollHeight: 0,
			heights: [],
			totalHeight: 0,
			renderHidden: true,
			triggerUpdate: null,
			defaultHeight: this.props.defaultHeight ?? 50,
			dataRows: this.props.rows,
		};

		this.init = this.init.bind(this);
		this.calcHeights = this.calcHeights.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
		this.handleSearchBar = this.handleSearchBar.bind(this);
		this.handleRowClick = this.handleRowClick.bind(this);
	}

	componentDidMount() {
		if (!this.props.rowHeight) {
			this.init();
			window.onresize = () => {
				this.calcHeights();
			};
		}
	}

	componentDidUpdate() {
		if (this.props.triggerUpdate !== this.state.triggerUpdate) {
			this.setState({ triggerUpdate: this.props.triggerUpdate });
			this.calcHeights();
		}

		if (
			this.props.rows?.length !== this.state.dataRows?.length ||
			Boolean(this.props.rows) !== Boolean(this.state.dataRows)
		) {
			this.setState(
				{
					dataRows: this.props.rows,
				},
				() => {
					this.init();
				}
			);
		}
	}

	init() {
		let heights = [];
		for (let i = 0; i < this.props.rows?.length; i++) {
			heights.push(this.props.defaultHeight ?? 50);
		}
		this.setState(
			{
				heights: heights,
			},
			() => {
				this.calcHeights();
			}
		);
	}

	calcHeights() {
		setTimeout(() => {
			let heights = this.state.heights;
			let totalHeight = 0;
			let defaultHeight = this.state.defaultHeight;
			for (let i = 0; i < this.props.rows.length; i++) {
				let rowEl = $("#vListItem-" + i);
				if (rowEl && Boolean(rowEl[0])) {
					rowEl = rowEl[0];
					if (rowEl?.style) {
						rowEl.style.minHeight = defaultHeight + "px";
					}

					let clientHeight = rowEl?.clientHeight ?? defaultHeight;
					if (heights[i] === undefined) {
						heights.push(clientHeight);
					} else if (clientHeight !== defaultHeight) {
						heights[i] = clientHeight;
					}
					totalHeight += heights[i];
				}
			}

			this.setState({
				heights: heights,
				totalHeight: totalHeight,
				renderHidden: false,
			});
		}, 1000);
	}

	handleScroll(ev) {
		let element;
		let scrollTop;
		if (ev === undefined) {
			scrollTop = 0;
		} else {
			ev.stopPropagation();
			element = ev.target;
			scrollTop = element.scrollTop;
		}
		this.setState({
			scrollHeight: scrollTop,
		});
		if (!Boolean(this.props.rowHeight)) {
			this.calcHeights();
		}
	}

	handleSearchBar(value) {
		//
		if (this.props.handleSearchBar) {
			this.props.handleSearchBar(value);
		}
		this.setState({
			searchValue: value,
		});
	}

	handleRowClick(ev, index) {
		ev.stopPropagation();
		if (this.props.onRowClick) {
			let rowEl = $("#vListItem-" + index);
			rowEl[0].style.minHeight = this.state.defaultHeight + "px";
			this.props.onRowClick(index);
			setTimeout(() => {
				this.calcHeights();
			}, 100);
		}
	}

	render() {
		if (this.props.rowHeight) {
			return (
				<Box>
					<Flex width="full" mb={2} mx="auto" maxWidth="100%" wrap="wrap">
						<Box flex={1} mr={2} className="skinnyScroll">
							{this.props.options}
						</Box>
					</Flex>
					{this.props.searchBar && (
						<VirtualListSearchBar
							handleSearchBar={this.handleSearchBar}
							autoFocusOnSearchBar={this.props.autoFocusOnSearchBar}
						/>
					)}

					<Skeleton isLoaded={!this.props.loadingData}>
						<Box
							className="skinnyScroll"
							width="full"
							maxH={this.props.listHeight ? this.props.listHeight + "px" : "700px"}
							overflowY="auto"
							onScroll={this.handleScroll}
						>
							<Stack
								height={this.props.rowHeight * this.props.rows?.length}
								spacing={0}
								position="relative"
							>
								{this.props.rows?.map((row, i) => {
									if (
										i * this.props.rowHeight >= this.state.scrollHeight &&
										i * this.props.rowHeight <
											this.state.scrollHeight + (this.props.listHeight ?? 700)
									) {
										return (
											<Box
												key={i}
												className="hoverPointerLight"
												minHeight={this.props.rowHeight + "px"}
												position="absolute"
												width="full"
												top={i * this.props.rowHeight + "px"}
											>
												{row}
												<Divider />
											</Box>
										);
									} else {
										return <></>;
									}
								})}
							</Stack>
						</Box>
					</Skeleton>
				</Box>
			);
		} else {
			let rollingHeight = 0;

			return (
				<Box>
					<Flex width="full" mb={2} mx="auto" maxWidth="100%" wrap="wrap">
						<Box flex={1} mr={2} className="skinnyScroll">
							{this.props.options}
						</Box>
					</Flex>

					{this.props.searchBar && <VirtualListSearchBar handleSearchBar={this.handleSearchBar} />}
					<Box
						className="skinnyScroll"
						px="2"
						height={this.props.listHeight ? this.props.listHeight + "px" : "700px"}
						overflowY="auto"
						onScroll={this.handleScroll}
						width="full"
					>
						<Stack
							height={(sum(this.state.heights) ?? this.props.listHeight ?? 700) + "px"}
							spacing={0}
							position="relative"
							width="full"
						>
							{this.props.rows?.map((row, i) => {
								rollingHeight += this.state.heights ? this.state.heights[i] : this.state.defaultHeight;
								let startHeight =
									rollingHeight -
									(this.state.heights ? this.state.heights[i] : this.state.defaultHeight);

								if (
									rollingHeight >= this.state.scrollHeight &&
									startHeight < this.state.scrollHeight + (this.props.listHeight ?? 700)
								) {
									return (
										<Box
											key={i}
											id={"vListItem-" + i}
											className={this.props.hoverDisabled ? "" : "hoverPointerLight"}
											minHeight={
												(this.state.heights
													? this.state.heights[i]
													: this.state.defaultHeight) + "px"
											}
											position="absolute"
											width="full"
											top={startHeight + "px"}
										>
											{row}
											<Divider />
										</Box>
									);
								} else {
									return <></>;
								}
							})}
						</Stack>
					</Box>
				</Box>
			);
		}
	}
}

export function VirtualListSearchBar({ handleSearchBar, autoFocusOnSearchBar }) {
	const [timer, setTimer] = useState(null);
	const [searchValue, setSearchValue] = useState("");

	const localHandleSearchBar = (ev) => {
		let value = ev.target.value;
		setTimer(clearTimeout(timer));
		setTimer(
			setTimeout(() => {
				handleSearchBar(value);
			}, 250)
		);
		setSearchValue(value);
	};

	return (
		<Box flex={1} pb="4">
			<InputGroup>
				<InputLeftElement color="gray.400" children={<i className="fas fa-search fa-lg"></i>} />
				<Input
					autoFocus={autoFocusOnSearchBar}
					name="vlSearchBar"
					bg="white"
					placeholder="Search..."
					value={searchValue}
					onChange={localHandleSearchBar}
				/>
			</InputGroup>
		</Box>
	);
}
