const FORM_STATUSES = [
	{
		id: "0",
		status: "Not Started",
		importanceLevel: 0,
		icon: "far fa-clipboard",
		color: "gray",
		todoList: "In Progress",
	},
	{
		id: "1",
		status: "In Progress",
		importanceLevel: 1,
		icon: "fas fa-list-ol",
		color: "cyan",
		todoList: "In Progress",
	},
	{
		id: "2",
		status: "Submitted",
		importanceLevel: 3,
		icon: "fas fa-cloud-upload-alt",
		color: "blue",
		todoList: "In Progress",
	},
	{
		id: "3",
		status: "Approved",
		importanceLevel: 5,
		icon: "fas fa-thumbs-up",
		color: "green",
		todoList: "Completed",
	},
	{
		id: "4",
		status: "Scheduled",
		importanceLevel: 6,
		icon: "fas fa-calendar-check",
		color: "teal",
		todoList: "Completed",
	},
	{
		id: "5",
		status: "Completed",
		importanceLevel: 7,
		icon: "fas fa-flag-checkered",
		color: "purple",
		todoList: "Completed",
	},
];
export default FORM_STATUSES;

// {
// 	status: "AWAITING MANAGER APPROVAL",
// 	importanceLevel: 4,
// 	icon: "fas fa-circle",
// 	color: "blue",
// 	todoList: "In Progress",
// },
// {
// 	status: "AWAITING HR APPROVAL",
// 	importanceLevel: 4,
// 	icon: "fas fa-circle",
// 	color: "blue",
// 	todoList: "In Progress",
// },
