import React, { useEffect, useState, useMemo, useRef, useCallback, forwardRef } from "react";
import {
	Switch,
	Menu,
	MenuItem,
	MenuGroup,
	MenuButton,
	MenuList,
	Flex,
	Text,
	Heading,
	Stack,
	HStack,
	IconButton,
} from "@chakra-ui/react";

import {
	useTable,
	useResizeColumns,
	useGroupBy,
	useExpanded,
	useGlobalFilter,
	useFilters,
	useSortBy,
	useBlockLayout,
	useColumnOrder,
} from "react-table";
import { VariableSizeList } from "react-window";
import { useExportData } from "react-table-plugins";
import GlobalSearchFilter from "../../ReactTable/inputs/GlobalSearchFilter";
import getExportFileBlob from "../../ReactTable/functions/getExportFileBlob";

import { formatValue } from "../../../helperFunctions";

export default function NotificationsVirtualList({
	columns = [],
	data = [],
	initialState,
	defaultColumn,
	filterTypes,
	renderRow,
	updateMyData,
	skipReset = false,
	onClose,
}) {
	const containerRef = useRef(null);
	const [containerHeight, setContainerHeight] = useState(containerRef?.current?.clientHeight ?? 1000);

	// Resize Observer
	useEffect(() => {
		if (!containerRef.current) return;
		const resizeObserver = new ResizeObserver(() => {
			if (containerRef?.current?.clientHeight >= 1400) {
				setContainerHeight(1400);
			} else {
				setContainerHeight(containerRef?.current?.clientHeight);
			}
		});
		resizeObserver.observe(containerRef?.current);
		return () => resizeObserver.disconnect();
	}, []);

	const listRef = useRef(null);

	const {
		getTableProps,
		getTableBodyProps,
		prepareRow,
		rows,
		state,
		preGlobalFilteredRows,
		setGlobalFilter,
		toggleAllRowsExpanded,
		allColumns,
		// exportData,
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			getExportFileBlob,
			autoResetPage: !skipReset,
			autoResetExpanded: false,
			filterTypes,
			initialState,
			updateMyData,
		},
		useBlockLayout,
		useColumnOrder,
		useResizeColumns,
		useFilters,
		useGlobalFilter,
		useGroupBy,
		useSortBy,
		useExportData,
		useExpanded
	);

	const RenderRow = useCallback(
		({ index, style }) => {
			const row = rows[index];
			prepareRow(row);

			let groupLevel = 0;
			let groupByVal = null;
			if (row.isGrouped) {
				groupLevel = state.groupBy?.findIndex((x) => x === row?.groupByID) + 1;

				let column = allColumns?.find((d) => d.id === row?.groupByID) ?? {};

				groupByVal = formatValue(row.groupByVal, column?.decimals, column?.valueType);
				if (column?.valueType === "boolean") {
					groupByVal = column.Header + ": " + groupByVal;
				}
			}

			return (
				<Flex {...row.getRowProps({ style })} flex={1} w="full" pr={2} h="full" overflow="hidden">
					{row.isGrouped && (
						<Flex w="full" flex={1} align="center" justify="space-between">
							{state.groupBy.length > 1 && groupLevel === 1 && (
								<HStack pt={6} pb={4} w="full" flex={1}>
									<Heading
										letterSpacing={4}
										fontSize="2xl"
										color="gray.600"
										textShadow="2px 2px 2px white"
										textTransform="uppercase"
										isTruncated
										opacity={0.9}
									>
										{groupByVal}
									</Heading>
								</HStack>
							)}

							{((state.groupBy.length === 1 && groupLevel === 1) ||
								(state.groupBy.length > 1 && groupLevel === 2)) && (
								<HStack textTransform="uppercase" color="gray.600">
									<IconButton
										key={"GroupBy-" + groupByVal + (row.isExpanded ? "-Open" : "-Closed")}
										onClick={() => {
											if (Boolean(listRef?.current)) {
												listRef?.current?.resetAfterIndex(0, false);
											}
											row.toggleRowExpanded();
										}}
										borderRadius="md"
										borderWidth={2}
										borderColor="gray.400"
										fontWeight="semibold"
										bg="whiteAlpha.700"
										color="gray.500"
										_hover={{
											color: "teal.500",
											borderColor: "teal.500",
											borderWidth: "2px",
										}}
										size="xs"
										_focus={{ boxShadow: "outline", borderWidth: "2px" }}
										icon={
											<i
												className={
													"fas " + (row.isExpanded ? "fa-minus" : "fa-plus") + " fa-fw"
												}
											/>
										}
									/>
									<Heading letterSpacing={1} size="md" opacity={0.9}>
										{groupByVal}
									</Heading>
								</HStack>
							)}

							{state.groupBy.length > 1 && groupLevel === 3 && (
								<HStack textTransform="uppercase" color="gray.500">
									<Heading letterSpacing={1} size="sm" isTruncated>
										{groupByVal}
									</Heading>
								</HStack>
							)}
							{state.groupBy.length > 1 && groupLevel === 4 && (
								<HStack textTransform="uppercase" color="gray.500">
									<Heading letterSpacing={1} size="sm" isTruncated></Heading>
								</HStack>
							)}
						</Flex>
					)}

					{!row.isGrouped && Boolean(renderRow) ? renderRow(row, updateMyData, listRef, onClose) : null}
				</Flex>
			);
		},
		[rows, prepareRow, state.groupBy, renderRow, updateMyData, allColumns, onClose]
	);

	let ListContainer = useMemo(() => {
		return (
			<VariableSizeList
				ref={listRef}
				height={containerHeight}
				itemCount={rows.length}
				className="skinnyScroll"
				itemSize={(index) => {
					let groupIndex = state.groupBy.findIndex((x) => x === rows[index]?.groupByID);
					return rows[index]?.canExpand
						? groupIndex >= 3
							? 0
							: groupIndex === 2
							? 40
							: groupIndex === 1
							? 40
							: 40
						: 150;
				}}
				style={{ overflowX: "hidden" }}
			>
				{RenderRow}
			</VariableSizeList>
		);
	}, [containerHeight, rows, RenderRow, state.groupBy]);

	useEffect(() => {
		if (Boolean(listRef?.current)) {
			listRef?.current?.resetAfterIndex(0, false);
		}
		toggleAllRowsExpanded(true);
	}, [toggleAllRowsExpanded, allColumns]);

	return (
		<Stack {...getTableProps()} w="full" flex={1} h="full">
			<HStack align="center" w="full" justify="flex-end" lineHeight={10} position="relative">
				<Flex flex={1} w="full" lineHeight={10}>
					<GlobalSearchFilter
						preGlobalFilteredRows={preGlobalFilteredRows}
						globalFilter={state.globalFilter}
						setGlobalFilter={setGlobalFilter}
						rowCount={(rows?.length ?? 0) - (rows?.filter((data) => data?.isGrouped === true)?.length ?? 0)}
						listRef={listRef}
					/>
				</Flex>

				{/* <IconButton
						key={(showAccess ? "show" : "hide") + "-toggleShowAccessButton"}
						onClick={() => setShowAccess.toggle()}
						borderRadius="md"
						borderWidth={"2px"}
						fontWeight="semibold"
						bg={showAccess ? "gray.500" : "gray.100"}
						color={showAccess ? "white" : "gray.500"}
						borderColor={showAccess ? "gray.500" : ""}
						_hover={{
							color: showAccess ? "white" : "gray.500",
							borderColor: "gray.500",
							borderWidth: "2px",
						}}
						_focus={{ boxShadow: "outline", borderWidth: "2px" }}
						icon={<i className={"fas " + (showAccess ? "fa-unlock" : "fa-lock") + " fa-lg fa-fw"} />}
					></IconButton> */}

				{/* <Menu p={0} placement="bottom-end">
					<MenuButton
						size="xs"
						px={2}
						py={2}
						transition="all 0.2s"
						borderRadius="md"
						borderWidth="2px"
						fontWeight="semibold"
						bg="gray.100"
						color="gray.500"
						h={10}
						minW={10}
						_hover={{
							color: "teal.500",
							borderColor: "teal.600",
						}}
						_expanded={{ bg: "teal.600", borderColor: "teal.600", color: "white" }}
						_focus={{ boxShadow: "outline" }}
					>
						<Text>
							<i className="fas fa-download fa-lg " />
						</Text>
					</MenuButton>

					<MenuList color="gray.500" bg="gray.100" overflowY="auto" maxH="container.md">
						<MenuGroup fontWeight="semibold" letterSpacing={1} color="gray.400">
							<MenuItem
								onClick={() => {
									exportData("xlsx", true);
								}}
							>
								<HStack align="center" spacing={3} letterSpacing={1}>
									<Text fontSize="lg">
										<i className="fas fa-file-excel fa-lg" />
									</Text>
									<Text fontWeight="semibold">EXCEL (.xlsx)</Text>
								</HStack>
							</MenuItem>
							<MenuItem
								onClick={() => {
									exportData("csv", true);
								}}
							>
								<HStack align="center" spacing={3} letterSpacing={1}>
									<Text fontSize="lg">
										<i className="fas fa-file-csv fa-lg" />
									</Text>
									<Text fontWeight="semibold">CSV (.csv)</Text>
								</HStack>
							</MenuItem>
						</MenuGroup>
					</MenuList>
				</Menu> */}

				<GroupByMenu
					allColumns={allColumns}
					toggleAllRowsExpanded={toggleAllRowsExpanded}
					groupBy={state.groupBy}
					initalSort={initialState.sortBy}
					listRef={listRef}
				/>
			</HStack>

			<Flex
				{...getTableBodyProps()}
				borderTopColor="whiteAlpha.700"
				borderTopWidth={2}
				direction="column"
				flex={1}
				w="full"
				h="full"
				overflow="hidden"
				ref={containerRef}
			>
				{ListContainer}
			</Flex>
		</Stack>
	);
}

const GroupByMenu = forwardRef(function ({ allColumns, toggleAllRowsExpanded, groupBy }, listRef) {
	const columns = useMemo(() => allColumns?.filter((d) => d.showGroupBy === true), [allColumns]);

	return (
		<Menu closeOnSelect={false} p={0} placement="bottom-end" isLazy>
			<MenuButton
				size="xs"
				p={2}
				borderRadius="md"
				borderWidth="2px"
				fontWeight="semibold"
				bg="gray.100"
				color="gray.500"
				h={10}
				minW={10}
				_hover={{
					color: "blue.500",
					borderColor: "blue.500",
				}}
				_expanded={{
					bg: "blue.500",
					color: "white",
					borderColor: "blue.500",
				}}
				_focus={{ boxShadow: "outline" }}
			>
				<Text>
					<i className="fas fa-cog fa-lg " />
				</Text>
			</MenuButton>

			<MenuList color="gray.500" bg="gray.50" minW="md" maxH="lg" overflow="auto" className="skinnyScroll">
				<MenuGroup
					title={
						<Flex w="full" flex={1}>
							<Flex align="center" letterSpacing={1} flex={2} isTruncated>
								<Text
									fontWeight="semibold"
									lineHeight={1.15}
									fontSize="sm"
									textTransform="uppercase"
									isTruncated
								>
									Columns
								</Text>
							</Flex>
							<Flex align="center" letterSpacing={1} flex={1} isTruncated justify="center">
								<Text
									fontWeight="semibold"
									lineHeight={1.15}
									fontSize="sm"
									textTransform="uppercase"
									isTruncated
								>
									Group By
								</Text>
							</Flex>
						</Flex>
					}
					fontWeight="semibold"
					letterSpacing={1}
					color="gray.500"
					borderBottom="2px"
					borderColor="gray.400"
				>
					{columns.map((column, i) => {
						return (
							column.id !== "expander" &&
							!column.disableExport && (
								<MenuItem key={column.id}>
									<Flex align="center" letterSpacing={1} flex={2} isTruncated>
										<Text
											lineHeight={1.15}
											fontSize="sm"
											isTruncated
											fontWeight="semibold"
											textTransform="uppercase"
										>
											{column.Header}
										</Text>
									</Flex>
									<Flex align="center" letterSpacing={1} flex={1} justify="center">
										<Switch
											id={column.id + "-GroupBy"}
											colorScheme="blue"
											isChecked={column.isGrouped}
											{...column.getGroupByToggleProps()}
											key={column.id + (Boolean(column.isGrouped) ? "-Grouped" : "-notGrouped")}
											onChange={() => {
												column.toggleGroupBy();

												for (let i = 0; i < groupBy?.length; i++) {
													let column = columns.find((d) => d.id === groupBy[i]) ?? {};
													if (Boolean(column?.id) && !column?.isSorted) {
														column.toggleSortBy(true, true);
													}
												}
												//column.toggleSortBy(false);
												if (Boolean(listRef?.current)) {
													listRef?.current?.resetAfterIndex(0, false);
												}
												toggleAllRowsExpanded(true);
											}}
										/>
									</Flex>
								</MenuItem>
							)
						);
					})}
				</MenuGroup>
			</MenuList>
		</Menu>
	);
});
