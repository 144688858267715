import React, { useState, useRef } from "react";
import { Center, Flex, Text, Heading, VStack, IconButton, Container, Image } from "@chakra-ui/react";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
// import { log } from "../../helperFunctions";

export default function CarouselExample({ heading, subHeading }) {
	const pages = [0, 1, 2, 3, 4];
	const xOffset = 100;
	const variants = {
		enter: (direction) => ({
			x: direction > 0 ? xOffset : -xOffset,
			opacity: 0,
		}),
		active: {
			x: 0,
			opacity: 1,
			transition: { delay: 0.2 },
		},
		exit: (direction) => ({
			x: direction > 0 ? -xOffset : xOffset,
			opacity: 0,
		}),
	};

	const [[currentPage, direction], setCurrentPage] = useState([0, 0]);

	/* We keep track of the pagination direction as well as
	 * current page, this way we can dynamically generate different
	 * animations depending on the direction of travel */
	function setPage(newPage, newDirection) {
		if (!newDirection) newDirection = newPage - currentPage;
		setCurrentPage([newPage, newDirection]);
	}

	const hasPaginated = useRef(false);

	function detectPaginationGesture(e, { offset }) {
		if (hasPaginated.current) return;
		let threshold = 200 / 2;
		let newPage = currentPage;
		let tabCount = pages.length;

		if (tabCount >= 1) {
			if (offset.x < -threshold) {
				// If user is dragging left, go forward a page
				if (currentPage === tabCount - 1) {
					newPage = 0;
				} else {
					newPage = currentPage + 1;
				}
				// Else if the user is dragging right,
				// go backwards a page
			} else if (offset.x > threshold) {
				if (currentPage === 0) {
					newPage = tabCount - 1;
				} else {
					newPage = currentPage - 1;
				}
			}
		}
		setPage(newPage, offset.x < 0 ? 1 : -1);
	}

	let backgroundImage;
	let footnote;
	if (currentPage === 0) {
		backgroundImage = "/images/iPPMech_2016_MainMenu.png";
		footnote = "The original version of iPPMech.com went live in 2016";
	} else if (currentPage === 1) {
		backgroundImage = "/images/iPPMech_2017_MainMenu.png";
		footnote = "The user interface was significantly upgraded in 2017";
	} else if (currentPage === 2) {
		backgroundImage = "/images/PPMPortal_2021_Timecard.png";
		footnote = "We went live with the Employee Portal and Timecard in April 2021";
	} else if (currentPage === 3) {
		backgroundImage = "/images/PPMPortal_2022_Profile.png";
		footnote = "We released the fully modernized version of Employee Portal in January 2022";
	} else if (currentPage === 4) {
		backgroundImage = "/images/PPMPortal_2022_MFR.png";
		footnote = "We are on track to fully rollout the MFR 2.0, PCO Log and D&F by December 2022";
	} else {
		backgroundImage = null;
		footnote = "";
	}

	return (
		<VStack maxW="full" w="full" px="8" pt="8" pb="4" bg="teal.600" flex={1} h="full" rounded="5" overflow="hidden">
			<VStack spacing={4} w="full">
				<Heading color="whiteAlpha.700" textTransform="uppercase" letterSpacing={6} size="xl" pt={16}>
					{subHeading}
				</Heading>
				<Heading color="whiteAlpha.800" textTransform="uppercase" letterSpacing={6} size="3xl">
					{heading}
				</Heading>
			</VStack>

			<Flex w="full" align="center" flex={1} justify="space-between" py={8}>
				<IconButton
					zIndex={2}
					mx="auto"
					color="whiteAlpha.800"
					_hover={{ color: "teal.500" }}
					variant="ghost"
					onClick={() => setPage(currentPage === 0 ? pages.length - 1 : currentPage - 1)}
					icon={<i className="fas fa-chevron-left fa-2x"></i>}
				/>

				<Container w="full" className="slider-container" rounded={5}>
					<AnimatePresence custom={direction}>
						<motion.div
							key={currentPage}
							className="slide"
							whileHover={{ scale: 1.005, transition: { duration: 0.5 } }}
							data-page={currentPage}
							variants={variants}
							initial="enter"
							animate="active"
							exit="exit"
							drag="x"
							onDrag={detectPaginationGesture}
							onDragStart={() => (hasPaginated.current = false)}
							onDragEnd={() => (hasPaginated.current = true)}
							// Snap the component back to the center
							// if it hasn't paginated
							dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
							// This will be used for components to resolve all
							// other variants, in this case initial and animate.
							custom={direction}
						>
							<Center
								maxH="736px"
								shadow="md"
								rounded={5}
								zIndex="0"
								style={{
									background: "white url(" + backgroundImage + " ) no-repeat",
									backgroundSize: "contain",
									backgroundPositionX: "center",
									backgroundPositionY: "90px",
								}}
								maxW="full"
								h="full"
							>
								<Image
									src={"/images/ImageFrame_WebBrowser.png"}
									maxW="container.lg"
									rounded={5}
								></Image>
							</Center>
							<Text
								pt={2}
								w="full"
								textAlign="center"
								className="slide-text"
								color="whiteAlpha.900"
								fontWeight="medium"
								fontSize="2xl"
							>
								{footnote}
							</Text>
						</motion.div>
					</AnimatePresence>
				</Container>

				<IconButton
					zIndex={2}
					mx="auto"
					color="whiteAlpha.800"
					_hover={{ color: "teal.500" }}
					variant="ghost"
					onClick={() => setPage(currentPage === pages.length - 1 ? 0 : currentPage + 1)}
					icon={<i className="fas fa-chevron-right fa-2x"></i>}
				/>
			</Flex>

			<Pagination pages={pages} currentPage={currentPage} setPage={setPage} />
			<Flex minH="2xs"></Flex>
		</VStack>
	);
}

const Pagination = (props) => {
	// Wrap all the pagination Indicators
	// with AnimateSharedPresence
	// so we can detect when Indicators
	// with a layoutId are removed/added
	const pages = props.pages ?? [0];
	return (
		<AnimateSharedLayout>
			<motion.div layout className="Indicators">
				{pages.map((page) => (
					<Indicator key={page} onClick={() => props.setPage(page)} isSelected={page === props.currentPage} />
				))}
			</motion.div>
		</AnimateSharedLayout>
	);
};

const Indicator = ({ isSelected, onClick }) => {
	return (
		<motion.div className="Indicator-container" onClick={onClick} style={{ zIndex: "2" }}>
			<div className="Indicator">
				{isSelected && (
					// By setting layoutId, when this component
					// is removed and a new one is added elsewhere,
					// the new component will animate out from the old one.
					<motion.div className="Indicator-highlight" layoutId="Indicator-highlight" />
				)}
			</div>
		</motion.div>
	);
};
