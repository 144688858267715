import React from "react";
import { Flex, Heading, Stack, Skeleton, Container } from "@chakra-ui/react";
import BasicCard from "../../../core/Layout/cards/BasicCard";
import UserProfileHeader from "../components/EmployeeProfileHeader";
import UserProfilePage from "../components/UserContactCard";
import TeamList from "../components/TeamList";

import { convertArrayToMap } from "../../../helperFunctions";
import OverlayPage from "../../../core/Layout/drawers/OverlayPage";

import { portalUser } from "../../../App";

// import { log } from "../../../helperFunctions";

export default class TeamMembersPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			management: null,
			users: null,
			teams: null,

			selectedEmployee: null,
		};

		this.init = this.init.bind(this);
		this.viewEmployee = this.viewEmployee.bind(this);
	}

	componentDidMount() {
		this.init();
	}
	//1 - Requires Training 2 - Needs Improvement 3 - Meets Expectations

	async init() {
		let users = portalUser.getUsers();
		let reviewersMap = convertArrayToMap(users, "reviewerUID", true);
		let managersMap = convertArrayToMap(users, "managerUID", true);
		let reviewingTeam = reviewersMap[portalUser.user?.employeeUID] ?? [];

		let managingTeam = managersMap[portalUser.user?.employeeUID] ?? [];
		let teams = [
			{ name: "Managing Team", members: [portalUser.user, ...managingTeam], role: "Manager" },
			{ name: "Reviewing Team", members: [portalUser.user, ...reviewingTeam], role: "Reviewer" },
		];

		this.setState({
			teams: teams,
		});
	}

	viewEmployee(member) {
		this.setState({
			selectedEmployee: member,
		});
	}

	render() {
		return (
			<OverlayPage
				flex={1}
				bg={portalUser.styles?.pageBG}
				isOpen={Boolean(this.state.selectedEmployee)}
				onClose={() => {
					this.viewEmployee(null);
				}}
				childComponent={
					<Container maxW="container.xl">
						<UserProfilePage employeeUID={this.state.selectedEmployee?.employeeUID} />
					</Container>
				}
			>
				<Stack
					spacing={portalUser.styles?.pageSpacing}
					p={portalUser.styles?.pagePadding}
					bg={portalUser.styles?.pageBG}
					w="full"
					h="full"
					flex={1}
					align="center"
				>
					<UserProfileHeader />

					<Container id="card-myteam" maxW="8xl" minH="xl" minW={{ base: "full", xl: "8xl" }} px={0}>
						<BasicCard title="Team Members" flex={2} portalStyles={portalUser.styles}>
							<Flex p={4} align="center">
								<Heading
									flex={3}
									size="md"
									letterSpacing={2}
									textTransform="uppercase"
									color="gray.500"
								>
									My Teams
								</Heading>

								<Heading
									flex={1}
									size="md"
									letterSpacing={2}
									textTransform="uppercase"
									color="gray.500"
								>
									My Role
								</Heading>

								<Heading
									flex={1}
									size="md"
									letterSpacing={2}
									textTransform="uppercase"
									color="gray.500"
								>
									Team Size
								</Heading>

								<Flex minW="35px"></Flex>
							</Flex>

							<Skeleton isLoaded={Boolean(this.state.teams)} minH="100px" rounded="5">
								<TeamList data={this.state.teams} viewEmployee={this.viewEmployee} />
							</Skeleton>
						</BasicCard>
					</Container>
				</Stack>
			</OverlayPage>
		);
	}
}
