import React, { useState } from "react";
import {
	Box,
	Flex,
	Center,
	Tooltip,
	InputGroup,
	InputLeftElement,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Button,
	ButtonGroup,
	Input,
	Select,
	InputRightElement,
	CloseButton,
	useBoolean,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	IconButton,
	VStack,
	Text,
	HStack,
} from "@chakra-ui/react";

import { mergeSort, log, sum } from "../../../helperFunctions";
import { v4 } from "uuid";
import $ from "jquery";

export default class VirtualTableV2 extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			scrollHeight: 0,
			heights: [],
			totalHeight: 0,
			renderHidden: false,
			collapsedColumns: [],
			triggerUpdate: null,
			filters: {},
			showFilters: false,
			orderedRows: this.props.rows,
			sorted: false,
			sortCol: null,
			sortOrder: 0,
			dataRows: this.props.rows,
		};

		this.init = this.init.bind(this);
		this.calcHeights = this.calcHeights.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
		this.handleSearchBar = this.handleSearchBar.bind(this);
		this.collapseColumn = this.collapseColumn.bind(this);
		this.handleRowClick = this.handleRowClick.bind(this);
		this.toggleFilters = this.toggleFilters.bind(this);
		this.handleColumnClick = this.handleColumnClick.bind(this);
		this.sortTable = this.sortTable.bind(this);
		this.handleFilterUpdate = this.handleFilterUpdate.bind(this);
	}

	componentDidMount() {
		if (!this.props.rowHeight) {
			this.init();
		}
		if (this.props.defaultSort) {
			this.sortTable(this.props.defaultSort);
		}
	}

	componentDidUpdate() {
		if (this.props.triggerUpdate !== this.state.triggerUpdate) {
			this.setState({ triggerUpdate: this.props.triggerUpdate }, () => {
				this.calcHeights();
			});

			if (this.props.sortable && this.state.sorted && !this.props.customRows) {
				this.sortTable();
			}
		}

		if (
			this.props.rows?.length !== this.state.dataRows?.length ||
			Boolean(this.props.rows) !== Boolean(this.state.dataRows)
		) {
			this.setState(
				{
					dataRows: this.props.rows,
				},
				() => {
					this.init();
				}
			);
		}
	}

	init() {
		let heights = [];
		for (let i = 0; i < this.props.rows?.length; i++) {
			heights.push(this.props.baseHeight ?? 50);
		}
		this.setState(
			{
				heights: heights,
			},
			() => {
				this.calcHeights();
			}
		);
	}

	calcHeights() {
		if (!this.state.renderHidden) {
			//this.setState({ renderHidden: true });
		}

		setTimeout(() => {
			let heights = this.state.heights;
			let totalHeight = 0;

			for (let i = 0; i < this.props.rows?.length; i++) {
				let rowEl = $("#vTableItem-" + (this.props.tableName ?? "") + i);
				if (rowEl && Boolean(rowEl[0])) {
					rowEl = rowEl[0];

					// if (rowEl?.style) {
					// 	// rowEl.style.minHeight = 40;
					// 	// rowEl.style.height = 40;
					// }

					let defaultHeight = this.props.baseHeight ?? 50;
					let clientHeight = rowEl?.clientHeight ?? defaultHeight;

					if (heights[i] === undefined) {
						heights.push(clientHeight);
					} else if (clientHeight !== defaultHeight && clientHeight >= defaultHeight) {
						heights[i] = clientHeight;
					} else if (clientHeight !== defaultHeight && clientHeight < defaultHeight) {
						heights[i] = defaultHeight;
					}
					totalHeight += heights[i];
				}
			}

			this.setState({
				heights: heights,
				totalHeight: totalHeight,
				renderHidden: false,
			});
		}, 1000);
	}

	handleScroll(ev) {
		let element;
		let scrollTop;
		if (ev === undefined) {
			scrollTop = 0;
		} else {
			ev.stopPropagation();
			element = ev.target;
			scrollTop = element.scrollTop;
		}
		this.setState({
			scrollHeight: scrollTop,
		});
		if (!Boolean(this.props.rowHeight)) {
			this.calcHeights();
		}
	}

	handleSearchBar(value) {
		//
		if (this.props.customRows) {
			this.props.handleSearchBar(value);
		}
		this.setState({
			searchValue: value,
		});
	}

	collapseColumn(ev, index) {
		ev.stopPropagation();

		let collapsedColumns = this.state.collapsedColumns;
		let foundIndex = collapsedColumns.indexOf(index);
		if (foundIndex === -1) {
			collapsedColumns.push(index);
		} else {
			collapsedColumns.splice(foundIndex, 1);
		}
		log(collapsedColumns);

		this.setState({ collapsedColumns: collapsedColumns });
	}

	handleRowClick(ev, index) {
		ev.stopPropagation();
		if (this.props.onRowClick) {
			this.props.onRowClick(index);
			setTimeout(() => {
				this.calcHeights();
			}, 100);
		}
	}

	toggleFilters() {
		let showFilters = this.state.showFilters;
		this.setState({ showFilters: !showFilters });
	}

	handleColumnClick(tHead) {
		if (this.props.sortable) {
			if (tHead.onClick) {
				tHead.onClick(tHead.name, tHead.type);
			} else if (tHead.selectAll) {
				let tableFilters = this.props.filters ? this.props.filters : this.state.filters;
				let rows;
				let activeRows = [];
				if (this.props.sortable && this.state.sorted) {
					rows = this.state.orderedRows;
				} else {
					rows = this.props.rows;
				}
				for (let i = 0; i < rows.length; i++) {
					if (Object.keys(tableFilters)?.length > 0) {
						for (let attr in tableFilters) {
							if (tableFilters[attr] !== "") {
								if (rows[i][attr]?.toLowerCase().includes(tableFilters[attr].toLowerCase())) {
									activeRows.push(rows[i]);
								}
							}
						}
					} else {
						activeRows.push(rows[i]);
					}
				}
				tHead.selectAll(activeRows);
			} else {
				this.sortTable(tHead.name, tHead.type);
			}
		}
	}

	sortTable(col, type) {
		let orderedRows = this.props.rows ?? [];
		let sortBy = { name: col ?? this.state.sortCol, type: type };
		let newSortOrder = this.state.sortOrder;

		if (col === this.state.sortCol) {
			if (newSortOrder === 0) {
				newSortOrder = 1;
			} else {
				newSortOrder = 0;
			}
		} else {
			newSortOrder = 0;
		}

		orderedRows = mergeSort(orderedRows, sortBy, newSortOrder);

		this.setState({
			orderedRows: orderedRows,
			sorted: true,
			sortCol: col ?? this.state.sortCol,
			sortOrder: newSortOrder,
		});
		return orderedRows;
	}

	handleFilterUpdate(ev, name, value) {
		let filterTimer = this.state.filterTimer;
		clearTimeout(filterTimer);
		let updatedFilters = this.state.filters;
		updatedFilters[name] = value;

		filterTimer = setTimeout(() => {
			this.setState({
				filters: updatedFilters,
			});
		}, 200);

		this.setState({
			filterTimer: filterTimer,
		});
	}

	render() {
		let tableWidth = 0;
		let filteredOut = 0;

		for (let i = 0; i < this.props.tHeadFormat?.length; i++) {
			if (this.state.collapsedColumns.includes(i)) {
				continue;
			}
			tableWidth += this.props.tHeadFormat[i].colWidth;
		}

		let tableFilters = this.props.filters ? this.props.filters : this.state.filters;

		let rows;
		if (this.props.sortable && this.state.sorted) {
			rows = this.state.orderedRows;
		} else {
			rows = this.props.rows;
		}

		let rowsShown = rows?.length;
		for (let i = 0; i < rows?.length; i++) {
			for (let attr in tableFilters) {
				if (tableFilters[attr] !== "") {
					if (!rows[i][attr]?.toLowerCase().includes(tableFilters[attr].toLowerCase())) {
						rowsShown--;
						break;
					}
				}
			}
		}

		if (this.props.rowHeight) {
			return (
				<VStack>
					<Flex width={tableWidth + 6} mb={2} mx="auto" maxWidth="100%">
						<Box flex={1} mr={2}>
							{this.props.options}
						</Box>
						{this.props.applyFilters && (
							<Box>
								<ButtonGroup size="md" isAttached variant="solid">
									<Tooltip
										placement="top"
										hasArrow={true}
										label={this.state.showFilters ? "Hide Filters" : "Show Filters"}
									>
										<Button
											variant="solid"
											border="2px"
											borderColor="gray.300"
											color="gray.500"
											px="2"
											size="md"
											onClick={this.toggleFilters}
										>
											<HStack spacing={2} align="center">
												<i className="fas fa-filter fa-fw"></i>
												<Text textTransform="uppercase">FILTERS</Text>
											</HStack>
										</Button>
									</Tooltip>
								</ButtonGroup>
							</Box>
						)}
					</Flex>
					<Box
						className="skinnyScroll"
						width={tableWidth + 10}
						maxWidth="full"
						maxHeight="1000px"
						overflowY="auto"
						pr="2"
						onScroll={this.handleScroll}
						mx="auto"
					>
						<Table
							width={tableWidth}
							size="sm"
							bg={this.props.bg ? this.props.bg : this.props.variant !== "flushed" ? "white" : ""}
							mb="4"
						>
							<Thead
								className="font-weight-bold text-nowrap"
								top="0"
								left="0"
								zIndex="2"
								position="sticky"
							>
								<Tr>
									{this.props.tHeadFormat.map((tHead, i) => (
										<Th
											key={v4()}
											width={tHead.width}
											minWidth={tHead.width}
											maxW={tHead.width}
											borderTopLeftRadius={i === 0 ? "5px" : ""}
											py="3"
											bg="teal"
											color="white"
											px="1"
											borderTopRightRadius={i === this.props.tHeadFormat.length - 1 ? "5px" : ""}
											className={tHead.classes ?? ""}
											onClick={() => {
												this.handleColumnClick(tHead);
											}}
											hidden={this.state.collapsedColumns.includes(i)}
											cursor={this.props.sortable || tHead.onClick ? "pointer" : ""}
										>
											<Flex
												justify={
													tHead.classes?.includes("text-center") ? "center" : "flex-start"
												}
												isTruncated
											>
												<Center>
													<Text isTruncated>{tHead.title ?? ""}</Text>
												</Center>
												{(this.props.sortable || tHead.onClick) &&
													tHead.name === (this.props.sortCol ?? this.state.sortCol) && (
														<span className="fa-stack">
															<i
																className="fas fa-sort fa-stack-1x fa-lg"
																color="darkgray"
															></i>
															<i
																className={
																	"fas fa-stack-1x fa-lg " +
																	((this.props.sortOrder ?? this.state.sortOrder) ===
																	0
																		? "fa-sort-up"
																		: "fa-sort-down")
																}
															></i>
														</span>
													)}
												{/* <Text as='span' onClick={(ev) => {this.collapseColumn(ev, i)}}>+</Text> */}
											</Flex>
										</Th>
									))}
								</Tr>
								{this.props.applyFilters && (
									<Tr bg="whitesmoke" hidden={!this.state.showFilters}>
										{this.props.tHeadFormat.map((tHead, i) => (
											<Th
												className={tHead.classes ?? ""}
												width={tHead.width}
												minWidth={tHead.width}
												maxW={tHead.width}
												px="1"
											>
												{tHead.name && (
													<TFilterCell
														name={tHead.name}
														placeholder={tHead.filterPlaceholder}
														onChange={
															this.props.addFilter
																? this.props.addFilter
																: this.handleFilterUpdate
														}
														type={tHead.type ?? "string"}
														simpleSelection={tHead.simple}
														options={tHead.options}
														filterClick={tHead.filterClick}
													/>
												)}
											</Th>
										))}
									</Tr>
								)}
							</Thead>
							{this.props.customRows && (
								<Tbody height={this.props.rowHeight * this.props.rows?.length} position="relative">
									{this.props.rows?.map((row, i) => {
										if (
											i * this.props.rowHeight >= this.state.scrollHeight - 40 &&
											i * this.props.rowHeight < this.state.scrollHeight - 40 + 1000
										) {
											return (
												<Tr
													className="hoverPointerLight"
													minHeight={this.props.rowHeight + "px"}
													position="absolute"
													width="full"
													top={i * this.props.rowHeight + "px"}
												>
													{row}
												</Tr>
											);
										}
									})}
								</Tbody>
							)}
							{!this.props.customRows && (
								<Tbody height={this.props.rowHeight * rowsShown} position="relative">
									{rows?.map((row, i) => {
										let render = true;
										for (let attr in tableFilters) {
											if (tableFilters[attr] !== "") {
												if (
													!row[attr]?.toLowerCase().includes(tableFilters[attr].toLowerCase())
												) {
													filteredOut++;
													render = false;
													break;
												}
											}
										}

										if (
											(i - filteredOut) * this.props.rowHeight >= this.state.scrollHeight - 40 &&
											(i - filteredOut) * this.props.rowHeight <
												this.state.scrollHeight - 40 + 1000 &&
											render
										) {
											return (
												<Tr
													borderRight="1px"
													borderRightColor="blackAlpha.200"
													className="hoverPointerLight"
													minHeight={this.props.rowHeight + "px"}
													position="absolute"
													width="full"
													top={(i - filteredOut) * this.props.rowHeight + "px"}
												>
													{this.props.tHeadFormat.map((tHead, j) => {
														let hidden = false;
														if (tHead.dependsOn) {
															for (let k = 0; k < tHead.dependsOn.length; k++) {
																if (
																	row[tHead.dependsOn[k].attr] !==
																	tHead.dependsOn[k].val
																) {
																	hidden = true;
																	break;
																}
															}
														}

														return (
															<Td
																key={v4()}
																className={tHead.classes}
																whiteSpace="nowrap"
																width={tHead.width}
																minWidth={tHead.width}
																maxW={tHead.width}
																hidden={this.state.collapsedColumns.includes(j)}
																py={4}
																px="2"
															>
																<Box width="full">
																	{(tHead.type === undefined ||
																		tHead.type === "text") && (
																		<Text fontSize="sm" hidden={hidden} isTruncated>
																			{row[tHead.name]}
																		</Text>
																	)}
																	{tHead.type === "checkbox" && tHead.cellClick && (
																		<Text
																			className={"hoverPointer"}
																			name={tHead.name}
																			onClick={() => {
																				tHead.cellClick(tHead.name, row);
																			}}
																			hidden={hidden}
																			textAlign="center"
																			w="full"
																		>
																			<i
																				style={{
																					color: row[tHead.name]
																						? "var(--chakra-colors-green-500)"
																						: "var(--chakra-colors-gray-500)",
																				}}
																				className={
																					"fa-lg " +
																					(row[tHead.name]
																						? "fas fa-check-square"
																						: "far fa-square")
																				}
																			></i>
																		</Text>
																	)}
																	{tHead.type === "email" && (
																		<Text
																			fontSize="sm"
																			className={"hoverPointer"}
																			name={tHead.name}
																			hidden={hidden}
																			isTruncated
																		>
																			<a href={"mailto:" + row[tHead.name]}>
																				{row[tHead.name]}
																			</a>
																		</Text>
																	)}
																</Box>
															</Td>
														);
													})}
												</Tr>
											);
										}
									})}
								</Tbody>
							)}
						</Table>
					</Box>
				</VStack>
			);
		} else {
			let rollingHeight = 0;

			return (
				<Box width="full">
					{(Boolean(this.props.options) || Boolean(this.props.applyFilters)) && (
						<Flex
							width={this.props.display === "flex" ? "full" : tableWidth + 6}
							mb={2}
							mx="auto"
							maxWidth="100%"
							wrap="wrap"
						>
							<Box flex={1} mr={2} className="skinnyScroll">
								{this.props.options}
							</Box>
							{this.props.applyFilters && (
								<Box pr="1" ml="auto">
									<ButtonGroup size="md" isAttached variant="solid">
										<Tooltip
											placement="top"
											hasArrow={true}
											label={this.state.showFilters ? "Hide Filters" : "Show Filters"}
										>
											<Button
												variant="solid"
												border="2px"
												borderColor="gray.300"
												color="gray.500"
												px="2"
												size="md"
												onClick={this.toggleFilters}
											>
												<HStack spacing={2} align="center">
													<i className="fas fa-filter fa-fw"></i>
													<Text textTransform="uppercase">FILTERS</Text>
												</HStack>
											</Button>
										</Tooltip>
										{Object.keys(tableFilters).length > 2 && (
											<Tooltip placement="top" hasArrow={true} label="Clear Filters">
												<IconButton
													variant="solid"
													border="2px"
													borderColor="red.300"
													color="red.500"
													px="2"
													size="md"
													onClick={this.clearFilters}
													icon={<i className="fas fa-times fa-fw"></i>}
												/>
											</Tooltip>
										)}
									</ButtonGroup>
								</Box>
							)}
						</Flex>
					)}

					<Box
						className="skinnyScroll"
						width="full"
						maxWidth="full"
						maxHeight="700px"
						overflowY="auto"
						onScroll={this.handleScroll}
						mx="auto"
						pr={this.props.noPadding ? "0" : "2"}
					>
						<Table size="sm" mb="4" width={this.props.display === "flex" ? "full" : tableWidth}>
							<Thead fontWeight="bold" isTruncated top="0" left="0" zIndex="2" position="sticky">
								<Tr>
									{this.props.tHeadFormat?.map((tHead, i) => (
										<Th p="0" key={v4()}>
											<Text
												as="span"
												onClick={(ev) => {
													this.collapseColumn(ev, i);
												}}
												hidden={!this.state.collapsedColumns.includes(i)}
											>
												+
											</Text>
										</Th>
									))}
								</Tr>

								<Tr>
									{this.props.tHeadFormat?.map((tHead, i) => (
										<Th
											key={v4()}
											py="3"
											px="1"
											borderTopLeftRadius={i === 0 ? "5px" : ""}
											borderTopRightRadius={i === this.props.tHeadFormat.length - 1 ? "5px" : ""}
											bg="teal"
											color="white"
											className={tHead.classes ?? ""}
											width={tHead.width}
											maxWidth={tHead.width}
											cursor={this.props.sortable || tHead.onClick ? "pointer" : ""}
											onClick={() => {
												this.props.sortable
													? this.sortTable(tHead.name, tHead.type)
													: tHead.onClick(tHead.name, tHead.type);
											}}
											hidden={this.state.collapsedColumns.includes(i)}
										>
											<Flex
												justify={
													tHead.classes?.includes("text-center") ? "center" : "flex-start"
												}
											>
												<Center>
													<Text fontWeight="semibold">{tHead.title ?? ""}</Text>
												</Center>
												{(this.props.sortable || tHead.onClick) &&
													tHead.name === (this.props.sortCol ?? this.state.sortCol) && (
														<span className="fa-stack">
															<i
																className="fas fa-sort fa-stack-1x fa-lg"
																color="darkgray"
															></i>
															<i
																className={
																	"fas fa-stack-1x fa-lg " +
																	((this.props.sortOrder ?? this.state.sortOrder) ===
																	0
																		? "fa-sort-up"
																		: "fa-sort-down")
																}
															></i>
														</span>
													)}
												{/* <Text as='span' onClick={(ev) => {this.collapseColumn(ev, i)}}>+</Text> */}
											</Flex>
										</Th>
									))}
								</Tr>
								{this.props.applyFilters && (
									<Tr bg="whitesmoke" hidden={!this.state.showFilters}>
										{this.props.tHeadFormat?.map((tHead, i) => (
											<Th
												className={tHead.classes ?? ""}
												hidden={this.state.collapsedColumns.includes(i)}
												width={tHead.width}
												maxWidth={tHead.width}
												px="1"
											>
												{tHead.name && (
													<TFilterCell
														name={tHead.name}
														placeholder={tHead.filterPlaceholder}
														onChange={
															this.props.addFilter
																? this.props.addFilter
																: this.handleFilterUpdate
														}
														type={tHead.type ?? "string"}
														simpleSelection={tHead.simple}
														options={tHead.options}
														filterClick={tHead.filterClick}
													/>
												)}
											</Th>
										))}
									</Tr>
								)}
							</Thead>
							<Tbody height={(sum(this.state.heights) ?? 1000) + "px"} position="relative">
								{/* {this.props.rows?.map((row, i) => 
                  <Tr id={'vTableItem-' + i} position='absolute' width='full' visibility='hidden' display='none'>{row}</Tr>
                )} */}
								{this.props.rows?.map((row, i) => {
									rollingHeight += this.state.heights ? this.state.heights[i] : 40;
									let startHeight = rollingHeight - (this.state.heights ? this.state.heights[i] : 40);

									if (
										rollingHeight >= this.state.scrollHeight &&
										startHeight < this.state.scrollHeight + 1000
									) {
										return (
											<Tr
												id={"vTableItem-" + (this.props.tableName ?? "") + i}
												lineHeight={(this.state.heights ? this.state.heights[i] : 40) + "px"}
												position="absolute"
												width="full"
												top={startHeight + "px"}
											>
												{row}
											</Tr>
										);
									}
								})}
							</Tbody>
						</Table>
					</Box>
				</Box>
			);
		}
	}
}

function TFilterCell(props) {
	const [timer, setTimer] = useState(null);
	const [value, setValue] = useState("");
	const [operatorIndex, setOperatorIndex] = useState(0);
	const [hovering, setHovering] = useBoolean();

	const operators = [
		{ icon: "fas fa-sm fa-equals", text: "EQUALS", value: "==" },
		{ icon: "fas fa-sm fa-not-equal", text: "NOT EQUAL", value: "!=" },
		{ icon: "fas fa-sm fa-less-than", text: "LESS THAN", value: "<" },
		{ icon: "fas fa-sm fa-greater-than", text: "GREATER THAN", value: ">" },
		{ icon: "fas fa-sm fa-less-than-equal", text: "LESS THAN OR EQUAL TO", value: "<=" },
		{ icon: "fas fa-sm fa-greater-than-equal", text: "GREATER THAN OR EQUAL TO", value: ">=" },
	];

	const handleChange = (ev) => {
		let newValue = ev.target.value;
		setValue(newValue);
		setTimer(clearTimeout(timer));
		let newTimer = setTimeout(() => {
			if (props.type === "number") {
				newValue = operators[operatorIndex].value + "-" + newValue;
			}
			props.onChange(null, props.name, newValue);
		}, 200);
		setTimer(newTimer);
	};

	const changeOperator = (ev) => {
		let index = ev.target.value;
		setOperatorIndex(index);
		let newValue = operators[index].value + "-" + value;
		props.onChange(null, props.name, newValue);
	};

	const clearValue = () => {
		let newValue = "";
		setValue(newValue);
		if (props.type === "number") {
			newValue = operators[operatorIndex].value + "-" + newValue;
		}

		props.onChange(null, props.name, newValue);
	};

	if (props.type === "number") {
		let showOperators = [0, 2, 3];
		//<InputRightElement children={<CloseButton size='sm' />} hidden={!hovering} />
		return (
			<InputGroup size="sm" onMouseEnter={setHovering.toggle} onMouseLeave={setHovering.toggle} padding={0}>
				<InputLeftElement
					color="gray.400"
					width="17px"
					justifyContent="start"
					py="0"
					px="1"
					children={
						<Menu>
							<MenuButton
								key={v4()}
								as={IconButton}
								size="sm"
								minW={4}
								padding={0}
								variant="unstyled"
								icon={<i className={operators[operatorIndex].icon}></i>}
							></MenuButton>
							<MenuList>
								{operators.map((operator, i) => (
									<MenuItem
										key={v4()}
										color="gray.500"
										fontWeight="semibold"
										icon={<i className={operator.icon}></i>}
										value={i}
										onClick={changeOperator}
										hidden={props.simpleSelection && !showOperators.includes(i)}
									>
										{operator.text}
									</MenuItem>
								))}
							</MenuList>
						</Menu>
					}
				/>
				<Input
					textAlign="center"
					pr="2"
					focusBorderColor="teal.600"
					bg="white"
					size="sm"
					placeholder={props.placeholder}
					name={props.name}
					value={value}
					onChange={handleChange}
					type="number"
					paddingStart={5}
					paddingEnd={0}
				/>
			</InputGroup>
		);
	} else if (props.type === "select") {
		return (
			<InputGroup size="sm" onMouseEnter={setHovering.toggle} onMouseLeave={setHovering.toggle}>
				<Select
					focusBorderColor="teal.600"
					bg="white"
					size="sm"
					name={props.name}
					value={value}
					onChange={handleChange}
				>
					{props.options.map((option, i) => (
						<option key={v4()} value={option.value}>
							{option.display ?? option.value}
						</option>
					))}
				</Select>
			</InputGroup>
		);
	} else if (props.type === "iconSelect") {
		return (
			<InputGroup size="sm" onMouseEnter={setHovering.toggle} onMouseLeave={setHovering.toggle}>
				<Menu>
					<MenuButton
						key={v4()}
						as={IconButton}
						size="sm"
						variant="unstyled"
						icon={<i className={props.options.find(({ value }) => value === parseInt(value)).icon}></i>}
					></MenuButton>
					<MenuList>
						{props.options.map((option, i) => (
							<MenuItem
								key={v4()}
								name={props.name}
								icon={<i className={option.icon}></i>}
								value={option.value}
								onClick={handleChange}
							>
								{option.display}
							</MenuItem>
						))}
					</MenuList>
				</Menu>
			</InputGroup>
		);
	} else if (props.type === "checkbox") {
		return (
			<span
				name={props.name}
				onClick={() => {
					props.filterClick();
				}}
			>
				<i
					style={{ color: props.name ? "var(--chakra-colors-green-500)" : "var(--chakra-colors-gray-500)" }}
					className={"fa-lg " + (props.name ? "fas fa-check-square" : "far fa-square")}
				></i>
			</span>
		);
	} else {
		return (
			<InputGroup size="sm" onMouseEnter={setHovering.toggle} onMouseLeave={setHovering.toggle}>
				<Input
					focusBorderColor="teal.600"
					bg="white"
					size="sm"
					placeholder={props.placeholder}
					name={props.name}
					value={value}
					onChange={handleChange}
				/>
				<InputRightElement children={<CloseButton size="sm" onClick={clearValue} />} hidden={!hovering} />
			</InputGroup>
		);
	}
}
