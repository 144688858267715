import React from "react";
import ToggleButton from "./ToggleButton";

export default function SizesButton({ sizesIsOpen, setSizesIsOpen }) {
	return (
		<>
			{Boolean(setSizesIsOpen) && (
				<ToggleButton
					toggleIsOpen={sizesIsOpen}
					onClick={setSizesIsOpen.toggle}
					toggleOptions={[
						{
							isOpen: true,
							label: "Show Column Sizes",
							icon: "fas fa-ruler-combined",
							color: "teal.500",
							bg: "whiteAlpha.400",
						},
						{
							isOpen: false,
							label: "Hide Column Sizes",
							icon: "fas fa-ruler-combined",
							color: "gray.500",
							bg: "whiteAlpha.400",
						},
					]}
				/>
			)}
		</>
	);
}
