import React from "react";
import ToggleButton from "./ToggleButton";

export default function ShowHideButton({ showIsActive = "0", onClickShowHideButton }) {
	return (
		<ToggleButton
			toggleIsOpen={showIsActive}
			onClick={onClickShowHideButton}
			toggleOptions={[
				{
					isOpen: true,
					label: "Hide",
					icon: "fas fa-eye",
					color: "teal.500",
					bg: "whiteAlpha.900",
				},
				{
					isOpen: false,
					label: "Show",
					icon: "fas fa-eye-slash",
					color: "gray.500",
					bg: "whiteAlpha.700",
				},
			]}
		/>
	);
}
