import React, { useState, useMemo } from "react";
import DataInput from "../../../core/Inputs/data/DataInput";
import { portalUser } from "../../../App";

export default function DFJobSelect({ dfJobs = portalUser.DFData.jobs, params = {}, selectDFJob }) {
	const [title, setTitle] = useState(null);

	useMemo(() => {
		setTitle(dfJobs?.find((d) => d.jobID === params?.jobID)?.title ?? null);
	}, [dfJobs, params?.jobID]);

	const changeValue = (e) => {
		setTitle(e.title);
		selectDFJob(e);
	};

	return (
		<DataInput
			type="select"
			variant="outline"
			// label="Select Job"
			placeholder="Select Job"
			searchable
			value={title}
			options={dfJobs?.filter((d) => d.isArchived === "0") ?? []}
			minW="lg"
			inputProps={{
				fontSize: "lg",
				bg: "whiteAlpha.600",
				py: "0",
				fontWeight: "semibold",
				textAlign: "left",
				color: "gray.500",
			}}
			optionText="title"
			optionSubheading="subtitle"
			onOptionSelect={(e) => changeValue(e)}
		/>
	);
}
