const baseHeaders = { Authorization: "Bearer " + process.env.REACT_APP_EGNYTE_BEARER_TOKEN };
const egnyteBasePath = "https://ppmechanical.egnyte.com/pubapi/v1/fs";
const egnyteBasePathLinks = "https://ppmechanical.egnyte.com/pubapi/v1/links";

export default class EgnyteAPI {
	async ajaxGet(url) {
		let response = await fetch(url, {
			method: "GET",
			headers: baseHeaders,
		})
			.then((res) =>
				res.json().then((data) => {
					return data;
				})
			)
			.catch(async (err) => {
				if (err.name === "AbortError") {
					console.log("Promise Aborted");
				} else {
					console.log(err);
					console.log("Promise Rejected");
					return { status: 400, err: err };
				}
			});

		return response;
	}

	async ajaxGetDownload(url) {
		let headers = {};
		Object.assign(headers, baseHeaders, { "Content-Type": "application/json" });

		let response = await fetch(url, {
			method: "GET",
			headers: headers,
		})
			.then((res) => {
				return res.blob();
				// res.then(data => {
				//     return data;
				// })
			})
			.catch(async (err) => {
				if (err.name === "AbortError") {
					console.log("Promise Aborted");
				} else {
					console.log(err);
					console.log("Promise Rejected");
					return { status: 400, err: err };
				}
			});

		return response;
	}

	async ajaxPost(url, body, extraHeaders) {
		if (!Boolean(extraHeaders)) {
			extraHeaders = {};
		}
		let headers = {};
		Object.assign(headers, baseHeaders, extraHeaders);

		let response = await fetch(url, {
			method: "POST",
			headers: headers,
			body: JSON.stringify(body),
		})
			.then((res) =>
				res.json().then((data) => {
					return data;
				})
			)
			.catch((err) => {
				if (err.name === "AbortError") {
					console.log("Promise Aborted");
				} else {
					console.log(err);
					console.log("Promise Rejected");
					return { status: 400, err: err };
				}
			});

		return response;
	}

	async ajaxUploadFile(url, body, extraHeaders) {
		if (!Boolean(extraHeaders)) {
			extraHeaders = {};
		}
		let headers = {};
		Object.assign(headers, baseHeaders, extraHeaders);

		let response = await fetch(url, {
			method: "POST",
			headers: headers,
			body: body,
		})
			.then((res) =>
				res.json().then((data) => {
					return data;
				})
			)
			.catch((err) => {
				if (err.name === "AbortError") {
					console.log("Promise Aborted");
				} else {
					console.log(err);
					console.log("Promise Rejected");
					return { status: 400, err: err };
				}
			});

		return response;
	}

	async ajaxDelete(url) {
		let response = await fetch(url, {
			method: "DELETE",
			headers: baseHeaders,
		})
			.then((res) =>
				res.json().then((response) => {
					return response;
				})
			)
			.catch((err) => {
				if (err.name === "AbortError") {
					console.log("Promise Aborted");
				} else {
					console.log(err);
					console.log("Promise Rejected");
					return { status: 400, err: err };
				}
			});

		return response;
	}

	testGet() {
		let apiRouteURL = "/Shared/Operations/DnF/";
		return this.ajaxGet(apiRouteURL);
	}

	// testLogin() {
	//     let url = '/puboauth/token';
	//     let headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
	//     let body = 'client_id:4mnm6y5gp9fr8ssyvjph5eme&';
	//     return this.ajaxPost(path, body, headers);
	// }

	async getFolder(path, createIfNotExists) {
		let url = egnyteBasePath + path;
		let result = await this.ajaxGet(url);
		if (createIfNotExists) {
			if (Boolean(result.errorMessage)) {
				let createResult = await this.createFolder(path);
				console.log("CREATE RESULT");
				console.log(createResult);
				if (Boolean(createResult.folder_id)) {
					result = await this.getFolder(path);
				}
			}
		}
		return result;
	}

	async getFolderById(folderId) {
		let url = `https://ppmechanical.egnyte.com/pubapi/v1/fs/ids/folder/${folderId}`;
		let result = await this.ajaxGet(url);
		return result;
	}

	getFile(body) {
		let url = egnyteBasePathLinks;
		let headers = { "Content-Type": "application/json" };
		return this.ajaxPost(url, body, headers);
	}

	createFolder(path) {
		let url = egnyteBasePath + path;
		let headers = { "Content-Type": "application/json" };
		let body = { action: "add_folder" };
		return this.ajaxPost(url, body, headers);
	}

	uploadFile(path, file) {
		path = `https://ppmechanical.egnyte.com/pubapi/v1/fs-content${path}/${file.name}`;
		let headers = {
			"Content-Disposition": `form-data; name="file"; filename="${file.name}"`,
			"Content-Type": file.type,
		};
		return this.ajaxUploadFile(path, file, headers);
	}

	movePath(pathToMove, destination) {
		let headers = { "Content-Type": "application/json" };
		let body = {
			action: "move",
			destination: destination,
		};
		return this.ajaxPost(pathToMove, body, headers);
	}

	deletePath(path) {
		path = `https://ppmechanical.egnyte.com/pubapi/v1/fs${path}`;
		this.ajaxDelete(path);
	}

	downloadFile(path) {
		path = `https://ppmechanical.egnyte.com/pubapi/v1/fs-content${path}`;
		// let url = egnyteBasePath + path;
		return this.ajaxGetDownload(path);
	}

	renameFolder(oldPath, newPath) {
		let oldPathUrl = egnyteBasePath + oldPath;
		let newPathUrl = `${newPath}`;
		let body = {
			action: "move",
			destination: newPathUrl,
		};
		let headers = { "Content-Type": "application/json" };
		return this.ajaxPost(oldPathUrl, body, headers);
	}
}
