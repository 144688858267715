import React from "react";
import ExampleContainer from "../common/ExampleContainer";
import ChartJSExample from "../components/ChartJSExample";
import RechartsExample from "../components/RechartsExample";

export default function ChartsExamplePage() {
	return (
		<>
			<ExampleContainer
				heading={"Charts & Graphs"}
				subheading={"ChartJS Example"}
				children={<ChartJSExample />}
			/>
			<ExampleContainer
				heading={"Charts & Graphs"}
				subheading={"Recharts Example"}
				children={<RechartsExample />}
			/>
		</>
	);
}
