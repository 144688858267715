import React from "react";
import ToggleButton from "../../../core/ReactTable/buttons/ToggleButton";

export default function SideBarButton({ sideBarIsOpen, setSideBarIsOpen, size = "md" }) {
	return (
		<>
			{Boolean(setSideBarIsOpen) && (
				<ToggleButton
					toggleIsOpen={sideBarIsOpen}
					onClick={setSideBarIsOpen.toggle}
					toggleOptions={[
						{
							isOpen: true,
							label: "Close Side Bar",
							icon: "fas fa-times",
							color: "gray.500",
							bg: "whiteAlpha.700",
							borderWidth: 1,
						},
						{
							isOpen: false,
							label: "Open Side Bar",
							icon: "fas fa-bars",
							color: "gray.500",
							bg: "whiteAlpha.700",
							borderWidth: 1,
						},
					]}
				/>
			)}
		</>
	);
}
