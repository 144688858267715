import { formatDate } from "../../../helperFunctions";

export default class RFI {
	constructor(rfi) {
		this.queryData = rfi?.queryData ?? rfi?.QueryData ?? "RFI";

		this.jccmKeyID = rfi?.jccmKeyID ?? rfi?.JCCMKeyID ?? null;
		this.jcco = rfi?.jcco ?? rfi?.JCCo ?? null;
		this.contract = rfi?.contract ?? rfi?.Contract?.trim() ?? null;

		this.rfiKeyID = rfi?.rfiKeyID ?? rfi?.RFIKeyID ?? null;
		this.rfi = rfi?.rfi ?? rfi?.RFI ?? null;
		this.rev = rfi?.rev ?? rfi?.Rev ?? 0;
		this.rfiNo = this.rfiNo ?? this.getRFINo();
		this.gcRef = rfi?.gcRef ?? rfi?.GCRef ?? null;
		this.status = rfi?.status ?? rfi?.Status ?? "Open";

		this.rfiDesc = rfi?.rfiDesc ?? rfi?.Description ?? null;
		this.reference = rfi?.reference ?? rfi?.Reference ?? null;
		this.specSections = rfi?.specSections ?? rfi?.SpecSections ?? null;
		this.drawings = rfi?.drawings ?? rfi?.Drawings ?? null;
		this.sketches = rfi?.sketches ?? rfi?.Sketches ?? null;
		this.detail = rfi?.detail ?? rfi?.Detail ?? null;
		this.notes = rfi?.notes ?? rfi?.Notes ?? null;
		this.attachUID = rfi?.attachUID ?? rfi?.UniqueAttchID ?? null;

		this.dateCreated = formatDate(rfi?.dateCreated ?? rfi?.DateCreated) ?? null;
		this.dateSent = formatDate(rfi?.dateSent ?? rfi?.DateSent) ?? null;
		this.dateAnswered = formatDate(rfi?.dateAnswered ?? rfi?.DateAnswered) ?? null;

		this.author = rfi?.author ?? rfi?.Author ?? null;
		this.authorUID = rfi?.authorUID ?? rfi?.AuthorUID ?? null;
		this.sentFrom = rfi?.sentFrom ?? rfi?.SentFrom ?? null;
		this.sentTo = rfi?.sentTo ?? rfi?.SentTo ?? null;
		this.secondarySentTo = rfi?.secondarySentTo ?? rfi?.SecondarySentTo ?? null;
		this.answeredBy = rfi?.answeredBy ?? rfi?.AnsweredBy ?? null;
		this.daysOutstanding = rfi?.daysOutstanding ?? rfi?.DaysOutstanding ?? null;
	}

	getRFINo() {
		let rfiNo = this.rfi;
		if (this.rev !== 0) {
			rfiNo += " R" + this.rev;
		}
		return rfiNo;
	}
}
