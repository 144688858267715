import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Flex, Text, useBoolean } from "@chakra-ui/react";
import LoadingSection from "../../../core/ProgressIndicators/components/LoadingSection";
import TabPanels from "../../../core/ReactTable/layout/TabPanels";
import Page from "../../../core/ReactTable/layout/Page";

import DFJobSelect from "../components/DFJobSelect";
import DFJobDrawer from "../components/DFJobDrawer";
import DFScheduleDrawer from "../components/DFScheduleDrawer";

import PageTemplateDFSchedule from "../classes/PageTemplateDFSchedule";
import { convertArrayToMap } from "../../../helperFunctions";

export default function DFSchedulesPage({
	reportSettings,
	dfJobs,
	selectedJobs,
	params,
	tabVal,
	isLoading = false,

	changeTab,
	updateBanner,
	initParams,
}) {
	const [panelVal, setPanelVal] = useState(0);
	const changePanel = useCallback((panelVal = 0) => {
		setPanelVal(panelVal);
	}, []);

	const [taskDrawerIsOpen, setTaskDrawerIsOpen] = useBoolean(false);
	const [jobDrawerIsOpen, setJobDrawerIsOpen] = useBoolean(false);
	const [selectedJob, setSelectedJob] = useState(null);
	const [updateTrigger, setUpdateTrigger] = useState(false);

	//Create tab panels from the list of dfJobs by a given property (eg. scopeDesc)
	//by mapping over the dfJobs array, using the report settings and current tabVal
	const panels = useMemo(() => {
		let panels = [];

		//Create map of DFJobs based off the key for
		//the specific tabVal found in report settingss
		let mapKey = reportSettings?.mapKey[tabVal] ?? null;
		if (Boolean(params?.jobID || params?.jccmKeyID)) {
			mapKey = null;
		}
		let panelMap = convertArrayToMap(selectedJobs, mapKey, true);

		//If the mapKey is a property of the dfJobs
		//push the DFJobs into groups by that key
		if (Boolean(mapKey)) {
			for (let key of Object?.keys(panelMap)) {
				panels.push({
					title: reportSettings?.bannerTitle,
					subtitle: key + " Projects",
					panel: key,
					data: panelMap[key] ?? [],
				});
			}
			//If the mapKey is not a property of the dfJobs (eg. null)
			//push the all the DFJobs into a single tab panel
		} else {
			panels.push({
				title: reportSettings?.bannerTitle,
				subtitle: reportSettings?.bannerSubtitle,
				panel: reportSettings?.bannerSubtitle,
				data: selectedJobs ?? [],
			});
		}
		return panels;
	}, [selectedJobs, tabVal, reportSettings, params]);

	//Update the banner title and subtitle to better match the currently shown jobs
	//Reset tab Panel back to the first tab panel if the tab is changed (panelVal = 0)
	useMemo(() => {
		let selectedPanel = panels[panelVal];
		if (!isLoading && selectedPanel?.data?.length > 0) {
			let title = selectedPanel?.title ?? reportSettings?.bannerTitle ?? null;
			let subtitle = selectedPanel?.subtitle ?? reportSettings?.bannerSubtitle ?? null;
			updateBanner(title, subtitle);
		}
		if (!isLoading && tabVal === 0 && panelVal !== 0) {
			setPanelVal(panelVal);
		}
	}, [
		isLoading,
		panelVal,
		panels,
		reportSettings?.bannerSubtitle,
		reportSettings?.bannerTitle,
		tabVal,
		updateBanner,
	]);

	//Identify cases when there is no data for the given tab panel
	//Nobody likes infinite loading screens or crashing virtual tables
	const noData = useMemo(() => {
		if (!isLoading && selectedJobs?.length === 0) {
			return true;
		} else if (!isLoading && panels[panelVal]?.data?.length === 0) {
			return true;
		} else {
			return false;
		}
	}, [isLoading, selectedJobs?.length, panels, panelVal]);

	const selectJob = (job, drawer = "job", init = false) => {
		if (Boolean(job?.jobID)) {
			if (init) {
				initParams(params);
				setJobDrawerIsOpen.off();
				setTaskDrawerIsOpen.off();
			} else {
				setSelectedJob(job);
				if (drawer === "job") {
					setJobDrawerIsOpen.on();
				} else if (drawer === "task") {
					setTaskDrawerIsOpen.on();
				}
			}
			setUpdateTrigger(true);
		} else {
			setSelectedJob(null);
			setJobDrawerIsOpen.off();
			setTaskDrawerIsOpen.off();
			setUpdateTrigger(true);
		}
	};

	useEffect(() => {
		if (updateTrigger) {
			setUpdateTrigger(false);
		}
	}, [updateTrigger]);

	return (
		<Flex direction="column" flex={1} justify="flex-start" h="full" rounded={5} position="relative">
			{isLoading && <LoadingSection />}
			{!isLoading && noData && <Text>No D&F Schedule</Text>}
			{!isLoading && !noData && (
				<>
					{Boolean(selectedJob) && Boolean(jobDrawerIsOpen) && (
						<DFJobDrawer
							dfJob={selectedJob}
							isOpen={jobDrawerIsOpen}
							onClose={() => selectJob(null)}
							selectJob={selectJob}
						/>
					)}
					{Boolean(selectedJob) && Boolean(taskDrawerIsOpen) && (
						<DFScheduleDrawer
							dfJob={selectedJob}
							isOpen={taskDrawerIsOpen}
							onClose={() => selectJob(null)}
							setUpdateTrigger={setUpdateTrigger}
						/>
					)}
					<TabPanels panels={panels} panelVal={panelVal} changePanel={changePanel}>
						<DFSchedulePageTemplate
							selectedPanel={panels[panelVal]}
							params={params}
							dfJobs={dfJobs}
							initParams={initParams}
							panelVal={panelVal}
							changePanel={changePanel}
							changeTab={changeTab}
							selectJob={selectJob}
							skipReset={jobDrawerIsOpen || taskDrawerIsOpen}
						/>
					</TabPanels>
				</>
			)}
		</Flex>
	);
}

export function DFSchedulePageTemplate({
	selectedPanel,
	dfJobs,
	params,
	panelVal,
	changePanel,
	selectJob,
	skipReset = false,
}) {
	const pageTemplate = useMemo(
		() =>
			new PageTemplateDFSchedule(
				selectedPanel?.data,
				params,
				{
					pageName: selectedPanel?.title,
					pageTitle: selectedPanel?.subtitle,
				},
				selectJob
			),

		[selectedPanel?.data, selectedPanel?.title, selectedPanel?.subtitle, params, selectJob]
	);

	return (
		<Flex direction="column" flex={1} justify="flex-start" h="full" rounded={5}>
			{pageTemplate?.data?.length === 0 ? (
				<LoadingSection />
			) : (
				<Page
					pageTemplate={pageTemplate}
					overrideView={{
						showReportHeader: false,
						showPageHeader: false,
						showPageFooter: false,
						bg: "transparent",
						shadow: "none",
						pageTitle: "",
						py: 0,
						px: 0,
						addressBar: false,
					}}
					panelVal={panelVal}
					changePanel={changePanel}
					pageHeaderDropdown={<DFJobSelect dfJobs={dfJobs} params={params} selectDFJob={selectJob} />}
					skipReset={skipReset}
					// pageButtons={pageButtons}
					// setSelectedRow={selectDFJob}
				/>
			)}
		</Flex>
	);
}
