import React, { useState } from "react";
import { Box, Flex, VStack, InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import $ from "jquery";
import { v4 } from "uuid";

export default class VirtualListV1 extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			scrollTop: 0,
			listHeight: this.props.listHeight,
			scrollHeight: 700,
			heightArr: this.props.heightArr,
			filterTimer: null,
			filters: {},
			showFilters: true,
			heightArray: [],
			data: null,
			searchValue: "",
			searchBarFilterTimer: null,
			filterSelections: {},
		};

		this.init = this.init.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
		this.handleMouseEnter = this.handleMouseEnter.bind(this);
		this.addHeight = this.addHeight.bind(this);
		this.updateRowHeight = this.updateRowHeight.bind(this);
		this.handleSearchBar = this.handleSearchBar.bind(this);
		this.createAvailableFilters = this.createAvailableFilters.bind(this);
		this.toggleFilters = this.toggleFilters.bind(this);
		this.selectFilter = this.selectFilter.bind(this);
	}

	componentDidMount() {
		this.init();
	}

	componentDidUpdate() {
		if (this.state.data?.length !== this.props.data.length) {
			this.init();
		}

		if (this.props.filterSelections) {
			if (Object.keys(this.state.filterSelections).length !== Object.keys(this.props.filterSelections).length) {
				this.init();
			}
		}
	}

	init() {
		let dataRows = [];

		for (let i = 0; i < this.props.data?.length; i++) {
			dataRows.push({
				key: v4(),
				height: (i + 1) * parseInt(this.props.rowHeight),
				element: this.props.data[i],
				visible: i < 20 ? true : false,
			});
		}

		this.setState({
			data: dataRows,
		});
		this.createAvailableFilters();
		setTimeout(() => {
			this.handleScroll();
		}, 400);
	}

	handleScroll(ev) {
		let element;
		let scrollTop;
		if (ev === undefined) {
			scrollTop = 0;
		} else {
			ev.stopPropagation();
			element = ev.target;
			scrollTop = element.scrollTop;
		}

		let data = this.state.data;

		for (let i = 0; i < data?.length; i++) {
			let topHeight = i === 0 ? 0 : data[i - 1].height;
			let bottomHeight = data[i].height;
			let visible = bottomHeight >= scrollTop - 250 && topHeight <= scrollTop + this.state.listHeight + 250;

			if (visible) {
				let actualHeight = parseFloat($("#" + data[i].key).outerHeight());
				let prevHeight = i === 0 ? data[i].height : Math.abs(data[i].height - data[i - 1].height);
				if (actualHeight !== prevHeight) {
					let heightDifference = actualHeight - prevHeight;

					for (let j = i; j < data.length; j++) {
						data[j].height += heightDifference;
					}
				}

				topHeight = i === 0 ? 0 : data[i - 1].height;
				bottomHeight = data[i].height;
				visible = bottomHeight >= scrollTop - 250 && topHeight <= scrollTop + this.state.listHeight + 250;
			}

			data[i].visible = Boolean(visible);
		}
		this.setState({
			scrollTop: scrollTop,
			data: data,
		});
	}

	handleMouseEnter(ev) {
		let element = ev.target;
		if (element === null || element === undefined) {
			return -1;
		}
		while (element?.classList && !Object.values(element?.classList).includes("vList")) {
			element = element.parentElement;
		}
		if (element?.clientHeight && element.clientHeight !== this.state.listHeight) {
			this.setState({ listHeight: element?.clientHeight });
		}
	}

	addHeight(index, height) {
		let newHeightArr = this.state.heightArr;
		for (let i = index; i < newHeightArr.length; i++) {
			newHeightArr[i] = parseFloat(newHeightArr[i]) + parseFloat(height);
		}
		this.setState({ heightArr: newHeightArr });
	}

	updateRowHeight(index, ogHeight, height) {
		let newHeightArr = this.state.heightArr;
		newHeightArr[index] = height;
		this.setState({ heightArr: newHeightArr });
	}

	handleSearchBar(value) {
		if (this.props.customRows) {
			this.props.handleSearchBar(value);
		}
		this.setState({
			searchValue: value,
		});
	}

	createAvailableFilters() {
		if (this.props.applyFilters) {
			if (this.props.filterSelections) {
				this.setState({
					filterSelections: this.props.filterSelections,
				});
			}
		} else {
		}
	}

	toggleFilters() {
		let showFilters = this.state.showFilters;
		this.setState({ showFilters: !showFilters });
	}

	selectFilter(ev, attr, selectedVal) {
		let value;
		if (ev === null) {
			value = selectedVal;
		} else {
			value = ev.target.name;
		}

		if (this.props.filterSelections) {
			this.props.selectFilter(attr, value);
		} else {
			let filters = this.state.filters;
			if (filters[attr] === undefined) {
				filters[attr] = [];
			}
			let valueIndex = filters[attr].indexOf(value);
			if (valueIndex === -1) {
				filters[attr].push(value);
			} else {
				filters[attr].splice(valueIndex, 1);
			}
			this.setState({ filters: filters });
		}
	}

	render() {
		return (
			<Box w="full">
				{this.props.searchBar && (
					<Flex mb={1}>
						<VirtualListV1SearchBar handleSearchBar={this.handleSearchBar} />
					</Flex>
				)}

				<VStack
					w="full"
					className="skinnyScroll"
					spacing="4"
					maxHeight={this.state.listHeight}
					overflow="auto"
					onScroll={this.handleScroll}
				>
					{!Boolean(this.props.isSimple) &&
						this.state.data?.map((row, i) => {
							let height =
								i === 0
									? this.state.data[i].height
									: Math.abs(this.state.data[i].height - this.state.data[i - 1].height);
							return (
								<Box w="full" key={"bodyRow-" + row.key}>
									<Box w="full" cursor="pointer" shadow="sm" mr="2">
										{React.cloneElement(row.element, {
											rowNum: i,
											addHeight: this.addHeight,
											id: row.key,
											height: height - 8,
											visible: row.visible,
										})}
									</Box>
								</Box>
							);
						})}

					{Boolean(this.props.isSimple) &&
						this.state.data?.map((row, i) => {
							let height =
								i === 0
									? this.state.data[i].height
									: Math.abs(this.state.data[i].height - this.state.data[i - 1].height);

							if (row.visible) {
								return (
									<Box key={"bodyRow-" + row.key}>
										<Box
											key={row.key}
											id={row.key}
											className="hoverPointerLight"
											cursor="pointer"
											mr="2"
										>
											{React.cloneElement(row.element, {
												rowNum: i,
												addHeight: this.addHeight,
												id: row.key,
												height: height - 8,
												visible: row.visible,
											})}
										</Box>
									</Box>
								);
							} else {
								return (
									<Box
										key={row.key}
										id={row.key}
										height={height - 8 + "px"}
										minHeight={height + "px"}
									></Box>
								);
							}
						})}
				</VStack>
			</Box>
		);
	}
}

function VirtualListV1SearchBar({ handleSearchBar, autoFocusOnSearchBar }) {
	const [timer, setTimer] = useState(null);
	const [searchValue, setSearchValue] = useState("");

	const localHandleSearchBar = (ev) => {
		let value = ev.target.value;
		setTimer(clearTimeout(timer));
		setTimer(
			setTimeout(() => {
				handleSearchBar(value);
			}, 250)
		);
		setSearchValue(value);
	};

	return (
		<Box flex={1} pb="4">
			<InputGroup>
				<InputLeftElement color="gray.400" children={<i className="fas fa-search fa-lg"></i>} />
				<Input
					autoFocus={autoFocusOnSearchBar}
					name="vlSearchBar"
					bg="white"
					placeholder="Search..."
					value={searchValue}
					onChange={localHandleSearchBar}
				/>
			</InputGroup>
		</Box>
	);
}
