import React, { useState, useEffect } from "react";
import {
	Text,
	Input,
	Stack,
	HStack,
	FormControl,
	FormLabel,
	Switch,
	Select,
	InputGroup,
	InputLeftAddon,
	InputRightAddon,
} from "@chakra-ui/react";
import BasicCard from "../../../core/Layout/cards/BasicCard";
import PhoneInput from "react-phone-number-input/input";
import PhoneNumber from "awesome-phonenumber";
import CELL_PHONE_CARRIERS from "../constants/CellPhoneCarriers";

import { portalUser } from "../../../App";
import { formatValue } from "../../../helperFunctions";

export default function UserPreferencesForm({ isDrawer = false }) {
	const [triggerUpdate, setTriggerUpdate] = useState(false);
	const cellPhone = portalUser?.user?.cellPhone ?? "";

	useEffect(() => {
		if (triggerUpdate) {
			setTriggerUpdate(false);
		}
	}, [triggerUpdate]);

	const updateFirstName = async (e) => {
		await portalUser.user.updateFirstName(e.target.value);
		setTriggerUpdate(true);
	};

	const updateLastName = async (e) => {
		await portalUser.user.updateLastName(e.target.value);
		setTriggerUpdate(true);
	};

	const updateCellPhone = async (e = "") => {
		if (typeof e === "object") {
			await portalUser.user.updateCellPhone(e.target.value, e.type === "blur");
		} else {
			portalUser.user.updateCellPhone(e);
		}
		setTriggerUpdate(true);
	};

	const updatePhoneCarrier = async (e) => {
		await portalUser.user.updateCellCarrier(e.target.value);
		setTriggerUpdate(true);
	};

	const updateShirtSize = async (e) => {
		await portalUser.user.updateShirtSize(e.target.value);
		setTriggerUpdate(true);
	};

	const updateShowBirthday = async (e) => {
		await portalUser.user.updateShowBirthday(e.target.checked);
		setTriggerUpdate(true);
	};

	const updateSharePhotos = async (e) => {
		await portalUser.user.updateSharePhotos(e.target.checked);
		setTriggerUpdate(true);
	};

	return (
		<BasicCard title="User Preferences" portalStyles={portalUser.styles}>
			<Stack id="card-employeeinfo" w="full" direction="column" spacing={isDrawer ? "3" : "4"}>
				<Stack
					w="full"
					spacing={isDrawer ? "3" : "6"}
					direction={{ base: "column", lg: isDrawer ? "column" : "row" }}
				>
					<FormControl id="firstName" flex={1}>
						<FormLabel color="gray.500" letterSpacing={1} textTransform="uppercase">
							Preferred First Name
						</FormLabel>
						<InputGroup
							shadow={portalUser.styles.cardShadow}
							borderColor={portalUser.styles.cardBorderColor}
							rounded="md"
							_focus={{ borderWidth: 1.5, borderColor: "green.500", bg: "whiteAlpha.900" }}
						>
							<InputLeftAddon
								pointerEvents="none"
								children={<Text color="gray.600" fontSize="lg" className={"fas fa-id-card"} />}
							/>
							<Input
								variant="filled"
								bg="whiteAlpha.600"
								color="gray.600"
								borderWidth={1}
								borderColor={portalUser.styles.cardBorderColor}
								rounded="md"
								_hover={{ bg: "whiteAlpha.900" }}
								_focus={{ borderWidth: 1.5, borderColor: "green.500", bg: "whiteAlpha.900" }}
								type="text"
								defaultValue={portalUser.user.firstName}
								maxLength={255}
								onBlur={(e) => {
									updateFirstName(e);
								}}
							/>
						</InputGroup>
					</FormControl>

					<FormControl id="lastName" flex={1}>
						<FormLabel color="gray.500" letterSpacing={1} textTransform="uppercase">
							Preferred Last Name
						</FormLabel>
						<InputGroup
							shadow={portalUser.styles.cardShadow}
							borderColor={portalUser.styles.cardBorderColor}
							rounded="md"
							_focus={{ borderWidth: 1.5, borderColor: "green.500", bg: "whiteAlpha.900" }}
						>
							<InputLeftAddon
								pointerEvents="none"
								children={<Text color="gray.600" fontSize="lg" className={"fas fa-id-card"} />}
							/>
							<Input
								variant="filled"
								bg="whiteAlpha.600"
								color="gray.600"
								borderWidth={1}
								borderColor={portalUser.styles.cardBorderColor}
								rounded="md"
								_hover={{ bg: "whiteAlpha.900" }}
								_focus={{ borderWidth: 1.5, borderColor: "green.500", bg: "whiteAlpha.900" }}
								type="text"
								defaultValue={portalUser.user?.lastName}
								maxLength={255}
								onBlur={(e) => {
									updateLastName(e);
								}}
							/>
						</InputGroup>
					</FormControl>
				</Stack>

				<Stack
					w="full"
					spacing={isDrawer ? "3" : "4"}
					direction={{ base: "column", lg: isDrawer ? "column" : "row" }}
				>
					<FormControl id="workCell" flex={1}>
						<FormLabel
							color={new PhoneNumber(cellPhone, "US").isValid() || cellPhone === "" ? "gray.500" : "red"}
							letterSpacing={1}
							textTransform="uppercase"
						>
							Cell Phone
						</FormLabel>
						<InputGroup
							shadow={portalUser.styles.cardShadow}
							borderColor={portalUser.styles.cardBorderColor}
							rounded="md"
							_focus={{ borderWidth: 1.5, borderColor: "teal.500", bg: "whiteAlpha.900" }}
						>
							<InputLeftAddon
								pointerEvents="none"
								children={<Text color="gray.600" fontSize="lg" className={"fas fa-mobile-alt"} />}
							/>
							<Input
								variant="filled"
								bg="whiteAlpha.600"
								color="gray.600"
								borderWidth={1}
								borderColor={portalUser.styles.cardBorderColor}
								rounded="md"
								_hover={{ bg: "whiteAlpha.900" }}
								_focus={{
									borderWidth: 1.5,
									borderColor: new PhoneNumber(cellPhone, "US").isValid() ? "green.500" : "red.500",
									bg: "whiteAlpha.900",
								}}
								as={PhoneInput}
								country="US"
								type="text"
								placeholder="(###) ###-####"
								value={new PhoneNumber(cellPhone, "US").getNumber()}
								isInvalid={cellPhone === "" ? false : !new PhoneNumber(cellPhone, "US").isValid()}
								onBlur={updateCellPhone}
								onChange={updateCellPhone}
							/>
							<InputRightAddon
								children={
									<Select
										className="skinnyScroll"
										variant="unstyled"
										type="text"
										onChange={(e) => {
											updatePhoneCarrier(e);
										}}
										placeholder="Cell Carrier"
										defaultValue={portalUser.user?.cellCarrier}
									>
										{CELL_PHONE_CARRIERS?.map((carrier, i) => (
											<option key={i} value={carrier.value}>
												{carrier.name}
											</option>
										))}
									</Select>
								}
							/>
						</InputGroup>
					</FormControl>

					<FormControl id="shirtSize" flex={1}>
						<FormLabel color="gray.500" letterSpacing={1} textTransform="uppercase">
							Shirt Size
						</FormLabel>
						<InputGroup
							shadow={portalUser.styles.cardShadow}
							borderColor={portalUser.styles.cardBorderColor}
							_focus={{ borderWidth: 1.5, borderColor: "green.500", bg: "whiteAlpha.900" }}
						>
							<InputLeftAddon
								pointerEvents="none"
								children={<Text color="gray.600" fontSize="lg" className={"fas fa-tshirt"} />}
							/>

							<Select
								variant="filled"
								bg="whiteAlpha.600"
								color="gray.600"
								borderWidth={1}
								borderColor={portalUser.styles.cardBorderColor}
								borderTopRightRadius="md"
								borderTopLeftRadius={0}
								borderBottomRightRadius="md"
								borderBottomLeftRadius={0}
								_hover={{ bg: "whiteAlpha.900" }}
								_focus={{ borderWidth: 1.5, borderColor: "green.500", bg: "whiteAlpha.900" }}
								placeholder="Select Shirt Size"
								onChange={(e) => {
									updateShirtSize(e);
								}}
								defaultValue={portalUser.user?.shirtSize}
							>
								<option value="S">S - Small</option>
								<option value="M">M - Medium</option>
								<option value="L">L - Large</option>
								<option value="XL">XL - Extra Large</option>
								<option value="2XL">2XL - 2x Extra Large</option>
								<option value="3XL">3XL - 3x Extra Large</option>
							</Select>
						</InputGroup>
					</FormControl>
				</Stack>

				<Stack
					w="full"
					spacing={isDrawer ? "3" : "6"}
					direction={{ base: "column", lg: isDrawer ? "column" : "row" }}
				>
					<FormControl id="shareBirthday" flex={1}>
						<FormLabel color="gray.500" letterSpacing={1} textTransform="uppercase">
							Share Birthday
						</FormLabel>
						<InputGroup
							shadow={portalUser.styles.cardShadow}
							borderColor={portalUser.styles.cardBorderColor}
							_focus={{ borderWidth: 1.5, borderColor: "teal.500", bg: "whiteAlpha.900" }}
						>
							<InputLeftAddon
								pointerEvents="none"
								children={<Text color="gray.600" fontSize="lg" className={"fas fa-birthday-cake"} />}
							/>
							<Input
								// isReadOnly
								variant="filled"
								bg="whiteAlpha.600"
								color="gray.600"
								borderWidth={1}
								borderColor={portalUser.styles.cardBorderColor}
								rounded="md"
								_hover={{ bg: "whiteAlpha.900" }}
								_focus={{
									borderWidth: 1.5,
									borderColor: "teal.500",
									bg: "whiteAlpha.900",
								}}
								type="text"
								cursor="not-allowed"
								value={formatValue(portalUser.user.birthDay, 0, "monthDay")}
							/>
							<InputRightAddon
								children={
									<HStack align="center">
										<Switch
											onChange={(e) => {
												updateShowBirthday(e);
											}}
											colorScheme="teal"
											size="md"
											mb="0"
											isChecked={portalUser.user?.shareBirthdayYN === "Y"}
										/>
										<Text>Allow</Text>
									</HStack>
								}
							/>
						</InputGroup>
					</FormControl>

					<FormControl id="sharePhotos" flex={1}>
						<FormLabel color="gray.500" letterSpacing={1} textTransform="uppercase">
							Share Photos
						</FormLabel>
						<InputGroup
							shadow={portalUser.styles.cardShadow}
							borderColor={portalUser.styles.cardBorderColor}
							_focus={{ borderWidth: 1.5, borderColor: "teal.500", bg: "whiteAlpha.900" }}
						>
							<InputLeftAddon
								pointerEvents="none"
								children={<Text color="gray.600" fontSize="lg" className={"fas fa-camera"} />}
							/>
							<Input
								variant="filled"
								bg="whiteAlpha.600"
								color="gray.600"
								borderWidth={1}
								borderColor={portalUser.styles.cardBorderColor}
								rounded="md"
								_hover={{ bg: "whiteAlpha.900" }}
								_focus={{
									borderWidth: 1.5,
									borderColor: "teal.500",
									bg: "whiteAlpha.900",
								}}
								value={portalUser.user?.sharePhotosYN === "Y" ? "Share Photos" : "Don't Share Photos"}
							/>
							<InputRightAddon
								children={
									<HStack align="center">
										<Switch
											onChange={(e) => {
												updateSharePhotos(e);
											}}
											colorScheme="teal"
											size="md"
											mb="0"
											isChecked={portalUser.user?.sharePhotosYN === "Y"}
										/>
										<Text>Allow</Text>
									</HStack>
								}
							/>
						</InputGroup>
					</FormControl>
				</Stack>
			</Stack>
			{/* </Stack> */}
		</BasicCard>
	);
}
