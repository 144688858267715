const DENOMINATOR = [
	{
		title: "Current Contract Amount",
		value: "currContractAmt",
		tooltip: "Contract Amount to Date",
	},
	{ title: "Projected Contract Amount", value: "projContractAmt", tooltip: "Expected Final Amount" },
	{
		title: "Bid Contract Amount",
		value: "bidContractAmt",
		tooltip: "Amount Bid",
	},
];

export default DENOMINATOR;
