import CONTRACT_ITEM_TYPES from "../constants/ContractItemTypes";
import { convertArrayToMap } from "../../../helperFunctions";

const itemTypeMap = convertArrayToMap(CONTRACT_ITEM_TYPES, "value");

export default class ContractItem {
	constructor(contractItem) {
		this.queryData = contractItem?.queryData ?? contractItem?.QueryData ?? "ContractItem";
		this.jcciKeyID = contractItem?.jcciKeyID ?? contractItem?.JCCIKeyID ?? null;
		this.jccmKeyID = contractItem?.jccmKeyID ?? contractItem?.JCCMKeyID ?? null;

		this.jcco = contractItem?.jcco ?? contractItem?.JCCo ?? null;
		this.contract = contractItem?.contract ?? contractItem?.Contract?.trim() ?? null;
		this.contractItem = contractItem?.contractItem ?? contractItem?.ContractItem ?? null;

		this.contractItemDesc = contractItem?.contractItemDesc ?? contractItem?.ContractItemDesc ?? null;
		this.department = contractItem?.department ?? contractItem?.Department ?? null;
		this.regionID = contractItem?.regionID ?? contractItem?.RegionID ?? null;
		this.tradeID = contractItem?.tradeID ?? contractItem?.TradeID ?? null;

		this.origContractAmt = contractItem?.origContractAmt ?? contractItem?.OrigContractAmt ?? 0;
		this.contractAmt = contractItem?.contractAmt ?? contractItem?.ContractAmt ?? 0;
		this.billedToDate = contractItem?.billedToDate ?? contractItem?.BilledToDate ?? 0;
		this.receivedToDate = contractItem?.receivedToDate ?? contractItem?.ReceivedToDate ?? 0;
		this.currentRetainAmt = contractItem?.currentRetainAmt ?? contractItem?.CurrentRetainAmt ?? 0;

		this.itemTypeID = contractItem?.itemTypeID ?? contractItem?.ItemTypeID ?? "LS";
		this.itemType = this.getItemType();
		this.itemTypeSeq = this.getItemTypeSeq();
		this.title = this.getTitle();
		this.subtitle = this.getSubtitle();
	}

	getItemTypeSeq() {
		let itemTypeSeq = itemTypeMap[this.itemTypeID]?.seq ?? 6;
		return itemTypeSeq;
	}

	getItemType() {
		let itemType = itemTypeMap[this.itemTypeID]?.label ?? "Lump Sum";
		return itemType;
	}

	getTitle() {
		let title = this.contractItem?.replace(/\s+/g, "") + " - " + this.contractItemDesc;
		return title;
	}

	getSubtitle() {
		let subtitle = this.itemTypeID + " - " + this.itemType;
		return subtitle;
	}
}
