import ARCustomer from "../modules/Operations/classes/Customer";
import APVendor from "../modules/Purchasing/classes/Vendor";
import JCProjectMgr from "../modules/Operations/classes/ProjectMgr";
import PREmployee from "../modules/Employee/classes/PREmployee";

export default class Company {
	constructor(company) {
		this.queryData = company?.queryData ?? company?.QueryData ?? "Company";
		this.co = company?.co?.toString() ?? company?.Co?.toString() ?? company?.CompanyID?.toString() ?? null;
		this.jcco =
			company?.jcco?.toString() ??
			company?.JCCo?.toString() ??
			company?.co?.toString() ??
			company?.Co?.toString() ??
			company?.CompanyID?.toString() ??
			null;
		this.prco =
			company?.prco?.toString() ??
			company?.PRCo?.toString() ??
			company?.co?.toString() ??
			company?.Co?.toString() ??
			company?.CompanyID?.toString() ??
			null;
		this.company = company?.company ?? company?.Company ?? "N/A";
		this.name = company?.name ?? company?.Name ?? "Not Applicable";
		this.address = company?.address ?? company?.Address ?? "18250 Euclid Street";
		this.city = company?.city ?? company?.City ?? "Fountain Valley";
		this.state = company?.state ?? company?.State ?? "CA";
		this.zip = company?.zip ?? company?.Zip ?? "92708";
		this.vendorGroup = company?.vendorGroup ?? company?.VendorGroup ?? "1";
		this.custGroup = company?.custGroup ?? company?.CustGroup ?? "1";
		this.phaseGroup = company?.phaseGroup ?? company?.phaseGroup ?? "1";
		this.tag = company?.tag ?? company?.Tag ?? "N/A";
		this.color = company?.color ?? company?.Color ?? "gray";
		this.getColors();
		this.title = company?.title ?? this.getTitle();
		this.subtitle = company?.subtitle ?? this.getSubtitle();
	}

	getColors(color = this.color) {
		if (color === "gray") {
			this.fontColor = "var(--chakra-colors-" + color + "-600)";
			this.bg = "white";
			this.borderColor = "var(--chakra-colors-" + color + "-400)";
		} else {
			this.fontColor = "white";
			this.bg = "var(--chakra-colors-" + color + "-500)";
			this.borderColor = "var(--chakra-colors-" + color + "-700)";
		}
	}

	getTitle() {
		return this.tag + " - Company: " + this.co;
	}

	getSubtitle() {
		return this.name;
	}

	getARCustomersMap(customers = []) {
		let map = {};
		map[null] = new ARCustomer();
		let array = customers?.filter((c) => c.custGroup === this.custGroup) ?? [];

		for (let i = 0; i < array?.length; i++) {
			let keyID = array[i].customer;
			if (!Boolean(map[keyID])) {
				map[keyID] = array[i];
			}
		}
		return map;
	}

	getAPVendorsMap(vendors = []) {
		let map = {};
		map[null] = new APVendor();
		let array = vendors?.filter((c) => c.vendorGroup === this.vendorGroup) ?? [];

		for (let i = 0; i < array?.length; i++) {
			let keyID = array[i].vendor;
			if (!Boolean(map[keyID])) {
				map[keyID] = array[i];
			}
		}

		return map;
	}

	getJCProjectMgrsMap(projectMgrs = []) {
		let map = {};
		map[null] = new JCProjectMgr();
		let array = projectMgrs?.filter((c) => c.jcco === this.co) ?? [];

		for (let i = 0; i < array?.length; i++) {
			let keyID = array[i].projectMgrID;
			if (!Boolean(map[keyID])) {
				map[keyID] = array[i];
			}
		}
		if (Boolean(map["100"])) {
			map[null] = map["100"];
		}
		return map;
	}

	getPREmployeesMap(employees = []) {
		let map = {};
		map[null] = new PREmployee();
		let array = employees?.filter((c) => c.prco === this.co) ?? [];

		for (let i = 0; i < array?.length; i++) {
			let keyID = array[i].employee;
			if (!Boolean(map[keyID])) {
				map[keyID] = array[i];
			}
		}

		return map;
	}
}
