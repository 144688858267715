import React, { useMemo } from "react";
import { Box, Drawer, DrawerContent, DrawerHeader, DrawerBody, DrawerCloseButton } from "@chakra-ui/react";

export default function FullPageDrawer({ isOpen = false, onClose, children }) {
	const isExpanded = useMemo(() => isOpen, [isOpen]);

	return (
		<>
			<Drawer isOpen={isExpanded} onClose={onClose} size="full" placement="right" bg="transparent">
				<DrawerContent bg="gray.50">
					<DrawerCloseButton />
					<DrawerHeader></DrawerHeader>
					<DrawerBody>
						<Box p={8}>{children}</Box>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
			{/* <Box>
				<Box
				// onClick={() => setIsExpanded(true)}
				>
					{children}
				</Box>
			</Box> */}
		</>
	);
}
