import React, { useState, useMemo } from "react";
import { Stack, Container } from "@chakra-ui/react";
import Page from "../../../core/ReactTable/layout/Page";
import HRPageHeader from "../components/HRPageHeader";
import EmployeeEditDrawer from "../../Employee/components/EmployeeEditDrawer";
import PageTemplateEmployees from "../classes/PageTemplateEmployees";

import { portalUser } from "../../../App";

export default function HREmployeesPage(props) {
	const pageTemplate = useMemo(() => {
		if (!props.isLoading) {
			let employees = portalUser.getPREmployees() ?? [];
			return new PageTemplateEmployees(employees, { module: "HRForms" });
		} else {
			return null;
		}
	}, [props.isLoading]);

	const [selectedEmployee, setSelectedEmployee] = useState(null);

	const saveEmployee = async (employee) => {
		setSelectedEmployee(null);
	};

	const closeEmployee = () => {
		setSelectedEmployee(null);
	};

	return (
		<Stack
			spacing={0}
			p={portalUser.styles?.pagePadding}
			bg={portalUser.styles?.pageBG}
			w="full"
			h="full"
			flex={1}
			align="center"
		>
			<HRPageHeader tab={props.tab} />
			<Container
				direction="column"
				maxW={"full"}
				display={"flex"}
				minW="container.lg"
				px={0}
				flex={1}
				justify="flex-start"
				h="full"
			>
				{!props.isLoading && (
					<Page
						pageTemplate={pageTemplate}
						overrideView={{
							showReportHeader: false,
							showPageHeader: false,
							showPageFooter: false,
							shadow: "none",
							orientation: "full",
						}}
						skipReset={false}
						setSelectedRow={setSelectedEmployee}
					/>
				)}
			</Container>
			{Boolean(selectedEmployee) && (
				<EmployeeEditDrawer
					selectedEmployee={selectedEmployee}
					isOpen={Boolean(selectedEmployee)}
					closeEmployee={closeEmployee}
					saveEmployee={saveEmployee}
				/>
			)}
		</Stack>
	);
}
