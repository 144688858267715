import React, { useState, useMemo } from "react";
import { Collapse, useBoolean, Flex, Stack } from "@chakra-ui/react";
import VirtualSection from "./VirtualSection";
import SectionHeader from "./SectionHeader";
import { log } from "../../../helperFunctions";

export default function Section({ page, section, headerButtons, searchBar, setSelectedRow, skipReset = false }) {
	const [collapseIsOpen, setCollapseIsOpen] = useBoolean(true);
	const subSections = useMemo(() => section?.subSections ?? [], [section?.subSections]);

	return (
		<Flex id="Section" direction="column" w="full" flex={1} h="full" rounded="md" pb={4}>
			{subSections?.length > 1 && (
				<SectionHeader
					section={section}
					collapseIsOpen={collapseIsOpen}
					setCollapseIsOpen={setCollapseIsOpen}
					headerButtons={headerButtons}
					searchBar={searchBar}
				/>
			)}

			<Collapse
				in={collapseIsOpen}
				animateOpacity
				style={{
					display: "flex",
					flex: 1,
					flexDirection: "row",
					width: "100%",
					justifyContent: "flex-start",
					alignItems: "flex-start",
					borderRadius: "var(--chakra-radii-md)",
				}}
			>
				<Stack
					spacing={8}
					w={"full"}
					direction="column"
					flex={1}
					align="flex-start"
					position="relative"
					rounded={"md"}
					// bg="whiteAlpha.400"
				>
					{subSections?.length > 0 &&
						subSections?.map((subSection, i) => (
							<Stack
								key={i}
								w={subSection?.width ?? "full"}
								direction="column"
								flex={1}
								align="flex-start"
								position="relative"
								rounded={"md"}
							>
								{["virtualTable", "virtualList"]?.includes(subSection?.type) && (
									<Virtualize
										page={page}
										subSection={subSection}
										setSelectedRow={setSelectedRow}
										skipReset={skipReset}
									/>
								)}
							</Stack>
							// <VirtualSection
							// 	page={page}
							// 	subSection={subSection}
							// 	setSelectedRow={setSelectedRow}
							// 	sectionCollapseIsOpen={collapseIsOpen}
							// />
						))}
				</Stack>
			</Collapse>
		</Flex>
	);
}

function Virtualize({ page, subSection, setSelectedRow, collapseIsOpen, skipReset = false }) {
	const [data, setData] = useState(subSection?.data ?? []);
	const [skipPageReset, setSkipPageReset] = useState(false);

	useMemo(() => {
		setData(subSection?.data ?? []);
		setSkipPageReset(skipReset);
	}, [setSkipPageReset, skipReset, subSection?.data]);

	const columns = useMemo(() => {
		let columns = subSection?.columns?.filter((d) => Boolean(d.id)) ?? [];
		if (columns?.length === 0) {
			columns = [{ id: "id", accessor: "id", Header: "Column" }];
		}
		return columns;
	}, [subSection]);

	const initialState = useMemo(() => {
		let columnOrder = columns?.map((d) => d.id) ?? [];
		let hiddenColumns = columns?.filter((d) => !Boolean(d.show))?.map((d) => d.id) ?? [];
		let groupBy = subSection?.groupBy?.filter((d) => columnOrder?.includes(d)) ?? [];
		let sortBy = subSection?.sortBy?.filter((d) => columnOrder?.includes(d.id)) ?? [];
		let filterBy = subSection?.filterBy?.filter((d) => columnOrder?.includes(d.id)) ?? [];

		return { columnOrder, hiddenColumns, sortBy, groupBy, filters: filterBy };
	}, [columns, subSection?.filterBy, subSection?.groupBy, subSection?.sortBy]);

	// const [collapseIsOpen, setCollapseIsOpen] = useBoolean(false);
	// const [searchIsOpen, setSearchIsOpen] = useBoolean(false);
	// const [sizesIsOpen, setSizesIsOpen] = useBoolean(false);
	// const [filterIsOpen, setFilterIsOpen] = useBoolean(false);
	// const [settingsIsOpen, setSettingsIsOpen] = useBoolean(false);

	// We need to keep the table from resetting the pageIndex when we
	// Update data. So we can keep track of that flag with a ref.

	// When our cell renderer calls updateMyData, we'll use
	// the rowIndex, columnId and new value to update the
	// original data
	const updateMyData = (rowIndex, columnId, value) => {
		// We also turn on the flag to not reset the page
		// setSkipPageReset.on();
		setData((old) =>
			old.map((row, index) => {
				// log("old", old);
				if (index === rowIndex) {
					if (typeof row?.updateRow === "function") {
						// log("Update function Exists", row);

						row = row.updateRow(columnId, value);
					} else {
						row[columnId] = value;
					}
					return {
						...old[rowIndex],
						[columnId]: value,
					};
				}

				return row;
			})
		);
		setSkipPageReset(false);
	};

	// After data chagnes, we turn the flag back off
	// so that if data actually changes when we're not
	// editing it, the page is reset
	// useEffect(() => {
	// 	setSkipPageReset.off();
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [data]);

	return (
		<VirtualSection
			page={page}
			subSection={subSection}
			data={data}
			columns={columns}
			initialState={initialState}
			skipPageReset={skipPageReset}
			updateMyData={updateMyData}
			setSelectedRow={setSelectedRow}
			collapseIsOpen={collapseIsOpen}
		/>
	);
}
