import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { v4 } from "uuid";
import { formatNumber } from "../../../helperFunctions";

export default function VirtualTableHeader({
	rows,
	headerGroups,
	filterIsOpen,
	maxRows,
	maxWidth,
	expanded,
	scrollBarWidth,
}) {
	return (
		<>
			{headerGroups?.map((headerGroup, i) => {
				let expandedIcon = "fas fa-plus fa-fw ";
				if (
					Object.keys(expanded).length === rows.length &&
					Object.values(expanded).every((item) => item === true)
				) {
					expandedIcon = "fas fa-minus fa-fw ";
				}
				return (
					<React.Fragment key={i}>
						<Flex
							color="white"
							bg="teal.700"
							fontWeight="semibold"
							w="full"
							borderTopRadius={5}
							isTruncated
							borderWidth={1}
							borderColor="gray.600"
							pr={rows?.length > maxRows ? scrollBarWidth + "px" : 0}
						>
							{headerGroup.headers.map((column, i) => {
								let borderRightWidth = 1;
								let borderRightColor = "transparent";

								if (
									(rows?.length <= maxRows && headerGroup?.headers?.length - 1 === i) ||
									headerGroup?.headers?.length === 0
								) {
									borderRightWidth = 0;
								}
								if (headerGroup?.headers?.length - 1 === i) {
									borderRightColor = "transparent";
								}

								return (
									<Box
										key={i}
										{...column.getSortByToggleProps()}
										w={column.width ?? "full"}
										isTruncated
										py={1}
										px={0}
										borderRightWidth={borderRightWidth}
										borderRightColor={borderRightColor}
										align="center"
										maxW={column.id === "expander" ? "40px" : column?.width ?? "full"}
										minW={
											column.id === "expander"
												? "40px"
												: (formatNumber(column.width, 0) / 100) * 75
										}
									>
										{column.id === "expander" ? (
											<Flex
												justify="center"
												align="center"
												h="full"
												isTruncated
												w="full"
												flex={1}
												key={expandedIcon}
												p={2}
												color="var(--chakra-colors-whiteAlpha-600)"
												maxW={column.id === "expander" ? "40px" : column?.width ?? "full"}
											>
												<Text
													// onClick={() => toggleAllRowsExpanded()}
													w="full"
													fontSize="md"
													fontFamily="body"
													textAlign="center"
													rounded={5}
													_hover={{
														color: "white",
														bg: "var(--chakra-colors-whiteAlpha-400)",
													}}
												>
													<i className={"far fa-comment-alt fa-lg fa-fw"}></i>
													{/* <i className={expandedIcon}></i> */}
												</Text>
											</Flex>
										) : (
											<Flex
												justify="space-between"
												align="center"
												h="full"
												isTruncated
												w="full"
												pl={2}
											>
												<Text
													// bg="blue.200"
													textTransform="uppercase"
													fontSize="xs"
													isTruncated
													whiteSpace={column?.whiteSpace ?? "wrap"}
													letterSpacing={1}
													lineHeight={1.35}
													textAlign={"left"}
												>
													{column.Header}
												</Text>

												{column.id !== "Selection" && column.id !== "expander" && (
													<Flex
														isTruncated
														key={v4()}
														rounded={5}
														_hover={{
															color: column.isSorted
																? "white"
																: "var(--chakra-colors-whiteAlpha-800)",
															bg: "var(--chakra-colors-whiteAlpha-200)",
														}}
														color={
															column.isSorted
																? "white"
																: "var(--chakra-colors-whiteAlpha-400)"
														}
														p={1}
														fontSize="sm"
													>
														{column.isSorted ? (
															<i
																className={
																	"fas fa-sort-" +
																	column.sortIcon +
																	"-down" +
																	(column.isSortedDesc ? "-alt" : "") +
																	" fa-lg "
																}
															></i>
														) : (
															<i className="fas fa-sort fa-lg "></i>
														)}
													</Flex>
												)}
											</Flex>
										)}
									</Box>
								);
							})}
						</Flex>

						{Boolean(filterIsOpen) && (
							<Flex
								id="filterBar"
								borderLeftWidth={1}
								borderRightWidth={1}
								borderBottomWidth={1}
								borderColor="gray.500"
								bg="gray.100"
								w="full"
								isTruncated
								maxW={maxWidth}
								pr={rows?.length > maxRows ? scrollBarWidth + "px" : 0}
							>
								{headerGroup.headers.map((column, i) => {
									let borderRightWidth = 1;
									let borderRightColor = "gray.300";
									if (
										(rows?.length <= maxRows && headerGroup?.headers?.length - 1 === i) ||
										headerGroup?.headers?.length === 0
									) {
										borderRightWidth = 0;
									}
									if (headerGroup?.headers?.length - 1 === i) {
										borderRightColor = "transparent";
									}

									return (
										<Box
											key={i}
											color="gray.600"
											fontWeight="semibold"
											fontSize="sm"
											textTransform="uppercase"
											py={1}
											w={column.width ?? "full"}
											isTruncated
											borderRightWidth={borderRightWidth}
											borderRightColor={borderRightColor}
											maxW={column.id === "expander" ? "40px" : column?.width ?? "full"}
										>
											{column.id !== "Selection" &&
											column.id !== "expander" &&
											column.canFilter ? (
												<Flex flex={1} align="center" justify="center" px={1}>
													{column.render("Filter")}
												</Flex>
											) : column.id === "Selection" || column.id === "expander" ? (
												<Flex flex={1} align="center" justify="center" h="full">
													<Text
														fontSize="md"
														fontFamily="body"
														textAlign="center"
														rounded={5}
														color="gray.500"
													>
														<i className={"fas fa-filter fa-fw"}></i>
													</Text>
												</Flex>
											) : (
												<Flex w={column.width ?? "full"} flex={1} />
											)}
										</Box>
									);
								})}
							</Flex>
						)}
					</React.Fragment>
				);
			})}
		</>
	);
}
