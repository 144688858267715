import { formatValue } from "../../../helperFunctions";
import moment from "moment";
import { v4 } from "uuid";

export default class CalendarEvent {
	constructor(event) {
		this.queryData = event?.queryData ?? event?.QueryData ?? "CalendarEvent";
		this.keyID = event?.keyID ?? event?.KeyID ?? null;
		this.eventUID = (event?.eventUID ?? event?.EventUID)?.toLowerCase() ?? v4();
		this.allDay = event?.allDay ?? event?.AllDay ?? true;
		this.allDayYN = event?.allDayYN ?? (event?.AllDay ? "Y" : "N");

		this.startDate = formatValue(event?.startDate ?? event?.StartDate, 0, "date") ?? null;
		this.endDate = formatValue(event?.endDate ?? event?.EndDate, 0, "date") ?? null;

		this.title = event?.title ?? event?.Title ?? "";
		this.description = event?.description ?? event?.Description ?? "";
		this.eventType = event?.eventType ?? event?.EventType ?? "MFR";
		this.regionID = event?.regionID ?? event?.RegionID ?? null;
		this.parentDeptID = (event?.parentDeptID ?? event?.ParentDeptID)?.toString() ?? 7;
		this.deptID = (event?.deptID ?? event?.DeptID)?.toString() ?? 7;
		this.employeeUID = (event?.employeeUID ?? event?.EmployeeUID)?.toLowerCase() ?? null;
		this.meetings = event?.meetings ?? event?.Meetings ?? [];

		this.getColors();

		this.start = this.orderByEventType(this.startDate);
		this.end = this.orderByEventType(this.endDate);
	}

	orderByEventType(date) {
		// if (["MFR", "QFR", "MFRSchedule"].includes(this.eventType)) {
		return moment(new Date(formatValue(date, 0, "dateTime")))
			.add(7, "hours")
			.toDate();
		// } else {
		// 	return this.convertUTCDateToLocalDate(date);
		// }
	}

	getColors() {
		let color = "white";
		if (this.eventType === "Holiday") {
			color = "purple";
		} else if (this.eventType === "Vacation") {
			color = "red";
		} else if (this.eventType === "Company") {
			color = "pink";
		} else if (this.eventType === "Time Off") {
			color = "cyan";
		} else if (this.eventType === "Outlook") {
			color = "gray";
		}

		if (color === "white") {
			this.color = color;
			this.fontColor = "var(--chakra-colors-gray-500)";
			this.bg = color;
			this.borderColor = "var(--chakra-colors-gray-500)";
		} else {
			this.color = color;
			this.fontColor = "white";
			this.bg = "var(--chakra-colors-" + color + "-500)";
			this.borderColor = "var(--chakra-colors-" + color + "-700)";
		}
	}

	convertUTCDateToLocalDate(date) {
		date = new Date(date);

		var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

		var offset = date.getTimezoneOffset() / 60;
		var hours = date.getHours();

		newDate.setHours(hours - offset);

		return newDate;
	}
}
