import { chakraThemeColorsMap } from "../../../helperFunctions";

export default class SectionTemplateMFRComments {
	constructor(contract = null, isCRS) {
		this.template = "MFRComments";
		this.name = "Comments";
		this.label = isCRS ? "VI." : "VII.";
		this.heading = "Comments";
		this.divider = false;
		this.pagebreak = 1;
		this.type = "labelValuePairs";
		this.py = 0;
		this.subSections = [new SubSectionTemplateMFRComments(contract)];
	}
}

class SubSectionTemplateMFRComments {
	constructor(contract = null) {
		this.template = "MFRComments";
		this.type = "labelValuePairs";

		this.columns = [
			{
				accessor: "value",
				height: 600,
				py: 2,
				px: 4,
				justifyContent: "flex-start",

				borderRadius: 5,
				borderTopColor: chakraThemeColorsMap.silver,
				borderLeftColor: chakraThemeColorsMap.silver,
				borderRightColor: chakraThemeColorsMap.silver,
				borderBottomColor: chakraThemeColorsMap.silver,

				borderTopWidth: 1,
				borderLeftWidth: 1,
				borderRightWidth: 1,
				borderBottomWidth: 1,
			},
		];

		this.data = [
			{
				value: contract?.comments ?? null,
				valueLineHeight: 1.3,
			},
		];
	}
}
