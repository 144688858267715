import React, { useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import DataInput from "../../../core/Inputs/data/DataInput";

import { portalUser } from "../../../App";
import { formatValue, dateIsValid } from "../../../helperFunctions";

export default function PayPeriodSelectNew({ basepath = "new/timecard", isLoading = false, payroll = null }) {
	let params = useParams();
	let history = useHistory();

	var prEndDate = formatValue(
		params?.prEndDate ?? payroll?.prEndDate ?? portalUser.user?.prEndDate ?? null,
		0,
		"date"
	);
	let payPeriodOptions = useMemo(() => {
		if (!isLoading) {
			return payroll?.payPeriods ?? portalUser.payroll?.payPeriods ?? [];
		} else {
			return [];
		}
	}, [isLoading, payroll?.payPeriods]);

	const selectPayPeriod = (newDate) => {
		if (!dateIsValid(newDate)) {
			newDate = portalUser.user?.prEndDate ?? null;
		}
		if (Boolean(newDate) && formatValue(newDate, 0, "date") !== formatValue(params.prEndDate, 0, "date")) {
			portalUser.user.prEndDate = formatValue(newDate, 0, "date");
			history.push(`/${basepath}/${params.tab}/${formatValue(newDate, 0, "dateURL")}`);
		}
	};

	return (
		<DataInput
			type="select"
			variant="outline"
			placeholder="Select Pay Period..."
			label="Week Ending"
			labelProps={{ color: "gray.500", letterSpacing: 2, w: "full" }}
			searchable
			value={prEndDate}
			options={payPeriodOptions}
			optionValue="prEndDate" //return only the prEndDate when selected, not the entire payPeriod
			optionText="prEndDate" //show the user the prEndDate from the payPeriod Object
			onOptionSelect={selectPayPeriod}
			textAlign="left"
			minW="3xs"
			maxW="sm"
			textTransform="uppercase"
			inputProps={{
				bg: "whiteAlpha.900",
			}}
		/>
	);
}
