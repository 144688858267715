import moment from "moment";
import { portalUser } from "../../../App";
import { formatDate, log, formatNumber } from "../../../helperFunctions";
import ProjectsAPI from "../../../APIs/ProjectsAPI";
import PCO from "./PCO";
import COItem from "./COItem";

export default class Contract {
	constructor(contract) {
		let contractNum = this.getContractNo(
			contract?.contract ?? contract?.Contract?.trim() ?? null,
			contract?.wipJob ?? contract?.WIPJob?.trim() ?? contract?.Contract?.trim()
		);
		this.projectsAPI = new ProjectsAPI();
		this.queryData = contract?.queryData ?? contract?.QueryData ?? "Contract";
		this.accessYN = contract?.accessYN ?? contract?.AccessYN ?? "N";

		// Project Info
		this.jccmKeyID = contract?.jccmKeyID ?? contract?.JCCMKeyID ?? null;
		this.jcco = contract?.jcco ?? contract?.JCCo ?? "1";
		this.contract = contractNum.contractNo;
		this.description = contract?.description ?? contract?.Description ?? null;
		this.contractStatus = contract?.contractStatus ?? contract?.ContractStatus ?? "1";
		this.status = contract?.status ?? this.getStatus();
		this.wipJob = contractNum.jobNo;

		this.contractVal = contractNum.contractVal;
		this.jobVal = contractNum.jobVal;

		this.regionID = contract?.regionID ?? contract?.RegionID ?? null;
		this.tradeID = contract?.tradeID ?? contract?.TradeID ?? null;

		// Project Team
		this.custGroup = contract?.custGroup ?? contract?.CustGroup ?? "1";
		this.customer = contract?.customer ?? contract?.Customer ?? null;
		this.customerName = contract?.customerName ?? contract?.CustomerName ?? null;
		this.customerPhone = contract?.customerPhone ?? contract?.CustomerPhone ?? null;
		this.ownerName = contract?.ownerName ?? contract?.OwnerName ?? null;
		this.ownerPhone = contract?.ownerPhone ?? contract?.OwnerPhone ?? null;
		this.pmEmployeeUID = contract?.pmEmployeeUID ?? contract?.PMEmployeeUID ?? null;
		this.projectMgrID = contract?.projectMgrID ?? contract?.ProjectMgrID ?? null;
		this.pmName = contract?.pmName ?? contract?.PMName ?? null;
		this.pmCell = contract?.pmCell ?? contract?.PMCell ?? null;
		this.projectAcctID = contract?.projectAcctID ?? contract?.ProjectAcctID ?? "N/A";
		this.paName = contract?.paName ?? contract?.PAName ?? (Boolean(contractNum.contractNo) ? "N/A" : null);
		this.purchaserID = contract?.purchaserID ?? contract?.PurchaserID ?? "N/A";
		this.purchaserName =
			contract?.purchaserName ?? contract?.PurchaserName ?? (Boolean(contractNum.contractNo) ? "N/A" : null);
		this.peName = contract?.peName ?? contract?.PEName ?? null;
		this.peCell = contract?.peCell ?? contract?.PECell ?? null;
		this.foremanName = contract?.foremanName ?? contract?.ForemanName ?? null;
		this.foremanCell = contract?.foremanCell ?? contract?.ForemanCell ?? null;

		this.companyDesc = contract?.companyDesc ?? contract?.CompanyDesc ?? null;
		this.region = contract?.region ?? contract?.Region ?? null;
		this.regionDesc = contract?.regionDesc ?? contract?.RegionDesc ?? null;

		// Project Info
		this.contractTypeID = contract?.contractTypeID ?? contract?.ContractTypeID ?? "1";
		this.contractType = contract?.contractType ?? contract?.ContractType ?? null;
		this.projectTypeID = contract?.projectTypeID ?? contract?.ProjectTypeID ?? "Unspecified";
		this.projectType = contract?.projectType ?? contract?.ProjectType ?? null;
		this.platformID = contract?.platformID ?? contract?.PlatformID ?? "3";
		this.platform = contract?.platform ?? contract?.Platform ?? null;
		this.insuranceTypeID = contract?.insuranceTypeID ?? contract?.InsuranceTypeID ?? "1";
		this.insuranceType = contract?.insuranceType ?? contract?.InsuranceType ?? null;

		// Project Address
		this.jobAddress = contract?.jobAddress ?? contract?.JobAddress ?? "Missing Project Address";
		this.jobCity = contract?.city ?? contract?.JobCity ?? null;
		this.jobState = contract?.state ?? contract?.JobState ?? null;
		this.jobZip = contract?.zip ?? contract?.JobZip ?? null;

		// Scope of Work %
		this.pctPLUM = contract?.pctPLUM ?? contract?.PctPLUM ?? 0;
		this.pctHVAC = contract?.pctHVAC ?? contract?.PctHVAC ?? 0;
		this.pctPIPE = contract?.pctPIPE ?? contract?.PctPIPE ?? 0;
		this.pctFUEL = contract?.pctFUEL ?? contract?.PctFUEL ?? 0;
		this.pctPROC = contract?.pctPROC ?? contract?.PctPROC ?? 0;
		this.pctFIRE = contract?.pctFIRE ?? contract?.PctFIRE ?? 0;
		this.pctSITE = contract?.pctSITE ?? contract?.PctSITE ?? 0;

		// Diversity Requirements
		this.pctLOCAL = contract?.pctLOCAL ?? contract?.PctLocalHire ?? 0;
		this.pctMBE = contract?.pctMBE ?? contract?.PctMBE ?? 0;
		this.pctSBE = contract?.pctSBE ?? contract?.PctSBE ?? 0;
		this.pctWBE = contract?.pctWBE ?? contract?.PctWBE ?? 0;
		this.pctDBE = contract?.pctDBE ?? contract?.PctDBE ?? 0;
		this.pctDVBE = contract?.pctDVBE ?? contract?.PctDVBE ?? 0;
		this.pctLBE = contract?.pctLBE ?? contract?.PctLBE ?? 0;
		this.pctCBE = contract?.pctCBE ?? contract?.PctCBE ?? 0;
		this.pctLSBE = contract?.pctLSBE ?? contract?.PctHUB ?? 0;

		//AdditionalInfo
		this.bondedYN = contract?.bondedYN ?? contract?.BondedYN ?? "N";
		this.billingDue = contract?.billingDue ?? contract?.BillDayOfMth ?? 15;
		this.certifiedYN = contract?.certifiedYN ?? contract?.CertifiedYN ?? "N";
		this.hrsPerManDay = contract?.hrsPerManDay ?? contract?.HrsPerManDay ?? 8;

		//Project Mths
		this.startMth = formatDate(contract?.startMth ?? contract?.StartMth) ?? null;
		this.endMth = formatDate(contract?.endMth ?? contract?.EndMth) ?? null;
		this.thruMth = formatDate(contract?.thruMth ?? contract?.ThruMth) ?? null;
		this.lastProjDate = formatDate(contract?.lastProjDate ?? contract?.LastProjDate) ?? null;

		// Safety
		this.safetyLostTimeAccidents =
			this.accessYN === "Y" ? contract?.safetyLostTimeAccidents ?? contract?.SafetyLostTimeAccidents ?? 0 : null;
		this.safetyLostDays = this.accessYN === "Y" ? contract?.safetyLostDays ?? contract?.SafetyLostDays ?? 0 : null;
		this.safetyRecordableAccidents =
			this.accessYN === "Y"
				? contract?.safetyRecordableAccidents ?? contract?.SafetyRecordableAccidents ?? 0
				: null;

		// Comments
		this.comments = this.accessYN === "Y" ? contract?.comments ?? contract?.Comments ?? null : null;

		//Project Dates
		this.origStartDate =
			this.accessYN === "Y" ? formatDate(contract?.origStartDate ?? contract?.OrigStartDate) ?? null : null;
		this.currStartDate =
			this.accessYN === "Y" ? formatDate(contract?.currStartDate ?? contract?.CurrStartDate) ?? null : null;
		this.origCloseDate =
			this.accessYN === "Y" ? formatDate(contract?.origCloseDate ?? contract?.OrigCloseDate) ?? null : null;
		this.projCloseDate =
			this.accessYN === "Y" ? formatDate(contract?.projCloseDate ?? contract?.ProjCloseDate) ?? null : null;
		this.closeDate = this.accessYN === "Y" ? formatDate(contract?.closeDate ?? contract?.CloseDate) ?? null : null;
		this.warrantyStartDate =
			this.accessYN === "Y"
				? formatDate(contract?.warrantyStartDate ?? contract?.WarrantyStartDate) ?? null
				: null;
		this.warrantyExpirationDate =
			this.accessYN === "Y"
				? formatDate(contract?.warrantyExpirationDate ?? contract?.WarrantyExpirationDate) ?? null
				: null;
		this.ddAsBuiltsDate =
			this.accessYN === "Y" ? formatDate(contract?.DDAsBuiltsDate ?? contract?.DDAsBuiltsDate) ?? null : null;
		this.adAsBuiltsDate =
			this.accessYN === "Y" ? formatDate(contract?.ADAsBuiltsDate ?? contract?.ADAsBuiltsDate) ?? null : null;
		this.ddOMsDate = this.accessYN === "Y" ? formatDate(contract?.DDOMsDate ?? contract?.DDOMsDate) ?? null : null;
		this.adOMsDate = this.accessYN === "Y" ? formatDate(contract?.ADOMsDate ?? contract?.ADOMsDate) ?? null : null;
		this.ddWarrantiesDate =
			this.accessYN === "Y" ? formatDate(contract?.DDWarrantiesDate ?? contract?.DDWarrantiesDate) ?? null : null;
		this.adWarrantiesDate =
			this.accessYN === "Y" ? formatDate(contract?.ADWarrantiesDate ?? contract?.ADWarrantiesDate) ?? null : null;
		this.ddPunchlistDate =
			this.accessYN === "Y" ? formatDate(contract?.DDPunchlistDate ?? contract?.DDPunchlistDate) ?? null : null;
		this.adPunchlistDate =
			this.accessYN === "Y" ? formatDate(contract?.ADPunchlistDate ?? contract?.ADPunchlistDate) ?? null : null;
		this.ddCustomerSurveyDate =
			this.accessYN === "Y"
				? formatDate(contract?.DDCustomerSurveyDate ?? contract?.DDCustomerSurveyDate) ?? null
				: null;
		this.adCustomerSurveyDate =
			this.accessYN === "Y"
				? formatDate(contract?.ADCustomerSurveyDate ?? contract?.ADCustomerSurveyDate) ?? null
				: null;
		this.ddTCOsDate =
			this.accessYN === "Y" ? formatDate(contract?.DDTCOsDate ?? contract?.DDTCOsDateDate) ?? null : null;
		this.adTCOsDate =
			this.accessYN === "Y" ? formatDate(contract?.ADTCODate ?? contract?.ADTCOsDateDate) ?? null : null;
		this.ddLienRightsDate =
			this.accessYN === "Y" ? formatDate(contract?.DDLienRightsDate ?? contract?.DDLienRightsDate) ?? null : null;
		this.adLienRightsDate =
			this.accessYN === "Y" ? formatDate(contract?.ADLienRightsDate ?? contract?.ADLienRightsDate) ?? null : null;
		this.ddCompleteBillingDate =
			this.accessYN === "Y"
				? formatDate(contract?.DDCompleteBillingDate ?? contract?.DDCompleteBillingDate) ?? null
				: null;
		this.adCompleteBillingDate =
			this.accessYN === "Y"
				? formatDate(contract?.ADCompleteBillingDate ?? contract?.ADCompleteBillingDate) ?? null
				: null;
		this.ddRetentionBillingDate =
			this.accessYN === "Y"
				? formatDate(contract?.DDRetentionBillingDate ?? contract?.DDRetentionBillingDate) ?? null
				: null;
		this.adRetentionBillingDate =
			this.accessYN === "Y"
				? formatDate(contract?.ADRetentionBillingDate ?? contract?.ADRetentionBillingDate) ?? null
				: null;

		// Contract Amounts
		this.origContractAmt =
			this.accessYN === "Y" ? contract?.origContractAmt ?? contract?.OrigContractAmt ?? 0 : null;
		this.adjOrigContractAmt =
			this.accessYN === "Y" ? contract?.adjOrigContractAmt ?? contract?.AdjOrigContractAmt ?? 0 : null;

		this.contractAmt = this.accessYN === "Y" ? contract?.contractAmt ?? contract?.ContractAmt ?? 0 : null;

		// Change Orders Amounts
		this.acoAmt = this.accessYN === "Y" ? contract?.acoAmt ?? contract?.ACOAmt ?? 0 : null;
		this.nonAmt = this.accessYN === "Y" ? contract?.nonAmt ?? contract?.NONAmt ?? 0 : null;
		this.pcoAmt = this.accessYN === "Y" ? contract?.pcoAmt ?? contract?.PCOAmt ?? 0 : null;
		this.pcoConv = this.accessYN === "Y" ? contract?.pcoConv ?? contract?.PCOConv ?? 0 : null;
		this.romAmt = this.accessYN === "Y" ? contract?.romAmt ?? contract?.ROMAmt ?? 0 : null;
		this.rmaAmt = this.accessYN === "Y" ? contract?.rmaAmt ?? contract?.RMAAmt ?? 0 : null;
		this.pmaAmt = this.accessYN === "Y" ? contract?.pmaAmt ?? contract?.PMAAmt ?? 0 : null;
		this.allowAmt = this.accessYN === "Y" ? contract?.allowAmt ?? contract?.AllowAmt ?? 0 : null;
		this.pcoEstCost = this.accessYN === "Y" ? contract?.pcoEstCost ?? contract?.PCOEstCost ?? 0 : null;
		this.pcoActualCost = this.accessYN === "Y" ? contract?.pcoActualCost ?? contract?.PCOActualCost ?? 0 : null;

		//Labor Hours & Job Cost Totals
		this.origEstHours = this.accessYN === "Y" ? contract?.origEstHours ?? contract?.OrigEstHours ?? 0 : null;
		this.currEstHours = this.accessYN === "Y" ? contract?.currEstHours ?? contract?.CurrEstHours ?? 0 : null;
		this.actualHours = this.accessYN === "Y" ? contract?.actualHours ?? contract?.ActualHours ?? 0 : null;
		this.projHours = this.accessYN === "Y" ? contract?.projHours ?? contract?.ProjHours ?? 0 : null;
		this.origEstCost = this.accessYN === "Y" ? contract?.origEstCost ?? contract?.OrigEstCost ?? 0 : null;
		this.currEstCost = this.accessYN === "Y" ? contract?.currEstCost ?? contract?.CurrEstCost ?? 0 : null;
		this.actualCost = this.accessYN === "Y" ? contract?.actualCost ?? contract?.ActualCost ?? 0 : null;
		this.remainCmtdCost = this.accessYN === "Y" ? contract?.remainCmtdCost ?? contract?.RemainCmtdCost ?? 0 : null;
		this.projCost = this.accessYN === "Y" ? contract?.projCost ?? contract?.ProjCost ?? 0 : null;

		// Accounts Recievable
		this.billedAmt = this.accessYN === "Y" ? contract?.billedAmt ?? contract?.BilledAmt ?? 0 : null;
		this.receivedAmt = this.accessYN === "Y" ? contract?.receivedAmt ?? contract?.ReceivedAmt ?? 0 : null;
		this.retentionAmt = this.accessYN === "Y" ? contract?.retentionAmt ?? contract?.RetentionAmt ?? 0 : null;
		this.netCashFlow = this.accessYN === "Y" ? contract?.netCashFlow ?? contract?.netCashFlow ?? 0 : null;

		// FORMULAS AND CALCS
		this.title = contract?.title ?? this.getTitle();
		this.subtitle = contract?.subtitle ?? this.getSubtitle();
		this.projectCity = contract?.projectCity ?? this.getProjectCity();

		// PROFIT % AND MARGIN %
		this.bidContractAmt = this.accessYN === "Y" ? this.getBidContractAmt() ?? 0 : null;
		this.projContractAmt = this.accessYN === "Y" ? this.getProjContractAmt() ?? 0 : null;
		this.bidProfitAmt = this.accessYN === "Y" ? this.getBidProfitAmt() ?? 0 : null;
		this.bidMarginPct = this.accessYN === "Y" ? this.getBidMarginPct() ?? 0 : null;
		this.currProfitAmt = this.accessYN === "Y" ? this.getCurrProfitAmt() ?? 0 : null;
		this.currMarginPct = this.accessYN === "Y" ? this.getCurrMarginPct() ?? 0 : null;
		this.projProfitAmt = this.accessYN === "Y" ? this.getProjProfitAmt() ?? 0 : null;
		this.projMarginPct = this.accessYN === "Y" ? this.getProjMarginPct() ?? 0 : null;
		this.varProfitAmt = this.accessYN === "Y" ? this.getVarProfitAmt() ?? 0 : null;
		this.varMarginPct = this.accessYN === "Y" ? this.getVarMarginPct() ?? 0 : null;

		this.costToDatePct = this.accessYN === "Y" ? this.getPctCompleteCosts() ?? 0 : null;
		this.billedToDatePct = this.accessYN === "Y" ? this.getBilledToDatePct() ?? 0 : null;
		this.retentionPct = this.accessYN === "Y" ? this.getRetentionPct() ?? 0 : null;
		this.pcos = contract?.pcos ?? [];
		this.acos = contract?.acos ?? [];
	}

	updateContract() {
		this.acos =
			portalUser.contractData?.filter((d) => d.queryData === "ACO" && d.jccmKeyID === this.jccmKeyID) ?? [];

		if (this.accessYN === "Y" && this.acos.length > 0) {
			let acos = this.acos?.filter((d) => d.nonContractYN === "N") ?? [];
			let nons = this.acos?.filter((d) => d.nonContractYN === "Y") ?? [];
			this.acoAmt = acos?.map((aco) => aco?.acoAmt).reduce((prev, curr) => prev + curr, 0) ?? 0;
			this.nonAmt = nons?.map((aco) => aco?.acoAmt).reduce((prev, curr) => prev + curr, 0) ?? 0;
		}

		this.pcos =
			portalUser.contractData?.filter(
				(d) => d.queryData === "PCO" && d.jccmKeyID === this.jccmKeyID && d.pcoStatus !== "APPD"
			) ?? [];

		if (this.accessYN === "Y" && this.pcos.length > 0) {
			let pcos = this.pcos?.filter((d) => ["PEND", "CREDIT"].includes(d.pcoStatus)) ?? [];
			let roms = this.pcos?.filter((d) => ["ROM"].includes(d.pcoStatus)) ?? [];
			let rmas = this.pcos?.filter((d) => ["RMA"].includes(d.pcoStatus)) ?? [];
			let allows = this.pcos?.filter((d) => ["ALLOW"].includes(d.pcoStatus)) ?? [];
			let costs = this.pcos?.filter((d) => ["PEND", "CREDIT", "RMA", "ALLOW"].includes(d.pcoStatus)) ?? [];

			this.pcoAmt = pcos?.map((pco) => pco?.pcoAmt).reduce((prev, curr) => prev + curr, 0) ?? 0;
			this.pcoConv = pcos?.map((pco) => pco?.pcoConv).reduce((prev, curr) => prev + curr, 0) ?? 0;
			this.romAmt = roms?.map((pco) => pco?.romAmt).reduce((prev, curr) => prev + curr, 0) ?? 0;
			this.rmaAmt = rmas?.map((pco) => pco?.pcoAmt).reduce((prev, curr) => prev + curr, 0) ?? 0;
			this.pmaAmt = rmas?.map((pco) => pco?.pcoConv).reduce((prev, curr) => prev + curr, 0) ?? 0;
			this.allowAmt = allows?.map((pco) => pco?.pcoConv).reduce((prev, curr) => prev + curr, 0) ?? 0;
			this.pcoEstCost = costs?.map((pco) => pco?.pcoEstCost).reduce((prev, curr) => prev + curr, 0) ?? 0;
			this.pcoActualCost = costs?.map((pco) => pco?.pcoActualCost).reduce((prev, curr) => prev + curr, 0) ?? 0;
		}

		// FORMULAS AND CALCS
		this.status = this.getStatus();
		this.title = this.getTitle();
		this.subtitle = this.getSubtitle();
		this.projectCity = this.getProjectCity();

		if (this.accessYN === "Y") {
			this.bidContractAmt = this.getBidContractAmt();
			this.projContractAmt = this.getProjContractAmt();
			this.bidProfitAmt = this.getBidProfitAmt();
			this.bidMarginPct = this?.getBidMarginPct();
			this.currProfitAmt = this.getCurrProfitAmt();
			this.currMarginPct = this.getCurrMarginPct();
			this.projProfitAmt = this.getProjProfitAmt();
			this.projMarginPct = this.getProjMarginPct();
			this.varProfitAmt = this?.getVarProfitAmt();
			this.varMarginPct = this.getVarMarginPct();
			this.costToDatePct = this.getPctCompleteCosts();
			this.billedToDatePct = this.getBilledToDatePct();
			this.retentionPct = this.getRetentionPct();
		}

		let contractTotals =
			portalUser.contractData?.find(
				(d) =>
					d.queryData === "ContractTotalsByMth" &&
					d.jccmKeyID === this.jccmKeyID &&
					moment(d.mth).format("MMM YYYY") === moment(new Date()).format("MMM YYYY")
			) ?? null;

		log("ContractTotals", contractTotals);
	}

	getContractNo(contract = "", wipJob = "") {
		let contractNo = contract?.trim()?.toUpperCase()?.replace(/\s/g, "") ?? "";
		let contractVal = null;
		if (!isNaN(parseFloat(contractNo))) {
			contractVal = parseFloat(contractNo);
		} else {
			let char = contractNo?.charAt(0).toLowerCase();
			char = char.charCodeAt(0) - 97;
			contractVal = char + contractNo.slice(1);
			if (!isNaN(parseFloat(contractVal))) {
				contractVal = parseFloat(contractVal);
			} else {
				// let char = contractNo?.charAt(0).charCodeAt(0) - 97
				// contractNo = char + contractNo.slice(1);
				// contractVal = parseFloat(contractNo)
			}
		}

		let jobNo = wipJob?.trim()?.toUpperCase()?.replace(/\s/g, "") ?? "";
		let jobVal = null;
		if (!isNaN(parseFloat(jobNo))) {
			jobVal = parseFloat(jobNo);
		} else {
			let char = jobNo?.charAt(0).toLowerCase();
			char = char.charCodeAt(0) - 97;
			jobVal = char + jobNo.slice(1);
			if (!isNaN(parseFloat(jobVal))) {
				jobVal = parseFloat(jobVal);
			} else {
				// let char = contractNo?.charAt(0).charCodeAt(0) - 97
				// contractNo = char + contractNo.slice(1);
				// contractVal = parseFloat(contractNo)
			}
		}

		return { contractNo: contractNo, contractVal: contractVal, jobNo: jobNo, jobVal: jobVal };
	}

	getTitle() {
		let title = this.contract + " " + this.description;
		return title;
	}

	getSubtitle() {
		let subtitle;
		if (this.contractStatus === "1") {
			subtitle = this.pmName + " (Company:" + this.jcco + " Status: Open)";
		} else {
			subtitle = "Co:" + this.jcco + " Status: Closed";
		}
		return subtitle;
	}

	getStatus() {
		let status = null;
		if (this.contractStatus === "0") {
			status = "Pending";
		} else if (this.contractStatus === "1") {
			status = "Open";
		} else if (this.contractStatus === "2") {
			status = "Soft Closed";
		} else if (this.contractStatus === "3") {
			status = "Closed";
		}
		return status;
	}

	getProjectCity() {
		let projectCity;
		if (this.jobCity === null) {
			projectCity = "Missing Project City";
		} else if (this.jobState === null) {
			projectCity = "Missing Project State";
		} else {
			projectCity = this.jobCity + ", " + this.jobState;
		}
		return projectCity;
	}

	/*****************************************************************/
	/******************* GENERAL MATH FORMULAS *********************/
	/**************************************************************/

	// GENERAL MATH FUNCTIONS
	getPct(numerator = 0, denominator = 0) {
		let pct = 0;
		if (numerator === null || denominator === null || isNaN(numerator) || isNaN(denominator)) {
			pct = null;
		} else if (parseFloat(denominator) !== 0) {
			pct = (parseFloat(numerator ?? 0) / parseFloat(denominator)) * 100;
		}
		return pct;
	}

	getRate(dollars = 0, hours = 0) {
		let rate = 0;
		if (dollars === null || hours === null || isNaN(dollars) || isNaN(hours)) {
			rate = null;
		} else if (parseFloat(hours) !== 0) {
			rate = parseFloat(dollars ?? 0) / parseFloat(hours);
		}
		return rate;
	}

	/*****************************************************************/
	/*************** TOTAL CONTRACT AMOUNTS ($) *********************/
	/************ (CONTRACT AMOUNT + ADJUSTMENT AMOUNTS) **********/
	/*************************************************************/

	getBidContractAmt() {
		return parseFloat(this.origContractAmt ?? 0) + parseFloat(this.adjOrigContractAmt ?? 0);
	}

	getProjContractAmt() {
		return (
			parseFloat(this.contractAmt ?? 0) +
			parseFloat(this.pcoConv ?? 0) +
			parseFloat(this.pmaAmt ?? 0) +
			parseFloat(this.allowAmt ?? 0)
		);
	}

	/*****************************************************************/
	/*************** TOTAL CONTRACT PROFIT ($) *********************/
	/************ (CONTRACT AMOUNT - PROFIT AMOUNT) ***************/
	/*************************************************************/

	getBidProfitAmt() {
		return this.getBidContractAmt() - parseFloat(this.origEstCost ?? 0);
	}

	getCurrProfitAmt() {
		return parseFloat(this.contractAmt ?? 0) - this.getActualCostWithPCOs();
	}

	getProjProfitAmt() {
		return this.getProjContractAmt() - this.getProjCostWithPCOs();
	}

	getVarProfitAmt() {
		return this.getProjProfitAmt() - this.getBidProfitAmt();
	}

	/*****************************************************************/
	/*************** TOTAL PERCENT MARGIN (%) **********************/
	/************ (PROFIT AMOUNT / CONTRACT AMOUNT) ***************/
	/*************************************************************/

	getBidMarginPct() {
		let bidProfitAmt = this?.getBidProfitAmt() ?? 0;
		let bidContractAmt = this?.getBidContractAmt() ?? 0;
		let bidMarginPct = this.getPct(bidProfitAmt, bidContractAmt);
		return bidMarginPct;
	}

	getCurrMarginPct() {
		return this.getPct(this?.getCurrProfitAmt(), parseFloat(this?.contractAmt ?? 0));
	}

	getProjMarginPct() {
		return this.getPct(this.getProjProfitAmt(), this.getProjContractAmt());
	}

	getVarMarginPct() {
		return this.getProjMarginPct() - this.getBidMarginPct();
	}

	/*****************************************************************/
	/************** COST TO DATE TOTALS W/ PCOS *********************/
	/*********** (COSTS TO DATE + PCO COSTS TO DATE) **************/
	/*************************************************************/

	getPCOCostToComplete() {
		return parseFloat(this.pcoEstCost ?? 0) - parseFloat(this.pcoActualCost ?? 0);
	}

	getActualCostWithPCOs() {
		return parseFloat(this.actualCost ?? 0) + parseFloat(this.pcoActualCost ?? 0);
	}

	getCurrEstCostWithPCOs() {
		return parseFloat(this.currEstCost ?? 0) + parseFloat(this.pcoEstCost ?? 0);
	}

	getProjCostWithPCOs() {
		return parseFloat(this.projCost ?? 0) + this.getPCOCostToComplete();
	}

	getCostToCompleteWithPCOs() {
		return this.getProjCostWithPCOs() - parseFloat(this.actualCost ?? 0);
	}

	getOverUnderBudgetWithPCOs() {
		return this.getProjCostWithPCOs() - this.getCurrEstCostWithPCOs();
	}

	/*****************************************************************/
	/********* PERCENT BILLED AND PERCENT COMPLETE % ****************/
	/******** (TOTALS TO DATE / TOTALS AT COMPLETION) **************/
	/*************************************************************/

	getCostToComplete() {
		return parseFloat(this.projCost ?? 0) - parseFloat(this.actualCost ?? 0);
	}

	getOverUnderBudget() {
		return parseFloat(this.projCost ?? 0) - this.getCurrEstCostWithPCOs();
	}

	/*****************************************************************/
	/********* PERCENT BILLED AND PERCENT COMPLETE % ****************/
	/******** (TOTALS TO DATE / TOTALS AT COMPLETION) **************/
	/*************************************************************/

	getBilledVsCost() {
		return parseFloat(this.billedAmt ?? 0) - parseFloat(this.actualCost ?? 0);
	}

	getReceivedVsCost() {
		return parseFloat(this.receivedAmt ?? 0) - parseFloat(this.actualCost ?? 0);
	}

	getCurrBillingBal() {
		return parseFloat(this.contractAmt ?? 0) - parseFloat(this.billedAmt ?? 0);
	}

	getProjBillingBal() {
		return this.getProjContractAmt() - parseFloat(this.billedAmt ?? 0); //this.getactualCostWithPCOs() JARED?
	}

	getBilledToDatePct() {
		return this.getPct(parseFloat(this.billedAmt ?? 0), parseFloat(this.contractAmt ?? 0));
	}

	getProjBilledPct() {
		return this.getPct(parseFloat(this.billedAmt ?? 0), this.getProjContractAmt());
	}

	getRetentionPct() {
		return this.getPct(parseFloat(this.retentionAmt ?? 0), parseFloat(this.billedAmt ?? 0));
	}

	getPctCompleteHours() {
		return this.getPct(parseFloat(this.actualHours ?? 0), parseFloat(this.projHours ?? 0));
	}

	getPctCompleteCosts() {
		return this.getPct(parseFloat(this.actualCost ?? 0), this.getProjCostWithPCOs()); //this.getactualCostWithPCOs() JARED?
	}

	/****************************************************************/
	/*************** SQL UPDATES -  JCCM AND JCJM  *****************/
	/************ Contract and Job Master Tables ******************/
	/*************************************************************/

	// Contracts - SQL UPDATE JCCM
	async updateJCCM(updates) {
		let result = await this.projectsAPI.UpdateJCCM(this.jccmKeyID, updates);
		log("DATABASE RESULT (JCCM)", result);
		return result;
	}

	getJCCMAttribute(attribute) {
		// OBJECTS
		if (attribute === "customer") {
			return "Customer";
		} else if (attribute === "contractType") {
			return "udContractType";
		} else if (attribute === "platform") {
			return "udPlatform";
		} else if (attribute === "projectType") {
			return "udProjectTypes";
		} else if (attribute === "insuranceType") {
			return "udInsurance";
		} else if (attribute === "bondedYN") {
			return "udBondedYN";
		}
		// STRINGS
		else if (attribute === "peName") {
			return "udProjectEngineer";
		} else if (attribute === "peCell") {
			return "udProjectEngineerCell";
		} else if (attribute === "ownerName") {
			return "udOwner";
		} else if (attribute === "ownerPhone") {
			return "udOwnerPhone";
		} else if (attribute === "foremanName") {
			return "udForeman";
		} else if (attribute === "foremanCell") {
			return "udForemanCell";
		} else if (attribute === "comments") {
			return "udComments";
		}
		// NUMBERS
		else if (attribute === "safetyLostTimeAccidents") {
			return "udLostTimeAccidents";
		} else if (attribute === "safetyRecordableAccidents") {
			return "udRecordableAccidents";
		} else if (attribute === "safetyLostDays") {
			return "udLostDays";
		} else if (attribute === "adjOrigContractAmt") {
			return "udAdjustedOriginalContract";
		} else if (attribute === "billingDue") {
			return "BillDayOfMth";
		} else if (attribute === "pctLOCAL") {
			return "udLocalHirePercent";
		} else if (attribute === "pctMBE") {
			return "udMBE";
		} else if (attribute === "pctSBE") {
			return "udSBE";
		} else if (attribute === "pctWBE") {
			return "udWBE";
		} else if (attribute === "pctDBE") {
			return "udDBE";
		} else if (attribute === "pctDVBE") {
			return "udDVBE";
		} else if (attribute === "pctLBE") {
			return "udLBE";
		} else if (attribute === "pctCBE") {
			return "udCBE";
		} else if (attribute === "pctLSBE") {
			return "udHUB";
		}

		// DATES
		else if (attribute === "origStartDate") {
			return "udOrigStartDate";
		} else if (attribute === "currStartDate") {
			return "udCurrentStartDate";
		} else if (attribute === "origCloseDate") {
			return "udOrigCompletionDate";
		} else if (attribute === "projCloseDate") {
			return "ProjCloseDate";
		} else if (attribute === "ddAsBuiltsDate") {
			return "udDDAsBuilts";
		} else if (attribute === "adAsBuiltsDate") {
			return "udADAsBuilts";
		} else if (attribute === "ddOMsDate") {
			return "udDDOMs";
		} else if (attribute === "adOMsDate") {
			return "udADOMs";
		} else if (attribute === "ddWarrantiesDate") {
			return "udDDWarranties";
		} else if (attribute === "adWarrantiesDate") {
			return "udADWarranties";
		} else if (attribute === "ddPunchlistDate") {
			return "udDDPunchlist";
		} else if (attribute === "adPunchlistDate") {
			return "udADPunchlist";
		} else if (attribute === "warrantyStartDate") {
			return "udWarrantyStartDate";
		} else if (attribute === "warrantyExpirationDate") {
			return "udWarrantyExpirationDate";
		} else if (attribute === "ddCustomerSurveyDate") {
			return "udDDCustomerSurvey";
		} else if (attribute === "adCustomerSurveyDate") {
			return "udADCustomerSurvey";
		} else if (attribute === "ddTCOsDate") {
			return "udDDTCO";
		} else if (attribute === "adTCOsDate") {
			return "udADTCO";
		} else if (attribute === "ddLienRightsDate") {
			return "udDDLienRights";
		} else if (attribute === "adLienRightsDate") {
			return "udADLienRights";
		} else if (attribute === "ddCompleteBillingDate") {
			return "udDDCompleteBilling";
		} else if (attribute === "adCompleteBillingDate") {
			return "udADCompleteBilling";
		} else if (attribute === "ddRetentionBillingDate") {
			return "udDDRetentionBilling";
		} else if (attribute === "adRetentionBillingDate") {
			return "udADRetentionBilling";
		}
	}

	// JOBS - SQL UPDATE JCJM
	async updateJCJM(updates) {
		let resultJCJM = await this.projectsAPI.UpdateJCJM(this.jccmKeyID, updates);
		log("DATABASE RESULT (JCJM)", resultJCJM);
		return resultJCJM;
	}

	getJCJMAttribute(attribute) {
		if (attribute === "projectMgr") {
			return "ProjectMgr";
		} else if (attribute === "projectAcct") {
			return "udPA";
		} else if (attribute === "purchaser") {
			return "udPurchasers";
		} else if (attribute === "jobAddress") {
			return "MailAddress";
		} else if (attribute === "jobCity") {
			return "MailCity";
		} else if (attribute === "jobState") {
			return "MailState";
		} else if (attribute === "jobZip") {
			return "MailZip";
		}
	}

	async update(attribute = null, value = null) {
		// log(attribute, value);

		let objects = [
			"projectMgr",
			"projectAcct",
			"purchaser",
			"customer",
			"contractType",
			"projectType",
			"platform",
			"insuranceType",
		];
		let strings = [
			"jobAddress",
			"jobCity",
			"jobState",
			"peName",
			"peCell",
			"ownerName",
			"ownerPhone",
			"foremanName",
			"foremanCell",
			"bondedYN",
			"comments",
		];

		let numbers = [
			"jobZip",
			"safetyLostTimeAccidents",
			"safetyRecordableAccidents",
			"safetyLostDays",
			"adjOrigContractAmt",
			"billingDue",
			"pctLOCAL",
			"pctMBE",
			"pctSBE",
			"pctWBE",
			"pctDBE",
			"pctDVBE",
			"pctLBE",
			"pctCBE",
			"pctLSBE",
		];

		let dates = [
			"origStartDate",
			"currStartDate",
			"origCloseDate",
			"projCloseDate",
			"ddAsBuiltsDate",
			"adAsBuiltsDate",
			"ddOMsDate",
			"adOMsDate",
			"ddWarrantiesDate",
			"adWarrantiesDate",
			"ddPunchlistDate",
			"adPunchlistDate",
			"warrantyStartDate",
			"warrantyExpirationDate",
			"ddCustomerSurveyDate",
			"adCustomerSurveyDate",
			"ddTCOsDate",
			"adTCOsDate",
			"ddLienRightsDate",
			"adLienRightsDate",
			"ddCompleteBillingDate",
			"adCompleteBillingDate",
			"ddRetentionBillingDate",
			"adRetentionBillingDate",
		];

		let jcjmAttributes = ["projectMgr", "projectAcct", "purchaser", "jobAddress", "jobCity", "jobState", "jobZip"];

		let val = null;
		// OBJECTS
		if (objects.includes(attribute)) {
			if (attribute === "projectMgr") {
				this.projectMgrID = value?.projectMgrID ?? this.projectMgrID;
				this.pmName = value?.pmName ?? this.pmName;
				this.pmCell = value?.pmCell ?? this.pmCell;
				val = this.projectMgrID;
			} else if (attribute === "projectAcct") {
				this.projectAcctID = value?.databaseValue ?? this.projectAcctID;
				this.paName = value?.displayValue ?? this.paName;
				val = this.projectAcctID;
			} else if (attribute === "purchaser") {
				this.purchaserID = value?.databaseValue ?? this.purchaserID;
				this.purchaserName = value?.displayValue ?? this.purchaserName;
				val = this.purchaserID;
			} else if (attribute === "customer") {
				this.customer = value?.customer ?? this.customer;
				this.customerName = value?.customerName ?? this.customerName;
				this.customerPhone = value?.customerPhone ?? this.customerPhone;
				val = this.customer;
			} else if (attribute === "contractType") {
				this.contractTypeID = value?.databaseValue ?? this.contractTypeID;
				this.contractType = value?.displayValue ?? this.contractType;
				val = this.contractTypeID;
			} else if (attribute === "platform") {
				this.platformID = value?.databaseValue ?? this.platformID;
				this.platform = value?.displayValue ?? this.platform;
				val = this.platformID;
			} else if (attribute === "projectType") {
				this.projectTypeID = value?.databaseValue ?? this.projectTypeID;
				this.projectType = value?.displayValue ?? this.projectType;
				val = this.projectTypeID;
			} else if (attribute === "insuranceType") {
				this.insuranceTypeID = value?.databaseValue ?? this.insuranceTypeID;
				this.insuranceType = value?.displayValue ?? this.insuranceType;
				val = this.insuranceTypeID;
			}
		}
		// STRINGS
		else if (strings.includes(attribute)) {
			val = value?.replace("'", "`") ?? null;
			if (attribute !== "comments") {
				val = val?.replace(/\n/g, " ")?.trim() ?? null;
			}

			if (["jobCity", "jobState"].includes(attribute)) {
				if (attribute === "jobState") {
					val = val?.toUpperCase() ?? this.jobState;
				}
				this[attribute] = val;
				this.getProjectCity();
			} else {
				this[attribute] = val;
			}
		}
		// NUMBERS
		else if (numbers.includes(attribute)) {
			val = parseFloat(value?.replace("$", "")?.replace(",", "")?.replace(" ", "")?.replace("%", "") ?? 0);
			if (!Boolean(val) || isNaN(val)) {
				val = 0;
			}
			if (
				["pctLOCAL", "pctMBE", "pctSBE", "pctWBE", "pctDBE", "pctDVBE", "pctLBE", "pctCBE", "pctLSBE"].includes(
					attribute
				)
			) {
				if (parseFloat(val) < 0) {
					val = 0;
				} else if (parseFloat(val) > 100) {
					val = 100;
				}
				this[attribute] = val;
				val = parseFloat(formatNumber(val, 1)) / 100;
			} else if (attribute === "adjOrigContractAmt") {
				this.adjOrigContractAmt = parseFloat(val ?? 0) - parseFloat(this.origContractAmt ?? 0);
				this.bidContractAmt = this.getBidContractAmt() ?? 0;
				this.bidProfitAmt = this.getBidProfitAmt() ?? 0;
				this.bidMarginPct = this.getBidMarginPct() ?? 0;
				this.varProfitAmt = this?.getVarProfitAmt() ?? 0;
				this.varMarginPct = this.getVarMarginPct() ?? 0;
			} else {
				this[attribute] = val;
			}
		}
		// DATES
		else if (dates.includes(attribute)) {
			if (Boolean(value)) {
				val = moment(new Date(value)).format("MM/DD/YYYY");
				this[attribute] = val;
			}
		}

		// Update JCJM
		if (jcjmAttributes.includes(attribute)) {
			let dbAttribute = this.getJCJMAttribute(attribute);
			let update = { [dbAttribute]: val };
			log("JCJM update", update);
			let result = await this.updateJCJM(update);
			return result;
		}
		// Update JCCM
		else {
			let dbAttribute = this.getJCCMAttribute(attribute);
			let update = { [dbAttribute]: val };
			log("JCCM update", update);
			let result = await this.updateJCCM(update);

			return result;
		}
	}

	// CONTRACT STATUS
	async updateAdjOrigContractAmt(value) {
		if (!isNaN(value)) {
			let update = { udAdjustedOriginalContract: value };
			this.updateJCCM(update);
		}
	}

	getCloseOutDates() {
		let closeOutDates = [
			{
				letter: "A.",
				name: "As-Builts",
				dueColumn: "ddAsBuiltsDate",
				actColumn: "adAsBuiltsDate",
				dueDate: Boolean(this.ddAsBuiltsDate)
					? moment(new Date(this.ddAsBuiltsDate)).format("MM/DD/YYYY")
					: null,
				actDate: Boolean(this.adAsBuiltsDate)
					? moment(new Date(this.adAsBuiltsDate)).format("MM/DD/YYYY")
					: null,
			},
			{
				letter: "B.",
				name: "O & M's",
				dueColumn: "ddOMsDate",
				actColumn: "adOMsDate",
				dueDate: Boolean(this.ddOMsDate) ? moment(new Date(this.ddOMsDate)).format("MM/DD/YYYY") : null,
				actDate: Boolean(this.adOMsDate) ? moment(new Date(this.adOMsDate)).format("MM/DD/YYYY") : null,
			},
			{
				letter: "C.",
				name: "Warranties",
				dueColumn: "ddWarrantiesDate",
				actColumn: "adWarrantiesDate",
				dueDate: Boolean(this.ddWarrantiesDate)
					? moment(new Date(this.ddWarrantiesDate)).format("MM/DD/YYYY")
					: null,
				actDate: Boolean(this.adWarrantiesDate)
					? moment(new Date(this.adWarrantiesDate)).format("MM/DD/YYYY")
					: null,
			},
			{
				letter: "D.",
				name: "Punchlist",
				dueColumn: "ddPunchlistDate",
				actColumn: "adPunchlistDate",
				dueDate: Boolean(this.ddPunchlistDate)
					? moment(new Date(this.ddPunchlistDate)).format("MM/DD/YYYY")
					: null,
				actDate: Boolean(this.adPunchlistDate)
					? moment(new Date(this.adPunchlistDate)).format("MM/DD/YYYY")
					: null,
			},
			{
				letter: "E.",
				name: "Warranty Period",
				dueColumn: "warrantyStartDate",
				actColumn: "warrantyExpirationDate",
				dueDate: Boolean(this.warrantyStartDate)
					? moment(new Date(this.warrantyStartDate)).format("MM/DD/YYYY")
					: null,
				actDate: Boolean(this.warrantyExpirationDate)
					? moment(new Date(this.warrantyExpirationDate)).format("MM/DD/YYYY")
					: null,
			},
			{
				letter: "F.",
				name: "Customer Survey",
				dueColumn: "ddCustomerSurveyDate",
				actColumn: "adCustomerSurveyDate",
				dueDate: Boolean(this.ddCustomerSurveyDate)
					? moment(new Date(this.ddCustomerSurveyDate)).format("MM/DD/YYYY")
					: null,
				actDate: Boolean(this.adCustomerSurveyDate)
					? moment(new Date(this.adCustomerSurveyDate)).format("MM/DD/YYYY")
					: null,
			},
			{
				letter: "G.",
				name: "T.C.O",
				dueColumn: "ddTCOsDate",
				actColumn: "adTCOsDate",
				dueDate: Boolean(this.ddTCOsDate) ? moment(new Date(this.ddTCOsDate)).format("MM/DD/YYYY") : null,
				actDate: Boolean(this.adTCOsDate) ? moment(new Date(this.adTCOsDate)).format("MM/DD/YYYY") : null,
			},
			{
				letter: "H.",
				name: "Lien Rights Expire",
				dueColumn: "ddLienRightsDate",
				actColumn: "adLienRightsDate",
				dueDate: Boolean(this.ddLienRightsDate)
					? moment(new Date(this.ddLienRightsDate)).format("MM/DD/YYYY")
					: null,
				actDate: Boolean(this.adLienRightsDate)
					? moment(new Date(this.adLienRightsDate)).format("MM/DD/YYYY")
					: null,
			},
			{
				letter: "I.",
				name: "100% Complete Billing",
				dueColumn: "ddCompleteBillingDate",
				actColumn: "adCompleteBillingDate",
				dueDate: Boolean(this.ddCompleteBillingDate)
					? moment(new Date(this.ddCompleteBillingDate)).format("MM/DD/YYYY")
					: null,
				actDate: Boolean(this.adCompleteBillingDate)
					? moment(new Date(this.adCompleteBillingDate)).format("MM/DD/YYYY")
					: null,
			},
			{
				letter: "J.",
				name: "Retention Billing",
				dueColumn: "ddRetentionBillingDate",
				actColumn: "ddRetentionBillingDate",
				dueDate: Boolean(this.ddRetentionBillingDate)
					? moment(new Date(this.ddRetentionBillingDate)).format("MM/DD/YYYY")
					: null,
				actDate: Boolean(this.ddRetentionBillingDate)
					? moment(new Date(this.ddRetentionBillingDate)).format("MM/DD/YYYY")
					: null,
			},
		];
		return closeOutDates;
	}

	newPCO(jccmKeyID = null) {
		// log("jccmKeyID", jccmKeyID);
		let contract = this.getContract(jccmKeyID);
		if (!Boolean(contract?.jccmKeyID)) {
			contract = new Contract();
		}
		let pco = new PCO({ jccmKeyID: contract?.jccmKeyID, jcco: contract?.jcco, contract: contract?.contract });
		let coItem = new COItem({ jccmKeyID: contract?.jccmKeyID, jcco: contract?.jcco, contract: contract?.contract });
		pco.coItems.push(coItem);
		this.tempData = [pco, coItem];

		return pco;
	}
}
