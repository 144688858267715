import moment from "moment";
import { v4 } from "uuid";
import FORM_TEMPLATE_TYPES from "../constants/FormTemplateTypes";
import { convertArrayToMap } from "../../../helperFunctions";

const formTypeMap = convertArrayToMap(FORM_TEMPLATE_TYPES, "formType");

export default class FormTemplate {
	constructor(template) {
		if (template instanceof FormTemplate) {
			Object.assign(this, template);
		} else {
			this.queryData = template?.queryData ?? "FormTemplate";

			//FormUID
			this.templateUID = template?.templateUID ?? v4();

			//Integer

			this.formYear = template?.formYear ?? moment(new Date()).format("YYYY");
			this.formType = template?.formType ?? "0";
			this.formTypeDesc = template?.formTypeDesc ?? null;

			//Text
			this.title = template?.title ?? "New Form Template";
			this.subtitle = template?.subtitle ?? "New Form Subtitle";

			//EmployeeUID
			this.createdBy = template?.createdBy?.toLowerCase() ?? null;
			this.modifiedBy = template?.modifiedBy?.toLowerCase() ?? null;

			//Dates
			this.dateCreated = moment(template?.dateCreated ?? new Date());
			this.dateDue = moment(template?.dateDue) ?? null;
			this.dateModified = moment(template?.dateModified) ?? null;
			this.dateExpired = moment(template?.dateExpired) ?? null;

			//Boolean
			this.isLockedYN = template?.isLockedYN ?? "N";
			this.isArchivedYN = template?.isArchivedYN ?? "N";

			//Database
			this.keyID = template?.keyID ?? null;
		}
		this.getFormTypeDesc();
	}

	getFormTypeDesc() {
		let formTypeDesc = formTypeMap[this.formType]?.formTypeDesc ?? "N/A";
		this.formTypeDesc = formTypeDesc;
		return formTypeDesc;
	}
}
