import TextInputFilter from "../../ReactTable/inputs/TextInputFilter";
import SelectInputFilter from "../../ReactTable/inputs/SelectInputFilter";
import MultiSelectFilter from "../../ReactTable/inputs/MultiSelectFilter";
import NumberRangeFilter from "../../ReactTable/inputs/NumberRangeFilter";
// import SliderColumnFilter from "../AppComponents/Tables/Filters/SliderColumnFilter";

import VALUE_TYPES from "../constants/ValueTypes";
import { formatValue, convertArrayToMap } from "../../../helperFunctions";

const valueTypesMap = convertArrayToMap(VALUE_TYPES, "valueType");

export default class Column {
	// Add icon and url to datatype constants
	constructor(column) {
		let valueType = column?.valueType ?? "string";
		let filter = column?.filter ?? null;
		let aggregate = column?.aggregate ?? null;
		let filterType = this.getFilterType(valueType, filter);
		let aggregateType = this.getAggregate(valueType, aggregate);

		this.id = column?.id ?? column?.accessor ?? null;
		this.accessor = column?.accessor ?? column?.id ?? null;
		this.Header = column?.Header ?? null;

		this.width = column?.width ?? "100%";
		this.maxWidth = column?.maxWidth ?? "100%";
		this.minWidth = column?.minWidth ?? 150;
		this.tooltip = column?.tooltip ?? null;
		this.height = column?.height ?? 16;
		this.py = column?.py ?? 0;
		this.px = column?.px ?? 2;
		this.justifyContent = column?.justifyContent ?? "center";
		this.borderRadius = column?.borderRadius ?? 0;

		this.borderTopColor = column?.borderTopColor ?? "dimgray";
		this.borderLeftColor = column?.borderLeftColor ?? "dimgray";
		this.borderRightColor = column?.borderRightColor ?? "dimgray";
		this.borderBottomColor = column?.borderBottomColor ?? "dimgray";

		this.borderTopWidth = column?.borderTopWidth ?? 0;
		this.borderLeftWidth = column?.borderLeftWidth ?? 0;
		this.borderRightWidth = column?.borderRightWidth ?? 0;
		this.borderBottomWidth = column?.borderBottomWidth ?? column?.accessor === "value" ? 1 : 0;

		this.fontSize = column?.fontSize ?? 10;
		this.lineHeight = column?.lineHeight ?? 1;
		this.color = column?.color ?? "gray.600";
		this.textSize = column?.textSize ?? "md";
		this.positiveColor = column?.positiveColor ?? column?.color ?? "gray.600";
		this.negativeColor = column?.negativeColor ?? "red.600";
		this.whiteSpace = column?.whiteSpace ?? "normal";
		this.overflow = column?.overflow ?? "hidden";

		this.valueType = valueType;
		this.formInput = column?.formInput ?? null;
		this.defaultValue = column?.defaultValue ?? [];

		this.textAlign = column?.textAlign ?? valueTypesMap[valueType]?.textAlign ?? "left";
		this.textTransform = column?.textTransform ?? "none";
		this.decimals = column?.decimals ?? valueTypesMap[valueType]?.decimals ?? 0;
		this.sortIcon = column?.sortIcon ?? valueTypesMap[valueType]?.sortIcon ?? "alpha";
		this.Filter = filterType.Filter;
		this.filter = filterType.filter;
		this.Aggregated = column?.Aggregated ?? aggregateType?.Aggregated;
		this.aggregate = aggregateType.aggregate;
		this.placeHolderID = column?.placeHolderID ?? null;
		this.placeholder = column?.placeholder ?? null;
		// this.aggregateArray = column?.aggregateArray ?? [];

		this.Footer = column?.Footer ?? null;

		if (column?.Cell) {
			this.Cell = column?.Cell;
		} else {
			this.Cell = ({ cell }) => {
				return formatValue(cell.value, cell?.column?.decimals ?? 0, cell?.column?.valueType ?? "string");
			};
		}

		this.FloatHeader = column?.FloatHeader ?? null;

		this.show = column?.show ?? true;
		this.showGroupBy = column?.showGroupBy ?? this.getShowGroupBy();
		this.editable = column?.editable ?? false;
		this.editableDate = column?.editableDate ?? false;
		this.clickable = column?.clickable ?? false;
		this.checkbox = column?.checkbox ?? false;
		this.disableExport = column?.disableExport ?? false;

		this.bg = column?.bg ?? "transparent";

		this.subRow = {
			rowNum: column?.subRow?.rowNum ?? 6,
			icon: column?.subRow?.icon ?? null,
			overflowX: column?.subRow?.overflowX ?? "hidden",
			overflowY: column?.subRow?.overflowY ?? "hidden",
			columns: column?.subRow?.columns ?? {},
		};

		this.getCellExportValue = getCellExportValue;
		this.getColumnExportValue = getColumnExportValue;
	}

	getFilterType(valueType = "string", filter = null) {
		let accessor = this.accessor;

		let filterType = { Filter: TextInputFilter, filter: "textNoCaps" };
		if (["integer", "number", "currency", "percent", "accounting"].includes(valueType)) {
			filterType = { Filter: TextInputFilter, filter: "equals" };
		}

		if (Boolean(filter)) {
			if (filter === "select") {
				filterType = { Filter: SelectInputFilter, filter: "equals" };
			} else if (filter === "multiselect") {
				filterType = { Filter: MultiSelectFilter, filter: "multiselect" };
			} else if (filter === "notequals") {
				filterType = { Filter: TextInputFilter, filter: "notequals" };
			}
		} else {
			if (["number", "currency", "percent"].includes(valueType)) {
				filterType = { Filter: NumberRangeFilter, filter: "between" };
			} else if (["year", "month", "date", "datetime"].includes(valueType)) {
				filterType = { Filter: MultiSelectFilter, filter: "equals" };
			} else if (["queryData", "region"].includes(accessor)) {
				filterType = { Filter: MultiSelectFilter, filter: "equals" };
			}
		}

		return filterType;
	}

	getAggregate(valueType = "string", aggregate = null) {
		let aggregateType = { aggregate: null, Aggregated: null };
		// if (Boolean(aggregate)) {
		// 	if (aggregate === "customSum") {
		// 		aggregate = customSum;
		// 	}

		// 	aggregateType.aggregate = aggregate;
		// 	aggregateType.Aggregated = ({ value }) => `${value}`;
		// } else {

		if (["number", "currency", "accounting"].includes(valueType)) {
			if (aggregate === null) {
				aggregate = "sum";
			} else if (aggregate === "count") {
				valueType = "number";
			}
			aggregateType.aggregate = aggregate;
			aggregateType.Aggregated = ({ value }) => `${value}`;
			// aggregateType.Aggregated = ({ row = {} }) => {
			// 	let newRows = row?.subRows?.map((row) => row.values) ?? [];
			// 	let subtotal = getSubtotal(newRows, this.id, aggregate);
			// 	return formatCell(subtotal, this.decimals, valueType);
			// };
		} else if (valueType === "percent") {
			aggregateType.aggregate = "average";
			aggregateType.Aggregated = ({ value }) => `${Math.round(value * 100) / 100} (avg)}`;
		} else if (aggregate === "string") {
			this.valueType = "string";
			aggregateType.aggregate = ({ row }) => {
				if (row?.leafRows?.length > 0) {
					return row?.leafRows[0].values[this.id];
				} else {
					return null;
				}
			};
			aggregateType.Aggregated = ({ row }) => {
				if (row?.leafRows?.length > 0) {
					return row?.leafRows[0].values[this.id];
				} else {
					return null;
				}
			};
		}
		// }

		// if (Boolean(aggregateType.aggregate)) {
		// 	if (aggregateType.aggregate !== "average") {
		// 		aggregateType.Aggregated = ({ value }) => `${value}`;
		// 	} else if (aggregateType.aggregate === "average") {
		// 		aggregateType.Aggregated = ({ value }) => `${Math.round(value * 100) / 100} (avg)}`;
		// 	}
		// }

		return aggregateType;
	}

	getShowGroupBy() {
		if (["string", "boolean", "month", "date", "percent"].includes(this.valueType)) {
			return true;
		} else {
			return false;
		}
	}
}

function getColumnExportValue(column = null) {
	if (Boolean(column)) {
		return column.Header;
	} else {
		return "";
	}
}

function getCellExportValue(row = null, column = null) {
	let value = "";
	if (Boolean(row) && Boolean(column)) {
		value = row?.values[column?.id];
		if (["month", "date", "datetime", "percent"].includes(column.valueType)) {
			value = formatValue(value, column.decimals, column.valueType);
		}
	}
	return value;
}
