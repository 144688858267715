import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import {
	Box,
	Stack,
	Skeleton,
	IconButton,
	Flex,
	useBoolean,
	Tag,
	TagLabel,
	Center,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Tooltip,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverCloseButton,
	PopoverBody,
	PopoverArrow,
	HStack,
	Button,
	Checkbox,
	Text,
} from "@chakra-ui/react";
import $ from "jquery";
import { sum } from "../../../helperFunctions";
import {
	useTable,
	useGroupBy,
	useExpanded,
	useGlobalFilter,
	useAsyncDebounce,
	useSortBy,
	useFilters,
} from "react-table";

import GlobalSearchFilter from "../inputs/GlobalSearchFilter";
import VirtualListV3GroupSortFilterPanel from "./VirtualListV3GroupSortFilterPanel";
import VirtualListV3GroupSortFilterTags from "./VirtualListV3GroupSortFilterTags";

import { v4 } from "uuid";
import { FaExpandAlt, FaCompressAlt } from "react-icons/fa";
import getFilterTypes from "../functions/getFilterTypes";
// import { log } from "../../../helperFunctions";

export default function VirtualListV3(props) {
	const listRef = useRef(null);
	const ref = useRef();
	const [filteringIsOpen, setFilterOpen] = useBoolean(false);
	const columns = useMemo(() => props?.columns ?? [], [props.columns]);
	const data = useMemo(() => props?.data ?? [], [props.data]);
	const reRenderId = v4();
	// const [reRenderId, setRenderID] = useState(v4());
	const minH = useMemo(() => props.minH ?? 50, [props.minH]);

	const filterTypes = getFilterTypes();

	const defaultColumn = useMemo(
		() => ({
			Filter: DefaultColumnFilter,
		}),
		[]
	);

	const initialState = useMemo(() => {
		let sortBy = [];
		if (Boolean(props.defaultSortAttr)) {
			if (props.defaultSortAttr instanceof Array) {
				sortBy = props.defaultSortAttr;
			} else {
				sortBy = [{ id: props.defaultSortAttr, desc: false }];
			}
		}
		let filters = Boolean(props.defaultFilters) ? props.defaultFilters : [];
		let groupBy = Boolean(props.defaultGroupBy) ? props.defaultGroupBy : [];
		let expanded = Boolean(props.defaultExpanded) ? props.defaultExpanded : [];

		return { sortBy, filters, groupBy, expanded };
	}, [props.defaultFilters, props.defaultGroupBy, props.defaultSortAttr, props.defaultExpanded]);

	const expandAll = useMemo(
		() => props.expandAll ?? props.defaultExpandAll ?? false,
		[props.defaultExpandAll, props.expandAll]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		isAllRowsExpanded,
		prepareRow,
		totalColumnsWidth,
		state,
		setGlobalFilter,
		toggleSortBy,
		toggleGroupBy,
		setAllFilters,
		setGroupBy,
		toggleAllRowsExpanded,
		toggleRowExpanded,
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			filterTypes,
			initialState: initialState,
			autoResetGlobalFilter: false,
			autoResetPage: !Boolean(props.skipPageReset),
		},
		useFilters,
		useGlobalFilter,
		useGroupBy,
		useSortBy,
		useExpanded
	);

	// const changeSortBy = (ev) => {
	// 	let sortBy = ev.target.value;
	// 	toggleSortBy(sortBy);
	// };

	useMemo(() => {
		// log("hello - filters", initialState?.filters);
		setAllFilters(initialState?.filters ?? []);
	}, [initialState?.filters, setAllFilters]);

	useMemo(() => {
		// log("hello - groupBy", initialState?.groupBy);
		setGroupBy(initialState?.groupBy ?? []);
	}, [initialState?.groupBy, setGroupBy]);

	useEffect(() => {
		if (expandAll) {
			toggleAllRowsExpanded(Boolean(expandAll));
		}
		if (initialState?.expanded) {
			Object.keys(initialState?.expanded).forEach((key) => {
				toggleRowExpanded(key, true);
			});
		}
	}, [expandAll, toggleAllRowsExpanded, initialState?.expanded, toggleRowExpanded]);

	// useMemo(() => {
	// 	Boolean(ref?.current) && ref?.current.resetAfterIndex(0, false);
	// 	// log("rows.length", rows.length);
	// 	// log("state.groupBy", state.groupBy);
	// 	// log("state.filters", state.filters);
	// 	// log("state.sortBy", state.sortBy);
	// }, []);

	const isGrouped = useMemo(() => {
		for (let i = 0; i < headerGroups?.length; i++) {
			for (let j = 0; j < headerGroups[i].headers?.length; j++) {
				if (headerGroups[i].headers[j].isGrouped) {
					return true;
				}
			}
		}
		return false;
	}, [headerGroups]);

	// useMemo(() => {
	// 	if (
	// 		Boolean(props?.listName) &&
	// 		Boolean(props?.getRowCount) &&
	// 		Boolean(data?.length > 0) &&
	// 		Boolean(columns?.length > 0)
	// 	) {
	// 		props.getRowCount(props.listName, rows?.filter((row) => !Boolean(row?.isGrouped)) ?? []);
	// 	}
	// }, [columns?.length, data?.length, props, rows]);

	const handleExpandAllToggle = () => {
		toggleAllRowsExpanded(!Boolean(isAllRowsExpanded));
		if (Boolean(ref?.current)) {
			ref?.current.resetAfterIndex(0, false);
		}
	};

	useMemo(() => {
		if (Boolean(ref?.current) && (state.expanded || state?.groupBy)) {
			ref?.current.resetAfterIndex(0, false);
		}
	}, [state.expanded, state?.groupBy]);

	// useEffect(() => {
	// 	// const column = allColumns.find((d) => d.id === "accessYN");
	// 	// if (showAccess) {
	// 	// 	// log("showAccess: click show All");
	// 	// 	column.setFilter([]);
	// 	// } else {
	// 	// 	// log("showAccess: click only Yes");
	// 	// 	column.setFilter("Y");
	// 	// }

	// 	if (Boolean(listRef?.current)) {
	// 		listRef?.current?.resetAfterIndex(0, false);
	// 	}
	// 	toggleAllRowsExpanded(true);
	// }, [toggleAllRowsExpanded]);
	// }, [showAccess, allColumns, toggleAllRowsExpanded]);

	const rowCount = useMemo(
		() => (rows?.length ?? 0) - (rows?.filter((data) => Boolean(data?.isGrouped))?.length ?? 0),
		[rows]
	);

	// log("columns", columns);
	// log("rows", rows);
	// log("state", state);
	// log("headerGroups", headerGroups);

	const buttonHoverStyle = {
		zIndex: "unset",
		borderColor: "var(--chakra-colors-teal-400)",
		borderWidth: 2,
		backgroundColor: "var(--chakra-colors-whiteAlpha-800)",
		color: "var(--chakra-colors-teal-500)",
		boxShadow: "var(--chakra-shadows-md)",
	};
	const buttonStyle = {
		borderColor: "var(--chakra-colors-gray-300)",
		borderWidth: 1,
		backgroundColor: "var(--chakra-colors-gray-100)",
		boxShadow: "var(--chakra-shadows-md)",
	};

	if (props.listOnly) {
		return (
			<Skeleton
				rounded={"md"}
				isLoaded={props.isLoaded === undefined ? Boolean(props.data) : props.isLoaded}
				minHeight={props.isLoaded || Boolean(props.data) ? "0px" : "200px"}
			>
				<VirtualReactListBody
					listRef={listRef}
					ref={ref}
					minH={minH}
					columns={columns}
					data={data}
					rows={rows}
					getTableProps={getTableProps}
					totalColumnsWidth={totalColumnsWidth}
					headerGroups={headerGroups}
					getTableBodyProps={getTableBodyProps}
					{...props}
					reRenderId={reRenderId}
					prepareRow={prepareRow}
					renderRow={props.renderRow}
					dividerProps={props.dividerProps}
					isAllRowsExpanded={isAllRowsExpanded}
					isGrouped={isGrouped}
				/>
			</Skeleton>
		);
	} else {
		return (
			<Box width="full" id={"listContainer-" + (props.listName ?? "")}>
				<Stack direction="row" w="full" spacing={3}>
					{filteringIsOpen && !Boolean(props.popoverFilter) && (
						<Box
							w="400px"
							minW="400px"
							hidden={false}
							overflowY="auto"
							className="skinnyScroll"
							display={{ base: "none", md: "block" }}
						>
							<VirtualListV3GroupSortFilterPanel
								headerGroups={headerGroups}
								toggleSortBy={toggleSortBy}
								toggleGroupBy={toggleGroupBy}
								state={state}
							/>
						</Box>
					)}
					<Stack flex={1} w="full">
						<Stack direction="row" w="full">
							<Center>
								<Popover placement="right" isLazy>
									<PopoverTrigger>
										<Box
											display={{
												base: "block",
												md: Boolean(props.popoverFilter) ? "block" : "none",
											}}
										>
											<Tooltip label="Filter/Sort" placement="top" hasArrow>
												<IconButton
													size="sm"
													colorScheme="gray"
													variant="outline"
													icon={<i className="fas fa-filter" />}
												/>
											</Tooltip>
										</Box>
									</PopoverTrigger>

									<PopoverContent>
										<PopoverArrow />
										<PopoverCloseButton />
										<PopoverBody py={6}>
											<Box maxH="600px" hidden={false} overflowY="auto" className="skinnyScroll">
												<VirtualListV3GroupSortFilterPanel
													headerGroups={headerGroups}
													toggleSortBy={toggleSortBy}
													toggleGroupBy={toggleGroupBy}
													state={state}
												/>
											</Box>
										</PopoverBody>
									</PopoverContent>
								</Popover>
								{!props.hideFilters && (
									<Box display={{ base: "none", md: Boolean(props.popoverFilter) ? "none" : "flex" }}>
										<Tooltip label="Sort/Filter" letterSpacing={1} placement="top">
											<IconButton
												shadow="md"
												maxH={10}
												h={10}
												borderRadius="md"
												style={filteringIsOpen ? buttonHoverStyle : {}}
												borderColor="gray.300"
												borderWidth={1}
												bg="gray.100"
												_hover={buttonHoverStyle}
												size="md"
												colorScheme="gray"
												variant="outline"
												onClick={setFilterOpen.toggle}
												color="gray.500"
												icon={<i className="fas fa-filter" />}
											/>
										</Tooltip>
									</Box>
								)}
							</Center>
							<Box flex={1}>
								<GlobalSearchFilter
									globalFilter={state.globalFilter}
									setGlobalFilter={setGlobalFilter}
									rowCount={rowCount}
									totalRowCount={data?.length}
									listRef={null}
								/>
							</Box>
							{isGrouped && (
								<Center>
									<Tooltip label="Expand/Collapse" placement="top" hasArrow>
										<IconButton
											id={"expandAllBtn-" + props.listName}
											variant="ghost"
											size="sm"
											icon={isAllRowsExpanded ? <FaCompressAlt /> : <FaExpandAlt />}
											onClick={handleExpandAllToggle}
										/>
									</Tooltip>
								</Center>
							)}
						</Stack>

						<VirtualListV3GroupSortFilterTags headerGroups={headerGroups} />

						<Box rounded={5} w="full">
							<Skeleton
								rounded={5}
								isLoaded={props.isLoaded === undefined ? Boolean(props.data) : props.isLoaded}
								minHeight={props.isLoaded || Boolean(props.data) ? "0px" : "400px"}
							>
								<VirtualReactListBody
									ref={ref}
									minH={minH}
									columns={columns}
									data={data}
									rows={rows}
									getTableProps={getTableProps}
									totalColumnsWidth={totalColumnsWidth}
									headerGroups={headerGroups}
									getTableBodyProps={getTableBodyProps}
									{...props}
									filteringIsOpen={filteringIsOpen}
									reRenderId={reRenderId}
									prepareRow={prepareRow}
									renderRow={props.renderRow}
									dividerProps={props.dividerProps}
									isAllRowsExpanded={isAllRowsExpanded}
									toggleSortBy={toggleSortBy}
									isGrouped={isGrouped}
								/>
							</Skeleton>
						</Box>
					</Stack>
				</Stack>
			</Box>
		);
	}
}

function VirtualReactListBody(props) {
	const minHeight = props.minH ?? 50;

	const [scrollHeight, setScrollHeight] = useState(0);
	const [totalHeight, setTotalHeight] = useState(0);
	const [defaultHeight, setDefaultHeight] = useState(minHeight);

	//const [reCalcTrigger, setTriggerCalc] = useState(v4());
	const [heights, setHeights] = useState(() => {
		let heightsArr = [];
		for (let i = 0; i < props.rows?.length; i++) {
			heightsArr.push(props.defaultRowHeight ?? minHeight);
		}
		//Try mapping or binary search with sorted array
		return heightsArr;
	});

	const listHeight = useMemo(() => (isNaN(props.listHeight) ? 1000 : parseInt(props.listHeight)), [props.listHeight]);

	const calcHeights = () => {
		let newHeights = [...heights];
		let totalHeight = 0;
		let firstRowSeen = false;
		for (let i = 0; i < props.rows?.length; i++) {
			let rowEl = $("#vListItem-" + (props.listName ?? "") + i);

			if (!Boolean(newHeights[i])) {
				newHeights.push(props.defaultRowHeight ?? minHeight);
			}
			if (rowEl && Boolean(rowEl[0])) {
				if (!firstRowSeen) {
					firstRowSeen = true;
				}
				rowEl = rowEl[0];
				if (rowEl?.style) {
					rowEl.style.minHeight = 10 + "px";
				}
				let clientHeight = rowEl?.clientHeight ?? defaultHeight;
				if (newHeights[i] === undefined) {
					newHeights.push(clientHeight);
				} else {
					if (newHeights[i] !== clientHeight) {
						newHeights[i] = clientHeight;
					}
				}
				// rowEl.style.minHeight = newHeights[i];
				totalHeight += newHeights[i];
			} else {
				if (firstRowSeen && newHeights.length === props.rows.length) {
					break;
				}
			}
		}

		//newHeights = [...newHeights];
		setHeights(newHeights);
		setTotalHeight(totalHeight);
		//}, 1000);
	};

	const updateScrollHeight = useAsyncDebounce((value) => {
		setScrollHeight(value);
	}, 19);

	const handleScroll = (ev) => {
		let element;
		let scrollTop;
		if (ev === undefined) {
			scrollTop = 0;
		} else {
			ev.stopPropagation();
			element = ev.target;
			scrollTop = element.scrollTop;
		}
		updateScrollHeight(scrollTop);
	};

	const runCalcs = useAsyncDebounce(() => {
		calcHeights();
	}, 200);

	useEffect(() => {
		runCalcs();
	}, [props.reRenderId, props.isAllRowsExpanded, runCalcs]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(calcHeights, [
		scrollHeight,
		props.rows,
		props.rows?.length,
		props.filteringIsOpen,
		props.listName,
		props.defaultRowHeight,
		minHeight,
		defaultHeight,
		// heights,
	]);

	useEffect(() => {
		for (let i = 0; i < props.headerGroups?.length; i++) {
			for (let j = 0; j < props.headerGroups[i].headers.length; j++) {
				if (Boolean(props.headerGroups[i].headers[j].columns)) {
					let columnWidth = 0;
					for (let k = 0; k < props.headerGroups[i].headers[j].columns.length; k++) {
						let columnEl = $(`#${props.headerGroups[i].headers[j].columns[k].id}`);
						if (Boolean(columnEl)) {
							columnWidth += columnEl?.outerWidth();
						}
					}
					let parentColEl = $(`#${props.headerGroups[i].headers[j].id}`);
					if (Boolean(parentColEl) && parentColEl[0]) {
						parentColEl[0].style.flex = "unset";
						parentColEl.width(
							columnWidth *
								(props.headerGroups[i].headers[j].columns?.length === 1
									? props.headerGroups[i].headers[j].totalVisibleHeaderCount
									: 1) -
								18
						);
					}
				}
			}
		}
	}, [props.rows, props.filteringIsOpen, props.headerGroups]);

	// const RenderRow = useMemo(() => props.renderRow, [props.renderRow]);

	// const RenderVirtualItem = useCallback(
	// 	({ listName, index, rows, hoverDisabled, minHeight, startHeight, rowsStriped }) => {
	// 		return (
	// 			<Box
	// 				id={"vListItem-" + (listName ?? "") + index}
	// 				// className={hoverDisabled ? "" : "hoverPointerLight"}
	// 				minHeight={minHeight + "px"}
	// 				position="absolute"
	// 				width="full"
	// 				top={startHeight + "px"}
	// 				bg={rowsStriped ? (index % 2 !== 0 ? "blackAlpha.100" : "unset") : "unset"}
	// 			>
	// 				<RenderRow row={rows} index={index} />
	// 				{/* <Divider /> */}
	// 			</Box>
	// 		);
	// 	},
	// 	[]
	// );

	let rollingHeight = 0;

	if (props.variant === "table") {
		return (
			<Box
				className="skinnyScroll"
				width="full"
				maxWidth="full"
				maxHeight="700px"
				overflowY="auto"
				onScroll={handleScroll}
				mx="auto"
			>
				<Table {...props.getTableProps()} size="sm" width={props.totalColumnsWidth} ref={props.ref}>
					<Thead background="green.700" position="sticky" top="0" left="0" zIndex="2">
						{props.headerGroups.map((headerGroup, i) => (
							<Tr key={i} {...headerGroup.getHeaderGroupProps()} display="flex">
								{headerGroup.headers.map((column, j) => (
									<Th
										key={j}
										{...column.getHeaderProps()}
										id={column?.id}
										color="white"
										textAlign="center"
										{...column.getGroupByToggleProps()}
										display="flex"
										alignItems="center"
										flex={column.flex}
										px={column.px}
										border="1px solid whitesmoke"
									>
										<Box w="full" fontSize={column.fontSize}>
											{column.render("Header")}
										</Box>
									</Th>
								))}
							</Tr>
						))}
					</Thead>
					<Tbody
						listRef={props.listRef}
						id={"listBody-" + (props.listName ?? "")}
						{...props.getTableBodyProps()}
						height={(sum(heights.slice(0, props.rows?.length ?? 0)) ?? listHeight) + "px"}
						position="relative"
					>
						{props.rows?.map((row, i) => {
							rollingHeight += heights ? heights[i] : defaultHeight;
							let startHeight = rollingHeight - (heights ? heights[i] : defaultHeight);

							if (rollingHeight >= scrollHeight && startHeight < scrollHeight + listHeight) {
								return (
									<Tr
										key={i}
										id={"vListItem-" + (props.listName ?? "") + i}
										lineHeight={(heights ? heights[i] : defaultHeight) + "px"}
										position="absolute"
										width="full"
										top={startHeight + "px"}
										display="flex"
									>
										{row}
									</Tr>
								);
							} else {
								return <></>;
							}
						})}
					</Tbody>
				</Table>
			</Box>
		);
	} else {
		return (
			<>
				<Box
					id={"vlist-" + (props.listName ?? "")}
					className="skinnyScroll"
					pr={2}
					maxHeight={listHeight + "px"}
					overflow="auto"
					onScroll={handleScroll}
					width="full"
				>
					{props.variant === "tableList" && (
						<Box
							background="green.700"
							width={props.variant === "tableList" ? props.totalColumnsWidth : "full"}
							position="sticky"
							top={0}
							zIndex={4}
						>
							{props.headerGroups.map((headerGroup, i) => (
								<Flex key={i} {...headerGroup.getHeaderGroupProps()} w="full">
									{headerGroup.headers.map((column, j) => (
										<Tooltip label={column.tooltip} placement="top" hasArrow>
											<Box
												key={j}
												{...column.getHeaderProps()}
												id={column?.id}
												color="white"
												textAlign="center"
												{...column.getSortByToggleProps()}
												display="flex"
												alignItems="center"
												flex={column.flex}
												px={column.px}
												fontSize="12px"
												border="1px solid whitesmoke"
												flexDirection="column"
												justifyContent="end"
											>
												<Box w="full" fontSize={column.fontSize}>
													{column.render("Header")}
												</Box>
												{Boolean(column.subHeader) && (
													<Box w="full" py={1}>
														<Tag size="sm" colorScheme={"green"}>
															<TagLabel fontSize={"9px"} letterSpacing={".05rem"}>
																{column.subHeader.toUpperCase()}
															</TagLabel>
														</Tag>
													</Box>
												)}
											</Box>
										</Tooltip>
									))}
								</Flex>
							))}
						</Box>
					)}

					<Stack
						id={"listBody-" + (props.listName ?? "")}
						height={(sum(heights.slice(0, props.rows?.length ?? 0)) ?? listHeight) + "px"}
						spacing={0}
						position="relative"
						width={props.variant === "tableList" ? props.totalColumnsWidth : "full"}
					>
						{props.rows?.map((row, i) => {
							rollingHeight += heights ? heights[i] : defaultHeight;
							let startHeight = rollingHeight - (heights ? heights[i] : defaultHeight);
							if (rollingHeight >= scrollHeight && startHeight < scrollHeight + listHeight) {
								props.prepareRow(row);
								return (
									<VirtualListItem
										key={i}
										row={row}
										listName={props.listName}
										hoverDisabled={props.hoverDisabled}
										minHeight={heights ? heights[i] : defaultHeight}
										startHeight={startHeight}
										rowsStriped={props.rowsStriped}
										index={i}
										renderRow={props.renderRow}
										dividerProps={props.dividerProps}
										calcHeights={calcHeights}
										isGrouped={props.isGrouped}
									/>
								);
							} else {
								return <></>;
							}
						})}
					</Stack>
				</Box>
			</>
		);
	}
}

class VirtualListItem extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			minHeight: this.props.minHeight,
			startHeight: this.props.startHeight,
			listName: this.props.listName,
			index: this.props.index,
			hoverDisabled: this.props.hoverDisabled,
			rowsStriped: this.props.rowsStriped,
			row: null,
			rowOriginal: JSON.stringify(this.props.row?.original ?? {}),
		};
	}

	componentDidMount() {
		let rowObj = Object.create(this.props.row);
		Object.assign(rowObj, this.props.row);
		this.setState({
			row: rowObj,
		});
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.minHeight !== this.props.minHeight) {
			return true;
		}
		if (nextProps.startHeight !== this.props.startHeight) {
			return true;
		}
		if (
			JSON.stringify(nextProps.row?.original) !== this.state.rowOriginal ||
			nextProps.row?.id !== this.state.row?.id
		) {
			let rowObj = Object.create(nextProps.row);
			Object.assign(rowObj, nextProps.row);
			this.setState({
				row: rowObj,
				rowOriginal: JSON.stringify(nextProps.row?.original),
			});
			return true;
		}
		if (Boolean(nextProps.row?.groupByID)) {
			if (
				nextProps.row?.isExpanded !== this.state.row?.isExpanded ||
				nextProps.row?.canExpand !== this.state.row?.canExpand ||
				nextProps.row?.isGrouped !== this.state.row?.isGrouped
			) {
				let rowObj = Object.create(nextProps.row);
				Object.assign(rowObj, nextProps.row);
				this.setState({
					row: rowObj,
				});
				return true;
			}
		}
		return false;
	}

	render() {
		const RenderRow = this.props.renderRow;

		return (
			<Box
				id={"vListItem-" + (this.props.listName ?? "") + this.props.index}
				minHeight={this.props.minHeight + "px"}
				position="absolute"
				width="full"
				top={this.props.startHeight + "px"}
				// bg={this.props.rowsStriped ? (this.props.index % 2 !== 0 ? "blackAlpha.50" : "unset") : "unset"}
			>
				<RenderRow
					row={this.props.row}
					index={this.props.index}
					calcHeights={this.props.calcHeights}
					isGrouped={this.props.isGrouped}
				/>
				{/* <Divider {...this.props.dividerProps} /> */}
			</Box>
		);
	}
}

function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter, id } }) {
	const [showMore, toggleShowMore] = useBoolean(true);

	const columns = useMemo(() => {
		let cols = [{ Header: "Value", id: "filterValue", accessor: (row) => row }];
		return cols;
	}, []);

	const uniqueValues = useMemo(() => {
		let values = [];
		for (let i = 0; i < preFilteredRows?.length; i++) {
			let value = preFilteredRows[i].values[id];
			if (Boolean(value) && !values.find(({ name }) => name === value)) {
				values.push({ name: value, isChecked: filterValue === value });
			}
		}
		return values;
	}, [preFilteredRows, filterValue, id]);

	const visibleFilters = useMemo(() => {
		if (showMore) {
			return uniqueValues;
		} else {
			return uniqueValues.slice(0, 6);
		}
	}, [showMore, uniqueValues]);

	// const handleSetFilter = (filterName) => {
	// 	let currFilter = [];
	// 	Object.assign(currFilter, filterValue);
	// 	let foundIndex = currFilter.indexOf(filterName);
	// 	if (foundIndex === -1) {
	// 		currFilter.push(filterName);
	// 	} else {
	// 		currFilter.splice(foundIndex, 1);
	// 	}
	// 	setFilter(currFilter);
	// };

	const RenderRow = useCallback(
		({ index, row }) => {
			let filter = row.original;

			return (
				<HStack
					bg="white"
					spacing={2}
					align="center"
					p={1}
					w="full"
					onClick={() => {
						setFilter(filterValue === filter.name ? undefined : filter.name);
					}}
				>
					<Checkbox size="sm" isChecked={filter.isChecked} />
					<Text color="gray.600" fontSize="sm">
						{filter.name}
					</Text>
				</HStack>
			);
		},
		[setFilter, filterValue]
	);

	return (
		<Stack spacing={0} rounded={"md"} w="full">
			<VirtualListV3
				data={visibleFilters ?? []}
				columns={columns}
				renderRow={RenderRow}
				listName={id.replace(/\s/gm, "") + "filter"}
				listOnly
				defaultRowHeight={22}
				listHeight={250}
			/>
			{!showMore && visibleFilters?.length < uniqueValues?.length && (
				<Button
					variant="ghost"
					size="sm"
					colorScheme="gray"
					color="gray.500"
					_hover={{ color: "blue.500", textDecoration: "underline" }}
					onClick={toggleShowMore.toggle}
				>
					Show More
				</Button>
			)}
		</Stack>
	);
}
