import moment from "moment";
import FormsAPI from "../../../APIs/FormsAPI";
import Form from "./Form";
import FormTemplate from "./FormTemplate";
import FormSection from "./FormSection";
import FormQuestion from "./FormQuestion";
import FormRating from "./FormRating";
import FormInput from "./FormInput";
import FormComment from "./FormComment";
import FormCommenter from "./FormCommenter";
import { convertArrayToMap, getSubtotal } from "../../../helperFunctions";

export default class FormData {
	constructor(data) {
		if (data instanceof FormData) {
			Object.assign(this, data);
		} else {
			this.formsAPI = null;
			this.formUID = null;
			this.msUserID = null;
			this.pathname = null;

			this.templates = data?.templates ?? [];
			this.forms = data?.forms ?? [];
			this.pages = data?.pages ?? [];
			this.sections = data?.forms ?? [];
			this.questions = data?.questions ?? [];
			this.ratings = data?.ratings ?? [];
			this.inputs = data?.inputs ?? [];
			this.comments = data?.comments ?? [];
			this.commenters = data?.commenters ?? [];
		}
	}

	async initFormData(msUserID = null) {
		let formData = this;

		if (Boolean(msUserID) && !Boolean(formData?.formsAPI)) {
			formData.msUserID = msUserID;
			formData.formsAPI = new FormsAPI();
			let params = {};
			let query = { msUserID: msUserID };
			let sqlData = await formData.formsAPI.GetFormData(params, query);
			if (sqlData.status === 200) {
				sqlData = sqlData?.value ?? [];
				formData = formData.classifyData(formData, sqlData);
			}
		}

		return formData;
	}

	getSubtotal(forms = this.forms, prop = "formYear", operation = "max", distinct = true) {
		let selectedYear = getSubtotal(forms ?? [], prop, operation, distinct);
		return selectedYear;
	}

	classifyData(formData = this, sqlData = []) {
		let lastUpdated = moment(new Date());
		formData.lastUpdated = lastUpdated;

		for (let i = 0; i < sqlData?.length; i++) {
			let queryData = sqlData[i]?.queryData;

			if (queryData === "FormTemplate") {
				let template = new FormTemplate(sqlData[i]);

				formData.templates.push(template);

				let templateForm = new Form(sqlData[i], template);
				formData.forms.push(templateForm);
			} else if (queryData === "Form") {
				let template = formData.templates?.find((d) => d.templateUID === sqlData[i]?.templateUID) ?? null;
				let form = new Form(sqlData[i], template);
				form.lastUpdated = lastUpdated;
				formData.forms.push(form);
			} else if (queryData === "FormSection") {
				let section = new FormSection(sqlData[i]);

				formData.sections.push(section);
			} else if (queryData === "FormQuestion") {
				let question = new FormQuestion(sqlData[i]);

				formData.questions.push(question);
			} else if (queryData === "FormRating") {
				let rating = new FormRating(sqlData[i]);

				formData.ratings.push(rating);
			} else if (queryData === "FormInput") {
				let input = new FormInput(sqlData[i]);

				formData.inputs.push(input);
			} else if (queryData === "FormComment") {
				let comment = new FormComment(sqlData[i]);

				formData.comments.push(comment);
			} else if (queryData === "FormCommenter") {
				let commenter = new FormCommenter(sqlData[i]);
				formData.commenters.push(commenter);
			}
		}

		return formData;
	}

	async getFormData(formUID = null, msUserID = this.msUserID) {
		let formData = this;
		let form = formData?.forms?.find((d) => d.formUID === formUID) ?? null;
		let templateUID = form?.templateUID ?? formUID;
		let selectedForms = formData?.forms ?? [];
		if (form?.formUID === form?.parentFormUID) {
			selectedForms = selectedForms?.filter((d) => d.parentFormUID === formUID) ?? [];
		} else {
			selectedForms = selectedForms?.filter((d) => d.formUID === formUID) ?? [];
		}
		let selectedFormUIDs = [...new Set(selectedForms?.map((d) => d.formUID) ?? [])];
		selectedFormUIDs = selectedFormUIDs?.filter((d) => Boolean(d)) ?? [];

		if (Boolean(formData?.formsAPI) && Boolean(msUserID) && Boolean(formUID) && Boolean(templateUID)) {
			let params = {};
			let query = { formUID, templateUID, msUserID };
			let sqlData = await formData?.formsAPI?.GetFormData(params, query);

			if (sqlData?.status === 200) {
				sqlData = sqlData?.value ?? [];

				//Original
				// formData.templates = formData.templates?.filter((d) => d.templateUID !== templateUID) ?? [];
				// formData.forms = formData.forms?.filter((d) => d.formUID !== formUID) ?? [];
				// formData.sections = formData.sections?.filter((d) => d.templateUID !== templateUID) ?? [];
				// formData.questions = formData.questions?.filter((d) => d.templateUID !== templateUID) ?? [];
				// formData.ratings = formData.ratings?.filter((d) => d.templateUID !== templateUID) ?? [];
				// formData.inputs = formData.inputs?.filter((d) => d.formUID !== formUID) ?? [];
				// formData.comments = formData.comments?.filter((d) => d.formUID !== formUID) ?? [];
				// formData.commenters = formData.commenters?.filter((d) => d.formUID !== formUID) ?? [];

				// NEW
				formData.templates = formData.templates?.filter((d) => d.templateUID !== templateUID) ?? [];
				formData.forms = formData.forms?.filter((d) => !selectedFormUIDs.includes(d.formUID)) ?? [];
				formData.sections = formData.sections?.filter((d) => d.templateUID !== templateUID) ?? [];
				formData.questions = formData.questions?.filter((d) => d.templateUID !== templateUID) ?? [];
				formData.ratings = formData.ratings?.filter((d) => d.templateUID !== templateUID) ?? [];
				formData.inputs = formData.inputs?.filter((d) => !selectedFormUIDs.includes(d.formUID)) ?? [];
				formData.comments = formData.comments?.filter((d) => !selectedFormUIDs.includes(d.formUID)) ?? [];
				formData.commenters = formData.commenters?.filter((d) => !selectedFormUIDs.includes(d.formUID)) ?? [];

				formData = formData.classifyData(formData, sqlData);
				formData.msUserID = msUserID;
				formData.formUID = formUID;
			}
		} else {
			formData.formUID = null;
		}

		return formData;
	}

	getSelectedData(formUID = this.formUID) {
		//Original
		// let selectedData = new FormData(this);
		// let form = selectedData?.forms?.find((d) => d.formUID === formUID) ?? null;
		// let templateUID = form?.templateUID ?? formUID;
		// selectedData.formUID = formUID;
		// selectedData.templates = selectedData.templates?.filter((d) => d.templateUID === templateUID) ?? [];
		// selectedData.forms = selectedData.forms?.filter((d) => d.formUID === formUID) ?? [];
		// selectedData.sections = selectedData.sections?.filter((d) => d.templateUID === templateUID) ?? [];
		// selectedData.questions = selectedData.questions?.filter((d) => d.templateUID === templateUID) ?? [];
		// selectedData.ratings = selectedData.ratings?.filter((d) => d.templateUID === templateUID) ?? [];
		// selectedData.inputs = selectedData.inputs?.filter((d) => d.formUID === formUID) ?? [];
		// selectedData.comments = selectedData.comments?.filter((d) => d.formUID === formUID) ?? [];
		// selectedData.commenters = selectedData.commenters?.filter((d) => d.formUID === formUID) ?? [];

		//NEW
		let selectedData = new FormData(this);
		let form = selectedData?.forms?.find((d) => d.formUID === formUID) ?? null;
		let templateUID = form?.templateUID ?? formUID;
		let selectedForms = selectedData?.forms ?? [];
		if (form?.formUID === form?.parentFormUID) {
			selectedForms = selectedForms?.filter((d) => d.parentFormUID === formUID) ?? [];
		} else {
			selectedForms = selectedForms?.filter((d) => d.formUID === formUID) ?? [];
		}
		let selectedFormUIDs = [...new Set(selectedForms?.map((d) => d.formUID) ?? [])];
		selectedFormUIDs = selectedFormUIDs?.filter((d) => Boolean(d)) ?? [];

		selectedData.formUID = formUID;
		selectedData.templates = selectedData.templates?.filter((d) => d.templateUID === templateUID) ?? [];
		selectedData.forms = selectedData.forms?.filter((d) => selectedFormUIDs.includes(d.formUID)) ?? [];
		selectedData.sections = selectedData.sections?.filter((d) => d.templateUID === templateUID) ?? [];
		selectedData.questions = selectedData.questions?.filter((d) => d.templateUID === templateUID) ?? [];
		selectedData.ratings = selectedData.ratings?.filter((d) => d.templateUID === templateUID) ?? [];
		selectedData.inputs = selectedData.inputs?.filter((d) => selectedFormUIDs.includes(d.formUID)) ?? [];
		selectedData.comments = selectedData.comments?.filter((d) => selectedFormUIDs.includes(d.formUID)) ?? [];
		selectedData.commenters = selectedData.commenters?.filter((d) => selectedFormUIDs.includes(d.formUID)) ?? [];

		return selectedData;
	}

	getForm(formUID = this.formUID) {
		let selectedData = this.getSelectedData(formUID);
		//COME BACK TOMORROW -> need to create a key to map the inputs for a form with a parentformUID

		let commentsMap = convertArrayToMap(selectedData?.comments, "inputUID", true);
		for (let i = 0; i < selectedData?.inputs?.length; i++) {
			selectedData.inputs[i].comments = commentsMap[selectedData?.inputs[i]?.inputUID] ?? [];
		}

		let ratingsMap = convertArrayToMap(selectedData?.ratings, "questionUID", true);
		// let inputsMap = convertArrayToMap(selectedData?.inputs, "questionUID", true);
		let inputsMap = convertArrayToMap(selectedData?.inputs, "formUID", true);
		inputsMap = convertArrayToMap(inputsMap[formUID] ?? [], "questionUID", true);
		for (let q = 0; q < selectedData?.questions?.length; q++) {
			selectedData.questions[q].ratings = ratingsMap[selectedData?.questions[q]?.questionUID] ?? [];
			selectedData.questions[q].inputs = inputsMap[selectedData?.questions[q]?.questionUID] ?? [];
		}

		let questionsMap = convertArrayToMap(selectedData?.questions, "sectionUID", true);
		for (let s = 0; s < selectedData?.sections?.length; s++) {
			selectedData.sections[s].questions = questionsMap[selectedData?.sections[s]?.sectionUID] ?? [];
		}

		let sectionsMap = convertArrayToMap(selectedData?.sections, "templateUID", true);
		let commentersMap = convertArrayToMap(selectedData?.commenters, "formUID", true);
		for (let f = 0; f < selectedData?.forms?.length; f++) {
			selectedData.forms[f].sections = sectionsMap[selectedData?.forms[f]?.templateUID] ?? [];
			selectedData.forms[f].commenters = commentersMap[selectedData?.forms[f]?.formUID] ?? [];
		}

		let form = selectedData?.forms?.find((d) => d.formUID === formUID) ?? null;

		if (Boolean(form)) {
			form = form.initFormPages();
		}

		return form;
	}

	initFormPages(formUID = this.formUID) {
		let selectedData = this.getSelectedData(formUID);

		let sectionsMap = convertArrayToMap(selectedData?.sections, "templateUID", true);

		selectedData.pages = [];
		for (let f = 0; f < selectedData?.forms?.length; f++) {
			let sections = sectionsMap[selectedData?.forms[f]?.templateUID];
			let pages = [];
			let page = {};
			for (let s = 0; s < sections?.length; s++) {
				sections[s].pageVal = pages.length;
				if (s === 0 || sections[s]?.isNewPageYN === "Y") {
					if (sections[s]?.isNewPageYN === "Y") {
						pages.push(page);
					}
					page = {
						templateUID: sections[s]?.templateUID,
						formUID: selectedData?.forms[f].formUID,
						pageVal: pages?.length,
						heading: sections[s]?.heading, //if no section heading do we take form heading?
						sectionUIDs: [],
					};

					let formStatus = selectedData?.forms[f].getFormStatus();

					if (
						window.location.href.includes("hr") &&
						["2", "3"].includes(selectedData?.forms[f].formType) &&
						parseInt(formStatus?.id) < 2 &&
						page.heading === "Overall Comments"
					) {
						page.heading = "Overall Comments";
					} else if (
						["2", "3"].includes(selectedData?.forms[f].formType) &&
						parseInt(formStatus?.id) < 2 &&
						page.heading === "Overall Comments"
					) {
						page.heading = "Invite Commenters";
					}
				}
				page.sectionUIDs.push(sections[s].sectionUID);
			}
			pages.push(page);
			// selectedData.forms[f].pages = pages;
			selectedData.pages.push(...pages);
		}

		return selectedData;
	}

	getFormPages(formUID = this.formUID) {
		let form = this.forms?.find((d) => d.formUID === formUID) ?? null;
		let pagesMap = convertArrayToMap(this.pages, "formUID", true);
		let pages = pagesMap[form?.formUID] ?? [];
		return pages;
	}

	getFormSections(formUID = this.formUID) {
		let form = this.forms?.find((d) => d.formUID === formUID) ?? null;
		let sectionsMap = convertArrayToMap(this.sections, "templateUID", true);
		let sections = sectionsMap[form?.templateUID] ?? [];
		return sections;
	}

	getFormQuestions(sectionUID = null) {
		let questions = this.questions;
		if (Boolean(sectionUID)) {
			let questionsMap = convertArrayToMap(questions, "sectionUID", true);
			questions = questionsMap[sectionUID] ?? [];
		}
		return questions;
	}

	getFormRatings(questionUID = null) {
		let ratings = this.ratings;
		if (Boolean(questionUID)) {
			let ratingsMap = convertArrayToMap(ratings, "questionUID", true);
			ratings = ratingsMap[questionUID] ?? [];
		}
		return ratings;
	}

	getFormInputs(params = { questionUID: null, formUID: null }) {
		let inputs = this.inputs;
		// let form = this.forms?.find((d) => d.formUID === params?.formUID);

		for (const [key, value] of Object.entries(params)) {
			// log(`${key}: ${value}`);
			if (Boolean(value)) {
				let inputsMap = convertArrayToMap(inputs, key, true);
				inputs = inputsMap[value] ?? [];
			}
		}

		// if (Boolean(params?.questionUID)) {
		// 	let inputsMap = convertArrayToMap(inputs, "questionUID", true);
		// 	inputs = inputsMap[params?.questionUID] ?? [];
		// }
		// if (Boolean(formUID)) {
		// 	let inputsMap = convertArrayToMap(inputs, "parentFormUID", true);
		// 	inputs = inputsMap[formUID] ?? [];
		// }
		return inputs;
	}

	getFormComments(inputUIDs = []) {
		let comments = this.comments;
		if (Boolean(inputUIDs?.length > 0)) {
			comments = comments?.filter((d) => inputUIDs.includes(d.inputUID)) ?? [];
		}
		return comments;
	}

	// getFormQuestions(formUID = this.formUID) {

	// 	let form = this?.forms?.find((d) => d.formUID === formUID) ?? null;
	// 	let sectionsMap = convertArrayToMap(this?.sections, "templateUID", true);
	// 	let sections = sectionsMap[form?.templateUID] ?? [];

	// 	// let commentsMap = convertArrayToMap(selectedData?.comments, "inputUID", true);
	// 	// for (let i = 0; i < selectedData?.inputs?.length; i++) {
	// 	// 	selectedData.inputs[i].comments = commentsMap[selectedData?.inputs[i]?.inputUID] ?? [];
	// 	// }

	// 	// let ratingsMap = convertArrayToMap(selectedData?.ratings, "questionUID", true);
	// 	// // let inputsMap = convertArrayToMap(selectedData?.inputs, "questionUID", true);
	// 	// let inputsMap = convertArrayToMap(selectedData?.inputs, "formUID", true);
	// 	// inputsMap = convertArrayToMap(inputsMap[formUID] ?? [], "questionUID", true);
	// 	// for (let q = 0; q < selectedData?.questions?.length; q++) {
	// 	// 	selectedData.questions[q].ratings = ratingsMap[selectedData?.questions[q]?.questionUID] ?? [];
	// 	// 	selectedData.questions[q].inputs = inputsMap[selectedData?.questions[q]?.questionUID] ?? [];
	// 	// }

	// 	// let questionsMap = convertArrayToMap(selectedData?.questions, "sectionUID", true);
	// 	// for (let s = 0; s < selectedData?.sections?.length; s++) {
	// 	// 	selectedData.sections[s].questions = questionsMap[selectedData?.sections[s]?.sectionUID] ?? [];
	// 	// }

	// 	// let commentersMap = convertArrayToMap(selectedData?.commenters, "formUID", true);
	// 	// for (let f = 0; f < selectedData?.forms?.length; f++) {
	// 	// 	selectedData.forms[f].sections = sectionsMap[selectedData?.forms[f]?.templateUID] ?? [];
	// 	// 	selectedData.forms[f].commenters = commentersMap[selectedData?.forms[f]?.formUID] ?? [];
	// 	// }

	// 	return sections;
	// }
}
