import React, { useMemo, useState } from "react";
import { Box, Flex, Text, Heading, VStack, Stack, HStack, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { formatValue, getValueColor, log } from "../../../helperFunctions";
import MFRProjectAnalysisSummarySection from "./MFRProjectAnalysisSummarySection";
import SectionTemplateMFRProjectAnalysis from "../templates/SectionTemplateMFRProjectAnalysis";

export default function ProjectAnalysis(props) {
	const contract = useMemo(() => props.contract, [props.contract]);
	const contractStatus = useMemo(() => props.contractStatus, [props.contractStatus]);
	// const prevContractStatus = useMemo(() => props.prevContractStatus, [props.prevContractStatus]);

	const [projectAnalysis, setProjectAnalysis] = useState(new SectionTemplateMFRProjectAnalysis());

	useMemo(() => {
		let projectAnalysis = new SectionTemplateMFRProjectAnalysis(contract, contractStatus);
		projectAnalysis = projectAnalysis?.subSections[0] ?? {};
		setProjectAnalysis(projectAnalysis);
	}, [contract, contractStatus]);

	log("projectAnalysis", projectAnalysis);

	return (
		<Stack w="full" spacing={2} px={4}>
			<HStack align="center">
				<Heading
					minW="25px"
					size={"md"}
					color={"gray.500"}
					textTransform="uppercase"
					isTruncated
					letterSpacing={2}
				>
					IV.
				</Heading>
				<Heading size={"md"} color={"gray.500"} textTransform="uppercase" isTruncated letterSpacing={2}>
					{"Project Analysis" + (["2", "4", "5"].includes(contract?.contractTypeID) ? " CRS" : "")}
				</Heading>
			</HStack>
			<VStack w="full" spacing={4}>
				<Box maxH={"full"} className="skinnyScroll" overflow={"auto"} pr={1} pb={1} w="full">
					<Table size="small" bg="white" color={"gray.600"} w="full">
						<Thead>
							<Tr>
								<Td px="2"></Td>
								<Td px="2">
									<VStack spacing={0} fontWeight="semibold">
										<Text fontSize="xs">Orig Budget Rate</Text>
										<Text>
											{formatValue(contractStatus?.getOrigEstRate() ?? 0, 2, "currency") +
												" / hr"}
										</Text>
									</VStack>
								</Td>
								<Td px="2">
									<VStack spacing={0} fontWeight="semibold">
										<Text fontSize="xs">Budget Rate</Text>
										<Text>
											{formatValue(contractStatus?.getCurrEstRate() ?? 0, 2, "currency") +
												" / hr"}
										</Text>
									</VStack>
								</Td>
								<Td px="2">
									<VStack spacing={0} fontWeight="semibold">
										<Text fontSize="xs">Current Rate</Text>
										<Text>
											{formatValue(contractStatus?.getActualRate() ?? 0, 2, "currency") + " / hr"}
										</Text>
									</VStack>
								</Td>
								<Td px="2">
									<VStack spacing={0} fontWeight="semibold">
										<Text fontSize="xs">Rate to Complete</Text>
										<Text>
											{formatValue(contractStatus?.getRateToComplete() ?? 0, 2, "currency") +
												" / hr"}
										</Text>
									</VStack>
								</Td>
								<Td px="2">
									<VStack spacing={0} fontWeight="semibold">
										<Text fontSize="xs">Projected Rate</Text>
										<Text>
											{formatValue(contractStatus?.getProjRate() ?? 0, 2, "currency") + " / hr"}
										</Text>
									</VStack>
								</Td>
								<Td px="2">
									<VStack spacing={0} fontWeight="semibold">
										<Text fontSize="xs">Variance on Rate</Text>
										<Text color={getValueColor(contractStatus?.getVarOnRateAmt())}>
											{formatValue(contractStatus?.getVarOnRateAmt(), 0, "currency")}
										</Text>
									</VStack>
								</Td>
							</Tr>

							<Tr borderColor="black" borderWidth="2px" textTransform="uppercase" bg={"teal.700"}>
								<Th>
									<Heading fontSize="xs" color={"white"} isTruncated p="2" textAlign="center">
										Categories
									</Heading>
								</Th>
								<Th>
									<Heading fontSize="xs" color={"white"} isTruncated p="2" textAlign="center">
										Original Budget
									</Heading>
								</Th>
								<Th>
									<Heading fontSize="xs" color={"white"} isTruncated p="2" textAlign="center">
										Current Budget
									</Heading>
								</Th>
								<Th>
									<Heading fontSize="xs" color={"white"} isTruncated p="2" textAlign="center">
										Current Cost
									</Heading>
								</Th>
								<Th>
									<Heading fontSize="xs" color={"white"} isTruncated p="2" textAlign="center">
										Cost to Complete
									</Heading>
								</Th>
								<Th>
									<Heading fontSize="xs" color={"white"} isTruncated p="2" textAlign="center">
										Cost at Completion
									</Heading>
								</Th>
								<Th>
									<Heading fontSize="xs" color={"white"} isTruncated p="2" textAlign="center">
										Over / (Under)
									</Heading>
								</Th>
							</Tr>
						</Thead>

						<Tbody>
							{projectAnalysis?.data?.map((row, i) => (
								<Tr _hover={{ bg: "blackAlpha.100" }} key={i}>
									<Td
										// onClick={() => {
										// 	openDrilldown(row?.costType);
										// }}
										px="2"
										py="1"
										isTruncated
										borderLeft="2px"
										borderRight="1px"
										borderBottomColor={"blackAlpha.200"}
										borderBottomWidth="1px"
									>
										<Flex w="full" isTruncated>
											<Text
												isTruncated
												fontSize="sm"
												_hover={{
													color: "blue.600",
													textDecoration: "underline",
													cursor: "pointer",
												}}
											>
												{row?.costTypeDesc}
											</Text>
										</Flex>
									</Td>
									<Td
										px="2"
										borderRight="1px"
										borderBottomColor={"blackAlpha.200"}
										borderBottomWidth="1px"
										bg={row?.costTypeDesc === "Pending Changes" && "blackAlpha.100"}
									>
										<Text textAlign="right">
											{row?.costTypeDesc === "Pending Changes"
												? "N/A"
												: formatValue(row?.origEstCost, 0, "currency")}
										</Text>
									</Td>
									<Td
										px="2"
										borderRight="1px"
										borderBottomColor={"blackAlpha.200"}
										borderBottomWidth="1px"
									>
										<Text textAlign="right">{formatValue(row?.currEstCost, 0, "currency")}</Text>
									</Td>
									<Td
										px="2"
										borderRight="1px"
										borderBottomColor={"blackAlpha.200"}
										borderBottomWidth="1px"
										bg={row?.costTypeDesc === "Pending Changes" && "blackAlpha.100"}
									>
										<Text textAlign="right">
											{row?.costTypeDesc === "Pending Changes"
												? "N/A"
												: formatValue(row?.actualCost, 0, "currency")}
										</Text>
									</Td>
									<Td
										px="2"
										borderRight="1px"
										borderBottomColor={"blackAlpha.200"}
										borderBottomWidth="1px"
									>
										<Text textAlign="right">{formatValue(row?.costToComplete, 0, "currency")}</Text>
									</Td>
									<Td
										px="2"
										borderRight="1px"
										borderBottomColor={"blackAlpha.200"}
										borderBottomWidth="1px"
									>
										<Text textAlign="right">{formatValue(row?.projCost, 0, "currency")}</Text>
									</Td>
									<Td
										px="2"
										borderRight="2px"
										borderBottomColor={"blackAlpha.200"}
										borderBottomWidth="1px"
									>
										<Text
											textAlign="right"
											color={getValueColor(row?.overUnder, "gray.600", "red.600", "green.600")}
										>
											{formatValue(row?.overUnder, 0, "currency", "accounting")}
										</Text>
									</Td>
								</Tr>
							))}
							<Tr fontWeight="bold" border="2px" bg="blackAlpha.100">
								<Td px="2" py="1">
									<Text textTransform="uppercase" letterSpacing={2}>
										Total
									</Text>
								</Td>
								<Td px="2" py="1">
									<Text textAlign="right">
										{formatValue(contractStatus?.origEstCost, 0, "currency")}
									</Text>
								</Td>
								<Td px="2" py="1">
									<Text textAlign="right">
										{formatValue(contractStatus?.getCurrEstCostWithPCOs(), 0, "currency")}
									</Text>
								</Td>
								<Td px="2" py="1">
									<Text textAlign="right">
										{formatValue(contractStatus?.actualCost, 0, "currency")}
									</Text>
								</Td>
								<Td px="2" py="1">
									<Text textAlign="right">
										{formatValue(contractStatus?.getCostToCompleteWithPCOs(), 0, "currency")}
									</Text>
								</Td>
								<Td px="2" py="1">
									<Text textAlign="right">
										{formatValue(parseFloat(contractStatus?.getProjCostWithPCOs()), 0, "currency")}
									</Text>
								</Td>
								<Td px="2" py="1">
									<Text
										textAlign="right"
										color={getValueColor(-contractStatus?.getOverUnderBudgetWithPCOs())}
										fontWeight={"semibold"}
									>
										{formatValue(
											contractStatus?.getOverUnderBudgetWithPCOs(),
											0,
											"currency",
											"accounting"
										)}
									</Text>
								</Td>
							</Tr>
							<MFRProjectAnalysisSummarySection contract={contract} contractStatus={contractStatus} />
						</Tbody>
					</Table>
				</Box>
			</VStack>
		</Stack>
	);
}
