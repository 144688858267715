import React, { useState, useEffect, useMemo } from "react";
import {
	Box,
	Center,
	Flex,
	Heading,
	Text,
	Stack,
	Button,
	Icon,
	HStack,
	Tooltip,
	IconButton,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Collapse,
	Tag,
	TagLabel,
	Portal,
} from "@chakra-ui/react";
import { FaTrashAlt } from "react-icons/fa";
import { AiFillEdit } from "react-icons/ai";
import BasicCard from "../../../core/Layout/cards/BasicCard";

import CompanyEvent from "../classes/CompanyEvent";
import EventDrawer from "./UpcomingEventsDrawer";

import { portalUser } from "../../../App";
import { formatValue, sortArrayBy } from "../../../helperFunctions";
import { motion } from "framer-motion";

export default function UpcomingEventsCard() {
	const [events, setEvents] = useState(null);

	const [drawerIsOpen, setDrawerIsOpen] = useState(false);
	const [selectedEvent, setSelectedEvent] = useState(null);
	const [showMore, setShowMore] = useState(false);

	useEffect(() => {
		const init = async () => {
			if (Boolean(portalUser.user?.usersAPI)) {
				let newEvents = portalUser.getCalendarEvents();
				newEvents = newEvents?.filter((d) => ["Holiday", "Company"].includes(d.eventType)) ?? [];

				let todayMoment = formatValue(new Date(), 0, "moment");
				let endMoment = formatValue(new Date(), 0, "moment")?.add(6, "M");
				newEvents =
					newEvents?.filter(
						(d) =>
							formatValue(d.end, 0, "moment").diff(todayMoment) >= 0 &&
							formatValue(d.end, 0, "moment").diff(endMoment) <= 0
					) ?? [];

				let timeOffRequests = portalUser.getTimeOffRequests();
				timeOffRequests = timeOffRequests?.filter((d) => d.level <= 5) ?? [];
				timeOffRequests =
					timeOffRequests?.filter(
						(d) =>
							formatValue(d.endDate, 0, "moment").diff(todayMoment) >= 0 &&
							formatValue(d.endDate, 0, "moment").diff(endMoment) <= 0
					) ?? [];

				newEvents = [...newEvents, ...timeOffRequests];
				newEvents = sortArrayBy(newEvents, "startDate", 1, "datetime");

				let events = [];
				for (let i = 0; i < newEvents?.length; i++) {
					events.push(new CompanyEvent(newEvents[i]));
				}

				// let events = await portalUser.user?.usersAPI.GetTeamCalendar(portalUser.user?.employeeUID, "Upcoming");
				// if (events?.status === 200) {
				// 	events = events.value;
				// 	log("oldEvents", events);
				// 	for (let i = 0; i < events?.length; i++) {
				// 		events[i] = new CompanyEvent(events[i]);
				// 	}
				// }

				let filteredEvents = [];
				for (let i = 0; i < events?.length; i++) {
					if (
						(events[i]?.regionID === portalUser.user.regionID || events[i]?.regionID === null) &&
						events[i].eventType !== "Time Off" &&
						!Boolean(portalUser.user.isPortalAdminYN === "Y")
					) {
						filteredEvents.push(events[i]);
					} else if (events[i].eventType !== "Time Off" && Boolean(portalUser.user.isPortalAdminYN === "Y")) {
						filteredEvents.push(events[i]);
					} else if (events[i].eventType === "Time Off") {
						filteredEvents.push(events[i]);
					}
				}

				setEvents(filteredEvents);
			}
		};
		if (!Boolean(events?.length > 0) && Boolean(portalUser.user?.usersAPI)) {
			init();
		}
	}, [events?.length]);

	const closeDrawer = () => {
		setDrawerIsOpen(false);
		setSelectedEvent(null);
	};

	const createEvent = async (event) => {
		event = new CompanyEvent(event);
		let result = await event.upload();
		if (result) {
			setEvents([...events, event]);
			closeDrawer();
		}
	};

	const saveEvent = async (event) => {
		let tempEvents = events;
		for (let i = 0; i < tempEvents?.length; i++) {
			if (tempEvents[i].eventUID === event.eventUID) {
				tempEvents[i].setTitle(event.title);
				tempEvents[i].setRegionID(event?.regionID);
				tempEvents[i].setEmployeeType(event.setEmployeeType);
				tempEvents[i].setDescription(event.description);
				tempEvents[i].setStartDate(event.startDate);
				tempEvents[i].setEndDate(event.endDate);
				tempEvents[i].setEventType(event.eventType);
				await tempEvents[i].sync();
				break;
			}
		}
		setEvents(tempEvents);
		closeDrawer();
	};

	const deleteEvent = async (eventUID) => {
		for (let i = 0; i < events?.length; i++) {
			if (events[i].eventUID === eventUID) {
				await events[i].delete();
				break;
			}
		}
		setEvents(events.filter((e) => e.eventUID !== eventUID));
	};

	const editEvent = (eventUID) => {
		let tempEvents = events;
		let selected = null;
		for (let i = 0; i < tempEvents?.length; i++) {
			if (tempEvents[i].eventUID === eventUID) {
				selected = events[i];
				break;
			}
		}
		setSelectedEvent(selected);
		setDrawerIsOpen(true);
	};

	let cardOptions = (
		<Box hidden={!Boolean(portalUser.user?.isPortalAdminYN ?? portalUser.user?.isDevOpsUserYN === "Y")}>
			<Button
				variant="outline"
				onClick={() => setDrawerIsOpen(true)}
				color="gray.500"
				px={2}
				size="md"
				bg="whiteAlpha.700"
				shadow="md"
				textTransform="uppercase"
				_hover={{
					color: "blue.500",
					fontWeight: "bold",
					bg: "whiteAlpha.800",
					borderColor: "blue.500",
					borderWidth: 2,
				}}
			>
				<HStack align="center" spacing={1}>
					<Text lineHeight={1} className={"fas fa-plus fa-fw"} fontSize="sm" />
					<Text lineHeight={1} fontSize="sm" letterSpacing={1} pr={1}>
						Add
					</Text>
				</HStack>
			</Button>
		</Box>
	);

	let eventRows = [];
	for (let i = 0; i < events?.length; i++) {
		eventRows.push(
			<UpcomingEventsCardRow key={i} event={events[i]} editEvent={editEvent} deleteEvent={deleteEvent} />
		);
	}

	return (
		<BasicCard
			title="Upcoming Events"
			cardOptions={cardOptions}
			bg="blackAlpha.100"
			p={4}
			shadow="lg"
			borderWidth={2}
		>
			{drawerIsOpen && (
				<EventDrawer
					isOpen={drawerIsOpen}
					onClose={closeDrawer}
					createEvent={createEvent}
					event={selectedEvent}
					saveEvent={saveEvent}
				/>
			)}

			<Stack spacing={3}>
				{eventRows?.map((eventRow, i) => i < 4 && eventRow)}

				<Collapse in={showMore} style={{ overflow: "visible" }}>
					<Stack spacing={3} pb={3}>
						{eventRows?.map((eventRow, i) => i >= 4 && eventRow)}
					</Stack>
				</Collapse>

				{events?.length > 4 && (
					<Flex w="full" justify="center">
						<Button
							variant="outline"
							onClick={() => setShowMore(!showMore)}
							color="gray.500"
							px={2}
							size="md"
							bg="whiteAlpha.700"
							shadow="md"
							textTransform="uppercase"
							_hover={{
								color: "blue.500",
								fontWeight: "bold",
								bg: "whiteAlpha.800",
								borderColor: "blue.500",
								textDecoration: "underline",
								borderWidth: 2,
							}}
						>
							<HStack align="center" spacing={1} key={!showMore ? "Show More" : "Show Less"}>
								<Text
									lineHeight={1}
									className={!showMore ? "fas fa-plus fa-fw" : "fas fa-minus fa-fw"}
									fontSize="sm"
								/>
								<Text lineHeight={1} fontSize="sm" letterSpacing={1} pr={1}>
									{!showMore ? "Show More" : "Show Less"}
								</Text>
							</HStack>
						</Button>
					</Flex>
				)}
			</Stack>
		</BasicCard>
	);
}

function UpcomingEventsCardRow(props) {
	const event = useMemo(() => props.event, [props.event]);
	const editable = Boolean(portalUser.user.isPortalAdminYN === "Y");

	return (
		<motion.div whileHover={{ scale: 1.01, transition: { duration: 0.5 } }}>
			<Flex
				p={4}
				_hover={{
					// bg: "white",
					borderWidth: 2,
					borderColor: event.getEventType().color,
					shadow: "lg",
					bg: event.getEventType().color?.replace(".600", ".50")?.replace(".500", ".50"),
				}}
				rounded="md"
				bg="whiteAlpha.800"
				borderWidth={2}
				shadow={portalUser.styles?.cardShadow}
				borderColor={portalUser.styles?.cardBorderColor}
			>
				<Stack>
					<Flex align="flex-end">
						<Box w="65px" h="65px" rounded="md" shadow="lg" bg="white" borderWidth={1}>
							<Center bg="red.600" borderTopRadius="md" p="1">
								<Heading
									as="h4"
									size="sm"
									color="whiteAlpha.900"
									textTransform="uppercase"
									fontWeight="900"
								>
									{formatValue(new Date(event?.endDate ?? event?.startDate), 0, "moment").format(
										"MMM"
									)}
								</Heading>
							</Center>

							<Heading py={1} fontSize="2xl" color="gray.600" h="full" textAlign="center">
								{formatValue(new Date(event?.endDate ?? event?.startDate), 0, "moment").format("D")}
							</Heading>
						</Box>
						<Tooltip label={event.getEventType().type} placement="right" bg={event.getEventType().color}>
							<IconButton
								isRound
								variant="unstyled"
								key={event.eventUID}
								size="sm"
								color="white"
								bg={event.getEventType().color}
								ml="-4"
								mb="-2"
							>
								<i className={event.getEventType().icon + " fa-lg fa-fw"} />
							</IconButton>
						</Tooltip>
					</Flex>

					{editable && event?.regionID !== null && (
						<Center maxW="70px" wrap={"wrap"}>
							{event?.regionID === 0 && (
								<Tag
									zIndex="10"
									size="sm"
									color="white"
									bg="blackAlpha.500"
									variant="solid"
									textTransform="uppercase"
								>
									<TagLabel w="full" textAlign="center">
										SoCal
									</TagLabel>
								</Tag>
							)}
							{event?.regionID === 1 && (
								<Tag
									zIndex="10"
									size="sm"
									color="white"
									bg="blackAlpha.500"
									variant="solid"
									textTransform="uppercase"
								>
									<TagLabel w="full" textAlign="center">
										NorCal
									</TagLabel>
								</Tag>
							)}
							{event?.regionID === 3 && (
								<Tag
									zIndex="10"
									size="sm"
									color="white"
									bg="blackAlpha.500"
									variant="solid"
									textTransform="uppercase"
								>
									<TagLabel w="full" textAlign="center">
										Hawaii
									</TagLabel>
								</Tag>
							)}
						</Center>
					)}
				</Stack>
				<Center px={4} flex={1}>
					<Stack spacing={0} flex={1}>
						<Heading size="md" color="gray.600">
							{event?.title?.replace("`", "'")}
						</Heading>
						<HStack align="center">
							<Text fontSize="md" color="gray.500">
								{formatValue(new Date(event.startDate), 0, "moment").format("dddd, MMMM Do YYYY")}
								{Boolean(event?.endDate) && event?.endDate !== event?.startDate
									? " - " +
									  formatValue(new Date(event.endDate), 0, "moment").format("dddd, MMMM Do YYYY")
									: ""}
							</Text>
							<Tag
								zIndex="10"
								size="sm"
								color="white"
								bg={event.getEventType().color}
								variant="solid"
								textTransform="uppercase"
							>
								<TagLabel textAlign="center">{event.getEventType().type}</TagLabel>
							</Tag>
						</HStack>

						<Text fontSize="sm" color="gray.500">
							{event.description?.replace("`", "'")}
						</Text>
					</Stack>
				</Center>
				{editable && (
					<Center>
						<Menu isLazy>
							<MenuButton pl={1} variant="ghost" size="sm" color="gray.500">
								<i className="fas fa-ellipsis-v fa-lg" />
							</MenuButton>
							<Portal>
								<MenuList>
									<MenuItem
										color="gray.500"
										onClick={() => {
											props.editEvent(event.eventUID);
										}}
									>
										<HStack
											spacing="4"
											align="center"
											_hover={{ color: "green.600", fontWeight: "bold" }}
										>
											<Icon as={AiFillEdit} w="30px" h="30px" opacity=".85" />
											<Heading
												as="h4"
												color="gray.500"
												size="sm"
												textTransform="uppercase"
												letterSpacing={1}
												_hover={{ color: "green.600", fontWeight: "bold" }}
											>
												EDIT EVENT
											</Heading>
										</HStack>
									</MenuItem>
									<MenuItem
										color="gray.500"
										onClick={() => {
											props.deleteEvent(event.eventUID);
										}}
									>
										<HStack
											spacing="4"
											align="center"
											_hover={{ color: "red.600", fontWeight: "bold" }}
										>
											<Icon as={FaTrashAlt} w="30px" h="30px" opacity=".85" />
											<Heading
												as="h4"
												color="gray.500"
												size="sm"
												textTransform="uppercase"
												letterSpacing={1}
												_hover={{ color: "red.600", fontWeight: "bold" }}
											>
												DELETE EVENT
											</Heading>
										</HStack>
									</MenuItem>
								</MenuList>
							</Portal>
						</Menu>
					</Center>
				)}
			</Flex>
		</motion.div>
	);
}
