import OperationsAPI from "../../../APIs/OperationsAPI";
import { formatValue, log } from "../../../helperFunctions";

export default class DiversityCert {
	constructor(cert) {
		this.operationsAPI = new OperationsAPI();
		this.queryData = cert?.queryData ?? "DiversityCert";
		this.keyID = cert?.keyID ?? null;
		this.vendorGroup = cert?.vendorGroup ?? null;
		this.vendorID = cert?.vendorID ?? null;
		this.diversityCert = cert?.diversityCert ?? null;
		this.expirationDate = formatValue(cert?.dateExpires, 0, "date") ?? null;
		this.reminderStatus = cert?.reminderStatus ?? null;
		this.reminderDate = cert?.reminderDate ?? null;
		this.city = cert?.city ?? null;
		this.certSubType = cert?.certSubType;
	}

	getCertAttribute(attribute) {
		// STRINGS
		if (attribute === "diversityCert") {
			return "CertType";
		} else if (attribute === "vendorGroup") {
			return "VendorGroup";
		} else if (attribute === "vendorID") {
			return "Vendor";
		} else if (attribute === "expirationDate") {
			return "Expiration";
		} else if (attribute === "reminderStatus") {
			return "ReminderStatus";
		} else if (attribute === "reminderDate") {
			return "ReminderDate";
		} else if (attribute === "city") {
			return "City";
		} else if (attribute === "certSubType") {
			return "CertSubType";
		}
	}

	// SQL DELETE Diversity Cert
	async deleteDiversityCert() {
		let result;
		if (Boolean(this.keyID)) {
			result = await this.operationsAPI.DeleteDiversityCert({ keyID: this.keyID });
			log("DATABASE RESULT DELETE Diversity Cert", result?.value);
		}
		return result;
	}

	async insertDiversityCert() {
		let data = {
			vendorGroup: this.vendorGroup,
			vendorID: this.vendorID,
			diversityCert: this.diversityCert,
			dateExpires: this.expirationDate,
		};
		let result = await this.operationsAPI.InsertDiversityCert({}, data);
		log("NEW CERTIFICATION INSERTED", result);
		return result;
	}

	async updateDiversity(updates) {
		let result = await this.operationsAPI.UpdateDiversityCert({ keyID: this.keyID }, updates);
		log("DATABASE RESULT", result?.value);
	}

	// Update the database
	async update(attribute = null, value = null) {
		let strings = ["diversityCert", "city", "certSubType"];
		let numbers = ["vendorGroup", "vendorID", "reminderStatus"];
		let dates = ["expirationDate", "reminderDate"];

		let certAttributes = [
			"diversityCert",
			"vendorGroup",
			"vendorID",
			"expirationDate",
			"reminderStatus",
			"reminderDate",
			"city",
			"certSubType",
		];

		let val = null;

		// STRINGS
		if (strings.includes(attribute)) {
			val = value?.replace("'", "`") ?? null;
			if (attribute !== "notes") {
				val = val?.replace(/\n/g, " ")?.trim() ?? null;
			}

			this[attribute] = val;
		}
		// NUMBERS
		else if (numbers.includes(attribute)) {
			val = parseFloat(formatValue(value, 2, "numeric"));

			if (!Boolean(val) || isNaN(val)) {
				val = 0;
			}

			this[attribute] = val;
		}
		// DATES
		else if (dates.includes(attribute)) {
			if (Boolean(value)) {
				val = formatValue(value, 0, "date");
				this[attribute] = val;
			}
		}

		// Update cert
		if (certAttributes.includes(attribute) && Boolean(this.keyID)) {
			let dbAttribute = this.getCertAttribute(attribute);

			let update = { [dbAttribute]: val };

			let result = await this.updateDiversity(update);

			return result;
		}
	}
}
