import { ajaxGET, ajaxPOST, ajaxPATCH, ajaxDELETE, testGET, testPOST, setQueryParams } from "./api-functions";
import { formatValue } from "../helperFunctions";
import { portalUser } from "../App";

const apiBaseURL =
	(process.env.NODE_ENV === "development"
		? "https://ppmportalappnodejs-api.azurewebsites.net"
		: window.location.origin) + "/api/users/";

export default class UsersAPI {
	async TestGET(headers = {}) {
		let response = await testGET(apiBaseURL, headers);
		return response;
	}

	async TestPOST(body = {}, headers = {}) {
		let response = await testPOST(apiBaseURL, body, headers);
		return response;
	}

	//----Batch API Calls-----

	async PortalUser_GetUserData(msUserID = portalUser.user?.msUserID) {
		var apiRouteURL = apiBaseURL + `userData/${msUserID}`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//UPDATE User by EmployeeUID
	async PortalUser_UserData_UpdateUser(employeeUID = null, data = {}) {
		var apiRouteURL = apiBaseURL + `userData/user/${employeeUID}`;
		if (Boolean(employeeUID) && Object.keys(data)?.length > 0) {
			let response = await ajaxPATCH(apiRouteURL, data);
			return response;
		} else {
			return;
		}
	}

	//UPDATE User Management by EmployeeUID
	async PortalUser_EmployeeData_UpdateManagement(employeeUID = null, data = {}) {
		var apiRouteURL = apiBaseURL + `userData/management/${employeeUID}`;
		if (Boolean(employeeUID) && Object.keys(data)?.length > 0) {
			let response = await ajaxPATCH(apiRouteURL, data);
			return response;
		} else {
			return;
		}
	}

	//UPDATE Employee by EmployeeUID
	async UpdatePREmployee(employeeUID, data) {
		var apiRouteURL = apiBaseURL + `premployee/${employeeUID}`;
		let response = await ajaxPATCH(apiRouteURL, data);
		return response;
	}

	//--------------- Announcements ---------------

	//INSERT Announcement
	async PortalUser_InsertAnnouncement(data) {
		var apiRouteURL = apiBaseURL + `userData/announcements`;
		let response = await ajaxPOST(apiRouteURL, data);
		return response;
	}

	//UPDATE Announcement by keyID
	async PortalUser_UpdateAnnouncement(keyID, data) {
		var apiRouteURL = apiBaseURL + `userData/announcements/${keyID}`;
		let response = await ajaxPATCH(apiRouteURL, data);
		return response;
	}

	//DELETE Announcement by keyID
	async PortalUser_DeleteAnnouncement(keyID) {
		var apiRouteURL = apiBaseURL + `userData/announcements/${keyID}`;
		let response = await ajaxDELETE(apiRouteURL);
		return response;
	}

	//--------------- PORTAL NOTIFICATIONS ---------------

	//INSERT Notification
	async PortalUser_InsertNotification(data) {
		var apiRouteURL = apiBaseURL + `userData/notifications`;
		let response = await ajaxPOST(apiRouteURL, data);
		return response;
	}

	//UPDATE Notification by keyID
	async PortalUser_UpdateNotification(keyID, data) {
		var apiRouteURL = apiBaseURL + `userData/notifications/${keyID}`;
		let response = await ajaxPATCH(apiRouteURL, data);
		return response;
	}

	//DELETE Notification by keyID
	async PortalUser_DeleteNotification(keyID) {
		var apiRouteURL = apiBaseURL + `userData/notifications/${keyID}`;
		let response = await ajaxDELETE(apiRouteURL);
		return response;
	}

	//UPDATE Notifications Dismiss All by msUserID
	async PortalUser_DismissAllNotifications(msUserID = portalUser.user?.msUserID, data) {
		var apiRouteURL = apiBaseURL + `userData/${msUserID}/notifications`;
		let response = await ajaxPATCH(apiRouteURL, data);
		return response;
	}

	//--------------- EMAIL NOTIFICATIONS ---------------

	//INSERT Email
	async SendgridEmail(data) {
		let apiRouteURL = apiBaseURL + "sendgrid";
		let response = await ajaxPOST(apiRouteURL, data);
		return response;
	}

	//--------------- CALENDAR EVENTS ---------------

	// //GET Calendar Events New  (*not currently filtering for specific user - currently App Startup*)
	// async PortalUser_GetCalendarEvents(msUserID = portalUser.user?.msUserID) {
	// 	var apiRouteURL = apiBaseURL + `userData/${msUserID}/calendarEvents`;
	// 	let response = await ajaxGET(apiRouteURL);
	// 	return response;
	// }

	//INSERT CalendarEvent ****LEGACY**** (Used By Class CompanyEvent and Components UpcomingEventsCard & UpcomingEventsDrawer)
	async InsertCalendarEvent(data) {
		var apiRouteURL = `${apiBaseURL}/calendarEvents`;
		let response = await ajaxPOST(apiRouteURL, data);
		return response;
	}

	//UPDATE CalendarEvent  ****LEGACY**** (Used By Class CompanyEvent and Components UpcomingEventsCard & UpcomingEventsDrawer)
	async UpdateCalendarEvent(eventUID, data) {
		var apiRouteURL = `${apiBaseURL}/calendarEvents/${eventUID}`;
		let response = await ajaxPATCH(apiRouteURL, data);
		return response;
	}

	//DELETE CalendarEvent  ****LEGACY**** (Used By Class CompanyEvent and Components UpcomingEventsCard & UpcomingEventsDrawer)
	async DeleteCalendarEvent(eventUID) {
		var apiRouteURL = `${apiBaseURL}/calendarEvents/${eventUID}/delete`;
		let response = await ajaxDELETE(apiRouteURL);
		return response;
	}

	//--------------- EMERGENCY CONTACTS ---------------

	//GET Emergency Contacts by EmployeeUID
	async GetEmergencyContacts(employeeUID) {
		var apiRouteURL = apiBaseURL + `user/${employeeUID}/emergencycontacts`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//INSERT Emergency Contacts by EmployeeUID
	async InsertEmergencyContact(employeeUID, contactUID, data) {
		var apiRouteURL = apiBaseURL + `user/${employeeUID}/emergencycontacts/${contactUID}`;
		let response = await ajaxPOST(apiRouteURL, data);
		return response;
	}

	//UPDATE Emergency Contacts by EmployeeUID & ContactUID
	async UpdateEmergencyContact(employeeUID, contactUID, data) {
		var apiRouteURL = apiBaseURL + `user/${employeeUID}/emergencycontacts/${contactUID}`;
		let response = await ajaxPATCH(apiRouteURL, data);
		return response;
	}

	//DELETE Emergency Contacts by EmployeeUID & ContactUID
	async DeleteEmergencyContact(employeeUID, contactUID) {
		var apiRouteURL = apiBaseURL + `user/${employeeUID}/emergencycontacts/${contactUID}`;
		let response = await ajaxDELETE(apiRouteURL);
		return response;
	}

	//--------------- LONGEVITY AWARDS ---------------

	//GET LongevityAwards
	async GetLongevityAwards(projectedDate) {
		projectedDate = formatValue(projectedDate, 0, "dateURL");
		var apiRouteURL = `${apiBaseURL}/longevity`;
		apiRouteURL += "?projectedDate=" + projectedDate;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//UPDATE LongevityAward
	async UpdateLongevityAward(employeeUID, data) {
		var apiRouteURL = `${apiBaseURL}/longevity/${employeeUID}`;
		let response = await ajaxPATCH(apiRouteURL, data);
		return response;
	}

	//GET LongevityAward Detail (Not Used Yet - Please Keep)
	async GetLongevityAwardDetail(employeeUID, projectedDate) {
		projectedDate = formatValue(projectedDate, 0, "dateURL");
		var apiRouteURL = `${apiBaseURL}/longevity/${employeeUID}/detail`;
		apiRouteURL += "?projectedDate=" + projectedDate;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//---------------------LEGACY  TO BE REMOVED ---------------------------//

	//----PPMUser LEGACY Calls-----

	//GET PR Employees *****LEGACY***** (PPMUser & TimeOffRequestLegacy Classes)
	async GetAllUsers(queryParam) {
		var apiRouteURL = apiBaseURL + "users";
		apiRouteURL += "?param=" + queryParam;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET PR Employees by Group ??? is a Post??? *****LEGACY***** (PPMUserList)
	async GetUsers(userArr) {
		var apiRouteURL = apiBaseURL + "usersByGroup";
		let data = { group: userArr };
		let response = await ajaxPOST(apiRouteURL, data);
		return response;
	}

	//GET PR Employees by MSUserID *****LEGACY***** (PortalUser - initPPMUser)
	async GetUser(msUserID = portalUser.user?.msUserID) {
		var apiRouteURL = apiBaseURL + `user/${msUserID}`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//UPDATE Portal User's Account MSUserID if NULL (PortalUser - initPPMUser)
	async SetMicrosoftID(msUserID, email) {
		var apiRouteURL = apiBaseURL + `microsoftUser/${msUserID}`;
		let data = { email: email };
		let response = await ajaxPATCH(apiRouteURL, data);
		return response;
	}

	//GET User by EmployeeUID  *****LEGACY***** (Used in Classes Email & TimeOffRequestLegacy)
	async GetUserByEmployeeUID(employeeUID) {
		var apiRouteURL = apiBaseURL + `userByEmployeeUID/${employeeUID}`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET Approvers by EmployeeUID *****LEGACY***** (TimeOffRequestsCard Component)
	async GetApprovers(employeeUID) {
		var apiRouteURL = apiBaseURL + `approvers/${employeeUID}`;
		apiRouteURL = setQueryParams(apiRouteURL);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//UPDATE Employee Management by EmployeeUID *****LEGACY***** (TimeOffRequestsCard Component)
	async UpdateEmployeeManagement(employeeUID, data = {}) {
		var apiRouteURL = apiBaseURL + `userReviewers/${employeeUID}`;
		if (Boolean(employeeUID) && Object.keys(data)?.length > 0) {
			let response = await ajaxPATCH(apiRouteURL, data);
			return response;
		} else {
			return;
		}
	}

	//----Notification LEGACY Calls-----

	//INSERT Notification ****LEGACY**** (Used By Class TimeOffRequestLegacy)
	async InsertNotification(data) {
		let apiRouteURL = apiBaseURL + "notification";
		let response = await ajaxPOST(apiRouteURL, data);
		return response;
	}

	//UPDATE Notification ****LEGACY**** (Used By Class TimeOffRequestLegacy)
	async UpdateNotification(notificationUID, data) {
		let apiRouteURL = apiBaseURL + `notification/${notificationUID}`;
		let response = await ajaxPATCH(apiRouteURL, data);
		return response;
	}

	//----Calendar Events LEGACY Calls-----

	//GET CalendarEvents  ****LEGACY**** (Used By Components HRCalendar, TimeOffAcknowledgeModal, TimeOffRequestModal & TimeOffRequestsCard)
	async GetCalendarEvents(params = {}, query = {}) {
		var apiRouteURL = `${apiBaseURL}/calendarEvents`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//----Timeoff Requests LEGACY Calls-----

	//GET All Timeoff Requests ****LEGACY**** (Used By Components HRCalendar)
	async GetTimeOffRequestList() {
		let apiRouteURL = apiBaseURL + "timeOffRequest/all";
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET Timeoff Request by EmployeeUID ****LEGACY**** (Used By Components EmployeeTimeOffPage & TimeOffRequestsCard)
	async GetUserTimeoffRequests(employeeUID) {
		var apiRouteURL = apiBaseURL + `user/${employeeUID}/timeoffRequests`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET Timeoff Request by RequestUID ****LEGACY**** (Used By Component TimeOffRequest &  Class TimeOffRequestLegacy)
	async GetTimeOffRequest(requestUID) {
		let apiRouteURL = apiBaseURL + `timeOffRequest/${requestUID}`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//INSERT Timeoff Request ****LEGACY**** (Used By Component HRCalendar &  Class TimeOffRequestLegacy)
	async InsertTimeoffRequest(request) {
		var apiRouteURL = apiBaseURL + "timeoffRequest";
		let data = { request };
		let response = await ajaxPOST(apiRouteURL, data);
		return response;
	}

	//UPDATE Timeoff Request by RequestUID ****LEGACY**** (Used By Class TimeOffRequestLegacy)
	async UpdateTimeOffRequest(requestUID, data) {
		let apiRouteURL = apiBaseURL + `timeOffRequest/${requestUID}`;
		let response = await ajaxPATCH(apiRouteURL, data);
		return response;
	}

	//DELETE Timeoff Request by RequestUID ****LEGACY**** (Used By Class TimeOffRequestLegacy)
	async DeleteTimeOffRequest(requestUID) {
		let apiRouteURL = apiBaseURL + `timeOffRequest/${requestUID}/delete`;
		let response = await ajaxDELETE(apiRouteURL);
		return response;
	}
}
