export default class ProjectData {
	constructor(project) {
		if (project instanceof ProjectData) {
			Object.assign(this, project);
		} else {
			//PROJECT
			this.projectAPI = project?.projectAPI ?? null;
			this.contracts = project?.contracts ?? [];
			this.items = project?.items ?? [];
			this.jobs = project?.jobs ?? [];
			this.costcodes = project?.costcodes ?? [];
			this.acos = project?.acos ?? [];
			this.pcos = project?.pcos ?? [];
			this.rfis = project?.rfis ?? [];
		}
	}

	classifyData(project = this, sqlData = []) {
		for (let i = 0; i < sqlData?.length; i++) {
			// let queryData = sqlData[i]?.queryData;
			// if (queryData === "ARCustomer") {
			// 	project.customers.push(new ARCustomer(sqlData[i]));
			// } else if (queryData === "APVendor") {
			// 	project.vendors.push(new APVendor(sqlData[i]));
			// } else if (queryData === "DiversityCert") {
			// 	project.diversityCerts.push(new DiversityCert(sqlData[i]));
			// } else if (queryData === "JCProjectMgr") {
			// 	project.projectMgrs.push(new JCProjectMgr(sqlData[i]));
			// } else if (queryData === "ProjectAcct") {
			// 	project.projectAccts.push(new ProjectAcct(sqlData[i]));
			// } else if (queryData === "Purchaser") {
			// 	project.purchasers.push(new Purchaser(sqlData[i]));
			// } else if (queryData === "Phase") {
			// 	project.costcodes.push(new JCPhase(sqlData[i]));
			// } else if (queryData === "CostType") {
			// 	project.costtypes.push(new JCCostType(sqlData[i]));
			// } else if (queryData === "ScopePct") {
			// 	project.scopes.push(new Scope(sqlData[i]));
			// }
		}

		return project;
	}

	async initProjectData(params = null) {
		let project = this;

		if (Boolean(params?.msUserID) && !Boolean(project?.projectAPI)) {
			let projectAPI = null; //new OpsAPI();
			let sqlData = null; //await userAPI.InitUser(params);
			if (sqlData?.status === 200) {
				sqlData = sqlData?.value ?? [];
				project = this.classifyData(project, sqlData);
				project.projectAPI = projectAPI;
			}
		}
		return project;
	}
}
