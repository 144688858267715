import { getValueColor, chakraThemeColorsMap } from "../../../helperFunctions";

export default class SectionTemplateMFRProjectAnalysis {
	constructor(contract, contractTotals = null) {
		this.template = "MFRProjectAnalysis";
		this.name = "Project Analysis";
		this.label = "IV.";
		this.heading = "Project Analysis";
		if (["2", "4", "5"].includes(contract?.contractTypeID)) {
			this.heading += " CRS";
		}
		this.divider = false;
		this.pagebreak = 1;
		this.type = "virtualTable";
		this.minRows = 8;
		this.py = 0;

		if (!["2", "4", "5"].includes(contract?.contractTypeID)) {
			this.subSections = [new SubSectionTemplateMFRProjectAnalysis(contract, contractTotals)];
		} else {
			this.subSections = [new SubSectionTemplateMFRProjectAnalysisCRS(contract, contractTotals)];
		}
	}
}

class SubSectionTemplateMFRProjectAnalysis {
	constructor(contract = null, contractTotals = null) {
		let origEstRate = 0;
		let currEstRate = 0;
		let actualRate = 0;
		let rateToComplete = 0;
		let projRate = 0;
		let varOnRateAmt = 0;

		let laborCostToComplete = 0;
		let subLabCostToComplete = 0;
		let matCostToComplete = 0;
		let subCostToComplete = 0;
		let equipCostToComplete = 0;
		let otherJobCostToComplete = 0;
		let contCostToComplete = 0;
		let pcoCostToComplete = 0;
		let costToComplete = 0;

		let projCostWithPCOs = 0;

		let laborOverUnder = 0;
		let subLabOverUnder = 0;
		let matOverUnder = 0;
		let subOverUnder = 0;
		let equipOverUnder = 0;
		let otherJobOverUnder = 0;
		let contOverUnder = 0;
		let pcoOverUnder = 0;
		let overUnderWithPCOs = 0;

		if (Boolean(contractTotals?.queryData)) {
			origEstRate = contractTotals?.getOrigEstRate() ?? 0;
			currEstRate = contractTotals?.getCurrEstRate() ?? 0;

			actualRate = contractTotals?.getActualRate() ?? 0;

			rateToComplete = contractTotals?.getRateToComplete() ?? 0;
			projRate = contractTotals?.getProjRate() ?? 0;
			varOnRateAmt = contractTotals?.getVarOnRateAmt() ?? 0;

			laborCostToComplete = contractTotals?.getLaborCostToComplete() ?? 0;
			subLabCostToComplete = contractTotals?.getSubLabCostToComplete() ?? 0;
			matCostToComplete = contractTotals?.getMatCostToComplete() ?? 0;
			subCostToComplete = contractTotals?.getSubCostToComplete() ?? 0;
			equipCostToComplete = contractTotals?.getEquipCostToComplete() ?? 0;
			otherJobCostToComplete = contractTotals?.getOtherJobCostToComplete() ?? 0;
			contCostToComplete = contractTotals?.getContCostToComplete() ?? 0;
			pcoCostToComplete = contractTotals?.getPCOCostToComplete() ?? 0;
			costToComplete = contractTotals?.getCostToCompleteWithPCOs() ?? 0;

			projCostWithPCOs = contractTotals?.getProjCostWithPCOs() ?? 0;

			laborOverUnder = contractTotals?.getLaborOverUnderBudget() ?? 0;
			subLabOverUnder = contractTotals?.getSubLabOverUnderBudget() ?? 0;
			matOverUnder = contractTotals?.getMatOverUnderBudget() ?? 0;
			subOverUnder = contractTotals?.getSubOverUnderBudget() ?? 0;
			equipOverUnder = contractTotals?.getEquipOverUnderBudget() ?? 0;
			otherJobOverUnder = contractTotals?.getOtherJobOverUnderBudget() ?? 0;
			contOverUnder = contractTotals?.getContOverUnderBudget() ?? 0;
			pcoOverUnder = contractTotals?.getPCOOverUnderBudget() ?? 0;
			overUnderWithPCOs = contractTotals?.getOverUnderBudgetWithPCOs() ?? 0;
		}

		this.template = "mfrProjectAnalysis";
		this.type = "virtualTable";
		this.columns = [
			{
				accessor: "costTypeDesc",
				Header: "Categories",
				width: "16%",
			},
			{
				accessor: "contract",
				Header: "Job #",
				width: "10%",
				show: false,
			},
			{
				accessor: "origEstCost",
				Header: "Orig Budget",
				textAlign: "right",
				valueType: "currency",
				width: "14%",
			},
			{
				accessor: "currEstCost",
				Header: "Curr Budget",
				textAlign: "right",
				valueType: "currency",
				width: "14%",
			},
			{
				accessor: "actualCost",
				Header: "Cost To Date",
				textAlign: "right",
				valueType: "currency",
				width: "14%",
			},
			{
				accessor: "costToComplete",
				Header: "Cost To Comp",
				textAlign: "right",
				valueType: "currency",
				width: "14%",
			},
			{
				accessor: "projCost",
				Header: "Cost @ Comp",
				textAlign: "right",
				valueType: "currency",
				width: "14%",
			},
			{
				accessor: "overUnder",
				Header: "Over / (Under)",
				textAlign: "right",
				valueType: "accounting",
				width: "14%",
			},
		];
		this.header = [
			{
				costTypeDesc: null,
				costTypeDescFontSize: 6,
				costTypeDescHeight: 8,

				contract: null,
				contractFontSize: 6,
				contractHeight: 8,

				origEstCost: "Orig Budget Rate",
				origEstCostType: "string",
				origEstCostTextAlign: "center",
				origEstCostFontSize: 6,
				origEstCostHeight: 8,
				origEstCostJustifyContent: "flex-end",

				currEstCost: "Curr Budget Rate",
				currEstCostType: "string",
				currEstCostTextAlign: "center",
				currEstCostFontSize: 6,
				currEstCostHeight: 8,
				currEstCostJustifyContent: "flex-end",

				actualCost: "Actual Rate",
				actualCostType: "string",
				actualCostTextAlign: "center",
				actualCostFontSize: 6,
				actualCostHeight: 8,
				actualCostJustifyContent: "flex-end",

				costToComplete: "Rate To Complete",
				costToCompleteType: "string",
				costToCompleteTextAlign: "center",
				costToCompleteFontSize: 6,
				costToCompleteHeight: 8,
				costToCompleteJustifyContent: "flex-end",

				projCost: "Projected Rate",
				projCostType: "string",
				projCostTextAlign: "center",
				projCostFontSize: 6,
				projCostHeight: 8,
				projCostJustifyContent: "flex-end",

				overUnder: "Variance On Rate",
				overUnderType: "string",
				overUnderTextAlign: "center",
				overUnderFontSize: 6,
				overUnderHeight: 8,
				overUnderJustifyContent: "flex-end",
			},
			{
				costTypeDesc: null,
				costTypeDescHeight: 14,

				contract: null,
				contractHeight: 14,

				origEstCost: origEstRate,
				origEstCostRightAddon: "/ hr",
				origEstCostTextAlign: "center",
				origEstCostDecimals: 2,
				origEstCostHeight: 14,

				currEstCost: currEstRate,
				currEstCostRightAddon: "/ hr",
				currEstCostTextAlign: "center",
				currEstCostDecimals: 2,
				currEstCostHeight: 14,

				actualCost: actualRate,
				actualCostRightAddon: "/ hr",
				actualCostTextAlign: "center",
				actualCostDecimals: 2,
				actualCostHeight: 14,

				costToComplete: rateToComplete,
				costToCompleteRightAddon: "/ hr",
				costToCompleteTextAlign: "center",
				costToCompleteDecimals: 2,
				costToCompleteHeight: 14,

				projCost: projRate,
				projCostRightAddon: "/ hr",
				projCostTextAlign: "center",
				projCostDecimals: 2,
				projCostHeight: 14,

				overUnder: varOnRateAmt,
				overUnderTextAlign: "center",
				overUnderHeight: 14,
				overUnderDecimals: 0,
				overUnderTextColor: getValueColor(varOnRateAmt),
			},
		];
		this.data = [
			{
				costTypeDesc: "Labor",
				contract: contractTotals?.contract ?? null,
				origEstCost: contractTotals?.laborOrigEstCost ?? 0,
				currEstCost: contractTotals?.laborCurrEstCost ?? 0,
				actualCost: contractTotals?.laborActualCost ?? 0,
				costToComplete: laborCostToComplete,
				projCost: contractTotals?.laborProjCost ?? 0,
				overUnder: laborOverUnder,
				overUnderTextColor: getValueColor(-laborOverUnder),
			},
			{
				costTypeDesc: "Managed Labor",
				contract: contractTotals?.contract ?? null,
				origEstCost: contractTotals?.subLabOrigEstCost ?? 0,
				currEstCost: contractTotals?.subLabCurrEstCost ?? 0,
				actualCost: contractTotals?.subLabActualCost ?? 0,
				costToComplete: subLabCostToComplete,
				projCost: contractTotals?.subLabProjCost ?? 0,
				overUnder: subLabOverUnder,
				overUnderTextColor: getValueColor(-subLabOverUnder),
			},
			{
				costTypeDesc: "Material",
				contract: contractTotals?.contract ?? null,
				origEstCost: contractTotals?.matOrigEstCost ?? 0,
				currEstCost: contractTotals?.matCurrEstCost ?? 0,
				actualCost: contractTotals?.matActualCost ?? 0,
				costToComplete: matCostToComplete,
				projCost: contractTotals?.matProjCost ?? 0,
				overUnder: matOverUnder,
				overUnderTextColor: getValueColor(-matOverUnder),
			},
			{
				costTypeDesc: "Subcontracts",
				contract: contractTotals?.contract ?? null,
				origEstCost: contractTotals?.subOrigEstCost ?? 0,
				currEstCost: contractTotals?.subCurrEstCost ?? 0,
				actualCost: contractTotals?.subActualCost ?? 0,
				costToComplete: subCostToComplete,
				projCost: contractTotals?.subProjCost ?? 0,
				overUnder: subOverUnder,
				overUnderTextColor: getValueColor(-subOverUnder),
			},
			{
				costTypeDesc: "Rental Equip",
				contract: contractTotals?.contract ?? null,
				origEstCost: contractTotals?.equipOrigEstCost ?? 0,
				currEstCost: contractTotals?.equipCurrEstCost ?? 0,
				actualCost: contractTotals?.equipActualCost ?? 0,
				costToComplete: equipCostToComplete,
				projCost: contractTotals?.equipProjCost ?? 0,
				overUnder: equipOverUnder,
				overUnderTextColor: getValueColor(-equipOverUnder),
			},
			{
				costTypeDesc: "Other Job Costs",
				contract: contractTotals?.contract ?? null,
				origEstCost: contractTotals?.otherJobOrigEstCost ?? 0,
				currEstCost: contractTotals?.otherJobCurrEstCost ?? 0,
				actualCost: contractTotals?.otherJobActualCost ?? 0,
				costToComplete: otherJobCostToComplete,
				projCost: contractTotals?.otherJobProjCost ?? 0,
				overUnder: otherJobOverUnder,
				overUnderTextColor: getValueColor(-otherJobOverUnder),
			},
			{
				costTypeDesc: "Contingency",
				contract: contractTotals?.contract ?? null,
				origEstCost: contractTotals?.contOrigEstCost ?? 0,
				currEstCost: contractTotals?.contCurrEstCost ?? 0,
				actualCost: contractTotals?.contActualCost ?? 0,
				costToComplete: contCostToComplete,
				projCost: contractTotals?.contProjCost ?? 0,
				overUnder: contOverUnder,
				overUnderTextColor: getValueColor(-contOverUnder),
			},
			{
				costTypeDesc: "Pending Changes",
				contract: contractTotals?.contract ?? null,
				origEstCost: "N/A",
				origEstCostType: "string",
				origEstCostBg: chakraThemeColorsMap.whitesmoke,
				currEstCost: contractTotals?.pcoEstCost ?? 0,
				actualCost: "N/A",
				actualCostType: "string",
				actualCostBg: chakraThemeColorsMap.whitesmoke,
				costToComplete: pcoCostToComplete,
				projCost: pcoCostToComplete,
				overUnder: pcoOverUnder,
				overUnderTextColor: getValueColor(-pcoOverUnder),
			},
		];
		this.footer = [
			{
				costTypeDesc: "Total",
				contract: contractTotals?.contract ?? null,
				origEstCost: contractTotals?.origEstCost ?? 0,
				currEstCost: contractTotals?.currEstCost ?? 0,
				actualCost: contractTotals?.actualCost ?? 0,
				costToComplete: costToComplete,
				projCost: projCostWithPCOs,
				overUnder: overUnderWithPCOs,
				overUnderTextColor: getValueColor(-overUnderWithPCOs),
			},
		];
	}
}

class SubSectionTemplateMFRProjectAnalysisCRS {
	constructor(contract = null, contractTotals = null) {
		let origEstRate = 0;
		let currEstRate = 0;
		let actualRate = 0;
		let rateToComplete = 0;
		let projRate = 0;
		let varOnRateAmt = 0;

		let laborCostToComplete = 0;
		let subLabCostToComplete = 0;
		let matCostToComplete = 0;
		let subCostToComplete = 0;
		let equipCostToComplete = 0;
		let otherJobCostToComplete = 0;
		let contCostToComplete = 0;
		let pcoCostToComplete = 0;
		let costToComplete = 0;

		let projCostWithPCOs = 0;

		let laborOverUnder = 0;
		let subLabOverUnder = 0;
		let matOverUnder = 0;
		let subOverUnder = 0;
		let equipOverUnder = 0;
		let otherJobOverUnder = 0;
		let contOverUnder = 0;
		let pcoOverUnder = 0;
		let overUnderWithPCOs = 0;

		if (Boolean(contractTotals?.queryData)) {
			origEstRate = contractTotals?.getOrigEstRate() ?? 0;
			currEstRate = contractTotals?.getCurrEstRate() ?? 0;

			actualRate = contractTotals?.getActualRate() ?? 0;

			rateToComplete = contractTotals?.getRateToComplete() ?? 0;
			projRate = contractTotals?.getProjRate() ?? 0;
			varOnRateAmt = contractTotals?.getVarOnRateAmt() ?? 0;

			laborCostToComplete = contractTotals?.getLaborCostToComplete() ?? 0;
			subLabCostToComplete = contractTotals?.getSubLabCostToComplete() ?? 0;
			matCostToComplete = contractTotals?.getMatCostToComplete() ?? 0;
			subCostToComplete = contractTotals?.getSubCostToComplete() ?? 0;
			equipCostToComplete = contractTotals?.getEquipCostToComplete() ?? 0;
			otherJobCostToComplete = contractTotals?.getOtherJobCostToComplete() ?? 0;
			contCostToComplete = contractTotals?.getContCostToComplete() ?? 0;
			pcoCostToComplete = contractTotals?.getPCOCostToComplete() ?? 0;
			costToComplete = contractTotals?.getCostToCompleteWithPCOs() ?? 0;

			projCostWithPCOs = contractTotals?.getProjCostWithPCOs() ?? 0;

			laborOverUnder = contractTotals?.getLaborOverUnderBudget() ?? 0;
			subLabOverUnder = contractTotals?.getSubLabOverUnderBudget() ?? 0;
			matOverUnder = contractTotals?.getMatOverUnderBudget() ?? 0;
			subOverUnder = contractTotals?.getSubOverUnderBudget() ?? 0;
			equipOverUnder = contractTotals?.getEquipOverUnderBudget() ?? 0;
			otherJobOverUnder = contractTotals?.getOtherJobOverUnderBudget() ?? 0;
			contOverUnder = contractTotals?.getContOverUnderBudget() ?? 0;
			pcoOverUnder = contractTotals?.getPCOOverUnderBudget() ?? 0;
			overUnderWithPCOs = contractTotals?.getOverUnderBudgetWithPCOs() ?? 0;
		}

		this.template = "ProjectAnalysisCRS";
		this.type = "virtualTable";
		this.columns = [
			{
				accessor: "costTypeDesc",
				Header: "Categories",
				width: "16%",
			},
			{
				accessor: "contract",
				Header: "Job #",
				width: "10%",
				show: false,
			},
			{
				accessor: "origEstCost",
				Header: "Orig Budget",
				textAlign: "right",
				valueType: "currency",
				width: "14%",
			},
			{
				accessor: "currEstCost",
				Header: "Curr Budget",
				textAlign: "right",
				valueType: "currency",
				width: "14%",
			},
			{
				accessor: "actualCost",
				Header: "Cost To Date",
				textAlign: "right",
				valueType: "currency",
				width: "14%",
			},
			{
				accessor: "costToComplete",
				Header: "Cost To Comp",
				textAlign: "right",
				valueType: "currency",
				width: "14%",
			},
			{
				accessor: "projCost",
				Header: "Cost @ Comp",
				textAlign: "right",
				valueType: "currency",
				width: "14%",
			},
			{
				accessor: "overUnder",
				Header: "Over / Under",
				textAlign: "right",
				valueType: "currency",
				positiveColor: "red",
				negativeColor: "green",
				zeroColor: "gray",
				width: "14%",
			},
		];
		this.header = [
			{
				costTypeDesc: null,
				costTypeDescFontSize: 6,
				costTypeDescHeight: 8,

				contract: null,
				contractFontSize: 6,
				contractHeight: 8,

				origEstCost: "Orig Budget Rate",
				origEstCostType: "string",
				origEstCostTextAlign: "center",
				origEstCostFontSize: 6,
				origEstCostHeight: 8,
				origEstCostJustifyContent: "flex-end",

				currEstCost: "Curr Budget Rate",
				currEstCostType: "string",
				currEstCostTextAlign: "center",
				currEstCostFontSize: 6,
				currEstCostHeight: 8,
				currEstCostJustifyContent: "flex-end",

				actualCost: "Actual Rate",
				actualCostType: "string",
				actualCostTextAlign: "center",
				actualCostFontSize: 6,
				actualCostHeight: 8,
				actualCostJustifyContent: "flex-end",

				costToComplete: "Rate To Complete",
				costToCompleteType: "string",
				costToCompleteTextAlign: "center",
				costToCompleteFontSize: 6,
				costToCompleteHeight: 8,
				costToCompleteJustifyContent: "flex-end",

				projCost: "Projected Rate",
				projCostType: "string",
				projCostTextAlign: "center",
				projCostFontSize: 6,
				projCostHeight: 8,
				projCostJustifyContent: "flex-end",

				overUnder: "Variance On Rate",
				overUnderType: "string",
				overUnderTextAlign: "center",
				overUnderFontSize: 6,
				overUnderHeight: 8,
				overUnderJustifyContent: "flex-end",
			},
			{
				costTypeDesc: null,
				costTypeDescHeight: 14,

				contract: null,
				contractHeight: 14,

				origEstCost: origEstRate,
				origEstCostRightAddon: "/ hr",
				origEstCostTextAlign: "center",
				origEstCostDecimals: 2,
				origEstCostHeight: 14,

				currEstCost: currEstRate,
				currEstCostRightAddon: "/ hr",
				currEstCostTextAlign: "center",
				currEstCostDecimals: 2,
				currEstCostHeight: 14,

				actualCost: actualRate,
				actualCostRightAddon: "/ hr",
				actualCostTextAlign: "center",
				actualCostDecimals: 2,
				actualCostHeight: 14,

				costToComplete: rateToComplete,
				costToCompleteRightAddon: "/ hr",
				costToCompleteTextAlign: "center",
				costToCompleteDecimals: 2,
				costToCompleteHeight: 14,

				projCost: projRate,
				projCostRightAddon: "/ hr",
				projCostTextAlign: "center",
				projCostDecimals: 2,
				projCostHeight: 14,

				overUnder: varOnRateAmt,
				overUnderTextAlign: "center",
				overUnderHeight: 14,
				overUnderTextColor: getValueColor(varOnRateAmt, chakraThemeColorsMap.black),
			},
		];
		this.data = [
			{
				costTypeDesc: "Labor",
				contract: contractTotals?.contract ?? null,
				origEstCost: contractTotals?.laborOrigEstCost ?? 0,
				currEstCost: contractTotals?.laborCurrEstCost ?? 0,
				actualCost: contractTotals?.laborActualCost ?? 0,
				costToComplete: laborCostToComplete,
				projCost: contractTotals?.laborProjCost ?? 0,
				overUnder: laborOverUnder,
				overUnderTextColor: getValueColor(laborOverUnder),
			},
			{
				costTypeDesc: "Managed Labor",
				contract: contractTotals?.contract ?? null,
				origEstCost: contractTotals?.subLabOrigEstCost ?? 0,
				currEstCost: contractTotals?.subLabCurrEstCost ?? 0,
				actualCost: contractTotals?.subLabActualCost ?? 0,
				costToComplete: subLabCostToComplete,
				projCost: contractTotals?.subLabProjCost ?? 0,
				overUnder: subLabOverUnder,
				overUnderTextColor: getValueColor(subLabOverUnder),
			},
			{
				costTypeDesc: "Material",
				contract: contractTotals?.contract ?? null,
				origEstCost: contractTotals?.matOrigEstCost ?? 0,
				currEstCost: contractTotals?.matCurrEstCost ?? 0,
				actualCost: contractTotals?.matActualCost ?? 0,
				costToComplete: matCostToComplete,
				projCost: contractTotals?.matProjCost ?? 0,
				overUnder: matOverUnder,
				overUnderTextColor: getValueColor(matOverUnder),
			},
			{
				costTypeDesc: "Subcontracts",
				contract: contractTotals?.contract ?? null,
				origEstCost: contractTotals?.subOrigEstCost ?? 0,
				currEstCost: contractTotals?.subCurrEstCost ?? 0,
				actualCost: contractTotals?.subActualCost ?? 0,
				costToComplete: subCostToComplete,
				projCost: contractTotals?.subProjCost ?? 0,
				overUnder: subOverUnder,
				overUnderTextColor: getValueColor(subOverUnder),
			},
			{
				costTypeDesc: "Rental Equip",
				contract: contractTotals?.contract ?? null,
				origEstCost: contractTotals?.equipOrigEstCost ?? 0,
				currEstCost: contractTotals?.equipCurrEstCost ?? 0,
				actualCost: contractTotals?.equipActualCost ?? 0,
				costToComplete: equipCostToComplete,
				projCost: contractTotals?.equipProjCost ?? 0,
				overUnder: equipOverUnder,
				overUnderTextColor: getValueColor(laborOverUnder),
			},
			{
				costTypeDesc: "Other Job Costs",
				contract: contractTotals?.contract ?? null,
				origEstCost: contractTotals?.otherJobOrigEstCost ?? 0,
				currEstCost: contractTotals?.otherJobCurrEstCost ?? 0,
				actualCost: contractTotals?.otherJobActualCost ?? 0,
				costToComplete: otherJobCostToComplete,
				projCost: contractTotals?.otherJobProjCost ?? 0,
				overUnder: otherJobOverUnder,
				overUnderTextColor: getValueColor(otherJobOverUnder),
			},
			{
				costTypeDesc: "Contingency",
				contract: contractTotals?.contract ?? null,
				origEstCost: contractTotals?.contOrigEstCost ?? 0,
				currEstCost: contractTotals?.contCurrEstCost ?? 0,
				actualCost: contractTotals?.contActualCost ?? 0,
				costToComplete: contCostToComplete,
				projCost: contractTotals?.contProjCost ?? 0,
				overUnder: contOverUnder,
				overUnderTextColor: getValueColor(contOverUnder),
			},
			{
				costTypeDesc: "Pending Changes",
				contract: contractTotals?.contract ?? null,
				origEstCost: "N/A",
				origEstCostType: "string",
				origEstCostBg: chakraThemeColorsMap.whitesmoke,
				currEstCost: contractTotals?.pcoEstCost ?? 0,
				actualCost: "N/A",
				actualCostType: "string",
				actualCostBg: chakraThemeColorsMap.whitesmoke,
				costToComplete: pcoCostToComplete,
				projCost: pcoCostToComplete,
				overUnder: pcoOverUnder,
				overUnderTextColor: getValueColor(pcoOverUnder),
			},
		];
		this.footer = [
			{
				costTypeDesc: "Total",
				contract: contractTotals?.contract ?? null,
				origEstCost: contractTotals?.origEstCost ?? 0,
				currEstCost: contractTotals?.currEstCost ?? 0,
				actualCost: contractTotals?.actualCost ?? 0,
				costToComplete: costToComplete,
				projCost: projCostWithPCOs,
				overUnder: overUnderWithPCOs,
				overUnderTextColor: getValueColor(overUnderWithPCOs),
			},
		];
	}
}
