import React from "react";
import ToggleButton from "./ToggleButton";

export default function DrawerButton({ drawerIsOpen = "0", onClickDrawerButton }) {
	return (
		<ToggleButton
			toggleIsOpen={drawerIsOpen}
			onClick={onClickDrawerButton}
			toggleOptions={[
				{
					isOpen: true,
					label: "Close",
					icon: "fa-regular fa-folder-open",
					color: "blue.600",
					bg: "whiteAlpha.900",
				},
				{
					isOpen: false,
					label: "Open",
					icon: "fa-solid fa-folder-open",
					color: "teal.500",
					bg: "whiteAlpha.700",
				},
			]}
		/>
	);
}
