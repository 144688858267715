import { UserAgentApplication, InteractionRequiredAuthError, LogLevel } from "msal";
import {
	Client,
	ImplicitMSALAuthenticationProvider,
	MSALAuthenticationProviderOptions,
} from "@microsoft/microsoft-graph-client";

import moment from "moment";

const config = {
	auth: {
		clientId: process.env.REACT_APP_MS_CLIENT_ID,
		authority: process.env.REACT_APP_MS_AUTHORITY,
		postLogoutRedirectUri: window.location.origin + "/login",
	},
	system: {
		allowNativeBroker: false, // Disables WAM Broker
		loggerOptions: {
			logLevel: LogLevel.Trace,
			loggerCallback: (level, message, containsPii) => {
				if (containsPii) {
					return;
				}
				switch (level) {
					case LogLevel.Error:
						console.error(message);
						return;
					case LogLevel.Info:
						console.info(message);
						return;
					case LogLevel.Verbose:
						console.debug(message);
						return;
					case LogLevel.Warning:
						console.warn(message);
						return;
					default:
						console.log(message);
						return;
				}
			},
		},
	},
};

const scopes = [
	"User.ReadWrite",
	"User.Read.All",
	"People.Read.All",
	"Group.ReadWrite.All",
	"Calendars.ReadWrite",
	"Place.Read.All",
	"Mail.ReadWrite",
	"Tasks.ReadWrite",
	"Notes.ReadWrite.All",
	"Files.ReadWrite",
	"Sites.ReadWrite.All",
];

export default class MSAuthAPI {
	constructor(msAuth) {
		if (msAuth instanceof MSAuthAPI) {
			Object.assign(this, msAuth);
		} else {
			this.msAuthToken = msAuth?.msAuthToken ?? null;
			this.msInstance = msAuth?.msInstance ?? null;
			this.msClient = msAuth?.msClient ?? null;
			this.msUser = msAuth?.msUser ?? null;
		}
	}

	init() {
		let instance = new UserAgentApplication(config);
		let providerOptions = new MSALAuthenticationProviderOptions(scopes);
		let authProvider = new ImplicitMSALAuthenticationProvider(instance, providerOptions);
		let client = Client.initWithMiddleware({ authProvider });
		instance.handleRedirectCallback((err) => {
			console.log("initMSAuth handleRedirectCallback - Error", err);
		});
		this.msInstance = instance;
		this.msClient = client;

		return new MSAuthAPI(this);
	}

	async initMSAuth(showInConsole = false) {
		let msAuth = this;
		msAuth = await msAuth.initMSAuthToken(showInConsole);
		msAuth = await msAuth.initMSUser(showInConsole);
		return msAuth;
	}

	async initMSAuthToken(showInConsole = false) {
		let msAuth = this;
		let msAuthToken = msAuth?.msAuthToken ?? null;
		if (!Boolean(msAuthToken)) {
			msAuthToken = await msAuth.getMSAuthToken();
			msAuth.msAuthToken = msAuthToken;
		}
		if (showInConsole) {
			console.log("MSAuthAPI initMSAuthToken", msAuth.msAuthToken);
		}

		return msAuth;
	}

	async initMSUser(showInConsole = false) {
		let msAuth = this;
		let msUserID = msAuth.msAuthToken?.account?.accountIdentifier ?? null;
		if (Boolean(msUserID)) {
			let msUser = await msAuth.getMSUser();
			msAuth.msUser = msUser;
		}
		if (showInConsole) {
			console.log("MSAuthAPI initMSUser", msAuth.msUser);
		}

		return msAuth;
	}

	async getMSAuthToken(forceRefresh = false) {
		try {
			let account = this.msInstance.getAccount();
			if (Boolean(account)) {
				var silentRequest = {
					scopes: scopes,
					account: account,
					forceRefresh: forceRefresh,
					refreshTokenExpirationOffsetSeconds: 7200, // 7200, // 2 hours * 60 minutes * 60 seconds = 7200 seconds (minimum of 1 hour)
					// cacheLookupPolicy: cacheLookupPolicy.Default // will default to CacheLookupPolicy.Default if omitted
				};

				const msAuthToken = this.msInstance.acquireTokenSilent(silentRequest).catch(async (err) => {
					let request = {
						scopes: scopes,
						loginHint: account?.userName ?? null,
					};

					if (err instanceof InteractionRequiredAuthError) {
						// fallback to interaction when silent call fails
						console.log("MSAuthAPI getMSAuthToken - err InteractionRequiredAuthError", err);

						return this.msInstance.acquireTokenRedirect(request);
					} else {
						if (!forceRefresh) {
							console.log("MSAuthAPI getMSAuthToken - acquireTokenSilent err - trying forceRefresh", err);
							return await this.getMSAuthToken(true);
						} else {
							console.log(
								"MSAuthAPI getMSAuthToken - acquireTokenSilent err w/forceRefresh - logging out",
								err
							);
							this.logoutMSUser();
						}
					}
				});

				return msAuthToken;
			}
		} catch (err) {
			console.log("MSAuthAPI getMSAuthToken - catch unknown err", err);
			return null;
		}
	}

	async getMSUser(msGraphURL = "https://graph.microsoft.com/v1.0/") {
		var requestURL = msGraphURL + "me";
		try {
			let msUser = await this.msClient
				.api(requestURL)
				.get()
				.then((response) => {
					return response;
				})
				.catch((err) => {
					console.log("MSAuthAPI GetMSUser - Error:", err);
					return err;
				});
			return msUser;
		} catch (err) {
			console.log("MSAuthAPI GetMSUser - Error:", err);
			return err;
		}
	}

	validateMSAuth(showInConsole = false) {
		let isAuthorized = false;
		let msUserID = null;
		let timeRemaining = 0;

		if (Boolean(this.msAuthToken)) {
			msUserID = this.msAuthToken?.account?.accountIdentifier ?? null;
			timeRemaining = this.getMSAuthTokenExpires(showInConsole) ?? 0;
		}

		if (Boolean(msUserID) && msUserID === this.msUser?.id && timeRemaining > 0) {
			isAuthorized = true;
		}

		if (showInConsole) {
			if (isAuthorized) {
				console.log("initMSAuth validateMSAuth - MSAuth is valid, Rendering App", this);
			} else {
				console.log("initMSAuth validateMSAuth - MSAuth is invalid, Rendering Login", this);
			}
		}

		return isAuthorized;
	}

	getMSAuthTokenExpires(
		showInConsole = false,
		returnValue = "timeRemaining",
		dateFormat = "MM/DD/YYYY hh:mm:ss",
		timeFormat = "minutes"
	) {
		let token = this.msAuthToken;
		let currentTime = moment(new Date());
		let tokenType = null;
		let tokenExpires = null;
		let timeRemaining = 0;

		if (token?.tokenType === "access_token") {
			tokenType = "Access Token";
		} else if (token?.tokenType === "refresh_token") {
			tokenType = "Refresh Token";
		} else {
			tokenType = token?.tokenType ?? "Token";
		}

		if (Boolean(token?.expiresOn)) {
			tokenExpires = moment(new Date(token?.expiresOn));
			timeRemaining = tokenExpires.diff(currentTime, timeFormat);
		}

		if (showInConsole) {
			if (Boolean(tokenType) && Boolean(tokenExpires)) {
				console.log(
					"MSAuthAPI getMSAuthTokenExpires - " +
						tokenType +
						" expires On: " +
						tokenExpires.format(dateFormat) +
						" (" +
						timeRemaining +
						" " +
						timeFormat +
						" remaining)"
				);
			} else {
				console.log("MSAuthAPI getMSAuthTokenExpires - Error: " + tokenType + " is missing or has expired");
			}
		}

		if (returnValue === "tokenExpires") {
			return tokenExpires;
		} else {
			return timeRemaining;
		}
	}

	loginMSUser(scopes = this.scopes, loginHint = this.account.username) {
		let loginResponse = this.msInstance
			.loginRedirect({ scopes, loginHint })
			.then((response) => {
				//TODO: check if tokenType is access or refresh - if refresh, get a new access token
				console.log("MSAuthAPI loginMSUser response:", response);
				return response;
			})
			.catch((err) => {
				console.log("MSAuthAPI loginMSUser err:", err);
				this.logoutMSUser();
			});
		return loginResponse;
	}

	logoutMSUser() {
		console.log("MSAuthAPI logoutMSUser - Logging MSUser Out", this.msInstance);
		this.msInstance.logout();
	}
}
