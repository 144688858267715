import React, { useState } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import Modal from "../../../core/Layout/modals/Modal";
import DataInput from "../../../core/Inputs/data/DataInput";

export default function PayrollUploadBatchModal(props) {
	const [paySeq, setPaySeq] = useState(1);

	const sendBatch = () => {
		let batchInfo = {
			prEndDate: props.payPeriod,
			prco: props.prco,
			prGroup: props.prGroup,
			paySeq: paySeq,
		};
		props.addBatch(batchInfo);
	};

	const paySequenceOptions = [
		{ value: 1, text: "1 - Payroll" },
		{ value: 2, text: "2 - Misc Time" },
		{ value: 5, text: "5 - Missed Time" },
		{ value: 6, text: "6 - Layoff" },
	];

	let approvedNum = 0;

	for (let i = 0; i < props.members?.length; i++) {
		if (
			props.selectedMembers.includes(props.members[i]?.timecard?.timecardHeader?.keyId) &&
			props.members[i].timecard?.timecardHeader.isApproved()
		) {
			approvedNum++;
		}
	}
	return (
		<Modal isOpen={props.isOpen} onClose={props.onClose} size="xl" title="Create & Send Batch" onSubmit={sendBatch}>
			<Box>
				<Flex wrap="wrap">
					<Box p={2} flex={1}>
						<DataInput name="prEndDate" value={props.payPeriod} label="PR End Date" />
					</Box>
					<Box p={2} flex={1}>
						<DataInput name="prcompany" value={props.prco} label="PR Company" type="number" />
					</Box>
					<Box p={2} flex={1}>
						<DataInput name="prGroup" value={props.prGroup} label="PR Group" type="number" />
					</Box>
				</Flex>
				<Flex wrap="wrap">
					<Box p={2}>
						<DataInput
							name="paySequence"
							value={paySeq}
							label="Pay Sequence"
							type="select"
							options={paySequenceOptions}
							onChange={(ev) => {
								setPaySeq(ev.target.value);
							}}
						/>
					</Box>
					<Box p={2}>
						<Text color="brand.gray">
							<span>{approvedNum}&nbsp;Approved</span>
							<br />
							<span>{props.selectedMembers.length - approvedNum}&nbsp;Remaining</span>
						</Text>
					</Box>
				</Flex>
			</Box>
		</Modal>
	);
}
