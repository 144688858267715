import { v4 } from "uuid";
import DevOpsAPI from "../../../APIs/DevOpsAPI";

export default class EmbedLink {
	constructor(link) {
		if (link instanceof EmbedLink) {
			Object.assign(this, link);
		} else {
			this.devopsAPI = new DevOpsAPI();
			this.id = link?.id ?? link?.KeyID ?? null;
			this.reportUID = link?.reportUID ?? link?.ReportUID ?? v4();
			this.url = link?.url ?? link?.URL ?? "";
			this.fileName = link?.fileName ?? link?.FileName ?? "New PowerBI Report";
			this.dateCreated = link?.dateCreated ?? link?.DateCreated ?? new Date();
			this.title = link?.title ?? link?.Title ?? null;
			this.subtitle = link?.subtitle ?? link?.Subtitle ?? null;
			this.isActive = link?.isActive ?? link?.isActive ?? false;
			this.tab = link?.tab ?? link?.Tab ?? null;
			this.isArchived = link?.isArchived ?? false;
		}
	}

	async upload() {
		let insertResult = await this.devopsAPI.InsertPowerBILink(this.reportUID);
		return insertResult;
	}

	async updateDB(updates) {
		let result = await this.devopsAPI.UpdatePowerBILink(this.reportUID, updates);
		if (result.status === 200) {
			return true;
		} else {
			return false;
		}
	}

	async updateTitle(value) {
		this.title = value.replace("'", "`");
		let updates = {
			Title: this.title,
		};
		await this.updateDB(updates);
	}

	async updateFileName(value) {
		this.fileName = value.replace("'", "`");
		let updates = {
			FileName: this.fileName,
		};
		await this.updateDB(updates);
	}

	async updateTab(value) {
		this.tab = value.replace("'", "`");
		let updates = {
			Tab: this.tab,
		};
		await this.updateDB(updates);
	}

	async updateURL(value) {
		this.url = value.replace("'", "`");
		let updates = {
			URL: this.url,
		};
		await this.updateDB(updates);
	}

	async updateIsActive(value) {
		this.isActive = value;
		let updates = {
			isActive: this.isActive,
		};
		await this.updateDB(updates);
	}
}
