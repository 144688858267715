import React, { useState, useEffect } from "react";
import { Stack } from "@chakra-ui/react";
import ProgressBar from "./ProgressBar";
import PieChart from "./PieChart";
import NUMERATOR from "../Constants/Numerator";
import DENOMINATOR from "../Constants/Denominator";
import { getSubtotal } from "../../../../../helperFunctions";

export default function SpentToDatePage({ diversityVendors, isLoading, contract, diversityReqs }) {
	const [numerator, setNumerator] = useState(NUMERATOR[0].value);
	const [denominator, setDenominator] = useState(DENOMINATOR[0].value);
	const [cost, setCost] = useState(contract?.actualCost + contract?.projCost);
	const [amount, setAmount] = useState(contract?.bidContractAmt);
	const [contractTotals, setContractTotals] = useState({});

	const groupDiversityRequirements = Object.values(
		diversityReqs.reduce((acc, item) => {
			// Create composite key using all three criteria to determine grouping
			const key = `${item.contract}_${item.divReqKeyID}_${item.divReqPct}`;

			if (!acc[key]) {
				// Create a new grouped item
				acc[key] = {
					...item,
					divCertType: [item.divCertType],
					divCertDesc: [item.divCertDesc],
				};
			} else {
				// If key exists, merge the divCertType and divCertDesc
				if (!acc[key].divCertType.includes(item.divCertType)) {
					acc[key].divCertType.push(item.divCertType);
				}
				if (!acc[key].divCertDesc.includes(item.divCertDesc)) {
					acc[key].divCertDesc.push(item.divCertDesc);
				}
			}
			return acc;
		}, {})
	);

	useEffect(() => {
		setContractTotals({
			actualCost: getSubtotal(diversityVendors, "actualCost", "sum", true),
			actualAndCommitedCost:
				getSubtotal(diversityVendors, "actualCost", "sum", true) +
				getSubtotal(diversityVendors, "committedCost", "sum", true),
			currContractAmt: contract?.contractAmt,
			projContractAmt: contract?.projContractAmt,
			bidContractAmt: contract?.bidContractAmt,
		});

		if (numerator === "actualAndCommitedCost") {
			setCost(contractTotals?.actualAndCommitedCost);
		} else if (numerator === "actualCost") {
			setCost(contractTotals?.actualCost);
		}

		if (denominator === "currContractAmt") {
			setAmount(contractTotals?.currContractAmt);
		} else if (denominator === "projContractAmt") {
			setAmount(contractTotals?.projContractAmt);
		} else if (denominator === "bidContractAmt") {
			setAmount(contractTotals?.bidContractAmt);
		}
	}, [
		contract,
		contractTotals?.actualAndCommitedCost,
		contractTotals?.actualCost,
		contractTotals?.bidContractAmt,
		contractTotals?.currContractAmt,
		contractTotals?.projContractAmt,
		denominator,
		diversityVendors,
		numerator,
	]);

	return (
		<Stack w="full" px={10} spacing={1}>
			<PieChart
				data={groupDiversityRequirements}
				contract={contract}
				diversityVendors={diversityVendors}
				diversityReqs={diversityReqs}
				numerator={numerator}
				setNumerator={setNumerator}
				denominator={denominator}
				setDenominator={setDenominator}
				cost={cost}
				amount={amount}
			/>
			<Stack w="full" px={5} spacing={6}>
				{Object.values(groupDiversityRequirements).map((group, index) => (
					<ProgressBar
						key={index}
						group={group}
						contract={contract}
						diversityVendors={diversityVendors}
						isLoading={isLoading}
						diversityReqs={diversityReqs}
						amount={amount}
						contractTotals={contractTotals}
						numerator={numerator}
					/>
				))}
			</Stack>
		</Stack>
	);
}

/*

	const test = [
		{
			queryData: "DiversityReq",
			jccmKeyID: "4096",
			jcco: 1,
			contract: " 3971.",
			divReqKeyID: "62",
			divReqPct: 0.03,
			divCertType: ["DVBE"],
			divCertSubType: null,
			divCertDesc: ["Disabled Veterans Business Enterprise"],
		},
		{
			queryData: "DiversityReq",
			jccmKeyID: "4096",
			jcco: 1,
			contract: " 3971.",
			divReqKeyID: "63",
			divReqPct: 0.07,
			divCertType: ["LBE"],
			divCertSubType: null,
			divCertDesc: ["Local Business Enterprise\t\t\t\t"],
		},
		{
			queryData: "DiversityReq",
			jccmKeyID: "4096",
			jcco: 1,
			contract: " 3971.",
			divReqKeyID: "65",
			divReqPct: 0.18,
			divCertType: ["SBE", "LSBE"],
			divCertSubType: null,
			divCertDesc: ["Small Business Enterprise", "Local Small Business Enterprise"],
		},
	];
*/
