import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import { App } from "./App";
import LoginPage from "./modules/App/pages/LoginPage";

import reportWebVitals from "./reportWebVitals";
import MSAuthAPI from "./APIs/MSAuthAPI";

export let msAuth = new MSAuthAPI();
init();

async function init() {
	if (!Boolean(msAuth.msInstance)) {
		msAuth = msAuth.init();
		await init();
	} else {
		msAuth = await msAuth.initMSAuth();
		let isAuthorized = msAuth.validateMSAuth(true);

		ReactDOM.render(
			<React.StrictMode>
				{isAuthorized ? <App msAuth={msAuth} /> : <LoginPage msAuth={msAuth} />}
			</React.StrictMode>,
			document.getElementById("root")
		);
	}
}

reportWebVitals();
