import React, { useRef } from "react";
import {
	Box,
	Flex,
	Text,
	Stack,
	HStack,
	Heading,
	// useOutsideClick
} from "@chakra-ui/react";
import { formatValue } from "../../../helperFunctions";

export default function VirtualTableRenderSubRow({
	row,
	rowHeight,
	maxRowHeight,
	allColumns,
	maxWidth,
	subRow,
	// toggleAllRowsExpanded,
	// toggleRowExpanded,
	// resetRowHeights,
}) {
	const ref = useRef();

	// const resetHeights = (ev) => {
	// 	ev.stopPropagation();
	// 	toggleAllRowsExpanded(false);
	// 	toggleRowExpanded(false);
	// 	resetRowHeights();
	// };

	// useOutsideClick({
	// 	ref: ref,
	// 	handler: (ev) => resetHeights(ev),
	// });

	return (
		<Box
			ref={ref}
			position="relative"
			maxW={maxWidth}
			maxH={maxRowHeight - rowHeight}
			h={maxRowHeight - rowHeight}
			bg="gray.200"
			zIndex={2}
			overflowX={subRow?.overflowX ?? "hidden"}
			overflowY={subRow?.overflowY ?? "hidden"}
			py={3}
			px={4}
			borderTopWidth="2px"
			borderTopColor="gray.500"
			borderBottomWidth="2px"
			borderBottomColor="gray.500"
			role="group"
		>
			<Flex
				fontFamily="body"
				bg="whiteAlpha.800"
				rounded="5"
				py={2}
				px={2}
				direction="column"
				shadow="md"
				h={maxRowHeight - rowHeight * 2}
				borderWidth="1px"
				borderColor="blackAlpha.300"
				_groupHover={{
					bg: "white",
					shadow: "lg",
				}}
			>
				<Text>{subRow}</Text>
				{Boolean(subRow?.columns?.length > 0) && (
					<Stack w="full" maxW={maxWidth}>
						{subRow?.columns?.map((subRowColumn, i) => {
							let column = allColumns.find((d) => d.id === subRowColumn.id);

							return (
								<React.Fragment key={i}>
									<HStack
										w="full"
										borderBottomWidth={2}
										borderBottomColor="blackAlpha.200"
										align="center"
										spacing={0}
									>
										{Boolean(subRow?.icon) && (
											<Heading
												color="gray.500"
												textTransform="uppercase"
												size="xs"
												letterSpacing={2}
												lineHeight="1"
												px={2}
											>
												<i className={subRow?.icon} />
											</Heading>
										)}
										<Heading
											color="gray.500"
											textTransform="uppercase"
											size="sm"
											letterSpacing={2}
											lineHeight="1"
											py={2}
										>
											{column.Header}
										</Heading>
									</HStack>
									<Text px={2} whiteSpace="wrap" h="full">
										{formatValue(row.values[subRowColumn.id], column?.decimals, column?.valueType)}
									</Text>
								</React.Fragment>
							);
						})}
					</Stack>
				)}
			</Flex>
		</Box>
	);
}
