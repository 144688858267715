import React, { useMemo } from "react";
import { Box, Stack, HStack, Divider, Text, useBoolean } from "@chakra-ui/react";
import DataInput from "../../Inputs/data/DataInput";

export default function VirtualListV3GroupSortFilterPanel(props) {
	return (
		<Box p={4} rounded={"md"} borderWidth={2} borderColor="blackAlpha.200" bg="blackAlpha.50">
			<Stack spacing={3}>
				<GroupByList
					headerGroups={props.headerGroups}
					toggleGroupBy={props.toggleGroupBy}
					groupBy={props.state.groupBy}
				/>
				<SortByList headerGroups={props.headerGroups} toggleSortBy={props.toggleSortBy} state={props.state} />
				<FilterOptions headerGroups={props.headerGroups} />
			</Stack>
		</Box>
	);
}

function GroupByList(props) {
	const groupByOptions = useMemo(() => {
		let options = [];
		for (let i = 0; i < props.headerGroups?.length; i++) {
			for (let j = 0; j < props.headerGroups[i]?.headers?.length; j++) {
				if (props.headerGroups[i]?.headers[j]?.canGroupBy) {
					options.push({
						text: props.headerGroups[i]?.headers[j]?.Header,
						value: props.headerGroups[i]?.headers[j]?.id,
					});
				}
			}
		}
		return options;
	}, [props.headerGroups]);

	const setGroupBy = (ev) => {
		props.toggleGroupBy(Boolean(ev.target.value) ? ev.target.value : props.state.groupBy[0]);
	};

	return (
		<>
			<DataInput
				name="groupBy"
				label="Group By"
				size="sm"
				type="select"
				labelProps={{ fontSize: "md", letterSpacing: "1" }}
				onChange={setGroupBy}
			>
				<>
					<option value={null}>Select...</option>
					{groupByOptions.map((option, i) => (
						<option key={i} value={option.value}>
							{option.text}
						</option>
					))}
				</>
			</DataInput>
			{/* <DataInput
				name="groupBy"
				label="Group By"
				type="select"
				labelProps={{ fontSize: "md", letterSpacing: "1" }}
				searchable
				placeholder="Select..."
				options={groupByOptions}
				optionValue="value"
				optionText="text"
				onOptionSelect={setGroupBy}
			/> */}
		</>
	);
}

function SortByList(props) {
	const sortByOptions = useMemo(() => {
		let options = [];
		for (let i = 0; i < props.headerGroups.length; i++) {
			for (let j = 0; j < props.headerGroups[i].headers.length; j++) {
				if (props.headerGroups[i].headers[j].canSort) {
					options.push({
						text: props.headerGroups[i].headers[j].Header + " (A-Z)",
						value: props.headerGroups[i].headers[j].id,
						desc: false,
					});
					options.push({
						text: props.headerGroups[i].headers[j].Header + " (Z-A)",
						value: props.headerGroups[i].headers[j].id,
						desc: true,
					});
				}
			}
		}
		return options;
	}, [props.headerGroups]);

	const setSortBy = (ev) => {
		let val = ev.target.value;
		let optionSelected = sortByOptions.find(({ text }) => text === val);
		props.toggleSortBy(optionSelected?.value, optionSelected?.desc);
	};

	return (
		<DataInput
			name="sortBy"
			label="Sort By"
			size="sm"
			type="select"
			labelProps={{ fontSize: "md", letterSpacing: "1" }}
			onChange={setSortBy}
		>
			<>
				<option value={""}>Select...</option>
				{sortByOptions?.map((option, i) => (
					<option key={i} value={option.text}>
						{option.text}
					</option>
				))}
			</>
		</DataInput>
	);
}

function FilterOptions({ headerGroups = [] }) {
	return (
		<>
			{Boolean(headerGroups?.length > 0) && (
				<Stack w="full" spacing={1}>
					<Text fontSize="md" fontWeight="bold" textTransform="uppercase" color="gray.500">
						Filter By
					</Text>
					<Divider borderWidth="1px" borderColor="blackAlpha.400" />
					<Stack spacing={4} py={2}>
						{headerGroups?.map((headerGroup) =>
							headerGroup?.headers?.map(
								(column, j) => column.canFilter && <FilterOptionList key={j} column={column} />
							)
						)}
					</Stack>
				</Stack>
			)}
		</>
	);
}

function FilterOptionList({ column }) {
	const [isOpen, setOpen] = useBoolean(true);
	var icon = isOpen ? "fas fa-minus" : " fas fa-plus";
	return (
		<>
			{Boolean(column?.canFilter) && (
				<Stack spacing={2}>
					<HStack
						key={icon}
						align="center"
						color="gray.500"
						fontSize="sm"
						fontWeight="600"
						textTransform="uppercase"
						onClick={setOpen.toggle}
						cursor="pointer"
						userSelect={"none"}
					>
						<Text>
							<i className={icon + " fa-fw"} />
						</Text>
						<Text>{column.render("Header")}</Text>
					</HStack>
					{isOpen && <Box>{column.render("Filter")}</Box>}
				</Stack>
			)}
		</>
	);
}
