import React, { useState, useRef } from "react";
import { Stack, Text, useBoolean, InputGroup, InputLeftAddon, Input, HStack, InputRightAddon } from "@chakra-ui/react";
import { formatValue } from "../../../helperFunctions";
import { useAsyncDebounce } from "react-table";

// A Global Search Bar Filter Input
// for Tables and Lists
export default function GlobalSearchFilter({
	globalFilter,
	setGlobalFilter,
	totalRowCount = 0,
	rowCount = 0,
	listRef = null,
}) {
	const [value, setValue] = useState(globalFilter);
	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 200);
	const globalFilterRef = useRef(null);
	const [isFocused, setFocus] = useBoolean();

	const inputGroupHoverStyle = {
		zIndex: "unset",
		borderColor: "var(--chakra-colors-teal-500)",
		borderWidth: 2,
	};
	const inputGroupStyle = {
		zIndex: "unset",
		borderColor: "var(--chakra-colors-teal-600)",
	};
	return (
		<HStack spacing={2} flex={1} lineHeight={10}>
			<InputGroup
				shadow="md"
				maxH={10}
				h={10}
				borderRadius="md"
				flex={1}
				style={isFocused ? inputGroupStyle : {}}
				_hover={inputGroupHoverStyle}
				borderWidth={1}
				borderColor="gray.300"
				role="group"
			>
				<InputLeftAddon
					borderColor="blackAlpha.200"
					_groupHover={{ color: "teal.500", borderWidth: 0 }}
					fontWeight="bold"
					color={isFocused ? "teal.500" : "gray.500"}
					borderWidth={0}
					px={2}
					maxH={"full"}
					children={
						<Text>
							<i className="fas fa-search fa-lg  fa-fw"></i>
						</Text>
					}
				/>

				<Input
					ref={globalFilterRef}
					size="md"
					variant="outline"
					colorScheme="teal"
					borderTopWidth={0}
					borderBottomWidth={0}
					borderColor="gray.300"
					borderLeftWidth={1}
					borderRightWidth={1}
					value={value || ""}
					maxH="full"
					onFocus={setFocus.on}
					onBlur={setFocus.off}
					// _groupHover={{ borderTopWidth: "0px", borderBottomWidth: "0px" }}
					// borderTopWidth={isFocused ? "0px" : "1px"}
					// borderBottomWidth={isFocused ? "0px" : "1px"}
					bg="whiteAlpha.800"
					onChange={(e) => {
						setValue(e.target.value);
						onChange(e.target.value);
						if (listRef !== null) {
							listRef?.current.resetAfterIndex(0, false);
						}
					}}
					placeholder={`Search...`}
				/>

				<InputRightAddon
					borderColor="blackAlpha.200"
					borderWidth={0}
					maxH={"full"}
					_groupHover={{ color: "teal.500", borderColor: "gray.500" }}
					px={2}
					color={isFocused ? "teal.500" : "gray.500"}
					children={
						<Stack spacing={1} justify="center" align="center" fontWeight="semibold">
							<HStack spacing={1} lineHeight={1}>
								<Text fontSize="sm">{formatValue(rowCount, 0, "number")}</Text>
								<Text fontSize={"var(--chakra-sizes-3)"}>
									{Boolean(totalRowCount) ? "of" : rowCount === 1 ? "Row" : "Rows"}
								</Text>
							</HStack>
							{Boolean(totalRowCount) && (
								<HStack spacing={1} lineHeight={1} fontSize={"var(--chakra-sizes-3)"}>
									<Text>{formatValue(totalRowCount, 0, "number")}</Text>
									<Text>{rowCount === 1 ? "Row" : "Rows"}</Text>
								</HStack>
							)}
						</Stack>
					}
				/>
			</InputGroup>
		</HStack>
	);
}
