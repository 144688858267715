import React, { useState, useMemo, useCallback } from "react";
import { Flex, Text, Tooltip } from "@chakra-ui/react";
import { convertArrayToMap } from "../../../helperFunctions";

export default function TableHeaderCell({ column }) {
	let sortMap = useMemo(
		() =>
			// TO DO: Fix Sort Order for Icons
			convertArrayToMap(
				[
					{
						sortKey: "notSorted",
						sortIcon: "fas fa-sort",
						sortColor: "var(--chakra-colors-whiteAlpha-400)",
						hoverSortColor: "var(--chakra-colors-whiteAlpha-800)",
						hoverSortBg: "var(--chakra-colors-whiteAlpha-200)",
					},
					{
						sortKey: "sortedAsc",
						sortIcon: "fas fa-sort-" + column.sortIcon + "-down",
						sortColor: "white",
						hoverSortColor: "white",
						hoverSortBg: "var(--chakra-colors-whiteAlpha-200)",
					},
					{
						sortKey: "sortedDesc",
						sortIcon: "fas fa-sort-" + column.sortIcon + "-down-alt",
						sortColor: "white",
						hoverSortColor: "white",
						hoverSortBg: "var(--chakra-colors-whiteAlpha-200)",
					},
				],
				"sortKey"
			),
		[column.sortIcon]
	);

	const getSortKey = useCallback((column) => {
		let sortKey = "notSorted";
		if (!Boolean(column?.isSorted) && !Boolean(column?.isSortedDesc)) {
			sortKey = "notSorted";
		} else if (Boolean(column?.isSorted) && Boolean(column?.isSortedDesc)) {
			sortKey = "sortedAsc";
		} else if (Boolean(column?.isSorted) && !Boolean(column?.isSortedDesc)) {
			sortKey = "sortedDesc";
		}
		return sortKey;
	}, []);

	const [sortKey, setSortKey] = useState(getSortKey(column));

	return (
		<Flex
			{...column?.getSortByToggleProps()}
			onClick={() => {
				column?.toggleSortBy();
				setSortKey(getSortKey(column));
			}}
			spacing={0}
			flex={1}
			w="full"
			align="center"
			isTruncated
			whiteSpace={"wrap"}
			textTransform="uppercase"
			overflow="visible"
			position="relative"
			py={1}
		>
			<Tooltip label={column?.tooltip} placement="top">
				<Text
					fontSize="xs"
					textAlign={column?.textAlign ?? "center"}
					letterSpacing={2}
					w="full"
					fontWeight="bold"
					overflowWrap={"break-word"}
				>
					{column?.render("Header")}
				</Text>
			</Tooltip>
			<Text
				key={sortKey}
				rounded={"md"}
				fontSize="xs"
				color={sortMap[sortKey]?.sortColor}
				_hover={{
					color: sortMap[sortKey]?.hoverSortColor,
					bg: sortMap[sortKey]?.hoverSortBg,
				}}
				position="absolute"
				bottom={1}
				right={0}
			>
				<i className={sortMap[sortKey]?.sortIcon + " fa-lg"}></i>
			</Text>
		</Flex>
	);
}
