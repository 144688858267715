import React from "react";
import ToggleButton from "./ToggleButton";

export default function CollapseButton({ collapseIsOpen, setCollapseIsOpen }) {
	return (
		<>
			{Boolean(setCollapseIsOpen) && (
				<ToggleButton toggleIsOpen={collapseIsOpen} onClick={setCollapseIsOpen.toggle} />
			)}
		</>
	);
}
