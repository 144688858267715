import React from "react";
import {
	Button,
	Stack,
	Heading,
	Flex,
	Spacer,
	Drawer,
	DrawerHeader,
	DrawerBody,
	DrawerFooter,
	DrawerContent,
	DrawerCloseButton,
} from "@chakra-ui/react";

export default function BasicDrawer({
	isOpen,
	onClose,
	size,
	placement,
	title,
	subtitle,
	onSubmit,
	isSubmitting,
	children,
}) {
	return (
		<Drawer isOpen={isOpen} onClose={onClose} size={size ?? "md"} placement={placement ?? "right"}>
			<DrawerContent>
				<DrawerHeader>
					<DrawerCloseButton />
					<Stack>
						{Boolean(title) && (
							<Heading as="h5" color="gray.400" textTransform="uppercase" letterSpacing={2}>
								{title}
							</Heading>
						)}
						{Boolean(subtitle) && (
							<Heading as="h6" size="sm" color="gray.500">
								{subtitle}
							</Heading>
						)}
					</Stack>
				</DrawerHeader>
				<DrawerBody>{children}</DrawerBody>
				{Boolean(onSubmit) && (
					<DrawerFooter>
						<Flex>
							<Spacer />
							<Stack direction="row">
								<Button
									variant="outline"
									size="md"
									textTransform="uppercase"
									letterSpacing={1}
									colorScheme="red"
									onClick={onClose}
								>
									CANCEL
								</Button>
								<Button
									size="md"
									colorScheme="blue"
									textTransform="uppercase"
									letterSpacing={1}
									onClick={onSubmit}
									isLoading={isSubmitting}
								>
									Save
								</Button>
							</Stack>
						</Flex>
					</DrawerFooter>
				)}
			</DrawerContent>
		</Drawer>
	);
}
