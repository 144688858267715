import { ajaxGET, ajaxPOST, ajaxPATCH, ajaxDELETE, testGET, testPOST, setQueryParams } from "./api-functions";
import { portalUser } from "../App";
import { formatValue } from "../helperFunctions";

const apiBaseURL =
	(process.env.NODE_ENV === "development"
		? "https://ppmportalappnodejs-api.azurewebsites.net"
		: window.location.origin) + "/api/payroll";

export default class PayrollAPI {
	async TestGET(headers = {}) {
		let response = await testGET(apiBaseURL, headers);
		return response;
	}

	async TestPOST(body = {}, headers = {}) {
		let response = await testPOST(apiBaseURL, body, headers);
		return response;
	}

	//----TIMECARD NEW----//

	//---Payroll Data-----
	async GetPayrollDataNew(params = {}, query = { msUserID: portalUser.user?.msUserID ?? null }) {
		var apiRouteURL = `${apiBaseURL}/payrollData`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET Pay Periods
	async GetPayPeriodsNew(params = {}, query = { msUserID: portalUser.user?.msUserID ?? null }) {
		var apiRouteURL = `${apiBaseURL}/payperiods`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET Timecard Headers
	async GetTimecardHeadersNew(params = {}, query = { msUserID: portalUser.user?.msUserID ?? null }) {
		var apiRouteURL = `${apiBaseURL}/timecardHeaders`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET Timecard Rows
	async GetTimecardRowsNew(params = {}, query = { msUserID: portalUser.user?.msUserID ?? null }) {
		var apiRouteURL = `${apiBaseURL}/timecardRows`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET Timecard Jobs
	async GetTimecardJobsNew(params = {}, query = { msUserID: portalUser.user?.msUserID }) {
		var apiRouteURL = `${apiBaseURL}/timecardJobs`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//----Batch API Calls----- SHOULD THIS BE A POST???
	async GetTimecardData(groupUID, data) {
		let apiRouteURL = `${apiBaseURL}/resourceGroupTimecardAll/${groupUID}`;
		let response = await ajaxPOST(apiRouteURL, data);
		return response;
	}

	//----Pay Periods-----
	async GetPayPeriods(employeeUID = portalUser.user?.employeeUID, status) {
		var apiRouteURL = `${apiBaseURL}/timecardPayPeriods/${employeeUID}`;
		apiRouteURL += "?status=" + status;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//----Timecard Jobs & Phases -----

	//TO DO - Tie these Timecard Jobs to the UserData_UserJobs
	async GetTimecardJobs(employeeUID = portalUser.user?.employeeUID, prco = portalUser.user?.prco) {
		var apiRouteURL = `${apiBaseURL}/timecardJobSecurity/${employeeUID}/co/${prco}`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	async GetTimecardJobPhases(job, prco) {
		var apiRouteURL = `${apiBaseURL}/timecardJobPhases/${job}/co/${prco}`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	async GetTimecardReviewJobs(employeeUID = portalUser.user?.employeeUID, prEndDate = portalUser.user?.prEndDate) {
		prEndDate = formatValue(prEndDate, 0, "dateURL");
		var apiRouteURL = `${apiBaseURL}/timecardReviewJobs/${employeeUID}`;
		apiRouteURL += "?prEndDate=" + prEndDate;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	async GetTimecardReviewJobPhases(keyID, prEndDate = portalUser.user?.prEndDate) {
		var apiRouteURL = `${apiBaseURL}/timecardReviewJobPhases/${keyID}`;
		apiRouteURL += "?prEndDate=" + formatValue(prEndDate, 0, "dateURL");
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//----Timecard Headers -----
	async GetTimecardHeaders(employeeUID, prco, prEndDate = portalUser.user?.prEndDate) {
		var apiRouteURL = `${apiBaseURL}/timecardHeader/${employeeUID}/prco/${prco}`;
		apiRouteURL += "?PREndDate=" + formatValue(prEndDate, 0, "dateURL");
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	async UpdateTimecardHeader(keyID, data) {
		var apiRouteURL = `${apiBaseURL}/timecardHeader/${keyID}`;
		let response = await ajaxPATCH(apiRouteURL, data);
		return response;
	}

	async UpdateTimecardHeaders(keyIDs, updates) {
		var apiRouteURL = `${apiBaseURL}/timecardHeaderMultiple`;
		var data = {
			keyIds: keyIDs,
			update: updates,
		};
		let response = await ajaxPATCH(apiRouteURL, data);
		return response;
	}

	//----Timecard Rows-----
	async GetTimecardRows(employeeUID = portalUser.user?.employeeUID, prco, prEndDate = portalUser.user?.prEndDate) {
		prEndDate = formatValue(prEndDate, 0, "dateURL");
		var apiRouteURL = `${apiBaseURL}/payweekHours/${employeeUID}/co/${prco}`;
		apiRouteURL += "?PREndDate=" + prEndDate;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	async InsertTimecardRow(data) {
		let employeeUID = data?.employeeUID;
		var apiRouteURL = `${apiBaseURL}/payweekHours/${employeeUID}`;
		data.prEndDate = formatValue(data?.prEndDate, 0, "dateURL");
		let response = await ajaxPOST(apiRouteURL, data);
		return response;
	}

	async UpdateTimecardRow(keyID, data) {
		var apiRouteURL = `${apiBaseURL}/payweekHours/${keyID}`;
		let response = await ajaxPATCH(apiRouteURL, data);
		return response;
	}

	async DeleteTimecardRow(keyID) {
		var apiRouteURL = `${apiBaseURL}/payweekHours/${keyID}`;
		let response = await ajaxDELETE(apiRouteURL);
		return response;
	}

	async CloneTimecardRows(employeeUID = portalUser.user?.employeeUID, prEndDate = portalUser.user?.prEndDate, prco) {
		var apiRouteURL = `${apiBaseURL}/timecard/${employeeUID}/clone`;
		let data = {
			prEndDate: prEndDate,
			co: prco,
		};
		let response = await ajaxPOST(apiRouteURL, data);
		return response;
	}

	//---------- Paystubs-----------

	async GetPaystubs(employeeUID = portalUser.user?.employeeUID) {
		let apiRouteURL = `${apiBaseURL}/paystubList/${employeeUID}`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	async GetAttachmentBlobFile(attachmentUID) {
		let apiRouteURL = `${apiBaseURL}/paystub/${attachmentUID}`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	async SendgridEmail(data) {
		let apiRouteURL = `${apiBaseURL}/sendgrid`;
		let response = await ajaxPOST(apiRouteURL, data);
		return response;
	}

	//---------- Resource Groups & Members -----------

	async GetPayrollEmployees() {
		var apiRouteURL = `${apiBaseURL}/users`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	async GetAllResourceGroups(employeeUID = portalUser.user?.employeeUID) {
		var apiRouteURL = `${apiBaseURL}/payrollResourceGroups/${employeeUID}`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	async GetResourceGroups(employeeUID = portalUser.user?.employeeUID, groupType) {
		var apiRouteURL = `${apiBaseURL}/resourceGroups/${employeeUID}`;
		apiRouteURL += "?groupType=" + groupType;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	async GetResourceGroupMembers(employeeUID, groupUID, groupType, prEndDate) {
		prEndDate = formatValue(prEndDate, 0, "dateURL");
		var apiRouteURL = `${apiBaseURL}/resourceGroupMembers/${employeeUID}/group/${groupUID}`;
		apiRouteURL += "?groupType=" + groupType + "&prEndDate=" + prEndDate;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	async InsertResourceGroupMember(groupUID, employeeUID) {
		var apiRouteURL = `${apiBaseURL}/resourceGroupMember/${groupUID}/member/${employeeUID}`;
		let response = await ajaxPOST(apiRouteURL);
		return response;
	}

	async UpdateResourceGroupMember(groupUID, employeeUID, data) {
		var apiRouteURL = `${apiBaseURL}/resourceGroupMember/${groupUID}/member/${employeeUID}`;
		let response = await ajaxPATCH(apiRouteURL, data);
		return response;
	}

	async DeleteResourceGroupMember(groupUID, employeeUID) {
		var apiRouteURL = `${apiBaseURL}/resourceGroupMember/${groupUID}/member/${employeeUID}`;
		let response = await ajaxDELETE(apiRouteURL);
		return response;
	}

	//---------- Payroll Upload -----------

	async InsertPayrollTimecardBatch(selectedEmployees, batchInfo, employeeUID) {
		let apiRouteURL = `${apiBaseURL}/timecardBatchesSelectedEmployee`;
		let data = { selectedEmployees, batchInfo, employeeUID };
		let response = await ajaxPOST(apiRouteURL, data);

		apiRouteURL = `${apiBaseURL}/timecardBatchesAdd`;
		response = await ajaxPOST(apiRouteURL, data);
		return response;
	}

	async GetPayrollTimecardExceptions(prEndDate = portalUser.user?.prEndDate) {
		prEndDate = formatValue(prEndDate, 0, "dateURL");
		var apiRouteURL = `${apiBaseURL}/timecardExceptions/${prEndDate}`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//--------Merit Increases---------

	async GetDepartmentLeaders(employeeUID) {
		var apiRouteURL = `${apiBaseURL}/departmentLeaders/${employeeUID}`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	async GetEmployeeHistory(employeeUID) {
		var apiRouteURL = `${apiBaseURL}/employeeHistory/${employeeUID}`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	async GetEmployeeHistoryDetail(employeeUID) {
		var apiRouteURL = `${apiBaseURL}/employeeHistoryDetail/${employeeUID}`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	async UpdateEmployeeHistory(keyID, data) {
		var apiRouteURL = `${apiBaseURL}/employeeHistory/${keyID}`;
		let response = await ajaxPATCH(apiRouteURL, data);
		return response;
	}
}
