import React, { useState, useMemo } from "react";
import moment from "moment";
import SectionObj from "../../../core/Reports/classes/Section";
import SectionTemplateManpowerActuals from "../templates/SectionTemplateManpowerActuals";
import SectionTemplateBillingAndCostActuals from "../templates/SectionTemplateBillingAndCostActuals";
import SectionTemplateManpowerProjections from "../templates/SectionTemplateManpowerProjections";
import SectionTemplateBillingAndCostProjections from "../templates/SectionTemplateBillingAndCostProjections";
import Section from "../../../core/Reports/components/Section";

export default function ManpowerAndBillingProjections(props) {
	let pagebreak = props.pagebreak ?? 0;

	const [manpowerActuals, setManpowerActuals] = useState(new SectionObj(new SectionTemplateManpowerActuals()));
	const [manpowerProjections, setManpowerProjections] = useState(
		new SectionObj(new SectionTemplateManpowerProjections())
	);
	const [billingCostActuals, setBillingCostActuals] = useState(
		new SectionObj(new SectionTemplateBillingAndCostActuals())
	);
	const [billingCostProjections, setBillingCostProjections] = useState(
		new SectionObj(new SectionTemplateBillingAndCostProjections())
	);

	useMemo(() => {
		let contractTotalsByMth = props.contractData?.filter((d) => d.queryData === "ContractTotalsByMth") ?? [];
		contractTotalsByMth = contractTotalsByMth?.filter(
			(d) => new Date(d.mth).getTime() <= new Date(props.thruMth).getTime()
		);

		let contractStatus =
			contractTotalsByMth?.filter(
				(d) => moment(new Date(d.mth)).format("MM/YY") === moment(new Date(props.thruMth)).format("MM/YY")
			)[0] ?? null;

		let manpowerProjectionsByMth =
			props.contractData?.filter((p) => p.queryData === "ManpowerProjectionsByMth") ?? [];
		manpowerProjectionsByMth =
			manpowerProjectionsByMth?.filter((p) => new Date(p.mth).getTime() >= new Date(props.thruMth).getTime()) ??
			[];

		let manpowerActuals = new SectionTemplateManpowerActuals(contractTotalsByMth, contractStatus);
		setManpowerActuals(new SectionObj(manpowerActuals));

		setManpowerProjections(
			new SectionObj(new SectionTemplateManpowerProjections(manpowerProjectionsByMth, contractStatus))
		);

		let billingCostActuals = new SectionTemplateBillingAndCostActuals(contractTotalsByMth, contractStatus);
		setBillingCostActuals(new SectionObj(billingCostActuals));

		let billingCostProjections = new SectionTemplateBillingAndCostProjections(
			manpowerProjectionsByMth,
			contractStatus
		);
		setBillingCostProjections(new SectionObj(billingCostProjections));
	}, [props.contractData, props.thruMth]);

	return (
		<>
			{pagebreak === 0 && <Section section={manpowerActuals} />}
			{pagebreak === 0 && <Section section={manpowerProjections} selectedKeys={props.selectedKeys} />}
			{pagebreak === 1 && <Section section={billingCostActuals} />}
			{pagebreak === 1 && <Section section={billingCostProjections} selectedKeys={props.selectedKeys} />}
		</>
	);
}
