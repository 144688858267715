import { useState } from "react";
import { useDisclosure, Center, Heading, VStack, Button } from "@chakra-ui/react";
import { motion } from "framer-motion";

export default function CollapseHorizontalExample({ heading, subHeading }) {
	const { getButtonProps, getDisclosureProps, isOpen } = useDisclosure();
	const [hidden, setHidden] = useState(!isOpen);

	return (
		<Center w="full" minH="sm" h="full" bg="white" rounded={5} p={8}>
			<Center border="3px dashed" borderColor="gray.200" rounded="xl" h="full" w="full">
				<VStack spacing={8} flex={1} w="full" zIndex={2} pb={48}>
					<VStack spacing={4} flex={1} w="full">
						<Heading
							color={!hidden && isOpen ? "gray.400" : "gray.500"}
							textShadow={!hidden && isOpen ? "2px 2px 1px var(--chakra-colors-gray-600)" : ""}
							textTransform="uppercase"
							letterSpacing={6}
							size="xl"
						>
							{subHeading}
						</Heading>
						<Heading
							color={!hidden && isOpen ? "gray.300" : "gray.400"}
							textShadow={!hidden && isOpen ? "3px 3px 1px var(--chakra-colors-gray-600)" : ""}
							textTransform="uppercase"
							letterSpacing={6}
							size="3xl"
						>
							{heading}
						</Heading>
					</VStack>
					<Button
						{...getButtonProps()}
						textTransform="uppercase"
						fontWeight="bold"
						border="2px"
						borderColor={isOpen ? "gray.500" : "gray.400"}
						shadow="md"
						size="lg"
						color={isOpen ? "gray.600" : "gray.500"}
						bg={isOpen ? "gray.400" : "gray.200"}
						letterSpacing={2}
					>
						{isOpen ? "Close" : "Open"}
					</Button>
				</VStack>
				<motion.div
					{...getDisclosureProps()}
					hidden={hidden}
					initial={false}
					onAnimationStart={() => setHidden(false)}
					onAnimationComplete={() => setHidden(!isOpen)}
					animate={{ width: isOpen ? "100%" : 0 }}
					transition={{
						// duration: 1.5,
						type: "spring",
						bounce: 0.2,
						// stiffness: 50,
					}}
					style={{
						background: "var(--chakra-colors-green-600)",
						overflow: "hidden",
						whiteSpace: "nowrap",
						position: "absolute",
						right: "0",
						height: "100%",
						top: "0",
						borderRadius: "5px",
					}}
				></motion.div>
			</Center>
		</Center>
	);
}
