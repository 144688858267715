import CompanyData from "./CompanyData";
import UserData from "./UserData";
import PayrollData from "./PayrollData";
import ProjectData from "./ProjectData";

import MSAuthAPI from "../../../APIs/MSAuthAPI";
import MSGraphAPI from "../../../APIs/MSGraphAPI";

// import { log, convertArrayToMap } from "../helperFunctions";

export default class DataStore {
	constructor(data) {
		if (data instanceof DataStore) {
			Object.assign(this, data);
		} else {
			//MSGRAPH
			this.msGraph = data?.msGraph ?? null;

			this.appData = data?.appData ?? null; //joyride, login steps, menus, logs, users, roles, defult settings
			this.companyData = data?.companyData ?? null; //companies, regions, trades, departments, roles, users, employees
			this.userData = data?.userData ?? null; //notifications, calendar events, announcements, time off requests, job access, menu access
			this.payrollData = data?.payrollData ?? null; //payperiods, timecard headers, timecard rows, paystubs, resource groups, resource members
			this.projectData = data?.projectData ?? null; //contracts, items, jobs, costcodes, acos, pcos, rfis
			this.formData = data?.formData ?? null; //templates, forms, sections, questions, ratings, inputs, comments
			this.sched = data?.sched ?? null; //schedules, workflows, sequences, deliverables
		}
	}

	async initMSGraph(data = this, msAuth = null) {
		if (msAuth instanceof MSAuthAPI && !Boolean(data.msGraph)) {
			data.msGraph = new MSGraphAPI(msAuth);
		}
		return data;
	}

	async initCompanyData(data = this) {
		let params = { msUserID: data.msGraph?.msUser?.id };
		if (Boolean(params?.msUserID) && !Boolean(data?.app)) {
			let app = new CompanyData();
			app = await app.initCompanyData(params);
			data.app = app;
		}
		return data;
	}

	async initUserData(data = this) {
		let params = { msUserID: data.msGraph?.msUser?.id };
		if (Boolean(params?.msUserID) && !Boolean(data?.user)) {
			let user = new UserData();
			user = await user.initUserData(params);
			data.user = user;
		}
		return data;
	}

	async initPayrollData(data = this) {
		let params = { msUserID: data.msGraph?.msUser?.id };
		if (Boolean(params?.msUserID) && !Boolean(data?.payroll)) {
			let payroll = new PayrollData();
			payroll = await payroll.initPayrollData(params);
			data.payroll = payroll;
		}
		return data;
	}

	// async initOperations(data = this) {
	// 	let params = { msUserID: data.msGraph?.msUser?.id };
	// 	if (Boolean(params?.msUserID) && !Boolean(data?.operations)) {
	// 		let operations = new Operations();
	// 		operations = await operations.initOperations(params);
	// 		data.operations = operations;
	// 	}
	// 	return data;
	// }

	async initProjectData(data = this) {
		let params = {};
		if (Boolean(params?.msUserID) && !Boolean(data?.project)) {
			let project = new ProjectData();
			project = await project.initProjectData(params);
			data.project = project;
		}
		return data;
	}

	async initSchedData(data = this) {
		let params = {};
		if (Boolean(params?.msUserID) && !Boolean(data?.payroll)) {
			let sched = null; // new PayrollData();
			sched = await sched.initSchedData(params);
			data.sched = sched;
		}
		return data;
	}

	async initFormData(data = this) {
		let params = {};
		if (Boolean(params?.msUserID) && !Boolean(data?.payroll)) {
			let forms = null; // new PayrollData();
			forms = await forms.initPayrollData(params);
			data.forms = forms;
		}
		return data;
	}
}
