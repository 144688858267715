import React from "react";
import { Skeleton, Container, Stack, VStack } from "@chakra-ui/react";

import MainContent from "../App/components/MainContent";
import PayPeriodSelect from "./components/PayPeriodSelect";
import ResourceGroupSelect from "./components/ResourceGroupSelect";
import PayrollUploadPage from "./pages/PayrollUploadPage";

import PayrollAPI from "../../APIs/PayrollAPI";
import PayPeriodsObjClassLegacy from "./classes/PayPeriodsObjClassLegacy";
import ResourceGroupsObjClassLegacy from "./classes/ResourceGroupsObjClassLegacy";
import TimecardJobClassLegacy from "./classes/TimecardJobClassLegacy";

import { portalUser } from "../../App";
import { getDeviceType } from "../../helperFunctions";

export default class Payroll extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			payrollAPI: new PayrollAPI(),
			tabVal: this.props.tabVal ?? 0,

			prEndDate: this.props.prEndDate ?? portalUser.user?.prEndDate,
			payPeriods: new PayPeriodsObjClassLegacy(),

			timecardJobs: null,

			resourceGroups: null,
			disabledGroups: [],
			activeGroup: null,
			employees: null,

			exceptions: null,
		};

		this.init = this.init.bind(this);
		this.getTimecardJobs = this.getTimecardJobs.bind(this);
		this.getPayPeriods = this.getPayPeriods.bind(this);
		this.getResourceGroups = this.getResourceGroups.bind(this);
		this.getTimecardExceptions = this.getTimecardExceptions.bind(this);

		this.changePayPeriod = this.changePayPeriod.bind(this);
		this.changeResourceGroup = this.changeResourceGroup.bind(this);

		this.changeTab = this.changeTab.bind(this);
	}

	componentDidMount() {
		this.init();
	}

	componentDidUpdate() {
		if (this.props.prEndDate !== this.state.prEndDate) {
			this.setState(
				{
					prEndDate: this.props.prEndDate,
					resourceGroups: null,
					activeGroup: null,
					employees: null,
				},
				() => {
					this.init();
				}
			);
		}
	}

	async init() {
		let prEndDate = this.state.prEndDate;
		await this.getTimecardJobs();
		await this.getPayPeriods(prEndDate);
		await this.getResourceGroups(prEndDate);
		await this.getTimecardExceptions(prEndDate);
	}

	//AVAILABLE JOBS
	async getTimecardJobs() {
		//NOTE FOR REFACTORING - NEED TO SAVE EmployeeUID So that we can compare if the user is being impersonated!
		let timecardJobs = this.state.timecardJobs;
		if (!Boolean(timecardJobs?.length === 0)) {
			timecardJobs = await this.state.payrollAPI.GetTimecardJobs(portalUser.user?.employeeUID);
			if (timecardJobs.status === 200) {
				timecardJobs = timecardJobs.value;
				for (let i = 0; i < timecardJobs.length; i++) {
					timecardJobs[i] = new TimecardJobClassLegacy(timecardJobs[i]);
				}
				this.setState({
					timecardJobs: timecardJobs,
				});
			}
		}
	}

	//PAY PERIODS
	async getPayPeriods(prEndDate = portalUser.user?.prEndDate) {
		let payPeriods = this.state.payPeriods;
		if (payPeriods?.payPeriods?.length === 0) {
			payPeriods = await payPeriods.initPayPeriods(portalUser.user?.employeeUID, prEndDate);
		} else {
			payPeriods.setPayPeriod(prEndDate);
		}

		this.setState({
			payPeriods: payPeriods,
		});
	}

	//RESOURCE GROUPS
	async getResourceGroups(prEndDate = portalUser.user?.prEndDate) {
		let resourceGroups;
		if (!Boolean(this.state.resourceGroups)) {
			resourceGroups = new ResourceGroupsObjClassLegacy(portalUser.user?.employeeUID, prEndDate);
			await resourceGroups.getAvailableResourceGroups();
		} else {
			resourceGroups = this.state.resourceGroups;
		}

		// let tabVal = this.state.tabVal;
		let level = "review";
		// if (tabVal === 1) {
		// 	level = "crewEntry";
		// } else {
		// 	level = "approval";
		// }

		let activeGroup = resourceGroups.getActiveGroup(level, null);

		this.setState({
			resourceGroups: resourceGroups,
			employees: null,
		});

		this.setState(
			{
				resourceGroups: resourceGroups,
				activeGroup: activeGroup,
				employees: null,
			},
			async () => {
				if (resourceGroups?.availableResourceGroups?.length > 0) {
					await this.changeResourceGroup(level, null);
				} else {
					this.setState({
						activeGroup: 1,
					});
				}
			}
		);
	}

	async getTimecardExceptions(prEndDate = portalUser.user?.prEndDate) {
		let exceptions = this.state.exceptions;
		if (!Boolean(exceptions)) {
			let exceptions = await this.state.payrollAPI.GetPayrollTimecardExceptions(prEndDate);
			if (exceptions.status === 200) {
				exceptions = exceptions.value;
			} else {
				exceptions = null;
			}
			this.setState({
				exceptions: exceptions,
			});
		}
	}

	changePayPeriod(payPeriod = null) {
		let prEndDate = payPeriod?.prEndDate ?? portalUser.user?.prEndDate;
		this.setState(
			{
				prEndDate: prEndDate,
				resourceGroups: null,
				activeGroup: null,
				employees: null,
			},
			() => {
				this.init();
			}
		);
	}

	async changeResourceGroup(level = "review", activeGroup) {
		this.setState(
			{
				activeGroup: null,
			},
			async () => {
				let prEndDate = this.state.prEndDate;
				let resourceGroups = this.state.resourceGroups;
				let group = resourceGroups.getActiveGroup(level, activeGroup);
				let disabledGroups = [];
				let employees = this.state.employees ?? {};

				if (Boolean(prEndDate) && !Boolean(group?.members)) {
					let loadedEmployees = await group?.getTimecardData(prEndDate, level);

					for (let i = 0; i < group?.members.length; i++) {
						if (employees[group.members[i]] === undefined) {
							employees[group.members[i]] = loadedEmployees[group.members[i]];
						}
					}
				}

				this.setState({
					resourceGroups: resourceGroups,
					activeGroup: group,
					disabledGroups: disabledGroups,
					employees: employees,
				});
			}
		);
	}

	async changeTab(tabVal) {
		this.setState({
			tabVal: tabVal,
		});
	}

	render() {
		let tabs = ["Upload Time", "Posted Time", "Exceptions"];
		let pageName = "Upload Time";
		if (this.state.tabVal === 1) {
			pageName = "Posted Time";
		} else if (this.state.tabVal === 2) {
			pageName = "Payroll Exceptions";
		}

		let resourceGroupMembers = [];

		for (let i = 0; i < this.state.activeGroup?.members?.length; i++) {
			resourceGroupMembers.push(this.state.employees[this.state.activeGroup.members[i]]);
		}

		return (
			<MainContent title={pageName} onTabChange={this.changeTab} tabVal={this.state.tabVal} tabs={tabs}>
				<Container maxW="100rem" my="4" px="4">
					<Stack
						spacing={4}
						direction={{ base: "column", sm: "row" }}
						justify={{ base: "flex-start", sm: "space-between" }}
					>
						<PayPeriodSelect
							tabVal={this.state.tabVal}
							prEndDate={this.state.prEndDate}
							payPeriods={this.state.payPeriods}
							changePayPeriod={this.changePayPeriod}
						/>

						<ResourceGroupSelect
							page={"payroll"}
							tabVal={this.state.tabVal}
							activeGroup={this.state.activeGroup}
							availableResourceGroups={this.state.resourceGroups?.availableResourceGroups}
							disabledGroups={this.state.disabledGroups}
							changeResourceGroup={this.changeResourceGroup}
						/>
					</Stack>
				</Container>

				{this.state.tabVal === 0 && (
					<Container maxW="100rem" px={getDeviceType() === "mobile" ? "0" : "4"}>
						<Skeleton
							isLoaded={
								Boolean(this.state.activeGroup) &&
								Boolean(this.state.payPeriods) &&
								Boolean(this.state.timecardJobs)
							}
							rounded="5"
							minH="sm"
						>
							<VStack
								w="full"
								spacing={2}
								p={{ base: "2", sm: "4" }}
								minH="sm"
								bg="blackAlpha.50"
								rounded="5"
							>
								{Boolean(this.state.activeGroup) &&
									Boolean(this.state.payPeriods) &&
									Boolean(this.state.timecardJobs) && (
										<PayrollUploadPage
											payPeriods={this.state.payPeriods}
											resourceGroup={this.state.activeGroup}
											timecardJobs={this.state.timecardJobs}
											members={resourceGroupMembers}
											reviewLevel="Review"
										/>
									)}
							</VStack>
						</Skeleton>
					</Container>
				)}

				{(this.state.tabVal === 1 || this.state.tabVal === 2) && (
					<Container maxW="100rem" px={getDeviceType() === "mobile" ? "0" : "4"}>
						<Skeleton
							isLoaded={
								Boolean(this.state.activeGroup) &&
								Boolean(this.state.payPeriods) &&
								Boolean(this.state.timecardJobs)
							}
							rounded="5"
							minH="sm"
						>
							<VStack
								w="full"
								spacing={2}
								p={{ base: "2", sm: "4" }}
								minH="sm"
								bg="blackAlpha.50"
								rounded="5"
							>
								{Boolean(this.state.activeGroup) &&
									Boolean(this.state.payPeriods) &&
									Boolean(this.state.timecardJobs) && (
										<PayrollUploadPage
											payPeriods={this.state.payPeriods}
											resourceGroup={this.state.activeGroup}
											timecardJobs={this.state.timecardJobs}
											members={resourceGroupMembers}
											reviewLevel="Review"
											showPosted
										/>
									)}
							</VStack>
						</Skeleton>
					</Container>
				)}
			</MainContent>
		);
	}
}
