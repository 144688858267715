import { ajaxGET, ajaxPOST, ajaxPATCH, testGET, testPOST, setQueryParams } from "./api-functions";
import { portalUser } from "../App";

const apiBaseURL =
	(process.env.NODE_ENV === "development"
		? "https://ppmportalappnodejs-api.azurewebsites.net"
		: window.location.origin) + "/api/devops/";

export default class DevOpsAPI {
	async TestGET(headers = {}) {
		let response = await testGET(apiBaseURL, headers);
		return response;
	}

	async TestPOST(body = {}, headers = {}) {
		let response = await testPOST(apiBaseURL, body, headers);
		return response;
	}

	//PowerBI Links-------------------------------------------//
	async GetPowerBIEmbedLinks() {
		var apiRouteURL = apiBaseURL + "powerbi/embedlinks";
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	async GetPowerBILinks() {
		var apiRouteURL = apiBaseURL + "powerbi/";
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	async InsertPowerBILink(reportUID) {
		var apiRouteURL = apiBaseURL + `powerbi/${reportUID}`;
		apiRouteURL = setQueryParams(apiRouteURL);
		let response = await ajaxPOST(apiRouteURL);
		return response;
	}

	async UpdatePowerBILink(reportUID, data) {
		var apiRouteURL = apiBaseURL + `powerbi/${reportUID}`;
		apiRouteURL = setQueryParams(apiRouteURL);
		let response = await ajaxPATCH(apiRouteURL, data);
		return response;
	}

	//----------------------NOT CURRENTLY USED------------------------//
	//--------Portal Config - SQL Tables and SQL Columns ------------//
	async GetPortalConfig(msUserID = portalUser.user?.msUserID ?? portalUser.msUser?.id) {
		var apiRouteURL = apiBaseURL + `portalConfig/${msUserID}`;
		apiRouteURL = setQueryParams(apiRouteURL);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	async GetPortalData(employeeUID, tableName) {
		var apiRouteURL = apiBaseURL + `portalConfig/${employeeUID}/data`;
		apiRouteURL = setQueryParams(apiRouteURL, { tableName: tableName });
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//----------------------NOT CURRENTLY USED----------------------------//
	//--------------MSGraph Sprint Planning Examples---------------------//
	async GetMSGraphSprints(teamId) {
		var apiRouteURL = apiBaseURL + `msgraph/${teamId}/sprints`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	async InsertMSGraphSprint(teamId, sprintId, data) {
		var apiRouteURL = apiBaseURL + `msgraph/${teamId}/sprints/${sprintId}`;

		let response = await ajaxPOST(apiRouteURL, data);
		return response;
	}

	async UpdateMSGraphSprint(keyID, data) {
		var apiRouteURL = apiBaseURL + `msgraph/sprints/${keyID}`;
		let response = await ajaxPATCH(apiRouteURL, data);
		return response;
	}

	async GetMSGraphTeamMeetings(teamId) {
		var apiRouteURL = apiBaseURL + `msgraph/${teamId}/teamMeetings`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	async InsertMSGraphTeamMeeting(teamId, meetingId, data) {
		var apiRouteURL = apiBaseURL + `msgraph/${teamId}/teamMeetings/${meetingId}`;
		let response = await ajaxPOST(apiRouteURL, data);
		return response;
	}

	async UpdateMSGraphTeamMeeting(keyID, data) {
		var apiRouteURL = apiBaseURL + `msgraph/teamMeetings/${keyID}`;
		let response = await ajaxPATCH(apiRouteURL, data);
		return response;
	}

	// MS GRAPH Channels
	async GetMSGraphChannels(teamId) {
		var apiRouteURL = apiBaseURL + `msgraph/${teamId}/channels`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	async InsertMSGraphChannel(teamId, channelId, data) {
		var apiRouteURL = apiBaseURL + `msgraph/${teamId}/channels/` + channelId;
		let response = await ajaxPOST(apiRouteURL, data);
		return response;
	}

	async UpdateMSGraphChannel(keyID, data) {
		var apiRouteURL = apiBaseURL + `msgraph/channels/${keyID}`;
		let response = await ajaxPATCH(apiRouteURL, data);
		return response;
	}

	// MS GRAPH Channel Tabs
	async GetMSGraphTabs(teamId) {
		var apiRouteURL = apiBaseURL + `msgraph/${teamId}/tabs`;
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	async InsertMSGraphTab(teamId, tabId, data) {
		var apiRouteURL = apiBaseURL + `msgraph/${teamId}/tabs/${tabId}`;
		let response = await ajaxPOST(apiRouteURL, data);
		return response;
	}

	async UpdateMSGraphTab(keyID, data) {
		var apiRouteURL = apiBaseURL + `msgraph/tabs/${keyID}`;
		let response = await ajaxPATCH(apiRouteURL, data);
		return response;
	}
}
