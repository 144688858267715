import PCO_STATUSES from "../constants/PCOStatuses";
import { getSubtotal, getRate } from "../../../helperFunctions";

export default class SectionTemplatePCOLog {
	constructor(pcos = [], isLoaded = false) {
		this.template = "PCOs";
		this.name = "Pending Change Order Log";
		this.heading = "Pending Change Orders";
		this.tooltip = "Click here to add a new Pending Change Order";
		this.divider = false;
		this.pagebreak = 0;
		this.wrap = true;
		this.type = "virtualTable";
		this.minRows = 1;
		this.maxRows = 16;

		this.subSections = [new SubSectionTemplatePCOLog(pcos)];
		this.isLoaded = isLoaded;
	}
}

class SubSectionTemplatePCOLog {
	constructor(pcos = []) {
		let sortGroupFilter = this.getSortGroupFilter(pcos);
		this.template = "PCOs";
		this.type = "virtualTable";
		this.show = pcos?.length > 0 ? true : false;
		this.groupBy = sortGroupFilter.groupBy;
		this.filterBy = sortGroupFilter.filterBy;
		this.sortBy = sortGroupFilter.sortBy;
		this.columns = [
			{
				accessor: "jcco",
				Header: "JCCo",
				show: false,
			},
			{
				accessor: "contract",
				Header: "Contract",
				show: false,
			},

			{
				accessor: "pco",
				Header: "PCO #",
				width: "85%",
				textAlign: "center",
			},
			{
				accessor: "description",
				Header: "Description",
				width: "300%",
				// editable: true,
			},
			{
				accessor: "gcRef",
				Header: "GC Ref #",
				// editable: true,
			},
			{
				accessor: "pcoStatus",
				Header: "Status",
				textAlign: "center",
				filter: "multiselect",
				show: false,
				// editable: true,
			},
			{
				accessor: "pcoStatusDesc",
				Header: "PCO Status",
				textAlign: "center",
				filter: "multiselect",
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
				// editable: true,
			},
			{
				accessor: "pcoAmt",
				Header: "Amt Submitted",
				textAlign: "right",
				valueType: "currency",
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
				// editable: true,
			},
			{
				accessor: "dateSubmitted",
				Header: "Date Submitted",
				textAlign: "right",
				valueType: "date",
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
				// editable: true,
			},
			{
				accessor: "pcoConv",
				Header: "PCO Conversion",
				textAlign: "right",
				valueType: "currency",
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
				// editable: true,
			},
			{
				accessor: "pctMargin",
				Header: "% Margin",
				textAlign: "right",
				valueType: "percent",
				decimals: 1,
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
				// editable: true,
			},
			{
				accessor: "pcoEstCost",
				Header: "Estimated Cost",
				valueType: "currency",
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},
			{
				accessor: "pctComplete",
				Header: "% Complete",
				textAlign: "right",
				valueType: "percent",
				decimals: 1,
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
				// editable: true,
			},
			{
				accessor: "pcoActualCost",
				Header: "Cost To Date",
				textAlign: "right",
				valueType: "currency",
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},
			{
				accessor: "notes",
				Header: "Notes / Comments",
				show: false,
			},
			{
				accessor: "expander",
				id: "expander",
				subRow: {
					rowNum: 8,
					overflowX: "hidden",
					overflowY: "auto",
					columns: [{ id: "notes" }],
				},
			},
		];

		this.data = pcos;
		this.footer = (rows = pcos) => {
			return this.getFooter(rows);
		};
	}

	getSortGroupFilter(pcos = []) {
		let groupBy = [];
		let sortBy = [{ id: "pco", desc: false }];
		let filterBy = [{ id: "pcoStatusDesc", value: ["PENDING", "ROM", "RMA", "CREDIT", "ALLOWANCE"] }];
		let keys = [...new Set(pcos?.map((data) => data.jccmKeyID))];
		if (keys.length > 1) {
			groupBy = ["jccmKeyID"];
			sortBy = [
				{ id: "co", desc: false },
				{ id: "contract", desc: false },
			];
		}
		let sortGroupFilter = { sortBy: sortBy, groupBy: groupBy, filterBy: filterBy };
		return sortGroupFilter;
	}

	getFooter(rows = []) {
		let footer = [];
		let statuses = PCO_STATUSES.filter((d) => !["BUDGET", "APPD"].includes(d.value));
		for (let i = 0; i < statuses?.length; i++) {
			let footerRow = {
				pcoStatusDesc: statuses[i].label,
				pcoStatusDescTextAlign: "right",
				pcoStatusDescFontSize: 8,
				pcoAmt: 0,
				dateSubmitted: 0,
				dateSubmittedType: "percent",
				dateSubmittedDecimals: 1,
				pcoConv: 0,
				pctMargin: 0,
				pcoEstCost: 0,
				pctComplete: 0,
				pcoActualCost: 0,
			};
			let showFooterRow = false;

			let filteredRows = [];
			if (statuses[i].value !== "TOTAL") {
				filteredRows = rows?.filter((d) => d.pcoStatus === statuses[i].value) ?? [];
			} else {
				filteredRows = rows?.filter((d) => !["BUDGET", "CLSD", "APPD"].includes(d.pcoStatus)) ?? [];
				showFooterRow = true;
			}

			if (filteredRows?.length > 0) {
				footerRow.pcoAmt = getSubtotal(filteredRows, "pcoAmt");
				footerRow.pcoConv = getSubtotal(filteredRows, "pcoConv");
				footerRow.pcoEstCost = getSubtotal(filteredRows, "pcoEstCost");
				footerRow.pcoActualCost = getSubtotal(filteredRows, "pcoActualCost");
				footerRow.dateSubmitted = getRate(footerRow.pcoConv, footerRow.pcoAmt) * 100;
				footerRow.pctMargin = getRate(footerRow.pcoConv - footerRow.pcoEstCost, footerRow.pcoConv) * 100;
				footerRow.pctComplete = getRate(footerRow.pcoActualCost, footerRow.pcoEstCost) * 100;

				if (
					statuses[i].value !== "TOTAL" &&
					footerRow.pcoAmt + footerRow.pcoConv + footerRow.pcoEstCost + footerRow.pcoActualCost !== 0
				) {
					showFooterRow = true;
				}
			}

			if (showFooterRow) {
				footer.push(footerRow);
			}
		}

		return footer;
	}
}
