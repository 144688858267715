import React, { useState, useMemo, useCallback } from "react";
import {
	Box,
	Flex,
	Spacer,
	Table,
	Thead,
	Th,
	Tbody,
	Tfoot,
	Tr,
	Td,
	Text,
	Heading,
	Input,
	Skeleton,
	useBoolean,
	HStack,
	Stack,
	Switch,
} from "@chakra-ui/react";
import { EmployeeHistoryList } from "../classes/MeritIncreasesClassesLegacy";

import { formatValue } from "../../../helperFunctions";

import moment from "moment";
import MeritIncreasesHistoryModal from "./MeritIncreasesHistoryModal";

export default function MeritIncreasesHistoryTable(props) {
	const title =
		props.row?.deptByRegion +
		(props.row?.deptByRegion === "Executive" ? "s" : "");
	const table = props.row?.deptByRegion + "-table";
	const employeeHistory = useMemo(() => props.row?.rows, [props.row?.rows]);

	const [selectedEmployee, selectEmployee] = useState(null);

	const closeModal = () => {
		selectEmployee(null);
	};
	const [showRates, onToggle] = useBoolean();
	const initEmployeeHistory = useCallback(() => {
		let employeeHistoryList = new EmployeeHistoryList();
		for (let i = 0; i < employeeHistory?.length; i++) {
			employeeHistoryList.push(employeeHistory[i]);
		}
		employeeHistoryList.sortEmployees();
		return employeeHistoryList;
	}, [employeeHistory]);

	const [history, setHistory] = useState([]);
	useMemo(() => {
		let employeeHistory = initEmployeeHistory();
		setHistory(employeeHistory);
	}, [initEmployeeHistory]);

	let topBottomBorder = {
		boxShadow:
			"inset 0 2px 0 #000000, inset 0 -2px 0 #000000, inset 2px 0 0 #000000, inset -2px 0 0 #000000",
	};
	let leftRightBorder = {
		boxShadow:
			"inset 2px 0 0 #000000, inset -2px 0 0 #000000, inset 0 -1px 0 #EDF2F7",
	};
	let leftBorder = { boxShadow: "inset 1px 0 0 #000000" };

	const handleProposalUpdate = async (ev, i) => {
		let name = ev.target.name;
		let value = ev.target.value;
		value = value.replace("'", "`");
		if (name !== "managerNotes") {
			value = value.replace("$", "");
			value = value.replace(",", "");
			value = value.replace("+", "");
			value = value.replace("%", "");
			value = parseFloat(value);
			if (isNaN(value)) {
				value = 0;
			}
		}
		let newHistory = history;

		newHistory[i]?.updateEmployeeRow(name, value);

		let tempHistory = new EmployeeHistoryList();
		Object.assign(tempHistory, newHistory);
		setHistory(tempHistory);
	};

	let proposalYear = props?.proposalYear - 1;

	let historyTotals = {};
	let pctIncreaseTotalsArr = {};
	let proposedIncreaseTotalsArr = {};
	if (history.length > 0) {
		historyTotals = history?.getEmployeeHistoryTotals();
		pctIncreaseTotalsArr = historyTotals.getPctIncrease(
			historyTotals.newSalary,
			historyTotals.oldSalary
		);
		proposedIncreaseTotalsArr = historyTotals.getPctIncrease(
			historyTotals.proposedSalary,
			historyTotals.newSalary
		);
	}

	let salaryTab = props.tabVal === 0;

	return (
		<Skeleton
			isLoaded={Boolean(history?.length > 0)}
			id={props.joyrideID ?? table + "-id"}
		>
			{Boolean(selectedEmployee) && (
				<MeritIncreasesHistoryModal
					proposalYear={proposalYear}
					isOpen={Boolean(selectedEmployee)}
					onClose={closeModal}
					selectedEmployee={selectedEmployee}
				/>
			)}
			<Flex flex={1} w="full" justify="space-between" align="center">
				<Heading
					textTransform="uppercase"
					letterSpacing={2}
					size="lg"
					color="gray.400"
					py={3}
				>
					{title}
				</Heading>

				<Spacer />
				{salaryTab && (
					<HStack spacing={2} align="center" justify="center" px="4">
						<Text
							fontSize="sm"
							textTransform="uppercase"
							color="gray.400"
							fontWeight="semibold"
						>
							Show Rates
						</Text>
						<Switch
							colorScheme="teal"
							isChecked={showRates}
							onChange={onToggle.toggle}
						/>
					</HStack>
				)}
			</Flex>
			<Box
				className="skinnyScroll"
				overflow="auto"
				maxH="container.lg"
				pr="1"
			>
				<Table
					size="small"
					bg="white"
					top="0"
					left="0"
					color="gray.600"
					key={table}
				>
					<Thead
						zIndex="2"
						top={0}
						position="sticky"
						style={{ borderSpacing: "0", insetBlockStart: "0" }}
						border="0px"
					>
						<Tr
							textTransform="uppercase"
							bg={props.table?.deptColor ?? "teal.700"}
							style={topBottomBorder}
						>
							<Th w="100px" maxW="100px">
								<Heading
									fontSize="xs"
									color="white"
									isTruncated
									p="2"
									textAlign="left"
								>
									Region
								</Heading>
							</Th>
							<Th w="225px" minW="250px">
								<Heading
									fontSize="xs"
									color="white"
									isTruncated
									p="2"
									textAlign="left"
								>
									Employee Name
								</Heading>
							</Th>
							<Th w="100px" maxW="100px">
								<Heading
									fontSize="xs"
									color="white"
									isTruncated
									p="2"
									textAlign="left"
								>
									Longevity
								</Heading>
							</Th>
							{salaryTab && (
								<Th w="125px" maxW="125px">
									<Heading
										fontSize="xs"
										color="white"
										p="2"
										textAlign="center"
									>
										Last&nbsp;Years Salary&nbsp;In&nbsp;
										{proposalYear - 1}
									</Heading>
								</Th>
							)}
							{salaryTab && (
								<Th w="125px" maxW="125px">
									<Heading
										fontSize="xs"
										color="white"
										p="2"
										textAlign="center"
									>
										Current Salary&nbsp;In&nbsp;
										{proposalYear}
									</Heading>
								</Th>
							)}
							{salaryTab && (
								<Th w="125px" maxW="150px">
									<Heading
										fontSize="xs"
										color="white"
										p="2"
										textAlign="center"
									>
										%&nbsp;Increase In&nbsp;{proposalYear}
									</Heading>
								</Th>
							)}
							{salaryTab && (
								<Th w="125px" maxW="150px">
									<Heading
										fontSize="xs"
										color="white"
										p="2"
										textAlign="center"
									>
										Proposed Salary&nbsp;for&nbsp;
										{proposalYear + 1}
									</Heading>
								</Th>
							)}
							{salaryTab && (
								<Th w="125px" maxW="150px">
									<Heading
										fontSize="xs"
										color="white"
										p="2"
										textAlign="center"
									>
										%&nbsp;Increase for&nbsp;
										{proposalYear + 1}
									</Heading>
								</Th>
							)}
							{salaryTab && (
								<Th w="125px" maxW="125px">
									<Heading
										fontSize="xs"
										color="white"
										p="2"
										textAlign="center"
									>
										Year&nbsp;End Bonus&nbsp;In&nbsp;
										{proposalYear - 1}
									</Heading>
								</Th>
							)}
							{salaryTab && (
								<Th w="125px" maxW="150px">
									<Heading
										fontSize="xs"
										color="white"
										p="2"
										textAlign="center"
									>
										Proposed Bonus&nbsp;for&nbsp;
										{proposalYear}
									</Heading>
								</Th>
							)}
							{salaryTab && (
								<Th w="125px" maxW="125px">
									<Heading
										fontSize="xs"
										color="white"
										p="2"
										textAlign="center"
									>
										{proposalYear + 1}&nbsp;Phone Allowance
									</Heading>
								</Th>
							)}
							{salaryTab && (
								<Th w="125px" maxW="125px">
									<Heading
										fontSize="xs"
										color="white"
										p="2"
										textAlign="center"
									>
										{proposalYear + 1}&nbsp;Vehicle
										Allowance
									</Heading>
								</Th>
							)}
							{salaryTab && (
								<Th w="125px" maxW="125px">
									<Heading
										fontSize="xs"
										color="white"
										p="2"
										textAlign="center"
									>
										{proposalYear + 1}&nbsp;Housing
										Allowance
									</Heading>
								</Th>
							)}
							{salaryTab && (
								<Th w="125px" maxW="125px">
									<Heading
										fontSize="xs"
										color="white"
										p="2"
										textAlign="center"
									>
										{proposalYear + 1}&nbsp;Total
										Yearly&nbsp;Comp
									</Heading>
								</Th>
							)}

							<Th>
								<Heading
									fontSize="xs"
									color="white"
									p="2"
									textAlign="left"
								>
									Manager's Comments
								</Heading>
							</Th>
						</Tr>
					</Thead>

					<Tbody
						style={{ borderSpacing: "0", insetBlockEnd: "0" }}
						border="none"
					>
						{history?.map((row, i) => {
							let pctIncreaseArr = row.getPctIncrease(
								row.newSalary,
								row.oldSalary
							);
							let proposedIncreaseArr = row.getPctIncrease(
								row.proposedSalary,
								row.newSalary
							);

							return (
								<Tr
									style={leftRightBorder}
									border="none"
									_hover={{ bg: "blackAlpha.50" }}
									key={row.keyID}
								>
									<Td px="2" style={leftBorder}>
										<Stack spacing={-1}>
											<Text isTruncated>
												{row.region}
											</Text>
											<Text isTruncated fontSize="xs">
												{row.employeeType}
											</Text>
										</Stack>
									</Td>
									<Td px="2" style={leftBorder}>
										<Stack spacing={-1}>
											<Text isTruncated>
												{row.firstName +
													" " +
													row.lastName}
											</Text>
											<Text isTruncated fontSize="xs">
												{row.title}
											</Text>
										</Stack>
									</Td>
									<Td px="2" style={leftBorder}>
										<Stack spacing={-1}>
											<Text isTruncated>
												{moment(row.hireDate).format(
													"M/D/yyyy"
												)}
											</Text>
											<Text isTruncated fontSize="xs">
												{row.getLongevity()}
											</Text>
										</Stack>
									</Td>
									{salaryTab && (
										<Td
											px="2"
											style={leftBorder}
											textAlign="right"
										>
											<Stack spacing={-1}>
												<Text isTruncated>
													{formatValue(
														row.oldSalary,
														0,
														"currency"
													)}
												</Text>
												{showRates && (
													<Text
														isTruncated
														fontSize="xs"
													>
														{formatValue(
															row.oldRate,
															2,
															"currency"
														) + "/hr"}
													</Text>
												)}
											</Stack>
										</Td>
									)}
									{salaryTab && (
										<Td
											px="2"
											style={leftBorder}
											textAlign="right"
										>
											<Stack spacing={-1}>
												<Text isTruncated>
													{formatValue(
														row.newSalary,
														0,
														"currency"
													)}
												</Text>
												{showRates && (
													<Text
														isTruncated
														fontSize="xs"
													>
														{formatValue(
															row.newRate,
															2,
															"currency"
														) + "/hr"}
													</Text>
												)}
											</Stack>
										</Td>
									)}
									{salaryTab && (
										<Td
											px="2"
											style={leftBorder}
											textAlign="right"
											onClick={(ev) => {
												ev.stopPropagation();
												selectEmployee(row);
											}}
											id={
												i === 0 &&
												Boolean(props.joyrideID)
													? "joyrideStep2"
													: null
											}
										>
											<Stack spacing={-1}>
												<Text
													isTruncated
													fontWeight="bold"
													color={pctIncreaseArr.color}
													_hover={{
														textDecoration:
															"underline",
														color: "blue.600",
														cursor: "pointer",
													}}
												>
													{pctIncreaseArr.symbol +
														formatValue(
															pctIncreaseArr.pctIncrease,
															2,
															"percent"
														)}
												</Text>
												{showRates &&
													pctIncreaseArr.increase !==
														0 && (
														<Text
															color={
																pctIncreaseArr.color
															}
															isTruncated
															fontSize="xs"
														>
															{pctIncreaseArr.symbol +
																formatValue(
																	pctIncreaseArr.increase,
																	0,
																	"currency"
																)}
														</Text>
													)}
											</Stack>
										</Td>
									)}
									{salaryTab && (
										<Td
											style={leftBorder}
											// bg={row.locked === "Y" ? "blackAlpha.50" : "transparent"}
											px="2"
											textAlign="right"
											id={
												i === 0 &&
												Boolean(props.joyrideID)
													? "joyrideStep3"
													: null
											}
										>
											<Stack spacing={-1}>
												<Input
													key={
														"proposedSalary-" +
														row.keyID +
														"-" +
														row.proposedSalary
													}
													// isDisabled={row.locked === "Y" || props.isDisabled}
													// cursor={
													// 	row.locked === "Y" || props.isDisabled
													// 		? "not-allowed"
													// 		: "pointer"
													// }
													_focus={{
														fontWeight: "bold",
													}}
													focusBorderColor="teal.600"
													variant="unstyled"
													textAlign="right"
													placeholder="$0"
													defaultValue={formatValue(
														row.proposedSalary,
														0,
														"currency"
													)}
													// defaultValue={
													// 	formatValue(row.proposedSalary, 0, "currency") === "$0" &&
													// 	row.locked === "N"
													// 		? null
													// 		: formatValue(row.proposedSalary, 0, "currency")
													// }
													name="proposedSalary"
													onBlur={(ev) => {
														handleProposalUpdate(
															ev,
															i
														);
													}}
													autoComplete="off"
												/>
												{showRates &&
													proposedIncreaseArr.increase !==
														0 && (
														<Text
															isTruncated
															fontSize="xs"
														>
															{formatValue(
																row.getProposedRate(),
																2,
																"currency"
															) + "/hr"}
														</Text>
													)}
											</Stack>
										</Td>
									)}

									{salaryTab && (
										<Td
											px="2"
											style={leftBorder}
											textAlign="right"
											id={
												i === 0 &&
												Boolean(props.joyrideID)
													? "joyrideStep4"
													: null
											}
										>
											<Stack spacing={-1}>
												<Input
													key={
														"proposedPctIncrease-" +
														row.keyID +
														"-" +
														row.getPctIncrease(
															row.proposedSalary,
															row.newSalary
														).symbol +
														row.getPctIncrease(
															row.proposedSalary,
															row.newSalary
														).pctIncrease
													}
													isTruncated
													fontWeight="bold"
													color={
														proposedIncreaseArr.color
													}
													// isDisabled={row.locked === "Y" || props.isDisabled}
													// cursor={
													// 	row.locked === "Y" || props.isDisabled
													// 		? "not-allowed"
													// 		: "pointer"
													// }
													_focus={{
														fontWeight: "bold",
													}}
													focusBorderColor="teal.600"
													variant="unstyled"
													textAlign="right"
													placeholder="0.00%"
													defaultValue={
														(formatValue(
															proposedIncreaseArr.pctIncrease,
															2,
															"percent"
														) === "0.00%"
															? ""
															: proposedIncreaseArr.symbol) +
														formatValue(
															proposedIncreaseArr.pctIncrease,
															2,
															"percent"
														)
													}
													name="proposedPctIncrease"
													onBlur={(ev) => {
														handleProposalUpdate(
															ev,
															i
														);
													}}
													autoComplete="off"
												/>
												{showRates &&
													proposedIncreaseArr.increase !==
														0 && (
														<Text
															isTruncated
															fontSize="xs"
															color={
																proposedIncreaseArr.color
															}
														>
															{proposedIncreaseArr.symbol +
																formatValue(
																	proposedIncreaseArr.increase,
																	0,
																	"currency"
																)}
														</Text>
													)}
											</Stack>
										</Td>
									)}
									{salaryTab && (
										<Td
											px="2"
											style={leftBorder}
											textAlign="right"
											id={
												i === 0 &&
												Boolean(props.joyrideID)
													? "joyrideStep8"
													: null
											}
										>
											<Text>
												{formatValue(
													row.bonusYE,
													0,
													"currency"
												)}
											</Text>
										</Td>
									)}
									{salaryTab && (
										<Td
											style={leftBorder}
											// bg={row.locked === "Y" ? "blackAlpha.50" : "transparent"}
											px="2"
										>
											<Input
												key={
													"proposedBonus-" +
													row.keyID +
													"-" +
													row.proposedBonus
												}
												// isDisabled={row.locked === "Y" || props.isDisabled}
												// cursor={
												// 	row.locked === "Y" || props.isDisabled ? "not-allowed" : "pointer"
												// }
												_focus={{ fontWeight: "bold" }}
												focusBorderColor="teal.600"
												variant="unstyled"
												textAlign="right"
												placeholder="$0"
												defaultValue={
													formatValue(
														row.proposedBonus,
														0,
														"currency"
													) === "$0"
														? ""
														: formatValue(
																row.proposedBonus,
																0,
																"currency"
														  )
												}
												name="proposedBonus"
												onBlur={(ev) => {
													handleProposalUpdate(ev, i);
												}}
												autoComplete="off"
											/>
										</Td>
									)}
									{salaryTab && (
										<Td
											style={leftBorder}
											// bg={row.locked === "Y" ? "blackAlpha.50" : "transparent"}
											px="2"
										>
											<Input
												key={
													"proposedCell-" +
													row.keyID +
													"-" +
													row.proposedCell
												}
												// isDisabled={row.locked === "Y" || props.isDisabled}
												// cursor={
												// 	row.locked === "Y" || props.isDisabled ? "not-allowed" : "pointer"
												// }
												_focus={{ fontWeight: "bold" }}
												focusBorderColor="teal.600"
												variant="unstyled"
												textAlign="right"
												placeholder="$0"
												defaultValue={
													formatValue(
														row.proposedCell,
														0,
														"currency"
													) === "$0"
														? ""
														: formatValue(
																row.proposedCell,
																0,
																"currency"
														  )
												}
												name="proposedCell"
												onBlur={(ev) => {
													handleProposalUpdate(ev, i);
												}}
												autoComplete="off"
											/>
										</Td>
									)}
									{salaryTab && (
										<Td
											style={leftBorder}
											// bg={row.locked === "Y" ? "blackAlpha.50" : "transparent"}
											px="2"
										>
											<Input
												key={
													"proposedVehicle-" +
													row.keyID +
													"-" +
													row.proposedVehicle
												}
												// isDisabled={row.locked === "Y" || props.isDisabled}
												// cursor={
												// 	row.locked === "Y" || props.isDisabled ? "not-allowed" : "pointer"
												// }
												_focus={{ fontWeight: "bold" }}
												focusBorderColor="teal.600"
												variant="unstyled"
												textAlign="right"
												placeholder="$0"
												// defaultValue={formatValue(row.proposedVehicle, 0, "currency")}
												defaultValue={
													formatValue(
														row.proposedVehicle,
														0,
														"currency"
													) === "$0"
														? ""
														: formatValue(
																row.proposedVehicle,
																0,
																"currency"
														  )
												}
												name="proposedVehicle"
												onBlur={(ev) => {
													handleProposalUpdate(ev, i);
												}}
												autoComplete="off"
											/>
										</Td>
									)}
									{salaryTab && (
										<Td
											style={leftBorder}
											// bg={row.locked === "Y" ? "blackAlpha.50" : "transparent"}
											px="2"
										>
											<Input
												key={
													"proposedHousing-" +
													row.keyID +
													"-" +
													row.proposedHousing
												}
												// isDisabled={row.locked === "Y" || props.isDisabled}
												// cursor={
												// 	row.locked === "Y" || props.isDisabled ? "not-allowed" : "pointer"
												// }
												_focus={{ fontWeight: "bold" }}
												focusBorderColor="teal.600"
												variant="unstyled"
												textAlign="right"
												placeholder="$0"
												defaultValue={
													formatValue(
														row.proposedHousing,
														0,
														"currency"
													) === "$0"
														? ""
														: formatValue(
																row.proposedHousing,
																0,
																"currency"
														  )
												}
												name="proposedHousing"
												onBlur={(ev) => {
													handleProposalUpdate(ev, i);
												}}
												autoComplete="off"
											/>
										</Td>
									)}
									{salaryTab && (
										<Td
											px="2"
											style={leftBorder}
											textAlign="right"
										>
											<Text>
												{formatValue(
													row.getTotalYearlyComp(),
													0,
													"currency"
												)}
											</Text>
										</Td>
									)}

									<Td
										style={leftBorder}
										px="2"
										id={
											i === 0 && Boolean(props.joyrideID)
												? "joyrideStep5"
												: null
										}
									>
										<Input
											key={
												"managerNotes-" +
												row.keyID +
												"-" +
												row.managerNotes
											}
											fontSize="sm"
											// isDisabled={row.locked === "Y" || props.isDisabled}
											// cursor={row.locked === "Y" || props.isDisabled ? "not-allowed" : "pointer"}
											_focus={{ fontWeight: "bold" }}
											focusBorderColor="teal.600"
											variant="unstyled"
											textAlign="left"
											placeholder="Add notes here"
											defaultValue={row.managerNotes}
											name="managerNotes"
											onBlur={(ev) => {
												handleProposalUpdate(ev, i);
											}}
											autoComplete="off"
										/>
									</Td>
								</Tr>
							);
						})}
					</Tbody>

					<Tfoot
						bottom={0}
						zIndex="2"
						position="sticky"
						style={{ borderCollapse: "collapse" }}
						bg="whitesmoke"
					>
						<Tr
							style={topBottomBorder}
							border="none"
							fontWeight="bold"
						>
							<Td border="none" py="2" px="3" colspan={3}>
								<Text
									textTransform="uppercase"
									letterSpacing={1}
									isTruncated
								>
									{title + " Totals:"}
								</Text>
							</Td>
							{salaryTab && (
								<Td border="none" p="2">
									<Text
										textTransform="uppercase"
										isTruncated
										textAlign="right"
									>
										{formatValue(
											historyTotals.oldSalary,
											0,
											"currency"
										)}
									</Text>
								</Td>
							)}
							{salaryTab && (
								<Td border="none" p="2">
									<Text
										textTransform="uppercase"
										isTruncated
										textAlign="right"
									>
										{formatValue(
											historyTotals.newSalary,
											0,
											"currency"
										)}
									</Text>
								</Td>
							)}

							{salaryTab && (
								<Td border="none" p="2" textAlign="right">
									<Stack spacing={-1}>
										<Text
											textTransform="uppercase"
											isTruncated
											textAlign="right"
											color={pctIncreaseTotalsArr.color}
										>
											{pctIncreaseTotalsArr.symbol +
												formatValue(
													pctIncreaseTotalsArr.pctIncrease,
													2,
													"percent"
												)}
										</Text>
										{showRates &&
											pctIncreaseTotalsArr.increase !==
												0 && (
												<Text
													isTruncated
													fontSize="xs"
													color={
														pctIncreaseTotalsArr.color
													}
												>
													{pctIncreaseTotalsArr.symbol +
														formatValue(
															pctIncreaseTotalsArr.increase,
															0,
															"currency"
														)}
												</Text>
											)}
									</Stack>
								</Td>
							)}
							{salaryTab && (
								<Td border="none" p="2" textAlign="right">
									<Text
										textTransform="uppercase"
										isTruncated
										textAlign="right"
									>
										{formatValue(
											historyTotals.proposedSalary,
											0,
											"currency"
										)}
									</Text>
								</Td>
							)}
							{salaryTab && (
								<Td border="none" p="2" textAlign="right">
									<Stack spacing={-1}>
										<Text
											textTransform="uppercase"
											isTruncated
											textAlign="right"
											color={
												proposedIncreaseTotalsArr.color
											}
										>
											{proposedIncreaseTotalsArr.symbol +
												formatValue(
													proposedIncreaseTotalsArr.pctIncrease,
													2,
													"percent"
												)}
										</Text>
										{showRates &&
											proposedIncreaseTotalsArr.increase !==
												0 && (
												<Text
													isTruncated
													fontSize="xs"
													color={
														proposedIncreaseTotalsArr.color
													}
												>
													{proposedIncreaseTotalsArr.symbol +
														formatValue(
															proposedIncreaseTotalsArr.increase,
															0,
															"currency"
														)}
												</Text>
											)}
									</Stack>
								</Td>
							)}
							{salaryTab && (
								<Td border="none" p="2">
									<Text
										textTransform="uppercase"
										isTruncated
										textAlign="right"
									>
										{formatValue(
											historyTotals.bonusYE,
											0,
											"currency"
										)}
									</Text>
								</Td>
							)}
							{salaryTab && (
								<Td border="none" p="2">
									<Text
										textTransform="uppercase"
										isTruncated
										textAlign="right"
									>
										{formatValue(
											historyTotals.proposedBonus,
											0,
											"currency"
										)}
									</Text>
								</Td>
							)}
							{salaryTab && (
								<Td border="none" p="2">
									<Text
										textTransform="uppercase"
										isTruncated
										textAlign="right"
									>
										{formatValue(
											historyTotals.proposedCell,
											0,
											"currency"
										)}
									</Text>
								</Td>
							)}
							{salaryTab && (
								<Td border="none" p="2">
									<Text
										textTransform="uppercase"
										isTruncated
										textAlign="right"
									>
										{formatValue(
											historyTotals.proposedVehicle,
											0,
											"currency"
										)}
									</Text>
								</Td>
							)}
							{salaryTab && (
								<Td border="none" p="2">
									<Text
										textTransform="uppercase"
										isTruncated
										textAlign="right"
									>
										{formatValue(
											historyTotals.proposedHousing,
											0,
											"currency"
										)}
									</Text>
								</Td>
							)}
							{salaryTab && (
								<Td border="none" p="2">
									<Text
										textTransform="uppercase"
										isTruncated
										textAlign="right"
									>
										{formatValue(
											historyTotals.totalYearlyComp,
											0,
											"currency"
										)}
									</Text>
								</Td>
							)}
							<Td border="none" p="2">
								<Text
									textTransform="uppercase"
									isTruncated
								></Text>
							</Td>
						</Tr>
					</Tfoot>
				</Table>
			</Box>
		</Skeleton>
	);
}
