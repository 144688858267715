const NUMERATOR = [
	{
		title: "Actual + Commited Cost",
		value: "actualAndCommitedCost",
		tooltip: "Total of All Costs Billed or not Billed",
	},
	{ title: "Actual Cost", value: "actualCost", tooltip: "Total of All Billed Costs" },
];

export default NUMERATOR;
