import React from "react";
import { Stack, Container } from "@chakra-ui/react";

import UserProfileCard from "../../Employee/components/EmployeeProfileHeader";
import AnnouncementsCard from "../components/AnnouncementsCard";
import SocialMediaCard from "../components/SocialMediaCard";
import CelebrationsCard from "../components/CelebrationsCard";
import ReferralsCard from "../components/ReferralsCard";
import WeekAheadCard from "../components/WeekAheadCard";
import UpcomingEventsCard from "../components/UpcomingEventsCard";

import { portalUser } from "../../../App";

export default function HomePage(props) {
	return (
		<Stack
			spacing={portalUser.styles?.pageSpacing ?? 8}
			p={portalUser.styles?.pagePadding ?? 8}
			bg={portalUser.styles?.pageBG ?? "gray.200"}
			w="full"
			flex={1}
			align="center"
		>
			<UserProfileCard />

			<Container maxW={{ base: "full", xl: "8xl" }} w="full" px={0}>
				<Stack
					spacing={portalUser.styles?.pageSpacing ?? 8}
					flex={1}
					direction={{ base: "column", lg: "row" }}
					w="full"
				>
					<Stack spacing={portalUser.styles?.pageSpacing ?? 8} flex={1} w="full">
						<AnnouncementsCard />
						<SocialMediaCard />
						<CelebrationsCard />
					</Stack>

					<Stack spacing={portalUser.styles?.pageSpacing ?? 8} flex={1} w="full">
						<WeekAheadCard />
						<UpcomingEventsCard />
						<ReferralsCard />
					</Stack>
				</Stack>
			</Container>
		</Stack>
	);
}
