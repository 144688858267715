import { portalUser } from "../../../App";
import { formatValue } from "../../../helperFunctions";
import moment from "moment";

export default class PayPeriod {
	constructor(payPeriod) {
		if (payPeriod instanceof PayPeriod) {
			Object.assign(this, payPeriod);
		} else {
			this.queryData = payPeriod?.queryData ?? "PayPeriod";
			this.prEndDate = formatValue(payPeriod?.prEndDate ?? portalUser.user?.prEndDate, 0, "date") ?? null;
			this.prco = payPeriod?.prco?.toString() ?? null;
			this.prGroup = payPeriod?.prGroup?.toString() ?? null;
			this.status = payPeriod?.status?.toString() ?? null;
			this.startDate = formatValue(payPeriod?.startDate, 0, "date") ?? null;
			this.endDate = formatValue(payPeriod?.endDate, 0, "date") ?? null;
			this.closeDate = formatValue(payPeriod?.closeDate, 0, "date") ?? null;
			this.isDefaultYN = payPeriod?.isDefaultYN ?? "N";
			this.isCurrentPeriod = payPeriod?.IsCurrentPeriod ?? 0;
			this.dates = this.getDates();
		}
	}

	getDates(prEndDate = this.prEndDate, days = 7) {
		var startDate = null;
		var endDate = null;
		var dates = [
			{ weekDay: "Sun", date: null },
			{ weekDay: "Mon", date: null },
			{ weekDay: "Tues", date: null },
			{ weekDay: "Wed", date: null },
			{ weekDay: "Thur", date: null },
			{ weekDay: "Fri", date: null },
			{ weekDay: "Sat", date: null },
		];
		prEndDate = formatValue(prEndDate, 0, "date") ?? null;

		if (Boolean(prEndDate)) {
			endDate = formatValue(prEndDate);
			startDate = moment(endDate, "M/D/YYYY").add(-days + 1, "days");

			dates = [];
			for (let i = 0; i < days; i++) {
				let date = moment(startDate, "M/D/YYYY").add(i, "days");
				dates.push({
					weekDay: date.format("ddd"),
					date: date.format("M/D"),
				});
			}
		}
		return dates;
	}
}
