import React, { useState, useMemo, useEffect } from "react";
import PCODrawer from "../components/PCODrawer";
import SectionObj from "../../../core/Reports/classes/Section";
import SectionTemplatePCOLog from "../templates/SectionTemplatePCOLog";
import SectionTemplateACOLog from "../templates/SectionTemplateACOLog";
import Section from "../../../core/Reports/components/Section";
import { portalUser } from "../../../App";

export default function ChangeOrderLogPage(props) {
	let pagebreak = props.pagebreak ?? 0;

	const contractData = useMemo(() => props.contractData, [props.contractData]);

	const [pcos, setPCOs] = useState(new SectionObj(new SectionTemplatePCOLog()));
	const [acos, setACOs] = useState(new SectionObj(new SectionTemplateACOLog()));
	const [selectedPCO, setSelectedPCO] = useState(null);
	const [updateTrigger, setUpdateTrigger] = useState(false);

	useEffect(() => {
		if (updateTrigger) {
			setUpdateTrigger(false);
		}
	}, [updateTrigger]);

	useMemo(() => {
		let contract = contractData?.find((d) => d.queryData === "Contract") ?? null;
		let pcos = contractData?.filter((d) => d.queryData === "PCO" && d.pcoStatus !== "APPD") ?? [];

		let isLoaded = Boolean(Boolean(pcos?.length > 0) || (Boolean(contract) && contract?.pcoAmt === 0));

		let acos = contractData?.filter((d) => d.queryData === "ACO") ?? [];
		let coItems = contractData?.filter((d) => d.queryData === "COItem") ?? [];

		for (let i = 0; i < pcos?.length; i++) {
			let items = coItems.filter((d) => d.pmopKeyID === pcos[i].pmopKeyID) ?? [];
			pcos[i].coItems = items;
			pcos[i].getPCOItemTotals();
		}

		for (let i = 0; i < acos?.length; i++) {
			let items = coItems.filter((d) => d.pmohKeyID === acos[i].pmohKeyID) ?? [];
			acos[i].coItems = items;
			acos[i].getACOItemTotals();
		}

		if (Boolean(contract)) {
			contract.updateContract();
		}

		let pcoSection = new SectionObj(new SectionTemplatePCOLog(pcos, isLoaded));

		setPCOs(pcoSection);
		setACOs(new SectionObj(new SectionTemplateACOLog(acos, 0, isLoaded)));
	}, [contractData]);

	const newPCO = async () => {
		let pco = portalUser.tempData?.filter((d) => (d.queryData = "PCO")) ?? [];
		if (portalUser.tempData?.length === 0) {
			pco = await portalUser?.newPCO(props.selectedKeys[0]);
			portalUser.tempData = [pco];
		} else {
			pco = pco[0];
		}
		setSelectedPCO(pco);
	};

	const savePCO = async (pco) => {
		if (!Boolean(pco?.pmopKeyID)) {
			pco = await pco.insertPMOP();
			portalUser.contractData.push(pco);
			if (Boolean(pco?.coItems?.length > 0)) {
				portalUser.contractData.push(...pco.coItems);
			}
		}

		let contractData = portalUser?.getSelectedData(props.selectedKeys);
		let pcos = contractData?.filter((d) => d.queryData === "PCO" && d.pcoStatus !== "APPD") ?? [];
		let coItems = contractData?.filter((d) => d.queryData === "COItem") ?? [];
		for (let i = 0; i < pcos?.length; i++) {
			let items = coItems.filter((d) => d.pmopKeyID === pcos[i].pmopKeyID) ?? [];
			pcos[i].coItems = items;
			pcos[i].getPCOItemTotals();
		}
		setPCOs(new SectionObj(new SectionTemplatePCOLog(pcos, pagebreak, 26)));
		if (pco?.pmopKeyID === null) {
			portalUser.tempData = [pco];
		} else {
			portalUser.tempData = [];
		}
		setSelectedPCO(null);
	};

	const closePCO = () => {
		if (selectedPCO?.pmopKeyID === null) {
			portalUser.tempData = [selectedPCO];
		} else {
			portalUser.tempData = [];
		}
		setSelectedPCO(null);
	};

	const deletePCO = async (pco) => {
		if (Boolean(pco?.pmopKeyID)) {
			let contractData = portalUser?.contractData;
			portalUser.contractData = contractData?.filter((d) => d.pmopKeyID !== pco?.pmopKeyID) ?? [];
			await pco.deletePMOP();
		}

		let contractData = portalUser?.getSelectedData(props.selectedKeys);
		let pcos = contractData?.filter((d) => d.queryData === "PCO" && d.pcoStatus !== "APPD") ?? [];
		let coItems = contractData?.filter((d) => d.queryData === "COItem") ?? [];
		for (let i = 0; i < pcos?.length; i++) {
			let items = coItems.filter((d) => d.pmopKeyID === pcos[i].pmopKeyID) ?? [];
			pcos[i].coItems = items;
			pcos[i].getPCOItemTotals();
		}

		setPCOs(new SectionObj(new SectionTemplatePCOLog(pcos, pagebreak, 26)));
		setSelectedPCO(null);
	};

	return (
		<>
			{Boolean(selectedPCO) && (
				<PCODrawer
					selectedPCO={selectedPCO}
					isOpen={Boolean(selectedPCO)}
					closePCO={closePCO}
					savePCO={savePCO}
					deletePCO={deletePCO}
				/>
			)}
			{pagebreak === 0 && (
				<Section
					section={pcos}
					selectedRow={selectedPCO}
					setSelectedRow={setSelectedPCO}
					selectedKeys={props.selectedKeys}
					newPCO={newPCO}
				/>
			)}
			{pagebreak === 0 && <Section section={acos} />}
		</>
	);
}
