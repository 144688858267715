import * as React from "react";
import { chakra } from "@chakra-ui/react";
// import { chakra, useToken } from "@chakra-ui/react";

export default function MAC5Logo(props) {
	const { logoColor = "currentColor", ...rest } = props;
	// const color = useToken('colors', logoColor)
	return (
		<chakra.svg viewBox="0 0 1500.01 156.83" fill="none" height="10" {...rest}>
			<g id="MAC5Logo">
				<g id="MAC5" fill={logoColor}>
					<path d="M202.94,154H170.63c-1.43,0-2.86-.05-4.29-.08,0-1.36.1-2.72.1-4.08,0-24.72.08-49.45-.05-74.17a44.84,44.84,0,0,0-4-19.23C158.34,47.76,151.7,42,142.67,39.46c-13.17-3.62-25.58-2-36.27,7.32A33.15,33.15,0,0,0,96.23,63.89c-.76,2.93-1.2,5.94-1.86,9.27h8.23c4.19,0,8.38-.11,12.57.06,1.94.08,2.68-.71,3.16-2.42,2.5-8.86,12.57-11.07,18.68-8,3.2,1.6,4.48,4.45,4.83,7.63.44,4,.4,8,.54,12.08.05,1.45,0,2.9,0,4.59-3-.19-5.72-.44-8.48-.53-8.72-.27-17.32.36-25.27,4.43-6.78,3.47-10.49,9.35-12.48,16.49a68.88,68.88,0,0,0-1.69,25.58c.86,8.31,3.91,16.09,12.07,20.93H78.43V2.4H308.68V22.84h-4.79q-16.11,0-32.22,0c-2.93,0-3,.08-3,2.89q0,16.67,0,33.33c0,1.31.22,2.61.37,4.23.8-.56,1.41-.91,1.93-1.35,5.5-4.71,12-5.43,18.85-4.72C300.3,58.3,307.46,67.09,308.34,77c.2,2.18.42,4.38.43,6.56,0,13.76.08,27.52,0,41.28a30.37,30.37,0,0,1-9.68,22.81c-5.16,4.84-11.29,7.78-18.64,7.66-4.24-.07-8.42,0-12.49-1.64-6.7-2.66-11.52-7.36-15.7-13.33v13.71H235.31c2-1.29,3.71-2.31,5.34-3.49,8.1-5.83,12.32-13.9,13.61-23.66q.11-.87.27-1.74c.48-2.4-.19-3.39-2.59-3.41-6.18-.06-12.36,0-18.54,0-2,0-2.81.9-3.29,2.92-1,4.15-2.59,7.85-7.68,8.79-6.2,1.15-12.36-1.81-13.77-7.16a11.57,11.57,0,0,1-.27-2.83q0-22.85,0-45.69a48.75,48.75,0,0,1,.28-7.84,10.08,10.08,0,0,1,11.81-8.29c6.58,1,8.59,3,9.8,9.72.23,1.23.49,2.44.8,4h23.08c.85-3.45-.06-6.82-.74-10.13a34.6,34.6,0,0,0-10.3-18.77,31.32,31.32,0,0,0-16.34-7.93c-10.87-1.92-21.06-.28-29.91,6.73a30,30,0,0,0-11.68,21.16c-1.86,18-.55,36.15-.81,54.23a40.68,40.68,0,0,0,3.55,18.22,30.79,30.79,0,0,0,12.63,13.69C201.27,152.92,202,153.37,202.94,154Zm65.55-37.32c.24,3.19.24,6.13.71,9,.95,5.88,5.54,8.67,10.51,8.52,5.33-.16,8.88-4.18,8.91-9.94.07-12.94,0-25.88,0-38.82a9.46,9.46,0,0,0-4-7.7,9.61,9.61,0,0,0-14.45,3.69c-1.09,2.3-2.37,3-4.81,2.89-4.3-.21-8.62-.06-13-.06v32.42Z" />
					<path d="M.82,62.63C3.58,71,6.36,79.33,9.1,87.69Q14.26,103.35,19.36,119c.77,2.37,1.61,4.73,2.25,7.14a2.29,2.29,0,0,0,2.64,2c2.57,0,5.14,0,7.71.06,1.32.05,1.76-.68,2.11-1.76,2.7-8.14,5.45-16.26,8.16-24.39q6-18.22,12.06-36.45A8.49,8.49,0,0,1,55.54,63V154.2c-1.27-.07-2.62-.22-4-.22q-24,0-48.1,0c-3,0-3,.05-3-3V62.64Z" />
					<path d="M28.07,82.94C18.57,55.6,9.3,29,0,2.21H55.89C46.65,29,37.47,55.67,28.07,82.94Z" />
					<path d="M142.57,114.86c-.22,3.79-.38,7.59-.66,11.38-.39,5.24-5.12,9.1-10.25,9.72-11,1.35-14.5-8.63-13.57-16.87a20.05,20.05,0,0,1,1.67-6.47c2.2-4.49,6.49-6.23,11.08-6.91,3.23-.47,6.57-.2,9.86-.27,1.32,0,1.75.61,1.71,1.91-.09,2.5,0,5,0,7.5Z" />
					<path d="M142.22,154h-8.7l-.41-.43c1.58-1.45,3.24-2.82,4.74-4.36s2.77-3.2,4.37-5.07Z" />
				</g>
				<g id="Construction" fill={logoColor}>
					<path d="M383.59,45.23H361.37c0-2.24,0-4.48,0-6.72a19.3,19.3,0,0,0-2.9-10.44c-3.79-5.75-8.38-6.8-14.53-5.69-5.74,1-7.94,4.16-9.22,10.65a53.72,53.72,0,0,0-.77,10.07q-.09,37.63,0,75.26a27.39,27.39,0,0,0,1.14,7.58c2.24,7.47,7.59,9.42,14.36,8.46,7.49-1.07,11.75-6.47,11.9-14.32,0-2.84,0-5.69,0-8.55H383c1.44,14.2-.82,27-12.17,36.74-6.13,5.24-13.13,8.38-21.45,8.39-6.24,0-12.37-.53-18.09-3-11.49-5-17.4-14.21-18.91-26.39a76,76,0,0,1-.29-9.43q0-21,0-41.94c0-13.89-.16-27.79.05-41.69.21-14.47,6.85-25.08,20.06-31.25C336.92.75,342,.13,347.2,0a34.92,34.92,0,0,1,20.06,5.26c8.6,5.43,13.5,13.44,15.25,23.22C383.48,33.91,383.26,39.55,383.59,45.23Z" />
					<path d="M1074.23,45.86H1096v9.75c3.24-2.88,6.07-6,9.49-8.31,10.79-7.19,27.1-2.1,31.83,9.94a33.46,33.46,0,0,1,2.4,11.52c.25,27.21.21,54.43.25,81.64,0,1.52-.15,3-.24,4.72H1118.1V152.5q0-36.08,0-72.16a22.68,22.68,0,0,0-.82-6.08c-2.46-8.78-11.51-9.18-16.45-6.16-3,1.86-3.83,5.11-4.35,8.31a43,43,0,0,0-.42,6.81q0,32.66,0,65.32v6.6h-21.77Z" />
					<path d="M502.44,45.79v9.47c2.2-2,4.5-4,6.75-6,5.58-5.1,12.25-5.57,19.21-4.43,11,1.8,16.6,11.22,17.39,20.27.24,2.77.39,5.55.39,8.33q0,37.85,0,75.7v6.06H524.42c0-.83-.13-1.75-.13-2.67q0-36.52,0-73c0-4-.83-7.83-4-10.56-3.62-3.09-7.83-3.05-12-1.35-3.91,1.58-5.07,5.22-5.46,8.91a134.81,134.81,0,0,0-.65,14.07c-.07,19.42,0,38.85,0,58.27v6.46H480.37V49.47c0-3.68,0-3.68,3.73-3.68Z" />
					<path d="M1058,99.34c-.29,10.15-.23,20.09-1,30-1.13,15.48-13.55,26.78-29.11,27.29-7.53.24-14.83-.36-21.37-4.55-7.34-4.71-12.23-11.16-13.35-20a120.28,120.28,0,0,1-1.09-13.8q-.23-18.64,0-37.3a76.79,76.79,0,0,1,1.25-13.11c1.9-10.1,8-17.16,17.36-20.92,10.23-4.11,20.71-4,30.69,1.15,9.4,4.82,14.71,12.82,15.56,23.27C1057.75,80.72,1057.7,90.15,1058,99.34Zm-22.54,1.31h0c0-8.09.21-16.18-.06-24.27-.24-7.25-6.37-11.37-14-9.3-4.69,1.27-7.12,5-7.2,10.62-.09,6.47,0,13,0,19.42,0,9-.11,18.11.11,27.15.15,6.37,4.42,10.29,10.58,10.28s10.33-3.79,10.52-10.07C1035.64,116.55,1035.46,108.6,1035.46,100.65Z" />
					<path d="M811.06,155.13H789.17V144.74c-1.62,1.89-2.89,3.45-4.23,4.94-5.77,6.39-13.08,7.87-21.25,6.72-10.73-1.52-16.24-10-17.77-18.73a48.32,48.32,0,0,1-.58-8.34q-.06-39.83,0-79.67c0-3.87,0-3.87,3.94-3.87,5.15,0,10.3.05,15.45,0,1.79,0,2.56.33,2.55,2.42q-.11,36.09,0,72.17a26.25,26.25,0,0,0,.6,5.67c1.35,5.89,5.07,8.71,11,8.58,5.38-.12,8.66-3,9.71-8.9a49.74,49.74,0,0,0,.63-8.54q.07-33.33,0-66.65V45.79H794c5.15,0,10.3,0,15.44,0,1.28,0,1.81.3,1.8,1.73q-.11,51.3-.14,102.6Z" />
					<path d="M430.1,156.66c-7.58.5-15.56-2.18-22.21-8.48a26.58,26.58,0,0,1-8.62-17.34c-.51-6-.8-12-.83-18-.06-12.42,0-24.84.23-37.25C399,62.43,404.45,51.94,417,47c13.25-5.17,26.28-4.14,37.47,5.8a25.3,25.3,0,0,1,8.84,18.39c.51,17.4.58,34.81.59,52.22,0,5.64-.59,11.33-3.31,16.47C454.43,151.53,444.54,156.73,430.1,156.66Zm11.74-56h0c0-8.09.2-16.18-.07-24.26C441.52,69,435.22,65,427.82,67c-4.57,1.23-7.1,4.73-7.26,9.87s-.09,10.15-.08,15.22c0,10.73-.11,21.47.11,32.2.14,6.4,4.41,10.29,10.56,10.28,6.31,0,10.42-3.67,10.63-10.15C442,116.49,441.84,108.54,441.84,100.61Z" />
					<path d="M625.53,75.93H604.85c-.21-.85-.44-1.75-.65-2.65-1.27-5.67-7.05-9.08-13-8.08-5.34.89-9.29,6.48-8.45,12.53a10.13,10.13,0,0,0,6.67,8.55C595,88.55,600.5,91,606.1,93.11c13.81,5.31,20.06,15.67,20.36,30.1.3,14.19-7.85,26.81-21,31.55-12.93,4.65-29.32,1.6-38.15-10.38-4.26-5.78-6.88-14-6.32-20.32h20.29c.72,2,1.3,3.93,2.15,5.77a11,11,0,0,0,14.27,5.52c4.82-2,7.85-5.37,8.06-10.76.22-5.54-2.25-9.75-7.21-12.1-4.22-2-8.67-3.53-13.06-5.16-7.08-2.62-13.53-6.19-18-12.52C560.59,84.87,560,74,564.59,63.18c8-19,31.56-23.57,46.28-14.34C620.76,55,624.88,64.37,625.53,75.93Z" />
					<path d="M896.18,79.11H874.66a59.62,59.62,0,0,0-1.59-6.55c-1.71-4.67-5.81-6.3-11.11-6a9.88,9.88,0,0,0-8.78,7.79,35,35,0,0,0-.59,6.13c-.05,13.31,0,26.63,0,40,0,1.46.14,2.93.15,4.4,0,6.75,6.07,10.83,12.69,9.66,5.5-1,7.36-3.17,8.56-8.89.13-.64,1-1.61,1.51-1.62,6.83-.11,13.65-.08,20.95-.08-.35,3.12-.36,6.08-1,8.87-3.69,15.21-17,24.1-31.12,23.94-5.75-.06-11.31-.52-16.45-3.15-9.47-4.86-15.52-12.5-16.31-23.22-.76-10.23-.6-20.54-.73-30.81-.11-7.85-.35-15.72.05-23.55.34-6.75,1.51-13.43,5.8-19.09,6.15-8.09,14.4-12,24.42-12.52,9-.45,17.43,1.4,24.37,7.56s10,14.46,10.75,23.63C896.26,76.62,896.18,77.72,896.18,79.11Z" />
					<path d="M909.57,63.07H898.78V45.72h10.81V12.44h22.1V45.69H945.1V62.93H931.8c-.1,1.72-.24,3.09-.24,4.46q0,27.81,0,55.61v.22c0,8.89,2.46,11.32,11.4,11.38h2.25v20.6c-8.88,0-17.79,1.26-25.58-4.35-7-5-9.78-12.14-9.82-20.41-.11-21.19,0-42.37,0-63.56C909.75,65.72,909.65,64.56,909.57,63.07Z" />
					<path d="M640.62,63H629.8V45.79c2.74,0,5.36,0,8,0,3,0,3,0,3-2.89q0-13.68,0-27.36v-3h21.93v33.3c4.05,0,7.72.11,11.38,0,1.9-.08,2.35.6,2.31,2.38-.11,4.85,0,9.69,0,14.83H662.89c-.09,1.36-.23,2.43-.23,3.51q0,28.35,0,56.71a27.23,27.23,0,0,0,.67,5.9,6.1,6.1,0,0,0,5.4,5.07c2.46.33,4.94.44,7.58.66,0,6.42,0,12.8-.05,19.19,0,.46-.65,1.34-1,1.32-7-.33-14.27.82-21-2.45-8.84-4.3-13.15-11.61-13.27-21.12-.29-22-.21-44-.28-66C640.75,65,640.68,64.19,640.62,63Z" />
					<path d="M685.05,45.81c6.93,0,13.75,0,20.56.08.45,0,1.22,1.09,1.25,1.69.13,3,.06,6,.06,9,4.56-3,8.91-6.22,13.55-8.91s10-3.46,15.59-3.24V67.62a33.44,33.44,0,0,0-3.82-.91c-11.42-1.38-22.86,5.23-24.94,18.48a53.71,53.71,0,0,0-.48,8.13q-.06,29.12,0,58.24c0,3.71,0,3.71-3.78,3.71h-18Z" />
					<path d="M975.7,155.23H953.9v-4.09q0-50.5,0-101c0-4.33,0-4.33,4.32-4.33,5.22,0,10.44.05,15.66,0,1.55,0,2,.47,2,2q-.18,9.46-.18,18.95,0,42.14,0,84.26Z" />
					<path d="M975.71,1.43V23.26H953.94V1.43Z" />
				</g>
			</g>
		</chakra.svg>
	);
}
