import { getSubtotal, getRate } from "../../../helperFunctions";

export default class SectionTemplateLaborDrilldown {
	constructor(jobCosts = [], pagebreak = 0, maxRows = 22, isLoaded = false) {
		this.template = "JobCostDrilldown";
		this.name = "Job Cost Drilldown";
		this.heading = "Labor Costs and Hours";
		this.divider = false;
		this.pagebreak = pagebreak;
		this.wrap = true;
		this.type = "virtualTable";
		this.minRows = 1;
		this.maxRows = maxRows;
		this.subSections = [new SubSectionTemplateLaborDrilldown(jobCosts)];
		this.isLoaded = isLoaded;
	}
}

class SubSectionTemplateLaborDrilldown {
	constructor(jobCosts = []) {
		this.template = "Labor";
		this.type = "virtualTable";
		this.show = jobCosts?.length > 0 ? true : false;
		this.autoExpandRows = true;
		this.columns = [
			{
				accessor: "jccmKeyID",
				Header: "Key ID",
				show: false,
				disableExport: true,
			},
			{
				accessor: "jcco",
				Header: "JCCo",
				show: false,
			},
			{
				accessor: "contract",
				Header: "Contract",
				show: false,
			},
			{
				accessor: "phase",
				Header: "Phase",
				placeHolderID: "jobPhase",
				show: false,
			},
			{
				accessor: "phaseDesc",
				Header: "Description",
				aggregate: "string",
				width: "300%",
				placeHolderID: "jobPhaseDesc",
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
				show: false,
			},
			{
				accessor: "jobPhase",
				Header: "Job Phase",
				width: "150%",
			},
			{
				accessor: "jobPhaseDesc",
				Header: "Description",
				width: "300%",
			},
			{
				accessor: "costType",
				Header: "Cost Type",
				width: "85%",
				valueType: "integer",
				textAlign: "center",
				filter: "multiselect",

				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},
			{
				accessor: "costTypeDesc",
				Header: "Cost Type Description",
				show: false,
			},
			{
				accessor: "origEstHours",
				Header: "Orig Budget Hours",
				textAlign: "right",
				valueType: "number",
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},

			{
				accessor: "currEstHours",
				Header: "Curr Budget Hours",
				textAlign: "right",
				valueType: "number",
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},

			{
				accessor: "actualHours",
				Header: "Actual Hours",
				textAlign: "right",
				valueType: "number",
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},
			{
				accessor: "remainHours",
				Header: "Hours To Complete",
				textAlign: "right",
				valueType: "number",
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},
			{
				accessor: "projHours",
				Header: "Projected Hours",
				textAlign: "right",
				valueType: "number",
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},
			{
				accessor: "origEstCost",
				Header: "Orig Budget Cost",
				textAlign: "right",
				valueType: "currency",
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},
			{
				accessor: "currEstCost",
				Header: "Curr Budget Cost",
				textAlign: "right",
				valueType: "currency",
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},
			{
				accessor: "actualCost",
				Header: "Actual Cost",
				textAlign: "right",
				valueType: "currency",

				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},

			{
				accessor: "remainCost",
				Header: "Cost To Complete",
				textAlign: "right",
				valueType: "currency",
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},

			{
				accessor: "projCost",
				Header: "Projected Cost",
				textAlign: "right",
				valueType: "currency",
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},
		];
		let sortGroupFilter = this.getSortGroupFilter(jobCosts);

		this.loopBy = sortGroupFilter.loopBy;
		this.groupBy = sortGroupFilter.groupBy;
		this.filterBy = sortGroupFilter.filterBy;
		this.sortBy = sortGroupFilter.sortBy;
		this.data = jobCosts;
		this.footer = this.getFooter(jobCosts);
	}

	getSortGroupFilter(jobCosts = []) {
		let loopBy = [
			// { id: "costType", heading: "costTypeDesc" },
			// { id: "basePhase", heading: "basePhaseDesc" },
		];
		let groupBy = [];
		// let groupBy = ["basePhase"];
		let sortBy = [
			{ id: "costType", desc: false },
			{ id: "jobPhase", desc: false },
		];
		let filterBy = [];

		let keys = [...new Set(jobCosts?.map((data) => data.jccmKeyID))];
		if (keys.length > 1) {
			groupBy = ["jccmKeyID"];
			sortBy = [
				{ id: "co", desc: false },
				{ id: "contract", desc: false },
				{ id: "costType", desc: false },
				{ id: "jobPhase", desc: false },
			];
		}
		let sortGroupFilter = { loopBy: loopBy, sortBy: sortBy, groupBy: groupBy, filterBy: filterBy };
		return sortGroupFilter;
	}

	getFooter(rows = []) {
		let footer = {
			costTypeType: "string",
			costType: "Total",
			costTypeTextAlign: "center",

			origEstHours: Boolean(rows.length > 0) ? getSubtotal(rows, "origEstHours") : 0,
			origEstCost: Boolean(rows.length > 0) ? getSubtotal(rows, "origEstCost") : 0,
			currEstHours: Boolean(rows.length > 0) ? getSubtotal(rows, "currEstHours") : 0,
			currEstCost: Boolean(rows.length > 0) ? getSubtotal(rows, "currEstCost") : 0,
			actualHours: Boolean(rows.length > 0) ? getSubtotal(rows, "actualHours") : 0,
			actualCost: Boolean(rows.length > 0) ? getSubtotal(rows, "actualCost") : 0,
			projHours: Boolean(rows.length > 0) ? getSubtotal(rows, "projHours") : 0,
			projCost: Boolean(rows.length > 0) ? getSubtotal(rows, "projCost") : 0,
			remainHours: Boolean(rows.length > 0)
				? getSubtotal(rows, "projHours") - getSubtotal(rows, "actualHours")
				: 0,
			remainCost: Boolean(rows.length > 0) ? getSubtotal(rows, "projCost") - getSubtotal(rows, "actualCost") : 0,
		};

		return [footer];
	}
}
