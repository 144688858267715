import React, { useMemo } from "react";
import { Flex } from "@chakra-ui/react";
// import TableBodyCell from "../TableCells/TableBodyCell";
// import FormInputCell from "../TableCells/FormInputCell";
import TableCell from "./TableCell";
import { convertArrayToMap } from "../../../helperFunctions";

const rowStyles = [
	{
		rowType: "TableBodyRow",
		bg: "transparent",
		color: "gray.500",
		borderColor: "gray.400",
		borderTopColor: "gray.400",
		borderBottomColor: "transparent",
		borderWidth: 1,
		borderTopWidth: 1,
		borderBottomWidth: 0,
		borderTopRadius: 0,
		borderBottomRadius: 0,
	},
];
const rowStylesMap = convertArrayToMap(rowStyles, "rowType");

export default function TableBodyRow({ row, style, setSelectedRow }) {
	// TODO: add logic for grouped rows
	const rowType = useMemo(() => {
		// log("row", row);
		if (!Boolean(row?.canExpand)) {
			return "TableBodyRow";
		} else {
			return "TableBodyRow";
		}
	}, [row]);

	const rowStyle = useMemo(
		() => (Boolean(rowStylesMap[rowType]) ? rowStylesMap[rowType] : rowStylesMap["TableBodyRow"]),
		[rowType]
	);
	const cells = useMemo(() => row?.cells ?? [], [row?.cells]);
	const listStyle = useMemo(() => style ?? {}, [style]); //important for VariableSizeList

	return (
		<Flex
			{...row.getRowProps()}
			style={listStyle}
			onClick={Boolean(setSelectedRow) ? () => setSelectedRow(row.original) : null}
			cursor={Boolean(setSelectedRow) && "pointer"}
			direction="row"
			flex={1}
			w="full"
			align="center"
			isTruncated
			_hover={{ bg: "gray.50" }}
			borderStyle="inset"
			bg={rowStyle?.bg}
			color={rowStyle?.color}
			borderColor={rowStyle?.borderColor}
			borderTopColor={rowStyle?.borderTopColor}
			borderBottomColor={rowStyle?.borderBottomColor}
			borderWidth={rowStyle?.borderWidth}
			borderTopWidth={rowStyle?.borderTopWidth}
			borderBottomWidth={rowStyle?.borderBottomWidth}
			borderTopRadius={rowStyle?.borderTopRadius}
			borderBottomRadius={rowStyle?.borderBottomRadius}
		>
			{cells?.length > 0 &&
				cells?.map((cell, i) => <TableCell key={i} rowType={rowType} column={cell?.column} cell={cell} />)}
		</Flex>
	);
}
