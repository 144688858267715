import React, { useMemo } from "react";
import { Text, Heading, Tr, Td } from "@chakra-ui/react";

import { formatNumber, formatValue } from "../../../helperFunctions";

export default function ProjectAnalysisCRSSummary(props) {
	const contractStatus = useMemo(() => props.contractStatus, [props.contractStatus]);

	return (
		<>
			<Tr>
				<Td
					px="2"
					py="1"
					borderLeft="2px"
					borderBottomColor={"blackAlpha.200"}
					borderBottomWidth="1px"
					borderTop="2px"
					borderTopColor="black"
				>
					<Text fontSize={"sm"}>Committed Cost</Text>
				</Td>
				<Td
					px="2"
					py="1"
					borderRight="2px"
					borderBottomColor={"blackAlpha.200"}
					borderBottomWidth="1px"
					borderTop="2px"
					borderTopColor="black"
				>
					<Text textAlign="right">{formatNumber(contractStatus?.remainCmtdCost ?? 0, 0, "currency")}</Text>
				</Td>
				<Td colSpan={2} border="0px"></Td>
				<Td
					borderColor="black"
					borderTopWidth="2px"
					borderBottomWidth="2px"
					borderLeftWidth="2px"
					bg={props.isPrinting ? "white" : "teal.700"}
					textTransform="uppercase"
					colSpan={2}
				>
					<Heading fontSize="xs" color={"white"} p="2" textAlign="left">
						Wage Type
					</Heading>
				</Td>
				<Td
					borderColor="black"
					borderTopWidth="2px"
					borderBottomWidth="2px"
					bg={props.isPrinting ? "white" : "teal.700"}
					textTransform="uppercase"
				>
					{/* <Heading fontSize="xs" color={"white"} p="2" textAlign="right">
						Rate
					</Heading> */}
				</Td>
				<Td
					borderColor="black"
					borderTopWidth="2px"
					borderBottomWidth="2px"
					borderRightWidth="2px"
					bg={props.isPrinting ? "white" : "teal.700"}
					textTransform="uppercase"
				>
					<Heading fontSize="xs" color={"white"} p="2" textAlign="right">
						Rate
					</Heading>
				</Td>
			</Tr>
			<Tr>
				<Td px="2" py="1" borderLeft="2px" borderBottomColor="black" borderBottomWidth="2px">
					<Text fontSize={"sm"}>Unapproved AP</Text>
				</Td>
				<Td px="2" py="1" borderRight="2px" borderBottomColor="black" borderBottomWidth="2px">
					<Text textAlign="right">{formatNumber(contractStatus?.unapprovedAPAmt ?? 0, 0, "currency")}</Text>
				</Td>
				<Td colSpan={2} border="0px"></Td>
				<Td
					px="2"
					py="1"
					borderLeft="2px"
					colSpan={2}
					borderBottomColor={"blackAlpha.200"}
					borderBottomWidth="1px"
				>
					<Text fontSize={"sm"}>Original Budgeted Rate</Text>
				</Td>
				<Td px="2" py="1" borderBottomColor={"blackAlpha.200"} borderBottomWidth="1px">
					<Text textAlign="right">
						{/* {formatValue(contractStatus?.getOrigEstRate() ?? 0, 2, "currency") + " / hr"} */}
					</Text>
				</Td>
				<Td px="2" py="1" borderRight="2px" borderBottomColor={"blackAlpha.200"} borderBottomWidth="1px">
					<Text textAlign="right">
						{formatValue(contractStatus?.getOrigEstRate() ?? 0, 2, "currency") + " / hr"}
					</Text>
				</Td>
			</Tr>
			<Tr>
				<Td colSpan={2} border="0px"></Td>
				<Td colSpan={2} border="0px"></Td>
				<Td
					px="2"
					py="1"
					borderLeft="2px"
					colSpan={2}
					borderBottomColor={"blackAlpha.200"}
					borderBottomWidth="1px"
				>
					<Text fontSize={"sm"}>Current Budgeted Rate</Text>
				</Td>
				<Td px="2" py="1" borderBottomColor={"blackAlpha.200"} borderBottomWidth="1px">
					<Text textAlign="right">
						{/* {formatValue(contractStatus?.getCurrEstRate() ?? 0, 2, "currency") + " / hr"} */}
					</Text>
				</Td>
				<Td px="2" py="1" borderRight="2px" borderBottomColor={"blackAlpha.200"} borderBottomWidth="1px">
					<Text textAlign="right">
						{formatValue(contractStatus?.getCurrEstRate() ?? 0, 2, "currency") + " / hr"}
					</Text>
				</Td>
			</Tr>
			<Tr>
				<Td colSpan={2} border="0px"></Td>
				<Td colSpan={2} border="0px"></Td>
				<Td
					px="2"
					py="1"
					borderLeft="2px"
					colSpan={2}
					borderBottomColor={"blackAlpha.200"}
					borderBottomWidth="1px"
				>
					<Text fontSize={"sm"}>Current Rate</Text>
				</Td>
				<Td px="2" py="1" borderBottomColor={"blackAlpha.200"} borderBottomWidth="1px">
					<Text textAlign="right">
						{/* {formatValue(contractStatus?.getActualRate() ?? 0, 2, "currency") + " / hr"} */}
					</Text>
				</Td>
				<Td px="2" py="1" borderRight="2px" borderBottomColor={"blackAlpha.200"} borderBottomWidth="1px">
					<Text textAlign="right">
						{formatValue(contractStatus?.getActualRate() ?? 0, 2, "currency") + " / hr"}
					</Text>
				</Td>
			</Tr>
			<Tr>
				<Td px="2" py="1"></Td>
				<Td px="2" py="1"></Td>
				<Td colSpan={2} border="0px"></Td>
				<Td
					px="2"
					py="1"
					borderLeft="2px"
					colSpan={2}
					borderBottomColor={"blackAlpha.200"}
					borderBottomWidth="1px"
				>
					<Text fontSize={"sm"}>Rate to Complete</Text>
				</Td>
				<Td px="2" py="1" borderBottomColor={"blackAlpha.200"} borderBottomWidth="1px">
					<Text textAlign="right">
						{/* {formatValue(contractStatus?.getRateToComplete() ?? 0, 2, "currency") + " / hr"} */}
					</Text>
				</Td>
				<Td px="2" py="1" borderRight="2px" borderBottomColor={"blackAlpha.200"} borderBottomWidth="1px">
					<Text textAlign="right">
						{formatValue(contractStatus?.getRateToComplete() ?? 0, 2, "currency") + " / hr"}
					</Text>
				</Td>
			</Tr>
			<Tr>
				<Td px="2" py="1"></Td>
				<Td px="2" py="1"></Td>
				<Td colSpan={2} border="0px"></Td>
				<Td
					px="2"
					py="1"
					borderLeft="2px"
					colSpan={2}
					borderBottomColor={"blackAlpha.200"}
					borderBottomWidth="1px"
				>
					<Text fontSize={"sm"}>Projected Rate</Text>
				</Td>
				<Td px="2" py="1" borderBottomColor={"blackAlpha.200"} borderBottomWidth="1px">
					<Text textAlign="right">
						{/* {formatValue(contractStatus?.getProjRate() ?? 0, 2, "currency") + " / hr"} */}
					</Text>
				</Td>
				<Td px="2" py="1" borderRight="2px" borderBottomColor={"blackAlpha.200"} borderBottomWidth="1px">
					<Text textAlign="right">
						{formatValue(contractStatus?.getProjRate() ?? 0, 2, "currency") + " / hr"}
					</Text>
				</Td>
			</Tr>
			<Tr>
				<Td px="2" py="1"></Td>
				<Td px="2" py="1"></Td>
				<Td colSpan={2} border="0px"></Td>
				<Td px="2" py="1" borderLeft="2px" borderBottomColor="black" borderBottomWidth="2px" colSpan={2}>
					<Text fontSize={"sm"}>Variance on Rate</Text>
				</Td>
				<Td px="2" py="1" borderBottomColor="black" borderBottomWidth="2px">
					<Text textAlign="right">
						{/* {formatValue(contractStatus?.getVarOnRateAmt(), 0, "currency")} */}
					</Text>
				</Td>
				<Td px="2" py="1" borderRight="2px" borderBottomColor="black" borderBottomWidth="2px">
					<Text textAlign="right">
						{formatValue(contractStatus?.getVarOnRateAmt(), 0, "currency") ?? "$0"}
					</Text>
				</Td>
			</Tr>
		</>
	);
}
