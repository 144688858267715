import { formatValue } from "../../../helperFunctions";

export default class TimecardRow {
	constructor(row) {
		if (row instanceof TimecardRow) {
			Object.assign(this, row);
		} else {
			this.queryData = row?.queryData ?? "TimecardHeader";

			this.keyID = row?.keyID?.toString() ?? null;
			this.prEndDate = formatValue(row?.prEndDate, 0, "date") ?? null;
			this.employeeUID = row?.employeeUID?.toLowerCase() ?? null;
			this.prco = row?.prco?.toString() ?? null;
			this.prEmployee = row?.prEmployee?.toString() ?? null;
			this.jcjmKeyID = row?.jcjmKeyID?.toString() ?? null;
			this.jcco = row?.jcco?.toString() ?? null;
			this.job = row?.job ?? null;
			this.phaseGroup = row?.phaseGroup?.toString() ?? null;
			this.phase = row?.phase ?? null;
			this.earnCode = row?.earnCode?.toString() ?? null;
			this.shift = row?.shift?.toString() ?? "1";
			this.day1 = row?.day1 ?? 0;
			this.day2 = row?.day2 ?? 0;
			this.day3 = row?.day3 ?? 0;
			this.day4 = row?.day4 ?? 0;
			this.day5 = row?.day5 ?? 0;
			this.day6 = row?.day6 ?? 0;
			this.day7 = row?.day7 ?? 0;
			this.day1Notes = row?.day1Notes ?? 0;
			this.day2Notes = row?.day2Notes ?? 0;
			this.day3Notes = row?.day3Notes ?? 0;
			this.day4Notes = row?.day4Notes ?? 0;
			this.day5Notes = row?.day5Notes ?? 0;
			this.day6Notes = row?.day6Notes ?? 0;
			this.day7Notes = row?.day7Notes ?? 0;
			this.paySeq = row?.paySeq?.toString() ?? null;
			this.hqbcKeyID = row?.hqbcKeyID?.toString() ?? null;
		}
	}
}
