import DevOpsAPI from "../../../APIs/DevOpsAPI";
import PowerBIEmbedLink from "./PowerBIEmbedLink";

export default class PowerBI {
	constructor(data) {
		if (data instanceof PowerBI) {
			Object.assign(this, data);
		} else {
			this.msUserID = data?.msUserID ?? null;
			this.devopsAPI = null;
			this.embedLinks = data?.embedLinks ?? [];
		}
	}

	async initPowerBI(msUserID = null) {
		let powerBI = this;

		if (Boolean(msUserID) && !Boolean(powerBI?.devopsAPI)) {
			powerBI.msUserID = msUserID;
			powerBI.devopsAPI = new DevOpsAPI();
			// let params = {};
			// let query = { msUserID: msUserID };
			let sqlData = await powerBI.devopsAPI.GetPowerBIEmbedLinks();
			if (sqlData.status === 200) {
				sqlData = sqlData?.value ?? [];
				powerBI = powerBI.classifyData(powerBI, sqlData);
			}
		}

		return powerBI;
	}

	classifyData(powerBI = this, sqlData = []) {
		for (let i = 0; i < sqlData?.length; i++) {
			let queryData = sqlData[i]?.queryData;
			if (queryData === "EmbedLink") {
				let embedLink = new PowerBIEmbedLink(sqlData[i]);
				powerBI.embedLinks.push(embedLink);
			}
		}
		return powerBI;
	}
}
