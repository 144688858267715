import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function EditorInput(props) {
	const [editorState, updateEditorState] = useState(null);
	const [contentState, updateContentState] = useState(null);

	const onEditorStateChange = (editorStateVal) => {
		updateEditorState(editorStateVal);
	};

	const onContentStateChange = (contentStateVal) => {
		updateContentState(contentStateVal);
		if (props.onChange) {
			let json = JSON.stringify(contentStateVal);
			props.onChange(json);
		}
	};

	const onBlur = () => {
		let json = JSON.stringify(contentState);
		if (props.onBlur) {
			props.onBlur(json);
		}
	};

	const toolbar = {
		options: ["inline", "textAlign", "link", "colorPicker", "fontFamily", "fontSize", "image"],
	};

	return (
		<Editor
			editorState={editorState}
			editorStyle={{
				minHeight: "150px",
				border: "1px solid",
				borderColor: "inherit",
				borderRadius: "var(--chakra-radii-md)",
				padding: "var(--chakra-space-2)",
				background: "var(--chakra-colors-whiteAlpha-900)",
			}}
			editorClassName="skinnyScroll"
			toolbarClassName="toolbarClassName"
			wrapperClassName="wrapperClassName"
			onEditorStateChange={onEditorStateChange}
			onContentStateChange={onContentStateChange}
			toolbar={toolbar}
			onBlur={onBlur}
			bg={"inherit"}
			readOnly={props.readOnly}
		/>
	);
}
