import React, { useState } from "react";
import { Box, HStack, Modal, ModalOverlay, ModalContent, IconButton, Button, Flex, Text } from "@chakra-ui/react";
import MainContent from "../../../legacy/App/components/MainContent";

export default function IFrame({ pageIcon, pageName, url, minH, variant }) {
	const [iframeMode, setIframeMode] = useState(variant);

	const onOpen = () => {
		setIframeMode("fullscreen");
	};

	const onClose = () => {
		setIframeMode(variant);
	};
	let bannerTitle = (
		<HStack align="center" spacing={2}>
			<i className={pageIcon}></i>
			{pageName}
		</HStack>
	);

	return (
		<>
			<Modal isOpen={Boolean(iframeMode === "fullscreen")} onClose={onClose} size="full" rounded={0}>
				<ModalOverlay />

				<ModalContent p={0}>
					{/* <IconButton
						size="md"
						variant="ghost"
						bg="whiteAlpha.500"
						position="absolute"
						color="gray.500"
						_hover={{ bg: "gray.200", color: "gray.400", borderWidth: 2, borderColor: "gray.500" }}
						right={1}
						top={1}
						p={0}
						onClick={onClose}
						icon={<i className={"fas fa-compress-arrows-alt fa-2x "}></i>}
					/> */}
					<Button
						_hover={{
							bg: "whiteAlpha.900",
							color: "gray.500",
							borderWidth: 2,
							borderColor: "gray.400",
						}}
						variant="outline"
						bg="whiteAlpha.700"
						right={4}
						top={4}
						onClick={onClose}
						position="absolute"
						color="gray.500"
					>
						<HStack textTransform="uppercase">
							<Text>
								<i className={"fas fa-compress-arrows-alt fa-fw "}></i>
							</Text>
							<Text>Exit</Text>
						</HStack>
					</Button>
					<iframe
						title="modal-Iframe"
						className="skinnyScroll"
						src={url}
						style={{
							height: "100%",
							width: "100%",

							display: "flex",
							flexDirection: "column",
							flexGrow: 1,
						}}
						seamless="seamless"
						allowFullScreen
					></iframe>
				</ModalContent>
			</Modal>

			{Boolean(variant === "simple") ? (
				<Box position="relative">
					<IconButton
						size="sm"
						variant="ghost"
						bg="blackAlpha.100"
						position="absolute"
						color="gray.400"
						borderWidth={1}
						_hover={{ bg: "gray.200", color: "gray.500", borderWidth: 2, borderColor: "gray.500" }}
						right={1}
						bottom={1}
						onClick={onOpen}
						p={0}
						icon={<i className={"fas fa-expand-arrows-alt fa-2x fa-fw "}></i>}
					/>
					<iframe
						title="simple-Iframe"
						className="skinnyScroll"
						src={url}
						width="100%"
						style={{ height: minH ?? "80vh" }}
						seamless="seamless"
						allowFullScreen
					></iframe>
				</Box>
			) : Boolean(variant === "powerApp") ? (
				<Flex
					position="relative"
					direction="row"
					flex={1}
					w="full"
					h="full"
					justify="flex-start"
					align="flex-start"
					// transform="translate(0px, 0px) scale(1.15)"
					overflow="auto"
				>
					<iframe
						title="simple-Iframe"
						className="skinnyScroll"
						src={url}
						width="100%"
						style={{
							height: "100%",
							width: "100%",
							minHeight: "700px",

							display: "flex",
							alignItems: "flex-start",
							flexDirection: "column",
							flexGrow: 1,
						}}
						seamless="seamless"
						allowFullScreen
					></iframe>
					<Button
						_hover={{
							bg: "whiteAlpha.900",
							color: "gray.500",
							borderWidth: 2,
							borderColor: "gray.400",
						}}
						variant="outline"
						bg="whiteAlpha.700"
						right={4}
						top={4}
						onClick={onOpen}
						position="absolute"
						color="gray.500"
					>
						<HStack textTransform="uppercase">
							<Text>
								<i className={"fas fa-expand-arrows-alt fa-fw "}></i>
							</Text>
							<Text>Fullscreen</Text>
						</HStack>
					</Button>
				</Flex>
			) : (
				<MainContent title={bannerTitle}>
					<Box style={{ height: "93vh" }}>
						<iframe
							title="withBanner-Iframe"
							className="skinnyScroll"
							src={url}
							width="100%"
							style={{ height: "95vh" }}
							seamless="seamless"
							allowFullScreen
						></iframe>
					</Box>
				</MainContent>
			)}
		</>
	);
}
