import React, { useState, useEffect, Fragment } from "react";
import {
	Box,
	Flex,
	Container,
	Stack,
	Heading,
	Text,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Tfoot,
	HStack,
} from "@chakra-ui/react";
import PPMLogo from "../../../core/Logos/components/PPMLogo";
import { portalUser } from "../../../App";
export default function PurchaseOrderPage() {
	const [rows, setRows] = useState([]);

	useEffect(() => {
		const row = (
			<Tr>
				<Td px="2" borderRight="1px" borderTop="1px" borderColor="gray.400"></Td>
				<Td px="2" borderRight="1px" borderTop="1px" borderColor="gray.400"></Td>
				<Td px="2" borderRight="1px" borderTop="1px" borderColor="gray.400"></Td>
				<Td px="2" borderRight="1px" borderTop="1px" borderColor="gray.400"></Td>
				<Td px="2" borderRight="1px" borderTop="1px" borderColor="gray.400"></Td>
				<Td px="2" borderTop="1px" borderColor="gray.400">
					<Text>$</Text>
				</Td>
			</Tr>
		);
		for (let i = 0; i < 24; i++) {
			setRows((rows) => [...rows, row]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Stack
			spacing={portalUser.styles?.pageSpacing}
			p={portalUser.styles?.pagePadding}
			bg={portalUser.styles?.pageBG}
		>
			<Container maxW={"8xl"} px="0" bg="white" rounded="md" shadow="xl" minH="8xl" py={4}>
				<Stack spacing={portalUser.styles?.pageSpacing} p={portalUser.styles?.pagePadding} w="full">
					<PPMLogo color="teal.700" h="90px" />
					<Flex
						w="full"
						flex={1}
						justify="center"
						py={2}
						borderTopWidth={"2px"}
						borderTopColor={"gray.400"}
						px="6"
					>
						<HStack
							color={"gray.600"}
							spacing={6}
							w="full"
							justify="center"
							align="center"
							divider={
								<Text px="6" fontSize="xs">
									<i className="fas fa-circle fa-xs"></i>
								</Text>
							}
						>
							<Text fontSize="md" isTruncated>
								18250 Euclid Street
							</Text>
							<Text fontSize="md" isTruncated>
								Fountain Valley, CA 92708
							</Text>
							<Text fontSize="md" isTruncated>
								Phone: (949) 474-9170
							</Text>
							<Text fontSize="md" isTruncated>
								Fax: (949) 474-9936
							</Text>
						</HStack>
					</Flex>

					<Heading textAlign="center" size="xl" color="teal.700" textTransform="uppercase" isTruncated>
						Purchase Order
					</Heading>

					<Stack spacing="8" pt="2" w="full" px={{ base: "0", lg: "4" }}>
						<Box w="full">
							<Flex flex={1} w="full">
								<Heading
									py="1"
									flex={1}
									size="sm"
									color="teal.700"
									textTransform="uppercase"
									isTruncated
									textAlign="right"
									px="3"
								>
									Vendor
								</Heading>
								<Flex
									flex={4}
									borderTop="2px"
									borderLeft="2px"
									borderRight="2px"
									borderBottom="1px"
									borderBottomColor="gray.400"
								></Flex>
								<Heading
									py="1"
									flex={2}
									size="sm"
									color="teal.700"
									textTransform="uppercase"
									isTruncated
									textAlign="right"
									px="3"
								>
									PO #
								</Heading>
								<Flex
									flex={3}
									borderTop="2px"
									borderLeft="2px"
									borderRight="2px"
									borderBottom="1px"
									borderBottomColor="gray.400"
								></Flex>
							</Flex>
							<Flex flex={1} w="full">
								<Heading
									py="1"
									flex={1}
									size="sm"
									color="teal.700"
									textTransform="uppercase"
									isTruncated
									textAlign="right"
									px="3"
								>
									Contact
								</Heading>
								<Flex
									flex={4}
									borderLeft="2px"
									borderRight="2px"
									borderBottom="1px"
									borderBottomColor="gray.400"
								></Flex>
								<Heading
									py="1"
									flex={2}
									size="sm"
									color="teal.700"
									textTransform="uppercase"
									isTruncated
									textAlign="right"
									px="3"
								>
									List #
								</Heading>
								<Flex
									flex={3}
									borderLeft="2px"
									borderRight="2px"
									borderBottom="1px"
									borderBottomColor="gray.400"
								></Flex>
							</Flex>
							<Flex flex={1} w="full">
								<Heading
									py="1"
									flex={1}
									size="sm"
									color="teal.700"
									textTransform="uppercase"
									isTruncated
									textAlign="right"
									px="3"
								>
									DWG SHEET #
								</Heading>
								<Flex
									flex={4}
									borderLeft="2px"
									borderRight="2px"
									borderBottom="1px"
									borderBottomColor="gray.400"
								></Flex>
								<Heading
									py="1"
									flex={2}
									size="sm"
									color="teal.700"
									textTransform="uppercase"
									isTruncated
									textAlign="right"
									px="3"
								>
									DATE ORDERED
								</Heading>
								<Flex
									flex={3}
									borderLeft="2px"
									borderRight="2px"
									borderBottom="1px"
									borderBottomColor="gray.400"
								></Flex>
							</Flex>
							<Flex flex={1} w="full">
								<Heading
									py="1"
									flex={1}
									size="sm"
									color="teal.700"
									textTransform="uppercase"
									isTruncated
									textAlign="right"
									px="3"
								>
									NOTES
								</Heading>
								<Flex flex={4} borderBottom="2px" borderLeft="2px" borderRight="2px"></Flex>
								<Heading
									py="1"
									flex={2}
									size="sm"
									color="teal.700"
									textTransform="uppercase"
									isTruncated
									textAlign="right"
									px="3"
								>
									DATE REQUIRED
								</Heading>
								<Flex flex={3} borderBottom="2px" borderLeft="2px" borderRight="2px"></Flex>
							</Flex>
						</Box>

						<Box w="full">
							<Flex flex={1} w="full">
								<Heading
									py="1"
									flex={1}
									size="sm"
									color="teal.700"
									textTransform="uppercase"
									isTruncated
									textAlign="right"
									px="3"
								>
									JOB NAME
								</Heading>
								<Flex
									flex={4}
									borderTop="2px"
									borderLeft="2px"
									borderRight="2px"
									borderBottom="1px"
									borderBottomColor="gray.400"
								></Flex>
								<Heading
									py="1"
									flex={2}
									size="sm"
									color="teal.700"
									textTransform="uppercase"
									isTruncated
									textAlign="right"
									px="3"
								>
									JOB #
								</Heading>
								<Flex
									flex={3}
									borderTop="2px"
									borderLeft="2px"
									borderRight="2px"
									borderBottom="1px"
									borderBottomColor="gray.400"
								></Flex>
							</Flex>
							<Flex flex={1} w="full">
								<Heading
									py="1"
									flex={1}
									size="sm"
									color="teal.700"
									textTransform="uppercase"
									isTruncated
									textAlign="right"
									px="3"
								>
									ADDRESS
								</Heading>
								<Flex
									flex={4}
									borderLeft="2px"
									borderRight="2px"
									borderBottom="1px"
									borderBottomColor="gray.400"
								></Flex>
								<Heading
									py="1"
									flex={2}
									size="sm"
									color="teal.700"
									textTransform="uppercase"
									isTruncated
									textAlign="right"
									px="3"
								>
									CITY
								</Heading>
								<Flex
									flex={3}
									borderLeft="2px"
									borderRight="2px"
									borderBottom="1px"
									borderBottomColor="gray.400"
								></Flex>
							</Flex>
							<Flex flex={1} w="full">
								<Heading
									py="1"
									flex={1}
									size="sm"
									color="teal.700"
									textTransform="uppercase"
									isTruncated
									textAlign="right"
									px="3"
								>
									SHIP TO
								</Heading>
								<Flex
									flex={4}
									borderLeft="2px"
									borderRight="2px"
									borderBottom="1px"
									borderBottomColor="gray.400"
								></Flex>
								<Heading
									py="1"
									flex={2}
									size="sm"
									color="teal.700"
									textTransform="uppercase"
									isTruncated
									textAlign="right"
									px="3"
								>
									ZIP
								</Heading>
								<Flex
									flex={3}
									borderLeft="2px"
									borderRight="2px"
									borderBottom="1px"
									borderBottomColor="gray.400"
								></Flex>
							</Flex>
							<Flex flex={1} w="full">
								<Heading
									py="1"
									flex={1}
									size="sm"
									color="teal.700"
									textTransform="uppercase"
									isTruncated
									textAlign="right"
									px="3"
								>
									Job CONTACT
								</Heading>
								<Flex flex={4} borderBottom="2px" borderLeft="2px" borderRight="2px"></Flex>
								<Heading
									py="1"
									flex={2}
									size="sm"
									color="teal.700"
									textTransform="uppercase"
									isTruncated
									textAlign="right"
									px="3"
								>
									PHONE #
								</Heading>
								<Flex flex={3} borderBottom="2px" borderLeft="2px" borderRight="2px"></Flex>
							</Flex>
						</Box>

						<Box w="full" pt="2">
							<Table size="small" bg="white" rounded="5">
								<Thead top="0" left="0" zIndex="2" position="sticky" border="2px">
									<Tr textTransform="uppercase" bg="teal.700">
										<Th>
											<Heading fontSize="md" color="white" isTruncated p="2" textAlign="center">
												Qty
											</Heading>
										</Th>
										<Th>
											<Heading fontSize="md" color="white" isTruncated p="2" textAlign="center">
												Size
											</Heading>
										</Th>
										<Th>
											<Heading fontSize="md" color="white" isTruncated p="2" textAlign="center">
												Description
											</Heading>
										</Th>
										<Th>
											<Heading fontSize="md" color="white" isTruncated p="2" textAlign="center">
												Cost Codes
											</Heading>
										</Th>
										<Th>
											<Heading fontSize="md" color="white" isTruncated p="2" textAlign="center">
												Unit Cost
											</Heading>
										</Th>
										<Th>
											<Heading fontSize="md" color="white" isTruncated p="2" textAlign="center">
												Total Cost
											</Heading>
										</Th>
									</Tr>
								</Thead>
								<Tbody border="2px">
									{rows?.map((row, i) => (
										<Fragment key={i}>{row}</Fragment>
									))}

									<Tr>
										<Td px="2" borderRight="1px" borderTop="1px" borderColor="gray.400"></Td>
										<Td px="2" borderRight="1px" borderTop="1px" borderColor="gray.400"></Td>
										<Td px="2" borderRight="1px" borderTop="1px" borderColor="gray.400">
											<Text fontWeight="semibold" color="teal.700" textTransform="uppercase">
												CONTACT SITE 30 MIN BEFORE DELIVERY
											</Text>
										</Td>
										<Td px="2" borderRight="1px" borderTop="1px" borderColor="gray.400"></Td>
										<Td px="2" borderRight="1px" borderTop="1px" borderColor="gray.400"></Td>
										<Td px="2" borderTop="1px" borderColor="gray.400">
											<Text>$</Text>
										</Td>
									</Tr>
									<Tr>
										<Td px="2" borderRight="1px" borderTop="1px" borderColor="gray.400"></Td>
										<Td px="2" borderRight="1px" borderTop="1px" borderColor="gray.400"></Td>
										<Td px="2" borderRight="1px" borderTop="1px" borderColor="gray.400">
											<Text fontWeight="semibold" color="teal.700" textTransform="uppercase">
												NO DROP-OFFS: PACKERS W/O PPM
											</Text>
										</Td>
										<Td px="2" borderRight="1px" borderTop="1px" borderColor="gray.400"></Td>
										<Td px="2" borderRight="1px" borderTop="1px" borderColor="gray.400">
											<Text
												fontWeight="semibold"
												color="teal.700"
												textTransform="uppercase"
												textAlign="right"
											>
												Subtotal
											</Text>
										</Td>
										<Td px="2" borderTop="1px" borderColor="gray.400">
											<Text>$</Text>
										</Td>
									</Tr>
									<Tr>
										<Td px="2" borderRight="1px" borderTop="1px" borderColor="gray.400"></Td>
										<Td px="2" borderRight="1px" borderTop="1px" borderColor="gray.400"></Td>
										<Td px="2" borderRight="1px" borderTop="1px" borderColor="gray.400">
											<Text fontWeight="semibold" color="teal.700" textTransform="uppercase">
												SIGNATURES WILL NOT BE PROCESSED FOR PAYMENT
											</Text>
										</Td>
										<Td px="2" borderRight="1px" borderTop="1px" borderColor="gray.400"></Td>
										<Td px="2" borderRight="1px" borderTop="1px" borderColor="gray.400"></Td>
										<Td px="2" borderTop="1px" borderColor="gray.400">
											<Text>$</Text>
										</Td>
									</Tr>
									<Tr>
										<Td px="2" borderRight="1px" borderTop="1px" borderColor="gray.400"></Td>
										<Td px="2" borderRight="1px" borderTop="1px" borderColor="gray.400"></Td>
										<Td px="2" borderRight="1px" borderTop="1px" borderColor="gray.400"></Td>
										<Td px="2" borderRight="1px" borderTop="1px" borderColor="gray.400"></Td>
										<Td px="2" borderRight="1px" borderTop="1px" borderColor="gray.400">
											<Text
												fontWeight="semibold"
												color="teal.700"
												textTransform="uppercase"
												textAlign="right"
											>
												Freight
											</Text>
										</Td>
										<Td px="2" borderTop="1px" borderColor="gray.400">
											<Text>$</Text>
										</Td>
									</Tr>
								</Tbody>
								<Tfoot>
									<Tr>
										<Td colSpan={4} borderBottom="0px"></Td>
										<Td px="2" borderBottom="0px">
											<Text
												fontWeight="semibold"
												color="teal.700"
												textTransform="uppercase"
												textAlign="right"
											>
												Total
											</Text>
										</Td>
										<Td
											px="2"
											borderRight="2px"
											borderLeft="2px"
											borderBottom="2px"
											borderColor="black"
											bg="blackAlpha.50"
										>
											<Text fontWeight="semibold">$</Text>
										</Td>
									</Tr>
								</Tfoot>
							</Table>
						</Box>
					</Stack>
				</Stack>
			</Container>
		</Stack>
	);
}
