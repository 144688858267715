import React, { useState, useRef } from "react";
import {
	Box,
	Flex,
	Center,
	HStack,
	FormControl,
	FormLabel,
	Text,
	InputGroup,
	Portal,
	Stack,
	Heading,
	useBoolean,
	ButtonGroup,
	IconButton,
	Tooltip,
	Popover,
	PopoverTrigger,
	PopoverContent,
} from "@chakra-ui/react";
import TextInput from "../text/TextInput";
import NumberInput from "../text/NumberInput";
import TextAreaInput from "../text/TextAreaInput";
import TextEditorInput from "../text/TextEditorInput";
import DateInput from "../select/DateInput";
import SelectInput from "../select/SelectInput";
// import MultiSelectInput from "./MultiSelectInput";
import SwitchInput from "../toggle/SwitchInput";
import RadioInput from "../toggle/RadioInput";
import FileInput from "../file/FileInput";
// import CSVInput from "./CSVInput";
import { v4 } from "uuid";
// import { log } from "../../helperFunctions";

export default function DataInput(props) {
	const [edit, setEdit] = useBoolean();
	const [value, setValue] = useState(props.value ?? props.defaultValue);
	const [onChangeEvent, setOnChangeEvent] = useState(null);
	const ref = useRef(null);

	let inputRef = props.inputRef ?? ref;

	const handleOnChange = (ev) => {
		let value;
		if (Boolean(ev?.target)) {
			value = ev.target.value;
			setOnChangeEvent(ev);
		} else {
			value = ev;
		}
		setValue(value);
	};

	const editValue = (ev) => {
		ev.stopPropagation();
		if (props.editable) {
			setValue(props.value ?? props.defaultValue);
			setEdit.on();
		}
	};

	const save = (ev) => {
		ev.stopPropagation();
		if (props.editable && props.onChange) {
			let returnVal = Boolean(onChangeEvent) ? onChangeEvent : { value };
			props.onChange(returnVal);
		}
		setEdit.off();
	};

	const cancel = (ev) => {
		ev?.stopPropagation();
		setValue(props.value ?? props.defaultValue);
		setEdit.off();
	};

	const variant = Boolean(props.variant) ? props.variant : "outline";

	let forwardProps = { ...props };

	if (props.editable) {
		forwardProps.value = value;
		forwardProps.onChange = handleOnChange;
	}

	let InputComponent;

	if (!Boolean(props.editable) || (Boolean(props.editable) && edit)) {
		InputComponent = (
			<InputGroup
				{...props.inputGroupProps}
				onClick={(ev) => {
					ev.stopPropagation();
				}}
			>
				<>{props.topAddon && <>{props.topAddon}</>}</>

				{((!props.type && !props.children) || props.type === "text") && (
					<Box w="full">
						<TextInput {...forwardProps} variant={variant} ref={inputRef} />
					</Box>
				)}
				{props.type === "number" && (
					<Box w="full">
						<NumberInput {...forwardProps} variant={variant} />
					</Box>
				)}
				{props.type === "select" && (
					<Box w="full">
						<SelectInput {...forwardProps} variant={variant} inputVariant={props.inputVariant} />
					</Box>
				)}
				{props.type === "switch" && (
					<Box w="full">
						<SwitchInput {...forwardProps} variant={variant} />
					</Box>
				)}
				{props.type === "date" && (
					<Box w="full">
						<DateInput valueType="date" variant={variant} {...forwardProps} ref={ref} />
					</Box>
				)}
				{props.type === "month" && (
					<Box w="full">
						<DateInput
							valueType="month"
							showMonthYearPicker={true}
							dateFormat="MMM yyyy"
							popperContainer={Portal}
							variant={variant}
							{...forwardProps}
							ref={inputRef}
						/>
					</Box>
				)}

				{props.type === "textarea" && (
					<Flex w="full">
						<TextAreaInput {...forwardProps} inputRef={inputRef} />{" "}
					</Flex>
				)}

				{props.type === "editor" && (
					<Box w="full">
						<TextEditorInput {...forwardProps} />
					</Box>
				)}
				{props.type === "radio" && (
					<Box w="full">
						<RadioInput {...forwardProps} variant={variant} />
					</Box>
				)}
				{props.type === "file" && (
					<Box w="full">
						<FileInput {...forwardProps} variant={variant} />
					</Box>
				)}
			</InputGroup>
		);
	}

	return (
		<Stack w="full" spacing={1} flex={props.flex ?? ""}>
			<FormControl
				key={v4()}
				isRequired={props.isRequired}
				display={props.display}
				alignItems={props.display === "flex" ? "center" : ""}
				size="sm"
				{...forwardProps}
			>
				{props.label && (
					<FormLabel
						_disabled={{ opacity: 1 }}
						mb={0}
						color={
							props.error?.type === "error"
								? "red.500"
								: props.error?.type === "warning"
								? "yellow.500"
								: "gray.500"
						}
						requiredIndicator={props.requiredIndicator}
					>
						<Text
							fontSize={props.display === "flex" ? "" : props.fontSize ?? "md"}
							textTransform="uppercase"
							fontWeight="600"
							display="inline-block"
							{...props.labelProps}
						>
							{props.label}
						</Text>

						{Boolean(props.helpText) && (
							<Tooltip label={props.helpText} placement="top">
								<Text as="span" ml={2}>
									<i className="fas fa-question-circle" />
								</Text>
							</Tooltip>
						)}
					</FormLabel>
				)}

				{!props.type && props.children && <>{props.children}</>}

				{props.popover && (
					<Popover
						isOpen={Boolean(props.editable) && edit}
						isLazy
						initialFocusRef={inputRef}
						onClose={cancel}
						placement="bottom-start"
					>
						<PopoverTrigger>
							<Text
								isTruncated={props.isTruncated ?? false}
								className={props.textTransform === "none" ? "" : "text-uppercase "}
								fontWeight="semibold"
								fontSize="sm"
								color={props.color ?? "gray.500"}
								onClick={editValue}
								cursor="pointer"
								{...props.valueProps}
								_hover={{ color: "blue.700", borderBottom: "1px blue dotted" }}
							>
								{
									<Text as="span">
										{Boolean(props.placeholder) && !Boolean(props.value)
											? props.placeholder
											: props.value}
										&nbsp;{props.unit}
									</Text>
								}
							</Text>
						</PopoverTrigger>
						{Boolean(props.editable) && edit && (
							<Portal>
								<PopoverContent mt={"-4rem"}>
									<Box p={2}>
										<Stack direction="row">
											{InputComponent}
											<Center>
												<ButtonGroup size="sm" isAttached>
													<IconButton
														colorScheme="green"
														icon={<i className="fas fa-check" />}
														onClick={save}
													/>
													<IconButton
														colorScheme="red"
														variant="outline"
														icon={<i className="fas fa-times" />}
														onClick={cancel}
													/>
												</ButtonGroup>
											</Center>
										</Stack>
									</Box>
								</PopoverContent>
							</Portal>
						)}
					</Popover>
				)}

				{Boolean(props.editable) && !edit && !props.popover && (
					<Tooltip label="Click To Edit" placement="top-start">
						<Heading
							as="h4"
							isTruncated={props.isTruncated ?? false}
							className={props.textTransform === "none" ? "" : "text-uppercase "}
							fontWeight="semibold"
							size="sm"
							color={props.color ?? "gray.500"}
							onClick={editValue}
							cursor="pointer"
							_hover={{ background: "white" }}
							{...props.valueProps}
						>
							{
								<Text as="span">
									{props.value}&nbsp;{props.unit}
								</Text>
							}
						</Heading>
					</Tooltip>
				)}

				{(!Boolean(props.editable) || (Boolean(props.editable) && edit)) && !props.popover && (
					<Stack direction="row">
						{InputComponent}

						{Boolean(props.editable) && edit && (
							<Center>
								<ButtonGroup size="xs" isAttached>
									<IconButton
										colorScheme="green"
										icon={<i className="fas fa-check" />}
										onClick={save}
									/>
									<IconButton
										colorScheme="red"
										variant="outline"
										icon={<i className="fas fa-times" />}
										onClick={cancel}
									/>
								</ButtonGroup>
							</Center>
						)}
					</Stack>
				)}
			</FormControl>
			{props.error && (
				<HStack
					align="center"
					w="full"
					spacing={2}
					justify="flex-start"
					color={
						props.error?.type === "error"
							? "red.500"
							: props.error?.type === "warning"
							? "yellow.500"
							: "blue.500"
					}
				>
					<Text h="full" fontSize="xs" textTransform="uppercase" fontWeight="600" letterSpacing={1}>
						<i
							className={
								props.error?.type === "error"
									? "fas fa-exclamation-triangle fa-fw"
									: props.error?.type === "warning"
									? "fas fa-question-circle fa-lg fa-fw"
									: "fas fa-info-circle fa-lg fa-fw"
							}
						/>
					</Text>
					<Text h="full" fontSize="xs" textTransform="uppercase" fontWeight="600" letterSpacing={1}>
						{props.error?.message}
					</Text>
				</HStack>
			)}
		</Stack>
	);
}
