import React, { useState } from "react";
import { Heading, Text, Stack, IconButton, useBoolean, ButtonGroup } from "@chakra-ui/react";
import DataInput from "../../Inputs/data/DataInput";

export default function LabelValuePair(props) {
	const [edit, setEdit] = useBoolean();
	const [value, setValue] = useState(props.value ?? props.defaultValue);

	const handleOnChange = (ev) => {
		let value = ev.target.value;
		setValue(value);
	};

	const editValue = (ev) => {
		ev.stopPropagation();
		if (props.editable) {
			setEdit.on();
		}
	};

	const save = (ev) => {
		ev.stopPropagation();
		if (props.editable && props.onChange) {
			let returnVal = { target: { name: props.name, value: value } };
			props.onChange(returnVal);
		}
		setEdit.off();
	};

	const cancel = (ev) => {
		ev.stopPropagation();
		if (props.editable) {
			setValue(props.value ?? props.defaultValue);
			setEdit.off();
		}
	};

	let forwardingProps = { ...props };
	forwardingProps.label = null;
	forwardingProps.value = value;
	forwardingProps.onChange = handleOnChange;
	forwardingProps.editable = null;

	if (props.direction === "vertical") {
		return (
			<Stack
				direction="column"
				align={props.align ?? "flex-start"}
				display={props.display ?? "flex"}
				spacing={props.variant === "smallText" ? 0 : 1}
				py={props.variant === "smallText" ? "0" : "2"}
				flex={props.flex}
				flexBasis={props.flexBasis}
				px={2}
				{...props.containerProps}
				onClick={props.HRReviewList ? undefined : editValue}
			>
				{props.variant !== "smallText" && (
					<>
						<Heading
							as="h5"
							textTransform="uppercase"
							isTruncated={props.isTruncated ?? false}
							size="xs"
							color="gray.400"
							{...props.labelProps}
						>
							{props.label}
						</Heading>
						{props.value && (
							<Heading
								as="h4"
								isTruncated={props.isTruncated ?? false}
								textTransform={props.textTransform ?? "uppercase"}
								fontWeight="semibold"
								size={props.size ?? "sm"}
								color={props.color ?? "gray.500"}
							>
								{props.value}
							</Heading>
						)}
						{props.subValue && (
							<Text
								textTransform={props.textTransform ?? "uppercase"}
								fontSize="sm"
								color={props.color ?? "gray.500"}
							>
								{props.subValue}
							</Text>
						)}
					</>
				)}

				{props.variant === "smallText" && (
					<>
						<Text
							textTransform="uppercase"
							isTruncated={props.isTruncated ?? false}
							fontSize="xs"
							fontWeight="600"
							color="gray.400"
							{...props.labelProps}
						>
							{props.label}
						</Text>
					</>
				)}

				{props.children && <>{props.children}</>}

				{props.variant === "smallText" && (
					<>
						{(props.value || props.placeholder) && (
							<>
								{(!props.editable || (props.editable && !edit)) && (
									<Heading
										as="h4"
										isTruncated={props.isTruncated ?? false}
										textTransform={props.textTransform}
										fontWeight={!Boolean(props.value) ? "normal" : "semibold"}
										size={props.size ?? "sm"}
										color={props.color ?? !Boolean(props.value) ? "gray.400" : "gray.500"}
									>
										{props.value ?? props.placeholder}
									</Heading>
								)}
								{props.editable && edit && (
									<Stack direction="row">
										<DataInput size="sm" {...forwardingProps} />
										<ButtonGroup size="sm" isAttached>
											<IconButton
												colorScheme="green"
												icon={<i className="fas fa-check" />}
												onClick={save}
											/>
											<IconButton
												variant="outline"
												colorScheme="red"
												icon={<i className="fas fa-times" />}
												onClick={cancel}
											/>
										</ButtonGroup>
									</Stack>
								)}
							</>
						)}
						{props.subValue && (
							<Text
								textTransform={props.textTransform ?? "uppercase"}
								fontSize="sm"
								color={props.color ?? "gray.500"}
							>
								{props.subValue}
							</Text>
						)}
					</>
				)}
			</Stack>
		);
	} else {
		return (
			<Stack
				direction={props.stackDirection ? props.stackDirection : "row"}
				align="flex-start"
				spacing={1}
				py={props.variant === "smallText" ? "0" : "2"}
				flex={props.flex}
				flexBasis={props.flexBasis}
				hidden={props.hidden ?? false}
				{...props.containerProps}
				px={2}
			>
				<Heading
					flex={1}
					as="h5"
					isTruncated={props.isTruncated ?? false}
					size="xs"
					color="gray.400"
					textAlign={props.labelProps?.textAlign}
					whiteSpace={props.whiteSpace ?? "nowrap"}
				>
					{props.label}:
				</Heading>
				<Heading
					flex={{ base: 1, md: 2 }}
					as="h4"
					fontWeight="semibold"
					size="sm"
					color="gray.500"
					align={props.valueAlign}
					whiteSpace={props.whiteSpace}
				>
					{props.value}
				</Heading>
			</Stack>
		);
	}
}
