import React, { useMemo } from "react";
import { Link, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import EGNYTE_DOMAIN_URL from "../constants/EgnyteDomainURL";

export default function EgnyteLinkMenu({ dfJob }) {
	const showEgnyteLinkMenu = useMemo(
		() => Boolean(dfJob?.egnyteLinkMatrix || dfJob?.egnyteLinkSubmittals || dfJob?.egnyteLinkPlanBreaks),
		[dfJob?.egnyteLinkMatrix, dfJob?.egnyteLinkPlanBreaks, dfJob?.egnyteLinkSubmittals]
	);

	return (
		<>
			{showEgnyteLinkMenu && (
				<Menu>
					<MenuButton
						p={2}
						transition="all 0.2s"
						borderRadius="md"
						_hover={{ bg: "gray.200" }}
						_expanded={{ color: "teal.500", bg: "whiteAlpha.900" }}
						_focus={{ color: "teal.500", bg: "whiteAlpha.900" }}
						bg="whiteAlpha.700"
						color="gray.500"
						fontWeight="semibold"
						letterSpacing={1}
						textTransform={"uppercase"}
					>
						Egnyte <ChevronDownIcon />
					</MenuButton>
					<MenuList>
						{dfJob?.egnyteLinkMatrix && (
							<Link
								_hover={{ color: "teal.500" }}
								href={EGNYTE_DOMAIN_URL + dfJob?.egnyteLinkMatrix}
								isExternal
							>
								<MenuItem fontWeight="semibold" textTransform={"uppercase"} letterSpacing={1}>
									Material Matrix
								</MenuItem>
							</Link>
						)}
						{dfJob?.egnyteLinkSubmittals && (
							<Link
								_hover={{ color: "teal.500" }}
								href={EGNYTE_DOMAIN_URL + dfJob?.egnyteLinkSubmittals}
								isExternal
							>
								<MenuItem fontWeight="semibold" textTransform={"uppercase"} letterSpacing={1}>
									Approved Submittals
								</MenuItem>
							</Link>
						)}
						{dfJob?.egnyteLinkPlanBreaks && (
							<Link
								_hover={{ color: "teal.500" }}
								href={EGNYTE_DOMAIN_URL + dfJob?.egnyteLinkPlanBreaks}
								isExternal
							>
								<MenuItem fontWeight="semibold" textTransform={"uppercase"} letterSpacing={1}>
									Misc Deliverables
								</MenuItem>
							</Link>
						)}
					</MenuList>
				</Menu>
			)}
		</>
	);
}
