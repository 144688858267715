import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Flex, useBoolean } from "@chakra-ui/react";
import MainContent from "../../legacy/App/components/MainContent";
import Tabs from "../../core/Layout/tabs/Tabs";
import IFrame from "../../core/Layout/modals/IFrame";

// import PayrollAPI from "../../APIs/PayrollAPI";
import PowerBI from "./classes/PowerBI";
import SectionObj from "../../core/Reports/classes/Section";
import { portalUser } from "../../App";
import { log } from "../../helperFunctions";

export default function PowerBIViewer(props) {
	const [updateTrigger, setUpdateTrigger] = useState(false);
	const [viewerMaxHeight, setViewerMaxHeight] = useState(800);
	const [tabs, setTabs] = useState([]);
	const [[tabVal, tabDirection], setTabVal] = useState([0, 0]);

	const changeTab = useCallback(
		(newTab, newDirection) => {
			if (!newDirection) newDirection = newTab - tabVal;
			setTabVal([newTab, newDirection]);
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		},
		[tabVal]
	);

	// const getAttachmentURL = async (attachmentID = null) => {
	// 	let attachmentURL = null;
	// 	if (Boolean(attachmentID)) {
	// 		let payrollAPI = new PayrollAPI();
	// 		let attachment = await payrollAPI.GetAttachmentBlobFile(attachmentID);
	// 		// Note to Dustin -  attachmentID is an integer - not a attachmentUID like the the api routes

	// 		if (attachment.status === 200) {
	// 			attachment = attachment.value;
	// 			let blob = new Blob([new Uint8Array(attachment[0].AttachmentData.data)], { type: "application/pdf" });
	// 			attachmentURL = URL.createObjectURL(blob);

	// 			// const pdfWindow = window.open();
	// 			// pdfWindow.location.href = attachmentURL;
	// 		}
	// 	}
	// 	return attachmentURL;
	// };

	// useEffect(() => {
	// 	const init = async () => {
	// 		let invoiceURL = null;

	// 		invoiceURL = await getAttachmentURL(1511446);
	// 		setAttachmentURL(invoiceURL);
	// 		setUpdateTrigger(true);
	// 	};
	// 	init();
	// }, [props.pageName]);

	// useEffect(() => {
	// 	if (updateTrigger) {
	// 		setUpdateTrigger(false);
	// 	}
	// }, [updateTrigger]);

	//DATA IS LOADING FLAG AND CALLBACK FUNCTION
	const [isLoading, setIsLoading] = useBoolean(true);
	const setDataLoading = useCallback(
		async function (callback) {
			if (!isLoading) {
				setIsLoading.on();
			}
			callback();
		},
		[isLoading, setIsLoading]
	);

	const reportUID = useMemo(() => props.reportUID, [props.reportUID]);
	const [powerBI, setPowerBI] = useState(portalUser?.powerBI ?? null);

	//ON PAGE LOAD INIT FORM DATA - GET FORMS & TEMPLATES
	const initPowerBI = useCallback(
		async (reportUID) => {
			let msUserID = portalUser.user?.msUserID ?? portalUser?.msUser?.id ?? null;
			let newPowerBI = powerBI;
			if (Boolean(msUserID) && !Boolean(newPowerBI)) {
				newPowerBI = new PowerBI(portalUser?.powerBI);
				newPowerBI = await newPowerBI?.initPowerBI(msUserID);
			}

			if (Boolean(reportUID)) {
				// 	newPowerBI = await newPowerBI?.getFormData(reportUID);
			}

			setPowerBI(newPowerBI);
			setIsLoading.off();
		},
		[powerBI, setIsLoading]
	);

	useEffect(() => {
		setDataLoading(() => initPowerBI(reportUID));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Update portalUser powerBI cache
	useMemo(() => {
		if (Boolean(powerBI) && !isLoading) {
			portalUser.powerBI = powerBI;
		}
	}, [powerBI, isLoading]);

	useMemo(() => {
		let tabs = [];
		let embedLinks = powerBI?.embedLinks ?? [];
		let name = "Power BI Reports";

		if (Boolean(props.module)) {
			embedLinks = embedLinks?.filter((d) => d.module === props.module) ?? [];
			name =
				props?.module === "EXEC"
					? "Executive Dashboards (EXEC)"
					: props?.module === "DEV"
					? "Dev Ops Reports (DEV)"
					: props?.module === "OPS"
					? "Operations Reports (OPS)"
					: "Power BI Reports";
		}

		let sections = embedLinks?.map((e) => ({
			name: name,
			heading: e.title + " (" + e.abbr + ")",
			url: e.url,
		}));

		for (let i = 0; i < sections?.length; i++) {
			if (Boolean(sections[i])) {
				let section = new SectionObj(sections[i]);

				tabs.push({
					header: section.name,
					name: section.heading,
					url: sections[i]?.url,
					pages: [<IFrame variant={"simple"} minH={viewerMaxHeight} url={sections[i]?.url} />],
				});
				setTabs(tabs);
			}
		}
	}, [powerBI?.embedLinks, props.module, viewerMaxHeight]);

	return (
		<MainContent subtitle={tabs[tabVal]?.name} title={tabs[tabVal]?.header ?? props.pageName}>
			<Flex bg="blackAlpha.500" direction="column" flex={1} justify="flex-start" w="full" h="full" py={4} px={6}>
				<Tabs
					tabs={tabs}
					tabVal={tabVal}
					tabDirection={tabDirection}
					changeTab={changeTab}
					viewerMaxHeight={viewerMaxHeight}
					setViewerMaxHeight={setViewerMaxHeight}
					updateTrigger={updateTrigger}
				>
					{tabs[tabVal]?.pages?.map((page, i) => {
						return <React.Fragment key={i}>{page}</React.Fragment>;
					})}
				</Tabs>
				{tabs[tabVal]?.url}
			</Flex>
		</MainContent>
	);
}
