import React, { useState, useMemo, useCallback } from "react";
import { Box, Flex, Heading, Stack, HStack } from "@chakra-ui/react";
import DataInput from "../../../core/Inputs/data/DataInput";
import MFRLabelValuePair from "./MFRLabelValuePair";

import { portalUser } from "../../../App";
import { formatValue, getValueColor, log } from "../../../helperFunctions";

export default function ContractStatus(props) {
	const contract = useMemo(() => props.contract, [props.contract]);
	const contractStatus = useMemo(() => props.contractStatus, [props.contractStatus]);
	// const prevContractStatus = useMemo(() => props.prevContractStatus, [props.prevContractStatus]);

	const [insuranceTypes, setInsuranceTypes] = useState([]);
	const [bondedYN, setBondedYN] = useState("N");
	const [insuranceType, setInsuranceType] = useState(null);
	const [billingDue, setBillingDue] = useState(null);

	// TO DO: UPDATE ADJ ORIG CONTRACT AMT

	useMemo(() => {
		setBondedYN(contract?.bondedYN);
		setInsuranceType(contract?.insuranceType);
		setBillingDue(contract?.billingDue);
		setInsuranceTypes(portalUser?.getInsuranceTypes());
	}, [contract]);

	const updateBondedYN = useCallback(
		(e) => {
			if (e !== contract?.bondedYN) {
				contract.update("bondedYN", e);
				setBondedYN(e);
			}
		},
		[contract]
	);

	const updateInsuranceType = useCallback(
		(e) => {
			if (e.databaseValue !== contract?.insuranceTypeID) {
				contract.update("insuranceType", e);
				setInsuranceType(contract?.insuranceType ?? null);
			}
		},
		[contract]
	);
	log("insuranceType", insuranceType);

	const updateBillingDue = useCallback(
		(e) => {
			if (e !== contract?.billingDue) {
				contract.update("billingDue", e);
				setBillingDue(e);
			}
		},
		[contract]
	);

	const bondedYNArr = [
		{ value: "Y", text: "Yes" },
		{ value: "N", text: "No" },
	];

	const billingArr = [
		{ value: "1", text: "1st" },
		{ value: "2", text: "2nd" },
		{ value: "3", text: "3rd" },
		{ value: "4", text: "4th" },
		{ value: "5", text: "5th" },
		{ value: "6", text: "6th" },
		{ value: "7", text: "7th" },
		{ value: "8", text: "8th" },
		{ value: "9", text: "9th" },
		{ value: "10", text: "10th" },
		{ value: "11", text: "11th" },
		{ value: "12", text: "12th" },
		{ value: "13", text: "13th" },
		{ value: "14", text: "14th" },
		{ value: "15", text: "15th" },
		{ value: "16", text: "16th" },
		{ value: "17", text: "17th" },
		{ value: "18", text: "18th" },
		{ value: "19", text: "19th" },
		{ value: "20", text: "20th" },
		{ value: "21", text: "21st" },
		{ value: "22", text: "22nd" },
		{ value: "23", text: "23rd" },
		{ value: "24", text: "24th" },
		{ value: "25", text: "25th" },
		{ value: "26", text: "26th" },
		{ value: "27", text: "27th" },
		{ value: "28", text: "28th" },
		{ value: "29", text: "29th" },
		{ value: "30", text: "30th" },
	];

	return (
		<Stack w="full" spacing={4} px={4}>
			<HStack align="center">
				<Heading
					minW="25px"
					size={"md"}
					color={"gray.500"}
					textTransform="uppercase"
					isTruncated
					letterSpacing={2}
				>
					II.
				</Heading>
				<Heading size={"md"} color={"gray.500"} textTransform="uppercase" isTruncated letterSpacing={2}>
					{"Contract Status" + (["2", "4", "5"].includes(contract?.contractTypeID) ? " CRS" : "")}
				</Heading>
			</HStack>

			<HStack spacing={8} w="full" align="flex-start" px={4}>
				<Box w="full" flex={5}>
					<Stack spacing={1} w="full" flex={1}>
						<MFRLabelValuePair
							direction="horizontal"
							valueAlign="right"
							label="A.&nbsp;&nbsp;Bid Contract"
							isTruncated
							value={
								<DataInput
									type="text"
									textAlign="right"
									variant="unstyled"
									borderRadius="0px"
									inputProps={{ py: "0px", px: "0px" }}
									defaultValue={formatValue(
										contractStatus?.bidContractAmt ?? contract?.bidContractAmt ?? 0,
										0,
										"currency"
									)}
									onBlur={async (ev) => {
										if (
											formatValue(ev.target.value, 0, "numeric") !==
											formatValue(contract?.bidContractAmt, 0, "numeric")
										) {
											await contract?.update("adjOrigContractAmt", ev.target.value);
											props.selectContract(contract?.jccmKeyID, false, true);
										}
									}}
								/>
							}
						/>
						<Box pl={8}>
							<Stack spacing={1} w="full" flex={1}>
								<MFRLabelValuePair
									color="gray.500"
									direction="horizontal"
									valueAlign="right"
									label="Approved Change Orders"
									isTruncated
									value={formatValue(contractStatus?.acoAmt ?? contract?.acoAmt ?? 0, 0, "currency")}
								/>
								<MFRLabelValuePair
									color="gray.500"
									direction="horizontal"
									valueAlign="right"
									label="Non Contract Change Orders"
									isTruncated
									value={formatValue(contractStatus?.nonAmt ?? contract?.nonAmt ?? 0, 0, "currency")}
								/>
							</Stack>
						</Box>
						<MFRLabelValuePair
							direction="horizontal"
							valueAlign="right"
							label="B.&nbsp;&nbsp;Current Contract Amount"
							fontWeight="bold"
							isTruncated
							key={"contractAmt" + contractStatus?.contractAmt}
							value={formatValue(
								contractStatus?.contractAmt ?? contract?.contractAmt ?? 0,
								0,
								"currency"
							)}
							sideNote={"(a)"}
						/>
						<Box pl={8}>
							<Stack spacing={1} w="full" flex={1}>
								<MFRLabelValuePair
									color="gray.500"
									direction="horizontal"
									valueAlign="right"
									label="Pending Change Orders"
									isTruncated
									value={formatValue(contractStatus?.pcoAmt ?? contract?.pcoAmt ?? 0, 0, "currency")}
									onClick={props.changeTab}
								/>
								<MFRLabelValuePair
									color="gray.500"
									direction="horizontal"
									valueAlign="right"
									label="Projected PCO Conversion"
									isTruncated
									value={formatValue(
										contractStatus?.pcoConv ?? contract?.pcoConv ?? 0,
										0,
										"currency"
									)}
									sideNote={"(b)"}
								/>
								<MFRLabelValuePair
									color="gray.500"
									direction="horizontal"
									valueAlign="right"
									label="RMA"
									isTruncated
									value={formatValue(contractStatus?.rmaAmt ?? contract?.rmaAmt ?? 0, 0, "currency")}
								/>
								<MFRLabelValuePair
									color="gray.500"
									direction="horizontal"
									valueAlign="right"
									label="PMA"
									isTruncated
									value={formatValue(contractStatus?.pmaAmt ?? contract?.pmaAmt ?? 0, 0, "currency")}
									sideNote={"(c)"}
								/>
								<MFRLabelValuePair
									color="gray.500"
									direction="horizontal"
									valueAlign="right"
									label="Allowances"
									isTruncated
									value={formatValue(
										contractStatus?.allowAmt ?? contract?.allowAmt ?? 0,
										0,
										"currency"
									)}
									sideNote={"(d)"}
								/>
							</Stack>
						</Box>
						<MFRLabelValuePair
							direction="horizontal"
							valueAlign="right"
							label="C.&nbsp;&nbsp;Projected Contract Amount"
							isTruncated
							fontWeight="bold"
							value={formatValue(
								contractStatus?.getProjContractAmt() ?? contract?.getProjContractAmt() ?? 0,
								0,
								"currency"
							)}
							sideNote={"(a+b+c+d)"}
						/>
					</Stack>
				</Box>

				<Box w="full" flex={4}>
					<Stack spacing={1} w="full" flex={1}>
						<Box w="full">
							<Stack spacing={1} w="full" flex={1}>
								<MFRLabelValuePair
									direction="horizontal"
									valueAlign="right"
									label="Bid Margin ($)"
									fontWeight="bold"
									textColor={getValueColor(
										contractStatus?.getBidProfitAmt() ?? contract?.getBidProfitAmt() ?? 0,
										"gray.600",
										"green.600",
										"red.600"
									)}
									value={formatValue(
										contractStatus?.getBidProfitAmt() ?? contract?.getBidProfitAmt() ?? 0,
										0,
										"currency"
									)}
								/>
								<MFRLabelValuePair
									direction="horizontal"
									valueAlign="right"
									label="Bid Margin (%)"
									fontWeight="bold"
									textColor={getValueColor(
										contractStatus?.getBidMarginPct() ?? contract?.getBidMarginPct() ?? 0,
										"gray.600",
										"green.600",
										"red.600"
									)}
									value={formatValue(
										contractStatus?.getBidMarginPct() ?? contract?.getBidMarginPct() ?? 0,
										1,
										"percent"
									)}
								/>
								<MFRLabelValuePair
									direction="horizontal"
									valueAlign="right"
									label="Projected Margin ($)"
									fontWeight="bold"
									textColor={getValueColor(
										contractStatus?.getProjProfitAmt() ?? contract?.getProjProfitAmt() ?? 0,
										"gray.600",
										"green.600",
										"red.600"
									)}
									value={formatValue(
										contractStatus?.getProjProfitAmt() ?? contract?.getProjProfitAmt() ?? 0,
										0,
										"currency"
									)}
									onClick={props.onToggle}
								/>
								<MFRLabelValuePair
									direction="horizontal"
									valueAlign="right"
									label="Projected Margin (%)"
									fontWeight="bold"
									textColor={getValueColor(
										contractStatus?.getProjMarginPct() ?? contract?.getProjMarginPct() ?? 0,
										"gray.600",
										"green.600",
										"red.600"
									)}
									value={formatValue(
										contractStatus?.getProjMarginPct() ?? contract?.getProjMarginPct() ?? 0,
										1,
										"percent"
									)}
									onClick={props.onToggle}
								/>
								<MFRLabelValuePair
									direction="horizontal"
									valueAlign="right"
									label="Variance Margin ($)"
									fontWeight="bold"
									textColor={getValueColor(
										formatValue(
											contractStatus?.getVarProfitAmt() ?? contract?.getVarProfitAmt() ?? 0
										),
										"gray.600",
										"green.600",
										"red.600"
									)}
									value={formatValue(
										contractStatus?.getVarProfitAmt() ?? contract?.getVarProfitAmt() ?? 0,
										0,
										"currency"
									)}
								/>
								<MFRLabelValuePair
									direction="horizontal"
									valueAlign="right"
									label="Variance Margin (%)"
									fontWeight="bold"
									textColor={getValueColor(
										contractStatus?.getVarMarginPct() ?? contract?.getVarMarginPct() ?? 0,
										"gray.600",
										"green.600",
										"red.600"
									)}
									value={formatValue(
										contractStatus?.getVarMarginPct() ?? contract?.getVarMarginPct() ?? 0,
										1,
										"percent"
									)}
								/>
							</Stack>
						</Box>

						<Flex direction="column" justify="flex-end">
							<Stack spacing={1}>
								<Box h={6}></Box>

								<MFRLabelValuePair
									color="gray.500"
									labelProps={{ textAlign: "right" }}
									direction="horizontal"
									valueAlign="left"
									label="Bonded (Y/N)"
									value={
										<DataInput
											key={"input-19-" + bondedYN}
											type="select"
											variant="unstyled"
											searchable
											inputProps={{
												py: "0",
												rounded: "0px",
												_hover: { bg: "blackAlpha.50", cursor: "pointer" },
											}}
											value={bondedYN}
											options={bondedYNArr}
											optionText="text"
											optionValue="value"
											onOptionSelect={updateBondedYN}
										/>
									}
								/>

								<MFRLabelValuePair
									color="gray.500"
									labelProps={{ textAlign: "right" }}
									direction="horizontal"
									valueAlign="left"
									label="OCIP/CCIP"
									value={
										<DataInput
											key={"input-20-" + insuranceType}
											type="select"
											variant="unstyled"
											searchable
											inputProps={{
												py: "0",
												rounded: "0px",
												_hover: { bg: "blackAlpha.50", cursor: "pointer" },
											}}
											value={insuranceType}
											options={insuranceTypes}
											optionText="displayValue"
											// optionValue="databaseValue"
											onOptionSelect={updateInsuranceType}
										/>
									}
								/>

								<MFRLabelValuePair
									color="gray.500"
									labelProps={{ textAlign: "right" }}
									direction="horizontal"
									valueAlign="left"
									label="Billing Is Due"
									value={
										<DataInput
											key={"input-21-" + billingDue}
											type="select"
											variant="unstyled"
											searchable
											inputProps={{
												py: "0",
												rounded: "0px",
												_hover: { bg: "blackAlpha.50", cursor: "pointer" },
											}}
											value={billingDue}
											options={billingArr}
											optionText="text"
											optionValue="value"
											onOptionSelect={updateBillingDue}
										/>
									}
								/>
							</Stack>
						</Flex>
					</Stack>
				</Box>
			</HStack>
		</Stack>
	);
}
