import React from "react";
import { Divider, Heading, Stack, Text } from "@chakra-ui/react";

export const JOYRIDE_APP = [
	{
		target: "body",
		placement: "center",
		hideCloseButton: true,
		showSkipButton: false,
		showProgress: false,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Portal Tour
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					Welcome to our new Employee Portal!
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					Click Next to begin a quick tour of the new features.
				</Text>
			</Stack>
		),
	},
	{
		target: "#avatarMenu",
		placement: "bottom-end",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Profile Menu
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					Click your profile picture to view your notifications, go to your profile, log out, or take this
					tour again.
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					If you wish to end the tour at any time, click Skip.
				</Text>
			</Stack>
		),
	},
	{
		target: "#menuDrawer",
		placement: "right-start",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Main Menu
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					Click a menu item to switch between different sections of the Employee Portal.
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					Arrows to the right indicate submenu options are available.
				</Text>
			</Stack>
		),
	},
	{
		target: "#menuItem-home",
		placement: "right",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Home Page
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					View company related information such as announcements, upcoming events, celebrations and links to
					social media.
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					Do you know someone interested in working with us? You can refer them to HR on this page.
				</Text>
			</Stack>
		),
	},
	{
		target: "#menuItem-profile",
		placement: "right",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Profile
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					Create a time off request, update your account info, add emergency contacts, or view your
					performance reviews.
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					You can apply filter options to your calendar to show or hide events such as Outlook meetings,
					company holidays, and department time off.
				</Text>
			</Stack>
		),
	},
	{
		target: "#menuItem-team",
		placement: "right",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					My Team
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					Managers can view general info for employees on their team and complete yearly performance reviews.
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					Note: For some employees, the manager may not be the same person as the one completing the
					performance review.
				</Text>
			</Stack>
		),
	},
	{
		target: "#menuItem-payroll",
		placement: "right",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Payroll
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					Access your weekly timecard, enter and approve time for your crews, and view your past paystubs.
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					Paystubs require a verification code that will be sent to your email when you click Send Code
					button.
				</Text>
			</Stack>
		),
	},
	{
		target: "#menuItem-projects",
		placement: "right",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Projects
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					This section contains a list of active and completed projects by region.
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					Click any column heading to sort by that column or click the Filter button to add additional
					filters.
				</Text>
			</Stack>
		),
	},
	{
		target: "#menuItem-directory",
		placement: "right",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Directory
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					This section contains a contact list of our employees and org charts by region.
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					You can create group emails by using the checkboxes to select employees and clicking the Send Email
					button.
				</Text>
			</Stack>
		),
	},
	{
		target: "#fullPageWalkthrough",
		placement: "left",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Page Tours
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					A Page Tour button will appear when a tutorial is available for a specific page in the portal.
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					Click the Page Tour button to view the tutorial. The portal will automatically prompt you when new
					features are added.
				</Text>
			</Stack>
		),
	},
	{
		target: "body",
		placement: "center",
		locale: { last: "End Tour" },
		hideCloseButton: true,
		showProgress: false,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Stay Tuned
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					Stay tuned for future updates as we continue to expand the Employee Portal!
				</Text>
			</Stack>
		),
	},
];

export const JOYRIDE_HOME = [
	{
		target: "body",
		placement: "center",
		hideCloseButton: true,
		showSkipButton: false,
		showProgress: false,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Home Page Tour
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					The Home Page is where you can view company related information such as announcements, upcoming
					events, celebrations and links to social media.
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					Click Next to begin a quick tutorial of the new features.
				</Text>
			</Stack>
		),
	},
	{
		target: "#card-announcements",
		placement: "right-start",
		beaconPlacement: "right-start",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Announcements
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					Important company announcements will appear here.
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					If you wish to end the tutorial at any time, click Skip.
				</Text>
			</Stack>
		),
	},
	{
		target: "#card-yourweekahead",
		placement: "left-start",
		beaconPlacement: "left-start",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Your Week Ahead
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					Your scheduled meetings in Outlook for the week will appear here.
				</Text>
				{/* <Text as='i' fontSize='sm' color='gray.400'>You can apply filter options to your calendar to show or hide events such as Outlook meetings, company holidays, and department time off.</Text> */}
			</Stack>
		),
	},
	{
		target: "#card-socialmedia",
		placement: "right-start",
		beaconPlacement: "right-start",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Social Media
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					You can click on the image to view Pan-Pacific's Instagram page, or the link below to visit the
					specified Social Media page.
				</Text>
				{/* <Text as='i' fontSize='sm' color='gray.400'>If you wish to end the tutorial at any time, click Skip.</Text> */}
			</Stack>
		),
	},
	{
		target: "#card-upcomingevents",
		placement: "left-start",
		beaconPlacement: "left-start",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Upcoming Events
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					Upcoming company events, holidays, and other important dates for your region will appear here.
				</Text>
				{/* <Text as='i' fontSize='sm' color='gray.400'>You can apply filter options to your calendar to show or hide events such as Outlook meetings, company holidays, and department time off.</Text> */}
			</Stack>
		),
	},
	{
		target: "#card-celebrations",
		placement: "top",
		beaconPlacement: "top-start",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Celebrations
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					Our celebrations show your peers that have reached a longevity milestone for the month. Feel free to
					reach out to congratulate them!
				</Text>
				{/* <Text as='i' fontSize='sm' color='gray.400'>If you wish to end the tutorial at any time, click Skip.</Text> */}
			</Stack>
		),
	},
	{
		target: "#card-referral",
		placement: "top",
		beaconPlacement: "top-start",
		locale: { last: "End Tour" },
		hideCloseButton: true,
		showProgress: false,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Upcoming Events
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					You can click 'Learn More' to refer someone to HR who you think would be a great fit for the Pan
					family.
				</Text>
				{/* <Text as='i' fontSize='sm' color='gray.400'>You can apply filter options to your calendar to show or hide events such as Outlook meetings, company holidays, and department time off.</Text> */}
			</Stack>
		),
	},
];

export const JOYRIDE_USERDASHBOARD = [
	{
		target: "body",
		placement: "center",
		hideCloseButton: true,
		showSkipButton: false,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Profile: Dashboard
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					The Dashboard is where you can view a summary of your weekly timecard, view and create time off
					requests, and view your calendar.{" "}
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					Click Next to begin a quick tutorial of the new features.
				</Text>
			</Stack>
		),
	},
	{
		target: "#card-timecard",
		placement: "right-start",
		beaconPlacement: "right-start",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Timecard Summary
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					This is a summary of your timecard for the current payweek.
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					Clicking this tile will link you directly your timecard in the payroll section of the Employee
					Portal.
				</Text>
			</Stack>
		),
	},
	{
		target: "#card-availabletimeoff",
		placement: "right-start",
		beaconPlacement: "right-start",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Available Time Off
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					This is a summary of your available sick and vacation time.
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					The amounts are updated after your vacation/sick days have been used.
				</Text>
			</Stack>
		),
	},
	{
		target: "#card-timeoffrequests",
		placement: "right-start",
		beaconPlacement: "right-start",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Time Off Requests
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					This is where you can submit Time Off Requests.
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					You can also filter the view to see your current and past requests, along with your department's
					approved time off.
				</Text>
			</Stack>
		),
	},
	{
		target: "#card-calendar",
		placement: "left-start",
		beaconPlacement: "left-start",
		locale: { last: "End Tour" },
		hideCloseButton: true,
		showProgress: false,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Calendar
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					You can filter this calendar to show your meetings in Outlook, company events, holidays, and
					vacation dates for yourself or your department
				</Text>
				{/* <Text as='i' fontSize='sm' color='gray.400'>You can apply filter options to your calendar to show or hide events such as Outlook meetings, company holidays, and department time off.</Text> */}
			</Stack>
		),
	},
];

export const JOYRIDE_USERACCOUNT = [
	{
		target: "body",
		placement: "center",
		hideCloseButton: true,
		showSkipButton: false,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Profile: Account
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					The Account Tab on your Profile is where you can edit your employee, payroll, and emergency contact
					information.{" "}
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					Click Next to begin a quick tutorial of the new features.
				</Text>
			</Stack>
		),
	},
	{
		target: "#card-employeeinfo",
		placement: "top",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Employee Info
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					Here you can edit information such as how your name is displayed in the portal, your cell phone and
					carrier, and your shirt size.
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					You can choose whether or not to share your birthday with fellow employees or share photos of
					yorself on company social media clicking Allow. *Birth year is never shown.
				</Text>
			</Stack>
		),
	},
	{
		target: "#card-payrollinfo",
		hideCloseButton: true,
		placement: "top",
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Payroll Info
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					Here you can edit payroll preferences such as the email address your paystub is sent to and the home
					address listed on your W-2.{" "}
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					Be sure to click save after editing your home address so that HR and Payroll are notified.
				</Text>
			</Stack>
		),
	},
	{
		target: "#card-emergencycontacts",
		placement: "top",
		locale: { last: "End Tour" },
		hideCloseButton: true,
		showProgress: false,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Emergency Contacts
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					This section is where you can add, edit or remove emergency contacts.
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					You much have at least one emergency contact on file.
				</Text>
			</Stack>
		),
	},
];

export const JOYRIDE_USERREVIEWS = [
	{
		target: "body",
		placement: "center",
		hideCloseButton: true,
		showSkipButton: false,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Profile: Reviews
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					This tab is where you can view past performance reviews and fill out self evaluations
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					Click Next to begin a quick tutorial of the new features.
				</Text>
			</Stack>
		),
	},
	{
		target: "#card-reviewheader",
		placement: "top",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Review Year
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					This is where you can select the year and form you looking to view or edit.
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					The review year dropdown will only show past reviews that have been entered via the portal.
				</Text>
			</Stack>
		),
	},

	{
		target: "#button-printform",
		placement: "top",
		locale: { last: "End Tour" },
		hideCloseButton: true,
		showProgress: false,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Print or Download
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					Click here if you wish to print or download the form as a PDF.
				</Text>
				{/* <Text as='i' fontSize='sm' color='gray.400'>If you wish to end the tutorial at any time, click Skip.</Text>  */}
			</Stack>
		),
	},
];

export const JOYRIDE_PROPOSEDINCREASES = [
	{
		target: "body",
		placement: "center",
		hideCloseButton: true,
		showSkipButton: false,
		showProgress: false,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={1}>
					Salary & Bonus Proposals
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					Tutorial of New Features
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					Click Next to begin a quick tutorial of the new features.
				</Text>
			</Stack>
		),
	},
	{
		target: "#joyrideStep1",
		placement: "top",
		beaconPlacement: "top-end",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={1}>
					Employees
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					This is where you will enter proposed salary increases for your employees
				</Text>
				{/* <Text as='i' fontSize='sm' color='gray.400'>If you wish to end the tutorial at any time, click Skip.</Text> */}
			</Stack>
		),
	},
	{
		target: "#joyrideStep6",
		placement: "top",
		beaconPlacement: "bottom-start",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={1}>
					Change Departments
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					Click one of the tabs above to change departments (if applicable)
				</Text>
				{/* <Text as='i' fontSize='sm' color='gray.400'>You can apply filter options to your calendar to show or hide events such as Outlook meetings, company holidays, and department time off.</Text> */}
			</Stack>
		),
	},
	{
		target: "#joyrideStep3",
		placement: "top",
		beaconPlacement: "top-end",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={1}>
					Enter Salary Amount
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					Click here to enter in a proposed salary amount , the amounts will save automatically
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					The % increase will calculate automatically.
				</Text>
			</Stack>
		),
	},
	{
		target: "#joyrideStep4",
		placement: "top",
		beaconPlacement: "top-end",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={1}>
					Enter % Increase
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					Click here if you would like to enter a % Increase
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					The proposed salary will be calculated and saved automatically
				</Text>
			</Stack>
		),
	},

	{
		target: "#joyrideStep2",
		placement: "top",
		beaconPlacement: "top-end",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={1}>
					View Salary History
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					Click an employees's % Increase to see the employees salary history
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					Previous salaries and bonus amounts go back as far as 2014
				</Text>
			</Stack>
		),
	},
	{
		target: "#joyrideStep5",
		placement: "top",
		beaconPlacement: "top-end",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={1}>
					Manager's Notes
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					Add any important notes about this employee for upper management
				</Text>
				{/* <Text as='i' fontSize='sm' color='gray.400'>If you wish to end the tutorial at any time, click Skip.</Text> */}
			</Stack>
		),
	},
	{
		target: "#joyrideStep7",
		placement: "bottom",
		beaconPlacement: "bottom-start",
		locale: { last: "End Tour" },
		hideCloseButton: true,
		showProgress: false,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={1}>
					Bonus & Allowances
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					Click the Bonus & Allowances Tab to enter those amounts for the current employees
				</Text>
				{/* <Text as='i' fontSize='sm' color='gray.400'>You can apply filter options to your calendar to show or hide events such as Outlook meetings, company holidays, and department time off.</Text> */}
			</Stack>
		),
	},
];

export const JOYRIDE_BIDBOARD = [
	{
		target: "body",
		placement: "center",
		hideCloseButton: true,
		showSkipButton: false,
		showProgress: false,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Material Request Tour
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					The Home Page is where you can view company related information such as announcements, upcoming
					events, celebrations and links to social media.
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					Click Next to begin a quick tutorial of the new features.
				</Text>
			</Stack>
		),
	},
	{
		target: "#card-announcements",
		placement: "right-start",
		beaconPlacement: "right-start",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Announcements
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					Important company announcements will appear here.
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					If you wish to end the tutorial at any time, click Skip.
				</Text>
			</Stack>
		),
	},
	{
		target: "#card-yourweekahead",
		placement: "left-start",
		beaconPlacement: "left-start",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Your Week Ahead
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					Your scheduled meetings in Outlook for the week will appear here.
				</Text>
				{/* <Text as='i' fontSize='sm' color='gray.400'>You can apply filter options to your calendar to show or hide events such as Outlook meetings, company holidays, and department time off.</Text> */}
			</Stack>
		),
	},
	{
		target: "#card-socialmedia",
		placement: "right-start",
		beaconPlacement: "right-start",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Social Media
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					You can click on the image to view Pan-Pacific's Instagram page, or the link below to visit the
					specified Social Media page.
				</Text>
				{/* <Text as='i' fontSize='sm' color='gray.400'>If you wish to end the tutorial at any time, click Skip.</Text> */}
			</Stack>
		),
	},
	{
		target: "#card-upcomingevents",
		placement: "left-start",
		beaconPlacement: "left-start",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Upcoming Events
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					Upcoming company events, holidays, and other important dates for your region will appear here.
				</Text>
				{/* <Text as='i' fontSize='sm' color='gray.400'>You can apply filter options to your calendar to show or hide events such as Outlook meetings, company holidays, and department time off.</Text> */}
			</Stack>
		),
	},
	{
		target: "#card-celebrations",
		placement: "top",
		beaconPlacement: "top-start",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Celebrations
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					Our celebrations show your peers that have reached a longevity milestone for the month. Feel free to
					reach out to congratulate them!
				</Text>
				{/* <Text as='i' fontSize='sm' color='gray.400'>If you wish to end the tutorial at any time, click Skip.</Text> */}
			</Stack>
		),
	},
	{
		target: "#card-referral",
		placement: "top",
		beaconPlacement: "top-start",
		locale: { last: "End Tour" },
		hideCloseButton: true,
		showProgress: false,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Upcoming Events
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					You can click 'Learn More' to refer someone to HR who you think would be a great fit for the Pan
					family.
				</Text>
				{/* <Text as='i' fontSize='sm' color='gray.400'>You can apply filter options to your calendar to show or hide events such as Outlook meetings, company holidays, and department time off.</Text> */}
			</Stack>
		),
	},
];

export const JOYRIDE_COMPANYDIRECTORY_EMPLOYEES = [
	{
		target: "body",
		placement: "center",
		hideCloseButton: true,
		showSkipButton: false,
		showProgress: false,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Company Directory: Employees
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					This tab shows a list of all active employees, filterable by region.
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					Click Next to begin a quick tutorial of the new features.
				</Text>
			</Stack>
		),
	},
	{
		target: "#card-myteam",
		placement: "right-start",
		beaconPlacement: "right-start",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					My Team
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					This is a summary of your timecard for the current payweek.
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					Clicking this tile will link you directly your timecard in the payroll section of the Employee
					Portal.
				</Text>
			</Stack>
		),
	},
	{
		target: "#card-availabletimeoff",
		placement: "right-start",
		beaconPlacement: "right-start",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Available Time Off
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					This is a summary of your available sick and vacation time.
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					The amounts are updated after your vacation/sick days have been used.
				</Text>
			</Stack>
		),
	},
	{
		target: "#card-timeoffrequests",
		placement: "right-start",
		beaconPlacement: "right-start",
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Time Off Requests
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					This is where you can submit Time Off Requests.
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					You can also filter the view to see your current and past requests, along with your department's
					approved time off.
				</Text>
			</Stack>
		),
	},
	{
		target: "#card-calendar",
		placement: "left-start",
		beaconPlacement: "left-start",
		locale: { last: "End Tour" },
		hideCloseButton: true,
		showProgress: false,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Calendar
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					You can filter this calendar to show your meetings in Outlook, company events, holidays, and
					vacation dates for yourself or your department
				</Text>
				{/* <Text as='i' fontSize='sm' color='gray.400'>You can apply filter options to your calendar to show or hide events such as Outlook meetings, company holidays, and department time off.</Text> */}
			</Stack>
		),
	},
];

export const JOYRIDE_MYTEAM = [
	{
		target: "body",
		placement: "center",
		hideCloseButton: true,
		showSkipButton: false,
		showProgress: false,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Team: My Team
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					This page is where managers can view general info for employees on their team.
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					Click Next to begin a quick tutorial of the new features.
				</Text>
			</Stack>
		),
	},
	{
		target: "#card-myteam",
		placement: "top",
		locale: { last: "End Tour" },
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					My Team
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					People listed here are the employees who directly report to you.
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					If an employee is either missing from this list or is shown but not supposed to be on this list ,
					please contact HR.
				</Text>
			</Stack>
		),
	},
];

export const JOYRIDE_MYTEAM_PERFORMANCEREVIEWS = [
	{
		target: "body",
		placement: "center",
		hideCloseButton: true,
		showSkipButton: false,
		showProgress: false,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					Team: My Team
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					This page is where managers can view general info for employees on their team.
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					Click Next to begin a quick tutorial of the new features.
				</Text>
			</Stack>
		),
	},
	{
		target: "#card-myteam",
		placement: "top",
		locale: { last: "End Tour" },
		hideCloseButton: true,
		content: (
			<Stack textAlign="left">
				<Heading as="h4" size="md" color="gray.600" textTransform="uppercase" letterSpacing={2}>
					My Team
				</Heading>
				<Divider />
				<Text fontSize="md" color="gray.500" fontWeight="semibold">
					People listed here are the employees who directly report to you.
				</Text>
				<Text as="i" fontSize="sm" color="gray.400">
					If an employee is either missing from this list or is shown but not supposed to be on this list ,
					please contact HR.
				</Text>
			</Stack>
		),
	},
];
