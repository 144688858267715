import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Flex, Text, Heading, Stack, HStack, Tooltip, IconButton, useBoolean, Link } from "@chakra-ui/react";
import moment from "moment";
import { portalUser } from "../../../App";
import { formatValue } from "../../../helperFunctions";
import ConfirmationPrompt from "../../Alerts/ConfirmationPrompt";

export default function NotificationRow(row = null, updateMyData, listRef, onClose) {
	const [dismissHover, setDismissHover] = useBoolean(false);
	const [deleteHover, setDeleteHover] = useBoolean(false);

	let notification = Boolean(row?.original) ? row.original : null;
	let timeoffRequest = Boolean(notification?.timeOffRequest) ? notification?.timeOffRequest : null;

	let isApprover = Boolean(
		timeoffRequest?.approverUID?.toLowerCase() === portalUser.user?.employeeUID?.toLowerCase()
	);
	let isCommenter = Boolean(notification?.category === "commenter");

	const dismissNotification = () => {
		updateMyData(row.index, "dateAcknowledged", moment(new Date()).format("MM/DD/YYYY HH:mm"));
		if (Boolean(listRef?.current)) {
			listRef?.current?.resetAfterIndex(0, false);
		}
	};

	const deleteNotification = () => {
		updateMyData(row.index, "delete");
		if (Boolean(listRef?.current)) {
			listRef?.current?.resetAfterIndex(0, false);
		}
	};

	const redirectLink = () => {
		portalUser.tempUID = timeoffRequest?.requestUID ?? null;
		// dismissNotification();
		onClose();
	};

	if (Boolean(notification)) {
		return (
			<Flex
				_hover={{
					shadow: "md",
					color: "gray.600",
					bg: dismissHover || deleteHover ? "whiteAlpha.600" : "white",
					borderColor: deleteHover ? "red.600" : dismissHover ? "blackAlpha.800" : "teal.600",
					opacity: dismissHover || deleteHover ? 0.85 : 1,
					cursor: notification?.type === "Request" ? "pointer" : "",
					// borderWidth: 3,
				}}
				p={3}
				my={2}
				flex={1}
				w="full"
				borderWidth={2}
				borderColor={deleteHover ? "red.600" : dismissHover ? "blackAlpha.800" : "gray.400"}
				bg={dismissHover || deleteHover ? "whiteAlpha.600" : "whiteAlpha.800"}
				color="gray.500"
				rounded={"lg"}
				shadow="sm"
				opacity={dismissHover || deleteHover ? 0.85 : 1}
				align="space-between"
				justify="space-between"
				direction="column"
				position="relative"
				// onClick={() => {
				// 	if (row.original.accessYN === "Y") {
				// 		props.selectContract(row.original.jccmKeyID);
				// 	} else {
				// 	}
				// }}
				key={notification?.notificationUID}
			>
				<HStack
					spacing={1}
					p={2}
					rounded="md"
					justify="flex-end"
					align="flex-start"
					position="absolute"
					right={0}
					top={0}
				>
					{!Boolean(notification?.dateAcknowledged) ? (
						<Tooltip label={"Dismiss"} position="top">
							<IconButton
								onClick={dismissNotification}
								bg="whiteAlpha.800"
								_hover={{ color: "white", borderColor: "blackAlpha.700", bg: "blackAlpha.500" }}
								color="gray.400"
								boxSize="25px"
								minW="25px"
								p={1}
								borderWidth={2}
								icon={<i className={"fas fa-times fa-fw "} />}
								onMouseLeave={setDismissHover.off}
								onMouseEnter={setDismissHover.on}
							></IconButton>
						</Tooltip>
					) : (
						<ConfirmationPrompt
							useIconButton
							confirmationFunction={deleteNotification}
							icon="fa-trash-alt"
							alertDialogHeader="Delete Notification"
							alertDialogBody="Are you sure you would like to delete this Notification?"
							alterDialogFooter="The data for the Notification will be deleted permanently."
							loadingText="Deleting..."
							onMouseLeave={setDeleteHover.off}
							onMouseEnter={setDeleteHover.on}
						/>
					)}
				</HStack>
				<Stack spacing={2} w="full" flex={1} h="full">
					<Heading fontSize="md" letterSpacing={1} w="full" textTransform={"uppercase"} isTruncated>
						{notification?.subject}
					</Heading>

					<Text fontSize="sm" letterSpacing={1}>
						{notification?.body}
					</Text>

					<Flex
						w="full"
						justify={!Boolean(notification?.dateAcknowledged) && isApprover ? "space-between" : "flex-end"}
						align="flex-end"
						isTruncated
						flex={1}
						rounded="md"
					>
						{!Boolean(notification?.dateAcknowledged) &&
							Boolean(notification?.redirectURL) &&
							(isApprover || isCommenter) && (
								<>
									{notification?.redirectURL?.includes("www.") ||
									notification?.redirectURL?.includes("https://") ? (
										<Link
											fontWeight="bold"
											textDecoration="underline"
											color="blue.600"
											isExternal
											onClick={redirectLink}
											href={notification?.redirectURL}
											textTransform={"uppercase"}
										>
											<HStack>
												<i class="fas fa-external-link-alt"></i>
												{notification?.redirectURL}
											</HStack>
										</Link>
									) : (
										<RouterLink
											style={{
												textDecoration: "underline",
												color: "var(--chakra-colors-blue-600)",
												fontWeight: "bold",

												textTransform: "uppercase",
											}}
											onClick={redirectLink}
											to={`${notification?.redirectURL}`}
										>
											<HStack>
												<i class="fas fa-external-link-alt"></i>
												<Text>Click to View</Text>
											</HStack>
										</RouterLink>
									)}
								</>
							)}

						<Text
							fontSize="md"
							letterSpacing={1}
							whiteSpace="nowrap"
							pl={2}
							fontWeight="bold"
							textTransform={"uppercase"}
						>
							{formatValue(notification?.dateCreated, 0, "date")}
						</Text>
					</Flex>
				</Stack>
			</Flex>
		);
	} else {
		return null;
	}
}
