import React, { useState, useMemo } from "react";
import {
	Box,
	Flex,
	Text,
	Heading,
	useBoolean,
	HStack,
	Stack,
	Tag,
	IconButton,
	Collapse,
	SimpleGrid,
	Avatar,
	AvatarBadge,
} from "@chakra-ui/react";

export default function MeritIncreasesDeptSecurityPage(props) {
	const [show, setShow] = useState(true);

	const handleToggle = () => setShow(!show);

	useMemo(() => setShow(props.defaultOpen), [props.defaultOpen]);

	const level = useMemo(() => props.level, [props.level]);

	const employees = useMemo(() => {
		let employees = level?.employees ?? [];
		employees =
			employees?.filter(
				(data) =>
					data?.employeeUID !== "AB653524-E7EE-40FA-B095-6A68D8BCEC27"
			) ?? [];
		employees =
			employees?.filter((data) => data?.showEmployee === true) ?? [];
		if (level.accessLevel !== 1 && Boolean(props.selectedEmployee)) {
			employees = employees.filter(
				(data) =>
					data?.employeeUID !== props.selectedEmployee?.employeeUID
			);
		}

		return employees;
	}, [level.accessLevel, level?.employees, props.selectedEmployee]);

	let numberOfEmployees = employees?.length ?? 0;

	return (
		<Box w="full" borderBottom="2px" borderBottomColor="gray.200">
			<HStack
				p="3"
				spacing={1}
				_hover={{ cursor: "pointer", bg: "blackAlpha.50" }}
			>
				<Avatar
					onClick={handleToggle}
					mb={2}
					bg="gray.400"
					size="lg"
					shadow="md"
					color="white"
					icon={
						<i
							className={
								"fas " +
								(level.accessLevelIcon ?? "fa-cog fa-spin") +
								" fa-lg "
							}
						></i>
					}
				>
					<AvatarBadge
						border="0.125em solid"
						boxSize="1.35em"
						bg="gray.500"
						shadow="sm"
						color="white"
					>
						<Text fontSize="md" fontWeight="bold">
							{numberOfEmployees}
						</Text>
					</AvatarBadge>
				</Avatar>
				<Stack
					spacing={1}
					rounded="5"
					py="4"
					px="4"
					onClick={handleToggle}
				>
					<HStack spacing={3} wrap="wrap">
						<Heading
							letterSpacing={2}
							color="gray.500"
							textTransform="uppercase"
							size="md"
							isTruncated
						>
							{level.accessLevelDesc}
						</Heading>
						<Heading
							letterSpacing={1}
							color="gray.400"
							textTransform="uppercase"
							size="sm"
							isTruncated
						>
							{"(" +
								numberOfEmployees +
								(numberOfEmployees === 1
									? " Person)"
									: " People)")}
						</Heading>
					</HStack>

					<Text color="gray.400" fontSize="lg" letterSpacing={1}>
						{level.accessLevelSubheading}
					</Text>
				</Stack>
			</HStack>

			<Collapse in={show}>
				<Box pb="6">
					<Box>
						{/* p='4' bg={'blackAlpha.50'} rounded='10' */}
						{/* <HStack spacing={6} pt={6} pb={12}>  */}
						<SimpleGrid
							minChildWidth="290px"
							spacingX={4}
							spacingY={4}
							columns={{ sm: 2, md: 3, lg: 4 }}
						>
							{employees?.map((employee, i) => {
								return (
									<MeritIncreasesDeptSecurity
										show={show}
										showDetail={props.showDetail}
										selectLeader={props.selectLeader}
										employee={employee}
										selectedEmployee={
											props.selectedEmployee
										}
										accessLevel={level.accessLevel}
										regionArr={props.regionArr}
									/>
								);
							})}
							{/* </HStack> */}
						</SimpleGrid>
					</Box>
				</Box>
			</Collapse>
		</Box>
	);
}

function MeritIncreasesDeptSecurity(props) {
	const [show, setShow] = useState(props.showDetail);

	const handleToggle = () => setShow(!show);

	useMemo(() => setShow(props.showDetail), [props.showDetail]);

	const [isHovering, setHovering] = useBoolean();

	const regionArr = useMemo(() => props.regionArr, [props.regionArr]);
	// let accessLevel = props.accessLevel ?? 0;
	let employee = props.employee;
	let departments = employee?.departments?.filter(
		(data) => data?.hasAccess === true
	);
	departments = departments?.filter((data) => data?.showDept === true);
	let departmentCount = departments?.length;

	let allDepts = false;
	if (departments?.filter((data) => data?.deptID === 1)?.length > 0) {
		allDepts = true;
		departmentCount = 15;
	}
	let icon =
		!isHovering && !show
			? departments[0]?.parentDeptIcon ?? "fa-plus"
			: show
			? "fa-minus"
			: "fa-plus";

	let allRegions = false;
	let regionCount = 0;
	for (let i = 0; i < departments?.length; i++) {
		let regions = departments[i].regions.filter(
			(data) => data?.hasAccess === true
		);
		regions = regions?.filter((data) => data?.showRegion === true);
		let currentRegions = 0;

		for (let j = 0; j < regions?.length; j++) {
			if (regions[j].regionID === -1) {
				allRegions = true;
				regionCount = 3;
				break;
			} else {
				currentRegions += 1;
			}
		}
		if (allRegions) {
			break;
		} else if (currentRegions > regionCount) {
			regionCount = currentRegions;
		}
	}

	return (
		<>
			{employee.showEmployee && (
				<Box
					onMouseEnter={setHovering.on}
					onMouseLeave={setHovering.off}
				>
					<HStack
						spacing={3}
						align="flex-start"
						rounded="5"
						p="2"
						bg={isHovering ? "blackAlpha.50" : "white"}
						cursor="pointer"
						shadow={isHovering ? "md" : ""}
						maxW="305px"
						isTruncated
					>
						<IconButton
							key={icon}
							onClick={handleToggle}
							variant="outline"
							size="sm"
							bg="white"
							color="gray.400"
							icon={
								<i
									className={"fas " + icon + " fa-fw fa-lg"}
								></i>
							}
						/>

						<Stack spacing={1} isTruncated>
							<Stack
								spacing={1}
								isTruncated
								maxW="305px"
								onClick={handleToggle}
							>
								<Heading
									color="gray.500"
									letterSpacing={1}
									textTransform="uppercase"
									size="sm"
									isTruncated
								>
									{employee.firstName +
										" " +
										employee.lastName}
								</Heading>
								<Text
									color="gray.500"
									fontWeight="semibold"
									fontSize="sm"
									textTransform="uppercase"
									letterSpacing={1}
									isTruncated
								>
									{employee.title}
								</Text>

								<Text
									color="gray.400"
									fontWeight="semibold"
									textTransform="uppercase"
									fontSize="sm"
								>
									{(allDepts ? "All" : departmentCount) +
										" Department" +
										(departmentCount === 1 ? "" : "s") +
										",  " +
										(allRegions ? "All" : regionCount) +
										" Region" +
										(regionCount === 1 ? "" : "s")}
								</Text>
								<Collapse in={show} maxH="container.lg">
									<Stack px="0" py="1" spacing={1}>
										{departments?.map((department, i) => {
											let regions =
												department?.regions?.filter(
													(data) =>
														data?.hasAccess === true
												);
											regions = regions?.filter(
												(data) =>
													data?.showRegion === true
											);

											return (
												<HStack
													spacing={2}
													align="center"
													rounded="5"
													maxW="305px"
													isTruncated
												>
													<Text
														color="gray.500"
														fontWeight="semibold"
														textTransform="uppercase"
														fontSize="xs"
														isTruncated
													>
														{department.dept}
													</Text>
													<HStack
														spacing={1}
														align="center"
														isTruncated
													>
														{regions?.map(
															(regiontag, i) => (
																<Tag
																	size="sm"
																	p={1}
																	rounded={5}
																	color="white"
																	textAlign="center"
																	bg={
																		regionArr.find(
																			({
																				region,
																			}) =>
																				region ===
																				regiontag.region
																		).color
																	}
																	textTransform="uppercase"
																>
																	{
																		regiontag.region
																	}
																</Tag>
															)
														)}
													</HStack>
												</HStack>
											);
										})}
									</Stack>
								</Collapse>
							</Stack>
						</Stack>
					</HStack>
					<Collapse in={isHovering && show}>
						<Flex
							pt="2"
							maxW="305px"
							spacing={1}
							onClick={(ev) => {
								ev.stopPropagation();
								props.selectLeader(employee);
							}}
							justify="flex-start"
							fontWeight="semibold"
							cursor="pointer"
						>
							<Text
								_hover={{ color: "blue.500" }}
								color="gray.300"
								fontSize="xs"
								textTransform="uppercase"
								letterSpacing={1}
							>
								<i className={"fas fa-cog fa-fw"}></i> Edit
								Access
							</Text>
						</Flex>
					</Collapse>
				</Box>
			)}
		</>
	);
}
