import React, { useMemo } from "react";
import {
	Tooltip,
	Box,
	Text,
	Heading,
	VStack,
	Stack,
	HStack,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
} from "@chakra-ui/react";
import moment from "moment";
import { formatValue, log } from "../../../helperFunctions";

export default function Accounting(props) {
	const billings = useMemo(() => props.billings ?? [], [props.billings]);
	const retention = useMemo(() => props.retention ?? [], [props.retention]);

	return (
		<Stack w="full" spacing={4} px={4}>
			<HStack align="center">
				<Heading
					minW="25px"
					size={"md"}
					color={"gray.500"}
					textTransform="uppercase"
					isTruncated
					letterSpacing={2}
				>
					{["2", "4", "5"].includes(props.contract?.contractTypeID) ? "II." : "III."}
				</Heading>
				<Heading size={"md"} color={"gray.500"} textTransform="uppercase" isTruncated letterSpacing={2}>
					Accounting
				</Heading>
			</HStack>

			<VStack w="full" spacing={4}>
				<Box maxH={"xs"} className="skinnyScroll" overflow="auto" pr={1} w="full">
					<Table size="small" bg="white" top="0" left="0" color={"gray.600"} w="full">
						<Thead
							style={{
								borderSpacing: "0",
								insetBlockStart: "0",
								border: "none",
								position: "sticky",
								top: "0",
								left: "0",
								zIndex: "2",
							}}
						>
							<Tr
								borderColor="black"
								borderWidth={"0px"}
								textTransform="uppercase"
								bg={"teal.700"}
								style={{
									boxShadow:
										"inset 0 2px 0 #000000, inset 0 -2px 0 #000000, inset 2px 0 0 #000000, inset -2px 0 0 #000000",
								}}
							>
								<Th>
									<Heading fontSize="xs" color="white" isTruncated p="2" textAlign="center">
										Bill #
									</Heading>
								</Th>
								<Th>
									<Heading fontSize="xs" color="white" isTruncated p="2" textAlign="left">
										Month
									</Heading>
								</Th>
								<Th>
									<Heading fontSize="xs" color="white" isTruncated p="2" textAlign="right">
										Net Billing
									</Heading>
								</Th>
								<Th>
									<Heading fontSize="xs" color="white" isTruncated p="2" textAlign="right">
										Submitted
									</Heading>
								</Th>
								<Th>
									<Heading fontSize="xs" color="white" isTruncated p="2" textAlign="right">
										Due By
									</Heading>
								</Th>
								<Th>
									<Heading fontSize="xs" color="white" isTruncated p="2" textAlign="right">
										Paid On
									</Heading>
								</Th>
								<Th>
									<Heading fontSize="xs" color="white" isTruncated p="2" textAlign="center">
										Early (-) / Late (+)
									</Heading>
								</Th>
								<Th>
									<Heading fontSize="xs" color="white" isTruncated p="2" textAlign="center">
										Expected Collection
									</Heading>
								</Th>
							</Tr>
						</Thead>

						<Tbody borderSpacing="0" insetBlockEnd="0" border="none">
							{billings?.map((billing, i) => {
								var billingStyle = { bg: "white", hoverBG: "blackAlpha.100", label: null };
								var remainingAmt = billing?.getRemainingAmt() ?? 0;

								if (!Boolean(billing?.datePaidOn) && billing?.netBillingAmt !== 0) {
									billingStyle.bg = "red.100";
									billingStyle.hoverBG = "red.200";
									billingStyle.label =
										"Remaining Amount: " + formatValue(remainingAmt, 2, "currency");
								} else if (
									Boolean(billing?.datePaidOn) &&
									Boolean(remainingAmt) &&
									remainingAmt > 0 &&
									billing?.netBillingAmt !== 0
								) {
									billingStyle.bg = "yellow.100";
									billingStyle.hoverBG = "yellow.200";
									billingStyle.label =
										"Remaining Amount: " + formatValue(remainingAmt, 2, "currency");
								} else if (billing?.netBillingAmt === 0) {
									billingStyle.bg = "gray.100";
									billingStyle.hoverBG = "blackAlpha.200";
								}

								return (
									<Tr
										key={i}
										_hover={{ bg: billingStyle.hoverBG }}
										borderRightWidth={"0px"}
										borderLeftWidth={"0px"}
										borderColor="black"
										bg={billingStyle.bg}
										style={{
											boxShadow:
												"inset 2px 0 0 #000000, inset -2px 0 0 #000000, inset 0 -1px 0 #EDF2F7",
										}}
									>
										<Td borderBottomColor="blackAlpha.200" borderBottomWidth="1px">
											<Text isTruncated p="1" textAlign="center" fontSize="md">
												{billings.length - i}
											</Text>
										</Td>
										<Td borderBottomColor="blackAlpha.200" borderBottomWidth="1px">
											<Text isTruncated p="1" textAlign="left" fontSize="md">
												{moment(new Date(billing.mth)).format("MMM YYYY")}
											</Text>
										</Td>
										<Td borderBottomColor="blackAlpha.200" borderBottomWidth="1px">
											<Text isTruncated p="1" textAlign="right" fontSize="md">
												{formatValue(billing.netBillingAmt, 2, "currency")}
											</Text>
										</Td>
										<Td borderBottomColor="blackAlpha.200" borderBottomWidth="1px">
											<Text isTruncated p="1" textAlign="right" fontSize="md">
												{billing.dateSubmitted === null
													? ""
													: moment(new Date(billing.dateSubmitted)).format("M/D/YYYY")}
											</Text>
										</Td>
										<Td borderBottomColor="blackAlpha.200" borderBottomWidth="1px">
											<Text isTruncated p="1" textAlign="right" fontSize="md">
												{billing.dateDueBy == null
													? ""
													: moment(new Date(billing.dateDueBy)).format("M/D/YYYY")}
											</Text>
										</Td>
										<Td borderBottomColor="blackAlpha.200" borderBottomWidth="1px">
											<Text isTruncated p="1" textAlign="right" fontSize="md">
												{billing.datePaidOn == null
													? ""
													: moment(new Date(billing.datePaidOn)).format("M/D/YYYY")}
											</Text>
										</Td>
										<Td borderBottomColor="blackAlpha.200" borderBottomWidth="1px">
											<Text isTruncated p="1" textAlign="center" fontSize="md">
												{billing.earlyLate}
											</Text>
										</Td>
										<Td borderBottomColor="blackAlpha.200" borderBottomWidth="1px">
											<Tooltip label={billingStyle.label}>
												<Text isTruncated p="1" textAlign="center" fontSize="md">
													{billing.expectedCollection == null
														? ""
														: moment(new Date(billing.expectedCollection)).format(
																"M/D/YYYY"
														  )}
												</Text>
											</Tooltip>
										</Td>
									</Tr>
								);
							})}

							{retention?.map((retention, i) => {
								var billingStyle = { bg: "white", hoverBG: "blackAlpha.100", label: null };
								var remainingAmt = retention?.getRemainingAmt() ?? 0;

								if (!Boolean(retention?.datePaidOn && retention?.netBillingAmt !== 0)) {
									billingStyle.bg = "red.100";
									billingStyle.hoverBG = "red.200";
									billingStyle.label =
										"Remaining Amount: " + formatValue(remainingAmt, 2, "currency");
								} else if (
									Boolean(retention?.datePaidOn) &&
									Boolean(remainingAmt) &&
									remainingAmt > 0 &&
									retention?.netBillingAmt !== 0
								) {
									billingStyle.bg = "yellow.100";
									billingStyle.hoverBG = "yellow.200";
									billingStyle.label =
										"Remaining Amount: " + formatValue(remainingAmt, 2, "currency");
								} else if (retention?.netBillingAmt === 0) {
									billingStyle.bg = "gray.100";
									billingStyle.hoverBG = "blackAlpha.200";
								}
								return (
									<Tr
										key={i}
										_hover={{ bg: billingStyle.hoverBG }}
										borderRightWidth={"0px"}
										borderLeftWidth={"0px"}
										borderColor="black"
										bg={billingStyle.bg}
										style={{
											boxShadow:
												"inset 2px 0 0 #000000, inset -2px 0 0 #000000, inset 0 -1px 0 #EDF2F7",
										}}
									>
										<Td>
											<Text isTruncated p="1" textAlign="center" fontSize="md">
												RETN
											</Text>
										</Td>
										<Td>
											<Text isTruncated p="1" textAlign="left" fontSize="md">
												{moment(new Date(retention.mth)).format("MMM YYYY")}
											</Text>
										</Td>
										<Td>
											<Text isTruncated p="1" textAlign="right" fontSize="md">
												{formatValue(retention.netBillingAmt, 0, "currency")}
											</Text>
										</Td>
										<Td>
											<Text isTruncated p="1" textAlign="right" fontSize="md">
												{retention.dateSubmitted == null
													? ""
													: moment(new Date(retention.dateSubmitted)).format("M/D/YYYY")}
											</Text>
										</Td>
										<Td>
											<Text isTruncated p="1" textAlign="right" fontSize="md">
												{retention.dateDueBy == null
													? ""
													: moment(new Date(retention.dateDueBy)).format("M/D/YYYY")}
											</Text>
										</Td>
										<Td>
											<Text isTruncated p="1" textAlign="right" fontSize="md">
												{retention.datePaidOn == null
													? ""
													: moment(new Date(retention.datePaidOn)).format("M/D/YYYY")}
											</Text>
										</Td>
										<Td>
											<Text isTruncated p="1" textAlign="center" fontSize="md">
												{formatValue(retention.earlyLate, 0)}
											</Text>
										</Td>
										<Td>
											<Tooltip label={billingStyle.label}>
												<Text isTruncated p="1" textAlign="center" fontSize="md">
													{retention.expectedCollection == null
														? ""
														: moment(new Date(retention.expectedCollection)).format(
																"M/D/YYYY"
														  )}
												</Text>
											</Tooltip>
										</Td>
									</Tr>
								);
							})}
						</Tbody>

						<Tfoot
							style={{
								borderCollapse: "collapse",
								border: "none",
								position: "sticky",
								bottom: "0",
								left: "0",
								zIndex: "2",
								background: "white",
							}}
						>
							<Tr
								borderWidth={"0px"}
								borderColor="black"
								textTransform="uppercase"
								style={{
									boxShadow:
										"inset 0 2px 0 #000000, inset 0 0 0 #000000, inset 0 0 0 #000000, inset 0 0 0 #000000",
								}}
							>
								<Th colSpan={9} minH="2px">
									<Heading fontSize="sm" isTruncated p="0" textAlign="right"></Heading>
								</Th>
							</Tr>
						</Tfoot>
					</Table>
				</Box>
			</VStack>
		</Stack>
	);
}
