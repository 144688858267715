import React from "react";
import { useContext, useState, createContext } from "react";
import { Box, Text, Heading, Button, Icon, Collapse, useColorModeValue, Stack, HStack, Circle } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

export default function TimelineExample(props) {
	const { nextStep, prevStep, reset, activeStep } = useSteps({
		initialStep: 0,
	});
	return (
		<Box
			mx="auto"
			maxW="2xl"
			color="gray.500"
			py="10"
			px={{
				base: "6",
				md: "8",
			}}
		>
			<Steps activeStep={activeStep} minH="container.md">
				<Step title="Projects Saving Time & Cost">
					<StepContent>
						<Stack shouldWrapChildren spacing="4">
							<Text>
								For each ad campaign that you create, you can control how much you&apos;re willing to
								spend on clicks and conversions, which networks and geographical locations you want your
								ads to show on, and more.
							</Text>
							<HStack>
								<Button size="sm" variant="outline" isDisabled>
									Back
								</Button>
								<Button size="sm" onClick={nextStep}>
									Next
								</Button>
							</HStack>
						</Stack>
					</StepContent>
				</Step>
				<Step title="New Functionality & Upgrades">
					<StepContent>
						<Stack shouldWrapChildren spacing="4">
							<Text>An ad group contains one or more ads which target a shared set of keywords.</Text>
							<HStack>
								<Button size="sm" onClick={prevStep} variant="outline">
									Back
								</Button>
								<Button size="sm" onClick={nextStep}>
									Next
								</Button>
							</HStack>
						</Stack>
					</StepContent>
				</Step>
				<Step title="Team Bench Strength & Cross Training">
					<StepContent>
						<Stack shouldWrapChildren spacing="4">
							<Text>
								Try out different ad text to see what brings in the most customers, and learn how to
								enhance your ads using features like ad extensions. If you run into any problems with
								your ads, find out how to tell if they&apos;re running and how to resolve approval
								issues.
							</Text>
							<HStack>
								<Button size="sm" onClick={prevStep} variant="outline">
									Back
								</Button>
								<Button size="sm" onClick={nextStep}>
									Finish
								</Button>
							</HStack>
						</Stack>
					</StepContent>
				</Step>
			</Steps>
			<HStack display={activeStep === 3 ? "flex" : "none"} mt="10" spacing="4" shouldWrapChildren>
				<Button size="sm" onClick={reset} variant="outline" verticalAlign="baseline">
					Reset
				</Button>
			</HStack>
		</Box>
	);
}

const Steps = (props) => {
	const { activeStep, children } = props;
	const steps = React.useMemo(
		() =>
			React.Children.toArray(children).map((step, i, arr) => (
				<StepContext.Provider
					key={i}
					value={{
						isActive: activeStep === i,
						isCompleted: activeStep > i,
						isLastStep: arr.length !== i + 1,
						step: i + 1,
					}}
				>
					{step}
					{arr.length !== i + 1 && <StepConnector />}
				</StepContext.Provider>
			)),
		[activeStep, children]
	);
	return <>{steps}</>;
};

const useSteps = (options) => {
	const { initialStep } = options;
	const [activeStep, setActiveStep] = useState(initialStep);

	const nextStep = () => setActiveStep(activeStep + 1);

	const prevStep = () => setActiveStep(activeStep - 1);

	const reset = () => setActiveStep(0);

	return {
		nextStep,
		prevStep,
		reset,
		activeStep,
	};
};

const useStep = () => {
	const context = useContext(StepContext);

	if (!context) {
		throw Error("Wrap your step with `<Steps />`");
	} else {
		return context;
	}
};

const Step = (props) => {
	const { title, children, ...boxProps } = props;
	const { isActive, isCompleted, step } = useStep();
	const accentColor = useColorModeValue("teal.500", "teal.300");
	const mutedColor = useColorModeValue("gray.400", "whiteAlpha.800");
	const activeColor = useColorModeValue("white", "black");
	return (
		<Box {...boxProps}>
			<HStack spacing="4">
				<Circle
					size="8"
					fontWeight="bold"
					color={isActive ? activeColor : isCompleted ? accentColor : mutedColor}
					bg={isActive ? accentColor : "transparent"}
					borderColor={isCompleted ? accentColor : "inherit"}
					borderWidth={isActive ? "0px" : "1px"}
				>
					{isCompleted ? <Icon as={CheckIcon} /> : step}
				</Circle>
				<Heading
					fontSize="lg"
					fontWeight="semibold"
					textTransform="uppercase"
					color={isActive || isCompleted ? "inherit" : mutedColor}
				>
					{title}
				</Heading>
			</HStack>
			<Collapse in={isActive}>{children}</Collapse>
		</Box>
	);
};

const StepContext = createContext(null);

const StepContent = (props) => {
	const { isLastStep } = useStep();
	return (
		<Box
			color={useColorModeValue("gray.600", "gray.400")}
			borderStartWidth={isLastStep ? "1px" : "0"}
			ms="4"
			mt="2"
			ps="8"
			pb="3"
			fontSize="sm"
			{...props}
		/>
	);
};

const StepConnector = () => {
	const { isCompleted, isActive } = useStep();
	const accentColor = useColorModeValue("teal.500", "teal.300");
	return (
		<Box
			borderStartWidth="1px"
			borderStartColor={isCompleted ? accentColor : "inherit"}
			height="6"
			mt={isActive ? "0" : "2"}
			mb="2"
			ms="4"
			ps="4"
		/>
	);
};
