import React, { useMemo, useState, useEffect, useCallback } from "react";
import { VStack, Text, Heading, Stack, HStack, Table, Thead, Tbody, Tr, Td } from "@chakra-ui/react";
import DataInput from "../../../core/Inputs/data/DataInput";
import { v4 } from "uuid";

export default function CloseOut(props) {
	const contract = useMemo(() => props.contract, [props.contract]);
	const [closeOut, setCloseOut] = useState([]);

	const updateDates = useCallback(
		async (attribute, e) => {
			if (e !== contract[attribute]) {
				await contract.update(attribute, e);
				setCloseOut(contract.getCloseOutDates());
			}
		},
		[contract]
	);

	useEffect(() => setCloseOut(contract?.getCloseOutDates() ?? null), [contract]);

	return (
		<Stack w="full" spacing={0} px={4}>
			<HStack align="center">
				<Heading
					minW="25px"
					size={"md"}
					color={"gray.500"}
					textTransform="uppercase"
					isTruncated
					letterSpacing={2}
				>
					{["2", "4", "5"].includes(props.contract?.contractTypeID) ? "V." : "VI."}
				</Heading>
				<Heading size={"md"} color={"gray.500"} textTransform="uppercase" isTruncated letterSpacing={2}>
					Close Out
				</Heading>
			</HStack>

			<VStack w="full" spacing={4} px={8}>
				<Table size="small" bg="white" color={"gray.600"} w="full">
					<Thead>
						<Tr>
							<Td border="0px" colSpan="2"></Td>
							<Td border="0px" maxW="175px" w="150px">
								<Heading
									textTransform="uppercase"
									fontSize="sm"
									color={"teal.700"}
									isTruncated
									p="2"
									textAlign="center"
								>
									Due Date
								</Heading>
							</Td>
							<Td border="0px" maxW="200px" w="200px" />
							<Td border="0px" maxW="175px" w="150px">
								<Heading
									textTransform="uppercase"
									fontSize="sm"
									color={"teal.700"}
									isTruncated
									p="2"
									textAlign="center"
								>
									Actual Date
								</Heading>
							</Td>
							<Td border="0px"></Td>
						</Tr>
					</Thead>
					<Tbody>
						{closeOut?.map((row, i) => (
							<Tr key={i}>
								<Td border="0px">
									<HStack>
										<Heading
											textTransform="uppercase"
											fontSize="sm"
											color={"teal.700"}
											isTruncated
											p="1"
											letterSpacing={1}
										>
											{row.letter}
										</Heading>
										<Heading
											textTransform="uppercase"
											fontSize="sm"
											color={"teal.700"}
											isTruncated
											p="1"
											letterSpacing={1}
										>
											{row.name}
										</Heading>
									</HStack>
								</Td>
								<Td border="0px">
									<Text
										textAlign="right"
										fontSize="sm"
										color={"gray.500"}
										fontWeight="semibold"
										px="2"
										letterSpacing={1}
									>
										{row.letter[0] === "E" ? "STARTS:" : ""}
									</Text>
								</Td>
								<Td maxW="150px" borderBottomColor={"gray.600"} borderBottomWidth={1}>
									<DataInput
										key={v4()}
										variant="unstyled"
										onChange={(ev) => {
											updateDates(row.dueColumn, ev);
										}}
										inputProps={{
											color: Boolean(row.dueDate) ? "gray.600" : "gray.400",
											py: "1",
											fontSize: "md",
										}}
										placeholder="mm/dd/yyyy"
										type="date"
										size="sm"
										inputBG="white"
										value={Boolean(row.dueDate) ? row.dueDate : null}
										// showPlaceholder
									/>
								</Td>
								<Td border="0px" maxW="150px">
									<Text
										textAlign="right"
										fontSize="sm"
										color={"gray.500"}
										fontWeight="semibold"
										px="2"
										letterSpacing={1}
									>
										{row.letter[0] === "E" ? "EXPIRES:" : ""}
									</Text>
								</Td>
								<Td maxW="150px" justify="center" borderBottomColor={"gray.600"} borderBottomWidth={1}>
									<DataInput
										key={v4()}
										variant="unstyled"
										onChange={(ev) => {
											updateDates(row.actColumn, ev);
										}}
										inputProps={{
											color: Boolean(row.actDate) ? "gray.600" : "gray.400",
											py: "1",
											fontSize: "md",
										}}
										placeholder="mm/dd/yyyy"
										type="date"
										size="sm"
										inputBG="white"
										value={Boolean(row.actDate) ? row.actDate : null}
										// showPlaceholder
									/>
								</Td>
								<Td border="0px"></Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</VStack>
		</Stack>
	);
}
