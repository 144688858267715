import React from "react";
import { Box, Flex, Button, Container, Stack, Heading, HStack, Input, Switch, Collapse } from "@chakra-ui/react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import MainContent from "../../legacy/App/components/MainContent";
import DevOpsAPI from "../../APIs/DevOpsAPI";
import EmbedLink from "./classes/EmbedLink";

import { portalUser } from "../../App";
import moment from "moment";

// import { log } from "../../helperFunctions";

export default class PowerBIDashboard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			api: new DevOpsAPI(),
			tabVal: 0,

			embedLinks: null,
			activeLink: null,
			previewLink: null,

			tab: props.tab ?? null,
			showArchived: false,
		};

		this.init = this.init.bind(this);
		this.handleTabChange = this.handleTabChange.bind(this);
		this.addEmbedLink = this.addEmbedLink.bind(this);
		this.updateTitle = this.updateTitle.bind(this);
		this.updateFileName = this.updateFileName.bind(this);
		this.updateURL = this.updateURL.bind(this);
		this.showPreview = this.showPreview.bind(this);
		this.toggleShowArchived = this.toggleShowArchived.bind(this);
	}

	componentDidMount() {
		this.init();
	}

	async init() {
		let tab = "Productivity";
		let embedLinks = await this.state.api.GetPowerBILinks();

		if (embedLinks.status === 200) {
			embedLinks = embedLinks.value;
			embedLinks = embedLinks.filter((d) => d.Tab === tab) ?? [];
			let activeLinks = [];
			let embeddedLinks = [];
			for (let i = 0; i < embedLinks?.length; i++) {
				let embedLink = new EmbedLink(embedLinks[i]);
				embeddedLinks.push(embedLink);

				if (Boolean(embedLink.isActive)) {
					activeLinks.push(embedLink);
				}
			}

			this.setState({
				embedLinks: embeddedLinks,
				activeLink: activeLinks[0],
				previewLink: activeLinks[0],
				tab: tab,
			});
		}
	}

	toggleShowArchived() {
		this.setState({
			showArchived: !this.state.showArchived,
		});
	}

	async updateTitle(e, reportUID) {
		let value = e.target.value;
		let embedLinks = this.state.embedLinks;
		for (let i = 0; i < embedLinks?.length; i++) {
			if (embedLinks[i].reportUID === reportUID) {
				await embedLinks[i].updateTitle(value);
				break;
			}
		}
		this.setState({
			embedLinks: embedLinks,
		});
	}

	async updateFileName(e, reportUID) {
		let value = e.target.value;
		let embedLinks = this.state.embedLinks;
		for (let i = 0; i < embedLinks?.length; i++) {
			if (embedLinks[i].reportUID === reportUID) {
				await embedLinks[i].updateFileName(value);
				break;
			}
		}
		this.setState({
			embedLinks: embedLinks,
		});
	}

	async updateURL(e, reportUID) {
		let value = e.target.value;
		let embedLinks = this.state.embedLinks;
		for (let i = 0; i < embedLinks?.length; i++) {
			if (embedLinks[i].reportUID === reportUID) {
				await embedLinks[i].updateURL(value);
				break;
			}
		}
		this.setState({
			embedLinks: embedLinks,
		});
	}

	showPreview(embedLink) {
		this.setState({
			previewLink: embedLink,
		});
	}

	async isActive(e, reportUID) {
		e.stopPropagation();
		let value = e.target.checked;
		let embedLinks = this.state.embedLinks;
		let activeLinkIndex = 0;
		for (let i = 0; i < embedLinks?.length; i++) {
			if (embedLinks[i].isActive) {
				await embedLinks[i].updateIsActive(false);
			}
			if (embedLinks[i].reportUID === reportUID) {
				await embedLinks[i].updateIsActive(value);
				activeLinkIndex = i;
			}
		}

		this.setState({
			embedLinks: embedLinks,
			activeLink: embedLinks[activeLinkIndex],
		});
	}

	handleTabChange(tabVal) {
		this.setState({ tabVal: tabVal });
	}

	async addEmbedLink() {
		let embedLinks = this.state.embedLinks;
		let embedLink = new EmbedLink({ tab: this.state.tab, isArchived: false });

		await embedLink.upload();
		embedLinks.push(embedLink);
		this.setState({
			embedLinks: embedLinks,
		});
	}

	render() {
		// let bannerTitle = (
		// 	<div>
		// 		<i className={this.props.pageIcon}></i>
		// 		{this.props.pageName}
		// 	</div>
		// );

		let tabs = ["Preview", "Embed Links"];

		return (
			<MainContent
				title={"Preview & Embed Reports"}
				// subtitle="Preview & Embed Reports"
				tabs={tabs}
				onTabChange={this.handleTabChange}
				tabVal={this.state.tabVal}
			>
				<Container p="4" maxW="9xl" bg={portalUser.styles?.pageBG} h="full">
					<Container minH="100%" maxW={{ base: "100%", xl: "9xl" }} p={4}>
						{this.state.tabVal === 0 && (
							<Stack p={4} px="10" spacing={3}>
								<Heading letterSpacing={2} textTransform={"uppercase"} color="gray.500">
									{this.state.activeLink?.title}
								</Heading>
								<Box w="full" height="78vh" bg="white" rounded="lg" shadow="lg">
									<iframe
										title="PowerBI-Iframe"
										src={this.state.activeLink?.url}
										width="100%"
										height="100%"
										padding={"8px"}
										style={{
											borderRadius: "var(--chakra-radii-lg)",
											boxShadow: "var(--chakra-shadows-lg)",
											backgroundColor: "white",
										}}
									/>
								</Box>
							</Stack>
						)}

						{this.state.tabVal === 1 && (
							<Container maxW="7xl" flex={1} minH="5xl">
								<Box w="full" minH="5xl">
									<Heading
										py="4"
										color="gray.500"
										variant="title"
										fontWeight="bold"
										size="xl"
										letterSpacing={2}
										textTransform="uppercase"
									>
										Embed Links
									</Heading>
									{this.state.embedLinks?.map((embedLink, i) => (
										<Box>
											<Collapse
												h="100%"
												in={
													(Boolean(
														this.state.previewLink.reportUID === embedLink.reportUID
													) &&
														!Boolean(this.state.showArchived)) ||
													Boolean(this.state.showArchived)
												}
											>
												<Box
													key={embedLink.reportUID}
													bg="white"
													p="4"
													mt="1"
													mb="4"
													shadow="lg"
													rounded="10"
													cursor="pointer"
													onClick={(e) => {
														this.showPreview(embedLink);
													}}
												>
													<Stack direction="row" spacing="4">
														<Stack spacing="1" flex={3}>
															<Heading
																color="teal.500"
																size="sm"
																textTransform="uppercase"
																isTruncated
															>
																Report Title
															</Heading>
															<Input
																onBlur={(e) => {
																	this.updateTitle(e, embedLink.reportUID);
																}}
																colorScheme="teal"
																bg="white"
																defaultValue={embedLink.title}
																placeholder="Report Title"
															/>
														</Stack>
														<Stack spacing="1" flex={3}>
															<Heading
																color="teal.500"
																size="sm"
																textTransform="uppercase"
																isTruncated
															>
																File Name
															</Heading>
															<Input
																onBlur={(e) => {
																	this.updateFileName(e, embedLink.reportUID);
																}}
																colorScheme="teal"
																bg="white"
																defaultValue={embedLink.fileName}
																placeholder="Embed Url"
															/>
														</Stack>
														<Stack spacing="1" flex={2}>
															<Heading
																color="teal.500"
																size="sm"
																textTransform="uppercase"
																isTruncated
															>
																Date Created
															</Heading>
															<Input
																colorScheme="teal"
																bg="white"
																isReadOnly
																defaultValue={moment(embedLink.dateCreated).format(
																	"M/D/yyyy, hh:mm A"
																)}
																placeholder="mm/dd/yyyy"
															/>
														</Stack>
														<Stack spacing="1" flex={1} align="center">
															<Heading
																color="teal.500"
																size="sm"
																textTransform="uppercase"
																isTruncated
															>
																Active
															</Heading>
															<Switch
																align="center"
																isChecked={embedLink.isActive}
																onChange={(e) => {
																	this.isActive(e, embedLink.reportUID);
																}}
																colorScheme="teal"
															/>
														</Stack>
													</Stack>
													<Stack spacing="1" my="4" flex={1}>
														<Heading
															color="teal.500"
															size="sm"
															textTransform="uppercase"
															isTruncated
														>
															Embed Link
														</Heading>
														<TextareaAutosize
															rowsMin="3"
															onBlur={(e) => {
																this.updateURL(e, embedLink.reportUID);
															}}
															style={{
																border: "1px solid #E2E8F0",
																borderRadius: "5px",
																paddingLeft: "10px",
																paddingRight: "10px",
															}}
															colorScheme="teal"
															bg="white"
															defaultValue={embedLink.url}
															placeholder="Embed Url"
														/>
													</Stack>

													<Collapse
														in={this.state.previewLink.reportUID === embedLink.reportUID}
													>
														<Box rounded="10" w="full" color="gray.400" p="4">
															<Heading
																mb="4"
																color="teal.500"
																size="sm"
																textTransform="uppercase"
																isTruncated
															>
																Preview
															</Heading>
															<Box
																height="50vh"
																minH="xl"
																bg="blackAlpha.50"
																rounded="10"
																border="2px dashed"
															>
																<iframe
																	title={"PowerBi-EmbedLink" + i}
																	style={{ borderRadius: "5px" }}
																	src={embedLink.url}
																	width="100%"
																	height="100%"
																/>
															</Box>
														</Box>
													</Collapse>
												</Box>
											</Collapse>
										</Box>
									))}

									<Flex w="full" align="flex-end" justify="flex-end">
										<HStack spacing={4}>
											<HStack spacing={2}>
												<Heading size="xs" color="gray.400" textTransform="uppercase">
													{(this.state.showArchived ? "Hide " : "Show ") + "Archived"}
												</Heading>
												<Switch
													size="lg"
													align="center"
													isChecked={this.state.showArchived}
													onChange={(e) => {
														this.toggleShowArchived();
													}}
													label={"Show Archived"}
													colorScheme="blue"
												/>
											</HStack>
											<Button
												onClick={this.addEmbedLink}
												colorScheme="green"
												textTransform="uppercase"
											>
												Add Link
											</Button>
										</HStack>
									</Flex>
								</Box>
							</Container>
						)}
					</Container>
				</Container>
			</MainContent>
		);
	}
}
