import React from "react";
import { Flex } from "@chakra-ui/react";

import IFrame from "../../core/Layout/modals/IFrame";

import MainContent from "../../legacy/App/components/MainContent";

export default function EmbedPowerApp({
	title = "PE Review Form",
	baseURL = "https://web.powerapps.com/webplayer/iframeapp?source=iframe&appId=/providers/Microsoft.PowerApps/apps/",
	appID = "f464da40-32c3-4d29-9d63-45369d49612a",
}) {
	return (
		<MainContent subtitle={"Power Apps "} title="Socal Operations PE Mid-Year Review">
			<Flex bg="blackAlpha.500" direction="row" flex={1} justify="flex-start" h="full" py={4} px={4}>
				<IFrame variant={"powerApp"} url={baseURL + appID} />
			</Flex>
		</MainContent>
	);
}
