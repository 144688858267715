import * as React from "react";
import { chakra } from "@chakra-ui/react";

export default function ReferralsSVG() {
	const st0 = "#374069";
	const st1 = "#323C65";
	const st2 = "#343E67";
	const st3 = "#0D1449";
	const st4 = "#071244";
	const st5 = "#262C58";
	const st6 = "#192355";
	const st7 = "#10144C";
	const st8 = "#11194F";
	const st9 = "#172155";
	const st10 = "#10164D";
	const st11 = "#EB7282";
	const st12 = "#EA7584";
	const st13 = "#E67180";
	const st14 = "#E57181";
	const st15 = "#7D849D";
	const st16 = "#EB7080";
	const st17 = "#7B819A";
	const st18 = "#EA7282";
	const st19 = "#EB6D7F";
	const st20 = "#777D98";
	const st21 = "#EB7584";
	const st22 = "#EA7180";
	const st23 = "#787F9A";
	const st24 = "#EB6E7E";
	const st25 = "#EB7182";
	const st26 = "#010B39";
	const st27 = "#0D1043";
	const st28 = "#E87B89";
	const st29 = "#1E2850";
	const st30 = "#E96E80";
	const st31 = "#6D758E";
	const st32 = "#ED6B7D";
	const st33 = "#FEBA89";
	const st34 = "#B8E3F1";
	const st35 = "#E95B6B";
	const st36 = "#FDBA89";
	const st37 = "#FDCBA1";
	const st38 = "#6FA89F";
	const st39 = "#FEFEFE";
	const st40 = "#E95A6A";
	const st41 = "#DC4963";
	const st42 = "#6DA59D";
	const st43 = "#FBDFC2";
	const st44 = "#FBE0C3";
	const st45 = "#BAE5F3";
	const st46 = "#EC7A88";
	const st47 = "#283565";
	const st48 = "#9AC2BD";
	const st49 = "#A0DEF4";
	const st50 = "#FED895";
	const st51 = "#AA8B79";
	const st52 = "#689998";
	const st53 = "#001D60";
	const st54 = "#1F1E4E";
	const st55 = "#0E1445";
	const st56 = "#0A2465";
	const st57 = "#042159";
	const st58 = "#71ABA1";
	const st59 = "#6E9BB8";
	const st60 = "#0B3A61";
	const st61 = "#A69AA0";
	const st62 = "#063D6B";
	const st63 = "#93EEFE";
	const st64 = "#02134B";
	const st65 = "#02124A";
	const st66 = "#061E5D";
	const st67 = "#111F54";
	const st68 = "#111F55";
	const st69 = "#051D5C";
	const st70 = "#031F5A";
	const st71 = "#04205D";
	const st72 = "#062F67";
	const st73 = "#0B3F6B";
	const st74 = "#0F4364";
	const st75 = "#99EFFD";
	const st76 = "#0C3E69";
	const st77 = "#1C204C";
	const st78 = "#221B49";
	const st79 = "#161644";
	const st80 = "#041956";
	const st81 = "#618EAB";
	const st82 = "#031C5D";
	const st83 = "#201A49";
	const st84 = "#111340";
	const st85 = "#FCCCA1";
	const st86 = "#FAE0C2";
	const st87 = "#F9D5AE";
	const st88 = "#F7CEA2";
	const st89 = "#F8E4C6";
	const st90 = "#374169";
	const st91 = "#FCDFC2";
	const st92 = "#80CBE2";
	const st93 = "#9FDDF4";
	const st94 = "#FEFED9";
	const st95 = "#80CBE1";
	const st96 = "#F8D7B0";

	return (
		<chakra.svg viewBox="50 0 550 475" maxH="125px">
			<g>
				<path
					fill={st0}
					d="M497.89,139.23c6.69,2.79,10.6,7.79,11.91,14.89c-0.83,11.79-0.23,23.6-0.34,35.39
          c-0.04,4.81-0.42,9.64,0.27,14.44c-1.42,7.75-6.16,12.76-13.4,15.22c-3.04,1.03-3.85,2.36-3.85,5.54
          c0.12,68.87,0.09,137.74,0.06,206.61c0,1.97,0.45,3.99-0.43,5.9c-1.08-1.12-0.64-2.52-0.64-3.8c-0.02-69.57-0.02-139.14-0.02-208.7
          c0-0.71-0.02-1.41-0.05-1.35c-0.22-1.42-1.12-0.42-0.59-0.83c0.17-0.13,0.22,0.01,0.35,0.37c0.59,1.61,0.24,3.27,0.24,4.9
          c0.02,68.74,0.02,137.47,0.04,206.21c0,1.97-0.41,3.99,0.4,5.92c-0.63,6.16-3.77,10.72-8.92,14.01
          c-7.06,3.68-14.68,3.55-22.37,3.55c-109.02-0.06-218.04-0.04-327.06-0.03c-4.15,0-8.3,0.06-12.42-0.57
          c-8.28-3.25-14.1-8.52-14.92-17.97c0.6-1.08,0.4-2.27,0.4-3.42c0.01-31.88,0.01-63.75-0.01-95.63c0-1.3,0.33-2.67-0.5-3.87
          c-0.51-2.06-2.53-1.94-3.93-2.62c-6.91-3.37-11.14-8.64-11.9-16.44c0.61-12.95,0.53-25.89,0.04-38.84
          c0.87-6.23,3.73-11.14,9.43-14.15c4.96-2.31,10.21-2.48,15.58-2.45c19.15,0.12,38.3,0.07,57.45,0.02c1.78,0,3.66,0.57,5.34-0.55
          c1.32-2.61-1.47-2.16-2.32-2.72c-8.24-5.39-16.32-10.93-22.1-19.14c-6.99-9.93-6.09-19.32,2.58-27.71
          c12.61-12.19,27.48-11.9,39.45,0.83c6.23,6.62,10.53,14.51,14.13,22.75c1.15,2.62,2.23,3.78,5.11,2.55
          c1.66-0.71,3.57-1.42,5.3-1.28c6.71,0.53,6.89-3.29,6.97-8.52c0.08-5.01-0.76-7.83-6.1-9.57c-5.94-1.93-9.59-6.86-10.94-13.16
          c-0.18-12.54-0.07-25.07-0.15-37.61c-0.03-4.76,0.26-9.52,0.11-14.29c-0.03-1.68,0.56-3.12,1.68-4.36c1.99-1.31,2.9-3.58,4.65-5.12
          c3.21-2.82,6.68-5.01,11.08-5.02c25.09-0.04,50.18-0.02,75.27-0.03c-24.77-0.02-49.54-0.01-74.31-0.02c-0.69,0-1.3-0.14-1.79-0.67
          c2-1.21,4.23-0.69,6.34-0.69c23.8-0.05,47.6-0.03,72.41-0.03c-4.85-3.26-9.05-5.97-13.12-8.85c-7.51-5.3-14.53-11.14-19.5-19.04
          c-7.12-11.31-5.9-21.71,3.56-31.14c15.76-15.71,34.32-15.13,49.05,1.53c7.35,8.31,12.67,17.89,16.93,28.03
          c1.43,3.41,2.89,4.5,6.38,2.93c1.92-0.86,4.16-0.98,6.26-1.43c5.65-0.09,11.28-0.67,16.92-1c2.68,0.58,3.2-1.41,4.04-3.24
          c4.34-9.39,9.38-18.33,16.38-26.08c18.67-20.69,40.2-11.93,51.96,3.52c5.67,7.45,5.25,15.85,1.14,24.05
          c-4.52,9.03-11.85,15.56-19.63,21.63c-3.8,2.96-7.84,5.63-11.86,8.49c0.55,1.47,1.93,0.53,2.84,0.53
          c23.32,0.06,46.65,0.03,69.97,0.07c2.12,0,4.35-0.49,6.34,0.78c-0.99,0.89-2.21,0.58-3.34,0.58c-24.27,0.02-48.54,0.02-72.8-0.02
          c-0.91,0-2.13,0.66-2.98-1.06c0.87,1.88,2.2,1.08,3.15,1.08c23.44,0.06,46.87,0.06,70.31,0.03
          C490.97,138.55,494.44,138.58,497.89,139.23z"
				/>
				<path
					fill={st1}
					d="M151.57,180.11c-2.76,0.19-2.62-1.74-2.83-3.63c-1.32-12.19-7.55-20.66-19.25-24.73
          c-2.32-0.81-4.84-1.14-7.3-1.39c-1.87-0.19-3.3-0.47-3.2-2.78c0.09-1.97,1.42-2.31,3.03-2.55c17.17-2.63,23.53-8.99,26.74-26.82
          c0.3-1.68,0.76-2.99,2.65-2.89c1.72,0.09,2.42,1.16,2.7,2.94c2.8,17.52,8.95,23.68,26.64,26.77c1.67,0.29,2.94,0.82,3.01,2.65
          c0.09,2.28-1.5,2.6-3.34,2.66c-12.18,0.45-23.27,9.19-25.91,22.3c-0.32,1.61-0.35,3.29-0.47,4.94
          C153.93,179.15,153.17,180.03,151.57,180.11z"
				/>
				<path
					fill={st2}
					d="M489.44,40.89c7.5,0,13.31,5.67,13.37,13.06c0.06,7.25-5.92,13.25-13.26,13.31
          c-7.57,0.06-13.74-6.04-13.61-13.45C476.07,46.48,481.9,40.89,489.44,40.89z"
				/>
				<path
					fill={st2}
					d="M250.62,71.33c-7.55-0.08-13.38-5.77-13.44-13.1c-0.06-7.53,5.99-13.41,13.71-13.34
          c7.36,0.07,13.21,6.02,13.14,13.37C263.95,65.64,258.04,71.4,250.62,71.33z"
				/>
				<path
					fill={st3}
					d="M121.08,456.88c2.7-1.81,5.36,0.2,8.05,0.2c112.78-0.15,225.55-0.13,338.33-0.11c4.24,0,8.2-0.82,12.01-2.7
          c1.01-0.5,2.15-2.01,3.46-0.34c-4.22,3.47-9.19,4.24-14.47,4.24c-91.38-0.03-182.77-0.03-274.15-0.04
          c-21.14,0-42.28,0.02-63.42-0.01C127.58,458.12,124.2,458.45,121.08,456.88z"
				/>
				<path
					fill={st4}
					d="M491.85,439.92c-1.41-1.16-0.88-2.77-0.88-4.2c-0.02-70.59-0.01-141.18,0-211.77c0-0.97,0.11-1.93,0.79-2.89
          c0,1.97,0,3.94,0,5.91c0,67.76,0,135.52,0.02,203.28c0,2.32,0.23,4.64,0.35,6.95C492.03,438.12,491.94,439.02,491.85,439.92z"
				/>
				<path
					fill={st5}
					d="M118.06,67.66c0-7.1,6.04-13.15,13.25-13.25c7.12-0.1,13.38,6.05,13.45,13.22
          c0.07,7.54-6.22,13.55-14.07,13.44C123.43,80.96,118.06,75.26,118.06,67.66z"
				/>
				<path
					fill={st6}
					d="M226.71,137.86c24.13,0.08,48.25,0.16,72.38,0.23c1.96,0.01,3.93,0,5.88,0.96c-1.95,0-3.9,0-5.85,0
          c-21.97,0-43.94,0.27-65.9-0.14c-8.06-0.15-14.19,2.35-19.14,8.49c-0.46,0.57-0.51,2.73-2.29,1.31
          C214.8,142.4,219.79,138.8,226.71,137.86z"
				/>
				<path
					fill={st7}
					d="M106.06,336c1.59,1.28,0.97,3.07,0.98,4.64c0.04,31.36,0.04,62.72,0.02,94.08c0,1.44,0.48,3.03-0.89,4.2
          c-0.09-15.82-0.24-31.64-0.25-47.46C105.9,372.97,106.01,354.48,106.06,336z"
				/>
				<path
					fill={st8}
					d="M210.12,153.08c1.13,0.83,0.91,2.06,0.91,3.2c0.01,15.16,0.01,30.31,0,45.47c0,1.15,0.2,2.36-0.87,3.23
          c-1.37-4.02-0.86-8.19-0.87-12.3c-0.05-11.41-0.04-22.82,0.04-34.24C209.33,156.65,209.84,154.87,210.12,153.08z"
				/>
				<path
					fill={st9}
					d="M497.89,139.23c-2.89,1.57-5.7-0.31-8.59-0.29c-23.78,0.21-47.56,0.11-71.34,0.11c-1.63,0-3.26,0-4.88,0
          c0-0.37,0-0.74,0-1.11c26.69,0,53.37,0,80.06,0C494.87,137.8,496.4,138.44,497.89,139.23z"
				/>
				<path
					fill={st10}
					d="M178.07,260.95c-1.39,1.81-3.37,1.03-5.09,1.04c-17.9,0.06-35.8,0.18-53.7-0.04
          c-6.65-0.08-13.15,0.47-19.59,1.99c2.94-2.48,6.52-3.27,10.17-3.29c22.08-0.08,44.16-0.04,66.24-0.02
          C176.76,260.62,177.41,260.83,178.07,260.95z"
				/>
				<path
					fill={st11}
					d="M192.98,82.11c3.03,1.63,3.14,4.99,3.88,7.87c0.41,1.61-0.89,3.24-2.54,2.77c-3.72-1.06-3.36-4.85-4.18-7.68
          C189.69,83.49,190.94,82.36,192.98,82.11z"
				/>
				<path
					fill={st12}
					d="M481.76,97.95c-0.09,1.91-1.04,3.38-2.66,2.98c-3.82-0.96-3.45-4.72-4.18-7.56
          c-0.41-1.59,0.71-3.25,2.44-2.83C481.23,91.48,480.2,95.61,481.76,97.95z"
				/>
				<path
					fill={st13}
					d="M108.16,229.14c-2.42,0.12-4.02-0.64-3.62-2.4c0.88-3.95,4.92-3.25,7.64-4.33c1.35-0.53,3.2,0.78,2.88,2.52
          C114.28,229.16,109.95,227.62,108.16,229.14z"
				/>
				<path
					fill={st14}
					d="M119.21,215.33c0.14,2-0.5,3.68-2.21,3.46c-4.16-0.54-3.38-4.66-4.49-7.3c-0.67-1.58,0.77-3.3,2.22-3.12
          C118.89,208.92,118.07,212.98,119.21,215.33z"
				/>
				<path
					fill={st15}
					d="M358.65,71.05c3.13,1.69,3.24,5.05,3.94,7.92c0.38,1.56-1.15,3.16-2.69,2.69c-3.68-1.12-3.28-4.87-4.01-7.72
          C355.45,72.22,356.84,71.35,358.65,71.05z"
				/>
				<path
					fill={st16}
					d="M470.37,111.2c-2.11,0.19-3.71-0.45-3.35-2.28c0.8-4.04,4.78-3.33,7.52-4.32c1.4-0.5,2.91,0.77,2.74,2.11
          C476.75,110.99,472.65,110.05,470.37,111.2z"
				/>
				<path
					fill={st17}
					d="M370.6,63.24c-0.4,2-1.67,2.67-3.23,2.98c-2.16,0.43-4.18,2.53-6.54,0.69c-1.07-0.83-1.31-2.32-0.42-3.1
          c2.44-2.14,5.4-3.2,8.69-2.65C370.16,61.34,370.51,62.44,370.6,63.24z"
				/>
				<path
					fill={st18}
					d="M203.42,97.08c-2.23-0.13-3.45-1.52-2.92-2.89c1.33-3.42,5.03-3.19,7.87-3.96c1.53-0.42,3.04,1.16,2.47,2.78
          C209.58,96.61,205.7,95.78,203.42,97.08z"
				/>
				<path
					fill={st19}
					d="M203.03,107.81c-0.08,2.09-0.89,3.64-2.64,3.13c-3.73-1.08-3.33-4.87-4.17-7.7
          c-0.43-1.44,1.04-2.75,2.37-2.52C202.76,101.45,201.58,105.64,203.03,107.81z"
				/>
				<path
					fill={st18}
					d="M488.67,105.17c-1.96,0.06-3.74-0.48-3.47-2.27c0.64-4.17,4.84-3.28,7.5-4.45c1.49-0.65,3.16,0.9,2.87,2.52
          C494.87,104.94,490.71,103.61,488.67,105.17z"
				/>
				<path
					fill={st20}
					d="M356.5,60.41c-0.03,2.09-1.19,3.2-2.82,2.74c-3.59-1.03-3.03-4.67-3.87-7.31c-0.51-1.6,0.56-3.24,2.37-2.82
          C356.14,53.94,355.06,58.06,356.5,60.41z"
				/>
				<path
					fill={st21}
					d="M192.81,98.85c-1.14,3.44-4.56,3.26-7.23,4.14c-1.57,0.52-3.4-0.16-3.29-1.96c0.28-4.46,4.66-3.33,7.22-4.58
          C191.03,95.7,192.22,97.12,192.81,98.85z"
				/>
				<path
					fill={st22}
					d="M487.81,116.06c-0.01,2.1-0.97,3.39-2.66,3.04c-3.73-0.78-3.23-4.45-4.11-7.05
          c-0.54-1.58,0.52-3.25,2.26-2.99C487.45,109.68,486.29,113.91,487.81,116.06z"
				/>
				<path
					fill={st23}
					d="M344.95,73.77c-2.03,0.13-3.36-0.81-3.07-2.51c0.66-3.88,4.49-3.15,7.04-4.17c1.53-0.61,3.3,0.25,3.09,2.09
          C351.53,73.53,347.17,72.22,344.95,73.77z"
				/>
				<path
					fill={st24}
					d="M125.26,233.12c0.13,2.45-0.26,4.14-2.2,3.86c-4.01-0.56-3.25-4.4-4.14-6.98c-0.54-1.55,0.17-2.99,2.14-3.11
          C123.07,226.76,125.17,230.31,125.26,233.12z"
				/>
				<path
					fill={st25}
					d="M126.61,223c-2.13,0.24-3.72-0.44-3.51-2.19c0.5-4.3,4.67-3.29,7.3-4.39c1.8-0.75,3.01,0.84,2.77,2.39
          C132.58,222.77,128.56,221.65,126.61,223z"
				/>
				<path
					fill={st26}
					d="M509.73,203.95c-0.25-0.59-0.73-1.19-0.73-1.78c-0.04-15.44-0.03-30.88,0.02-46.31
          c0-0.58,0.51-1.16,0.78-1.74C510.15,170.73,510.28,187.34,509.73,203.95z"
				/>
				<path
					fill={st27}
					d="M90.27,278.09c1.57,2.27,0.73,4.83,0.76,7.25c0.11,8.95,0.07,17.91,0.02,26.86c-0.01,1.6,0.55,3.35-0.82,4.73
          C90.24,303.98,90.25,291.03,90.27,278.09z"
				/>
				<path
					fill={st28}
					d="M198.87,96.44c-0.03,1.53-0.92,2.48-2.35,2.54c-1.21,0.05-2.17-0.78-2.25-2.09
          c-0.09-1.53,0.83-2.53,2.29-2.58C197.79,94.26,198.6,95.16,198.87,96.44z"
				/>
				<path
					fill={st29}
					d="M371.94,108.18c-5.61,0.91-11.18,2.39-16.92,1C360.6,107.71,366.24,107.42,371.94,108.18z"
				/>
				<path
					fill={st30}
					d="M483.49,104.91c-0.09,1.28-0.86,2.04-2.14,2.1c-1.29,0.06-2.18-0.66-2.29-1.98
          c-0.12-1.39,0.76-2.09,2.06-2.14C482.42,102.84,483.2,103.59,483.49,104.91z"
				/>
				<path
					fill={st31}
					d="M358.15,66.94c0,1.41-0.58,2.39-1.88,2.48c-1.21,0.09-2.22-0.49-2.28-1.92c-0.06-1.31,0.59-2.2,1.9-2.35
          C357.23,64.99,357.99,65.73,358.15,66.94z"
				/>
				<path
					fill={st32}
					d="M121.07,223.16c-0.19,1.43-1.14,1.93-2.41,1.76c-1.1-0.14-1.69-0.92-1.66-2.04c0.04-1.35,0.8-2.14,2.16-2.05
          C120.51,220.93,121.12,221.81,121.07,223.16z"
				/>
				<path
					fill={st33}
					d="M483.86,437.83c-1.92,6.68-6.33,10.63-13.12,11.98c-0.96,0.96-2.18,0.62-3.31,0.62
          c-23.73,0.02-47.47,0.02-71.2,0c-1.1,0-2.36,0.43-3.22-0.73c-0.06-0.6-0.12-1.2-0.18-1.8c-0.59-1.44-0.33-2.96-0.33-4.44
          c-0.01-68.02-0.01-136.03,0-204.05c0-1.49-0.22-3,0.3-4.45c1.92-1.75,4.33-1.53,6.63-1.53c25.96-0.05,51.92-0.04,77.88,0
          c2.3,0,4.7-0.22,6.69,1.4c0.77,1.58,0.41,3.28,0.41,4.92c0.02,64.41,0.02,128.83-0.01,193.24
          C484.41,434.59,484.91,436.31,483.86,437.83z"
				/>
				<path
					fill={st33}
					d="M246.06,448.73c-3.47-0.39-5.51-2.99-7.87-5.11c-3.26-2.81-3.05-6.75-3.11-10.43
          c-0.17-9.66-0.06-19.32-0.06-28.98c0-53.3-0.03-106.6,0.07-159.9c0.01-3.2-1.61-6.64,0.81-9.67c2.23-1.49,4.78-1.23,7.25-1.24
          c24.75-0.03,49.5-0.03,74.24,0c2.63,0,5.36-0.31,7.68,1.44c1.57,1.82,1.31,4.06,1.32,6.2c0.03,66.95,0.03,133.9,0,200.85
          c0,6.69-0.78,7.44-7.53,7.46c-21.82,0.05-43.63-0.03-65.45,0.08C250.89,449.45,248.58,448.15,246.06,448.73z"
				/>
				<path
					fill={st34}
					d="M383,220.06c1.61,1.01,1.13,2.64,1.13,4.07c0.01,69.45,0.01,138.9,0,208.35c0,0.83-0.14,1.65-0.22,2.48
          c-0.48,0.43-1.02,0.77-1.61,1.02c-9.05,1.14-18.14,0.37-27.21,0.49c-5.77,0.08-11.56,0.37-17.33-0.26
          c-0.65-0.13-1.26-0.37-1.84-0.7c-0.84-0.63-1.27-1.49-1.39-2.53c-0.05-69.01-0.01-138.02-0.01-207.03c0-1.31-0.28-2.67,0.43-3.91
          c0.35-0.65,0.7-1.29,1.05-1.94c2.36-1.2,4.88-0.52,7.32-0.54c11.61-0.08,23.21-0.06,34.82-0.01
          C379.76,219.57,381.46,219.11,383,220.06z"
				/>
				<path
					fill={st35}
					d="M191.8,449.12c-21.16,0.03-42.32,0.08-63.47,0.06c-2.11,0-4.38,0.27-6.16-1.39c0.69-2.67,2.83-1.76,4.48-1.7
          c19.46,0.76,38.93,0.07,58.39,0.36c4.16,0.06,5.75-1.75,5.47-5.7c-0.08-1.16-0.07-2.33-0.01-3.49c0.16-3.35-1.32-4.83-4.74-4.78
          c-5.99,0.09-11.98,0.02-17.97,0.02c-11.98-0.01-23.96,0-35.94,0c-3.5,0-7.02,0-10.32-1.38c-2.9-1.21-3.85-0.14-4,2.79
          c-0.11,2.11,0.8,4.83-2.23,5.93c-0.12-0.86-0.23-1.72-0.35-2.58c-1.07-1.65-0.87-3.52-0.87-5.34c-0.01-30.14-0.02-60.27,0-90.41
          c0-6.07,0.4-6.49,6.54-6.5c22.48-0.03,44.96-0.03,67.43,0c5.51,0.01,5.97,0.47,5.97,6c0.03,34.13,0.04,68.27-0.05,102.4
          C193.96,445.42,195.05,448.2,191.8,449.12z"
				/>
				<path
					fill={st36}
					d="M228.22,211.9c-7.66-2.6-10.77-7.97-10.2-15.97c0.38-5.26,0.16-10.57,0.21-15.86
          c1.36-0.51,2.71-0.24,3.85,0.43c5.29,3.11,11.07,3.03,16.93,3.01c22.43-0.07,44.87-0.02,67.3-0.04c2.31,0,4.67,0.33,6.83-0.82
          c1.89-1.48,1.42-3.59,1.43-5.52c0.06-8.11-0.03-16.22-0.09-24.33c-0.02-2.26-0.35-4.7,1.61-6.51c0.24-0.09,0.48-0.15,0.74-0.18
          c0.85,0.79,0.64,1.83,0.64,2.8c0.01,19.91,0.01,39.82,0.01,59.73c0,0.83,0.01,1.66-0.51,2.38c-0.33,0.32-0.66,0.63-0.98,0.95
          c-1.19,0.88-2.56,0.53-3.85,0.53c-26.7,0.02-53.4,0.02-80.11,0C230.75,212.49,229.36,212.92,228.22,211.9z"
				/>
				<path
					fill={st33}
					d="M500.6,203.95c-1.46,4.2-4.96,6.14-8.69,7.8c-0.85,1.19-2.12,0.74-3.22,0.74
          c-27.28,0.02-54.56,0.02-81.85,0.01c-0.98,0-2.01,0.16-2.88-0.5c-1.93-3.74-0.74-7.74-0.89-11.61c-0.19-4.79,0.04-9.6,0.1-14.4
          c1.93-3.27,5.21-2.48,8.07-2.49c23.93-0.08,47.86-0.06,71.8-0.03c4.51,0.01,8.88-0.18,13.12-2.21c3.44-1.64,4.83-0.62,5.1,3.36
          C501.67,191.08,501.24,197.53,500.6,203.95z"
				/>
				<path
					fill={st37}
					d="M500.6,203.95c-1.48-6.07-0.36-12.22-0.62-18.33c-0.13-3.01,0.2-5.92-4.04-2.64
          c-2.45,1.89-5.44,2.11-8.54,2.11c-25.98-0.08-51.96-0.05-77.93-0.04c-2.14,0-4.35-0.35-6.31,0.95c0-8.66,0-17.31,0-25.97
          c1.69-2.31,4.2-2.49,6.72-2.5c23.91-0.06,47.83-0.15,71.74,0.05c4.65,0.04,9.23,0.98,13.67,2.5c3.59,1.23,5.48,3.48,5.16,7.45
          c-0.24,2.92,0.18,5.87-0.04,7.57c-0.26-3.55,0.64-8.31-0.7-13.07c-0.58-2.07-0.43-4.31,1.21-6.1c1.4,0.02,1.5,1.01,1.5,2.04
          c-0.03,13.68,0.1,27.37-0.08,41.05c0,0.27-0.35,0.54-0.55,0.81C501.39,201.2,500.99,202.57,500.6,203.95z"
				/>
				<path
					fill={st38}
					d="M229.95,447c0.28,0.83,2.13,2.23-0.3,2.27c-8.32,0.14-16.64,0.08-24.96-0.08c-3.14-0.06-1.72-2.73-1.73-4.17
          c-0.12-18.48-0.11-36.97-0.12-55.45c-0.01-16.32,0.2-32.64-0.13-48.96c-0.1-4.82,1.52-5.96,5.91-5.62c4.47,0.34,9,0.31,13.48,0
          c3.87-0.28,5.07,0.87,5.05,4.92c-0.18,31.31-0.1,62.62-0.1,93.92c0,2,0.04,4,0.06,5.99c0.43,1.58,0.31,3.31-1.59,1.46
          c-5.44-5.3-11.87-2.33-17.87-2.44c-2.36-0.04-2.09,2.56-2.05,4.36c0.06,2.27,1.47,3.18,3.57,3.25c4.96,0.15,9.93,0.04,14.89,0.06
          C226.04,446.51,228.04,446.22,229.95,447z"
				/>
				<path
					fill={st37}
					d="M315.02,185.01c-27.81,0.01-55.63-0.05-83.44,0.09c-5.23,0.03-8.98-3.16-13.35-5.03c0-4.69,0-9.37,0-14.06
          c1.51-4.82,5.53-6.4,9.86-7.22c4.39-0.83,8.85-1.35,13.35-1.35c21.91,0.04,43.82,0.01,65.73,0.02c1.82,0,3.65-0.04,5.46,0.33
          c1.84,0.38,2.94,1.33,2.86,3.4C315.2,169.13,316.04,177.09,315.02,185.01z"
				/>
				<path
					fill={st39}
					d="M386.14,137.14c-3.22,0-5.25-0.21-7.21,0.04c-4.2,0.55-5.51-0.35-3.94-4.91
          c4.84-13.98,9.96-27.78,18.29-40.14c3.08-4.57,6.59-8.77,11.04-12.09c11.06-8.25,29.96-2.36,34.5,10.74
          c1.53,4.4,0.47,8.5-1.68,12.43c-4.35,7.94-11.18,13.52-18.24,18.79c-4.65,3.47-9.68,6.43-14.47,9.73
          C398.53,135.78,392.33,138.62,386.14,137.14z"
				/>
				<path
					fill={st39}
					d="M332.39,137.14c-4.79,1.12-9.7-1.11-14.6-3.82c-10.05-5.58-19.98-11.31-28.66-18.94
          c-4.91-4.32-9.25-9.02-11.32-15.49c-1-3.11-0.62-5.98,0.63-8.71c6.99-15.21,26.08-18.86,37.93-7.12
          c11.16,11.05,16.9,25.3,22.5,39.57c0.61,1.54,1.1,3.13,1.64,4.7C343.85,137.14,343.85,137.14,332.39,137.14z"
				/>
				<path
					fill={st40}
					d="M99.16,316.94c-0.03-10.33-0.06-20.66-0.08-30.99c1.75-5.09,5.89-6.37,10.63-6.46
          c9.28-0.17,18.56-0.05,27.84-0.06c13.42-0.01,26.84-0.03,40.26,0.02c2.44,0.01,5.03-0.36,7.08,1.54
          c0.04,13.81,0.06,27.62,0.13,41.43c0.01,2.17-0.01,3.83-3.04,3.8c-24.63-0.17-49.25-0.22-73.88-0.3c1.31-3.41,4.37-2.4,6.77-2.41
          c19.31-0.12,38.62-0.06,57.93-0.07c1.5,0,3-0.04,4.49-0.03c2.89,0.02,4.26-1.31,4.2-4.25c-0.06-2.99,0.02-5.99-0.01-8.99
          c-0.05-5.16-0.44-5.6-5.8-5.61c-18.98-0.05-37.95,0.01-56.93-0.04c-4.81-0.01-9.67,0.5-14.38-1.02c-1.99-0.65-2.79,0.16-2.79,2.13
          c0.01,2.33,0.17,4.68-0.11,6.99C101.28,314.29,101.74,316.48,99.16,316.94z"
				/>
				<path
					fill={st41}
					d="M99.16,316.94c2.11-5.21,0-10.78,1.38-16.6c5.92,3.45,12.29,2.65,18.56,2.66c19.47,0.02,38.94,0.09,58.4-0.06
          c4.09-0.03,6.56,0.26,5.67,5.49c-0.66,3.88-0.58,8.03,0,11.94c0.61,4.08-0.89,4.71-4.47,4.69c-21.8-0.13-43.59-0.08-65.39-0.03
          c-1.75,0-3.7-0.67-5.2,0.9C103.25,324.8,100.22,321.85,99.16,316.94z"
				/>
				<path
					fill={st42}
					d="M227.23,283.92c-0.07,11.97-0.14,23.93-0.2,35.9c-0.03,6.28-0.02,6.28-6.53,6.29
          c-7.48,0.01-14.96-0.09-22.44,0.05c-2.86,0.06-4.19-0.6-4.14-3.83c0.19-13.46,0.14-26.92,0.19-40.39c0.98-1.84,2.72-2.32,4.59-2.37
          c7.9-0.21,15.81-0.2,23.71,0c1.66,0.04,3.29,0.36,4.52,1.65C227.63,282.06,227.6,282.97,227.23,283.92z"
				/>
				<path
					fill={st39}
					d="M203.19,260.38c-2.92,0.38-5.94-0.68-8.97-2.28c-10.73-5.67-21.39-11.44-29.99-20.23
          c-7.03-7.18-8.02-12.8-3.4-18.27c7.32-8.67,16.59-10.54,24.46-4.73c7.33,5.41,11.49,13.23,15.19,21.22
          c2.78,6,5.06,12.24,7.29,18.47C209.83,260.33,209.68,260.38,203.19,260.38z"
				/>
				<path
					fill={st43}
					d="M483.67,235.02c-30.17-0.02-60.35-0.03-90.52-0.05c-1.27-4.27-0.69-8.58-0.33-12.89
          c0.37-0.66,0.74-1.33,1.11-1.99c1.52-1,3.23-0.52,4.85-0.52c26.54-0.03,53.09-0.03,79.63,0c1.92,0,4.05-0.61,5.58,1.24
          C484.49,225.56,485.15,230.31,483.67,235.02z"
				/>
				<path
					fill={st44}
					d="M315.02,185.01c0-7.31-0.11-14.62,0.04-21.93c0.06-2.99-0.9-4.08-3.98-4.06c-24.59,0.1-49.19-0.12-73.77,0.21
          c-4.6,0.06-9.4,0.53-13.94,2.62c-2.29,1.05-2.87,3.53-5.13,4.17c0.05-3.48-0.3-7.02,0.23-10.43c0.76-4.9,4.54-7.45,8.73-9.41
          c0.81-0.8,1.85-0.6,2.82-0.6c27.85-0.01,55.7-0.01,83.56-0.01c0.83,0,1.66-0.01,2.44,0.35c0,0,0.08,0.38,0.09,0.38
          c-0.04,11.2-0.06,22.39-0.16,33.59C315.92,181.6,316.63,183.54,315.02,185.01z"
				/>
				<path
					fill={st44}
					d="M501.07,155.93c0,7.3,0,14.6,0,21.9c-0.37,0-0.75,0.01-1.12,0.01c0-3.48-0.07-6.97,0.02-10.45
          c0.08-2.91-1.15-4.54-3.96-5.51c-4.91-1.7-9.92-2.76-15.07-2.77c-23.64-0.08-47.29-0.01-70.93-0.07c-2.37-0.01-4.61,0.36-6.85,0.99
          c0.27-4.72-1.37-9.55,0.87-14.14c3.3-0.68,6.63-0.29,9.95-0.29c24.47-0.04,48.94-0.04,73.42,0.01c1.75,0,3.66-0.7,5.27,0.66
          c3.42,1.5,6.09,3.88,8.13,6.99C501.75,154.06,501.78,154.95,501.07,155.93z"
				/>
				<path
					fill={st41}
					d="M115.29,439.84c1.57-3.33,0.46-6.85,0.75-10.27c0.17-2.05,0.72-2.85,2.71-1.45
          c3.72,2.61,7.96,2.91,12.37,2.89c18.63-0.09,37.27,0.06,55.9-0.12c3.88-0.04,5.16,0.99,5.11,5.02
          c-0.13,12.06,0.04,12.06-11.96,12.06c-16.3,0-32.61-0.16-48.91,0.14c-3.07,0.06-6.03-2.33-9.1-0.31
          C118.62,446.21,116.39,443.51,115.29,439.84z"
				/>
				<path
					fill={st45}
					d="M393.97,146.06c-0.04,17.32-0.08,34.63-0.12,51.95c-0.69,0.02-1.2-0.27-1.55-0.86
          c-1.09-2.18-0.86-4.54-0.88-6.85c-0.05-7.81-0.01-15.62-0.02-23.44c0-3-0.65-5.21-4.34-5.22c-18.12-0.04-36.23-0.06-54.35,0.01
          c-3.41,0.01-4.12,2.06-4.13,4.89c-0.03,7.98,0.03,15.96-0.04,23.93c-0.02,2.46,0.32,5.02-1.25,7.24c-0.56,0.52-1.11,0.51-1.66-0.02
          c-0.94-1.71-0.61-3.58-0.61-5.38c-0.03-13.47-0.04-26.94,0-40.41c0.01-1.95-0.46-4.03,1.02-5.74l-0.05-0.22
          c4.77-0.85,9.58-0.32,14.37-0.35c16.25-0.08,32.5-0.04,48.75-0.01C390.72,145.59,392.42,145.16,393.97,146.06z"
				/>
				<path
					fill={st46}
					d="M184.89,280.99c-24.48,0-48.96,0.11-73.44-0.09c-5.06-0.04-9.07,1.35-12.37,5.05
          c-0.61-9.94,0.94-12.78,9.14-16.81c0.8-0.82,1.84-0.61,2.81-0.61c23.52-0.01,47.04-0.01,70.56-0.01c0.83,0,1.66-0.01,2.41,0.43
          C186.12,272.84,184.66,276.98,184.89,280.99z"
				/>
				<path
					fill={st47}
					d="M236.16,235.08c-1.71,2.97-0.07,6-0.08,8.98c-0.1,63.04-0.07,126.08-0.08,189.13c0,3.64-0.12,7.29,2.19,10.44
          c-1.13,1.36-1.72,0.35-2.01-0.6c-1.09-3.64-2.21-7.25-2.2-11.16c0.05-68.57,0.03-137.15,0.09-205.72c0-1.74-1.23-4.17,1.68-5.06
          c0.24,0.17,0.47,0.34,0.71,0.51c0.34,0.49,0.57,1.02,0.71,1.59C237.31,227.18,238.34,231.27,236.16,235.08z"
				/>
				<path
					fill={st48}
					d="M226.93,281.97c-3.78-1.79-7.82-0.95-11.7-0.85c-7.04,0.19-14.15-1.15-21.12,0.83
          c0.02-4.28,0.05-8.56,0.07-12.84c10.89-0.94,21.79-0.98,32.68,0.01c0.13,0.62,0.27,1.24,0.4,1.87
          C228.3,274.69,228.77,278.36,226.93,281.97z"
				/>
				<path
					fill={st49}
					d="M358.35,136.98c-4.46,1.42-6.44-0.7-7.11-4.88c-0.28-1.78-0.95-3.51-1.5-5.24c-2.23-7-2.27-7.14,5.07-8.66
          c4.2-0.87,8.5-1.27,12.78-1.67c2.43-0.23,3.64,0.45,2.36,3.4c-1.63,3.78-3.18,7.72-3.87,11.75
          C365.25,136.52,362.97,138.23,358.35,136.98z"
				/>
				<path
					fill={st41}
					d="M191.8,449.12c1.83-1.35,1.2-3.34,1.2-5.08c0.03-34.31-0.05-68.63,0.12-102.94c0.02-4.34-1.48-5.21-5.45-5.18
          c-22.49,0.17-44.97,0.19-67.46-0.02c-4.3-0.04-5.23,1.4-5.21,5.42c0.1,31.98-0.01,63.96-0.06,95.94c-0.24-0.18-0.5-0.33-0.78-0.44
          c-0.89-0.99-0.58-2.2-0.58-3.33c-0.02-31.37-0.01-62.75,0-94.12c0-1.14-0.25-2.34,0.49-3.38c0.69-0.37,1.37-0.74,2.06-1.11
          c3.12-0.77,6.29-0.32,9.44-0.33c20.65-0.05,41.3-0.04,61.95-0.01c1.81,0,3.66-0.35,5.44,0.35c0.4,0.4,0.79,0.8,1.19,1.2
          c0.54,1.1,0.36,2.29,0.36,3.44c0.01,34.97,0.01,69.95,0,104.92c0,1.15,0.18,2.34-0.36,3.44c-0.36,0.68-0.72,1.35-1.08,2.03
          c-0.32,0.44-0.61,0.4-0.86-0.07C192.1,449.59,191.96,449.34,191.8,449.12z"
				/>
				<path
					fill={st50}
					d="M246.06,448.73c1.6-1.34,3.52-0.75,5.29-0.75c22.63-0.04,45.25-0.14,67.88,0.08c4.52,0.04,5.88-1.1,5.87-5.78
          c-0.16-69.04-0.11-138.09-0.12-207.13c0.32-0.09,0.64-0.18,0.95-0.28c0.21,0.25,0.43,0.5,0.64,0.76c0.1,0.32,0.19,0.63,0.28,0.95
          c0.29,6.16,0.12,12.32,0.12,18.47c0.02,62.26,0.01,124.51,0.01,186.77c0,8.12-0.01,8.13-8.35,8.13c-21.47,0-42.95,0.01-64.42-0.02
          c-2.14,0-4.35,0.39-6.35-0.8C247.26,449.01,246.66,448.87,246.06,448.73z"
				/>
				<path
					fill={st51}
					d="M247.87,449.14c23.97-0.07,47.95-0.28,71.92-0.08c4.94,0.04,6.35-1.04,6.34-6.22
          c-0.2-66.94-0.13-133.89-0.12-200.83c0-2.33,0.14-4.65,0.21-6.98c0.8-0.13,1.21,0.21,1.24,1.03c0.06,69.9,0.01,139.8-0.01,209.7
          c0,1.4,0.68,3.02-0.74,4.21l-0.41-0.05l-0.39,0.15c-1.59,0.69-3.27,0.36-4.91,0.36c-22.68,0.02-45.37,0.03-68.05-0.01
          c-1.61,0-3.32,0.49-4.83-0.53C248.03,449.64,247.95,449.39,247.87,449.14z"
				/>
				<path
					fill={st52}
					d="M229.95,447c-7.83,2.09-15.81,0.9-23.69,0.73c-4.06-0.09-1.76-4.2-2.14-6.48c-0.31-1.83-0.61-4.25,2.55-4.26
          c5.47-0.01,10.94,0.03,16.41,0c2.75-0.01,2.56,2.38,3.39,3.96c0,0,0.31-0.09,0.36-0.19c0.13-0.3,0.19-0.63,0.28-0.95
          C228.05,442.21,229,444.6,229.95,447z"
				/>
				<path
					fill={st53}
					d="M326.7,449.96c0.1-0.99,0.29-1.97,0.29-2.96c0.01-69.84,0.01-139.68,0-209.53c0-0.83-0.1-1.66-0.15-2.49
          c-1.8-4.67-2.22-9.32,0.26-13.91c1.5,1.54,0.86,3.47,0.86,5.23c0.03,72.72,0.03,145.44-0.02,218.17
          C327.95,446.33,328.86,448.54,326.7,449.96z"
				/>
				<path
					fill={st54}
					d="M385.15,446.64c0-74.75,0-149.51,0-224.26c0.16,0,0.31,0,0.47,0c0,74.81,0,149.63,0,224.44
          C385.47,446.76,385.31,446.7,385.15,446.64z"
				/>
				<path
					fill={st55}
					d="M334.94,222.05c0.06,1.83,0.17,3.65,0.17,5.48c0.02,68.79,0.03,137.58,0.05,206.37
          c1.52,4.7,2.41,9.37-0.38,13.95c-1.33-1.61-0.74-3.52-0.74-5.29c-0.04-71.78-0.05-143.55-0.02-215.33
          C334.02,225.5,333.3,223.54,334.94,222.05z"
				/>
				<path
					fill={st56}
					d="M392.82,222.08c0.11,4.3,0.22,8.6,0.33,12.89c-0.06,2.49-0.17,4.98-0.18,7.47
          c-0.01,65.66-0.01,131.32-0.01,196.98c0,2.82-0.09,5.65-0.13,8.47c-1.48-1.55-0.83-3.49-0.83-5.24
          c-0.04-71.78-0.04-143.55-0.01-215.33C391.99,225.57,391.34,223.63,392.82,222.08z"
				/>
				<path
					fill={st57}
					d="M483.67,235.02c0.08-4.64,0.16-9.28,0.25-13.92c-0.28-0.23-0.37-0.46-0.27-0.68c0.1-0.22,0.19-0.33,0.29-0.33
          c1.57,1.29,1,3.07,1,4.67c0.02,70.26,0.01,140.52,0.01,210.78c0,1,0,2-0.03,3c0,0.1-0.19,0.19-0.29,0.29
          c-0.25-0.33-0.51-0.67-0.76-1c0-65.94,0-131.88-0.01-197.82C483.85,238.35,483.74,236.68,483.67,235.02z"
				/>
				<path
					fill={st58}
					d="M227.05,252.67c0,0.99,0.27,2.09-0.06,2.95c-0.59,1.55,1.79,4.64-1.69,4.56c-2.78-0.06-6.99,1.94-7.61-3.53
          c-0.22-1.93-0.98-3.85-1.78-5.65c-1.36-3.03-1.17-4.51,2.72-5.31C227.1,243.97,227.05,243.7,227.05,252.67z"
				/>
				<path
					fill={st59}
					d="M326.03,146.16c0.03,17.28,0.06,34.56,0.08,51.84c1.65,1.8,1.35,4.06,1.24,6.15
          c-0.22,4.16,1.75,5.52,5.69,5.5c17.9-0.09,35.81-0.06,53.71-0.02c2.44,0.01,5.02-0.5,7.14,1.33c-0.34,0.39-0.68,0.78-1.02,1.17
          c-10.79,0.84-21.6,0.32-32.4,0.31c-11.46,0-22.94,0.53-34.39-0.32c-0.39-0.4-0.79-0.79-1.18-1.19c-0.85-4.43-0.35-8.9-0.37-13.35
          c-0.08-15.56-0.04-31.13-0.01-46.69c0-1.62-0.45-3.32,0.5-4.85C325.36,146.09,325.7,146.13,326.03,146.16z"
				/>
				<path
					fill={st60}
					d="M194.15,447.88c0-37.27,0-74.54,0-111.8c1.42,1.37,0.85,3.14,0.85,4.73c0.03,34.11,0.03,68.23,0,102.34
          C195,444.75,195.55,446.51,194.15,447.88z"
				/>
				<path
					fill={st61}
					d="M327.11,221.08c-0.09,4.64-0.18,9.28-0.26,13.91c-0.21,0.01-0.42,0.03-0.63,0.04c0,0-0.39-0.06-0.39-0.06
          c-1.53-1.85-1.18-4.08-1.24-6.22c-0.15-5.69-0.69-6.3-6.37-6.3c-24.64-0.03-49.28,0.01-73.91-0.05c-2.75-0.01-5.71,0.74-8.14-1.37
          l-0.19,0.18l-0.23-0.13c0.09-0.33,0.18-0.66,0.27-0.99c1.02-0.81,2.23-0.54,3.37-0.54c27.97-0.01,55.94-0.02,83.92,0.02
          c1.24,0,2.7-0.61,3.69,0.76C326.87,220.6,326.9,220.85,327.11,221.08z"
				/>
				<path
					fill={st62}
					d="M114.07,335.98c0.03,33.61,0.06,67.22,0.09,100.84c-0.05,0.16-0.06,0.35-0.16,0.47
          c-0.17,0.21-0.39,0.38-0.59,0.57c-0.12-0.92-0.34-1.84-0.34-2.76c-0.02-31.65-0.02-63.29,0-94.94
          C113.08,338.71,112.64,337.11,114.07,335.98z"
				/>
				<path
					fill={st63}
					d="M393.9,210.98c-21.22,0.02-42.44-0.03-63.66,0.11c-3.17,0.02-4.58-0.76-4.27-4.13
          c0.27-2.96,0.11-5.96,0.14-8.95c0.28,0,0.56,0,0.85-0.01c0.21,0.21,0.42,0.43,0.63,0.64c0.22,0.44,0.47,0.88,0.56,1.38
          c1.51,8.48,1.51,8.49,10.39,8.49c15.13,0,30.26-0.01,45.4,0c6.89,0,7.84-0.24,7.65-7.01c-0.03-1,0.26-1.97,0.78-2.86
          c0.21-0.21,0.42-0.43,0.63-0.64c0.29,0.01,0.57,0.01,0.86,0.01C393.87,202.33,393.88,206.65,393.9,210.98z"
				/>
				<path
					fill={st64}
					d="M327,220.33c-6.95-0.1-13.9-0.28-20.85-0.28c-23.38-0.02-46.75,0.02-70.13,0.04
          c1.25-1.65,3.05-1.03,4.63-1.03c27.12-0.04,54.23-0.04,81.35,0C323.71,219.06,325.73,218.35,327,220.33z"
				/>
				<path
					fill={st65}
					d="M483.93,220.09c-0.01,0.34-0.01,0.67-0.02,1.01c-1.79-1.2-3.82-0.8-5.77-0.81
          c-28.07-0.08-56.14-0.14-84.21-0.21c1.25-1.63,3.05-1.02,4.63-1.03c26.91-0.03,53.83-0.03,80.74,0.01
          C480.88,219.07,482.68,218.47,483.93,220.09z"
				/>
				<path
					fill={st66}
					d="M228.22,211.9c29.26,0.02,58.52,0.05,87.77,0.07c-0.92,1.36-2.34,1.05-3.63,1.05
          c-27.66,0.01-55.32,0.01-82.97-0.01c-0.74,0-1.49-0.24-2.23-0.37c0.05-0.12,0.06-0.31,0.15-0.36
          C227.59,212.13,227.91,212.02,228.22,211.9z"
				/>
				<path
					fill={st67}
					d="M492.67,146.26c-24.24-0.07-48.47-0.14-72.71-0.23c-5.31-0.02-10.62-0.1-15.93-0.15
          c1.06-1.15,2.46-0.82,3.75-0.82c27.44-0.02,54.88-0.02,82.32,0c0.93,0,1.87,0.22,2.8,0.37c0.04,0.01,0.08,0.29,0.04,0.43
          C492.9,146,492.76,146.13,492.67,146.26z"
				/>
				<path
					fill={st68}
					d="M316.01,145.91c-26.78,0.04-53.56,0.08-80.34,0.13c-2.83,0.01-5.65,0.08-8.48,0.13
          c-0.43-1.93,1.11-1.09,1.74-1.09c27.94-0.04,55.89-0.03,83.83-0.03C313.92,145.05,315.11,144.92,316.01,145.91z"
				/>
				<path
					fill={st69}
					d="M403.95,212c26.32-0.05,52.64-0.1,78.96-0.16c3-0.01,6-0.06,8.99-0.09c-0.88,1.78-2.56,1.25-3.96,1.26
          c-26.78,0.03-53.56,0.02-80.34,0.01C406.31,213.02,404.9,213.32,403.95,212z"
				/>
				<path
					fill={st70}
					d="M393.01,449.69c25.91,0.04,51.82,0.08,77.73,0.12c0.9,0.46,2.31,1.08-0.1,1.12
          c-4.48,0.09-8.96,0.03-13.43,0.03c-19.57,0-39.14,0.03-58.71-0.04C396.64,450.91,394.45,451.81,393.01,449.69z"
				/>
				<path
					fill={st71}
					d="M248.11,449.89c25.93,0.06,51.86,0.12,77.8,0.18c-0.74,0.29-1.48,0.85-2.22,0.85
          c-25.25,0.04-50.5,0.04-75.75,0.02c-0.27,0-0.54-0.2-0.81-0.3C247.46,450.39,247.78,450.14,248.11,449.89z"
				/>
				<path
					fill={st72}
					d="M184,268.96c-25.26,0.06-50.52,0.12-75.78,0.18c-0.59-1.54,0.66-1.12,1.29-1.12
          c23.94-0.02,47.87-0.01,71.81-0.01C182.32,268.02,183.32,268.04,184,268.96z"
				/>
				<path
					fill={st73}
					d="M108.38,327.18c24.78,0,49.55,0,74.33,0c0,0.16,0,0.32,0,0.47c-24.84,0-49.69,0-74.53,0
          C108.24,327.5,108.31,327.34,108.38,327.18z"
				/>
				<path
					fill={st74}
					d="M192.96,334.88c-25.61,0-51.22-0.01-76.82-0.01c1.2-1.31,2.79-0.85,4.23-0.85c22.79-0.03,45.58-0.03,68.37,0
          C190.17,334.01,191.76,333.56,192.96,334.88z"
				/>
				<path
					fill={st75}
					d="M382.91,435.01c0.33-0.02,0.66-0.03,1-0.04c0.01,4.65,0.02,9.3,0.03,13.95c-1.38,1.23-3.09,1.02-4.73,1.02
          c-12.75,0.02-25.5,0.04-38.25,0c-5.43-0.02-5.52-0.08-5.42-5.63c0.06-3.11-0.55-6.27,0.43-9.35c0.36,0.01,0.71,0.02,1.07,0.04
          c1.81,2.11,1.45,4.7,1.49,7.15c0.04,2.91,1.35,4.23,4.25,4.24c11.47,0.05,22.94,0.04,34.41,0c2.89-0.01,4.21-1.32,4.26-4.23
          C381.48,439.71,381.11,437.12,382.91,435.01z"
				/>
				<path
					fill={st76}
					d="M192.21,449.85c0.29-0.06,0.58-0.04,0.86,0.07c-0.95,1.29-2.35,1.04-3.65,1.04
          c-20.96,0.01-41.92,0-62.88-0.02c-0.47,0-0.93-0.2-1.38-1.09C147.5,449.85,169.85,449.85,192.21,449.85z"
				/>
				<path
					fill={st77}
					d="M393.97,146.06c-22.66-0.04-45.32-0.08-67.99-0.12c1.19-1.29,2.77-0.88,4.21-0.88
          c19.88-0.02,39.75-0.02,59.63,0C391.26,145.06,392.85,144.63,393.97,146.06z"
				/>
				<path
					fill={st78}
					d="M326.09,212.14c22.26,0,44.53,0.01,66.79,0.01c-1.02,1.23-2.43,0.87-3.72,0.87
          c-19.79,0.02-39.57,0.02-59.36,0C328.52,213.02,327.11,213.34,326.09,212.14z"
				/>
				<path
					fill={st79}
					d="M325.03,146.05c-0.04,21.63-0.08,43.26-0.12,64.89c-1.23-1.01-0.88-2.41-0.88-3.69
          c-0.03-19.03-0.04-38.06-0.02-57.08C324,148.75,323.54,147.14,325.03,146.05z"
				/>
				<path
					fill={st80}
					d="M316.97,211.02c-0.05-21.64-0.09-43.27-0.14-64.91c1.51,0.81,1.15,2.26,1.15,3.53
          c0.01,19.41,0,38.82-0.01,58.23C317.98,209.02,318.09,210.22,316.97,211.02z"
				/>
				<path
					fill={st81}
					d="M335.97,434.97c0.16,4.44-1.86,10.11,0.79,12.98c2.71,2.94,8.46,0.89,12.87,0.95
          c11.43,0.14,22.87,0.03,34.31,0.02c0.01,0.35,0.02,0.71,0.04,1.06c-6.22,1.06-12.49,0.41-18.73,0.41
          c-9.72-0.01-19.45,0.52-29.16-0.33c-0.44-0.73-0.87-1.47-1.31-2.2c0.13-4.65,0.25-9.3,0.38-13.95
          C335.43,434.26,335.7,434.61,335.97,434.97z"
				/>
				<path
					fill={st82}
					d="M501.07,155.93c-0.09-0.89-0.18-1.78-0.27-2.67c2.63,0.16,2.09,2.3,2.09,3.75
          c0.08,14.23,0.06,28.46,0.03,42.69c-0.01,2.48-0.66,0.94-1.13,0.12c-0.02-12.86-0.03-25.73-0.09-38.59
          C501.68,159.45,502.18,157.58,501.07,155.93z"
				/>
				<path
					fill={st83}
					d="M336.09,450.06c15.96-0.03,31.92-0.05,47.89-0.08c-0.83,1.09-2.03,0.98-3.19,0.98
          c-13.99,0-27.98,0-41.97-0.01C337.81,450.95,336.79,450.98,336.09,450.06z"
				/>
				<path
					fill={st84}
					d="M383,220.06c-15.67,0.02-31.33,0.03-47,0.05c0.89-1.39,2.31-1.07,3.59-1.08c13.26-0.02,26.52-0.02,39.78,0
          C380.66,219.04,382.08,218.71,383,220.06z"
				/>
				<path
					fill={st47}
					d="M226.86,269.12c-10.89,0-21.79-0.01-32.68-0.01c-0.38-0.49-0.22-1.04,0.29-1.05
          c10.71-0.04,21.41-0.03,32.12-0.01c0.12,0,0.35,0.15,0.35,0.24C226.96,268.56,226.89,268.84,226.86,269.12z"
				/>
				<path
					fill={st47}
					d="M226.93,281.97c0.11-3.66,0.22-7.32,0.34-10.98c0.46-0.04,0.93-0.08,1.39-0.12
          c-0.02,4.4,2.01,9.03-1.42,13.06C227.13,283.27,227.03,282.62,226.93,281.97z"
				/>
				<path
					fill={st85}
					d="M156.39,137.91c3.93,3.98,8.16,7.56,14.11,9.81c-9.14,3.98-15.45,9.94-19.11,19.29
          c-2.05-5.33-5.18-9.17-8.42-12.93c-0.54-1.42-2.11-3.04,0.17-4.18c5.08-2.53,8.56-6.52,11.2-11.41
          C154.72,137.81,155.47,137.28,156.39,137.91z"
				/>
				<path
					fill={st86}
					d="M156.39,137.91c-3.69,4.99-6.46,10.87-13.02,13.01c-2.11,0.69,1.33,2.3-0.39,3.16
          c-3.24-2.26-6.33-4.75-11.05-6.14c9.43-3.99,15.58-10.05,19.29-18.99C153.07,132.17,154.73,135.04,156.39,137.91z"
				/>
				<path
					fill={st87}
					d="M489.47,47.01c3.89,0.03,6.81,3.03,6.81,6.98c-0.01,3.94-2.96,7.01-6.8,7.06c-3.85,0.05-7.42-3.53-7.27-7.31
          C482.35,50.06,485.67,46.98,489.47,47.01z"
				/>
				<path
					fill={st88}
					d="M253.14,51.79c3.39,1.03,4.65,3.7,4.51,6.83c-0.14,3.01-1.94,5.4-4.91,6.19c-3,0.8-5.93,0.42-7.63-2.8
          C246.29,57.43,250.34,55.1,253.14,51.79z"
				/>
				<path
					fill={st89}
					d="M253.14,51.79c-1.09,4.65-5.45,6.74-8.03,10.22c-2.21-2.65-1.96-5.44-0.06-8
          C247.07,51.28,249.78,50.14,253.14,51.79z"
				/>
				<path
					fill={st90}
					d="M131.4,79.07c-6.75-0.02-11.37-4.52-11.35-11.07c0.02-6.79,4.83-11.85,11.31-11.91
          c6.3-0.06,11.68,5.06,11.65,11.09C142.97,74.49,138.48,79.09,131.4,79.07z"
				/>
				<path
					fill={st91}
					d="M236.16,235.08c0-4.35,0.01-8.7,0.01-13.06c1.55-0.89,3.25-0.46,4.88-0.46c26.39-0.03,52.78-0.02,79.17-0.02
          c5.25,0,5.32,0.02,5.23,5.49c-0.05,2.62,0.54,5.3-0.44,7.87c0,0-0.03,0.24-0.03,0.24C295.37,235.13,265.77,235.1,236.16,235.08z"
				/>
				<path
					fill={st92}
					d="M382.91,435.01c0.28,12.95,0.28,12.95-12.75,12.95c-9.44,0-18.88-0.21-28.3,0.1
          c-4.08,0.13-5.29-1.3-4.91-5.11c0.26-2.62,0.08-5.29,0.1-7.94C352.33,435,367.62,435,382.91,435.01z"
				/>
				<path
					fill={st41}
					d="M154.05,336.99c11.15,0,22.3-0.03,33.44,0.03c1.52,0.01,3.83-0.81,4.24,1.42c0.53,2.88,0.85,5.93-0.3,8.8
          c-0.14,0.34-1.16,0.49-1.75,0.45c-22.75-1.6-45.52-0.35-68.28-0.6c-3.97-0.04-5.45-0.88-5.51-5.19c-0.06-4.64,2-5.03,5.71-4.97
          C132.41,337.11,143.23,336.99,154.05,336.99z"
				/>
				<path
					fill={st93}
					d="M326.96,198c0.01-11.16,0.14-22.33-0.04-33.49c-0.06-3.51,1.08-4.56,4.56-4.54c18.99,0.14,37.99,0.14,56.98,0
          c3.47-0.03,4.62,1.02,4.56,4.54c-0.18,11.16-0.05,22.32-0.04,33.49c0,0-0.06,0.07-0.06,0.07c-1.92,1.72-4.3,1.56-6.6,1.57
          c-17.57,0.04-35.14,0.04-52.71,0c-2.31-0.01-4.69,0.14-6.6-1.58L326.96,198z"
				/>
				<path
					fill={st94}
					d="M325.01,234.91c-0.06-12.88-0.06-12.88-13.03-12.88c-25.27,0-50.54,0-75.82,0c0-0.33,0-0.66,0-1
          c28.36,0,56.71,0.06,85.07-0.07c3.52-0.02,5.28,0.63,4.83,4.59c-0.36,3.11-0.18,6.29-0.24,9.43
          C325.55,235,325.28,234.98,325.01,234.91z"
				/>
				<path
					fill={st95}
					d="M327.02,198.06c21.97,0,43.94,0.01,65.91,0.01c0.13,11.96,0.13,11.96-11.83,11.96
          c-16.08,0-32.16-0.19-48.23,0.11c-4.87,0.09-6.59-1.4-5.93-6.13C327.22,202.07,327.01,200.05,327.02,198.06z"
				/>
				<path
					fill={st96}
					d="M131.33,60.75c3.84-0.02,6.94,3.02,7,6.86c0.06,3.98-3.34,7.41-7.18,7.26c-3.45-0.13-6.97-3.79-6.93-7.21
          C124.25,64.06,127.65,60.76,131.33,60.75z"
				/>
			</g>
		</chakra.svg>
	);
}
