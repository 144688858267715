import React, { useMemo } from "react";
import { Select, IconButton } from "@chakra-ui/react";
// import DataInput from "../../../core/Inputs/data/DataInput";

const hourOptions = [
	{ value: "0.0", title: "0.0" },
	// { value: "0.5", title: "0.5" },
	{ value: "1.0", title: "1.0" },
	// { value: "1.5", title: "1.5" },
	{ value: "2.0", title: "2.0" },
	// { value: "2.5", title: "2.5" },
	{ value: "3.0", title: "3.0" },
	// { value: "3.5", title: "3.5" },
	{ value: "4.0", title: "4.0" },
	// { value: "4.5", title: "4.5" },
	{ value: "5.0", title: "5.0" },
	// { value: "5.5", title: "5.5" },
	{ value: "6.0", title: "6.0" },
	// { value: "6.5", title: "6.5" },
	{ value: "7.0", title: "7.0" },
	// { value: "7.5", title: "7.5" },
	{ value: "8.0", title: "8.0" },
	// { value: "8.5", title: "8.5" },
	{ value: "9.0", title: "9.0" },
	// { value: "9.5", title: "9.5" },
	{ value: "10.0", title: "10.0" },
	// { value: "10.5", title: "10.5" },
	{ value: "11.0", title: "11.0" },
	// { value: "11.5", title: "11.5" },
	{ value: "12.0", title: "12.0" },
	// { value: "11.5", title: "11.5" },
	{ value: "13.0", title: "13.0" },
	// { value: "13.5", title: "13.5" },
	{ value: "14.0", title: "14.0" },
	// { value: "13.5", title: "14.5" },
	{ value: "15.0", title: "15.0" },
	// { value: "15.5", title: "15.5" },
	{ value: "16.0", title: "16.0" },
];

export default function TimecardEntryHourInputSmallDevice({ hour, item, disabled, onChange, hourIndex, openNotes }) {
	const isOverTime = useMemo(() => Boolean(hour > 8), [hour]);

	return (
		<>
			<Select
				variant="outline"
				bg="white"
				color={isOverTime ? "red" : "gray.600"}
				placeholder="0.0"
				onChange={(e) => {
					onChange(e, hourIndex, true);
				}}
				defaultValue={hour === "0.0" ? "" : hour}
			>
				{hourOptions?.map((hour, i) => (
					<option key={i} value={hour.value}>
						{hour.title}
					</option>
				))}
			</Select>
			<IconButton
				icon={
					<i
						className={
							"fa-lg " + (item.hasNotes(hourIndex) ? "fas fa-clipboard-check" : "far fa-clipboard")
						}
					></i>
				}
				color="gray.500"
				// position="absolute"
				// right={-4}
				bg="none"
				hidden={!Boolean(item.hasNotes(hourIndex)) && disabled}
				size="sm"
				variant="ghost"
				cursor={!Boolean(item.keyId) ? "not-allowed" : "pointer"}
				onClick={(ev) => {
					// ev.preventDefault();
					// ev.stopPropagation();
					openNotes(hourIndex);
				}}
				isDisabled={!Boolean(item.keyId)}
				tabIndex="-1"
			/>
		</>
	);
}

/* OLD

<DataInput
				key={item?.keyId}
				name={`${item?.keyId}-time`}
				type="select"
				searchable
				options={hourOptions}
				value={hour === "0.0" ? "" : hour}
				optionValue="value"
				optionText="title"
				variant="outline"
				color={isOverTime ? "red" : "gray.600"}
				isModal
				onOptionSelect={(value) => {
					onChange({ target: { value } }, hourIndex, false); //This way specifically for the searchable
				}}
			/>
			<Input
				bg="white"
				borderBottomWidth={2}
				variant="flushed"
				placeholder="0.0"
				fontWeight="semibold"
				position="relative"
				color={isOverTime ? "red" : "gray.600"}
				value={hour === "0.0" ? "" : hour}
				size="md"
				textAlign="center"
				type="number"
				isDisabled={!Boolean(item.keyId) || disabled || item.isLocked()}
				maxW="50px"
				onChange={(ev) => {
					ev.preventDefault();
					ev.stopPropagation();
					onChange(ev, hourIndex, false);
				}}
				onBlur={(ev) => {
					ev.preventDefault();
					ev.stopPropagation();
					onChange(ev, hourIndex, true);
				}}
			/>

*/
