import React, { useMemo } from "react";
import { VStack, Heading, Stack, HStack } from "@chakra-ui/react";
import DataInput from "../../../core/Inputs/data/DataInput";

// import { log } from "../../../../helperFunctions";
export default function Comments(props) {
	const contract = useMemo(() => props.contract, [props.contract]);

	return (
		<Stack w="full" spacing={4} px={4}>
			<HStack align="center">
				<Heading
					minW="25px"
					size={"md"}
					color={"gray.500"}
					textTransform="uppercase"
					isTruncated
					letterSpacing={2}
				>
					{["2", "4", "5"].includes(props.contract?.contractTypeID) ? "VI." : "VII."}
				</Heading>
				<Heading size={"md"} color={"gray.500"} textTransform="uppercase" isTruncated letterSpacing={2}>
					Comments
				</Heading>
			</HStack>
			<VStack w="full" px={4}>
				<DataInput
					key={"comments-" + contract?.comments}
					name="udComments"
					type="textarea"
					rowsMin={60}
					defaultValue={contract?.comments}
					onBlur={(ev) => {
						if (ev.target.value !== contract?.comments) {
							contract.update("comments", ev.target.value);
						}
					}}
					flex={1}
					variant="outline"
					inputBG="white"
				/>
			</VStack>
		</Stack>
	);
}
