import moment from "moment";
import PageTemplateMonthlyForecast from "./PageTemplateMonthlyForecast";
import PageTemplateManpowerProjections from "./PageTemplateManpowerProjections";
import PageTemplateCostAndBillingProjections from "./PageTemplateCostAndBillingProjections";
import PageTemplateChangeOrderLog from "../../Contracts/templates/PageTemplateChangeOrderLog";
// import PageTemplateDiversity from "./PageTemplateDiversity";

// Monthly Forcast Report and Monthly Forecast CRS Report Templates
export default class ReportTemplateMonthlyForecast {
	constructor(selectedData = [], thruMth = moment(new Date()).startOf("month")) {
		this.reportTemplate = "MonthlyForecast";
		this.reportName = "Monthly Forecast Report";
		this.pages = [
			new PageTemplateMonthlyForecast(selectedData, thruMth),
			new PageTemplateManpowerProjections(selectedData, thruMth),
			new PageTemplateCostAndBillingProjections(selectedData, thruMth),
			new PageTemplateChangeOrderLog(selectedData),
			// new PageTemplateDiversity(selectedData),
		];
	}
}
