export default class Leader {
	constructor(leader) {
		this.queryData = leader?.queryData ?? leader?.QueryData ?? "Leader";
		this.employeeUID = leader?.employeeUID ?? leader?.EmployeeUID ?? null;
		this.co = leader?.prco ?? leader?.PRCo ?? leader?.co ?? leader?.Co ?? null;
		this.prco = leader?.prco ?? leader?.PRCo ?? leader?.co ?? leader?.Co ?? null;
		this.jcco = leader?.jcco ?? leader?.JCCo ?? leader?.co ?? leader?.Co ?? null;
		this.regionID = leader?.regionID ?? leader?.RegionID ?? null;
		this.deptID = leader?.deptID ?? leader?.DeptID ?? null;
	}
}
