import { formatDate } from "../../../helperFunctions";

export default class JobCostDetailByMth {
	constructor(jobCost) {
		this.queryData = jobCost?.queryData ?? jobCost?.QueryData ?? "JobCostDetailByMth";
		this.mth = formatDate(jobCost?.mth ?? jobCost?.Mth) ?? null;
		this.jccmKeyID = jobCost?.jccmKeyID ?? jobCost?.JCCMKeyID ?? null;

		this.jcco = jobCost?.jcco ?? jobCost?.JCCo ?? null;
		this.contract = jobCost?.contract ?? jobCost?.Contract?.trim() ?? null;
		this.job = jobCost?.job ?? jobCost?.Job?.trim() ?? null;
		this.contractItem = jobCost?.contractItem ?? jobCost?.ContractItem?.trim() ?? null;

		this.jcpmKeyID = jobCost?.jcpmKeyID ?? jobCost?.JCPMKeyID ?? null;
		this.phase = jobCost?.phase ?? jobCost?.Phase ?? null;
		this.phaseDesc = jobCost?.phaseDesc ?? jobCost?.PhaseDesc ?? null;

		this.jcjpKeyID = jobCost?.jcjpKeyID ?? jobCost?.JCJPKeyID ?? null;
		this.jobPhase = jobCost?.jobPhase ?? jobCost?.JobPhase ?? null;
		this.jobPhaseDesc = jobCost?.jobPhaseDesc ?? jobCost?.JobPhaseDesc ?? null;

		this.costType = jobCost?.costType ?? jobCost?.CostType ?? null;
		this.costTypeDesc = jobCost?.costTypeDesc ?? jobCost?.CostTypeDesc ?? null;
		this.costTypeFullDesc =
			jobCost?.costType ?? jobCost?.CostType + " - " + jobCost?.costTypeDesc ?? jobCost?.CostTypeDesc ?? null;

		this.origEstCost = jobCost?.origEstCost ?? jobCost?.OrigEstCost ?? 0;
		this.origEstHours = jobCost?.origEstHours ?? jobCost?.OrigEstHours ?? 0;
		this.origEstUnits = jobCost?.origEstUnits ?? jobCost?.OrigEstUnits ?? 0;

		this.currEstCost = jobCost?.currEstCost ?? jobCost?.CurrEstCost ?? 0;
		this.currEstHours = jobCost?.currEstHours ?? jobCost?.CurrEstHours ?? 0;
		this.currEstUnits = jobCost?.currEstUnits ?? jobCost?.CurrEstUnits ?? 0;

		this.actualCost = jobCost?.actualCost ?? jobCost?.ActualCost ?? 0;
		this.actualHours = jobCost?.actualHours ?? jobCost?.ActualHours ?? 0;
		this.actualUnits = jobCost?.actualUnits ?? jobCost?.ActualUnits ?? 0;

		this.projCost = jobCost?.projCost ?? jobCost?.ProjCost ?? 0;
		this.projHours = jobCost?.projHours ?? jobCost?.ProjHours ?? 0;
		this.projUnits = jobCost?.projUnits ?? jobCost?.ProjUnits ?? 0;

		this.remainCmtdCost = jobCost?.remainCmtdCost ?? jobCost?.RemainCmtdCost ?? 0;
		this.remainCmtdUnits = jobCost?.remainCmtdUnits ?? jobCost?.RemainCmtdUnits ?? 0;
		this.totalCmtdCost = jobCost?.totalCmtdCost ?? jobCost?.TotalCmtdCost ?? 0;
		this.totalCmtdUnits = jobCost?.totalCmtdUnits ?? jobCost?.TotalCmtdUnits ?? 0;

		this.actualPlusCmtdCost =
			jobCost?.actualPlusCmtdCost ??
			(jobCost?.actualCost ?? jobCost?.ActualCost ?? 0) +
				(jobCost?.remainCmtdCost ?? jobCost?.RemainCmtdCost ?? 0);
		this.actualPlusCmtdUnits =
			jobCost?.actualPlusCmtdUnits ??
			(jobCost?.actualUnits ?? jobCost?.ActualUnits ?? 0) +
				(jobCost?.remainCmtdUnits ?? jobCost?.RemainCmtdUnits ?? 0);

		this.remainCost =
			jobCost?.remainCost ??
			(jobCost?.projCost ?? jobCost?.ProjCost ?? 0) - (jobCost?.actualCost ?? jobCost?.ActualCost ?? 0);
		this.remainHours =
			jobCost?.remainHours ??
			(jobCost?.projHours ?? jobCost?.ProjHours ?? 0) - (jobCost?.actualHours ?? jobCost?.ActualHours ?? 0);
		this.remainUnits =
			jobCost?.remainUnits ??
			(jobCost?.projUnits ?? jobCost?.ProjUnits ?? 0) - (jobCost?.actualUnits ?? jobCost?.ActualUnits ?? 0);

		this.getRemaining();
	}

	getRemaining() {
		this.remainCost = this.projCost - this.actualCost;
		this.remainHours = this.projHours - this.actualHours;
		this.remainUnits = this.projUnits - this.actualUnits;
	}
}
