import React, { useMemo, useEffect, useState } from "react";
import { VStack, Container, Divider, Box, Flex, Heading, HStack, Tooltip, IconButton, Text } from "@chakra-ui/react";
import DataInput from "../../../core/Inputs/data/DataInput";
import PPMLogo from "../../../core/Logos/components/PPMLogo";

import { portalUser } from "../../../App";
import { formatValue } from "../../../helperFunctions";

export default function Page(props) {
	return (
		<VStack spacing={8} w="full">
			<Container
				maxW={props.maxW ?? "full"}
				w="full"
				bg="white"
				rounded="5"
				shadow="xl"
				minH={props.minH ?? "8xl"}
				px={8}
				py={8}
				display="flex"
				flexDirection="column"
				flex={1}
			>
				<Flex direction="column" align="flex-start" w="full" h="full" flex={1}>
					<PageHeader
						closeForm={props.closeForm}
						printForm={props.printForm}
						selectContract={props.selectContract}
						selectThruMth={props.selectThruMth}
						contract={props.contract}
						thruMth={props.thruMth}
						contractData={props.contractData}
						title={props.title}
						hideDropdown={props.hideDropdown}
					/>

					<VStack spacing={4} pt="2" w="full" flex={1} overflow="hidden">
						{props.children}
					</VStack>
				</Flex>
			</Container>
		</VStack>
	);
}

function PageHeader(props) {
	const [contracts, setContracts] = useState([]);
	const [months, setMonths] = useState([]);
	const [region, setRegion] = useState({});

	useEffect(() => {
		let data = portalUser?.getContracts();
		data = data?.filter((d) => d.accessYN === "Y" && d.contractStatus === "1") ?? [];

		setContracts(data);
	}, []);

	useMemo(() => {
		let regions = portalUser?.getRegions();
		let region = regions.filter((p) => p.regionID === props.contract?.regionID)[0] ?? {};

		setRegion(region);
	}, [props.contract?.regionID]);

	useMemo(() => {
		let contractTotalsByMth = props.contractData?.filter((d) => d.queryData === "ContractTotalsByMth") ?? [];

		let months = [];
		if (contractTotalsByMth.length > 0) {
			months = contractTotalsByMth?.map(({ mth, title, subtitle }) => {
				return {
					mth,
					title,
					subtitle,
				};
			});
		}
		setMonths(months);
	}, [props.contractData]);

	return (
		<Box spacing={1} w="full" divider={<Divider borderColor={"gray.600"} borderBottomWidth={"1px"} opacity={1} />}>
			<Flex w="full" justify="space-between">
				<PPMLogo color={"teal.700"} h="55px" minW="225px" />

				<VStack spacing={2} flex={1} px="4" py="2" align="center" minH="75px">
					<Heading
						as={"h1"}
						textAlign="center"
						fontSize={"3xl"}
						letterSpacing={4}
						w="full"
						color={"gray.500"}
						textTransform="uppercase"
						isTruncated
					>
						{props.title}
					</Heading>

					{!props?.hideDropdown && (
						<Box minH={10}>
							<Flex align="center">
								<Flex minW={props.thruMth ? "md" : "lg"}>
									<DataInput
										type="select"
										variant="outline"
										placeholder="Select Project"
										searchable
										value={props.contract?.title ?? null}
										options={contracts}
										inputProps={{
											fontSize: "lg",
											bg: "transparent",
											py: "0",
											fontWeight: "semibold",
											textAlign: "left",
											color: "teal.600",
										}}
										optionText="title"
										optionSubheading="subtitle"
										onOptionSelect={(e) => {
											props.selectContract(e.jccmKeyID);
										}}
									/>
								</Flex>

								{props.thruMth && (
									<>
										<Heading
											textAlign="center"
											px={2}
											fontSize="sm"
											color="gray.400"
											textTransform="uppercase"
											letterSpacing={2}
										>
											Thru
										</Heading>
										{Boolean(
											["2", "4", "5"].includes(props.contract?.contractTypeID) &&
												props.title === "Monthly Forecast Report"
										) ? (
											<Heading
												textAlign="center"
												fontSize="lg"
												color="teal.600"
												textTransform="uppercase"
												letterSpacing={2}
											>
												{formatValue(props.thruMth, 0, "month")}
											</Heading>
										) : (
											<Flex minW="125px">
												<DataInput
													type="select"
													variant="outline"
													placeholder="MMM YYYY"
													searchable
													inputProps={{
														bg: "transparent",
														textTransform: "uppercase",
														textAlign: "left",
														py: "0",
														fontWeight: "semibold",
														fontSize: "lg",
														color: "teal.600",
													}}
													value={formatValue(props.thruMth, 0, "month")}
													options={months}
													optionValue="mth"
													optionText="title"
													optionSubheading="subtitle"
													onOptionSelect={props.selectThruMth}
												/>
											</Flex>
										)}
									</>
								)}
							</Flex>
						</Box>
					)}
				</VStack>

				<Flex minW="225px" justify="flex-end">
					<HStack align="flex-start" spacing={2}>
						{Boolean(props.contract?.jccmKeyID) && (
							<Tooltip label="Refresh Data" hasArrow={true} placement="top">
								<IconButton
									onClick={() => props.selectContract(props.contract?.jccmKeyID, false, true)}
									variant="ghost"
									color="gray.400"
									icon={<i className="fas fa-sync-alt fa-lg" />}
								/>
							</Tooltip>
						)}

						<Tooltip label="Print/Download" hasArrow={true} placement="top">
							<IconButton
								onClick={props.printForm}
								aria-label="printPDF"
								variant="ghost"
								color="gray.400"
								icon={<i className="fas fa-print fa-lg" />}
							></IconButton>
						</Tooltip>

						<Tooltip label="Close Report" hasArrow={true} placement="top">
							<IconButton
								onClick={props.closeForm}
								variant="ghost"
								color="gray.400"
								icon={<i className="far fa-times-circle fa-lg " />}
							/>
						</Tooltip>
					</HStack>
				</Flex>
			</Flex>

			<Flex w="full" flex={1} justify="center" py={2} borderTopWidth={"2px"} borderTopColor={"gray.400"} px="6">
				<HStack
					color={"gray.600"}
					spacing={6}
					w="full"
					justify="center"
					align="center"
					divider={
						<Text px="6" fontSize="xs">
							<i className="fas fa-circle fa-xs"></i>
						</Text>
					}
				>
					<Text fontSize="md" isTruncated>
						{region?.address ?? "18250 Euclid Street"}
					</Text>
					<Text fontSize="md" isTruncated>
						{region?.city ?? "Fountain Valley"}, {region?.state ?? "CA"} {region?.zip ?? "92708"}
					</Text>
					<Text fontSize="md" isTruncated>
						Phone: {region?.phone ?? "(949) 474-9170"}
					</Text>
					<Text fontSize="md" isTruncated>
						Fax: {region?.fax ?? "(949) 474-9936"}
					</Text>
				</HStack>
			</Flex>
		</Box>
	);
}

// VERSION BELOW DO NOT DELETE
// export default function Page(props) {
// 	const [pages, setPages] = useState([]);

// 	useMemo(() => {
// 		let template = props.template ?? "ReportTemplate";
// 		let selectedKeys = props.selectedKeys ?? [];

// 		let pageTemplates = portalUser?.document?.getPageTemplates();
// 		let pageTemplate = pageTemplates.find((d) => d.pageTemplate === template);

// 		let minLength = selectedKeys?.length;
// 		if (!Boolean(minLength) || minLength === 0) {
// 			minLength = 1;
// 		}

// 		let pages = [];
// 		for (let i = 0; i < minLength; i++) {
// 			let page = pageTemplate;
// 			page.parameters.jccmKeyID = selectedKeys[i] ?? null;
// 			page.parameters.thruMth = props.thruMth;
// 			pages.push(page);
// 		}

// 		log("Set Page Template", pages);
// 		setPages(pages);
// 	}, [props.template, props.selectedKeys, props.thruMth]);

// 	return (
// 		<VStack spacing={8} w="full">
// 			 {pages?.map((page, i) => (
// 			<Container
// 			  key={i}
// 				maxW={props.maxW ?? "full"}
// 				w="full"
// 				bg="white"
// 				rounded="5"
// 				shadow="xl"
// 				minH={props.minH ?? "8xl"}
// 				px={8}
// 				py={8}
// 				display="flex"
// 				flexDirection="column"
// 				flex={1}
// 			>
// 				<Flex direction="column" align="flex-start" w="full" h="full" flex={1}>
// 					<PageHeader
// 						page={page}
// 						closeForm={props.closeForm}
// 						printForm={props.printForm}
// 						selectContract={props.selectContract}
// 						selectThruMth={props.selectThruMth}

// 					/>

// 					<VStack spacing={4} pt="2" w="full" flex={1} overflow="hidden">
// 						{props.children}
// 					</VStack>
// 				</Flex>
// 			</Container>
// 			))}
// 		</VStack>
// 	);
// }

// FUTURE VERSION BELOW DO NOT DELETE
// export default function PageHeader(props) {
// 	const [region, setRegion] = useState(null);
// 	const [contract, setContract] = useState(null);
// 	const [thruMth, setThruMth] = useState(null);

// 	const [contracts, setContracts] = useState([]);
// 	const [months, setMonths] = useState([]);
// 	const [iPPMechURL, setiPPMechURL] = useState(null);

// 	useMemo(() => {
// 		let contracts = portalUser?.getContracts() ?? [];
// 		contracts = contracts?.filter((d) => d.accessYN === "Y" && d.contractStatus === "1") ?? [];

// 		let contract = contracts?.filter((d) => d.jccmKeyID === props.jccmKeyID) ?? null;
// 		let thruMth = props.thruMth ?? formatValue(new Date(),0,'moment').startOf("month");

// 		let regions = portalUser?.getRegions() ?? [];
// 		let region = regions.find((d) => d.regionID === (contract?.region ?? portalUser?.user?.regionID ?? "0"));

// 		setContract(contract);
// 		setContracts(contracts);
// 		setThruMth(thruMth);
// 		setRegion(region);
// 	}, [props.jccmKeyID, props.thruMth]);

// 	useEffect(() => {
// 		let months =
// 			portalUser?.contractData?.filter(
// 				(d) => d.queryData === "ContractTotalsByMth" && d.jccmKeyID === contract?.jccmKeyID
// 			) ?? [];
// 		if (months.length > 0) {
// 			months = months?.map(({ mth, title, subtitle }) => {
// 				return {
// 					mth,
// 					title,
// 					subtitle,
// 				};
// 			});
// 		}
// 		setMonths(months);
// 	}, [contract]);

// 	return (
// 		<Box spacing={1} w="full" divider={<Divider borderColor={"gray.600"} borderBottomWidth={"1px"} opacity={1} />}>
// 			<Flex w="full" justify="space-between">
// 				<PPMLogo color={"teal.700"} h="55px" minW="225px" />

// 				<VStack spacing={2} flex={1} px="4" py="2" align="center" minH="75px">
// 					<Heading
// 						as={"h1"}
// 						textAlign="center"
// 						fontSize={Boolean(contract) ? "2xl" : "4xl"}
// 						letterSpacing={Boolean(contract) ? 4 : 6}
// 						w="full"
// 						color={"gray.500"}
// 						textTransform="uppercase"
// 						isTruncated
// 					>
// 						{props.page?.pageName}
// 					</Heading>

// 					{Boolean(props.page?.parameters.hasOwnProperty("jccmKeyID")) && (
// 						<Box minH={10}>
// 							<Flex align="center">
// 								<Flex minW={thruMth ? "md" : "lg"}>
// 									<DataInput
// 										type="select"
// 										variant="outline"
// 										placeholder="Select Project"
// 										searchable
// 										value={contract?.title ?? null}
// 										options={contracts}
// 										inputProps={{
// 											fontSize: "lg",
// 											bg: "transparent",
// 											py: "0",
// 											fontWeight: "semibold",
// 											textAlign: "left",
// 											color: "teal.600",
// 										}}
// 										optionText="title"
// 										optionSubheading="subtitle"
// 										onOptionSelect={(e) => {
// 											props.selectContract(e.jccmKeyID);
// 										}}
// 									/>
// 								</Flex>

// 								{thruMth && (
// 									<>
// 										<Heading
// 											textAlign="center"
// 											px={2}
// 											fontSize="sm"
// 											color="gray.400"
// 											textTransform="uppercase"
// 											letterSpacing={2}
// 										>
// 											Thru
// 										</Heading>
// 										{Boolean(
// 											["2", "4", "5"].includes(contract?.contractTypeID) &&
// 												props.title === "Monthly Forecast Report"
// 										) ? (
// 											<Heading
// 												textAlign="center"
// 												fontSize="lg"
// 												color="teal.600"
// 												textTransform="uppercase"
// 												letterSpacing={2}
// 											>
// 												{formatValue(thruMth, 0, 'month')}
// 											</Heading>
// 										) : (
// 											<Flex minW="125px">
// 												<DataInput
// 													type="select"
// 													variant="outline"
// 													placeholder="MMM YYYY"
// 													searchable
// 													inputProps={{
// 														bg: "transparent",
// 														textTransform: "uppercase",
// 														textAlign: "left",
// 														py: "0",
// 														fontWeight: "semibold",
// 														fontSize: "lg",
// 														color: "teal.600",
// 													}}
// 													value={formatValue(thruMth, 0, 'month')}
// 													options={months}
// 													optionValue="mth"
// 													optionText="title"
// 													optionSubheading="subtitle"
// 													onOptionSelect={props.selectThruMth}
// 												/>
// 											</Flex>
// 										)}
// 									</>
// 								)}
// 							</Flex>
// 						</Box>
// 					)}
// 				</VStack>

// 				<Flex minW="225px" justify="flex-end">
// 					<HStack align="flex-start" spacing={2}>
// 						{/* {Boolean(props.contract) && (
// 							<PDFDownloadLink
// 								document={<DocumentPDF document={document} logo={logo} />}
// 								fileName="MonthlyForecastReport.pdf"
// 							>
// 								<Tooltip label="Print/Download" hasArrow={true} placement="top">
// 									<IconButton

// 										aria-label="printPDF"
// 										variant="ghost"
// 										color="gray.400"
// 										icon={<i className="fas fa-print fa-lg" />}
// 									></IconButton>
// 								</Tooltip>
// 							</PDFDownloadLink>

// 						)} */}
// 						{Boolean(iPPMechURL) && (
// 							<Tooltip
// 								label="This link will temporarily load a PDF from iPPMech.com"
// 								hasArrow={true}
// 								placement="top"
// 							>
// 								<Link color="gray.400" href={iPPMechURL} isExternal target="_blank">
// 									<IconButton
// 										onClick={props.printForm}
// 										aria-label="printPDF"
// 										variant="ghost"
// 										color="gray.400"
// 										icon={<i className="fas fa-print fa-lg" />}
// 									></IconButton>
// 								</Link>
// 							</Tooltip>
// 						)}
// 						{!Boolean(iPPMechURL) && (
// 							<Tooltip label="Print/Download" hasArrow={true} placement="top">
// 								<IconButton
// 									onClick={props.printForm}
// 									aria-label="printPDF"
// 									variant="ghost"
// 									color="gray.400"
// 									icon={<i className="fas fa-print fa-lg" />}
// 								></IconButton>
// 							</Tooltip>
// 						)}
// 						<Tooltip label="Close" hasArrow={true} placement="top">
// 							<IconButton
// 								onClick={props.closeForm}
// 								variant="ghost"
// 								color="gray.400"
// 								icon={<i className="far fa-times-circle fa-lg " />}
// 							/>
// 						</Tooltip>
// 					</HStack>
// 				</Flex>
// 			</Flex>

// 			<Flex w="full" flex={1} justify="center" py={2} borderTopWidth={"2px"} borderTopColor={"gray.400"} px="6">
// 				<HStack
// 					color={"gray.600"}
// 					spacing={6}
// 					w="full"
// 					justify="center"
// 					align="center"
// 					divider={
// 						<Text px="6" fontSize="xs">
// 							<i className="fas fa-circle fa-xs"></i>
// 						</Text>
// 					}
// 				>
// 					<Text fontSize="md" isTruncated>
// 						{region?.address ?? "18250 Euclid Street"}
// 					</Text>
// 					<Text fontSize="md" isTruncated>
// 						{region?.city ?? "Fountain Valley"}, {region?.state ?? "CA"} {region?.zip ?? "92708"}
// 					</Text>
// 					<Text fontSize="md" isTruncated>
// 						Phone: {region?.phone ?? "(949) 474-9170"}
// 					</Text>
// 					<Text fontSize="md" isTruncated>
// 						Fax: {region?.fax ?? "(949) 474-9936"}
// 					</Text>
// 				</HStack>
// 			</Flex>
// 		</Box>
// 	);
// }
