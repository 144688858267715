import { portalUser } from "../../../App";
import { formatDate } from "../../../helperFunctions";

export default class HRLongevityArrayClassLegacy extends Array {
	constructor(data = []) {
		super();
		if (Boolean(data?.length > 0)) {
			for (let i = 0; i < data?.length; i++) {
				let row = new HRLongevityRowClassLegacy(data[i]);
				this.push(row);
			}
		}
	}

	getAwardYearRange(awardYears, thresholdMth = 10) {
		for (let i = 0; i < this?.length; i++) {
			this[i].setAwardYearRange(awardYears, thresholdMth);
		}
		return this;
	}
}

class HRLongevityRowClassLegacy {
	constructor(row) {
		this.employeeUID = (row?.employeeUID ?? row?.EmployeeUID)?.toLowerCase() ?? null;
		this.name = row?.name ?? row?.Name ?? null;
		this.email = row?.email ?? row?.Email ?? null;
		this.region = row?.region ?? row?.Region ?? null;
		this.employeeType = row?.employeeType ?? row?.EmployeeType ?? null;
		this.hireDate = formatDate(row?.hireDate) ?? formatDate(row?.HireDate) ?? null;
		this.currLongevity = 0;
		this.projLongevity = row?.projLongevity ?? row?.LongevityYears ?? 0;
		this.prevLongevity = row?.prevLongevity ?? row?.PreviousLongevity ?? 0;

		this.currLongevityDesc = row?.currLongevityDesc ?? this.getLongevityDesc(this.currLongevity);
		this.projLongevityDesc = row?.projLongevityDesc ?? this.getLongevityDesc(this.projLongevity);
		this.prevLongevityDesc = row?.prevLongevityDesc ?? this.getLongevityDesc(this.prevLongevity);

		this.lastAward = row?.lastAward ?? row?.LastAward ?? null;
		this.lastAwardYear = row?.lastAwardYear ?? row?.LastAwardYear ?? null;

		this.awardYear5 = row?.awardYear5 ?? row?.AwardYear5 ?? null;
		this.awardYear7 = row?.awardYear7 ?? row?.AwardYear7 ?? null;
		this.awardYear10 = row?.awardYear10 ?? row?.AwardYear10 ?? null;
		this.awardYear15 = row?.awardYear15 ?? row?.AwardYear15 ?? null;
		this.awardYear20 = row?.awardYear20 ?? row?.AwardYear20 ?? null;
		this.awardYear25 = row?.awardYear25 ?? row?.AwardYear25 ?? null;
		this.awardYear30 = row?.awardYear30 ?? row?.AwardYear30 ?? null;
		this.awardYear35 = row?.awardYear35 ?? row?.AwardYear35 ?? null;
		this.awardYear40 = row?.awardYear40 ?? row?.AwardYear40 ?? null;
		this.awardYear45 = row?.awardYear45 ?? row?.AwardYear45 ?? null;
		this.awardYear50 = row?.awardYear50 ?? row?.AwardYear50 ?? null;
		this.awardYear55 = row?.awardYear55 ?? row?.AwardYear55 ?? null;
		this.awardYear60 = row?.awardYear60 ?? row?.AwardYear60 ?? null;
		this.awardYear65 = row?.awardYear65 ?? row?.AwardYear65 ?? null;
		this.awardYear70 = row?.awardYear70 ?? row?.AwardYear70 ?? null;

		this.yearRange = row?.yearRange ?? null;
		this.projYear = row?.projYear ?? parseInt(this.projLongevity) ?? 0;
		this.awardThisYear = row?.awardThisYear ?? false;

		this.longevityHistory = null;
	}

	getLongevityDesc(value = 0) {
		value = parseFloat(value ?? 0);
		let years = parseInt(value);
		let months = value - parseFloat(years);

		if (months <= 0) {
			months = 0;
		} else {
			months = parseInt(months * 12);
		}
		let longevityDesc = { years: years, months: months };
		return longevityDesc;
	}

	setAwardYearRange(awardYears, thresholdMth = 10) {
		let projYear = this.projLongevityDesc?.years;
		if (this.projLongevityDesc?.months > thresholdMth) {
			projYear = projYear + 1;
			this.projYear = projYear;
		}

		let minValue = 0;
		for (let i = 0; i < awardYears?.length; i++) {
			if (projYear === awardYears[i]) {
				this.awardThisYear = true;
			}

			if (projYear >= minValue && projYear < awardYears[i]) {
				if (minValue === 0) {
					this.yearRange = "Less Than " + awardYears[i] + " Years";
				} else {
					this.yearRange = minValue + "+ Years";
				}
				break;
			}
			minValue = awardYears[i];
		}
	}

	async updateDB(updates) {
		await portalUser.usersAPI?.UpdateLongevityAward(this.employeeUID, updates);
	}

	async updateEmployeeLongevityRow(attribute, value) {
		// Validate Input
		if (value !== null) {
			// Remove Non Numeric characters
			value = value?.toString();
			value = value?.replace(/\D/g, "");

			// Remove Non Numeric characters
			if (isNaN(value)) {
				value = null;
				// log("NaN");
			} else if (value?.length !== 4) {
				value = null;
				// log("Length Not 4");
			}
		}

		let currentValue = this[attribute];
		if (currentValue !== null) {
			currentValue = currentValue.toString();
		}

		this[attribute] = value;

		if (value !== currentValue) {
			let dbAttribute = this.getDBAttribute(attribute);
			let update = { [dbAttribute]: value };
			await this.updateDB(update);
		}
	}

	getDBAttribute(attribute) {
		if (attribute === "awardYear5") {
			return "AwardYear5";
		} else if (attribute === "awardYear7") {
			return "AwardYear7";
		} else if (attribute === "awardYear10") {
			return "AwardYear10";
		} else if (attribute === "awardYear15") {
			return "AwardYear15";
		} else if (attribute === "awardYear20") {
			return "AwardYear20";
		} else if (attribute === "awardYear25") {
			return "AwardYear25";
		} else if (attribute === "awardYear30") {
			return "AwardYear30";
		} else if (attribute === "awardYear35") {
			return "AwardYear35";
		} else if (attribute === "awardYear40") {
			return "AwardYear40";
		} else if (attribute === "awardYear45") {
			return "AwardYear45";
		} else if (attribute === "awardYear50") {
			return "AwardYear50";
		} else if (attribute === "awardYear55") {
			return "AwardYear55";
		} else if (attribute === "awardYear60") {
			return "AwardYear60";
		} else if (attribute === "awardYear65") {
			return "AwardYear65";
		} else if (attribute === "awardYear70") {
			return "AwardYear70";
		}
	}
}
