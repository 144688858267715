import React from "react";
import { Text, RadioGroup, Stack, Radio } from "@chakra-ui/react";

export default function RadioInput(props) {
	return (
		<RadioGroup
			name={props.name}
			value={props.value}
			onChange={props.onChange}
			colorScheme={props.colorScheme}
			size={props.size}
			readOnly={props.readOnly}
			{...props.inputProps}
		>
			{props.children && <>{props.children}</>}
			{!props.children && props.options && (
				<Stack pl={2} direction={props.direction ?? "column"} spacing={1}>
					{props.options?.map((option, i) => (
						<Radio value={option[props.optionValue ?? "value"]} mb={0}>
							<Text fontWeight="500" color="gray.600">
								{option[props.optionText ?? "text"]}
							</Text>
						</Radio>
					))}
				</Stack>
			)}
			{!props.children && props.text && (
				<Radio value={props.radioValue} mb={0}>
					<Text fontWeight="500" color="gray.600">
						{props.text}
					</Text>
				</Radio>
			)}
		</RadioGroup>
	);
}
