import React from "react";
import { HStack, Box } from "@chakra-ui/react";

export default function ContainerHeightsPage() {
	return (
		<HStack spacing={2} w="full" align="flex-start">
			<Box
				bg="teal.900"
				w="container.md"
				h={"8xl"}
				color="gray.50"
				borderWidth={1}
				borderColor="gray.300"
				p="2"
				rounded={"md"}
			>
				Extra-Large Box (8xl)
			</Box>
			<Box
				bg="teal.800"
				w="container.md"
				h={"7xl"}
				color="gray.50"
				borderWidth={1}
				borderColor="gray.300"
				p="2"
				rounded={"md"}
			>
				Extra-Large Box (7xl)
			</Box>
			<Box
				bg="teal.700"
				w="container.md"
				h={"6xl"}
				color="gray.50"
				borderWidth={1}
				borderColor="gray.300"
				p="2"
				rounded={"md"}
			>
				Extra-Large Box (6xl)
			</Box>
			<Box
				bg="teal.600"
				w="container.md"
				h={"5xl"}
				color="gray.50"
				borderWidth={1}
				borderColor="gray.300"
				p="2"
				rounded={"md"}
			>
				Extra-Large Box (5xl)
			</Box>
			<Box
				bg="teal.500"
				w="container.md"
				h={"4xl"}
				color="gray.50"
				borderWidth={1}
				borderColor="gray.300"
				p="2"
				rounded={"md"}
			>
				Extra-Large Box (4xl)
			</Box>
			<Box
				bg="teal.400"
				w="container.md"
				h={"3xl"}
				color="gray.50"
				borderWidth={1}
				borderColor="gray.300"
				p="2"
				rounded={"md"}
			>
				Extra-Large Box (3xl)
			</Box>
			<Box
				bg="teal.300"
				w="container.md"
				h={"2xl"}
				color="gray.50"
				borderWidth={1}
				borderColor="gray.300"
				p="2"
				rounded={"md"}
			>
				Extra-Large Box (2xl)
			</Box>
			<Box
				bg="teal.200"
				w="container.md"
				h={"xl"}
				color="gray.600"
				borderWidth={1}
				borderColor="gray.300"
				p="2"
				rounded={"md"}
			>
				Extra-Large Box (xl)
			</Box>
			<Box
				bg="teal.100"
				w="container.md"
				h={"lg"}
				color="gray.600"
				borderWidth={1}
				borderColor="gray.300"
				p="2"
				rounded={"md"}
			>
				Large Box (lg)
			</Box>
			<Box
				bg="teal.50"
				w="container.md"
				h={"md"}
				color="gray.600"
				borderWidth={1}
				borderColor="gray.300"
				p="2"
				rounded={"md"}
			>
				Medium Box (md)
			</Box>
			<Box
				bg="white"
				w="container.md"
				h={"sm"}
				color="gray.600"
				borderWidth={1}
				borderColor="gray.300"
				p="2"
				rounded={"md"}
			>
				Small Box (sm)
			</Box>
			<Box
				bg="blackAlpha.50"
				w="container.md"
				h={"xs"}
				color="gray.600"
				borderWidth={1}
				borderColor="gray.300"
				p="2"
				rounded={"md"}
			>
				Extra-Small Box (xs)
			</Box>
			<Box
				bg="blackAlpha.100"
				w="container.md"
				h={"2xs"}
				color="gray.600"
				borderWidth={1}
				borderColor="gray.300"
				p="2"
				rounded={"md"}
			>
				2x Extra-Small Box (2xs)
			</Box>
			<Box
				bg="blackAlpha.200"
				w="container.md"
				h={"3xs"}
				color="gray.600"
				borderWidth={1}
				borderColor="gray.300"
				p="2"
				rounded={"md"}
			>
				3x Extra-Small Box (3xs)
			</Box>
		</HStack>
	);
}
