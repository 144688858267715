import { formatDate, getSubtotal } from "../../../helperFunctions";
import moment from "moment";
export default class ItemTypeTotalsByMth {
	constructor(itemType = null, lumpSumGCs = true) {
		this.queryData = itemType?.queryData ?? itemType?.QueryData ?? "ItemTypeTotalsByMth";
		this.jccmKeyID = itemType?.jccmKeyID ?? itemType?.JCCMKeyID ?? null;
		this.jcco = itemType?.jcco ?? itemType?.JCCo ?? null;
		this.contract = itemType?.contract ?? itemType?.Contract?.trim() ?? null;
		this.startMth = formatDate(itemType?.startMth ?? itemType?.StartMth) ?? null;
		this.endMth = formatDate(itemType?.endMth ?? itemType?.EndMth) ?? null;

		this.year = moment(new Date(formatDate(itemType?.mth ?? itemType?.Mth) ?? null)).format("YYYY");
		this.mth = formatDate(itemType?.mth ?? itemType?.Mth) ?? null;

		this.itemTypeSeq = itemType?.itemTypeSeq ?? itemType?.ItemTypeSeq;
		this.itemTypeID = itemType?.itemTypeID ?? itemType?.ItemTypeID ?? "COW";
		this.itemType = itemType?.itemType ?? itemType?.ItemType ?? "COW";
		this.itemTypeDesc = this.getItemTypeDesc();

		this.origContractAmt = itemType?.origContractAmt ?? itemType?.OrigContractAmt ?? 0;
		this.adjOrigContractAmt = itemType?.adjOrigContractAmt ?? itemType?.AdjOrigContractAmt ?? 0;
		this.bidContractAmt = itemType?.bidContractAmt ?? itemType?.BidContractAmt ?? 0;
		this.contractAmt = itemType?.contractAmt ?? itemType?.ContractAmt ?? 0;
		this.acoAmt =
			(itemType?.acoAmt ?? itemType?.ACOAmt ?? 0) -
			(itemType?.adjOrigContractAmt ?? itemType?.AdjOrigContractAmt ?? 0);

		// this.pcoAmt = itemType?.pcoAmt ?? itemType?.PCOAmt ?? 0;
		// this.pcoConvTot = itemType?.pcoConvTot ?? itemType?.PCOConvTot ?? 0;

		this.pcoConv = itemType?.pcoConv ?? itemType?.PCOConv ?? 0;
		this.pcoEstCost = itemType?.pcoEstCost ?? itemType?.PCOEstCost ?? 0;
		this.pcoActualCost = itemType?.pcoActualCost ?? itemType?.PCOActualCost ?? 0;

		this.projContractAmt = this.getProjContractAmt();

		this.billedAmt = itemType?.billedAmt ?? itemType?.BilledAmt ?? 0;
		this.adjBilledAmt = itemType?.adjBilledAmt ?? itemType?.AdjBilledAmt ?? 0;
		this.newBilledAmt = this.getBilledAmt(lumpSumGCs);

		this.receivedAmt = itemType?.receivedAmt ?? itemType?.ReceivedAmt ?? 0;
		this.retentionAmt = itemType?.retentionAmt ?? itemType?.RetentionAmt ?? 0;

		this.origEstCost = itemType?.origEstCost ?? itemType?.OrigEstCost ?? 0;
		this.currEstCost = itemType?.currEstCost ?? itemType?.CurrEstCost ?? 0;
		this.actualCost = itemType?.actualCost ?? itemType?.ActualCost ?? 0;
		this.remainCost = itemType?.remainCost ?? 0;
		this.projCost = itemType?.projCost ?? itemType?.ProjCost ?? 0;

		this.projRemainBilling = this.getProjRemainBilling(lumpSumGCs);
		this.finalContractAmt = this.getFinalContractAmt(lumpSumGCs);

		this.itemTypeDetailByMth = itemType?.itemTypeDetailByMth ?? [];
	}

	getItemTypeDesc() {
		let itemTypeDesc = this.itemTypeID + " - " + this.itemType;
		return itemTypeDesc;
	}

	getProjContractAmt() {
		let projContractAmt =
			parseFloat(this.contractAmt ?? 0) +
			(parseFloat(this.pcoConv ?? 0) + parseFloat(this.pmaAmt ?? 0) + parseFloat(this.allowAmt ?? 0));
		return projContractAmt;
	}

	getBilledAmt(lumpSumGCs = true) {
		let billedAmt = parseFloat(this.billedAmt ?? 0);
		if (Boolean(lumpSumGCs)) {
			billedAmt += parseFloat(this.adjBilledAmt ?? 0);
		}
		return billedAmt;
	}

	getProjRemainBilling(lumpSumGCs = true) {
		let projRemainingBilling = 0;
		if (lumpSumGCs) {
			if (["COW"].includes(this.itemTypeID)) {
				projRemainingBilling = getSubtotal(this.itemTypeDetailByMth, "projRemainBilling");
			} else if (!["BUFF"].includes(this.itemTypeID)) {
				projRemainingBilling = this.getProjContractAmt() - this.getBilledAmt(lumpSumGCs);
			}
		} else {
			if (["COW", "GCS"].includes(this.itemTypeID)) {
				projRemainingBilling = getSubtotal(this.itemTypeDetailByMth, "projRemainBilling");
			} else if (!["BUFF"].includes(this.itemTypeID)) {
				projRemainingBilling = this.getProjContractAmt() - this.getBilledAmt(lumpSumGCs);
			}
		}

		return projRemainingBilling;
	}

	getFinalContractAmt(lumpSumGCs = true) {
		let finalContractAmt = this.getProjRemainBilling(lumpSumGCs) + this.getBilledAmt(lumpSumGCs);
		return finalContractAmt;
	}

	getItemTypeDetailTotals(lumpSumGCs = true) {
		let itemTypeDetailByMth = this.itemTypeDetailByMth;

		// let pcoAmt = this.pcoAmt;
		// let pcoConvPct = getRate(this.pcoConv, this.pcoConvTot);

		// if (pcoConvPct === 0 && this.pcoAmt !== 0 && this.itemTypeID === "COW") {
		// 	this.pcoAmt = pcoAmt;
		// } else {
		// 	this.pcoAmt = pcoAmt * pcoConvPct;
		// }

		for (let i = 0; i < itemTypeDetailByMth?.length; i++) {
			itemTypeDetailByMth[i]?.toggleLumpSumGCs(lumpSumGCs);
		}

		if (itemTypeDetailByMth?.length > 0) {
			this.itemTypeDetailByMth = itemTypeDetailByMth;

			this.actualCost = getSubtotal(itemTypeDetailByMth, "actualCost");
			this.remainCost = getSubtotal(itemTypeDetailByMth, "remainCost");
			this.projCost = getSubtotal(itemTypeDetailByMth, "projCost");

			this.adjBilledAmt = getSubtotal(itemTypeDetailByMth, "adjBilledAmt");
			this.preBilled = getSubtotal(itemTypeDetailByMth, "preBilled");
			this.activeBilled = getSubtotal(itemTypeDetailByMth, "activeBilled");
			this.billedIndirectCost = getSubtotal(itemTypeDetailByMth, "billedIndirectCost");
			this.unBilledCostLessIndirects = getSubtotal(itemTypeDetailByMth, "unBilledCostLessIndirects");

			this.newBilledAmt = this.getBilledAmt(lumpSumGCs);
			this.projRemainBilling = this.getProjRemainBilling(lumpSumGCs);
			this.finalContractAmt = this.getFinalContractAmt(lumpSumGCs);
		}
		return this;
	}
}
