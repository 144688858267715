import React, { useMemo, useState, useEffect, useCallback, useRef } from "react";
// import { useAsyncDebounce } from "react-table";
import FormInputClass from "../classes/FormInput";
import {
	Avatar,
	Box,
	Flex,
	Text,
	Container,
	Stack,
	HStack,
	Heading,
	useBoolean,
	Collapse,
	Radio,
	Button,
} from "@chakra-ui/react";
import BasicCard from "../../Layout/cards/BasicCard";
import DataInput from "../../Inputs/data/DataInput";
import ToggleButton from "../../ReactTable/buttons/ToggleButton";
import FormComment from "./FormComment";
import { portalUser } from "../../../App";
import { getSubtotal, convertArrayToMap, log } from "../../../helperFunctions";

export default function FormInput(props) {
	// const formData = useMemo(() => props.formData ?? null, [props.formData]);
	const formData = portalUser.formData;
	const form = useMemo(() => props.form ?? null, [props.form]);
	const section = useMemo(() => props.section ?? null, [props.section]);
	const question = useMemo(() => props.question ?? null, [props.question]);
	const ratings = useMemo(() => props.ratings ?? null, [props.ratings]);
	const inputs = useMemo(() => props.inputs ?? null, [props.inputs]);
	const [input, setInput] = useState(props.input ?? null);
	const inputNum = useMemo(() => props.inputNum ?? 0, [props.inputNum]);
	const usersMap = useMemo(() => props.usersMap, [props.usersMap]);
	const isPrinting = useMemo(() => props.isPrinting ?? false, [props.isPrinting]);
	const validationError = useMemo(() => props.validationError ?? null, [props.validationError]);

	const isReadOnly = useMemo(() => {
		let readOnly = true;
		let formStatus = null;
		if (Boolean(form)) {
			readOnly = form?.getIsReadOnly();
			formStatus = form?.getFormStatus();
		} else {
			readOnly = true;
		}

		if (portalUser.user.isPortalAdminYN === "Y") {
			readOnly = false;
		} else if (
			["radio", "rating"].includes(question?.inputType) &&
			portalUser.user.isPortalAdminYN === "Y" &&
			form?.assignedTo !== portalUser.user.employeeUID
		) {
			readOnly = true;
		} else if (form?.assignedTo === portalUser.user.employeeUID && parseInt(formStatus?.id) < 2) {
			readOnly = false;
		} else if (
			parseInt(formStatus?.id) < 5 &&
			form?.assignedFor === portalUser.user?.employeeUID &&
			form?.formType === "2" &&
			section?.heading === "Overall Comments"
		) {
			readOnly = false;
		} else if (form?.assignedTo !== portalUser.user.employeeUID && portalUser.user.isPortalAdminYN === "N") {
			readOnly = true;
		} else if (
			form?.formType === "3" &&
			form?.formUID === form?.parentFormUID &&
			form?.assignedTo === portalUser.user.employeeUID &&
			parseInt(formStatus?.id) > 2 &&
			parseInt(formStatus?.id) < 5
		) {
			readOnly = false;
		}

		return readOnly;
	}, [form, question?.inputType, section?.heading]);

	const [triggerUpdate, setTriggerUpdate] = useState(false);
	useEffect(() => {
		if (Boolean(triggerUpdate)) {
			setTriggerUpdate(false);
		}
	}, [triggerUpdate]);

	const comments = useMemo(() => {
		let comments = [];

		if (!triggerUpdate) {
			let formInputs = inputs;
			if (form?.formUID !== form?.parentFormUID) {
				formInputs = formInputs?.filter((d) => d.formUID === form?.formUID) ?? [];
			}
			let inputUIDs = [...new Set(formInputs?.map((d) => d.inputUID) ?? [])];
			inputUIDs = inputUIDs?.filter((d) => Boolean(d)) ?? [];
			comments = formData.getFormComments(inputUIDs);

			if (form?.assignedFor === portalUser.user.employeeUID) {
				comments = comments.filter((d) => d.isVisibleYN === "Y") ?? [];
			}
		}
		comments?.sort((a, b) => new Date(a.commentDate) - new Date(b.commentDate));

		return comments;
	}, [form?.assignedFor, form?.formUID, form?.parentFormUID, formData, inputs, triggerUpdate]);

	const [showCommentsCard, setShowCommentsCard] = useBoolean(false);

	const canAddEditComments = useMemo(() => form?.canAddEditComments() ?? false, [form]);

	const inputsMap = useMemo(() => (inputs?.length > 0 ? convertArrayToMap(inputs, "formUID", true) : null), [inputs]);

	const score = useMemo(() => {
		let ratingVals = ratings?.map((d) => (isNaN(parseInt(d.heading)) ? null : parseInt(d.heading))) ?? [];
		ratingVals = ratingVals.filter((d) => Boolean(d)) ?? [];

		let inputVals =
			inputsMap[form?.formUID]?.map((d) => (isNaN(ratingVals[d.value - 1]) ? null : ratingVals[d.value - 1])) ??
			[];
		inputVals = inputVals.filter((d) => Boolean(d)) ?? [];

		let maxscore = getSubtotal(ratingVals, null, "max") * (inputVals?.length ?? 0);
		let score = getSubtotal(inputVals, null, "sum");

		return score + "/" + maxscore;
	}, [form?.formUID, inputsMap, ratings]);

	const updateFormInput = async (ev, attr = null) => {
		let result;
		if (Boolean(attr)) {
			if (typeof ev === "object") {
				result = await props.input.updateFormInput(attr, ev.target.value);
			} else if (typeof ev === "string") {
				result = await props.input.updateFormInput(attr, ev);
			}
			props.resetValidation();
			let input = new FormInputClass(result);
			setInput(input);
			props.updateRadioButton(input);
		}
	};

	//Note: This had useAsyncDebounce
	const insertFormComment = useCallback(async () => {
		let comment = await props.input.insertFormComment();
		if (Boolean(comment)) {
			log("Insert Form Comment", comment);
			props.resetValidation();
			setTriggerUpdate(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Note: This had useAsyncDebounce
	const deleteFormComment = useCallback(async (commentUID = null) => {
		let comment = await props.input.deleteFormComment(commentUID);
		if (Boolean(comment)) {
			log("Delete Form Comment", comment);
			let newComments = comments?.filter((d) => d.commentUID !== comment?.commentUID);
			if (comment?.error) {
				newComments.push(comment);
			} else {
				props.resetValidation();
				setTriggerUpdate(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	var commentCardStyles = portalUser.styles;
	commentCardStyles.cardBG = "gray.100";

	const [isInvalid, setIsInvalid] = useBoolean(false);

	const validateInput = useCallback(
		(input = null) => {
			let error = null;
			if (Boolean(input)) {
				error = input.validate();
			}
			if (Boolean(error)) {
				log("input Error", error);
				setIsInvalid.on();
			} else {
				setIsInvalid.off();
			}
		},
		[setIsInvalid]
	);

	useMemo(() => {
		if (Boolean(props.isInvalid)) {
			validateInput(input);
		}
	}, [input, props.isInvalid, validateInput]);

	const styles = useMemo(() => {
		let flexDirection = isPrinting ? "row" : { base: "column", md: "row" };

		let headingFontColor = isPrinting ? "var(--chakra-colors-gray-800)" : "gray.500";
		let headingFontSize = isPrinting ? "18px" : "xl";
		let headingLetterSpacing = 2;
		let headingTextTransform = "uppercase";

		let subheadingTextAlign = "right";
		let subheadingMinW = isPrinting ? "150px" : 32;
		let subheadingMaxW = isPrinting ? "250px" : 40;
		let subheadingFontColor = isPrinting ? "var(--chakra-colors-gray-800)" : "gray.500";
		let subheadingFontSize = isPrinting ? "12px" : "md";
		let subheadingLetterSpacing = 1;

		let inputFontSize = isPrinting ? "16px" : "md";
		let inputFontColor = isPrinting ? "var(--chakra-colors-gray-800)" : "gray.500";
		let inputBottomColor = isInvalid ? "red.600" : isPrinting ? "var(--chakra-colors-gray-700)" : "gray.300";
		let inputBottomWidth = 2;
		let inputBorderWidth = isPrinting ? 0 : 1;
		let inputBorderColor = isInvalid ? "red.600" : isPrinting ? "transparent" : "gray.200";

		// let inputBorderColor = isReadOnly || isPrinting ? "transparent" : "gray.200";

		let radioFontSize = isPrinting ? "18px" : "lg";
		let descFontSize = isPrinting ? "12px" : "sm";

		return {
			headingFontColor,
			headingFontSize,
			headingLetterSpacing,
			headingTextTransform,

			flexDirection,

			subheadingMinW,
			subheadingMaxW,
			subheadingTextAlign,
			subheadingFontColor,
			subheadingFontSize,
			subheadingLetterSpacing,

			inputFontSize,
			inputFontColor,
			inputBottomColor,
			inputBottomWidth,
			inputBorderColor,
			inputBorderWidth,
			descFontSize,

			radioFontSize,
		};
	}, [isPrinting, isInvalid]);

	const textInputStyles = useMemo(() => {
		let textInputPY = isPrinting ? 1 : 0;
		let textInputPX = isPrinting ? 1 : 0;

		return {
			py: textInputPY,
			px: textInputPX,
		};
	}, [isPrinting]);

	useMemo(() => {
		if (Boolean(props.input)) {
			setInput(props.input);
		}
	}, [props.input]);

	useMemo(() => {
		if (!Boolean(props.isInvalid)) {
			setIsInvalid.off();
		}
	}, [props.isInvalid, setIsInvalid]);

	const inputRef = useRef(null); // Create a ref for the input element

	const handleFocus = () => {
		if (inputRef.current) {
			inputRef.current.focus(); // Focus the input element
			// inputRef.current.scrollIntoView();
			var viewportOffset = inputRef.current?.getBoundingClientRect();
			// these are relative to the viewport, i.e. the window
			let top = viewportOffset?.top ?? 0;
			if (top > 30) {
				top = top - 30;
			}

			window.scrollTo({
				top,
				behavior: "smooth",
			});
		}
	};

	useMemo(() => {
		if (Boolean(validationError?.inputUID === input.inputUID)) {
			setIsInvalid.on();
			if (validationError?.message?.includes("This selection requires a comment")) {
				setShowCommentsCard.on();
			}
			handleFocus();
		}
	}, [input.inputUID, setIsInvalid, setShowCommentsCard, validationError?.inputUID, validationError?.message]);

	useMemo(() => {
		if (comments?.length > 0 && ["2", "3"].includes(form.formType)) {
			setShowCommentsCard.on();
		}
	}, [comments?.length, form.formType, setShowCommentsCard]);

	return (
		<Box
			key={"input" + input?.inputUID}
			id={"input" + input?.inputUID}
			w="full"
			rounded="md"
			role={!isReadOnly && !showCommentsCard && "group"}
		>
			<Flex align="center" w="full" flex={1} rounded="md">
				{["text"].includes(question?.inputType) && (
					<HStack align="flex-start" w="full" flex={1} py={textInputStyles.py}>
						{section?.heading !== "Overall Comments" && (
							<Text
								minW="var(--chakra-sizes-5)"
								fontSize={styles.subheadingFontSize}
								color={"transparent"}
								py={1}
							>
								{inputNum + 1}
							</Text>
						)}
						<Flex
							w="full"
							flex={1}
							justify="space-between"
							minH="40px"
							borderTopWidth={styles.inputBorderWidth}
							borderRightWidth={styles.inputBorderWidth}
							borderLeftWidth={styles.inputBorderWidth}
							borderColor={styles.inputBorderColor}
							borderBottomColor={styles.inputBottomColor}
							borderBottomWidth={styles.inputBottomWidth}
							align="center"
							lineHeight={"short"}
							bg="whiteAlpha.700"
							borderTopRadius="md"
							px={textInputStyles.px}
						>
							{isPrinting ? (
								<HStack align="flex-start" w="full" flex={1} py={1}>
									<Text fontSize={styles.inputFontSize} color={styles.inputFontColor}>
										{input?.value}
									</Text>
								</HStack>
							) : (
								// <DataInput
								// 	type="textarea"
								// 	inputRef={inputRef}
								// 	inputBorder={0}
								// 	rowsMin={
								// 		form?.formType === "1" ? 5 : section?.heading === "Overall Comments" ? 8 : 3
								// 	}
								// 	id={input?.inputUID}
								// 	placeholder="Response..."
								// 	defaultValue={input?.value ?? null}
								// 	onBlur={(ev) => updateFormInput(ev, "value")}
								// 	inputProps={{
								// 		fontSize: styles.inputFontSize,
								// 		color: styles.inputFontColor,
								// 		lineHeight: "var(--chakra-lineHeights-short)",
								// 	}}
								// 	isReadOnly={isReadOnly}
								// 	preventScroll={true}
								// />
								<DataInput
									type="textarea"
									rowsMin={2}
									// mt="-1"
									inputRef={inputRef}
									inputBorder={0}
									placeholder="Add Notes (optional)"
									onBlur={(ev) => updateFormInput(ev, "value")}
									defaultValue={input?.value ?? null}
									isReadOnly={isReadOnly}
									isModal
								/>
							)}
						</Flex>
					</HStack>
				)}

				{["listText"].includes(question?.inputType) && (
					<Flex w="full" flex={1} justify="space-between" align="center">
						<HStack align="flex-start" w="full" flex={1}>
							<Flex
								borderBottomColor={"transparent"}
								borderBottomWidth={styles.inputBottomWidth}
								align="center"
								py={1}
								lineHeight={"short"}
							>
								<Heading
									minW="var(--chakra-sizes-6)"
									fontSize={styles.radioFontSize}
									color={styles.subheadingFontColor}
									textAlign="right"
									py={1}
								>
									{inputNum + 1 + "."}
								</Heading>
							</Flex>
							<Flex
								w="full"
								justify="space-between"
								borderBottomColor={styles.inputBottomColor}
								borderBottomWidth={styles.inputBottomWidth}
								align="center"
								py={textInputStyles.py}
								px={textInputStyles.px}
								lineHeight={"short"}
								bg="whiteAlpha.700"
								borderTopRadius="md"
							>
								{isPrinting ? (
									<>
										<HStack align="flex-start" w="full" flex={1} py={1}>
											<Text fontSize={styles.inputFontSize} color={styles.inputFontColor}>
												{input?.value}
											</Text>
										</HStack>
										<Text
											minW="var(--chakra-sizes-6)"
											fontSize={styles.inputFontSize}
											color={"transparent"}
										>
											{inputNum + 1}
										</Text>
									</>
								) : (
									<DataInput
										type="textarea"
										inputBorder={0}
										inputRef={inputRef}
										rowsMin={1}
										id={input?.inputUID}
										placeholder="Response..."
										defaultValue={input?.value ?? null}
										onBlur={(ev) => updateFormInput(ev, "value")}
										inputProps={{
											fontSize: styles.inputFontSize,
											color: styles.inputFontColor,
											lineHeight: "var(--chakra-lineHeights-short)",
										}}
										isReadOnly={isReadOnly}
										isModal
									/>
								)}
							</Flex>
						</HStack>
					</Flex>
				)}

				{["radio"].includes(question?.inputType) && (
					<Flex w="full" flex={1} justify="space-between" align="center" ref={inputRef}>
						<HStack align="center" w="full" flex={1} lineHeight={"short"} py={1}>
							<Text minW="var(--chakra-sizes-6)" fontSize={styles.inputFontSize} color={"transparent"}>
								{inputNum + 1}
							</Text>

							<Radio
								minW="var(--chakra-sizes-6)"
								fontSize={styles.radioFontSize}
								color={styles.subheadingFontColor}
								textAlign="center"
								bg="whiteAlpha.700"
								borderColor={styles.inputBottomColor}
								colorScheme="teal"
								size="lg"
								id={input?.inputUID}
								value={input?.value}
								key={input?.inputUID + "-" + input?.value}
								onClick={() =>
									!isReadOnly &&
									updateFormInput(input?.value?.toString() !== "1" ? "1" : "0", "value")
								}
								isChecked={parseInt(input?.value) === 1}
								isDisabled={isReadOnly}
							></Radio>
							{Boolean(input?.description) && (
								<Text
									px={2}
									// py={1}
									fontSize={styles.inputFontSize}
									color={styles.inputFontColor}
									fontWeight="semibold"
								>
									{input?.description}
								</Text>
							)}
						</HStack>
					</Flex>
				)}

				{["rating"].includes(question?.inputType) && (
					<HStack w="full" flex={1} ref={inputRef}>
						<Flex
							w="full"
							flex={1}
							justify="space-between"
							align="center"
							bg="gray.100"
							borderWidth={2}
							shadow="sm"
							minH={"40px"}
							rounded="md"
							h="full"
							fontWeight={"semibold"}
							_groupHover={{ bg: "gray.50", fontWeight: "bold" }}
						>
							<HStack align="center" w="full" flex={3}>
								{Boolean(input?.description) && (
									<Text px={2} fontSize={styles.descFontSize} color={styles.inputFontColor}>
										{input?.description}
									</Text>
								)}
							</HStack>
							{ratings?.map((rating, r) => (
								<HStack
									align="center"
									spacing={1}
									w="full"
									key={rating?.ratingUID + " - " + r}
									flex={1}
									textAlign="center"
									justify="center"
									borderLeftColor={styles.inputBottomColor}
									borderLeftWidth={2}
								>
									{inputs?.map((input, i) => (
										<>
											{input?.formUID === form?.formUID && (
												<Radio
													key={input?.inputUID + " - " + i}
													minW="var(--chakra-sizes-6)"
													fontSize={styles.radioFontSize}
													color={styles.subheadingFontColor}
													textAlign="center"
													bg="whiteAlpha.800"
													borderColor={styles.inputBottomColor}
													colorScheme="teal"
													size="lg"
													id={input?.inputUID}
													value={input?.value}
													onClick={() => {
														if (!isReadOnly) {
															updateFormInput(
																input?.value === rating?.order?.toString()
																	? ""
																	: rating?.order?.toString(),
																"value"
															);
														}
													}}
													isChecked={input?.value === rating?.order?.toString()}
													isDisabled={isReadOnly}
													_disabled={{
														bg: "whiteAlpha.800",
														_checked: {
															bg: "teal.500",
															color: "whiteAlpha.800",
															borderColor: "teal.500",
														},
													}}
												/>
											)}
											{input?.formUID !== form?.formUID &&
												form?.formUID === form?.parentFormUID &&
												input?.value === rating?.order?.toString() && (
													<>
														{/* //OLD METHOD WHEN MULTIPLE TABS WERE SHOWN */}
														<Avatar
															size="xs"
															fontSize="lg"
															bg={"blue.500"}
															color="whiteAlpha.900"
															name={
																formData?.forms?.find(
																	(d) => d.formUID === input?.formUID
																)?.assignedToName ?? null
															}
															src={
																usersMap[
																	formData?.forms?.find(
																		(d) => d.formUID === input?.formUID
																	)?.assignedTo ?? null
																]?.profilePhoto ?? null
															}
														/>
													</>
												)}
										</>
									))}
								</HStack>
							))}
						</Flex>
						{form?.formType === "3" && (
							<Flex minW={"35px"} justify="center" maxW="35px" w="35px">
								<Text color="gray.400">{score === "0/0" ? "N/A" : score}</Text>
							</Flex>
						)}
					</HStack>
				)}
				{form?.formType !== "1" && !isPrinting && (
					<HStack
						fontSize={styles.headingFontSize}
						color={styles.headingFontColor}
						justify="center"
						align="flex-end"
						_groupHover={{ opacity: question?.inputType === "radio" ? input.value : 1 }}
						_hover={{ opacity: question?.inputType === "radio" ? input.value : 1 }}
						opacity={showCommentsCard || comments?.length > 0 ? 1 : 0}
						px={1}
					>
						<ShowCommentsButton
							commentCount={comments?.length ?? 0}
							isOpen={showCommentsCard}
							isReadOnly={isReadOnly}
							setIsOpen={setShowCommentsCard}
						/>
					</HStack>
				)}

				{/* VALIDATE INPUT TEST 
				<Button
					variant="outline"
					onClick={() => handleFocus(input)}
					color="yellow.500"
					px={2}
					size="md"
					bg="whiteAlpha.800"
					shadow="md"
					textTransform="uppercase"
					_hover={{
						color: "orange.500",
						fontWeight: "bold",
						bg: "orange.50",
						borderColor: "orange.500",
						borderWidth: 2,
					}}
					tabIndex={-1}
				>
					<HStack align="center" spacing={1}>
						<Text lineHeight={1} className={"fas fa-exclamation-circle fa-fw"} fontSize="sm" />
						<Text lineHeight={1} fontSize="sm" letterSpacing={1} pr={1}>
							Focus
						</Text>
					</HStack>
				</Button> */}
			</Flex>

			<Collapse in={showCommentsCard}>
				<Container w="full" flex={1} maxW="container.lg" py={showCommentsCard ? 4 : 0}>
					<BasicCard portalStyles={commentCardStyles} title={"Comments"}>
						<Stack w="full" flex={1} spacing={6} pt={4}>
							{comments?.map((comment, c) => (
								<FormComment
									key={c}
									form={form}
									input={input}
									comment={comment}
									usersMap={usersMap}
									deleteFormComment={deleteFormComment}
								/>
							))}
							{canAddEditComments && (
								<Flex w="full" flex={1} justify="center">
									<Button
										variant="outline"
										onClick={() => insertFormComment()}
										color="gray.500"
										px={2}
										size="md"
										bg="whiteAlpha.800"
										shadow="md"
										textTransform="uppercase"
										_hover={{
											color: "blue.500",
											fontWeight: "bold",
											bg: "whiteAlpha.900",
											borderColor: "blue.500",
											borderWidth: 2,
										}}
										tabIndex={-1}
									>
										<HStack align="center" spacing={1}>
											<Text lineHeight={1} className={"fas fa-plus fa-fw"} fontSize="sm" />
											<Text lineHeight={1} fontSize="sm" letterSpacing={1} pr={1}>
												Add Comment
											</Text>
										</HStack>
									</Button>
								</Flex>
							)}
						</Stack>
					</BasicCard>
				</Container>
			</Collapse>
		</Box>
	);
}

function ShowCommentsButton({ commentCount = 0, isReadOnly, isOpen = true, setIsOpen }) {
	let label = "Show Comments";
	if (!isReadOnly) {
		label = "Add Comments";
	}
	// else if (isReadOnly && commentCount === 0) {
	// 	label = "Show Comments";
	// }

	if (Boolean(setIsOpen)) {
		return (
			<ToggleButton
				toggleIsOpen={isOpen}
				onClick={setIsOpen?.toggle}
				toggleOptions={[
					{
						isOpen: true,
						label: "Hide Comments",
						icon: "fas fa-comment-alt",
						color: "whiteAlpha.800",
						bg: "blue.400",
						tabIndex: -1,
						hover: { bg: "gray.300", color: "whiteAlpha.800" },
					},
					{
						isOpen: false,
						label: label,
						icon: "far fa-comment-alt",
						color: "blue.500",
						bg: "whiteAlpha.800",
						tabIndex: -1,
						hover: { bg: "blue.50", color: "blue.300" },
					},
				]}
			/>
		);
	} else {
		return <></>;
	}
}
