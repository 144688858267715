import React, { useEffect, useState } from "react";
import { Box, Flex, Heading, Stack, HStack, Skeleton } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { motion } from "framer-motion";
import PayrollAPI from "../../../APIs/PayrollAPI";
import BasicCard from "../../../core/Layout/cards/BasicCard";
import TimecardClass from "../../../legacy/Payroll/classes/TimecardClassLegacy";
import { portalUser, ppmUser } from "../../../App";
import { formatValue } from "../../../helperFunctions";

export default function TimecardSummaryCard() {
	const [timecard, setTimecard] = useState(null);
	const [payPeriod, setPayPeriod] = useState(null);

	useEffect(() => {
		const payrollAPI = new PayrollAPI();
		const init = async () => {
			let timecardHeader = await payrollAPI.GetTimecardHeaders(
				portalUser.user?.employeeUID,
				portalUser.user?.prco,
				portalUser.user?.prEndDate
			);

			let timecardList = new TimecardClass(ppmUser, portalUser.user?.prEndDate, timecardHeader.value[0]);
			await timecardList.loadPayweekTime();

			setTimecard(timecardList);
			setPayPeriod(portalUser.user?.prEndDate);
		};
		if (Boolean(portalUser.user?.employeeUID)) {
			init();
		}
	}, []);

	return (
		<motion.div whileHover={{ scale: 1.01, transition: { duration: 0.5 } }} id="card-timecard">
			<RouterLink style={{ textDecoration: "none" }} to="/payroll/timecard">
				<BasicCard title="Timecard Summary" portalStyles={portalUser.styles}>
					<Box className="hoverPointer">
						<Skeleton isLoaded={timecard} minH="60px" rounded={5}>
							<Flex flex={1} align="center" color="gray.500" justify="space-between" direction="column">
								<Flex flex="1" w="full">
									<HStack spacing="3" flex={1} py="3" w="full" justify="left" flexBasis="225px">
										<Heading as="h4" color={"teal.600"} size="lg">
											<i className="fas fa-clock fa-lg fa-fw " />
										</Heading>
										<Stack spacing={1}>
											<Heading variant="title" isTruncated size="xs" color="gray.400">
												Week Ending
											</Heading>
											<Heading
												as="h4"
												isTruncated
												textTransform="uppercase"
												letterSpacing={1}
												fontWeight="semibold"
												fontSize="xl"
											>
												{formatValue(payPeriod, 0, "date")}
											</Heading>
										</Stack>
									</HStack>

									<HStack spacing="3" flex={1} py="3" w="full" justify="left" flexBasis="225px">
										<Heading as="h4" color={"teal.600"} size="lg">
											<i className="fas fa-user-clock fa-lg fa-fw " />
										</Heading>
										<Stack textAlign="center" spacing={1}>
											<Heading variant="title" isTruncated size="xs" color="gray.400">
												Week Total
											</Heading>
											<HStack spacing="2">
												<Heading
													as="h4"
													isTruncated
													textTransform="uppercase"
													letterSpacing={1}
													fontWeight="semibold"
													fontSize="xl"
												>
													{timecard?.getWeekTotal().toFixed(1)}
												</Heading>
												<Heading
													as="h4"
													isTruncated
													textTransform="uppercase"
													letterSpacing={1}
													fontWeight="normal"
													fontSize="lg"
												>
													Hrs
												</Heading>
											</HStack>
										</Stack>
									</HStack>
								</Flex>

								<Flex flex="1" w="full">
									<HStack spacing="3" flex={1} py="3" w="full" justify="left" flexBasis="225px">
										<Heading as="h4" color={timecard?.getStatus().color + ".500"} size="lg">
											<i className={timecard?.getStatus().icon + " fa-fw "} />
										</Heading>
										<Stack spacing={1}>
											<Heading variant="title" size="xs" color="gray.400">
												Status
											</Heading>
											<Heading
												as="h4"
												isTruncated
												textTransform="uppercase"
												fontWeight="semibold"
												fontSize="xl"
											>
												{timecard?.getStatus().status}
											</Heading>
										</Stack>
									</HStack>

									<HStack spacing="3" flex={1} py="3" w="full" justify="left" flexBasis="225px">
										<Heading as="h4" color={"teal.600"} size="lg">
											<i className={"fas fa-file-invoice-dollar fa-lg fa-fw "} />
										</Heading>
										<Stack spacing={1}>
											<Heading variant="title" size="xs" color="gray.400">
												Expenses
											</Heading>
											<HStack>
												<Heading
													as="h4"
													isTruncated
													textTransform="uppercase"
													letterSpacing={1}
													fontWeight="semibold"
													fontSize="xl"
												>
													$0.00
												</Heading>
												<Heading
													as="h4"
													isTruncated
													textTransform="uppercase"
													letterSpacing={1}
													fontWeight="semibold"
													fontSize="md"
												>
													(Due Fri)
												</Heading>
											</HStack>
										</Stack>
									</HStack>
								</Flex>
							</Flex>
						</Skeleton>
					</Box>
				</BasicCard>
			</RouterLink>
		</motion.div>
	);
}
