import React from "react";
import { Page, Document, StyleSheet, View, Text } from "@react-pdf/renderer";
import PageHeaderPDF from "./PageHeaderPDF";
import SectionPDF from "./SectionPDF";
import { v4 } from "uuid";
// import { log, chakraThemeColorsMap } from "../../helperFunctions";

export function DocumentPDF(props) {
	const document = React.useMemo(() => props?.document ?? [], [props?.document]);
	// https://stackoverflow.com/questions/69642937/re-rendering-issues-when-loading-all-pdf-pages-in-react-pdf
	return (
		<Document
			title={props.title}
			fileName={props.fileName}
			creator="PPM Portal"
			pageMode="fullScreen"
			key={props.fileName}
		>
			{document?.reports?.map((report, i) => {
				return (
					<React.Fragment key={i}>
						{report?.pages?.map((page, j) => {
							let pageSize = page?.size ?? "LETTER";

							const pageStyles = StyleSheet.create({
								page: {
									fontFamily: "Helvetica",
									lineHeight: 1,
									flexDirection: "column",
									alignItems: "center",
									paddingBottom: 42,
									position: "relative",
								},
								pageHeader: {
									paddingTop: 12,
									paddingHorizontal: 16,
									paddingBottom: 8,
									width: "100%",
								},
								pageBody: {
									paddingHorizontal: 16,
									width: "100%",
									flexDirection: "column",
									flexGrow: 1,
									fontSize: 10,
									// backgroundColor: chakraThemeColorsMap.green100,
								},
								pageFooter: {
									paddingTop: 16,
									paddingBottom: 16,
									fontSize: pageSize === "TABLOID" ? 14 : 12,
									width: "100%",
									textAlign: "center",
									color: "grey",
									alignItems: "center",
									position: "absolute",
									bottom: 0,
								},
							});

							return (
								<React.Fragment key={j}>
									{page.show && (
										<Page
											key={page?.pageUID ?? v4()}
											wrap
											style={pageStyles?.page}
											size={page?.size ?? "LETTER"}
											orientation={page?.orientation ?? "portrait"}
										>
											<View
												key={(page?.keyID ?? "page0") + "-header"}
												style={pageStyles?.pageHeader}
												fixed
											>
												<PageHeaderPDF page={page} logo={props?.logo} />
											</View>

											<View wrap style={pageStyles?.pageBody}>
												{page?.pageBody?.map((section) => {
													return <SectionPDF section={section} />;
												})}
											</View>

											<View
												key={(page?.keyID ?? "page0") + "-footer"}
												style={pageStyles?.pageFooter}
												fixed
											>
												<Text
													render={({ pageNumber, totalPages }) =>
														`Page ${pageNumber} / ${totalPages}`
													}
												/>
											</View>
										</Page>
									)}
								</React.Fragment>
							);
						})}
					</React.Fragment>
				);
			})}
		</Document>
	);
}

export default DocumentPDF;
