import React, { useEffect, useState, useMemo, useRef, useCallback, forwardRef } from "react";
import {
	Switch,
	Menu,
	MenuItem,
	MenuGroup,
	MenuButton,
	MenuList,
	Flex,
	Text,
	Heading,
	VStack,
	Stack,
	HStack,
	IconButton,
	useBoolean,
	Collapse,
	Portal,
} from "@chakra-ui/react";

import {
	useTable,
	useResizeColumns,
	useGroupBy,
	useExpanded,
	useGlobalFilter,
	useFilters,
	useSortBy,
	useBlockLayout,
	useColumnOrder,
} from "react-table";
import { VariableSizeList } from "react-window";
import { useExportData } from "react-table-plugins";

import GlobalSearchFilter from "../inputs/GlobalSearchFilter";
import TextInputColumnFilter from "../inputs/TextInputFilter";
import getFilterTypes from "../functions/getFilterTypes";
import getExportFileBlob from "../functions/getExportFileBlob";

import moment from "moment";
import { v4 } from "uuid";
import {
	formatNumber,
	log,
	formatValue,
	// log,
	// , getValueColor
} from "../../../helperFunctions";

export default function ProjectSelectVirtualList(props) {
	const containerRef = useRef(null);
	const listRef = useRef(null);
	const columns = useMemo(() => props.columns, [props.columns]);
	const data = useMemo(() => props.data, [props.data]);

	const [showAccess, setShowAccess] = useBoolean(false);

	const defaultColumn = useMemo(
		() => ({
			width: 150,
			Filter: TextInputColumnFilter,
			sortIcon: "alpha",
			show: true,
		}),
		[]
	);

	const filterTypes = getFilterTypes();

	const [containerHeight, setContainerHeight] = useState(containerRef?.current?.clientHeight ?? 1000);

	let initialHiddenColumns = [];
	let initalColumnsFalse = props.columns.filter((d) => d.show === false);
	if (initalColumnsFalse?.length > 0) {
		initialHiddenColumns = initalColumnsFalse.map((d) => d.accessor);
	}

	const {
		getTableProps,
		getTableBodyProps,
		prepareRow,
		rows,
		state,
		setGlobalFilter,
		toggleAllRowsExpanded,
		allColumns,
		exportData,
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			getExportFileBlob,

			filterTypes,
			initialState: {
				hiddenColumns: initialHiddenColumns,
				sortBy: props.sortBy,
				groupBy: props.groupBy,
				filters: props.filterBy,
			},
		},
		useBlockLayout,
		useColumnOrder,
		useResizeColumns,
		useFilters,
		useGlobalFilter,
		useGroupBy,
		useSortBy,
		useExportData,
		useExpanded
	);

	const RenderRow = useCallback(
		({ index, style }) => {
			const row = rows[index];
			prepareRow(row);

			// const formatCell = (value = null, decimals = 0, valueType = "string") => {
			// 	if (value === null || valueType === "string") {
			// 		return value;
			// 	} else if (valueType === "number") {
			// 		return formatNumber(value, decimals);
			// 	} else if (valueType === "currency") {
			// 		return formatNumber(value, decimals, valueType);
			// 	} else if (valueType === "percent") {
			// 		return formatNumber(value, decimals, valueType);
			// 	} else if (valueType === "month") {
			// 		return moment(value).format("MMMM YYYY");
			// 	} else if (valueType === "date") {
			// 		return moment(value).format("M/D/YYYY");
			// 	} else if (valueType === "dateTime") {
			// 		return moment(value).format("M/D/YYYY h:mm A");
			// 	} else if (valueType === "boolean") {
			// 		return value === "Y" || value === true || parseFloat(value) === 1 ? "Yes" : "No";
			// 	} else {
			// 		return value;
			// 	}
			// };

			let groupLevel = 0;

			if (row?.isGrouped) {
				log("grouped Row", row);

				let columns = row?.cells?.map((tab) => tab.column);
				// log("columns " + row.groupByID, columns);
				let column = columns?.find((d) => d.id === row?.groupByID) ?? null;
				log("column " + row?.groupByID, column);
				groupLevel = state.groupBy?.findIndex((x) => x === row?.groupByID) + 1;

				//let column = allColumns?.find((d) => d.id === row?.groupByID) ?? {};
				// let groupByVal = null;
				//groupByVal = formatCell(row.groupByVal, column.decimals, column.valueType);
				// if (column?.valueType === "boolean") {
				// 	groupByVal = column.Header + ": " + groupByVal;
				// }

				return (
					<Flex {...row.getRowProps({ style })} flex={1} w="full" pr={2} h="full" overflow="hidden">
						{row.isGrouped && (
							<Flex w="full" flex={1} align="center" justify="space-between">
								{state.groupBy.length > 1 && groupLevel === 1 && (
									<HStack pt={6} pb={2} w="full" flex={1}>
										<Heading
											letterSpacing={4}
											fontSize="2xl"
											color="gray.600"
											textShadow="2px 2px 2px white"
											textTransform="uppercase"
											isTruncated
											opacity={0.9}
										>
											{formatValue(row?.groupByVal, column?.decimals, column?.valueType)}
										</Heading>
									</HStack>
								)}

								{((state.groupBy.length === 1 && groupLevel === 1) ||
									(state.groupBy.length > 1 && groupLevel === 2)) && (
									<HStack textTransform="uppercase" color="gray.600">
										<IconButton
											key={"GroupBy-" + row?.groupByID + (row.isExpanded ? "-Open" : "-Closed")}
											onClick={() => {
												if (Boolean(listRef?.current)) {
													listRef?.current?.resetAfterIndex(0, false);
												}
												row.toggleRowExpanded();
											}}
											borderRadius="md"
											borderWidth={2}
											borderColor="gray.400"
											fontWeight="semibold"
											bg="whiteAlpha.700"
											color="gray.500"
											_hover={{
												color: "teal.500",
												borderColor: "teal.500",
												borderWidth: "2px",
											}}
											size="xs"
											_focus={{ boxShadow: "outline", borderWidth: "2px" }}
											icon={
												<i
													className={
														"fas " + (row.isExpanded ? "fa-minus" : "fa-plus") + " fa-fw"
													}
												/>
											}
										/>
										<Heading letterSpacing={3} size="md" opacity={0.9} isTruncated>
											{formatValue(row?.groupByVal, column?.decimals, column?.valueType)}
										</Heading>
									</HStack>
								)}

								{state.groupBy.length > 1 && groupLevel === 3 && (
									<HStack textTransform="uppercase" color="gray.500">
										<Heading letterSpacing={2} size="sm" isTruncated>
											{formatValue(row?.groupByVal, column?.decimals, column?.valueType)}
										</Heading>
									</HStack>
								)}
								{state.groupBy.length > 1 && groupLevel === 4 && (
									<HStack textTransform="uppercase" color="gray.500">
										<Heading letterSpacing={2} size="sm" isTruncated></Heading>
									</HStack>
								)}
							</Flex>
						)}
					</Flex>
				);
			} else {
				return (
					<Flex {...row.getRowProps({ style })} flex={1} w="full" pr={2} h="full" overflow="hidden">
						{!row.isGrouped && (
							<Flex
								_hover={{
									shadow: "md",
									color: "gray.600",
									bg: "white",
									borderColor: row.original?.accessYN === "Y" ? "teal.500" : "red.600",
									opacity: 1,
									borderWidth: 3,
								}}
								p={4}
								mt={state.groupBy.length === 0 ? 1 : 0}
								mb={state.groupBy.length === 0 ? 1 : 2}
								flex={1}
								w="full"
								borderWidth={2}
								borderColor={"gray.400"}
								bg={"whiteAlpha.800"}
								color="gray.500"
								rounded={10}
								shadow="sm"
								align={"flex-start"}
								opacity={row.original.accessYN === "N" ? 0.85 : 1}
								cursor={row.original.accessYN === "N" ? "not-allowed" : "pointer"}
								onClick={() => {
									if (row.original.accessYN === "Y") {
										props.selectContract(row.original.jccmKeyID);
									} else {
									}
								}}
								key={v4()}
							>
								<VStack w="full" flex={1} spacing={row.original.accessYN === "N" ? 0 : 3} h="full">
									<Flex w="full" flex={1} justify="space-between">
										<Stack spacing={1} isTruncated w="full">
											<HStack textTransform="uppercase" align="flex-end" spacing={2} isTruncated>
												<Heading fontSize="md" letterSpacing={1}>
													{row.original.contract}
												</Heading>
												<Heading isTruncated size="sm" letterSpacing={1}>
													{row.original?.description}
												</Heading>
											</HStack>

											<Text fontSize="sm" letterSpacing={1} whiteSpace="nowrap">
												{row.original?.customerName}
											</Text>
										</Stack>
										<Stack textAlign="right" justify="flex-end" align="flex-end" spacing={1}>
											{row.original?.accessYN === "N" && (
												<HStack
													pl={2}
													spacing={2}
													bg="gray.200"
													rounded={5}
													py={1}
													px={4}
													align="center"
													maxW="115px"
													justify="space-evenly"
													cursor="pointer"
													border="1px"
													borderColor="gray.400"
													color="gray.400"
													_hover={{ bg: "gray.100", border: "2px", color: "blue.500" }}
												>
													<Text>
														<i className={"fas fa-lock fa-lg"} />
													</Text>
													<Text
														textTransform="uppercase"
														letterSpacing={1}
														fontSize="xs"
														fontWeight="bold"
													>
														Request Access
													</Text>
												</HStack>
											)}

											<Text fontSize="sm" letterSpacing={1} whiteSpace="nowrap" pl={2}>
												{row.original?.projectCity}
											</Text>
										</Stack>
									</Flex>
									<Flex w="full" h="full" flex={1} align="center" justify="center">
										<VStack
											w="full"
											// h="full"
											align={row.original.accessYN === "N" ? "flex-start" : "center"}
										>
											<Flex w="full" flex={1} align="center" justify="space-evenly">
												<Stack spacing={0} flex={1}>
													<Text fontSize="xs" letterSpacing={1} whiteSpace="nowrap">
														Project Manager
													</Text>
													<Heading size="xs" whiteSpace="nowrap">
														{row.original?.pmName}
													</Heading>
												</Stack>

												<Stack spacing={0} flex={1} isTruncated>
													<Text fontSize="xs" letterSpacing={1} whiteSpace="nowrap">
														Project Accountant
													</Text>
													<Heading size="xs" whiteSpace="nowrap">
														{row.original?.paName}
													</Heading>
												</Stack>

												<Stack spacing={0} flex={1} isTruncated>
													<Text fontSize="xs" letterSpacing={1} whiteSpace="nowrap">
														Purchaser
													</Text>
													<Heading size="xs" whiteSpace="nowrap">
														{row.original?.purchaserName ?? "N/A"}
													</Heading>
												</Stack>

												<Stack spacing={0} flex={1} isTruncated>
													<Text fontSize="xs" letterSpacing={1} whiteSpace="nowrap">
														Project Duration
													</Text>
													<Heading size="xs" whiteSpace="nowrap">
														{moment(row.original?.startMth).format("MMM YYYY") +
															" - " +
															moment(row.original?.endMth).format("MMM YYYY")}
													</Heading>
												</Stack>
											</Flex>
											{row.original?.accessYN === "Y" && (
												<Flex w="full" flex={1} align="center" justify="space-evenly">
													<Stack spacing={0} flex={1}>
														<Text fontSize="xs" letterSpacing={1} whiteSpace="nowrap">
															Original Contract
														</Text>
														<HStack spacing={2}>
															<Heading size="xs" whiteSpace="nowrap">
																{formatNumber(
																	row?.original?.bidContractAmt,
																	0,
																	"currency"
																)}
																({formatNumber(row.original.bidMarginPct, 1, "percent")}
																)
															</Heading>
														</HStack>
													</Stack>

													<Stack spacing={0} flex={1} isTruncated>
														<Text fontSize="xs" letterSpacing={1} whiteSpace="nowrap">
															Projected Contract
														</Text>
														<HStack spacing={2}>
															<Heading size="xs" whiteSpace="nowrap">
																{formatNumber(
																	row?.original?.projContractAmt,
																	0,
																	"currency"
																)}
																(
																{formatNumber(row.original.projMarginPct, 1, "percent")}
																)
															</Heading>
														</HStack>
													</Stack>

													<Stack spacing={0} flex={1} isTruncated>
														<Text fontSize="xs" letterSpacing={1} whiteSpace="nowrap">
															Billed To Date
														</Text>
														<HStack spacing={2}>
															<Heading size="xs" whiteSpace="nowrap">
																{formatNumber(row?.original?.billedAmt, 0, "currency")}(
																{formatNumber(
																	row.original.billedToDatePct,
																	1,
																	"percent"
																)}
																)
															</Heading>
														</HStack>
													</Stack>

													<Stack spacing={0} flex={1} isTruncated>
														<Text fontSize="xs" letterSpacing={1} whiteSpace="nowrap">
															Cost To Date
														</Text>

														<Heading size="xs" whiteSpace="nowrap">
															{formatNumber(row?.original?.actualCost, 0, "currency")}(
															{formatNumber(row.original.costToDatePct, 1, "percent")})
														</Heading>
													</Stack>
												</Flex>
											)}
										</VStack>
									</Flex>
								</VStack>
							</Flex>
						)}
					</Flex>
				);
			}
		},
		[prepareRow, rows, state.groupBy, props.selectContract, allColumns]
	);

	let ListContainer = useMemo(() => {
		return (
			<VariableSizeList
				ref={listRef}
				height={containerHeight}
				itemCount={rows.length}
				itemSize={(index) => {
					let groupIndex = state.groupBy.findIndex((x) => x === rows[index]?.groupByID);
					return rows[index]?.canExpand
						? groupIndex >= 3
							? 0
							: groupIndex === 2
							? 30
							: groupIndex === 1
							? 45
							: 60
						: 180;
				}}
				style={{ overflowX: "hidden" }}
			>
				{RenderRow}
			</VariableSizeList>
		);
	}, [containerHeight, rows, RenderRow, state.groupBy]);

	// Resize Observer
	useEffect(() => {
		// wait for the elementRef to be available
		if (!containerRef.current) return;
		const resizeObserver = new ResizeObserver(() => {
			// Do what you want to do when the size of the element changes
			if (containerRef?.current?.clientHeight >= 1200) {
				setContainerHeight(1200);
			} else {
				setContainerHeight(containerRef?.current?.clientHeight);
			}
		});
		resizeObserver.observe(containerRef?.current);
		return () => resizeObserver.disconnect(); // clean up
	}, []);

	useEffect(() => {
		const column = allColumns?.find((d) => d.id === "accessYN") ?? null;
		if (Boolean(column)) {
			if (showAccess) {
				// log("showAccess: click show All");
				column?.setFilter([]);
			} else {
				// log("showAccess: click only Yes");
				column?.setFilter("Y");
			}
		}

		if (Boolean(listRef?.current)) {
			listRef?.current?.resetAfterIndex(0, false);
		}
		toggleAllRowsExpanded(true);
	}, [showAccess, allColumns, toggleAllRowsExpanded]);

	return (
		<Stack {...getTableProps()} minW="container.sm" h="full">
			<Flex w="full" justify="space-between" align="flex-end">
				<Heading
					w="full"
					textAlign="left"
					color="whiteAlpha.900"
					textShadow="2px 2px 2px var(--chakra-colors-gray-600)"
					textTransform="uppercase"
					size="lg"
					letterSpacing={6}
				>
					{props.heading}
				</Heading>
			</Flex>
			<Stack bg="whiteAlpha.600" rounded={5} py={4} pl={4} pr={2} borderColor="gray.200" borderWidth={2} h="full">
				<HStack align="center" w="full" justify="flex-end" lineHeight={10} position="relative">
					<Flex flex={1} w="full" lineHeight={10}>
						<GlobalSearchFilter
							globalFilter={state.globalFilter}
							setGlobalFilter={setGlobalFilter}
							rowCount={
								(rows?.length ?? 0) - (rows?.filter((data) => data?.isGrouped === true)?.length ?? 0)
							}
							listRef={listRef}
							totalRowCount={data?.length}
						/>
					</Flex>

					<IconButton
						key={(showAccess ? "show" : "hide") + "-toggleShowAccessButton"}
						onClick={() => setShowAccess.toggle()}
						borderRadius="md"
						borderWidth={"2px"}
						fontWeight="semibold"
						bg={showAccess ? "gray.500" : "gray.100"}
						color={showAccess ? "white" : "gray.500"}
						borderColor={showAccess ? "gray.500" : ""}
						_hover={{
							color: showAccess ? "white" : "gray.500",
							borderColor: "gray.500",
							borderWidth: "2px",
						}}
						_focus={{ boxShadow: "outline", borderWidth: "2px" }}
						icon={<i className={"fas " + (showAccess ? "fa-unlock" : "fa-lock") + " fa-lg fa-fw"} />}
					></IconButton>

					<Menu p={0} placement="bottom-end">
						<MenuButton
							size="xs"
							px={2}
							py={2}
							transition="all 0.2s"
							borderRadius="md"
							borderWidth="2px"
							fontWeight="semibold"
							bg="gray.100"
							color="gray.500"
							h={10}
							minW={10}
							_hover={{
								color: "teal.500",
								borderColor: "teal.600",
							}}
							_expanded={{ bg: "teal.600", borderColor: "teal.600", color: "white" }}
							_focus={{ boxShadow: "outline" }}
						>
							<Text>
								<i className="fas fa-download fa-lg " />
							</Text>
						</MenuButton>

						<MenuList color="gray.500" bg="gray.100" overflowY="auto" maxH="container.md">
							<MenuGroup fontWeight="semibold" letterSpacing={1} color="gray.400">
								<MenuItem
									onClick={() => {
										exportData("xlsx", true);
									}}
								>
									<HStack align="center" spacing={3} letterSpacing={1}>
										<Text fontSize="lg">
											<i className="fas fa-file-excel fa-lg" />
										</Text>
										<Text fontWeight="semibold">EXCEL (.xlsx)</Text>
									</HStack>
								</MenuItem>
								<MenuItem
									onClick={() => {
										exportData("csv", true);
									}}
								>
									<HStack align="center" spacing={3} letterSpacing={1}>
										<Text fontSize="lg">
											<i className="fas fa-file-csv fa-lg" />
										</Text>
										<Text fontWeight="semibold">CSV (.csv)</Text>
									</HStack>
								</MenuItem>
							</MenuGroup>
						</MenuList>
					</Menu>

					<GroupByMenu
						allColumns={allColumns}
						toggleAllRowsExpanded={toggleAllRowsExpanded}
						groupBy={state.groupBy}
						initalSort={props.sortBy}
						listRef={listRef}
					/>
				</HStack>

				<Collapse in={showAccess}>
					<HStack justify="flex-start" w="full" align="flex-start">
						{showAccess && (
							<Text fontSize="sm" textAlign="right" color="gray.600">
								Showing Locked Jobs
							</Text>
						)}
					</HStack>
				</Collapse>

				<Flex
					{...getTableBodyProps()}
					borderTopColor="whiteAlpha.700"
					borderTopWidth={2}
					direction="column"
					flex={1}
					w="full"
					h="full"
					overflow="hidden"
					ref={containerRef}
				>
					{ListContainer}
				</Flex>
			</Stack>
		</Stack>
	);
}

const GroupByMenu = forwardRef(function ({ allColumns, toggleAllRowsExpanded, groupBy }, listRef) {
	const [columns, setColumns] = useState([]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => setColumns(allColumns.filter((d) => d.showGroupBy === true)), []);

	return (
		<Menu closeOnSelect={false} p={0} placement="bottom-end" isLazy>
			<MenuButton
				size="xs"
				p={2}
				borderRadius="md"
				borderWidth="2px"
				fontWeight="semibold"
				bg="gray.100"
				color="gray.500"
				h={10}
				minW={10}
				_hover={{
					color: "blue.500",
					borderColor: "blue.500",
				}}
				_expanded={{
					bg: "blue.500",
					color: "white",
					borderColor: "blue.500",
				}}
				_focus={{ boxShadow: "outline" }}
			>
				<Text>
					<i className="fas fa-cog fa-lg " />
				</Text>
			</MenuButton>
			<Portal>
				<MenuList color="gray.500" bg="gray.50" minW="md" maxH="lg" overflow="auto" className="skinnyScroll">
					<MenuGroup
						title={
							<Flex w="full" flex={1}>
								<Flex align="center" letterSpacing={1} flex={2} isTruncated>
									<Text
										fontWeight="semibold"
										lineHeight={1.15}
										fontSize="sm"
										textTransform="uppercase"
										isTruncated
									>
										Columns
									</Text>
								</Flex>
								<Flex align="center" letterSpacing={1} flex={1} isTruncated justify="center">
									<Text
										fontWeight="semibold"
										lineHeight={1.15}
										fontSize="sm"
										textTransform="uppercase"
										isTruncated
									>
										Group By
									</Text>
								</Flex>
							</Flex>
						}
						fontWeight="semibold"
						letterSpacing={1}
						color="gray.500"
						borderBottom="2px"
						borderColor="gray.400"
					>
						{columns.map((column, i) => {
							return (
								column.id !== "expander" &&
								!column.disableExport && (
									<MenuItem key={column.id}>
										<Flex align="center" letterSpacing={1} flex={2} isTruncated>
											<Text
												lineHeight={1.15}
												fontSize="sm"
												isTruncated
												fontWeight="semibold"
												textTransform="uppercase"
											>
												{column.Header}
											</Text>
										</Flex>
										<Flex align="center" letterSpacing={1} flex={1} justify="center">
											<Switch
												id={column.id + "-GroupBy"}
												colorScheme="blue"
												isChecked={column.isGrouped}
												{...column.getGroupByToggleProps()}
												key={column.accessor}
												onChange={() => {
													column.toggleGroupBy();

													for (let i = 0; i < groupBy?.length; i++) {
														let column = columns.find((d) => d.id === groupBy[i]) ?? {};
														if (Boolean(column?.id) && !column?.isSorted) {
															column.toggleSortBy(true, true);
														}
													}
													//column.toggleSortBy(false);
													if (Boolean(listRef?.current)) {
														listRef?.current?.resetAfterIndex(0, false);
													}
													toggleAllRowsExpanded(true);
												}}
											/>
										</Flex>
									</MenuItem>
								)
							);
						})}
					</MenuGroup>
				</MenuList>
			</Portal>
		</Menu>
	);
});
