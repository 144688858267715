import React from "react";

import MainContent from "../../legacy/App/components/MainContent";
import DevTeamPage from "./pages/DevTeamPage";

export default class DevOps extends React.Component {
	constructor(props) {
		super(props);
		this.state = { tabVal: 0 };
		this.handleTabChange = this.handleTabChange.bind(this);
	}

	handleTabChange(tabVal) {
		this.setState({ tabVal: tabVal });
	}

	render() {
		return (
			<MainContent
				title={this.props.pageName}
				tabs={[]}
				onTabChange={this.handleTabChange}
				tabVal={this.state.tabVal}
			>
				<DevTeamPage />
			</MainContent>
		);
	}
}
