import * as matchSorter from "match-sorter";

export default function getFilterTypes() {
	return {
		fuzzyText: (rows, id, filterValue) => {
			// fuzzyTextFilterFn.autoRemove = (val) => !val;
			return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
		},
		text: (rows, id, filterValue) => {
			return rows.filter((row) => {
				const rowValue = row.values[id];
				return rowValue !== undefined
					? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
					: true;
			});
		},
		textNoCaps: (rows, id, filterValue) => {
			if (!Boolean(filterValue)) {
				return rows;
			} else {
				return rows.filter(
					(row) =>
						Boolean(row.values[id]) &&
						String(row.values[id])?.toLowerCase().includes(String(filterValue)?.toLowerCase())
				);
			}
		},
		multiselect: (rows, id, filterValues) => {
			if (filterValues?.length === 0) return rows;
			return rows.filter((r) => filterValues.includes(r.values[id]));
		},

		roundedMedian: (leafValues) => {
			let min = leafValues[0] || 0;
			let max = leafValues[0] || 0;

			leafValues?.forEach((value) => {
				min = Math.min(min, value);
				max = Math.max(max, value);
			});

			return Math.round((min + max) / 2);
		},
		notequals: (rows, id, filterValue) => {
			return rows.filter((row) => {
				const rowValue = row.values[id];
				return rowValue !== undefined ? rowValue !== filterValue : true;
			});
		},
	};
}
