export default class SecurityGroup {
	constructor(securityGroup) {
		this.queryData = securityGroup?.queryData ?? securityGroup?.QueryData ?? "SecurityGroup";
		this.ddsgKeyID = securityGroup?.ddsgKeyID ?? securityGroup?.DDSGKeyID ?? null;
		this.securityGroup = securityGroup?.securityGroup ?? securityGroup?.SecurityGroup ?? null;
		this.name = securityGroup?.name ?? securityGroup?.Name ?? null;
		this.description = securityGroup?.description ?? securityGroup?.Description ?? null;
		this.groupType = securityGroup?.groupType ?? securityGroup?.GroupType ?? null;
		this.vpUserName = securityGroup?.vpUserName ?? securityGroup?.VPUserName ?? null;
	}
}
