import React, { useState, useMemo, useRef } from "react";
import {
	Flex,
	SlideFade,
	Text,
	useBoolean,
	InputGroup,
	InputLeftAddon,
	Input,
	HStack,
	InputRightAddon,
} from "@chakra-ui/react";
import { useAsyncDebounce } from "react-table";
import SearchButton from "./SearchButton";
export default function SearchBar({
	searchIsOpen,
	setSearchIsOpen,
	globalFilter,
	setGlobalFilter,
	rowCount = 0,
	listRef = null,
}) {
	const [value, setValue] = useState(globalFilter);
	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 200);
	const globalFilterRef = useRef(null);
	const [isFocused, setFocus] = useBoolean();

	const searchCount = useMemo(() => rowCount, [rowCount]);

	// const inputGroupHoverStyle = {
	// 	zIndex: "unset",
	// 	borderColor: "var(--chakra-colors-teal-500)",
	// 	borderWidth: "2px",
	// };
	const inputGroupStyle = {
		zIndex: "unset",
		borderColor: "var(--chakra-colors-teal-600)",
		borderWidth: "1px",
		boxShadow: "0px 0px 0px 1px var(--chakra-colors-teal-600)",
	};
	return (
		<Flex direction="row" justify="space-between" w="full" rounded={"md"}>
			<SlideFade
				in={searchIsOpen}
				offsetX="100px"
				offsetY="0px"
				unmountOnExit
				style={{
					display: "flex",
					flex: 1,
					flexDirection: "row",
					width: "100%",
					justifyContent: "flex-end",
					alignItems: "center",
				}}
			>
				<HStack spacing={2} flex={1}>
					{Boolean(setSearchIsOpen) && (
						<SearchButton searchIsOpen={searchIsOpen} setSearchIsOpen={setSearchIsOpen} />
					)}

					<InputGroup
						borderRadius="md"
						flex={1}
						style={isFocused ? inputGroupStyle : {}}
						// _hover={inputGroupHoverStyle}
						role="group"
					>
						<InputLeftAddon
							borderColor="gray.200"
							// borderRightWidth="1px"
							// _groupHover={{ color: "teal.500" }}
							fontWeight="bold"
							color={isFocused ? "teal.500" : "gray.500"}
							size="md"
							maxH={"full"}
							px={2}
							children={<i className="fas fa-search fa-lg fa-fw"></i>}
						/>

						<Input
							ref={globalFilterRef}
							size="md"
							variant="outline"
							colorScheme="teal"
							value={value || ""}
							maxH="100%"
							// onFocus={setFocus.on}
							// onBlur={setFocus.off}
							// _groupHover={{ borderTopWidth: "0px", borderBottomWidth: "0px" }}
							// borderTopWidth={isFocused ? "0px" : "1px"}
							// borderBottomWidth={isFocused ? "0px" : "1px"}
							bg="white"
							onChange={(e) => {
								setValue(e.target.value);
								onChange(e.target.value);
								if (listRef !== null) {
									listRef?.current.resetAfterIndex(0, false);
								}
							}}
							placeholder={`Search...`}
						/>

						<InputRightAddon
							borderColor="gray.200"
							// borderLeftWidth="1px"
							maxH={"full"}
							_groupHover={{ color: "teal.500" }}
							px={2}
							color={isFocused ? "teal.500" : "gray.500"}
							children={<Text>{searchCount + " " + (searchCount === 1 ? "Row" : "Rows")}</Text>}
						/>
					</InputGroup>
				</HStack>
			</SlideFade>
		</Flex>
	);
}
